import { call, put, takeLatest } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
import { loaiHangHoaService } from '../../services/loaiHangHoa/loaiHangHoa'
import { getAllLoaiHangHoa } from '../actions/loaiHangHoaAction'
import { on } from 'devextreme/events'
import { take } from 'lodash'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* loaiHangHoaSaga() {
  //get ThuocVatTuPhanLoai
  yield takeLatest(
    typeAction.GET_THUOC_VAT_TU_PHAN_LOAI,
    function* getThuocVatTuPhanLoai({ idnhom, keyword }) {
      try {
        const { data } = yield call(() => loaiHangHoaService.getAllLoaiHangHoa(idnhom, keyword))

        yield put({
          type: typeAction.DISPATCH_THUOC_VAT_TU_PHAN_LOAI,
          payload: data,
        })
      } catch (error) {
        yield console.log(error)
      }
    },
  )

  //post ThuocVatTuPhanLoai
  yield takeLatest(
    typeAction.ADD_NEW_THUOC_VAT_TU_PHAN_LOAI,
    function* postThuocVatTuPhanLoai({ form, onReset }) {
      try {
        const { data } = yield call(() => loaiHangHoaService.postLoaiHangHoa(form))
        yield put(getAllLoaiHangHoa())
        onReset()
        Toast.fire({
          icon: 'success',
          title: 'Tạo nhãn thành công!',
        })
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Tạo nhãn thất bại!',
        })
        yield console.log(err)
      }
    },
  )
  //update ThuocVatTuPhanLoai
  yield takeLatest(
    typeAction.UPDATE_THUOC_VAT_TU_PHAN_LOAI,
    function* updateThuocVatTuPhanLoai({ form }) {
      try {
        const { data } = yield call(() => loaiHangHoaService.updateLoaiHangHoa(form))
        yield put(getAllLoaiHangHoa())
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật hàng hóa thành công!',
        })
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật hàng hóa thất bại!',
        })
        yield console.log(err)
      }
    },
  )
  //get ThuocVatTuPhanLoai by id
  yield takeLatest(typeAction.GET_INFO_THUOC_VAT_TU, function* ({ id }) {
    try {
      const { data } = yield call(() => loaiHangHoaService.getDetailThuocVatTu(id))
      yield put({
        type: typeAction.DISPATCH_INFO_THUOC_VAT_TU,
        payload: data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })

  //delete ThuocVatTuPhanLoai
  yield takeLatest(typeAction.DELETE_THUOC_VAT_TU_PHAN_LOAI, function* ({ id, handleCancel }) {
    try {
      const { data } = yield call(() => loaiHangHoaService.deleteLoaiHangHoa(id))
      yield put(getAllLoaiHangHoa())
      handleCancel()
      Toast.fire({
        icon: 'success',
        title: 'Xóa hàng hóa thành công!',
      })
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Không thể xoá loại hàng vì có nhóm hàng liên quan',
      })
      yield console.log(err)
    }
  })

  //get NhomHangHoaByLoai
  yield takeLatest(
    typeAction.GET_NHOM_HANG_HOA_BY_LOAI,
    function* getNhomHangHoaByLoai({ id, keyword }) {
      try {
        const { data } = yield call(() => loaiHangHoaService.getNhomHangHoaByLoai(id, keyword))
        yield put({
          type: typeAction.DISPATCH_NHOM_HANG_HOA_BY_LOAI,
          payload: data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  yield takeLatest(typeAction.POST_NEW_NHOM_HANG_HOA, function* postNhom({ form, onReset }) {
    try {
      const { data } = yield call(() => loaiHangHoaService.postNhomHangHoa(form))
      onReset()
      Toast.fire({
        icon: 'success',
        title: 'Tạo nhóm hàng hóa thành công!',
      })
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Tạo nhóm hàng hóa thất bại!',
      })
      yield console.log(err)
    }
  })

  //get all Loai

  yield takeLatest(
    typeAction.GET_ALL_LOAI_NHOM_HANG_HOA,
    function* getAllLoaiNhomHangHoa({ setLoai }) {
      try {
        const { data } = yield call(() => loaiHangHoaService.getAllLoaiHangHoa('', ''))
        setLoai(data)
      } catch (err) {
        yield console.log(err)
      }
    },
  )

  yield takeLatest(
    typeAction.UPDATE_NHOM_HANG_HOA,
    function* updateNhomHangHoa({ form, handleCancel, fetchDanhMucNhan }) {
      try {
        const { data } = yield call(() => loaiHangHoaService.updateNhomHangHoa(form))
        handleCancel()
        fetchDanhMucNhan()
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật nhóm hàng hóa thành công!',
        })
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật nhóm hàng hóa thất bại!',
        })
        yield console.log(err)
      }
    },
  )

  yield takeLatest(
    typeAction.DELETE_NHOM_HANG_HOA,
    function* deleteNhomHangHoa({ id, fetchDanhMucNhan }) {
      try {
        const { data } = yield call(() => loaiHangHoaService.deleteNhomHangHoa(id))
        fetchDanhMucNhan()
        Toast.fire({
          icon: 'success',
          title: 'Xóa nhóm hàng hóa thành công!',
        })
      } catch (err) {
        console.log('err', err)
        Toast.fire({
          icon: 'error',
          title: 'Xóa nhóm hàng hóa thất bại!',
        })
        yield console.log(err)
      }
    },
  )

  yield takeLatest(
    typeAction.UPDATE_TPCH_NHOM_HANG_HOA,
    function* updateThucPhamChucNang({ active, listIdNhom, fetchDanhMucNhan }) {
      try {
        yield call(() => loaiHangHoaService.updateThucPhamChucNang(active, listIdNhom))
        fetchDanhMucNhan()
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật thành công!',
        })
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật thất bại!',
        })
        yield console.log(err)
      }
    },
  )
}

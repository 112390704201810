import React from 'react'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Card, Col, Row, Statistic } from 'antd'

const Thongke = () => (
  <Row gutter={16}>
    <Col span={6}>
      <Card bordered={false}>
        <Statistic
          title="Văn bản đến trong ngày"
          value={11}
          valueStyle={{ color: '#3f8600' }}
          suffix="văn bản"
        />
      </Card>
    </Col>
    <Col span={6}>
      <Card bordered={false}>
        <Statistic
          title="Văn bản đến trong tháng"
          value={23}
          valueStyle={{ color: '#3f8600' }}
          suffix="văn bản"
        />
      </Card>
    </Col>
    <Col span={6}>
      <Card bordered={false}>
        <Statistic
          title="Văn bản chờ xử lý"
          value={23}
          valueStyle={{ color: '#3f8600' }}
          suffix="văn bản"
        />
      </Card>
    </Col>
    <Col span={6}>
      <Card bordered={false}>
        <Statistic
          title="Văn bản đã xử lý"
          value={23}
          valueStyle={{ color: '#3f8600' }}
          suffix="văn bản"
        />
      </Card>
    </Col>
  </Row>
)

export default Thongke

import { SearchOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Empty, Input, Space, Table } from 'antd'
import moment from 'moment'
import 'moment/locale/vi'
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import * as typeAction from '../../../../store/constants/constants'

import { getInfoPTNhapByIdAction } from '../../../../store/actions/NhapKhoAction'
import { listInfoThuocVTChyenKho_Action } from '../../../../store/actions/chuyenKhoAction'
import ModalDetail from './ModalDetail'
import ModalDetailXuat from './ModalXuat/ModalDetailXuat'
import { configSearchTableAnt } from '../../../../utils/configSearchTableAntd'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
moment.locale('vi')

const TableTheKho = ({ isloading, setSearchValues, searchValues, setValueExport }) => {
  const { historyTheKho } = useSelector((state) => state.thuocVTReducer)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [show, setShow] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenXuat, setIsModalOpenXuat] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const dispatch = useDispatch()

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setShow(false)
    setIsModalOpen(false)
    // tra về kho chi iết ban đầu khi cancel
    dispatch({
      type: typeAction.DISPATCH_INFO_THUOVT_NHAP_KHO,
      payload: [],
    })
    dispatch({
      type: typeAction.DISPATCH_INFO_PT_NHAP_KHO,
      payload: null,
    })
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }
  useEffect(() => {
    const filterData = () => {
      let filtered = historyTheKho
      Object.keys(searchValues).forEach((key) => {
        if (searchValues[key]) {
          filtered = filtered.filter((item) =>
            item[key]?.toString().toLowerCase().includes(searchValues[key].toLowerCase()),
          )
        }
      })
      setFilteredData(filtered)
      setValueExport(filtered)
    }
    filterData()
  }, [searchValues, historyTheKho])
  const columns = [
    {
      title: 'Ngày',
      dataIndex: 'ngay',
      key: 'ngay',
      ...configSearchTableAnt(setSearchValues, searchValues, 'ngay', historyTheKho, 90, true, {
        render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
      }),
    },
    {
      title: 'Phiếu',
      dataIndex: 'maPhieu',
      key: 'maPhieu',
      ...configSearchTableAnt(setSearchValues, searchValues, 'maPhieu', historyTheKho, 90, false, {
        render: (text, row) =>
          row.loai === 'Nhập Kho' || row.loai === 'Chuyển Kho' ? (
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => {
                if (row.loai === 'Nhập Kho') {
                  console.log(row)
                  dispatch(getInfoPTNhapByIdAction(row.idNhapXuat))
                  setIsModalOpen(true)
                } else {
                  dispatch(listInfoThuocVTChyenKho_Action(row.idNhapXuat))
                  setIsModalOpenXuat(true)
                }
                // dispatch(getInfoPTNhapByIdAction(record.idNhapXuat))
                // showModal()
              }}
            >
              {text}
            </span>
          ) : (
            text
          ),
      }),
    },
    {
      title: 'SL nhập',
      dataIndex: 'soLuongNhap',
      key: 'soLuongNhap',
      ...configSearchTableAnt(
        setSearchValues,
        searchValues,
        'soLuongNhap',
        historyTheKho,
        100,
        false,
        {
          align: 'center',
          render: (text) => formatNumberVND(text || 0),
        },
      ),
    },
    {
      title: 'SL xuất',
      dataIndex: 'soLuongXuat',
      key: 'soLuongXuat',
      ...configSearchTableAnt(
        setSearchValues,
        searchValues,
        'soLuongXuat',
        historyTheKho,
        100,
        false,
        {
          align: 'center',
          render: (text) => formatNumberVND(text || 0),
        },
      ),
    },
    {
      title: 'Số lô',
      dataIndex: 'soLo',
      key: 'soLo',
      align: 'center',
      ...configSearchTableAnt(setSearchValues, searchValues, 'soLo', historyTheKho, 100, false, {
        align: 'center',
      }),
    },
    {
      title: 'Hạn dùng',
      dataIndex: 'hanDung',
      key: 'hanDung',
      ...configSearchTableAnt(setSearchValues, searchValues, 'hanDung', historyTheKho, 100, true, {
        align: 'center',
        render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
      }),
    },
    {
      title: 'Số hoá đơn',
      dataIndex: 'soHoaDon',
      key: 'soHoaDon',
      ...configSearchTableAnt(setSearchValues, searchValues, 'soHoaDon', historyTheKho, 140, false),
    },
    {
      title: 'Loại',
      dataIndex: 'loai',
      key: 'loai',
      ...configSearchTableAnt(setSearchValues, searchValues, 'loai', historyTheKho, 80, false),
    },
    {
      title: 'Chi nhánh chuyển',
      dataIndex: 'tenChiNhanhXuat',
      key: 'tenChiNhanhXuat',
      ...configSearchTableAnt(
        setSearchValues,
        searchValues,
        'tenChiNhanhXuat',
        historyTheKho,
        180,
        false,
      ),
    },
    {
      title: 'kho chuyển',
      dataIndex: 'tenKhoXuat',
      key: 'tenKhoXuat',
      ...configSearchTableAnt(
        setSearchValues,
        searchValues,
        'tenKhoXuat',
        historyTheKho,
        130,
        false,
      ),
    },
    {
      title: 'Chi nhánh nhập',
      dataIndex: 'tenChiNhanhNhap',
      key: 'tenChiNhanhNhap',
      ...configSearchTableAnt(
        setSearchValues,
        searchValues,
        'tenChiNhanhNhap',
        historyTheKho,
        150,
        false,
      ),
    },
    {
      title: 'kho nhập',
      dataIndex: 'tenKhoNhap',
      key: 'tenKhoNhap',
      ...configSearchTableAnt(
        setSearchValues,
        searchValues,
        'tenKhoNhap',
        historyTheKho,
        120,
        false,
      ),
    },
    // {
    //   title: 'Số lượng',
    //   dataIndex: 'soLuong',
    //   key: 'soLuong',
    //   align: 'center',
    //   sorter: {
    //     compare: (a, b) => {
    //       return a.soLuong - b.soLuong
    //     },
    //   },
    //   sortDirections: ['descend', 'ascend'],
    //   onCell: () => ({
    //     style: {
    //       minWidth: 100,
    //     },
    //   }),
    // },
  ]

  return (
    <>
      <ConfigProvider
        theme={{
          token: { padding: 5, borderRadius: 0 },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          onChange={handleTableChange}
          locale={{
            triggerDesc: 'Sắp xếp giảm dần',
            triggerAsc: 'Sắp xếp tăng dần',
            cancelSort: 'Hủy sắp xếp',
            emptyText: <Empty description="Không tìm thấy dữ liệu" />,
          }}
          loading={isloading}
          bordered
          scroll={{ x: historyTheKho?.length > 0 ? 'max-content' : 1600, y: '62vh' }}
          pagination={false}
          columns={columns}
          dataSource={filteredData}
          summary={(pageData) => {
            let countSLNhap = 0
            let countSLXuat = 0

            pageData.forEach(({ soLuongNhap, soLuongXuat }) => {
              countSLNhap += soLuongNhap
              countSLXuat += soLuongXuat
            })

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center" className="text-red-500">
                    {countSLNhap}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>{countSLXuat}</Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                  <Table.Summary.Cell index={8}></Table.Summary.Cell>
                  <Table.Summary.Cell index={9}></Table.Summary.Cell>
                  <Table.Summary.Cell index={10}></Table.Summary.Cell>
                  <Table.Summary.Cell index={11}></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )
          }}
        />
      </ConfigProvider>
      {isModalOpen && (
        <ModalDetail
          handleCancel={handleCancel}
          show={show}
          setShow={setShow}
          isModalOpen={isModalOpen}
        />
      )}
      {isModalOpenXuat && (
        <ModalDetailXuat cancelModal={() => setIsModalOpenXuat(false)} open={isModalOpenXuat} />
      )}
    </>
  )
}

export default TableTheKho

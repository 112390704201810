import { call, delay, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { xetNghiemService } from '../../services/xetNghiem/xetNghiemService'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { CĐHAService } from '../../services/CĐHA/CĐHAService'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* CĐHASage() {
  yield takeLatest(typeAction.GET_LIST_ALL_CĐHA, function* ListAllCĐHA({ type }) {
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      const result = yield call(() => CĐHAService.getListAllCĐHA())
      yield put({
        type: typeAction.DISPATCH_LIST_ALL_CĐHA,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })
  //add new CĐHA
  yield takeLatest(typeAction.ADD_CĐHA, function* AddCĐHA({ type, form, handleReloadData }) {
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      yield call(() => CĐHAService.addCĐHA(form))
      yield call(() => handleReloadData())
      // yield put({ type: typeAction.SEARCH_CĐHA })
      Toast.fire({
        icon: 'success',
        title: 'Thêm chẩn đoán hình ảnh thành công',
      })
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'Thêm chẩn đoán hình ảnh thất bại',
      })
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })

  //edit CĐHA
  yield takeLatest(typeAction.EDIT_CĐHA, function* editCĐHA({ type, form, handleReloadData }) {
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      yield call(() => CĐHAService.editCĐHA(form))
      yield call(() => handleReloadData())
      // yield put({ type: typeAction.SEARCH_CĐHA })
      Toast.fire({
        icon: 'success',
        title: 'Sửa chẩn đoán hình ảnh thành công',
      })
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'Sửa chẩn đoán hình ảnh thất bại',
      })
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })

  //delete CĐHA
  yield takeLatest(typeAction.DELETE_CĐHA, function* deleteCĐHA({ type, id, handleReloadData }) {
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      yield call(() => CĐHAService.deleteCĐHA(id))
      yield call(() => handleReloadData())
      // yield put({ type: typeAction.SEARCH_CĐHA })
      Toast.fire({
        icon: 'success',
        title: 'Xóa chẩn đoán hình ảnh thành công',
      })
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'Xóa chẩn đoán hình ảnh thất bại',
      })
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })
  //search CĐHA
  yield takeLatest(
    typeAction.SEARCH_CĐHA,
    function* searchCĐHA({ type, keyword, idct, idnhomchha, idChuyenKhoa }) {
      try {
        yield put({ type: typeAction.OPEN_LOADING_PAGE })
        const result = yield call(() =>
          CĐHAService.searchCĐHA(keyword, idct, idnhomchha, idChuyenKhoa),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_ALL_CĐHA,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      } finally {
        yield put({ type: typeAction.CLOSE_LOADING_PAGE })
      }
    },
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_toolbarClassName__3LVBY {
  background-color: #f5f5f5;
}
.style_editorClassName__NypCg {
  min-height: 31rem;
  max-height: 31rem; /* Giới hạn chiều cao */
  overflow-y: auto;  /* Hiển thị thanh cuộn dọc khi vượt quá chiều cao */
}
.style_editorClassNameModal__Fwm05 {
  min-height: 20.8rem;
  max-height: 20.8rem; /* Giới hạn chiều cao */
  overflow-y: auto;  /* Hiển thị thanh cuộn dọc khi vượt quá chiều cao */
}`, "",{"version":3,"sources":["webpack://./src/components/layout/PhanAnh/style.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,iBAAiB;EACjB,iBAAiB,EAAE,uBAAuB;EAC1C,gBAAgB,GAAG,mDAAmD;AACxE;AACA;EACE,mBAAmB;EACnB,mBAAmB,EAAE,uBAAuB;EAC5C,gBAAgB,GAAG,mDAAmD;AACxE","sourcesContent":[".toolbarClassName {\n  background-color: #f5f5f5;\n}\n.editorClassName {\n  min-height: 31rem;\n  max-height: 31rem; /* Giới hạn chiều cao */\n  overflow-y: auto;  /* Hiển thị thanh cuộn dọc khi vượt quá chiều cao */\n}\n.editorClassNameModal {\n  min-height: 20.8rem;\n  max-height: 20.8rem; /* Giới hạn chiều cao */\n  overflow-y: auto;  /* Hiển thị thanh cuộn dọc khi vượt quá chiều cao */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarClassName": `style_toolbarClassName__3LVBY`,
	"editorClassName": `style_editorClassName__NypCg`,
	"editorClassNameModal": `style_editorClassNameModal__Fwm05`
};
export default ___CSS_LOADER_EXPORT___;

import {
  ConfigProvider,
  DatePicker,
  Table,
  Input,
  Space,
  TreeSelect,
  Select,
  Button,
  Tag,
} from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Highlighter from 'react-highlight-words'
import * as typeAction from '../../../../store/constants/constants'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import { SearchOutlined } from '@ant-design/icons'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { getBranchNotVP } from '../../../../store/actions/BranchAction'
import { reportService } from '../../../../services/Report/reportService'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const Danhsach = () => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { isLoadingTable } = useSelector((state) => state.modalReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [company, setCompany] = useState(id_company)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [ChuyenKhoa, setChuyenKhoa] = useState([])
  const searchInput = useRef(null)
  const [date, setDate] = useState({ from: now, to: now })
  const [branch, setBranch] = useState([])
  const [value, setValue] = useState([BranchLogin])
  const [Phieu, setPhieu] = useState([])
  const [lyDoFilters, setLyDoFilters] = useState([])
  const [valueExport, setValueExport] = useState([])
  const fetchReport = async () => {
    try {
      openLoading()
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT302',
      )
      let idChiNhanhs = null
      if (value?.length > 0 && value[0] !== 'all') {
        idChiNhanhs = value.map((id) => `&idcn=${id}`).join('&')
        const { data } = await reportService.getDanhSachBenhNhanKhongLayThuoc(
          company,
          idChuyenKhoa,
          idChiNhanhs,
          date.from,
          date.to,
        )
        setPhieu(data)
      } else {
        const { data } = await reportService.getDanhSachBenhNhanKhongLayThuoc(
          company,
          idChuyenKhoa,
          '',
          date.from,
          date.to,
        )
        setPhieu(data)
      }
      closeLoading()
    } catch (error) {
      closeLoading()
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      closeLoading()
    }
  }
  const fetLyDo = async () => {
    const { data } = await reportService.getAllLyDoLayThuoc()
    const uniqueLyDo = [...new Set(data.map((i) => i.lydo))] // gộp những lý do trùng nhau
    setLyDoFilters(uniqueLyDo.map((lydo) => ({ text: lydo, value: lydo })))
  }
  const handleDatePicker = (name) => (date, dateString) => {
    const formatDate = moment(dateString, dateFormat).format(momentDate)
    setDate((prevSate) => ({ ...prevSate, [name]: formatDate }))
  }
  const onChangeCompany = (value) => {
    setCompany(value)
    setValue([''])
    setIdChuyenKhoa('')
  }
  //-------------- lấy danh sách chuyên kho ------------//
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
      })),
    },
  ]
  const openLoading = () => {
    dispatch({
      type: typeAction.OPEN_LOADING_TABLE,
    })
  }
  const closeLoading = () => {
    dispatch({
      type: typeAction.CLOSE_LOADING_TABLE,
    })
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT298',
      )

      const formatData = valueExport?.map((item) => ({
        'Ngày khám': item?.ngaykham ? moment(item?.ngaykham).format('DD/MM/YYYY HH:mm:ss') : '-',
        'Tên chi nhánh': item?.tenchinhanh,
        'Dược sĩ': item?.duocsi,
        'Mã bệnh nhân': item?.mabenhnhan,
        'Tên bệnh nhân': item?.tenbenhnhan,
        'Lý do': item?.lydo,
        'Kiểu toa': item?.kieutoa === 1 ? 'Bác sĩ không kê toa' : 'Khách không lấy toa',
      }))
      const name = 'Danh sách khách hàng không lấy thuốc'
      formatData?.length && exportExcelformat(formatData, name)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  useEffect(() => {
    dispatch(getBranchNotVP(company, setBranch))
  }, [company])
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const recordValue = record[dataIndex]
      return recordValue
        ? recordValue.toString().toLowerCase().includes(value.toLowerCase())
        : false // Handle cases where the value is null or undefined
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const onChangeTable = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }
  //------------ lấy danh sách chi nhánh theo chuyên khoa ----------//
  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchReport()
    fetLyDo()
    fetchChuyenKhoa()
  }, [])
  useEffect(() => {
    setValueExport(Phieu)
  }, [Phieu])
  return (
    <>
      <div className="flex gap-3 border-dotted border-b-0  p-2 ">
        {infoUser?.idNhom !== 4 ? (
          <Select
            showSearch
            value={company}
            onChange={onChangeCompany}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className="w-80"
          />
        ) : (
          ''
        )}
        {infoUser?.idNhom !== 4 ? (
          <Select
            showSearch
            value={idChuyenKhoa}
            onChange={onChangeChuyenKhoa}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
              { label: 'Tất cả chuyên khoa', value: '' },
              ...ChuyenKhoa.map((items) => ({
                label: items.tenChuyenKhoa,
                value: items.idChuyenKhoa,
              })),
            ]}
            className="w-80"
          />
        ) : (
          ''
        )}
        {infoUser?.idNhom !== 4 ? (
          <div className="w-80">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
        ) : (
          ''
        )}

        <div className="flex items-center gap-3">
          <DatePicker
            allowClear={false}
            value={dayjs(date.from)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('from')}
            format={dateFormat}
          />
          <span className="font-semibold text-lg">-</span>
          <DatePicker
            allowClear={false}
            value={dayjs(date.to)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>

        <div className="flex gap-3">
          <IconButton onClick={fetchReport} color="primary" aria-label="delete" size="small">
            <SyncIcon />
          </IconButton>
        </div>
        <div className="flex gap-3 ml-auto">
          <Button
            disabled={!Phieu?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
            borderRadius: 0,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          onChange={onChangeTable}
          dataSource={Phieu}
          loading={isLoadingTable}
          bordered
          pagination={false}
          scroll={{
            x: Phieu?.length > 0 ? 'max-content' : 0,
            y: '65vh',
          }}
          columns={[
            {
              title: 'STT',
              dataIndex: 'stt',
              key: 'stt',
              align: 'center',
              onCell: () => ({
                style: {
                  minWidth: 40,
                },
              }),
            },
            {
              title: 'Ngày',
              dataIndex: 'ngaykham',
              key: 'ngaykham',
              onCell: () => ({
                style: {
                  minWidth: 40,
                },
              }),
              render: (text) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
            },
            {
              title: 'Chi nhánh',
              dataIndex: 'tenchinhanh',
              key: 'tenchinhanh',
              onCell: () => ({
                style: {
                  minWidth: 120,
                },
              }),
            },
            {
              title: 'Tên dược sĩ',
              dataIndex: 'duocsi',
              key: 'duocsi',
              onCell: () => ({
                style: {
                  minWidth: 120,
                },
              }),
              ...getColumnSearchProps('duocsi'),
            },
            {
              title: 'Tên bác sĩ',
              dataIndex: 'bskedon',
              key: 'bskedon',
              onCell: () => ({
                style: {
                  minWidth: 120,
                },
              }),
              ...getColumnSearchProps('bskedon'),
            },
            {
              title: 'Mã bệnh nhân',
              dataIndex: 'mabenhnhan',
              key: 'mabenhnhan',
              onCell: () => ({
                style: {
                  minWidth: 120,
                },
              }),
              ...getColumnSearchProps('mabenhnhan'),
            },
            {
              title: 'Tên bệnh nhân',
              dataIndex: 'tenbenhnhan',
              key: 'tenbenhnhan',
              onCell: () => ({
                style: {
                  minWidth: 120,
                },
              }),
              ...getColumnSearchProps('tenbenhnhan'),
            },
            {
              title: 'Lý do không lấy thuốc',
              dataIndex: 'lydo',
              key: 'lydo',
              onCell: () => ({
                style: {
                  minWidth: 150,
                },
              }),
              filters: lyDoFilters,
              onFilter: (value, record) => record.lydo === value,
              render: (text, record) => record?.noidungkolaythuoc ?? text,
            },
            {
              filters: [
                {
                  text: 'Bác sĩ không kê toa',
                  value: 1,
                },
                {
                  text: 'Khách không lấy toa',
                  value: 2,
                },
              ],
              onFilter: (value, record) => record.kieutoa === value,
              title: 'kiểu toa',
              dataIndex: 'kieutoa',
              key: 'kieutoa',
              onCell: () => ({
                style: {
                  minWidth: 60,
                },
              }),
              render: (text) => (
                <Tag bordered={false} color={text === 1 ? 'blue' : 'green'}>
                  {text === 1 ? 'Bác sĩ không kê toa' : 'Khách không lấy toa'}
                </Tag>
              ),
            },
          ]}
          summary={(pageData) => {
            const total = pageData?.length
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <p className="font-semibold"> Tổng phiếu: {total}</p>
                  </Table.Summary.Cell>{' '}
                  <Table.Summary.Cell colSpan={7}></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )
          }}
        />
      </ConfigProvider>
    </>
  )
}

export default Danhsach

import * as typeAction from '../constants/constants'

export const BNChoKhamTMTDAction = (idcnpk) => ({
    type: typeAction.GET_BN_CHO_KHAM_TMTD,
    idcnpk,
})
export const BNDangVaDaKhamTMTDAction = (idnv, idcn, tuNgay, denNgay) => ({
    type: typeAction.GET_BN_DANG_VA_DA_KHAM_TMTD,
    idnv,
    idcn,
    tuNgay,
    denNgay
})
import { Formik } from 'formik'
import React, { memo, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import * as typeAction from '../../../store/constants/constants'
import { useTranslation } from 'react-i18next'
import { loginSchema } from '../../../schemas/loginSchema'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from '../../../store/actions/userAction'
import { useNavigate } from 'react-router-dom'
import { listBranchAction } from '../../../store/actions/BranchAction'
import Autocomplete from '@mui/material/Autocomplete'
import ModalCheckIPMac from '../../../components/common/ModalCheckIPMac/ModalCheckIPMac'
import { authService } from '../../../services/auth/authService'
import { notification } from 'antd'

const FormLogin = memo(() => {
  const [showPassword, setShowPassword] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const { listBranch } = useSelector((state) => state.branchReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const [value, setValue] = useState(null)
  const [isCheckIP, setIsCheckIP] = useState(false)
  const [code, setCode] = useState(null)
  const { t } = useTranslation('translation')
  const [IDCompany, setIDCompany] = useState(ID_COMPANY || '')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const idBranchRegitry = ID_BRANCH
  const IS_DEV = process.env.REACT_APP_ENVIRONMENT_DEV === 'dev'
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    })
  }
  const handleLogin = async (values, action) => {
    dispatch({
      type: typeAction.OPEN_LOADING,
    })
    if (IS_DEV) {
      try {
        const { data } = await authService.getIPMac() // check ipmac
        //set mac address
        dispatch({
          type: typeAction.DISPATCH_LOGIN_INFO,
          payload: data,
        })
        values.ipmac = data.macAddresses[0]
        dispatch(loginUser(values, IDCompany, navigate, action))
      } catch (err) {
        dispatch({
          type: typeAction.CLOSE_LOADING,
        })
        console.log(err)
        openNotificationWithIcon(
          'error',
          'Lỗi Đăng nhập',
          'Bạn chưa mở phần mềm hổ trợ đăng nhập, vui lòng hãy mở phần mềm',
        )
      }
      // action.resetForm()
      // setValue(null)
      return
    }
    dispatch(loginUser(values, IDCompany, navigate, action))
    dispatch({
      type: typeAction.DISPATCH_USERNAME,
      payload: null,
    })
    dispatch({
      type: typeAction.DISPATCH_DEFAULT_BRANCH,
      payload: null,
    })
  }
  const onChangeCompany = (e, props) => {
    const { value } = e.target
    setIDCompany(value)
    setValue(null)
    props.setFieldValue('idChiNhanh', null)
    dispatch(listBranchAction(value))
  }
  const getIPMac = async () => {
    try {
      const { data } = await authService.getIPMac()
      setCode(data)
    } catch (err) {
      console.log(err)
    }
  }
  // xử lý selelect chọn phòng khám
  const handleSelect = (newValue, props) => {
    setValue(newValue)
    newValue === null
      ? props.setFieldValue('idChiNhanh', null)
      : props.setFieldValue('idChiNhanh', newValue.value) // setFieldValue maPhongKham vào >>>> form formik
  }
  const handleChangeUsername = async (e, props) => {
    const value = e.target.value
    props.setFieldValue('username', value)
  }
  // show password
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  useEffect(() => {
    if (listBranch) {
      const initialBranch = listBranch?.find((branch) => branch.idChiNhanh === ID_BRANCH) || null
      initialBranch &&
        setValue({ label: initialBranch.tenChiNhanh, value: initialBranch.idChiNhanh })
    }
  }, [listBranch])
  useEffect(() => {
    getIPMac()
  }, [])
  return (
    <>
      {contextHolder}
      <Formik
        enableReinitialize={true}
        initialValues={{
          username: '',
          password: '',
          idChiNhanh: ID_BRANCH || null,
        }}
        onSubmit={handleLogin}
        validationSchema={loginSchema}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            {/* các field của form */}
            <Box
              className="flex flex-col gap-4"
              sx={{
                width: '100%',
              }}
            >
              <FormControl className="w-full" variant="outlined">
                <TextField
                  id="outlined-multiline-flexible"
                  label={t('Tên tài khoản')}
                  className="w-full "
                  size="small"
                  value={props.values.username}
                  onBlur={props.handleBlur}
                  onChange={(e) => {
                    handleChangeUsername(e, props)
                  }}
                  name="username"
                  maxRows={4}
                  autoComplete="username" // Add this line
                />
                {props.touched.username && props.errors.username && (
                  <span className="text-left text-red-500">* {t(props.errors.username)}</span>
                )}
              </FormControl>

              <FormControl className="w-full" size="small" variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">{t('Mật khẩu')}</InputLabel>
                <OutlinedInput
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.password}
                  name="password"
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  autoComplete="current-password" // Add this line
                />
                {props.touched.password && props.errors.password && (
                  <span className="text-left text-red-500">* {t(props.errors.password)}</span>
                )}
              </FormControl>
              <FormControl sx={{ width: '100%' }} size="small">
                <InputLabel id="demo-select-small-label">{t('Chọn công ty')}</InputLabel>
                <Select
                  className="text-start"
                  labelId="demo-select-small-label"
                  value={IDCompany || ''}
                  onChange={(e) => onChangeCompany(e, props)}
                  id="demo-select-small"
                  label={t('Chọn công ty')}
                  MenuProps={{ style: { maxHeight: 300 } }}
                >
                  {listCompany?.map((items) => (
                    <MenuItem key={items.idct} value={items.idct}>
                      {items.tenct}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: '100%' }} size="small">
                <Autocomplete
                  value={value}
                  size="small"
                  disablePortal
                  onChange={(e, newValue) => handleSelect(newValue, props)}
                  id="combo-box-demo"
                  options={
                    listBranch?.map((items) => ({
                      label: items.tenChiNhanh,
                      value: items.idChiNhanh,
                    })) || []
                  } // Provide a default empty array
                  renderInput={(params) => <TextField {...params} label={t('Chọn phòng khám')} />}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                />
                {props.touched.idChiNhanh && props.errors.idChiNhanh && (
                  <span className="text-left text-red-500">* {t(props.errors.idChiNhanh)}</span>
                )}
              </FormControl>
              <p className="text-start">
                Bạn chưa đăng ký sử dụng?{' '}
                <a onClick={() => setIsCheckIP(true)} className="text-blue-500" href="#">
                  Đăng ký
                </a>
              </p>
              <Box>
                <Button type="submit" className="w-full" variant="contained">
                  {t('Đăng nhập')}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
      <ModalCheckIPMac
        isCheckIP={isCheckIP}
        setIsCheckIP={setIsCheckIP}
        code={code}
        getIPMac={getIPMac}
        idBranchRegitry={idBranchRegitry}
      />
    </>
  )
})

export default FormLogin

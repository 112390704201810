import JsBarcode from 'jsbarcode'
import moment from 'moment'
import { generateLogo } from '../../algorithm'
import { jsPDFCus } from '../../jsPdfAddFont'
import { splitLongWord } from '../../splitWordForReport'
import 'moment/locale/vi'
moment.locale('vi')

export const generatePhieuKhamKhucXa = (infoBN, ttKham, PkDangNhap) => {
  const doc = jsPDFCus('p', 'mm', 'a4')
  let yPos = 10
  let xPos = 8
  const pageWidth = doc.internal.pageSize.width
  const pageHeight = doc.internal.pageSize.height
  //header
  generateLogo(doc, xPos, PkDangNhap)

  //barcode
  JsBarcode('#barcode', `123123123123`, {
    displayValue: false,
  })

  const canvas = document.getElementById('barcode')
  // Convert canvas to an image
  const barcodeDataURL = canvas.toDataURL()
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 50, 5, 40, 15) // Adjust position and size as needed
  doc.setFont('times', 'bold')
  doc.setFontSize(16)
  doc.text('PHIẾU KHÁM KHÚC XẠ', doc.internal.pageSize.width / 2, 30, {
    align: 'center',
  })
  yPos = 35
  doc.setFontSize(12)
  doc.setFont('times', 'regular')
  doc.text('Họ và tên', xPos, yPos)
  doc.text('Năm sinh', pageWidth - 60, yPos)
  doc.setFont('times', 'italic')
  doc.text('Name: ', doc.getTextDimensions('Họ và tên ').w + xPos, yPos)
  doc.text('D.O.B: ', pageWidth - 42, yPos)
  doc.setFont('times', 'bold')
  doc.text(
    `${infoBN.benhNhan.tenBenhNhan}`,
    doc.getTextDimensions('Họ và tên Name: ').w + xPos,
    yPos,
  )
  doc.text(`${moment(infoBN.benhNhan.ngaySinh).format('YYYY')}`, pageWidth - 28, yPos)
  yPos += 5
  doc.text('THỊ LỰC (VISUAL ACUITY)', xPos + 10, yPos)
  doc.text('NHÃN ÁP (TONOMETRY)', pageWidth - 72, yPos)
  yPos -= 4
  //table thi luc
  doc.line(xPos, yPos + 6, 120, yPos + 6)
  doc.line(xPos, yPos + 12, 120, yPos + 12)
  //text header
  doc.setFontSize(11)
  doc.setFont('times', 'regular')
  doc.text('MP (OD)', 62, yPos + 10)
  doc.text('MT (OS)', 82, yPos + 10)
  doc.text('2M (OU)', 102, yPos + 10)

  doc.text('Không kính', xPos + 5, yPos + 18)
  doc.setFont('times', 'italic')
  doc.text('without Rx', xPos + 5, yPos + 22)
  doc.setFont('times', 'regular')
  doc.text('Kính lỗ PH', xPos + 5, yPos + 28)
  doc.text('Kính cũ (CC)', xPos + 5, yPos + 36)

  //data
  //khong kinh xa
  doc.text(`${ttKham.thiluckhongkinhxA_MP ?? ''}`, 70, yPos + 17, {
    align: 'center',
  })
  doc.text(`${ttKham.thiluckhongkinhxA_MT ?? ''}`, 90, yPos + 17, {
    align: 'center',
  })
  doc.text(`${ttKham.thiluckhongkinhxA_2M ?? ''}`, 110, yPos + 17, {
    align: 'center',
  })
  //khong kinh gan
  doc.text(`${ttKham.thiluckhongkinhgaN_MP ?? ''}`, 70, yPos + 23, {
    align: 'center',
  })
  doc.text(`${ttKham.thiluckhongkinhgaN_MT ?? ''}`, 90, yPos + 23, {
    align: 'center',
  })
  doc.text(`${ttKham.thiluckhongkinhgaN_2M ?? ''}`, 110, yPos + 23, {
    align: 'center',
  })
  //kinh lo ph xa
  doc.text(`${ttKham.thiluckinhloxA_MP ?? ''}`, 70, yPos + 29, {
    align: 'center',
  })
  doc.text(`${ttKham.thiluckinhloxA_MT ?? ''}`, 90, yPos + 29, {
    align: 'center',
  })
  doc.text(`${ttKham.thiluckinhloxA_2M ?? ''}`, 110, yPos + 29, {
    align: 'center',
  })
  //kinh cu xa
  doc.text(`${ttKham.thiluckinhcuxA_MP ?? ''}`, 70, yPos + 35, {
    align: 'center',
  })
  doc.text(`${ttKham.thiluckinhcuxA_MT ?? ''}`, 90, yPos + 35, {
    align: 'center',
  })
  doc.text(`${ttKham.thiluckinhcuxA_2M ?? ''}`, 110, yPos + 35, {
    align: 'center',
  })
  //kinh cu gan
  doc.text(`${ttKham.thiluckinhcugaN_MP ?? ''}`, 70, yPos + 41, {
    align: 'center',
  })
  doc.text(`${ttKham.thiluckinhcugaN_MT ?? ''}`, 90, yPos + 41, {
    align: 'center',
  })
  doc.text(`${ttKham.thiluckinhcugaN_2M ?? ''}`, 110, yPos + 41, {
    align: 'center',
  })

  doc.line(xPos + 32, yPos + 18, 120, yPos + 18)
  doc.text('Xa', xPos + 35, yPos + 17)
  doc.setFont('times', 'italic')
  doc.text('Dist', xPos + 36 + doc.getTextDimensions('Xa').w, yPos + 17)

  doc.line(xPos, yPos + 24, 120, yPos + 24)
  doc.setFont('times', 'regular')
  doc.text('Gần', xPos + 35, yPos + 23)
  doc.setFont('times', 'italic')
  doc.text('Near', xPos + 36 + doc.getTextDimensions('Gần').w, yPos + 23)

  doc.line(xPos, yPos + 30, 120, yPos + 30)
  doc.setFont('times', 'regular')
  doc.text('Xa', xPos + 35, yPos + 29)
  doc.setFont('times', 'italic')
  doc.text('Dist', xPos + 36 + doc.getTextDimensions('Xa').w, yPos + 29)

  doc.line(xPos + 32, yPos + 36, 120, yPos + 36)
  doc.setFont('times', 'regular')
  doc.text('Xa', xPos + 35, yPos + 35)
  doc.setFont('times', 'italic')
  doc.text('Dist', xPos + 36 + doc.getTextDimensions('Xa').w, yPos + 35)

  doc.line(xPos, yPos + 42, 120, yPos + 42)
  doc.setFont('times', 'regular')
  doc.text('Gần', xPos + 35, yPos + 41)
  doc.setFont('times', 'italic')
  doc.text('Near', xPos + 36 + doc.getTextDimensions('Gần').w, yPos + 41)

  doc.line(xPos, yPos + 6, xPos, yPos + 42)
  doc.line(40, yPos + 12, 40, yPos + 42)

  doc.line(60, yPos + 6, 60, yPos + 42)
  doc.line(80, yPos + 6, 80, yPos + 42)
  doc.line(100, yPos + 6, 100, yPos + 42)
  doc.line(120, yPos + 6, 120, yPos + 42)

  //table nhan ap
  doc.setFont('times', 'regular')
  doc.text('MP (OD)', pageWidth - 70, yPos + 10)
  doc.text('MT (OS)', pageWidth - 40, yPos + 10)
  doc.line(xPos + 187, yPos + 6, pageWidth - 75, yPos + 6)
  doc.line(xPos + 187, yPos + 12, pageWidth - 75, yPos + 12)
  doc.line(xPos + 187, yPos + 18, pageWidth - 75, yPos + 18)

  doc.line(pageWidth - 75, yPos + 6, pageWidth - 75, yPos + 18)
  doc.line(pageWidth - 45, yPos + 6, pageWidth - 45, yPos + 18)
  doc.text(`${ttKham.nhanaP_MP ?? ''}`, (pageWidth - 75 + pageWidth - 45) / 2, yPos + 17, {
    align: 'center',
  })
  doc.text(`${ttKham.nhanaP_MT ?? ''}`, (pageWidth - 44 + pageWidth - 15) / 2, yPos + 17, {
    align: 'center',
  })
  doc.line(pageWidth - 15, yPos + 6, pageWidth - 15, yPos + 18)
  yPos = yPos + 46

  //table kinh cu
  doc.setFont('times', 'bold')
  doc.text('KÍNH CŨ (CURRENT GLASSES)', xPos + 10, yPos)
  yPos += 2

  //line x
  doc.line(xPos, yPos, xPos, yPos + 18)
  doc.line(xPos + 40, yPos, xPos + 40, yPos + 18)
  doc.line(xPos + 70, yPos, xPos + 70, yPos + 18)
  doc.line(xPos + 100, yPos, xPos + 100, yPos + 18)
  doc.line(xPos + 130, yPos, xPos + 130, yPos + 18)
  doc.line(xPos + 160, yPos, xPos + 160, yPos + 18)
  doc.line(xPos + 192, yPos, xPos + 192, yPos + 18)

  doc.line(xPos, yPos, pageWidth - 10, yPos)
  doc.line(xPos, yPos + 6, pageWidth - 10, yPos + 6)
  doc.line(xPos, yPos + 12, pageWidth - 42, yPos + 12)
  doc.line(xPos, yPos + 18, pageWidth - 10, yPos + 18)
  //text
  doc.setFont('times', 'regular')
  doc.text('MP (OD)', xPos + 5, yPos + 10)
  doc.text('MT (OS)', xPos + 5, yPos + 16)
  doc.text('Cầu (Sph)', xPos + 45, yPos + 5)
  doc.text(`${ttKham.kinhcU_MP_CAU ?? ''}`, xPos + 55, yPos + 11, {
    align: 'center',
  })
  doc.text(`${ttKham.kinhcU_MT_CAU ?? ''}`, xPos + 55, yPos + 17, {
    align: 'center',
  })

  doc.text('Loạn (Cyl)', xPos + 78, yPos + 5)
  doc.text(`${ttKham.kinhcU_MP_LOAN ?? ''}`, xPos + 85, yPos + 11, {
    align: 'center',
  })
  doc.text(`${ttKham.kinhcU_MT_LOAN ?? ''}`, xPos + 85, yPos + 17, {
    align: 'center',
  })

  doc.text('Trục (Axis)', xPos + 107, yPos + 5)
  doc.text(`${ttKham.kinhcU_MP_TRUC ?? ''}`, xPos + 115, yPos + 11, {
    align: 'center',
  })
  doc.text(`${ttKham.kinhcU_MT_TRUC ?? ''}`, xPos + 115, yPos + 17, {
    align: 'center',
  })

  doc.text('ADD', xPos + 139, yPos + 5)
  doc.text(`${ttKham.kinhcU_MP_ADD ?? ''}`, xPos + 145, yPos + 11, {
    align: 'center',
  })
  doc.text(`${ttKham.kinhcU_MT_ADD ?? ''}`, xPos + 145, yPos + 17, {
    align: 'center',
  })

  doc.text('PD', xPos + 170, yPos + 5)
  doc.text(`${ttKham.kinhcU_PD ?? ''}`, xPos + 176, yPos + 15, {
    align: 'center',
  })

  //table do khuc xa
  yPos = yPos + 22
  doc.setFont('times', 'bold')
  doc.text('ĐO KHÚC XẠ (REFRACTION)', xPos + 10, yPos)
  yPos += 2
  doc.line(xPos, yPos, pageWidth - 10, yPos)
  doc.line(xPos, yPos + 6, pageWidth - 10, yPos + 6)
  doc.line(xPos, yPos + 12, pageWidth - 10, yPos + 12)
  doc.line(xPos, yPos + 18, pageWidth - 10, yPos + 18)
  doc.line(xPos, yPos + 24, pageWidth - 10, yPos + 24)
  doc.line(xPos, yPos, xPos, yPos + 24)
  doc.line(xPos + 110, yPos, xPos + 110, yPos + 24)
  doc.line(xPos + 150, yPos, xPos + 150, yPos + 24)
  doc.line(xPos + 192, yPos, xPos + 192, yPos + 24)
  //text
  doc.setFont('times', 'regular')
  doc.text('MP (OD)', xPos + 120, yPos + 4)
  doc.text('MT (OS)', pageWidth - 40, yPos + 4)

  doc.text('Khách quan không liệt ĐT', xPos + 3, yPos + 10)
  doc.text('Khách quan liệt ĐT', xPos + 3, yPos + 16)
  doc.text('Chủ quan', xPos + 3, yPos + 22)

  doc.setFont('times', 'italic')
  doc.text(
    ' (Objective w/o Cycloplegic)',
    xPos + 3 + doc.getTextDimensions('Khách quan không liệt ĐT').w,
    yPos + 10,
  )
  doc.setFont('times', 'regular')
  doc.text(`${ttKham.khachquankhonglietdT_MP ?? ''}`, xPos + 130, yPos + 10, {
    align: 'center',
  })
  doc.text(`${ttKham.khachquankhonglietdT_MT ?? ''}`, xPos + 171, yPos + 10, {
    align: 'center',
  })

  doc.setFont('times', 'italic')
  doc.text(
    ' (Objective w Cycloplegic)',
    xPos + 3 + doc.getTextDimensions('Khách quan liệt ĐT').w,
    yPos + 16,
  )
  doc.setFont('times', 'regular')
  doc.text(`${ttKham.khachquanlietdT_MP ?? ''}`, xPos + 130, yPos + 16, {
    align: 'center',
  })
  doc.text(`${ttKham.khachquanlietdT_MT ?? ''}`, xPos + 171, yPos + 16, {
    align: 'center',
  })

  doc.setFont('times', 'italic')
  doc.text(' (Subjective)', xPos + 3 + doc.getTextDimensions('Chủ quan').w, yPos + 22)
  doc.setFont('times', 'regular')
  doc.text(`${ttKham.chuquaN_MP ?? ''}`, xPos + 130, yPos + 22, {
    align: 'center',
  })
  doc.text(`${ttKham.chuquaN_MT ?? ''}`, xPos + 171, yPos + 22, {
    align: 'center',
  })
  //table kinh dieu chinh
  yPos = yPos + 28
  doc.setFont('times', 'bold')
  doc.text('KÍNH ĐIỀU CHỈNH (SPECTACLE PRESCRIPTION)', xPos + 10, yPos)
  yPos += 2
  doc.line(xPos, yPos, xPos, yPos + 18)
  doc.line(xPos + 20, yPos, xPos + 20, yPos + 18)
  doc.line(xPos + 50, yPos, xPos + 50, yPos + 18)
  doc.line(xPos + 80, yPos, xPos + 80, yPos + 18)
  doc.line(xPos + 110, yPos, xPos + 110, yPos + 18)
  doc.line(xPos + 140, yPos, xPos + 140, yPos + 18)
  doc.line(xPos + 168, yPos, xPos + 168, yPos + 18)
  doc.line(xPos + 192, yPos, xPos + 192, yPos + 18)

  doc.line(xPos, yPos, pageWidth - 10, yPos)
  doc.line(xPos, yPos + 6, pageWidth - 10, yPos + 6)
  doc.line(xPos, yPos + 12, pageWidth - 34, yPos + 12)
  doc.line(xPos, yPos + 18, pageWidth - 10, yPos + 18)
  //text
  doc.setFont('times', 'regular')
  doc.text('MP (OD)', xPos + 2, yPos + 10)
  doc.text('MT (OS)', xPos + 2, yPos + 16)
  doc.text('Cầu (Sph)', xPos + 25, yPos + 5)
  doc.text(`${ttKham.kinhdieuchinH_MP_CAU ?? ''}`, xPos + 35, yPos + 11, {
    align: 'center',
  })
  doc.text(`${ttKham.kinhdieuchinH_MT_CAU ?? ''}`, xPos + 35, yPos + 17, {
    align: 'center',
  })

  doc.text('Loạn (Cyl)', xPos + 58, yPos + 5)
  doc.text(`${ttKham.kinhdieuchinH_MP_LOAN ?? ''}`, xPos + 65, yPos + 11, {
    align: 'center',
  })
  doc.text(`${ttKham.kinhdieuchinH_MT_LOAN ?? ''}`, xPos + 65, yPos + 17, {
    align: 'center',
  })

  doc.text('Trục (Axis)', xPos + 87, yPos + 5)
  doc.text(`${ttKham.kinhdieuchinH_MP_TRUC ?? ''}`, xPos + 95, yPos + 11, {
    align: 'center',
  })
  doc.text(`${ttKham.kinhdieuchinH_MT_TRUC ?? ''}`, xPos + 95, yPos + 17, {
    align: 'center',
  })

  doc.text('TL (VA)', xPos + 117, yPos + 5)
  doc.text(`${ttKham.kinhdieuchinH_MP_TL ?? ''}`, xPos + 125, yPos + 11, {
    align: 'center',
  })
  doc.text(`${ttKham.kinhdieuchinH_MT_TL ?? ''}`, xPos + 125, yPos + 17, {
    align: 'center',
  })

  doc.text('ADD', xPos + 144, yPos + 5)
  doc.text(`${ttKham.kinhdieuchinH_MP_ADD ?? ''}`, xPos + 154, yPos + 11, {
    align: 'center',
  })
  doc.text(`${ttKham.kinhdieuchinH_MT_ADD ?? ''}`, xPos + 154, yPos + 17, {
    align: 'center',
  })

  doc.text('PD', xPos + 174, yPos + 5)
  doc.text(`${ttKham.kinhdieuchinH_PD ?? ''}`, xPos + 180, yPos + 15, {
    align: 'center',
  })

  //table kinh tiep xuc
  yPos = yPos + 22
  doc.setFont('times', 'bold')
  doc.text('KÍNH TIẾP XÚC (CONTACT LENS PRESCRIPTION)', xPos + 10, yPos)
  yPos += 2
  doc.line(xPos, yPos, xPos, yPos + 18)
  doc.line(xPos + 30, yPos, xPos + 30, yPos + 18)
  doc.line(xPos + 70, yPos, xPos + 70, yPos + 18)
  doc.line(xPos + 110, yPos, xPos + 110, yPos + 18)
  doc.line(xPos + 150, yPos, xPos + 150, yPos + 18)
  doc.line(xPos + 192, yPos, xPos + 192, yPos + 18)

  doc.line(xPos, yPos, pageWidth - 10, yPos)
  doc.line(xPos, yPos + 6, pageWidth - 10, yPos + 6)
  doc.line(xPos, yPos + 12, pageWidth - 10, yPos + 12)
  doc.line(xPos, yPos + 18, pageWidth - 10, yPos + 18)
  //text
  doc.setFont('times', 'regular')
  doc.text('MP (OD)', xPos + 5, yPos + 10)
  doc.text('MT (OS)', xPos + 5, yPos + 16)
  doc.text('Công suất (Power)', xPos + 35, yPos + 5)
  doc.text(`${ttKham.kinhtiepxuC_MP_CONGSUAT ?? ''}`, xPos + 50, yPos + 11, {
    align: 'center',
  })
  doc.text(`${ttKham.kinhtiepxuC_MT_CONGSUAT ?? ''}`, xPos + 50, yPos + 17, {
    align: 'center',
  })

  doc.text('BC', xPos + 85, yPos + 5)
  doc.text(`${ttKham.kinhtiepxuC_MP_BC ?? ''}`, xPos + 90, yPos + 11, {
    align: 'center',
  })
  doc.text(`${ttKham.kinhtiepxuC_MT_BC ?? ''}`, xPos + 90, yPos + 17, {
    align: 'center',
  })

  doc.text('DIA', xPos + 124, yPos + 5)
  doc.text(`${ttKham.kinhtiepxuC_MP_DIA ?? ''}`, xPos + 130, yPos + 11, {
    align: 'center',
  })
  doc.text(`${ttKham.kinhtiepxuC_MT_DIA ?? ''}`, xPos + 130, yPos + 17, {
    align: 'center',
  })

  doc.text('Hiệu (Brand)', xPos + 158, yPos + 5)
  doc.text(`${ttKham.kinhtiepxuC_MP_HIEUBRAND ?? ''}`, xPos + 171, yPos + 11, {
    align: 'center',
  })
  doc.text(`${ttKham.kinhtiepxuC_MT_HIEUBRAND ?? ''}`, xPos + 171, yPos + 17, {
    align: 'center',
  })

  yPos = yPos + 22
  doc.setFont('times', 'bold')
  doc.text('KHÁM TIỀN CHẨN (PRE TEST)', xPos + 10, yPos)
  yPos += 2
  doc.line(xPos, yPos, pageWidth - 10, yPos)
  doc.line(xPos, yPos + 6, pageWidth - 10, yPos + 6)
  doc.line(xPos, yPos + 12, pageWidth - 10, yPos + 12)
  doc.line(xPos, yPos + 18, pageWidth - 10, yPos + 18)
  doc.line(xPos, yPos + 24, pageWidth - 10, yPos + 24)
  doc.line(xPos, yPos + 30, pageWidth - 10, yPos + 30)
  doc.line(xPos + 45, yPos + 36, pageWidth - 10, yPos + 36)
  doc.line(xPos, yPos + 42, pageWidth - 10, yPos + 42)
  doc.line(xPos, yPos + 48, pageWidth - 10, yPos + 48)
  doc.line(xPos, yPos + 54, pageWidth - 10, yPos + 54)
  doc.line(xPos, yPos + 60, pageWidth - 10, yPos + 60)

  doc.line(xPos, yPos, xPos, yPos + 60)
  doc.line(xPos + 45, yPos + 42, xPos + 45, yPos + 30)
  doc.line(xPos + 70, yPos, xPos + 70, yPos + 48)
  doc.line(xPos + 110, yPos, xPos + 110, yPos + 48)
  doc.line(xPos + 150, yPos, xPos + 150, yPos + 60)
  doc.line(xPos + 192, yPos, xPos + 192, yPos + 60)

  doc.setFont('times', 'regular')
  doc.text('Sắc giác ', xPos + 2, yPos + 10)
  doc.setFont('times', 'italic')
  doc.text('Color', doc.getTextDimensions('Sắc giác ').w + xPos + 2, yPos + 10)

  doc.setFont('times', 'regular')
  doc.text('Đồng tử ', xPos + 2, yPos + 16)
  doc.setFont('times', 'italic')
  doc.text('Pupil', doc.getTextDimensions('Đồng tử ').w + xPos + 2, yPos + 16)

  doc.setFont('times', 'regular')
  doc.text('Vận nhãn ', xPos + 2, yPos + 22)
  doc.setFont('times', 'italic')
  doc.text('EOM', doc.getTextDimensions('Vận nhãn ').w + xPos + 2, yPos + 22)

  doc.setFont('times', 'regular')
  doc.text('Thị trường ', xPos + 2, yPos + 28)
  doc.setFont('times', 'italic')
  doc.text('Visual field', doc.getTextDimensions('Thị trường ').w + xPos + 2, yPos + 28)

  doc.setFont('times', 'regular')
  doc.text('Test che mắt ', xPos + 2, yPos + 35)
  doc.setFont('times', 'italic')
  doc.text('Cover test', doc.getTextDimensions('Test che mắt ').w + xPos + 2, yPos + 35)

  doc.setFont('times', 'regular')
  doc.text('Xa ', xPos + 47, yPos + 34)
  doc.setFont('times', 'italic')
  doc.text('Dist', doc.getTextDimensions('Xa ').w + xPos + 47, yPos + 34)
  doc.setFont('times', 'regular')
  doc.text('Gần ', xPos + 47, yPos + 40)
  doc.setFont('times', 'italic')
  doc.text('Near', doc.getTextDimensions('Gần ').w + xPos + 47, yPos + 40)

  doc.setFont('times', 'regular')
  doc.text('Biên độ điều tiết AA', xPos + 2, yPos + 46)
  doc.setFont('times', 'regular')
  doc.text('Cận điểm quy tụ NPC ', xPos + 2, yPos + 52)
  doc.setFont('times', 'regular')
  doc.text('Thị giác lập thể Stereo', xPos + 2, yPos + 58)

  doc.text('MP (OD)', xPos + 85, yPos + 5)
  doc.text(`${ttKham.khamtienchaN_MP_SACGIAC ?? ''}`, xPos + 90, yPos + 11, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_MP_DONGTU ?? ''}`, xPos + 90, yPos + 17, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_MP_VANNHAN ?? ''}`, xPos + 90, yPos + 23, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_MP_THITRUONG ?? ''}`, xPos + 90, yPos + 29, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_MP_TESTCHEMAT_XA ?? ''}`, xPos + 90, yPos + 35, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_MP_TESTCHEMAT_GAN ?? ''}`, xPos + 90, yPos + 41, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_MP_BODIEUTIETAA ?? ''}`, xPos + 90, yPos + 47, {
    align: 'center',
  })

  doc.text('MT (OS)', xPos + 124, yPos + 5)
  doc.text(`${ttKham.khamtienchaN_MT_SACGIAC ?? ''}`, xPos + 130, yPos + 11, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_MT_DONGTU ?? ''}`, xPos + 130, yPos + 17, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_MT_VANNHAN ?? ''}`, xPos + 130, yPos + 23, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_MT_THITRUONG ?? ''}`, xPos + 130, yPos + 29, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_MT_TESTCHEMAT_XA ?? ''}`, xPos + 130, yPos + 35, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_MT_TESTCHEMAT_GAN ?? ''}`, xPos + 130, yPos + 41, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_MT_BODIEUTIETAA ?? ''}`, xPos + 130, yPos + 47, {
    align: 'center',
  })

  doc.text('2M(OU)', xPos + 158, yPos + 5)
  doc.text(`${ttKham.khamtienchaN_2M_SACGIAC ?? ''}`, xPos + 171, yPos + 11, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_2M_DONGTU ?? ''}`, xPos + 171, yPos + 17, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_2M_VANNHAN ?? ''}`, xPos + 171, yPos + 23, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_2M_THITRUONG ?? ''}`, xPos + 171, yPos + 29, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_2M_TESTCHEMAT_XA ?? ''}`, xPos + 171, yPos + 35, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_2M_TESTCHEMAT_GAN ?? ''}`, xPos + 171, yPos + 41, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_2M_BODIEUTIETAA ?? ''}`, xPos + 171, yPos + 47, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_CANDIEMQUYTUNPC ?? ''}`, xPos + 171, yPos + 53, {
    align: 'center',
  })
  doc.text(`${ttKham.khamtienchaN_THIGIACLAPTHESTEREO ?? ''}`, xPos + 171, yPos + 59, {
    align: 'center',
  })
  yPos = yPos + 65

  doc.text('CHẨN ĐOÁN ', xPos, yPos)
  yPos += 10
  const formattedDateTime = `Ngày ${moment(new Date()).format('DD')} tháng ${moment(new Date()).format('MM')} năm ${moment(new Date()).format('YYYY')}`
  doc.text(pageWidth - 15 - formattedDateTime.length * 2, yPos, formattedDateTime)
  yPos += 5
  doc.text(
    pageWidth - 11 - 'Chữ ký chuyên viên Khúc xạ NK'.length * 2,
    yPos,
    'Chữ ký chuyên viên Khúc xạ NK',
  )
  doc.setFont('times', 'bold')
  doc.text(pageWidth - 25 - `${ttKham.nguoikham}`.length * 2, yPos + 20, `${ttKham.nguoikham}`)
  yPos -= 15
  doc.setFont('times', 'italic')
  doc.text(' Assessment:', xPos + doc.getTextDimensions('CHẨN ĐOÁN ').w, yPos)
  doc.setFont('times', 'regular')
  const chandoan = `${infoBN.chandoan ?? ''} `
  //chẩn đoán
  if (chandoan.length > 60) {
    const wordsArray = splitLongWord(chandoan, 60)
    doc.text(wordsArray[0], xPos + doc.getTextDimensions('CHẨN ĐOÁN Assessment: ').w, yPos)
    doc.text(wordsArray[1], xPos, yPos + 5)
    yPos += 5
  } else {
    doc.text(`${chandoan ?? ''}`, xPos + doc.getTextDimensions('CHẨN ĐOÁN Assessment: ').w, yPos)
    yPos += 5
  }
  yPos += 5
  doc.text('LƯU Ý ', xPos, yPos)
  doc.setFont('times', 'italic')
  doc.text(' Remarks:', xPos + doc.getTextDimensions('LƯU Ý ').w, yPos)
  doc.setFont('times', 'regular')
  const luuy = `${infoBN.ghichu ?? ''} `
  //chẩn đoán
  if (luuy.length > 60) {
    const wordsArray = splitLongWord(luuy, 60)
    doc.text(wordsArray[0], xPos + doc.getTextDimensions('LƯU Ý Remarks:  ').w, yPos)
    doc.text(wordsArray[1], xPos, yPos + 5)
    yPos += 5
  } else {
    doc.text(`${luuy ?? ''}`, xPos + doc.getTextDimensions('LƯU Ý Remarks:  ').w, yPos)
    yPos += 5
  }
  yPos += 5
  doc.text('Ngày khám ', xPos, yPos)
  doc.setFont('times', 'italic')
  doc.text(' Date of exam:', xPos + doc.getTextDimensions('Ngày khám ').w, yPos)
  doc.setFont('times', 'regular')
  doc.text(
    `${infoBN.ngaykham ? moment(infoBN.ngaykham).format('DD/MM/YYYY') : ''}`,
    xPos + doc.getTextDimensions('Ngày khám Date of exam: ').w,
    yPos,
  )
  yPos += 5
  doc.text('Tái khám ', xPos, yPos)
  doc.setFont('times', 'italic')
  doc.text(' Recall:', xPos + doc.getTextDimensions('Tái khám ').w, yPos)
  doc.setFont('times', 'regular')
  doc.text(
    `${infoBN.ngaytaikham ? moment(infoBN.ngaytaikham).format('DD/MM/YYYY') : ''}`,
    xPos + doc.getTextDimensions('Ngày khám Recall: ').w,
    yPos,
  )

  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}

import { EyeOutlined, FilterOutlined } from '@ant-design/icons'
import CachedIcon from '@mui/icons-material/Cached'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import IconButton from '@mui/material/IconButton'
import {
  Button,
  ConfigProvider,
  DatePicker,
  Input,
  Modal,
  Select,
  Table,
  Tooltip,
  TreeSelect,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import { cardService } from '../../../../services/card/cardService'
import { getInfoPhieuThuAction } from '../../../../store/actions/cardAction'
import { formattedNumber } from '../../../../utils/formattedNumber'
import Static from '../Static/Static'
import DrawerPT from './DrawerPT/DrawerPT'
import { buildFilterCondition } from '../../../../utils/buildFilterCondition'
import { listBranchAction } from '../../../../store/actions/BranchAction'
import 'moment/locale/vi'
import { formatNumber } from 'devextreme/localization'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const { Text } = Typography
const now = moment()
const formatMoment = 'YYYY-MM-DD'
const dateFormat = 'DD-MM-YYYY'
const TablePhieuThu = () => {
  const dispatch = useDispatch()
  const idBranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { chuyenKhoa } = useSelector((state) => state.cartReducer)
  const [currentPage, setCurrentPage] = useState(1)
  const { listBranch, PkDangNhap } = useSelector((state) => state.branchReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const ruleUser = ['Lễ tân '].includes(infoUser?.tenNhom)
  const [isLoading, setIsLoading] = useState(false)
  // const [isModalOpen, setIsModalOpen] = useState(false)
  // const [isModalEdit, setIsModalEdit] = useState(false)
  const [value, setValue] = useState([idBranchLogin])
  const [company, setCompany] = useState(id_company)
  const [filterCK, setFilterCK] = useState(null)
  // const [Branch, setBranch] = useState(idBranchLogin)
  const [type, setType] = useState(null)
  const [group, setGroup] = useState(null)
  const [dateFrom, setDateFrom] = useState(now.format(formatMoment))
  const [dateTo, setDateTo] = useState(now.format(formatMoment))
  const [keyword, setKeyword] = useState('')
  const [listPhieuThu, setListPhieuThu] = useState([])
  const [open, setOpen] = useState(false)
  const [searchMaPhieuThu, setSearchMaPhieuThu] = useState('')
  const [searchNgayThu, setSearchNgayThu] = useState('')
  const [searchMaBenhNhan, setSearchMaBenhNhan] = useState('')
  const [searchTenBenhNhan, setSearchTenBenhNhan] = useState('')
  const [searchNgaySinh, setSearchNgaySinh] = useState('')
  const [searchMaThe, setSearchMaThe] = useState('')
  const [searchTenThe, setSearchTenThe] = useState('')
  const [searchMaBaoCao, setSearchMaBaoCao] = useState('')
  const [searchTenBaoCao, setSearchTenBaoCao] = useState('')
  const [searchNguoiTao, setSearchNguoiTao] = useState('')
  const [searchNguoiBan, setSearchNguoiBan] = useState('')
  const [searchVoucher, setSearchVoucher] = useState('')
  const [searchVoucherDoiTac, setSearchVoucherDoiTac] = useState('')

  const [filterTienMat, setFilterTienMat] = useState('=')
  const [searchTienMat, setSearchTienMat] = useState(null)
  const [filterChuyenKhoan, setFilterChuyenKhoan] = useState('=')
  const [searchChuyenKhoan, setSearchChuyenKhoan] = useState(null)
  const [filterMayPos, setFilterMayPos] = useState('=')
  const [searchMayPos, setSearchMayPos] = useState(null)
  const [filterMomo, setFilterMomo] = useState('=')
  const [searchMomo, setSearchMomo] = useState(null)
  const [filterZalopay, setFilterZalopay] = useState('=')
  const [searchZalopay, setSearchZalopay] = useState(null)
  const [filterVnpay, setFilterVnpay] = useState('=')
  const [searchVnpay, setSearchVnpay] = useState(null)
  const [filterTheTT, setFilterTheTT] = useState('=')
  const [searchTheTT, setSearchTheTT] = useState(null)

  const [filterThanhTien, setFilterThanhTien] = useState('=')
  const [searchThanhTien, setSearchThanhTien] = useState(null)
  const [filterTienGiam, setFilterTienGiam] = useState('=')
  const [searchTienGiam, setSearchTienGiam] = useState(null)
  const [filterThanhToan, setFilterThanhToan] = useState('=')
  const [searchThanhToan, setSearchThanhToan] = useState(null)

  const PhepTinh = [
    { label: '>', value: '>' },
    { label: '<', value: '<' },
    { label: '=', value: '=' },
  ]

  const handleOnChageCongTy = (value) => {
    setCompany(value)
    setValue(['all'])
    dispatch(listBranchAction(value))
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: listBranch
        ?.filter(
          (item) =>
            item?.loaiChiNhanh === 'CHINHANH' &&
            (!company || item?.idct === company) &&
            (!filterCK || item?.idChuyenKhoa === filterCK),
        )
        ?.map((item, index) => ({
          title: item?.tenChiNhanh,
          value: item?.idChiNhanh,
          key: `${item?.idChiNhanh}`,
        })),

      // ?.filter((item) => item?.loaiChiNhanh === 'CHINHANH')
      // ?.filter((item) => (company ? item?.idct === company : item)) // dư
      // ?.filter((item) => (filterCK ? item?.idChuyenKhoa === filterCK : item))
    },
  ]
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const showDrawer = (idthept, idbn, idbnthu2, idthe) => {
    setOpen(true)
    dispatch(getInfoPhieuThuAction(idthept, idbn, idbnthu2, idthe))
  }
  const onClose = () => {
    setOpen(false)
    // dispatch({
    //   type: TypeAction.RESET_PHIEUTHU_CASHIER,
    // })
  }
  const onChangeTable = (pagination) => {
    setCurrentPage(pagination.current)
  }
  //-------- lấy danh sách phiếu thu thẻ thành viên -------//
  const fetchListPhieuThu = async () => {
    try {
      setIsLoading(true)
      let kw = keyword?.length ? keyword?.trim() : keyword
      const result = await cardService.getListPhieuThu(
        value?.includes('all')
          ? listBranch
              ?.filter(
                (item) =>
                  item?.loaiChiNhanh === 'CHINHANH' &&
                  (!company || item?.idct === company) &&
                  (!filterCK || item?.idChuyenKhoa === filterCK),
              )
              ?.map((item) => `idcn=${item?.idChiNhanh}`)
              ?.join('&')
              ?.toString()
          : value
              ?.map((item) => `idcn=${item}`)
              ?.join('&')
              ?.toString(),
        PkDangNhap?.idCongTy,
        dateFrom,
        dateTo,
        kw.length > 0 ? kw : null,
      )
      setListPhieuThu(result?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  // const showModal = () => {
  //   setIsModalOpen(true)
  //   dispatch(getListTypeCardByCNAction(PkDangNhap?.idChiNhanh))
  // }
  // const handleEditCard = (idbn, idthe) => {
  //   setIsModalEdit(true)
  //   dispatch(infoCardBN(idbn, idthe, PkDangNhap?.idChiNhanh))
  // }
  const handleReload = () => {
    fetchListPhieuThu()
    // console.log(listPhieuThu)
  }
  const handleSelected = (name) => (value) => {
    // kiểm tra name set theo filter nào
    if (name === 'branch') {
      // nếu giá trị chọn tất cả  =>> value = null Types
      // value !== 'all' ? setBranch(value) : setBranch(null)
    } else if (name === 'Types') {
      value !== 'all' ? setType(value) : setType(null)
    } else if (name === 'Group') {
      value !== 'all' ? setGroup(value) : setGroup(null)
    } else if (name === 'ChuyenKhoa') {
      value !== 'all' ? setFilterCK(value) : setFilterCK(null)
      setValue(['all'])
    }
  }
  const handleDatePicker = (name) => (Date, dateString) => {
    // format date
    const value = moment(dateString, dateFormat).format(formatMoment)
    name === 'from' ? setDateFrom(value) : setDateTo(value)
  }

  const { confirm } = Modal
  const showDisableConfirm = (record, sudung) => {
    //console.log(record)
    // confirm({
    //   title: sudung === 0 ? 'Bạn có muốn ngưng sử dụng thẻ thành viên ?' : 'Bạn có muốn tiếp tục sử dụng thẻ thành viên ?',
    //   icon: <QuestionCircleFilled />,
    //   content: (<p>
    //     Bạn xác nhận {sudung === 0 ? 'ngưng sử dụng' : 'tiếp tục sử dụng'} thẻ thành viên:
    //     <br></br>
    //     {record.maThe} - {record.tenBenhNhan}
    //   </p>),
    //   okText: 'Yes',
    //   okType: sudung === 0 ? 'danger' : 'primary',
    //   cancelText: 'No',
    //   onOk() {
    //     dispatch(putDisableMemberCardAction(record.idThe, sudung))
    //     setIsModalEdit(false)
    //     handleReload()
    //     // resetStore()
    //     handleReload()
    //   },
    //   onCancel() {
    //     // console.log('Cancel')
    //   },
    // })
  }
  const columns = [
    {
      title: 'STT ',
      // dataIndex: 'stt',
      // key: 'stt',
      width: 50,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => {
        return (currentPage - 1) * 50 + index + 1
      },
    },
    {
      title: 'Mã phiếu thu',
      dataIndex: 'maphieuthu',
      key: 'maphieuthu',
      fixed: 'left',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.maphieuthu ?? '').localeCompare(b?.maphieuthu ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchMaPhieuThu(e.target.value)}
                  value={searchMaPhieuThu}
                />
              </ConfigProvider>
            </>
          ),
          fixed: 'left',
          dataIndex: 'maphieuthu',
          key: 'search' + 'maphieuthu',
          width: 120,
          render: (text, record) => (
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => {
                showDrawer(record.idthept, record.idbn, record.idbns, record.idthe)
                // handlePhieuThuTTV(record.idBN, record.idThe)
              }}
            >
              {text}
            </span>
          ),
        },
      ],
    },
    {
      title: 'Ngày thu',
      dataIndex: 'ngaythanhtoan',
      key: 'ngaythanhtoan',
      align: 'center',
      fixed: 'left',
      width: 140,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.ngaythanhtoan ?? '').localeCompare(b?.ngaythanhtoan ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchNgayThu(e.target.value)}
                  value={searchNgayThu}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'ngaythanhtoan',
          key: 'search' + 'ngaythanhtoan',
          align: 'center',
          fixed: 'left',
          width: 140,
          render: (text, record, index) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
        },
      ],
    },
    {
      title: 'Tên bệnh nhân',
      dataIndex: 'tenbenhnhan',
      key: 'tenbenhnhan',
      width: 250,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tenbenhnhan ?? '').localeCompare(b?.tenbenhnhan ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchTenBenhNhan(e.target.value)}
                  value={searchTenBenhNhan}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'tenbenhnhan',
          key: 'search' + 'tenbenhnhan',
          width: 250,
        },
      ],
    },
    {
      title: 'Mã bệnh nhân',
      dataIndex: 'mabenhnhan',
      key: 'mabenhnhan',
      width: 140,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.mabenhnhan ?? '').localeCompare(b?.mabenhnhan ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchMaBenhNhan(e.target.value)}
                  value={searchMaBenhNhan}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'mabenhnhan',
          key: 'search' + 'mabenhnhan',
          width: 120,
        },
      ],
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaysinh',
      key: 'ngaysinh',
      align: 'center',
      width: 100,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.ngaysinh ?? '').localeCompare(b?.ngaysinh ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchNgaySinh(e.target.value)}
                  value={searchNgaySinh}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'ngaysinh',
          key: 'search' + 'ngaysinh',
          align: 'center',
          width: 100,
          render: (text, record, index) => moment(text).format('DD/MM/YYYY'),
        },
      ],
    },
    {
      title: 'Mã thẻ ',
      dataIndex: 'mathe',
      key: 'mathe',
      width: 100,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.mathe ?? '').localeCompare(b?.mathe ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchMaThe(e.target.value)}
                  value={searchMaThe}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'mathe',
          key: 'search' + 'mathe',
          width: 100,
        },
      ],
    },
    {
      title: 'Tên thẻ',
      dataIndex: 'tenthe',
      key: 'tenthe',
      width: 300,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tenthe ?? '').localeCompare(b?.tenthe ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchTenThe(e.target.value)}
                  value={searchTenThe}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'tenthe',
          key: 'search' + 'tenthe',
          width: 300,
        },
      ],
    },
    {
      title: 'Mã báo cáo',
      dataIndex: 'mabaocao',
      key: 'mabaocao',
      width: 110,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.mabaocao ?? '').localeCompare(b?.mabaocao ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchMaBaoCao(e.target.value)}
                  value={searchMaBaoCao}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'mabaocao',
          key: 'search' + 'mabaocao',
          width: 110,
        },
      ],
    },
    {
      title: 'Tên báo cáo',
      dataIndex: 'tenbaocao',
      key: 'tenbaocao',
      width: 300,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tenbaocao ?? '').localeCompare(b?.tenbaocao ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchTenBaoCao(e.target.value)}
                  value={searchTenBaoCao}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'tenbaocao',
          key: 'search' + 'tenbaocao',
          width: 300,
        },
      ],
    },
    {
      title: 'Người tạo',
      dataIndex: 'nguoithu',
      key: 'nguoithu',
      //render: (text, record, index) => text + (record.manguoitao ? ' - ' + record.manguoitao : ''),
      width: 220,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.nguoithu ?? '').localeCompare(b?.nguoithu ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchNguoiTao(e.target.value)}
                  value={searchNguoiTao}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'nguoithu',
          key: 'search' + 'nguoithu',
          width: 220,
        },
      ],
    },
    {
      title: 'Người bán',
      dataIndex: 'nguoiban',
      key: 'nguoiban',
      width: 220,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.nguoiban ?? '').localeCompare(b?.nguoiban ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchNguoiBan(e.target.value)}
                  value={searchNguoiBan}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'nguoiban',
          key: 'search' + 'nguoiban',
          width: 220,
        },
      ],
    },
    {
      title: 'Voucher',
      dataIndex: 'mavoucher',
      width: 100,
      key: 'mavoucher',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.mavoucher ?? '').localeCompare(b?.mavoucher ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchVoucher(e.target.value)}
                  value={searchVoucher}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'mavoucher',
          key: 'search' + 'mavoucher',
          width: 100,
        },
      ],
    },
    // tạo thẻ chưa mở chức năng đối tác
    // {
    //   title: 'Voucher đối tác',
    //   dataIndex: 'mavoucherdoitac',
    //   width: 140,
    //   key: 'mavoucherdoitac',
    //   showSorterTooltip: false,
    //   sorter: {
    //     compare: (a, b) => {
    //       return (a?.mavoucherdoitac ?? '').localeCompare(b?.mavoucherdoitac ?? '')
    //     },
    //   },
    //   children: [
    //     {
    //       title: (
    //         <>
    //           <ConfigProvider
    //             theme={{
    //               token: {
    //                 borderRadius: 0,
    //               },
    //             }}
    //           >
    //             <Input
    //               size="small"
    //               onChange={(e) => setSearchVoucherDoiTac(e.target.value)}
    //               value={searchVoucherDoiTac}
    //             />
    //           </ConfigProvider>
    //         </>
    //       ),
    //       dataIndex: 'mavoucherdoitac',
    //       key: 'search' + 'mavoucherdoitac',
    //       width: 140,
    //     },
    //   ],
    // },
    {
      title: 'Tiền mặt',
      dataIndex: 'tienmat',
      key: 'tienmat',
      align: 'right',
      width: 140,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tienmat ?? 0) - (b?.tienmat ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setFilterTienMat(value)}
                    value={filterTienMat}
                    options={PhepTinh}
                  />
                  <Input
                    size="small"
                    type="Number"
                    className="text-xs"
                    min={0}
                    step={1}
                    value={searchTienMat}
                    onChange={(e) => setSearchTienMat(e.target.value)}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'tienmat',
          key: 'search' + 'tienmat',
          width: 140,
          align: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },
    {
      title: 'Chuyển khoản',
      dataIndex: 'chuyenkhoan',
      key: 'chuyenkhoan',
      align: 'right',
      width: 140,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.chuyenkhoan ?? 0) - (b?.chuyenkhoan ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setFilterChuyenKhoan(value)}
                    value={filterChuyenKhoan}
                    options={PhepTinh}
                  />
                  <Input
                    size="small"
                    type="Number"
                    className="text-xs"
                    min={0}
                    step={1}
                    value={searchChuyenKhoan}
                    onChange={(e) => setSearchChuyenKhoan(e.target.value)}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'chuyenkhoan',
          key: 'search' + 'chuyenkhoan',
          width: 140,
          align: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },
    {
      title: 'Máy pos',
      dataIndex: 'pos',
      key: 'pos',
      align: 'right',
      width: 140,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.pos ?? 0) - (b?.pos ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setFilterMayPos(value)}
                    value={filterMayPos}
                    options={PhepTinh}
                  />
                  <Input
                    size="small"
                    type="Number"
                    className="text-xs"
                    min={0}
                    step={1}
                    value={searchMayPos}
                    onChange={(e) => setSearchMayPos(e.target.value)}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'pos',
          key: 'search' + 'pos',
          width: 140,
          align: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },

    {
      title: 'Momo',
      dataIndex: 'momo',
      key: 'momo',
      align: 'right',
      width: 140,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.momo ?? 0) - (b?.momo ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setFilterMomo(value)}
                    value={filterMomo}
                    options={PhepTinh}
                  />
                  <Input
                    size="small"
                    type="Number"
                    className="text-xs"
                    min={0}
                    step={1}
                    value={searchMomo}
                    onChange={(e) => setSearchMomo(e.target.value)}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'momo',
          key: 'search' + 'momo',
          width: 140,
          align: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },

    {
      title: 'ZaloPay',
      dataIndex: 'zalopay',
      key: 'zalopay',
      align: 'right',
      width: 140,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.zalopay ?? 0) - (b?.zalopay ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setFilterZalopay(value)}
                    value={filterZalopay}
                    options={PhepTinh}
                  />
                  <Input
                    size="small"
                    type="Number"
                    className="text-xs"
                    min={0}
                    step={1}
                    value={searchZalopay}
                    onChange={(e) => setSearchZalopay(e.target.value)}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'zalopay',
          key: 'search' + 'zalopay',
          width: 140,
          align: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },

    {
      title: 'VNPay',
      dataIndex: 'vnpay',
      key: 'vnpay',
      align: 'right',
      width: 140,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.vnpay ?? 0) - (b?.vnpay ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setFilterVnpay(value)}
                    value={filterVnpay}
                    options={PhepTinh}
                  />
                  <Input
                    size="small"
                    type="Number"
                    className="text-xs"
                    min={0}
                    step={1}
                    value={searchVnpay}
                    onChange={(e) => setSearchVnpay(e.target.value)}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'vnpay',
          key: 'search' + 'vnpay',
          width: 140,
          align: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },

    {
      title: 'Thẻ thanh toán',
      dataIndex: 'thett',
      key: 'thett',
      align: 'right',
      width: 140,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.thett ?? 0) - (b?.thett ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setFilterTheTT(value)}
                    value={filterTheTT}
                    options={PhepTinh}
                  />
                  <Input
                    size="small"
                    type="Number"
                    className="text-xs"
                    min={0}
                    step={1}
                    value={searchTheTT}
                    onChange={(e) => setSearchTheTT(e.target.value)}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'thett',
          key: 'search' + 'thett',
          width: 140,
          align: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },

    {
      title: 'Thành tiền',
      dataIndex: 'tiensudung',
      key: 'tiensudung',
      align: 'right',
      width: 140,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tiensudung ?? 0) - (b?.tiensudung ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setFilterThanhTien(value)}
                    value={filterThanhTien}
                    options={PhepTinh}
                  />
                  <Input
                    size="small"
                    type="Number"
                    className="text-xs"
                    min={0}
                    step={1}
                    value={searchThanhTien}
                    onChange={(e) => setSearchThanhTien(e.target.value)}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'tiensudung',
          key: 'search' + 'tiensudung',
          width: 140,
          align: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },
    {
      title: 'Tiền giảm',
      dataIndex: 'tiengiamgia',
      key: 'tiengiamgia',
      align: 'right',
      width: 140,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tiengiamgia ?? 0) - (b?.tiengiamgia ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setFilterTienGiam(value)}
                    value={filterTienGiam}
                    options={PhepTinh}
                  />
                  <Input
                    size="small"
                    type="Number"
                    className="text-xs"
                    min={0}
                    step={1}
                    value={searchTienGiam}
                    onChange={(e) => setSearchTienGiam(e.target.value)}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'tiengiamgia',
          key: 'search' + 'tiengiamgia',
          width: 140,
          align: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },
    {
      title: 'Thanh toán',
      dataIndex: 'tienthanhtoan',
      key: 'tienthanhtoan',
      align: 'right',
      width: 140,
      fixed: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tienthanhtoan ?? 0) - (b?.tienthanhtoan ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setFilterThanhToan(value)}
                    value={filterThanhToan}
                    options={PhepTinh}
                  />
                  <Input
                    size="small"
                    type="Number"
                    className="text-xs"
                    min={0}
                    step={1}
                    value={searchThanhToan}
                    onChange={(e) => setSearchThanhToan(e.target.value)}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'tienthanhtoan',
          key: 'search' + 'tienthanhtoan',
          width: 140,
          align: 'right',
          fixed: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },
    // {
    //   title: "Ngày tạo",
    //   dataIndex: "ngayDangKy",
    //   key: "ngayDangKy",
    //   align: "center",
    //   width: 150,
    //   render: (text, record, index) =>
    //     moment(text).format("DD/MM/YYYY HH:mm:ss"),
    // },
    // {
    //   title: "Thẻ Thanh toán",
    //   dataIndex: "theThanhToan",
    //   key: "theThanhToan",
    //   width: 120,
    //   render: (text, record, index) =>
    //     text === 0 ? (
    //       <Badge status="error" text="Chưa kích hoạt" />
    //     ) : (
    //       <Badge status="success" text="Đã kích hoạt" />
    //     ),
    // },
    // {
    //   title: "Sử dụng",
    //   dataIndex: "suDung",
    //   key: "suDung",
    //   width: 70,
    //   align: "center",
    //   render: (text, record, index) =>
    //     text === 1 ? <Checkbox checked /> : <Checkbox disabled />,
    // },
    // {
    //   title: "Hiệu lực",
    //   dataIndex: "hieuLuc",
    //   key: "hieuLuc",
    //   width: 70,
    //   align: "center",
    //   render: (text, record, index) =>
    //     text === 1 ? (checkHetHanTTV(record?.denNgay) ? <Checkbox disabled /> : <Checkbox checked />) : <Checkbox disabled />,
    // },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 40,
      align: 'center',
      fixed: 'right',
      render: (text, record, index) => (
        <>
          <Tooltip title="Xem chi tiết" color="blue" className="cursor-pointer">
            <EyeOutlined
              style={{ fontSize: 20 }}
              className="text-blue-500"
              onClick={() => {
                showDrawer(record.idthept, record.idbn, record.idbns, record.idthe)
                // handlePhieuThuTTV(record.idBN, record.idThe)
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ]
  useEffect(() => {
    fetchListPhieuThu()
  }, [])
  // useEffect(() => {
  //   dispatch(listBranchAction(id_company))
  //   dispatch(cardReceiveAction(Branch, dateFrom, dateTo, type, group, keyword, PkDangNhap?.idCongTy))
  // }, [detailCard, isModalEdit, isModalOpen])
  // useEffect(() => {
  //   dispatch(cardReceiveAction(Branch, dateFrom, dateTo, type, group, keyword, PkDangNhap?.idCongTy))
  // }, [isModalEdit])

  const exportToExcel = () => {
    const formattedData = listPhieuThu.map((item) => ({
      'Mã phiếu thu': item.maphieuthu,
      'Ngày thu': moment(item.ngaythanhtoan).format('DD/MM/YYYY HH:mm:ss'),
      'Tên bệnh nhân': item.tenbenhnhan,
      'Mã bệnh nhân': item.mabenhnhan,
      'Ngày sinh': moment(item.ngaysinh).format('DD/MM/YYYY'),
      'Mã thẻ': item.mathe,
      'Tên thẻ': item.tenthe,
      'Mã báo cáo': item.mabaocao,
      'Tên báo cáo': item.tenbaocao,
      'Người tạo': item.nguoithu,
      'Người bán': item.nguoiban,
      'Voucher ': item.mavoucher,
      'Tiền mặt': item.tienmat,
      'Chuyển khoản': item.chuyenkhoan,
      'Máy pos': item.pos,
      'Máy momo': item.momo,
      'Zalo Pay': item.zalopay,
      VNPay: item.vnpay,
      'Thẻ thanh toán': item.thett,
      'Thành tiền': item.tiensudung,
      'Tiền giảm': item.tiengiamgia,
      'Thanh toán': item.tienthanhtoan,
    }))
    const name = 'Phiếu thu TTV'
    exportExcelformat(formattedData, name)
  }

  return (
    <div className="p-2">
      <div className="w-full pb-3">
        <Static listPhieuThu={listPhieuThu} />
      </div>
      <div className="flex gap-2 items-center justify-between">
        <div className="flex gap-1">
          <ul className="flex gap-1">
            <li>
              <p className="font-semibold">Từ ngày</p>
              <DatePicker
                format={dateFormat}
                allowClear={false}
                maxDate={dayjs(now)}
                defaultValue={dayjs(dateFrom)}
                onChange={handleDatePicker('from')}
                className="w-full"
              />
            </li>
            <li>
              <p className="font-semibold">Đến ngày</p>
              <DatePicker
                onChange={handleDatePicker('to')}
                maxDate={dayjs(now)}
                format={dateFormat}
                allowClear={false}
                defaultValue={dayjs(dateTo)}
                className="w-full"
              />
            </li>
          </ul>
          {ruleUser ? (
            <></>
          ) : (
            <>
              <div className="flex gap-1">
                <div className="max-w-[17rem]">
                  <label className="font-semibold">Công ty</label>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    suffixIcon={<FilterOutlined />}
                    defaultValue={id_company}
                    options={listCompany?.map((item) => ({
                      label: item.tenct,
                      value: item.idct,
                    }))}
                    className="w-full"
                    onChange={handleOnChageCongTy}
                  />
                </div>
                <div className="max-w-[17rem]">
                  <label className="font-semibold">Chuyên khoa</label>
                  <Select
                    onChange={handleSelected('ChuyenKhoa')}
                    suffixIcon={<FilterOutlined />}
                    defaultValue="all"
                    className="w-full"
                    options={[
                      { label: 'Tất cả chuyên khoa', value: 'all' },
                      ...(chuyenKhoa
                        ? chuyenKhoa?.map((items) => ({
                            label: items?.tenChuyenKhoa,
                            value: items?.idChuyenKhoa,
                          }))
                        : []),
                    ]}
                  />
                </div>
                <div className="min-w-[17rem]">
                  <label className="font-semibold">Chi nhánh</label>
                  <TreeSelect
                    {...tProps}
                    style={{ flexWrap: 'unset' }}
                    className="w-full h-8 overflow-clip"
                  />
                </div>
              </div>
            </>
          )}
          <div className="max-w-64">
            <label className="font-semibold">Tìm kiếm</label>
            <Input
              className="w-full"
              placeholder="Nhập từ khóa..."
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
              value={keyword}
            />
          </div>
          <div className="pt-3">
            <IconButton
              disabled={isLoading}
              sx={{ marginRight: 2 }}
              onClick={handleReload}
              color="primary"
            >
              <CachedIcon />
            </IconButton>
            <Button
              disabled={isLoading || !listPhieuThu?.length}
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>
      <div className="min-h-[70vh] mt-2">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            loading={isLoading}
            locale={{
              triggerDesc: 'Sắp xếp giảm dần',
              triggerAsc: 'Sắp xếp tăng dần',
              cancelSort: 'Hủy sắp xếp',
            }}
            bordered
            dataSource={listPhieuThu?.filter(
              (item) =>
                (!searchMaPhieuThu ||
                  (item?.maphieuthu ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchMaPhieuThu ?? '')?.toLocaleUpperCase())) &&
                (!searchNgayThu ||
                  moment(item?.ngaythanhtoan)
                    ?.format('DD/MM/YYYY HH:mm:ss')
                    ?.includes((searchNgayThu ?? '')?.toLocaleUpperCase())) &&
                (!searchMaBenhNhan ||
                  (item?.mabenhnhan ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchMaBenhNhan ?? '')?.toLocaleUpperCase())) &&
                (!searchTenBenhNhan ||
                  (item?.tenbenhnhan ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchTenBenhNhan ?? '')?.toLocaleUpperCase())) &&
                (!searchNgaySinh ||
                  moment(item?.ngaysinh)
                    ?.format('DD/MM/YYYY')
                    ?.includes((searchNgaySinh ?? '')?.toLocaleUpperCase())) &&
                (!searchMaThe ||
                  (item?.mathe ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchMaThe ?? '')?.toLocaleUpperCase())) &&
                (!searchTenThe ||
                  (item?.tenthe ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchTenThe ?? '')?.toLocaleUpperCase())) &&
                (!searchMaBaoCao ||
                  (item?.mabaocao ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchMaBaoCao ?? '')?.toLocaleUpperCase())) &&
                (!searchTenBaoCao ||
                  (item?.tenbaocao ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchTenBaoCao ?? '')?.toLocaleUpperCase())) &&
                (!searchNguoiTao ||
                  (item?.nguoithu ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchNguoiTao ?? '')?.toLocaleUpperCase())) &&
                (!searchNguoiBan ||
                  (item?.nguoiban ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchNguoiBan ?? '')?.toLocaleUpperCase())) &&
                (!searchVoucher ||
                  (item?.mavoucher ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchVoucher ?? '')?.toLocaleUpperCase())) &&
                (!searchVoucherDoiTac ||
                  (item?.mavoucherdoitac ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchVoucherDoiTac ?? '')?.toLocaleUpperCase())) &&
                (!searchTienMat ||
                  buildFilterCondition(filterTienMat, searchTienMat, item?.tienmat)) &&
                (!searchChuyenKhoan ||
                  buildFilterCondition(filterChuyenKhoan, searchChuyenKhoan, item?.chuyenkhoan)) &&
                (!searchMayPos || buildFilterCondition(filterMayPos, searchMayPos, item?.pos)) &&
                (!searchMomo || buildFilterCondition(filterMomo, searchMomo, item?.momo)) &&
                (!searchZalopay ||
                  buildFilterCondition(filterZalopay, searchZalopay, item?.zalopay)) &&
                (!searchVnpay || buildFilterCondition(filterVnpay, searchVnpay, item?.vnpay)) &&
                (!searchTheTT || buildFilterCondition(filterTheTT, searchTheTT, item?.thett)) &&
                (!searchThanhTien ||
                  buildFilterCondition(filterThanhTien, searchThanhTien, item?.tiensudung)) &&
                (!searchTienGiam ||
                  buildFilterCondition(filterTienGiam, searchTienGiam, item?.tiengiamgia)) &&
                (!searchThanhToan ||
                  buildFilterCondition(filterThanhToan, searchThanhToan, item?.tienthanhtoan)),
            )}
            onChange={onChangeTable}
            pagination={{
              pageSize: 50,
              showSizeChanger: false,
            }}
            columns={columns}
            scroll={{
              x: 0,
              y: '55vh',
            }}
            summary={(pageData) => {
              let chuyenKhoan = 0
              let tienMat = 0
              let mayPos = 0
              let Momo = 0
              let zaloPay = 0
              let vnPay = 0
              let TheTT = 0
              let ThanhTien = 0
              let TienGiam = 0
              let ThanhToan = 0
              pageData.forEach(
                ({
                  pos,
                  thett,
                  tiensudung,
                  zalopay,
                  vnpay,
                  momo,
                  chuyenkhoan,
                  tienmat,
                  tienthanhtoan,
                  tiengiamgia,
                }) => {
                  chuyenKhoan += chuyenkhoan
                  tienMat += tienmat
                  mayPos += pos
                  Momo += momo
                  zaloPay += zalopay
                  vnPay += vnpay
                  TheTT += thett
                  ThanhTien += tiensudung
                  TienGiam += tiengiamgia ?? 0
                  ThanhToan += tienthanhtoan
                },
              )
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                    <Table.Summary.Cell index={9}></Table.Summary.Cell>
                    <Table.Summary.Cell index={10}></Table.Summary.Cell>
                    <Table.Summary.Cell index={11}></Table.Summary.Cell>
                    <Table.Summary.Cell index={12}></Table.Summary.Cell>
                    <Table.Summary.Cell index={13} align="right">
                      <Text type="danger">{formatNumber(tienMat)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14} align="right">
                      <Text type="danger">{formatNumber(chuyenKhoan)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15} align="right">
                      <Text type="danger">{formatNumber(mayPos)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={16} align="right">
                      <Text type="danger">{formatNumber(Momo)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={17} align="right">
                      <Text type="danger">{formatNumber(zaloPay)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={18} align="right">
                      <Text type="danger">{formatNumber(vnPay)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={19} align="right">
                      <Text type="danger">{formatNumber(TheTT)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={20} align="right">
                      <Text type="danger">{formatNumber(ThanhTien)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={21} align="right">
                      <Text type="danger">{formatNumber(TienGiam)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={22} align="right">
                      <Text type="danger">{formatNumber(ThanhToan)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={23}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
      <DrawerPT open={open} onClose={onClose} />
      {/* <ModalAdd setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
      <ModalEdit isModalEdit={isModalEdit} setIsModalEdit={setIsModalEdit} /> */}
    </div>
  )
}

export default TablePhieuThu

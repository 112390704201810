import React, { useState } from 'react'
import { ConfigProvider, Table } from 'antd'
import IconButton from '@mui/material/IconButton'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { DatePicker, Select, TreeSelect, Button } from 'antd'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { reportService } from '../../../../../services/Report/reportService'
import dayjs from 'dayjs'
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const { SHOW_PARENT } = TreeSelect
const TongHop = ({ ID_BRANCH, ID_COMPANY }) => {
  const [branchs, setBranchs] = useState([])
  const [chuyenkhoas, setChuyenKhoas] = useState([])
  const [data, setData] = useState ([])
  const [dataExport, setDataExport] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  //   const [form, setForm] = useState({
  //     idCompany: ID_COMPANY,
  //     idChuyenKhoa: null,
  //     idBranch: [ID_BRANCH],
  //     form: null,
  //     to: null,
  //   })
  //   const fetchBranchByChuyenKhoa = async (value) => {
  //     try {
  //       const { data } = await reportService.getBranchByChuyenKhoa(form?.idCompany, value)
  //       setChuyenKhoas(data)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   const treeData = [
  //     {
  //       title: 'Tất cả',
  //       value: 'all',
  //       key: '0-1',
  //       children: branchs?.map((branch, index) => ({
  //         title: branch.tenChiNhanh,
  //         value: branch.idChiNhanh,
  //       })),
  //     },
  //   ]
  //   const tProps = {
  //     treeData,
  //     value: form?.idBranch,
  //     onChange,
  //     treeCheckable: true,
  //     showCheckedStrategy: SHOW_PARENT,
  //     placeholder: 'Chọn chi nhánh...',
  //     filterTreeNode: (input, treeNode) =>
  //       treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
  //     showSearch: true,
  //     style: {
  //       width: '100%',
  //     },
  //   }
  const fetchData = async () => {
    try {
    } catch (error) {
      console.log(error)
    } finally {
    }
  }
  const exportToExcel = () => {}
  return (
    <>
      {/* <div>
        <ConfigProvider>
          <Table />
        </ConfigProvider>
      </div> */}
    </>
  )
}

export default TongHop

import React from "react";
import { Input } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";

const BNEdit = ({ isBNtang }) => {
  const { infoBN, infoBNthu2 } = useSelector((state) => state.cartReducer);
  return (
    <form className="text-start">
      <div className="flex flex-col gap-2">
        <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">
              Họ và tên:
            </label>
            <Input
              style={{ color: "black" }}
              disabled
              value={isBNtang ? infoBNthu2?.tenBenhNhan : infoBN?.tenBenhNhan}
              size="small"
            />
          </div>
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">Mã BN:</label>
            <Input
              style={{ color: "black" }}
              value={isBNtang ? infoBNthu2?.maBenhNhan : infoBN?.maBenhNhan}
              disabled
              size="small"
            />
          </div>
        </div>
        <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">Đối tượng:</label>
            <Input
              disabled
              style={{ color: "black" }}
              value={isBNtang ? infoBNthu2?.tenDoiTuong : infoBN?.tenDoiTuong}
              size="small"
            />
          </div>
          <div className="flex w-1/2 gap-1 ">
            <label className="w-1/3">Nghề nghiệp:</label>
            <Input
              disabled
              style={{ color: "black" }}
              value={isBNtang ? infoBNthu2?.tenNgheNghiep : infoBN?.tenNgheNghiep}
              size="small"
            />
          </div>
        </div>
        <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">Ngày sinh:</label>
            <Input
              disabled
              style={{ color: "black" }}
              value={isBNtang ? infoBNthu2?.ngaySinh && moment(infoBNthu2?.ngaySinh).format("DD/MM/YYYY") : infoBN?.ngaySinh && moment(infoBN?.ngaySinh).format("DD/MM/YYYY")}
              size="small"
            />
          </div>
          <div className="flex w-1/2 gap-1">
            <label className="w-1/3">Dân tộc:</label>
            <Input
              disabled
              style={{ color: "black" }}
              value={isBNtang ? infoBNthu2?.tenDanToc : infoBN?.tenDanToc}
              size="small"
            />
          </div>
        </div>
        <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">Điện thoại:</label>
            <Input
              disabled
              style={{ color: "black" }}
              value={isBNtang ? infoBNthu2?.dienThoai : infoBN?.dienThoai}
              size="small"
            />
          </div>
          <div className="flex gap-1 w-1/2">
            <label className="w-1/3 ">Giới tính:</label>
            <Input
              disabled
              style={{ color: "black" }}
              value={isBNtang ? infoBNthu2?.gioiTinh : infoBN?.gioiTinh}
              size="small"
            />
          </div>
        </div>
        <div className="flex gap-1">
          <label className="w-[117px] text-end">Địa chỉ:</label>
          <Input
            disabled
            style={{ color: "black" }}
            value={isBNtang ? infoBNthu2?.diaChi : infoBN?.diaChi}
            size="small"
          />
        </div>
      </div>
    </form>
  );
};

export default BNEdit;

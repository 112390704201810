import { https } from '../apiService'

export const loaiHangHoaService = {
  //ThuocVatTuPhanLoai?keyword=11
  getAllLoaiHangHoa: (idnhom, keyword) =>
    https.get(`ThuocVatTuPhanLoai`, {
      params: {
        idnhom,
        keyword,
      },
    }),
  postLoaiHangHoa: (data) => https.post('ThuocVatTuPhanLoai/createNew', data),
  updateLoaiHangHoa: (data) => https.put('ThuocVatTuPhanLoai/UpdateTypeByIdLoai', data),
  getDetailThuocVatTu: (id, keyword) => https.get(`ThuocVatTuPhanLoai/${id}`),
  deleteLoaiHangHoa: (id) => https.delete(`ThuocVatTuPhanLoai/DeleteTypeByIdLoai?idLoai=${id}`),
  // api/ThuocVatTuNhom/SearchNhomThuoc?idPhanLoai=1
  getNhomHangHoaByLoai: (id, keyword) =>
    https.get(`ThuocVatTuNhom/SearchNhomThuoc?idPhanLoai=${id}&keyword=${keyword}`),
  postNhomHangHoa: (data) => https.post('ThuocVatTuNhom/createNew', data),
  updateNhomHangHoa: (form) => https.put('ThuocVatTuNhom/UpdateGroupsByIdNhom', form),
  deleteNhomHangHoa: (id) => https.delete(`ThuocVatTuNhom/DeleteGroupByIdNhom?idNhom=${id}`),

  updateThucPhamChucNang: (active, listIdNhom) => {
    const params = listIdNhom.map((id) => `idnhom=${id}`).join('&')
    return https.put(`ThuocVatTuNhom/ActivesTPCNByNhom?active=${active}&${params}`)
  },
}

import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  ListBNCRM: null,
  listKH: null,
  infoBNCRM: null,
  listSMS: [],
  listBNImport: null,
}

const benhnhanCRMReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_LIST_ALL_BN_CRM:
        draft.ListBNCRM = payload
        break
      case typeAction.DISPATCH_ALL_KH_BN_CRM:
        draft.listKH = payload
        break
      case typeAction.DISPATCH_INFO_BN_CRM:
        draft.infoBNCRM = payload
        break
      case typeAction.ADD_LIST_INFO_SMS_CRM:
        draft.listSMS = payload
        break
      case typeAction.IMPORT_DATA_CRM:
        draft.listBNImport = payload
        break
      default:
        return state
    }
  })
}

export default benhnhanCRMReducer

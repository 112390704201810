import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, Input, Pagination, Table, Tabs, Modal, Select } from 'antd'
import TabXuLyPhanAnh from './TabXuLy/TabXuLyPhanAnh'
import TabPhanAnhKhachHang from './TabXuLy/TabPhanAnhKhachHang'
import { listBranchAction } from '../../../store/actions/BranchAction'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { https } from '../../../services/apiService'
import ToastCus from '../../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal

function XuLy() {
  const dispatch = useDispatch()
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  useEffect(() => {
    dispatch(listAllCompanyAction())
    dispatch(listBranchAction(ID_COMPANY))
  }, [])
  return (
    <div className="p-3 h-full bg-[#EFEFEF]">
      <div
        className="h-full p-2 bg-white border rounded-xl"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <Tabs
          defaultActiveKey={1}
          items={[
            {
              key: 1,
              value: 1,
              label: 'Phản ảnh khách hàng',
              children: (
                <>
                  <TabPhanAnhKhachHang />
                </>
              ),
            },
            {
              key: 2,
              value: 2,
              label: 'Xử lý phản ảnh',
              children: (
                <>
                  <TabXuLyPhanAnh />
                </>
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}

export default XuLy

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Alert } from 'antd'
import History from './History'
import Images from './Images'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
const ImageSA = ({ image, setImage, listImagePrint, setListImagePrint }) => {
  const dispatch = useDispatch()
  const { infoBNSieuAm } = useSelector((state) => state.sieuAmReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const [tabKey, settabKey] = useState(1)
  const [listQuyen, setlistQuyen] = useState([])
  const quyenHinhAnh = 'QHT336'
  const quyenLichSu = 'QHT335'
  const checkQuyenCacTabs = async (maquyen) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, maquyen)
      setlistQuyen((prev) => (prev?.find((item) => item === maquyen) ? prev : [...prev, maquyen]))
    } catch (error) {
      setlistQuyen((prev) => prev?.filter((item) => item !== maquyen))
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  useEffect(() => {
    tabKey === 1 && checkQuyenCacTabs(quyenHinhAnh) //hình ảnh
    tabKey === 2 && checkQuyenCacTabs(quyenLichSu) //lịch sử
  }, [tabKey, infoBNSieuAm])
  return (
    <div className="p-2 min-h-[400px]">
      <Tabs
        value={tabKey}
        onChange={settabKey}
        items={[
          {
            key: 1,
            label: 'Hình ảnh',
            children: listQuyen?.includes(quyenHinhAnh) ? (
              <Images
                image={image}
                setImage={setImage}
                listImagePrint={listImagePrint}
                setListImagePrint={setListImagePrint}
              />
            ) : (
              <Alert message="Tài khoản không có quyền thao tác!" type="error" showIcon />
            ),
          },
          {
            key: 2,
            label: 'Lịch sử khám',
            children: listQuyen?.includes(quyenLichSu) ? (
              <History />
            ) : (
              <Alert message="Tài khoản không có quyền thao tác!" type="error" showIcon />
            ),
          },
        ]}
      />
    </div>
  )
}

export default ImageSA

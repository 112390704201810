import React from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Divider, Modal, InputNumber } from 'antd'
import TextField from '@mui/material/TextField'
import { formattedNumber } from '../../../../utils/formattedNumber'
import moment from 'moment'
import { addToaBanLe } from '../../../../store/actions/drugstoreAction'
import ToastCus from '../../../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')
const PayModal = ({
  benhNhanTT,
  cancelModal,
  isModal,
  tongGiaTien,
  thuocBN,
  dataBNTTC,
  resetForm,
  ghiChu,
}) => {
  const dispatch = useDispatch()
  const { infoVC } = useSelector((state) => state.drugstoreReducer)
  const handleSubmit = (values) => {
    const today = moment()
    benhNhanTT.ngayThanhToan = today.format('YYYY-MM-DDTHH:mm:ss')
    values.tienthanhtoan = tongGiaTien
    values.tongtien = tongGiaTien
    // console.log(values);
    // console.log(benhNhanTT);
    cancelModal()
    dispatch(addToaBanLe(benhNhanTT, values, thuocBN, dataBNTTC, infoVC, ghiChu, resetStore))
  }
  const resetStore = () => {
    formik.resetForm()
    resetForm()
  }
  const formik = useFormik({
    initialValues: {
      idbntt: 0,
      idbnttchung: 0,
      idthe: null,
      tienmat: 0,
      chuyenkhoan: 0,
      thett: 0,
      momo: 0,
      vnpay: 0,
      zalopay: 0,
      pos: 0,
      tongtien: 0,
      tienthanhtoan: 0,
      trangthaithanhtoan: 2, // 2 => đã thanh toán
    },
    onSubmit: (values) => handleSubmit(values),
  })
  const { tienmat, chuyenkhoan, momo, vnpay, zalopay, pos, thett } = formik.values
  const totalPay = [tienmat, chuyenkhoan, momo, vnpay, zalopay, pos, thett].reduce(
    (acc, curr) => acc + curr,
    0,
  )
  // thực hiện xử lý các ô input
  const onChangInput = (name, value) => {
    // const { value, name } = event.target
    //* nếu value === '' thì value = 0
    //* ngược lại set set giá trị về só thực, và giá trị phải lớn hơn 0
    //* trường hợp value null hoặt unf thì giá trị = 0
    const newValue = value === '' ? 0 : Math.max(0, parseFloat(value) || 0)
    // tạo gia mãng value nháp
    const updatedValues = {
      ...formik.values,
      [name]: newValue,
    }
    // tính tổng trước
    const totalPay = [
      updatedValues.tienmat,
      updatedValues.chuyenkhoan,
      updatedValues.momo,
      updatedValues.vnpay,
      updatedValues.zalopay,
      updatedValues.pos,
      updatedValues.thett,
    ].reduce((acc, curr) => acc + curr, 0)
    // nếu lớn hơn giá trị không thực hiện
    if (totalPay <= tongGiaTien) {
      formik.setFieldValue(name, newValue)
    }
  }
  const handleKeyDown = (event) => {
    const { name } = event.target
    // Nếu phím "P" được nhấn
    if (event.key === 'P' || event.key === 'p') {
      // Tính tổng giá trị hiện tại của các input
      const totalPay = [
        formik.values.tienmat,
        formik.values.chuyenkhoan,
        formik.values.momo,
        formik.values.vnpay,
        formik.values.zalopay,
        formik.values.pos,
        formik.values.thett,
      ].reduce((acc, curr) => acc + curr, 0)

      // Tính giá trị cần thiết để tổng đạt tới totalPrice
      const remainingValue = Math.max(0, tongGiaTien - totalPay)

      // Đặt giá trị này cho ô input hiện tại
      formik.setFieldValue(name, remainingValue)
      event.preventDefault()
    }
  }
  return (
    <Modal open={isModal} onCancel={cancelModal} footer={null}>
      <form className="mt-10 flex flex-col gap-5" action="">
        <div className="grid grid-cols-2 gap-5">
          <div>
            <label className="font-semibold">Tiền mặt</label>
            <InputNumber
              value={formik.values.tienmat}
              name="tienmat"
              onKeyUp={handleKeyDown}
              onChange={(value) => onChangInput('tienmat', value)} //onChangeInput
              className="w-full"
              step={100}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
          <div>
            <label className="font-semibold">Chuyển khoản</label>
            <InputNumber
              value={formik.values.chuyenkhoan}
              name="chuyenkhoan"
              onKeyUp={handleKeyDown}
              onChange={(value) => onChangInput('chuyenkhoan', value)} //onChangeInput
              className="w-full"
              step={100}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5">
          <div>
            <label className="font-semibold">Momo</label>
            <InputNumber
              value={formik.values.momo}
              name="momo"
              onKeyUp={handleKeyDown}
              onChange={(value) => onChangInput('momo', value)} //onChangeInput
              className="w-full"
              step={100}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
          <div>
            <label className="font-semibold">VNPay</label>
            <InputNumber
              value={formik.values.vnpay}
              name="vnpay"
              onKeyUp={handleKeyDown}
              onChange={(value) => onChangInput('vnpay', value)} //onChangeInput
              className="w-full"
              step={100}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5">
          <div>
            <label className="font-semibold">Zalopay</label>
            <InputNumber
              value={formik.values.zalopay}
              name="zalopay"
              onKeyUp={handleKeyDown}
              onChange={(value) => onChangInput('zalopay', value)} //onChangeInput
              className="w-full"
              step={100}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
          <div>
            <label className="font-semibold">Máy POS</label>
            <InputNumber
              value={formik.values.pos}
              name="pos"
              onKeyUp={handleKeyDown}
              onChange={(value) => onChangInput('pos', value)} //onChangeInput
              className="w-full"
              step={100}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5">
          <div>
            <label className="font-semibold">Thẻ thanh toán</label>
            <InputNumber
              value={formik.values.thett}
              name="thett"
              onKeyUp={handleKeyDown}
              onChange={(value) => onChangInput('thett', value)} //onChangeInput
              className="w-full"
              step={100}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
          <div className="flex items-center">
            <label className="font-semibold text-red-500">* Chưa đăng kí thẻ</label>
            {/* <label className="font-semibold">
            SỐ DƯ: {formattedNumber(0)} VNĐ
          </label> */}
          </div>
        </div>
        <p>
          <i>
            {' '}
            * Gợi ý: chọn ô thanh toán và nhấn phím <span className="font-semibold">P</span> để tính
            tổng tiền{' '}
          </i>
        </p>
        <div className="">
          <Divider dashed style={{ margin: 10 }} />
          <ul className="flex flex-col gap-3">
            <li className="flex">
              <h2 className="w-2/3 font-semibold text-base">Tổng tiền </h2>
              <span className="w-1/3 text-end">{formattedNumber(tongGiaTien)} VNĐ</span>
            </li>
            <li className="flex">
              <h2 className="w-2/3 font-semibold text-base">Tổng thanh toán </h2>
              <span className="w-1/3 text-end text-blue-500 font-semibold">
                {formattedNumber(totalPay)} VNĐ
              </span>
            </li>
          </ul>
          <div className="mt-10 flex justify-end">
            <Button
              disabled={tongGiaTien > totalPay}
              onClick={formik.handleSubmit}
              type="primary "
              className="w-full"
            >
              Thanh toán
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default PayModal

import React, { useEffect, useState, useRef } from 'react'
import * as typeAction from '../../../../store/constants/constants'
import {
  Tabs,
  Segmented,
  Menu,
  Table,
  ConfigProvider,
  Button,
  Space,
  Input,
  notification,
} from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import Highlighter from 'react-highlight-words'
import {
  fetchListChiDinhCĐHA_Action,
  fetchListChiDinhKham_Action,
  fetchListChiDinhXetNghiem_Action,
  getListGroupCĐHA_Action,
  groupXetNghiem_Action,
  groupsChiDinhKham_Action,
  postApplyMemberCardAction,
  getListXetNghiemTiepNhanAction,
  getListCDHATiepNhanAction,
} from '../../../../store/actions/receiveAction'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import ToastCus from '../../../../utils/Toast'
const idnhomDVKham = [1, 2, 3, 6, 21, 22, 24, 25, 42, 46] //đi khám không tính đo khúc xạ, không có 4

const TabsChiDinh = ({ formPay, setFormPay, ArrXN, ArrCĐHA, ArrKB, newArr }) => {
  const XN = 'Xét nghiệm'
  const KB = 'Khám bệnh'
  const CĐHA = 'Chẩn đoán hình ảnh'
  const {
    groupsChiDinh,
    ChiDinh,
    infoBN,
    UuDaiTaiKham,
    discountArr,
    infoChuongTrinhGiamGiaDoiTac,
    memberCard,
  } = useSelector((state) => state.receiveReducer)
  const [valueSegmented, setValueSegmented] = useState(KB)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const branch = JSON.parse(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const dispatch = useDispatch()
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) => text,
    // searchedColumn === dataIndex ? (
    //   <Highlighter
    //     highlightStyle={{
    //       backgroundColor: '#ffc069',
    //       padding: 0,
    //     }}
    //     searchWords={[searchText]}
    //     autoEscape
    //     textToHighlight={text ? text.toString() : ''}
    //   />
    // ) : (
    //   text
    // ),
  })
  const [api, contextHolder] = notification.useNotification()
  const totalPayXN = ArrXN.reduce((tong, item) => tong + item.thanhToan, 0)
  const totalPayKB = ArrKB.reduce((tong, item) => tong + item.thanhToan, 0)
  const totalPayCDHA = ArrCĐHA.reduce((tong, item) => tong + item.thanhToan, 0)
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const openNotification = () => {
    api.warning({
      message: `Chọn dịch vụ`,
      description: 'Dịch vụ đã được chọn!',
      placement: 'topRight',
    })
  }
  const handleMenu = ({ key }) => {
    switch (valueSegmented) {
      case KB:
        dispatch(fetchListChiDinhKham_Action(key, branch))
        break
      case XN:
        // dispatch(fetchListChiDinhXetNghiem_Action(key, branch))
        // dispatch(getListXetNghiemTiepNhanAction(branch))
        break
      case CĐHA:
        // dispatch(fetchListChiDinhCĐHA_Action(key))
        // dispatch(getListCDHATiepNhanAction(ID_COMPANY))
        break
      default:
        break
    }
  }
  const handleSetTotalPay = (namePay, value) => {
    setFormPay((prevState) => ({ ...prevState, [namePay]: value }))
  }

  const getTaiKhamStatus = (UuDaiTaiKham, id) => {
    let checkTaiKham = false
    let index = 0
    if (UuDaiTaiKham.length > 0) {
      const indexTK = UuDaiTaiKham.findIndex((item) => item.iddv === id)
      if (indexTK !== -1) {
        checkTaiKham = true
        index = indexTK
      }
    }

    return { checkTaiKham, index }
  }
  const onClickChiDinh = (id, dongia, idnhom) => {
    if (discountArr?.length > 0 || infoChuongTrinhGiamGiaDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng bỏ voucher hoặc đối tác trước khi thêm chỉ định!',
      })
      return
    }
    const DKtaiKham = (id === 741 || id === 388 || id ===606) && infoBN?.idLoaiKham === 2 // CT tái khám bên sản + lão + mắt
    switch (valueSegmented) {
      case KB:
        for (let items of ArrKB) {
          if (id === items.idChiDinh && items?.idNhomDV !== 41) {
            openNotification() // check chỉ định có được chọn hay chưa
            return
          }
          if (
            idnhomDVKham?.includes(idnhom) &&
            idnhomDVKham?.includes(items?.idNhomDV) &&
            idnhom !== items?.idNhomDV
          ) {
            // không chọn trùng dv khác nhau mà cùng là đi khám thì không cho cọn
            api.warning({
              message: `Chọn dịch vụ`,
              description: 'Dịch vụ khám loại khác đã được chọn!',
              placement: 'topRight',
            })
            return
          }
        }
        const result = getTaiKhamStatus(UuDaiTaiKham, id)
        if (DKtaiKham) {
          dispatch({
            type: typeAction.DISTPATCH_LIST_KB_RECEIVE,
            payload: { id: id, taiKham: true, ptGiam: 100 },
          })
          return
        }
        if (result.checkTaiKham) {
          const { ptgiamgia } = UuDaiTaiKham[result.index]
          dispatch({
            type: typeAction.DISTPATCH_LIST_KB_RECEIVE,
            payload: { id: id, taiKham: true, ptGiam: ptgiamgia },
          })
        } else if (memberCard?.idthe && memberCard?.idbn === infoBN?.idbn) {
          dispatch(
            postApplyMemberCardAction({
              idthe: memberCard.idthe,
              iddv: id,
              tiendichvu: dongia,
              loaithedichvu: 1,
            }), // Loại thẻ dịch vụ (idLoaiDV === loaithedichvu) : (1 - dv khám bệnh, 2 - dv xét nghiệm, 3 - dv cdha)
          )
        } else {
          dispatch({
            type: typeAction.DISTPATCH_LIST_KB_RECEIVE,
            payload: { id: id, taiKham: false, ptGiam: 0 },
          })
        }
        break
      case XN:
        for (let items of ArrXN) {
          if (id === items.idChiDinh) {
            openNotification()
            return
          }
        }
        if (memberCard?.idthe && memberCard?.idbn === infoBN?.idbn) {
          dispatch(
            postApplyMemberCardAction({
              idthe: memberCard.idthe,
              iddv: id,
              tiendichvu: dongia,
              loaithedichvu: 2,
            }), // Loại thẻ dịch vụ (idLoaiDV === loaithedichvu) : (1 - dv khám bệnh, 2 - dv xét nghiệm, 3 - dv cdha)
          )
        } else {
          dispatch({
            type: typeAction.DISTPATCH_LIST_XN_RECEIVE,
            payload: id,
          })
        }

        break
      case CĐHA:
        for (let items of ArrCĐHA) {
          if (id === items.idChiDinh) {
            openNotification()
            return
          }
        }
        if (memberCard?.idthe && memberCard?.idbn === infoBN?.idbn) {
          dispatch(
            postApplyMemberCardAction({
              idthe: memberCard.idthe,
              iddv: id,
              tiendichvu: dongia,
              loaithedichvu: 3,
            }), // Loại thẻ dịch vụ (idLoaiDV === loaithedichvu) : (1 - dv khám bệnh, 2 - dv xét nghiệm, 3 - dv cdha)
          )
        } else {
          dispatch({
            type: typeAction.DISTPATCH_LIST_CDHA_RECEIVE,
            payload: id,
          })
        }
        break
      default:
        break
    }
  }
  const restChiDinh = () => {
    dispatch({
      type: typeAction.DISPATCH_CHI_DINH,
      payload: null,
    })
  }
  const reLoadXetNghiem = () => {
    if (valueSegmented === XN) dispatch(getListXetNghiemTiepNhanAction(branch))
  }
  // chọn loại chỉ định
  const handleChangeSegmented = (value) => {
    setValueSegmented(value)
    if (value === KB) {
      restChiDinh()
      dispatch(groupsChiDinhKham_Action(branch))
    }
    if (value === XN) {
      // restChiDinh()
      dispatch(getListXetNghiemTiepNhanAction(branch))
      // dispatch(groupXetNghiem_Action(branch))
    }
    if (value === CĐHA) {
      // restChiDinh()
      dispatch(getListCDHATiepNhanAction(ID_COMPANY))
      // dispatch(getListGroupCĐHA_Action())
      // restChiDinh()
    }
  }
  useEffect(() => {
    dispatch(groupsChiDinhKham_Action(branch))
  }, [])
  // set giá thanh toán theo từng chỉ định
  useEffect(() => {
    handleSetTotalPay('tienCDHA', totalPayCDHA)
  }, [ArrCĐHA])
  useEffect(() => {
    handleSetTotalPay('tienDichVu', totalPayKB)
  }, [ArrKB])
  useEffect(() => {
    handleSetTotalPay('tienXetNghiem', totalPayXN)
  }, [ArrXN])
  useEffect(() => {
    // lây danh sách dịch vụ khám của nhóm chỉ định đầu tiên
    if (groupsChiDinh && groupsChiDinh.length !== 0) {
      if (valueSegmented === KB) {
        dispatch(fetchListChiDinhKham_Action(groupsChiDinh[0].idNhom, branch))
      }
      if (valueSegmented === XN) {
        // dispatch(fetchListChiDinhXetNghiem_Action(groupsChiDinh[0].idnhomxn, branch))
      }
      if (valueSegmented === CĐHA) {
        // dispatch(fetchListChiDinhCĐHA_Action(groupsChiDinh[0].idnhomcdha))
      }
    }
  }, [groupsChiDinh])

  const columnsKB = [
    {
      title: 'Dịch vụ',
      dataIndex: 'dv',
      key: 'dv',
      ...getColumnSearchProps('dv'),
      sorter: {
        compare: (a, b) => {
          return (a?.dv ?? '').localeCompare(b?.dv ?? '')
        },
      },
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
      width: 90,
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (Number(a?.dongia) ?? 0) - (Number(b?.dongia) ?? 0)
        },
      },
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: 50,
      align: 'center',
    },
  ]
  const columns = [
    {
      title: 'Dịch vụ',
      dataIndex: 'dv',
      key: 'dv',
      sorter: {
        compare: (a, b) => {
          return (a?.dv ?? '').localeCompare(b?.dv ?? '')
        },
      },
      ...getColumnSearchProps('dv'),
    },
    {
      title: <p className="text-sm">Nhóm</p>,
      dataIndex: 'tennhom',
      key: 'tennhom',
      width: 140,
      align: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.tennhom ?? '').localeCompare(b?.tennhom ?? '')
        },
      },
      className: 'text-xs',
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
      width: 90,
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (Number(a?.dongia) ?? 0) - (Number(b?.dongia) ?? 0)
        },
      },
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: 50,
      align: 'center',
    },
  ]
  return (
    <div className="flex flex-col gap-5">
      {contextHolder}
      <Segmented
        onChange={handleChangeSegmented}
        // onClick={reLoadXetNghiem}
        options={[KB, XN, CĐHA]}
        block
      />
      <div className="flex">
        {valueSegmented === KB && (
          <div className="w-1/3 overflow-auto h-[260px]">
            <Menu
              onClick={handleMenu}
              mode="inline"
              defaultSelectedKeys={['1']}
              items={groupsChiDinh?.map((items) => ({
                label: items?.tenNhom,
                key: items?.idNhom,
              }))}
            />
            {/* <Menu
          onClick={handleMenu}
          mode="inline"
          defaultSelectedKeys={['1']}
          items={
            valueSegmented === KB
              ? groupsChiDinh?.map((items) => ({
                label: items?.tenNhom,
                key: items?.idNhom,
              }))
              : valueSegmented === XN
                ? groupsChiDinh?.map((items) => ({
                  label: items?.tennhom,
                  key: items?.idnhomxn,
                }))
                : groupsChiDinh?.map((items) => ({
                  label: items?.tennhom,
                  key: items?.idnhomcdha,
                }))
          }
        /> */}
          </div>
        )}
        <div className={valueSegmented === KB ? 'w-2/3' : 'w-full'}>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
      
            }}
          >
            <Table
              scroll={{
                y: 225,
              }}
              pagination={false}
              bordered
              columns={valueSegmented === KB ? columnsKB : columns}
              dataSource={ChiDinh?.map((items) => ({
                dv: items.tenChiDinh,
                price: formatNumberVND(items.donGia ?? items.dongia ?? 0),
                dongia: items.donGia ?? items.dongia ?? 0,
                tennhom: (items?.tennhom ?? items?.tenNhom)
                  ?.toLowerCase()
                  ?.replace('xét nghiệm', '')
                  ?.trim()
                  .toUpperCase(),
                action: (
                  <PlusOutlined
                    onClick={() => {
                      onClickChiDinh(items.idChiDinh, items.donGia ?? items.dongia, items?.idNhomDV)
                    }}
                    className="text-blue-500 cursor-pointer"
                  />
                ),
              }))}
              className="w-full"
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  )
}

export default TabsChiDinh

import { Modal, Input, Select, message, Checkbox } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useFormik } from 'formik'
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { branchNhapKhoByCompany, getBranchNhapKho } from '../../../../store/actions/NhapKhoAction'
import * as typeAction from '../../../../store/constants/constants'
import { nhanvienService } from '../../../../services/nhanvien/nhanvienService'
import { AddUserSchema } from '../../../../schemas/AddUserSchema'
import { branchService } from '../../../../services/branch/branchService'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { addUser } from '../../../../store/actions/userAction'
import moment from 'moment'
import _ from 'lodash'
import 'moment/locale/vi'
moment.locale('vi')

const CreateUser = ({ cancelModalAdd, isModal, typeUser }) => {
  const dispatch = useDispatch()
  const { listCompany } = useSelector((state) => state.companyReducer)
  const id_company = Number(localStorage.getItem('id_company'))
  const [staff, setStaff] = useState([])
  const [branch, setBranch] = useState(null)
  const [role, setRole] = useState()
  const [listNhomQuyen, setlistNhomQuyen] = useState([])

  const { infoUser } = useSelector((state) => state.userReducer)
  const [ipmaclogin, setIpmacLogin] = useState() // Trạng thái lưu trữ kết quả đăng nhập IP MAC

  // const loggedInUserId = infoUser?.idnv
  // const loggedInUserName = infoUser?.tenNV

  const handleSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }

  // //------ lấy dánh sách nhân viên ------------
  // const fetcchStaffs = async (keyword) => {
  //   const result = await nhanvienService.getStaffsChuaTaoTK(keyword)
  //   // const filteredStaff = result.data.filter(staff => staff.id !== loggedInUserId)
  //   setStaff(result.data)
  // }

  //-------- lấy danh sách nhân viên -------//
  const fetchListNhanVien = async (keyword) => {
    try {
      const result = await nhanvienService.getStaffsChuaTaoTK(keyword)
      setStaff(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        fetchListNhanVien(keyword)
      }
    }, 300),
    [],
  )
  //--------- lấy danh sách vai trò  ----------//
  const fetchRule = async () => {
    try {
      const result = await nhanvienService.getRulerUser()
      setRole(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  //--------- lấy danh sách nhóm  ----------//
  const getListNhomQuyen = async () => {
    try {
      const { data } = await phanQuyenService.getListNhomQuyen(null) //keyword
      setlistNhomQuyen(data?.filter((item) => item?.sudung))
    } catch (error) {
      console.log(error)
    }
  }
  //---------- lấy danh sách chi nhánh theo công ty ---------//
  const fetchBranch = async (id) => {
    try {
      const result = await branchService.getListBranch(id)
      setBranch(result.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = (value) => {
    const now = moment()
    value.ngayTao = now.format('YYYY-MM-DDTHH:mm:ss')
    value.nguoiTao = infoUser?.tenNV
    // value.nguoiTao = loggedInUserName
    // Cập nhật giá trị isLock dựa vào trạng thái của checkbox
    value.isLock = formik.values.isLock ? 0 : 1
    // Cập nhật IP MAC trước khi dispatch action addUser
    // if (ipmaclogin) {
    //   value.ipmac = ipmaclogin
    dispatch(addUser(value, restStore))
    // } else {
    //   formik.setFieldError('ipmac', 'Vui lòng cập nhật IP MAC')
    // }
  }
  const restStore = () => {
    cancelModalAdd()
    formik.resetForm()
    setStaff([])
  }
  const formik = useFormik({
    initialValues: {
      taiKhoan: null,
      matKhau: null,
      passwordConfirmation: null,
      idnv: null,
      idChiNhanh: null,
      // nguoiTao: user?.taiKhoan || ,
      // ngayTao: '',
      idNhom: null,
      IDCT: id_company, // set trạng thái mặc đình là 1 >> CTy y tế chấn văn
      isLock: 0,
      idndloai: null,
      ipmac: null,
      idnqht: 0, // nhóm quyền hệ thống
    },
    validationSchema: AddUserSchema,
    onSubmit: (value) => handleSubmit(value),
  })
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchRule()
    fetchBranch(id_company)
    getListNhomQuyen()
  }, [])

  const handleLockChange = (e) => {
    const isLock = e.target.checked ? 0 : 1
    formik.setFieldValue('isLock', isLock)
  }
  // Cập nhật IP MAC khi thay đổi input
  const handleIPMACChange = (e) => {
    // Kiểm tra nếu giá trị nhập vào là rỗng thì set giá trị cho ipmaclogin là null
    const value = e.target?.value?.trim()
    setIpmacLogin(value === '' ? null : value)
    formik.setFieldValue('ipmac', value)
  }

  return (
    <Modal
      open={isModal}
      onCancel={restStore}
      onOk={formik.handleSubmit} // Sử dụng formik.handleSubmit khi người dùng bấm nút "Tạo"
      okText={'Tạo'}
      cancelText="Hủy"
      title="Tạo người dùng mới"
    >
      <div className="mt-8">
        <form className="flex flex-col gap-2">
          <div>
            <label className="font-semibold">
              <span className="text-red-500">*</span> Tài khoản:
            </label>
            <Input
              name="taiKhoan"
              type="text"
              autoComplete="off"
              status={formik.errors.taiKhoan ? 'error' : ''}
              value={formik.values.taiKhoan}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div>
            <label className="font-semibold">
              <span className="text-red-500">*</span> Mật khẩu:
            </label>
            <Input
              name="matKhau"
              type="password"
              autoComplete="new-password"
              status={formik.errors.matKhau ? 'error' : ''}
              value={formik.values.matKhau}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div>
            <label className="font-semibold">Nhập lại mật khẩu:</label>
            <Input
              name="passwordConfirmation"
              type="password"
              status={formik.errors.passwordConfirmation ? 'error' : ''}
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.passwordConfirmation && formik.touched.passwordConfirmation && (
              <span className="text-red-500  text-base">Hãy nhập lại mật khẩu!</span>
            )}
          </div>
          <div>
            <label className="font-semibold">Công ty:</label>
            <Select
              showSearch
              value={formik.values.IDCT}
              onChange={(value) => {
                fetchBranch(value)
                formik.setFieldValue('idChiNhanh', null)
                formik.setFieldValue('IDCT', value)
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div>
            <label className="font-semibold">
              <span className="text-red-500">*</span> Chi nhánh:
            </label>
            <Select
              status={formik.errors.idChiNhanh ? 'error' : ''}
              value={formik.values.idChiNhanh}
              showSearch
              onChange={handleSelected('idChiNhanh')}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={branch?.map((items) => ({
                label: items.tenChiNhanh,
                value: items.idChiNhanh,
              }))}
              className="w-full"
            />
          </div>
          <div>
            <label className="font-semibold">
              <span className="text-red-500">*</span>Vai trò:
            </label>
            <Select
              status={formik.errors.idNhom ? 'error' : ''}
              value={formik.values.idNhom}
              onChange={handleSelected('idNhom')}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={role?.map((items) => ({
                label: items.tenNhom,
                value: items.idNhom,
              }))}
              className="w-full"
            />
          </div>
          <div>
            <label className="font-semibold">
              <span className="text-red-500">*</span>Nhóm quyền:
            </label>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              className="w-full"
              status={formik.errors.idnqht ? 'error' : ''}
              value={formik.values.idnqht}
              onChange={handleSelected('idnqht')}
              options={[
                {
                  key: 0,
                  value: 0,
                  label: 'Tùy chỉnh',
                },
                ...listNhomQuyen?.map((item) => ({
                  key: item?.idnqht,
                  value: item?.idnqht,
                  label: item?.tennhomquyen,
                })),
              ]}
            />
          </div>
          <div>
            <label className="font-semibold">Loại:</label>
            <Select
              value={formik.values.idndloai}
              onChange={handleSelected('idndloai')}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={typeUser?.map((items) => ({
                label: items.tenLoai,
                value: items.idNguoiDungLoai,
              }))}
              className="w-full"
            />
          </div>
          <div>
            <label className="font-semibold">
              <span className="text-red-500">*</span>
              Nhân viên:
            </label>
            <Select
              status={formik.errors.idnv ? 'error' : ''}
              value={formik.values.idnv}
              onChange={handleSelected('idnv')}
              // showSearch
              // filterOption={(input, option) =>
              //   option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              // }
              showSearch
              allowClear
              onSearch={debounceDropDown}
              filterOption={false}
              notFoundContent={null}
              placeholder={'Tìm tên nhân viên...'}
              suffixIcon={<SearchOutlined className=" " />}
              options={staff?.map((items) => ({
                label: items.tennhanvien,
                value: items.idnv,
                manv: items.manhanvien,
              }))}
              optionRender={(options) => (
                <ul className="flex">
                  <li className="w-3/4">{options.data.label}</li>
                  <li className="w-1/4 border-x text-center">{options.data.manv}</li>
                </ul>
              )}
              className="w-full"
            />
          </div>
          <div className="flex items-center gap-4">
            <label className="font-semibold">Hiệu lực:</label>
            <Checkbox
              checked={formik.values.isLock === 0} // Đảo ngược giá trị để checkbox phản ánh đúng trạng thái
              onChange={handleLockChange}
              className="mr-2"
            >
              {formik.values.isLock === 0 ? 'Kích hoạt tài khoản' : 'Không kích hoạt tài khoản'}
            </Checkbox>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default CreateUser

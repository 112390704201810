import { SearchOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Input, Space, Table, Tabs } from 'antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import { https } from '../../../../services/apiService'
import DoKhucXa from './DoKhucXa'
import SieuAmBN from './SieuAmBN'
import 'moment/locale/vi'
moment.locale('vi')

function ResultCLS(props) {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoBN } = useSelector((state) => state.traCuuReducer)
  const [listLichSu, setListLichSu] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isQuyenXem, setisQuyenXem] = useState(false)
  const searchInput = useRef(null)

  useEffect(() => {
    ;(async () => {
      if (infoBN?.idbn) {
        try {
          await phanQuyenService.putCheckQuyenThaoTac(
            infoUser?.taiKhoan ?? userInfo?.taiKhoan,
            'QHT014',
          )
          setisQuyenXem(true)
        } catch (error) {
          setisQuyenXem(false)
          console.error('Error : ', error)
          if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
            dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
            return
          }
          ToastCus.fire({
            icon: 'error',
            title: error?.response?.data?.message ?? 'Thao tác thất bại!',
          })
        }
      }
    })()
  }, [infoBN])
  useEffect(() => {
    ;(async () => {
      if (infoBN?.idbn) {
        setLoading(true)
        try {
          const { data } = await https.get(
            `BenhNhanXetNghiem/GetLichSuBenhNhanXetNghiem_CheckbacSi?idbn=${infoBN?.idbn}&idChiNhanh=${PkDangNhap?.idChiNhanh}&idNV=${infoUser.idnv}`,
          )
          setListLichSu(data)
        } catch (error) {
          console.error('Error : ', error)
        } finally {
          setLoading(false)
        }
      }
    })()
  }, [infoBN, PkDangNhap, infoUser])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columnsXN = [
    {
      title: 'Ngày XN',
      dataIndex: 'ngay',
      key: 'ngay',
      align: 'center',
      className: 'text-sm',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm') : ''),
      width: 150,
    },
    {
      title: 'Mã phiếu XN',
      dataIndex: 'phieu',
      key: 'phieu',
      align: 'left',
      className: 'text-sm',
      width: 150,
      ...getColumnSearchProps('phieu'),
    },
    {
      title: 'Tên XN',
      dataIndex: 'tenxetnghiem',
      key: 'tenxetnghiem',
      align: 'left',
      className: 'text-sm',
    },
    {
      title: 'Kết quả',
      dataIndex: 'ketqua',
      key: 'ketqua',
      align: 'left',
      className: 'text-sm',
    },
    {
      title: 'Chỉ số',
      dataIndex: 'chisobinhthuong',
      key: 'chisobinhthuong',
      align: 'left',
      className: 'text-sm',
    },
    {
      title: 'Đơn vị',
      dataIndex: 'donvigui',
      key: 'donvigui',
      align: 'left',
      className: 'text-sm',
    },
    {
      title: 'Bác sĩ chỉ định',
      dataIndex: 'bschidinh',
      key: 'bschidinh',
      align: 'left',
      className: 'text-sm',
    },
    {
      title: 'KTV XN',
      dataIndex: 'ktvxn',
      key: 'ktvxn',
      align: 'left',
      className: 'text-sm',
    },
    {
      title: 'File KQ đính kèm',
      dataIndex: 'files',
      key: 'files',
      align: 'left',
      className: 'text-sm',
      render: (text, record) => {
        return record.file.map((item, idx) => (
          <a href={item} className="text-blue-500 block p-2" target="_blank" rel="noreferrer">
            File {idx + 1}
          </a>
        ))
      },
    },
  ]

  return (
    <div>
      <div className="flex gap-2">
        <div className="h-full w-full">
          {!isQuyenXem && infoBN?.idbn && (
            <div className="flex justify-center items-center text-lg font-semibold pt-10">
              Tài khoản không có quyền thao tác!
            </div>
          )}
          <Tabs
            className={isQuyenXem ? '' : 'hidden'}
            defaultActiveKey="1"
            items={[
              {
                label: 'Xét nghiệm',
                key: 1,
                children: (
                  <div>
                    <h2 className="text-gray-500 font-semibold text-lg mb-2">Lịch sử xét nghiệm</h2>
                    <div className="mt-2">
                      <ConfigProvider
                        theme={{
                          token: {
                            padding: 5,
                          },
                          components: {
                            Table: {
                              rowHoverBg: '#ecf0f1',
                              headerBg: '#e6e6e6',
                              footerBg: '#e6e6e6',
                              borderColor: '#BABABA',
                            },
                          },
                        }}
                      >
                        <Table
                          bordered
                          scroll={{
                            y: 650,
                          }}
                          pagination={false}
                          columns={columnsXN}
                          dataSource={listLichSu.map((item) => ({
                            ...item,
                            file: item.fileketqua
                              ? item.fileketqua
                                  .split(';')
                                  .filter((val) => val !== '')
                                  .map((item) => item)
                              : [],
                          }))}
                          loading={loading}
                        />
                      </ConfigProvider>
                    </div>
                  </div>
                ),
              },
              {
                label: 'Siêu âm',
                key: 2,
                children: (
                  <div>
                    <SieuAmBN />
                  </div>
                ),
              },
              {
                label: 'Đo khúc xạ',
                key: 3,
                children: (
                  <div>
                    <DoKhucXa />
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  )
}

ResultCLS.propTypes = {}

export default React.memo(ResultCLS)

import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import LayoutApp from '../../../../HOCs/LayoutApp'
import moment from 'moment'
import {
  Button,
  ConfigProvider,
  DatePicker,
  Pagination,
  Table,
  Select,
  Tooltip,
  Input,
  Space,
} from 'antd'
import dayjs from 'dayjs'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getList_Template_SMS } from '../../../../store/actions/smsAction'
import { https } from '../../../../services/apiService'
import { cloneDeep } from 'lodash'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import * as XLSX from 'xlsx'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'

function BaoCaoSms(props) {
  const { listTemplate } = useSelector((state) => state.smsReducer)
  const [listTemplateAll, setListTemplateAll] = useState([])
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const [totalPage, setTotalPage] = useState(1)
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [templateChoosed, setTemplateChoosed] = useState('')
  const [status, setStatus] = useState('')
  const [idLoaiSMS, setIdLoaiSMS] = useState('')
  const [data, setData] = useState([])
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [rowActive, setRowActive] = useState(null)
  const [statusSend, setStatusSend] = useState([])
  const [allBrandName, setAllBrandName] = useState([])
  const [brandName, setBrandName] = useState('')
  const [allLoaiSMS, setAllLoaiSMS] = useState([])

  useEffect(() => {
    dispatch(getList_Template_SMS())
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('SMSTaiKhoan/GetAll')
        setAllBrandName([
          {
            value: '',
            label: 'Tất cả',
          },
          ...data.map((item) => ({ value: item.idtaikhoan, label: item.brandname })),
        ])
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('SMSTrangThai/GetTrangThaiSMS')
        setStatusSend([
          {
            value: '',
            label: 'Tất cả',
          },
          ...data.map((item) => ({ value: item.idtt, label: item.trangthai })),
        ])
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('SMSLoaiSMS/GetAllLoaiSMS')
        setAllLoaiSMS([
          {
            value: '',
            label: 'Tất cả',
          },
          ...data.map((item) => ({ value: item.idloaisms, label: item.loaisms })),
        ])
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    if (listTemplate) {
      const cloneListTemplate = cloneDeep(listTemplate)
      setListTemplateAll([
        {
          idtp: '',
          label: 'Tất cả',
          matp: 'Tất cả',
          noidungmau: '',
        },
        ...cloneListTemplate,
      ])
    }
  }, [listTemplate])

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const filter = useMemo(() => {
    return {
      fromDate: `?fromDate=${dateForm}`,
      toDate: `&toDate=${dateTo}`,
      trangThai: status ? `&trangThai=${status}` : '',
      idTP: templateChoosed ? `&idTP=${templateChoosed}` : '',
      idTaiKhoan: brandName ? `&idTaiKhoan=${brandName}` : '',
      idLoaiSMS: idLoaiSMS ? `&idLoaiSMS=${idLoaiSMS}` : '',
    }
  }, [dateForm, dateTo, status, templateChoosed, brandName, idLoaiSMS])

  const onLoad = async (filter) => {
    setIsLoading(true)
    try {
      const condition = Object.values(filter).filter(Boolean).join('')
      const result = await https.get(`SMSGuiTinNhan/GetDanhSachSMSByCondition${condition}`)
      setData(result.data.result)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    onLoad(filter)
  }, [])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1890ff',
              color: '#fff',
              borderColor: '#1890ff',
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 50,
      align: 'center',
      key: 'stt',
    },
    {
      title: 'Brand name',
      dataIndex: 'brandname',
      width: 150,
      align: 'center',
      key: 'brandname',
      ...getColumnSearchProps('brandname'),
    },
    {
      title: 'Mã BN',
      dataIndex: 'mabenhnhan',
      width: 150,
      // align: 'center',
      key: 'mabenhnhan',
      ...getColumnSearchProps('mabenhnhan'),
    },
    {
      title: 'Tên BN',
      dataIndex: 'tenbenhnhan',
      width: 250,
      // align: 'center',
      key: 'tenbenhnhan',
      ...getColumnSearchProps('mabenhnhan'),
    },
    {
      title: 'Điện thoại',
      dataIndex: 'sodienthoai',
      width: 150,
      align: 'center',
      key: 'sodienthoai',
      ...getColumnSearchProps('sodienthoai'),
      render: (text, record) => (record.idsms === rowActive?.idsms ? <p>{text}</p> : ''),
    },
    {
      title: 'Nội dung',
      dataIndex: 'noidung',
      // align: 'center',
      key: 'noidung',
      ...getColumnSearchProps('noidung'),
    },
    {
      title: 'Ngày gửi',
      dataIndex: 'ngaygui',
      width: 150,
      align: 'center',
      key: 'ngaygui',
      render: (text) => (text ? moment(text)?.format('DD/MM/YYYY HH:mm:ss') : '-'),
    },
    {
      title: 'Người gửi',
      dataIndex: 'tennguoigui',
      key: 'tennguoigui',
      width: 200,
      ...getColumnSearchProps('tennguoigui'),
    },
    {
      title: 'Loại SMS',
      dataIndex: 'tenloaisms',
      key: 'tenloaisms',
      width: 300,
      ...getColumnSearchProps('tenloaisms'),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'trangthai',
      width: 150,
      // align: 'center',
      key: 'trangthai',
      render: (text, record) =>
        record.trangthaisms === 1 ? (
          <p className="text-green-500">Gửi thành công</p>
        ) : (
          <p className="text-red-500">Gửi thất bại</p>
        ),
    },
  ]

  const exportToExcel = () => {
    const headers = [
      'Brand name',
      'Mã bệnh nhân',
      'Tên bệnh nhân',
      // 'Điện thoại',
      'Nội dung',
      'Ngày gửi',
      'Người gửi',
      'Trạng thái',
    ]

    const formattedData = data.map((item) => ({
      'Brand name': item.brandname,
      'Tên bệnh nhân': item.tenbenhnhan,
      'Mã bệnh nhân': item.mabenhnhan,
      // 'Điện thoại': item.sodienthoai,
      'Nội dung': item.noidung,
      'Ngày gửi': moment(item.ngaygui).format('DD/MM/YYYY HH:mm:ss'),
      'Người gửi': item.tennguoigui,
      'Trạng thái': item.trangthaisms === 1 ? 'Gửi thành công' : 'Gửi thất bại',
    }))

    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, 'Báo cáo sms.xlsx')
  }

  return (
    <>
      <div className="h-full w-full bg-[#EBEBEB] p-2 border ">
        <div
          className="border mt-2 rounded-md bg-white"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <div className="flex gap-2 justify-between border-dotted border-b-0 p-4">
            <div className="flex gap-2 justify-between w-full items-center">
              <Select
                className="w-36"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={allBrandName?.map(({ label, value }) => ({
                  label,
                  value,
                }))}
                value={brandName}
                onChange={(value) => {
                  setBrandName(value)
                }}
              />
              <Select
                className="w-36"
                showSearch
                options={listTemplateAll?.map(({ idtp, matp, noidungmau }) => {
                  return {
                    label: (
                      <Tooltip title={noidungmau} placement="rightTop">
                        <div className="truncate max-w-[580px]">{matp}</div>
                      </Tooltip>
                    ),
                    value: idtp,
                    // Store original text for filtering
                    searchText: matp,
                  }
                })}
                filterOption={(input, option) =>
                  option?.searchText?.toLowerCase().includes(input.toLowerCase())
                }
                value={templateChoosed}
                onChange={(value) => {
                  setTemplateChoosed(value)
                }}
              />

              <Select
                className="w-64"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={statusSend?.map(({ label, value }) => ({
                  label,
                  value,
                }))}
                value={status}
                onChange={(value) => {
                  setStatus(value)
                }}
              />

              <Select
                className="w-96"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={allLoaiSMS?.map(({ label, value }) => ({
                  label,
                  value,
                }))}
                value={idLoaiSMS}
                onChange={(value) => {
                  setIdLoaiSMS(value)
                }}
              />

              <div className="flex items-center gap-2">
                <DatePicker
                  className="w-32"
                  allowClear={false}
                  defaultValue={dayjs(now)}
                  maxDate={dayjs(now)}
                  onChange={handleDatePicker('form')}
                  format={dateFormat}
                />
                <span className="font-semibold text-lg">-</span>
                <DatePicker
                  className="w-32"
                  allowClear={false}
                  defaultValue={dayjs(now)}
                  maxDate={dayjs(now)}
                  onChange={handleDatePicker('to')}
                  format={dateFormat}
                />
              </div>
              <div className="flex gap-3 mr-auto">
                <IconButton
                  onClick={() => onLoad(filter)}
                  color="primary"
                  aria-label="delete"
                  size="small"
                >
                  <SyncIcon />
                </IconButton>
              </div>
              <div className="ml-auto">
                <Button
                  onClick={exportToExcel}
                  type="text"
                  size="middle"
                  className="text-green-700 p-1"
                  icon={<ImportExportIcon />}
                  loading={loadingExport}
                  disabled={loadingExport}
                >
                  Xuất Excel
                </Button>
              </div>
            </div>
          </div>
          <div className="p-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                  fontSize: 14,
                },
                components: {
                  Table: {
                    cellFontSize: 13,
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                pagination={{
                  pageSize: 20,
                  showSizeChanger: false,
                }}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      setRowActive(record)
                    }, // click row
                  }
                }}
                scroll={{ x: 2500, y: 715 }}
                loading={isLoading}
                bordered
                dataSource={data.map((item, idx) => ({
                  ...item,
                  key: item.id,
                  stt: idx + 1,
                }))}
                columns={columns}
              />
            </ConfigProvider>
            {/* <div className="flex justify-end">
              <Pagination
                className="mt-5"
                showSizeChanger={false}
                current={+useSearch.get('page')}
                simple
                defaultCurrent={1}
                pageSize={20}
                total={20 * totalPage}
                onChange={(page, pagesize) => {
                  //   handleChangePage(search, dateFrom, dateTo, company, chiNhanh, page)
                }}
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

BaoCaoSms.propTypes = {}

export default BaoCaoSms

import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { xuatSuDungService } from '../../services/xuatSuDung/xuatSuDungService'
import ToastCus from '../../utils/Toast'

export function* xuatSuDungSaga() {
  // POST PHIẾU XUẤT SỬ DỤNG
  yield takeLatest(
    typeAction.POST_PHIEU_XUAT_SU_DUNG,
    function* postPhieuXuatSuDungSaga({ type, form, list, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data: infoPhieu } = yield call(() => xuatSuDungService.postPhieuXuatSuDung(form))
        const formatData = list?.map((item) => ({
          idthuoc: item?.idthuoc,
          // tenbietduoc: item?.tenbietduoc,
          // tenhoatchat: item?.tenhoatchat,
          solo: item?.solo,
          handung: item?.handung,
          sohoadon: item?.sohoadon,
          soluong: item?.soluong,
          // masodangky: item?.masodangky,
          ghichu: item?.ghichu,
          stt: item?.stt,
          // mathuoc: item?.mathuoc,
          // dvt: item?.dvt,
          idxuatsd: infoPhieu?.data?.idxuatsd,
        }))
        yield call(() => xuatSuDungService.postListVTHH(formatData))
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}

import { Button, Input, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React, { useCallback, useState } from 'react'
import TextField from '@mui/material/TextField'
import { SearchOutlined, CreditCardOutlined } from '@ant-design/icons'
import moment from 'moment'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { searchBN_Action } from '../../../../store/actions/receiveAction'
import { addNewPaymentCard } from '../../../../store/actions/cardAction'
import 'moment/locale/vi'
moment.locale('vi')
const AddNewCard = ({ modalAdd, setModalAdd }) => {
  const today = moment()
  const IDLOAITHE = 1
  const { listBN } = useSelector((state) => state.receiveReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [infoBN, setInfoBN] = useState(null)
  const idBranch = Number(localStorage.getItem('BRANH_LOGIN'))
  const dispatch = useDispatch()
  const cancelModal = () => {
    setModalAdd(false)
  }

  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        dispatch(searchBN_Action(keyword?.trim()))
      }
    }, 300),
    [],
  )
  const handleInfoBN = (value, option) => {
    setInfoBN(option)
  }
  const handleResetForm = () => {
    formik.resetForm()
    setInfoBN(null)
    cancelModal()
  }
  const formik = useFormik({
    initialValues: {
      mathe: null,
      nguoitao: '',
    },
    onSubmit: (values, actions) => {
      values.nguoitao = infoUser?.tenNV
      dispatch(addNewPaymentCard(IDLOAITHE, infoBN?.value, idBranch, values, handleResetForm))
    },
  })
  return (
    <Modal footer={null} open={modalAdd} onCancel={cancelModal} title={'Tạo thẻ thanh toán'}>
      <Select
        onSearch={debounceDropDown}
        value={null}
        onChange={handleInfoBN}
        filterOption={false}
        notFoundContent={null}
        placeholder="Tìm kiếm Tên BN, Mã BN, SĐT, CCCD, Mã TTV..."
        suffixIcon={<SearchOutlined className=" " />}
        options={listBN?.map((items) => ({
          label: items?.tenbenhnhan,
          value: items?.idbn,
          MaBN: items?.mabenhnhan,
          ngaySinh: items?.ngaysinh ? moment(items?.ngaysinh).format('DD/MM/YYYY') : '',
        }))}
        optionRender={(options) => (
          <ul className="flex">
            <li className="w-[25%]">{options.data.MaBN}</li>
            <li className="w-[55%] border-x pl-2">{options.data.label}</li>
            <li className="w-[20%] text-center">{options.data.ngaySinh}</li>
          </ul>
        )}
        showSearch
        allowClear
        className="w-full"
      />
      <div className="p-2 border mt-2 rounded-lg">
        <CreditCardOutlined
          style={{ fontSize: 36 }}
          className="text-gray-400 p-2 bg-[#F0F0F5] rounded-base"
        />
        <p className="bg-[#F0F0F5] h-10 mt-2 p-2 font-semibold text-base flex items-center justify-start rounded-lg">
          {formik.values.mathe}
        </p>
        <p className="bg-[#F0F0F5] h-10 mt-2 p-2 font-semibold text-base flex items-center justify-start rounded-lg">
          <span className="text-gray-400 mr-5">NGÀY TẠO</span> {today.format('DD/MM/YYYY')}
        </p>
        <div className="flex gap-5 w-full ">
          <p className="bg-[#F0F0F5] w-2/3 h-10 mt-2 p-2 font-semibold text-base flex items-center justify-start rounded-lg">
            {infoBN?.label}
          </p>
          <p className="bg-[#F0F0F5] w-1/3 h-10 mt-2 p-2 font-semibold text-base flex items-center justify-start rounded-lg">
            {infoBN?.MaBN}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-2 mt-5">
        <TextField
          size="small"
          onChange={formik.handleChange}
          name="mathe"
          value={formik.values.mathe}
          id="outlined-basic"
          label="Mã thẻ"
          variant="outlined"
        />
      </div>
      <Button
        onClick={formik.handleSubmit}
        disabled={!infoBN || !formik.values.mathe}
        className="w-full"
        size="large"
        type="primary mt-5"
      >
        Tạo thẻ
      </Button>
    </Modal>
  )
}

export default AddNewCard

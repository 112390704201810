import { Image } from 'antd'
import React from 'react'
const Images = ({ listImage }) => {
  const images = listImage?.split(';')
  return (
    <div className=" h-full">
      <div className="flex flex-wrap gap-2">
        {images ? images.filter((item) => item).map((item, idx) => (
          <div key={item.idx} className="border rounded-md w-[150px] h-[150px] overflow-hidden  ">
            <div className="overflow-hidden">
              <Image loading='lazy' width={150} height={150} className='object-cover' src={item} />
            </div>
          </div>
        )) : null}
      </div>
    </div>
  )
}

export default Images
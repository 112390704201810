import React from 'react'
import { Tabs } from 'antd'
import HoaHongSan from './HoaHongSan'
import HoaHongMat from './HoaHongMat'

function HoaHongThuThua(props) {
  return (
    <div className="p-2  bg-[#E8E8E8] h-full">
      <div
        className="bg-white rounded-md border p-2 h-full"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <Tabs type="card" className="h-full">
          <Tabs.TabPane tab="Chuyên khoa Sản" key={1}>
            <HoaHongSan />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Chuyên khoa Mắt" key={2}>
            <HoaHongMat />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  )
}
export default HoaHongThuThua

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from 'antd'
import Images from './Images'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
const ImageSA = ({ image, setImage, listImagePrint, setListImagePrint }) => {
  const dispatch = useDispatch()
  const { infoBNDienTim } = useSelector((state) => state.dienTimReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const [isQuyenHinhAnh, setisQuyenHinhAnh] = useState(false)
  const checkQuyenHinhAnh = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT341',
      )
      setisQuyenHinhAnh(true)
    } catch (error) {
      setisQuyenHinhAnh(false)
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  useEffect(() => {
    checkQuyenHinhAnh()
  }, [])
  useEffect(() => {
    checkQuyenHinhAnh()
  }, [infoBNDienTim])
  return (
    <div className="p-2 min-h-[400px]">
      {isQuyenHinhAnh ? (
        <Images image={image} setImage={setImage} />
      ) : (
        <Alert message="Tài khoản không có quyền thao tác!" type="error" showIcon />
      )}
    </div>
  )
}

export default ImageSA

import React, { useState } from 'react'
import CalendarHeader from './components/CalendarHeader'
import CalendarGrid from './components/CalendarGrid'
import { startOfMonth } from 'date-fns'

export default function Lichlamviectheonhanvien() {
  const [currentMonth, setCurrentMonth] = useState(startOfMonth(new Date()))
  const [days, setDays] = useState([])
  const [branchIds, setBranchIds] = useState([])
  const [companyIds, setCompanyIds] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [loadingGrid, setLoadingGrid] = useState(false)
  const [positions, setPositions] = useState([])
  const [selectedPosition, setSelectedPosition] = useState('') // Add state for selected position
  const [selectedChuyenKhoa, setSelectedChuyenKhoa] = useState(null)
  const [pagination, setPagination] = useState('') // Add state for pagination
  const handleBranchChange = (ids) => {
    setBranchIds(ids)
  }

  const handleCompanyChange = (ids) => {
    setCompanyIds(ids)
  }

  const handleLoad = () => {
    setLoadingGrid(true)
  }

  const handlePositionChange = (id) => {
    setSelectedPosition(id) // Update selected position
  }
  return (
    <div className="h-full w-full bg-[#EBEBEB]  p-2 ">
      <div className=" bg-white mb-4 border-dotted border-b-0  p-2 border rounded-sm">
        <CalendarHeader
          setDays={setDays}
          setCurrentMonth={setCurrentMonth}
          onBranchChange={handleBranchChange}
          onCompanyChange={handleCompanyChange}
          setSearchKeyword={setSearchKeyword}
          onLoad={setLoadingGrid}
          positions={positions}
          setPositions={setPositions}
          selectedPosition={selectedPosition} // Pass selected position to CalendarHeader
          handlePositionChange={handlePositionChange} // Pass handlePositionChange to CalendarHeader
          selectedChuyenKhoa={selectedChuyenKhoa}
          setSelectedChuyenKhoa={setSelectedChuyenKhoa}
          setPagination={setPagination}
        />
      </div>
      <div className=" bg-white  border-dotted border-b-0  p-2 border rounded-sm">
        <CalendarGrid
          currentMonth={currentMonth}
          days={days}
          branchIds={branchIds}
          companyIds={companyIds}
          searchKeyword={searchKeyword}
          onLoad={handleLoad}
          selectedPosition={selectedPosition} // Pass selected position to CalendarGrid
          selectedChuyenKhoa={selectedChuyenKhoa}
          pagination={pagination} // Pass pagination to CalendarGrid`
        />
      </div>
    </div>
  )
}

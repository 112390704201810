import { call, put, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { khachhangCRMService } from '../../services/khachhangCRM/khachhangCRMService'
import ToastCus from '../../utils/Toast'
import _ from 'lodash'

export function* khachhangCRMSaga() {
  yield takeLatest(typeAction.GET_ALL_KHACHHANG_CRM, function* allKhachHangCRM({ type, payload }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => khachhangCRMService.getAllKhachHangCRM())
      const data = _.orderBy(result.data,'tennguoitao','asc')
      data.sort((a, b) => new Date(b.ngaytao) - new Date(a.ngaytao));
      yield put({
        type: typeAction.DISPATCH_ALL_KHACHHANG_CRM,
        payload: data,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  //add
  yield takeLatest(typeAction.ADD_KHACHHANG_CRM, function* addKhachHangCRM({ type, form, onLoad }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      yield call(() => khachhangCRMService.addKhachHangCRM(form))
      onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Thêm mới thành công',
      })
    } catch (err) {
      console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: 'Thêm mới thất bại',
      })
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  //edit
  yield takeLatest(
    typeAction.EDIT_KHACHHANG_CRM,
    function* editKhachHangCRM({ type, form, onLoad }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => khachhangCRMService.editKhachHangCRM(form))
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Sửa thông tin thành công',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Sửa thông tin thất bại',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(typeAction.GET_KHACHHANG_CRM_BY_ID, function* getById({ type, id }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => khachhangCRMService.getKhachHangCRMById(id))
      yield put({
        type: typeAction.DISPATCH_INFO_KHACHHANG_CRM,
        payload: result.data.result,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
}

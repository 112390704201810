import * as typeAction from '../constants/constants'

export const postPhanAnhAction = (formData, idcn, getListPhanAnhByTrangThai) => ({
  type: typeAction.POST_PHAN_ANH_KHACH_HANG,
  payload: formData,
  idcn,
  getListPhanAnhByTrangThai,
})
export const putPhanAnhAction = (form, getListPhanAnhByTrangThai) => ({
  type: typeAction.PUT_PHAN_ANH_KHACH_HANG,
  form,
  getListPhanAnhByTrangThai,
})
export const postListXuLyAction = (
  list,
  infoPAKH,
  getInfoPhanAnh,
  getListPhanAnhKhachHang,
  setIsLoading,
) => ({
  type: typeAction.POST_LIST_XULY_PHANANH,
  list,
  infoPAKH,
  getInfoPhanAnh,
  getListPhanAnhKhachHang,
  setIsLoading,
})
export const putNhanXuLyAction = (
  infoXLPA,
  infoPAKH,
  getInfoPhanAnhVaXuLy,
  getListXuLyPhanAnh,
  setIsLoading,
) => ({
  type: typeAction.PUT_NHAN_XULY_PHANANH,
  infoXLPA,
  infoPAKH,
  getInfoPhanAnhVaXuLy,
  getListXuLyPhanAnh,
  setIsLoading,
})
export const putThongTinXuLyAction = (
  formData,
  infoXLPA,
  infoPAKH,
  getInfoPhanAnhVaXuLy,
  getListXuLyPhanAnh,
  setIsLoading,
  handleResetForm,
) => ({
  type: typeAction.PUT_THONGTIN_XULY_PHANANH,
  formData,
  infoXLPA,
  infoPAKH,
  getInfoPhanAnhVaXuLy,
  getListXuLyPhanAnh,
  setIsLoading,
  handleResetForm,
})
export const putDaXuLyPAKHAction = (
  infoPAKH,
  getInfoPhanAnh,
  getListPhanAnhKhachHang,
  setIsLoading,
) => ({
  type: typeAction.PUT_DAXULY_PHANANH,
  infoPAKH,
  getInfoPhanAnh,
  getListPhanAnhKhachHang,
  setIsLoading,
})

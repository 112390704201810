import { Input, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createLoaiHangHoaSchema } from '../../../schemas/createLoaiHangHoaSchema'
import {
  createLoaiHangHoa,
  getAllLoaiHangHoa,
  getNhomHangHoaByLoai,
} from '../../../store/actions/loaiHangHoaAction'
import moment from 'moment'
const CreateLoaiThuoc = ({ openAdd, setOpenAdd, group, keywordNhan }) => {
  // 2024-11-16T00:50:09.541Z
  const now = moment()
  const { infoUser } = useSelector((state) => state.userReducer)
  const loai = [
    { label: 'Thuốc', value: 'THUOC' },
    { label: 'Hàng hóa', value: 'HANGHOA' },
  ]
  const dispatch = useDispatch()
  const handleCancel = () => {
    setOpenAdd(false)
  }
  const handleSubmit = (value) => {
    dispatch(createLoaiHangHoa(value, onReset))
  }
  const onChangeSelected = (value) => {
    formik.setFieldValue('loai', value)
  }
  const onReset = () => {
    dispatch(getAllLoaiHangHoa(group, keywordNhan))

    formik.resetForm()
    handleCancel()
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phanloai: '',
      loai: '',
      ghichu: '',
      nguoiTao: infoUser?.tenNV,
      ngayTao: now.format('YYYY-MM-DD'),
    },
    validationSchema: createLoaiHangHoaSchema,
    onSubmit: (value) => handleSubmit(value),
  })
  return (
    <Modal
      onOk={formik.handleSubmit}
      okText="Tạo"
      cancelText="Huỷ bỏ"
      open={openAdd}
      onCancel={handleCancel}
      title={
        <p className="font-semibold text-xl w-full text-center text-blue-500">TẠO LOẠI HÀNG HÓA</p>
      }
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
            Tên Phân loại <span className="text-red-500">(*)</span>
          </label>
          <Input
            name="phanloai"
            onChange={formik.handleChange}
            status={formik.errors.phanloai && formik.touched.phanloai ? 'error' : null}
            value={formik.values.phanloai}
          />
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
            Loại <span className="text-red-500">(*)</span>
          </label>
          <Select
            placeholder="chọn nhóm người dùng"
            onChange={onChangeSelected}
            value={formik.values.loai}
            options={loai?.map((item) => ({
              label: item.label,
              value: item.value,
            }))}
            className="w-full"
            name="loai"
            status={formik.errors.loai && formik.touched.loai ? 'error' : null}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">Ghi chú</label>
          <Input name="ghichu" onChange={formik.handleChange} value={formik.values.ghichu} />
        </div>
      </div>
    </Modal>
  )
}

export default CreateLoaiThuoc

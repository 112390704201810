import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ConfigProvider, Table } from 'antd'
import FilterButton from './Filter/FilterButton'
import moment from 'moment'

const columns = [
  {
    title: 'STT',
    width: 80,
    align: 'center',
    render: (text, record, index) => index + 1,
    fixed: 'left',
  },
  {
    title: 'Tài khoản',
    dataIndex: 'taiKhoan',
    key: 'taiKhoan',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Người dùng',
    dataIndex: 'nguoiDung',
    key: 'nguoiDung',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Chi nhánh',
    dataIndex: 'chiNhanh',
    key: 'chiNhanh',
    width: 200,
  },
  {
    title: 'Địa chỉ MAC',
    dataIndex: 'diaChiMAC',
    key: 'diaChiMAC',
    width: 150,
    align: 'center',
  },
  {
    title: 'Địa chỉ IP',
    dataIndex: 'diaChiIP',
    key: 'diaChiIP',
    width: 200,
    align: 'center',
  },
  {
    title: 'Thời gian',
    dataIndex: 'thoiGian',
    key: 'thoiGian',
    width: 150,
    align: 'center',
  },
  {
    title: 'Hành động',
    dataIndex: 'hanhDong',
    key: 'hanhDong',
    width: 100,
    align: 'center',
  },
  {
    title: 'Đối tượng',
    dataIndex: 'doiTuong',
    key: 'doiTuong',
    width: 200,
    align: 'center',
  },
  {
    title: 'Mô tả',
    dataIndex: 'moTa',
    key: 'moTa',
    render: (text) => (
      <div
        style={{
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap', // Preserve white spaces and line breaks
        }}
        dangerouslySetInnerHTML={{
          __html: text.replace(/\n/g, '<br />').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;'),
        }}
      />
    ),
  },
  {
    title: 'Trạng thái',
    dataIndex: 'trangThai',
    key: 'trangThai',
    align: 'center',
    fixed: 'right',
    width: 100,
    render: (text) =>
      text === 'Thành công' ? (
        <span style={{ color: 'green' }}>Thành công</span>
      ) : (
        <span style={{ color: 'red' }}>Thất bại</span>
      ),
  },
]

const data = [
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa \n\tTT952411250004-952300066- \n\tHOÀNG PHÚC KHANG-30/12/2022- \n(0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thành công',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thất bại',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thành công',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thất bại',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thành công',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thất bại',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thành công',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thất bại',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thành công',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thất bại',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thành công',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thất bại',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thành công',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thất bại',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thành công',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thất bại',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thành công',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thất bại',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thành công',
  },
  {
    STT: 1,
    taiKhoan: 'admin',
    nguoiDung: 'Nguyễn Văn A',
    mayTinh: 'DESKTOP-123456',
    chiNhanh: 'Công ty cổ phần',
    diaChiMAC: 'E8-9C-25-2C-69-CE',
    diaChiIP: '115.74.242.249/192.168.1.75',
    thoiGian: '12:30 01/01/2021',
    hanhDong: 'Lưu',
    doiTuong: 'Báo cáo',
    moTa: 'Lưu phiếu bán toa TT952411250004-952300066-HOÀNG PHÚC KHANG-30/12/2022- (0) HH001541_Augmentin 250mg/31,25mg(4 Gói x 13,000 = 52,000)-(Lô: HR6N / Date: 16/11/2025 / Số HD: 118865 / Số lưu hành: VN-17444-13) HH001579_Soluboston 20mg(1 Viên x 8,000 = 8,000)-(Lô: 070424 / Date: 04/04/2027 / Số HD: 629 / Số lưu hành: VD-32506-19) HH000833_A.T Ibuprofen (ống 5ml)(6 Ống x 4,000 = 24,000)-(Lô: 4354 / Date: 10/07/2026 / Số HD: 00000372 / Số lưu hành: VD-25631-16) HH001337_Glotadol 150mg(0 Gói x 3,000 = 0)-(Lô: 1230171 / Date: 29/12/2025 / Số HD: 59211 / Số lưu hành: VD-21643-14)',
    trangThai: 'Thất bại',
  },
]


function LichSuNguoiDung(props) {

  return (
    <div className="p-2 bg-[#E8E8E8] h-full ">
      <div
        className="bg-white p-2 h-full rounded-md"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <div className="w-full">
          <div className="p-2">
            <FilterButton />
          </div>

          {/* <Button
            loading={isLoadingExport}
            onClick={exportToExcel}
            className="text-green-700 font-semibold"
            type="default"
          >
            Excel <ImportExportIcon />
          </Button> */}
        </div>
        <div className="mt-5">
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
                fontSize: 13,
              },
            }}
          >
            <Table
              virtual
              // loading={loading}
              pagination={false}
              scroll={{
                y: 700,
                x: 2500,
              }}
              bordered
              className="mb-5n"
              dataSource={data}
              columns={columns}
            />
          </ConfigProvider>
          {/* <div className="flex justify-end">
              <Pagination
                className="mt-5"
                showSizeChanger={false}
                current={+useSearch.get('page')}
                simple
                defaultCurrent={1}
                pageSize={20}
                total={20 * totalPage}
                onChange={(page, pagesize) => {
                  setPage(page)
                }}
              />
            </div> */}
        </div>
      </div>
    </div>
  )
}

LichSuNguoiDung.propTypes = {}

export default LichSuNguoiDung

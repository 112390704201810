import { Input, Modal } from 'antd'
import React from 'react'

const ModalHD = ({ hoaDon, setHoaDon, setFormPay }) => {
  const cancel = () => {
    setHoaDon(false)
  }
  return (
    <Modal open={hoaDon} onCancel={cancel} onOk={cancel} title="Xuất hoá đơn">
      <label className="font-semibold">Nội dung xuất hoá đơn</label>
      <Input.TextArea
        onChange={(e) =>
          setFormPay((preState) => ({ ...preState, ghiChuXuatHoaDon: e.target.value }))
        }
      />
    </Modal>
  )
}
export default ModalHD

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConfigProvider, DatePicker, Table, TreeSelect, Select, Button } from 'antd'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { reportService } from '../../../../services/Report/reportService'
import { branchService } from '../../../../services/branch/branchService'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import { exportExcelformatSumCol } from '../../../../utils/export/exportExcelFormat'
import { formattedNumber } from '../../../../utils/formattedNumber'
import ToastCus from '../../../../utils/Toast'
import { columns } from './Columns'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

const BaoCaoXetNghiem = () => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState(['all'])
  const [dataReport, setDataReport] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [search, setSearch] = useState('')

  const fetchReport = async () => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT526',
      )
      const { data } = await reportService.getBaoCaoXetNghiem(
        dateForm,
        dateTo,
        (value?.includes('all') || !value?.length ? branch : value)
          ?.map(
            (item) =>
              `idChiNhanhs=${value?.includes('all') || !value?.length ? item?.idChiNhanh : item}`,
          )
          ?.join('&')
          ?.toString(),

        // search?.length ? search?.trim() : null,

        // value?.includes('all') ? branch?.map((item) => item?.idChiNhanh) : value,
      )
      setDataReport(data)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const fetchBranch = async (id) => {
    try {
      setIsLoading(true)
      const { data } = await branchService.getBranchNotVP(id)
      setBranch(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT527',
      )

      const formatData = dataReport?.map((item, index) => ({
        'STT ': ++index,
        'Chi nhánh': item?.tenchinhanh,
        'Ngày ': item?.ngaychidinh ? moment(item?.ngaychidinh).format('DD/MM/YYYY HH:mm:ss') : '-',
        'Mã bệnh nhân': item?.mabenhnhan,
        'Tên bệnh nhân': item?.tenbenhnhan,
        'Tên đối tác xét nghiệm': item?.tendoitac,
        'Địa chỉ': item?.diachi,
        'Mã xét nghiệm': item?.maxn,
        'Tên xét nghiệm': item?.tenxn,
        'ĐVT ': item?.dvt,
        'Thanh toán': formattedNumber(item?.tienthanhtoan ?? 0),
        'Tiền gửi mẫu': formattedNumber(item?.tienguimau ?? 0),
        'Lợi nhuận': formattedNumber(item?.loinhuan ?? 0),
        'Trạng thái': item?.trangthaiguimau,
      }))
      const name = 'BÁO CÁO XÉT NGHIỆM'
      if (formatData?.length)
        exportExcelformatSumCol(formatData, name, ['Thanh toán', 'Tiền gửi mẫu', 'Lợi nhuận'])
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  const onLoad = () => {
    setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
    if (!value || !value?.length) setValue(['all'])
    fetchReport()
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
  }, [])

  useEffect(() => {
    if (branch?.length) fetchReport()
  }, [branch])

  return (
    <div className="p-2 bg-[#EFEFEF] h-full">
      <div className="p-3 bg-white h-full">
        <div className="flex gap-3 border-dotted border-b-0  p-2 ">
          <div className="w-[17rem] flex items-center gap-2">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="w-[17rem]">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranch(value)
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-[17rem] flex gap-2">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>

          <div className="flex gap-3">
            <IconButton
              disabled={isLoading}
              onClick={onLoad}
              color="primary"
              aria-label="delete"
              size="small"
            >
              <SyncIcon />
            </IconButton>
          </div>
          <div className="flex gap-3 ml-auto">
            <Button
              disabled={isLoading || !dataReport?.length}
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              borderRadius: 0,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            loading={isLoading}
            bordered
            scroll={{
              x: 1500,
              y: 715,
            }}
            dataSource={dataReport}
            columns={columns}
            pagination={false} // Disable pagination
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                  <Table.Summary.Cell index={8}></Table.Summary.Cell>
                  <Table.Summary.Cell index={9}></Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right" index={10}>
                    <span className="font-semibold">
                      {
                        formattedNumber(
                          dataReport?.reduce((tong, item) => (tong += item?.tienthanhtoan), 0) ?? 0,
                        )
                        //đơn giá
                        // dataReport?.reduce((tong, item) => (tong += item?.thanhtoan), 0),
                      }
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right" index={11}>
                    <span className="font-semibold">
                      {
                        formattedNumber(
                          dataReport?.reduce((tong, item) => (tong += item?.tienguimau), 0) ?? 0,
                        )
                        //thành tiền
                        // data?.reduce((tong, item) => (tong += item?.tienguimau), 0),
                      }
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right" index={12}>
                    <span className="font-semibold">
                      {
                        formattedNumber(
                          dataReport?.reduce((tong, item) => (tong += item?.loinhuan), 0) ?? 0,
                        )
                        //thành tiền
                        // data?.reduce((tong, item) => (tong += item?.loinhuan), 0),
                      }
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={13}></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default BaoCaoXetNghiem

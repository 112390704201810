import React from 'react'
import { Tabs } from 'antd'
import LoaiVanBan from './LoaiVanBan/LoaiVanBan'
import LinhVuc from './LinhVuc/LinhVuc'

function DanhMuc(props) {
  return (
    <div className="p-2  bg-[#E8E8E8] h-full">
      <div
        className="bg-white rounded-md border p-2 h-full"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <Tabs type="card" className="h-full">
          <Tabs.TabPane tab="Loại văn bản" key={1}>
            <LoaiVanBan />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Lĩnh vực" key={2}>
            <LinhVuc />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  )
}
export default DanhMuc

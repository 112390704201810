import { Input } from 'antd'
import moment from 'moment'
import React from 'react'
import 'moment/locale/vi'
moment.locale('vi')

const InputHook = ({ label, ...props }) => {
  return (
    <>
      <label className="font-semibold text-gray-500 w-1/3" htmlFor={props.name}>
        {label}:
      </label>
      <Input
        className="w-2/3"
        variant="borderless"
        readOnly
        value={
          props.type === 'text'
            ? props.defaultValue
            : moment(props.defaultValue).format('DD/MM/YYYY HH:mm:ss')
        }
      />
    </>
  )
}

export default InputHook

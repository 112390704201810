import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Segmented, Alert } from 'antd'
import FormCD from './FormCD/FormCD'
import Index from '../Index/Index'
import FormCLS from '../../../ChildrenClinic/General/Tab/FormCLS/FormCLS'
import FormKQXN from '../../../ChildrenClinic/General/Tab/FormKQXN/FormKQXN'
import FormKQCDHA from '../../../MaternityClinic/General/Tab/FormKQCDHA/FormKQCDHA'
import HistoryTongQuat from '../../../ChildrenClinic/General/Tab/HistoryTongQuat/HistoryTongQuat'
import { updateBnDetailSan } from '../../../../../store/actions/maternityClinicAction'
import { maternityClinicSchema } from '../../../../../schemas/maternityClinicSchema'
import { phanQuyenService } from '../../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../../store/actions/phanQuyenAction'
import * as typeAction from '../../../../../store/constants/constants'
import ToastCus from '../../../../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const tab_CD = 'Chẩn đoán'
const tab_CLS = 'Chỉ định cận lâm sàng'
const tab_KQXN = 'Kết quả xét nghiệm'
const tab_KQCDHA = 'Kết quả CĐHA'
const tab_LSTQ = 'Lịch sử tổng quát'

const TabDiagnostic = ({ defaultValuePK, formBenhNhan }) => {
  const dispatch = useDispatch()
  const now = moment()
  const SO_NGAY_TAI_KHAM = 0
  const initialStartDate = now.add(SO_NGAY_TAI_KHAM, 'days').format('YYYY-MM-DD')
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const { DetailBNKhamSan } = useSelector((state) => state.maternityClinicReducer)
  const { DetailBN, ThuocBN, DetailToaThuocBN, infoBN, historyKham, NgayThuoc } = useSelector(
    (state) => state.childrenClinicReducer,
  )
  const { infoUser } = useSelector((state) => state.userReducer)
  const [date, setDate] = useState(initialStartDate)
  const [count, setCount] = useState(SO_NGAY_TAI_KHAM)
  const [checkBox, setCheckBox] = useState(false)
  const [segmentedValue, setSegmentedValue] = useState(tab_CD)
  const [isPrintToaThuocKhiLuu, setIsPrintToaThuocKhiLuu] = useState(false)
  const [listQuyenTab, setlistQuyenTab] = useState([])
  const [isLoadQuyen, setisLoadQuyen] = useState(false)
  const quyenFormCD = 'QHT076'
  const quyenHistoryTongQuat = 'QHT081'
  const quyenFormKQXN = 'QHT082'
  const quyenFormKQCDHA = 'QHT083'

  const formikPhuSan = useFormik({
    enableReinitialize: true,
    initialValues: {
      // para: '',
      // chukykinh: '',
      // nguathai: '',
      // kinhchot: '',
      // kinhapchot: '',
      // dusanhlucdau: '',
      // dusanh: '',
      // tuoithai: '',
      // lucthaituan: 0,
      // lucthaingay: 0,
      idbnttc: DetailBN?.idbnttc,
      congo: DetailBNKhamSan?.congo,
      khamtrong: DetailBNKhamSan?.khamtrong,
      amho: DetailBNKhamSan?.amho,
      amdao: DetailBNKhamSan?.amdao,
      ctc: DetailBNKhamSan?.ctc,
      tucung: DetailBNKhamSan?.tucung,
      bctc: DetailBNKhamSan?.bctc,
      timthai: DetailBNKhamSan?.timthai,
      ngoithai: DetailBNKhamSan?.ngoithai,
      oi: DetailBNKhamSan?.oi,
      kchau: DetailBNKhamSan?.kchau,
      phanphut: DetailBNKhamSan?.phanphut,
      phanphup: DetailBNKhamSan?.phanphup,
      gnkhac: DetailBNKhamSan?.gnkhac,

      cungdo: DetailBNKhamSan?.cungdo,
      tsm: DetailBNKhamSan?.tsm,

      nhiN2VU: DetailBNKhamSan?.nhiN2VU,
      nhintrai: DetailBNKhamSan?.nhintrai,
      nhinphai: DetailBNKhamSan?.nhinphai,
      sonantrai: DetailBNKhamSan?.sonantrai,
      sonanphai: DetailBNKhamSan?.sonanphai,
      hachnach: DetailBNKhamSan?.hachnach,

      idloaisan: DetailBNKhamSan?.idloaisan ?? 1,
      nguoitao: infoUser?.tenNV,
    },
    // onSubmit: (values) => formik.setFieldValue('formPhuSan', values),
    // validationSchema: maternityClinicSchema,
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbnttc: DetailBN?.idbnttc,
      ngaytaikham: DetailBN?.ngaytaikham,
      trangthaikhambenh: 2,
      bskham: DetailBN?.bskham,
      lydokham: DetailBN?.lydokham,
      benhsu: DetailBN?.benhsu,
      trieuchung: DetailBN?.trieuchung,
      idchandoan: DetailBN?.idchandoan,
      chandoan: DetailBN?.chandoan,
      idloidan: DetailBN?.idloidan,
      loidan: DetailBN?.loidan,
      diung: DetailBN?.diung,
      quatrinhbenhly: DetailBN?.quatrinhbenhly,
      tieusugiadinh: DetailBN?.tieusugiadinh,
      toanthan: DetailBN?.toanthan,
      cacbophan: DetailBN?.cacbophan,
      chandoanbandau: DetailBN?.chandoanbandau,
      idicD10BANDAU: DetailBN?.idicD10BANDAU,
      daxuly: DetailBN?.daxuly,
      chandoanravien: DetailBN?.chandoanravien,
      idicD10RAVIEN: DetailBN?.idicD10RAVIEN,
      dieutringoaitru: DetailBN?.dieutringoaitru,
      dienbienlamsang: DetailBN?.dienbienlamsang,
      benhkemtheo: DetailBN?.benhkemtheo,
      idicD10BENHKEMTHEO: DetailBN?.idicD10BENHKEMTHEO,
      ppdieutri: DetailBN?.ppdieutri,
      ttlucravien: DetailBN?.ttlucravien,
      huongxuly: DetailBN?.huongxuly,
      lydokolaythuoc: DetailBN?.lydokolaythuoc ?? 0,
      para: DetailBN?.para,
      chukikinh: DetailBN?.chukikinh,
      nguathai: DetailBN?.nguathai,
      kinhchot: DetailBN?.kinhchot,
      kinhapchot: DetailBN?.kinhapchot,
      dusanhkc: DetailBN?.dusanhkc,
      dusanh: DetailBN?.dusanh,
      ngaysanh: DetailBN?.ngaysanh,
      tuoithai: DetailBN?.tuoithai,
      tuanthai: DetailBN?.tuanthai,
      ngaythai: DetailBN?.ngaythai,
      tongtrang: DetailBN?.tongtrang ?? 'Trung bình',
      timphoi: DetailBN?.timphoi ?? 'Tim đều, phổi không ran',
      daniem: DetailBN?.daniem ?? 'Hồng',
      bung: DetailBN?.bung ?? 'Mềm',
      phu: DetailBN?.phu ?? 'Không',
      chieucao: DetailBN?.chieucao,
      cannang: DetailBN?.cannang,
      mach: DetailBN?.mach,
      huyetap: DetailBN?.huyetap,
      nhietdo: DetailBN?.nhietdo,
      nhiptho: DetailBN?.nhiptho,
      spO2: DetailBN?.spO2,
      ghichu: DetailBN?.ghichu,
    },
    onSubmit: (values) => handleSubmit(values),
    validationSchema: maternityClinicSchema,
  })
  const handleSegmentedChange = (value) => {
    setSegmentedValue(value)
  }
  const handleSubmit = (value) => {
    value.idbskham = infoUser?.idnv
    value.bskham = infoUser?.tenNV
    value.tiensubanthan = formBenhNhan.values.tienSuBanThan
    value.ngaytaikham = moment(date).format('YYYY-MM-DD')
    value.giorapk = moment().format('YYYY-MM-DDTHH:mm:ss')
    const newArr = []
    if (!checkBox && ThuocBN?.length > 0) {
      // lấy thuốc
      let sttThuoc = 1
      for (let item of ThuocBN) {
        newArr.push({
          idbnttc: DetailBN?.idbnttc,
          idthuoc: item?.idthuoc,
          tenBietDuoc: item?.tenBietDuoc,
          tenHoatChat: item?.tenhoatchat,
          duongDung: item?.duongDung,
          dvt: item?.dvt,
          hamLuong: item?.hamLuong,
          ghiChu: item?.ghiChu,
          lieuDung: `${item?.lieuDung ?? 0}`,
          soLanNgay: `${item?.soLanNgay ?? 0}`,
          bsKeDon: infoUser?.tenNV,
          donViDung: item?.donViDung,
          donGia: item?.giaBan,
          isGongKinh: item?.isGongKinh,
          soLuong: item?.soLuong,
          idkhocn: item?.idkhocn,
          stt: `${sttThuoc++}`, // chuỗi, để còn tách lô 1.1 1.2 1.3
        })
      }
    } else value.trangthaikhambenh = 4 // không lấy thuốc
    dispatch(
      updateBnDetailSan(
        infoBN,
        formBenhNhan.values,
        value,
        formikPhuSan.values,
        defaultValuePK,
        newArr,
        isPrintToaThuocKhiLuu,
      ),
    )
  }
  // useEffect(() => {
  //   const today = moment().startOf('day')
  //   if (DetailBN?.ngaytaikham) {
  //     const startDate = moment(DetailBN.ngaykham).startOf('day')
  //     const endDate = moment(DetailBN.ngaytaikham).startOf('day')
  //     const diffDays = endDate.diff(startDate, 'days')
  //     setCount(diffDays)
  //   } else {
  //     const selectedDate = moment(formik.values.ngaytaikham).startOf('day')
  //     const diffDays = selectedDate.diff(today, 'days')
  //     setCount(diffDays)
  //   }
  //   if (formik.values.ngaytaikham === null) setCount(0)
  // }, [date, DetailBN])
  const calculateDiffDays = (start, end) => {
    const startDate = moment(start).startOf('day')
    const endDate = moment(end).startOf('day')
    return endDate.diff(startDate, 'days')
  }

  const checkQuyenCacTabs = async (maquyen) => {
    try {
      setisLoadQuyen(true)
      maquyen &&
        (await phanQuyenService.putCheckQuyenThaoTac(
          infoUser?.taiKhoan ?? userInfo?.taiKhoan,
          maquyen,
        ))
      setlistQuyenTab((prev) =>
        prev?.find((item) => item === maquyen) ? prev : [...prev, maquyen],
      )
    } catch (error) {
      console.error('Error : ', error)
      setlistQuyenTab((prev) => prev?.filter((item) => item !== maquyen))
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setisLoadQuyen(false)
    }
  }
  useEffect(() => {
    const today = moment().startOf('day')
    const diffDays = formik.values.ngaytaikham
      ? calculateDiffDays(today, formik.values.ngaytaikham)
      : 0
    setCount(diffDays)
    dispatch({
      type: typeAction.DISPATCH_SO_LAN_THUOC_NHI,
      payload: diffDays,
    })
  }, [date, formik.values.ngaytaikham])
  //--------- set ngày tái khám mặt định-------------//
  useEffect(() => {
    if (!DetailBN?.ngaytaikham) {
      formik.setFieldValue('ngaytaikham', initialStartDate)
    } else {
      setDate(DetailBN?.ngaytaikham)
    }
    const diffDays = DetailBN?.ngaytaikham
      ? calculateDiffDays(DetailBN?.ngaykham, DetailBN?.ngaytaikham)
      : SO_NGAY_TAI_KHAM
    setCount(diffDays)
  }, [DetailBN])

  // useEffect(() => {
  //   if (!DetailBN?.ngaytaikham) {
  //     formik.setFieldValue('ngaytaikham', initialStartDate)
  //   }
  //   const diffDays = DetailBN?.ngaytaikham
  //     ? calculateDiffDays(DetailBN?.ngaykham, DetailBN?.ngaytaikham)
  //     : SO_NGAY_TAI_KHAM
  //   setCount(diffDays)
  // }, [DetailBN])
  // useEffect(() => {
  //   if (NgayThuoc !== count) {
  //     dispatch({
  //       type: typeAction.NGAY_THUOC_NHI,
  //       payload: count,
  //     })
  //   }
  // }, [count])
  //   const selectedDate = moment(date).startOf('day')
  //   const diffDays = selectedDate.diff(today, 'days')
  //   setCount(diffDays)
  // }, [date])
  // useEffect(() => {
  //   setIsPrintToaThuocKhiLuu(false)
  //   setCheckBox(
  //     infoBN && !DetailToaThuocBN?.DanhSachThuoc?.length && DetailBN?.lydokolaythuoc ? true : false,
  //   )
  // }, [DetailBN])
  useEffect(() => {
    setIsPrintToaThuocKhiLuu(false)
    setCheckBox(
      infoBN && !DetailToaThuocBN?.DanhSachThuoc?.length && DetailBN?.lydokolaythuoc ? true : false,
    )
  }, [DetailToaThuocBN?.DanhSachThuoc, DetailToaThuocBN?.DanhSachThuoc?.length])
  useEffect(() => {
    segmentedValue === tab_CD && checkQuyenCacTabs(quyenFormCD) //chẩn đoán
    segmentedValue === tab_LSTQ && checkQuyenCacTabs(quyenHistoryTongQuat) //lskb tq
    segmentedValue === tab_KQXN && checkQuyenCacTabs(quyenFormKQXN) //kq xn
    segmentedValue === tab_KQCDHA && checkQuyenCacTabs(quyenFormKQCDHA) //kq cdha
  }, [segmentedValue])
  return (
    <div>
      <div>
        <Index />
      </div>
      <div className="mt-3 px-2">
        <div className="flex justify-between">
          <Segmented
            options={[tab_CD, tab_LSTQ, tab_CLS, tab_KQXN, tab_KQCDHA]}
            value={segmentedValue}
            onChange={handleSegmentedChange}
          />
        </div>
        <div>
          {segmentedValue === tab_CD && listQuyenTab?.includes(quyenFormCD) ? (
            <FormCD
              formBenhNhan={formBenhNhan}
              checkBox={checkBox}
              setCheckBox={setCheckBox}
              formik={formik}
              setDate={setDate}
              date={date}
              count={count}
              formikPhuSan={formikPhuSan}
              setCount={setCount}
              isPrintToaThuocKhiLuu={isPrintToaThuocKhiLuu}
              setIsPrintToaThuocKhiLuu={setIsPrintToaThuocKhiLuu}
            />
          ) : segmentedValue === tab_LSTQ && listQuyenTab?.includes(quyenHistoryTongQuat) ? (
            <HistoryTongQuat historyKham={historyKham} />
          ) : segmentedValue === tab_KQXN && listQuyenTab?.includes(quyenFormKQXN) ? (
            <FormKQXN />
          ) : segmentedValue === tab_CLS ? (
            <FormCLS formik={formik} />
          ) : segmentedValue === tab_KQCDHA && listQuyenTab?.includes(quyenFormKQCDHA) ? (
            <FormKQCDHA />
          ) : !isLoadQuyen ? (
            <div className="my-2">
              <Alert message="Tài khoản không có quyền thao tác!" type="error" showIcon />
            </div>
          ) : (
            <></>
          )}

          {/* // : segmentedValue === tab_CLS ? (
        //   <FormCLS formik={formik} />
        // ) : segmentedValue === tab_KQXN ? (
        //   <FormKQXN />
        // ) : (
        //   <FormKQCDHA />
        // )}{' '} */}
        </div>
      </div>
    </div>
  )
}

export default TabDiagnostic

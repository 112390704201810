import moment from 'moment'
import { call, put, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { vanPhongPhamService } from '../../services/vanPhongPham/vanphongphamService'
import ToastCus from '../../utils/Toast'
import { trangThaiDatHang } from '../../utils/trangThaiDatHang'
import 'moment/locale/vi'
moment.locale('vi')

export function* vanPhongPhamSaga() {
  yield takeLatest(typeAction.GET_ALL_HANG_HOA, function* getAllHangHoa({ type, idct }) {
    yield put({
      type: typeAction.OPEN_LOADING_PAGE,
    })
    try {
      const result = yield call(() => vanPhongPhamService.getAllHangHoa(idct))
      yield put({
        type: typeAction.DISPATCH_LIST_HANGHOA,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  yield takeLatest(
    typeAction.GET_ALL_DON_DAT_HANG_BY_TRANG_THAI,
    function* getAllDonHangByTrangThai({
      type,
      keyword,
      fromDate,
      toDate,
      pageIndex,
      idTrangThai,
      idct,
      idcn,
      nguoiduyet,
    }) {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        const result = yield call(() =>
          vanPhongPhamService.getAllDonHangByTrangThai(
            keyword,
            fromDate,
            toDate,
            pageIndex,
            idTrangThai,
            idct,
            idcn,
            nguoiduyet,
          ),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_DATHANG,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.GET_ALL_DON_DAT_HANG_THU_MUA_DUYET,
    function* getAllDonHangByTrangThai({ type, keyword, idct, pageIndex }) {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        const { data } = yield call(() =>
          vanPhongPhamService.getAllDonHangThuMuaDuyet(keyword, idct, pageIndex),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_DATHANG,
          payload: data,
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.GET_ALL_DON_DAT_HANG_BY_IDCT_IDCN,
    function* getAllDonHangByIdCtyIdCn({ type, keyword, fromDate, toDate, pageIndex, idct, idcn }) {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        const result = yield call(() =>
          vanPhongPhamService.getAllDonHangByIdCtyIdCn(
            keyword,
            fromDate,
            toDate,
            pageIndex,
            idct,
            idcn,
          ),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_DATHANG,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(typeAction.GET_ALL_NGUOI_DUYET, function* getAllNguoiDuyet({ type }) {
    try {
      const result = yield call(() => vanPhongPhamService.getAllNguoiDuyet())
      yield put({
        type: typeAction.DISPATCH_LIST_NGUOI_DUYET,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })

  yield takeLatest(
    typeAction.GET_ALL_DAT_HANG,
    function* getAllDatHang({ type, keyword, fromDate, toDate, pageIndex, idnv }) {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        const result = yield call(() =>
          vanPhongPhamService.getAllDatHang(keyword, fromDate, toDate, pageIndex, idnv),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_DATHANG,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.ADD_DON_DAT_HANG,
    function* addDonDatHang({ type, form, onLoad, setLoadingBtn, handleCloseModal }) {
      setLoadingBtn(true)
      try {
        yield call(() => vanPhongPhamService.addDonDatHang(form))
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Tạo đơn đặt hàng thành công',
        })
        handleCloseModal()
      } catch (err) {
        yield console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: err?.response?.data?.message || 'Tạo đơn đặt hàng thất bại',
        })
      } finally {
        setLoadingBtn(false)
      }
    },
  )

  yield takeLatest(
    typeAction.EDIT_DON_DAT_HANG,
    function* editDonDatHang({
      type,
      form,
      onLoad,
      setLoadingBtn,
      handleCloseModal,
      updateStatus,
    }) {
      setLoadingBtn(true)
      try {
        //update hàng
        yield call(() => vanPhongPhamService.editDonDatHang(form))
        // update status
        if (updateStatus) {
          yield call(() =>
            vanPhongPhamService.updateTrangThaiDonHang(
              form.iddathang,
              trangThaiDatHang.vanChuyen,
              form.nguoisua,
            ),
          )
        }
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Duyệt đơn đặt hàng thành công',
        })
        handleCloseModal()
      } catch (err) {
        yield console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: err?.response?.data?.message || 'Duyệt đơn đặt hàng thất bại',
        })
      } finally {
        setLoadingBtn(false)
      }
    },
  )

  yield takeLatest(typeAction.GET_INFO_DON_HANG, function* getInfoDonHang({ type, id }) {
    try {
      const { data } = yield call(() => vanPhongPhamService.getInfoDonHang(id))
      yield put({
        type: typeAction.DISPATCH_INFO_DONHANG,
        payload: data[0],
      })
    } catch (err) {
      yield console.log(err)
    }
  })

  yield takeLatest(
    typeAction.GET_INFO_DON_HANG_INCLUDE_MONEY,
    function* getInfoDonHangIncludeMoney({ type, id }) {
      try {
        const { data } = yield call(() => vanPhongPhamService.getInfoDonHangIncludeMoney(id))
        yield put({
          type: typeAction.DISPATCH_INFO_DONHANG,
          payload: data[0],
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )

  yield takeLatest(
    typeAction.UPDATE_TRANG_THAI_DON_DAT_HANG,
    function* updateTrangThaiDonHang({ type, id, idTrangThai, idnv, onLoad }) {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        yield call(() => vanPhongPhamService.updateTrangThaiDonHang(id, idTrangThai, idnv))
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Gửi đơn đặt hàng thành công',
        })
      } catch (err) {
        yield console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: err?.response?.data?.message || 'Gửi đơn đặt hàng thất bại',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.UPDATE_TRANG_THAI_LIST_DON_DAT_HANG,
    function* updateTrangThaiListDonHang({ type, listDonDatHang, onLoad }) {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        yield call(() => vanPhongPhamService.updateTrangThaiListDonHang(listDonDatHang))
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Duyệt đơn đặt hàng thành công',
        })
      } catch (err) {
        yield console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: err?.response?.data?.message || 'Duyệt đơn đặt hàng thất bại',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.NHAN_DON_DAT_HANG,
    function* updateSoLuongGiaoNhan({ type, form, setLoadingBtn, handleCancel }) {
      setLoadingBtn(true)
      try {
        yield call(() => vanPhongPhamService.updateSoLuongGiaoNhan(form))
        ToastCus.fire({
          icon: 'success',
          title: 'Cập nhật đơn đặt hàng thành công',
        })
        handleCancel()
      } catch (err) {
        yield console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: err?.response?.data?.message || 'Cập nhật đơn đặt hàng thất bại',
        })
      } finally {
        setLoadingBtn(false)
      }
    },
  )

  yield takeLatest(typeAction.DELETE_DON_HANG, function* deleteDonHang({ type, id, onLoad }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
    } catch (err) {
      yield console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: err?.response?.data?.message || 'Cập nhật đơn đặt hàng thất bại',
      })
    }
  })

  yield takeLatest(typeAction.DELETE_DON_HANG, function* deleteDonHang({ type, id, onLoad }) {
    yield put({
      type: typeAction.OPEN_LOADING_PAGE,
    })
    try {
      yield call(() => vanPhongPhamService.deleteDonHang(id))
      onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Xóa đơn đặt hàng thành công',
      })
    } catch (err) {
      yield console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: err?.response?.data?.message || 'Xóa đơn đặt hàng thất bại',
      })
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
}

import React, { useEffect, useState } from 'react'
import { ConfigProvider, DatePicker, Input, Select, Table, Tabs, TreeSelect } from 'antd'
import SyncIcon from '@mui/icons-material/Sync'
import { branchService } from '../../../../../services/branch/branchService'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { useDispatch, useSelector } from 'react-redux'
import { SearchOutlined } from '@ant-design/icons'
import { IconButton } from '@mui/material'

const { SHOW_PARENT } = TreeSelect

export default function VanbanTable() {
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const [selectedBranches, setSelectedBranches] = useState(['all'])
  const [isLoading, setIsLoading] = useState(false)
  const [company, setCompany] = useState(id_company)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',

      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]

  const fetchBranch = async (id) => {
    try {
      setIsLoading(true)
      const result = await branchService.getBranchNotVP(id)
      setBranch(result.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        await dispatch(listAllCompanyAction())
        await fetchBranch(id_company)
      } catch (error) {
        console.log(error)
      }
    }
    initialize()
  }, [])
  const onChange = (newValue) => {
    setSelectedBranches(newValue)
  }
  const tProps = {
    treeData,
    value: selectedBranches,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn công ty ',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  const onLoad = () => {
    setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
    // fetchReport()
  }
  return (
    <div
      className=" p-2 border mx-2 mt-2 rounded-md"
      style={{
        height: '850px',
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              horizontalItemPadding: 12,
            },
          },
        }}
      >
        <Tabs
          items={[
            {
              key: 1,
              label: 'Danh sách văn bản',
              children: (
                <>
                  <div className="p-1">
                    <div className="flex items-center gap-2">
                      <div className="w-[17rem]">
                        <Select
                          showSearch
                          value={company}
                          onChange={(value) => {
                            setCompany(value)
                            fetchBranch(value)
                            setSelectedBranches(['all'])
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '')
                              .toLowerCase()
                              .localeCompare((optionB?.label ?? '').toLowerCase())
                          }
                          options={listCompany?.map(({ idct, tenct }) => ({
                            label: tenct,
                            value: idct,
                          }))}
                          className="w-full"
                        />
                      </div>
                      <div className="w-80">
                        <TreeSelect
                          {...tProps}
                          style={{ flexWrap: 'unset' }}
                          className="w-full h-8 overflow-clip"
                        />
                      </div>
                      <div className="flex w-64">
                        <Input
                          value={search}
                          placeholder="Tìm kiếm theo tên văn bản"
                          onChange={(e) => setSearch(e.target.value)}
                          prefix={<SearchOutlined />}
                        />
                      </div>
                      <div className="flex gap-2">
                        <IconButton
                          disabled={isLoading}
                          onClick={onLoad}
                          color="primary"
                          aria-label="delete"
                          size="small"
                        >
                          <SyncIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 5,
                      },
                      components: {
                        Table: {
                          rowHoverBg: '#ecf0f1',
                          headerBg: '#e6e6e6',
                          footerBg: '#e6e6e6',
                          borderColor: '#BABABA',
                        },
                      },
              
                    }}
                  >
                    <Table
                      scroll={{
                        y: 600,
                      }}
                      onRow={(record) => ({
                        // onClick: () => onClickBnPending(record),
                      })}
                      bordered
                      pagination={false}
                      //   dataSource={BNPending}
                      columns={[
                        {
                          key: 'STT',
                          dataIndex: 'STT',
                          title: 'STT',
                          width: 40,
                          render: (text, record, index) => ++index,
                        },
                        {
                          key: 'tenvanban',
                          dataIndex: 'tenbenhnhan',
                          title: 'Tên văn bản',
                        },
                        {
                          key: 'loại văn bản',
                          dataIndex: 'loaivanban',
                          title: 'Loại văn bản',
                        },
                        {
                          key: 'số file',
                          dataIndex: 'sofile',
                          title: 'Số file',
                        },
                      ]}
                    />
                  </ConfigProvider>
                </>
              ),
            },
            {
              key: 2,
              label: 'Văn bản đã xử lý',
              children: (
                <>
                  <div className="p-1">
                    <div className="flex items-center gap-2">
                      <div className="w-[17rem]">
                        <Select
                          showSearch
                          value={company}
                          onChange={(value) => {
                            setCompany(value)
                            fetchBranch(value)
                            setSelectedBranches(['all'])
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '')
                              .toLowerCase()
                              .localeCompare((optionB?.label ?? '').toLowerCase())
                          }
                          options={listCompany?.map(({ idct, tenct }) => ({
                            label: tenct,
                            value: idct,
                          }))}
                          className="w-full"
                        />
                      </div>
                      <div className="w-80">
                        <TreeSelect
                          {...tProps}
                          style={{ flexWrap: 'unset' }}
                          className="w-full h-8 overflow-clip"
                        />
                      </div>
                      <div className="flex w-64">
                        <Input
                          value={search}
                          placeholder="Tìm kiếm theo tên văn bản"
                          onChange={(e) => setSearch(e.target.value)}
                          prefix={<SearchOutlined />}
                        />
                      </div>
                      <div className="flex gap-2">
                        <IconButton
                          disabled={isLoading}
                          onClick={onLoad}
                          color="primary"
                          aria-label="delete"
                          size="small"
                        >
                          <SyncIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 5,
                      },
                      components: {
                        Table: {
                          rowHoverBg: '#ecf0f1',
                          headerBg: '#e6e6e6',
                          footerBg: '#e6e6e6',
                          borderColor: '#BABABA',
                        },
                      },
              
                    }}
                  >
                    <Table
                      scroll={{
                        y: 600,
                      }}
                      onRow={(record) => ({
                        // onClick: () => onClickBnPending(record),
                      })}
                      bordered
                      pagination={false}
                      //   dataSource={BNPending}
                      columns={[
                        {
                          key: 'STT',
                          dataIndex: 'STT',
                          title: 'STT',
                          width: 40,
                          render: (text, record, index) => ++index,
                        },
                        {
                          key: 'tenvanban',
                          dataIndex: 'tenbenhnhan',
                          title: 'Tên văn bản',
                        },
                        {
                          key: 'loại văn bản',
                          dataIndex: 'loaivanban',
                          title: 'Loại văn bản',
                        },
                        {
                          key: 'số file',
                          dataIndex: 'sofile',
                          title: 'Số file',
                        },
                      ]}
                    />
                  </ConfigProvider>
                </>
              ),
            },
          ]}
        ></Tabs>
      </ConfigProvider>
    </div>
  )
}

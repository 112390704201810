import React, { useCallback, useEffect, useState } from 'react'
import { Table, Select, Input, ConfigProvider, Button, Modal, notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllChuongs, getICD10ByChuongs } from '../../../store/actions/ICD10Action'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import _, { debounce } from 'lodash'
import {
  SearchOutlined,
  PlusOutlined,
  FileExcelOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import CachedIcon from '@mui/icons-material/Cached'
import ModalAdd from './ModalAdd'
import ModalEdit from './ModalEdit'
import { https } from '../../../services/apiService'
import { exportExcelformat } from '../../../utils/export/exportExcelFormat'

const IDC10 = () => {
  const dispatch = useDispatch()
  const PAGE_SIZE = 50
  const { Chuongs, IDC10s } = useSelector((state) => state.IDC10Reducer)
  const { isLoadingTable } = useSelector((state) => state.modalReducer)
  const [totalPages, setTotalPages] = useState(1)
  const [IdChuong, setIdChuong] = useState('')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [editRecord, setEditRecord] = useState(null)

  const maQuyenXemDs = 'QHT432'
  const maQuyenXemCt = 'QHT434'
  const maQuyenXuatExcel = 'QHT433'
  const maQuyenThemIcd = 'QHT431'
  const maQuyenXoaIcd = 'QHT436'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        dispatch(
          getICD10ByChuongs(
            infoNew?.search,
            IdChuong,
            infoNew?.page,
            PAGE_SIZE,
            infoNew?.reset,
            setTotalPages,
          ),
        )
        break
      case maQuyenXemCt:
        showEditModal(infoNew)
        break
      case maQuyenXuatExcel:
        exportToExcel()
        break
      case maQuyenThemIcd:
        showModal()
        break
      case maQuyenXoaIcd:
        showDeleteConfirm(infoNew)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXemCt:
        handleEditCancel()
        break
      case maQuyenXuatExcel:
        //
        break
      case maQuyenThemIcd:
        handleCancel()
        break
      case maQuyenXoaIcd:
        //
        break
      default:
        return false
    }
  }

  const { confirm } = Modal

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    fetchDataSource(true, search, 1)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const showEditModal = async (record) => {
    try {
      const response = await https.get(`ChanDoan/GetICD10ByID?idICD=${record?.idicD10}`)

      if (response.status === 200) {
        setEditRecord(response.data)
        setIsEditModalVisible(true)
      }
    } catch (error) {
      console.log('Error:', error)
      notification.error({
        message: 'Lấy thông tin thất bại',
        description: 'Có lỗi xảy ra khi lấy thông tin ICD-10.',
      })
    }
  }

  const handleEditCancel = () => {
    setIsEditModalVisible(false)
  }

  const exportToExcel = async () => {
    const data = IDC10s?.data?.map((item, index) => ({
      STT: index + 1,
      'Mã ICD-10': item.mabenh,
      'Tên tiếng việt': item.icD10TV,
      'Tên tiếng anh': item.icD10TA,
      'Ghi chú': item.ghichu,
    }))

    const name = 'ICD10s'
    data?.length && exportExcelformat(data, name)
  }

  useEffect(() => {
    fetchDataSource(true, search, 1)
    dispatch(fetchAllChuongs())
  }, [])
  const fetchDataSource = (reset, search, page) => {
    setPage(page)
    dispatch(
      putCheckQuyenThaoTacAction(
        maQuyenXemDs,
        { reset, search, page },
        actionIsTrue,
        actionIsFalse,
      ),
    )
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword, page, reset) => {
      fetchDataSource(reset, keyword, page)
      // dispatch(getICD10ByChuongs(keyword, IdChuong, page, PAGE_SIZE, reset, setTotalPages))
    }, 300),
    [],
  )
  const onChangeSearch = (e) => {
    const { value } = e.target
    setSearch(value)
    debounceDropDown(value, 1, true)
  }
  const loadMoreData = (reset, search, page) => {
    if (totalPages < page) return // NẾU DỮ LIỆU CHỈ CÓ 1 PAGE THÌ DỪNG CALL API
    fetchDataSource(reset, search, page + 1)
  }
  const handleScroll = useCallback(
    debounce(() => {
      const tableBody = document.querySelector('.table-icd10 .ant-table-body')
      if (
        tableBody.scrollTop + tableBody.clientHeight >= tableBody.scrollHeight - 100 &&
        !isLoadingTable &&
        search === ''
      ) {
        loadMoreData(false, search, page)
      }
    }, 300),
    [isLoadingTable],
  )
  useEffect(() => {
    const tableBody = document.querySelector('.table-icd10 .ant-table-body')
    tableBody.addEventListener('scroll', handleScroll)
    return () => tableBody.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Bạn có chắc muốn xoá ICD-10 này?',
      icon: <ExclamationCircleOutlined />,
      content: `Tên tiếng việt: ${record.icD10TV}`,
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk: async () => {
        try {
          const response = await https.delete(`ChanDoan/DeleteICD10?id=${record.idicD10}`)
          if (response.status === 204) {
            notification.success({
              message: 'Xóa thành công',
              description: `ICD-10 với ID: ${record.idicD10} đã được xóa thành công.`,
            })
            fetchDataSource(true, search, 1)
          }
        } catch (error) {
          console.log('Failed:', error)
          notification.error({
            message: 'Xóa thất bại',
            description: 'Có lỗi xảy ra khi xóa ICD-10.',
          })
        }
      },
    })
  }

  return (
    <div className="bg-[#EFEFEF] h-full p-3 ">
      <div className="bg-white h-full border rounded-xl  p-2">
        <div className="mb-4 flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
          <div className="flex items-center gap-4">
            <span className="text-sm font-medium text-gray-700">Chương:</span>
            <Select
              onChange={setIdChuong}
              value={IdChuong}
              options={[
                {
                  label: 'Tất cả',
                  value: '',
                },
                ...Chuongs?.map((chuong) => ({
                  label: chuong.chuongTV,
                  value: chuong.idChuong,
                })),
              ]}
              style={{ width: 500 }}
              className="text-sm"
            />
          </div>
          <div className="flex flex-col gap-2 sm:flex-row sm:items-center">
            <Input
              placeholder="Tìm kiếm..."
              prefix={<SearchOutlined className="text-gray-400" />}
              onChange={onChangeSearch}
              style={{ width: 300 }}
              className="text-sm"
            />
            <Button
              type="primary"
              icon={<CachedIcon />}
              onClick={() => fetchDataSource(true, search, 1)}
            >
              Tải lại
            </Button>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                dispatch(
                  putCheckQuyenThaoTacAction(maQuyenThemIcd, null, actionIsTrue, actionIsFalse),
                )
              }
            >
              Tạo mới
            </Button>
            <ModalAdd
              isModalVisible={isModalVisible}
              handleOk={handleOk}
              handleCancel={handleCancel}
            />
            <ModalEdit
              isEditModalVisible={isEditModalVisible}
              handleOk={handleOk}
              handleEditCancel={handleEditCancel}
              editRecord={editRecord}
            />
            <Button
              disabled={!IDC10s?.data?.length}
              icon={<FileExcelOutlined />}
              // onClick={exportToExcel}
              onClick={() =>
                dispatch(
                  putCheckQuyenThaoTacAction(maQuyenXuatExcel, null, actionIsTrue, actionIsFalse),
                )
              }
            >
              Xuất Excel
            </Button>
          </div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            bordered
            loading={isLoadingTable}
            className="border rounded border-gray-200 w-full table-icd10"
            columns={[
              {
                title: 'STT',
                dataIndex: 'STT',
                key: 1,
                width: 60,
                align: 'center',
                render: (text, _, index) => ++index,
              },
              {
                title: 'Mã ICD-10',
                dataIndex: 'mabenh',
                key: 2,
                width: 90,
              },
              {
                title: 'Tên tiếng việt',
                dataIndex: 'icD10TV',
                key: 3,
              },
              {
                title: 'Tên tiếng anh',
                dataIndex: 'icD10TA',
                key: 4,
              },
              {
                title: 'Ghi chú',
                dataIndex: 'ghichu',
                key: 5,
              },
              {
                title: 'Hành động',
                dataIndex: 'ghichu',
                key: 6,
                width: 90,
                align: 'center',
                render: (_, record) => (
                  <ul className="flex gap-3 justify-center">
                    <li
                      onClick={() =>
                        dispatch(
                          putCheckQuyenThaoTacAction(
                            maQuyenXoaIcd,
                            record,
                            actionIsTrue,
                            actionIsFalse,
                          ),
                        )
                      }
                      className="text-red-500 border bg-red-100 rounded hover:bg-red-500 cursor-pointer hover:text-white duration-200"
                    >
                      <DeleteOutlined className="p-1" style={{ fontSize: 16 }} />
                    </li>
                    <li
                      onClick={() =>
                        dispatch(
                          putCheckQuyenThaoTacAction(
                            maQuyenXemCt,
                            record,
                            actionIsTrue,
                            actionIsFalse,
                          ),
                        )
                      }
                      className="text-green-700 border bg-green-100 rounded hover:bg-green-700 cursor-pointer hover:text-white duration-200"
                    >
                      <EditOutlined className="p-1" style={{ fontSize: 16 }} />
                    </li>
                  </ul>
                ),
              },
            ]}
            dataSource={IDC10s?.data}
            rowKey="idicD10" // Ensure this matches the correct field name in your data
            scroll={{ x: 1000, y: '78vh' }}
            pagination={false}
            size="middle"
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default IDC10

import * as typeAction from '../constants/constants'

export const ListXetNghiemAction = () => ({
  type: typeAction.GET_LIST_DANH_MUC_XET_NGHIEM,
})

export const getXetNghiemByCondition = (filter) => ({
  type: typeAction.GET_XET_NGHIEM_BY_CONDITION,
  filter
})

export const ListXetNghiem_Theo_Ct_Action = (idct) => ({
  type: typeAction.GET_LIST_DANH_MUC_XET_NGHIEM_THEO_CT,
  idct,
})

export const ListXetNghiem_Idct_Idnhomxn_Action = (idnhomxn, idct) => ({
  type: typeAction.GET_LIST_DANH_MUC_XET_NGHIEM_THEO_CT_NHOMXN,
  idnhomxn,
  idct,
})
export const listGroupsXetNghiemAction = () => ({
  type: typeAction.GET_LIST_GROUPS_XETNGHIEM,
})
export const searchXetNghiemByGroupsAction = (idGroups) => ({
  type: typeAction.SEARCH_XET_NGHIEM_BY_GRUOPS,
  idGroups,
})
export const getInfoXetNghiemAction = (idxn) => ({
  type: typeAction.GET_INFO_XET_NGHIEM,
  idxn,
})
export const addNewDMXetNghiemAction = (form, onLoad) => ({
  type: typeAction.POST_NEW_DANH_MUC_XET_NGHIEM,
  form,
  onLoad,
})
export const deleteDMXetNghiemAction = (idxn, onLoad) => ({
  type: typeAction.DELETE_DANH_MUC_XET_NGHIEM,
  idxn,
  onLoad,
})
export const updateDMXetNghiemAction = (form, onLoad) => ({
  type: typeAction.UPDATE_DANH_MUC_XET_NGHIEM,
  form,
  onLoad,
})
//------- thông tin bệnh nhân xét ngiệm -----//
export const infoBNXetNghiem = (idbn, idbnttc) => ({
  type: typeAction.GET_INFO_BN_XET_NGHIEM,
  idbn,
  idbnttc,
})
//---------- cập nhật kết quả bệnh nhân xét nghiệm ----------//
export const updateBNXN = (form, onLoad) => ({
  type: typeAction.UPDATE_BN_XET_NGHIEM,
  form,
  onLoad,
})
export const searchDanhMucXNAction = (keyword, idnhomxn) => ({
  type: typeAction.SEARCH_XET_NGHIEM_BY_GROUPS_KEYWORD,
  keyword,
  idnhomxn,
})

export const searchDanhMucXNByIdctAndKeyword = (keyword, idct) => ({
  type: typeAction.SEARCH_XET_NGHIEM_BY_CTY_KEYWORD,
  keyword,
  idct,
})

export const searchDanhMucXNByGroupsAndCty = (keyword, idnhomxn, idct) => ({
  type: typeAction.SEARCH_XET_NGHIEM_BY_GROUPS_AND_CTY_KEYWORD,
  keyword,
  idnhomxn,
  idct,
})

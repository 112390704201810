import JsBarcode from 'jsbarcode'
import moment from 'moment/moment'
import { formatNumberVND } from '../../utils/formatNumberVND'
import { numberToWords } from '../../utils/numberToWords'
import { generateLogo } from '../algorithm'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord } from '../splitWordForReport'

export const generatePhieuThuTiepNhan = (
  benhNhanTTC,
  arrChiDinh,
  benhNhanThanhToan,
  benhNhanChiTietTT = {},
  PkDangNhap,
) => {
  const doc = jsPDFCus('p', 'mm', 'a5')
  let yPos = 10
  let xPos = 8
  let ruleWidth = 55 // for text of price is longer
  const pageWidth = doc.internal.pageSize.width
  //header
  generateLogo(doc, xPos, PkDangNhap)

  //barcode
  JsBarcode('#barcode', benhNhanThanhToan.maPhieuThu, {
    displayValue: false,
  })
  const canvas = document.getElementById('barcode')
  const barcodeDataURL = canvas.toDataURL()
  const barcodeWidth = 35 // Width of the barcode image
  const barcodeX = pageWidth - 45 // X coordinate of the barcode
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 45, 5, 35, 14)
  // Calculate the center position for text
  const text = benhNhanThanhToan.maPhieuThu ?? ''
  const textWidth = (doc.getStringUnitWidth(text) * 11) / doc.internal.scaleFactor // Width of the text
  const textX = barcodeX + (barcodeWidth - textWidth) / 2 // Centered X coordinate for the text
  doc.setFontSize(11)
  doc.text(text, textX, 22)

  doc.setFont('times', 'bold')
  doc.setFontSize(13)
  doc.text('PHIẾU THU TIỀN', doc.internal.pageSize.width / 2, 30, { align: 'center' })

  doc.setFontSize(11)
  doc.text(`STT: ${benhNhanTTC.sttkham ?? ''}`, pageWidth - 50, 32)
  doc.text(benhNhanTTC.tenPhongKham ?? '', pageWidth - 50, 36)

  let textWidthName = doc.getTextDimensions('Họ và tên: ').w
  doc.text(benhNhanTTC.benhNhan.tenBenhNhan, xPos + textWidthName, 42)
  doc.setFont('times', 'regular')
  doc.text('Họ và tên: ', xPos, 42)
  doc.text(`Mã BN: ${benhNhanTTC.benhNhan.maBenhNhan}`, pageWidth - 50, 42)

  const diaChiBenhNhan = `Địa chỉ: ${benhNhanTTC.benhNhan.diaChi ?? ''}${benhNhanTTC.benhNhan.tenPhuongXa ? ', ' + benhNhanTTC.benhNhan.tenPhuongXa : ''}${benhNhanTTC.benhNhan.tenQuanHuyen ? ', ' + benhNhanTTC.benhNhan.tenQuanHuyen : ''}${benhNhanTTC.benhNhan.tenTinhTP ? ', ' + benhNhanTTC.benhNhan.tenTinhTP : ''}`
  if (diaChiBenhNhan.length > 80) {
    const wordsArray = splitLongWord(diaChiBenhNhan, 80)
    doc.text(wordsArray[0], xPos, 47)
    doc.text(wordsArray[1], xPos, 52)
    yPos = 52
  } else {
    doc.text(diaChiBenhNhan, xPos, 47)
    yPos = 47
  }
  yPos = yPos + 5
  if (PkDangNhap.idChuyenKhoa === 1) {
    //lần khám
    doc.text(`Loại khám: ${benhNhanTTC.tenLoaiKham}`, xPos, yPos)
    yPos = yPos + 5
    doc.text(`Số lần đã tái khám: ${benhNhanTTC.soLanDaTaiKham}`, xPos, yPos)
    yPos = yPos + 5
  }

  //table
  const headers = ['STT', 'Tên dịch vụ', 'Đơn vị', 'SL', 'Đơn giá', 'Thành tiền']
  const data = arrChiDinh.map((item, index) => [
    index + 1,
    item.tenChiDinh,
    item.dvt,
    1,
    formatNumberVND(item.dongia),
    formatNumberVND(item.tienThanhToan),
  ])

  // for landscape
  // const columnWidths = [15, 70, 25, 20, 30, 30]  //190
  //for portrait
  const columnWidths = [10, 35, 18, 17, 25, 25]
  doc.autoTable({
    head: [headers],
    body: data,
    startY: yPos,
    columnStyles: {
      // Set custom column widths
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1], halign: 'left' },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3], halign: 'right' },
      4: { cellWidth: columnWidths[4], halign: 'right' },
      5: { cellWidth: columnWidths[5], halign: 'right' },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1, // Set line width for table borders
      fillColor: '#fff', // Set fill color to white for headers and rows
      textColor: '#000', // Set text color to black for headers and rows
      lineColor: '#000',
      halign: 'center',
      valign: 'middle',
      fontStyle: 'regular',
      fontSize: 9,
      // Set line color to black for headers and rows
    },
    headStyles: {
      font: 'times',
      fillColor: '#fff', // Set fill color to white for headers
      textColor: '#000', // Set text color to black for headers
      fontStyle: 'bold', // Set font weight to bold for headers
      fontSize: 10,
    },
    alternateRowStyles: {
      font: 'times',
      fontStyle: 'regular',
      fillColor: '#fff', // Set fill color to white for alternate rows
    },
    showHead: 'firstPage',
    margin: { left: xPos },
  })

  const tongTien = arrChiDinh.reduce((total, item) => total + item.tienThanhToan, 0)
  const data2Column = [
    ['', ''],
    ['TỔNG TIỀN: ', formatNumberVND(tongTien)],
  ]
  doc.autoTable({
    head: [data2Column[0]],
    body: data2Column.slice(1),
    startY: doc.autoTable.previous.finalY,
    columnStyles: {
      // Set custom column widths (portrait)
      0: { cellWidth: 105, halign: 'right' },
      1: { cellWidth: 25, halign: 'right', fontStyle: 'bold' },
      // Set custom column widths (landscape)
      // 0: { cellWidth: 160, halign: 'right' },
      // 1: { cellWidth: 30, halign: 'right', fontStyle: 'bold' },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1, // Set line width for table borders
      fillColor: '#fff', // Set fill color to white for headers and rows
      textColor: '#000', // Set text color to black for headers and rows
      lineColor: '#000',
      valign: 'middle',
      fontStyle: 'bold', // Set font weight to bold for headers
      fontSize: 10,
      // Set line color to black for headers and rows
    },
    headStyles: {
      font: 'times',
      fillColor: '#fff', // Set fill color to white for headers
      textColor: '#000', // Set text color to black for headers
      fontSize: 10,
    },
    alternateRowStyles: {
      font: 'times',
      fontStyle: 'bold',
      fillColor: '#fff', // Set fill color to white for alternate rows
    },
    showHead: 'never',
    margin: { left: xPos },
  })

  doc.setFont('times', 'italic')
  doc.setFontSize(10)
  // landspace: 60, portrait: 40
  if (`Bằng chữ: ${numberToWords(benhNhanThanhToan.tienThanhToan)}.`.length > 40) {
    const wordsArray = splitLongWord(
      `Bằng chữ: ${numberToWords(benhNhanThanhToan.tienThanhToan)}.`,
      40,
    )
    doc.text(wordsArray[0], xPos, doc.autoTable.previous.finalY + 5)
    doc.text(wordsArray[1], xPos, doc.autoTable.previous.finalY + 10)
  } else {
    doc.text(
      `${`Bằng chữ: ${numberToWords(benhNhanThanhToan.tienThanhToan)}.` ?? ''}`,
      xPos,
      doc.autoTable.previous.finalY + 5,
    )
  }

  yPos = doc.autoTable.previous.finalY

  yPos += 5
  const dateText = `Ngày ${moment(benhNhanThanhToan.ngaythanhtoan).format('DD')} tháng ${moment(benhNhanThanhToan.ngaythanhtoan).format('MM')} năm ${moment(benhNhanThanhToan.ngaythanhtoan).format('YYYY')}`
  const doctorText = benhNhanThanhToan.thuNgan ?? ''
  const dateTextWidth = doc.getTextWidth(dateText)
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateTextX = pageWidth - dateTextWidth - 20

  // Calculate the center point of the date text
  const dateCenterX = dateTextX + dateTextWidth / 2

  // Position the doctor text centered below the date text
  const doctorTextX = dateCenterX - doctorTextWidth / 2

  doc.text(dateText, dateTextX, yPos)
  yPos += 5
  doc.setFont('times', 'regular')
  doc.text('Người lập phiếu', pageWidth - 52, yPos)
  doc.setFont('times', 'bold')
  doc.text(doctorText, doctorTextX, yPos + 20)

  doc.setFontSize(8)
  doc.text(
    `(*)Ngày in phiếu: ${moment().format('DD')}/${moment().format('MM')}/${moment().format('YYYY')}`,
    xPos,
    doc.internal.pageSize.height - 5,
  )
  // doc.save('Phieu-thu.pdf')
  // doc.autoPrint()
  // const pdfOutput = doc.output('blob')
  // const url = URL.createObjectURL(pdfOutput)

  // const iframe = document.createElement('iframe')
  // iframe.style.position = 'relative';  // Hoặc 'absolute' nếu cần
  // iframe.style.width = '559px';        // Chiều rộng tương ứng khổ A5
  // iframe.style.height = '794px';       // Chiều cao tương ứng khổ A5
  // iframe.src = url

  // document.body.appendChild(iframe)

  // iframe.onload = function () {
  //   iframe.contentWindow.focus()
  //   iframe.contentWindow.print() // Gửi lệnh in trực tiếp
  //   iframe.contentWindow.onafterprint = function () {
  //     closeLoading();
  //   };
  // }
  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}

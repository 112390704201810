import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Input, Button, Modal, Spin, Checkbox } from 'antd'
import ToastCus from '../../../../../utils/Toast'
import {
  postNhomQuyenAction,
  putNhomQuyenAction,
} from '../../../../../store/actions/phanQuyenAction'

const ModalNhom = ({ isModalNhom, setisModalNhom, infoEditNhom, getListNhom }) => {
  const dispatch = useDispatch()
  const [form, setform] = useState(null)
  const [isLoading, setisLoading] = useState(false)
  const handleSubmit = () => {
    if (!form || !form?.tennhomquyen) {
      if (!form) {
        setform((prev) => ({ ...prev, tennhomquyen: null }))
      }
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    if (infoEditNhom && !infoEditNhom?.idnqht) {
      resetForm()
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    setisLoading(true)
    if (!infoEditNhom || !infoEditNhom?.idnqht) {
      //thêm mới
      dispatch(postNhomQuyenAction(form, resetForm))
    } else if (infoEditNhom?.idnqht) {
      // cập nhật
      dispatch(putNhomQuyenAction({ ...form, idnqht: infoEditNhom?.idnqht }, resetForm))
    }
  }
  const resetForm = () => {
    setform(null)
    setisModalNhom(false)
    setisLoading(false)
    getListNhom()
  }
  useEffect(() => {
    if (isModalNhom && infoEditNhom?.idnqht) {
      setform(infoEditNhom)
    } else {
      setform(null)
    }
  }, [isModalNhom, infoEditNhom])
  return (
    <Modal
      open={isModalNhom}
      onCancel={() => !isLoading && setisModalNhom(false)}
      title={<p className="text-center">{infoEditNhom ? 'Chỉnh sửa' : 'Thêm'}&nbsp;nhóm quyền</p>}
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <Button disabled={isLoading} onClick={() => !isLoading && setisModalNhom(false)}>
            Đóng
          </Button>
          <Button type="primary" loading={isLoading} onClick={handleSubmit}>
            Lưu
          </Button>
        </>
      )}
    >
      <Spin spinning={isLoading}>
        <div className="grid grid-flow-row gap-2">
          <div>
            <div className="font-semibold">
              <span className="text-red-500">(*)</span>&nbsp;Tên nhóm:
            </div>
            <Input
              status={
                form && (!form?.tennhomquyen || !form?.tennhomquyen?.trim()?.length) ? 'error' : ''
              }
              value={form?.tennhomquyen}
              onChange={(e) => setform((prev) => ({ ...prev, tennhomquyen: e.target.value }))}
            />
          </div>
          <div>
            <div className="font-semibold">Ghi chú:</div>
            <Input
              value={form?.ghichu}
              onChange={(e) => setform((prev) => ({ ...prev, ghichu: e.target.value }))}
            />
          </div>
          {infoEditNhom?.idnqht && (
            <div className="flex justify-start items-center gap-2">
              <div className="font-semibold">Sử dụng:</div>
              <Checkbox
                checked={form?.sudung}
                onChange={(e) => setform((prev) => ({ ...prev, sudung: e.target.checked ? 1 : 0 }))}
              />
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  )
}

export default ModalNhom

import { call, put, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'

import ToastCus from '../../utils/Toast'
import { goikhamCRMService } from '../../services/goikhamCRM/goikhamCRMService'

export function* goikhamCRMSaga() {
  yield takeLatest(typeAction.GET_ALL_GOIKHAM_CRM, function* getAllGoikhamCRM({ type, payload }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => goikhamCRMService.getAllGoikhamCRM())
      result.data.sort((a, b) => new Date(a.ngaytao) - new Date(b.ngaytao))
      yield put({
        type: typeAction.DISPATCH_ALL_GOIKHAM_CRM,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  //add
  yield takeLatest(typeAction.ADD_GOIKHAM_CRM, function* addGoikhamCRM({ type, form, onLoad }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      yield call(() => goikhamCRMService.addGoikhamCRM(form))
      onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Thêm mới thành công',
      })
    } catch (err) {
      console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: 'Thêm mới thất bại',
      })
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  //edit
  yield takeLatest(typeAction.EDIT_GOIKHAM_CRM, function* editGoikhamCRM({ type, form, onLoad }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      yield call(() => goikhamCRMService.editGoikhamCRM(form))
      onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Sửa thông tin thành công',
      })
    } catch (err) {
      console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: 'Sửa thông tin thất bại',
      })
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  yield takeLatest(typeAction.GET_GOIKHAM_CRM_BY_ID, function* getById({ type, id }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => goikhamCRMService.getGoikhamCRMById(id))
      const resultListDV = yield call(() => goikhamCRMService.getListDVByIdGK(id))

      yield put({
        type: typeAction.DISPATCH_INFO_GOIKHAM_CRM,
        payload: { ...result.data.result, listDV: [...resultListDV.data.result] },
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  yield takeLatest(typeAction.GET_GOIKHAM_CRM_BY_ID_KH, function* getByIdKH({ type, id }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => goikhamCRMService.getListGoiKhamByIdKH(id))
      result.data.result.sort((a, b) => b.crmGoiKham.tengoikham.localeCompare(a.crmGoiKham.tengoikham));
      yield put({
        type: typeAction.DISPATCH_ALL_GOIKHAM_KHACHHANG_CRM,
        payload: result.data.result,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
}

import ImportExportIcon from '@mui/icons-material/ImportExport'
import PrintIcon from '@mui/icons-material/Print'
import IconButton from '@mui/material/IconButton'
import { Button as AntButton, Input, Modal } from 'antd'
import { useFormik } from 'formik'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import 'moment/locale/vi'
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import { https } from '../../../../services/apiService'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { generatePhieuNhapKho } from '../../../../utils/report/phieuNhapKho'
import InfoPhieu from './InfoPhieu'
import TableDetail from './TableDetail'
moment.locale('vi')

const ModalDetail = ({ isModalOpen, handleCancel }) => {
  const { infoPTNhap, ListThuocVT_update, thuocVT, listDoiTac } = useSelector(
    (state) => state.NhapKhoReducer,
  )
  const [editingKey, setEditingKey] = useState('')
  const [editTable, setEditTable] = useState(false)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const dispatch = useDispatch()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      IdNhapXuat: infoPTNhap?.idNhapXuat,
      MaPhieu: infoPTNhap?.maPhieu,
      TenPhieu: infoPTNhap?.tenPhieu || '',
      IdKhoNhap: infoPTNhap?.idKhoNhap,
      NoiDung: infoPTNhap?.noiDung,
      NhanVienNhan: infoPTNhap?.nhanVienNhan,
      NgayNhan: infoPTNhap?.ngayNhan,
      TrangThai: infoPTNhap?.trangThai,
      IdDoiTac: infoPTNhap?.idDoiTac,
      SoHoaDon: infoPTNhap?.soHoaDon,
      NgayHoaDon: infoPTNhap?.ngayHoaDon || '',
      LinkHoaDon: infoPTNhap?.linkHoaDon || '',
      FileHoaDon: infoPTNhap?.fileHoaDon,
      IdHinhThuc: infoPTNhap?.idHinhThuc,
      IdPhuongThuc: infoPTNhap?.idPhuongThuc,
      GhiChu: infoPTNhap?.ghiChu || '',
    },
  })

  const exportToExcel = () => {
    // Custom column names
    const headers = [
      'STT',
      'Mã hàng',
      'Tên hàng',
      'SL chẵn',
      'Giá chẵn',
      'Quy cách',
      'SL lẻ',
      'Giá lẻ',
      'Tổng lẻ',
      'Tổng tiền',
      'P.Gia công',
      'P.Vận chuyển',
      '% CK trước VAT',
      'Tiền CK trước VAT',
      'Thành tiền',
      '% VAT',
      'Tiền VAT',
      'Thành tiền',
      'Thực trả',
      'Nhiệt độ',
      'MSDK',
      'Số lô',
      'Hạn dùng',
      'Ghi chú',
    ]
    const formattedData = ListThuocVT_update.map((items, idx) => ({
      STT: idx + 1,
      'Mã hàng': items.thuocVatTu?.maThuoc,
      'Tên hàng': items.thuocVatTu?.tenBietDuoc,
      'SL chẵn': Math.floor(items.soLuongLe / items.soLuongDongGoi),
      'Giá chẵn': formattedNumber(items.soLuongDongGoi * items.donGiaMua),
      'Quy cách': items.quyCachDongGoi,
      'SL lẻ': items.countLe,
      'Giá lẻ': formattedNumber(items.donGiaMua),
      'Tổng lẻ': items.soLuongLe,
      'Tổng tiền': formattedNumber(items.soLuongLe * items.donGiaMua),
      'P.Gia công': items.phiGiaCong,
      'P.Vận chuyển': items.phiVanChuyen,
      '% CK trước VAT': items.ptckTruocVat,
      'Tiền CK trước VAT': formattedNumber(Math.round(items.ckTruocVat)),
      'Thành tiền': formattedNumber(items.tongTienTruocVAT),
      '% VAT': items.ptvat,
      'Tiền VAT': formattedNumber(Math.round(items.vat5 + items.vat8 + items.vat10)),
      'Thành tiền': formattedNumber(items.thanhTien),
      'Thực trả': formattedNumber(Math.round(items.thucTra)),
      'Nhiệt độ': items.nhietDo,
      MSDK: items.maSoDangKy,
      'Số lô': items.soLo,
      'Hạn dùng': items.hanDung ? moment(items.hanDung).format('DD/MM/YYYY') : '-',
      'Ghi chú': items.ghiChu,
    }))
    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, `${infoPTNhap?.maPhieu}.xlsx`)
  }

  const handlePrintPhieuNhap = async (infoPTNhap, ListThuocVT_update, PkDangNhap) => {
    if (infoPTNhap.tenKhoNhap === 'Kho vắc xin') {
      try {
        const result = await https.get(
          `ChiNhanh/GetChiNhanhByID?idchinhanh=${infoPTNhap.idChiNhanhNhan}`,
        )
        let clonePK = cloneDeep(PkDangNhap)
        clonePK = {
          ...clonePK,
          idChuyenKhoa: result.data.idChuyenKhoa,
          diaChi: result.data.diaChi,
          dienThoai: result.data.dienThoai,
          sohotline: result.data.sohotline,
        }
        generatePhieuNhapKho(infoPTNhap, ListThuocVT_update, clonePK)
      } catch (error) {
        console.log(error)
      }
    } else {
      generatePhieuNhapKho(infoPTNhap, ListThuocVT_update, PkDangNhap)
    }
  }

  return (
    <>
      <Modal
        className="text-center"
        open={isModalOpen}
        footer={null}
        onCancel={() => {
          handleCancel()
          setEditingKey('')
          setEditTable(false)
        }}
        width={1400}
      >
        <div className="flex gap-2">
          <div className="w-2/3">
            <div className="flex items-center gap-2">
              <h2 className="font-semibold text-base text-start ">{infoPTNhap?.maPhieu}</h2>
              <IconButton
                onClick={() => handlePrintPhieuNhap(infoPTNhap, ListThuocVT_update, PkDangNhap)}
                color="primary"
                aria-label="add to shopping cart"
              >
                <PrintIcon />
              </IconButton>
            </div>
            <div className="text-start">
              <div className="flex items-center mb-1">
                <label className="w-16 text-gray-500 font-semibold">Đối tác: </label>
                <Input variant="borderless" readOnly value={infoPTNhap?.doiTac?.tenDoiTac} />
              </div>
            </div>
            <div className="mt-2">
              <TableDetail
                setEditTable={setEditTable}
                editTable={editTable}
                editingKey={editingKey}
                setEditingKey={setEditingKey}
              />
            </div>
          </div>
          <div className="w-1/3 text-start ">
            <div className="flex gap-2  mt-8 justify-end mb-2">
              <AntButton
                onClick={exportToExcel}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
              >
                Xuất Excel
              </AntButton>
            </div>
            <div className="border rounded-md min-h-[550px]">
              <InfoPhieu formik={formik} />
              <div>
                <ul className="flex gap-2 mb-2 flex-wrap">
                  {infoPTNhap?.fileHoaDon?.split(';').map((url, index) => {
                    const isImage =
                      url.endsWith('.jpg') ||
                      url.endsWith('.jpeg') ||
                      url.endsWith('.png') ||
                      url.endsWith('.gif') ||
                      url.endsWith('.bmp')
                    return (
                      <li key={index} className="relative group ">
                        <a href={url} target="_blank" rel="noopener noreferrer">
                          {isImage ? (
                            <img
                              src={url}
                              alt={`Image ${index + 1}`}
                              className="w-20 transition duration-300 transform group-hover:scale-110 group-hover:z-10"
                            />
                          ) : (
                            <img
                              src="https://www.svgrepo.com/download/144578/pdf.svg"
                              alt={`PDF ${index + 1}`}
                              className="w-20 transition duration-300 transform group-hover:scale-110 group-hover:z-10"
                            />
                          )}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalDetail

import React, { useEffect, useRef, useState, useCallback } from 'react'
import * as TypeAction from '../../../store/constants/constants'
import _ from 'lodash'
import { Input, Select, Divider, Tooltip, Checkbox } from 'antd'
import SyncIcon from '@mui/icons-material/Sync'
import {
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
  SolutionOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  addNewBNTTC_Action,
  getAllSelectClinicAction,
  getInfoBN_Action,
  getListDiscount,
  listPKByChiNhanh_Action,
  removeListDiscount,
  searchBN_Action,
  setChuongTrinhGiamGiaDoiTac,
  getInfoNguoiThan,
} from '../../../store/actions/receiveAction'
import { phanQuyenService } from '../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../store/actions/phanQuyenAction'
import TableHistory from './Table/TableHistory'
import { Button } from '@mui/material'
import TabsChiDinh from './TabsChiDinh/TabsChiDinh'
import TablePay from './Table/TablePay'
import ModalCreateCustomer from './ModalCreateCustomer/ModalCreateCustomer'
import moment from 'moment'
import { useFormik } from 'formik'
import ModalPay from './ModalPay/ModalPay'
import { formatNumberVND } from '../../../utils/formatNumberVND'
import { https } from '../../../services/apiService'
import { receiveSchema } from '../../../schemas/receiveSchema'
import ToastCus from '../../../utils/Toast'
import { recieveService } from '../../../services/receive/recieveService'
import ModalHD from './ModalHD'
import { calculateAge } from '../../../utils/calculateAge'
import 'moment/locale/vi'
import PrinterTTQG from '../../../utils/PrinterTTQG'
import { TTQGPDF } from '../../../utils/TTQGPDF'
// import '../../../App.css'
moment.locale('vi')
const idnhomDV = [1, 2, 3, 4, 6, 21, 22, 24, 25, 42, 46]

const Receive = ({ BnDkkOnline }) => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const barcodeRef = useRef(null)
  const componentRef = useRef()
  const {
    nguonKH,
    phongKham,
    hinhThucTT,
    nguoiThan,
    listBN,
    infoBN,
    discountArr,
    infoChuongTrinhGiamGiaDoiTac,
    ArrXN,
    ArrCĐHA,
    ArrKB,
    paymentCard,
    memberCard,
    listCombo,
    listCTGiamGia,
  } = useSelector((state) => state.receiveReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  // const { ArrXN, ArrCĐHA, ArrKB, paymentCard, memberCard } = useSelector(
  //   (state) => state.receiveReducer,
  // )
  const newArr = ArrKB.concat(ArrXN, ArrCĐHA) // nối các mãng lại với nhau
  const tongThanhToan = newArr.reduce((tong, item) => tong + item.thanhToan, 0)
  const tongGiam = newArr.reduce((tong, item) => tong + item.tienGiam, 0)
  const [openModaCreateCustomer, setOpenModaCreateCustomer] = useState(false)
  const idBranchLogin = Number(JSON.parse(localStorage.getItem('BRANH_LOGIN')))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const [history, setHistory] = useState([])
  const [isModalPay, setIsModalPay] = useState(false)
  const [isPrint, setIsPrint] = useState(false)
  const [listVoucher, setListVoucher] = useState([]) // list chương trình đối tác gotit, bảo hiểm
  const [isDoiTac, setIsDoiTac] = useState(false)
  const [maVoucher, setMaVoucher] = useState('')
  const [maVoucherDoiTac, setMaVoucherDoiTac] = useState('')
  const [valueGiaVoucherDoiTac, setValueGiaVoucherDoiTac] = useState(0)
  const [valueChuongTrinhDoiTac, setValueChuongTrinhDoiTac] = useState('')
  const [hoaDon, setHoaDon] = useState(false)
  const [CTComboApply, setCTComboApply] = useState(null)
  const [CTGiamGiaApply, setCTGiamGiaApply] = useState(null)
  const [listMaVoucher, setListMaVoucher] = useState([]) // list mã voucher ['abc','xyz',...]
  const [listDVTruocApplyVC, setListDVTruocApplyVC] = useState([])
  const [formPay, setFormPay] = useState({
    // idbntt: 0,
    // idbnttc: 0,
    idPhuongThuc: 1,
    // idHinhThuc: 0,
    hoaDonDienTu: 0,
    // maVoucher: 0,
    tienThanhToan: 0,
    idloaitt: 1,
    // ngayThanhToan: "2024-05-08T02:09:46.790Z",
    thuNgan: infoUser?.tenNV,
    tienDichVu: 0,
    tienXetNghiem: 0,
    tienCDHA: 0,
    tienGiamGia: 0,
    // idCaLamViec: infoUser?.dangNhap.idCaLamViec,
    ghiChuXuatHoaDon: '',
  })
  const [form, setForm] = useState({
    // idbntt: 0,
    // idbnttchung: 0,
    idthe: null,
    tienmat: 0,
    chuyenkhoan: 0,
    thett: 0,
    momo: 0,
    vnpay: 0,
    zalopay: 0,
    pos: 0,
    tongtien: 0,
    tiengiamgia: 0,
    tienthanhtoan: 0,
    trangthaithanhtoan: 2,
  })
  const handleChangeCheckbox = (e) => {
    const { checked } = e.target
    formik.setFieldValue('hoaDon', checked ? 1 : 0)
    if (checked) {
      setFormPay({ ...formPay, hoaDonDienTu: 1, ghiChuXuatHoaDon: formik.values.ghiChuHoaDon })
    } else {
      setFormPay({ ...formPay, hoaDonDienTu: 0, ghiChuXuatHoaDon: '' })
    }
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        dispatch(searchBN_Action(keyword?.trim(), ID_COMPANY))
      }
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  // show modal create new customer
  const showCreateCustomer = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT027',
      )
      setOpenModaCreateCustomer(true)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  // cacel show modal create new customer
  const cancelCreateCustomer = () => {
    setOpenModaCreateCustomer(false)
  }
  // handleChange Selected
  const handleChangeSeleted = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  // handle Change info bệnh nhân

  // ---------- lấy danh sach lịch sử khám bệnh  --------//
  const fetchHistory = async (id) => {
    try {
      const { data } = await recieveService.getHitoryBN(id)
      setHistory(data)
    } catch (error) {
      console.log(error)
    }
  }

  // //---------- in mã TCQG----------------
  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=350,width=900')
    printWindow.document.write('<html><head><title>Print</title>')
    printWindow.document.write(`
     <style>
      @media print {
        @page {
          margin: 0; /* Loại bỏ khoảng trắng ngoài */
        }
        body, html {
          // width: 50mm;
          // height: 30mm;
          margin: 0;
          // border: 1px solid black;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          transform: rotate(0deg);
        }
      }
    </style>
    `)
    printWindow.document.write('</head><body>')
    printWindow.document.write(barcodeRef.current.innerHTML) // Sử dụng useRef để lấy nội dung
    printWindow.document.write('</body></html>')
    printWindow.document.close()
    printWindow.print()

    // Đợi một khoảng thời gian ngắn và sau đó tự động đóng cửa sổ in
    setTimeout(() => {
      printWindow.close() // Đóng cửa sổ in
    }, 300) // Thay đổi thời gian nếu cần
    // TTQGPDF(infoBN?.tenBenhNhan,infoBN?.maTCQG || '00000000')
  }
  // hanldle Sumit
  const handleSubmit = (value) => {
    let Arr = [],
      checkKhongDiKham = true
    ArrKB?.map((item) => {
      if (
        idnhomDV.includes(item.dichVu.idNhomDV)
        // item.dichVu.idNhomDV === 1 ||
        // item.dichVu.idNhomDV === 2 ||
        // item.dichVu.idNhomDV === 3 ||
        // item.dichVu.idNhomDV === 4 ||
        // item.dichVu.idNhomDV === 21 ||
        // item.dichVu.idNhomDV === 22 ||
        // item.dichVu.idNhomDV === 24 ||
        // item.dichVu.idNhomDV === 25 ||
        // item.dichVu.idNhomDV === 42 ||
        // item.dichVu.idNhomDV === 46
      )
        checkKhongDiKham = false // là có đi khám

      // Arr.push({
      //   idthe: memberCard?.idbn === infoBN?.idbn ? memberCard?.idthe : null,
      //   idbn: infoBN?.idbn,
      //   tiensd: item.thanhToan,
      //   trangthaisd: 0,
      //   idlt: memberCard?.idlt,
      //   iddv: item.idChiDinh,
      //   idcn: PkDangNhap?.idChiNhanh,
      //   nguoinhan: infoUser?.tenNV,
      //   nguoitt: infoBN?.tenBenhNhan,
      //   ghichu: '',
      // })
    })
    if (
      (ArrKB?.length > 0 && checkKhongDiKham) ||
      (ArrKB?.length === 0 && (ArrXN?.length > 0 || ArrCĐHA?.length > 0))
    )
      value.trangthaikhambenh = 4
    else value.trangthaikhambenh = 1
    value.tenbennhan = infoBN?.tenBenhNhan ?? ''
    dispatch(
      addNewBNTTC_Action(
        value,
        ArrXN,
        ArrCĐHA,
        ArrKB,
        {
          //form api BenhNhanThanhToan
          ...formPay,
          idcn: PkDangNhap?.idChiNhanh,
          idct: PkDangNhap?.idCongTy,
          idchuyenkhoa: PkDangNhap?.idChuyenKhoa,
          idCaLamViec: infoUser?.dangNhap.idCaLamViec,
          thuNgan: infoUser?.tenNV,
          idThuNgan: infoUser?.idnv,
          tienthanhtoan: form.tienthanhtoan, // xanh
          tiengiamgia: tongGiam, // den
          tienGiamGia: tongGiam, // den
          maVoucher: maVoucher?.trim()?.length > 0 ? maVoucher?.trim() : null, // string
          mavoucherdoitac: maVoucherDoiTac?.trim()?.length > 0 ? maVoucherDoiTac?.trim() : null, // string
          listMaVC: listMaVoucher?.length > 0 ? listMaVoucher : null, // array
          chuongtrinhcombo: CTComboApply?.trim()?.length > 0 ? CTComboApply : null, // string
          chuongtrinhgiamgia: CTGiamGiaApply?.trim()?.length > 0 ? CTGiamGiaApply : null, // string

          idctdoitac: infoChuongTrinhGiamGiaDoiTac?.idctDoiTac ?? null,
          tiengiamdoitac: infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0,
        },
        {
          //form api BenhNhanChiTietTT
          ...form,
          idthe: memberCard?.idbn === infoBN?.idbn ? memberCard?.idthe : null,
          tiengiamgia: tongGiam, // den
          tiengiamdoitac: infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0,
        },
        paymentCard,
        isPrint,
        maVoucher,
        maVoucherDoiTac,
      ),
    )
    // dispatch(postListDVApplyTTV_Action(Arr))
    formik.resetForm()
    dispatch({
      type: TypeAction.DISPATCH_INFO_BENH_NHAN,
      payload: null,
    })
    dispatch({
      type: TypeAction.DISPATCH_MEMBER_CARD_RECEIVE,
      payload: null,
    })
    dispatch({
      type: TypeAction.UPDATE_NEW_PRICE_CHI_DINH,
      payload: [],
    })
    dispatch({
      type: TypeAction.DISPATCH_SET_CHUONGTRINH_GIAMGIA_DOITAC,
      payload: null,
    })
    setHistory([])
    setFormPay({
      idPhuongThuc: 1,
      hoaDonDienTu: 0,
      tienThanhToan: 0,
      thuNgan: infoUser?.tenNV,
      tienDichVu: 0,
      tienXetNghiem: 0,
      tienCDHA: 0,
      tienGiamGia: 0,
      idloaitt: 1,
      // idCaLamViec: infoUser?.dangNhap.idCaLamViec,
      ghiChuXuatHoaDon: '',
    })
    setForm({
      // idbntt: 0,
      // idbnttchung: 0,
      idthe: null,
      tienmat: 0,
      chuyenkhoan: 0,
      thett: 0,
      momo: 0,
      vnpay: 0,
      zalopay: 0,
      pos: 0,
      tongtien: 0,
      tiengiamgia: 0,
      tienthanhtoan: 0,
      trangthaithanhtoan: 2,
    })
    setIsDoiTac(false)
    setMaVoucherDoiTac('')
    setValueGiaVoucherDoiTac(0)
    setValueChuongTrinhDoiTac('')
  }
  //############# FORM ############
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbn: infoBN?.idbn,
      chieucao: null,
      cannang: null,
      mach: '',
      huyetap: '',
      nhietdo: '',
      nhiptho: '',
      spO2: '',
      idcnpk: phongKham?.length > 0 ? phongKham[0]?.idcnpk : null,
      // idlankham: 1, // Lần khám set là khám lần đầu
      idnguon: 1, // mặc định nguồn là người nhà
      ghichu: '',
      nguoitao: infoUser?.tenNV,
      ngaykham: '',
      trangthaikhambenh: 1, // trạng thái tiếp nhận khám bệnh
      // idvoucher: 0,
      lydokham: null,
      idcalamviec: infoUser?.dangNhap.idCaLamViec,
      idcn: PkDangNhap?.idChiNhanh,
      idct: PkDangNhap?.idCongTy,
      idchuyenkhoa: PkDangNhap?.idChuyenKhoa,
      nguoinha: '',
      tennguoinha: '',
      dienthoainguoinha: '',
      ghiChuHoaDon: infoBN?.ghiChuHoaDon,
      hoaDon: infoBN?.hoaDon,
      // sottv: '',
      // loaittv: '',
      // tungayttv: null,
      // denngayttv: null,
      // idsudungthe: 0,
      // idloaikham: 1,
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: receiveSchema,
  })
  useEffect(() => {
    dispatch(getAllSelectClinicAction())
    dispatch(listPKByChiNhanh_Action(idBranchLogin))
  }, [])
  // useEffect(() => {
  //   setForm((prevState) => ({
  //     ...prevState,
  //     tienthanhtoan: infoChuongTrinhGiamGiaDoiTac ? tongThanhToan - 0 : tongThanhToan,
  //     tiengiamgia: infoChuongTrinhGiamGiaDoiTac ? tongGiam + 0 : tongGiam,
  //   }))
  // }, [tongThanhToan, tongGiam, infoChuongTrinhGiamGiaDoiTac])

  //auto check xuat hoa don
  // useEffect(() => {
  //   setFormPay({
  //     ...formPay,
  //     thuNgan: infoUser?.tenNV,
  //     ghiChuXuatHoaDon: formik.values.ghiChuHoaDon,
  //     hoaDonDienTu: formik.values.hoaDon,
  //   })
  // }, [infoUser, formik.values.ghiChuHoaDon, formik.values.hoaDon])

  useEffect(() => {
    setFormPay({
      ...formPay,
      thuNgan: infoUser?.tenNV,
    })
  }, [infoUser])

  //set tổng thanh toán
  // useEffect(() => {
  //   const total = infoChuongTrinhGiamGiaDoiTac
  //     ? formPay.tienDichVu + formPay.tienCDHA + formPay.tienXetNghiem - 0
  //     : formPay.tienDichVu + formPay.tienCDHA + formPay.tienXetNghiem
  //   setFormPay((prevState) => ({ ...prevState, tienThanhToan: total }))
  // }, [formPay.tienDichVu, formPay.tienCDHA, formPay.tienXetNghiem, infoChuongTrinhGiamGiaDoiTac])
  // set tổng thanh toán khám bệnh

  const handlePay = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT041',
      )
      if (
        infoBN &&
        newArr.length &&
        ((maVoucher && discountArr.length === 0) ||
          (valueGiaVoucherDoiTac && !infoChuongTrinhGiamGiaDoiTac))
      ) {
        ToastCus.fire({
          icon: 'error',
          title: 'Vui lòng chọn áp dụng voucher hoặc đối tác!',
        })
        return
      }
      setForm({
        ...form,
        tiengiamgia: Number(tongGiam), //+ Number(infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0),
        tienthanhtoan:
          Number(tongThanhToan) - Number(infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0),
      })
      setIsModalPay(true)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const handleApplyVoucher = async () => {
    if (infoChuongTrinhGiamGiaDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập voucher trước khi nhập đối tác!',
      })
      return
    }
    if (tongThanhToan <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Giá trị hóa đơn thanh toán nhỏ hơn giá trị voucher!',
      })
      return
    }
    if (!maVoucher || maVoucher?.trim().length <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Voucher không hợp lệ!',
      })
      return
    }
    // dispatch(applyVoucherAction(ArrKB, temp, {
    //   nguoikichhoat: infoUser.tenNV,
    //   iddt: infoBN?.idDoiTuong ?? -1,
    //   idcn: PkDangNhap?.idChiNhanh,
    // }))

    // call api get list discount || return
    // if (!maVoucher || maVoucher?.trim().length <= 0) return
    try {
      let temp = maVoucher
        ?.trim()
        ?.replaceAll(' ', '')
        ?.replaceAll(';', ',')
        ?.replaceAll('-', ',')
        ?.replaceAll('/', ',')
      setMaVoucher(temp)
      // let dataApply = [...newArr]
      let formatData = newArr?.map((item) => ({
        iddv: item.idChiDinh,
        dongia: item.donGia,
        mavoucher: null, ///////////////
        ptgiam: Math.round(item.ptGiam),
        tiengiam: Math.round(item.tienGiam),
        thanhtien: Math.round(item.thanhToan),
        trangthaitt: 1,
        nguoikichhoat: infoUser.tenNV,
        iddt: infoBN?.idDoiTuong ?? -1,
        idgt:
          ((infoBN?.gioiTinh === `Nam` && 1) ||
            (infoBN?.gioiTinh === `Nữ` && 2) ||
            (infoBN?.gioiTinh === `Khác` && 3)) ??
          -1,
        idcn: PkDangNhap?.idChiNhanh,
        loaivoucherdichvu:
          (item.type === 'KB' && 1) || (item.type === 'XN' && 2) || (item.type === 'CDHA' && 3), /////////
      }))
      setListDVTruocApplyVC(newArr)
      let listMaVC = []
      const vouchers = temp?.split(',')?.filter((vc) => _.isString(vc) && vc?.length > 0)
      if (vouchers?.filter((item, index) => vouchers?.indexOf(item) !== index)?.length > 0) {
        ToastCus.fire({
          icon: 'error',
          title: `Voucher bị trùng!`,
        })
        return
      }
      for (let i = 0; i < vouchers.length; i++) {
        const vc = vouchers[i]
        listMaVC.push(vc)
        formatData = formatData?.map((item) => ({
          ...item,
          mavoucher: vc, // gán mã voucher hiện tại
        }))
        const { data } = await recieveService.postApplyVoucherTiepNhan(formatData) // https.post(`VoucherDanhSach/ApplyVoucher`, formatData);
        formatData = [...data] // cập nhật formatData với kết quả từ API
      }
      setListMaVoucher(listMaVC)
      // dispatch action apply voucher
      dispatch(getListDiscount(formatData))
    } catch (error) {
      console.log(error)
      const message = error?.response?.data?.message
      ToastCus.fire({
        icon: 'error',
        title: (message ? `${message}!\n` : '') + 'Áp dụng voucher thất bại!',
      })
    }

    // const voucherCodes = await temp?.split(',') || [];
    // for (const vc of voucherCodes) {
    //   if (_.isString(vc) && vc?.length > 0) {
    //     formatData = await formatData?.map((item) => ({
    //       ...item,
    //       mavoucher: vc,///////////////
    //     }))
    //     const { data } = await https.post(`VoucherDanhSach/ApplyVoucher`, formatData)//////// formatData
    //     formatData = [...data]
    //     console.log(formatData)
    //   }
    // }
    // console.log('>>>>>>>>>>>>>>>>', formatData)
    // const formatData = newArr?.map((item) => ({
    //   iddv: item.idChiDinh,
    //   dongia: item.donGia,
    //   mavoucher: vc,///////////////
    //   ptgiam: item.ptGiam,
    //   tiengiam: item.tienGiam,
    //   thanhtien: item.thanhToan,
    //   trangthaitt: 1,
    //   nguoikichhoat: infoUser.tenNV,
    //   iddt: infoBN?.idDoiTuong ?? -1,
    //   idcn: PkDangNhap?.idChiNhanh,
    // }))
    // let cloneArrKB = [...ArrKB]
    // cloneArrKB.forEach((item) => {
    //   data.forEach((element) => {
    //     if (item.idChiDinh === element.iddv) {
    //       item.tienGiam = element.giatrigiam
    //       item.giaTienSauKhiGiam = element.giatri
    //       item.ptGiam = element.phantramgiam
    //       item.thanhToan = element.giatri
    //     }
    //   })
    // })
    // formatData = [...cloneArrKB, ...ArrXN, ...ArrCĐHA]
    // // yield put({
    // //   type: typeAction.UPDATE_NEW_PRICE_CHI_DINH,
    // //   payload: listDV,
    // // })
    // dispatch({
    //   type: TypeAction.DISPATCH_SET_ARR_KB,
    //   payload: cloneArrKB,
    // })

    // dispatch action apply voucher
    // dispatch(getListDiscount(formatData))

    // const formatData = newArr.map((item) => ({
    //   iddv: item.idChiDinh,
    //   dongia: item.donGia,
    //   mavoucher: maVoucher?.trim(),
    //   ptgiam: item.ptGiam,
    //   tiengiam: item.tienGiam,
    //   thanhtien: item.thanhToan,
    //   trangthaitt: 1,
    //   nguoikichhoat: infoUser.tenNV,
    //   iddt: infoBN?.idDoiTuong,
    //   idcn: PkDangNhap?.idChiNhanh,
    // }))
    // const { data } = await https.post(`VoucherDanhSach/ApplyVoucher`, dataApply)//////// formatData
    // console.log(data[data.length - 1])
    // if (data?.length > 0 && data[0]?.giatrigiam === 0) {
    //   handleUnApplyVoucher()
    //   ToastCus.fire({
    //     icon: 'error',
    //     title: 'Voucher không hợp lệ!',
    //   })
    //   return
    // }
  }
  const handleNguoiNha = (value) => {
    formik.setFieldValue('nguoinha', value)
    dispatch(getInfoNguoiThan(infoBN?.idbn, value, formik))
  }
  const handleUnApplyVoucher = async () => {
    //call api unuse voucher
    try {
      //https.put(`VoucherDanhSach/UpdateCancelVoucher?mavoucher=${maVoucher}`)
      //dispatch action apply voucher
      dispatch(removeListDiscount(listDVTruocApplyVC))

      // dispatch({
      //   type: TypeAction.DISPATCH_SET_ARR_KB,
      //   payload: listDVTruocApplyVC,
      // })
      // dispatch({
      //   type: TypeAction.UPDATE_NEW_PRICE_CHI_DINH,
      //   payload: [],
      // })
      setListMaVoucher([])
      setMaVoucher('')
    } catch (error) {
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message,
      })
      console.log(error)
    }
  }
  const handleInfoBN = (value) => {
    dispatch(getInfoBN_Action(value, PkDangNhap?.idChuyenKhoa, formik))
    fetchHistory(value)
  }
  const handleChangeDoiTac = (e) => {
    if (!infoBN) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn bệnh nhân!',
      })
      return
    }
    if (!newArr.length) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng thêm chỉ định!',
      })
      return
    }
    if (infoChuongTrinhGiamGiaDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng bỏ sử dụng voucher đối tác',
      })
      return
    }
    setIsDoiTac(e.target.checked)
    if (!e.target.checked) {
      setMaVoucherDoiTac('')
      setValueGiaVoucherDoiTac(0)
      setValueChuongTrinhDoiTac('')
    }
  }

  const handleApplyVoucherDoiTac = () => {
    if (!valueChuongTrinhDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn chương trình đối tác',
      })
      return
    }
    if (!valueGiaVoucherDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập giá trị voucher của đối tác',
      })
      return
    }
    if (tongThanhToan < valueGiaVoucherDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Giá trị hóa đơn thanh toán nhỏ hơn giá trị voucher',
      })
      setMaVoucherDoiTac('')
      setValueGiaVoucherDoiTac(0)
      return
    }
    dispatch(
      setChuongTrinhGiamGiaDoiTac({
        idctDoiTac: valueChuongTrinhDoiTac,
        maVoucher: maVoucherDoiTac,
        giaVoucher: valueGiaVoucherDoiTac,
      }),
    )
  }

  const handleUnApplyVoucherDoiTac = () => {
    dispatch(setChuongTrinhGiamGiaDoiTac(null))
    setIsDoiTac(false)
    setMaVoucherDoiTac('')
    setValueGiaVoucherDoiTac(0)
    setValueChuongTrinhDoiTac('')
  }
  useEffect(() => {
    setListMaVoucher([])
    setMaVoucher('')
    setMaVoucherDoiTac('')
    setIsDoiTac(false)
    setValueGiaVoucherDoiTac(0)
    setValueChuongTrinhDoiTac('')
    dispatch({
      type: TypeAction.UPDATE_NEW_PRICE_CHI_DINH,
      payload: [],
    })
    dispatch({
      type: TypeAction.DISPATCH_SET_CHUONGTRINH_GIAMGIA_DOITAC,
      payload: null,
    })
  }, [infoBN])
  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get(`Voucher/GetChuongTrinhDoiTac`)
        setListVoucher(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  // useEffect(() => {
  //   setForm({ ...form, idthe: memberCard?.idthe ?? null })
  // }, [memberCard])///////////////////////////////////////////////////////////////////////

  const setMaCTComboApply = (mact) => {
    setCTComboApply((prev) => {
      if (!prev || prev?.trim()?.length === 0) return mact
      else if (!prev.includes(mact)) return `${prev},${mact}`
      return prev
    })
  }
  const setMaCTGGApply = (mact) => {
    setCTGiamGiaApply((prev) => {
      if (!prev || prev?.trim()?.length === 0) return mact
      else if (!prev.includes(mact)) return `${prev},${mact}`
      return prev
    })
  }
  const applyCTGiamGia = async (arrkb, arrxn, arrcdha) => {
    if (listCTGiamGia?.length > 0) {
      let listDVGiam = [],
        listDVKhongGiam = [],
        dvgiam = null,
        ptgiamNew = 0,
        daGiam = false
      //////////////////////////////////////////////////////
      if (arrkb?.length > 0) {
        listDVGiam = []
        listDVKhongGiam = []
        for (const itemKB of arrkb) {
          daGiam = false
          if (!itemKB?.loaithedichvu && !itemKB?.taikham && itemKB?.ptGiam === 0) {
            for (const itemCT of listCTGiamGia) {
              if (
                (itemCT?.IDTTDT === 0 ||
                  (itemCT?.IDTTDT === 1 &&
                    infoBN &&
                    itemCT?.DanhSachDoiTuong?.find(
                      (itemDT) => itemDT?.iddt === infoBN?.idDoiTuong,
                    ))) &&
                itemCT?.DanhSachChiDinh?.find(
                  (itemCD) => itemCD?.loaivoucherdichvu === 1 && itemCD?.iddv === itemKB?.idChiDinh,
                )
              ) {
                daGiam = true
                //ptgiamNew = (itemKB?.ptGiam ?? 0) + (itemCT?.GIATRI ?? 0)
                listDVGiam.push({
                  ...itemKB,
                  ptGiam: itemCT?.GIATRI,
                  tienGiam: (itemKB?.donGia * itemCT?.GIATRI) / 100,
                  thanhToan: (itemKB?.donGia * (100 - itemCT?.GIATRI)) / 100,
                  ctgg: itemCT,
                })
                setMaCTGGApply(itemCT?.MACT)
                // setCTGiamGiaApply((prev) => {
                //   if (!prev || prev?.trim().length === 0) return itemCT?.MACT
                //   else return `${prev}, ${itemCT?.MACT}`
                // })
                break
              }
            }
          }
          if (!daGiam) listDVKhongGiam.push(itemKB)
        }
        if (listDVGiam?.length > 0) {
          arrkb = listDVGiam.concat(listDVKhongGiam)
        }
      }
      //////////////////////////////////////////////////////
      if (arrxn?.length > 0) {
        listDVGiam = []
        listDVKhongGiam = []
        for (const itemXB of arrxn) {
          daGiam = false
          if (!itemXB?.loaithedichvu && !itemXB?.taikham && itemXB?.ptGiam === 0) {
            for (const itemCT of listCTGiamGia) {
              if (
                (itemCT?.IDTTDT === 0 ||
                  (infoBN &&
                    itemCT?.IDTTDT === 1 &&
                    itemCT?.DanhSachDoiTuong?.find(
                      (itemDT) => itemDT?.iddt === infoBN?.idDoiTuong,
                    ))) &&
                itemCT?.DanhSachChiDinh?.find(
                  (itemCD) => itemCD?.loaivoucherdichvu === 2 && itemCD?.iddv === itemXB?.idChiDinh,
                )
              ) {
                daGiam = true
                //ptgiamNew = (itemXB?.ptGiam ?? 0) + (itemCT?.GIATRI ?? 0)
                listDVGiam.push({
                  ...itemXB,
                  ptGiam: itemCT?.GIATRI,
                  tienGiam: (itemXB?.donGia * itemCT?.GIATRI) / 100,
                  thanhToan: (itemXB?.donGia * (100 - itemCT?.GIATRI)) / 100,
                  ctgg: itemCT,
                })
                setMaCTGGApply(itemCT?.MACT)
                break
              }
            }
          }
          if (!daGiam) listDVKhongGiam.push(itemXB)
        }
        if (listDVGiam?.length > 0) {
          arrxn = listDVGiam.concat(listDVKhongGiam)
        }
      }
      //////////////////////////////////////////////////////
      if (arrcdha?.length > 0) {
        listDVGiam = []
        listDVKhongGiam = []
        for (const itemCDHA of arrcdha) {
          daGiam = false
          if (!itemCDHA?.loaithedichvu && !itemCDHA?.taikham && itemCDHA?.ptGiam === 0) {
            for (const itemCT of listCTGiamGia) {
              if (
                (itemCT?.IDTTDT === 0 ||
                  (infoBN &&
                    itemCT?.IDTTDT === 1 &&
                    itemCT?.DanhSachDoiTuong?.find(
                      (itemDT) => itemDT?.iddt === infoBN?.idDoiTuong,
                    ))) &&
                itemCT?.DanhSachChiDinh?.find(
                  (itemCD) =>
                    itemCD?.loaivoucherdichvu === 3 && itemCD?.iddv === itemCDHA?.idChiDinh,
                )
              ) {
                daGiam = true
                //ptgiamNew = (itemCDHA?.ptGiam ?? 0) + (itemCT?.GIATRI ?? 0)
                listDVGiam.push({
                  ...itemCDHA,
                  ptGiam: itemCT?.GIATRI,
                  tienGiam: (itemCDHA?.donGia * itemCT?.GIATRI) / 100,
                  thanhToan: (itemCDHA?.donGia * (100 - itemCT?.GIATRI)) / 100,
                  ctgg: itemCT,
                })
                setMaCTGGApply(itemCT?.MACT)
                break
              }
            }
          }
          if (!daGiam) listDVKhongGiam.push(itemCDHA)
        }
        if (listDVGiam?.length > 0) {
          arrcdha = listDVGiam.concat(listDVKhongGiam)
        }
      }
    }
    dispatch({
      type: TypeAction.DISPATCH_SET_ARR_KB,
      payload: arrkb,
    })
    dispatch({
      type: TypeAction.DISPATCH_SET_ARR_XN,
      payload: arrxn,
    })
    dispatch({
      type: TypeAction.DISPATCH_SET_ARR_CDHA,
      payload: arrcdha,
    })
  }
  const handleApplyChuongTrinh = async () => {
    setCTComboApply(null)
    setCTGiamGiaApply(null)
    let arrCD = await ArrKB.concat(ArrXN, ArrCĐHA)?.map(
      (item) =>
        !item?.loaithedichvu && !item?.taikham
          ? {
              ...item,
              ptGiam: 0,
              tienGiam: 0,
              thanhToan: item.donGia,
              combo: null,
              ctgg: null,
            }
          : { ...item },
      //||((item?.loaithedichvu || item?.taikham) && { ...item })
    )
    if (listCombo?.length > 0) {
      let listDVGiam = [],
        listDVTruocGiam = [],
        listDVKhongGiam = []
      for (const itemCB of listCombo) {
        if (
          itemCB?.DanhSachDichVu?.length > 0 &&
          arrCD?.length >= itemCB?.DanhSachDichVu?.length &&
          (itemCB?.IDTTDT === 0 ||
            (itemCB?.IDTTDT === 1 &&
              infoBN &&
              itemCB?.DanhSachDoiTuong?.find((itemDT) => itemDT?.iddt === infoBN?.idDoiTuong)))
        ) {
          for (const itemCD of arrCD) {
            let loaiDv =
              (itemCD?.type === 'KB' && 1) ||
              (itemCD?.type === 'XN' && 2) ||
              (itemCD?.type === 'CDHA' && 3)
            const index = itemCB?.DanhSachDichVu?.findIndex(
              (dvCB) => dvCB?.iddv === itemCD?.idChiDinh && dvCB?.loaivoucherdichvu === loaiDv,
            )
            if (index === -1) listDVKhongGiam.push(itemCD)
            else {
              //listDVTruocGiam.push(itemCD)
              if (!itemCD?.loaithedichvu && !itemCD?.taikham) {
                listDVGiam.push({
                  ...itemCD,
                  ptGiam: itemCB.GIATRI,
                  tienGiam: (itemCD.donGia * itemCB.GIATRI) / 100,
                  thanhToan: (itemCD.donGia * (100 - itemCB.GIATRI)) / 100,
                  combo: itemCB,
                })
              } else listDVGiam.push({ ...itemCD, combo: itemCB })
            }
          }
          // if (itemCB?.DanhSachDichVu?.length !== listDVGiam?.length) {
          //   listDVTruocGiam = []
          // } else
          if (itemCB?.DanhSachDichVu?.length === listDVGiam?.length) {
            setMaCTComboApply(itemCB?.MACT)
            arrCD = listDVGiam.concat(listDVKhongGiam)
          }
        }
        listDVGiam = []
        listDVKhongGiam = []
      }
      // dispatch({
      //   type: TypeAction.DISPATCH_SET_ARR_KB,
      //   payload: arrCD?.filter((item) => item?.type === 'KB'),
      // })
      // dispatch({
      //   type: TypeAction.DISPATCH_SET_ARR_XN,
      //   payload: arrCD?.filter((item) => item?.type === 'XN'),
      // })
      // dispatch({
      //   type: TypeAction.DISPATCH_SET_ARR_CDHA,
      //   payload: arrCD?.filter((item) => item?.type === 'CDHA'),
      // })
    }
    applyCTGiamGia(
      arrCD?.filter((item) => item?.type === 'KB'),
      arrCD?.filter((item) => item?.type === 'XN'),
      arrCD?.filter((item) => item?.type === 'CDHA'),
    )
    // else if (comboDangApDung && comboDangApDung?.DVTruocGiam?.length > 0) await unApplyCombo()
  }
  useEffect(() => {
    handleApplyChuongTrinh()
  }, [ArrKB?.length, ArrXN?.length, ArrCĐHA?.length]) // ArrKB?.length, ArrXN?.length, ArrCĐHA?.length

  const handleClickUpdateXHD = async () => {
    try {
      await https.put('BenhNhan', {
        idbn: infoBN?.idbn,
        hoaDon: formik.values.hoaDon,
        ghiChuHoaDon: formik.values.ghiChuHoaDon,
      })
      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật thông tin xuất hóa đơn thành công',
      })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (BnDkkOnline) handleInfoBN(BnDkkOnline)
  }, [BnDkkOnline]) // ArrKB?.length, ArrXN?.length, ArrCĐHA?.length

  return (
    <>
      <div className="h-full">
        <form className="flex gap-1 h-full" action="">
          <div className="rounded-lg flex flex-col h-full w-3/5 border ">
            <div className="flex items-start gap-2 rounded-lg m-2 h-[25px]">
              <div className="mt-1">
                <Tooltip color="#5BBCFF" title="Tạo bệnh nhân">
                  <button
                    type="button"
                    onClick={showCreateCustomer}
                    className="group cursor-pointer outline-none hover:rotate-90 duration-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25px"
                      height="25px"
                      viewBox="0 0 24 24"
                      className="stroke-blue-500 fill-none  group-active:stroke-blue-200 group-active:fill-blue-600 group-active:duration-0 duration-300"
                    >
                      <path
                        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                        strokeWidth="1.5"
                      ></path>
                      <path d="M8 12H16" strokeWidth="1.5"></path>
                      <path d="M12 16V8" strokeWidth="1.5"></path>
                    </svg>
                  </button>
                </Tooltip>
              </div>
              <Select
                onSearch={debounceDropDown}
                value={null}
                onChange={handleInfoBN}
                filterOption={false}
                notFoundContent={null}
                placeholder="Tìm kiếm Tên BN, Mã BN, SĐT, CCCD, Mã TTV..."
                suffixIcon={<SearchOutlined className=" " />}
                options={listBN?.map((items) => ({
                  label: items?.tenbenhnhan,
                  value: items?.idbn,
                  MaBN: items?.mabenhnhan,
                  ngaySinh: items?.ngaysinh ? moment(items?.ngaysinh).format('DD/MM/YYYY') : '',
                  diaChi: items?.diachi,
                  tinh: items?.tentinhtp,
                  quan: items?.tenquanhuyen,
                  xa: items?.tenphuongxa,
                  gioiTinh: items?.gioitinh,
                  // mathe: items?.mathe,
                }))}
                optionRender={(options) => (
                  <ul className="flex">
                    <li className="w-5/6">
                      <p className="font-semibold">
                        {options?.data?.label} - {options?.data?.MaBN}
                        {/* {options?.data?.mathe ? ` - TTV: ${options?.data?.mathe}` : ''} */}
                      </p>
                      <i className="text-gray-500">
                        Địa chỉ: {options?.data?.diaChi}
                        {options?.data?.xa && `, ${options?.data?.xa}`}
                        {options?.data?.quan && `, ${options?.data?.quan}`}
                        {options?.data?.tinh && `, ${options?.data?.tinh}`}
                      </i>
                    </li>
                    <li className="w-1/6 flex flex-col">
                      <p>Ngày sinh: {options?.data?.ngaySinh} </p>
                      <i className="text-gray-500">Giới tính: {options?.data?.gioiTinh}</i>
                    </li>
                  </ul>
                )}
                showSearch
                allowClear
                className="w-full"
              />
            </div>
            <div className="h-full flex flex-col gap-1">
              <div className="h-1/2 flex justify-center items-center bg-white rounded-lg">
                <div className="flex flex-col gap-2 p-2 rounded-lg bg-white">
                  <div className="flex  gap-5">
                    <div className="flex w-1/3 gap-1 items-center">
                      <label className="w-1/4 text-end font-semibold">Họ và tên:</label>
                      <Input
                        className="w-3/4"
                        readOnly
                        variant="filled"
                        value={infoBN?.tenBenhNhan}
                        size="small"
                        suffix={
                          infoBN?.idbn && (
                            <SyncIcon
                              sx={{ fontSize: 22 }}
                              onClick={() => {
                                handleInfoBN(infoBN?.idbn)
                              }}
                              className=" text-blue-500 cursor-pointer"
                            />
                          )
                        }
                      />
                    </div>
                    <div className="flex w-1/3 gap-1 items-center">
                      <label className=" w-1/4 text-end font-semibold">Mã BN:</label>
                      <Input
                        className="w-3/4"
                        readOnly
                        variant="filled"
                        value={infoBN?.maBenhNhan}
                        size="small"
                        suffix={
                          infoBN && (
                            <SolutionOutlined className="text-lg text-blue-500 hover:text-xl cursor-pointer duration-200" />
                          )
                        }
                      />
                    </div>
                    <div className="flex w-1/3 gap-1 items-center">
                      <label className="w-1/4 text-end font-semibold">SĐT:</label>
                      <Input
                        className="w-3/4"
                        readOnly
                        variant="filled"
                        value={infoBN?.dienThoai}
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="flex  gap-2">
                    <div className="flex w-1/3 gap-1 items-center">
                      <label className="w-1/4 text-end font-semibold">Mã TCQG:</label>
                      <div
                        className="barcode-container"
                        ref={barcodeRef}
                        style={{ display: 'none' }}
                      >
                        <PrinterTTQG
                          name={infoBN?.tenBenhNhan}
                          barcode={infoBN?.maTCQG || '00000000'}
                        />
                      </div>
                      <Input
                        className="w-3/4"
                        readOnly
                        variant="filled"
                        value={infoBN?.maTCQG}
                        size="small"
                        suffix={
                          infoBN && (
                            <PrinterOutlined
                              onClick={handlePrint}
                              className="text-lg text-blue-500 hover:text-xl cursor-pointer duration-200"
                            />
                          )
                        }
                      />
                    </div>
                    <div className="flex w-1/3 gap-1 items-center">
                      <label className="w-1/4 text-end font-semibold">Đối tượng:</label>
                      <Input
                        className="w-3/4"
                        readOnly
                        variant="filled"
                        value={infoBN?.tenDoiTuong}
                        size="small"
                      />
                    </div>
                    <div className="flex w-1/3 gap-1 items-center">
                      <label className="w-1/4 text-end font-semibold">Dân tộc:</label>
                      <Input
                        className="w-3/4"
                        readOnly
                        variant="filled"
                        value={infoBN?.tenDanToc}
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <div className="flex w-1/3 gap-1 items-center">
                      <label className="w-1/4 text-end font-semibold">Giới tính:</label>
                      <Input
                        className="w-3/4"
                        readOnly
                        variant="filled"
                        value={infoBN?.gioiTinh}
                        size="small"
                      />
                    </div>
                    <div className="flex w-1/3 gap-1">
                      <label className="w-1/4 text-end font-semibold ">Ngày sinh:</label>
                      <Input
                        className="w-3/4"
                        readOnly
                        variant="filled"
                        value={infoBN?.ngaySinh && moment(infoBN?.ngaySinh).format('DD/MM/YYYY')}
                        size="small"
                      />
                    </div>
                    <div className="flex w-1/3 gap-1">
                      <label className="w-1/4 text-end font-semibold">Tuổi:</label>
                      <Input
                        className="w-3/4"
                        readOnly
                        variant="filled"
                        value={infoBN?.ngaySinh && calculateAge(infoBN?.ngaySinh)}
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <div className="flex w-1/3 gap-1 items-center">
                      <label className="w-1/4 text-end font-semibold">Tỉnh/TP:</label>
                      <Input
                        className="w-3/4"
                        readOnly
                        variant="filled"
                        value={infoBN?.tenTinhTP}
                        size="small"
                      />
                    </div>
                    <div className="flex w-1/3 gap-1">
                      <label className="w-1/4 text-end font-semibold">Q.Huyện:</label>
                      <Input
                        className="w-3/4"
                        readOnly
                        variant="filled"
                        value={infoBN?.tenQuanHuyen}
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 w-1/3">
                      <label className="w-1/4 text-end font-semibold">X.Phường:</label>
                      <Input
                        className="w-3/4"
                        readOnly
                        variant="filled"
                        value={infoBN?.tenPhuongXa}
                        size="small"
                      />
                    </div>
                  </div>

                  <div className="flex gap-3 ">
                    <div className="flex w-2/3 gap-1 items-center">
                      <label className="w-[12%] text-end font-semibold">Địa chỉ:</label>
                      <Input
                        className="w-[88%]"
                        readOnly
                        variant="filled"
                        value={infoBN?.diaChi}
                        size="small"
                      />
                    </div>
                    <div className="flex w-1/3 gap-1 items-center">
                      <label className="w-[27%] text-end font-semibold">Loại khám:</label>
                      <Input
                        className="w-[73%]"
                        readOnly
                        variant="filled"
                        value={
                          infoBN?.idLoaiKham === 2
                            ? 'Tái Khám'
                            : infoBN?.idLoaiKham === 4
                              ? 'Khám bệnh quá ngày Tái khám'
                              : infoBN?.idLoaiKham === 1
                                ? 'Khám lần đầu'
                                : 'Bệnh mới'
                        }
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <div className="flex w-1/3 gap-1 items-center">
                      <label className="w-1/4 text-end font-semibold">Số TTV:</label>
                      <Input
                        className="w-3/4"
                        styles={{ color: 'black' }}
                        value={memberCard?.mathe}
                        readOnly
                        variant="filled"
                        size="small"
                        // addonAfter={
                        //   <PlusOutlined className="text-lg text-green-700  cursor-pointer" />
                        // }
                      />
                    </div>
                    <div className="flex gap-1 w-1/3">
                      <label className="w-1/4 text-end font-semibold">Loại TTV:</label>
                      <Input
                        className="w-3/4"
                        readOnly
                        variant="filled"
                        value={memberCard?.tenloaithe}
                        size="small"
                      />
                    </div>
                    <div className="flex justify-start w-1/3">
                      <div className="w-1/4 flex gap-1 justify-end">
                        <label className="font-semibold">Từ:</label>
                      </div>
                      <div className="w-3/4 flex gap-1 pl-1">
                        <Input
                          size="small"
                          className="w-2/5"
                          readOnly
                          variant="filled"
                          value={
                            memberCard?.tungay
                              ? moment(memberCard?.tungay).format('DD/MM/YYYY')
                              : ''
                          }
                        />
                        <label className="w-1/5 text-end font-semibold">Đến:</label>
                        <Input
                          size="small"
                          className="w-2/5"
                          readOnly
                          variant="filled"
                          value={
                            memberCard?.denngay
                              ? moment(memberCard?.denngay).format('DD/MM/YYYY')
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <Divider
                    style={{
                      margin: 2,
                    }}
                    dashed
                  />
                  <div className="flex gap-5">
                    <div className="flex gap-1 w-1/3 ">
                      <label className="w-1/4 text-end">Người nhà:</label>
                      <Select
                        onChange={handleNguoiNha}
                        value={formik.values.nguoinha}
                        options={nguoiThan?.map((items) => ({
                          label: items.ten,
                          value: items.ten,
                        }))}
                        className="w-3/4"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 w-1/3 ">
                      <label className="w-1/4 text-end">Họ và tên:</label>
                      <Input
                        onChange={formik.handleChange}
                        value={formik.values.tennguoinha}
                        name="tennguoinha"
                        className="w-3/4"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 w-1/3 ">
                      <label className="w-1/4  text-end ">SĐT:</label>
                      <Input
                        onChange={formik.handleChange}
                        value={formik.values.dienthoainguoinha}
                        name="dienthoainguoinha"
                        className="w-3/4"
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <div className="flex w-1/3 gap-1 items-center">
                      <label className="w-1/4 text-end">
                        <span className="text-red-500">*</span> Lý do:
                      </label>
                      <Input
                        status={formik.errors.lydokham ? 'error' : ''}
                        onChange={formik.handleChange}
                        value={formik.values.lydokham}
                        name="lydokham"
                        placeholder="Lý do khám bệnh"
                        className="w-3/4"
                        size="small"
                      />
                    </div>
                    <div className="flex w-1/3 gap-1 items-center">
                      <label className="w-1/4 text-end">Nguồn:</label>
                      <Select
                        onChange={handleChangeSeleted('idnguon')}
                        value={formik.values.idnguon}
                        options={nguonKH?.map((items) => ({
                          value: items.idNguonKH,
                          label: items.nguon,
                        }))}
                        className="w-3/4"
                        size="small"
                      />
                    </div>
                    <div className="flex w-1/3 gap-1 items-center">
                      <label className="w-1/4 text-end">
                        <span className="text-red-500">*</span> P.Khám:
                      </label>
                      <Select
                        status={formik.errors.idcnpk ? 'error' : ''}
                        // defaultValue={}
                        value={formik.values.idcnpk}
                        onChange={handleChangeSeleted('idcnpk')}
                        className="w-3/4"
                        options={phongKham?.map((items) => ({
                          value: items.idcnpk,
                          label: items.tenPhongKham,
                        }))}
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex gap-1 w-1/4 ">
                      <label className="w-1/3 text-end">SpO2:</label>
                      <Input
                        onChange={formik.handleChange}
                        value={formik.values.spO2}
                        name="spO2"
                        className="w-2/3"
                        addonAfter="%"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 w-1/4 ">
                      <label className="w-1/3 text-end">Huyết áp:</label>
                      <Input
                        // type="Number"
                        onChange={formik.handleChange}
                        value={formik.values.huyetap}
                        name="huyetap"
                        className="w-2/3"
                        addonAfter="mmHg"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 w-1/4 ">
                      <label className="w-1/3 text-end">Nhịp thở:</label>
                      <Input
                        // type="Number"
                        onChange={formik.handleChange}
                        value={formik.values.nhiptho}
                        name="nhiptho"
                        className="w-2/3"
                        addonAfter="Lần/p"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 w-1/4 ">
                      <label className="w-1/3 text-end">Mạch:</label>
                      <Input
                        // type="Number"
                        onChange={formik.handleChange}
                        value={formik.values.mach}
                        name="mach"
                        className="w-2/3"
                        addonAfter="Lần/p"
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex gap-1 w-1/4 ">
                      <label className="w-1/3  text-end ">STT:</label>
                      <Input disabled className="w-2/3" size="small" />
                    </div>
                    <div className="flex gap-1 w-1/4 ">
                      <label className="w-1/3 text-end">Cân nặng:</label>
                      <Input
                        type="Number"
                        value={formik.values.cannang}
                        name="cannang"
                        onChange={(e) => {
                          const value = e.target.value === '' ? null : Number(e.target.value)
                          formik.setFieldValue('cannang', value)
                        }}
                        className="w-2/3"
                        addonAfter="Kg"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 w-1/4 ">
                      <label className="w-1/3 text-end">Chiều cao:</label>
                      <Input
                        type="Number"
                        value={formik.values.chieucao}
                        name="chieucao"
                        onChange={(e) => {
                          const value = e.target.value === '' ? null : Number(e.target.value)
                          formik.setFieldValue('chieucao', value)
                        }}
                        className="w-2/3"
                        addonAfter="Cm"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 w-1/4 ">
                      <label className="w-1/3  text-end ">Nhiệt độ:</label>
                      <Input
                        // type="Number"
                        value={formik.values.nhietdo}
                        onChange={formik.handleChange}
                        name="nhietdo"
                        className="w-2/3"
                        addonAfter={<>&#8451;</>}
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="flex w-full gap-1 items-center">
                      <label className="w-[9%] text-end">Ghi chú: </label>
                      <Input
                        value={formik.values.ghichu}
                        onChange={formik.handleChange}
                        name="ghichu"
                        size="small"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-1/2 bg-white rounded-lg">
                <TableHistory history={history} />
              </div>
            </div>
          </div>
          <div className="w-2/5 h-full bg-white pl-1 rounded-lg">
            <div className="h-[55%] ">
              <div className="border  rounded-md">
                <TablePay newArr={newArr} />
              </div>
              <div className="flex flex-col w-full gap-2 py-3">
                {CTComboApply && (
                  <>
                    <div className="flex w-full items-center justify-start">
                      <div className="text-sm w-[107px] font-semibold text-end">Combo:</div>
                      <div className="pl-1">{CTComboApply}</div>
                    </div>
                  </>
                )}
                {CTGiamGiaApply && (
                  <>
                    <div className="flex w-full items-center justify-start">
                      <div className="text-sm w-[107px] font-semibold text-end">CT giảm giá:</div>
                      <div className="pl-1">{CTGiamGiaApply}</div>
                    </div>
                  </>
                )}
                {/* voucher đối tác */}
                <div className="flex gap-5 items-center">
                  <div className="flex w-full gap-1 items-center">
                    <label
                      className="text-sm w-[107px] font-semibold text-end"
                      // onClick={() => {
                      //   console.log(comboDangApDung)
                      // }}
                    >
                      Đối tác:
                    </label>
                    <Checkbox checked={isDoiTac} onChange={handleChangeDoiTac} />
                    {isDoiTac ? (
                      <Select
                        disabled={!newArr.length || infoChuongTrinhGiamGiaDoiTac}
                        style={{ color: 'black' }}
                        className="w-[12.1rem]"
                        size="small"
                        value={valueChuongTrinhDoiTac}
                        options={listVoucher?.map((items) => ({
                          label: items.tenct,
                          value: items.idct,
                        }))}
                        onChange={(value) => setValueChuongTrinhDoiTac(value)}
                      />
                    ) : null}
                  </div>

                  <div className="flex w-full gap-1 items-center">
                    {isDoiTac ? (
                      <>
                        <label className="text-sm font-semibold text-end">Giá:</label>
                        <Input
                          disabled={!newArr.length || infoChuongTrinhGiamGiaDoiTac}
                          style={{ color: 'black' }}
                          size="small"
                          type="number"
                          step={1}
                          min={0}
                          value={valueGiaVoucherDoiTac}
                          onChange={(e) =>
                            setValueGiaVoucherDoiTac(
                              Number(e.target.value ?? 0) > Number(tongThanhToan)
                                ? Number(tongThanhToan)
                                : Number(e.target.value ?? 0),
                            )
                          }
                          className="w-[120px] text-right"
                          // value={maVoucher}
                        />
                      </>
                    ) : null}
                  </div>
                </div>
                {isDoiTac && (
                  <div className="flex gap-5">
                    <div className="flex w-1/2 gap-1">
                      <label className="text-sm font-semibold w-1/3 text-end">
                        Voucher đối tác:
                      </label>
                      <Input
                        disabled={!newArr.length || infoChuongTrinhGiamGiaDoiTac}
                        style={{ color: 'black' }}
                        size="small"
                        className="w-2/3"
                        value={maVoucherDoiTac}
                        onChange={(e) => setMaVoucherDoiTac(e.target.value)}
                      />
                    </div>
                    <div className="w-1/2 flex gap-5">
                      <div className="w-1/2 ">
                        <ul className="flex justify-around">
                          {newArr.length &&
                          infoBN &&
                          valueGiaVoucherDoiTac > 0 &&
                          !infoChuongTrinhGiamGiaDoiTac ? (
                            <li
                              onClick={handleApplyVoucherDoiTac}
                              className="bg-green-200 text-green-700 hover:bg-green-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
                            >
                              <CheckOutlined />
                            </li>
                          ) : null}
                          {infoChuongTrinhGiamGiaDoiTac ? (
                            <li
                              onClick={handleUnApplyVoucherDoiTac}
                              className="bg-red-200 text-red-700 hover:bg-red-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
                            >
                              <CloseOutlined />
                            </li>
                          ) : null}
                        </ul>
                      </div>
                      <div className="text-red-500 text-xl font-semibold w-1/2 text-end">
                        {/* -
                      {formatNumberVND(
                        infoChuongTrinhGiamGiaDoiTac && +infoChuongTrinhGiamGiaDoiTac?.giaVoucher,
                      )}{' '}
                      VNĐ */}
                      </div>
                    </div>
                  </div>
                )}
                {/* voucher */}
                <div className="flex gap-5">
                  <div className="flex w-1/2 gap-1 items-center">
                    <label className="text-sm font-semibold w-1/3 text-end flex items-center justify-end">
                      {/* <span className="mr-2 bg-green-200 text-green-700 hover:bg-green-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6">
                        <CheckOutlined />
                      </span> */}
                      Voucher:
                    </label>
                    <Input
                      disabled={!newArr.length || discountArr.length}
                      style={{ color: 'black' }}
                      size="small"
                      className="w-2/3"
                      value={maVoucher}
                      onChange={(e) => setMaVoucher(e.target.value)}
                    />
                  </div>
                  <div className="w-1/2 flex gap-5">
                    <div className="w-1/2 ">
                      <ul className="flex justify-around">
                        {maVoucher && infoBN && newArr.length && !discountArr.length ? (
                          <li
                            onClick={handleApplyVoucher}
                            className="bg-green-200 text-green-700 hover:bg-green-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
                          >
                            <CheckOutlined />
                          </li>
                        ) : null}
                        {discountArr.length ? (
                          <li
                            onClick={handleUnApplyVoucher}
                            className="bg-red-200 text-red-700 hover:bg-red-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
                          >
                            <CloseOutlined />
                          </li>
                        ) : null}
                      </ul>
                    </div>
                    <div className="text-red-500 text-xl font-semibold w-1/2 text-end">
                      -
                      {formatNumberVND(
                        Number(tongGiam) + Number(infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0),
                      )}{' '}
                      VNĐ
                    </div>
                  </div>
                </div>

                {/* phương thức */}
                <div className="flex items-center gap-5">
                  <div className="flex w-1/2 items-center gap-1">
                    <label className="text-sm w-1/3 font-semibold text-end">Phương thức:</label>
                    <div className="w-2/3 flex items-center gap-2">
                      <Select
                        defaultValue={1}
                        className="w-2/3"
                        size="small"
                        value={formPay.idPhuongThuc}
                        onChange={(value) => {
                          setFormPay({ ...formPay, idPhuongThuc: value })
                        }}
                        options={
                          [
                            { label: 'Thanh toán', value: 1 },
                            { label: 'Công nợ', value: 2 },
                          ]
                          // ?.map((items) => ({
                          //   label: items.label,
                          //   value: items.value,
                          // }))
                        }
                      />
                      <div className="w-1/3 flex gap-2">
                        <label className="text-sm w-1/2 font-semibold text-end">HĐ:</label>
                        <Checkbox
                          checked={formPay.hoaDonDienTu && formPay.hoaDonDienTu === 1}
                          onChange={handleChangeCheckbox}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2  flex">
                    <Button
                      disabled={newArr.length === 0 || !infoBN}
                      className="w-1/2"
                      onClick={() => {
                        handlePay()
                        // formik.handleSubmit();
                      }}
                      variant="contained"
                      size="small"
                      color="success"
                    >
                      Thanh toán
                    </Button>
                    <div className="text-green-700 text-xl font-semibold w-1/2 text-end">
                      {formatNumberVND(
                        Number(tongThanhToan) -
                          Number(infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0),
                      )}{' '}
                      VNĐ
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-5">
                  <label className="text-sm font-semibold w-32 text-end">Nội dung XHĐ:</label>
                  <Input
                    name="ghiChuHoaDon"
                    style={{ color: 'black' }}
                    size="small"
                    className="w-[70%]"
                    value={formik.values.ghiChuHoaDon}
                    onChange={formik.handleChange}
                    disabled={!infoBN}
                  />
                  {infoBN && (
                    <li
                      onClick={handleClickUpdateXHD}
                      className="bg-green-200 text-green-700 hover:bg-green-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
                    >
                      <CheckOutlined />
                    </li>
                  )}
                </div>
              </div>
            </div>
            <div className="h-[45%]">
              <Divider
                style={{
                  margin: '10px 0',
                }}
              >
                <span className="text-blue-500">CHỈ ĐỊNH</span>
              </Divider>
              <TabsChiDinh
                formPay={formPay}
                ArrXN={ArrXN}
                ArrCĐHA={ArrCĐHA}
                ArrKB={ArrKB}
                newArr={newArr}
                setFormPay={setFormPay}
              />
            </div>
          </div>
        </form>
      </div>
      <ModalCreateCustomer
        openModaCreateCustomer={openModaCreateCustomer}
        cancelCreateCustomer={cancelCreateCustomer}
      />
      <ModalPay
        onSubmit={formik.handleSubmit}
        form={form}
        setForm={setForm}
        isModalPay={isModalPay}
        tongThanhToan={tongThanhToan}
        setIsModalPay={setIsModalPay}
        setIsPrint={setIsPrint}
      />
      {/* <ModalHD hoaDon={hoaDon} setHoaDon={setHoaDon} setFormPay={setFormPay} /> */}
    </>
  )
}

export default Receive

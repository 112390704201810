import React, { useState } from 'react'
import VanbanTable from './Table/VanbanTable'
import ChitietVanBan from './ChiTiet/ChitietVanBan'
import Dinhkem from './Dinhkem/Dinhkem'
import { Button, Input, Modal } from 'antd'
import AttachEmailIcon from '@mui/icons-material/AttachEmail'
import TextArea from 'antd/es/input/TextArea'
import Thongke from './ThongKe/Thongke'
import { SendOutlined } from '@ant-design/icons'

export default function VanbanDen() {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  return (
    <>
      <div className="h-full bg-[#EFEFEF]">
        <div>
          <div className="p-2">
            <div className=" mb-2 ">
              <Thongke />
            </div>
            <div className="bg-white flex border rounded-lg">
              <div className="w-1/3 border-r">
                <VanbanTable />
              </div>
              <div className="w-2/3">
                <div className="flex justify-end mb-2 p-4">
                  {/* <Button type="primary" className="flex items-center gap-2 mx-2">
                    Lưu
                  </Button> */}
                  <Button
                    type="primary"
                    className="flex items-center gap-2 mx-2"
                    onClick={showModal}
                  >
                    Chuyển tiếp
                    <SendOutlined />
                  </Button>
                </div>
                <div>
                  <ChitietVanBan />
                </div>
                <div>
                  <Dinhkem />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        width="55%"
        title={
          <p className="font-semibold text-xl w-full text-center text-blue-500">XỬ LÝ VĂN BẢN</p>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Lưu"
        cancelText="Hủy bỏ"
      >
        <div className="flex gap-5">
          <div className="flex flex-col gap-2 w-2/3">
            <div className="flex flex-col gap-1">
              <label className="font-medium text-gray-400">
                Tên văn bản :<span className="text-red-500">(*)</span>
              </label>
              <Input name="tenvanban" />
            </div>
            <div className="flex flex-row gap-4 w-full">
              <div className="flex flex-col w-2/3">
                <label className="font-medium text-gray-400">
                  Loại văn bản:<span className="text-red-500">(*)</span>
                </label>
                <Input name="loaivanban" />
              </div>
              <div className="flex flex-col w-1/3">
                <label className="text-gray-400 font-medium">
                  Số văn bản :<span className="text-red-500">(*)</span>
                </label>
                <Input name="sovanban" />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-gray-400 font-medium">Nội dung xử lý :</label>
              <TextArea name="noidungxuly" />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-gray-400 font-medium">Ghi chú :</label>
              <Input name="ghichu" />
            </div>
          </div>
          <div className="flex flex-col gap-2 w-1/3">
            <div className="flex gap-2">
              <div className="flex flex-col gap-1 w-1/2">
                <label className="text-gray-400 font-medium">Người xử lý : </label>
                <Input name="nguoixyly" disabled />
              </div>
              <div className="flex flex-col gap-1 w-1/2">
                <label className="text-gray-400 font-medium">Ngày xử lý :</label>
                <Input name="ngayxyly" disabled />
              </div>
            </div>
            <div>
              <label className="text-gray-400 font-medium">Chi nhánh gửi :</label>
              <Input name="ngayxyly" disabled />
            </div>
            <div className="flex flex-col gap-1 items-center">
              <div
                className="text-center"
                style={{
                  animation: 'blink 1s infinite, scaleUp 1s infinite',
                  color: '#0000ff', // Change text color to blue
                  fontWeight: 'bold', // Make the text bold
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Add shadow to the text
                }}
              >
                Bàn giao
              </div>
            </div>

            <style jsx>{`
              @keyframes blink {
                0%,
                100% {
                  opacity: 1;
                }
                50% {
                  opacity: 0;
                }
              }

              @keyframes scaleUp {
                0%,
                100% {
                  transform: scale(1);
                }
                50% {
                  transform: scale(1.2);
                }
              }
            `}</style>
            <div className="flex gap-2">
              <div className="flex flex-col gap-1 w-1/2">
                <label className="text-gray-400 font-medium">Người nhận : </label>
                <Input name="nguoixyly" />
              </div>
              <div className="flex flex-col gap-1 w-1/2">
                <label className="text-gray-400 font-medium">Ngày nhận :</label>
                <Input name="ngayxyly" />
              </div>
            </div>
            <div>
              <label className="text-gray-400 font-medium">Chi nhánh nhận :</label>
              <Input name="ngayxyly" />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

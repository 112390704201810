import { https } from '../apiService'
export const drugstoreService = {
  getBNCho: (idChiNhanh) => https.get(`NhaThuoc/GetDanhSachChoLayThuoc?chiNhanh=${idChiNhanh}`),
  getBNDone: (idChiNhanh) => https.get(`NhaThuoc/GetDanhSachDaLayThuoc?chiNhanh=${idChiNhanh}`),
  getBNChoBsKeToaLai: (chiNhanh) =>
    https.get(`NhaThuoc/GetDanhSachChoKeDon`, { params: { chiNhanh } }),
  getServiceNhaThuoc: (idbnttc) =>
    https.get(`BenhNhanPhieuThu/GetDanhSachToaThuoc?idbnttc=${idbnttc}`),
  updateThuoc: (data) => https.put('BenhNhanPhieuThu/UpdateBenhNhanPhieuThu', data),
  updateThuocKhiTraThuoc: (data) =>
    https.put('BenhNhanPhieuThu/UpdateBenhNhanPhieuThuTraThuoc', data),
  getPhieuThu: (idct, idChuyenKhoa, idcn, tuNgay, denNgay, idCa, keyword) =>
    https.get(`BenhNhanPhieuThu/GetDanhSachPhieuThu?idct=${idct}${idcn}${idCa}`, {
      params: {
        idChuyenKhoa,
        tuNgay,
        denNgay,
        keyword,
      },
    }),
  postBNBanLe: (dataBNTTC) => https.post('BenhNhanTTC/AddBenhNhanTTC_ForBanLe', dataBNTTC),
  postPhieuThuBanLe: (data) => https.post('BenhNhanPhieuThu/ThemBenhNhanPhieuThu', data),
  getLyDoKhongLayThuoc: () => https.get('LyDoKoLayThuoc/GetAllLyDo_DuocSi'),
  getLyDoTraToaChoBs: () => https.get('LyDoKoLayThuoc/GetLyDoTraToa'),
  putBNTTTraThuoc: (form) => https.put(`BenhNhanThanhToan/UpdateBenhNhanThanhToanThuoc`, form),
  putTraToaChoBSKeLai: (idbnttc, idloaitt) =>
    https.put(`BenhNhanPhieuThu/UpdateTraThuoc?idbnttc=${idbnttc}&idloaitt=${idloaitt}`),
  getBNTTtheoLoaiTT: (idbnttc, idloaitt) =>
    https.get(`BenhNhanThanhToan/GetBenhNhanThanhToanByIDBNTTCToaThuoc`, {
      params: { idbnttc, idloaitt },
    }),
  getListThuocTra: (idcn, tuNgay, denNgay, keyword) =>
    https.get('BenhNhanPhieuThu/GetDanhSachPhieuThuTraThuoc', {
      params: {
        idcn,
        tuNgay,
        denNgay,
        keyword,
      },
    }),
  getListThuocKhacLo: (IDChiNhanh, IDThuoc) =>
    https.get('TonKho/ByIDThuocAndIDChiNhanh', {
      params: {
        IDChiNhanh,
        IDThuoc,
      },
    }),
  postApplyVoucherHangHoa: (list) => https.post('VoucherDanhSach/ApplyVoucherHangHoa', list),
  putFileTraThuoc: (IDBNTTC, FILEHINHANHS) =>
    https.put(`BenhNhanPhieuThu/UpdateFileBenhNhanPhieuThu?IDBNTTC=${IDBNTTC}`, FILEHINHANHS),
  putDaNhanToa: (idBNTTC) => https.put(`BenhNhanPhieuThu/UpdateDaNhanToa?idBNTTC=${idBNTTC}`),

  getThuocBan: (idct, idcn, idcalamviec, idloaitt, tuNgay, denNgay, keyword) =>
    https.get(`BenhNhanPhieuThu/GetDanhSachBanThuoc?idct=${idct}${idcn}`, {
      params: {
        idcalamviec,
        idloaitt,
        tuNgay,
        denNgay,
        keyword,
      },
    }),
  postLichSuTraToa: (form) => https.post('BenhNhanPhieuThu/AddLichSuTraToa', form),
  getListLichSuTraToa: (idcn, idloaitoa, tuNgay, denNgay) =>
    https.get(
      `BenhNhanPhieuThu/GetLichSuTraToa?${idcn}&idloaitoa=${idloaitoa}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
}

import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  lisTypesCard: null,
  chuyenKhoa: null,
  infoBN: null,
  detailCard: null,
  cardReceive: null,
  paymentCard: null,
  infoPaymentCard: null,
  BNPaymentCard: null,
  historyPaymentCard: null,
  historyMemberCard: null,
  lichSuMuaTTV: null,
  tongGiamGia: 0,
  tongThanhToan: 0,
  discountArr: null,
  infoVoucherDoiTac: null,
  listGroupCard: null,
  soLanKham: null,
  listTypesCardByCN: null,
  infoPhieuThuTTV: null,
  infoBNthu2: null,
  CTGiamTTV: 0, // chi ap dung den 30/10
  infoBnTang: null,
  infoBnThu2Tang: null,
}

const cartReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_LIST_TYPES_CARD:
        draft.lisTypesCard = payload
        break
      case typeAction.DISPATCH_LIST_TYPES_CARD_BY_CN:
        draft.listTypesCardByCN = payload
        break
      case typeAction.DISPATCH_CHUYEN_KHOA_CARD:
        draft.chuyenKhoa = payload
        break
      case typeAction.DISPATCH_INFO_BN_CARD:
        if (payload) {
          let diaChiNew = '' + payload?.diaChi
          if (payload?.tenPhuongXa) diaChiNew += ', ' + payload.tenPhuongXa
          if (payload?.tenQuanHuyen) diaChiNew += ', ' + payload.tenQuanHuyen
          if (payload?.tenTinhTP) diaChiNew += ', ' + payload.tenTinhTP
          //draft.infoBN = payload
          draft.infoBN = { ...payload, diaChi: diaChiNew }
          // draft.infoBN?.diaChi = diaChiNew
        } else {
          draft.infoBN = null
        }
        break
      case typeAction.DISPATCH_INFO_BN_THU2_CARD:
        if (payload) {
          let diaChiNew2 = '' + payload?.diaChi
          if (payload?.tenPhuongXa) diaChiNew2 += ', ' + payload.tenPhuongXa
          if (payload?.tenQuanHuyen) diaChiNew2 += ', ' + payload.tenQuanHuyen
          if (payload?.tenTinhTP) diaChiNew2 += ', ' + payload.tenTinhTP
          draft.infoBNthu2 = { ...payload, diaChi: diaChiNew2 }
        } else {
          draft.infoBNthu2 = null
        }
        break

      case typeAction.DISPATCH_INFO_BN_CARD_TANGSAN:
        if (payload) {
          let diaChiNew = '' + payload?.diaChi
          if (payload?.tenPhuongXa) diaChiNew += ', ' + payload.tenPhuongXa
          if (payload?.tenQuanHuyen) diaChiNew += ', ' + payload.tenQuanHuyen
          if (payload?.tenTinhTP) diaChiNew += ', ' + payload.tenTinhTP
          //draft.infoBN = payload
          draft.infoBnTang = { ...payload, diaChi: diaChiNew }
          // draft.infoBN?.diaChi = diaChiNew
        } else {
          draft.infoBnTang = null
        }
        break
      case typeAction.DISPATCH_INFO_BN_THU2_CARD_TANGSAN:
        if (payload) {
          let diaChiNew2 = '' + payload?.diaChi
          if (payload?.tenPhuongXa) diaChiNew2 += ', ' + payload.tenPhuongXa
          if (payload?.tenQuanHuyen) diaChiNew2 += ', ' + payload.tenQuanHuyen
          if (payload?.tenTinhTP) diaChiNew2 += ', ' + payload.tenTinhTP
          draft.infoBnThu2Tang = { ...payload, diaChi: diaChiNew2 }
        } else {
          draft.infoBnThu2Tang = null
        }
        break

      case typeAction.DISPATCH_INFO_TYPE_CARD:
        draft.tongThanhToan = payload?.tiensudung ?? 0
        draft.detailCard = payload
        break
      case typeAction.DISPATCH_ALL_CARD_RECEIVE:
        draft.cardReceive = payload
        break
      case typeAction.DISPATCH_LIST_PAYMENT_CARD:
        draft.paymentCard = payload
        break
      case typeAction.DISPATCH_INFO_PAYMENT_CARD:
        draft.infoPaymentCard = payload
        break
      case typeAction.DISPATCH_INFO_BN_PAYMENT_CARD:
        draft.BNPaymentCard = payload
        break
      case typeAction.DISPATCH_HISTORY_PAYMENT_CARD:
        draft.historyPaymentCard = payload
        break
      case typeAction.DISPATCH_TONG_GIAM_TTV:
        draft.tongGiamGia = payload
        break
      case typeAction.DISPATCH_TONG_THANH_TOAN_TTV:
        draft.tongThanhToan = payload
        break
      // case typeAction.DISPATCH_SET_TONG_GIAM_TTV:
      //   draft.tongGiamGia += payload
      //   break
      // case typeAction.DISPATCH_SET_TONG_THANH_TOAN_TTV:
      //   draft.tongThanhToan += payload
      //   break
      case typeAction.UPDATE_NEW_PRICE_CHI_DINH_TTV:
        draft.discountArr = payload
        break
      case typeAction.SET_INFO_VOUCHER_DOITAC_TTV:
        draft.infoVoucherDoiTac = payload
        break
      case typeAction.RESET_FORM_TAO_TTV:
        draft.discountArr = null
        draft.infoVoucherDoiTac = null
        draft.infoBN = null
        draft.tongGiamGia = 0
        draft.tongThanhToan = 0
        draft.detailCard = null
        draft.CTGiamTTV = 0
        break
      case typeAction.DISPATCH_HISTORY_MEMBER_CARD:
        draft.historyMemberCard = payload
        break
      case typeAction.DISPATCH_LIST_GROUP_CARD:
        draft.listGroupCard = payload
        break
      case typeAction.DISPATCH_SO_LAN_KHAM_MEMBER_CARD:
        draft.soLanKham = payload
        break
      case typeAction.DISPATCH_INFO_PHIEUTHU_TTV:
        draft.infoPhieuThuTTV = payload
        break
      case typeAction.DISPACTH_PRICE_TTV_CT_GIAM:
        draft.CTGiamTTV = payload // chi ap dung den 30/10
        break
      case typeAction.DISPATCH_LICHSU_MUA_TTV:
        draft.lichSuMuaTTV = payload
        break
      case typeAction.RESET_STORE_LICHSU:
        draft.infoBN = null
        draft.infoBNthu2 = null
        draft.detailCard = null
        draft.historyMemberCard = null
        draft.lichSuMuaTTV = null
        draft.infoBnTang = null
        draft.infoBnThu2Tang = null
        break
      default:
        return state
    }
  })
}

export default cartReducer

import { https } from '../apiService'
export const smsService = {
  getTemplate: () => https.get(`SMSTemplate/GetAll`),
  // getBNSms: (numberTP, numberTTV) =>
  //   https.get(`/BenhNhan/GetBenhNhanGuiSMS?numberTP=${numberTP}&numberTTV=${numberTTV}`),
  getBNSms: (idChuyenKhoa, numberTP, numberTTV, ageGroup) =>
    https.get(`/BenhNhan/GetBenhNhanGuiSMS`, {
      params: { idChuyenKhoa, numberTP, numberTTV, ageGroup },
    }),
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_button__4-gpF{
    width: 100%;
    display: flex;
    gap:5px;
}

.style_button__4-gpF:hover img {
    transition: all 0.5s;
    transform: rotate(360deg);
}`, "",{"version":3,"sources":["webpack://./src/components/common/ButtonLang/style.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,OAAO;AACX;;AAEA;IACI,oBAAoB;IACpB,yBAAyB;AAC7B","sourcesContent":[".button{\n    width: 100%;\n    display: flex;\n    gap:5px;\n}\n\n.button:hover img {\n    transition: all 0.5s;\n    transform: rotate(360deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `style_button__4-gpF`
};
export default ___CSS_LOADER_EXPORT___;

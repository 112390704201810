import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider, Table, ConfigProvider } from 'antd'
import style from './style.module.css'
import moment from 'moment'
const columns = [
  {
    title: 'Ngày khám',
    dataIndex: 'ngaykham',
    width: 130,
    align: 'center',
  },
  {
    title: 'Tái khám',
    dataIndex: 'TaiKham',
    width: 80,
    align: 'center',
  },
  {
    title: 'Loại khám',
    dataIndex: 'LoaiKham',
    width: 140,
  },
  {
    title: 'Chẩn đoán',
    dataIndex: 'ChuanDoan',
  },
  {
    title: 'Bác sĩ khám',
    dataIndex: 'BSKham',
    width: 250,
  },
]

const TableHistory = ({ history }) => {
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  return (
    <div className="p-2">
      <Divider
        style={{
          margin: '10px 0',
        }}
      >
        <span className="text-blue-500">LỊCH SỬ KHÁM BỆNH</span>
      </Divider>
      {/* <h2>
        {" "}
        <span className="text-blue-500 font-semibold">Bệnh nhân:</span>
      </h2> */}
      <ConfigProvider
        theme={{
          token: {
            padding: 3,
            fontSize: 13,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
  
        }}
      >
        <Table
          dataSource={history.map((items) => ({
            ngaykham: items.ngaykham && moment(items.ngaykham).format('DD/MM/YYYY HH:mm:ss'),
            TaiKham: items.ngaytaikham && moment(items.ngaytaikham).format('DD/MM/YYYY'),
            LoaiKham: items.tenLoaiKham,
            ChuanDoan: items.chandoan,
            BSKham: items.bskham,
            dusanh: items.dusanh && moment(items.dusanh).format('DD/MM/YYYY'),
          }))}
          pagination={false}
          bordered
          className={style.table_history}
          scroll={{ y: 200 }}
          columns={
            PkDangNhap?.idChuyenKhoa === 2
              ? [
                  ...columns,
                  {
                    title: 'Dự sanh',
                    dataIndex: 'dusanh',
                    width: 80,
                    align: 'center',
                  },
                ]
              : columns
          }
        />
      </ConfigProvider>
    </div>
  )
}

export default TableHistory

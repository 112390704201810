// src/components/layout/ChildrenClinic/General/Index/EditableSpan.jsx
import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { updateDetailBN } from '../../../../../store/actions/maternityClinicAction'

const EditableSpan = ({ value, unit, field, isEditable = true }) => {
  const dispatch = useDispatch()
  const [isEditing, setIsEditing] = useState(false)
  const [inputValue, setInputValue] = useState(value)
  const [inputWidth, setInputWidth] = useState(null)
  const spanRef = useRef(null)

  useEffect(() => {
    if (spanRef.current) {
      setInputWidth(spanRef.current.offsetWidth)
    }
  }, [isEditing])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const handleDoubleClick = () => {
    if (isEditable) {
      setIsEditing(true)
    }
  }

  const handleChange = (e) => {
    const newValue =
      field === 'chieucao' || field === 'cannang' ? parseFloat(e.target.value) : e.target.value
    setInputValue(newValue)
    dispatch(updateDetailBN({ [field]: newValue }))
  }

  const handleBlur = () => {
    setIsEditing(false)
  }

  return isEditing ? (
    <input
      type={field === 'chieucao' || field === 'cannang' ? 'number' : 'text'}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      autoFocus
      style={{ width: inputWidth }}
      className="text-end pr-2 font-semibold text-green-700 text-sm"
    />
  ) : (
    <span
      ref={spanRef}
      className="text-end pr-2 font-semibold text-green-700 text-sm"
      onDoubleClick={handleDoubleClick}
    >
      {inputValue} {unit}
    </span>
  )
}

export default EditableSpan

import React, { useCallback, useEffect, useState } from 'react'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import LayoutApp from '../../../HOCs/LayoutApp'
import { Button, ConfigProvider, Input, Popconfirm, Table, Tooltip } from 'antd'
import {
  PlusOutlined,
  ContainerOutlined,
  DeleteOutlined,
  SearchOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  deletePartner,
  fetListPartner_Action,
  searchPartner,
} from '../../../store/actions/partnerAction'
import ModalCreatePartner from './ModalCreatePartner/ModalCreatePartner'
import ModalEditPartner from './ModalEditPartner/ModalEditPartner'
import { debounce } from 'lodash'
import * as XLSX from 'xlsx'
import { configSearchTableAnt } from '../../../utils/configSearchTableAntd'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'

const Partner = () => {
  const dispatch = useDispatch()
  const { listPartner } = useSelector((state) => state.partnerReducer)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchValues, setSearchValues] = useState({})
  const [isModalOpenEdit, setIsModalOpenEdit] = useState({
    show: false,
    data: {},
  })
  const [search, setSearch] = useState('')
  const [data2, setData2] = useState([])
  const [valueExport, setValueExport] = useState([])

  const maQuyenXemDs = 'QHT640'
  const maQuyenXemCt = 'QHT357'
  const maQuyenXuatExcel = 'QHT356'
  const maQuyenThemDt = 'QHT355'
  const maQuyenXoaDt = 'QHT359'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        dispatch(searchPartner(search))
        break
      case maQuyenXemCt:
        setIsModalOpenEdit(infoNew)
        break
      case maQuyenXuatExcel:
        exportToExcel()
        break
      case maQuyenThemDt:
        setIsModalOpen(true)
        break
      case maQuyenXoaDt:
        handleDeleteById(infoNew?.idDoiTac)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXemCt:
        setIsModalOpenEdit({
          show: false,
          data: {},
        })
        break
      case maQuyenXuatExcel:
        //
        break
      case maQuyenThemDt:
        setIsModalOpen(false)
        break
      case maQuyenXoaDt:
        //
        break
      default:
        return false
    }
  }

  const onLoad = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }

  useEffect(() => {
    // dispatch(fetListPartner_Action())
    onLoad()
  }, [dispatch, search])

  const handleDeleteById = (id) => {
    dispatch(deletePartner(id, onLoad))
  }

  const debounceGetDataSearch = useCallback(
    debounce((keyword) => {
      setSearch(keyword)
    }, 500),
    [],
  )

  const handleSearchInput = (e) => {
    const { value } = e.target
    debounceGetDataSearch(value)
  }

  const handleClearSearch = () => {
    setSearch('')
    //get lai data
    dispatch(fetListPartner_Action())
  }
  const compare = (a, b, dataIndex) => {
    if (a[dataIndex] < b[dataIndex]) {
      return -1
    }
    if (a[dataIndex] > b[dataIndex]) {
      return 1
    }
    return 0
  }

  const filteredData2 = (
    data, // lọc giá trị search
  ) =>
    data?.filter((item) =>
      Object.entries(searchValues).every(
        ([key, value]) => !value || item[key]?.toLowerCase().includes(value.toLowerCase()),
      ),
    )

  const onChangeTable = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }
  useEffect(() => {
    setData2(listPartner)
    setValueExport(listPartner)
  }, [listPartner])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      fixed: 'left',
      align: 'center',
      render: (text, record, index) => {
        return <span>{index + 1}</span>
      },
    },
    {
      title: 'Mã đối tác',
      dataIndex: 'maDoiTac',
      key: 'maDoiTac',
      fixed: 'left',
      align: 'center',
      width: 140,
      ...configSearchTableAnt(setSearchValues, searchValues, 'maDoiTac', listPartner, 140, false),
    },
    {
      title: 'Tên viết tắt',
      dataIndex: 'tenVietTat',
      key: 'tenVietTat',
      fixed: 'left',
      align: 'center',
      width: 120,

      ...configSearchTableAnt(setSearchValues, searchValues, 'tenVietTat', listPartner, 120, false),
    },
    {
      title: 'Tên đối tác ',
      dataIndex: 'tenDoiTac',
      key: 'tenDoiTac',
      width: 400,
      fixed: 'left',
      sorter: (a, b) => compare(a, b, 'tenDoiTac'),
      showSorterTooltip: false,
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenDoiTac', listPartner, 400, false),
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'diaChi',
      key: 'diaChi',
      width: 450,
      ...configSearchTableAnt(setSearchValues, searchValues, 'diaChi', listPartner, 450, false),
    },
    {
      title: 'SĐT',
      dataIndex: 'dienThoai',
      key: 'dienThoai',
      align: 'center',
      width: 120,
      ...configSearchTableAnt(setSearchValues, searchValues, 'dienThoai', listPartner, 120, false),
    },
    {
      title: 'Mã số thuế',
      dataIndex: 'maSoThue',
      key: 'maSoThue',
      align: 'center',
      width: 150,
      ...configSearchTableAnt(setSearchValues, searchValues, 'maSoThue', listPartner, 150, false),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      width: 120,
      ...configSearchTableAnt(setSearchValues, searchValues, 'email', listPartner, 120, false),
    },
    {
      title: 'Website',
      dataIndex: 'website',
      key: 'website',
      align: 'center',
      width: 120,
      ...configSearchTableAnt(setSearchValues, searchValues, 'website', listPartner, 120, false),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      fixed: 'right',
    },
  ]

  const exportToExcel = () => {
    const headers = [
      'Mã đối tác',
      'Tên viết tắt',
      'Tên đối tác',
      'Địa chỉ',
      'SĐT',
      'Mã số thuế',
      'Email',
      'Website',
    ]
    const formattedData = filteredData2(valueExport)?.map((item) => ({
      'Mã đối tác': item.maDoiTac,
      'Tên viết tắt': item.tenVietTat,
      'Tên đối tác': item.tenDoiTac,
      'Địa chỉ': `${item.diaChi ?? ''}${item.tenPhuongXa ? ', ' + item.tenPhuongXa : ''}${item.tenQuanHuyen ? ', ' + item.tenQuanHuyen : ''}${item.tenTinhTP ? ', ' + item.tenTinhTP : ''}`,
      'SĐT ': item.dienThoai,
      'Mã số thuế': item.maSoThue,
      'Email ': item.email,
      'Website ': item.website,
    }))
    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, 'Đối tác.xlsx')
  }

  return (
    <>
      <div className="p-5 bg-[#EFEFEF]">
        <div className="p-2 bg-white rounded-xl border">
          <div className="flex justify-between gap-2">
            <div className="w-80">
              <Input
                allowClear
                className="w-full"
                // value={search}
                placeholder="Tìm kiếm"
                onChange={handleSearchInput}
                prefix={<SearchOutlined />}
                // suffix={search.length > 0 && <CloseOutlined onClick={handleClearSearch} />}
              />
            </div>
            <Button onClick={onLoad} type="primary" shape="circle" icon={<SyncOutlined />} />
            <Button
              disabled={!filteredData2(valueExport)?.length}
              // onClick={exportToExcel}
              onClick={() =>
                dispatch(
                  putCheckQuyenThaoTacAction(maQuyenXuatExcel, null, actionIsTrue, actionIsFalse),
                )
              }
              type="text"
              size="middle"
              className="text-green-700 ml-auto"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                dispatch(
                  putCheckQuyenThaoTacAction(maQuyenThemDt, null, actionIsTrue, actionIsFalse),
                )
              }
            >
              Tạo đối tác
            </Button>
          </div>
          <div className="mt-5">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                  borderRadius: 0,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                bordered
                scroll={{
                  x: filteredData2(data2)?.length ? 'max-content' : 1500,
                }}
                pagination={{
                  pageSize: 20,
                  showSizeChanger: false,
                }}
                onChange={onChangeTable}
                columns={columns}
                dataSource={filteredData2(data2)?.map((item, index) => ({
                  STT: ++index,
                  diaChi: `${item.diaChi ?? ''}${item.tenPhuongXa ? ', ' + item.tenPhuongXa : ''}${item.tenQuanHuyen ? ', ' + item.tenQuanHuyen : ''}${item.tenTinhTP ? ', ' + item.tenTinhTP : ''}`,
                  maDoiTac: item.maDoiTac,
                  tenDoiTac: item.tenDoiTac,
                  dienThoai: item.dienThoai,
                  email: item.email,
                  website: item.website,
                  maSoThue: item.maSoThue,
                  tenVietTat: item.tenVietTat,
                  action: (
                    <ul className="flex justify-around">
                      <li>
                        <Tooltip title="Xem" color="#108ee9">
                          <ContainerOutlined
                            onClick={() =>
                              dispatch(
                                putCheckQuyenThaoTacAction(
                                  maQuyenXemCt,
                                  { show: true, data: { ...item } },
                                  actionIsTrue,
                                  actionIsFalse,
                                ),
                              )
                            }
                            className="text-xl text-[#108ee9]  cursor-pointer"
                          />
                        </Tooltip>
                      </li>
                      <li>
                        <Tooltip title="Xoá" color="red">
                          <Popconfirm
                            title="Xóa đối tác"
                            onConfirm={() =>
                              dispatch(
                                putCheckQuyenThaoTacAction(
                                  maQuyenXoaDt,
                                  item,
                                  actionIsTrue,
                                  actionIsFalse,
                                ),
                              )
                            }
                            okText="Xác nhận"
                            cancelText="Hủy"
                            icon={<DeleteOutlined style={{ color: 'red' }} />}
                          >
                            <DeleteOutlined
                              //   onClick={showModal}
                              className="text-xl text-red-500  cursor-pointer"
                            />
                          </Popconfirm>
                        </Tooltip>
                      </li>
                    </ul>
                  ),
                }))}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <ModalCreatePartner
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          onLoad={onLoad}
        />
      )}
      {isModalOpenEdit.show && (
        <ModalEditPartner
          isModalOpenEdit={isModalOpenEdit}
          setIsModalOpenEdit={setIsModalOpenEdit}
          onLoad={onLoad}
        />
      )}
    </>
  )
}

export default Partner

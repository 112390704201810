import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import * as typeAction from '../constants/constants'
import { cardService } from '../../services/card/cardService'
import { detailCardService } from '../../services/card/detailCardService'
import { khoVTservice } from '../../services/khoVT/khoVTservice'
import { recieveService } from '../../services/receive/recieveService'
import moment from 'moment'
import { generatePhieuThuLeTan } from '../../utils/report/phieuThuLeTan'
import { searchPaymentCard, fetchInfoTypeCard } from '../actions/cardAction'
import { cashierService } from '../../services/cashier/cashierService'
import { generatePhieuThuTienTTV } from '../../utils/report/phieuThuTienTTV'
import { phanQuyenService } from '../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../actions/phanQuyenAction'
import ToastCus from '../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* cardSaga() {
  yield takeLatest(
    typeAction.GET_ALL_TYPE_CARD,
    function* listCardMemberShips({ type, idChuyenKhoa, keyword }) {
      try {
        const result = yield call(() => cardService.listTypeCard(idChuyenKhoa, keyword))
        const chuyenKhoa = yield call(() => khoVTservice.getAllChuyenKhoa())
        yield put({
          type: typeAction.DISPATCH_LIST_TYPES_CARD,
          payload: result.data,
        })
        yield put({
          type: typeAction.DISPATCH_CHUYEN_KHOA_CARD,
          payload: chuyenKhoa.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  // ###### LẤY THÔNG LOẠI THẺ THÁNH VIEN THU NGAN
  yield takeLatest(
    typeAction.GET_INO_TTV_THU_NGAN,
    function* infoTypeCard({ type, IDLOAI, idcn, idTinh, idbn }) {
      try {
        const result = yield call(() => cardService.getInfoTypeCardByCN(IDLOAI, idcn))
        const { data: TheBN } = yield call(() => cardService.getAllCardTTVByBenhNhan(idbn))
        const typeTheNhi = IDLOAI === 1 || IDLOAI === 2 || IDLOAI === 3 // check phải thẻ nhi hay không
        const Discount = () => {
          if (typeTheNhi && TheBN.length === 0) {
            if (idTinh !== 46) {
              //id tỉnh HCM
              return 200000 // giảm giá ngoài tỉnh
            } else {
              return 250000 // giảm giá trong tỉnh HCM
            }
          } else {
            return 0
          }
        }
        const resultPrice = Discount()
        const totalPrice = result.data.tiensudung - resultPrice
        yield put({
          type: typeAction.DISPATCH_INFO_TYPE_CARD,
          payload: result.data, //{ ...result.data, tiensudung: totalPrice },
        })
        yield put({
          type: typeAction.DISPACTH_PRICE_TTV_CT_GIAM,
          payload: resultPrice,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  // ###### LẤY THÔNG LOẠI THẺ THÁNH VIEN
  yield takeLatest(
    typeAction.GET_INFO_TYPES_CARD,
    function* infoTypeCard({ type, IDLOAI, idcn, valueCheck }) {
      try {
        const result = yield call(() => cardService.getInfoTypeCardByCN(IDLOAI, idcn))
        const list = yield call(() => detailCardService.getListTheDichVuByIdLT(IDLOAI))

        yield put({
          type: typeAction.DISPATCH_INFO_TYPE_CARD,
          payload: result.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_THE_DICH_VU,
          payload: list.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  // ###### LẤY THÔNG TIN BỆNH NHÂN THẺ THÀNH VIEN
  yield takeLatest(
    typeAction.GET_INFO_BN_CARD,
    function* listCardMemberShips({ type, idbn, isBNtang }) {
      try {
        const result = yield call(() => recieveService.getInfoBN(idbn))
        const infoPaymentCard = yield call(() => cardService.getInfoPaymentCardByIdBN(idbn))

        if (isBNtang) {
          yield put({
            type: typeAction.DISPATCH_INFO_BN_THU2_CARD,
            payload: result.data,
          })
        } else {
          yield put({
            type: typeAction.DISPATCH_INFO_BN_CARD,
            payload: result.data,
          })
        }

        yield put({
          type: typeAction.DISPATCH_INFO_PAYMENT_CARD,
          payload: infoPaymentCard.data[0],
        })
      } catch (err) {
        console.log(err)
      }
    },
  )

  // ###### LẤY THÔNG TIN BỆNH NHÂN THẺ THÀNH VIEN NHI TẶNG KHI CÓ THẺ SẢN
  yield takeLatest(
    typeAction.GET_INFO_BN_CARD_TANGSAN,
    function* listCardMemberShips({ type, idbn, isBNtang }) {
      try {
        const result = yield call(() => recieveService.getInfoBN(idbn))
        if (isBNtang) {
          yield put({
            type: typeAction.DISPATCH_INFO_BN_THU2_CARD_TANGSAN,
            payload: result.data,
          })
        } else {
          yield put({
            type: typeAction.DISPATCH_INFO_BN_CARD_TANGSAN,
            payload: result.data,
          })
        }
      } catch (err) {
        console.log(err)
      }
    },
  )
  // ###### ACTIVE THE THANH TOÁN BN
  yield takeLatest(
    typeAction.ACTIVE_CARD,
    function* activeCard({ type, idthe, openNotificationWithIcon }) {
      try {
        yield call(() => cardService.activeCardPay(idthe))
        const infoCard = yield call(() => cardService.infoCard(idthe))
        openNotificationWithIcon()
        yield put({
          type: typeAction.DISPATCH_INFO_TYPE_CARD,
          payload: infoCard.data[0],
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  //######## XEM THÔNG TIN THẺ BN
  yield takeLatest(
    typeAction.INFO_CARD_BN,
    function* cardBN({ type, idbn, idbnthu2, idthe, idcn }) {
      try {
        const now = moment().format('YYYY-MM-DD')
        const result = yield call(() => recieveService.getInfoBN(idbn))
        const infoCard = yield call(() => cardService.infoCard(idthe))
        const listTypesCardByCN = yield call(() => cardService.getListTypeCardByCN(idcn))
        const infoPaymentCard = yield call(() => cardService.getInfoPaymentCardByIdBN(idbn))
        if (idbnthu2) {
          const infoBNthu2 = yield call(() => recieveService.getInfoBN(idbnthu2))
          yield put({
            type: typeAction.DISPATCH_INFO_BN_THU2_CARD,
            payload: infoBNthu2.data,
          })
        }
        const lichSuDV = yield call(() => cardService.getHistoryMemberCard(idthe, now, now)) //tungay, denngay
        const soLanKham = yield call(() => cardService.getSoLanKham(idthe, idbn))
        // const lichSuMuaThe = yield call(() => cardService.getLichSuMuaTTV(idthe))
        const lichSuThaoTacThe = yield call(() => cardService.getLichSuThaoTacTTV(idthe))
        yield put({
          type: typeAction.DISPATCH_SO_LAN_KHAM_MEMBER_CARD,
          payload: soLanKham.data[0],
        })
        yield put({
          type: typeAction.DISPATCH_HISTORY_MEMBER_CARD,
          payload: lichSuDV.data,
        })
        yield put({
          type: typeAction.DISPATCH_LICHSU_MUA_TTV,
          payload: lichSuThaoTacThe.data,
        })

        yield put({
          type: typeAction.DISPATCH_INFO_PAYMENT_CARD,
          payload: infoPaymentCard.data[0],
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_CARD,
          payload: result.data,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_TYPE_CARD,
          payload: infoCard.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_TYPES_CARD_BY_CN,
          payload: listTypesCardByCN.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  // ###### TẠO THẺ THÀNH VIÊN CHO BN
  yield takeLatest(
    typeAction.ADD_NEW_CARD_BN,
    function* listCardMemberShips({
      type,
      form,
      typeCard,
      idbn,
      idChiNhanh,
      formTT,
      setIsModalOpen,
      formPrint,
      formLoad,
      formBNthu2,
    }) {
      // const now = moment()
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        // form.ngaydangky = now.format()
        form = {
          ...form,
          idbn: idbn,
          idlt: typeCard,
          idcn: idChiNhanh,
        }
        const result = yield call(() => cardService.postCardBN(form))
        if (formBNthu2 && result?.data?.idthe) {
          formBNthu2 = { ...formBNthu2, idthe: result.data.idthe }
          // console.log(result?.data, form, formBNthu2)
          yield call(() => cardService.postCardBNthu2(formBNthu2))
        }
        if (formTT) {
          yield call(() => cashierService.postTruTienTheThanhToan(formTT))
        }

        // const listTTV = yield call(() =>
        //   cardService.getAllCardReceive(
        //     formLoad?.idcn,
        //     formLoad?.tungay,
        //     formLoad?.denngay,
        //     formLoad?.idlt,
        //     formLoad?.idnt,
        //     formLoad?.keyword,
        //     formLoad?.idct,
        //   ),
        // )
        // yield put({
        //   type: typeAction.DISPATCH_ALL_CARD_RECEIVE,
        //   payload: listTTV.data,
        // })

        formPrint = { ...formPrint, maphieuthu: result?.data?.maphieuthu }
        yield call(() => generatePhieuThuTienTTV(formPrint))
        if (form.mavoucher?.trim().length > 0 && form?.listMaVoucher?.length > 0) {
          yield call(() =>
            cardService.addBenhNhanVoucher([
              { mavoucher: form?.listMaVoucher[0], idbn, iddv: 1889 },
            ]),
          ) //form.mavoucher.trim()
          yield call(() => cardService.putSuDungVoucher(form?.listMaVoucher, form.nguoitao))
          yield put({
            type: typeAction.UPDATE_NEW_PRICE_CHI_DINH_TTV,
            payload: null,
          })
        }
        //add voucher doi tac
        if (form.mavoucherdoitac?.trim().length > 0) {
          const formatData = {
            mavoucher: form.mavoucherdoitac.trim(),
            idcn: form.idcn,
            nguoitao: form.nguoitao,
            idct: form.idct,
            giatri: form.giatri,
          }
          yield call(() => recieveService.addVoucherDoiTac(formatData))
          yield call(() =>
            cardService.putSuDungVoucher([form.mavoucherdoitac.trim()], form.nguoitao),
          )

          yield put({
            type: typeAction.SET_INFO_VOUCHER_DOITAC_TTV,
            payload: null,
          })
        }
        yield put({
          type: typeAction.DISPATCH_INFO_TYPE_CARD,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_CARD,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_THU2_CARD,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_TONG_GIAM_TTV,
          payload: 0,
        })
        yield put({
          type: typeAction.DISPATCH_TONG_THANH_TOAN_TTV,
          payload: 0,
        })
        yield call(() => setIsModalOpen(false))
        Toast.fire({
          icon: 'success',
          title: 'Tạo thẻ thành viên thành công!',
        })
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Tạo thẻ thành viên thất bại!',
        })
        // if (form.mavoucher) {
        //   yield call(() => cardService.updateCancelUseVoucher(form.mavoucher))
        //   yield put({
        //     type: typeAction.UPDATE_NEW_PRICE_CHI_DINH_TTV,
        //     payload: null,
        //   })
        // }
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // ###### LẤY DANH SÁCH THẺ THÀNH VIÊN TIẾP NHẬN
  yield takeLatest(
    typeAction.GET_ALL_CARD_RECEIVE,
    function* cardReceive({ type, idChiNhanh, tuNgay, denNgay, idlt, idnhom, keyword }) {
      //idChiNhanh là arr
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT045'),
        )

        const result = yield call(() =>
          cardService.getAllCardReceive(idChiNhanh, tuNgay, denNgay, idlt, idnhom, keyword),
        )
        yield put({
          type: typeAction.DISPATCH_ALL_CARD_RECEIVE,
          payload: result.data,
        })
      } catch (error) {
        console.error('Error : ', error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // ###### LẤY DANH SÁCH THẺ THANH TOÁN
  yield takeLatest(typeAction.GET_ALL_PAYMEMNT_CARD, function* cardReceive({ type, keyword }) {
    try {
      const result = yield call(() => cardService.paymentCards(keyword))
      yield put({
        type: typeAction.DISPATCH_LIST_PAYMENT_CARD,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  // ###### LẤY THÔNG TIN THẺ THANH TOÁN VÀ THÔNG TIN BỆNH NHÂN
  yield takeLatest(typeAction.GET_INFO_PAYMENR_CARD, function* cardReceive({ type, idthe, idbn }) {
    try {
      const result = yield call(() => cardService.getInfoPaymentCard(idthe))
      const infoBN = yield call(() => recieveService.getInfoBN(idbn))
      yield put({
        type: typeAction.DISPATCH_INFO_PAYMENT_CARD,
        payload: result.data[0],
      })
      yield put({
        type: typeAction.DISPATCH_INFO_BN_PAYMENT_CARD,
        payload: infoBN.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  // ###### NẠP TIỀN THẺ THANH TOÁN
  yield takeLatest(
    typeAction.POST_RECHARGE_PAYMENT_CARD,
    function* addPaymentCard({ type, form, closeModal }) {
      const Dateformat = 'YYYY-MM-DDTHH:mm:ss'
      const now = moment()
      const dataPK = yield select((state) => state.branchReducer)
      const { PkDangNhap } = dataPK
      try {
        form.ngaysudung = now.format(Dateformat)
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => cardService.postPaymentCard(form))
        const result = yield call(() => cardService.getInfoPaymentCard(form.idthe))
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_PAYMENT_CARD,
          payload: result.data[0],
        })
        yield closeModal()
        yield generatePhieuThuLeTan(form, PkDangNhap)

        Toast.fire({
          icon: 'success',
          title: 'Nạp thẻ thành công!',
        })
        //export phieu thu le tan
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Nạp thẻ thất bại!',
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // ###### LẤY LỊCH SỬ THẺ THANH TOÁN
  yield takeLatest(
    typeAction.GET_HISTORY_PAYMENT_CARD,
    function* cardReceive({ type, idthe, idbn, tuNgay, denNgay, setIsLoading }) {
      try {
        setIsLoading(true)
        const result = yield call(() =>
          cardService.getHistoryPaymentCard(idthe, idbn, tuNgay, denNgay),
        )
        yield put({
          type: typeAction.DISPATCH_HISTORY_PAYMENT_CARD,
          payload: result.data,
        })
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setIsLoading(false)
      }
    },
  )
  //-------------- tạo thẻ thanh toán ------------------//
  yield takeLatest(
    typeAction.POST_PAYMENT_CARD,
    function* createPaymentCard({ type, idlt, idBN, idChiNhanh, form, handleResetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => cardService.createPaymentCard(idlt, idBN, idChiNhanh, form))
        yield put(searchPaymentCard(''))
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        handleResetForm()
        Toast.fire({
          icon: 'success',
          title: 'Tạo thẻ thành công!',
        })
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Bệnh nhân đã có thẻ thanh toán!',
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        console.log(err)
      }
    },
  )

  //apply ma giam gia
  yield takeLatest(
    typeAction.GET_LIST_DISCOUNT_TTV,
    function* updateListChiDinhGiamGia({ type, listDV }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })

        let tongTienGiam = 0,
          tongThanhToan = 0
        listDV
          ?.filter((item) => item.trangthaitt !== 2)
          ?.map((item) => {
            tongTienGiam += item.tiengiam
            tongThanhToan += item.thanhtien
          })

        yield put({
          type: typeAction.UPDATE_NEW_PRICE_CHI_DINH_TTV,
          payload: listDV,
        })
        yield put({
          type: typeAction.DISPATCH_TONG_GIAM_TTV,
          payload: tongTienGiam, // listDV[0].giatrigiam,
        })
        yield put({
          type: typeAction.DISPATCH_TONG_THANH_TOAN_TTV,
          payload: tongThanhToan, // listDV[0].giatri,
        })
        Toast.fire({
          icon: 'success',
          title: 'Áp dụng voucher thành công',
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // Remove Giá giảm của chỉ định
  yield takeLatest(
    typeAction.REMOVE_LIST_DISCOUNT_TTV,
    function* unApplyListChiDinhGiamGia({ type, donGiaTTV, dataTypeCard }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        //su dung 1 voucher (cong don voucher chua ap dung)
        yield put({
          type: typeAction.DISPATCH_TONG_GIAM_TTV,
          payload: 0,
        })
        yield put({
          type: typeAction.DISPATCH_TONG_THANH_TOAN_TTV,
          payload: donGiaTTV,
        })
        yield put({
          type: typeAction.UPDATE_NEW_PRICE_CHI_DINH_TTV,
          payload: null,
        })
        if (dataTypeCard)
          yield put(
            fetchInfoTypeCard(
              dataTypeCard?.idlt,
              dataTypeCard?.idcn,
              dataTypeCard?.idtinh,
              dataTypeCard?.idbn,
            ),
          )

        Toast.fire({
          icon: 'success',
          title: 'Đã hủy áp dụng voucher',
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // ###### LẤY LỊCH SỬ THẺ THÀNH VIÊN
  yield takeLatest(
    typeAction.GET_HISTORY_MEMBER_CARD,
    function* getHistoryMemberCardSaga({ type, idthe, idbn, tungay, denngay }) {
      try {
        const lichSuDV = yield call(() => cardService.getHistoryMemberCard(idthe, tungay, denngay))
        const soLanKham = yield call(() => cardService.getSoLanKham(idthe, idbn))

        // const lichSuMuaThe = yield call(() => cardService.getLichSuMuaTTV(idthe))
        // yield put({
        //   type: typeAction.DISPATCH_LICHSU_MUA_TTV,
        //   payload: lichSuMuaThe.data,
        // })

        yield put({
          type: typeAction.DISPATCH_SO_LAN_KHAM_MEMBER_CARD,
          payload: soLanKham.data[0],
        })
        yield put({
          type: typeAction.DISPATCH_HISTORY_MEMBER_CARD,
          payload: lichSuDV.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  // ###### LẤY VÔ HIỆU HÓA THẺ THÀNH VIÊN
  yield takeLatest(
    typeAction.PUT_PAUSE_MEMBER_CARD,
    function* cardReceive({ type, idthe, ngayBaoLuu, idnv, formLoad }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => cardService.putPauseMemberCard(idthe, ngayBaoLuu, idnv))
        // const listTTV = yield call(() =>
        //   cardService.getAllCardReceive(
        //     formLoad?.idcn,
        //     formLoad?.tungay,
        //     formLoad?.denngay,
        //     formLoad?.idlt,
        //     formLoad?.idnt,
        //     formLoad?.keyword,
        //     formLoad?.idct,
        //   ),
        // )
        // yield put({
        //   type: typeAction.DISPATCH_ALL_CARD_RECEIVE,
        //   payload: listTTV.data,
        // })
        // yield put({
        //   type: typeAction.DISPATCH_HISTORY_MEMBER_CARD,
        //   payload: result.data,
        // })
        // yield loadTongTien(result.data)
        Toast.fire({
          icon: 'success',
          title: 'Ngừng sử dụng thẻ thành viên thành công!',
        })
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Ngừng sử dụng thẻ thành viên thất bại!',
        })
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // ###### LẤY HỦY THẺ THÀNH VIÊN
  yield takeLatest(
    typeAction.PUT_DISABLE_MEMBER_CARD,
    function* cardReceive({ type, idthe, idnv,tienHoanThe,  }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => cardService.putDisableMemberCard(idthe, idnv,tienHoanThe))
        Toast.fire({
          icon: 'success',
          title: 'Hủy thẻ thành viên thành công!',
        })
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Hủy thẻ thành viên thất bại!',
        })
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // ###### LẤY TIẾP TỤC SỬ DỤNG THẺ THÀNH VIÊN
  yield takeLatest(
    typeAction.PUT_RUESE_MEMBER_CARD,
    function* cardReceive({ type, idthe, ngaybaoluu, idnv, formLoad }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => cardService.putRueseMemberCard(idthe, ngaybaoluu, idnv))
        // const listTTV = yield call(() =>
        //   cardService.getAllCardReceive(
        //     formLoad?.idcn,
        //     formLoad?.tungay,
        //     formLoad?.denngay,
        //     formLoad?.idlt,
        //     formLoad?.idnt,
        //     formLoad?.keyword,
        //     formLoad?.idct,
        //   ),
        // )
        // yield put({
        //   type: typeAction.DISPATCH_ALL_CARD_RECEIVE,
        //   payload: listTTV.data,
        // })
        // yield put({
        //   type: typeAction.DISPATCH_HISTORY_MEMBER_CARD,
        //   payload: result.data,
        // })
        // yield loadTongTien(result.data)
        Toast.fire({
          icon: 'success',
          title: 'Tái sử dụng thẻ thành viên thành công!',
        })
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Tái sử dụng thẻ thành viên thất bại!',
        })
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // ###### LẤY SỐ LẦN KHÁM THẺ THÀNH VIÊN
  yield takeLatest(
    typeAction.GET_SO_LAN_KHAM_MEMBER_CARD,
    function* cardReceive({ type, idthe, idbn }) {
      try {
        const result = yield call(() => cardService.getSoLanKham(idthe, idbn))
        yield put({
          type: typeAction.DISPATCH_SO_LAN_KHAM_MEMBER_CARD,
          payload: result.data,
        })
        // Toast.fire({
        //   icon: 'success',
        //   title: 'Ngừng sử dụng thẻ thành viên thành công!',
        // })
      } catch (err) {
        // Toast.fire({
        //   icon: 'error',
        //   title: 'Ngừng sử dụng thẻ thành viên thất bại!',
        // })
        console.log(err)
      }
    },
  )
  // ###### TẠO THẺ THÀNH VIÊN KHI ĐÃ CÓ THẺ THANH TOÁN
  yield takeLatest(
    typeAction.PUT_CREATE_MEMBER_CARD_TTT,
    function* putCreateMemberCardTTTSaga({ type, idthe, form, formPrint, formLoad, formBNthu2 }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => cardService.putCreateMemberCardTTT(form))
        if (formBNthu2 && result?.data?.idthe) {
          formBNthu2 = { ...formBNthu2, idthe: result.data.idthe }
          yield call(() => cardService.postCardBNthu2(formBNthu2))
        }
        formPrint = { ...formPrint, maphieuthu: result?.data?.maphieuthu }
        yield call(() => generatePhieuThuTienTTV(formPrint))
        if (form.mavoucher?.trim().length > 0 && form?.listMaVoucher?.length > 0) {
          yield call(() =>
            cardService.addBenhNhanVoucher([
              { mavoucher: form?.listMaVoucher[0], idbn: form.idbn, iddv: 1889 },
            ]),
          ) //mavoucher: form.mavoucher.trim()
          yield call(() => cardService.putSuDungVoucher(form?.listMaVoucher, form.nguoitao)) //form.mavoucher.trim()
          yield put({
            type: typeAction.UPDATE_NEW_PRICE_CHI_DINH_TTV,
            payload: null,
          })
        }
        //add voucher doi tac
        if (form.mavoucherdoitac?.trim().length > 0) {
          const formatData = {
            mavoucher: form.mavoucherdoitac.trim(),
            idcn: form.idcn,
            nguoitao: form.nguoitao,
            idct: form.idct,
            giatri: form.giatri,
          }
          yield call(() => recieveService.addVoucherDoiTac(formatData))
          yield call(() =>
            cardService.putSuDungVoucher([form.mavoucherdoitac.trim()], form.nguoitao),
          )
          yield put({
            type: typeAction.SET_INFO_VOUCHER_DOITAC_TTV,
            payload: null,
          })
        }
        if (form.thett > 0) {
          const formTT = {
            idthe: form.idthe,
            idbn: form.idbn,
            nguoinhan: form.nguoitao,
            tiensudung: form.thett,
            chinhanhnap: form.chinhanhnap,
          }
          yield call(() => cashierService.postTruTienTheThanhToan(formTT))
        }
        // const listTTV = yield call(() =>
        //   cardService.getAllCardReceive(
        //     formLoad?.idcn,
        //     formLoad?.tungay,
        //     formLoad?.denngay,
        //     formLoad?.idlt,
        //     formLoad?.idnt,
        //     formLoad?.keyword,
        //     formLoad?.idct,
        //   ),
        // )
        // yield put({
        //   type: typeAction.DISPATCH_ALL_CARD_RECEIVE,
        //   payload: listTTV.data,
        // })
        yield put({
          type: typeAction.DISPATCH_INFO_TYPE_CARD,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_CARD,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_THU2_CARD,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_TONG_GIAM_TTV,
          payload: 0,
        })
        yield put({
          type: typeAction.DISPATCH_TONG_THANH_TOAN_TTV,
          payload: 0,
        })
        Toast.fire({
          icon: 'success',
          title: 'Tạo thẻ thành viên thành công!',
        })
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Tạo thẻ thành viên thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // ###### LẤY DANH SÁCH LOẠI THẺ THEO CHI NHÁNH
  yield takeLatest(typeAction.GET_LIST_TYPES_CARD_BY_CN, function* cardReceive({ type, idcn }) {
    try {
      const listTypesCardByCN = yield call(() => cardService.getListTypeCardByCN(idcn))
      yield put({
        type: typeAction.DISPATCH_LIST_TYPES_CARD_BY_CN,
        payload: listTypesCardByCN.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  // ###### LẤY TẤT CẢ DANH MỤC ĐỂ SEARCH TTV
  yield takeLatest(
    typeAction.GET_ALL_DANHMUC_SEARCH_TTV,
    function* getCacDanhMucSearchTTVSaga({ type }) {
      try {
        const TypeCard = yield call(() => cardService.listTypeCard())
        const GroupCard = yield call(() => cardService.listGroupCard())
        const listCK = yield call(() => khoVTservice.getAllChuyenKhoa())
        yield put({
          type: typeAction.DISPATCH_CHUYEN_KHOA_CARD,
          payload: listCK.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_TYPES_CARD,
          payload: TypeCard.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_GROUP_CARD,
          payload: GroupCard.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  // ###### NÂNG CẤP THẺ THÀNH VIÊN
  yield takeLatest(
    typeAction.PUT_UPGRADE_MEMBER_CARD,
    function* putUpgradeMemberCardSaga({ type, form, formPrint, formLoad, formBNthu2 }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => cardService.putUpgradeMemberCard(form))
        if (formBNthu2 && result?.data?.idthe) {
          formBNthu2 = { ...formBNthu2, idthe: result.data.idthe }
          yield call(() => cardService.postCardBNthu2(formBNthu2))
        }
        formPrint = { ...formPrint, maphieuthu: result?.data?.maphieuthu }
        yield call(() => generatePhieuThuTienTTV(formPrint))
        if (form.thett > 0) {
          const formTT = {
            idthe: form.idthe,
            idbn: form.idbn,
            nguoinhan: form.nguoitao,
            tiensudung: form.thett,
            chinhanhnap: form.chinhanhnap,
          }
          yield call(() => cashierService.postTruTienTheThanhToan(formTT))
        }
        // const listTTV = yield call(() =>
        //   cardService.getAllCardReceive(
        //     formLoad?.idcn,
        //     formLoad?.tungay,
        //     formLoad?.denngay,
        //     formLoad?.idlt,
        //     formLoad?.idnt,
        //     formLoad?.keyword,
        //     formLoad?.idct,
        //   ),
        // )
        // yield put({
        //   type: typeAction.DISPATCH_ALL_CARD_RECEIVE,
        //   payload: listTTV.data,
        // })
        // if (form.mavoucher) {
        //   yield call(() =>
        //     cardService.addBenhNhanVoucher([
        //       {
        //         mavoucher: form.mavoucher,
        //         idbn: form.idbn,
        //         iddv: 1889,
        //       },
        //     ]),
        //   )
        //   yield put({
        //     type: typeAction.UPDATE_NEW_PRICE_CHI_DINH_TTV,
        //     payload: null,
        //   })
        // }
        yield put({
          type: typeAction.DISPATCH_INFO_TYPE_CARD,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_CARD,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_THU2_CARD,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_TONG_GIAM_TTV,
          payload: 0,
        })
        yield put({
          type: typeAction.DISPATCH_TONG_THANH_TOAN_TTV,
          payload: 0,
        })
        Toast.fire({
          icon: 'success',
          title: 'Nâng cấp thẻ thành công!',
        })
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Nâng cấp thẻ thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // ###### GIA HẠN THẺ THÀNH VIÊN
  yield takeLatest(
    typeAction.PUT_EXTEND_MEMBER_CARD,
    function* putExtendMemberCardSaga({ type, form, formPrint, formLoad }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => cardService.putExtendMemberCard(form))
        formPrint = { ...formPrint, maphieuthu: result?.data?.maphieuthu }
        yield call(() => generatePhieuThuTienTTV(formPrint))
        if (form.thett > 0) {
          const formTT = {
            idthe: form.idthe,
            idbn: form.idbn,
            nguoinhan: form.nguoitao,
            tiensudung: form.thett,
            chinhanhnap: form.chinhanhnap,
          }
          yield call(() => cashierService.postTruTienTheThanhToan(formTT))
        }
        // const listTTV = yield call(() =>
        //   cardService.getAllCardReceive(
        //     formLoad?.idcn,
        //     formLoad?.tungay,
        //     formLoad?.denngay,
        //     formLoad?.idlt,
        //     formLoad?.idnt,
        //     formLoad?.keyword,
        //     formLoad?.idct,
        //   ),
        // )
        // yield put({
        //   type: typeAction.DISPATCH_ALL_CARD_RECEIVE,
        //   payload: listTTV.data,
        // })
        // if (form.mavoucher) {
        //   yield call(() =>
        //     cardService.addBenhNhanVoucher([
        //       {
        //         mavoucher: form.mavoucher,
        //         idbn: form.idbn,
        //         iddv: 1889,
        //       },
        //     ]),
        //   )
        //   yield put({
        //     type: typeAction.UPDATE_NEW_PRICE_CHI_DINH_TTV,
        //     payload: null,
        //   })
        // }
        yield put({
          type: typeAction.DISPATCH_INFO_TYPE_CARD,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_CARD,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_THU2_CARD,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_TONG_GIAM_TTV,
          payload: 0,
        })
        yield put({
          type: typeAction.DISPATCH_TONG_THANH_TOAN_TTV,
          payload: 0,
        })
        Toast.fire({
          icon: 'success',
          title: 'Gia hạn thẻ thành công!',
        })
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Gia hạn thẻ thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  //######## XEM THÔNG TIN PHIẾU THU THẺ THÀNH VIÊN
  yield takeLatest(
    typeAction.GET_INFO_PHIEUTHU_TTV,
    function* getInfoPhieuThuSaga({ type, idthept, idbn, idbnthu2, idthe }) {
      try {
        const infoPT = yield call(() => cardService.getInfoPhieuThu(idthept))
        const infoBN = yield call(() => recieveService.getInfoBN(idbn))
        const infoCard = yield call(() => cardService.infoCard(idthe))
        if (idbnthu2) {
          const infoBNthu2 = yield call(() => recieveService.getInfoBN(idbnthu2))
          yield put({
            type: typeAction.DISPATCH_INFO_BN_THU2_CARD,
            payload: infoBNthu2.data,
          })
        } else {
          yield put({
            type: typeAction.DISPATCH_INFO_BN_THU2_CARD,
            payload: null,
          })
        }
        yield put({
          type: typeAction.DISPATCH_INFO_PHIEUTHU_TTV,
          payload: infoPT.data,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_CARD,
          payload: infoBN.data,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_TYPE_CARD,
          payload: infoCard.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )

  // ###### TẠO THẺ THÀNH VIÊN CHO BN
  yield takeLatest(
    typeAction.POST_TTV_NHI_TANGSAN,
    function* listCardMemberShips({ type, form, formBNthu2, infoTTVSan, handleClose }) {
      // console.log(form, formBNthu2)
      // return
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => cardService.postCardBN(form))
        if (formBNthu2 && result?.data?.idthe) {
          formBNthu2 = { ...formBNthu2, idthe: result.data.idthe }
          yield call(() => cardService.postCardBNthu2(formBNthu2))
        }
        yield call(() =>
          cardService.postLichSuThaoTacTTV({
            // "idlichsuthe": 0,
            idthe: infoTTVSan?.idthe,
            mathe: infoTTVSan?.mathe,
            tenthe: infoTTVSan?.tenthe,
            // "tiensudung": 0,
            // "ngaythaotac": "2024-09-20T09:27:31.892Z",
            idbn: infoTTVSan?.idbn,
            // "tiengiamgia": 0,
            // "tienthanhtoan": 0,
            // "thaotac": "string",
            nguoithaotac: form?.idnguoitao,
            tungay: infoTTVSan?.tungay,
            denngay: infoTTVSan?.denngay,
            // "tennguoithaotac": "string"
          }),
        )
        yield call(() => handleClose())
        Toast.fire({
          icon: 'success',
          title: 'Tạo thẻ thành viên thành công!',
        })
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Tạo thẻ thành viên thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // ###### THÊM LOẠI THẺ THÀNH VIÊN
  yield takeLatest(
    typeAction.POST_LOAI_THE,
    function* listCardMemberShips({ type, form, resetForm, formLoad }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => cardService.postLoaiThe(form))
        const result = yield call(() => cardService.listTypeCard(formLoad?.idck, formLoad?.kw))
        yield put({
          type: typeAction.DISPATCH_LIST_TYPES_CARD,
          payload: result.data,
        })
        yield call(() => resetForm())
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}

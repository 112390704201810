import React, { useState } from 'react'
import { Button, Divider, Modal, InputNumber, Input } from 'antd'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '@mui/material/TextField'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import moment from 'moment'
import { postBNThuoc, putBNTraThuoc } from '../../../../../store/actions/drugstoreAction'
import ToastCus from '../../../../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')
const ModalPay = ({
  check,
  idLyDo,
  isModal,
  setIdLyDo,
  setCheck,
  cancelModal,
  totalPrice,
  formik_Billing,
  checkTraThuoc,
  setCheckTraThuoc,
  lyDoKhac, // lý do không lấy thuốc trước khi thanh toán
}) => {
  const idBranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const toDay = moment()
  const dispatch = useDispatch()
  const { service, BNDetail, infoVC } = useSelector((state) => state.drugstoreReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [value, setValue] = useState(0)
  const checkBsDangKeToaLai = !service?.BSKEDON || !service?.NGAYKEDON
  const handleSubmit = (values) => {
    if (checkBsDangKeToaLai) {
      ToastCus.fire({
        icon: 'error',
        title: 'Bác sĩ đang kê toa lại không thể chỉnh sửa!',
      })
      return
    }
    values.idbnttchung = BNDetail.idbnttc
    values.tongtien = totalPrice + (infoVC?.tongGiam ?? 0)

    values.tienthanhtoan = totalPrice
    values.tiengiamgia = infoVC?.tongGiam ?? 0

    formik_Billing.values.tienGiamGia = infoVC?.tongGiam ?? 0
    formik_Billing.values.maVoucher = infoVC?.chuoiMaVc ?? null

    formik_Billing.values.tienThanhToan = totalPrice
    formik_Billing.values.tienThuoc = totalPrice
    formik_Billing.values.ngayThanhToan = toDay.format('YYYY-MM-DDTHH:mm:ss')
    const checkTraThuocFull = service?.DanhSachThuoc?.findIndex((item) => item?.soluong > 0) // -1 là trả full thuốc
    const arrThuoc = []
    for (let items of service?.DanhSachThuoc) {
      const tienGiamVC =
        infoVC && infoVC?.listVTHHGiam?.length > 0
          ? infoVC?.listVTHHGiam?.find(
              (itemThuocDaGiam) => itemThuocDaGiam?.idthuoc === items?.idthuoc,
            )?.tiengiam
          : 0
      arrThuoc.push({
        idbnthuoc: items.idbnthuoc,
        soLuong: check ? 0 : items.soluong,
        tongTien: check ? 0 : items.soluong * items.dongia - (tienGiamVC ?? 0),
        benhNhanLayTHuoc: check ? 0 : checkTraThuoc ? 2 : 1,
        lyDoKoLayThuoc: check || checkTraThuoc ? idLyDo : 0,
        noidungkolaythuoc:
          check && idLyDo
            ? lyDoKhac?.tenlydo === 'Khác'
              ? lyDoKhac?.noidunglydokhac
              : lyDoKhac?.tenlydo
            : null,
        duocSi: infoUser?.tenNV,
        idLoaiToa: 1,
        soluongcu: checkTraThuoc ? items?.soluongcu ?? null : check ? items?.soluong : null,
        ghiChu: checkTraThuoc ? lyDoKhac : null,
        nguoitra: checkTraThuoc ? infoUser?.idnv : null,
        tiengiam: tienGiamVC ?? 0, //items?.idthuoc
      })
    }
    cancelModal()
    if (checkTraThuoc) {
      // thanh toán trả lại thuốc
      // dispatch(
      //   putBNTraThuoc(
      //     { ...formik_Billing.values, idnv: infoUser?.idnv }, // bệnh nhân thanh toán
      //     values, // bệnh nhân thanh toán chi tiết
      //     arrThuoc,
      //     idBranchLogin,
      //     resetFormData,
      //   ),
      // )
    } else {
      // thanh toán toa bình thường + sau khi trả toa
      console.log(formik_Billing.values);
      dispatch(postBNThuoc(formik_Billing.values, values, arrThuoc, idBranchLogin, resetFormData))
    }
  }
  const resetFormData = () => {
    formik.resetForm()
    formik_Billing.resetForm()
    cancelModal()
    setCheck(false)
    setCheckTraThuoc(false)
    setIdLyDo(7)
  }
  const formik = useFormik({
    initialValues: {
      idbntt: 0,
      idbnttchung: 0,
      idthe: null,
      tienmat: 0,
      chuyenkhoan: 0,
      thett: 0,
      momo: 0,
      vnpay: 0,
      zalopay: 0,
      pos: 0,
      tongtien: 0,
      tienthanhtoan: 0,
      trangthaithanhtoan: 2, // 2 => đã thanh toán
    },
    onSubmit: (values) => handleSubmit(values),
  })
  const { tienmat, chuyenkhoan, momo, vnpay, zalopay, pos, thett } = formik.values
  const totalPay = [tienmat, chuyenkhoan, momo, vnpay, zalopay, pos, thett].reduce(
    (acc, curr) => acc + curr,
    0,
  )
  // thực hiện xử lý các ô input
  const onChangInput = (name, value) => {
    // const { value, name } = event.target
    //* nếu value === '' thì value = 0
    //* ngược lại set set giá trị về só thực, và giá trị phải lớn hơn 0
    //* trường hợp value null hoặt unf thì giá trị = 0
    const newValue = value === '' ? 0 : Math.max(0, parseFloat(value) || 0)
    // tạo gia mãng value nháp
    const updatedValues = {
      ...formik.values,
      [name]: newValue,
    }
    // tính tổng trước
    const totalPay = [
      updatedValues.tienmat,
      updatedValues.chuyenkhoan,
      updatedValues.momo,
      updatedValues.vnpay,
      updatedValues.zalopay,
      updatedValues.pos,
      updatedValues.thett,
    ].reduce((acc, curr) => acc + curr, 0)
    // if(newValue !== 0){
    //   setValue(prevState =>  totalPay + prevState)
    // }
   
    // nếu lớn hơn giá trị không thực hiện
    if (totalPay <= totalPrice) {
      formik.setFieldValue(name, newValue)
    }
  }
  const handleKeyDown = (event) => {
    const { name } = event.target
    // Nếu phím "P" được nhấn
    if (event.key === 'P' || event.key === 'p') {
      // Tính tổng giá trị hiện tại của các input
      const totalPay = [
        formik.values.tienmat,
        formik.values.chuyenkhoan,
        formik.values.momo,
        formik.values.vnpay,
        formik.values.zalopay,
        formik.values.pos,
        formik.values.thett,
      ].reduce((acc, curr) => acc + curr, 0)

      // Tính giá trị cần thiết để tổng đạt tới totalPrice
      const remainingValue = Math.max(0, totalPrice - totalPay)
      // if(value < totalPrice){
      //   setValue(remainingValue + totalPay)
      // }
      
      // Đặt giá trị này cho ô input hiện tại
      formik.setFieldValue(name, remainingValue)
      event.preventDefault()
    }
  }
  const onChangePriceCustom = (value) => {
    setValue( value)
    // const newValue = value === '' ? 0 : Math.max(0, parseFloat(value) || 0)
    // console.log(newValue);
    
    // const updatedValues = {
    //   ...formik.values,
    //   tienmat: newValue,
    // }
    // // tính tổng trước
    // const totalPay = [
    //   updatedValues.tienmat,
    //   updatedValues.chuyenkhoan,
    //   updatedValues.momo,
    //   updatedValues.vnpay,
    //   updatedValues.zalopay,
    //   updatedValues.pos,
    //   updatedValues.thett,
    // ].reduce((acc, curr) => acc + curr, 0)
    // if (totalPay <= totalPrice) {
    //   formik.setFieldValue('tienmat', newValue)
    // }
  }

  return (
    <Modal
      footer={null}
      onCancel={cancelModal}
      title={<p className="text-blue-500 text-xl">Thanh toán chi tiết</p>}
      open={isModal}
    >
      <form className="mt-10 flex flex-col gap-5" action="">
        <div className="grid grid-cols-2 gap-5">
          <div>
            <label className="font-semibold">Tiền mặt</label>
            <InputNumber
              value={formik.values.tienmat}
              name="tienmat"
              onKeyUp={handleKeyDown}
              onChange={(value) => onChangInput('tienmat', value)} //onChangeInput
              className="w-full"
              step={100}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
          <div>
            <label className="font-semibold">Chuyển khoản</label>
            <InputNumber
              value={formik.values.chuyenkhoan}
              name="chuyenkhoan"
              onKeyUp={handleKeyDown}
              onChange={(value) => onChangInput('chuyenkhoan', value)} //onChangeInput
              className="w-full"
              step={100}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-5">
          <div>
            <label className="font-semibold">Momo</label>
            <InputNumber
              value={formik.values.momo}
              name="momo"
              onKeyUp={handleKeyDown}
              onChange={(value) => onChangInput('momo', value)} //onChangeInput
              className="w-full"
              step={100}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
          <div>
            <label className="font-semibold">VNPay</label>
            <InputNumber
              value={formik.values.vnpay}
              name="vnpay"
              onKeyUp={handleKeyDown}
              onChange={(value) => onChangInput('vnpay', value)} //onChangeInput
              className="w-full"
              step={100}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-5">
          <div>
            <label className="font-semibold">Zalopay</label>
            <InputNumber
              value={formik.values.zalopay}
              name="zalopay"
              onKeyUp={handleKeyDown}
              onChange={(value) => onChangInput('zalopay', value)} //onChangeInput
              className="w-full"
              step={100}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
          <div>
            <label className="font-semibold">Máy POS</label>
            <InputNumber
              value={formik.values.pos}
              name="pos"
              onKeyUp={handleKeyDown}
              onChange={(value) => onChangInput('pos', value)} //onChangeInput
              className="w-full"
              step={100}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5">
          <div>
            <label className="font-semibold">Thẻ thanh toán</label>
            <InputNumber
              value={formik.values.thett}
              name="thett"
              onKeyUp={handleKeyDown}
              onChange={(value) => onChangInput('thett', value)} //onChangeInput
              className="w-full"
              step={100}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
          <div className="flex items-center">
            <label className="font-semibold text-red-500">* Chưa đăng kí thẻ</label>
            {/* <label className="font-semibold">
              SỐ DƯ: {formattedNumber(0)} VNĐ
            </label> */}
          </div>
        </div>
        <div className="">
          <Divider dashed />
          <ul className="flex flex-col gap-3">
            <li className="flex justify-between">
              <h2 className="w-2/3 font-semibold text-base">Tiền khách đưa</h2>
              <InputNumber
                value={value}
                  name="tienmat"
                // onKeyUp={handleKeyDown}
                onChange={onChangePriceCustom} //onChangeInput
                className="w-36"
                step={100}
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
                suffix="VNĐ"
              />
            </li>
            <li className="flex justify-between">
              <h2 className="w-2/3 font-semibold text-base">Tổng tiền </h2>
              <Input
              
                className="w-36 font-semibold text-green-700"
                value={formattedNumber(totalPrice)}
                readOnly
                variant="filled"
                suffix="VNĐ"
              />
              {/* <span className="w-1/3 text-end">{formattedNumber(totalPay)} VNĐ</span> */}
            </li>
            <li className="flex justify-between">
              <h2 className="w-2/3 font-semibold text-base">Tiền thừa </h2>
              <Input
                className="w-36 font-semibold text-red-700"
                value={formattedNumber(totalPrice - value)}
                readOnly
                variant="filled"
                suffix="VNĐ"
              />
            </li>
            {/* <li className="flex">
              <h2 className="w-2/3 font-semibold text-base">Tổng thanh toán </h2>
              <span className="w-1/3 text-end text-blue-500 font-semibold">
                {formattedNumber(totalPay)} VNĐ
              </span>
            </li> */}
          </ul>
          <div className="mt-10 flex justify-end">
            <Button
              disabled={totalPrice > totalPay || checkBsDangKeToaLai}
              onClick={formik.handleSubmit}
              type="primary "
              className="w-full"
            >
              Thanh toán
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default ModalPay

import { Modal, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import * as typeAction from '../../../store/constants/constants'
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { suCoService } from '../../../services/suCoService/suCoServvice'

const ModalChangeBranch = () => {
  const dispatch = useDispatch()
  const [branchs, setBranchs] = useState([])
  const { isChangeBranch } = useSelector((state) => state.modalReducer)
  const ID_BRANCH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY_LOGIN = Number(localStorage.getItem('id_company'))
  const [Branch, setBranch] = useState({ idBranch: ID_BRANCH_LOGIN, idct: ID_COMPANY_LOGIN })
  const cancelModal = () => {
    dispatch({
      type: typeAction.DISPATCH_CLOSE_CHANGE_BRANCH,
    })
  }

  const handleOK = async () => {
    await localStorage.setItem('BRANH_LOGIN', Branch.idBranch)
    await localStorage.setItem('id_company', Branch.idct)
    cancelModal()
    window.location.reload()
  }
  const fetchBranch = async () => {
    try {
      const { data } = await suCoService.getAllBranh()
      setBranchs(data)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchBranch()
  }, [])
  return (
    <Modal
      onOk={handleOK}
      okText="Chuyển đổi"
      cancelText="Huỷ"
      onCancel={cancelModal}
      open={isChangeBranch}
      title={
        <h2 className="text-center">
          Chuyển chi nhánh đăng nhập <SyncAltOutlinedIcon />
        </h2>
      }
    >
      <p className="font-semibold">Tên chi nhánh:</p>
      <Select
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        showSearch
        value={Branch.idBranch}
        onChange={(value, option) => setBranch({ idBranch: option.value, idct: option.idct })}
        options={branchs?.map((item) => ({
          label: item.tenchinhanh,
          value: item.idchinhanh,
          idct: item.idct,
        }))}
        className="w-full"
      />
    </Modal>
  )
}

export default ModalChangeBranch

import { https } from "../apiService";

export const branchService = {
  getListBranch: (id) => https.get(`chinhanh/?idCT=${id}`),
  getBranchNotVP: (id) => https.get(`ChiNhanh/GetAllByIdCTNotVanPhong?idCT=${id}`),
  getDefaultBranch: (username) => https.get(`ChiNhanh/user/${username}`),
  getbranchLogin: (id) => https.get(`ChiNhanh/GetChiNhanh/${id}`),
  getCaLamViet: () => https.get("calamviec"),
  getListBranchDetail: (IDCompany,fromDate,toDate, keyword) =>
    https.get(`ChiNhanh/GetChiNhanhByIDCT?idct=${IDCompany}&keyword=${keyword}&fromDate=${fromDate}&toDate=${toDate}`),
  getInfoBranch: (id) => https.get(`ChiNhanh/GetChiNhanhByID?idchinhanh=${id}`),
  getStaffs: () => https.get('NhanVien'),
  postBranch: (form) => https.post('ChiNhanh/AddChiNhanh', form),
  deleteBranchByID: (id) => https.delete(`ChiNhanh/DeleteChiNhanh/${id}`),
  updateBranch: (form) => https.put(`ChiNhanh/UpdateChiNhanh/${form.idChiNhanh}`, form),
  getBranchsByIdCtyAndIdChuyenKhoa:(idct,idChuyenKhoa) => https.get(`ChiNhanh/GetChiNhanhByCTYAndChuyenKhoa?idCT=${idct}&idChuyenKhoa=${idChuyenKhoa}`)
  // updateBranch: (form) => https.put(`ChiNhanh/GetChiNhanhByID/${form.idChiNhanh}`, form)
};

import JsBarcode from 'jsbarcode'
import moment from 'moment/moment'
import { generateLogo, generateThuVietNamese } from '../algorithm'
import { calculateAgeForReport } from '../calculateAge'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord } from '../splitWordForReport'
import { generatePhieuTuVanThuocNgoai } from './phieuTuVanThuocNgoai'

export const generatePhieuToaThuoc = (infoBn, benhNhanTTC, toaThuoc, PkDangNhap) => {
  // tach toa thuoc
  const toaThuocTuVan = toaThuoc?.filter(
    (item) =>
      item?.idphanloai === 3 ||
      item?.idphanloai === 5 ||
      item?.idnhomthuoc === 69 ||
      item?.idnhomthuoc === 125 ||
      item?.idnhomthuoc === 237,
  )
  const toaThuocXuat = toaThuoc?.filter(
    (item) =>
      item?.idphanloai !== 3 &&
      item?.idphanloai !== 5 &&
      item?.idnhomthuoc !== 69 &&
      item?.idnhomthuoc !== 125 &&
      item?.idnhomthuoc !== 237,
  )

  const doc = jsPDFCus('p', 'mm', 'a5')
  let yPos = 10
  let xPos = 8
  let ruleWidth = 55 // for text of price is longer
  const pageWidth = doc.internal.pageSize.width

  //in toa thuoc
  if (toaThuocXuat.length > 0) {
    //header
    generateLogo(doc, xPos, PkDangNhap)

    JsBarcode('#barcode', `TT${benhNhanTTC.idbnttc}`, {
      displayValue: false,
    })
    const canvas = document.getElementById('barcode')
    const barcodeDataURL = canvas.toDataURL()
    const barcodeWidth = 35 // Width of the barcode image
    const barcodeX = pageWidth - 45 // X coordinate of the barcode
    doc.addImage(barcodeDataURL, 'PNG', pageWidth - 45, 5, 35, 14)
    // Calculate the center position for text
    const text = `TT${benhNhanTTC.idbnttc}`
    const textWidth = (doc.getStringUnitWidth(text) * 11) / doc.internal.scaleFactor // Width of the text
    const textX = barcodeX + (barcodeWidth - textWidth) / 2 // Centered X coordinate for the text
    doc.setFontSize(10)
    doc.text(text, textX, 22)

    doc.text(`Mã BN: ${benhNhanTTC.benhNhan.maBenhNhan ?? ''}`, pageWidth - 45, 26)
    doc.setFont('times', 'bold')
    doc.setFontSize(14)
    doc.text('TOA THUỐC', doc.internal.pageSize.width / 2, 30, { align: 'center' })
    let textWidthName = doc.getTextDimensions('Họ và tên: ').w
    doc.setFontSize(11)
    doc.text(benhNhanTTC.benhNhan.tenBenhNhan.toUpperCase(), textWidthName, 35)
    doc.setFont('times', 'regular')
    doc.setFontSize(10)
    doc.text('Họ và tên: ', xPos, 35)
    let textWidthGioiTinh = doc.getTextDimensions('Giới tính: ').w
    doc.text('Giới tính:', pageWidth - 50, 35)
    doc.setFont('times', 'bold')
    doc.text(benhNhanTTC.benhNhan.gioiTinh, pageWidth - 50 + textWidthGioiTinh, 35)
    doc.setFont('times', 'regular')
    doc.text(`Ngày sinh: ${moment(benhNhanTTC.benhNhan.ngaySinh).format('DD/MM/YYYY')}`, xPos, 40)
    doc.text(`Tuổi: ${calculateAgeForReport(benhNhanTTC.benhNhan.ngaySinh)}`, pageWidth - 80, 40)
    //dịa chỉ
    const diaChiBenhNhan = `Địa chỉ: ${infoBn.diaChi ?? ''}${infoBn.tenPhuongXa ? ', ' + infoBn.tenPhuongXa : ''}${infoBn.tenQuanHuyen ? ', ' + infoBn.tenQuanHuyen : ''}${infoBn.tenTinhTP ? ', ' + infoBn.tenTinhTP : ''}`
    if (diaChiBenhNhan.length > 80) {
      const wordsArray = splitLongWord(diaChiBenhNhan, 80)
      doc.text(wordsArray[0], xPos, 45)
      doc.text(wordsArray[1], xPos, 50)
      yPos = 50
    } else {
      doc.text(diaChiBenhNhan, xPos, 45)
      yPos = 45
    }
    yPos += 5
    doc.text('Cân nặng: ', xPos, yPos)
    if (PkDangNhap.idChuyenKhoa === 1) {
      doc.text('Chiều cao: ', pageWidth / 2 - 20, yPos)
      doc.text('Nhiệt độ: ', pageWidth - 50, yPos)
    } else {
      doc.text('Mạch ', pageWidth / 2 - 20, yPos)
      doc.text('Huyết áp: ', pageWidth - 50, yPos)
    }

    doc.setFont('times', 'bold')
    let canNangWidth = doc.getTextDimensions('Cân nặng: ').w
    let chieuCaoWidth = doc.getTextDimensions('Chiều cao: ').w
    let nhietDoWidth = doc.getTextDimensions('Nhiệt độ: ').w
    let machWidth = doc.getTextDimensions('Mạch: ').w
    let huyetApWidth = doc.getTextDimensions('Huyết áp: ').w

    doc.text(`${benhNhanTTC.cannang ? `${benhNhanTTC.cannang} kg` : ''}`, canNangWidth + xPos, yPos)
    if (PkDangNhap.idChuyenKhoa === 1) {
      doc.text(
        `${benhNhanTTC.chieucao ? `${benhNhanTTC.chieucao} cm` : ''}`,
        chieuCaoWidth + pageWidth / 2 - 20,
        yPos,
      )
      doc.text(
        `${benhNhanTTC.nhietdo ? `${benhNhanTTC.nhietdo} °C` : ''}`,
        nhietDoWidth + pageWidth - 50,
        yPos,
      )
    } else {
      doc.text(
        `${benhNhanTTC.mach ? `${benhNhanTTC.mach} lần/phút` : ''}`,
        machWidth + pageWidth / 2 - 20,
        yPos,
      )
      doc.text(
        `${benhNhanTTC.huyetap ? `${benhNhanTTC.huyetap} mmHg` : ''}`,
        huyetApWidth + pageWidth - 50,
        yPos,
      )
    }
    yPos += 5
    doc.setFont('times', 'regular')
    //chẩn đoán
    if (`Chẩn đoán: ${benhNhanTTC.chandoan ?? ''}`.length > 80) {
      const wordsArray = splitLongWord(`Chẩn đoán: ${benhNhanTTC.chandoan ?? ''}`, 80)
      for (let index = 0; index < wordsArray.length; index++) {
        doc.text(wordsArray[index], xPos, yPos)
        yPos += 5
      }
    } else {
      doc.text(`Chẩn đoán: ${benhNhanTTC.chandoan ?? ''}`, xPos, yPos)
      yPos += 5
    }
    // do khuc xa mat
    if (PkDangNhap.idChuyenKhoa === 4 && benhNhanTTC?.dataDoKhucXa?.nguoikham) {
      doc.setFont('times', 'bold')
      doc.text('THỊ LỰC (VISUAL ACUITY)', doc.internal.pageSize.width / 2, yPos, {
        align: 'center',
      })
      yPos -= 4
      //table thi luc
      doc.line(xPos + 10, yPos + 6, 130, yPos + 6)
      doc.line(xPos + 10, yPos + 12, 130, yPos + 12)
      //text header
      doc.setFontSize(10)
      doc.setFont('times', 'regular')
      doc.text('MP (OD)', 72, yPos + 10)
      doc.text('MT (OS)', 92, yPos + 10)
      doc.text('2M (OU)', 112, yPos + 10)

      doc.text('Không kính', xPos + 15, yPos + 18)
      doc.setFont('times', 'italic')
      doc.text('without Rx', xPos + 15, yPos + 22)
      doc.setFont('times', 'regular')
      doc.text('Kính lỗ PH', xPos + 15, yPos + 28)
      doc.text('Kính cũ (CC)', xPos + 15, yPos + 36)

      //data
      //khong kinh xa
      doc.text(`${benhNhanTTC?.dataDoKhucXa?.thiluckhongkinhxA_MP ?? ''}`, 80, yPos + 17, {
        align: 'center',
      })
      doc.text(`${benhNhanTTC?.dataDoKhucXa?.thiluckhongkinhxA_MT ?? ''}`, 100, yPos + 17, {
        align: 'center',
      })
      doc.text(`${benhNhanTTC?.dataDoKhucXa?.thiluckhongkinhxA_2M ?? ''}`, 120, yPos + 17, {
        align: 'center',
      })
      //khong kinh gan
      doc.text(`${benhNhanTTC?.dataDoKhucXa?.thiluckhongkinhgaN_MP ?? ''}`, 80, yPos + 23, {
        align: 'center',
      })
      doc.text(`${benhNhanTTC?.dataDoKhucXa?.thiluckhongkinhgaN_MT ?? ''}`, 100, yPos + 23, {
        align: 'center',
      })
      doc.text(`${benhNhanTTC?.dataDoKhucXa?.thiluckhongkinhgaN_2M ?? ''}`, 120, yPos + 23, {
        align: 'center',
      })
      //kinh lo ph xa
      doc.text(`${benhNhanTTC?.dataDoKhucXa?.thiluckinhloxA_MP ?? ''}`, 80, yPos + 29, {
        align: 'center',
      })
      doc.text(`${benhNhanTTC?.dataDoKhucXa?.thiluckinhloxA_MT ?? ''}`, 100, yPos + 29, {
        align: 'center',
      })
      doc.text(`${benhNhanTTC?.dataDoKhucXa?.thiluckinhloxA_2M ?? ''}`, 120, yPos + 29, {
        align: 'center',
      })
      //kinh cu xa
      doc.text(`${benhNhanTTC?.dataDoKhucXa?.thiluckinhcuxA_MP ?? ''}`, 80, yPos + 35, {
        align: 'center',
      })
      doc.text(`${benhNhanTTC?.dataDoKhucXa?.thiluckinhcuxA_MT ?? ''}`, 100, yPos + 35, {
        align: 'center',
      })
      doc.text(`${benhNhanTTC?.dataDoKhucXa?.thiluckinhcuxA_2M ?? ''}`, 120, yPos + 35, {
        align: 'center',
      })
      //kinh cu gan
      doc.text(`${benhNhanTTC?.dataDoKhucXa?.thiluckinhcugaN_MP ?? ''}`, 80, yPos + 41, {
        align: 'center',
      })
      doc.text(`${benhNhanTTC?.dataDoKhucXa?.thiluckinhcugaN_MT ?? ''}`, 100, yPos + 41, {
        align: 'center',
      })
      doc.text(`${benhNhanTTC?.dataDoKhucXa?.thiluckinhcugaN_2M ?? ''}`, 120, yPos + 41, {
        align: 'center',
      })

      doc.line(xPos + 42, yPos + 18, 130, yPos + 18)
      doc.text('Xa', xPos + 45, yPos + 17)
      doc.setFont('times', 'italic')
      doc.text('Dist', xPos + 46 + doc.getTextDimensions('Xa').w, yPos + 17)

      doc.line(xPos + 10, yPos + 24, 130, yPos + 24)
      doc.setFont('times', 'regular')
      doc.text('Gần', xPos + 45, yPos + 23)
      doc.setFont('times', 'italic')
      doc.text('Near', xPos + 46 + doc.getTextDimensions('Gần').w, yPos + 23)

      doc.line(xPos + 10, yPos + 30, 130, yPos + 30)
      doc.setFont('times', 'regular')
      doc.text('Xa', xPos + 45, yPos + 29)
      doc.setFont('times', 'italic')
      doc.text('Dist', xPos + 46 + doc.getTextDimensions('Xa').w, yPos + 29)

      doc.line(xPos + 42, yPos + 36, 130, yPos + 36)
      doc.setFont('times', 'regular')
      doc.text('Xa', xPos + 45, yPos + 35)
      doc.setFont('times', 'italic')
      doc.text('Dist', xPos + 46 + doc.getTextDimensions('Xa').w, yPos + 35)

      doc.line(xPos + 10, yPos + 42, 130, yPos + 42)
      doc.setFont('times', 'regular')
      doc.text('Gần', xPos + 45, yPos + 41)
      doc.setFont('times', 'italic')
      doc.text('Near', xPos + 46 + doc.getTextDimensions('Gần').w, yPos + 41)

      doc.line(xPos + 10, yPos + 6, xPos + 10, yPos + 42)
      doc.line(50, yPos + 12, 50, yPos + 42)

      doc.line(70, yPos + 6, 70, yPos + 42)
      doc.line(90, yPos + 6, 90, yPos + 42)
      doc.line(110, yPos + 6, 110, yPos + 42)
      doc.line(130, yPos + 6, 130, yPos + 42)

      yPos += 47

      doc.setFont('times', 'bold')
      doc.text('NHÃN ÁP (TONOMETRY)', doc.internal.pageSize.width / 2, yPos, { align: 'center' })
      doc.setFont('times', 'regular')
      doc.text('MP (OD)', xPos + 42, yPos + 7)
      doc.text('MT (OS)', xPos + 72, yPos + 7)
      yPos -= 4
      doc.line(xPos + 35, yPos + 6, xPos + 95, yPos + 6)
      doc.line(xPos + 35, yPos + 12, xPos + 95, yPos + 12)
      doc.line(xPos + 35, yPos + 18, xPos + 95, yPos + 18)

      doc.line(xPos + 35, yPos + 6, xPos + 35, yPos + 18)
      doc.line(xPos + 65, yPos + 6, xPos + 65, yPos + 18)
      doc.line(xPos + 95, yPos + 6, xPos + 95, yPos + 18)

      doc.text(
        `${benhNhanTTC?.dataDoKhucXa?.nhanaP_MP ?? ''}`,
        (2 * xPos + 35 + 65) / 2,
        yPos + 17,
        {
          align: 'center',
        },
      )
      doc.text(
        `${benhNhanTTC?.dataDoKhucXa?.nhanaP_MT ?? ''}`,
        (2 * xPos + 65 + 95) / 2,
        yPos + 17,
        {
          align: 'center',
        },
      )
      yPos += 24
    }

    yPos -= 4
    // Add a line
    doc.line(xPos, yPos, pageWidth - 10, yPos)
    yPos += 5
    doc.setFontSize(9)
    if (benhNhanTTC?.khongCoToaNhungVanInToa) {
      // chuyên khoa nhi + mắt không có thuốc nhưng vẫn in phiếu toa thuốc
    } else {
      for (let i = 0; i < toaThuocXuat.length; i++) {
        // Check if content exceeds the page height
        if (yPos > 200) {
          doc.addPage() // Add a new page
          yPos = 10 // Reset yPos for new page
        }

        //chia 2 cột
        doc.setFont('times', 'bold')
        //check text dài
        doc.text(`${toaThuocXuat[i].soLuong} ${toaThuocXuat[i].dvt}`, pageWidth - 25, yPos)
        if (`${i + 1}. ${toaThuocXuat[i].tenhoatchat || toaThuocXuat[i].tenHoatChat}`.length > 65) {
          const wordsArray = splitLongWord(
            `${i + 1}. ${toaThuocXuat[i].tenhoatchat || toaThuocXuat[i].tenHoatChat}`,
            65,
          )
          for (let j = 0; j < wordsArray.length; j++) {
            doc.text(`${wordsArray[j]}`, xPos, yPos)
            yPos += 4
          }
        } else {
          doc.text(
            `${i + 1}. ${toaThuocXuat[i].tenhoatchat || toaThuocXuat[i].tenHoatChat}`,
            xPos,
            yPos,
          )
          yPos += 4
        }
        doc.setFont('times', 'italic')
        //check text cách dùng dài
        if (
          `Một ngày ${toaThuocXuat[i].duongDung ?? ''} ${toaThuocXuat[i].soLanNgay} lần, một lần ${toaThuocXuat[i].lieuDung} ${toaThuocXuat[i].donvidung} ${toaThuocXuat[i].ghiChu ? `(${toaThuocXuat[i].ghiChu})` : ''}`
            .length > 65
        ) {
          const wordsArray = splitLongWord(
            `Một ngày ${toaThuocXuat[i].duongDung ?? ''} ${toaThuocXuat[i].soLanNgay} lần, một lần ${toaThuocXuat[i].lieuDung} ${toaThuocXuat[i].donvidung} ${toaThuocXuat[i].ghiChu ? `(${toaThuocXuat[i].ghiChu})` : ''}`,
            65,
          )
          for (let j = 0; j < wordsArray.length; j++) {
            doc.text(`${wordsArray[j]}`, xPos, yPos)
            yPos += 4
          }
        } else {
          doc.text(
            `Một ngày ${toaThuocXuat[i].duongDung ?? ''} ${toaThuocXuat[i].soLanNgay} lần, một lần ${toaThuocXuat[i].lieuDung} ${toaThuocXuat[i].donvidung} ${toaThuocXuat[i].ghiChu ? `(${toaThuocXuat[i].ghiChu})` : ''}`,
            xPos,
            yPos,
          )
          yPos += 4
        }
        yPos += 1
      }
    }
    yPos = yPos - 5

    if (PkDangNhap.idChuyenKhoa === 4) {
      if (doc.internal.pageSize.height - yPos < 50) {
        doc.addPage()
        yPos = 10
      }
    } else {
      if (doc.internal.pageSize.height - yPos < 80) {
        doc.addPage()
        yPos = 10
      }
    }

    yPos += 5
    doc.line(xPos, yPos, pageWidth - 10, yPos)
    yPos += 5
    doc.setFont('times', 'bold')
    const timeWidth = doc.getTextDimensions('Chủ nhật - 03/03/2024').w
    doc.text(
      `${generateThuVietNamese(benhNhanTTC.ngaytaikham)} - ${moment(benhNhanTTC.ngaytaikham).format('DD/MM/YYYY')}`,
      timeWidth,
      yPos,
    )
    doc.setFont('times', 'regular')
    doc.text('Ngày tái khám: ', xPos, yPos)

    const dateText = `Ngày ${moment(benhNhanTTC.ngaykham).format('DD')} tháng ${moment(benhNhanTTC.ngaykham).format('MM')} năm ${moment(benhNhanTTC.ngaykham).format('YYYY')}`
    const doctorText = benhNhanTTC.bskham ?? ''
    const dateTextWidth = doc.getTextWidth(dateText)
    const doctorTextWidth = doc.getTextWidth(doctorText)
    const dateTextX = pageWidth - dateTextWidth - 15 // 15 is the padding from the right edge

    // Calculate the center point of the date text
    const dateCenterX = dateTextX + dateTextWidth / 2

    // Position the doctor text centered below the date text
    const doctorTextX = dateCenterX - doctorTextWidth / 2

    doc.text(dateText, dateTextX, yPos)
    yPos += 5

    doc.text('Lời dặn:', xPos, yPos)
    doc.text('Bác sĩ khám bệnh', pageWidth - 46, yPos)
    doc.setFont('times', 'bold')
    doc.text(doctorText, doctorTextX, yPos + 20)

    //lời dặn
    yPos += 4
    doc.setFont('times', 'regular')
    if (`- ${benhNhanTTC.loidan ?? ''}`.length > 50) {
      const wordsArray = splitLongWord(`- ${benhNhanTTC.loidan ?? ''}`, 50)
      for (let j = 0; j < wordsArray.length; j++) {
        doc.text(`${wordsArray[j]}`, xPos, yPos)
        yPos += 3.5
      }
      yPos += 1
    } else {
      doc.text(`- ${benhNhanTTC.loidan ?? ''}`, xPos, yPos)
      yPos += 4.5
    }

    //tái khám miễn phí
    // if (PkDangNhap.idChuyenKhoa === 1) {
    //   doc.setFont('times', 'bold')
    //   const defaultLoiDanTaiKham =
    //     '- Tái khám miễn phí khi đúng hẹn tối đa 3 lần trong cùng 1 đợt bệnh'
    //   const wordsArrayTaiKham = splitLongWord(defaultLoiDanTaiKham, 50)
    //   for (let j = 0; j < wordsArrayTaiKham.length; j++) {
    //     doc.text(`${wordsArrayTaiKham[j]}`, xPos, yPos)
    //     yPos += 5
    //   }
    // } else {
    //   doc.setFont('times', 'bold')
    //   const defaultLoiDanTaiKham = ' Tái khám 1 lần miễn phí khi đúng hẹn.'
    //   const wordsArrayTaiKham = splitLongWord(defaultLoiDanTaiKham, 50)
    //   for (let j = 0; j < wordsArrayTaiKham.length; j++) {
    //     doc.text(`${wordsArrayTaiKham[j]}`, xPos, yPos)
    //     yPos += 5
    //   }
    // }

    doc.setFont('times', 'italic')
    //lời dặn default

    const defaultLoiDan =
      '- Toa thuốc chỉ có giá trị tại thời điểm bác sĩ chẩn đoán bệnh. Chúng tôi miễn trừ trách nhiệm trong trường hợp toa thuốc khách hàng sử dụng không ngay tại thời điểm chẩn đoán bệnh'
    const wordsArray = splitLongWord(defaultLoiDan, 50)
    for (let j = 0; j < wordsArray.length; j++) {
      doc.text(`${wordsArray[j]}`, xPos, yPos)
      yPos += 3.5
    }
    yPos += 3.5
    doc.line(xPos, yPos - 4, pageWidth - 10, yPos - 4)
    if (PkDangNhap.idChuyenKhoa === 1) {
      doc.setFontSize(8)
      doc.setFont('times', 'bold')
      doc.text('Tái khám ngay khi có các dấu hiệu sau :', xPos, yPos)
      doc.setFont('times', 'regular')
      yPos += 4
      doc.text('* Ngủ li bì hay vật vã', xPos, yPos)
      doc.text('* Co giật', xPos + 30, yPos)
      doc.text('* Nôn ói nhiều', xPos + 45, yPos)
      doc.text('* Vết mỗ sưng nề hoặc chảy máu', xPos + 65, yPos)
      doc.text('* Tiêu máu', xPos + 110, yPos)
      yPos += 4
      doc.text('* Sốt cao khó hạ', xPos, yPos)
      doc.text('* Tím tái', xPos + 30, yPos)
      doc.text('* Bỏ ăn uống', xPos + 45, yPos)
      doc.text('* Thở bất thường', xPos + 65, yPos)
      doc.text('* Bệnh nặng hơn', xPos + 110, yPos)
    }

    if (PkDangNhap.idChuyenKhoa === 2) {
      doc.setFontSize(8)
      doc.setFont('times', 'italic')
      doc.text('(*) Mang theo toa thuốc này khi tái khám.', xPos, yPos)
    }
    // doc.save('phieuToaThuoc.pdf')
    doc.text(
      `(*)Ngày in phiếu: ${moment().format('DD/MM/YYYY HH:mm:ss')}`,
      pageWidth - 140,
      doc.internal.pageSize.height - 5,
    )
  }

  //in toa tu van
  if (toaThuocTuVan.length > 0) {
    if (toaThuocXuat.length > 0) {
      doc.addPage()
    }
    generatePhieuTuVanThuocNgoai(doc, infoBn, benhNhanTTC, toaThuocTuVan, PkDangNhap)
  }
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}

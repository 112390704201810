import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker, Select, TreeSelect, Button, ConfigProvider, Table } from 'antd'
import IconButton from '@mui/material/IconButton'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { reportService } from '../../../../../services/Report/reportService'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { khoVTservice } from '../../../../../services/khoVT/khoVTservice'
import { phanQuyenService } from '../../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../../store/actions/phanQuyenAction'
import { exportExcelformat } from '../../../../../utils/export/exportExcelFormat'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import { calculateSum } from '../../../../../utils/calculateSum'
import ToastCus from '../../../../../utils/Toast'
import dayjs from 'dayjs'
import moment from 'moment'
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const { SHOW_PARENT } = TreeSelect

const Vaccine = () => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [branchs, setBranchs] = useState([])
  const [chuyenkhoas, setChuyenKhoas] = useState([])
  const [data, setData] = useState([])
  const [dataExport, setDataExport] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState({
    idCompany: ID_COMPANY,
    idChuyenKhoa: '',
    idBranch: [ID_BRANCH],
    form: now,
    to: now,
  })
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoas(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBranchByChuyenKhoa = async (idct, value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(idct, value)
      setBranchs(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangSelected = (name) => (value) => {
    setForm((prevState) => ({ ...prevState, [name]: value, idBranch: [''] }))
    fetchBranchByChuyenKhoa(form?.idCompany, value)
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: '',
      key: 'all',
      children: branchs?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const handleDatePicker = (name) => (date, dateString) => {
    const value = moment(dateString, dateFormat).format(momentDate)
    setForm((prevState) => ({ ...prevState, [name]: value }))
  }
  const tProps = {
    treeData,
    value: form?.idBranch,
    onChange: (value) => setForm((prevState) => ({ ...prevState, idBranch: value })),
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const fetchData = async () => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT475',
      )
      let idChiNhanhs = null
      if (form.idBranch?.length > 0 && form.idBranch[0] !== '') {
        idChiNhanhs = form.idBranch?.map((id) => `&idChiNhanhs=${id}`).join('&')
        const { data } = await reportService.getLoiNhuanVaccine(
          form.idCompany,
          form.idChuyenKhoa,
          idChiNhanhs,
          form.form,
          form.to,
        )
        setData(data)
      } else {
        const { data } = await reportService.getLoiNhuanVaccine(
          form.idCompany,
          form.idChuyenKhoa,
          '',
          form.form,
          form.to,
        )
        setData(data)
      }
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranchByChuyenKhoa(ID_COMPANY, '')
    fetchChuyenKhoa()
  }, [])
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT476',
      )
      const formatData = data?.map((item) => ({
        Ngày: moment(item.ngayBan).format('DD/MM/YYYY'),
        Phiếu: item.maPhieu,
        'Loại phiếu': item.loaiPhieu,
        'Ngày khai trương': moment(item.ngayKhaiTruong).format('DD/MM/YYYY'),
        'Chi nhánh': item.tenChiNhanh,
        'Mã hàng': item.maHang,
        'Tên hàng': item.tenhang,
        'Đơn vị': item.dvt,
        'Số lượng': item.soLuong,
        'Giá nhập': item.tienGiaNhap,
        'Tiền vốn': item.tienVonNhapHang,
        'Giá bán': item.tienGiaBan,
        'Thành tiền': item.thanhTien,
        '% giảm': item.ptGiamGia,
        'Tiền giảm': item.tienGiam,
        'Thanh toán': item.thanhToan,
        'Lợi nhuận': item.loiNhuan,
        'Ghi chú': item.ghiChu,
      }))
      const name = 'Lợi nhuận vaccine'
      formatData?.length && exportExcelformat(formatData, name)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className="flex gap-5 justify-between border-dotted border-b-0">
        <div className="flex flex-wrap gap-5 items-center mb-2">
          <div className="w-80">
            <Select
              showSearch
              value={form.idCompany}
              onChange={(value) => {
                fetchBranchByChuyenKhoa(value, '')
                // setIdChiNhanh(null)
                setForm((prevState) => ({
                  ...prevState,
                  idCompany: value,
                  idChuyenKhoa: '',
                  idBranch: [''],
                }))
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-56">
            <Select
              onChange={onChangSelected('idChuyenKhoa')}
              className="w-full"
              value={form.idChuyenKhoa}
              options={[
                { label: 'Tất cả chuyên khoa', value: '' },
                ...chuyenkhoas.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
            />
          </div>
          <div className="w-72 ">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-3">
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('form')}
                format={dateFormat}
              />
              <span className="font-semibold text-lg">-</span>
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('to')}
                format={dateFormat}
              />
            </div>
            <div className="flex gap-3">
              <IconButton onClick={fetchData} color="primary" aria-label="delete" size="small">
                <SyncIcon />
              </IconButton>
            </div>
            <div className="font-semibold ">
              <Button
                disabled={!data?.length}
                onClick={exportToExcel}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
              >
                Xuất Excel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#EFEFEF',
                footerBg: '#EFEFEF',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            bordered
            loading={isLoading}
            columns={[
              {
                title: 'Thông tin phiếu xuất',
                key: 'tt',
                children: [
                  {
                    title: 'STT',
                    key: 'stt',
                    render: (text, record, index) => ++index,
                    align: 'center',
                    width: 60,
                  },
                  {
                    title: 'Ngày',
                    key: 'ngayBan',
                    dataIndex: 'ngayBan',
                    width: 150,
                    render: (text) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
                  },
                  {
                    title: 'Phiếu',
                    key: 'maPhieu',
                    dataIndex: 'maPhieu',
                  },
                  {
                    title: 'Loại phiếu',
                    key: 'loaiPhieu',
                    dataIndex: 'loaiPhieu',
                  },
                  {
                    title: 'Ngày khai trương',
                    key: 'ngayKhaiTruong',
                    dataIndex: 'ngayKhaiTruong',
                    width: 130,
                    render: (text) => moment(text).format('DD/MM/YYYY'),
                  },
                  {
                    title: 'Chi nhánh',
                    key: 'tenChiNhanh',
                    dataIndex: 'tenChiNhanh',
                    width: 250,
                  },
                ],
              },
              {
                title: 'Thông tin hàng hoá',
                key: 'bl',
                children: [
                  {
                    title: 'Mã hàng',
                    key: 'maHang',
                    dataIndex: 'maHang',
                  },
                  {
                    title: 'Tên hàng',
                    key: 'tenhang',
                    dataIndex: 'tenhang',
                    width: 250,
                  },
                  {
                    title: 'Đơn vị',
                    key: 'dvt',
                    dataIndex: 'dvt',
                  },
                  {
                    title: 'Số lượng',
                    key: 'soLuong',
                    dataIndex: 'soLuong',
                    align: 'right',
                  },
                ],
              },
              {
                title: 'Nhập hàng',
                key: 'bl',
                children: [
                  {
                    title: 'Giá nhập',
                    key: 'tienGiaNhap',
                    dataIndex: 'tienGiaNhap',
                    align: 'right',
                    render: (text) => formattedNumber(text || 0),
                  },
                  {
                    title: 'Tiền vốn',
                    key: 'tienVonNhapHang',
                    dataIndex: 'tienVonNhapHang',
                    align: 'right',
                    render: (text) => formattedNumber(text || 0),
                  },
                ],
              },
              {
                title: 'Xuất hàng',
                key: 'bl',
                children: [
                  {
                    title: 'Giá bán',
                    key: 'tienGiaBan',
                    dataIndex: 'tienGiaBan',
                    align: 'right',
                    render: (text) => formattedNumber(text || 0),
                  },
                  {
                    title: 'Thành tiền',
                    key: 'thanhTien',
                    dataIndex: 'thanhTien',
                    align: 'right',
                    render: (text) => formattedNumber(text || 0),
                  },
                  {
                    title: '% Giảm',
                    key: 'ptgiam',
                    dataIndex: 'ptgiam',
                    align: 'right',
                    render: (text) => formattedNumber(text || 0),
                  },
                  {
                    title: 'Tiền giảm',
                    key: 'tienGiam',
                    dataIndex: 'tienGiam',
                    align: 'right',
                    render: (text) => formattedNumber(text || 0),
                  },
                  {
                    title: 'Thanh toán',
                    key: 'thanhToan',
                    dataIndex: 'thanhToan',
                    align: 'right',
                    render: (text) => formattedNumber(text || 0),
                  },
                ],
              },

              {
                title: 'Ghi chú',
                key: 'bl',
                children: [
                  {
                    title: 'Lợi nhuận',
                    key: 'loiNhuan',
                    dataIndex: 'loiNhuan',
                    align: 'right',
                    render: (text) => formattedNumber(text || 0),
                  },
                  {
                    title: 'Ghi chú',
                    key: 'ghiChu',
                    dataIndex: 'ghiChu',
                  },
                ],
              },
            ]}
            scroll={{ y: '70vh', x: 2500 }}
            dataSource={data}
            pagination={false}
            summary={() => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={9}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'soLuong'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'tienGiaNhap'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'tienVonNhapHang'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'tienGiaBan'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'thanhTien'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'ptgiam'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'tienGiam'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'thanhToan'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'loiNhuan'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={2} index={10}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default Vaccine

import React, { useCallback, useEffect, useState } from 'react'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import LayoutApp from '../../../HOCs/LayoutApp'
import { Button, ConfigProvider, Input, Popconfirm, Table, Tooltip } from 'antd'
import {
  PlusOutlined,
  ContainerOutlined,
  DeleteOutlined,
  SearchOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  deletePartner,
  fetListPartner_Action,
  searchPartner,
} from '../../../store/actions/partnerAction'
import ModalCreatePartner from './ModalCreatePartner/ModalCreatePartner'
import ModalEditPartner from './ModalEditPartner/ModalEditPartner'
import { debounce } from 'lodash'
import * as XLSX from 'xlsx'

const Partner = () => {
  const dispatch = useDispatch()
  const { listPartner } = useSelector((state) => state.partnerReducer)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenEdit, setIsModalOpenEdit] = useState({
    show: false,
    data: {},
  })
  const [search, setSearch] = useState('')

  useEffect(() => {
    dispatch(fetListPartner_Action())
  }, [dispatch])

  const handleDeleteById = (id) => {
    dispatch(deletePartner(id))
  }

  const debounceGetDataSearch = useCallback(
    debounce((keyword) => {
      dispatch(searchPartner(keyword))
    }, 500),
    [],
  )

  const handleSearchInput = (e) => {
    const { value } = e.target
    debounceGetDataSearch(value)
    setSearch(value)
  }

  const handleClearSearch = () => {
    setSearch('')
    //get lai data
    dispatch(fetListPartner_Action())
  }
  const compare = (a, b, dataIndex) => {
    if (a[dataIndex] < b[dataIndex]) {
      return -1
    }
    if (a[dataIndex] > b[dataIndex]) {
      return 1
    }
    return 0
  }
  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
    },
    {
      title: 'Mã đối tác',
      dataIndex: 'maDoiTac',
      key: 'maDoiTac',
      align: 'center',
      width: 140,
      sorter: (a, b) => compare(a, b, 'maDoiTac'),
      showSorterTooltip: false,
    },
    {
      title: 'Tên viết tắt',
      dataIndex: 'tenVietTat',
      key: 'tenVietTat',
      align: 'center',
      width: 120,
      sorter: (a, b) => compare(a, b, 'tenVietTat'),
      showSorterTooltip: false,
    },
    {
      title: 'Tên đối tác ',
      dataIndex: 'tenDoiTac',
      key: 'tenDoiTac',
      width: 400,
      sorter: (a, b) => compare(a, b, 'tenDoiTac'),
      showSorterTooltip: false,
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'diaChi',
      key: 'diaChi',
      width: 450,
      sorter: (a, b) => compare(a, b, 'diaChi'),
      showSorterTooltip: false,
    },
    {
      title: 'SĐT',
      dataIndex: 'dienThoai',
      key: 'dienThoai',
      align: 'center',
      width: 120,
      sorter: (a, b) => compare(a, b, 'dienThoai'),
      showSorterTooltip: false,
    },
    {
      title: 'Mã số thuế',
      dataIndex: 'maSoThue',
      key: 'maSoThue',
      align: 'center',
      width: 150,
      sorter: (a, b) => compare(a, b, 'maSoThue'),
      showSorterTooltip: false,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      width: 120,
      sorter: (a, b) => compare(a, b, 'email'),
      showSorterTooltip: false,
    },
    {
      title: 'Website',
      dataIndex: 'website',
      key: 'website',
      align: 'center',
      width: 120,
      sorter: (a, b) => compare(a, b, 'website'),
      showSorterTooltip: false,
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      key: 'action',
      width: 120,
    },
  ]

  const exportToExcel = () => {
    const headers = [
      'Mã đối tác',
      'Tên viết tắt',
      'Tên đối tác',
      'Địa chỉ',
      'SĐT',
      'Mã số thuế',
      'Email',
      'Website',
    ]
    const formattedData = listPartner.map((item) => ({
      'Mã đối tác': item.maDoiTac,
      'Tên viết tắt': item.tenVietTat,
      'Tên đối tác': item.tenDoiTac,
      'Địa chỉ': `${item.diaChi ?? ''}${item.tenPhuongXa ? ', ' + item.tenPhuongXa : ''}${item.tenQuanHuyen ? ', ' + item.tenQuanHuyen : ''}${item.tenTinhTP ? ', ' + item.tenTinhTP : ''}`,
      SĐT: item.dienThoai,
      'Mã số thuế': item.maSoThue,
      Email: item.email,
      Website: item.website,
    }))
    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, 'Đối tác.xlsx')
  }

  return (
    <>
      <div className="p-5 bg-[#EFEFEF]">
        <div className="p-2 bg-white rounded-xl border">
          <div className="flex justify-between">
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalOpen(true)}>
              Tạo đối tác
            </Button>
            <Button
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 mr-auto ml-4"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
            <div className="ml-5 w-80">
              <Input
                className="w-full"
                value={search}
                placeholder="Tìm kiếm"
                onChange={handleSearchInput}
                prefix={<SearchOutlined />}
                suffix={search.length > 0 && <CloseOutlined onClick={handleClearSearch} />}
              />
            </div>
          </div>
          <div className="mt-5">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                  borderRadius: 0,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                bordered
                scroll={{
                  x: 1500,
                }}
                pagination={{
                  pageSize: 20,
                  showSizeChanger: false,
                }}
                columns={columns}
                dataSource={listPartner?.map((item, index) => ({
                  STT: ++index,
                  diaChi: `${item.diaChi ?? ''}${item.tenPhuongXa ? ', ' + item.tenPhuongXa : ''}${item.tenQuanHuyen ? ', ' + item.tenQuanHuyen : ''}${item.tenTinhTP ? ', ' + item.tenTinhTP : ''}`,
                  maDoiTac: item.maDoiTac,
                  tenDoiTac: item.tenDoiTac,
                  dienThoai: item.dienThoai,
                  email: item.email,
                  website: item.website,
                  maSoThue: item.maSoThue,
                  tenVietTat: item.tenVietTat,
                  action: (
                    <ul className="flex justify-around">
                      <li>
                        <Tooltip title="Xem" color="#108ee9">
                          <ContainerOutlined
                            onClick={() => setIsModalOpenEdit({ show: true, data: { ...item } })}
                            className="text-xl text-[#108ee9]  cursor-pointer"
                          />
                        </Tooltip>
                      </li>
                      <li>
                        <Tooltip title="Xoá" color="red">
                          <Popconfirm
                            title="Xóa đối tác"
                            onConfirm={() => handleDeleteById(item.idDoiTac)}
                            okText="Xác nhận"
                            cancelText="Hủy"
                            icon={<DeleteOutlined style={{ color: 'red' }} />}
                          >
                            <DeleteOutlined
                              //   onClick={showModal}
                              className="text-xl text-red-500  cursor-pointer"
                            />
                          </Popconfirm>
                        </Tooltip>
                      </li>
                    </ul>
                  ),
                }))}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <ModalCreatePartner isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      )}
      {isModalOpenEdit.show && (
        <ModalEditPartner
          isModalOpenEdit={isModalOpenEdit}
          setIsModalOpenEdit={setIsModalOpenEdit}
        />
      )}
    </>
  )
}

export default Partner

import { DatePicker, Input } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import _ from 'lodash'
export const configSearchTableAnt = (
  setSearchValues,
  searchValues,
  dataIndex,
  data,
  minWidth,
  date = false,
  propsChildren
) => ({
  // * setValueSearch là 1 state của component: const [searchValues, setSearchValues] = useState({})
  sorter: (a, b) => compare(a, b, dataIndex),
  showSorterTooltip: false,
  onFilter: (value, record) => record[dataIndex] === value,
  filters: date ? false : getUniqueFilters(data, dataIndex),
  filterSearch: true,
  children: [
    {
      ...propsChildren,
      // render: (text) => (date ?  (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '') : text),
      title: date ? (
        <DatePicker
          //   value={searchValues[dataIndex] !== '' ? dayjs(searchValues[dataIndex], 'YYYY-MM-DD') : ''}
          format={'DD/MM/YYYY'}
          size="small"
          onChange={(date, DateString) => {
            const formatValue = DateString
              ? moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD')
              : ''
            handleSearchChange(dataIndex, formatValue, setSearchValues)
          }}
        />
      ) : (
        <Input
          value={searchValues[dataIndex]}
          size="small"
          onChange={(e) => handleSearchChange(dataIndex, e.target.value, setSearchValues)}
        />
      ),
      onCell: () => ({
        style: {
          minWidth: minWidth,
        },
      }),
      dataIndex: dataIndex,
      key: dataIndex,
    },
  ],
})
const compare = (a, b, dataIndex) => {
  const valueA = (_.get(a, dataIndex) ?? '').toString().toLowerCase()
  const valueB = (_.get(b, dataIndex) ?? '').toString().toLowerCase()
  if (valueA < valueB) {
    return -1
  }
  if (valueA > valueB) {
    return 1
  }
  return 0
}
// lấy danh sách value filters
const getUniqueFilters = (data, dataIndex) => {
  const uniqueValues = Array.from(new Set(data?.map((item) => item[dataIndex]))) // Lấy các giá trị không trùng
  return uniqueValues?.map((value) => ({
    text: value,
    value: value,
  }))
}
const handleSearchChange = (column, value, setSearchValues) => {
  setSearchValues((prevValues) => ({
    ...prevValues,
    [column]: value,
  }))
}

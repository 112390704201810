import { call, put, select, takeLatest } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import { childrenClinicService } from '../../services/childrenClinic/childrenClinicService'
import { khamSangLocService } from '../../services/khamSangLoc/khamSangLocService'
import * as typeAction from '../constants/constants'
import { thuocKeToa } from '../actions/childrenClinicAction'
import { BNChoKhamSangLocAction } from '../actions/khamSangLocAction'
import { generatePhieuKiemTruocTiemChungLao } from '../../utils/report/phieuKiemTruocTiemChungLao'
import { generatePhieuKiemTruocTiemChungSan } from '../../utils/report/phieuKiemTruocTiemChungSan'
import { generatePhieuKiemTruocTiemChung } from '../../utils/report/phieuKiemTruocTiemChung'
import { generateKhamBenhXuatVacXin } from '../../utils/report/vacxin/phieuKhamBenhXuatVacxin'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* khamSangLocSaga() {
  // LẤY DANH SÁCH BỆNH NHÂN CHỜ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_BN_CHO_KHAM_SANG_LOC,
    function* getBNChoKhamSangLocSaga({ type, idcn }) {
      try {
        const result = yield call(() => khamSangLocService.getBNChoKhamSangLoc(idcn))
        yield put({
          type: typeAction.DISPATCH_BN_CHO_KHAM_SANG_LOC,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
  // LẤY DANH SÁCH BỆNH NHÂN ĐANG KHÁM VÀ ĐÃ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_BN_DANG_VA_DA_KHAM_SANG_LOC,
    function* getBNDaKhamSangLocSaga({ type, idcn, tuNgay, denNgay }) {
      try {
        const listDangKham = yield call(() =>
          khamSangLocService.getBNDangKhamSangLoc(idcn, tuNgay, denNgay),
        )
        const listDaKham = yield call(() =>
          khamSangLocService.getBNDaKhamSangLoc(idcn, tuNgay, denNgay),
        )
        yield put({
          type: typeAction.DISPATCH_BN_DANG_KHAM_SANG_LOC,
          payload: listDangKham.data,
        })
        yield put({
          type: typeAction.DISPATCH_BN_DA_KHAM_SANG_LOC,
          payload: listDaKham.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
  // LẤY THÔNG TIN CHI TIẾT BỆNH NHÂN KHÁM BỆNH
  yield takeLatest(
    typeAction.GET_INFO_BN_KSL,
    function* getInfoBNKhamSangLocSaga({ type, idbnttc, idbn, idcn }) {
      try {
        const result = yield call(() => khamSangLocService.getInfoBNKhamSangLoc(idbnttc))
        const lichSuTC = yield call(() => khamSangLocService.getLichSuTiemChung(idbn))
        const listVX = yield call(() => khamSangLocService.getListTonVacXin(idcn))
        const newData = listVX?.data
          .filter((items) => items?.tonKho >= 0)
          .map((items, index) => ({ ...items, ID: index }))
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KSL,
          payload: result.data,
        })
        yield put({
          type: typeAction.DISPATCH_LICHSU_TIEMCHUNG_KSL,
          payload: lichSuTC.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_TON_VAC_XIN_KSL,
          payload: newData,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
  // LƯU THÔNG TIN KHÁM SÀNG LỌC CỦA BỆNH NHÂN
  // yield takeLatest(
  //   typeAction.POST_INFO_BN_KSL,
  //   function* postInfoBNKhamSangLocSaga({ type, formksl, idcn, tungay, denngay, formPrint }) {
  //     try {
  //       yield put({
  //         type: typeAction.OPEN_LOADING_PAGE,
  //       })
  //       const { PkDangNhap } = yield select((state) => state.branchReducer)
  //       yield call(() => khamSangLocService.postInfoBNKhamSangLoc(formksl))
  //       if (PkDangNhap.idChuyenKhoa === 3) {
  //         //lao
  //         yield call(() => generatePhieuKiemTruocTiemChungLao(formPrint))
  //       } else if (PkDangNhap.idChuyenKhoa === 2) {
  //         // san
  //         yield call(() => generatePhieuKiemTruocTiemChungSan(formPrint))
  //       } else {
  //         yield call(() => generatePhieuKiemTruocTiemChung(formPrint))
  //       }

  //       // yield call(() => doKhucXaService.putListDVBN(listdv))
  //       const listCho = yield call(() => khamSangLocService.getBNChoKhamSangLoc(idcn))
  //       const listDang = yield call(() =>
  //         khamSangLocService.getBNDangKhamSangLoc(idcn, tungay, denngay),
  //       )
  //       const listDa = yield call(() =>
  //         khamSangLocService.getBNDaKhamSangLoc(idcn, tungay, denngay),
  //       )
  //       yield put({
  //         type: typeAction.DISPATCH_BN_CHO_KHAM_SANG_LOC,
  //         payload: listCho.data,
  //       })
  //       yield put({
  //         type: typeAction.DISPATCH_BN_DANG_KHAM_SANG_LOC,
  //         payload: listDang.data,
  //       })
  //       yield put({
  //         type: typeAction.DISPATCH_BN_DA_KHAM_SANG_LOC,
  //         payload: listDa.data,
  //       })
  //       yield put({
  //         type: typeAction.DISPATCH_INFO_BN_KHAM,
  //         payload: null,
  //       })
  //       yield put({
  //         type: typeAction.DISPATCH_DETAIL_BN_KHAM,
  //         payload: null,
  //       })
  //       // yield put({
  //       //   type: typeAction.DISPATCH_INFO_BN_DKX,
  //       //   payload: null,
  //       // })
  //       Toast.fire({
  //         icon: 'success',
  //         title: 'Lưu thông tin thành công!',
  //       })
  //     } catch (error) {
  //       console.log(error)
  //       Toast.fire({
  //         icon: 'error',
  //         title: 'Lưu thông tin thất bại!',
  //       })
  //     } finally {
  //       yield put({
  //         type: typeAction.CLOSE_LOADING_PAGE,
  //       })
  //     }
  //   },
  // )
  //---------------- Cập nhật THÔNG TIN KHÁM BỆNH-------------//
  yield takeLatest(
    typeAction.UPDATE_DETAIL_BN_KHAM_SANG_LOC,
    function* BNPending({
      type,
      infoBN,
      formBenhNhan,
      form,
      idcnPk,
      newArr,
      formksl,
      idcn,
      tungay,
      denngay,
      formPrint,
    }) {
      try {
        let toaThuoc = []
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => childrenClinicService.updateDetailBN(form))
        if (newArr.length !== 0) {
          const formatData = newArr?.map((item) => ({
            ...item,
            mui: parseFloat(item?.mui), //******************************//
            lieuDung: item?.lieuDung + '',
            soHoaDon: `${item?.soHoaDon}`,
          }))
          const toathuoc = yield call(() =>
            childrenClinicService.putBNThuocBSKeToaLaiVacXin(form?.idbnttc, formatData),
          )
          toaThuoc = [...toathuoc?.data?.sort((a, b) => (a?.stt ?? '').localeCompare(b?.stt ?? ''))]
          yield call(() => generateKhamBenhXuatVacXin(infoBN, result.data, toaThuoc, PkDangNhap))
          const listVX = yield call(() => khamSangLocService.getListTonVacXin(idcn))
          const newData = listVX?.data
            .filter((items) => items?.tonKho >= 0)
            .map((items, index) => ({ ...items, ID: index }))
          yield put({
            type: typeAction.DISPATCH_LIST_TON_VAC_XIN_KSL,
            payload: newData,
          })
        }
        yield call(() => khamSangLocService.postInfoBNKhamSangLoc(formksl))
        yield call(() => childrenClinicService.putInfoBN(formBenhNhan))
        if (PkDangNhap.idChuyenKhoa === 3) {
          //lao
          yield call(() => generatePhieuKiemTruocTiemChungLao(formPrint))
        } else if (PkDangNhap.idChuyenKhoa === 2) {
          // san
          yield call(() => generatePhieuKiemTruocTiemChungSan(formPrint))
        } else {
          yield call(() => generatePhieuKiemTruocTiemChung(formPrint))
        }

        // yield call(() => doKhucXaService.putListDVBN(listdv))
        // const listCho = yield call(() => khamSangLocService.getBNChoKhamSangLoc(idcn))
        const listDang = yield call(() =>
          khamSangLocService.getBNDangKhamSangLoc(idcn, tungay, denngay),
        )
        const listDa = yield call(() =>
          khamSangLocService.getBNDaKhamSangLoc(idcn, tungay, denngay),
        )
        // yield put({
        //   type: typeAction.DISPATCH_BN_CHO_KHAM_SANG_LOC,
        //   payload: listCho.data,
        // })
        yield put({
          type: typeAction.DISPATCH_BN_DANG_KHAM_SANG_LOC,
          payload: listDang.data,
        })
        yield put({
          type: typeAction.DISPATCH_BN_DA_KHAM_SANG_LOC,
          payload: listDa.data,
        })
        // yield put(BNChoKhamSangLocAction(idcnPk))
        // yield put(thuocKeToa(ID_BRANCH_LOGIN))
        // yield put({
        //   type: typeAction.DISPATCH_DETAIL_BN_KHAM,
        //   payload: null,
        // })
        // yield put({
        //   type: typeAction.DISPATCH_INFO_BN_KHAM,
        //   payload: null,
        // })
        // yield put({
        //   type: typeAction.RESET_TOA_THUOC_BN_DOCTOR,
        //   payload: [],
        // })
        yield put({
          type: typeAction.RESET_BS_KHAM_CAPNHAT,
        })
        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công!',
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      } catch (error) {
        console.log(error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Lưu thất bại!',
        })
      }
    },
  )
  // bác sĩ sửa toa VẮC XIN đã kê, kê lại toa đã lưu *********** KÊ TOA VẮC XIN
  yield takeLatest(
    typeAction.PUT_BN_THUOC_BS_KE_TOA_LAI_VACXIN_KSL,
    function* updateBNThuocBSKeToaLaiVacXin({ type, idbnttc, list, infobn, bnttc, pkdangnhap }) {
      try {
      } catch (error) {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Lưu toa thất bại!',
        })
        console.log(error)
      }
    },
  )
}

import { https } from '../apiService'

export const khamSangLocService = {
  getBNChoKhamSangLoc: (idcn) => https.get(`KhamSangLoc/GetBenhNhanChoKhamSangLoc?idcn=${idcn}`),
  getBNDangKhamSangLoc: (idcn, tuNgay, denNgay) =>
    https.get(
      `KhamSangLoc/GetBenhNhanDangKhamSangLoc?idcn=${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getBNDaKhamSangLoc: (idcn, tuNgay, denNgay) =>
    https.get(
      `KhamSangLoc/GetBenhNhanDaKhamSangLoc?idcn=${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getInfoBNKhamSangLoc: (idbnttc) =>
    https.get(`KhamSangLoc/GetBenhNhanKhamSangLoc?idbnttc=${idbnttc}`),
  postInfoBNKhamSangLoc: (form) => https.post(`KhamSangLoc/AddBenhNhanKhamSangLoc`, form),
  getListTonVacXin: (idcn) => https.get(`TonKho/GetVacxinByChiNhanh?IDChiNhanh=${idcn}`),
  postToaThuocKSL: (data) => https.post('BenhNhanThuoc/ThemBenhNhanThuocByTiemChung', data),
  getLichSuTiemChung: (idbn) => https.get(`BenhNhanTheoDoiSauTiem/GetVacXinBenhNhan?idbn=${idbn}`),
  checkCanhBaoHoatChat: (form) => https.put(`CanhBaoHoatChat/CheckCanhBaoHoatChat`, form),
  checkCanhBaoMuiTiem: (idthuoc, idbn) =>
    https.put(`CanhBaoMuiTiem/CheckCanhBaoMuiTiem?idthuoc=${idthuoc}&idbn=${idbn}`),
  checkCanhBaoDoTuoi: (idthuoc, ngay) =>
    https.put(`CanhBaoDoTuoi/CheckCanhBaoDoTuoi?idthuoc=${idthuoc}&ngay=${ngay}`),
  checkCanhBaoKhoangCach: (idthuoc, idbn) =>
    https.put(`CanhBaoKhoangCach/CheckCanhBaoKhoangCach?idthuoc=${idthuoc}&idbn=${idbn}`),
  getListLyDoKhongKeVacXin: () => https.get(`LyDoKoLayThuoc/GetAllLyDo_TiemChung`),
}

import * as typeAction from '../constants/constants'

export const BNChoKhamSan = (idcnPk) => ({
  type: typeAction.GET_BN_CHO_KHAM_BY_PK_SAN,
  idcnPk,
})
export const BNDetailInfoSan = (idbn, idbnttc) => ({
  type: typeAction.GET_INFO_DETAIL_BN_KHAM_SAN,
  idbn,
  idbnttc,
})
export const updateBnDetailSan = (infoBN,formBenhNhan, form, formPhuSan, idcnPk, newArr, isPrint) => ({
  type: typeAction.UPDATE_DETAIL_BN_KHAM_SAN,
  infoBN,
  formBenhNhan,
  form,
  formPhuSan,
  idcnPk,
  newArr,
  isPrint,
})
export const BNDaKhamSanAction = (idnv, idcn, tuNgay, denNgay) => ({
  type: typeAction.GET_BN_DA_KHAM_BY_PK_SAN,
  idnv,
  idcn,
  tuNgay,
  denNgay,
})

export const updateDetailBN = (payload) => ({
  type: 'UPDATE_DETAIL_BN',
  payload,
})

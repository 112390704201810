import React from 'react'
import { Tabs, Badge } from 'antd'
import TiepNhan from './TiepNhan'
import Info from './Info/Info'
import InfoVanBan from './InfoVanBan/InfoVanBan'

function ThongBao(props) {
  const unreadCount = 5 // Số thông báo chưa đọc, bạn có thể thay đổi giá trị này theo dữ liệu thực tế

  return (
    <>
      <div className="w-full h-full p-2 bg-[#E7E7E7]">
        <div className="h-full flex gap-2">
          <div
            className="w-1/3 bg-white rounded-md border h-full"
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <div className="p-2 ">
              <TiepNhan />
            </div>
          </div>
          <div className="w-2/3 h-full gap-2  flex flex-col ">
            <div
              className="h-1/4 bg-white border rounded-md"
              style={{
                boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
              }}
            >
              <div className="p-2">
                <h2 className="font-semibold text-lg text-gray-500">Thông tin</h2>
                <Info />
              </div>
            </div>
            <div
              className="h-3/4 bg-white border rounded-md"
              style={{
                boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
              }}
            >
              <div className="p-2">
                <h2 className="font-semibold text-lg text-gray-500">Thông tin xử lý văn bản</h2>
                <InfoVanBan />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ThongBao

import {
  CloseOutlined,
  GoldOutlined,
  SaveOutlined,
  DeleteOutlined,
  CloudUploadOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { Button } from '@mui/material'
import {
  Input,
  Select,
  Tabs,
  notification,
  Table,
  ConfigProvider,
  InputNumber,
  Checkbox,
  Modal,
  Divider,
} from 'antd'
import { useFormik } from 'formik'
import 'jspdf-autotable'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  branchNhapKhoByCompany,
  fetchInfoThuocVT,
  getBranchNhapKho,
  getHangHoaByMaHang,
  getHangVPPKhoTong,
  getInfoDoitac,
  getlistDoitac,
  searchThuocVT,
} from '../../../../store/actions/NhapKhoAction'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { postPhieuXuatNoiBoAction } from '../../../../store/actions/xuatNoiBoAction'
import * as typeAction from '../../../../store/constants/constants'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { https } from '../../../../services/apiService'
import { chuyenKhoService } from '../../../../services/ChuyenKho/chuyenKhoService'
import { recieveService } from '../../../../services/receive/recieveService'
import { searchBN_Action } from '../../../../store/actions/receiveAction'
import ToastCus from '../../../../utils/Toast'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components'

import 'moment/locale/vi'
moment.locale('vi')
const InputNumVND = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
  }
`
function LapPhieuXuat() {
  const [totalPrice, setTotalPrice] = useState(0)
  // ------dùng để focus vào items mới -------------//
  const [selectedItem, setSelectedItem] = useState(null)
  const id_company = Number(localStorage.getItem('id_company'))
  const id_branch = Number(localStorage.getItem('BRANH_LOGIN'))
  const [branch, setBranch] = useState(id_branch)
  const [listBranch, setListBranch] = useState([])
  const now = useMemo(() => moment(), [])
  const dispatch = useDispatch()
  const [maHang, setMaHang] = useState(null)
  const [selectedFile, setSelectedFile] = useState([])
  const [listKho, setListKho] = useState([])
  const [khoXuat, setKhoXuat] = useState(null) //id kho cn
  const [api, contextHolder] = notification.useNotification()
  const [company, setCompany] = useState(id_company)
  const [isModal, setIsModal] = useState(false)
  // lấy thông tin người dùng >> tạm thời
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listBN } = useSelector((state) => state.receiveReducer)
  const [infoBN, setInfoBN] = useState(null)
  const [isLoadingBN, setIsLoadingBN] = useState(false)

  const [listTonVTHH, setListTonVTHH] = useState([])
  const [listXuatVTHH, setListXuatVTHH] = useState([])
  const [isLoadingTon, setIsLoadingTon] = useState(false)
  const [formPay, setFormPay] = useState({
    tienmat: 0,
    chuyenkhoan: 0,
    pos: 0,
    momo: 0,
    vnpay: 0,
    zalopay: 0,
  })
  const fileInputRef = useRef(null)
  const dropzoneRef = useRef(null)
  const tongTien = listXuatVTHH?.reduce(
    (tong, item) => (tong += (item?.dongia ?? 0) * (item?.soluong ?? 0)),
    0,
  )
  const tongTra = Object.entries(formPay)
    .map(([key, value]) => value)
    .reduce((tong, item) => (tong += item), 0)

  const handleOpenFormPay = () => {
    if (!khoXuat || !listXuatVTHH?.length) {
      ToastCus.fire({
        icon: 'error',
        title: 'Không tìm thấy hàng hóa cần xuất!',
      })
      return
    } else if (listXuatVTHH?.find((item) => item?.soluong <= 0)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại số lượng!',
      })
      return
    }
    setIsModal(true)
    setFormPay({
      tienmat: 0,
      chuyenkhoan: 0,
      pos: 0,
      momo: 0,
      vnpay: 0,
      zalopay: 0,
    })
  }
  const handleOnChangeHinhThucTT = (tenHinhThuc, giaTri) => {
    setFormPay((prev) => {
      let conLai =
        tongTien -
        Object.entries(prev)
          .map(([key, value]) => (key === tenHinhThuc ? 0 : value))
          .reduce((tong, item) => (tong += item), 0)
      return {
        ...prev,
        [tenHinhThuc]: giaTri <= conLai ? giaTri : conLai,
      }
    })
  }
  const deleteFileById = (id) => {
    const updatedData = selectedFile.filter((item) => item.id !== id)
    setSelectedFile(updatedData)
  }
  const handleDivClick = () => {
    fileInputRef.current.click()
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px solid #000'
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
    handleFileChange(e.dataTransfer.files[0])
  }
  const handleFileSelect = (e) => {
    handleFileChange(e.target.files[0])
  }
  const handleFileChange = async (file) => {
    //  let file = event.target.files[0]
    // List of allowed file types
    const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp']
    if (!allowedFileTypes.includes(file.type)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ chấp nhận tệp là hình ảnh!',
      })
      fileInputRef.current.value = '' // clear the input
      return
    }
    // formik.setFieldValue('HoaDonFile', file)
    // console.log(formData);
    fileInputRef.current.value = ''
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      const localUrl = URL.createObjectURL(file)
      setSelectedFile((preState) => [
        ...preState,
        { base64: e.target.result, URL: localUrl, name: file.name, id: uuidv4(), file },
      ])
    }
  }
  // xoá hình ảnh
  const initialValues = useMemo(
    () => ({
      idphuongthuc: 1,
      idxuathoadon: 0,
      DiaChi: '',
      GhiChu: '',
      NguoiXuat: infoUser?.idnv,
      HoaDonFiles: null,
      clickedButton: '',
    }),
    [now, id_company],
  )
  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: initialValues,
    // validationSchema: KhoNhapSchema,
    onSubmit: (value, action) => handleSubmit(value, action),
  })
  const closeImg = () => {
    setSelectedFile([])
    formik.setFieldValue('HoaDonFile', null)
  }
  const selectRef = useRef(null)

  const resetForm = () => {
    setIsModal(false)
    formik.resetForm()
    setInfoBN(null)
    setKhoXuat(null)
    setListXuatVTHH([])
    setSelectedFile([])
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Clear the input
    }
  }
  // xử lí button submit
  const handleSubmit = (values, action) => {
    if (tongTien - tongTra !== 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    if (!infoBN || !infoBN?.idbn) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn bệnh nhân!',
      })
      return
    }
    if (
      listXuatVTHH?.find(
        (item) =>
          !item?.soluong ||
          !item?.tonkho ||
          item?.soluong <= 0 ||
          item?.tonkho <= 0 ||
          item?.soluong > item?.tonkho,
      )
    ) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại số lượng!',
      })
      return
    }
    const formData = new FormData()
    formData.append('TIENMAT', formPay?.tienmat)
    formData.append('CHUYENKHOAN', formPay?.chuyenkhoan)
    formData.append('POS', formPay?.pos)
    formData.append('MOMO', formPay?.momo)
    formData.append('VNPAY', formPay?.vnpay)
    formData.append('ZALOPAY', formPay?.zalopay)
    // formData.append('THETT', infoUser?.idnv)
    formData.append('IDKHOXUAT', khoXuat)
    formData.append('NGUOIXUAT', infoUser?.idnv)
    formData.append('TIENGIAMGIA', 0)
    formData.append('TIENTHANHTOAN', tongTra)
    formData.append('GHICHU', formik.values.GhiChu)
    formData.append('IDXUATHOADON', formik.values.idxuathoadon)
    formData.append('IDPHUONGTHUC', formik.values.idphuongthuc)

    formData.append('DIACHI', formik.values.DiaChi)
    formData.append('IDBN', infoBN?.idbn)

    //formData.append('FILEHOADON', values.GHICHU)

    if (selectedFile?.length !== 0) {
      const dataHoaDonFiles = selectedFile?.map((item) => item.file)
      dataHoaDonFiles?.forEach((file, index) => {
        formData.append('files', file)
      })
      // const formDataObj = Object.fromEntries(formData.entries())
      // console.log(formDataObj)
      // for (let pair of formData.entries()) {
      //   console.log(pair)
      // }
      // console.log(formData)
    }
    setIsModal(false)
    dispatch(postPhieuXuatNoiBoAction(formData, listXuatVTHH, infoBN, resetForm))
    //////////////////////
  }

  const debounceDropDownBN = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        dispatch(searchBN_Action(keyword?.trim(), id_company))
      }
    }, 300),
    [],
  )
  // search lấy thông tin thuốc vật tư
  const debounceDropDown = useCallback(
    _.debounce((keyword, company) => {
      dispatch(searchThuocVT(keyword, company))
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const handleSearch = (keyword) => {
    const value = keyword || ''
    debounceDropDown(keyword, company)
  }
  const onchangeDateHoaDon = useCallback(
    (date, dateString) => {
      const dateHoaDon = dateString ? moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : null
      formik.setFieldValue('NgayHoaDon', dateHoaDon)
    },
    [formik],
  )
  // xử lý thông tin chọn
  const handleChangeSelect = useCallback(
    (name, value) => {
      formik.setFieldValue(name, value)
    },
    [formik],
  )

  // xử lý phong các HÌnh thức và phương thức
  const handleSelect = (name) => (value) => {
    formik.setFieldValue(name, value)
  }

  // xử lí chọn kho chi tiết
  const handleChoose = async (value) => {
    dispatch(fetchInfoThuocVT(value, setSelectedItem))
    // console.log(selectedItem)
    // const validate = await checkStoreThuocVT(value)
    // validate
    //   ? dispatch(fetchInfoThuocVT(value))
    //   : openNotificationWithIcon('error', 'Chọn thuốc vật tư', 'Sản phẩm đã có ')
  }
  const handleChooseMemoized = useCallback(handleChoose, [handleChoose])
  const handleChangeDoiTac = useCallback(
    (name, value) => {
      formik.setFieldValue(name, value)
      dispatch(getInfoDoitac(value))
    },
    [formik],
  )
  // lấy chi nhánh theo công ty
  const handleCompany = useCallback(
    (value) => {
      dispatch(branchNhapKhoByCompany(value))
      setCompany(value)
      formik.setFieldValue('IDCT', value)
      setBranch(null)
      dispatch({
        type: typeAction.DISPATCH_LIST_KHONHAP,
        payload: null,
      })
    },
    [formik],
  )
  // lấy kho nhập
  const hanldeKhoNhapByBranch = (idChiNhanh) => {
    setBranch(idChiNhanh)
    dispatch(getBranchNhapKho(idChiNhanh))
  }

  // // xử lý on change
  const handleDatePickerNgayNhap = useCallback(
    (date, DateString) => {
      // Lấy giờ hiện tại
      const currentTime = moment().format('HH:mm:ss')
      // Ghép ngày đã chọn với giờ hiện tại
      const combinedDateTime = date.format('DD/MM/YYYY') + ' ' + currentTime
      formik.setFieldValue(
        'NgayNhan',
        moment(combinedDateTime, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss'),
      )
    },
    [formik],
  )
  const handleOnChangeSoLuongVTHH = (soluong, ton, key) => {
    if (soluong <= 0 || soluong > ton) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại số lượng!',
      })
      return
    }
    setListXuatVTHH((prev) =>
      prev?.map((item) => (item?.key === key ? { ...item, soluong: soluong } : item)),
    )
  }
  const handleAddVTHH = (value, opt) => {
    if (!branch || !khoXuat) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn kho xuất!',
      })
      return
    }
    if (listXuatVTHH?.find((item) => item?.key === opt?.key)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vật tư hàng hóa này đã được chọn!',
      })
      return
    }
    if (!opt?.infoVTHH?.tonKho || opt?.infoVTHH?.tonKho <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vật tư hàng hóa này không đủ tồn!',
      })
      return
    }
    setListXuatVTHH((prev) => [
      ...prev,
      {
        key: opt?.key,
        idthuoc: opt?.infoVTHH?.idThuoc,
        tenbietduoc: opt?.infoVTHH?.thuocVatTu?.tenBietDuoc,
        tenhoatchat: opt?.infoVTHH?.thuocVatTu?.tenHoatChat,
        solo: opt?.infoVTHH?.soLo,
        handung: opt?.infoVTHH?.hanDung,
        sohoadon: opt?.infoVTHH?.soHoaDon,
        soluong: 1,
        masodangky: opt?.infoVTHH?.thuocVatTu?.maSoDangKy,
        ghichu: null,
        stt: (prev?.length ?? 0) + 1,
        mathuoc: opt?.infoVTHH?.thuocVatTu?.maThuoc,
        dvt: opt?.infoVTHH?.thuocVatTu?.dvt,
        tonkho: opt?.infoVTHH?.tonKho,
        dongia: opt?.infoVTHH?.thuocVatTu?.giaMua,
        dongiamin: opt?.infoVTHH?.thuocVatTu?.giaMua,
      },
    ])
  }
  const getListVTHHbyIdKhoCN = async (idkhocn) => {
    try {
      setIsLoadingTon(true)
      const { data } = await https.get(`TonKho/${idkhocn ?? 0}`)
      setListTonVTHH(data?.result?.filter((item) => item?.tonKho > 0))
      setListXuatVTHH([])
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingTon(false)
    }
  }
  const setPricre = (sum) => {
    setTotalPrice(sum)
  }
  /// reste form
  const onclikReset = () => {
    closeImg()
    dispatch({
      type: typeAction.RESET_INFO_THUOVT,
    })
    formik.handleReset()
  }
  // lưu
  const handleSave = () => {
    formik.setFieldValue('clickedButton', 'save')
    formik.handleSubmit()
  }
  // luuw và in
  const handleSaveAndPrinter = () => {
    formik.setFieldValue('clickedButton', 'save & print')
    formik.handleSubmit()
  }
  // ------------- lấy mã hàng --------//
  const onClickCopyHang = () => {
    // Lấy 3 chữ cái đầu của maHang
    const prefix = maHang.substring(0, 3)
    if (prefix !== 'VPP') {
      dispatch(getHangHoaByMaHang(maHang, setMaHang, setSelectedItem, formik))
      return
    }
    dispatch(getHangVPPKhoTong(maHang, formik.values.IdDoiTac, setMaHang, setSelectedItem, formik))
  }
  // select key
  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.ctrlKey && event.key === 'k') {
  //       event.preventDefault()
  //       if (selectRef.current) {
  //         selectRef.current.focus()
  //       }
  //     } else if (event.key === 'F5') {
  //       event.preventDefault()
  //       onclikReset()
  //     } else if (event.key === 'F9') {
  //       event.preventDefault()
  //       handleSave()
  //     } else if (event.key === 'F4') {
  //       event.preventDefault()
  //       handleSaveAndPrinter()
  //     }
  //   }

  //   window.addEventListener('keydown', handleKeyDown)

  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown)
  //   }
  // }, [])

  //   useEffect(() => {
  //     dispatch(getlistDoitac())
  //     dispatch(branchNhapKhoByCompany(id_company))
  //     dispatch(listAllCompanyAction())
  //   }, [])
  const getListKhoChiNhanh = async (idcn) => {
    try {
      setIsLoadingTon(true)
      const { data } = await chuyenKhoService.getlistKhoNhan(idcn)
      setListKho(data)
      setKhoXuat(data?.length ? data[0].idKhoCN : null)
      getListVTHHbyIdKhoCN(data?.length ? data[0].idKhoCN : null)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingTon(false)
    }
  }

  //---------------- lấy danh thông tin bệnh nhân -----------
  const fetchInfoBN = async (id) => {
    try {
      setIsLoadingBN(true)
      const result = await recieveService.getInfoBN(id)
      setInfoBN(result.data)
      formik.setFieldValue(
        'DiaChi',
        `${result.data?.diaChi ?? ''}${result.data?.tenPhuongXa ? ', ' + result.data?.tenPhuongXa : ''}${result.data?.tenQuanHuyen ? ', ' + result.data?.tenQuanHuyen : ''}${result.data?.tenTinhTP ? ', ' + result.data?.tenTinhTP : ''}`,
      )
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingBN(false)
    }
  }
  useEffect(() => {
    ;(async () => {
      try {
        getListKhoChiNhanh(id_branch)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get(`ChiNhanh/GetAllByIdCTNotVanPhong?idCT=${id_company}`)
        setListBranch(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  return (
    <div>
      <form>
        <div>
          <div className="grid grid-cols-2 gap-2 mt-2 items-center w-full ">
            <div className="grid grid-cols-6 items-center gap-1">
              <label className="text-end font-semibold">
                <span className="text-red-500 text-xs">(*)</span>Tên BN:
              </label>
              {infoBN ? (
                <>
                  <Input
                    className="col-span-2"
                    size="small"
                    readOnly
                    variant="filled"
                    value={infoBN?.tenBenhNhan}
                    suffix={
                      <>
                        <CloseOutlined
                          onClick={() => {
                            setInfoBN(null)
                            formik.setFieldValue('DiaChi', null)
                          }}
                          className=" text-red-500 cursor-pointer text-lg"
                        />
                      </>
                    }
                  />
                  <label className="text-end font-semibold">Mã BN:</label>
                  <Input
                    className="col-span-2"
                    size="small"
                    readOnly
                    variant="filled"
                    value={infoBN?.maBenhNhan}
                  />
                </>
              ) : (
                <>
                  <Select
                    disabled={isLoadingBN}
                    onSearch={debounceDropDownBN}
                    value={isLoadingBN ? 'Đang lấy dữ liệu bệnh nhân...' : null}
                    onChange={fetchInfoBN}
                    filterOption={false}
                    notFoundContent={null}
                    placeholder="Tìm kiếm Tên BN, Mã BN, SĐT, CCCD, Mã TTV..."
                    suffixIcon={<SearchOutlined className=" " />}
                    options={listBN?.map((items) => ({
                      label: items.tenbenhnhan,
                      value: items.idbn,
                      MaBN: items.mabenhnhan,
                      ngaySinh: items.ngaysinh ? moment(items.ngaysinh).format('DD/MM/YYYY') : '',
                      tinh: items.tentinhtp,
                      quan: items.tenquanhuyen,
                      xa: items.tenphuongxa,
                      gioiTinh: items.gioitinh,
                      diaChi: items.diachi,
                    }))}
                    optionRender={(options) => (
                      // <ul className="flex">
                      //   <li className="w-3/4">
                      //     <p className="font-semibold">
                      //       {options?.data?.label} - {options?.data?.MaBN}
                      //       {/* {options?.data?.mathe ? ` - TTV: ${options?.data?.mathe}` : ''} */}
                      //     </p>
                      //     <i className="text-gray-500">
                      //       Địa chỉ: {options?.data?.diaChi}
                      //       {options?.data?.xa && `, ${options?.data?.xa}`}
                      //       {options?.data?.quan && `, ${options?.data?.quan}`}
                      //       {options?.data?.tinh && `, ${options?.data?.tinh}`}
                      //     </i>
                      //   </li>
                      //   <li className="w-1/4 flex flex-col">
                      //     <p>Ngày sinh: {options?.data?.ngaySinh} </p>
                      //     <i className="text-gray-500">Giới tính: {options?.data?.gioiTinh}</i>
                      //   </li>
                      // </ul>
                      <ul>
                        <li>
                          <div className="flex justify-between w-full">
                            <p className="font-semibold">
                              {options.data.label} - {options.data.MaBN}
                            </p>
                            <p>{options.data.ngaySinh}</p>
                          </div>

                          <div className="flex justify-between w-full">
                            <i className="text-gray-500 text-wrap">
                              Địa chỉ: {options.data.diaChi}
                              {options.data.xa && `, ${options.data.xa}`}
                              {options.data.quan && `, ${options.data.quan}`}
                              {options.data.tinh && `, ${options.data.tinh}`}
                            </i>
                            <i className="text-gray-500">{options?.data?.gioiTinh}</i>
                          </div>
                        </li>
                      </ul>
                    )}
                    showSearch
                    allowClear
                    className="w-full col-span-5 text-black"
                    size="small"
                  />
                </>
              )}
            </div>
            {/* <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-6 items-center gap-1">
                <label className="text-end font-semibold">Tên BN:</label>
                <Input
                  className="col-span-5"
                  size="small"
                  value={'Khách vãng lai'}
                  readOnly
                  variant="filled"
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">Mã BN:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  value={'Khách vãng lai'}
                  readOnly
                  variant="filled"
                />
              </div>
            </div> */}
            <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">Người xuất:</label>
                <Input
                  className="col-span-2"
                  readOnly
                  variant="filled"
                  value={infoUser?.tenNV}
                  // name="nhanVienNhan"
                  size="small"
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">Ngày xuất:</label>
                <Input
                  className="col-span-2"
                  readOnly
                  variant="filled"
                  value={moment().format('DD/MM/YYYY HH:mm:ss')}
                  // name="nhanVienNhan"
                  size="small"
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 items-center gap-2 mt-2 w-full ">
            <div className="grid grid-cols-6 items-center gap-1">
              <label className="text-end font-semibold">Địa chỉ:</label>
              <Input
                disabled={isLoadingBN}
                className="col-span-5"
                size="small"
                name="DiaChi"
                value={formik.values.DiaChi}
                onChange={formik.handleChange}
              />
            </div>
            <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">
                  <span className="text-red-500 text-xs">(*)</span>Chi nhánh xuất:
                </label>
                <Select
                  status={!branch && 'error'}
                  tabIndex={1}
                  value={branch}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={listBranch.map((items) => ({
                    label: items.tenChiNhanh,
                    value: items.idChiNhanh,
                  }))}
                  className="col-span-2 w-full"
                  size="small"
                  onChange={(value) => {
                    setBranch(value)
                    getListKhoChiNhanh(value)
                  }}
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">
                  <span className="text-red-500 text-xs">(*)</span>Kho xuất:
                </label>
                <Select
                  status={!khoXuat && 'error'}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => {
                    setKhoXuat(value)
                    getListVTHHbyIdKhoCN(value)
                  }}
                  className="w-full col-span-2"
                  size="small"
                  value={khoXuat}
                  options={listKho?.map(({ idKhoCN, tenKho }) => ({
                    label: tenKho,
                    value: idKhoCN,
                  }))}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 mt-2 items-center w-full ">
            <div className="grid grid-cols-6 items-center gap-1">
              <label className="text-end font-semibold">Ghi chú:</label>
              <Input
                className="col-span-5"
                size="small"
                name="GhiChu"
                value={formik.values.GhiChu}
                onChange={formik.handleChange}
              />
            </div>
            <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">Phương thức:</label>
                <Select
                  className="w-full col-span-2"
                  size="small"
                  options={[
                    {
                      key: 1,
                      value: 1,
                      label: 'Thanh Toán',
                    },
                    {
                      key: 2,
                      value: 2,
                      label: 'Công nợ',
                    },
                  ]}
                  value={formik.values.idphuongthuc}
                  onChange={(value) => formik.setFieldValue('idphuongthuc', value)}
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">Xuất HĐ:</label>
                <Checkbox
                  className="ml-[0.1rem]"
                  checked={formik.values.idxuathoadon}
                  onChange={(e) => formik.setFieldValue('idxuathoadon', e.target.checked ? 1 : 0)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <Tabs
            items={[
              {
                key: 1,
                label: (
                  <div className="flex items-center gap-2">
                    {' '}
                    <GoldOutlined style={{ fontSize: 18 }} /> Hàng hóa
                  </div>
                ),
                children: (
                  <>
                    <div className="flex items-center mb-2 gap-2">
                      <Select
                        disabled={isLoadingTon}
                        className="w-full"
                        placeholder="Nhập tên vật tư hàng hoá..."
                        // ref={selectRef}
                        // // size="small"
                        // showSearch
                        // allowClear
                        // onChange={handleChooseMemoized}
                        // value={null}
                        // defaultActiveFirstOption={false}
                        // prefix={null}
                        // filterOption={false}
                        // onSearch={handleSearch}
                        // notFoundContent={null}
                        showSearch
                        onChange={handleAddVTHH}
                        filterOption={(value, option) => {
                          const tenBietDuoc = option?.infoVTHH?.thuocVatTu?.tenBietDuoc ?? ''
                          const maThuoc = option?.infoVTHH?.thuocVatTu?.maThuoc ?? ''
                          const searchText = value?.toLowerCase()
                          return (
                            tenBietDuoc?.toLowerCase()?.includes(searchText) ||
                            maThuoc?.toLowerCase()?.includes(searchText)
                          )
                        }}
                        dropdownRender={(menu) => (
                          <div>
                            <div
                              className="flex bg-gray-100"
                              style={{ padding: '8px', fontWeight: 'bold' }}
                            >
                              <span className="w-[5%] text-center ">Mã hàng</span>
                              <span className="w-[60%] text-center">Tên thuốc</span>
                              <span className="w-[10%] text-center">Số lô</span>
                              <span className="w-[5%] text-center">Hạn dùng</span>
                              <span className="w-[10%] text-center">Số hoá đơn</span>
                              <span className="w-[5%] text-center">Đơn vị </span>
                              <span className="w-[5%] text-center">Tồn</span>
                            </div>
                            <div style={{ marginBottom: '8px' }}>{menu}</div>
                          </div>
                        )}
                        optionLabelProp="label"
                        value={null}
                        options={
                          !branch || !khoXuat
                            ? []
                            : listTonVTHH?.map((d, index) => ({
                                infoVTHH: d,
                                key: `${d?.idThuoc}${d?.soLo}${d?.hanDung}${d?.soHoaDon}`,
                                value: `${d?.idThuoc}${d?.soLo}${d?.hanDung}${d?.soHoaDon}`,
                                label: (
                                  <ul className="flex no-underline">
                                    <li className="flex w-[5%] border-r-2">
                                      <p className="w-full truncate text-center">
                                        {d?.thuocVatTu?.maThuoc}
                                      </p>
                                    </li>
                                    <li className="flex w-[60%] border-r-2">
                                      <p className="px-2 w-full truncate">
                                        {d?.thuocVatTu?.tenBietDuoc}
                                      </p>
                                    </li>
                                    <li className="flex w-[10%] border-r-2 pl-2">
                                      <p className="w-full truncate text-center">{d?.soLo}</p>
                                    </li>
                                    <li className="flex w-[5%] border-r-2 items-end justify-center">
                                      <p className="w-full truncate text-center">
                                        {d?.hanDung && moment(d?.hanDung).format('DD/MM/YYYY')}
                                      </p>
                                    </li>
                                    <li className="flex w-[10%] border-r-2 pl-2">
                                      <p className="w-full truncate text-center">{d?.soHoaDon}</p>
                                    </li>
                                    <li className="flex w-[5%] border-r-2 pl-2 items-end justify-center">
                                      <p className="w-full truncate text-center">
                                        {d?.thuocVatTu?.dvt}
                                      </p>
                                    </li>
                                    {/* <li className="flex w-[10%] border-r-2 pl-2">
                                              <p>{d?.maSoDangKy}</p>
                                            </li> */}
                                    <li className="flex w-[5%] items-end justify-center">
                                      <p className="w-full truncate text-center">{d?.tonKho}</p>
                                    </li>
                                  </ul>
                                ),
                              }))
                        }
                      />
                    </div>
                    <ConfigProvider
                      theme={{
                        token: {
                          padding: 5,
                        },
                        components: {
                          Table: {
                            rowHoverBg: '#ecf0f1',
                            headerBg: '#e6e6e6',
                            footerBg: '#e6e6e6',
                            borderColor: '#BABABA',
                          },
                        },
                      }}
                    >
                      <Table
                        //   scroll={{ x: 1000 }}
                        //   pagination={{
                        //     pageSize: 20,
                        //     showSizeChanger: false,
                        //   }}
                        scroll={{ y: 540 }}
                        pagination={false}
                        loading={isLoadingTon}
                        dataSource={listXuatVTHH}
                        bordered
                        columns={[
                          {
                            title: 'STT',
                            dataIndex: 'stt',
                            key: 'stt',
                            width: 45,
                            align: 'center',
                          },
                          {
                            title: 'Mã hàng',
                            dataIndex: 'mathuoc',
                            key: 'mathuoc',
                            width: 120,
                          },
                          {
                            title: 'Tên hàng',
                            dataIndex: 'tenbietduoc',
                            key: 'tenbietduoc',
                          },
                          {
                            title: 'Số lô',
                            dataIndex: 'solo',
                            key: 'solo',
                            width: 120,
                          },
                          {
                            title: 'Hạn Dùng',
                            dataIndex: 'handung',
                            key: 'handung',
                            align: 'center',
                            width: 120,
                            render: (text, record, index) =>
                              text ? moment(text).format('DD/MM/YYYY') : '-',
                          },
                          {
                            title: 'Số hóa đơn',
                            dataIndex: 'sohoadon',
                            key: 'sohoadon',
                            width: 120,
                          },
                          {
                            title: 'ĐVT',
                            dataIndex: 'dvt',
                            key: 'dvt',
                            width: 60,
                            align: 'center',
                          },
                          {
                            title: 'Đơn giá',
                            dataIndex: 'dongia',
                            key: 'dongia',
                            width: 120,
                            align: 'center',
                            render: (text, record, index) => (
                              <InputNumVND
                                className="w-full pr-5"
                                size="small"
                                value={text}
                                onChange={(value) =>
                                  setListXuatVTHH((prev) =>
                                    prev?.map((item) =>
                                      item?.key === record?.key
                                        ? {
                                            ...item,
                                            dongia: value,
                                          }
                                        : item,
                                    ),
                                  )
                                }
                                step={100}
                                formatter={(value) => {
                                  // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                                  return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }}
                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                min={record?.dongiamin}
                                max={999999999}
                              />
                            ),
                          },
                          {
                            title: 'Số lượng',
                            dataIndex: 'soluong',
                            key: 'soluong',
                            width: 75,
                            align: 'center',
                            render: (text, record, index) => (
                              <InputNumber
                                // status={!text && 'error'}
                                className="w-full"
                                size="small"
                                value={text}
                                min={1}
                                step={1}
                                max={record?.tonkho}
                                status={
                                  !text ||
                                  !record?.tonkho ||
                                  text <= 0 ||
                                  record?.tonkho <= 0 ||
                                  text > record?.tonkho
                                    ? 'error'
                                    : ''
                                }
                                onChange={(value) =>
                                  handleOnChangeSoLuongVTHH(value, record?.tonkho, record?.key)
                                }
                              />
                            ),
                          },
                          {
                            title: 'Tồn',
                            dataIndex: 'tonkho',
                            key: 'tonkho',
                            width: 75,
                            align: 'center',
                          },
                          {
                            title: 'Thành tiền',
                            dataIndex: 'thanhtien',
                            key: 'thanhtien',
                            width: 100,
                            align: 'right',
                            render: (text, record, index) =>
                              formattedNumber((record?.dongia ?? 0) * (record?.soluong ?? 0)),
                          },
                          {
                            title: 'Ghi chú',
                            dataIndex: 'ghichu',
                            key: 'ghichu',
                            width: 200,
                            render: (text, record, index) => (
                              <Input
                                className="w-full"
                                size="small"
                                value={text}
                                onChange={(e) => {
                                  setListXuatVTHH((prev) =>
                                    prev?.map((item) =>
                                      item?.key === record?.key
                                        ? { ...item, ghichu: e.target.value }
                                        : item,
                                    ),
                                  )
                                }}
                              />
                            ),
                          },
                          {
                            title: '',
                            dataIndex: 'action',
                            key: 'action',
                            width: 40,
                            align: 'center',
                            render: (text, record, index) => (
                              <CloseOutlined
                                className="text-lg text-red-500 cursor-pointer flex justify-center items-center"
                                onClick={() =>
                                  setListXuatVTHH((prev) =>
                                    prev?.filter((item) => item?.key !== record?.key),
                                  )
                                }
                              />
                            ),
                          },
                        ]}
                      />
                    </ConfigProvider>
                  </>
                ),
              },
              {
                key: 2,
                label: (
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
                      />
                    </svg>
                    <div className="flex gap-1 items-start">
                      Đính kèm
                      {selectedFile?.length > 0 && (
                        <span className="font-semibold text-white bg-red-500 text-[10px] pl-[0.35rem] pr-[0.35rem] border border-red-500 rounded-full">
                          {selectedFile?.length}
                        </span>
                      )}
                    </div>
                  </div>
                ),

                children: (
                  // <Attach
                  //   setSelectedFile={setSelectedFile}
                  //   selectedFile={selectedFile}
                  //   formik={formik}
                  // />
                  <>
                    <ul className="flex flex-wrap gap-2 mb-2">
                      {selectedFile?.map((items, index) => (
                        <li
                          key={`upHinh${index}`}
                          className="w-[10rem] h-[10rem] overflow-hidden border rounded-md relative"
                        >
                          <DeleteOutlined
                            onClick={() => deleteFileById(items.id)}
                            className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                            style={{ fontSize: 18 }}
                          />
                          <a href={items?.URL} target="_blank">
                            <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                              <div className="relative "></div>
                              <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                                {items.name}
                              </p>
                            </div>
                            <img src={items.base64} className="w-full" alt={items.name} />
                          </a>
                        </li>
                      ))}
                    </ul>
                    <div
                      style={{
                        border: '2px dashed #cccccc',
                        borderRadius: '4px',
                        padding: '30px',
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                      className="border"
                      ref={dropzoneRef}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      onClick={handleDivClick}
                    >
                      <CloudUploadOutlined style={{ fontSize: 36 }} className="text-gray-500" />
                      <label style={{ cursor: 'pointer' }}>
                        <p className="font-semibold text-lg text-gray-500">
                          Thả tập tin vào đây hoặc bấm vào để tải lên.
                        </p>
                      </label>
                      <input
                        ref={fileInputRef}
                        id="fileInput"
                        type="file"
                        onChange={handleFileSelect}
                      />
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>
        <div className="mt-5 flex gap-5 justify-end">
          {/* <Button onClick={onclikReset} variant="outlined" color="info" size="small">
                          Làm mới ( F5 )
                        </Button> */}
          <div className="font-semibold text-lg w-[15rem] flex justify-between items-center mr-5">
            <div>Tổng tiền:</div>
            <div className="text-sky-600">
              {formattedNumber(tongTien ?? 0)}
              &nbsp;VNĐ
            </div>
          </div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="button"
            onClick={handleOpenFormPay}
            // startIcon={<SaveOutlined />}
          >
            Thanh toán
          </Button>
          {/* <Button
                          variant="contained"
                          color="success"
                          size="small"
                          type="button"
                          onClick={handleSaveAndPrinter}
                        >
                          Lưu & in ( F4 )
                        </Button> */}
        </div>
      </form>
      <Modal
        title={<p className="text-center">Hình thức thanh toán</p>}
        open={isModal}
        onOk={formik.handleSubmit}
        onCancel={() => setIsModal(false)}
        width={500}
        okText={'Thanh toán'}
        cancelText="Huỷ bỏ"
      >
        <div className="grid grid-flow-row gap-3 mb-5">
          <div className="grid grid-cols-2 items-center gap-5">
            <div>
              <label className="font-semibold">Tiền mặt</label>
              <InputNumber
                className="w-full"
                value={formPay?.tienmat}
                onChange={(value) => {
                  handleOnChangeHinhThucTT('tienmat', value)
                }}
                onKeyUp={(e) => {
                  if (e.key === 'p' || e.key === 'P') {
                    handleOnChangeHinhThucTT('tienmat', tongTien)
                  }
                  return (e.target.value = `${e.target.value}`
                    ?.replace(/[^\d,]/g, '')
                    ?.replace(/,+/g, ',')
                    ?.replace(/,$/, ''))
                }}
                step={100}
                formatter={(value) => {
                  // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                  return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
            <div>
              <label className="font-semibold">Chuyển khoản</label>
              <InputNumber
                className="w-full"
                value={formPay?.chuyenkhoan}
                onChange={(value) => handleOnChangeHinhThucTT('chuyenkhoan', value)}
                onKeyUp={(e) => {
                  if (e.key === 'p' || e.key === 'P') {
                    handleOnChangeHinhThucTT('chuyenkhoan', tongTien)
                  }
                  return (e.target.value = `${e.target.value}`
                    ?.replace(/[^\d,]/g, '')
                    ?.replace(/,+/g, ',')
                    ?.replace(/,$/, ''))
                }}
                step={100}
                formatter={(value) => {
                  // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                  return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 items-center gap-5">
            <div>
              <label className="font-semibold">Máy POS</label>
              <InputNumber
                className="w-full"
                value={formPay?.pos}
                onChange={(value) => handleOnChangeHinhThucTT('pos', value)}
                onKeyUp={(e) => {
                  if (e.key === 'p' || e.key === 'P') {
                    handleOnChangeHinhThucTT('pos', tongTien)
                  }
                  return (e.target.value = `${e.target.value}`
                    ?.replace(/[^\d,]/g, '')
                    ?.replace(/,+/g, ',')
                    ?.replace(/,$/, ''))
                }}
                step={100}
                formatter={(value) => {
                  // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                  return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
            <div>
              <label className="font-semibold">Momo</label>
              <InputNumber
                className="w-full"
                value={formPay?.momo}
                onChange={(value) => handleOnChangeHinhThucTT('momo', value)}
                onKeyUp={(e) => {
                  if (e.key === 'p' || e.key === 'P') {
                    handleOnChangeHinhThucTT('momo', tongTien)
                  }
                  return (e.target.value = `${e.target.value}`
                    ?.replace(/[^\d,]/g, '')
                    ?.replace(/,+/g, ',')
                    ?.replace(/,$/, ''))
                }}
                step={100}
                formatter={(value) => {
                  // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                  return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 items-center gap-5">
            <div>
              <label className="font-semibold">VNPay</label>
              <InputNumber
                className="w-full"
                value={formPay?.vnpay}
                onChange={(value) => handleOnChangeHinhThucTT('vnpay', value)}
                onKeyUp={(e) => {
                  if (e.key === 'p' || e.key === 'P') {
                    handleOnChangeHinhThucTT('vnpay', tongTien)
                  }
                  return (e.target.value = `${e.target.value}`
                    ?.replace(/[^\d,]/g, '')
                    ?.replace(/,+/g, ',')
                    ?.replace(/,$/, ''))
                }}
                step={100}
                formatter={(value) => {
                  // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                  return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
            <div>
              <label className="font-semibold">ZaloPay</label>
              <InputNumber
                className="w-full"
                value={formPay?.zalopay}
                onChange={(value) => handleOnChangeHinhThucTT('zalopay', value)}
                onKeyUp={(e) => {
                  if (e.key === 'p' || e.key === 'P') {
                    handleOnChangeHinhThucTT('zalopay', tongTien)
                  }
                  return (e.target.value = `${e.target.value}`
                    ?.replace(/[^\d,]/g, '')
                    ?.replace(/,+/g, ',')
                    ?.replace(/,$/, ''))
                }}
                step={100}
                formatter={(value) => {
                  // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                  return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
          </div>
          <div>
            <p>
              <i>
                * Gợi ý: chọn ô thanh toán và nhấn phím <span className="font-semibold">P</span> để
                tính tổng tiền
              </i>
            </p>
          </div>
          <div>
            <Divider dashed style={{ margin: 10 }} />
            <div className="grid grid-flow-row font-semibold text-lg">
              <div className="grid grid-cols-2">
                <div>Tổng tiền</div>
                <div className="text-end">
                  {formattedNumber(tongTien ?? 0)}
                  &nbsp;VNĐ
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div>Tổng trả</div>
                <div className="text-end text-sky-600">
                  {formattedNumber(tongTra ?? 0)}
                  &nbsp;VNĐ
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default LapPhieuXuat

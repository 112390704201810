import React from 'react'
import { Input } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'
import 'moment/locale/vi'
import { calculateAge } from '../../../../../utils/calculateAge'
moment.locale('vi')
const InfoBN = ({ formBenhNhan }) => {
  const { infoBN, DetailBN, TTV, DetailToaThuocBN } = useSelector(
    (state) => state.childrenClinicReducer,
  )
  const checkUpdate =
    DetailBN && DetailBN?.trangthaikhambenh !== 1 && DetailToaThuocBN?.DANHANTOA !== 0
  return (
    <form className="text-start p-2">
      <div className="flex flex-col gap-2">
        <div className="flex gap-3 text-end">
          <div className="flex w-1/3 gap-1 items-center">
            <label className="w-24">Họ và tên:</label>
            <Input style={{ color: 'black' }} disabled value={infoBN?.tenBenhNhan} size="small" />
          </div>
          <div className="flex w-1/3 gap-2 items-center">
            <div className="w-1/2 flex gap-1">
              <label className="w-28">Mã BN:</label>
              <Input style={{ color: 'black' }} disabled value={infoBN?.maBenhNhan} size="small" />
            </div>
            <div className="w-1/2 flex gap-1">
              <label className="w-28">Ngày sinh:</label>
              <Input
                style={{ color: 'black' }}
                disabled
                value={infoBN?.ngaySinh && moment(infoBN?.ngaySinh).format('DD/MM/YYYY')}
                size="small"
              />
            </div>
          </div>
          <div className="flex w-1/3 gap-2 items-center">
            <div className="flex w-1/2 gap-1 ">
              <label className="text-end w-16">Tuổi:</label>
              <Input
                disabled
                style={{ color: 'blue', fontWeight: 600 }}
                value={calculateAge(infoBN?.ngaySinh)}
                size="small"
              />
            </div>

            <div className="w-1/2 flex gap-1">
              <label className="w-28">Giới tính</label>
              <Input style={{ color: 'black' }} disabled value={infoBN?.gioiTinh} size="small" />
            </div>
          </div>
        </div>
        <div className="flex gap-3 text-end">
          <div className="flex w-1/3 gap-1 items-center">
            <label className="w-24">Người nhà:</label>
            <Input style={{ color: 'black' }} disabled value={DetailBN?.tennguoinha} size="small" />
          </div>
          <div className="flex w-1/3 gap-2 items-center">
            <div className="w-1/2 flex gap-1">
              <label className="w-28">Vai vế:</label>
              <Input style={{ color: 'black' }} disabled value={DetailBN?.nguoinha} size="small" />
            </div>
            <div className="w-1/2 flex gap-1">
              <label className="w-28">Mã TCQG</label>
              <Input style={{ color: 'black' }} disabled value={infoBN?.maTCQG} size="small" />
            </div>
          </div>
          <div className="flex w-1/3 gap-2 items-center">
            <div className="w-1/2 flex gap-1">
              <label className="w-28">Quốc tịch:</label>
              <Input style={{ color: 'black' }} disabled value={infoBN?.tenQuocTich} size="small" />
            </div>
            <div className="w-1/2 flex gap-1">
              <label className="w-28">Dân tộc:</label>
              <Input style={{ color: 'black' }} disabled value={infoBN?.tenDanToc} size="small" />
            </div>
          </div>
        </div>
        <div className="flex gap-3">
          <div className="w-1/3 flex gap-1">
            <label className="w-24 text-end">Số TTV:</label>
            <Input disabled style={{ color: 'black' }} value={TTV?.mathe} size="small" />
          </div>
          <div className="flex w-1/3  gap-1">
            <label className="w-[20%] text-end">Loại thẻ:</label>
            <Input disabled style={{ color: 'black' }} value={TTV?.tenthe} size="small" />
          </div>
          <div className="w-1/3 flex gap-1">
            <div className="flex w-1/2 gap-1">
              <label className="text-end w-28">Từ ngày:</label>
              <Input
                disabled
                style={{ color: 'black' }}
                value={TTV?.tungay && moment(TTV?.tungay).format('DD/MM/YYYY')}
                size="small"
              />
            </div>
            <div className="flex w-1/2 gap-1 ">
              <label className="text-end w-28">Đến ngày:</label>
              <Input
                disabled
                style={{ color: 'black' }}
                value={TTV?.denngay && moment(TTV?.denngay).format('DD/MM/YYYY')}
                size="small"
              />
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <div className="w-2/3 flex gap-1">
            <label className="w-[9.7%] text-end">Địa chỉ:</label>
            <Input disabled style={{ color: 'black' }} value={infoBN?.diaChi} size="small" />
          </div>
          <div className="w-1/3 flex gap-1">
            <div className="w-1/2 flex gap-1">
              <label className="w-32 text-end">Đối tượng:</label>
              <Input style={{ color: 'black' }} disabled value={infoBN?.tenDoiTuong} size="small" />
            </div>
            <div className="flex w-1/2 gap-1 ">
              <label className="text-end w-32">Ngày khám:</label>
              <Input
                disabled
                style={{ color: 'black' }}
                value={DetailBN?.ngaykham && moment(DetailBN?.ngaykham).format('DD/MM/YYYY HH:mm')}
                size="small"
              />
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <div className="w-1/2 flex gap-1">
            <label className="w-24 text-end">Dị ứng:</label>
            <Input
              onChange={formBenhNhan?.handleChange}
              name="diUng"
              disabled={checkUpdate}
              style={{ color: 'black' }}
              value={formBenhNhan?.values?.diUng}
              size="small"
            />
          </div>
          <div className="w-1/2 flex gap-1">
            <label className="w-20 text-end">Tiền sử:</label>
            <Input
              onChange={formBenhNhan?.handleChange}
              name="tienSuBanThan"
              disabled={checkUpdate}
              style={{ color: 'black' }}
              value={formBenhNhan?.values?.tienSuBanThan}
              size="small"
            />
          </div>
        </div>
      </div>
    </form>
  )
}

export default InfoBN

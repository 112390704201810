import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Button,
  Checkbox,
  ConfigProvider,
  DatePicker,
  Divider,
  Input,
  Select,
  Spin,
  Table,
  Tabs,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import {
  FilterOutlined,
  PrinterOutlined,
  SaveOutlined,
  SearchOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons'
import dayjs from 'dayjs'
import { childrenClinicService } from '../../../../services/childrenClinic/childrenClinicService'
import { render } from 'bwip-js'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { useFormik } from 'formik'
import { debounce } from 'lodash'
import ToastCus from '../../../../utils/Toast'
import { benhAnNgoaiTru } from '../../../../utils/report/benhAnNgoaiTru'
import InfoBenhNhanSan from './InfoBenhNhanSan'
import { maternityClinicService } from '../../../../services/maternityClinic/maternityClinicService'
import { SuggestDoctor } from '../../../../store/actions/childrenClinicAction'
import { generateTT32 } from '../../../../utils/report/TT32'
import { benhAnMat } from '../../../../utils/report/benhAnMat'
import ImageTiemChung from './ImageTiemChung'
import { generatePhieuSanLon } from '../../../../utils/report/san/sanLon'
import { generatePhieuSanNho } from '../../../../utils/report/san/sanNho'
import { generatePhieuPhuKhoa } from '../../../../utils/report/san/phuKhoa'
import { generateNhuHoa } from '../../../../utils/report/san/nhuHoa'
import 'moment/locale/vi'
import FormBenhNhanTTC from './FormBenhNhanTTC/FormBenhNhanTTC'
import { getInfoBN_TT_Action } from '../../../../store/actions/traCuuAction'
moment.locale('vi')

const formatNameCompare = (string) => {
  const dotIndex = string.indexOf('.')
  if (dotIndex !== -1) {
    return string.slice(string.indexOf('.') + 1).trim()
  }

  return string.trim()
}

const columnsDV = [
  {
    title: 'Mã chỉ định',
    dataIndex: 'maChiDinh',
    key: 'maChiDinh',
    align: 'center',
    className: 'text-sm',
    width: 150,
  },

  {
    title: 'Tên chỉ định',
    dataIndex: 'tenChiDinh',
    key: 'tenChiDinh',
    align: 'left',
    className: 'text-sm',
  },
  {
    title: 'ĐVT',
    dataIndex: 'dvt',
    key: 'dvt',
    align: 'center',
    className: 'text-sm',
    width: 100,
  },
  {
    title: 'Đơn giá',
    dataIndex: 'dongia',
    key: 'dongia',
    align: 'right',
    className: 'text-sm',
    width: 150,
    render: (text) => formatNumberVND(text),
  },
  {
    title: 'Tiền giảm giá',
    dataIndex: 'tiengiamgia',
    key: 'tiengiamgia',
    align: 'right',
    className: 'text-sm',
    width: 150,
    render: (text) => formatNumberVND(text),
  },
  {
    title: 'Tiền thanh toán',
    dataIndex: 'tienthanhtoan',
    key: 'tienthanhtoan',
    align: 'right',
    className: 'text-sm',
    width: 150,
    render: (text) => formatNumberVND(text),
  },
]

const columnsThuoc = [
  {
    title: 'Mã thuốc',
    dataIndex: 'mathuoc',
    align: 'left',
    key: 'mathuoc',
    align: 'center',
    className: 'text-sm',
    width: 150,
  },
  {
    title: 'Tên thuốc',
    dataIndex: 'tenbietduoc',
    align: 'left',
    key: 'tenbietduoc',
    align: 'left',
    className: 'text-sm',
  },
  {
    title: 'ĐVT',
    dataIndex: 'dvt',
    key: 'dvt',
    align: 'center',
    className: 'text-sm',
    width: 100,
  },
  {
    title: 'Liều dùng',
    dataIndex: 'lieudung',
    key: 'lieudung',
    align: 'center',
    className: 'text-sm',
    width: 100,
  },
  {
    title: 'Số lần/ngày',
    dataIndex: 'solanngay',
    key: 'solanngay',
    align: 'center',
    className: 'text-sm',
    width: 100,
  },
  {
    title: 'SL kê',
    dataIndex: 'soluongke',
    key: 'soluongke',
    align: 'center',
    className: 'text-sm',
    width: 100,
  },
  {
    title: 'SL bán',
    dataIndex: 'soluongban',
    key: 'soluongban',
    align: 'center',
    className: 'text-sm',
    width: 100,
  },
  {
    title: 'Đơn giá',
    dataIndex: 'dongia',
    key: 'dongia',
    align: 'right',
    className: 'text-sm',
    render: (text) => formatNumberVND(text),
    width: 120,
  },
  {
    title: 'Thành tiền',
    dataIndex: 'thanhtien',
    key: 'thanhtien',
    align: 'right',
    className: 'text-sm',
    render: (text) => formatNumberVND(text),
    width: 120,
  },
]

const listItemClasses =
  'flex items-center border py-2 rounded-md hover:bg-sky-100 hover:cursor-pointer hover:-translate-y-1 duration-200'
const activeListItemClasses =
  'flex items-center border py-2 rounded-md bg-blue-200 cursor-pointer -translate-y-1 duration-200'

function HistoryKhamBenh({ open, benhNhanThanhToans }) {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoBN } = useSelector((state) => state.traCuuReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [listLichSu, setListLichSu] = useState([])
  const [listLichSuDefault, setListLichSuDefault] = useState([])
  const [toaThuoc, setToaThuoc] = useState([])
  const [listDV, setListDV] = useState([])
  const [activeItemId, setActiveItemId] = useState(null) // State to manage active item
  const [loading, setLoading] = useState(false)
  const [time, setTime] = useState(null)
  const [listLyDoKhongKeToa, setListLyDoKhongKeToa] = useState([])
  const [ChanDoan, setChanDoan] = useState([])
  const [infoBNTTC, setInfoBNTTC] = useState(null)
  const [infoBNSan, setInfoBNSan] = useState(null)
  const [listLoaiKham, setListLoaiKham] = useState([])
  const [listImage, setListImage] = useState([])
  const [bmi, setBmi] = useState(0)
  const [nguoinha, setNguoiNha] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('NguoiNhaBenhNhan/GetAllNguoiNha')
        setNguoiNha(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    dispatch(SuggestDoctor())
  }, [])

  const fetchListLoaiKham = async () => {
    try {
      const result = await maternityClinicService.getListLoaiKham()
      setListLoaiKham(result.data)
    } catch (error) {
      console.log(error)
    }
    // console.log(result.data)
  }

  const fechChanDoan = async (keyword) => {
    try {
      const result = await childrenClinicService.getChanDoan(keyword)
      setChanDoan(result?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchMissingOption = async (key) => {
    const currentValue = formik.values[key]
    if (currentValue && !ChanDoan.some((item) => item.idicD10 === currentValue)) {
      try {
        const response = await https.get(`ChanDoan/GetICD10ByID?idICD=${currentValue}`)
        const newOption = response.data
        setChanDoan((prevOptions) => [...prevOptions, newOption])
      } catch (error) {
        console.error('Error fetching data for missing option:', error)
      }
    }
  }

  useEffect(() => {
    if (!infoBN) return
    ;(async () => {
      try {
        const { data } = await https.get(
          `BenhNhanThongTinChung/GetLichSuKhamBenh?idBN=${infoBN.idbn}&idNV=${infoUser.idnv}`,
        )
        //filter k lay ban le
        const dataFilter = data.filter((item) => item.ngaykham !== null)
        setListLichSu(dataFilter)
        setListLichSuDefault(dataFilter)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [infoBN])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nguoiSua: infoUser?.tenNV,
      idbnttc: infoBNTTC?.idbnttc,
      ngaytaikham: infoBNTTC?.ngaytaikham,
      bskham: infoBNTTC?.bskham,
      lydokham: infoBNTTC?.lydokham,
      benhsu: infoBNTTC?.benhsu,
      trieuchung: infoBNTTC?.trieuchung,
      chandoan: infoBNTTC?.chandoan,
      idchandoan: infoBNTTC?.idchandoan,
      loidan: infoBNTTC?.loidan,
      tienSuBanThan: infoBNTTC?.tiensubanthan,
      diung: infoBNTTC?.diung,
      quatrinhbenhly: infoBNTTC?.quatrinhbenhly,
      tieusugiadinh: infoBNTTC?.tieusugiadinh,
      toanthan: infoBNTTC?.toanthan,
      cacbophan: infoBNTTC?.cacbophan,
      chandoanbandau: infoBNTTC?.chandoanbandau,
      idicD10BANDAU: infoBNTTC?.idicD10BANDAU,
      daxuly: infoBNTTC?.daxuly,
      chandoanravien: infoBNTTC?.chandoanravien,
      idicD10RAVIEN: infoBNTTC?.idicD10RAVIEN,
      dieutringoaitru: infoBNTTC?.dieutringoaitru,
      dienbienlamsang: infoBNTTC?.dienbienlamsang,
      benhkemtheo: infoBNTTC?.benhkemtheo,
      ppdieutri: infoBNTTC?.ppdieutri,
      ttlucravien: infoBNTTC?.ttlucravien,
      huongxuly: infoBNTTC?.huongxuly,
      lydokolaythuoc: infoBNTTC?.lydokolaythuoc,
      tinhtrangravien: infoBNTTC?.tinhtrangravien,
      benhlykhac: infoBNTTC?.benhlykhac,
      mat: infoBNTTC?.mat,
      ranghammat: infoBNTTC?.ranghammat,
      taimuihong: infoBNTTC?.taimuihong,
      coxuongkhop: infoBNTTC?.coxuongkhop,
      thankinh: infoBNTTC?.thankinh,
      thantietnieu: infoBNTTC?.thantietnieu,
      tieuhoa: infoBNTTC?.tieuhoa,
      hohap: infoBNTTC?.hohap,
      tuanhoan: infoBNTTC?.tuanhoan,
      bienchung: infoBNTTC?.bienchung,
      kqcls: infoBNTTC?.kqcls,
      idicD10BENHKEMTHEO: infoBNTTC?.idicD10BENHKEMTHEO,
      idicD10BIENCHUNG: infoBNTTC?.idicD10BIENCHUNG,
      //infor benh nhan san
      para: infoBNTTC?.para,
      chukikinh: infoBNTTC?.chukikinh,
      nguathai: infoBNTTC?.nguathai,
      kinhchot: infoBNTTC?.kinhchot,
      kinhapchot: infoBNTTC?.kinhapchot,
      dusanhkc: infoBNTTC?.dusanhkc,
      dusanh: infoBNTTC?.dusanh,
      ngaysanh: infoBNTTC?.ngaysanh,
      tuoithai: infoBNTTC?.tuoithai,
      tuanthai: infoBNTTC?.tuanthai,
      ngaythai: infoBNTTC?.ngaythai,
      tongtrang: infoBNTTC?.tongtrang ?? 'Trung bình',
      timphoi: infoBNTTC?.timphoi ?? 'Tim đều, phổi không ran',
      daniem: infoBNTTC?.daniem ?? 'Hồng',
      bung: infoBNTTC?.bung ?? 'Mềm',
      phu: infoBNTTC?.phu ?? 'Không',
      tennguoinha: infoBNTTC?.tennguoinha,
      dienthoainguoinha: infoBNTTC?.dienthoainguoinha,
      nguoinha: infoBNTTC?.nguoinha,
      // thông tin sinh trắc
      chieucao: infoBNTTC?.chieucao,
      cannang: infoBNTTC?.cannang,
      mach: infoBNTTC?.mach,
      huyetap: infoBNTTC?.huyetap,
      nhietdo: infoBNTTC?.nhietdo,
      nhiptho: infoBNTTC?.nhiptho,
      spo2: infoBNTTC?.spO2,
      ghichu: infoBNTTC?.ghichu,
    },
    onSubmit: (values) => handleSubmit(values),
    //disable for performance
    validateOnChange: false,
    validateOnBlur: false,
  })

  useEffect(() => {
    const idsToCheck = [
      'idchandoan',
      'idicD10BANDAU',
      'idicD10BENHKEMTHEO',
      'idicD10BIENCHUNG',
      'idicD10RAVIEN',
    ]

    idsToCheck.forEach((id) => {
      if (formik.values[id]) {
        fetchMissingOption(id)
      }
    })
  }, [
    formik.values.idchandoan,
    formik.values.idicD10BANDAU,
    formik.values.idicD10BENHKEMTHEO,
    formik.values.idicD10BIENCHUNG,
    formik.values.idicD10RAVIEN,
  ])

  useEffect(() => {
    if (formik.values.chieucao && formik.values.cannang) {
      const bmi = (formik.values.cannang / Math.pow(formik.values.chieucao / 100, 2)).toFixed(2)
      setBmi(bmi)
    }
  }, [formik.values.chieucao, formik.values.cannang])

  const formikTTBN = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbn: infoBN?.idbn,
      diUng: infoBN?.benhNhan?.diUng,
      tienSuBanThan: infoBN?.benhNhan?.tienSuBanThan,
      nguoiSua: infoUser?.idnv,
    },
    //disable for performance
    validateOnChange: false,
    validateOnBlur: false,
  })

  const formikPhuSan = useFormik({
    enableReinitialize: true,
    initialValues: {
      nguoiSua: infoUser?.tenNV,
      idbnsan: infoBNSan?.idbnsan,
      idbnttc: infoBNSan?.idbnttc,
      congo: infoBNSan?.congo,
      khamtrong: infoBNSan?.khamtrong,
      amho: infoBNSan?.amho,
      amdao: infoBNSan?.amdao,
      ctc: infoBNSan?.ctc,
      tucung: infoBNSan?.tucung,
      bctc: infoBNSan?.bctc,
      timthai: infoBNSan?.timthai,
      ngoithai: infoBNSan?.ngoithai,
      oi: infoBNSan?.oi,
      kchau: infoBNSan?.kchau,
      phanphut: infoBNSan?.phanphut,
      phanphup: infoBNSan?.phanphup,
      gnkhac: infoBNSan?.gnkhac,
      cungdo: infoBNSan?.cungdo,
      tsm: infoBNSan?.tsm,
      nhiN2VU: infoBNSan?.nhiN2VU,
      nhintrai: infoBNSan?.nhintrai,
      nhinphai: infoBNSan?.nhinphai,
      sonantrai: infoBNSan?.sonantrai,
      sonanphai: infoBNSan?.sonanphai,
      hachnach: infoBNSan?.hachnach,
      idloaisan: infoBNSan?.idloaisan ?? 1,
    },
    //disable for performance
    validateOnChange: false,
    validateOnBlur: false,
  })
  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      //put bn
      if (
        infoBN.benhNhan.diUng !== formikTTBN.values.diUng ||
        infoBN.benhNhan.tienSuBanThan !== formikTTBN.values.tienSuBanThan
      ) {
        await https.put(`BenhNhan`, formikTTBN.values)
        // get new BN Info
        await dispatch(getInfoBN_TT_Action(formikTTBN.values.idbn, PkDangNhap?.idChuyenKhoa))
      }
      await https.put(`BenhNhanTTC`, values)
      if (infoBNSan) {
        await https.put(`BenhNhanSan/UpdateBenhNhanSan`, formikPhuSan.values)
        await getInfoBNSan(infoBNTTC.idbnttc)
      }
      //get new value
      await getInfoBNTTC(infoBNTTC.idbnttc)

      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật bệnh án thành công',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Cập nhật bệnh án thất bại',
      })
    } finally {
      setLoading(false)
    }
  }

  const getDVbyIdBenhNhanTTC = async (idbnttc) => {
    setLoading(true)
    try {
      const { data } = await https.get(`BenhNhanDichVu/GetBenhNhanDichVu/${idbnttc}`)
      setListDV(
        data.map((item) => ({
          maChiDinh: item.madichvu,
          tenChiDinh: item.tendichvu,
          dvt: item.donvitinh,
          dongia: item.dongia,
          tiengiamgia: item.tiengiamgia,
          tienthanhtoan: item.tienthanhtoan,
        })),
      )
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getInfoBNTTC = async (idbnttc) => {
    setLoading(true)
    try {
      const { data } = await https.get(`BenhNhanTTC/GetByIDBNTTC?id=${idbnttc}`)
      if (data.linkhinhanhtiemchung) {
        const splitImage = data.linkhinhanhtiemchung.split(';')
        setListImage(splitImage.filter((item) => item !== ''))
      } else {
        setListImage([])
      }
      setInfoBNTTC(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getInfoBNSan = async (idbnttc) => {
    setLoading(true)
    try {
      const result = await https.get(`BenhNhanSan/GetBenhNhanSanByIDBNTTC?idbnttc=${idbnttc}`)
      //benh nhan san
      setInfoBNSan(result.status === 200 ? result.data : null)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getCDHAbyIdBenhNhanTTC = async (idbnttc) => {
    setLoading(true)
    try {
      const { data } = await https.get(`BenhNhanCDHA/GetBenhNhanCDHA/${idbnttc}`)
      setListDV((prev) => [
        ...prev,
        ...data.map((item) => ({
          dvt: item.dvt,
          maChiDinh: item.macdha,
          tenChiDinh: item.tencdha,
          dongia: item.dongia,
          tiengiamgia: item.tiengiamgia,
          tienthanhtoan: item.tienthanhtoan,
        })),
      ])
      //   setListDV(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const getXNbyIdBenhNhanTTC = async (idbnttc) => {
    setLoading(true)
    try {
      const { data } = await https.get(`BenhNhanXetNghiem/GetBenhNhanXetNghiem/${idbnttc}`)
      setListDV((prev) => [
        ...prev,
        ...data.map((item) => ({
          dvt: item.dvt,
          maChiDinh: item.maxn,
          tenChiDinh: item.tenxn,
          dongia: item.dongia,
          tiengiamgia: item.tiengiamgia,
          tienthanhtoan: item.tienthanhtoan,
        })),
      ])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getToaThuocbyIdBenhNhanTTC = async (idbnttc) => {
    setLoading(true)
    try {
      const { data } = await https.get(`BenhNhanThuoc/GetDanhSachToaThuocLichSu?idbnttc=${idbnttc}`)
      setToaThuoc(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleShowDetail = async (item) => {
    setLoading(true)
    setActiveItemId(item.idbnttc)
    try {
      const promises = [
        getInfoBNTTC(item.idbnttc),
        getInfoBNSan(item.idbnttc),
        getDVbyIdBenhNhanTTC(item.idbnttc),
        getCDHAbyIdBenhNhanTTC(item.idbnttc),
        getXNbyIdBenhNhanTTC(item.idbnttc),
        getToaThuocbyIdBenhNhanTTC(item.idbnttc),
      ]

      // Wait for all promises to resolve
      await Promise.all(promises)

      const benhNhanThanhToan = benhNhanThanhToans.find(
        (thanhToan) => thanhToan.idbnttc === item.idbnttc,
      )
      if (benhNhanThanhToan) {
        // console.log('Ghi chú thanh toán:', benhNhanThanhToan?.ghiChu)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const onClickFilter = () => {
    if (!time) {
      setListLichSu(listLichSuDefault)
      return
    }
    const data = listLichSuDefault.filter(
      (item) => dayjs(item.ngaykham).format('DD/MM/YYYY') === time,
    )
    setListLichSu(data)
  }

  const fetchListLyDoKhongKeToa = async () => {
    try {
      const result = await childrenClinicService.getListLyDoKhongKeToa()
      setListLyDoKhongKeToa(result.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fechChanDoan('')
    fetchListLoaiKham()
    fetchListLyDoKhongKeToa()
  }, [])

  const handlePrint = (infoBNTTC) => {
    const icdObj = {
      chandoan: ChanDoan?.find((item) => item.idicD10 === infoBNTTC.idchandoan)?.mabenh,
      chandoanbandau: ChanDoan?.find((item) => item.idicD10 === infoBNTTC.idicD10BANDAU)?.mabenh,
      chandoanravien: ChanDoan?.find((item) => item.idicD10 === infoBNTTC.idicD10RAVIEN)?.mabenh,
      benhkemtheo: ChanDoan?.find((item) => item.idicD10 === infoBNTTC.idicD10BENHKEMTHEO)?.mabenh,
      bienchung: ChanDoan?.find((item) => item.idicD10 === infoBNTTC.idicD10BIENCHUNG)?.mabenh,
    }
    // lấy tiền sử bản thân/ dị ứng từ bệnh nhân (ko lấy từ bnttc)
    const formatData = {
      ...infoBNTTC,
      tiensubanthan: infoBN.benhNhan.tienSuBanThan,
      diung: infoBN.benhNhan.diUng,
    }
    benhAnNgoaiTru(formatData, icdObj)
  }

  const handlePrintTT32 = (PkDangNhap) => {
    generateTT32(PkDangNhap)
  }

  const handlePrintPhieuSan = (infoBNTTC, infoBNSan, PkDangNhap) => {
    switch (infoBNSan.idloaisan) {
      case 1: //sản lớn
        generatePhieuSanLon(infoBNTTC, infoBNSan, PkDangNhap)
        break
      case 2: //sản nhỏ
        generatePhieuSanNho(infoBNTTC, infoBNSan, PkDangNhap)
        break
      case 3: //phụ khoa
        generatePhieuPhuKhoa(infoBNTTC, infoBNSan, PkDangNhap)
        break
      case 4: // nhũ hoa
        generateNhuHoa(infoBNTTC, infoBNSan, PkDangNhap)
        break
      default:
        break
    }
  }
  const handleDatePicker = (name) => (data, dateString) => {
    const formatDate = moment(dateString, 'DD/MM/YYYY')
      .set({
        hour: 23,
        minute: 59,
        second: 59,
      })
      .format('YYYY-MM-DDTHH:mm:ss')
    formik.setFieldValue(name, formatDate)
  }
  const validateUpdateBNTTC = true
  // infoUser?.idnv !== infoBNTTC?.idbskham || moment().diff(moment(infoBNTTC?.ngaykham), 'days') > 1
  const checkNguoiTao =
    (infoUser?.tenNV && formatNameCompare(infoUser?.tenNV)) ===
      (infoBNTTC?.nguoitao && formatNameCompare(infoBNTTC?.nguoitao)) &&
    moment().diff(moment(infoBNTTC?.ngaykham), 'days') <= 1

  const handleUpdateThongTinNguoiNha = async () => {
    const { idbnttc, tennguoinha, dienthoainguoinha, nguoinha, nguoiSua } = formik.values
    setLoading(true)
    try {
      await https.put(`BenhNhanTTC`, {
        idbnttc,
        tennguoinha,
        dienthoainguoinha,
        nguoinha,
        nguoiSua,
      })
      //get new value
      await getInfoBNTTC(infoBNTTC.idbnttc)
      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật thông tin người nhà thành công',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Cập nhật thông tin người nhà thất bại',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open === false) {
      //reset
      setInfoBNTTC(null)
      setListLichSuDefault([])
      setListLichSu([])
      setActiveItemId(null)
      formik.resetForm()
    }
  }, [open])

  const benhNhanThanhToan = benhNhanThanhToans?.find(
    (item) => infoBNTTC && item.idbnttc === infoBNTTC.idbnttc,
  )

  return (
    <div>
      <h2 className="text-gray-500 font-semibold text-lg mb-2 ">Lịch sử khám bệnh</h2>
      <div className="flex gap-2">
        <div className="w-1/6 pr-2 border-r-2 h-full">
          <div className="flex">
            <DatePicker
              placeholder="Chọn ngày khám"
              onChange={(value, dateString) => {
                setTime(dateString)
              }}
              format={'DD/MM/YYYY'}
              className="w-full"
            />
            <Button
              onClick={onClickFilter}
              type="primary"
              icon={<FilterOutlined />}
              className="ml-2"
            ></Button>
          </div>
          {/* date picker time */}

          <ul className=" flex flex-col gap-2 mt-2 rounded-md h-[680px] overflow-auto p-2">
            {listLichSu.map((item) => (
              <li
                key={item.idbnttc}
                onClick={() => handleShowDetail(item)}
                className={
                  item.idbnttc === activeItemId && open ? activeListItemClasses : listItemClasses
                }
              >
                <p className="w-full text-center font-semibold">
                  {moment(item.ngaykham).format('DD/MM/YYYY HH:mm')}
                </p>
              </li>
            ))}
          </ul>
        </div>
        {infoBNTTC && listLichSuDefault.length > 0 && (
          <div className="relative w-5/6">
            <div className="flex items-center mb-2">
              <h2 className="text-gray-500 font-semibold text-lg mb-2 mt-2">Thông tin khám bệnh</h2>
              <Button
                type="primary"
                className="ml-auto"
                onClick={formik.handleSubmit}
                icon={<SaveOutlined />}
                // disabled={validateUpdateBNTTC}
                loading={loading}
              >
                Lưu
              </Button>
              <Button
                disabled={loading}
                onClick={() => handlePrint(infoBNTTC)}
                type="primary"
                icon={<PrinterOutlined />}
                className="ml-2"
              >
                In bệnh án
              </Button>
              <Button
                disabled={loading}
                onClick={() => handlePrint(infoBNTTC)}
                type="default"
                icon={<SaveOutlined />}
                className="ml-2 text-green-700"
              >
                Bệnh án (Word)
              </Button>
              <Button
                // disabled={loading}
                onClick={() => handlePrintTT32(PkDangNhap)}
                type="primary"
                icon={<PrinterOutlined />}
                className="ml-2"
                disabled
              >
                In Khám sức khỏe
              </Button>

              {infoBNSan && PkDangNhap?.idChuyenKhoa === 2 ? ( // PkDangNhap?.idChuyenKhoa === 2 đăng nhập pk sản mới dc in , trên phiếu in có thông tin phòng khám đăng nhập
                <Button
                  onClick={() => handlePrintPhieuSan(infoBNTTC, infoBNSan, PkDangNhap)}
                  type="primary"
                  icon={<PrinterOutlined />}
                  className="ml-2"
                >
                  In Phiếu{' '}
                  {
                    listLoaiKham.find((item) => item.idbnsanloai === formikPhuSan.values.idloaisan)
                      ?.tensanloai
                  }
                </Button>
              ) : null}
            </div>
            <div className="container mx-auto flex gap-1 flex-col">
              <div className="grid grid-cols-4 gap-1 w-full">
                <li
                  className="p-1 rounded-md bg-white grid grid-cols-3 items-center"
                  style={{
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
                  }}
                >
                  <p className="text-gray-500">Chiều cao</p>
                  <Input
                    size="small"
                    className="text-center font-semibold text-green-700 text-sm"
                    onChange={formik.handleChange}
                    value={formik.values.chieucao}
                    name="chieucao"
                    type="number"
                    step="any"
                    disabled={validateUpdateBNTTC}
                    style={{ color: '#15803d' }}
                  />
                  <span className="text-center font-semibold text-green-700 text-sm">Cm</span>
                </li>
                <li
                  className="p-1 rounded-md bg-white grid grid-cols-3 items-center"
                  style={{
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
                  }}
                >
                  <p className="text-gray-500">Cân nặng</p>
                  <Input
                    size="small"
                    className="text-center font-semibold text-green-700 text-sm"
                    onChange={formik.handleChange}
                    value={formik.values.cannang}
                    name="cannang"
                    type="number"
                    step="any"
                    disabled={validateUpdateBNTTC}
                    style={{ color: '#15803d' }}
                  />
                  <span className="text-center font-semibold text-green-700 text-sm">Kg</span>
                </li>
                <li
                  className="p-1 rounded-md bg-white grid grid-cols-3 items-center"
                  style={{
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
                  }}
                >
                  <p className="text-gray-500">Mạch</p>
                  <Input
                    size="small"
                    className="text-center font-semibold text-green-700 text-sm"
                    onChange={formik.handleChange}
                    value={formik.values.mach}
                    name="mach"
                    disabled={validateUpdateBNTTC}
                    style={{ color: '#15803d' }}
                  />
                  <span className="text-center font-semibold text-green-700 text-sm">Lần/phút</span>
                </li>
                <li
                  className="p-1 rounded-md bg-white grid grid-cols-3 items-center"
                  style={{
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
                  }}
                >
                  <p className="text-gray-500">Huyết áp</p>
                  <Input
                    size="small"
                    className="text-center font-semibold text-green-700 text-sm"
                    onChange={formik.handleChange}
                    value={formik.values.huyetap}
                    name="huyetap"
                    disabled={validateUpdateBNTTC}
                    style={{ color: '#15803d' }}
                  />
                  <span className="text-center font-semibold text-green-700 text-sm">mmHg</span>
                </li>
                <li
                  className="p-1 rounded-md bg-white grid grid-cols-3 items-center"
                  style={{
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
                  }}
                >
                  <p className="text-gray-500">Nhiệt độ</p>
                  <Input
                    size="small"
                    className="text-center font-semibold text-green-700 text-sm"
                    onChange={formik.handleChange}
                    value={formik.values.nhietdo}
                    name="nhietdo"
                    disabled={validateUpdateBNTTC}
                    style={{ color: '#15803d' }}
                  />
                  <span className="text-center font-semibold text-green-700 text-sm">&#8451;</span>
                </li>
                <li
                  className="p-1 rounded-md bg-white grid grid-cols-3 items-center"
                  style={{
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
                  }}
                >
                  <p className="text-gray-500">Nhịp thở</p>
                  <Input
                    size="small"
                    className="text-center font-semibold text-green-700 text-sm"
                    onChange={formik.handleChange}
                    value={formik.values.nhiptho}
                    name="nhiptho"
                    disabled={validateUpdateBNTTC}
                    style={{ color: '#15803d' }}
                  />
                  <span className="text-center font-semibold text-green-700 text-sm">Lần/Phút</span>
                </li>
                <li
                  className="p-1 rounded-md bg-white grid grid-cols-3 items-center"
                  style={{
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
                  }}
                >
                  <p className="text-gray-500">Spo2</p>
                  <Input
                    size="small"
                    className="text-center font-semibold text-green-700 text-sm"
                    onChange={formik.handleChange}
                    value={formik.values.spo2}
                    name="spo2"
                    disabled={validateUpdateBNTTC}
                    style={{ color: '#15803d' }}
                  />
                  <span className="text-center font-semibold text-green-700 text-sm">%</span>
                </li>
                <li
                  className="p-1 rounded-md bg-white grid grid-cols-3 items-center"
                  style={{
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
                  }}
                >
                  <p className="text-gray-500">BMI</p>
                  <span className="text-center font-semibold text-green-700 text-sm">{bmi}</span>
                </li>
              </div>
            </div>
            <div>
              <div className="flex gap-1 mt-1 items-center">
                <div className="flex gap-1 mt-1 w-1/2">
                  <div className="flex gap-1 mt-1 w-1/2 items-center">
                    <label className="font-semibold w-32 ml-4">Người nhà: </label>
                    <Select
                      showSearch
                      className="w-full"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      onChange={(value) => formik.setFieldValue('nguoinha', value)}
                      value={formik.values.nguoinha}
                      options={nguoinha?.map((items) => ({
                        label: items.ten,
                        value: items.ten,
                      }))}
                      size="small"
                      disabled={!checkNguoiTao}
                    />
                  </div>
                  <div className="flex gap-1 mt-1 w-1/2 items-center">
                    <label className="font-semibold w-32 ml-4">SĐT: </label>
                    <Input
                      disabled={!checkNguoiTao}
                      style={{ color: 'black' }}
                      name="dienthoainguoinha"
                      size="small"
                      className={!checkNguoiTao ? 'w-full cursor-default' : 'w-full'}
                      value={formik.values.dienthoainguoinha}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="flex gap-1 mt-1 w-1/2">
                  <div className="w-3/4 flex gap-1 items-center">
                    <label className="font-semibold w-32 ml-4">Tên người nhà: </label>
                    <Input
                      disabled={!checkNguoiTao}
                      style={{ color: 'black' }}
                      name="tennguoinha"
                      size="small"
                      className={!checkNguoiTao ? 'w-full cursor-default' : 'w-full'}
                      value={formik.values.tennguoinha}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="w-1/4 flex gap-1">
                    {checkNguoiTao && (
                      <Button
                        type="primary"
                        className="ml-4"
                        onClick={handleUpdateThongTinNguoiNha}
                      >
                        Cập nhật
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex gap-1 mt-1 items-center">
                <div className="flex gap-1 mt-1 w-1/4">
                  <label className="font-semibold w-32 ml-4">Toa thuốc: </label>
                  <Input
                    disabled
                    name="toathuoc"
                    size="small"
                    style={{ color: 'black' }}
                    value={`TT${infoBNTTC?.benhNhan?.maBenhNhan}`}
                  />
                </div>
                <div className="flex gap-1 mt-1 w-1/4 items-center">
                  <label className="font-semibold w-32 ml-4">STT: </label>
                  <Input
                    disabled
                    value={infoBNTTC?.sttkham}
                    name="stt"
                    size="small"
                    style={{ color: 'black' }}
                  />
                </div>
                <div className="flex gap-1 mt-1 w-1/4 items-center">
                  <label className="font-semibold w-40 ml-4">Ngày khám: </label>
                  <DatePicker
                    placeholder=" "
                    allowClear={false}
                    disabled
                    style={{ color: 'black' }}
                    className="w-full cursor-default"
                    value={infoBNTTC?.ngaykham && dayjs(infoBNTTC?.ngaykham, 'YYYY-MM-DD')}
                    format={'DD/MM/YYYY'}
                    size="small"
                  />
                </div>
                <div className="flex gap-1 mt-1 w-1/4 items-center">
                  <label className="font-semibold w-32 ml-4">Tái khám: </label>
                  <DatePicker
                    minDate={dayjs(infoBNTTC?.ngaykham)}
                    onChange={handleDatePicker('ngaytaikham')}
                    allowClear={false}
                    disabled={validateUpdateBNTTC}
                    className="w-full "
                    value={
                      formik.values.ngaytaikham
                        ? dayjs(formik.values.ngaytaikham, 'YYYY-MM-DDTHH:mm:ss')
                        : null
                    }
                    format="DD/MM/YYYY"
                    size="small"
                  />
                </div>
              </div>
              {infoBNTTC?.lydokolaythuoc ? (
                <div className="flex gap-1 mt-1">
                  <div className="flex gap-1 mt-1 w-1/4">
                    <Checkbox
                      checked={infoBNTTC?.lydokolaythuoc}
                      className="ml-4 flex items-center"
                    >
                      Không kê toa
                    </Checkbox>
                  </div>
                  <div className="flex gap-1 mt-1 w-3/4">
                    <label className="font-semibold w-44 ml-4">Lý do không kê toa: </label>
                    <Select
                      disabled
                      className="w-full"
                      size="small"
                      value={infoBNTTC?.lydokolaythuoc}
                      options={listLyDoKhongKeToa.map((item) => ({
                        key: item.idld,
                        value: item.idld,
                        label: item.lydo,
                      }))}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Ghi chú: </label>
                <Input
                  size="small"
                  value={formik.values.ghichu}
                  name="ghichu"
                  onChange={formik.handleChange}
                  disabled={validateUpdateBNTTC}
                  style={{ color: 'black' }}
                />
              </div>
              {benhNhanThanhToan && (
                <div className="flex gap-1 mt-1">
                  <label className="font-semibold w-44 text-end">Ghi chú thanh toán: </label>
                  <Input
                    size="small"
                    value={benhNhanThanhToan.ghiChu}
                    disabled
                    style={{ color: 'black' }}
                  />
                </div>
              )}
              <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Tiền sử bản thân: </label>
                <Input
                  placeholder="Không"
                  onChange={formikTTBN.handleChange}
                  value={formikTTBN.values.tienSuBanThan}
                  name="tienSuBanThan"
                  size="small"
                  // disabled={validateUpdateBNTTC}
                  // style={{ color: 'black' }}
                />
              </div>
              <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Dị ứng: </label>
                <Input
                  placeholder="Không"
                  onChange={formikTTBN.handleChange}
                  value={formikTTBN.values.diUng}
                  name="diUng"
                  size="small"
                  disabled={validateUpdateBNTTC}
                  style={{ color: 'black' }}
                />
              </div>
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    label: 'Thông tin chung',
                    key: 1,
                    children: (
                      <FormBenhNhanTTC formik={formik} validateUpdateBNTTC={validateUpdateBNTTC} />
                    ),
                  },
                  infoBNSan
                    ? {
                        label: listLoaiKham.find(
                          (item) => item.idbnsanloai === formikPhuSan.values.idloaisan,
                        )?.tensanloai,
                        key: 2,
                        children: <InfoBenhNhanSan formik={formik} formikPhuSan={formikPhuSan} />,
                      }
                    : null,
                ]}
              />
            </div>

            <Divider />
            <h2 className="text-gray-500 font-semibold text-lg mb-2 mt-2">Dịch vụ</h2>
            <div className="mt-2">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                  },
                  components: {
                    Table: {
                      rowHoverBg: '#ecf0f1',
                      headerBg: '#e6e6e6',
                      footerBg: '#e6e6e6',
                      borderColor: '#BABABA',
                    },
                  },
                }}
              >
                <Table
                  bordered
                  pagination={{
                    pageSize: 10,
                    showSizeChanger: false,
                  }}
                  scroll={{ y: 200 }}
                  columns={columnsDV}
                  dataSource={listDV}
                  loading={loading}
                  footer={() => (
                    <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      Tổng: {formatNumberVND(listDV.reduce((a, b) => a + b.tienthanhtoan, 0))}
                    </div>
                  )}
                />
              </ConfigProvider>
            </div>
            <Divider />
            {!infoBNTTC?.lydokolaythuoc && (
              <>
                <h2 className="text-gray-500 font-semibold text-lg mb-2">Toa thuốc</h2>
                <div className="mt-2">
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 5,
                      },
                      components: {
                        Table: {
                          rowHoverBg: '#ecf0f1',
                          headerBg: '#e6e6e6',
                          footerBg: '#e6e6e6',
                          borderColor: '#BABABA',
                        },
                      },
                    }}
                  >
                    <Table
                      bordered
                      pagination={{
                        pageSize: 10,
                        showSizeChanger: false,
                      }}
                      scroll={{ y: 200 }}
                      columns={columnsThuoc}
                      dataSource={toaThuoc}
                      loading={loading}
                      footer={() => (
                        <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          Tổng: {formatNumberVND(toaThuoc.reduce((a, b) => a + b.thanhtien, 0))}
                        </div>
                      )}
                    />
                  </ConfigProvider>
                </div>
              </>
            )}
            <Divider />
            <h2 className="text-gray-500 font-semibold text-lg mb-2">Tiêm chủng</h2>
            <div className="max-h-[300px] overflow-auto">
              <ImageTiemChung
                activeItemId={activeItemId}
                listImage={listImage}
                setListImage={setListImage}
                getInfoBNTTC={getInfoBNTTC}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

HistoryKhamBenh.propTypes = {}

export default React.memo(HistoryKhamBenh)

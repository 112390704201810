import { InputNumber } from 'antd'
import React from 'react'
import style from './style.module.css'
const CustomInput = ({formik,name}) => {
  return (
    <InputNumber
      size="small"
      onChange={(value) => formik.setFieldValue(name, value)}
      name={name}
      className={style.input_align}
      value={formik.values[name]}
      step="0.001"
      formatter={(value) => {
        const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
        return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }}
      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
      max={999999999}
    />
  )
}

export default CustomInput

import { DatePicker, Input, Modal } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as typeAction from '../../../../store/constants/constants'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import dayjs from 'dayjs'
import { createNewKyhang, getKyHangs } from '../../../../store/actions/kiemKeAction'
const today = moment()
const ModalCreateKyHang = () => {
  const { isKyHang } = useSelector((state) => state.modalReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const ID_BRANCH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const dispatch = useDispatch()
  const handleCancel = () => {
    dispatch({
      type: typeAction.CLOSE_CREATE_KY_HANG_HOA,
    })
  }
  const resetForm = () => {
    handleCancel()
    formik.resetForm()
    dispatch(getKyHangs())
  }
  const handleSubmit = (values) => {
    dispatch(createNewKyhang(values,resetForm))
  }
  //-----------validate form -------
  const validationSchema = Yup.object({
    tenky: Yup.string().required('Vui lòng nhập tên kỳ'),
    denngay: Yup.date().required('Vui lòng chọn ngày chốt kỳ'),
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tenky: '',
      denngay: '',
      nguoitao: infoUser?.idnv,
      idchinhanh:ID_BRANCH_LOGIN
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: validationSchema,
  })
  return (
    <Modal
      onOk={formik.handleSubmit}
      open={isKyHang}
      okText="Tạo"
      cancelText="Huỷ bỏ"
      onCancel={handleCancel}
      title="Tạo kỳ hàng hoá"
    >
      <form className="flex flex-col gap-2">
        <div>
          <labe>
            {' '}
            <span className="text-red-500">(*)</span> Tên kỳ hàng:
          </labe>
          <Input
            name="tenky"
            placeholder="Nhập tên kỳ hàng"
            value={formik.values.tenky}
            onChange={formik.handleChange}
          />
          {formik.touched.tenky && formik.errors.tenky && (
            <span className="text-red-500">* {formik.errors.tenky}</span>
          )}
        </div>
        <div>
          <label>
            {' '}
            <span className="text-red-500">(*)</span> Ngày chốt kỳ:
          </label>
          <DatePicker
            value={formik.values.denngay ? dayjs(formik.values.denngay) : ''}
            className="w-full"
            format={'DD/MM/YYYY'}
            maxDate={dayjs(today)}
            onChange={(date, dateString) => {
              const formatValue =
                dateString !== ''
                  ? moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD')
                  : dateString
              formik.setFieldValue('denngay', formatValue)
            }}
          />
          {formik.touched.denngay && formik.errors.denngay && (
            <span className="text-red-500">* {formik.errors.denngay}</span>
          )}
        </div>
      </form>
    </Modal>
  )
}

export default ModalCreateKyHang

import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SyncIcon from '@mui/icons-material/Sync'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { DatePicker, Select, ConfigProvider, Table, Input, Button, TreeSelect } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { getDanhSachThuocBan } from '../../../../store/actions/drugstoreAction'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { reportService } from '../../../../services/Report/reportService'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import _ from 'lodash'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const { SHOW_PARENT } = TreeSelect
const Producs = ({ IDLoaiThanhToan }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { PTThuoc } = useSelector((state) => state.drugstoreReducer)
  const [open, setOpen] = useState(false)
  const [branch, setBranch] = useState([])
  const [ChuyenKhoa, setChuyenKhoa] = useState([])
  const [idChuyenKhoa, setIdChuyenKhoa] = useState(null)
  const [value, setValue] = useState([BranchLogin])
  const [isLoading, setIsLoading] = useState(false)
  const [date, setDate] = useState({ form: now, to: now })
  const [chiTiet, setChiTiet] = useState(null)
  const [idCaLamViec, setIdCaLamViec] = useState('')
  const [data, setData] = useState([])
  const [keyword, setKeyword] = useState('')
  const dispatch = useDispatch()
  const filter = {
    idloaitt: IDLoaiThanhToan,
    idcalamviec: idCaLamViec,
    idct: id_company,
    idcn: value,
    tuNgay: date.form,
    denNgay: date.to,
    keyword: keyword.trim(),
  }
  const handleDatePicker = (name) => (date, dateString) => {
    const formatDateValue = moment(dateString, dateFormat).format(momentDate)
    setDate((prevSate) => ({ ...prevSate, [name]: formatDateValue }))
  }
  const debounceDropDown = useCallback(
    _.debounce((filter, keyword) => {
      dispatch(getDanhSachThuocBan({ ...filter, keyword: keyword }, setIsLoading, setData))
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const onReload = () => {
    dispatch(getDanhSachThuocBan(filter, setIsLoading, setData))
  }
  const onSearch = (e) => {
    const { value } = e.target
    setKeyword(value)
    debounceDropDown(filter, value)
  }
  useEffect(() => {
    fetchBranchByChuyenKhoa(null)
    fetchChuyenKhoa()
    onReload()
  }, [])
  const exportExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        (IDLoaiThanhToan === 4 && 'QHT258') || // bán toa
          (IDLoaiThanhToan === 5 && 'QHT270') || // bán lẻ
          (IDLoaiThanhToan === 6 && 'QHT286'), // xuất vắc xin
      )

      const formatData = data?.map((item) => ({
        'Phiếu xuất': item.maphieuthu,
        'Chi nhánh': item.tenchinhanh,
        'Ngày xuất': moment(item.ngayban).format('DD/MM/YYYY'),
        'Dược sĩ': item.duocsi,
        'Mã hàng': item.mathuoc,
        'Tên hàng': item.tenbietduoc,
        'Tên hoạt chất': item.tenhoatchat,
        'Đơn vị': item.dvt,
        'Số lượng': item.soluong,
        'Đơn giá': item.dongia,
        'Thành tiền': item.thanhtien,
        '% Giảm': item.ptgiam,
        'Tiền giảm': item.tiengiam,
        'Thanh toán': item.tongtien,
        'Số lô': item.solo,
        'Hạn dung': moment(item.handung).format('DD/MM/YYYY'),
        'Số hoá đơn': item.sohoadon,
        'Số đăng kí': item.masodangky,
        'Mã voucher': item.mavoucher,
        'Ghi chú': item.ghichu,
      }))
      const name = `Danh sách xuất thuốc` // format date L = '26.08.2024'
      formatData?.length && exportExcelformat(formatData, name)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }
  //------------ lấy danh sách chi nhánh theo chuyên khoa ----------//
  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(id_company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  //-------------- lấy danh sách chuyên kho ------------//
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
      })),
    },
  ]
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 45,
      align: 'center',
      key: 'stt',
      fixed: 'left',
      showSorterTooltip: false,
      render: (text, _, index) => ++index,
    },
    {
      title: 'Phiếu xuất',
      dataIndex: 'maphieuthu',
      width: 120,
      showSorterTooltip: false,
      key: 'maphieuthu',
      fixed: 'left',
      align: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.maphieuthu ?? '').localeCompare(b?.maphieuthu ?? '')
        },
      },
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenchinhanh',
      // width: 250,
      showSorterTooltip: false,
      key: 'tenchinhanh',
      fixed: 'left',
      align: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.tenchinhanh ?? '').localeCompare(b?.tenchinhanh ?? '')
        },
      },
    },
    {
      title: 'Ngày xuất',
      dataIndex: 'ngayban',
      // width: 140,
      align: 'center',
      key: 'ngayban',
      showSorterTooltip: false,
      render: (text) => (text ? moment(text)?.format('DD/MM/YYYY HH:mm:ss') : '-'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngayban ?? '').localeCompare(b?.ngayban ?? '')
        },
      },
    },
    {
      title: 'Dược sĩ',
      dataIndex: 'duocsi',
      // width: 140,
      key: 'duocsi',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.duocsi ?? '').localeCompare(b?.duocsi ?? '')
        },
      },
    },
    {
      title: 'Mã hàng',
      dataIndex: 'mathuoc',
      key: 'mathuoc',
      showSorterTooltip: false,
      onCell: () => ({
        style: {
          minWidth: 90,
        },
      }),
      sorter: {
        compare: (a, b) => {
          return (a?.mathuoc ?? '').localeCompare(b?.mathuoc ?? '')
        },
      },
    },
    {
      title: 'Tên hàng',
      dataIndex: 'tenbietduoc',
      showSorterTooltip: false,
      key: 'tenbietduoc',
      sorter: {
        compare: (a, b) => {
          return (a?.tenbietduoc ?? '').localeCompare(b?.tenbietduoc ?? '')
        },
      },
    },
    {
      title: 'Hoạt chất',
      dataIndex: 'tenhoatchat',
      key: 'tenhoatchat',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tenhoatchat ?? '').localeCompare(b?.tenhoatchat ?? '')
        },
      },
    },
    {
      title: 'Đơn vị',
      dataIndex: 'dvt',
      key: 'dvt',
      onCell: () => ({
        style: {
          minWidth: 90,
        },
      }),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.dvt ?? '').localeCompare(b?.dvt ?? '')
        },
      },
    },
    {
      title: 'Số lượng',
      dataIndex: 'soluong',
      key: 'soluong',
      align: 'center',
      onCell: () => ({
        style: {
          minWidth: 90,
        },
      }),
      showSorterTooltip: false,
      render: (text) => formattedNumber(text ?? 0),
      sorter: {
        compare: (a, b) => {
          return (a?.soluong ?? '').localeCompare(b?.soluong ?? '')
        },
      },
    },
    {
      title: 'Đơn giá',
      dataIndex: 'dongia',
      key: 'dongia',
      align: 'right',
      onCell: () => ({
        style: {
          minWidth: 100,
        },
      }),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.dongia ?? 0) - (b.dongia ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.tienMat ?? '').localeCompare(b?.tienMat ?? '')
      //   },
      // },
    },
    {
      title: 'Thành tiền',
      dataIndex: 'thanhtien',
      key: 'thanhtien',
      onCell: () => ({
        style: {
          minWidth: 100,
        },
      }),
      showSorterTooltip: false,
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (a.thanhtien ?? 0) - (b.thanhtien ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.chuyenKhoan ?? '').localeCompare(b?.chuyenKhoan ?? '')
      //   },
      // },
    },
    {
      title: '% Giảm',
      dataIndex: 'ptgiam',
      key: 'ptgiam',
      onCell: () => ({
        style: {
          minWidth: 100,
        },
      }),
      align: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.ptgiam ?? 0) - (b.ptgiam ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: 'Tiền giảm',
      dataIndex: 'tiengiam',
      key: 'tiengiam',
      onCell: () => ({
        style: {
          minWidth: 100,
        },
      }),
      align: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.tiengiam ?? 0) - (b.tiengiam ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: 'Thanh toán',
      dataIndex: 'tongtien',
      key: 'tongtien',
      align: 'right',
      showSorterTooltip: false,
      onCell: () => ({
        style: {
          minWidth: 100,
        },
      }),
      sorter: {
        compare: (a, b) => {
          return (a.tongtien ?? 0) - (b.tongtien ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.moMo ?? '').localeCompare(b?.moMo ?? '')
      //   },
      // },
    },
    {
      title: 'Số lô',
      dataIndex: 'solo',
      key: 'solo',
      align: 'right',
      width: 90,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.solo ?? 0) - (b.solo ?? 0)
        },
      },
    },
    {
      title: 'Hạn dùng',
      dataIndex: 'handung',
      key: 'handung ',
      align: 'right',
      width: 90,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.handung ?? 0) - (b.handung ?? 0)
        },
      },
      render: (text) => (text ? moment(text)?.format('DD/MM/YYYY') : '-'),
    },
    {
      title: 'Số hoà đơn',
      dataIndex: 'sohoadon',
      key: 'sohoadon',
      align: 'right',
      onCell: () => ({
        style: {
          minWidth: 100,
        },
      }),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.sohoadon ?? 0) - (b.sohoadon ?? 0)
        },
      },
    },
    {
      title: 'Số đăng kí',
      dataIndex: 'masodangky',
      key: 'masodangky',
      onCell: () => ({
        style: {
          minWidth: 100,
        },
      }),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.masodangky ?? '').localeCompare(b?.masodangky ?? '')
        },
      },
    },
    {
      title: 'Mã voucher',
      dataIndex: 'mavoucher',
      key: 'mavoucher',
      align: 'right',
      onCell: () => ({
        style: {
          minWidth: 110,
        },
      }),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.mavoucher ?? 0) - (b.mavoucher ?? 0)
        },
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      onCell: () => ({
        style: {
          minWidth: 100,
        },
      }),
      align: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.ghichu ?? 0) - (b.ghichu ?? 0)
        },
      },
    },
  ]
  return (
    <div className="h-full w-full ">
      <div className="flex gap-5 justify-between  mb-2 h-full border-b border-dotted pb-2">
        <div className="flex gap-2 justify-between w-full items-center">
          <div className="flex gap-2">
            <div className="flex items-center gap-1">
              <div className="flex flex-col">
                <label className="font-semibold">Từ ngày</label>
                <DatePicker
                  allowClear={false}
                  defaultValue={dayjs(now)}
                  maxDate={dayjs(now)}
                  onChange={handleDatePicker('form')}
                  format={dateFormat}
                />
              </div>
              <div className="flex flex-col">
                <label className="font-semibold">Đến ngày</label>
                <DatePicker
                  allowClear={false}
                  defaultValue={dayjs(now)}
                  maxDate={dayjs(now)}
                  onChange={handleDatePicker('to')}
                  format={dateFormat}
                />
              </div>
            </div>
            {/* <div className="w-56">
            <label className='font-semibold' htmlFor="">Chuyên khoa</label>
            <Select
              onChange={onChangeChuyenKhoa}
              className="w-full"
              value={idChuyenKhoa}
              options={[
                { label: 'Tất cả', value: null },
                ...ChuyenKhoa.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
            />
          </div> */}
            <div className="w-72">
              <label className="font-semibold" htmlFor="">
                Chi nhánh
              </label>
              <TreeSelect
                {...tProps}
                style={{ flexWrap: 'unset' }}
                className="w-full h-8 overflow-clip"
              />
            </div>
            <div className="w-60 ">
              <label className="font-semibold" htmlFor="">
                Ca làm việc
              </label>
              <Select
                options={[
                  { label: 'Tất cả', value: '' },
                  { label: 'Ca A', value: 1 },
                  { label: 'Ca B', value: 2 },
                  { label: 'Ca C', value: 3 },
                ]}
                onChange={(value) => setIdCaLamViec(value)}
                value={idCaLamViec}
                className="w-full"
              />
            </div>
          </div>
          <div className="flex gap-2 mt-5">
            <Button onClick={onReload} icon={<SyncIcon />} type="primary ">
              Tải lại
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div className="flex gap-2 justify-between mb-2">
          <div className="w-60 flex gap-1 items-center">
            <Input
              placeholder="Nhập từ khóa..."
              prefix={<SearchOutlined className="pr-1" />}
              onChange={onSearch}
              value={keyword}
              className="w-full"
            />
          </div>
          <div className="flex gap-2">
            <Button
              onClick={exportExcel}
              disabled={data.length === 0}
              className="text-green-700 font-semibold"
              type="text"
            >
              <ImportExportIcon /> Excel
            </Button>
          </div>
        </div>
      </div>
      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              x: data.length !== 0 ? 'max-content' : 2200,
              y: 600,
            }}
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={data}
            columns={columns}
            summary={(pageData) => {
              let SL = 0
              let DonGia = 0
              let ThanhTien = 0
              let ptGiam = 0
              let ThanhToan = 0
              let TienGiam = 0

              pageData.forEach(({ soluong, dongia, thanhtien, ptgiam, tiengiam, tongtien }) => {
                SL += soluong
                DonGia += dongia
                ThanhTien += thanhtien
                ptGiam += ptgiam
                ThanhToan += tongtien
                TienGiam += tiengiam
              })
              // console.log;
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                    <Table.Summary.Cell index={9}>
                      <p className="font-semibold text-center">{formattedNumber(SL)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10}>
                      <p className="font-semibold text-right">{formattedNumber(DonGia)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11}>
                      <p className="font-semibold text-right">{formattedNumber(ThanhTien)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12}>
                      <p className="font-semibold text-right">{formattedNumber(ptGiam)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13}>
                      <p className="font-semibold text-right">{formattedNumber(TienGiam)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14}>
                      <p className="font-semibold text-right">{formattedNumber(ThanhToan)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15}></Table.Summary.Cell>
                    <Table.Summary.Cell index={16}></Table.Summary.Cell>
                    <Table.Summary.Cell index={17}></Table.Summary.Cell>
                    <Table.Summary.Cell index={18}></Table.Summary.Cell>
                    <Table.Summary.Cell index={19}></Table.Summary.Cell>
                    <Table.Summary.Cell index={20}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default Producs

import * as typeAction from '../constants/constants'
export const fetchLoiDanBsDanhMuc = (search) => ({
  type: typeAction.GET_LOI_DAN_BS_DANH_MUC,
  search
})
export const deleteLoiDanBs = (id, reload) => ({
  type: typeAction.DELETE_LOI_DAN_BAC_SI,
  id,
  reload,
})
export const addNewLoiDanBs = (form, reload) => ({
  type: typeAction.ADD_NEW_LOI_DAN_BAC_SI,
  form,
  reload,
})
export const getInfoLoiDanById = (id) => ({
  type: typeAction.GET_INFO_LOI_DAN,
  id,
})
export const updateLoiDanById = (form, reload) => ({
  type: typeAction.UPDATE_LOI_DAN_BAC_SI,
  form,
  reload,
})

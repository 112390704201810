import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { drugstoreService } from '../../services/drugstore/drugstoreService'
import { XuatVacXinService } from '../../services/XuatVacXin/XuatVacXinService'
import { recieveService } from '../../services/receive/recieveService'
import Swal from 'sweetalert2'
import { childrenClinicService } from '../../services/childrenClinic/childrenClinicService'
import { BNDaLayThuoc, BNchoThuoc } from '../actions/drugstoreAction'
import { generatePhieuBanLeToaThuoc } from '../../utils/report/phieuThuToaBanLe'
import { generatePhieuThuTienToaThuoc } from '../../utils/report/phieuThuTienToaThuoc'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* xuatVacXinSaga() {
  // LẤY DANH SÁCH BN CHỜ LẤY THUỐC
  yield takeLatest(
    typeAction.GET_BN_CHO_LAY_THUOC_XVX,
    function* getBNChoXVXSaga({ type, idChiNhanh }) {
      try {
        const result = yield call(() => XuatVacXinService.getBNChoXVX(idChiNhanh))
        yield put({
          type: typeAction.DISPATCH_BN_LAY_THUOC_XVX,
          payload: result.data?.map((item) => ({ ...item, tab: 1 })),
        })
        yield put({
          type: typeAction.RESET_STORE_DRUGSTORE,
        })

        // yield put({
        //   type: typeAction.DISPATCH_BN_LAY_THUOC,
        //   payload: result.data?.map((item) => ({ ...item, tab: 1 })),
        // })
        // yield put({
        //   type: typeAction.DISPATCH_DETAI_BN_NHA_THUOC,
        //   payload: [],
        // })
        // yield put({
        //   type: typeAction.DISPATCH_INFO_BN_NHA_THUOC,
        //   payload: null,
        // })
        // yield put({
        //   type: typeAction.DISPATCH_SERVICE_NHA_THUOC,
        //   payload: null,
        // })
      } catch (err) {
        console.log(err)
      }
    },
  )
  // LẤY DANH SÁCH BN ĐÃ LẤY THUỐC
  yield takeLatest(
    typeAction.GET_BN_DA_LAY_THUOC_XVX,
    function* getBNDoneXVXSaga({ type, idChiNhanh }) {
      try {
        const result = yield call(() => XuatVacXinService.getBNDoneXVX(idChiNhanh))
        yield put({
          type: typeAction.DISPATCH_BN_LAY_THUOC_XVX,
          payload: result.data?.map((item) => ({ ...item, tab: 2 })),
        })
        yield put({
          type: typeAction.RESET_STORE_DRUGSTORE,
        })
        // yield put({
        //   type: typeAction.DISPATCH_DETAI_BN_NHA_THUOC,
        //   payload: [],
        // })
        // yield put({
        //   type: typeAction.DISPATCH_INFO_BN_NHA_THUOC,
        //   payload: null,
        // })
        // yield put({
        //   type: typeAction.DISPATCH_SERVICE_NHA_THUOC,
        //   payload: null,
        // })
      } catch (err) {
        console.log(err)
      }
    },
  )

  //------------------- LẤY DANH SÁCH PHIẾU THU BÁN VẮC XIN ---------//
  yield takeLatest(
    typeAction.GET_PHIEU_THU_VACXIN,
    function* getPhieuThuVacXinSaga({ type, filter }) {
      try {
        const { idcn, tuNgay, denNgay, keyword } = filter
        const result = yield call(() =>
          XuatVacXinService.getPhieuThuVacXin(idcn, tuNgay, denNgay, keyword),
        )
        yield put({
          type: typeAction.DISPATCH_PHIEU_THU_VACXIN,
          payload: result.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  yield takeLatest(
    typeAction.GET_BN_CHO_BS_KE_TOA_LAI_XVX,
    function* getBNChoBsKeToaLaiXVXSaga({ type, idChiNhanh }) {
      try {
        const result = yield call(() => XuatVacXinService.getBNChoBsKeToaLaiXVX(idChiNhanh))
        yield put({
          type: typeAction.DISPATCH_BN_LAY_THUOC_XVX,
          payload: result.data?.map((item) => ({ ...item, tab: 3 })),
        })
        yield put({
          type: typeAction.RESET_STORE_DRUGSTORE,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  // //------------------- LẤY DANH SÁCH PHIẾU THU ---------//
  // yield takeLatest(typeAction.GET_PHIEU_THU_THUOC, function* PTThuoc({ type, filter }) {
  //   try {
  //     const { idcn, tuNgay, denNgay } = filter
  //     const result = yield call(() => drugstoreService.getPhieuThu(idcn, tuNgay, denNgay))
  //     yield put({
  //       type: typeAction.DISPATCH_PHIEU_THU_THUOC,
  //       payload: result.data,
  //     })
  //   } catch (err) {
  //     console.log(err)
  //   }
  // })
  // //---------------- POST THUỐC BỆNH NHÂN -------------------//
  // yield takeLatest(
  //   typeAction.POST_BN_THUOC,
  //   function* AddThuoc({ type, fromPay, formPayDetail, data, idChiNhanh, resetFormData }) {
  //     try {
  //       const dataPK = yield select((state) => state.branchReducer)
  //       const { PkDangNhap } = dataPK
  //       yield put({
  //         type: typeAction.OPEN_LOADING_PAGE,
  //       })
  //       const drug = yield call(() => drugstoreService.updateThuoc(data))
  //       // -------- update lại trạng thái BN thông tin chung -----------//
  //       const bnttc = yield call(() =>
  //         childrenClinicService.updateDetailBN({
  //           idbnttc: formPayDetail.idbnttchung,
  //           trangthaikhambenh: 4, // trại thái đã hoàn thành khám bệnh
  //         }),
  //       )
  //       const result = yield call(() => recieveService.postBNPay(fromPay))
  //       formPayDetail.idbntt = result.data.idbntt
  //       yield call(() => recieveService.postDetailPay(formPayDetail))
  //       yield put(BNchoThuoc(idChiNhanh))
  //       yield put({
  //         type: typeAction.RESET_STORE_DRUGSTORE,
  //       })
  //       resetFormData()
  //       yield put({
  //         type: typeAction.CLOSE_LOADING_PAGE,
  //       })
  //       yield call(() =>
  //         generatePhieuThuTienToaThuoc(result.data, bnttc.data, drug.data, PkDangNhap),
  //       )
  //       Toast.fire({
  //         icon: 'success',
  //         title: 'Thanh toán thuốc thành công ',
  //       })
  //     } catch (err) {
  //       yield put({
  //         type: typeAction.CLOSE_LOADING_PAGE,
  //       })
  //       Toast.fire({
  //         icon: 'error',
  //         title: 'Thanh toán thuốc thất bại',
  //       })
  //       console.log(err)
  //     }
  //   },
  // )
  // // LẤY DANH SÁCH CHI TIẾT BN NHÀ THUỐC
  // yield takeLatest(typeAction.GET_DETAIL_BN_NHA_THUOC, function* BNDone({ type, idbnttc }) {
  //   try {
  //     yield put({
  //       type: typeAction.OPEN_LOADING_PAGE,
  //     })
  //     const detailBN = yield call(() => childrenClinicService.getDetailBNKham(idbnttc))
  //     yield put({
  //       type: typeAction.DISPATCH_DETAI_BN_NHA_THUOC,
  //       payload: detailBN.data,
  //     })
  //     const infoBN = yield call(() => recieveService.getInfoBN(detailBN.data.idbn))
  //     yield put({
  //       type: typeAction.DISPATCH_INFO_BN_NHA_THUOC,
  //       payload: infoBN.data,
  //     })
  //     const service = yield call(() => drugstoreService.getServiceNhaThuoc(idbnttc))
  //     yield put({
  //       type: typeAction.DISPATCH_SERVICE_NHA_THUOC,
  //       payload: service.data,
  //     })
  //     yield put({
  //       type: typeAction.CLOSE_LOADING_PAGE,
  //     })
  //   } catch (err) {
  //     console.log(err)
  //     yield put({
  //       type: typeAction.CLOSE_LOADING_PAGE,
  //     })
  //   }
  // })
  // //-------------- POST BÁN LẺ --------------
  // yield takeLatest(
  //   typeAction.POST_BN_BAN_LE,
  //   function* addBNBanLe({ type, benhNhanTT, detailPay, thuocBN, dataBNTTC, resetStore }) {
  //     try {
  //       const dataPK = yield select((state) => state.branchReducer)
  //       const { PkDangNhap } = dataPK
  //       yield put({
  //         type: typeAction.OPEN_LOADING_PAGE,
  //       })

  //       // console.log(benhNhanTT, detailPay, thuocBN, dataBNTTC);
  //       const BenhNhanTTC = yield call(() => drugstoreService.postBNBanLe(dataBNTTC))
  //       const data = []
  //       for (const items of thuocBN) {
  //         data.push({
  //           idbnttc:  BenhNhanTTC.data.idbnttc,
  //           idthuoc: items.idThuoc,
  //           soLuong: Number(items.SL),
  //           donGia: items.thuocVatTu.giaBan,
  //           thanhTien:  Number(items.SL) * items.thuocVatTu.giaBan,
  //           tongTien:  Number(items.SL) * items.thuocVatTu.giaBan,
  //           benhNhanLayTHuoc: 1,
  //           duocSi: benhNhanTT.thuNgan,
  //           idkhocn: items.idKhoCN,
  //           soLo: items.soLo,
  //           hanDung: items.hanDung,
  //           idLoaiToa: 6, // loại bán lẻ,
  //           soHoaDon: items.soHoaDon,
  //         })
  //       }
  //       // console.log(data);
  //       const dataPT = yield call(() => drugstoreService.postPhieuThuBanLe(data))
  //       benhNhanTT.idbnttc = BenhNhanTTC.data.idbnttc
  //       const result = yield call(() => recieveService.postBNPay(benhNhanTT))
  //       detailPay.idbntt = result.data.idbntt
  //       yield call(() => recieveService.postDetailPay(detailPay))
  //       yield call(() => generatePhieuBanLeToaThuoc(BenhNhanTTC.data, dataPT.data, PkDangNhap, result.data))
  //       yield put({
  //         type: typeAction.CLOSE_LOADING_PAGE,
  //       })

  //       resetStore()
  //       Toast.fire({
  //         icon: 'success',
  //         title: 'Thanh toán thuốc thành công ',
  //       })
  //     } catch (err) {
  //       yield put({
  //         type: typeAction.CLOSE_LOADING_PAGE,
  //       })
  //       Toast.fire({
  //         icon: 'error',
  //         title: 'Thanh toán thuốc thất bại',
  //       })
  //       console.log(err)
  //     }
  //   },
  // )
}

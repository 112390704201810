import { https } from '../apiService'

export const khoDieuChinhService = {
  getListVTHHbyIdKhoCN: (idKhoCN) => https.get(`TonKho/${idKhoCN}`),
  postPhieuDieuChinhKho: (formData) => https.post(`PhieuDieuChinh/AddPhieuDC`, formData),
  postListVTHH: (list) => https.post(`PhieuDieuChinhChiTiet/AddPhieuDieuChinhChiTietList`, list),
  getListPhieuXuat: (keyword, idct, idcn, tuNgay, denNgay) =>
    https.get(
      `PhieuDieuChinh/GetAllByCondition?${keyword?.length ? `keyword=${keyword}&` : ''}idct=${idct}&${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getInfoPhieuXuat: (id) => https.get(`PhieuDieuChinh/GetPhieuDieuChinhByID?IDNHAPXUAT=${id}`),
  getListVTHHDaXuat: (keyword, idct, idcn, tuNgay, denNgay) =>
    https.get(`PhieuDieuChinh/GetHangHoaPhieuDieuChinh`, {
      params: { keyword, idct, idcn, tuNgay, denNgay },
    }),
  getListVTHHCuaPhieuXuat: (id) =>
    https.get(`PhieuDieuChinhChiTiet/GetChiTietPhieu`, { params: { IDNHAPXUAT: id } }),
}

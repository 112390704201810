// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.style_input_align__DwZJb{
    width: 100%;
}
.style_input_align__DwZJb div:last-child input {
    text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/ThuMua/Modal/style.module.css"],"names":[],"mappings":";AACA;IACI,WAAW;AACf;AACA;IACI,iBAAiB;AACrB","sourcesContent":["\n.input_align{\n    width: 100%;\n}\n.input_align div:last-child input {\n    text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_align": `style_input_align__DwZJb`
};
export default ___CSS_LOADER_EXPORT___;

import { SearchOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  ConfigProvider,
  DatePicker,
  Divider,
  Drawer,
  Input,
  Radio,
  Select,
  Space,
  Table,
  Tabs,
} from 'antd'
import locale from 'antd/es/date-picker/locale/vi_VN'
import { Option } from 'antd/es/mentions'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import {
  getList_BN_BY_CHINHANH_CTY_Mat,
  updateInfoBN_Mat,
} from '../../../../store/actions/traCuuBnMatAction'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import HistoryKhamBenh from '../History/HistoryKhamBenh'
import 'moment/locale/vi'
moment.locale('vi')

const columnsThanhToan = [
  {
    title: 'Thời gian',
    dataIndex: 'ngayThanhToan',
    key: 'ngayThanhToan',
    align: 'center',
    className: 'text-sm',
    render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm') : ''),
  },
  {
    title: 'Loại thanh toán',
    dataIndex: 'tenLoaiTT',
    key: 'tenLoaiTT',
    align: 'left',
    className: 'text-sm',
  },
  {
    title: 'Phương thức thanh toán',
    dataIndex: 'tenPhuongThuc',
    key: 'tenPhuongThuc',
    align: 'left',
    className: 'text-sm',
  },
  {
    title: 'Tiền thanh toán',
    dataIndex: 'tienThanhToan',
    key: 'tienThanhToan',
    align: 'right',
    className: 'text-sm',
    render: (text) => formatNumberVND(text),
  },
]

const DrawerTT = ({ open, onClose, company, chiNhanh }) => {
  const { infoBN } = useSelector((state) => state.traCuuBnMatReducer)
  const { ngheNghiep, danToc, doiTuong, quocTich } = useSelector((state) => state.receiveReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [cityData, setCityData] = useState(null)
  const [district, setDistrict] = useState(null)
  const [wardsData, setWardsData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [activeKey, setActiveKey] = useState(1)
  const [activeTab, setActiveTab] = useState(1)
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [historyThe, setHistoryThe] = useState([])

  const handleClose = () => {
    onClose()
    setActiveKey(1)
  }

  const getListHistoryThe = async () => {
    try {
      const { data } = await https.get(
        `TheThanhVien_PhieuThu/GetTraCuuTheThanhVienPhieuThu?idbn=${infoBN?.benhNhan.idbn}`,
      )
      setHistoryThe(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (infoBN?.benhNhan?.idbn) {
      getListHistoryThe()
    }
  }, [infoBN])

  const calculateAge = (date) => {
    const birthDate = new Date(date)
    const today = new Date()
    // Calculate the age in years
    let ageYears = today.getFullYear() - birthDate.getFullYear()
    let ageMonths = today.getMonth() - birthDate.getMonth()
    let ageDays = today.getDate() - birthDate.getDate()

    // Adjust for negative months and days
    if (ageDays < 0) {
      ageMonths--
      ageDays += new Date(today.getFullYear(), today.getMonth(), 0).getDate()
    }
    if (ageMonths < 0) {
      ageYears--
      ageMonths += 12
    }
    return {
      ageYears,
      ageMonths,
      ageDays,
    }
  }

  const ageBN = useMemo(() => {
    return calculateAge(infoBN?.benhNhan?.ngaySinh)
  }, [infoBN?.benhNhan?.ngaySinh])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tenBenhNhan: infoBN?.benhNhan?.tenBenhNhan,
      gioiTinh: infoBN?.benhNhan?.gioiTinh,
      ngaySinh: infoBN?.benhNhan?.ngaySinh,
      tuoi: `${ageBN.ageYears} tuổi, ${ageBN.ageMonths} tháng, ${ageBN.ageDays} ngày`,
      diaChi: infoBN?.benhNhan?.diaChi,
      idTinh: infoBN?.benhNhan?.idTinh, // mặc định 46 là thành phố HCM
      idQuan: infoBN?.benhNhan?.idQuan,
      idPhuong: infoBN?.benhNhan?.idPhuong,
      dienThoai: infoBN?.benhNhan?.dienThoai,
      cccd: infoBN?.benhNhan?.cccd,
      ngayCap: infoBN?.benhNhan?.ngayCap,
      idDoiTuong: infoBN?.benhNhan?.idDoiTuong, // mặc định 1 là đối tượng vãng lai
      idDanToc: infoBN?.benhNhan?.idDanToc, // mặc định 1 là dân tộc kinh
      idNgheNghiep: infoBN?.benhNhan?.idNgheNghiep,
      idQuocTich: infoBN?.benhNhan?.idQuocTich, // mắc định 1 là nước VN
      maTCQG: infoBN?.benhNhan?.maTCQG,
      hoaDon: infoBN?.benhNhan?.hoaDon,
      ghiChuHoaDon: infoBN?.benhNhan?.ghiChuHoaDon,
    },
    onSubmit: (value, action) => handleSubmit(value, action),
  })

  const onLoad = () => {
    dispatch(getList_BN_BY_CHINHANH_CTY_Mat(company, chiNhanh, '', 1, setLoading))
  }

  const handleSubmit = (values, action) => {
    dispatch(
      updateInfoBN_Mat(
        {
          idbn: infoBN?.benhNhan.idbn,
          ...values,
        },
        onLoad,
        PkDangNhap?.idChuyenKhoa,
        setLoading,
      ),
    )
  }
  const handleRadio = (e) => {
    const { value } = e.target
    formik.setFieldValue('gioiTinh', value)
  }

  const handleSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }

  async function getDataDistrict(idQuan) {
    try {
      const result = await https.get(`QuanHuyen/${idQuan}`)
      setDistrict(result.data)
    } catch (err) {
      console.error(err)
    }
  }

  async function getDataWards(idPhuong) {
    try {
      const result = await https.get(`PhuongXa/${idPhuong}`)
      setWardsData(result.data)
    } catch (err) {
      console.error(err)
    }
  }

  async function getDataCity() {
    try {
      const result = await https.get(`tinh`)
      setCityData(result.data)
    } catch (err) {
      console.error(err)
    }
  }

  const handleCityChange = (value) => {
    getDataDistrict(value)
    formik.setFieldValue('idTinh', value)
  }

  const handleDistrictChange = (value) => {
    getDataWards(value)
    formik.setFieldValue('idQuan', value)
  }

  const handleWardChange = (value) => {
    formik.setFieldValue('idPhuong', value)
  }

  useEffect(() => {
    getDataCity()
  }, [])

  useEffect(() => {
    if (infoBN?.benhNhan?.idTinh) getDataDistrict(infoBN?.benhNhan?.idTinh)
  }, [infoBN?.benhNhan?.idTinh])

  useEffect(() => {
    if (infoBN?.benhNhan?.idQuan) getDataWards(infoBN?.benhNhan?.idQuan)
  }, [infoBN?.benhNhan?.idQuan])

  const onChange = (key) => {
    setActiveKey(key)
  }

  const handleCalculateAge = (date, dateString) => {
    // Cập nhật trường tuổi
    const result = calculateAge(date)
    formik.setFieldValue(
      'tuoi',
      `${result.ageYears} tuổi, ${result.ageMonths} tháng, ${result.ageDays} ngày`,
    )
    formik.setFieldValue('ngaySinh', date.format('YYYY-MM-DD'))
  }

  const handleChangeHoaDon = (e) => {
    formik.setFieldValue('hoaDon', e.target.checked ? 1 : 0)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columnsLichSuThe = [
    {
      title: 'Số phiếu',
      dataIndex: 'maphieuthu',
      key: 'maphieuthu',
      className: 'text-sm',
      width: 150,
      fixed: 'left',
      ...getColumnSearchProps('maphieuthu'),
    },
    {
      title: 'Mã thẻ TV',
      dataIndex: 'mathe',
      key: 'mathe',
      className: 'text-sm',
      width: 150,
      fixed: 'left',
      ...getColumnSearchProps('mathe'),
    },
    {
      title: 'Loại thẻ',
      dataIndex: 'loaithe',
      key: 'loaithe',
      className: 'text-sm',
      width: 200,
      ...getColumnSearchProps('loaithe'),
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      className: 'text-sm',
      width: 250,
    },
    {
      title: 'Ngày lập',
      dataIndex: 'ngaylap',
      key: 'ngaylap',
      className: 'text-sm',
      align: 'center',
      width: 150,
      render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm') : ''),
    },
    {
      title: 'Ngày hiệu lực',
      dataIndex: 'ngayhieuluc',
      key: 'ngayhieuluc',
      className: 'text-sm',
      align: 'center',
      width: 150,
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : ''),
    },
    {
      title: 'Ngày kết thúc',
      dataIndex: 'ngayketthuc',
      key: 'ngayketthuc',
      align: 'center',
      className: 'text-sm',
      width: 150,
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : ''),
    },
    {
      title: 'Số tháng',
      dataIndex: 'sothang',
      key: 'sothang',
      className: 'text-sm',
      align: 'center',
      width: 80,
    },
    {
      title: 'Thành tiền',
      dataIndex: 'tiensudung',
      key: 'tiensudung',
      className: 'text-sm',
      align: 'right',
      width: 150,
      render: (text) => (text ? formatNumberVND(text) : 0),
    },
    {
      title: '% giảm giá',
      dataIndex: 'ptgiamgia',
      key: 'ptgiamgia',
      className: 'text-sm',
      align: 'center',
      width: 100,
    },
    {
      title: 'Tiền giảm',
      dataIndex: 'tiengiamgia',
      key: 'tiengiamgia',
      className: 'text-sm',
      align: 'right',
      width: 150,
      render: (text) => (text ? formatNumberVND(text) : 0),
    },
    {
      title: 'Thanh toán',
      dataIndex: 'tienthanhtoan',
      key: 'tienthanhtoan',
      className: 'text-sm',
      align: 'right',
      width: 150,
      render: (text) => (text ? formatNumberVND(text) : 0),
    },
    {
      title: 'Phương thức',
      dataIndex: 'phuongthuc',
      key: 'phuongthuc',
      className: 'text-sm',
      width: 250,
    },
    // {
    //   title: 'Hình thức',
    //   dataIndex: 'tenLoaiTT',
    //   key: 'tenLoaiTT',
    //   className: 'text-sm',
    //   width: 200,
    // },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      className: 'text-sm',
      width: 250,
    },
    {
      title: 'Hiệu lực',
      dataIndex: 'hieuluc',
      key: 'hieuluc',
      className: 'text-sm',
      align: 'center',
      width: 100,
      render: (text, record) => <Checkbox checked={record.hieuluc} />,
      fixed: 'right',
    },
  ]

  return (
    <Drawer
      title={`Thông tin bệnh nhân - ${infoBN?.benhNhan?.tenBenhNhan} - Mã BN: ${infoBN?.benhNhan?.maBenhNhan}`}
      width={'80%'}
      placement="right"
      size="large"
      onClose={handleClose}
      open={open}
      loading={loading}
    >
      <div>
        <Tabs
          onChange={onChange}
          activeKey={activeKey}
          type="card"
          items={[
            {
              label: 'Thông tin',
              key: 1,
              children: (
                <div>
                  <h2 className="text-gray-500 font-semibold text-lg mb-2 ">Thông tin bệnh nhân</h2>
                  <form className="text-start">
                    <div className="flex flex-col gap-5">
                      <div className="flex">
                        <div className="flex w-1/2 items-center ">
                          <label className="font-semibold w-28 ">Tên BN :</label>
                          <Input
                            size="small"
                            value={formik.values.tenBenhNhan}
                            name="tenBenhNhan"
                            onChange={formik.handleChange}
                            className="w-full"
                          />
                        </div>
                        <div className="flex w-1/2 items-center ml-4">
                          <label className="font-semibold w-28">Mã BN :</label>
                          <Input
                            size="small"
                            value={infoBN?.benhNhan?.maBenhNhan}
                            name="maBenhNhan"
                            readOnly
                            className="w-full"
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex w-1/2 items-center ">
                          <label className="font-semibold w-28">Ngày sinh :</label>
                          <DatePicker
                            allowClear={false}
                            maxDate={dayjs(new Date())}
                            locale={locale}
                            className="w-full"
                            value={
                              formik.values.ngaySinh !== ''
                                ? dayjs(formik.values.ngaySinh, 'YYYY-MM-DD')
                                : ''
                            }
                            format={'DD-MM-YYYY'}
                            onChange={handleCalculateAge}
                            size="small"
                          />
                        </div>
                        <div className="flex w-1/2 items-center ml-4">
                          <label className="font-semibold w-28">Giới tính :</label>
                          <Radio.Group
                            onChange={handleRadio}
                            className="w-full text-center"
                            options={[
                              {
                                label: 'Nam',
                                value: 'Nam',
                              },
                              {
                                label: 'Nữ',
                                value: 'Nữ',
                              },
                              {
                                label: 'Khác',
                                value: 'Khác',
                              },
                            ]}
                            // onChange={onChange1}
                            value={formik.values.gioiTinh}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex w-1/2 items-center ">
                          <label className="font-semibold w-28">Đối tượng :</label>
                          <Select
                            value={formik.values.idDoiTuong}
                            options={doiTuong?.map((items) => ({
                              label: items.tenDoiTuong,
                              value: items.idDoiTuong,
                            }))}
                            onChange={handleSelected('idDoiTuong')}
                            className="w-full"
                            size="small"
                          />
                        </div>
                        <div className="flex w-1/2 items-center ml-4">
                          <label className="font-semibold w-28">Nghề nghiệp:</label>
                          <Select
                            options={ngheNghiep?.map((items) => ({
                              value: items.idNN,
                              label: items.tenNN,
                            }))}
                            value={formik.values.idNgheNghiep}
                            onChange={handleSelected('idNgheNghiep')}
                            className="w-full"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex w-1/2 items-center ">
                          <label className="font-semibold w-28">Dân tộc :</label>
                          <Select
                            options={danToc?.map((items) => ({
                              label: items.tenDanToc,
                              value: items.idDanToc,
                            }))}
                            onChange={handleSelected('idDanToc')}
                            value={formik.values.idDanToc}
                            className="w-full"
                            size="small"
                          />
                        </div>
                        <div className="flex w-1/2 items-center ml-4">
                          <label className="font-semibold w-28">Quốc tịch :</label>
                          <Select
                            value={formik.values.idQuocTich}
                            options={quocTich?.map((items) => ({
                              label: items.tenQuocTich,
                              value: items.idQuocTich,
                            }))}
                            onChange={handleSelected('idQuocTich')}
                            className="w-full"
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex w-1/2 items-center ">
                          <label className="font-semibold w-28">Tuổi :</label>
                          <p className="w-full text-center font-semibold text-red-500">
                            {formik.values.tuoi}
                          </p>
                        </div>
                        <div className="flex w-1/2 items-center ml-4">
                          <label className="font-semibold w-28">Điện thoại:</label>
                          <Input
                            name="dienThoai"
                            value={formik.values.dienThoai}
                            onChange={formik.handleChange}
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex w-1/2 items-center ">
                          <label className="font-semibold w-28">CCCD :</label>
                          <Input
                            name="cccd"
                            value={formik.values.cccd}
                            onChange={formik.handleChange}
                            size="small"
                          />
                        </div>
                        <div className="flex w-1/2 items-center ml-4">
                          <label className="font-semibold w-28">Ngày cấp:</label>
                          <DatePicker
                            allowClear={false}
                            maxDate={dayjs(new Date())}
                            className="w-full"
                            value={formik.values.ngayCap ? dayjs(formik.values.ngayCap) : ''}
                            format={'DD-MM-YYYY'}
                            onChange={(e) => formik.setFieldValue('ngayCap', e)}
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex w-1/2 items-center ">
                          <label className="font-semibold w-28">Mã TCQG:</label>
                          <Input
                            name="maTCQG"
                            value={formik.values.maTCQG}
                            onChange={formik.handleChange}
                            size="small"
                          />
                        </div>
                        <div className="flex w-1/2 items-center ml-4">
                          <label className="font-semibold w-28">Tỉnh/TP:</label>
                          <Select
                            onChange={handleCityChange}
                            className="w-full truncate"
                            placeholder="Chọn Tỉnh / Thành phố"
                            size="small"
                            name="idTinh"
                            value={formik.values.idTinh}
                          >
                            {cityData?.map((tinh) => (
                              <Option key={tinh.idTinh} value={tinh.idTinh}>
                                {tinh.tenTinh}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex w-1/2 items-center ">
                          <label className="font-semibold w-28">Q.Huyện:</label>
                          <Select
                            status={formik.errors.idQuan ? 'error' : ''}
                            onChange={handleDistrictChange}
                            options={district?.map((quan) => ({
                              label: quan.tenQuan,
                              value: quan.idQuan,
                            }))}
                            className="w-full truncate"
                            placeholder="Chọn Quận / Huyện"
                            size="small"
                            name="idQuan"
                            value={formik.values.idQuan} // Set the value here
                          >
                            {district?.map((quan) => (
                              <Option key={quan.idQuan} value={quan.idQuan}>
                                {quan.tenQuan}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <div className="flex w-1/2 items-center ml-4">
                          <label className="font-semibold w-28">X.Phường:</label>
                          <Select
                            status={formik.errors.idPhuong ? 'error' : ''}
                            onChange={handleWardChange}
                            options={wardsData?.map((phuong) => ({
                              label: phuong.tenPhuong,
                              value: phuong.idPhuong,
                            }))}
                            className="w-full truncate"
                            placeholder="Chọn Phường / Xã"
                            size="small"
                            name="idPhuong"
                            value={formik.values.idPhuong} // Set the value here
                          >
                            {wardsData?.map((phuong) => (
                              <Option key={phuong.idPhuong} value={phuong.idPhuong}>
                                {phuong.tenPhuong}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>

                      <div className="flex">
                        <label className="font-semibold w-[6.5rem]">Địa chỉ: </label>
                        <Input
                          size="small"
                          name="diaChi"
                          value={formik.values.diaChi}
                          onChange={formik.handleChange}
                          className="w-full"
                        />
                      </div>
                      <div className="flex">
                        <label className="font-semibold w-[6.5rem]">Nội dung XHĐ: </label>
                        <div className="w-full flex gap-4 ml-4">
                          <Checkbox checked={formik.values.hoaDon} onChange={handleChangeHoaDon} />
                          <Input
                            size="small"
                            name="ghiChuHoaDon"
                            value={formik.values.ghiChuHoaDon}
                            onChange={formik.handleChange}
                            className="w-full"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 flex justify-end">
                      <Button onClick={formik.handleSubmit} className="mr-2" type="primary">
                        Lưu
                      </Button>
                    </div>
                  </form>

                  <Divider />
                  <h2 className="text-gray-500 font-semibold text-lg mb-2 ">Lịch sử thanh toán</h2>
                  <div className="flex flex-col gap-5">
                    <ConfigProvider
                      theme={{
                        token: {
                          padding: 5,
                        },
                        components: {
                          Table: {
                            rowHoverBg: '#ecf0f1',
                            headerBg: '#e6e6e6',
                            footerBg: '#e6e6e6',
                            borderColor: '#BABABA',
                          },
                        },
                      }}
                    >
                      <Table
                        scroll={{
                          y: 200,
                        }}
                        bordered
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: false,
                        }}
                        columns={columnsThanhToan}
                        dataSource={infoBN?.benhNhanThanhToans}
                      />
                    </ConfigProvider>
                  </div>

                  <Divider />
                  <Tabs
                    activeKey={activeTab}
                    onChange={(value) => {
                      setActiveTab(value)
                    }}
                    items={[
                      {
                        label: 'Thông tin thẻ',
                        key: 1,
                        children: (
                          <div className="flex flex-col gap-5">
                            <div className="flex">
                              <div className="flex w-1/2 items-center ">
                                <label className="font-semibold w-28 ">Tên thẻ :</label>
                                <p>{infoBN?.memberCard?.tenthe}</p>
                              </div>
                              <div className="flex w-1/2 items-center ">
                                <label className="font-semibold w-28">Mã thẻ :</label>
                                <p>{infoBN?.memberCard?.mathe}</p>
                              </div>
                            </div>
                            <div className="flex">
                              <div className="flex w-1/2 items-center ">
                                <label className="font-semibold w-28">Loại thẻ:</label>
                                <p>{infoBN?.memberCard?.tenloaithe}</p>
                              </div>
                              <div className="flex w-1/2 items-center ">
                                <label className="font-semibold w-28">Thời hạn:</label>
                                <p>
                                  <span className="font-bold">
                                    {infoBN?.memberCard?.tungay
                                      ? ` Từ ${moment(infoBN?.memberCard?.tungay).format('DD/MM/YYYY')}`
                                      : ''}
                                  </span>{' '}
                                  <span className="font-bold">
                                    {infoBN?.memberCard?.denngay
                                      ? ` Đến ${moment(infoBN?.memberCard?.denngay).format('DD/MM/YYYY')}`
                                      : ''}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        ),
                      },
                      {
                        label: 'Lịch sử thẻ',
                        key: 2,
                        children: (
                          <div className="flex flex-col gap-5">
                            <ConfigProvider
                              theme={{
                                token: {
                                  padding: 5,
                                },
                                components: {
                                  Table: {
                                    rowHoverBg: '#ecf0f1',
                                  },
                                },
                              }}
                            >
                              <Table
                                scroll={{
                                  x: 2500,
                                  y: 200,
                                }}
                                bordered
                                pagination={false}
                                columns={columnsLichSuThe}
                                dataSource={historyThe}
                              />
                            </ConfigProvider>
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
              ),
            },
            {
              label: 'Lịch sử khám bệnh',
              key: 2,
              children: <HistoryKhamBenh open={open} />,
            },
          ]}
        />
      </div>
    </Drawer>
  )
}

export default React.memo(DrawerTT)

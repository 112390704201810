import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Input, Select } from 'antd'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { useDispatch, useSelector } from 'react-redux'
import { sieuAmService } from '../../../../services/SieuAm/sieuAmService'
import { https } from '../../../../services/apiService'
import { infoBNSieuAm as getInfoBNSieuAm } from '../../../../store/actions/sieuAmAction'
import ToastCus from '../../../../utils/Toast'
import { isWithin24Hours } from '../../../../utils/algorithm'
import { generatePhieuSieuAm } from '../../../../utils/report/san/phieuSieuAm'
import BieuMau from './BieuMau'
import style from './style.module.css'
import { generatePhieuSieuAmMultiImage } from '../../../../utils/report/san/phieuSieuAmForMultiImageOnly'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'

const Template = ({ formik, editorState, setEditorState, listImagePrint }) => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { AllBieuMau } = useSelector((state) => state.sieuAmReducer)
  const { infoBNSieuAm } = useSelector((state) => state.sieuAmReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [open, setOpen] = useState(false)
  const [isLoading, setisLoading] = useState(false)

  const onEditorStateChange = (newState) => {
    setEditorState(newState)

    // const contentState = newState.getCurrentContent()
    // const htmlContent = stateToHTML(contentState)
    // const row = convertToRaw(contentState)
  }
  //--------- INFO LÂY BIỂU ------------//
  const onClickBieuMau = async (value) => {
    const { data } = await sieuAmService.getInfoBieuMau(value)
    const convertJson = JSON.parse(data.mota)
    formik.setFieldValue('KETLUAN', data.ketluan)
    formik.setFieldValue('tieude', data.tieude)
    const contentState = convertFromRaw(convertJson)
    setEditorState(EditorState.createWithContent(contentState))
  }
  const cancelModal = () => {
    setOpen(false)
  }

  const handlePrint = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT563',
      )

      listImagePrint.length >= 3
        ? generatePhieuSieuAmMultiImage(infoBNSieuAm, listImagePrint, PkDangNhap)
        : generatePhieuSieuAm(infoBNSieuAm, listImagePrint, PkDangNhap)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  const handleUpdate = async () => {
    const contentState = editorState.getCurrentContent()
    const row = convertToRaw(contentState)
    const data = JSON.stringify(row)
    // console.log(data)
    const dataUpdate = {
      idbnttc: infoBNSieuAm?.idbnttc,
      idbncdha: infoBNSieuAm?.idbncdha,
      bschidinh: infoBNSieuAm?.bschidinh,
      bssieuam: infoBNSieuAm?.bssieuam,
      chandoansobo: infoBNSieuAm?.chandoansobo,
      loaisieuam: infoBNSieuAm?.loaisieuam,
      para: formik.values.PARA,
      ketluan: formik.values.KETLUAN,
      bieumau: data,
      tieude: formik.values.tieude,
      nguoisua: infoUser?.idnv,
    }
    try {
      setisLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT564',
      )
      await https.put('BenhNhanSieuAm/UpdateBenhNhanSieuAm', dataUpdate)
      dispatch(getInfoBNSieuAm(infoBNSieuAm.idbnttc, infoBNSieuAm.idbncdha))
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setisLoading(true)
    }
  }

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <div className="p-2 border-b flex justify-between items-center">
          <div>
            <Button
              type="primary"
              disabled={infoBNSieuAm?.idttsieuam === 1 || !infoBNSieuAm}
              onClick={formik.handleSubmit}
            >
              Lưu kết quả
            </Button>
            {/* <Button type="link" icon={<PrinterOutlined style={{ fontSize: 18 }} />}>
              Phiếu
            </Button> */}
            {infoBNSieuAm &&
              infoBNSieuAm?.idttsieuam === 1 &&
              infoBNSieuAm?.nguoisua === infoUser?.idnv && // người tạo lần đầu tiên (có id người sửa, không có ngày sửa) để cập nhật dựa trên đúng idnv mới dc cập nhật
              isWithin24Hours(infoBNSieuAm?.ngaysieuam) && (
                <Button loading={isLoading} type="primary" className="ml-4" onClick={handleUpdate}>
                  Cập nhật
                </Button>
              )}
            {infoBNSieuAm?.idttsieuam === 1 && infoBNSieuAm && (
              <Button type="primary" className="ml-4" onClick={handlePrint}>
                In
              </Button>
            )}
          </div>
          <div className="flex items-center gap-1">
            <label className="font-semibold">Biểu mẫu: </label>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onChange={onClickBieuMau}
              className="w-72"
              options={AllBieuMau?.map((item) => ({
                label: item.tenbieumau,
                value: item.idbm,
              }))}
            />
            {/* {infoUser?.tenNhom === 'Quản trị hệ thống' && (
              <PlusCircleOutlined
                onClick={() => setOpen(true)}
                className="cursor-pointer text-blue-500"
                style={{ fontSize: 20 }}
              />
            )} */}
          </div>
        </div>
        <div>
          <Editor
            toolbar={{
              options: ['inline', 'blockType', 'fontFamily', 'textAlign', 'colorPicker'],
              inline: {
                options: ['bold', 'italic'],
              },
              blockType: {
                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
              },
              fontFamily: {
                options: ['Times New Roman'],
              },
            }}
            editorState={editorState}
            toolbarClassName={style.toolbarClassName}
            wrapperClassName={style.wrapperClassName}
            editorClassName={style.editorClassName}
            onEditorStateChange={onEditorStateChange}
          />
        </div>
      </div>

      <div className="p-2 border-t">
        <h2 className="font-semibold">KẾT LUẬN</h2>
        <Input.TextArea
          style={{
            height: 120,
            resize: 'none',
            fontFamily: 'Time New Roman',
          }}
          className="font-bold text-[1rem]"
          name="KETLUAN"
          onChange={formik.handleChange}
          value={formik.values.KETLUAN}
        />
      </div>
      {/* <BieuMau cancelModal={cancelModal} open={open} /> */}
    </div>
  )
}

export default Template

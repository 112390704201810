import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import {
  Button,
  ConfigProvider,
  Input,
  InputNumber,
  Table,
  Tooltip,
  Modal,
  Select,
  Spin,
} from 'antd'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { tiemChungService } from '../../../../services/tiemChung/tiemChungService'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import * as typeAction from '../../../../store/constants/constants'
import { SearchOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import _, { cloneDeep } from 'lodash'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import moment from 'moment'
import ToastCus from '../../../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal
function MuiTiem() {
  const dispatch = useDispatch()
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [isLoading, setIsLoading] = useState(false)
  const [listNhomVacXin, setListNhomVacXin] = useState([])
  const [listVacXin, setListVacXin] = useState([])
  const [listCanhBaoMuiTiem, setListCanhBaoMuiTiem] = useState([])
  const [nhomVX, setNhomVX] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [isModal, setIsModal] = useState(false)
  const [vacXinEdit, setVacXinEdit] = useState(null)
  const [isLoadingAction, setisLoadingAction] = useState(false)

  //############# FORM ############
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // idcbmt: 0,
      idthuoc: null,
      mathuoc: '',
      tenthuoc: '',
      tenhoatchat: '',
      dvt: '',
      ngaycachnhau: 0,
      nhasanxuat: '',
      masodangky: '',
      ghichu: '',
      tennhomthuoc: '', //////////////////////
      // nguoitao: 0,
      // nguoisua: 0,
    },
    onSubmit: (value) => handleSubmit(value),
    // validationSchema: receiveSchema,
  })
  const maQuyenXemDs = 'QHT574'
  const maQuyenXemCt = 'QHT575'
  const maQuyenCapNhat = 'QHT576'
  const maQuyenThem = 'QHT573'
  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        getListCanhBaoMuiTiem()
        break
      case maQuyenXemCt:
        setIsModal(true)
        setVacXinEdit(infoNew)
        break
      case maQuyenCapNhat:
        handleSubmitEdit()
        break
      case maQuyenThem:
        formik.handleSubmit()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        setListCanhBaoMuiTiem([])
        break
      case maQuyenXemCt:
        setIsModal(false)
        setVacXinEdit(null)
        break
      case maQuyenCapNhat:
        break
      case maQuyenThem:
        break
      default:
        return false
    }
  }
  const handleSubmit = async (value) => {
    if (!value?.idthuoc) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn vắc xin cần cảnh báo!',
      })
      return
    }
    try {
      setisLoadingAction(true)
      await tiemChungService.postInfoCanhBaoMuiTiem({ ...value, nguoitao: infoUser?.idnv })
      formik.resetForm()
      onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    } finally {
      setisLoadingAction(false)
      cancelModal()
    }
  }
  const handleSubmitEdit = async () => {
    if (!formik.values || !formik.values?.idcbmt || !formik.values?.idthuoc) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn lại vắc xin cần cảnh báo!',
      })
      return
    }
    try {
      setisLoadingAction(true)
      await tiemChungService.putInfoCanhBaoMuiTiem({ ...formik.values, nguoisua: infoUser?.idnv })
      formik.resetForm()
      onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    } finally {
      setisLoadingAction(false)
      cancelModal()
    }
  }
  const handleVacXin = (value, opt) => {
    formik.setValues((prev) => ({
      ...prev,
      idthuoc: opt?.itemVX?.idThuoc,
      mathuoc: opt?.maThuoc,
      tenthuoc: opt?.tenBietDuoc,
      tenhoatchat: opt?.label,
      dvt: opt?.itemVX?.dvt,
      nhasanxuat: opt?.itemVX?.nhaSanXuat,
      masodangky: opt?.soDangKy,
      tennhomthuoc: opt?.itemVX?.nhom,
    }))
  }
  const cancelModal = () => {
    if (!isLoadingAction) {
      setIsModal(false)
      setVacXinEdit(null)
    }
  }
  const getListCanhBaoMuiTiem = async () => {
    try {
      setIsLoading(true)
      const { data } = await tiemChungService.getListCanhBaoMuiTiem(
        keyword?.trim()?.length > 0 ? keyword?.trim() : null,
        nhomVX,
      ) //loại vắc xin = 8
      setListCanhBaoMuiTiem(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getListNhomVacXin = async () => {
    try {
      setIsLoading(true)
      const { data } = await khoVTservice.getListGroupsThuocVT(8) //loại vắc xin = 8
      setListNhomVacXin(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getListVacXin = async (kw) => {
    try {
      const { data } = await khoVTservice.SearchThuocCanhBao(kw, ID_COMPANY, 8, null)
      setListVacXin(data)
      // keyword,
      // idCT,
      // idPhanLoai,
      // idNhom,
      // pageIndex, //loại vắc xin = 8
    } catch (error) {
      console.log(error)
    }
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        getListVacXin(keyword?.trim()?.replaceAll('  ', ' '))
      }
    }, 300),
    [],
  )
  const onLoad = () => {
    // getListCanhBaoMuiTiem()
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  useEffect(() => {
    if (vacXinEdit) {
      formik.setValues((prev) => ({
        ...prev,
        idcbmt: vacXinEdit?.idcbmt,
        idthuoc: vacXinEdit?.idthuoc,
        mathuoc: vacXinEdit?.mathuoc,
        tenthuoc: vacXinEdit?.tenthuoc,
        tenhoatchat: vacXinEdit?.tenhoatchat,
        dvt: vacXinEdit?.dvt,

        ngaycachnhau: vacXinEdit?.ngaycachnhau,

        nhasanxuat: vacXinEdit?.nhasanxuat,
        masodangky: vacXinEdit?.masodangky,
        ghichu: vacXinEdit?.ghichu,
        tennhomthuoc: vacXinEdit?.nhom,
      }))
    } else formik.resetForm()
  }, [vacXinEdit])
  useEffect(() => {
    getListNhomVacXin()
    onLoad()
  }, [])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 45,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã thuốc',
      dataIndex: 'mathuoc',
      key: 'mathuoc',
      width: 120,
      fixed: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.mathuoc ?? '').localeCompare(b?.mathuoc ?? '')
        },
      },
    },
    {
      title: 'Tên thuốc',
      dataIndex: 'tenthuoc',
      key: 'tenthuoc',
      width: 350,
      fixed: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.tenthuoc ?? '').localeCompare(b?.tenthuoc ?? '')
        },
      },
    },
    {
      title: 'Tên hoạt chất',
      dataIndex: 'tenhoatchat',
      key: 'tenhoatchat',
      width: 450,
      sorter: {
        compare: (a, b) => {
          return (a?.tenhoatchat ?? '').localeCompare(b?.tenhoatchat ?? '')
        },
      },
    },
    {
      title: 'ĐVT',
      dataIndex: 'dvt',
      key: 'dvt',
      align: 'center',
      width: 60,
      sorter: {
        compare: (a, b) => {
          return (a?.dvt ?? '').localeCompare(b?.dvt ?? '')
        },
      },
    },
    // {
    //   title: 'Nhóm',
    //   dataIndex: 'tieude',
    //   key: 'tieude',
    //   width: 150,
    // },
    {
      title: 'Nhà sản xuất',
      dataIndex: 'nhasanxuat',
      key: 'nhasanxuat',
      width: 270,
      sorter: {
        compare: (a, b) => {
          return (a?.nhasanxuat ?? '').localeCompare(b?.nhasanxuat ?? '')
        },
      },
    },
    {
      title: 'Số đăng ký',
      dataIndex: 'masodangky',
      key: 'masodangky',
      width: 170,
      sorter: {
        compare: (a, b) => {
          return (a?.masodangky ?? '').localeCompare(b?.masodangky ?? '')
        },
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'nguoitao',
      key: 'nguoitao',
      width: 220,
      sorter: {
        compare: (a, b) => {
          return (a?.nguoitao ?? '').localeCompare(b?.nguoitao ?? '')
        },
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngaytao',
      key: 'ngaytao',
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaytao ?? '').localeCompare(b?.ngaytao ?? '')
        },
      },
    },
    {
      title: 'Người sửa',
      dataIndex: 'nguoisua',
      key: 'nguoisua',
      width: 220,
      sorter: {
        compare: (a, b) => {
          return (a?.nguoisua ?? '').localeCompare(b?.nguoisua ?? '')
        },
      },
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'ngaysua',
      key: 'ngaysua',
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaysua ?? '').localeCompare(b?.ngaysua ?? '')
        },
      },
    },
    {
      title: 'Ngày cách nhau',
      dataIndex: 'ngaycachnhau',
      key: 'ngaycachnhau',
      width: 130,
      align: 'center',
      fixed: 'right',
      sorter: {
        compare: (a, b) => {
          return (a?.ngaycachnhau ?? 0) - (b?.ngaycachnhau ?? 0)
        },
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 45,
      align: 'center',
      fixed: 'right',
      render: (text, record, index) => (
        <ul className="flex justify-center items-center gap-2">
          <li className="flex justify-center items-center">
            <Tooltip title="Chỉnh sửa" color="green">
              <EditOutlined
                onClick={() => {
                  dispatch(
                    putCheckQuyenThaoTacAction(maQuyenXemCt, record, actionIsTrue, actionIsFalse),
                  )
                }}
                className="text-xl text-green-500  cursor-pointer"
              />
            </Tooltip>
          </li>
        </ul>
      ),
    },
  ]
  return (
    <div>
      <Modal
        width={800}
        open={isModal}
        onCancel={cancelModal}
        // okText={'Lưu'}
        // onOk={vacXinEdit ? handleSubmitEdit : formik.handleSubmit}
        // cancelText="Huỷ bỏ"
        title={<p className="text-center">{vacXinEdit ? 'Chỉnh sửa' : 'Thêm'} cảnh báo mũi tiêm</p>}
        className="flex flex-col gap-2"
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button onClick={cancelModal} loading={isLoadingAction}>
              Hủy bỏ
            </Button>
            <Button
              type="primary"
              // onClick={vacXinEdit ? handleSubmitEdit : formik.handleSubmit}
              onClick={() =>
                dispatch(
                  putCheckQuyenThaoTacAction(
                    vacXinEdit ? maQuyenCapNhat : maQuyenThem,
                    null,
                    actionIsTrue,
                    actionIsFalse,
                  ),
                )
              }
              loading={isLoadingAction}
            >
              Lưu
            </Button>
          </>
        )}
      >
        <Spin spinning={isLoadingAction}>
          <div className="grid grid-flow-row">
            {!vacXinEdit && (
              <Select
                placeholder="Nhập tên vắc xin..."
                className="w-full"
                showSearch
                allowClear
                onSearch={debounceDropDown}
                value={null}
                filterOption={false}
                notFoundContent={null}
                suffixIcon={<SearchOutlined className=" " />}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={handleVacXin}
                options={listVacXin?.map((item) => ({
                  key: `` + item?.idThuoc + item?.maSoDangKy,
                  value: item?.idThuoc,
                  label: item?.tenHoatChat,
                  tenBietDuoc: item?.tenBietDuoc,
                  maThuoc: item?.maThuoc?.toUpperCase(),
                  soDangKy: item?.maSoDangKy?.toUpperCase(),
                  itemVX: item,
                }))}
                optionRender={(options) => (
                  <ul className="flex">
                    <li className="w-[60%] border-e">
                      <p className="font-semibold w-full text-pretty">{options?.data?.label}</p>
                      <span className="text-xs w-full text-gray-500">
                        ({options?.data?.tenBietDuoc})
                      </span>
                    </li>
                    <li className="w-[20%] border-e text-center flex items-center justify-center">
                      {options?.data?.maThuoc}
                    </li>
                    <li className="w-[20%] border-e text-center flex items-center justify-center">
                      {options?.data?.soDangKy}
                    </li>
                  </ul>
                )}
              />
            )}
            <div className="grid grid-flow-row gap-1 mt-3">
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <label className="font-semibold">Tên thuốc:</label>
                  <Input
                    size="small"
                    readOnly
                    variant="filled"
                    value={formik.values.tenthuoc}
                    // onChange={formik.handleChange}
                    // name="tennguoinha"
                  />
                </div>
                <div>
                  <label className="font-semibold">Mã thuốc:</label>
                  <Input
                    size="small"
                    readOnly
                    variant="filled"
                    value={formik.values.mathuoc}
                    // onChange={formik.handleChange}
                    // name="tennguoinha"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <label className="font-semibold">Tên hoạt chất:</label>
                  <Input
                    size="small"
                    readOnly
                    variant="filled"
                    value={formik.values.tenhoatchat}
                    // onChange={formik.handleChange}
                    // name="tennguoinha"
                  />
                </div>
                <div>
                  <label className="font-semibold">Nhóm thuốc:</label>
                  <Input
                    size="small"
                    readOnly
                    variant="filled"
                    value={formik.values.tennhomthuoc}
                    // onChange={formik.handleChange}
                    // name="tennguoinha"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <label className="font-semibold">Đơn vị tính:</label>
                  <Input
                    size="small"
                    readOnly
                    variant="filled"
                    value={formik.values.dvt}
                    // onChange={formik.handleChange}
                    // name="tennguoinha"
                  />
                </div>
                <div>
                  <label className="font-semibold">Số đăng ký:</label>
                  <Input
                    size="small"
                    readOnly
                    variant="filled"
                    value={formik.values.masodangky}
                    // onChange={formik.handleChange}
                    // name="tennguoinha"
                  />
                </div>
              </div>
              <div>
                <label className="font-semibold">Nhà sản xuất:</label>
                <Input
                  size="small"
                  readOnly
                  variant="filled"
                  value={formik.values.nhasanxuat}
                  // onChange={formik.handleChange}
                  // name="tennguoinha"
                />
              </div>
              <div>
                <label className="font-semibold">Ngày cách nhau:</label>
                <InputNumber
                  className="w-full"
                  size="small"
                  min={0}
                  step={1}
                  value={formik.values.ngaycachnhau}
                  onChange={(value) => formik.setFieldValue('ngaycachnhau', value ? value : 0)}
                  name="ngaycachnhau"
                />
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
      <div className="flex justify-between items-center mb-2">
        <div className="flex justify-start items-center gap-2">
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            placeholder="Nhóm vắc xin..."
            className="w-60"
            options={listNhomVacXin?.map((item) => ({
              key: `` + item?.idNhom + item?.idPhanLoai,
              value: item?.idNhom,
              label: item?.nhom,
            }))}
            onChange={setNhomVX}
            disabled={isLoading}
            value={nhomVX}
          />
          <Input
            placeholder="Nhập từ khóa..."
            prefix={<SearchOutlined className="pr-1" />}
            onChange={(e) => {
              setKeyword(e.target.value)
            }}
            disabled={isLoading}
            value={keyword}
            className="w-60"
          />
          <IconButton
            onClick={() => {
              setKeyword((prev) => (prev?.length > 0 ? prev?.trim() : prev))
              onLoad()
            }}
            disabled={isLoading}
            color="primary"
            aria-label="delete"
            size="small"
          >
            <SyncIcon />
          </IconButton>
        </div>
        <Button
          type="primary"
          className="flex justify-center items-center"
          onClick={() => {
            formik.resetForm()
            setVacXinEdit(null)
            setIsModal(true)
          }}
        >
          <PlusOutlined />
          &nbsp;Thêm
        </Button>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          //   scroll={{ x: 1000 }}
          //   pagination={{
          //     pageSize: 20,
          //     showSizeChanger: false,
          //   }}
          scroll={{ y: 715 }}
          pagination={false}
          loading={isLoading}
          columns={columns}
          dataSource={listCanhBaoMuiTiem}
          bordered
        />
      </ConfigProvider>
    </div>
  )
}

export default MuiTiem

import { SearchOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Empty, Input, Space, Table } from 'antd'
import moment from 'moment'
import 'moment/locale/vi'
import React, { useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import * as typeAction from '../../../../store/constants/constants'

import ModalDetail from './ModalDetail'
moment.locale('vi')

const TableTheKho = ({ isloading }) => {
  const { historyTheKho } = useSelector((state) => state.thuocVTReducer)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [show, setShow] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch()

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setShow(false)
    setIsModalOpen(false)
    // tra về kho chi iết ban đầu khi cancel
    dispatch({
      type: typeAction.DISPATCH_INFO_THUOVT_NHAP_KHO,
      payload: [],
    })
    dispatch({
      type: typeAction.DISPATCH_INFO_PT_NHAP_KHO,
      payload: null,
    })
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columns = [
    {
      title: 'Ngày',
      dataIndex: 'ngay',
      key: 'date',
      width: 90,
      render: (text) => moment(text).format('DD/MM/YYYY'),
      onFilter: (value, record) => {
        const formattedRecordDate = record.ngay ? moment(record.ngay).format('DD/MM/YYYY') : ''
        return formattedRecordDate.includes(value)
      },
      sorter: (a, b) => moment(a.ngay).unix() - moment(b.ngay).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Phiếu',
      dataIndex: 'maPhieu',
      key: 'maPhieu',
      ...getColumnSearchProps('maPhieu'),
      render: (text, row) =>
        row.loai === 'Nhập Kho' || row.loai === 'Xuất Kho' ? (
          <span
            className="text-blue-500 cursor-pointer"
            onClick={() => {
              if(row.loai === 'Nhập Kho') {
                console.log(row.idKhoNhap);
              } else {
                console.log(row.idKhoXuat);

              }
              // dispatch(getInfoPTNhapByIdAction(record.idNhapXuat))
              // showModal()
            }}
          >
            {text}
          </span>
        ) : (
          text
        ),
    },
    {
      title: 'Loại',
      dataIndex: 'loai',
      key: 'loai',
      ...getColumnSearchProps('loai'),
    },
    {
      title: 'Chi nhánh chuyển',
      dataIndex: 'tenChiNhanhXuat',
      key: 'tenChiNhanhXuat',
      // width: 220,
      ...getColumnSearchProps('tenChiNhanhXuat'),
      onCell: () => ({
        style: {
          minWidth: 150,
        },
      }),
    },
    {
      title: 'kho chuyển',
      dataIndex: 'tenKhoXuat',
      key: 'tenKhoXuat',
      ...getColumnSearchProps('tenKhoXuat'),
      onCell: () => ({
        style: {
          minWidth: 110,
        },
      }),
    },
    {
      title: 'Chi nhánh nhập',
      dataIndex: 'tenChiNhanhNhap',
      key: 'tenChiNhanhNhap',
      ...getColumnSearchProps('tenChiNhanhNhap'),
      onCell: () => ({
        style: {
          minWidth: 150,
        },
      }),
      // width: 220,
    },
    {
      title: 'kho nhập',
      dataIndex: 'tenKhoNhap',
      key: 'tenKhoNhap',
      ...getColumnSearchProps('tenKhoNhap'),
      onCell: () => ({
        style: {
          minWidth: 100,
        },
      }),
    },
    // {
    //   title: 'Số lượng',
    //   dataIndex: 'soLuong',
    //   key: 'soLuong',
    //   align: 'center',
    //   sorter: {
    //     compare: (a, b) => {
    //       return a.soLuong - b.soLuong
    //     },
    //   },
    //   sortDirections: ['descend', 'ascend'],
    //   onCell: () => ({
    //     style: {
    //       minWidth: 100,
    //     },
    //   }),
    // },
    {
      title: 'Số lượng nhập',
      dataIndex: 'soLuongNhap',
      key: 'soLuongNhap',
      align: 'center',
      sorter: {
        compare: (a, b) => {
          return a.soLuongNhap - b.soLuongNhap
        },
      },
      sortDirections: ['descend', 'ascend'],
      onCell: () => ({
        style: {
          minWidth: 100,
        },
      }),
    },
    {
      title: 'Số lượng xuất',
      dataIndex: 'soLuongXuat',
      key: 'soLuongXuat',
      align: 'center',
      sorter: {
        compare: (a, b) => {
          return a.soLuongXuat - b.soLuongXuat
        },
      },
      sortDirections: ['descend', 'ascend'],
      onCell: () => ({
        style: {
          minWidth: 100,
        },
      }),
    },
    {
      title: 'Số lô',
      dataIndex: 'soLo',
      key: 'soLo',
      align: 'center',
      ...getColumnSearchProps('soLo'),
      onCell: () => ({
        style: {
          minWidth: 70,
        },
      }),
    },
    {
      title: 'Số hoá đơn',
      dataIndex: 'soHoaDon',
      key: 'soHoaDon',
      align: 'center',
      ...getColumnSearchProps('soHoaDon'),
      onCell: () => ({
        style: {
          minWidth: 110,
        },
      }),
    },
    {
      title: 'Hạn dùng',
      dataIndex: 'hanDung',
      key: 'hanDung',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
      onFilter: (value, record) => {
        const formattedRecordDate = record.hanDung
          ? moment(record.hanDung).format('DD/MM/YYYY')
          : ''
        return formattedRecordDate.includes(value)
      },
      sorter: (a, b) => moment(a.hanDung).unix() - moment(b.hanDung).unix(),
      sortDirections: ['descend', 'ascend'],
      onCell: () => ({
        style: {
          minWidth: 90,
        },
      }),
    },
  ]

  return (
    <>
      <ConfigProvider
        theme={{
          token: { padding: 5, borderRadius: 0 },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          locale={{
            triggerDesc: 'Sắp xếp giảm dần',
            triggerAsc: 'Sắp xếp tăng dần',
            cancelSort: 'Hủy sắp xếp',
            emptyText: <Empty description="Không tìm thấy dữ liệu" />,
          }}
          loading={isloading}
          bordered
          scroll={{ x: historyTheKho?.length > 0 ? 'max-content' : 1600, y: '72vh' }}
          pagination={false}
          columns={columns}
          dataSource={historyTheKho}
          summary={(pageData) => {
            let countSLNhap = 0
            let countSLXuat = 0

            pageData.forEach(({ soLuongNhap, soLuongXuat }) => {
              countSLNhap += soLuongNhap
              countSLXuat += soLuongXuat
            })

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align="center" className="text-red-500">
                    {countSLNhap}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9}>{countSLXuat}</Table.Summary.Cell>
                  <Table.Summary.Cell index={10}></Table.Summary.Cell>
                  <Table.Summary.Cell index={11} align="right"></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )
          }}
        />
      </ConfigProvider>
      <ModalDetail
        handleCancel={handleCancel}
        show={show}
        setShow={setShow}
        isModalOpen={isModalOpen}
      />
    </>
  )
}

export default TableTheKho

import { https } from '../apiService'
export const traCuuService = {
  postNewBN: (form) => https.post('BenhNhan', form),
  searchBN: (keyword, idct) =>
    https.get(`BenhNhan/SearchBenhNhan`, {
      params: {
        keyword,
        idct,
      },
    }),
  getInfoBN: (idBN) => https.get(`BenhNhan/${idBN}`),
  getInfoBN_TT: (idBN) => https.get(`BenhNhanTTC/GetByIDBN?id=${idBN}`),
  getAllBN: () => https.get(`BenhNhan/GetAll`),
  updateBN: (form) => https.put(`BenhNhan`, form),
  getByIdChinhanhIdct: (idct, idChinhanh, keyword, pageIndex) =>
    https.get(
      `BenhNhan/GetByCondition?idCongTy=${idct}&keyword=${keyword}&pageNumber=${pageIndex}`,
      {
        params: {
          idChinhanh,
        },
      },
    ),

  getByIdChinhanhIdctBnMat: (idct, idChinhanh, keyword, pageIndex) =>
    https.get(
      `BenhNhan/GetBenhNhanMatByCondition?idCongTy=${idct}&keyword=${keyword}&pageNumber=${pageIndex}`,
      {
        params: {
          idChinhanh,
        },
      },
    ),
  getBNMatByIdct: (idct, keyword, pageIndex) =>
    https.get(
      `BenhNhan/GetBenhNhanMatByCondition?idCongTy=${idct}&keyword=${keyword}&pageNumber=${pageIndex}`,
    ),
}

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import {
  Button,
  ConfigProvider,
  Input,
  InputNumber,
  Table,
  Tooltip,
  Modal,
  Select,
  Spin,
} from 'antd'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { tiemChungService } from '../../../../services/tiemChung/tiemChungService'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import * as typeAction from '../../../../store/constants/constants'
import { SearchOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import _, { cloneDeep } from 'lodash'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import moment from 'moment'
import ToastCus from '../../../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal
function DoTuoi() {
  const dispatch = useDispatch()
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [isLoading, setIsLoading] = useState(false)
  const [listNhomVacXin, setListNhomVacXin] = useState([])
  const [listVacXin, setListVacXin] = useState([])
  const [listCanhBaoDoTuoi, setListCanhBaoDoTuoi] = useState([])
  const [nhomVX, setNhomVX] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [isModal, setIsModal] = useState(false)
  const [vacXinEdit, setVacXinEdit] = useState(null)
  const [isLoadingAction, setisLoadingAction] = useState(false)
  const initTuoi = {
    nam: 0,
    thang: 0,
    tuan: 0,
    ngay: 0,
  }
  const [tuTuoi, settuTuoi] = useState(initTuoi)
  const [denTuoi, setdenTuoi] = useState(initTuoi)
  //############# FORM ############
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // idcbdt: 0,
      idthuoc: null,
      mathuoc: '',
      tenthuoc: '',
      tenhoatchat: '',
      dvt: '',
      tunam: 0,
      tuthang: 0,
      tutuan: 0,
      tungay: 0,
      dennam: 0,
      denthang: 0,
      dentuan: 0,
      denngay: 0,
      nhasanxuat: '',
      sodangky: '',
      ghichu: '',
      tennhomthuoc: '', //////////////////////
      // nguoitao: 0,
      // nguoisua: 0,
    },
    onSubmit: (value) => handleSubmit(value),
    // validationSchema: receiveSchema,
  })
  const maQuyenXemDs = 'QHT570'
  const maQuyenXemCt = 'QHT571'
  const maQuyenCapNhat = 'QHT572'
  const maQuyenThem = 'QHT569'
  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        getListCanhBaoDoTuoi()
        break
      case maQuyenXemCt:
        setIsModal(true)
        setVacXinEdit(infoNew)
        break
      case maQuyenCapNhat:
        handleSubmitEdit()
        break
      case maQuyenThem:
        formik.handleSubmit()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        setListCanhBaoDoTuoi([])
        break
      case maQuyenXemCt:
        setIsModal(false)
        setVacXinEdit(null)
        break
      case maQuyenCapNhat:
        break
      case maQuyenThem:
        break
      default:
        return false
    }
  }

  const resetForm = () => {
    settuTuoi(initTuoi)
    setdenTuoi(initTuoi)
    formik.resetForm()
    setVacXinEdit(null)
  }
  const handleSubmit = async (value) => {
    if (!value?.idthuoc) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn vắc xin cần cảnh báo!',
      })
      return
    }
    if (value?.tungay > value?.denngay) {
      ToastCus.fire({
        icon: 'error',
        title: 'Số ngày tuổi không hợp lệ!',
      })
      return
    }
    try {
      setisLoadingAction(true)
      await tiemChungService.postInfoCanhBaoDoTuoi({ ...value, nguoitao: infoUser?.idnv })
      resetForm()
      onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    } finally {
      setisLoadingAction(false)
      cancelModal()
    }
  }
  const handleSubmitEdit = async () => {
    if (!formik.values || !formik.values?.idcbdt || !formik.values?.idthuoc) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn lại vắc xin cần cảnh báo!',
      })
      return
    }
    if (formik.values?.tungay > formik.values?.denngay) {
      ToastCus.fire({
        icon: 'error',
        title: 'Số ngày tuổi không hợp lệ!',
      })
      return
    }
    try {
      setisLoadingAction(true)
      await tiemChungService.putInfoCanhBaoDoTuoi({ ...formik.values, nguoisua: infoUser?.idnv })
      resetForm()
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    } finally {
      setisLoadingAction(false)
      cancelModal()
    }
  }
  const handleVacXin = (value, opt) => {
    formik.setValues((prev) => ({
      ...prev,
      idthuoc: opt?.itemVX?.idThuoc,
      mathuoc: opt?.maThuoc,
      tenthuoc: opt?.tenBietDuoc,
      tenhoatchat: opt?.label,
      dvt: opt?.itemVX?.dvt,
      nhasanxuat: opt?.itemVX?.nhaSanXuat,
      sodangky: opt?.soDangKy,
      tennhomthuoc: opt?.itemVX?.nhom,
    }))
    // formik.setFieldValue('idthuoc', opt?.itemVX?.idThuoc)
    // formik.setFieldValue('mathuoc', opt?.maThuoc)
    // formik.setFieldValue('tenthuoc', opt?.tenBietDuoc)
    // formik.setFieldValue('tenhoatchat', opt?.label)
    // formik.setFieldValue('dvt', opt?.itemVX?.dvt)
    // formik.setFieldValue('nhasanxuat', opt?.itemVX?.nhaSanXuat)
    // formik.setFieldValue('sodangky', opt?.soDangKy)
    // formik.setFieldValue('tennhomthuoc', opt?.itemVX?.nhom)
  }
  const handleChangeTuoi = (name, field) => (value) => {
    const newValue = value > 0 ? value.toFixed() : 0
    name === 'tuTuoi' && settuTuoi((prev) => ({ ...prev, [field]: Number(newValue) }))
    name === 'denTuoi' && setdenTuoi((prev) => ({ ...prev, [field]: Number(newValue) }))
  }
  const tinhTuoiTiem = (ngay) => {
    let ngayTemp = ngay
    let namtuoi = ngay > 0 ? Math.floor(ngay / 365) : 0
    ngay = ngay - namtuoi * 365
    let thangtuoi = ngay > 0 ? Math.floor(ngay / 30) : 0
    ngay = ngay - thangtuoi * 30
    let tuantuoi = ngay > 0 ? Math.floor(ngay / 7) : 0
    let ngaytuoi = ngay - tuantuoi * 7
    let tuoiTiem = `${namtuoi > 0 ? namtuoi + ' tuổi ' : ''}${thangtuoi > 0 ? thangtuoi + ' tháng ' : ''}${tuantuoi > 0 ? tuantuoi + ' tuần ' : ''}${ngaytuoi > 0 ? ngaytuoi + ' ngày ' : ''}${ngayTemp === 0 ? ' 0' : ''}${!namtuoi || namtuoi === 0 ? ' tuổi' : ''}`
    return tuoiTiem?.replaceAll(`  `, ' ')?.trim()
    // return `${namtuoi} ${thangtuoi} ${tuantuoi} ${ngay}`
    // return `${(nam > 0 ? nam * 365 : 0) + (thang > 0 ? thang * 30 : 0) + (tuan > 0 ? tuan * 7 : 0) + (ngay > 0 ? ngay : 0)} ngày tuổi`
    // //let tuoiTiem = `${nam && nam !== 0 ? nam + ' tuổi ' : ''}${thang && thang !== 0 ? thang + ' tháng ' : ''}${tuan && tuan !== 0 ? tuan + ' tuần ' : ''}${ngay && ngay !== 0 ? ngay + ' ngày ' : ''}${!nam || nam === 0 ? ' tuổi' : ''}`
    // return tuoiTiem?.replaceAll(`  `, ' ')?.trim()
  }
  const cancelModal = () => {
    if (!isLoadingAction) {
      setIsModal(false)
      setVacXinEdit(null)
    }
  }
  const getListCanhBaoDoTuoi = async () => {
    try {
      setIsLoading(true)
      const { data } = await tiemChungService.getListCanhBaoDoTuoi(
        keyword?.trim()?.length > 0 ? keyword?.trim() : null,
        nhomVX,
      ) //loại vắc xin = 8
      setListCanhBaoDoTuoi(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getListNhomVacXin = async () => {
    try {
      setIsLoading(true)
      const { data } = await khoVTservice.getListGroupsThuocVT(8) //loại vắc xin = 8
      setListNhomVacXin(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getListVacXin = async (kw) => {
    try {
      const { data } = await khoVTservice.SearchThuocCanhBao(kw, ID_COMPANY, 8, null)
      setListVacXin(data)
      // keyword,
      // idCT,
      // idPhanLoai,
      // idNhom,
      // pageIndex, //loại vắc xin = 8
    } catch (error) {
      console.log(error)
    }
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        getListVacXin(keyword?.trim()?.replaceAll('  ', ' '))
      }
    }, 300),
    [],
  )
  const onLoad = () => {
    // getListCanhBaoDoTuoi()
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  const tinhSoNgayThanhTuoi = (soNgay) => {
    let namtuoi = soNgay > 0 ? Math.floor(soNgay / 365) : 0
    soNgay = soNgay - namtuoi * 365
    let thangtuoi = soNgay > 0 ? Math.floor(soNgay / 30) : 0
    soNgay = soNgay - thangtuoi * 30
    let tuantuoi = soNgay > 0 ? Math.floor(soNgay / 7) : 0
    let ngaytuoi = soNgay - tuantuoi * 7
    return {
      nam: namtuoi > 0 ? namtuoi : 0,
      thang: thangtuoi > 0 ? thangtuoi : 0,
      tuan: tuantuoi > 0 ? tuantuoi : 0,
      ngay: ngaytuoi > 0 ? ngaytuoi : 0,
    }
  }
  useEffect(() => {
    if (vacXinEdit?.idcbdt) {
      formik.setValues((prev) => ({
        ...prev,
        idcbdt: vacXinEdit?.idcbdt,
        idthuoc: vacXinEdit?.idthuoc,
        mathuoc: vacXinEdit?.mathuoc,
        tenthuoc: vacXinEdit?.tenthuoc,
        tenhoatchat: vacXinEdit?.tenhoatchat,
        dvt: vacXinEdit?.dvt,
        tunam: vacXinEdit?.tunam,
        tuthang: vacXinEdit?.tuthang,
        tutuan: vacXinEdit?.tutuan,
        tungay: vacXinEdit?.tungay,
        dennam: vacXinEdit?.dennam,
        denthang: vacXinEdit?.denthang,
        dentuan: vacXinEdit?.dentuan,
        denngay: vacXinEdit?.denngay,
        nhasanxuat: vacXinEdit?.nhasanxuat,
        sodangky: vacXinEdit?.sodangky,
        ghichu: vacXinEdit?.ghichu,
        tennhomthuoc: vacXinEdit?.nhom,
      }))
      settuTuoi(tinhSoNgayThanhTuoi(vacXinEdit?.tungay ?? 0))
      setdenTuoi(tinhSoNgayThanhTuoi(vacXinEdit?.denngay ?? 0))
    } else {
      formik.resetForm()
      settuTuoi(initTuoi)
      setdenTuoi(initTuoi)
    }
  }, [vacXinEdit])

  useEffect(() => {
    const soNgay =
      (tuTuoi?.nam ?? 0) * 365 +
      (tuTuoi?.thang ?? 0) * 30 +
      (tuTuoi?.tuan ?? 0) * 7 +
      (tuTuoi?.ngay ?? 0)
    formik.setFieldValue('tungay', soNgay)
  }, [tuTuoi])
  useEffect(() => {
    const soNgay =
      (denTuoi?.nam ?? 0) * 365 +
      (denTuoi?.thang ?? 0) * 30 +
      (denTuoi?.tuan ?? 0) * 7 +
      (denTuoi?.ngay ?? 0)
    formik.setFieldValue('denngay', soNgay)
  }, [denTuoi])

  useEffect(() => {
    getListNhomVacXin()
    onLoad()
  }, [])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 45,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã thuốc',
      dataIndex: 'mathuoc',
      key: 'mathuoc',
      width: 120,
      fixed: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.mathuoc ?? '').localeCompare(b?.mathuoc ?? '')
        },
      },
    },
    {
      title: 'Tên thuốc',
      dataIndex: 'tenthuoc',
      key: 'tenthuoc',
      width: 350,
      fixed: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.tenthuoc ?? '').localeCompare(b?.tenthuoc ?? '')
        },
      },
    },
    {
      title: 'Tên hoạt chất',
      dataIndex: 'tenhoatchat',
      key: 'tenhoatchat',
      width: 450,
      sorter: {
        compare: (a, b) => {
          return (a?.tenhoatchat ?? '').localeCompare(b?.tenhoatchat ?? '')
        },
      },
    },
    {
      title: 'ĐVT',
      dataIndex: 'dvt',
      key: 'dvt',
      align: 'center',
      width: 60,
      sorter: {
        compare: (a, b) => {
          return (a?.dvt ?? '').localeCompare(b?.dvt ?? '')
        },
      },
    },
    // {
    //   title: 'Nhóm',
    //   dataIndex: 'tieude',
    //   key: 'tieude',
    //   width: 150,
    // },
    {
      title: 'Nhà sản xuất',
      dataIndex: 'nhasanxuat',
      key: 'nhasanxuat',
      width: 270,
      sorter: {
        compare: (a, b) => {
          return (a?.nhasanxuat ?? '').localeCompare(b?.nhasanxuat ?? '')
        },
      },
    },
    {
      title: 'Số đăng ký',
      dataIndex: 'sodangky',
      key: 'sodangky',
      width: 170,
      sorter: {
        compare: (a, b) => {
          return (a?.sodangky ?? '').localeCompare(b?.sodangky ?? '')
        },
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'nguoitao',
      key: 'nguoitao',
      width: 220,
      sorter: {
        compare: (a, b) => {
          return (a?.nguoitao ?? '').localeCompare(b?.nguoitao ?? '')
        },
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngaytao',
      key: 'ngaytao',
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaytao ?? '').localeCompare(b?.ngaytao ?? '')
        },
      },
    },
    {
      title: 'Người sửa',
      dataIndex: 'nguoisua',
      key: 'nguoisua',
      width: 220,
      sorter: {
        compare: (a, b) => {
          return (a?.nguoisua ?? '').localeCompare(b?.nguoisua ?? '')
        },
      },
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'ngaysua',
      key: 'ngaysua',
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaysua ?? '').localeCompare(b?.ngaysua ?? '')
        },
      },
    },
    {
      title: 'Tuổi tiêm',
      dataIndex: 'tuoitiem',
      key: 'tuoitiem',
      width: 400,
      fixed: 'right',
      render: (text, record, index) =>
        `Từ ` + tinhTuoiTiem(record?.tungay) + ` - Đến ` + tinhTuoiTiem(record?.denngay),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 45,
      align: 'center',
      fixed: 'right',
      render: (text, record, index) => (
        <ul className="flex justify-center items-center gap-2">
          <li className="flex justify-center items-center">
            <Tooltip title="Chỉnh sửa" color="green">
              <EditOutlined
                onClick={() => {
                  dispatch(
                    putCheckQuyenThaoTacAction(maQuyenXemCt, record, actionIsTrue, actionIsFalse),
                  )
                }}
                className="text-xl text-green-500  cursor-pointer"
              />
            </Tooltip>
          </li>
        </ul>
      ),
    },
  ]
  return (
    <div>
      <Modal
        width={800}
        open={isModal}
        onCancel={cancelModal}
        // okText={'Lưu'}
        // onOk={vacXinEdit ? handleSubmitEdit : formik.handleSubmit}
        // cancelText="Huỷ bỏ"
        title={<p className="text-center">{vacXinEdit ? 'Chỉnh sửa' : 'Thêm'} cảnh báo độ tuổi</p>}
        className="flex flex-col gap-2"
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button onClick={cancelModal} loading={isLoadingAction}>
              Hủy bỏ
            </Button>
            <Button
              type="primary"
              // onClick={vacXinEdit ? handleSubmitEdit : formik.handleSubmit}
              onClick={() =>
                dispatch(
                  putCheckQuyenThaoTacAction(
                    vacXinEdit ? maQuyenCapNhat : maQuyenThem,
                    null,
                    actionIsTrue,
                    actionIsFalse,
                  ),
                )
              }
              loading={isLoadingAction}
            >
              Lưu
            </Button>
          </>
        )}
      >
        <Spin spinning={isLoadingAction}>
          <div className="grid grid-flow-row">
            {!vacXinEdit && (
              <Select
                placeholder="Nhập tên vắc xin..."
                className="w-full"
                showSearch
                allowClear
                onSearch={debounceDropDown}
                value={null}
                filterOption={false}
                notFoundContent={null}
                suffixIcon={<SearchOutlined className=" " />}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={handleVacXin}
                options={listVacXin?.map((item) => ({
                  key: `` + item?.idThuoc + item?.maSoDangKy,
                  value: item?.idThuoc,
                  label: item?.tenHoatChat,
                  tenBietDuoc: item?.tenBietDuoc,
                  maThuoc: item?.maThuoc?.toUpperCase(),
                  soDangKy: item?.maSoDangKy?.toUpperCase(),
                  itemVX: item,
                }))}
                optionRender={(options) => (
                  <ul className="flex">
                    <li className="w-[60%] border-e">
                      <p className="font-semibold w-full text-pretty">{options?.data?.label}</p>
                      <span className="text-xs w-full text-gray-500">
                        ({options?.data?.tenBietDuoc})
                      </span>
                    </li>
                    <li className="w-[20%] border-e text-center flex items-center justify-center">
                      {options?.data?.maThuoc}
                    </li>
                    <li className="w-[20%] border-e text-center flex items-center justify-center">
                      {options?.data?.soDangKy}
                    </li>
                  </ul>
                )}
              />
            )}
            <div className="grid grid-flow-row gap-1 mt-3">
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <label className="font-semibold">Tên thuốc:</label>
                  <Input size="small" readOnly variant="filled" value={formik.values.tenthuoc} />
                </div>
                <div>
                  <label className="font-semibold">Mã thuốc:</label>
                  <Input size="small" readOnly variant="filled" value={formik.values.mathuoc} />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <label className="font-semibold">Tên hoạt chất:</label>
                  <Input size="small" readOnly variant="filled" value={formik.values.tenhoatchat} />
                </div>
                <div>
                  <label className="font-semibold">Nhóm thuốc:</label>
                  <Input
                    size="small"
                    readOnly
                    variant="filled"
                    value={formik.values.tennhomthuoc}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <label className="font-semibold">Đơn vị tính:</label>
                  <Input size="small" readOnly variant="filled" value={formik.values.dvt} />
                </div>
                <div>
                  <label className="font-semibold">Số đăng ký:</label>
                  <Input size="small" readOnly variant="filled" value={formik.values.sodangky} />
                </div>
              </div>
              <div>
                <label className="font-semibold">Nhà sản xuất:</label>
                <Input size="small" readOnly variant="filled" value={formik.values.nhasanxuat} />
              </div>
              <div className="grid grid-cols-2 gap-3">
                <div className="grid grid-flow-row">
                  <div>
                    <label className="font-semibold">Từ ngày tuổi:</label>
                    <InputNumber
                      className="w-full"
                      size="small"
                      min={0}
                      step={1}
                      value={formik.values.tungay}
                      onChange={(value) => formik.setFieldValue('tungay', value ? value : 0)}
                      name="tungay"
                      readOnly
                      variant="filled"
                    />
                  </div>
                </div>
                <div className="grid grid-flow-row">
                  <div>
                    <label className="font-semibold">Đến ngày tuổi:</label>
                    <InputNumber
                      className="w-full"
                      size="small"
                      min={0}
                      step={1}
                      value={formik.values.denngay}
                      onChange={(value) => formik.setFieldValue('denngay', value ? value : 0)}
                      name="denngay"
                      readOnly
                      variant="filled"
                    />
                  </div>
                </div>
              </div>
              <div>
                <label className="font-semibold">Tuổi tiêm:</label>
                <Input
                  size="small"
                  readOnly
                  variant="filled"
                  value={
                    `Từ ` +
                    tinhTuoiTiem(formik.values.tungay) +
                    ` - Đến ` +
                    tinhTuoiTiem(formik.values.denngay)
                  }
                />
              </div>
              <div className="border-t mt-2 pt-1">
                <div className="grid grid-cols-2 gap-3 justify-center items-center">
                  <div>
                    <div className="font-semibold w-full pb-1">Từ</div>
                    <div className="grid grid-flow-row gap-3">
                      <InputNumber
                        size="small"
                        className="w-full"
                        min={0}
                        step={1}
                        addonAfter={<p className="w-12">Năm</p>}
                        value={tuTuoi?.nam}
                        onChange={handleChangeTuoi('tuTuoi', 'nam')}
                      />
                      <InputNumber
                        size="small"
                        className="w-full"
                        min={0}
                        step={1}
                        addonAfter={<p className="w-12">Tháng</p>}
                        value={tuTuoi?.thang}
                        onChange={handleChangeTuoi('tuTuoi', 'thang')}
                      />
                      <InputNumber
                        size="small"
                        className="w-full"
                        min={0}
                        step={1}
                        addonAfter={<p className="w-12">Tuần</p>}
                        value={tuTuoi?.tuan}
                        onChange={handleChangeTuoi('tuTuoi', 'tuan')}
                      />
                      <InputNumber
                        size="small"
                        className="w-full"
                        min={0}
                        step={1}
                        addonAfter={<p className="w-12">Ngày</p>}
                        value={tuTuoi?.ngay}
                        onChange={handleChangeTuoi('tuTuoi', 'ngay')}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="font-semibold w-full pb-1">Đến</div>
                    <div className="grid grid-flow-row gap-3">
                      <InputNumber
                        size="small"
                        className="w-full"
                        min={0}
                        step={1}
                        addonAfter={<p className="w-12">Năm</p>}
                        value={denTuoi?.nam}
                        onChange={handleChangeTuoi('denTuoi', 'nam')}
                      />
                      <InputNumber
                        size="small"
                        className="w-full"
                        min={0}
                        step={1}
                        addonAfter={<p className="w-12">Tháng</p>}
                        value={denTuoi?.thang}
                        onChange={handleChangeTuoi('denTuoi', 'thang')}
                      />
                      <InputNumber
                        size="small"
                        className="w-full"
                        min={0}
                        step={1}
                        addonAfter={<p className="w-12">Tuần</p>}
                        value={denTuoi?.tuan}
                        onChange={handleChangeTuoi('denTuoi', 'tuan')}
                      />
                      <InputNumber
                        size="small"
                        className="w-full"
                        min={0}
                        step={1}
                        addonAfter={<p className="w-12">Ngày</p>}
                        value={denTuoi?.ngay}
                        onChange={handleChangeTuoi('denTuoi', 'ngay')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
      <div className="flex justify-between items-center mb-2">
        <div className="flex justify-start items-center gap-2">
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            placeholder="Nhóm vắc xin..."
            className="w-60"
            options={listNhomVacXin?.map((item) => ({
              key: `` + item?.idNhom + item?.idPhanLoai,
              value: item?.idNhom,
              label: item?.nhom,
            }))}
            onChange={setNhomVX}
            disabled={isLoading}
            value={nhomVX}
          />
          <Input
            placeholder="Nhập từ khóa..."
            prefix={<SearchOutlined className="pr-1" />}
            onChange={(e) => {
              setKeyword(e.target.value)
            }}
            disabled={isLoading}
            value={keyword}
            className="w-60"
          />
          <IconButton
            onClick={() => {
              setKeyword((prev) => (prev?.length > 0 ? prev?.trim() : prev))
              onLoad()
            }}
            disabled={isLoading}
            color="primary"
            aria-label="delete"
            size="small"
          >
            <SyncIcon />
          </IconButton>
        </div>
        <Button
          type="primary"
          className="flex justify-center items-center"
          onClick={() => {
            resetForm()
            setIsModal(true)
          }}
        >
          <PlusOutlined />
          &nbsp;Thêm
        </Button>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          //   scroll={{ x: 1000 }}
          //   pagination={{
          //     pageSize: 20,
          //     showSizeChanger: false,
          //   }}
          scroll={{ y: 715 }}
          pagination={false}
          loading={isLoading}
          columns={columns}
          dataSource={listCanhBaoDoTuoi}
          bordered
        />
      </ConfigProvider>
    </div>
  )
}

export default DoTuoi

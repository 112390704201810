import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Input, Select } from 'antd'
import { recieveService } from '../../../../services/receive/recieveService'

import 'moment/locale/vi'
moment.locale('vi')
const InfoBN = ({ infoBN, setInfoBNTTC, InfoBNTTC, ghiChu, setGhiChu }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [NguoiNha, setNguoiNha] = useState([])
  // ------- lấy danh sách người nhà -------
  const nguoiNha = async () => {
    try {
      const result = await recieveService.getListNguoiThan()
      setNguoiNha(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    nguoiNha()
  }, [])
  return (
    <div className="border rounded-lg min-h-80">
      <h2 className="border-b text-lg font-semibold text-gray-400 p-2">Bệnh nhân</h2>
      <div className="grid grid-flow-row mt-2 gap-2">
        <div className="grid grid-cols-5 gap-2 px-2">
          <div className="col-span-3 grid grid-cols-3 items-center">
            <div className="font-semibold">Tên BN:</div>
            <div className="col-span-2">{infoBN?.tenBenhNhan}</div>
          </div>
          <div className="col-span-2 grid grid-cols-3 items-center">
            <div className="font-semibold">Mã BN:</div>
            <div className="col-span-2 pl-1">{infoBN?.maBenhNhan}</div>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-2 px-2">
          <div className="col-span-3 grid grid-cols-3 items-center">
            <div className="font-semibold">Giới tính:</div>
            <div className="col-span-2">{infoBN?.gioiTinh}</div>
          </div>
          <div className="col-span-2 grid grid-cols-3 items-center">
            <div className="font-semibold">Ngày sinh:</div>
            <div className="col-span-2 pl-1">
              {infoBN?.ngaySinh && moment(infoBN?.ngaySinh).format('DD/MM/YYYY')}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-2 px-2">
          <div className="col-span-3 grid grid-cols-3 items-center">
            <div className="font-semibold">Đối tượng:</div>
            <div className="col-span-2">{infoBN?.tenDoiTuong}</div>
          </div>
          <div className="col-span-2 grid grid-cols-3 items-center">
            <div className="font-semibold">Dân tộc:</div>
            <div className="col-span-2 pl-1">{infoBN?.tenDanToc}</div>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-2 px-2">
          <div className="col-span-3 grid grid-cols-3 items-center">
            <div className="font-semibold">Nghề nghiệp:</div>
            <div className="col-span-2">{infoBN?.tenNgheNghiep}</div>
          </div>
          <div className="col-span-2 grid grid-cols-3 items-center">
            <div className="font-semibold">Quốc tịch:</div>
            <div className="col-span-2 pl-1">{infoBN?.tenQuocTich}</div>
          </div>
        </div>
        <div className="grid grid-cols-5 px-2">
          <div className="font-semibold">Địa chỉ:</div>
          <div className="col-span-4">{infoBN?.diaChi}</div>
        </div>
        <div className="grid grid-cols-5 gap-2 px-2">
          <div className="col-span-3 grid grid-cols-3 items-center">
            <div className="font-semibold">Dược sĩ:</div>
            <div className="col-span-2">{infoBN?.idbn ? infoUser?.tenNV : ''}</div>
          </div>
          <div className="col-span-2 grid grid-cols-3 items-center">
            <div className="font-semibold">Chi nhánh:</div>
            <div className="col-span-2 pl-1">{infoBN?.idbn ? PkDangNhap?.tenChiNhanh : ''}</div>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-2 px-2">
          <div className="col-span-3 grid grid-cols-3 items-center">
            <div className="font-semibold">Người nhà:</div>
            <Select
              value={InfoBNTTC.nguoinha}
              size="small"
              className="col-span-2 w-full"
              onChange={(value) => setInfoBNTTC((preState) => ({ ...preState, nguoinha: value }))}
              options={NguoiNha.map((items) => ({
                label: items.ten,
                value: items.ten,
              }))}
            />
          </div>
          <div className="col-span-2 grid grid-cols-3 items-center">
            <div className="font-semibold">SĐT:</div>
            <div className="col-span-2 pl-1">
              <Input
                value={InfoBNTTC.dienthoainguoinha}
                size="small"
                className="w-full"
                onChange={(e) => {
                  const { value } = e.target
                  setInfoBNTTC((preState) => ({ ...preState, dienthoainguoinha: value }))
                }}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-5 px-2">
          <div className="font-semibold">Ghi chú:</div>
          <Input
            size="small"
            className="col-span-4 w-full"
            value={ghiChu}
            onChange={(e) => {
              const { value } = e.target
              setGhiChu(value)
            }}
          />
        </div>
      </div>
      {/* <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Tên BN:</label>
          <p>{infoBN?.tenBenhNhan}</p>
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">Mã BN:</label>
          <p>{infoBN?.maBenhNhan}</p>
        </div>
      </div>
      <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Giới tính:</label>
          <p>{infoBN?.gioiTinh}</p>
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">Ngày sinh:</label>
          <p>{infoBN?.ngaySinh && moment(infoBN?.ngaySinh).format('DD/MM/YYYY HH:mm:ss')}</p>
        </div>
      </div>
      <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Dân tộc:</label>
          <p>{infoBN?.tenDanToc}</p>
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">Đối tượng:</label>
          <p>{infoBN?.tenDoiTuong}</p>
        </div>
      </div>
      <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Nghề nghiệp:</label>
          <p>{infoBN?.tenNgheNghiep}</p>
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">Quốc tịch:</label>
          <p>{infoBN?.tenQuocTich}</p>
        </div>
      </div>
      <div className="flex p-2">
        <label className="font-semibold w-24">Địa chỉ: </label>
        <p>{infoBN?.diaChi}</p>
      </div>
      <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Dược sĩ:</label>
          <p>{infoUser?.tenNV}</p>
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">Chi nhánh:</label>
          <p>{PkDangNhap?.tenChiNhanh}</p>
        </div>
      </div>
      <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Người nhà:</label>
          <Select
            value={InfoBNTTC.nguoinha}
            size="small"
            className="w-32"
            onChange={(value) => setInfoBNTTC((preState) => ({ ...preState, nguoinha: value }))}
            options={NguoiNha.map((items) => ({
              label: items.ten,
              value: items.ten,
            }))}
          />
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">SĐT:</label>
          <Input
            value={InfoBNTTC.dienthoainguoinha}
            size="small"
            onChange={(e) => {
              const { value } = e.target
              setInfoBNTTC((preState) => ({ ...preState, dienthoainguoinha: value }))
            }}
          />
        </div>
      </div>
      <div className="p-2 flex">
        <label className="font-semibold w-1/4">Người nhà:</label>
        <label className="w-3/4">Người nhà:</label>
      </div> */}
    </div>
  )
}

export default InfoBN

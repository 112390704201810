import { PrinterOutlined, SaveOutlined } from '@ant-design/icons'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Button, Checkbox, Divider, Drawer, Input, InputNumber, Select, Space } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import { generatePhieuThuTiepNhan } from '../../../../../utils/report/phieuThuTiepNhan'
import {
  updateDetailPayment,
  updateGhiChuHD,
  updatePTBenhNhan,
} from '../../../../../store/actions/cashierAction'
import InputCustom from './InputCustom'
import 'moment/locale/vi'
moment.locale('vi')
const DrawerPT = ({ open, onClose }) => {
  const { DetailTT, infoBNcuaPT, InfoBN, BNTTC, ArrXN, ArrCĐHA, ArrKB } = useSelector(
    (state) => state.cashierReducer,
  )
  const { nguonKH } = useSelector((state) => state.receiveReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [Service, SetService] = useState({ DV: [], CDHA: [], XN: [] })
  const [DataPayment, setDataPayment] = useState(null)
  const [benhNhanTTC, setBenNhanTTC] = useState(null)
  const [isUpdate, setIsUpdate] = useState(false)
  const [show, setShow] = useState(false)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const dispatch = useDispatch()
  // gộp danh sách các mãng dữ liệu
  const DataService = isUpdate
    ? [...Service.DV, ...Service.CDHA, ...Service.XN]
    : [...ArrKB, ...ArrCĐHA, ...ArrXN]
  const DataPaymentUpdate = show ? DataPayment : DetailTT
  const totalPrice = DataService?.reduce((total, items) => total + items.tienthanhtoan, 0) ?? 0
  const tienGiamUpdate = DataService?.reduce((total, items) => total + items.tiengiamgia, 0) ?? 0
  const [ghichu, setGhiChu] = useState('')
  const now = moment().format('YYYY-MM-DDTHH:mm:ss')

  const handlePrint = async (DetailTT, InfoBN, BNTTC, service) => {
    const dataBenhNhanTTC = {
      sttkham: BNTTC.sttkham,
      tenPhongKham: BNTTC.tenPhongKham,
      tenLoaiKham: BNTTC?.tenLoaiKham,
      soLanDaTaiKham: BNTTC?.soLanDaTaiKham,
      benhNhan: {
        tenBenhNhan: InfoBN?.tenBenhNhan,
        maBenhNhan: InfoBN?.maBenhNhan,
        diaChi: InfoBN?.diaChi,
        tenPhuongXa: InfoBN?.tenPhuongXa,
        tenQuanHuyen: InfoBN?.tenQuanHuyen,
        tenTinhThanh: InfoBN?.tenTinhThanh,
        chiNhanh: {
          diaChi: InfoBN?.chiNhanh?.diaChi,
          soDienThoai: InfoBN?.chiNhanh?.dienThoai,
        },
      },
    }
    const dataChiDinh = [...ArrXN, ...ArrCĐHA, ...ArrKB].map((item) => ({
      tenChiDinh: item?.tendichvu || item?.tenxn || item?.tencdha,
      dvt: item.donvitinh || item.dvt,
      tienThanhToan: item?.tienthanhtoan,
      dongia: item?.dongia,
    }))
    const dataBenhNhanThanhToan = {
      maPhieuThu: DetailTT?.maphieuthu,
      tienThanhToan: DetailTT?.tienthanhtoan,
      ngaythanhtoan: DetailTT?.ngaythanhtoan,
      thuNgan: DetailTT?.thungan,
    }
    const dataBenhNhanChiTietTT = {}
    generatePhieuThuTiepNhan(
      dataBenhNhanTTC,
      dataChiDinh,
      dataBenhNhanThanhToan,
      dataBenhNhanChiTietTT,
      PkDangNhap,
    )
  }
  const handleChangeInputPrice = (value, { name, id, field }) => {
    SetService((prevState) => {
      const updatedService = { ...prevState }
      updatedService[name] = updatedService[name].map((item) =>
        item.idbndv === id || item.idbnxn === id || item.idbncdha === id
          ? { ...item, [field]: value }
          : item,
      )

      return updatedService
    })
  }
  const onChangeDetailPayment = (name) => (value) => {
    setDataPayment((prevState) => ({ ...prevState, [name]: value }))
  }
  const onChangeBenhNhanTTC = (name) => (value) => {
    setBenNhanTTC((prevState) => ({ ...prevState, [name]: value }))
  }
  //  lấy  DataPaymentUpdate?.ghichu, vào setGhiChu
  useEffect(() => {
    setGhiChu(DataPaymentUpdate?.ghichu)
  }, [DataPaymentUpdate?.ghichu])
  const updateServicePT = () => {
    dispatch(
      updatePTBenhNhan(
        Service,
        DetailTT?.idbntt,
        resetUpdate,
        DetailTT?.tiengiamdoitac || 0,
        infoUser?.idnv,
        DetailTT?.idbnttc,
        ghichu,
        now,
        infoUser?.tenNV,
      ),
    )
  }

  const resetUpdate = () => {
    setIsUpdate(false)
  }
  const updateDetailPay = () => {
    if (totalEditPayment === totalPrice) {
      dispatch(updateDetailPayment(DataPayment, setShow, DetailTT?.idbntt, benhNhanTTC))
    }
  }
  const handleDiscountPrice = (value, { name, id, dongia }) => {
    SetService((prevState) => {
      const updatedService = { ...prevState }
      updatedService[name] = updatedService[name].map((item) =>
        item.idbndv === id || item.idbnxn === id || item.idbncdha === id
          ? {
              ...item,
              ptgiamgia: value,
              tiengiamgia: dongia * (value / 100),
              tienthanhtoan: dongia - dongia * (value / 100),
            }
          : item,
      )

      return updatedService
    })
  }
  useEffect(() => {
    SetService({ DV: ArrKB, CDHA: ArrCĐHA, XN: ArrXN })
  }, [ArrXN, ArrCĐHA, ArrKB])
  useEffect(() => {
    setDataPayment(DetailTT)
  }, [DetailTT])
  useEffect(() => {
    setBenNhanTTC(BNTTC)
  }, [BNTTC])
  const InputProp = {
    onChangeDetailPayment,
    show: show,
  }
  const candelShow = () => {
    onClose()
    setShow(false)
    setIsUpdate(false)
  }
  // Lấy ngày hiện tại và định dạng nó để so sánh
  const currentDate = moment().format('YYYY-MM-DD')
  const detailDate = moment(DetailTT?.ngaythanhtoan).format('YYYY-MM-DD') // Giả sử `ngayLamViec` là trường ngày trong `DetailTT`
  const checkValidateLT =
    DetailTT?.idcalamviec !== infoUser?.dangNhap.idCaLamViec || detailDate !== currentDate
  const totalEditPayment =
    DataPayment?.tienmat +
    DataPayment?.chuyenkhoan +
    DataPayment?.momo +
    DataPayment?.vnpay +
    DataPayment?.zalopay +
    DataPayment?.pos +
    DataPayment?.thett
  const checkEditThanhToan = // chỉ có những nhóm admin, quan trị lễ tân mới có thể thao tác và lễ tân chỉ có thể thao tác trong ngày
    infoUser?.idNhom === 1 ||
    infoUser?.idNhom === 28 ||
    infoUser?.idNhom === 27 ||
    (infoUser?.idNhom === 3 && moment(DetailTT?.ngaythanhtoan).format('YYYY-MM-DD') === currentDate)

  const onChangeGhiChuHD = (e) => {
    const { value } = e.target
    if (infoUser?.idNhom === 28 || infoUser?.idNhom === 1 || infoUser?.idNhom === 27) {
      setDataPayment((prevState) => ({ ...prevState, ghichuxuathoadon: value }))
    }
    if (checkValidateLT) return
    setDataPayment((prevState) => ({ ...prevState, ghichuxuathoadon: value }))
  }
  const onChecked = (e) => {
    const { checked } = e.target
    if (infoUser?.idNhom === 28 || infoUser?.idNhom === 1 || infoUser?.idNhom === 27) {
      setDataPayment((prevState) => ({ ...prevState, hoadondientu: checked ? 1 : 0 }))
      dispatch(updateGhiChuHD({ ...DataPayment, hoadondientu: checked ? 1 : 0 }))
    }
    if (checkValidateLT) return
    setDataPayment((prevState) => ({ ...prevState, hoadondientu: checked ? 1 : 0 }))
    dispatch(updateGhiChuHD({ ...DataPayment, hoadondientu: checked ? 1 : 0 }))
  }
  const saveGhichu = () => {
    if (checkValidateLT) return
    dispatch(updateGhiChuHD(DataPayment))
  }
  return (
    <Drawer
      title={'Chi tiết phiếu thu'}
      placement="right"
      size="large"
      onClose={candelShow}
      open={open}
      extra={
        <Space>
          <Button
            onClick={() => handlePrint(DetailTT, InfoBN, BNTTC, [...ArrXN, ...ArrCĐHA, ...ArrKB])}
            type="primary"
            icon={<PrinterOutlined />}
          ></Button>
        </Space>
      }
    >
      <div>
        <h2 className="text-gray-500 font-semibold text-lg mb-2">Thông tin bệnh nhân</h2>
        <div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Tên BN :</label>
              <p>{InfoBN?.tenBenhNhan}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Mã BN :</label>
              <p>{InfoBN?.maBenhNhan}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Ngày sinh :</label>
              <p>{InfoBN?.ngaySinh && moment(InfoBN?.ngaySinh).format('DD/MM/YYYY')}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Phiếu thu :</label>
              <p>{DetailTT?.maphieuthu}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Giới tính :</label>
              <p>{InfoBN?.gioiTinh}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Ngày thu :</label>
              <p>
                {DetailTT?.ngaythanhtoan &&
                  moment(DetailTT?.ngaythanhtoan).format('DD/MM/YYYY HH:mm:ss')}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Đối tượng :</label>
              <p>{InfoBN?.tenDoiTuong}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Loại khám :</label>
              <p>
                {BNTTC?.idloaikham === 1
                  ? 'Khám lần đầu'
                  : BNTTC?.idloaikham === 2
                    ? 'Tái khám'
                    : 'Khám bệnh mới'}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Nguồn :</label>
              {show ? (
                <Select
                  className="w-36"
                  size="small"
                  value={benhNhanTTC?.idnguon}
                  onChange={onChangeBenhNhanTTC('idnguon')}
                  options={nguonKH?.map((item) => ({
                    label: item.nguon,
                    value: item.idNguonKH,
                  }))}
                />
              ) : (
                <p>{infoBNcuaPT?.nguon}</p>
              )}
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Quốc tịch :</label>
              <p>{InfoBN?.tenQuocTich}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Tên người thân :</label>
              <p>{BNTTC?.tennguoinha}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">SĐT:</label>
              <p>{InfoBN?.dienThoai}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Chi nhánh :</label>
              <p>{infoBNcuaPT?.tenchinhanh}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Mã TCQG :</label>
              <p>{InfoBN?.maTCQG}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Người thu :</label>
              <p>{DetailTT?.thungan}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Số thứ tự :</label>
              <p>{BNTTC?.sttkham}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Bác sĩ khám :</label>
              <p>{BNTTC?.bskham}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Phòng :</label>
              <p>{BNTTC?.tenPhongKham}</p>
            </div>
          </div>
          <div className="flex">
            <label className="font-semibold w-32">Địa chỉ :</label>
            {/* <p>{InfoBN?.diaChi}</p> */}
            <p>{` ${InfoBN?.diaChi ?? ''}${InfoBN?.tenPhuongXa ? ', ' + InfoBN?.tenPhuongXa : ''}${InfoBN?.tenQuanHuyen ? ', ' + InfoBN?.tenQuanHuyen : ''}${InfoBN?.tenTinhTP ? ', ' + InfoBN?.tenTinhTP : ''}`}</p>
          </div>
        </div>
        <Divider style={{ margin: 15 }} />
      </div>
      <div>
        <div className="flex justify-between items-center">
          <h2 className="text-gray-500 font-semibold text-lg mb-2">Chi tiết thanh toán</h2>
          {show ? (
            <div className="flex gap-2">
              <div
                onClick={updateDetailPay}
                className={`bg-blue-200 flex ${totalEditPayment === totalPrice ? `text-blue-700 hover:bg-blue-500` : 'text-gray-400'}  cursor-pointer duration-150 hover:text-white items-center rounded-md p-[5px]`}
              >
                <SaveOutlined style={{ fontSize: 18 }} />
              </div>
              <div
                onClick={() => setShow(false)}
                className="bg-red-200 flex text-red-700 hover:bg-red-500 cursor-pointer duration-150 hover:text-white items-center rounded-md p-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
              </div>
            </div>
          ) : (
            <>
              {checkEditThanhToan ? (
                <div
                  onClick={() => setShow(true)}
                  className="bg-green-200 flex text-green-700 hover:bg-green-500 cursor-pointer duration-150 hover:text-white items-center rounded-md p-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                </div>
              ) : (
                ''
              )}
            </>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-32">Tiền mặt :</label>
              <InputCustom props={InputProp} value={DataPaymentUpdate?.tienmat} name="tienmat" />
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-32">Chuyển khoản :</label>
              <InputCustom
                props={InputProp}
                value={DataPaymentUpdate?.chuyenkhoan}
                name="chuyenkhoan"
              />
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-32">Thẻ thanh toán :</label>
              <InputCustom props={InputProp} value={DataPaymentUpdate?.thett} name="thett" />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-32">Momo :</label>
              <InputCustom props={InputProp} value={DataPaymentUpdate?.momo} name="momo" />
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-32">VNPay :</label>
              <InputCustom props={InputProp} value={DataPaymentUpdate?.vnpay} name="vnpay" />
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-32">ZaloPay :</label>
              <InputCustom props={InputProp} value={DataPaymentUpdate?.zalopay} name="zalopay" />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-32">Máy POS :</label>
              <InputCustom props={InputProp} value={DataPaymentUpdate?.pos} name="pos" />
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-32">Phương thức TT :</label>
              {show ? (
                <Select
                  onChange={onChangeDetailPayment('idphuongthuc')}
                  size="small"
                  value={DataPaymentUpdate?.idphuongthuc}
                  options={[
                    { label: 'Thanh toán', value: 1 },
                    { label: 'Công nợ', value: 2 },
                  ]}
                />
              ) : (
                <p>
                  {DetailTT?.idphuongthuc === 1
                    ? 'Thanh toán'
                    : DetailTT?.idphuongthuc === 2
                      ? 'Công nợ'
                      : ''}
                </p>
              )}
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-32">Xuất HĐ điện tử :</label>
              <Checkbox onChange={onChecked} checked={DataPayment?.hoadondientu === 1} />
            </div>
          </div>
          <div className="flex">
            <label className="font-semibold w-36">Ghi chú xuất HĐ:</label>
            <Input
              className="text-red-500"
              variant="filled"
              size="small"
              onPressEnter={saveGhichu}
              onBlur={saveGhichu}
              onChange={onChangeGhiChuHD}
              value={DataPayment?.ghichuxuathoadon}
            />
          </div>
        </div>
        <Divider style={{ margin: 15 }} />
      </div>
      <div>
        <div className="flex justify-between items-center">
          <h2 className="text-gray-500 font-semibold text-lg mb-2">Chi tiết dịch vụ</h2>
          {isUpdate ? (
            <div className="flex gap-2">
              <div
                onClick={updateServicePT}
                className="bg-blue-200 flex text-blue-700 hover:bg-blue-500 cursor-pointer duration-150 hover:text-white items-center rounded-md p-[5px]"
              >
                <SaveOutlined style={{ fontSize: 18 }} />
              </div>
              <div
                onClick={() => setIsUpdate(false)}
                className="bg-red-200 flex text-red-700 hover:bg-red-500 cursor-pointer duration-150 hover:text-white items-center rounded-md p-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
              </div>
            </div>
          ) : (
            <>
              {infoUser?.idNhom === 1 || infoUser?.idNhom === 28 || infoUser?.idNhom === 27 ? (
                <div
                  onClick={() => setIsUpdate(true)}
                  className="bg-green-200 flex text-green-700 hover:bg-green-500 cursor-pointer duration-150 hover:text-white items-center rounded-md p-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                </div>
              ) : (
                ''
              )}
            </>
          )}
        </div>
        {/* Ghi chú */}
        <div className="flex">
          <label className="font-semibold w-32">Ghi chú TT:</label>
          {isUpdate ? (
            <Input
              variant="filled"
              size="small"
              value={ghichu}
              onChange={(e) => setGhiChu(e.target.value)}
            />
          ) : (
            <Input variant="filled" size="small" value={DataPaymentUpdate?.ghichu} readOnly />
          )}
        </div>
        <TableContainer>
          <Table aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell>Tên chỉ định</TableCell>
                <TableCell align="right" width="100">
                  Đơn giá
                </TableCell>
                <TableCell align="right" width="80">
                  % giảm
                </TableCell>
                <TableCell align="right" width="100">
                  Tiền giảm
                </TableCell>
                <TableCell align="right" width="100">
                  Thành tiền
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {DataService.map((row) => (
                <TableRow>
                  <TableCell>{row.tendichvu || row.tenxn || row.tencdha}</TableCell>
                  <TableCell align="right">
                    <InputNumber
                      readOnly={!isUpdate}
                      variant={isUpdate ? 'outlined' : 'borderless'}
                      size="small"
                      className="w-full"
                      value={row.dongia}
                      name={row.idbndv ? 'DV' : row.idbnxn ? 'XN' : 'CDHA'}
                      onChange={(value) =>
                        handleChangeInputPrice(value, {
                          name: row.idbndv ? 'DV' : row.idbnxn ? 'XN' : 'CDHA',
                          id: row.idbndv || row.idbnxn || row.idbncdha,
                          field: 'dongia',
                        })
                      }
                      formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                      min={0}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <InputNumber
                      readOnly={!isUpdate}
                      variant={isUpdate ? 'outlined' : 'borderless'}
                      size="small"
                      className="w-full"
                      value={row.ptgiamgia}
                      name={row.idbndv ? 'DV' : row.idbnxn ? 'XN' : 'CDHA'}
                      onChange={(value) =>
                        handleDiscountPrice(value, {
                          name: row.idbndv ? 'DV' : row.idbnxn ? 'XN' : 'CDHA',
                          id: row.idbndv || row.idbnxn || row.idbncdha,
                          dongia: row.dongia,
                        })
                      }
                      formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                      min={0}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {' '}
                    <InputNumber
                      readOnly={!isUpdate}
                      variant={isUpdate ? 'outlined' : 'borderless'}
                      size="small"
                      className="w-full"
                      value={row.tiengiamgia}
                      name={row.idbndv ? 'DV' : row.idbnxn ? 'XN' : 'CDHA'}
                      onChange={(value) =>
                        handleChangeInputPrice(value, {
                          name: row.idbndv ? 'DV' : row.idbnxn ? 'XN' : 'CDHA',
                          id: row.idbndv || row.idbnxn || row.idbncdha,
                          field: 'tiengiamgia',
                        })
                      }
                      formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                      min={0}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <InputNumber
                      readOnly={!isUpdate}
                      variant={isUpdate ? 'outlined' : 'borderless'}
                      size="small"
                      className="w-full"
                      value={row.tienthanhtoan}
                      name={row.idbndv ? 'DV' : row.idbnxn ? 'XN' : 'CDHA'}
                      onChange={(value) =>
                        handleChangeInputPrice(value, {
                          name: row.idbndv ? 'DV' : row.idbnxn ? 'XN' : 'CDHA',
                          id: row.idbndv || row.idbnxn || row.idbncdha,
                          field: 'tienthanhtoan',
                        })
                      }
                      formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                      min={0}
                    />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell rowSpan={5} colSpan={2} />
                <TableCell colSpan={2}>Tổng tiền</TableCell>
                <TableCell align="right">
                  {formattedNumber(
                    (DetailTT?.tiengiamgia ?? 0) +
                      (DetailTT?.tiengiamdoitac ?? 0) +
                      (DetailTT?.tienthanhtoan ?? 0),
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Tiền giảm</TableCell>
                <TableCell align="right">{formattedNumber(tienGiamUpdate)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Tiền giảm đối tác</TableCell>
                <TableCell align="right">
                  {formattedNumber(DetailTT?.tiengiamdoitac ?? 0)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Tổng giảm</TableCell>
                <TableCell align="right">
                  {formattedNumber(tienGiamUpdate + (DetailTT?.tiengiamdoitac ?? 0))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Thanh toán</TableCell>
                <TableCell align="right">{formattedNumber(totalPrice)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Drawer>
  )
}

export default DrawerPT

import { Input, Modal, Select, Checkbox } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createDanhMucNhan,
  getAllNhanDanhMuc,
  getDanhMucByNhan,
} from '../../../store/actions/suCoAction'
import { CreateDanhMucNhanSchema } from '../../../schemas/CreateDanhMucNhanSchema'
import { loaiHangHoaService } from '../../../services/loaiHangHoa/loaiHangHoa'
import { CreateNhomHangHoaSchema } from '../../../schemas/CreateNhomHangHoaSchema'
import {
  createNhomHangHoa,
  getNhomHangHoaByLoai,
  getAllLoaiHangHoa,
} from '../../../store/actions/loaiHangHoaAction'
import moment from 'moment'

const CreateDanhMuc = ({ openAdDM, setOpenAddDM, active, keywordDanhMuc }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { loaiHangHoa } = useSelector((state) => state.loaiHangHoaReducer)
  const [nhan, setNhan] = useState([])
  const dispatch = useDispatch()
  const handleCancel = () => {
    setOpenAddDM(false)
  }
  const handleSubmit = (value) => {
    dispatch(createNhomHangHoa(value, onReset))
  }
  const onChangeSelected = (value) => {
    formik.setFieldValue('idPhanLoai', value)
  }

  const onReset = () => {
    formik.resetForm()
    handleCancel()
    dispatch(getNhomHangHoaByLoai(active, ''))
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idPhanLoai: active,
      nhom: '',
      nguoiTao: infoUser?.tenNV,
      ngayTao: moment().format('YYYY-MM-DD'),
      ghiChu: '',
      thucPhamChucNang: 0,
    },
    validationSchema: CreateNhomHangHoaSchema,
    onSubmit: (value) => handleSubmit(value),
  })
  useEffect(() => {
    // Update nhan whenever loaiHangHoa changes
    if (loaiHangHoa?.length > 0) {
      setNhan(loaiHangHoa)
    }
  }, [loaiHangHoa])

  useEffect(() => {
    // Initial load
    dispatch(getAllLoaiHangHoa('', ''))
  }, [dispatch])
  return (
    <Modal
      onOk={formik.handleSubmit}
      okText="Tạo"
      cancelText="Huỷ bỏ"
      open={openAdDM}
      onCancel={handleCancel}
      title={
        <p className="font-semibold text-xl w-full text-center text-blue-500">TẠO NHÓM HÀNG HÓA</p>
      }
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
            Tên hàng hóa <span className="text-red-500">(*)</span>
          </label>
          <Input
            name="nhom"
            onChange={formik.handleChange}
            status={formik.errors.nhom && formik.touched.nhom ? 'error' : null}
            value={formik.values.nhom}
          />
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
            Loại <span className="text-red-500">(*)</span>
          </label>
          <div className="flex flex-row gap-2 items-center">
            <Select
              showSearch
              filterOption={(input, option) => {
                const name = option?.name ?? ''
                const searchText = input.toLowerCase()
                return name.toLowerCase().includes(searchText)
              }}
              placeholder="Chọn loại nhãn"
              onChange={onChangeSelected}
              value={formik.values.idPhanLoai}
              options={nhan?.map((item) => ({
                label: (
                  <div className="flex justify-between ">
                    <p> {item.phanLoai}</p>
                    <span
                      className={`font-semibold ${item.loai === 'THUOC' ? 'text-blue-500' : item.loai === 'HANGHOA' ? 'text-green-700' : 'text-cyan-500'}`}
                    >
                      {item.loai === 'THUOC'
                        ? 'Thuốc'
                        : item.loai === 'HANGHOA'
                          ? 'Hàng hóa'
                          : 'Vật tư'}
                    </span>
                  </div>
                ),
                name: item.loai,
                value: item.idPhanLoai,
              }))}
              className="w-full"
              name="nhom"
              status={formik.errors.idPhanLoai && formik.touched.idPhanLoai ? 'error' : null}
            />
          </div>
        </div>
        <div className="flex flex-row gap-1">
          <Checkbox
            name="thucPhamChucNang"
            onChange={(e) => formik.setFieldValue('thucPhamChucNang', e.target.checked ? 1 : 0)}
            checked={formik.values.thucPhamChucNang === 1}
          />{' '}
          <label className="text-sm"> Thực phẩm chức năng</label>
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">Ghi chú</label>
          <Input
            name="ghiChu"
            onChange={formik.handleChange}
            status={formik.errors.ghiChu && formik.touched.ghiChu ? 'error' : null}
            value={formik.values.ghiChu}
          />
        </div>
      </div>
    </Modal>
  )
}

export default CreateDanhMuc

import { ContainerOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { IconButton, Tooltip } from '@mui/material'
import {
  Button,
  ConfigProvider,
  DatePicker,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Table,
} from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  deleteDonHang,
  getAllDonHangByIdCtyIdCn,
  getDatHang,
  getInfoDonHang,
  updateTrangThaDatHang,
} from '../../../store/actions/vanPhongPhamAction'
import { trangThaiDatHang } from '../../../utils/trangThaiDatHang'
import ModalAdd from './Modal/ModalAdd'
import ModalEdit from './Modal/ModalEdit'
import ModalInfo from './Modal/ModalInfo'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const formatMoment = 'YYYY-MM-DD'
const dateFormat = 'DD-MM-YYYY'
const { confirm } = Modal

function DatHang(props) {
  const company = +localStorage.getItem('id_company')
  const chiNhanh = +localStorage.getItem('BRANH_LOGIN')
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listDatHang } = useSelector((state) => state.vanPhongPhamReducer)
  const [dateFrom, setDateFrom] = useState(now.format(formatMoment))
  const [dateTo, setDateTo] = useState(now.format(formatMoment))
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openInfo, setOpenInfo] = useState(false)
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [keyword, setKeyword] = useState('')

  const dispatch = useDispatch()
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
    },
    {
      title: 'Mã đặt hàng',
      dataIndex: 'madathang',
      key: 'madathang',
      width: 80,
    },
    {
      title: 'Bộ phận duyệt',
      dataIndex: 'chinhanhnhan',
      key: 'chinhanhnhan',
      width: 150,
    },
    {
      title: 'Tên đơn hàng',
      dataIndex: 'tendonhang',
      key: 'tendonhang',
      width: 200,
    },
    {
      title: 'Thời gian đặt hàng',
      dataIndex: 'thoigiandathang',
      key: 'thoigiandathang',
      width: 100,
      align: 'center',
    },
    {
      title: 'Người đặt hàng',
      dataIndex: 'nguoidat',
      key: 'nguoidat',
      width: 120,
    },
    // {
    //   title: 'Ngày đặt',
    //   dataIndex: 'ngaydat',
    //   key: 'ngaydat',
    //   width: 80,
    //   align: 'center',
    // },
    // {
    //   title: 'Người duyệt',
    //   dataIndex: 'nguoiduyet',
    //   key: 'nguoiduyet',
    //   width: 120,
    // },
    // {
    //   title: 'Ngày duyệt',
    //   dataIndex: 'ngayduyet',
    //   key: 'ngayduyet',
    //   width: 80,
    //   align: 'center',
    // },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 120,
    },
    {
      title: 'Trạng thái',
      key: 'trangthai',
      dataIndex: 'trangthai',
      width: 60,
      align: 'center',
      fixed: 'right',
    },
    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      width: 40,
      align: 'center',
      fixed: 'right',
    },
  ]

  const onLoad = () => {
    dispatch(getAllDonHangByIdCtyIdCn(keyword, dateFrom, dateTo, 1, company, chiNhanh))
  }

  useEffect(() => {
    onLoad()
  }, [])

  const handleDatePicker = (name) => (Date, dateString) => {
    // format date
    const value = moment(dateString, dateFormat).format(formatMoment)
    name === 'from' ? setDateFrom(value) : setDateTo(value)
  }

  const handleShowModalDetail = (record, trangthai) => {
    dispatch(getInfoDonHang(record.iddathang))
    if (trangThaiDatHang.taoPhieu === trangthai && record.idnguoidat === infoUser.idnv) {
      setOpenEdit(true)
    } else {
      setOpenInfo(true)
    }
  }

  const showConfirm = (item) => {
    confirm({
      title: (
        <div>
          <p>Xác nhận gửi phiếu đặt hàng:</p>
          <p className="font-bold">{item?.tendonhang} ?</p>
        </div>
      ),
      icon: <ExclamationCircleFilled />,
      content: `Gửi phiếu sẽ không thể sửa thông tin (bao gồm số lượng và hàng hóa)`,
      okText: 'Gửi phiếu',
      cancelText: 'Huỷ bỏ',
      onOk() {
        dispatch(updateTrangThaDatHang(item.iddathang, trangThaiDatHang.guiPhieu, '', onLoad))
      },
      onCancel() {},
    })
  }

  const handleChangePage = (keyword, dateFrom, dateTo, page) => {
    setCurrentPage(page)
    SetSearchParams({ page })
    dispatch(getAllDonHangByIdCtyIdCn(keyword, dateFrom, dateTo, page, company, chiNhanh))
  }

  const handleDeleteItem = (record) => {
    dispatch(deleteDonHang(record.iddathang, onLoad))
  }

  const renderTrangThai = useCallback(
    (value) => {
      let statusProps = {
        text: '',
        className: '',
      }

      switch (value) {
        case trangThaiDatHang.guiPhieu:
        case trangThaiDatHang.duyetPhieu:
        case trangThaiDatHang.duyetPhieuKSNB:
          statusProps = {
            text: 'Chờ duyệt',
            className: 'bg-blue-200 text-blue-800',
          }
          break
        case trangThaiDatHang.vanChuyen:
          statusProps = { text: 'Vận chuyển', className: 'bg-yellow-200 text-yellow-800' }
          break
        case trangThaiDatHang.nhapHang:
          statusProps = { text: 'Nhập hàng', className: 'bg-red-200 text-red-800' }
          break
        case trangThaiDatHang.hoanThanh:
          statusProps = { text: 'Hoàn thành', className: 'bg-green-200 text-green-800' }
          break
        default:
          statusProps = { text: 'Không xác định', className: 'bg-gray-200 text-gray-800' }
          break
      }

      return (
        <span className={`px-2 py-1 rounded-full text-xs font-medium ${statusProps.className}`}>
          {statusProps.text}
        </span>
      )
    },
    [trangThaiDatHang],
  )

  //setTotalPage
  useEffect(() => {
    if (listDatHang?.totalPages) setTotalPage(listDatHang?.totalPages)
  }, [listDatHang?.totalPages])

  return (
    <div className="h-full w-full bg-[#EBEBEB] p-2 border rounded-sm ">
      <div className="flex gap-5 justify-between bg-white border-dotted border-b-0 p-4">
        <div className="flex gap-5 justify-between w-full">
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('from')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="w-64">
            <Input
              className="w-full"
              placeholder="Nhập tên đơn hàng"
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
              value={keyword}
            />
          </div>
          <div className="flex gap-3 mr-auto">
            <IconButton
              color="primary"
              aria-label="delete"
              size="small"
              onClick={() =>
                dispatch(getAllDonHangByIdCtyIdCn(keyword, dateFrom, dateTo, 1, company, chiNhanh))
              }
            >
              <SyncIcon />
            </IconButton>
          </div>
          <div className="ml-auto">
            {/* <Button
              // onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button> */}
          </div>
          <div>
            <Button type="primary" onClick={() => setOpen(true)}>
              Đặt hàng
            </Button>
          </div>
        </div>
      </div>
      <div className="bg-white p-2">
        <div>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <Table
              bordered
              scroll={{
                x: 2000,
                y: 800,
              }}
              pagination={false}
              columns={columns}
              dataSource={listDatHang?.data?.map((item, idx) => ({
                ...item,
                stt: (currentPage - 1) * 20 + (idx + 1),
                thoigiandathang: item.thoigiandathang
                  ? moment(item.thoigiandathang).format('DD/MM/YYYY HH:mm')
                  : '',
                ngayduyet: item.ngayduyet ? moment(item.ngayduyet).format('DD/MM/YYYY') : '',
                ngaydat: item.ngaydat ? moment(item.ngaydat).format('DD/MM/YYYY') : '',
                trangthai:
                  item.trangthaidathang === 1 ? (
                    <Button
                      onClick={() => showConfirm(item)}
                      size="small"
                      type="primary"
                      style={{
                        fontSize: 12,
                        padding: 2,
                      }}
                      disabled={item.idnguoidat !== infoUser?.idnv}
                    >
                      Gửi phiếu
                    </Button>
                  ) : (
                    renderTrangThai(item.trangthaidathang)
                  ),
                action: (
                  <ul className="flex justify-around">
                    <li>
                      <Tooltip title="Xem" color="#108ee9">
                        <ContainerOutlined
                          onClick={() => handleShowModalDetail(item, item.trangthaidathang)}
                          className="text-xl text-[#108ee9]  cursor-pointer"
                        />
                      </Tooltip>
                    </li>
                    {item.trangthaidathang === trangThaiDatHang.taoPhieu &&
                      item.idnguoidat === infoUser?.idnv && (
                        <li>
                          <Popconfirm
                            title="Xác nhận xóa đơn hàng ?"
                            onConfirm={() => handleDeleteItem(item)}
                            okText="Xóa"
                            cancelText="Hủy"
                            icon={<DeleteOutlined style={{ color: 'red' }} />}
                          >
                            <DeleteOutlined className="text-xl text-red-500  cursor-pointer" />
                          </Popconfirm>
                        </li>
                      )}
                  </ul>
                ),
              }))}
            />
          </ConfigProvider>
          <div className="flex justify-end">
            <Pagination
              className="mt-5"
              showSizeChanger={false}
              current={+useSearch.get('page')}
              simple
              defaultCurrent={1}
              pageSize={20}
              total={20 * totalPage}
              onChange={(page, pagesize) => {
                handleChangePage(keyword, dateFrom, dateTo, page)
              }}
            />
          </div>
        </div>
      </div>
      {open && <ModalAdd open={open} setOpen={setOpen} onLoad={onLoad} />}
      {openEdit && <ModalEdit openEdit={openEdit} setOpenEdit={setOpenEdit} onLoad={onLoad} />}
      {openInfo && <ModalInfo openInfo={openInfo} setOpenInfo={setOpenInfo} onLoad={onLoad} />}
    </div>
  )
}

DatHang.propTypes = {}

export default DatHang

import { https } from '../apiService'

export const thuThuatService = {
  getListVTThuThuat: (idcn) => https.get(`TonKho/KhoCapCuuAndDieuDuong/${idcn}`),
  getListBNCho: (idcn) => https.get(`BenhNhanDichVu/DanhSachCho_DichVuThuThuat?idCN=${idcn}`),
  getListBNDaThucHien: (idcn, tungay, denngay) =>
    https.get(
      `BenhNhanDichVu/DanhSachHoanTat_DichVuThuThuat?idCN=${idcn}&tuNgay=${tungay}&denNgay=${denngay}`,
    ),
  // getInfoBNDVThuThuat: (idbnttc) => https.get(`BenhNhanDichVu/DichVuTHuThuatBy/${idbnttc}`),
  getInfoBNDVThuThuat: (idbnttc) =>
    https.get(`BenhNhanDichVu/GetThongTinBenhNhanDichVuThuThuat?idbnttc=${idbnttc}`),
  postListVTBNThuThuat: (list) => https.post(`BenhNhanVatTuTieuHao/AddBenhNhanVatTuTieuHao`, list),
  putListDVBNThuThuat: (list) => https.put(`BenhNhanDichVu/UpdateTrangThaiBenhNhanDichVu`, list),
  getListVTTheoKhoDieuDuong: (idcn) =>
    https.get(`TonKho/GetKhoDieuDuongByChiNhanh`, { params: { IDChiNhanh: idcn } }),
  getListVTTheoKhoCapCuu: (idcn) =>
    https.get(`TonKho/GetKhoCapCuuByChiNhanh`, { params: { IDChiNhanh: idcn } }),
  putListVTBNThuThuat: (idbnttc, list) =>
    https.put(`BenhNhanVatTuTieuHao/UpdateBenhNhanVatTuTieuHaoBinhThuong?idbnttc=${idbnttc}`, list),
  getListVTTheoKhoKTV: (idcn) =>
    https.get(`TonKho/GetTrongKinhByChiNhanh`, { params: { IDChiNhanh: idcn } }),
}

import { PrinterOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Divider, Drawer, Space, Table } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { https } from '../../../../services/apiService'
import { generatePhieuKetQuaXetNghiem } from '../../../../utils/report/phieuKetQuaXetNghiem'
import { calculateAge } from '../../../../utils/calculateAge'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')

const columnsXN = [
  { title: 'Mã XN', dataIndex: 'maxn', key: 'maxn', width: 150, align: 'center' },
  { title: 'Tên xét nghiệm', dataIndex: 'tenxn', key: 'tenxn' },
  { title: 'Đơn vị tính', dataIndex: 'dvt', key: 'dvt', align: 'center' },
  {
    title: 'Kết quả ',
    dataIndex: 'ketqua',
    key: 'ketqua',
  },
  {
    title: 'Chỉ số',
    dataIndex: 'chisobinhthuong',
    key: 'chisobinhthuong',
    width: 150,
    align: 'center',
  },
]

const DrawerChiTietXN = ({ open, setOpen }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [xetNghiem, setXetNghiem] = useState([])
  const [infoBn, setInfoBN] = useState(null)

  useEffect(() => {
    if (!open?.data?.idbnttc) return
    ;(async () => {
      try {
        const { data } = await https.get(`BenhNhanTTC/GetByIDBNTTC?id=${open.data.idbnttc}`)
        setInfoBN(data)
      } catch (err) {
        console.log(err)
      }
    })()
  }, [open?.data?.idbnttc])

  useEffect(() => {
    if (!open?.data?.idbnttc) return
    ;(async () => {
      try {
        const { data } = await https.get(
          `BenhNhanXetNghiem/GetBenhNhanXetNghiem/${open.data.idbnttc}`,
        )
        setXetNghiem(data)
      } catch (err) {
        console.log(err)
      }
    })()
  }, [open?.data?.idbnttc])

  const handlePrint = async (PkDangNhap) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT316',
      )
      generatePhieuKetQuaXetNghiem(infoBn, xetNghiem, PkDangNhap)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  return (
    <Drawer
      width={900}
      title={'Chi tiết xét nghiệm'}
      placement="right"
      size="large"
      onClose={() => setOpen(false)}
      open={open?.show}
      extra={
        <Space>
          <Button
            size="small"
            onClick={() => handlePrint(PkDangNhap)}
            type="primary"
            icon={<PrinterOutlined />}
          ></Button>
        </Space>
      }
    >
      <div>
        <h2 className="text-gray-500 font-semibold text-lg mb-2">Thông tin bệnh nhân</h2>
        <div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Tên BN :</label>
              <p>{infoBn?.benhNhan?.tenBenhNhan}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Mã BN :</label>
              <p>{infoBn?.benhNhan?.maBenhNhan}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Ngày sinh :</label>
              <p>
                {infoBn?.benhNhan?.ngaySinh &&
                  moment(infoBn?.benhNhan?.ngaySinh).format('DD/MM/YYYY')}
              </p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-32">Giới tính :</label>
              <p>{infoBn?.benhNhan?.gioiTinh}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Đối tượng :</label>
              <p>{infoBn?.benhNhan?.tenDoiTuong}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Tuổi :</label>
              <p>{infoBn?.benhNhan?.ngaySinh && calculateAge(infoBn?.benhNhan?.ngaySinh)}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Nguồn :</label>
              <p>{infoBn?.tenNguon}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Quốc tịch :</label>
              <p>{infoBn?.benhNhan?.tenQuocTich}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Tên người thân :</label>
              <p>{infoBn?.tennguoinha}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">SĐT:</label>
              <p>{infoBn?.dienthoainguoinha}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Chi nhánh :</label>
              <p></p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Mã TCQG:</label>
              <p>{infoBn?.maTCQG}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Số thứ tự:</label>
              <p>{infoBn?.sttkham}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Phòng:</label>
              <p>{infoBn?.tenPhongKham}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Bác sĩ khám :</label>
              <p>{infoBn?.bskham}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Loại khám :</label>
              <p>{infoBn?.tenLoaiKham}</p>
            </div>
          </div>
          <div className="flex">
            <label className="font-semibold w-36">Địa chỉ: </label>
            <p>{` ${infoBn?.benhNhan?.diaChi ?? ''}${infoBn?.benhNhan?.tenPhuongXa ? ', ' + infoBn?.benhNhan?.tenPhuongXa : ''}${infoBn?.benhNhan?.tenQuanHuyen ? ', ' + infoBn?.benhNhan?.tenQuanHuyen : ''}${infoBn?.benhNhan?.tenTinhTP ? ', ' + infoBn?.benhNhan?.tenTinhTP : ''}`}</p>
          </div>
        </div>
        <Divider style={{ margin: 15 }} />
        <div>
          {/* Table displaying prescription details */}
          <h2 className="text-gray-500 font-semibold text-lg mb-2">Chi tiết xét nghiệm</h2>
          <div className="mt-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                bordered
                pagination={{
                  pageSize: 10,
                  showSizeChanger: false,
                }}
                columns={columnsXN}
                dataSource={xetNghiem}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default DrawerChiTietXN

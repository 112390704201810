import moment from 'moment/moment'
import { formatNumberVND } from '../../utils/formatNumberVND'
import { numberToWords } from '../../utils/numberToWords'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord } from '../splitWordForReport'
import { logoToDataURI } from '../../assets/images/logoNhiBase64'
import { logoSanToDataURI } from '../../assets/images/logoSanBase64'
import { logoLaoToDataURI } from '../../assets/images/logoLaoBase64'
import { logoMatToDataUI } from '../../assets/images/logoMatBase64'
import { generateDot, generateLogo } from '../algorithm'
import JsBarcode from 'jsbarcode'

export const generatePhieuThuTienTTV = ({
  infoBN,
  infoBNthu2,
  infoTTV,
  infoTT,
  PkDangNhap,
  maphieuthu,
}) => {
  const doc = jsPDFCus('p', 'mm', 'a5')
  let yPos = 10
  let xPos = 8
  let ruleWidth = 55 // for text of price is longer
  const pageWidth = doc.internal.pageSize.width

  //logo
  generateLogo(doc, xPos, PkDangNhap)

  JsBarcode('#barcode', `${maphieuthu}`, {
    displayValue: false,
  })
  const canvas = document.getElementById('barcode')
  const barcodeDataURL = canvas.toDataURL()
  const barcodeWidth = 30 // Width of the barcode image
  const barcodeX = pageWidth - 40 // X coordinate of the barcode
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 40, 5, 30, 12)
  // Calculate the center position for text
  const text = `${maphieuthu}`
  const textWidth = (doc.getStringUnitWidth(text) * 11) / doc.internal.scaleFactor // Width of the text
  const textX = barcodeX + (barcodeWidth - textWidth) / 2 // Centered X coordinate for the text
  doc.setFontSize(10)
  doc.text(text, textX, 20)
  doc.text(`Mã BN: ${infoBN.maBenhNhan ?? ''}`, pageWidth - 40, 26)
  doc.setFont('times', 'bold')
  doc.setFontSize(16)
  doc.text('PHIẾU THU TIỀN', doc.internal.pageSize.width / 2, 35, { align: 'center' })

  yPos = 45
  doc.setFontSize(11)
  doc.setFont('times', 'regular')
  doc.text('Họ và tên người nộp: ', xPos, yPos)
  doc.text(
    `${infoTTV?.nguoimua ?? ''}`,
    doc.getTextDimensions('Họ và tên người nộp: ').w + xPos + 5,
    yPos,
  )
  const dotNguoiNop = generateDot(
    doc,
    infoTTV?.nguoimua,
    xPos + 5 + doc.getTextDimensions('Họ và tên người nộp: ').w,
    yPos,
  )
  doc.text(dotNguoiNop.positionX, dotNguoiNop.yPos, dotNguoiNop.content)
  yPos = yPos + 7

  //chính

  doc.text('Họ và tên: ', xPos, yPos)
  doc.setFont('times', 'regular')
  doc.text(`${infoBN?.tenBenhNhan ?? ''}`, doc.getTextDimensions('Họ và tên: ').w + xPos, yPos)
  const dotHoTen = generateDot(doc, `Họ và tên: ${infoBN?.tenBenhNhan ?? ''}`, xPos, yPos)
  doc.text(dotHoTen.positionX, dotHoTen.yPos, dotHoTen.content)
  yPos = yPos + 7

  doc.text('Địa chỉ: ', xPos, yPos)
  const textWidthDiaChi = doc.getTextDimensions('Địa chỉ: ').w
  doc.setFont('times', 'regular')
  const diaChiBenhNhan = infoBN?.diaChi ?? ''
  if (diaChiBenhNhan.length > 55) {
    const wordsArray = splitLongWord(diaChiBenhNhan, 55)
    doc.text(wordsArray[0], textWidthDiaChi + xPos, yPos)
    doc.text(wordsArray[1], xPos, yPos + 5)
    const dotDiaChi = generateDot(doc, wordsArray[1], textWidthDiaChi + xPos, yPos + 5)
    doc.text(dotDiaChi.positionX, dotDiaChi.yPos, dotDiaChi.content)
    yPos += 5
  } else {
    doc.text(diaChiBenhNhan, textWidthDiaChi + xPos, yPos)
    const dotDiaChi = generateDot(doc, diaChiBenhNhan, xPos + textWidthDiaChi, yPos)
    doc.text(dotDiaChi.positionX, dotDiaChi.yPos, dotDiaChi.content)
  }
  yPos += 7

  //phụ
  if (infoBNthu2) {
    doc.text('Họ và tên: ', xPos, yPos)
    doc.setFont('times', 'regular')
    doc.text(
      `${infoBNthu2?.tenBenhNhan ?? ''}`,
      doc.getTextDimensions('Họ và tên: ').w + xPos,
      yPos,
    )
    const dotHoTenPhu = generateDot(doc, `Họ và tên: ${infoBNthu2?.tenBenhNhan ?? ''}`, xPos, yPos)
    doc.text(dotHoTenPhu.positionX, dotHoTenPhu.yPos, dotHoTenPhu.content)
    yPos = yPos + 7

    doc.text('Địa chỉ: ', xPos, yPos)
    const textWidthDiaChiPhu = doc.getTextDimensions('Địa chỉ: ').w
    doc.setFont('times', 'regular')
    const diaChiBenhNhanPhu = infoBNthu2?.diaChi ?? ''
    if (diaChiBenhNhanPhu.length > 60) {
      const wordsArray = splitLongWord(diaChiBenhNhanPhu, 60)
      doc.text(wordsArray[0], textWidthDiaChiPhu + xPos, yPos)
      doc.text(wordsArray[1], xPos, yPos + 5)
      const dotDiaChiPhu = generateDot(doc, wordsArray[1], xPos + textWidthDiaChiPhu, yPos + 5)
      doc.text(dotDiaChiPhu.positionX, dotDiaChiPhu.yPos, dotDiaChiPhu.content)
      yPos += 5
    } else {
      doc.text(diaChiBenhNhan, textWidthDiaChiPhu + xPos, yPos)
      const dotDiaChiPhu = generateDot(doc, diaChiBenhNhan, xPos + textWidthDiaChiPhu, yPos)
      doc.text(dotDiaChiPhu.positionX, dotDiaChiPhu.yPos, dotDiaChiPhu.content)
    }
    yPos += 7
  }
  doc.text(
    `Mã thẻ: ${infoTTV.mathe}  Ngày hiệu lực: ${moment(infoTTV.tungay).format('DD/MM/YYYY')}  Ngày hết hạn: ${moment(infoTTV.denngay).format('DD/MM/YYYY')}`,
    xPos,
    yPos,
  )
  yPos += 7
  doc.text('Lý do: ', xPos, yPos)
  doc.setFont('times', 'regular')
  doc.text(`${infoTTV?.lydo ?? ''}`, doc.getTextDimensions('Lý do: ').w + xPos, yPos)
  const dotLydo = generateDot(doc, `Lý do: ${infoTTV?.lydo ?? ''}`, xPos, yPos)
  doc.text(dotLydo.positionX, dotLydo.yPos, dotLydo.content)
  yPos = yPos + 7

  doc.text('Số tiền bằng số: ', xPos, yPos)
  doc.setFont('times', 'regular')
  doc.text(
    `${formatNumberVND(infoTT?.tienthanhtoan ?? 0)}`,
    doc.getTextDimensions('Số tiền bằng số: ').w + xPos,
    yPos,
  )
  const dotSoTien = generateDot(
    doc,
    `Số tiền bằng số: ${formatNumberVND(infoTT?.tienthanhtoan ?? 0)}`,
    xPos,
    yPos,
  )
  doc.text(dotSoTien.positionX, dotSoTien.yPos, dotSoTien.content)
  yPos = yPos + 7

  doc.text('Số tiền bằng chữ: ', xPos, yPos)
  const convertSotien = numberToWords(infoTT?.tienthanhtoan ?? 0)
  doc.setFont('times', 'regular')
  if (convertSotien.length > 55) {
    const wordsArray = splitLongWord(convertSotien, 50)
    doc.text(wordsArray[0], doc.getTextDimensions('Số tiền băng chữ: ').w + xPos, yPos)
    doc.text(wordsArray[1], xPos, yPos + 5)
    const dotTien = generateDot(doc, wordsArray[1], xPos, yPos + 5)
    doc.text(dotTien.positionX, dotTien.yPos, dotTien.content)
    yPos += 5
  } else {
    doc.text(convertSotien, doc.getTextDimensions('Số tiền băng chữ: ').w + xPos, yPos)
    const dotTien = generateDot(doc, `Số tiền băng chữ: ${convertSotien}`, xPos, yPos)
    doc.text(dotTien.positionX, dotTien.yPos, dotTien.content)
  }
  yPos += 10
  doc.setFontSize(11)
  const dateText = `Ngày ${moment(infoTTV.ngaythanhtoan).format('DD')} tháng ${moment(infoTTV.ngaythanhtoan).format('MM')} năm ${moment(infoTTV.ngaythanhtoan).format('YYYY')}`
  doc.text(dateText, pageWidth - 55, yPos)
  yPos += 5
  doc.text('Người nộp tiền', xPos + 5, yPos)
  doc.text('Người thu và lập phiếu', pageWidth - 52, yPos)

  yPos += 4
  doc.text('(Ký, họ và tên)', xPos + 5, yPos)
  doc.text('(Ký, họ và tên)', pageWidth - 45, yPos)

  // doc.save('Phieu-thu.pdf')
  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}

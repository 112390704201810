import React, { useCallback, useEffect, useState } from 'react'
import { Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import moment from 'moment'
import _ from 'lodash'

const LichSu = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    // Tạo dữ liệu giả
    const fakeData = [
      {
        id: 1,
        branch: 'Chi nhánh Đồng Đen',
        sender: 'Tu Trưởng',
        sendDate: '16/06/2024',
        fileCount: 5,
        isRead: false,
      },
      {
        id: 2,
        branch: 'Chi nhánh Tân Bình',
        sender: 'Nguyễn Văn A',
        sendDate: '15/06/2024',
        fileCount: 3,
        isRead: false,
      },
      {
        id: 3,
        branch: 'Chi nhánh Phú Nhuận',
        sender: 'Trần Thị B',
        sendDate: '14/06/2024',
        fileCount: 7,
        isRead: true,
      },
    ]
    setData(fakeData)
  }, [])

  const handleNotificationClick = (id) => {
    setData((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item, isRead: true } : item)),
    )
  }

  return (
    <>
      <Select
        value={null}
        filterOption={false}
        notFoundContent={null}
        placeholder="Tìm kiếm chi nhánh, người gửi "
        suffixIcon={<SearchOutlined />}
        showSearch
        allowClear
        className="w-full"
      />
      <div className="border mt-2 rounded-md">
        <ul className="h-[776px] p-2 flex flex-col gap-2 overflow-auto">
          {data.map((item) => (
            <li
              key={item.id}
              className={`border rounded-md p-2 cursor-pointer hover:bg-sky-100 hover:-translate-y-1 duration-200 ${
                item.isRead ? 'bg-gray-100' : 'bg-white'
              }`}
              onClick={() => handleNotificationClick(item.id)}
            >
              <div className="flex justify-between items-center">
                <div>
                  <h1 className="font-semibold text-base">Từ: {item.branch}</h1>
                  <p className="text-gray-400">Ngày gửi: {item.sendDate}</p>
                </div>
                <p className="font-semibold text-base">Người gửi: {item.sender}</p>
              </div>
              <div className="flex justify-between">
                <p className="text-gray-400">Số file: {item.fileCount}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default LichSu

import { Button, Input, Modal, InputNumber } from 'antd'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { postListDVApplyTTV_Action } from '../../../../store/actions/receiveAction'
import { putPhieuThuAction } from '../../../../store/actions/cashierAction'
import { cashierService } from '../../../../services/cashier/cashierService'
import { generatePhieuThuLeTan } from '../../../../utils/report/phieuThuLeTan'
import 'moment/locale/vi'
moment.locale('vi')
const today = moment()
const idnhomDV = [1, 2, 3, 4, 6, 21, 22, 24, 25, 42, 46]
const ModalPay = ({
  isModalPay,
  setIsModalPay,
  form,
  setForm,
  setIsPrint,
  resetFormThanhToan,
  maCTGGdangApply,
}) => {
  // const { paymentCard } = useSelector((state) => state.cashierReducer)
  const {
    paymentCard,
    ArrXN,
    ArrCĐHA,
    ArrKB,
    memberCard,
    InfoBN,
    infoBNcuaPT,
    tongGiam,
    tongThanhToan,
    BNTTC,
    infoChuongTrinhGiamGiaDoiTac,
  } = useSelector((state) => state.cashierReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const dispatch = useDispatch()

  const handleCancel = () => {
    setIsModalPay(false)
  }
  // //-----------fetch phiếu đã thu tiền trong ngày --------------
  // const fetchPhieuThu = async () => {
  //   const result = await cashierService.getPhieuThu(
  //     today.format('YYYY-MM-DD'),
  //     today.format('YYYY-MM-DD'),
  //     PkDangNhap?.idChiNhanh,
  //   )
  //   setPhieuThu(result?.data)
  // }
  const onSubmit = (isPrint) => {
    let ArrCD = ArrKB.concat(ArrXN, ArrCĐHA) // nối các mãng lại với nhau
    let itemCheckDiKhamLai = null
    if (!ArrKB?.find((item) => item?.trangthaitt === 2 && idnhomDV.includes(item?.idnhomdv))) {
      itemCheckDiKhamLai = ArrKB?.find(
        (item) => item?.trangthaitt !== 2 && idnhomDV.includes(item?.idnhomdv),
      )
    }
    let formTT = {
      idbntt: infoBNcuaPT?.idbntt,
      idbnttc: BNTTC?.idbnttc,
      BNTT: {
        // tenThanhToan: 0,//api
        idbntt: infoBNcuaPT?.idbntt,
        maPhieuThu: infoBNcuaPT?.phieuthu,
        tienThanhToan: ArrCD?.reduce((tong, item) => tong + item.tienthanhtoan, 0),
        tienDichVu: ArrKB?.reduce((tong, item) => tong + item.tienthanhtoan, 0),
        tienXetNghiem: ArrXN?.reduce((tong, item) => tong + item.tienthanhtoan, 0),
        tienCDHA: ArrCĐHA?.reduce((tong, item) => tong + item.tienthanhtoan, 0),
        tienGiamGia: ArrCD?.reduce((tong, item) => tong + item.tiengiamgia, 0),
        thuNgan: infoUser?.tenNV,
        // idThuNgan: infoUser?.idnv,
        maVoucher: form?.mavoucher?.trim().length > 0 ? form?.mavoucher?.trim() : null, // lưu vào phiếu thu
        mavoucherdoitac:
          form?.voucherDoiTac?.maVoucher?.trim().length > 0 ? form?.voucherDoiTac?.maVoucher : null, // lưu vào phiếu thu
        mavoucher: form?.mavoucher?.trim().length > 0 ? form?.mavoucher?.trim() : null, // lưu vết bệnh nhân dùng voucher
        voucherDoiTac: form?.voucherDoiTac ?? null,
        idcn: PkDangNhap.idChiNhanh,
        listMaVC: form?.listMaVC?.length > 0 ? form?.listMaVC : null,
        chuongtrinhgiamgia:
          maCTGGdangApply?.trim()?.length > 0
            ? maCTGGdangApply?.replaceAll(' ', '')?.replaceAll(',,', ',')
            : null, // string
        idctdoitac: infoChuongTrinhGiamGiaDoiTac?.idctDoiTac ?? null,
        tiengiamdoitac: infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0,
      },
      BNCTTT: {
        idthe: memberCard?.idbn === BNTTC?.idbn ? memberCard?.idthe : null,
        tienmat: form.tienmat,
        chuyenkhoan: form.chuyenkhoan,
        thett: form.thett,
        momo: form.momo,
        vnpay: form.vnpay,
        zalopay: form.zalopay,
        pos: form.pos,
        tongtien: tongThanhToan,
        tiengiamgia: tongGiam, //////
        tienthanhtoan: tongThanhToan,
        tiengiamdoitac: infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0,
      },
      ArrKB: ArrKB?.map((item) => ({ ...item, trangthaitt: 2, idtrangthaitt: 2 })),
      ArrXN: ArrXN?.map((item) => ({ ...item, trangthaitt: 2, idtrangthaitt: 2 })),
      ArrCĐHA: ArrCĐHA?.map((item) => ({ ...item, trangthaitt: 2, idtrangthaitt: 2 })),
      checkDiKhamLai: itemCheckDiKhamLai,
      ArrDvSDThe: memberCard
        ? ArrKB.concat(ArrXN, ArrCĐHA)?.map(
            (item) =>
              memberCard?.idbn === BNTTC?.idbn && {
                idthe: memberCard?.idthe,
                idbn: BNTTC?.idbn,
                tiensd: item?.tienthanhtoan, // tiền thanh toán (sau khi giảm)
                trangthaisd: 1, //
                // idlt: 0,
                iddv: item?.iddichvu ?? item?.idxn ?? item?.idcdha,
                idcn: PkDangNhap?.idChiNhanh,
                nguoinhan: infoUser?.tenNV, // tên lễ tân
                nguoitt: BNTTC?.tennguoinha ?? InfoBN?.tenBenhNhan, // tên người nhà hoặc tên bệnh nhân
                ghichu: '',
                loaichidinh: (item?.iddichvu && 1) ?? (item?.idxn && 2) ?? (item?.idcdha && 3), //dv = 1, xn = 2, cdha = 3
              },
          )
        : null,
    }
    if (paymentCard && form.thett > 0) {
      formTT = {
        ...formTT,
        TTT: {
          idthe: paymentCard?.idThe,
          idbn: InfoBN?.idbn,
          nguoinhan: infoUser?.tenNV,
          tiensudung: form.thett,
          chinhanhnap: PkDangNhap?.idChiNhanh,
        },
      }
    }
    let formPrint = {
      isPrint: false,
    }
    if (isPrint) {
      formPrint = {
        isPrint: true,
        BNTTC: BNTTC,
        ArrCD: ArrKB.concat(ArrXN, ArrCĐHA),
        BNTT: formTT.BNTT,
        PkDangNhap: PkDangNhap,
      }
    }
    dispatch(putPhieuThuAction(formTT, PkDangNhap?.idChiNhanh, formPrint))
    // dispatch(postListDVApplyTTV_Action(Arr))
    // fetchPhieuThu()
    resetFormThanhToan()
    setIsPrint(isPrint)
    handleCancel()
  }
  // kiển tra tổng giá có vược quá giá thanh toán hay không
  const checkValuePrice = (total, name, value) => {
    if (total > form.tienthanhtoan) {
      setForm((prevState) => ({ ...prevState, [name]: 0 }))
    } else {
      setForm((prevState) => ({ ...prevState, [name]: value }))
    }
  }
  const onChangeInput = (name, value) => {
    // const { name, value } = e.target
    const newValue = Number(value)
    // setForm({ ...form, [name]: newValue });
    switch (name) {
      case 'tienmat':
        const total_0 =
          newValue +
          form.chuyenkhoan +
          form.pos +
          form.momo +
          form.vnpay +
          form.zalopay +
          form.thett
        checkValuePrice(total_0, name, newValue)
        break
      case 'chuyenkhoan':
        const total_1 =
          form.tienmat + newValue + form.pos + form.momo + form.vnpay + form.zalopay + form.thett
        checkValuePrice(total_1, name, newValue)
        break
      case 'momo':
        const total_2 =
          form.tienmat +
          form.chuyenkhoan +
          form.pos +
          newValue +
          form.vnpay +
          form.zalopay +
          form.thett
        checkValuePrice(total_2, name, newValue)
        break
      case 'vnpay':
        const total_3 =
          form.tienmat +
          form.chuyenkhoan +
          form.pos +
          form.momo +
          newValue +
          form.zalopay +
          form.thett
        checkValuePrice(total_3, name, newValue)
        break
      case 'zalopay':
        const total_4 =
          form.tienmat +
          form.chuyenkhoan +
          form.pos +
          form.momo +
          form.vnpay +
          newValue +
          form.thett
        checkValuePrice(total_4, name, newValue)
        break
      case 'pos':
        const total_5 =
          form.tienmat +
          form.chuyenkhoan +
          newValue +
          form.momo +
          form.vnpay +
          form.zalopay +
          form.thett
        checkValuePrice(total_5, name, newValue)
        break
      case 'thett':
        if (newValue > paymentCard?.tienVi) return
        const total_6 =
          form.tienmat +
          form.chuyenkhoan +
          form.pos +
          form.momo +
          form.vnpay +
          form.zalopay +
          newValue
        checkValuePrice(total_6, name, newValue)
        break
      default:
        break
    }
  }

  const handleKeyDown = (e) => {
    const { name } = e.target
    if (e.key === 'P' || e.key === 'p') {
      // Kiểm tra nếu phím nhấn là "P"
      // Cập nhật giá tiền vào ô input
      switch (name) {
        case 'tienmat':
          const priceTM = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceTM }))
          break
        case 'chuyenkhoan':
          const priceCK = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceCK }))
          break
        case 'momo':
          const priceMOMO = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceMOMO }))
          break
        case 'vnpay':
          const priceVNPAY = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceVNPAY }))
          break
        case 'zalopay':
          const priceZALOPAY = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceZALOPAY }))
          break
        case 'pos':
          const pricePOS = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: pricePOS }))
          break
        case 'thett':
          const priceTheTT = form.tienthanhtoan - form.tongtien
          if (priceTheTT > paymentCard?.tienVi) {
            setForm((prevState) => ({ ...prevState, [name]: paymentCard?.tienVi }))
            break
          }
          setForm((prevState) => ({ ...prevState, [name]: priceTheTT }))
          break
        default:
          break
      }
    }
  }
  useEffect(() => {
    const newTotal =
      parseFloat(form.chuyenkhoan) +
      parseFloat(form.momo) +
      parseFloat(form.pos) +
      parseFloat(form.vnpay) +
      parseFloat(form.zalopay) +
      parseFloat(form.tienmat) +
      parseFloat(form.thett)
    setForm((prevState) => ({ ...prevState, tongtien: newTotal }))
  }, [form.chuyenkhoan, form.momo, form.pos, form.vnpay, form.zalopay, form.tienmat, form.thett])

  return (
    <Modal
      className="text-center"
      title="Hình thức thanh toán"
      okText="Thanh Toán"
      width={600}
      cancelText="Huỷ"
      onCancel={handleCancel}
      open={isModalPay}
      footer={[
        <Button
          onClick={() => onSubmit(true)}
          disabled={form.tongtien !== form.tienthanhtoan}
          type="primary"
        >
          Thanh toán & In
        </Button>,
        <Button
          onClick={() => onSubmit(false)}
          disabled={form.tongtien !== form.tienthanhtoan}
          type="primary"
        >
          Thanh toán
        </Button>,
      ]}
    >
      <form className="text-start">
        <div className="flex flex-col gap-2">
          <div className="flex gap-5">
            <div className="w-1/2">
              <h2 className="font-semibold">Tiền mặt:</h2>
              <InputNumber
                value={form.tienmat}
                name="tienmat"
                onKeyUp={handleKeyDown}
                onChange={(value) => onChangeInput('tienmat', value)} //onChangeInput
                className="w-full"
                step={100}
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
            <div className="w-1/2">
              <h2 className="font-semibold">Chuyển khoản:</h2>
              <InputNumber
                value={form.chuyenkhoan}
                name="chuyenkhoan"
                onKeyUp={handleKeyDown}
                onChange={(value) => onChangeInput('chuyenkhoan', value)} //onChangeInput
                className="w-full"
                step={100}
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-1/2">
              <h2 className="font-semibold">Máy POS:</h2>
              <InputNumber
                value={form.pos}
                name="pos"
                onKeyUp={handleKeyDown}
                onChange={(value) => onChangeInput('pos', value)} //onChangeInput
                className="w-full"
                step={100}
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
            <div className="w-1/2">
              <h2 className="font-semibold">Momo:</h2>
              <InputNumber
                value={form.momo}
                name="momo"
                onKeyUp={handleKeyDown}
                onChange={(value) => onChangeInput('momo', value)} //onChangeInput
                className="w-full"
                step={100}
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-1/2">
              <h2 className="font-semibold">VNPay:</h2>
              <InputNumber
                value={form.vnpay}
                name="vnpay"
                onKeyUp={handleKeyDown}
                onChange={(value) => onChangeInput('vnpay', value)} //onChangeInput
                className="w-full"
                step={100}
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
            <div className="w-1/2">
              <h2 className="font-semibold">Zalo Pay:</h2>
              <InputNumber
                value={form.zalopay}
                name="zalopay"
                onKeyUp={handleKeyDown}
                onChange={(value) => onChangeInput('zalopay', value)} //onChangeInput
                className="w-full"
                step={100}
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
          </div>
          <div className="flex gap-5 items-center">
            <div className="w-1/2">
              <h2 className="font-semibold">Thẻ thanh toán:</h2>
              <InputNumber
                disabled={!paymentCard}
                value={form.thett}
                name="thett"
                onKeyUp={handleKeyDown}
                onChange={(value) => onChangeInput('thett', value)} //onChangeInput
                className="w-full"
                step={100}
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
            <div className="w-1/2">
              {' '}
              {!paymentCard ? (
                <h2 className="font-semibold text-red-500 pt-5">* Chưa kích hoạt thẻ</h2>
              ) : (
                <h2 className="font-semibold  pt-5">
                  Tối đa : {formatNumberVND(paymentCard?.tienVi ?? 0)} VNĐ
                </h2>
              )}
            </div>
          </div>
          <p>
            <i>
              {' '}
              * Gợi ý: chọn ô thanh toán và nhấn phím <span className="font-semibold">P</span> để
              tính tổng tiền{' '}
            </i>
          </p>
        </div>
        <div className="flex py-2 ">
          <h2 className="w-1/2 text-lg font-semibold text-center">
            Tiền thanh toán: {formatNumberVND(form.tienthanhtoan)} VNĐ
          </h2>
          <h2 className="w-1/2 text-lg font-semibold text-center">
            Tổng tiền: {formatNumberVND(form.tongtien)} VNĐ
          </h2>
        </div>
      </form>
    </Modal>
  )
}

export default ModalPay

import { https } from '../apiService'
export const soKhamBenhService = {
  searchBN: (keyword, idct) =>
    https.get(`BenhNhan/SearchBenhNhan`, {
      params: {
        keyword,
        idct,
      },
    }),
  getByIdChinhanhIdct: (idct, idChinhanh) =>
    https.get(`BenhNhan/GetByCondition?idCongTy=${idct}&idChiNhanh=${idChinhanh}`),
}

import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Modal, Select, Input, Button as AntButton } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import PrintIcon from '@mui/icons-material/Print'
import IconButton from '@mui/material/IconButton'
import AttachmentIcon from '@mui/icons-material/Attachment'
import Button from '@mui/material/Button'
import _, { cloneDeep } from 'lodash'
import {
  UpdatePTNhapKhoAction,
  acceptNhapKho,
  addnewDetailNhapKho,
  deleteFileNhap,
  searchThuocVT,
  uploadFileNhap,
} from '../../../../store/actions/NhapKhoAction'
import InfoPhieu from './InfoPhieu'
import TableDetail from './TableDetail'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { https } from '../../../../services/apiService'
import { updateFormDetailNhapKhoSchema } from '../../../../schemas/updateFormDetailNhapKhoSchema'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import { generatePhieuNhapKho } from '../../../../utils/report/phieuNhapKho'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { useFormik } from 'formik'
import * as XLSX from 'xlsx'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

const ModalDetail = ({ isModalOpen, handleCancel, disablePrice }) => {
  const { infoPTNhap, ListThuocVT_update, thuocVT, listDoiTac } = useSelector(
    (state) => state.NhapKhoReducer,
  )
  const { infoUser } = useSelector((state) => state.userReducer)
  const fileInputRef = useRef(null)
  const [show, setShow] = useState(false)
  const [editingKey, setEditingKey] = useState('')
  const [editTable, setEditTable] = useState(false)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const dispatch = useDispatch()

  const maQuyenXuatExcelCt = disablePrice ? 'QHT607' : 'QHT613'
  const maQuyenChuyenTT = disablePrice ? 'QHT611' : 'QHT609'
  const maQuyenCapNhatPhieu = disablePrice ? 'QHT620' : 'QHT614'
  const maQuyenThemHang = disablePrice ? 'QHT621' : 'QHT619'
  const maQuyenInCt = 'QHT612'
  const maQuyenThemFile = 'QHT615'
  const maQuyenXoaFile = 'QHT618'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXuatExcelCt:
        exportToExcel()
        break
      case maQuyenChuyenTT:
        accept()
        break
      case maQuyenCapNhatPhieu:
        setShow(true)
        break
      case maQuyenThemHang:
        const index = ListThuocVT_update?.length
        dispatch(addnewDetailNhapKho(index, infoNew?.value, infoPTNhap?.idNhapXuat, cancel))
        break
      case maQuyenInCt:
        handlePrintPhieuNhap(infoPTNhap, ListThuocVT_update, PkDangNhap)
        break
      case maQuyenThemFile:
        fileInputRef.current.click()
        break
      case maQuyenXoaFile:
        deleteFile(infoNew?.url)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXuatExcelCt:
        break
      case maQuyenChuyenTT:
        break
      case maQuyenCapNhatPhieu:
        break
      case maQuyenThemHang:
        break
      case maQuyenInCt:
        break
      case maQuyenThemFile:
        break
      case maQuyenXoaFile:
        break
      default:
        return false
    }
  }

  //----- UPLOAD FILE-------//
  const handleFileChange = (event) => {
    let file = event.target.files[0]
    const formData = new FormData()
    formData.append('IdNhapXuat', infoPTNhap?.idNhapXuat)
    formData.append('HoaDonFiles', file)
    dispatch(uploadFileNhap(infoPTNhap?.idNhapXuat, formData))
  }
  // search lấy thông tin thuốc vật tư
  const debounceDropDown = useCallback(
    _.debounce((keyword, company) => {
      dispatch(searchThuocVT(keyword, company))
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const handleSearch = (keyword) => {
    const value = keyword || ''
    debounceDropDown(keyword, infoPTNhap?.idct)
  }
  const checkValidate = !(infoUser?.idnv === infoPTNhap?.nhanVienNhan || infoUser?.idnv === 1)
  const handleButtonClick = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenThemFile, null, actionIsTrue, actionIsFalse))
  }
  //-------delete file nhập kho ---------/
  const deleteFile = (url) => {
    if (!checkValidate) {
      const form = {
        idNhapXuat: infoPTNhap?.idNhapXuat,
        hoaDonFiles: url,
      }
      dispatch(deleteFileNhap(infoPTNhap?.idNhapXuat, form))
    }
  }
  //-------- accept nhap kho -------//
  const accept = () => {
    dispatch(acceptNhapKho(infoPTNhap?.idNhapXuat))
  }
  // submit update
  const handleSubmitUpdate = (values) => {
    const fd = new FormData()
    for (let key in values) {
      fd.append(key, values[key])
    }
    dispatch(UpdatePTNhapKhoAction(fd, infoPTNhap?.idNhapXuat))
    setShow(false)
  }
  const cancel = () => {
    setEditTable(false)
    setEditingKey('')
  }
  const onSelectedDoiTac = (value) => {
    formik.setFieldValue('IdDoiTac', value)
  }
  const handleChoose = (value) => {
    dispatch(
      putCheckQuyenThaoTacAction(maQuyenThemHang, { value: value }, actionIsTrue, actionIsFalse),
    )
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      IdNhapXuat: infoPTNhap?.idNhapXuat,
      MaPhieu: infoPTNhap?.maPhieu,
      TenPhieu: infoPTNhap?.tenPhieu || '',
      IdKhoNhap: infoPTNhap?.idKhoNhap,
      NoiDung: infoPTNhap?.noiDung,
      NhanVienNhan: infoPTNhap?.nhanVienNhan,
      NgayNhan: infoPTNhap?.ngayNhan,
      TrangThai: infoPTNhap?.trangThai,
      IdDoiTac: infoPTNhap?.idDoiTac,
      SoHoaDon: infoPTNhap?.soHoaDon,
      NgayHoaDon: infoPTNhap?.ngayHoaDon || '',
      LinkHoaDon: infoPTNhap?.linkHoaDon || '',
      FileHoaDon: infoPTNhap?.fileHoaDon,
      IdHinhThuc: infoPTNhap?.idHinhThuc,
      IdPhuongThuc: infoPTNhap?.idPhuongThuc,
      GhiChu: infoPTNhap?.ghiChu || '',
    },
    validationSchema: updateFormDetailNhapKhoSchema,
    onSubmit: (values) => handleSubmitUpdate(values),
  })

  const exportToExcel = () => {
    // Custom column names
    const headers = [
      'STT',
      'Mã hàng',
      'Tên hàng',
      'SL chẵn',
      'Giá chẵn',
      'Quy cách',
      'SL lẻ',
      'Giá lẻ',
      'Tổng lẻ',
      'Tổng tiền',
      'P.Gia công',
      'P.Vận chuyển',
      '% CK trước VAT',
      'Tiền CK trước VAT',
      'Thành tiền',
      '% VAT',
      'Tiền VAT',
      'Thành tiền',
      'Thực trả',
      'Nhiệt độ',
      'MSDK',
      'Số lô',
      'Hạn dùng',
      'Ghi chú',
    ]
    const formattedData = ListThuocVT_update?.map((items, idx) => ({
      STT: idx + 1,
      'Mã hàng': items.thuocVatTu?.maThuoc,
      'Tên hàng': items.thuocVatTu?.tenBietDuoc,
      'SL chẵn': Math.floor(items.soLuongLe / items.soLuongDongGoi),
      'Giá chẵn': disablePrice ? 0 : formattedNumber(items.soLuongDongGoi * items.donGiaMua),
      'Quy cách': items.quyCachDongGoi,
      'SL lẻ': items.countLe,
      'Giá lẻ': disablePrice ? 0 : formattedNumber(items.donGiaMua),
      'Tổng lẻ': disablePrice ? 0 : items.soLuongLe,
      'Tổng tiền': disablePrice ? 0 : formattedNumber(items.soLuongLe * items.donGiaMua),
      'P.Gia công': disablePrice ? 0 : items.phiGiaCong,
      'P.Vận chuyển': disablePrice ? 0 : items.phiVanChuyen,
      '% CK trước VAT': disablePrice ? 0 : items.ptckTruocVat,
      'Tiền CK trước VAT': disablePrice ? 0 : formattedNumber(Math.round(items.ckTruocVat)),
      'Thành tiền': disablePrice ? 0 : formattedNumber(items.tongTienTruocVAT),
      '% VAT': disablePrice ? 0 : items.ptvat,
      'Tiền VAT': disablePrice
        ? 0
        : formattedNumber(Math.round(items.vat5 + items.vat8 + items.vat10)),
      'Thành tiền': disablePrice ? 0 : formattedNumber(items.thanhTien),
      'Thực trả': disablePrice ? 0 : formattedNumber(Math.round(items.thucTra)),
      'Nhiệt độ': items.nhietDo,
      MSDK: items.maSoDangKy,
      'Số lô': items.soLo,
      'Hạn dùng': items.hanDung ? moment(items.hanDung).format('DD/MM/YYYY') : '-',
      'Ghi chú': items.ghiChu,
    }))
    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, `${infoPTNhap?.maPhieu}.xlsx`)
  }

  const handlePrintPhieuNhap = async (infoPTNhap, ListThuocVT_update, PkDangNhap) => {
    if (infoPTNhap.tenKhoNhap === 'Kho vắc xin') {
      try {
        const result = await https.get(
          `ChiNhanh/GetChiNhanhByID?idchinhanh=${infoPTNhap.idChiNhanhNhan}`,
        )
        let clonePK = cloneDeep(PkDangNhap)
        clonePK = {
          ...clonePK,
          idChuyenKhoa: result.data.idChuyenKhoa,
          diaChi: result.data.diaChi,
          dienThoai: result.data.dienThoai,
          sohotline: result.data.sohotline,
        }
        generatePhieuNhapKho(infoPTNhap, ListThuocVT_update, clonePK)
      } catch (error) {
        console.log(error)
      }
    } else {
      generatePhieuNhapKho(infoPTNhap, ListThuocVT_update, PkDangNhap)
    }
  }

  return (
    <>
      <Modal
        className="text-center"
        open={isModalOpen}
        footer={null}
        onCancel={() => {
          handleCancel()
          setEditingKey('')
          setEditTable(false)
        }}
        width={1400}
      >
        <div className="flex gap-2">
          <div className="w-2/3">
            <div className="flex items-center gap-2">
              <h2 className="font-semibold text-base text-start ">{infoPTNhap?.maPhieu}</h2>
              {!disablePrice && (
                <IconButton
                  onClick={() =>
                    dispatch(
                      putCheckQuyenThaoTacAction(maQuyenInCt, null, actionIsTrue, actionIsFalse),
                    )
                  }
                  color="primary"
                  aria-label="add to shopping cart"
                >
                  <PrintIcon />
                </IconButton>
              )}
            </div>
            <div className="text-start">
              <div className="flex items-center mb-1">
                <label className="w-16 text-gray-500 font-semibold">Đối tác: </label>
                {!show ? (
                  <Input variant="borderless" readOnly value={infoPTNhap?.doiTac?.tenDoiTac} />
                ) : (
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={onSelectedDoiTac}
                    value={formik.values.IdDoiTac}
                    className="w-full"
                    options={listDoiTac?.map((item) => ({
                      label: item.tenDoiTac,
                      value: item.idDoiTac,
                    }))}
                  />
                )}
              </div>
              <Select
                disabled={infoPTNhap?.trangThai !== 5 || checkValidate || disablePrice}
                className="w-full"
                // size="small"
                showSearch
                allowClear
                onChange={handleChoose}
                placeholder="Nhập tên vật tư hàng hoá  "
                value={null}
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                onSearch={handleSearch}
                notFoundContent={null}
                options={(thuocVT || []).map((d) => ({
                  value: d.idThuoc,
                  label: (
                    <u className="flex no-underline items-center">
                      <li className="flex w-[10%]">{d.maThuoc}</li>
                      <li className=" w-[78%] px-1 border-x text-start">
                        <p className="font-semibold">{d.tenBietDuoc}</p>
                        <i className="text-gray-500 text-sm"> Đối tác: {d.tenDoiTac} </i>
                      </li>
                      <li className=" w-[12%] text-end">
                        {formatNumberVND(d.giaMua * d.quyCachDongGoi)} VNĐ
                      </li>
                    </u>
                  ),
                }))}
              />
            </div>
            <div className="mt-2">
              <TableDetail
                disablePrice={disablePrice}
                setEditTable={setEditTable}
                editTable={editTable}
                editingKey={editingKey}
                setEditingKey={setEditingKey}
              />
            </div>
          </div>
          <div className="w-1/3 text-start ">
            <div className="flex gap-2  mt-8 justify-end mb-2">
              <AntButton
                disabled={!ListThuocVT_update?.length}
                // onClick={exportToExcel}
                onClick={() =>
                  dispatch(
                    putCheckQuyenThaoTacAction(
                      maQuyenXuatExcelCt,
                      null,
                      actionIsTrue,
                      actionIsFalse,
                    ),
                  )
                }
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
              >
                Xuất Excel
              </AntButton>
              {!show ? (
                <>
                  <Button
                    disabled={checkValidate || disablePrice}
                    // onClick={() => setShow(true)}
                    onClick={() =>
                      dispatch(
                        putCheckQuyenThaoTacAction(
                          maQuyenCapNhatPhieu,
                          null,
                          actionIsTrue,
                          actionIsFalse,
                        ),
                      )
                    }
                    variant="contained"
                    size="small"
                  >
                    Sửa
                  </Button>
                  <Button
                    disabled={checkValidate}
                    // onClick={accept}
                    onClick={() =>
                      dispatch(
                        putCheckQuyenThaoTacAction(
                          maQuyenChuyenTT,
                          null,
                          actionIsTrue,
                          actionIsFalse,
                        ),
                      )
                    }
                    variant="outlined"
                    size="small"
                    sx={{
                      display: infoPTNhap?.trangThai === 5 ? 'block' : 'none',
                    }}
                  >
                    Nhập kho
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="contained" onClick={formik.handleSubmit} size="small">
                    Lưu
                  </Button>
                  <Button onClick={() => setShow(false)} variant="outlined" size="small">
                    Huỷ bỏ
                  </Button>
                </>
              )}
            </div>
            <div className="border rounded-md min-h-[550px]">
              <InfoPhieu formik={formik} show={show} />
              {!disablePrice && (
                <div>
                  <div className="mb-2 flex justify-end mr-2">
                    <input
                      ref={fileInputRef}
                      id="file-upload"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <Button
                      disabled={checkValidate}
                      onClick={handleButtonClick}
                      size="small"
                      variant="outlined"
                      startIcon={<AttachmentIcon />}
                    >
                      Đính kèm
                    </Button>
                  </div>
                  <ul className="flex gap-2 mb-2 flex-wrap">
                    {infoPTNhap?.fileHoaDon?.split(';').map((url, index) => {
                      const isImage =
                        url.endsWith('.jpg') ||
                        url.endsWith('.jpeg') ||
                        url.endsWith('.png') ||
                        url.endsWith('.gif') ||
                        url.endsWith('.bmp')
                      return (
                        <li key={index} className="relative group ">
                          <a href={url} target="_blank" rel="noopener noreferrer">
                            {isImage ? (
                              <img
                                src={url}
                                alt={`Image ${index + 1}`}
                                className="w-20 transition duration-300 transform group-hover:scale-110 group-hover:z-10"
                              />
                            ) : (
                              <img
                                src="https://www.svgrepo.com/download/144578/pdf.svg"
                                alt={`PDF ${index + 1}`}
                                className="w-20 transition duration-300 transform group-hover:scale-110 group-hover:z-10"
                              />
                            )}
                          </a>
                          <div
                            disabled
                            onClick={() =>
                              dispatch(
                                putCheckQuyenThaoTacAction(
                                  maQuyenXoaFile,
                                  { url: url },
                                  actionIsTrue,
                                  actionIsFalse,
                                ),
                              )
                            }
                            className={`absolute top-0 left-0 bg-white hover:bg-slate-200 p-1 transition duration-300 group-hover:scale-125 rounded ${!checkValidate ? 'text-red-500' : 'text-gray-400'}   cursor-pointer`}
                          >
                            <DeleteOutlined style={{ fontSize: 18 }} />
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalDetail

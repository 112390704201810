import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  LoiDans: [],
  infoLoiDan: null,
}
const LoiDanBsReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_LOI_DAN_DANH_MUC:
        draft.LoiDans = payload
        break
      case typeAction.DISPATCH_INFO_LOI_DAN:
        draft.infoLoiDan = payload
        break
      default:
        return state
    }
  })
}

export default LoiDanBsReducer

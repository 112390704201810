import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ConfigProvider,
  DatePicker,
  Table,
  TreeSelect,
  Select,
  Button,
  Input,
  InputNumber,
} from 'antd'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { reportService } from '../../../../services/Report/reportService'
import { branchService } from '../../../../services/branch/branchService'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { buildFilterCondition } from '../../../../utils/buildFilterCondition'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import { exportExcelformatSumCol } from '../../../../utils/export/exportExcelFormat'
import { formattedNumber } from '../../../../utils/formattedNumber'
import ToastCus from '../../../../utils/Toast'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

const BaoCaoVacXin = () => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [branch, setBranch] = useState([])
  const [listChuyenKhoa, setListChuyenKhoa] = useState([])
  const [chuyenKhoa, setChuyenKhoa] = useState('all')
  const [value, setValue] = useState(['all'])
  const [dataReport, setDataReport] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [search, setSearch] = useState('')
  const [kwChiNhanh, setkwChiNhanh] = useState(null)
  const [kwMaHang, setkwMaHang] = useState(null)
  const [kwTenHang, setkwTenHang] = useState(null)
  const [kwHoatChat, setkwHoatChat] = useState(null)
  const [kwNhomHang, setkwNhomHang] = useState(null)
  const [kwDonVi, setkwDonVi] = useState(null)
  const [filterSoLuong, setfilterSoLuong] = useState('=')
  const [kwSoLuong, setkwSoLuong] = useState(null)
  const [filterDonGia, setfilterDonGia] = useState('=')
  const [kwDonGia, setkwDonGia] = useState(null)
  const [filterThanhTien, setfilterThanhTien] = useState('=')
  const [kwThanhTien, setkwThanhTien] = useState(null)
  const [filterPhanTramGiam, setfilterPhanTramGiam] = useState('=')
  const [kwPhanTramGiam, setkwPhanTramGiam] = useState(null)
  const [filterGiamGia, setfilterGiamGia] = useState('=')
  const [kwGiamGia, setkwGiamGia] = useState(null)
  const [filterThanhToan, setfilterThanhToan] = useState('=')
  const [kwThanhToan, setkwThanhToan] = useState(null)

  const dataDaFilter = dataReport?.filter(
    (item) =>
      (!kwChiNhanh ||
        (item?.chinhanh ?? '')
          ?.toLocaleUpperCase()
          ?.includes((kwChiNhanh ?? '')?.toLocaleUpperCase())) &&
      (!kwMaHang ||
        (item?.mahang ?? '')
          ?.toLocaleUpperCase()
          ?.includes((kwMaHang ?? '')?.toLocaleUpperCase())) &&
      (!kwTenHang ||
        (item?.tenhang ?? '')
          ?.toLocaleUpperCase()
          ?.includes((kwTenHang ?? '')?.toLocaleUpperCase())) &&
      (!kwHoatChat ||
        (item?.hoatchat ?? '')
          ?.toLocaleUpperCase()
          ?.includes((kwHoatChat ?? '')?.toLocaleUpperCase())) &&
      (!kwNhomHang ||
        (item?.nhomhang ?? '')
          ?.toLocaleUpperCase()
          ?.includes((kwNhomHang ?? '')?.toLocaleUpperCase())) &&
      (!kwDonVi ||
        (item?.dvt ?? '')?.toLocaleUpperCase()?.includes((kwDonVi ?? '')?.toLocaleUpperCase())) &&
      (!kwSoLuong || buildFilterCondition(filterSoLuong, kwSoLuong, item?.soluong)) &&
      (!kwDonGia || buildFilterCondition(filterDonGia, kwDonGia, item?.dongia)) &&
      (!kwThanhTien || buildFilterCondition(filterThanhTien, kwThanhTien, item?.thanhtien)) &&
      (!kwPhanTramGiam ||
        buildFilterCondition(filterPhanTramGiam, kwPhanTramGiam, item?.phantramgiam)) &&
      (!kwGiamGia || buildFilterCondition(filterGiamGia, kwGiamGia, item?.giamgia)) &&
      (!kwThanhToan || buildFilterCondition(filterThanhToan, kwThanhToan, item?.thanhtoan)),
  )

  const PhepTinh = [
    { label: '>', value: '>' },
    { label: '<', value: '<' },
    { label: '=', value: '=' },
  ]
  const fetchReport = async () => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT528',
      )
      const { data } = await reportService.getBaoCaoVacXin(
        (value?.includes('all') ? branch : value)
          ?.map((item) => `idChiNhanhs=${value?.includes('all') ? item?.idChiNhanh : item}`)
          ?.join('&')
          ?.toString(),
        dateForm,
        dateTo,
      )
      setDataReport(data)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const getListChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setListChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBranch = async (idct, idck) => {
    try {
      setIsLoading(true)
      const { data } = await branchService.getBranchNotVP(idct)
      if (!idck || idck === 'all') setBranch(data)
      else if (idck) setBranch(data?.filter((item) => item?.idChuyenKhoa === idck))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT529',
      )

      const formatData = dataDaFilter?.map((item, index) => ({
        'STT ': ++index,
        'Chi nhánh': item?.chinhanh,
        'Mã hàng': item?.mahang,
        'Tên hàng': item?.tenhang,
        'Hoạt chất': item?.hoatchat,
        'Nhóm hàng': item?.nhomhang,
        'Đơn vị': item?.dvt,
        'Số lượng': item?.soluong,
        'Đơn giá': formattedNumber(item?.dongia),
        'Thành tiền': formattedNumber(item?.thanhtien),
        'Giảm %': item?.phantramgiam,
        'Giảm giá': formattedNumber(item?.giamgia),
        'Thanh toán': formattedNumber(item?.thanhtoan),
      }))
      const name = 'BÁO CÁO VẮC XIN'
      if (formatData?.length)
        exportExcelformatSumCol(formatData, name, [
          'STT ',
          'Số lượng',
          'Thành tiền',
          'Giảm giá',
          'Thanh toán',
        ])
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const onLoad = () => {
    setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
    fetchReport()
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    getListChuyenKhoa()
    fetchBranch(id_company, chuyenKhoa)
  }, [])
  useEffect(() => {
    if (branch?.length) fetchReport()
  }, [branch])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 40,
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'chinhanh',
      key: 'chinhanh',
      width: 250,
      fixed: 'left',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.chinhanh ?? '').localeCompare(b?.chinhanh ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setkwChiNhanh(e.target.value)}
                  value={kwChiNhanh}
                />
              </ConfigProvider>
            </>
          ),
          fixed: 'left',
          dataIndex: 'chinhanh',
          key: 'search' + 'chinhanh',
          width: 250,
        },
      ],
    },
    {
      title: 'Mã hàng',
      dataIndex: 'mahang',
      key: 'mahang',
      width: 150,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.mahang ?? '').localeCompare(b?.mahang ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setkwMaHang(e.target.value)}
                  value={kwMaHang}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'mahang',
          key: 'search' + 'mahang',
          width: 150,
        },
      ],
    },
    {
      title: 'Tên hàng',
      dataIndex: 'tenhang',
      key: 'tenhang',
      width: 200,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tenhang ?? '').localeCompare(b?.tenhang ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setkwTenHang(e.target.value)}
                  value={kwTenHang}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'tenhang',
          key: 'search' + 'tenhang',
          width: 200,
        },
      ],
    },
    {
      title: 'Hoạt chất',
      dataIndex: 'hoatchat',
      key: 'hoatchat',
      width: 350,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.hoatchat ?? '').localeCompare(b?.hoatchat ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setkwHoatChat(e.target.value)}
                  value={kwHoatChat}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'hoatchat',
          key: 'search' + 'hoatchat',
          width: 350,
        },
      ],
    },
    {
      title: 'Nhóm hàng',
      dataIndex: 'nhomhang',
      key: 'nhomhang',
      width: 200,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.nhomhang ?? '').localeCompare(b?.nhomhang ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setkwNhomHang(e.target.value)}
                  value={kwNhomHang}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'nhomhang',
          key: 'search' + 'nhomhang',
          width: 200,
        },
      ],
    },
    {
      title: 'Đơn vị',
      dataIndex: 'dvt',
      key: 'dvt',
      width: 100,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.dvt ?? '').localeCompare(b?.dvt ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input size="small" onChange={(e) => setkwDonVi(e.target.value)} value={kwDonVi} />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'dvt',
          key: 'search' + 'dvt',
          width: 100,
        },
      ],
    },
    {
      title: 'Số lượng',
      dataIndex: 'soluong',
      key: 'soluong',
      width: 120,
      align: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.soluong ?? 0) - (b?.soluong ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setfilterSoLuong(value)}
                    value={filterSoLuong}
                    options={PhepTinh}
                  />
                  <InputNumber
                    size="small"
                    // type="Number"
                    className="text-xs"
                    value={kwSoLuong}
                    onChange={(value) => setkwSoLuong(value)}
                    // formatter={(value) => {
                    //   const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                    //   return roundedValue
                    //     ? `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    //     : ''
                    // }}
                    // parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    step={100}
                    max={999999999}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'soluong',
          key: 'search' + 'soluong',
          width: 120,
          align: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },
    {
      title: 'Đơn giá',
      dataIndex: 'dongia',
      key: 'dongia',
      width: 150,
      align: 'right',
      // render: (text) => formattedNumber(text ?? 0),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.dongia ?? 0) - (b?.dongia ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setfilterDonGia(value)}
                    value={filterDonGia}
                    options={PhepTinh}
                  />
                  <InputNumber
                    size="small"
                    // type="Number"
                    className="text-xs"
                    value={kwDonGia}
                    onChange={(value) => setkwDonGia(value)}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return roundedValue
                        ? `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : ''
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    step={100}
                    max={999999999}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'dongia',
          key: 'search' + 'dongia',
          width: 150,
          align: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },
    {
      title: 'Thành tiền',
      dataIndex: 'thanhtien',
      key: 'thanhtien',
      width: 150,
      align: 'right',
      // render: (text) => formattedNumber(text ?? 0),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.thanhtien ?? 0) - (b?.thanhtien ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setfilterThanhTien(value)}
                    value={filterThanhTien}
                    options={PhepTinh}
                  />
                  <InputNumber
                    size="small"
                    // type="Number"
                    className="text-xs"
                    value={kwThanhTien}
                    onChange={(value) => setkwThanhTien(value)}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return roundedValue
                        ? `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : ''
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    step={100}
                    max={999999999}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'thanhtien',
          key: 'search' + 'thanhtien',
          width: 150,
          align: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },
    {
      title: '% Giảm',
      dataIndex: 'phantramgiam',
      key: 'phantramgiam',
      width: 120,
      onCell: () => ({
        style: {
          minWidth: 80,
        },
      }),
      align: 'center',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.phantramgiam ?? 0) - (b?.phantramgiam ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setfilterPhanTramGiam(value)}
                    value={filterPhanTramGiam}
                    options={PhepTinh}
                  />
                  <InputNumber
                    size="small"
                    // type="Number"
                    className="text-xs"
                    value={kwPhanTramGiam}
                    onChange={(value) => setkwPhanTramGiam(value)}
                    // formatter={(value) => {
                    //   const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                    //   return roundedValue
                    //     ? `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    //     : ''
                    // }}
                    // parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    step={100}
                    max={999999999}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'phantramgiam',
          key: 'search' + 'phantramgiam',
          width: 120,
          onCell: () => ({
            style: {
              minWidth: 80,
            },
          }),
          align: 'right',
          render: (text) => (text ? `${text.toFixed(2)}%` : '-'),
        },
      ],
    },
    {
      title: 'Giảm giá',
      dataIndex: 'giamgia',
      key: 'giamgia',
      width: 150,
      align: 'right',
      render: (text) => formattedNumber(text ?? 0),

      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.giamgia ?? 0) - (b?.giamgia ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setfilterGiamGia(value)}
                    value={filterGiamGia}
                    options={PhepTinh}
                  />
                  <InputNumber
                    size="small"
                    // type="Number"
                    className="text-xs"
                    value={kwGiamGia}
                    onChange={(value) => setkwGiamGia(value)}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return roundedValue
                        ? `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : ''
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    step={100}
                    max={999999999}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'giamgia',
          key: 'search' + 'giamgia',
          width: 150,
          align: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },
    {
      title: 'Thanh toán',
      dataIndex: 'thanhtoan',
      key: 'thanhtoan',
      width: 150,
      align: 'right',
      fixed: 'right',
      render: (text) => formattedNumber(text ?? 0),

      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.thanhtoan ?? 0) - (b?.thanhtoan ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setfilterThanhToan(value)}
                    value={filterThanhToan}
                    options={PhepTinh}
                  />
                  <InputNumber
                    size="small"
                    // type="Number"
                    className="text-xs"
                    value={kwThanhToan}
                    onChange={(value) => setkwThanhToan(value)}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return roundedValue
                        ? `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : ''
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    step={100}
                    max={999999999}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'thanhtoan',
          key: 'search' + 'thanhtoan',
          width: 150,
          align: 'right',
          fixed: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },
    //   {
    //     title: 'Trạng thái',
    //     dataIndex: 'trangthai',
    //     key: 'trangthai',
    //     width: 150,
    //     align: 'right',
    //     fixed: 'right',
    //     render: (text) => (
    //       <span style={{ color: text === '1' ? 'green' : 'red', fontWeight: 'bold' }}>
    //         {text === '1' ? 'Đã gửi mẫu' : 'Chưa gửi mẫu'}
    //       </span>
    //     ),
    //   },
  ]
  return (
    <div className="p-2 bg-[#EFEFEF] h-full">
      <div className="p-3 bg-white h-full">
        <div className="flex gap-3 border-dotted border-b-0  p-2 ">
          <div className="w-[17rem] flex items-center gap-2">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="w-[17rem]">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranch(value, chuyenKhoa)
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-[17rem]">
            <Select
              showSearch
              value={chuyenKhoa}
              onChange={(value) => {
                setChuyenKhoa(value)
                fetchBranch(company, value)
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                {
                  label: 'Tất cả chuyên khoa',
                  value: 'all',
                },
                ...listChuyenKhoa?.map((item) => ({
                  label: item?.tenChuyenKhoa,
                  value: item?.idChuyenKhoa,
                })),
              ]}
              className="w-full"
            />
          </div>
          <div className="w-[17rem] flex gap-2">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>

          <div className="flex gap-3">
            <IconButton color="primary" onClick={fetchReport} aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
          <div className="flex gap-3 ml-auto">
            <Button
              disabled={isLoading || !dataDaFilter?.length}
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              borderRadius: 0,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            loading={isLoading}
            bordered
            scroll={{
              // x: 2200,
              y: 715,
            }}
            dataSource={dataDaFilter}
            columns={columns}
            pagination={false} // Disable pagination
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7} className="text-right">
                    <span className="font-semibold">
                      {formattedNumber(
                        dataDaFilter?.reduce((total, item) => total + (item?.soluong ?? 0), 0),
                      ) ?? 0}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} className="text-right"></Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right" index={9}>
                    <span className="font-semibold">
                      {formattedNumber(
                        dataDaFilter?.reduce((total, item) => total + (item?.thanhtien ?? 0), 0),
                      ) ?? 0}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right" index={10}></Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right" index={11}>
                    <span className="font-semibold">
                      {formattedNumber(
                        dataDaFilter?.reduce((total, item) => total + (item?.giamgia ?? 0), 0),
                      ) ?? 0}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={12} className="text-right">
                    <span className="font-semibold">
                      {formattedNumber(
                        dataDaFilter?.reduce((total, item) => total + (item?.thanhtoan ?? 0), 0),
                      ) ?? 0}
                    </span>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default BaoCaoVacXin

import { call, put, takeLatest } from 'redux-saga/effects'
import { goikhamkhachhangCRMService } from '../../services/goikhamkhachhangCRM/goikhamkhachhangCRMService'
import ToastCus from '../../utils/Toast'
import * as typeAction from '../constants/constants'

export function* goikhamkhachhangCRMSaga() {
  yield takeLatest(
    typeAction.GET_ALL_GOIKHAM_KHACHHANG_CRM,
    function* allGoiKhamKhachHangCRM({ type, payload }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => goikhamkhachhangCRMService.getAllGoiKhamKhachHangCRM())
        yield put({
          type: typeAction.DISPATCH_ALL_GOIKHAM_KHACHHANG_CRM,
          payload: result.data,
        })
      } catch (err) {
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  //add
  yield takeLatest(
    typeAction.ADD_GOIKHAM_KHACHHANG_CRM,
    function* addGoiKhamKhachHangCRM({ type, form }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => goikhamkhachhangCRMService.addGoiKhamKhachHangCRM(form))
        ToastCus.fire({
          icon: 'success',
          title: 'Thêm mới thành công',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thêm mới thất bại',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  //edit
  yield takeLatest(
    typeAction.EDIT_GOIKHAM_KHACHHANG_CRM,
    function* editGoiKhamKhachHangCRM({ type, form }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => goikhamkhachhangCRMService.editGoiKhamKhachHangCRM(form))
        const result = yield call(() => goikhamkhachhangCRMService.getGoiKhamKhachHangCRMById(form[0].idkh))
        yield put({
          type: typeAction.DISPATCH_INFO_GOIKHAM_KHACHHANG_CRM,
          payload: result.data.result,
        })
        ToastCus.fire({
          icon: 'success',
          title: 'Sửa thông tin thành công',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Sửa thông tin thất bại',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(typeAction.GET_GOIKHAM_KHACHHANG_CRM_BY_ID, function* getById({ type, id }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => goikhamkhachhangCRMService.getGoiKhamKhachHangCRMById(id))
      yield put({
        type: typeAction.DISPATCH_INFO_GOIKHAM_KHACHHANG_CRM,
        payload: result.data.result,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
}

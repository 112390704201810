import { https } from '../apiService'
export const xuatNoiBoService = {
  postPhieuXuatNoiBo: (formData) => https.post(`XuatNoiBo/AddXuatNoiBo`, formData),
  postListVTHH: (list) => https.post(`XuatNoiBoChiTiet/AddXuatNoiBoChiTiet`, list),
  getListPhieuXuat: (keyword, idct, idcn, tuNgay, denNgay, idCa) =>
    https.get(
      `XuatNoiBo/GetAllXuatNoiBo?${keyword?.length ? `keyword=${keyword}&` : ''}idct=${idct}&${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}${idCa}`,
    ), //    https.get(`XuatNoiBo/GetAllXuatNoiBo`, { params: { keyword, idct, idcn, tuNgay, denNgay } }),

  // getInfoPhieuXuat: (IDXUATSD) => https.get(`XuatSuDung/GetByIDXUATSD`, { params: { IDXUATSD } }),
  getListVTHHCuaPhieuXuat: (idxnb) =>
    https.get(`XuatNoiBoChiTiet/GetXuatNoiBoChiTiet`, { params: { idxnb } }),
  getListVTHHDaXuat: (keyword, idct, idcn, tuNgay, denNgay) =>
    https.get(`XuatNoiBo/GetHangHoaXuatNoiBo`, {
      params: { keyword, idct, idcn, tuNgay, denNgay },
    }),
}

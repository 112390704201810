import React from "react";
import { Spin } from "antd";
import { useSelector } from "react-redux";
const LoadingPage = () => {
    const {isLoadingPage} = useSelector(state => state.modalReducer)
  return (
    <>
      <Spin  size="large"  spinning={isLoadingPage} fullscreen />
    </>
  );
};

export default LoadingPage;

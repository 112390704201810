import { call, select, put, takeLatest } from 'redux-saga/effects'
import { xetNghiemService } from '../../services/xetNghiem/xetNghiemService'
import { phanQuyenService } from '../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../actions/phanQuyenAction'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
import Swal from 'sweetalert2'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* xetNghiemSaga() {
  yield takeLatest(
    typeAction.GET_LIST_DANH_MUC_XET_NGHIEM,
    function* listDanhMucXetNghiem({ type }) {
      try {
        const result = yield call(() => xetNghiemService.listDanhMucXetNghiem())
        yield put({
          type: typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )

  yield takeLatest(
    typeAction.GET_LIST_DANH_MUC_XET_NGHIEM_THEO_CT,
    function* listDanhMucXetNghiem_CT({ type, idct }) {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        const result = yield call(() => xetNghiemService.listDanhMucXetNghiem_CT(idct))
        yield put({
          type: typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  yield takeLatest(
    typeAction.GET_LIST_DANH_MUC_XET_NGHIEM_THEO_CT_NHOMXN,
    function* listDanhMucXetNghiem_CT_NhomXn({ type, idnhomxn, idct }) {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        const result = yield call(() =>
          xetNghiemService.listDanhMucXetNghiemTheoCT_NHOMXN(idnhomxn, idct),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // GET LIST GROUPS XET NGHIỆM
  yield takeLatest(typeAction.GET_LIST_GROUPS_XETNGHIEM, function* listGroupsXetNghiem({ type }) {
    try {
      const result = yield call(() => xetNghiemService.listGroupsXetNghiem())
      yield put({
        type: typeAction.DISPATCH_GROUPS_XETNGHIEM,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // SEARCH XET NGHIEM BY GROUPS KEYWORD
  yield takeLatest(
    typeAction.SEARCH_XET_NGHIEM_BY_GROUPS_KEYWORD,
    function* listXetNghiemByGroupsKeyword({ type, keyword, idnhomxn }) {
      try {
        const result = yield call(() => xetNghiemService.searchByGroupsKeyword(keyword, idnhomxn))
        yield put({
          type: typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )

  yield takeLatest(
    typeAction.SEARCH_XET_NGHIEM_BY_GROUPS_AND_CTY_KEYWORD,
    function* listXetNghiemByGroupsKeyword({ type, keyword, idnhomxn, idct }) {
      try {
        const result = yield call(() =>
          xetNghiemService.searchByGroupsAndCtyKeyword(keyword, idnhomxn, idct),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )

  yield takeLatest(
    typeAction.SEARCH_XET_NGHIEM_BY_CTY_KEYWORD,
    function* listXetNghiemByGroupsKeyword({ type, keyword, idct }) {
      try {
        const result = yield call(() => xetNghiemService.searchByCtyKeyword(keyword, idct))
        yield put({
          type: typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )

  // // SEARCH XET NGHIEM BY GROUPS
  // yield takeLatest(
  //   typeAction.SEARCH_XET_NGHIEM_BY_GRUOPS,
  //   function* listCetNghiemByGroups({ type, idGroups }) {
  //     try {
  //       const result = yield call(() => xetNghiemService.searchByGroups(idGroups))
  //       yield put({
  //         type: typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM,
  //         payload: result.data,
  //       })
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   },
  // )

  //CHI TIET XET NGHIEM
  yield takeLatest(typeAction.GET_INFO_XET_NGHIEM, function* infoXetNgiem({ type, idxn }) {
    try {
      const result = yield call(() => xetNghiemService.fetchInfoXetNghiem(idxn))
      yield put({
        type: typeAction.DISPATCH_INFO_XET_NGHIEM,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // ADD NEW DANH MỤC XÉT NGHIỆM
  yield takeLatest(
    typeAction.POST_NEW_DANH_MUC_XET_NGHIEM,
    function* createDMXetNghiem({ type, form, onLoad }) {
      try {
        yield call(() => xetNghiemService.postDMXetNghiem(form))
        yield onLoad()
        Toast.fire({
          icon: 'success',
          title: 'Tạo thành công!',
        })
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Tạo thất bại!',
        })
      }
    },
  )
  // DELETE DANH MỤC XÉT NGHIÊM
  yield takeLatest(
    typeAction.DELETE_DANH_MUC_XET_NGHIEM,
    function* deleteDMXetNghiem({ type, idxn, onLoad }) {
      try {
        yield call(() => xetNghiemService.deleteDMXetNghiem(idxn))
        yield onLoad()
        Toast.fire({
          icon: 'success',
          title: 'Xóa thành công!',
        })
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Xóa thất bại!',
        })
      }
    },
  )
  // UPDATE DANH MỤC XÉT NGHIÊM
  yield takeLatest(
    typeAction.UPDATE_DANH_MUC_XET_NGHIEM,
    function* updateDMXetNghiem({ type, form, onLoad }) {
      try {
        yield call(() => xetNghiemService.putDMXetNghiem(form))
        yield onLoad()
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật thành công!',
        })
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật thất bại!',
        })
      }
    },
  )
  //---------- update bệnh nhân xét nghiêm -------------//
  yield takeLatest(
    typeAction.UPDATE_BN_XET_NGHIEM,
    function* updateBNXetNghiem({ type, form, onLoad }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT313'),
        )

        yield call(() => xetNghiemService.updateBNXN(form))
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_XN,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_HISTORY_XET_NGHIEM,
          payload: [],
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        yield call(() => onLoad())
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (error) {
        console.error('Error : ', error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      }
    },
  )
  //------------- LẤY TẤT CẢ THÔNG TINH CỦA BỆNH NHÂN XÉT NGHIỆM ----------
  yield takeLatest(
    typeAction.GET_INFO_BN_XET_NGHIEM,
    function* infoBNXetNghiem({ type, idbn, idbnttc }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => xetNghiemService.getHistory(idbn))
        const infoDetail = yield call(() => xetNghiemService.getDetailXN(idbnttc))
        const listVtthBn = infoDetail?.data?.DanhSachVatTuTieuHao?.filter(
          (item) => item?.idXetNghiem,
        )
        yield put({
          type: typeAction.DISPATCH_HISTORY_XET_NGHIEM,
          payload: result.data,
        })
        // const { DanhSachXetNghiem, ...other } = infoDetail.data;
        // const newData = DanhSachXetNghiem.map(items => ({ ...items, }));
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_XN,
          payload: { ...infoDetail.data, DanhSachVatTuTieuHao: listVtthBn },
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      } catch (error) {
        console.log(error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  //get by condition
  yield takeLatest(
    typeAction.GET_XET_NGHIEM_BY_CONDITION,
    function* getXNByCondition({ type, filter }) {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        const condition = Object.values(filter).filter(Boolean).join('')
        const { data } = yield call(() => xetNghiemService.getXetNghiemByCondition(condition))

        yield put({
          type: typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM,
          payload: data,
        })
      } catch (error) {
        console.log(error)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ConfigProvider, DatePicker, Input, Select, Table, TreeSelect } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import * as XLSX from 'xlsx'
import { branchService } from '../../../../../services/branch/branchService'
import { khoVTservice } from '../../../../../services/khoVT/khoVTservice'
import { reportService } from '../../../../../services/Report/reportService'
import { https } from '../../../../../services/apiService'
import axios from 'axios'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { SearchOutlined } from '@ant-design/icons'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import {
  exportExcelformat,
  exportExcelformatSumCol,
} from '../../../../../utils/export/exportExcelFormat'
import _ from 'lodash'
import dayjs from 'dayjs'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/vi_VN'
import 'moment/locale/vi'
import 'dayjs/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

function SuDung() {
  const dispatch = useDispatch()
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState(['all'])
  const [dataReport, setDataReport] = useState([])
  const [listChuyenKhoa, setListChuyenKhoa] = useState([])
  const [chuyenKhoa, setChuyenKhoa] = useState('all')
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateFrom, setDateFrom] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [search, setSearch] = useState('')

  // const tongSTTT = dataReport?.reduce((tong, item) => (tong += item?.tongsotoatuantruoc), 0)
  // const tongST = dataReport?.reduce((tong, item) => (tong += item?.tongsotoa), 0)
  // const tongTLCLTongST = Math.round((((tongST - tongSTTT) * 100) / tongSTTT) * 10) / 10
  // const tongTCLTTT = dataReport?.reduce(
  //   (tong, item) => (tong += item?.tongsotoacolaythuoctuantruoc),
  //   0,
  // )
  // const tongTCLT = dataReport?.reduce((tong, item) => (tong += item?.tongsotoacolaythuoc), 0)
  // const tongTLCLTongTCLT = Math.round((((tongTCLT - tongTCLTTT) * 100) / tongTCLTTT) * 10) / 10

  const tinhThu2CuaNgayBatKy = (date) => {
    const inputDate = moment(date)
    const dayOfWeek = inputDate.day()
    const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1
    return inputDate.subtract(daysToSubtract, 'days').format('YYYY-MM-DD')
  }
  const fetchReport = async () => {
    try {
      setIsLoading(true)
      const { data } = await reportService.getBaoCaoTinhHinhSuDung(
        (value?.includes('all') || !value?.length ? branch : value)
          ?.map(
            (item) => `idcn=${value?.includes('all') || !value?.length ? item?.idChiNhanh : item}`,
          )
          ?.join('&')
          ?.toString(),
        dateFrom,
        dateTo,
      )
      setDataReport(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getListChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setListChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBranch = async (idct, idck) => {
    try {
      setIsLoading(true)
      const { data } = await branchService.getBranchNotVP(idct)
      if (!idck || idck === 'all') setBranch(data)
      else if (idck) setBranch(data?.filter((item) => item?.idChuyenKhoa === idck))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = () => {
    const formatData = dataReport?.map((item, index) => ({
      'STT ': ++index,
      'Tên chi nhánh': item?.tenchinhanh,
      'Tên vắc xin': item?.tenbietduoc,
      'Nhóm hàng': item?.loaivacxin,
      'Tên nhà sản xuất': item?.nhasanxuat,
      'Tên nhà cung cấp': item?.tennhacungcap,
      'Số lô': item?.solo,
      'Mã số đăng ký': item?.masodangky,
      'Hạn dùng': item?.handung ? moment(item?.handung).format('DD/MM/YYYY') : '-',
      'Số tồn tháng trước': item?.tonthangtruoc, //formattedNumber(item?.tonthangtruoc ?? 0),
      'Số nhập': item?.sonhap, //formattedNumber(item?.sonhap ?? 0),
      'Số tiêm': item?.sotiem, //formattedNumber(item?.sotiem ?? 0),
      'Số hủy': item?.sohuy, //formattedNumber(item?.sohuy ?? 0),
      'Số sử dụng': item?.sosudung, //formattedNumber(item?.sosudung ?? 0),
      'Số hiện còn': item?.sohiencon, //formattedNumber(item?.sohiencon ?? 0),
    }))
    const name = 'BÁO CÁO TÌNH HÌNH SỬ DỤNG VẮC XIN'
    if (formatData?.length)
      exportExcelformatSumCol(formatData, name, [
        'Số tồn tháng trước',
        'Số nhập',
        'Số tiêm',
        'Số hủy',
        'Số sử dụng',
        'Số hiện còn',
      ])
  }
  const onLoad = () => {
    // setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
    if (!value?.length) setValue(['all'])
    fetchReport()
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    getListChuyenKhoa()
    fetchBranch(id_company, chuyenKhoa)
  }, [])

  // useEffect(() => {//load lần đầu
  //   if (branch?.length) fetchReport()
  // }, [branch])

  // useEffect(() => {
  //   ;(async () => {
  //     try {
  //       const { data } = await axios.get(
  //         'http://192.168.1.250:4343/api/CaLamViec/Mobile/GetDanhSachDangKy?idNhanVien=4783&deviceID=8491B6C1-8EFF-4D71-8A1C-720589C53D71',
  //       )

  //       console.log(data)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   })()
  // }, [])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Tên chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      fixed: 'left',
      // width: 250,
      render: (text, record, index) => <p className="min-w-[3rem]">{text}</p>,
    },
    {
      title: 'Tên vắc xin',
      dataIndex: 'tenbietduoc',
      key: 'tenbietduoc',
      fixed: 'left',
      render: (text, record, index) => <p className="min-w-[3rem]">{text}</p>,
    },
    {
      title: 'Nhóm hàng',
      dataIndex: 'loaivacxin',
      key: 'loaivacxin',
      render: (text, record, index) => <p className="min-w-[2.5rem]">{text}</p>,
    },
    {
      title: 'Tên nhà sản xuất',
      dataIndex: 'nhasanxuat',
      key: 'nhasanxuat',
      render: (text, record, index) => <p className="min-w-[3.5rem]">{text}</p>,
    },
    {
      title: 'Tên nhà cung cấp',
      dataIndex: 'tennhacungcap',
      key: 'tennhacungcap',
      render: (text, record, index) => <p className="min-w-[3.5rem]">{text}</p>,
    },
    {
      title: 'Số lô',
      dataIndex: 'solo',
      key: 'solo',
      render: (text, record, index) => <p className="min-w-[2.5rem]">{text}</p>,
    },
    {
      title: 'Mã số đăng ký',
      dataIndex: 'masodangky',
      key: 'masodangky',
      render: (text, record, index) => <p className="min-w-[3rem]">{text}</p>,
    },
    {
      title: 'Hạn dùng',
      dataIndex: 'handung',
      key: 'handung',
      align: 'center',
      render: (text, record, index) => (
        <p className="min-w-[2rem]">{text ? moment(text).format('DD/MM/YYYY') : '-'}</p>
      ),
    },
    {
      title: 'Số tồn tháng trước',
      dataIndex: 'tonthangtruoc',
      key: 'tonthangtruoc',
      align: 'center',
      width: 140,
    },
    {
      title: 'Số nhập',
      dataIndex: 'sonhap',
      key: 'sonhap',
      align: 'center',
      width: 80,
    },
    {
      title: 'Số tiêm',
      dataIndex: 'sotiem',
      key: 'sotiem',
      align: 'center',
      width: 80,
    },
    {
      title: 'Số hủy',
      dataIndex: 'sohuy',
      key: 'sohuy',
      align: 'center',
      width: 80,
    },
    {
      title: 'Số sử dụng',
      dataIndex: 'sosudung',
      key: 'sosudung',
      align: 'center',
      width: 110,
    },
    {
      title: 'Số hiện còn',
      dataIndex: 'sohiencon',
      key: 'sohiencon',
      align: 'center',
      width: 110,
    },
    // {
    //   title: 'Ghi chú',
    //   dataIndex: 'tennhom',
    //   key: 'tennhom',
    // },
    // {
    //   title: 'Thông tin vắc xin',
    //   dataIndex: 'thongtinvacxin',
    //   key: 'thongtinvacxin',
    //   children: [
    //     {
    //       title: 'Mã hàng',
    //       dataIndex: 'mahang',
    //       key: 'mahang',
    //       // align: 'center',
    //       width: 120,
    //     },
    //     {
    //       title: 'Tên vắc xin',
    //       dataIndex: 'tenvacxin',
    //       key: 'tenvacxin',
    //     },
    //   ],
    // },
  ]

  return (
    <>
      <div className="flex gap-2">
        <div className="w-[17rem] flex justify-between items-start gap-1">
          <DatePicker
            placeholder="Từ ngày..."
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={(date, dateString) =>
              setDateFrom(moment(dateString, dateFormat).format(momentDate))
            }
            format={dateFormat}
            locale={{ ...locale, week: { start: 1 } }}
          />
          <div className="font-semibold text-2xl flex items-start">-</div>
          <DatePicker
            placeholder="Đến ngày..."
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={(date, dateString) =>
              setDateTo(moment(dateString, dateFormat).format(momentDate))
            }
            format={dateFormat}
            locale={{ ...locale, week: { start: 1 } }}
          />
        </div>
        <div className="w-[17rem]">
          <Select
            showSearch
            value={company}
            onChange={(value) => {
              setCompany(value)
              fetchBranch(value, chuyenKhoa)
              setValue('all')
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className="w-full"
          />
        </div>
        <div className="w-[17rem]">
          <Select
            showSearch
            value={chuyenKhoa}
            onChange={(value) => {
              setChuyenKhoa(value)
              fetchBranch(company, value)
              setValue('all')
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                label: 'Tất cả chuyên khoa',
                value: 'all',
              },
              ...listChuyenKhoa?.map((item) => ({
                label: item?.tenChuyenKhoa,
                value: item?.idChuyenKhoa,
              })),
            ]}
            className="w-full"
          />
        </div>
        <div className="w-[17rem] flex gap-2">
          <TreeSelect
            {...tProps}
            style={{ flexWrap: 'unset' }}
            className="w-full h-8 overflow-clip"
          />
        </div>
        {/* <div className="flex w-[17rem]">
          <Input
            value={search}
            placeholder="Nhập mã hàng, tên hàng..."
            onChange={(e) => setSearch(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </div> */}
        <div className="flex gap-2">
          <IconButton
            disabled={isLoading}
            onClick={onLoad}
            color="primary"
            aria-label="delete"
            size="small"
          >
            <SyncIcon />
          </IconButton>
        </div>
        <div className="font-semibold ">
          <Button
            disabled={isLoading || !dataReport?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <div className="mt-2">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              x: dataReport?.length && 'max-content',
              y: '68.7vh',
            }}
            pagination={false}
            loading={isLoading}
            bordered
            // dataSource={dataReport.concat(dataReport)}
            dataSource={dataReport}
            columns={columns}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row className="text-center font-semibold">
                  {columns?.map((item, index) => (
                    <Table.Summary.Cell index={index}>
                      {item?.key === 'tonthangtruoc' &&
                        dataReport?.reduce((tong, item) => (tong += item?.tonthangtruoc), 0)}
                      {item?.key === 'sonhap' &&
                        dataReport?.reduce((tong, item) => (tong += item?.sonhap), 0)}
                      {item?.key === 'sotiem' &&
                        dataReport?.reduce((tong, item) => (tong += item?.sotiem), 0)}
                      {item?.key === 'sohuy' &&
                        dataReport?.reduce((tong, item) => (tong += item?.sohuy), 0)}
                      {item?.key === 'sosudung' &&
                        dataReport?.reduce((tong, item) => (tong += item?.sosudung), 0)}
                      {item?.key === 'sohiencon' && (
                        <p className="pr-[1rem]">
                          {dataReport?.reduce((tong, item) => (tong += item?.sohiencon), 0)}
                        </p>
                      )}
                    </Table.Summary.Cell>
                  ))}
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default SuDung

import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import { Button, ConfigProvider, DatePicker, Select, Table, TreeSelect } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import { reportService } from '../../../../../services/Report/reportService'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { debounce } from 'lodash'
import { https } from '../../../../../services/apiService'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

const LoaiKham = ({ ChuyenKhoa }) => {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [value, setValue] = useState([BranchLogin])
  const [data, setData] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const dispatch = useDispatch()
  const [reloadTrigger, setReloadTrigger] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [count, setCount] = useState({
    tongLanDau: 0,
    tongTaiKham: 0,
    tongBenhMoi: 0,
    tong: 0,
  })
  const [loadingExport, setLoadingExport] = useState(false)

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const onLoad = (page) => {
    fetchReport(page)
  }
  //-------- export excel-----------//
  const exportToExcel = async (company, idChuyenKhoa, value, dateForm, dateTo) => {
    setLoadingExport(true)
    try {
      const headers = [
        'STT',
        'Ngày',
        'Chi nhánh',
        'Khám lần đầu',
        'Khám bệnh mới',
        'Tái khám',
        'Tổng lượt khám',
      ]
      let dataExport = []
      let idChiNhanhs = null
      if (value?.length > 0 && value[0] !== 'all') {
        // Giả sử `value` là mảng các ID. Chuyển đổi mảng thành chuỗi truy vấn
        idChiNhanhs = value?.map((id) => `&idChiNhanhs=${id}`).join('&')
        const { data } = await https.get(
          `ThongKeLuotKham/ThongKeLoaiKhamExcel?idCongTy=${company}&idChuyenKhoa=${idChuyenKhoa}&fromDate=${dateForm}&toDate=${dateTo}${idChiNhanhs}`,
        )
        dataExport = data
      } else {
        const { data } = await https.get(
          `ThongKeLuotKham/ThongKeLoaiKhamExcel?idCongTy=${company}&idChuyenKhoa=${idChuyenKhoa}&fromDate=${dateForm}&toDate=${dateTo}`,
        )
        dataExport = data
      }
      const formattedData = dataExport.map((item, idx) => ({
        STT: idx + 1,
        Ngày: item?.ngay ? moment(item?.ngay).format('DD/MM/YYYY') : '-',
        'Chi nhánh': item?.tenChiNhanh,
        'Khám lần đầu': item?.khamLanDau ? item?.khamLanDau : 0,
        'Khám bệnh mới': item?.khamBenhMoi ? item?.khamBenhMoi : 0,
        'Tái khám': item?.taiKham ? item?.taiKham : 0,
        'Tổng lượt khám': item?.total ? item?.total : 0,
      }))
      const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
      XLSX.writeFile(wb, 'Báo cáo loại khám.xlsx')
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  // ------------ lấy danh sách report theo chi nhánh ------------//
  const fetchReport = async (page) => {
    setIsLoading(true)
    try {
      let idChiNhanhs = null
      if (value?.length > 0 && value[0] !== 'all') {
        // Giả sử `value` là mảng các ID. Chuyển đổi mảng thành chuỗi truy vấn
        idChiNhanhs = value?.map((id) => `&idChiNhanhs=${id}`).join('&')
        const { data } = await reportService.getThongKeLoaiKham(
          company,
          idChuyenKhoa,
          idChiNhanhs,
          dateForm,
          dateTo,
          page,
        )
        setTotalPages(data.totalPage)
        setData((prev) => [...prev, ...data.data])
        setCount({
          tongLanDau: data.tongLanDau,
          tongTaiKham: data.tongTaiKham,
          tongBenhMoi: data.tongBenhMoi,
          tong: data.tong,
        })
      } else {
        const { data } = await reportService.getThongKeLoaiKham(
          company,
          idChuyenKhoa,
          '',
          dateForm,
          dateTo,
          page,
        )
        setTotalPages(data.totalPage)
        setData((prev) => [...prev, ...data.data])
        setCount({
          tongLanDau: data.tongLanDau,
          tongTaiKham: data.tongTaiKham,
          tongBenhMoi: data.tongBenhMoi,
          tong: data.tong,
        })
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      key: '0-1',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
        key: `0-1-${index}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranchByChuyenKhoa('')
  }, [])

  useEffect(() => {
    fetchReport(page)
  }, [page, reloadTrigger])

  const handleScroll = useCallback(
    debounce(() => {
      //api search return all data not pagination
      // disable scroll when search filtering
      const tableBody = document.querySelector('.table-luotkham-loaikham .ant-table-body')
      if (
        tableBody.scrollTop + tableBody.clientHeight >= tableBody.scrollHeight - 50 &&
        !isLoading
      ) {
        if (page + 1 > totalPages) {
          return
        }
        setPage((prevPage) => prevPage + 1)
      }
    }, 300),
    [isLoading],
  )

  useEffect(() => {
    const tableBody = document.querySelector('.table-luotkham-loaikham .ant-table-body')
    tableBody.addEventListener('scroll', handleScroll)
    return () => tableBody.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  const handleReload = () => {
    setData([])
    setPage(1)
    setReloadTrigger((prev) => prev + 1)
  }

  return (
    <>
      <div className="flex gap-5 justify-between border-dotted border-b-0 py-2 ">
        <div className="flex gap-5">
          <div className="w-80">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranchByChuyenKhoa('')
                setIdChuyenKhoa('')
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>

          <div className="w-64 flex gap-1">
            <Select
              showSearch
              value={idChuyenKhoa}
              onChange={onChangeChuyenKhoa}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { label: 'Tất cả', value: '' },
                ...ChuyenKhoa.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
              className="w-80"
            />
          </div>
          <div className="w-64 flex gap-1">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="flex gap-3">
            <IconButton onClick={handleReload} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
          <div className="font-semibold ">
            <Button
              onClick={() => exportToExcel(company, idChuyenKhoa, value, dateForm, dateTo)}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>

      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              x: 1500,
              y: '66vh',
            }}
            className="table-luotkham-loaikham"
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={data}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 40,
                align: 'center',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                width: 120,
                render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
              },
              {
                title: 'Chi nhánh',
                dataIndex: 'tenChiNhanh',
                key: 'tenChiNhanh',
                width: 250,
              },
              {
                title: 'Loại khám',
                key: 1,
                children: [
                  {
                    title: 'Lần đầu',
                    key: 1.1,
                    dataIndex: 'khamLanDau',
                    width: 100,
                    align: 'center',
                  },
                  {
                    title: 'Bệnh mới',
                    key: 1.1,
                    dataIndex: 'khamBenhMoi',
                    width: 100,
                    align: 'center',
                  },
                  {
                    title: 'Tái khám',
                    key: 1.1,
                    dataIndex: 'taiKham',
                    width: 100,
                    align: 'center',
                  },
                ],
              },

              //   {
              //     title: 'Ca 2',
              //     key: 1,
              //     children: [
              //       {
              //         title: 'Số lượt',
              //         key: 1.1,
              //         dataIndex: 'luotkhamcA2',
              //         width:100,
              //         align:'center'
              //       },
              //       {
              //         title: 'Chiếm %',
              //         key: 1.2,
              //         dataIndex: 'phantramcA2',
              //         render: (text) => (
              //           <Progress
              //             percent={text}
              //             percentPosition={{
              //               align: 'center',
              //               type: 'inner',
              //             }}
              //             size={[100, 20]}
              //           />
              //         ),
              //       },
              //     ],
              //   },
              //   {
              //     title: 'Ca 3',
              //     key: 1,
              //     children: [
              //       {
              //         title: 'Số lượt',
              //         key: 1.1,
              //         dataIndex: 'luotkhamcA3',
              //         width:100,
              //         align:'center'
              //       },
              //       {
              //         title: 'Chiếm %',
              //         key: 1.2,
              //         dataIndex: 'phantramcA3',
              //         render: (text) => (
              //           <Progress
              //             percent={text}
              //             percentPosition={{
              //               align: 'center',
              //               type: 'inner',
              //             }}
              //             size={[100, 20]}
              //           />
              //         ),
              //       },
              //     ],
              //   },
              {
                title: 'Tổng lượt',
                dataIndex: 'total',
                align: 'center',
                key: 'total',
                width: 100,
              },
            ]}
            summary={(pageData) => {
              let lanDau = 0
              let benhMoi = 0
              let TaiKham = 0
              let totalCount = 0

              pageData.forEach(({ khamLanDau, khamBenhMoi, taiKham, total }) => {
                lanDau += khamLanDau
                benhMoi += khamBenhMoi
                TaiKham += taiKham
                totalCount += total
              })
              // const cells = Array.from({ length: 10 }, (_, index) => (
              //   <Table.Summary.Cell key={index} align="center" index={index}>
              //     <p className={`font-semibold ${index === 6 && 'pr-[1.05rem]'}`}>
              //       {index === 3
              //         ? count.tongLanDau
              //         : index === 4
              //           ? count.tongBenhMoi
              //           : index === 5
              //             ? count.tongTaiKham
              //             : index === 6
              //               ? count.tong
              //               : ''}
              //     </p>
              //   </Table.Summary.Cell>
              // ))
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row className="text-blue-500 font-semibold">
                    <Table.Summary.Cell index={0} colSpan={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="center">
                      {lanDau}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="center">
                      {benhMoi}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="center">
                      {TaiKham}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="center">
                      {totalCount}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default LoaiKham

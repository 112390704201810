import React, { useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import * as typeAction from '../../../../store/constants/constants'
import CachedIcon from '@mui/icons-material/Cached'
import { Tooltip, Select, ConfigProvider, Table, Button, Input, Tag, DatePicker } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { ContainerOutlined } from '@ant-design/icons'
import { branchTonKhoAction, listTonKho_Action } from '../../../../store/actions/thuocVTAction'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import moment from 'moment'
import { https } from '../../../../services/apiService'
import { buildFilterCondition } from '../../../../utils/buildFilterCondition'
import dayjs from 'dayjs'
import { buildDateFilterCondition } from '../../../../utils/buildDateFilterCondition'
import { formatNumber } from 'devextreme/localization'
import { useSearchParams } from 'react-router-dom'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import 'moment/locale/vi'
import { getTonKiemKe } from '../../../../store/actions/kiemKeAction'
import exportExcelKiemKe from '../../../../utils/export/exportKiemKe'
moment.locale('vi')
const today = moment()
const formatDate = 'DD/MM/YYYY'
const formatDataDB = 'YYYY-MM-DD'
const Products = () => {
  const { kyHang } = useSelector((state) => state.thuocVTReducer)
  const id_company = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { isLoadingTable } = useSelector((state) => state.modalReducer)
  const { listThuocVT, branchTonKho, tonKiemKe } = useSelector((state) => state.thuocVTReducer)
  const ROLE_KHO_VIEWS = [41, 107, 108, 68, 61, 69, 225, 125, 136, 147, 255, 187, 197] // chỉ xem được các loại kho này
  const ID_NHOM_LE_TAN = 3
  const { listCompany } = useSelector((state) => state.companyReducer)
  const idBranchDefault = Number(localStorage.getItem('BRANH_LOGIN'))
  const [idChiNhanh, setIdChiNhanh] = useState(idBranchDefault)
  const [loading, setLoading] = useState(false)
  const [company, setCompany] = useState(id_company)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [listKho, setListKho] = useState([])
  const [khoCN, setKhoCN] = useState(null)
  const [searchMa, setSearchMa] = useState('')
  const [searchTenThuoc, setSearchTenThuoc] = useState('')
  const [searchHoatChat, setSearchHoatChat] = useState('')
  const [searchDonVi, setDonVi] = useState('')
  const [searchQuyCach, setSearchQuyCach] = useState('')
  const [searchBranch, setSearchBranch] = useState('')
  const [searchKho, setSearchKho] = useState('')
  const [filterXuat, setfilterXuat] = useState('=')
  const [searchXuat, setSearchXuat] = useState('')
  const [filterNhap, setfilterNhap] = useState('=')
  const [searchNhap, setSearchNhap] = useState('')
  const [searchSLChan, setSearchSLChan] = useState('')
  const [filterTon, setfilterTon] = useState('=')
  const [searchTon, setSearchTon] = useState('')
  const [searchSoLo, setSearchSoLo] = useState('')
  const [searchHD, setSearchHD] = useState([])
  const [searchDate, setSearchDate] = useState('')
  const [filterDate, setFilterDate] = useState('=')
  const [slThucTe, setSLthucTe] = useState('')
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [dataTo, setDateTo] = useState(today.format(formatDataDB))
  const [searchText, setSearchText] = useState(null)
  const [idky, setIdky] = useState(null)
  const dispatch = useDispatch()

  const maQuyenXemDs = 'QHT234'
  const maQuyenXuatExcel = 'QHT235'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        dispatch(getTonKiemKe(form))
        break
      case maQuyenXuatExcel:
        dataSource?.length && exportExcelKiemKe(dataSource)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXuatExcel:
        //
        break
      default:
        return false
    }
  }

  const handleSearch = (name) => (e) => {
    const { value } = e.target
    switch (name) {
      case 'ma':
        setSearchMa(value || '')
        break
      case 'tenBietDuoc':
        setSearchTenThuoc(value || '')
        break
      case 'tenHoatChat':
        setSearchHoatChat(value || '')
        break
      case 'dvt':
        setDonVi(value || '')
        break
      case 'quyCach':
        setSearchQuyCach(value || '')
        break
      case 'tongXuat':
        setSearchXuat(value || '')
        break
      case 'tongNhap':
        setSearchNhap(value || '')
        break
      case 'slChan':
        setSearchSLChan(value || '')
        break
      case 'tonKho':
        setSearchTon(value || '')
        break
      case 'soLo':
        setSearchSoLo(value || '')
        break
      case 'soHoaDon':
        setSearchHD(value || '')
        break
      case 'tonkhothucte':
        setSLthucTe(value || '')
        break
      default:
        break
    }
  }
  const handleSearchHD = (value) => {
    setSearchHD(value || '')
  }
  const handleSelected = (value) => {
    setSearchBranch(value || '')
  }
  const handleSearchKho = (value) => {
    setSearchKho(value || '')
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const infoTonKho = (info) => {
    dispatch({
      type: typeAction.DISPATCH_INFO_TON_kHO,
      payload: info,
    })
  }
  const handleCompany = (value) => {
    setCompany(value)
    dispatch(branchTonKhoAction(value))
    setIdChiNhanh(null)
  }

  const filteredData = tonKiemKe?.data?.filter(
    ({
      thuocVatTu,
      tenCN,
      tenKhoCn,
      soHoaDon,
      tongXuat,
      tongNhap,
      tonKho,
      soLo,
      hanDung,
      tonkhothucte,
    }) => {
      const maLower = searchMa.trim().toLowerCase()
      const tenLower = searchTenThuoc.trim().toLowerCase()
      const hoatChat = searchHoatChat.trim().toLowerCase()
      const dvt = searchDonVi.trim().toLowerCase()
      const quyCach = searchQuyCach.trim().toLowerCase()
      const branch = searchBranch.trim().toLowerCase()
      const kho = searchKho.trim().toLowerCase()
      const thute = slThucTe.trim().toLowerCase()
      const soLoLower = searchSoLo.trim().toLowerCase()
      const HDLower = searchHD?.map((hd) => hd.trim().toLowerCase())
      const soLuongChan = Math.floor(tonKho / thuocVatTu?.quyCachDongGoi) // Calculate full packages
      const soLuongLe = tonKho - soLuongChan * thuocVatTu?.quyCachDongGoi // Calculate remaining loose units
      let displaySoLuong

      // Format the quantity display
      if (soLuongChan > 0 && soLuongLe > 0) {
        displaySoLuong = `${soLuongChan} ${thuocVatTu?.donViChan} ${soLuongLe} ${thuocVatTu?.dvt}`
      } else if (soLuongChan > 0) {
        displaySoLuong = `${soLuongChan} ${thuocVatTu?.donViChan}`
      } else {
        displaySoLuong = `${soLuongLe} ${thuocVatTu?.dvt}`
      }
      const displaySoLuongLower = displaySoLuong.toLowerCase()
      return (
        (thuocVatTu?.maThuoc?.toLowerCase().includes(maLower) || !thuocVatTu?.maThuoc) &&
        (thuocVatTu?.tenBietDuoc?.toLowerCase().includes(tenLower) || !thuocVatTu?.tenBietDuoc) &&
        (thuocVatTu?.tenHoatChat?.toLowerCase().includes(hoatChat) || !thuocVatTu?.tenHoatChat) &&
        (thuocVatTu?.dvt?.toLowerCase().includes(dvt) || !thuocVatTu?.dvt) &&
        (thuocVatTu?.quyCach?.toLowerCase().includes(quyCach) || !thuocVatTu?.quyCach) &&
        (tenCN?.toLowerCase().includes(branch) || !tenCN) &&
        (soLo?.toLowerCase().includes(soLoLower) || !soLo) &&
        (HDLower.includes(soHoaDon?.toLowerCase()) || HDLower.length === 0) &&
        (tenKhoCn?.toLowerCase().includes(kho) || !tenKhoCn) &&
        // (tonkhothucte?.includes(thute) || !tonkhothucte) &&
        buildFilterCondition(filterXuat, searchXuat, tongXuat) &&
        buildFilterCondition(filterNhap, searchNhap, tongNhap) &&
        (displaySoLuongLower.includes(searchSLChan.toLowerCase()) || !displaySoLuongLower) &&
        buildFilterCondition(filterTon, searchTon, tonKho) &&
        buildDateFilterCondition(filterDate, searchDate, hanDung)
      )
    },
  )
  const exportToExcel = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXuatExcel, null, actionIsTrue, actionIsFalse))
  }
  const getUniqueFilters = (data, dataIndex) => {
    const uniqueValues = Array.from(new Set(tonKiemKe?.data?.map((item) => item[dataIndex]))) // Lấy các giá trị không trùng
    return uniqueValues?.map((value) => ({
      label: value,
      value: value,
    }))
  }
  const calculateTotals = (data) => {
    return data?.reduce(
      (totals, { tongNhap, tonKho, tongXuat, thuocVatTu }) => {
        totals.tongNhap += tongNhap || 0
        totals.tonKho += tonKho || 0
        totals.tongXuat += tongXuat || 0
        totals.tongSoluongChan += Math.trunc(tonKho / thuocVatTu?.quyCachDongGoi) || 0
        return totals
      },
      { tongNhap: 0, tonKho: 0, tongXuat: 0, tongSoluongChan: 0 },
    )
  }
  const totals = calculateTotals(filteredData)
  const searchDebounce = useCallback(
    _.debounce((e) => {
      let { value } = e.target
      setSearchText(value.trim())
      SetSearchParams({ page: 1 })
    }, 300),
    [],
  )

  useEffect(() => {
    dispatch(listAllCompanyAction())
    dispatch(branchTonKhoAction(id_company))
  }, [])

  const form = {
    idKy: idky,
    idCT: company,
    idChiNhanh: idChiNhanh,
    idKhoCN: khoCN,
    keyword: searchText,
  }
  const onLoad = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  useEffect(() => {
    if (kyHang.length !== 0) {
      onLoad()
    }
  }, [searchText])
  useEffect(() => {
    if (kyHang.length !== 0) {
      setIdky(kyHang[0].idky)
    }
  }, [kyHang])
  const getKhoTheoChiNhanh = async (value) => {
    try {
      const { data } = await https.get(`KhoChiNhanh/${value}`)
      setListKho(data)
    } catch (error) {
      console.log(error)
    }
  }
  const PhepTinh = [
    { label: '>', value: '>' },
    { label: '<', value: '<' },
    { label: '=', value: '=' },
  ]
  useEffect(() => {
    getKhoTheoChiNhanh(idChiNhanh)
  }, [])
  const compare = (a, b, dataIndex) => {
    if (a[dataIndex] < b[dataIndex]) {
      return -1
    }
    if (a[dataIndex] > b[dataIndex]) {
      return 1
    }
    return 0
  }
  useEffect(() => {
    if (listKho.length !== 0) {
      setKhoCN(viewsRoleKho(listKho)[0].value)
    }
  }, [listKho])
  const dataSource = filteredData?.map(
    (
      {
        soHoaDon,
        thuocVatTu,
        tenCN,
        tenKhoCn,
        tongNhap,
        ghichu,
        tongXuat,
        tonKho,
        hanDung,
        soLo,
        tonkhothucte,
      },
      index,
    ) => {
      const soLuongChan = Math.floor(tonKho / thuocVatTu?.quyCachDongGoi) // Calculate full packages
      const soLuongLe = tonKho - soLuongChan * thuocVatTu?.quyCachDongGoi // Calculate remaining loose units
      let displaySoLuong

      // Format the quantity display
      if (soLuongChan > 0 && soLuongLe > 0) {
        displaySoLuong = `${soLuongChan} ${thuocVatTu?.donViChan} ${soLuongLe} ${thuocVatTu?.dvt}`
      } else if (soLuongChan > 0) {
        displaySoLuong = `${soLuongChan} ${thuocVatTu?.donViChan}`
      } else {
        displaySoLuong = `${soLuongLe} ${thuocVatTu?.dvt}`
      }
      return {
        STT: ++index,
        maThuoc: thuocVatTu?.maThuoc,
        tenBietDuoc: thuocVatTu?.tenBietDuoc,
        tenHoatChat: thuocVatTu?.tenHoatChat,
        dvt: thuocVatTu?.dvt,
        quyCach: thuocVatTu?.quyCach,
        tenCN,
        tenKhoCn,
        tongNhap,
        tongXuat,
        tonKho,
        soLo,
        slChan: displaySoLuong,
        hanDung,
        soHoaDon,
        tonkhothucte,
        ghichu,
        action: (
          <ul className="flex justify-around">
            <li
              onClick={() =>
                infoTonKho({ thuocVatTu, tenCN, tenKhoCn, tongNhap, tongXuat, tonKho, hanDung })
              }
            >
              <Tooltip title="Xem chi tiết" color="#108ee9">
                <ContainerOutlined
                  onClick={showModal}
                  className="text-xl text-[#108ee9] cursor-pointer"
                />
              </Tooltip>
            </li>
          </ul>
        ),
      }
    },
  )
  const columSummary = () => {
    const NumberColum = 18
    const tonKhoData = {
      8: totals?.tongNhap,
      9: totals?.tongXuat,
      10: totals?.tongSoluongChan,
      11: totals?.tonKho,
    }
    return Array.from({ length: NumberColum }, (_, i) => ({
      key: i,
      value: formatNumber(tonKhoData[i]) ?? null,
    }))
  }
  const summaryData = columSummary()
  const handleTableChange = (pagination) => {
    SetSearchParams({ page: pagination.current })
  }
  const checkExpiration = (hanDung) => {
    if (!hanDung) return false
    const expirationDate = moment(hanDung, 'DD/MM/YYYY')
    const currentDatePlusThreeMonths = moment().add(3, 'months').endOf('day')
    return expirationDate.isBefore(currentDatePlusThreeMonths)
  }
  const viewsRoleKho = (data) => {
    // Nếu là lễ tân chỉ lấy kho: VPP, Tài sản, Vận hành
    let newData = []
    if (infoUser?.idNhom !== ID_NHOM_LE_TAN) {
      newData = [
        {
          tenKho: 'Tất cả',
          idKhoCN: null,
        },
        ...data,
      ]
    } else {
      newData = data?.filter((item) => ROLE_KHO_VIEWS.includes(item.idKho))
    }
    return newData?.map((item) => ({
      label: item.tenKho,
      value: item.idKhoCN,
    }))
  }

  return (
    <>
      <div className="flex justify-between border border-dashed p-2 rounded-md bg-slate-50 mb-4">
        <h2 className="font-semibold text-lg text-gray-500 flex items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 text-green-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
            />
          </svg>{' '}
          Kỳ hàng hoá{' '}
        </h2>
        <Select
          onChange={setIdky}
          value={idky}
          className="w-72"
          options={kyHang.map((item) => ({
            label: item.tenky,
            value: item.idky,
          }))}
        />
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-5">
          <div className="font-semibold w-64 flex gap-1">
            <Select
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              showSearch
              onChange={handleCompany}
              value={company}
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="font-semibold w-60 flex gap-1">
            <Select
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              showSearch
              onChange={(value) => {
                setIdChiNhanh(value)
                setKhoCN(null)
                getKhoTheoChiNhanh(value)
              }}
              value={idChiNhanh}
              options={[
                { label: 'Tất cả', value: null },
                ...branchTonKho?.map((item) => ({
                  label: item.tenChiNhanh,
                  value: item.idChiNhanh,
                })),
              ]}
              className="w-full"
            />
          </div>
          <div className="font-semibold w-48 flex gap-1">
            <Select
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              value={khoCN}
              showSearch
              onChange={(value) => setKhoCN(value)}
              options={viewsRoleKho(listKho)}
              className="w-full"
            />
          </div>
          <div className="font-semibold flex gap-5 items-center">
            <div
              className="cursor-pointer rounded-sm text-blue-500  hover:bg-slate-200"
              onClick={onLoad}
            >
              <CachedIcon sx={{ fontSize: 25 }} />
            </div>
          </div>
        </div>
        <div className="flex gap-2 ml-5">
          <Input.Search
            placeholder="Nhập từ khoá tìm kiếm "
            onChange={searchDebounce}
            className=" w-64 2xl:block hidden"
          />
          <Button
            disabled={!dataSource?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 hidden xl:flex"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
              />
            </svg>
            Xuất excel
          </Button>
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <Input.Search
          placeholder="Nhập từ khoá tìm kiếm "
          onChange={searchDebounce}
          className="2xl:hidden block mt-2"
        />
        <Button
          disabled={!dataSource?.length}
          onClick={exportToExcel}
          type="text"
          size="middle"
          className="text-green-700 xl:hidden"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
            />
          </svg>
          Xuất excel
        </Button>
      </div>

      <div className="mt-2 ">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
              borderRadius: 0,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#EFEFEF',
                footerBg: '#EFEFEF',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            rowClassName={(record) => (checkExpiration(record.hanDung) ? 'bg-orange-300' : '')}
            loading={isLoadingTable}
            pagination={{
              pageSize: 100,
              showSizeChanger: false,
            }}
            onChange={handleTableChange}
            bordered
            scroll={{
              x: 1500,
              y: '58vh',
            }}
            columns={[
              {
                title: 'STT',
                dataIndex: 'STT',
                width: 60,
                align: 'center',
                fixed: 'left',
                render: (text, record, index) => {
                  return (useSearch.get('page') - 1) * 100 + index + 1
                },
              },
              {
                title: 'Mã thuốc',
                dataIndex: 'maThuoc',
                width: 120,
                fixed: 'left',
                sorter: (a, b) => compare(a, b, 'maThuoc'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Input
                          size="small"
                          onChange={(e) => setSearchMa(e.target.value)}
                          value={searchMa}
                        />
                      </>
                    ),
                    width: 120,
                    dataIndex: 'maThuoc',
                    fixed: 'left',
                    key: 1,
                  },
                ],
              },
              {
                title: 'Tên thuốc',
                dataIndex: 'tenBietDuoc',
                width: 250,
                fixed: 'left',
                sorter: (a, b) => compare(a, b, 'tenBietDuoc'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Input
                          size="small"
                          onChange={handleSearch('tenBietDuoc')}
                          value={searchTenThuoc}
                        />
                      </>
                    ),
                    dataIndex: 'tenBietDuoc',
                    key: 2,
                    width: 250,
                    fixed: 'left',
                  },
                ],
              },
              {
                title: 'Hoạt chất',
                dataIndex: 'tenHoatChat',
                width: 350,
                sorter: (a, b) => compare(a, b, 'tenHoatChat'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Input
                          size="small"
                          onChange={handleSearch('tenHoatChat')}
                          value={searchHoatChat}
                        />
                      </>
                    ),
                    dataIndex: 'tenHoatChat',
                    key: 2,
                    width: 350,
                  },
                ],
              },
              {
                title: 'Đơn vị tính',
                dataIndex: 'dvt',
                width: 100,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'dvt'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Input size="small" onChange={handleSearch('dvt')} value={searchDonVi} />
                      </>
                    ),
                    dataIndex: 'dvt',
                    key: 2,
                    width: 100,
                  },
                ],
              },

              {
                title: 'Quy cách',
                dataIndex: 'quyCach',
                align: 'center',
                width: 120,
                sorter: (a, b) => compare(a, b, 'quyCach'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Input
                          size="small"
                          onChange={handleSearch('quyCach')}
                          value={searchQuyCach}
                        />
                      </>
                    ),
                    dataIndex: 'quyCach',
                    key: 2,
                    width: 120,
                  },
                ],
              },
              {
                title: 'Chi nhánh',
                dataIndex: 'tenCN',
                width: 230,
                align: 'left',
                sorter: (a, b) => compare(a, b, 'tenCN'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Select
                          size="small"
                          className="w-full"
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          showSearch
                          onChange={handleSelected}
                          value={searchBranch}
                          options={[
                            { label: 'Tất cả', value: '' },
                            ...branchTonKho?.map((item) => ({
                              label: item.tenChiNhanh,
                              value: item.tenChiNhanh,
                            })),
                          ]}
                        />
                      </>
                    ),
                    dataIndex: 'tenCN',
                    key: 2,
                    width: 230,
                  },
                ],
              },
              {
                title: 'Kho chi nhánh',
                dataIndex: 'tenKhoCn',
                width: 150,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'tenKhoCn'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Select
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          size="small"
                          value={searchKho}
                          showSearch
                          onChange={handleSearchKho}
                          options={[
                            { label: 'Tất cả', value: '' },
                            ...listKho.map((item) => ({
                              label: item.tenKho,
                              value: item.tenKho,
                              key: item.tenKho, // Thêm key cho mỗi tùy chọn
                            })),
                          ]}
                          className="w-full"
                        />
                      </>
                    ),
                    dataIndex: 'tenKhoCn',
                    key: 2,
                    width: 150,
                  },
                ],
              },
              {
                title: 'Nhập kho',
                dataIndex: 'tongNhap',
                width: 130,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'tongNhap'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <div className="flex gap-1">
                        <Select
                          onChange={(value) => setfilterNhap(value)}
                          value={filterNhap}
                          size="small"
                          className="w-16"
                          options={PhepTinh}
                        />
                        <Input
                          size="small"
                          type="Number"
                          value={searchNhap}
                          onChange={handleSearch('tongNhap')}
                        />
                      </div>
                    ),
                    dataIndex: 'tongNhap',
                    key: 2,
                    width: 130,
                    render: (text) =>
                      text < 0 ? (
                        <Tag color="error" bordered={false}>
                          {text}
                        </Tag>
                      ) : (
                        text
                      ),
                  },
                ],
              },
              {
                title: 'Xuất kho',
                dataIndex: 'tongXuat',
                width: 130,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'tongXuat'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <div className="flex gap-1">
                        <Select
                          onChange={(value) => setfilterXuat(value)}
                          value={filterXuat}
                          size="small"
                          className="w-16"
                          options={PhepTinh}
                        />
                        <Input
                          size="small"
                          type="Number"
                          value={searchXuat}
                          onChange={handleSearch('tongXuat')}
                        />
                      </div>
                    ),
                    dataIndex: 'tongXuat',
                    key: 2,
                    width: 130,
                    render: (text) =>
                      text < 0 ? (
                        <Tag color="error" bordered={false}>
                          {text}
                        </Tag>
                      ) : (
                        text
                      ),
                  },
                ],
              },
              {
                title: 'SL chẵn',
                dataIndex: 'slChan',
                width: 130,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'slChan'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Input
                          size="small"
                          value={searchSLChan}
                          onChange={handleSearch('slChan')}
                        />
                      </>
                    ),
                    dataIndex: 'slChan',
                    key: 2,
                    width: 130,
                    render: (text) =>
                      text < 0 ? (
                        <Tag color="error" bordered={false}>
                          {text}
                        </Tag>
                      ) : (
                        text
                      ),
                  },
                ],
              },
              {
                title: 'Tồn kho SL lẻ',
                dataIndex: 'tonKho',
                width: 130,
                align: 'center',

                sorter: (a, b) => compare(a, b, 'tonKho'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <div className="flex gap-1">
                        <Select
                          onChange={(value) => setfilterTon(value)}
                          value={filterTon}
                          size="small"
                          className="w-16"
                          options={PhepTinh}
                        />
                        <Input
                          size="small"
                          type="Number"
                          value={searchTon}
                          onChange={handleSearch('tonKho')}
                        />
                      </div>
                    ),
                    dataIndex: 'tonKho',
                    key: 2,
                    width: 130,
                    render: (text) =>
                      text < 0 ? (
                        <Tag color="error" bordered={false}>
                          {text}
                        </Tag>
                      ) : (
                        text
                      ),
                  },
                ],
              },
              {
                title: 'Số lô',
                dataIndex: 'soLo',
                width: 90,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'soLo'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Input size="small" onChange={handleSearch('soLo')} value={searchSoLo} />
                      </>
                    ),
                    dataIndex: 'soLo',
                    key: 2,
                    width: 90,
                  },
                ],
              },
              {
                title: 'Hạn dùng',
                dataIndex: 'hanDung',
                width: 160,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'hanDung'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <div className="flex gap-1">
                        <Select
                          onChange={(value) => setFilterDate(value)}
                          value={filterDate}
                          size="small"
                          className="w-16"
                          options={PhepTinh}
                        />
                        <DatePicker
                          value={searchDate !== '' ? dayjs(searchDate, 'YYYY-MM-DD') : ''}
                          size="small"
                          format={'DD/MM/YYYY'}
                          onChange={(date, DateString) => {
                            setSearchDate(
                              DateString
                                ? moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD')
                                : '',
                            )
                          }}
                        />
                      </div>
                    ),
                    render: (text) => moment(text).format('DD/MM/YYYY'),
                    dataIndex: 'hanDung',
                    key: 'hanDung',
                    width: 160,
                  },
                ],
              },
              {
                title: 'Số HĐ',
                dataIndex: 'soHoaDon',
                width: 100,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'soHoaDon'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Select
                          mode="multiple"
                          size="small"
                          value={searchHD}
                          onChange={handleSearchHD}
                          options={getUniqueFilters(tonKiemKe?.data, 'soHoaDon')}
                          style={{
                            width: '100%',
                            height: '24px',
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        />
                        {/* <Input size="small" onChange={handleSearch('soHoaDon')} value={searchHD} /> */}
                      </>
                    ),
                    dataIndex: 'soHoaDon',
                    key: 'soHoaDon',
                    width: 150,
                  },
                ],
              },
              {
                title: 'SL thực tế',
                dataIndex: 'tonkhothucte',
                width: 120,
                sorter: (a, b) => compare(a, b, 'tonkhothucte'),
                showSorterTooltip: false,
                // children: [
                //   {
                //     title: (
                //       <>
                //         <Input size="small" onChange={handleSearch('tonkhothucte')} value={slThucTe} />
                //       </>
                //     ),
                //     dataIndex: 'tonkhothucte',
                //     key: 'tonkhothucte',
                //     width: 120,
                //   },
                // ],
              },
              {
                title: 'Ghi chú',
                dataIndex: 'ghichu',
                width: 160,
                sorter: (a, b) => compare(a, b, 'ghichu'),
                showSorterTooltip: false,
                // children: [
                //   {
                //     title: (
                //       <>
                //         <Input size="small" onChange={handleSearch('ghichu')} value={searchSoLo} />
                //       </>
                //     ),
                //     dataIndex: 'ghichu',
                //     key: 'ghichu',
                //     width: 160,
                //   },
                // ],
              },
              {
                title: 'Chênh lệch',
                dataIndex: 'chenlech',
                width: 90,
                fixed: 'right',
                render: (_, record) => {
                  const tonTT = record?.tonkhothucte || record?.tonKho
                  const count = tonTT - record?.tonKho
                  return (
                    <p
                      className={`font-semibold ${count >= 0 ? 'text-green-500' : 'text-red-500'}`}
                    >
                      {count > 0 ? '+' : ''}
                      {count}
                    </p>
                  )
                },
              },
            ]}
            dataSource={dataSource}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  {summaryData?.map((item) => (
                    <Table.Summary.Cell key={item.key} index={item.key}>
                      {' '}
                      <p className="font-semibold">{item.value}</p>
                    </Table.Summary.Cell>
                  ))}
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default Products

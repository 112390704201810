import { call, put, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { tmtdClinicService } from '../../services/tmtdClinic/tmtdClinicService'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* tmtdClinicSaga() {
  // LẤY DANH SÁCH BỆNH NHÂN CHỜ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_BN_CHO_KHAM_TMTD,
    function* BNPendingTMTD({ type, idcnpk }) {
      try {
        const result = yield call(() => tmtdClinicService.getBNChoKhamTMTD(idcnpk))
        yield put({
          type: typeAction.DISPATCH_BN_CHO_KHAM_TMTD,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      }
    })
  // LẤY DANH SÁCH BỆNH NHÂN ĐANG KHÁM VÀ ĐÃ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_BN_DANG_VA_DA_KHAM_TMTD,
    function* BNDangVaDaKhamTMTD({ type, idnv, idcn, tuNgay, denNgay }) {
      try {
        const listDangKham = yield call(() => tmtdClinicService.getBNDangKhamTMTD(idnv, idcn, tuNgay, denNgay),)
        const listDaKham = yield call(() => tmtdClinicService.getBNDaKhamTMTD(idnv, idcn, tuNgay, denNgay),)
        yield put({
          type: typeAction.DISPATCH_BN_DANG_KHAM_TMTD,
          payload: listDangKham.data,
        })
        yield put({
          type: typeAction.DISPATCH_BN_DA_KHAM_TMTD,
          payload: listDaKham.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
}


import { Table, ConfigProvider, Input, Select, DatePicker, Tooltip, Button } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CachedIcon from '@mui/icons-material/Cached'
import moment from 'moment'
import dayjs from 'dayjs'
import _ from 'lodash'
import {
  branchPTByCompany,
  filterPT_recevieCK,
  listInfoThuocVTChyenKho_Action,
} from '../../../../../store/actions/chuyenKhoAction'
import { chuyenKhoService } from '../../../../../services/ChuyenKho/chuyenKhoService'
import * as XLSX from 'xlsx'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import 'moment/locale/vi'
moment.locale('vi')

const columns = [
  {
    title: 'STT',
    dataIndex: 'STT',
    key: 'STT',
    width: 40,
    align: 'center',
  },
  {
    title: 'Mã phiếu',
    dataIndex: 'MAPHIEU',
    key: 'MAPHIEU',
    width: 120,
  },
  {
    title: 'Tên phiếu',
    dataIndex: 'TENPHIEU',
    key: 'TENPHIEU',
  },
  {
    title: 'Người nhận',
    dataIndex: 'NGUOINHAN',
    key: 'NGUOINHAN',
  },
  {
    title: 'Ngày Nhận',
    dataIndex: 'NGAYNHAN',
    key: 'NGAYNHAN',
    width: 120,
  },

  {
    title: 'Công ty nhận',
    dataIndex: 'CTYNHAN',
    key: 'CTYNHAN',
    width: 150,
  },
  {
    title: 'Chi nhánh nhận',
    dataIndex: 'chiNhanhNhan',
    key: 'chiNhanhNhan',
  },
  {
    title: 'Kho nhận',
    dataIndex: 'KHONHAN',
    key: 'KHONHAN',
    width: 150,
  },
  {
    title: 'Người chuyển',
    dataIndex: 'NGUOICHUYEN',
    key: 'NGUOICHUYEN',
  },
  {
    title: 'Ngày chuyển',
    dataIndex: 'NGAYCHUYEN',
    key: 'NGAYCHUYEN',
    width: 120,
  },
  {
    title: 'Công ty chuyên',
    dataIndex: 'CTYCHUYEN',
    key: 'CTYCHUYEN',
    width: 150,
  },
  {
    title: 'Chi nhánh chuyển',
    dataIndex: 'chiNhanhChuyen',
    key: 'chiNhanhChuyen',
  },
  {
    title: 'Kho chuyển',
    dataIndex: 'KHOCHUYEN',
    key: 'KHOCHUYEN',
    width: 150,
  },
  {
    title: 'Ghi chú',
    dataIndex: 'GHICHU',
    key: 'GHICHU',
    width: 200,
  },
  {
    title: 'Hành động',
    dataIndex: 'ACTION',
    key: 'ACTION',
    width: 100,
    align: 'center',
    fixed: 'right',
  },
]

const now = new Date()
const dateFormat = 'DD-MM-YYYY'

const Receive = ({ showModal, setStatusUpdate }) => {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const { PTReceive, branchPT } = useSelector((state) => state.chuyenKhoReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(id_company)
  const [idChiNhanh, setIdChiNhanh] = useState(BranchLogin)
  const [since, setSince] = useState(now)
  const [toDate, SetToDate] = useState(now)
  const [ListKho, setListKho] = useState([])
  const [searchKho, setSearchKho] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState('')
  // set ngày lại từ ngày
  const handleSince = (name) => (date, dateString) => {
    if (name === 'since') {
      setSince(dateString)
    } else {
      SetToDate(dateString)
    }
  }
  const handleShowModalDetail = (idNhapXuat) => {
    showModal()
    dispatch(listInfoThuocVTChyenKho_Action(idNhapXuat))
    setStatusUpdate(false) // tắt trạng thái cho phép update
  }
  const statusChuyenKho = 3 // trạng thái nhận
  const filter = {
    status: statusChuyenKho, //
    since: moment(since, dateFormat).format('YYYY-MM-DD'),
    toDate: moment(toDate, dateFormat).format('YYYY-MM-DD'),
    idChiNhanh: idChiNhanh,
    idCongTy: company,
    keyword: search,
  }
  // xử lí chọn chi nhánh
  const handleSelected = (value) => {
    setIdChiNhanh(value)
    fetchKho(value)
    setSearchKho('')
  }
  const filteredData = data?.filter((items) => {
    const findKho = searchKho.toLowerCase()
    return items?.tenKhoNhap?.toLowerCase().includes(findKho)
  })
  //------------fetch dữ liệu ------------//
  const fetchData = async () => {
    try {
      setIsLoading(true)
      const { data } = await chuyenKhoService.getPTAccessCTY(filter)
      const sortedData = _.orderBy(data, 'ngayXuat', 'desc')
      setData(sortedData)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const handleCompany = (value) => {
    dispatch(branchPTByCompany(value))
    setCompany(value)
    setIdChiNhanh(null)
  }
  // xử lí lọc dưc liệu
  const handleFilter = () => {
    // dispatch(filterPT_recevieCK(filter, setIsLoading))
    fetchData()
  }
  //---------- lấy danh sách kho chi nhánh-------------//
  const fetchKho = async (id) => {
    const { data } = await chuyenKhoService.getlistKhoNhan(id)
    setListKho(data)
  }
  useEffect(() => {
    //dispatch(filterPT_recevieCK(filter, setIsLoading))
    dispatch(branchPTByCompany(id_company))
    fetchKho(BranchLogin)
    fetchData()
  }, [])
  // useEffect(() => {
  //   const sortedData = _.orderBy(PTReceive, 'ngayXuat', 'desc')
  //   setData(sortedData)
  // }, [PTReceive])

  const exportToExcel = () => {
    const headers = [
      'Mã phiếu',
      'Tên phiếu',
      'Người nhận',
      'Ngày nhận',
      'Công ty nhận',
      'Chi nhánh nhận',
      'Kho nhận',
      'Người chuyển',
      'Ngày chuyển',
      'Công ty chuyển',
      'Chi nhánh chuyển',
      'Kho chuyển',
      'Ghi chú',
    ]

    const formattedData = filteredData.map((item) => ({
      'Mã phiếu': item.maPhieu,
      'Tên phiếu': item.tenPhieu,
      'Người nhận': item.tenNVNhan,
      'Ngày nhận': moment(item.ngayNhan).format('DD/MM/YYYY HH:mm:ss'),
      'Công ty nhận': item.tenCTNhan,
      'Chi nhánh nhận': item.tenChiNhanhNhan,
      'Kho nhận': item.tenKhoNhap,
      'Người chuyển': item.tenNVXuat,
      'Ngày chuyển': moment(item.ngayXuat).format('DD/MM/YYYY HH:mm:ss'),
      'Công ty chuyển': item.tenCTXuat,
      'Chi nhánh chuyển': item.tenChiNhanhXuat,
      'Kho chuyển': item.tenKhoXuat,
      'Ghi chú': item.noiDung,
    }))

    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, 'Phiếu chuyển kho - Phiếu đã nhận.xlsx')
  }

  return (
    <div className="h-full">
      <div className="flex gap-5">
        <div className="w-80 flex gap-1">
          <Select
            onChange={handleCompany}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={company}
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className="w-full"
          />
        </div>
        <div className="w-72 flex gap-1">
          <Select
            onChange={handleSelected}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={idChiNhanh}
            options={[
              { label: 'Tất cả', value: null },
              ...branchPT?.map(({ idChiNhanh, tenChiNhanh }) => ({
                label: tenChiNhanh,
                value: idChiNhanh,
              })),
            ]}
            className="w-full"
          />
        </div>
        <div className="w-52">
          <Select
            onChange={(value) => setSearchKho(value)}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={searchKho}
            options={[
              { label: 'Tất cả', value: '' },
              ...ListKho?.map((item) => ({
                label: item.tenKho,
                value: item.tenKho,
              })),
            ]}
            className="w-full"
          />
        </div>
        <div className="flex gap-3">
          <div>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleSince('since')}
              format={dateFormat}
            />
          </div>
          <div>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              minDate={dayjs(since, dateFormat)}
              onChange={handleSince('toDate')}
              format={dateFormat}
            />
          </div>
        </div>
        <div
          className="cursor-pointer rounded-sm text-blue-500  hover:bg-slate-200"
          onClick={handleFilter}
        >
          <CachedIcon sx={{ fontSize: 25 }} />
        </div>
        <div className="flex gap-3 ml-auto">
          <Button
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <div
        className=" mt-2 "
        // style={{
        //     boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        // }}
      >
        <ConfigProvider
          theme={{
            token: {
              padding: 4,
              fontSize: 12,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },    
          }}
        >
          <Table
            loading={isLoading}
            scroll={{
              x: 2200,
            }}
            bordered
            pagination={{
              pageSize: 20,
              showSizeChanger: false,
            }}
            columns={columns}
            dataSource={filteredData?.map((items, index) => ({
              STT: ++index,
              MAPHIEU: items.maPhieu,
              TENPHIEU: items.tenPhieu,
              NGUOINHAN: items.tenNVNhan,
              NGAYNHAN: moment(items.ngayNhan).format('DD/MM/YYYY HH:mm:ss'),
              chiNhanhNhan: items.tenChiNhanhNhan,
              KHONHAN: items.tenKhoNhap,
              chiNhanhChuyen: items.tenChiNhanhXuat,
              NGUOICHUYEN: items.tenNVXuat,
              NGAYCHUYEN: moment(items.ngayXuat).format('DD/MM/YYYY HH:mm:ss'),
              KHOCHUYEN: items.tenKhoXuat,
              GHICHU: items.noiDung,
              CTYCHUYEN: items.tenCTXuat,
              CTYNHAN: items.tenCTNhan,
              ACTION: (
                <Tooltip title="Xem chi tiết" color="#108ee9">
                  <EyeOutlined
                    onClick={() => handleShowModalDetail(items.idNhapXuat)}
                    className="text-xl text-[#108ee9]  cursor-pointer"
                  />
                </Tooltip>
              ),
            }))}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default Receive

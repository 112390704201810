import { EditOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, Checkbox, ConfigProvider, Empty, Input, Space, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import LayoutApp from '../../../HOCs/LayoutApp'
import { congtyCRMService } from '../../../services/congtyCRM/congtyCRMService'
import { getAllCtyCRM, getCtyCRMById } from '../../../store/actions/congtyCRMAction'
import { getLienHeCRM, getThongTinLienHeCRM } from '../../../store/actions/lienheCRMAction'
import ToastCus from '../../../utils/Toast'
import ModalAdd from './Modal/ModalAddLienHe'
import ModalEdit from './Modal/ModalEditLienHe'
import ModalAddCty from './ModalAddCty/ModalAddCty'
import ModalDetail from './ModalDetail/ModalDetail'
import ModalEditCty from './ModalEditCty/ModalEditCty'
import { generateTT32 } from '../../../utils/report/TT32'
import 'moment/locale/vi'
import { https } from '../../../services/apiService'
import { debounce } from 'lodash'
moment.locale('vi')

const CongTy = () => {
  const dispatch = useDispatch()
  const { dsLienHe } = useSelector((state) => state.lienheCRMReducer)
  // const { listCTy, infoCty } = useSelector((state) => state.congtyCRMReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [show, setShow] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [openAddLienHe, setOpenAddLienHe] = useState(false)
  const [openEditLienHe, setOpenEditLienHe] = useState(false)
  const [activeRow, setActiveRow] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [showSDT, setShowSDT] = useState(null)
  const [listCty, setListCty] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [reloadTrigger, setReloadTrigger] = useState(0)

  //deleteCongty
  const deleteCty = async (idcty) => {
    try {
      await congtyCRMService.deleteCompayById(idcty)
      dispatch(getAllCtyCRM())
      ToastCus.fire({
        icon: 'success',
        title: 'Xóa công ty thành công',
      })
    } catch (err) {
      console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: 'Có lỗi xảy ra khi xóa công ty',
      })
    }
  }

  const fetchAllCty = async (page = 1, keyword) => {
    setLoading(true)
    try {
      const { data } = await https.get(`CRMCongTy/GetAllCRMCongTyPagination`, {
        params: {
          pageNumber: page,
          keyword: keyword,
        },
      })
      setListCty((prev) => [...prev, ...data.data])
      setTotalPages(data.totalPage)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const columns = [
    {
      title: 'Mã số thuế',
      dataIndex: 'masothue',
      key: 'masothue',
      width: 120,
    },
    {
      title: 'Mã Cty',
      dataIndex: 'mact',
      key: 'mact',
      width: 120,
    },
    {
      title: 'Tên công ty',
      dataIndex: 'tenct',
      key: 'tenct',
    },
    {
      title: 'Người tạo',
      dataIndex: 'tennguoitao',
      key: 'tennguoitao',
      width: 150,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngaytao',
      key: 'ngaytao',
      width: 100,
      render: (text, record, index) => {
        return text ? moment(text).format('DD/MM/YYYY') : ''
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 150,
    },
    {
      title: 'Người phụ trách',
      dataIndex: 'nhanvienphutrach',
      key: 'nhanvienphutrach',
      width: 150,
    },
    infoUser?.tenNhom === 'Sales'
      ? {
          width: 0,
        }
      : {
          title: '',
          key: 'action',
          dataIndex: 'action',
          width: 50,
          align: 'center',
          fixed: 'right',
        },
  ]

  const columnsLienHe = [
    {
      title: 'Người liên hệ',
      dataIndex: 'nguoilienhe',
      key: 'nguoilienhe',
    },
    {
      title: 'SĐT',
      dataIndex: 'dienthoailienhe',
      key: 'dienthoailienhe',
      align: 'center',
      width: 120,
      render: (text, record) => (record.idttlh === showSDT?.idttlh ? text : ''),
    },
    {
      title: 'Sử dụng',
      dataIndex: 'ghichu',
      key: 'ghichu',
      align: 'center',
      render: (text, record, index) => <Checkbox checked={record.sudung} />,
      width: 80,
    },
    {
      title: '',
      dataIndex: 'action',
      width: 50,
      align: 'center',
      key: 'action',
    },
  ]

  const onLoad = (page, keyword) => {
    fetchAllCty(page, keyword)
  }
  const handleClickRow = (item) => {
    if (infoUser?.tenNhom === 'Sales') return
    setActiveRow(item)
    dispatch(getLienHeCRM(item.idct))
  }

  const handleShowChitiet = (item) => {
    dispatch(getThongTinLienHeCRM(item.idttlh))
    setOpenEditLienHe(true)
  }

  useEffect(() => {
    fetchAllCty(page, keyword)
  }, [page, reloadTrigger])

  const handleScroll = useCallback(
    debounce(() => {
      //api search return all data not pagination
      // disable scroll when search filtering
      const tableBody = document.querySelector('.table-crm-congty .ant-table-body')
      if (
        tableBody.scrollTop + tableBody.clientHeight >= tableBody.scrollHeight - 100 &&
        !loading
      ) {
        if (page + 1 > totalPages) {
          return
        }
        setPage((prevPage) => prevPage + 1)
      }
    }, 300),
    [loading],
  )

  useEffect(() => {
    const tableBody = document.querySelector('.table-crm-congty .ant-table-body')
    tableBody.addEventListener('scroll', handleScroll)
    return () => tableBody.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  const handleSearch = () => {
    setListCty([])
    setPage(1)
    setReloadTrigger((prev) => prev + 1)
  }

  const refreshData = () => {
    setListCty([])
    setPage(1)
    onLoad(1, keyword)
  }

  return (
    <>
      <div className="p-2 h-full">
        <div className="bg-[#efefef] rounded-md h-full">
          <div className="flex gap-2 h-full p-2 bg-[#efefef]">
            <div
              className={` ${infoUser?.tenNhom === 'Sales' ? 'w-full' : 'w-2/3'} h-full bg-white rounded-md`}
            >
              <div className="p-2 h-full">
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center gap-2">
                    <Input
                      className="w-80"
                      value={keyword}
                      placeholder="Nhập tên cty hoặc mã cty/mã số thuế..."
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                    <Button onClick={handleSearch} type="primary">
                      <SyncOutlined />
                    </Button>
                  </div>

                  {infoUser?.tenNhom === 'Sales' ? null : (
                    <div className="flex justify-end">
                      <Button onClick={() => setShowAdd(true)} type="primary">
                        Thêm công ty
                      </Button>
                      <Button
                        className="ml-2"
                        type="primary"
                        onClick={() => setOpenAddLienHe(true)}
                      >
                        Thêm liên hệ
                      </Button>
                    </div>
                  )}
                </div>

                <div>
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 5,
                      },
                      components: {
                        Table: {
                          rowHoverBg: '#ecf0f1',
                          headerBg: '#e6e6e6',
                          footerBg: '#e6e6e6',
                          borderColor: '#BABABA',
                        },
                      },
                    }}
                  >
                    <Table
                      loading={loading}
                      scroll={{
                        x: '100%',
                        y: 'calc(100vh - 250px)',
                      }}
                      bordered
                      pagination={false}
                      onRow={(record) => ({
                        onClick: () => {
                          handleClickRow(record)
                        },
                      })}
                      className="table-crm-congty"
                      columns={columns}
                      dataSource={listCty?.map(
                        ({
                          masothue,
                          idct,
                          mact,
                          tenct,
                          dienthoai,
                          fax,
                          email,
                          website,
                          ghichu,
                          ngaytao,
                          tennguoitao,
                          nhanvienphutrach,
                        }) => ({
                          key: idct,
                          masothue,
                          idct,
                          mact,
                          tenct,
                          dienthoai,
                          fax,
                          email,
                          website,
                          ghichu,
                          ngaytao,
                          tennguoitao,
                          nhanvienphutrach,
                          action: (
                            <ul className="flex gap-2 justify-around gap-2 ">
                              {/* {infoUser?.tenNhom === 'Sales' ? null : (
                                <li className="text-lg text-red-500">
                                  <Tooltip
                                    title="Xóa"
                                    placement="top"
                                    className="cursor-pointer"
                                    color="red"
                                  >
                                    <Popconfirm
                                      placement="left"
                                      title="Xoá công ty"
                                      description="Bạn có chắc xoá Công ty này không ?"
                                      onConfirm={(e) => {
                                        e.stopPropagation()
                                        deleteCty(idct)
                                      }}
                                      okText="Xoá"
                                      okType="danger"
                                      cancelText="Huỷ"
                                      icon={
                                        <QuestionCircleOutlined
                                          style={{
                                            color: 'red',
                                          }}
                                        />
                                      }
                                    >
                                      <DeleteOutlined />
                                    </Popconfirm>
                                  </Tooltip>
                                </li>
                              )} */}

                              <li className="text-lg text-green-500">
                                <Tooltip
                                  title="Chi tiết"
                                  className="cursor-pointer"
                                  placement="top"
                                  color="green"
                                >
                                  <EditOutlined
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      dispatch(getCtyCRMById(idct))
                                      setShowEdit(true)
                                    }}
                                  />
                                </Tooltip>
                              </li>
                            </ul>
                          ),
                        }),
                      )}
                    />
                  </ConfigProvider>
                </div>
              </div>
            </div>
            {infoUser?.tenNhom === 'Sales' ? null : (
              <div className="w-1/3 h-full bg-white rounded-md">
                <div className="p-2">
                  <h1 className="text-lg text-center text-gray-600 font-bold">Thông tin liên hệ</h1>

                  {activeRow && (
                    <>
                      <p className="mb-2">
                        Công ty: <span className="font-semibold text-lg">{activeRow?.tenct}</span>
                      </p>
                      {infoUser?.tenNhom === 'Sales' ? null : (
                        <div>
                          {dsLienHe?.length ? (
                            <ConfigProvider
                              theme={{
                                token: {
                                  padding: 5,
                                },
                                components: {
                                  Table: {
                                    rowHoverBg: '#ecf0f1',
                                    headerBg: '#e6e6e6',
                                    footerBg: '#e6e6e6',
                                    borderColor: '#BABABA',
                                  },
                                },
                              }}
                            >
                              <Table
                                pagination={false}
                                columns={columnsLienHe}
                                onRow={(record) => ({
                                  onClick: () => {
                                    setShowSDT(record)
                                  },
                                })}
                                dataSource={dsLienHe?.map((item) => ({
                                  ...item,
                                  action: (
                                    <ul className="flex gap-2 justify-around gap-2 ">
                                      <li className="text-lg text-green-500">
                                        <Tooltip
                                          title="Chi tiết"
                                          className="cursor-pointer"
                                          placement="top"
                                          color="green"
                                        >
                                          <EditOutlined
                                            onClick={() => {
                                              handleShowChitiet(item)
                                            }}
                                          />
                                        </Tooltip>
                                      </li>
                                    </ul>
                                  ),
                                }))}
                                bordered
                              />
                            </ConfigProvider>
                          ) : (
                            <Empty description={<span>Chưa có thông tin liên hệ</span>} />
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showEdit && <ModalEditCty showEdit={showEdit} onClose={setShowEdit} onLoad={refreshData} />}
      {showAdd && <ModalAddCty showAdd={showAdd} onClose={setShowAdd} onLoad={refreshData} />}
      {openAddLienHe && (
        <ModalAdd
          openAddLienHe={openAddLienHe}
          setOpenAddLienHe={setOpenAddLienHe}
          rowActive={activeRow}
        />
      )}
      {openEditLienHe && (
        <ModalEdit openEditLienHe={openEditLienHe} setOpenEditLienHe={setOpenEditLienHe} />
      )}

      {show && <ModalDetail show={show} setShow={setShow} />}
    </>
  )
}

export default CongTy

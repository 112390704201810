import React, { useEffect, useState } from 'react'
import { Tabs, Button, Segmented, Checkbox, Input, DatePicker } from 'antd'
import FormDKX from './FormDKX/FormDKX'
import FormTK from './FormTK/FormTK'
// import FormCLS from './FormCLS/FormCLS'
// import FormKQXN from './FormKQXN/FormKQXN'
import { useFormik, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { updateBnDetail } from '../../../../../store/actions/childrenClinicAction'
import {
  postInfoBNDoKhucXaAction,
  putInfoBNDoKhucXaAction,
} from '../../../../../store/actions/doKhucXaAction'
import { ChildrenClinicSchema } from '../../../../../schemas/ChilrenClinicSchema'
import * as typeAction from '../../../../../store/constants/constants'
import 'moment/locale/vi'
moment.locale('vi')
const TabDiagnostic = ({formBenhNhan}) => {
  const now = moment()
  const dateFormat = 'DD/MM/YYYY'
  const formatDateAPI = 'YYYY-MM-DD'
  const tab_DKX = 'Đo khúc xạ'
  const tab_KTK = 'Tròng & gọng kính'
  // const tab_KQXN = 'Kết quả xét nghiệm'
  const dispatch = useDispatch()
  const SO_NGAY_TAI_KHAM = 0
  const initialStartDate = now.add(SO_NGAY_TAI_KHAM, 'days').format('YYYY-MM-DD')
  const { DetailBN, ThuocBN, DetailToaThuocBN, infoBN, NgayThuoc } = useSelector(
    (state) => state.childrenClinicReducer,
  )
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { InfoBNDoKhucXa } = useSelector((state) => state.doKhucXaReducer)
  const [date, setDate] = useState(initialStartDate)
  const [count, setCount] = useState(SO_NGAY_TAI_KHAM)
  const [checkBox, setCheckBox] = useState(false)
  const [segmentedValue, setSegmentedValue] = useState(tab_DKX)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbnttc: DetailBN?.idbnttc,
      thiluckhongkinhxA_MP: InfoBNDoKhucXa?.THILUCKHONGKINHXA_MP,
      thiluckhongkinhxA_MT: InfoBNDoKhucXa?.THILUCKHONGKINHXA_MT,
      thiluckhongkinhxA_2M: InfoBNDoKhucXa?.THILUCKHONGKINHXA_2M,
      thiluckhongkinhgaN_MP: InfoBNDoKhucXa?.THILUCKHONGKINHGAN_MP,
      thiluckhongkinhgaN_MT: InfoBNDoKhucXa?.THILUCKHONGKINHGAN_MT,
      thiluckhongkinhgaN_2M: InfoBNDoKhucXa?.THILUCKHONGKINHGAN_2M,
      thiluckinhloxA_MP: InfoBNDoKhucXa?.THILUCKINHLOXA_MP,
      thiluckinhloxA_MT: InfoBNDoKhucXa?.THILUCKINHLOXA_MT,
      thiluckinhloxA_2M: InfoBNDoKhucXa?.THILUCKINHLOXA_2M,
      thiluckinhcuxA_MP: InfoBNDoKhucXa?.THILUCKINHCUXA_MP,
      thiluckinhcuxA_MT: InfoBNDoKhucXa?.THILUCKINHCUXA_MT,
      thiluckinhcuxA_2M: InfoBNDoKhucXa?.THILUCKINHCUXA_2M,
      thiluckinhcugaN_MP: InfoBNDoKhucXa?.THILUCKINHCUGAN_MP,
      thiluckinhcugaN_MT: InfoBNDoKhucXa?.THILUCKINHCUGAN_MT,
      thiluckinhcugaN_2M: InfoBNDoKhucXa?.THILUCKINHCUGAN_2M,
      nhanaP_MP: InfoBNDoKhucXa?.NHANAP_MP,
      nhanaP_MT: InfoBNDoKhucXa?.NHANAP_MT,
      khachquankhonglietdT_MP: InfoBNDoKhucXa?.KHACHQUANKHONGLIETDT_MP,
      khachquankhonglietdT_MT: InfoBNDoKhucXa?.KHACHQUANKHONGLIETDT_MT,
      khachquanlietdT_MP: InfoBNDoKhucXa?.KHACHQUANLIETDT_MP,
      khachquanlietdT_MT: InfoBNDoKhucXa?.KHACHQUANLIETDT_MT,
      chuquaN_MP: InfoBNDoKhucXa?.CHUQUAN_MP,
      chuquaN_MT: InfoBNDoKhucXa?.CHUQUAN_MT,
      kinhcU_MP_CAU: InfoBNDoKhucXa?.KINHCU_MP_CAU,
      kinhcU_MP_LOAN: InfoBNDoKhucXa?.KINHCU_MP_LOAN,
      kinhcU_MP_TRUC: InfoBNDoKhucXa?.KINHCU_MP_TRUC,
      kinhcU_MP_ADD: InfoBNDoKhucXa?.KINHCU_MP_ADD,
      kinhcU_MT_CAU: InfoBNDoKhucXa?.KINHCU_MT_CAU,
      kinhcU_MT_LOAN: InfoBNDoKhucXa?.KINHCU_MT_LOAN,
      kinhcU_MT_TRUC: InfoBNDoKhucXa?.KINHCU_MT_TRUC,
      kinhcU_MT_ADD: InfoBNDoKhucXa?.KINHCU_MT_ADD,
      kinhcU_PD: InfoBNDoKhucXa?.KINHCU_PD,
      kinhdieuchinH_MP_CAU: InfoBNDoKhucXa?.KINHDIEUCHINH_MP_CAU,
      kinhdieuchinH_MP_LOAN: InfoBNDoKhucXa?.KINHDIEUCHINH_MP_LOAN,
      kinhdieuchinH_MP_TRUC: InfoBNDoKhucXa?.KINHDIEUCHINH_MP_TRUC,
      kinhdieuchinH_MP_TL: InfoBNDoKhucXa?.KINHDIEUCHINH_MP_TL,
      kinhdieuchinH_MP_ADD: InfoBNDoKhucXa?.KINHDIEUCHINH_MP_ADD,
      kinhdieuchinH_MT_CAU: InfoBNDoKhucXa?.KINHDIEUCHINH_MT_CAU,
      kinhdieuchinH_MT_LOAN: InfoBNDoKhucXa?.KINHDIEUCHINH_MT_LOAN,
      kinhdieuchinH_MT_TRUC: InfoBNDoKhucXa?.KINHDIEUCHINH_MT_TRUC,
      kinhdieuchinH_MT_TL: InfoBNDoKhucXa?.KINHDIEUCHINH_MT_TL,
      kinhdieuchinH_MT_ADD: InfoBNDoKhucXa?.KINHDIEUCHINH_MT_ADD,
      kinhdieuchinH_PD: InfoBNDoKhucXa?.KINHDIEUCHINH_PD,
      kinhtiepxuC_MP_CONGSUAT: InfoBNDoKhucXa?.KINHTIEPXUC_MP_CONGSUAT,
      kinhtiepxuC_MP_BC: InfoBNDoKhucXa?.KINHTIEPXUC_MP_BC,
      kinhtiepxuC_MP_DIA: InfoBNDoKhucXa?.KINHTIEPXUC_MP_DIA,
      kinhtiepxuC_MP_HIEUBRAND: InfoBNDoKhucXa?.KINHTIEPXUC_MP_HIEUBRAND,
      kinhtiepxuC_MT_CONGSUAT: InfoBNDoKhucXa?.KINHTIEPXUC_MT_CONGSUAT,
      kinhtiepxuC_MT_BC: InfoBNDoKhucXa?.KINHTIEPXUC_MT_BC,
      kinhtiepxuC_MT_DIA: InfoBNDoKhucXa?.KINHTIEPXUC_MT_DIA,
      kinhtiepxuC_MT_HIEUBRAND: InfoBNDoKhucXa?.KINHTIEPXUC_MT_HIEUBRAND,
      khamtienchaN_MP_SACGIAC: InfoBNDoKhucXa?.KHAMTIENCHAN_MP_SACGIAC,
      khamtienchaN_MT_SACGIAC: InfoBNDoKhucXa?.KHAMTIENCHAN_MT_SACGIAC,
      khamtienchaN_2M_SACGIAC: InfoBNDoKhucXa?.KHAMTIENCHAN_2M_SACGIAC,
      khamtienchaN_MP_DONGTU: InfoBNDoKhucXa?.KHAMTIENCHAN_MP_DONGTU,
      khamtienchaN_MT_DONGTU: InfoBNDoKhucXa?.KHAMTIENCHAN_MT_DONGTU,
      khamtienchaN_2M_DONGTU: InfoBNDoKhucXa?.KHAMTIENCHAN_2M_DONGTU,
      khamtienchaN_MP_VANNHAN: InfoBNDoKhucXa?.KHAMTIENCHAN_MP_VANNHAN,
      khamtienchaN_MT_VANNHAN: InfoBNDoKhucXa?.KHAMTIENCHAN_MT_VANNHAN,
      khamtienchaN_2M_VANNHAN: InfoBNDoKhucXa?.KHAMTIENCHAN_2M_VANNHAN,
      khamtienchaN_MP_THITRUONG: InfoBNDoKhucXa?.KHAMTIENCHAN_MP_THITRUONG,
      khamtienchaN_MT_THITRUONG: InfoBNDoKhucXa?.KHAMTIENCHAN_MT_THITRUONG,
      khamtienchaN_2M_THITRUONG: InfoBNDoKhucXa?.KHAMTIENCHAN_2M_THITRUONG,
      khamtienchaN_MP_TESTCHEMAT_XA: InfoBNDoKhucXa?.KHAMTIENCHAN_MP_TESTCHEMAT_XA,
      khamtienchaN_MT_TESTCHEMAT_XA: InfoBNDoKhucXa?.KHAMTIENCHAN_MT_TESTCHEMAT_XA,
      khamtienchaN_2M_TESTCHEMAT_XA: InfoBNDoKhucXa?.KHAMTIENCHAN_2M_TESTCHEMAT_XA,
      khamtienchaN_MP_TESTCHEMAT_GAN: InfoBNDoKhucXa?.KHAMTIENCHAN_MP_TESTCHEMAT_GAN,
      khamtienchaN_MT_TESTCHEMAT_GAN: InfoBNDoKhucXa?.KHAMTIENCHAN_MT_TESTCHEMAT_GAN,
      khamtienchaN_2M_TESTCHEMAT_GAN: InfoBNDoKhucXa?.KHAMTIENCHAN_2M_TESTCHEMAT_GAN,
      khamtienchaN_MP_BODIEUTIETAA: InfoBNDoKhucXa?.KHAMTIENCHAN_MP_BODIEUTIETAA,
      khamtienchaN_MT_BODIEUTIETAA: InfoBNDoKhucXa?.KHAMTIENCHAN_MT_BODIEUTIETAA,
      khamtienchaN_2M_BODIEUTIETAA: InfoBNDoKhucXa?.KHAMTIENCHAN_2M_BODIEUTIETAA,
      khamtienchaN_CANDIEMQUYTUNPC: InfoBNDoKhucXa?.KHAMTIENCHAN_CANDIEMQUYTUNPC,
      khamtienchaN_THIGIACLAPTHESTEREO: InfoBNDoKhucXa?.KHAMTIENCHAN_THIGIACLAPTHESTEREO,
      nguoikham: infoUser?.tenNV,
      action: '',
    },
    onSubmit: (values) => handleSubmit(values),
    // validationSchema: ChildrenClinicSchema,
  })
  const handleSegmentedChange = (value) => {
    setSegmentedValue(value)
  }
  const resetForm = () => {
    formik.resetForm()
  }
  const handleSubmit = (value) => {
    dispatch(
      putInfoBNDoKhucXaAction(
        //postInfoBNDoKhucXaAction
        {
          ...value,
          nguoikham:
            DetailBN?.trangthaikhambenh === 1 &&
            !InfoBNDoKhucXa?.NGUOIKHAM &&
            value?.action === 'capnhat'
              ? null
              : infoUser?.tenNV,
        },
        InfoBNDoKhucXa?.DichVuBenhNhanDoKhucXa?.map((item) => ({
          ...item,
          idnguoithuchien: infoUser?.idnv,
          nguoithuchien: infoUser?.tenNV,
        })),
        PkDangNhap?.idChiNhanh,
        moment().format(formatDateAPI),
        moment().format(formatDateAPI),
        resetForm,
      ),
    )
  }
  // useEffect(() => {
  //   const today = moment().startOf('day')
  //   if (DetailBN?.ngaytaikham) {
  //     const startDate = moment(DetailBN.ngaykham).startOf('day')
  //     const endDate = moment(DetailBN.ngaytaikham).startOf('day')
  //     const diffDays = endDate.diff(startDate, 'days')
  //     setCount(diffDays)
  //   } else {
  //     const selectedDate = moment(formik.values.ngaytaikham).startOf('day')
  //     const diffDays = selectedDate.diff(today, 'days')
  //     setCount(diffDays)
  //   }
  //   if (formik.values.ngaytaikham === null) setCount(0)
  // }, [date, DetailBN?.ngaytaikham])
  // useEffect(() => {
  //   const today = moment().startOf('day')
  //   const selectedDate = moment(formik.values.ngaytaikham).startOf('day')
  //   const diffDays = selectedDate.diff(today, 'days')
  //   setCount(diffDays)
  //   if (formik.values.ngaytaikham === null) setCount(0)
  // }, [date])
  // useEffect(() => {
  //   const today = moment().startOf('day')
  //   if (DetailBN?.ngaytaikham) {
  //     const startDate = moment(DetailBN.ngaykham).startOf('day')
  //     const endDate = moment(DetailBN.ngaytaikham).startOf('day')
  //     const diffDays = endDate.diff(startDate, 'days')
  //     setCount(diffDays)
  //   } else {
  //     setCount(0)
  //   }
  // }, [DetailBN?.ngaytaikham])
  // useEffect(() => {
  //   if (NgayThuoc !== count) {
  //     dispatch({
  //       type: typeAction.NGAY_THUOC_NHI,
  //       payload: count,
  //     })
  //   }
  // }, [count])
  // //   const selectedDate = moment(date).startOf('day')
  // //   const diffDays = selectedDate.diff(today, 'days')
  // //   setCount(diffDays)
  // // }, [date])
  // useEffect(() => {
  //   setCheckBox(
  //     infoBN && !DetailToaThuocBN?.DanhSachThuoc?.length && DetailBN?.lydokolaythuoc ? true : false,
  //   )
  // }, [DetailBN])
  return (
    <div className="mt-3 px-2">
      <div className="flex justify-between">
        <Segmented
          options={[
            tab_DKX,
            tab_KTK,
            //  tab_KQXN
          ]}
          value={segmentedValue}
          onChange={handleSegmentedChange}
        />
      </div>
      <div>
        {segmentedValue === tab_DKX ? (
          <FormDKX
            checkBox={true}
            // setCheckBox={setCheckBox}
            formik={formik}
            // setDate={setDate}
            // date={date}
            // count={count}
            // setCount={setCount}
          />
        ) : segmentedValue === tab_KTK ? (
          <>
            <FormTK />
          </>
        ) : (
          <>{/* <FormKQXN /> */}</>
        )}{' '}
      </div>
    </div>
  )
}

export default TabDiagnostic

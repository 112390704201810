import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { ConfigProvider, Popconfirm, Table } from 'antd'
import { DataGrid } from '@mui/x-data-grid'
import { QuestionCircleOutlined } from '@ant-design/icons'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'
import { useDispatch, useSelector } from 'react-redux'
import { deleteChiTietChuyenKho_Action } from '../../../../store/actions/chuyenKhoAction'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

const TableVT = ({ statusUpdate }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { detailPT, detailThuocVT } = useSelector((state) => state.chuyenKhoReducer)
  const dispatch = useDispatch()

  const maQuyenXoaHang = 'QHT625'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXoaHang:
        dispatch(deleteChiTietChuyenKho_Action(infoNew?.id, infoNew?.idNhapXuat))
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXoaHang:
        //
        break
      default:
        return false
    }
  }
  const handleDelete = (id) => {
    dispatch(
      putCheckQuyenThaoTacAction(
        maQuyenXoaHang,
        { id: id, idNhapXuat: detailPT?.idNhapXuat },
        actionIsTrue,
        actionIsFalse,
      ),
    )
  }
  return (
    <ConfigProvider
      theme={{
        token: {
          padding: 5,
          borderRadius: 0,
        },
        components: {
          Table: {
            rowHoverBg: '#ecf0f1',
            headerBg: '#e6e6e6',
            footerBg: '#e6e6e6',
            borderColor: '#BABABA',
          },
        },
      }}
    >
      <Table
        scroll={{
          x: 1500,
          y: 500,
        }}
        pagination={false}
        dataSource={detailThuocVT?.map((item) => ({
          key: item.idChiTietNX,
          maThuoc: item?.thuocVatTu?.maThuoc,
          tenBietDuoc: item?.thuocVatTu?.tenBietDuoc,
          SLchan: Math.floor(item?.soLuongLe / item.soLuongDongGoi),
          donViChan: item?.thuocVatTu?.donViChan,
          quyCachDongGoi: item?.quyCachDongGoi,
          SLle:
            item.soLuongLe -
            Math.floor(item?.soLuongLe / item.soLuongDongGoi) * item?.soLuongDongGoi,
          dvt: item?.thuocVatTu?.dvt,
          soLo: item?.soLo,
          hanDung: item?.hanDung,
          ghiChu: item?.ghiChu,
          idChiTietNX: item?.idChiTietNX,
          soHoaDon: item?.soHoaDon,
        }))}
        bordered
        columns={[
          {
            title: 'STT',
            dataIndex: 'STT',
            key: 'STT',
            width: 40,
            align: 'center',
            render: (text, record, index) => ++index,
          },
          {
            title: 'Mã hàng',
            dataIndex: 'maThuoc',
            key: 'maThuoc',
            width: 150,
          },
          {
            title: 'Tên hàng',
            dataIndex: 'tenBietDuoc',
            key: 'tenBietDuoc',
            width: 500,
          },
          {
            title: 'SL chẵn',
            dataIndex: 'SLchan',
            key: 'SLchan',
            align: 'center',
            width: 90,
          },
          {
            title: 'ĐVT',
            dataIndex: 'donViChan',
            key: 'donViChan',
            width: 80,
          },
          {
            title: 'Quy cách',
            dataIndex: 'quyCachDongGoi',
            key: 'quyCachDongGoi',
            width: 120,
          },
          {
            title: 'SL lẻ',
            dataIndex: 'SLle',
            key: 'SLle',
            width: 60,
            align: 'center',
          },
          {
            title: 'ĐVT',
            dataIndex: 'dvt',
            key: 'dvt',
            width: 80,
          },
          {
            title: 'Số lô',
            dataIndex: 'soLo',
            key: 'soLo',
            width: 100,
          },
          {
            title: 'Hạn dùng',
            dataIndex: 'hanDung',
            key: 'hanDung',
            render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
            width: 100,
          },
          {
            title: 'Số HĐ',
            dataIndex: 'soHoaDon',
            key: 'soHoaDon',
            width: 100,
          },
          {
            title: 'Ghi chú',
            dataIndex: 'ghiChu',
            key: 'ghiChu',
            width: 250,
          },
          {
            title: 'Hành động',
            dataIndex: 'action',
            align: 'center',
            key: 'action',
            width: 100,
            fixed: 'right',
            render: (text, record, index) => (
              <Popconfirm
                title="Xoá thuốc vật tư"
                description="Bạn có chắc muốn xoá vật tư này không ?"
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: 'red',
                    }}
                  />
                }
                onConfirm={() => handleDelete(record.idChiTietNX)}
                okText="Xoá"
                cancelText="Huỷ"
                disabled={!statusUpdate || infoUser?.idnv !== detailPT?.nhanVienXuat}
              >
                <DeleteSweepIcon
                  color={
                    !statusUpdate || infoUser?.idnv !== detailPT?.nhanVienXuat
                      ? 'disabled'
                      : 'error'
                  }
                  style={{ cursor: 'pointer' }}
                />
              </Popconfirm>
            ),
          },
        ]}
      />
    </ConfigProvider>
  )
}

export default TableVT

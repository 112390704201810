import { https } from '../apiService'
export const XuatVacXinService = {
  getBNChoXVX: (idChiNhanh) => https.get(`NhaThuoc/GetDanhSachChoVacxin?chiNhanh=${idChiNhanh}`),
  getBNDoneXVX: (idChiNhanh) => https.get(`NhaThuoc/GetDanhSachDaVacxin?chiNhanh=${idChiNhanh}`),
  getBNChoBsKeToaLaiXVX: (chiNhanh) =>
    https.get(`NhaThuoc/GetDanhSachChoKeDonVacXin`, { params: { chiNhanh } }),
  // getPhieuThuVacXin: (idcn, tuNgay, denNgay, keyword) =>
  //   https.get('BenhNhanPhieuThu/GetDanhSachPhieuThuVacXin', {
  //     params: { idcn, tuNgay, denNgay, keyword },
  //   }),
  getPhieuThuVacXin: (idcn, tuNgay, denNgay, keyword, idCa) =>
    https.get(
      `BenhNhanPhieuThu/GetDanhSachPhieuThuVacXin?${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}${idCa}${keyword?.length ? `&keyword=${keyword}` : ''}`,
    ),
  // getServiceNhaThuoc: (idbnttc) =>
  //   https.get(`BenhNhanPhieuThu/GetDanhSachToaThuoc?idbnttc=${idbnttc}`),
  // updateThuoc: (data) => https.put('BenhNhanPhieuThu/UpdateBenhNhanPhieuThu', data),
  // postBNBanLe: (dataBNTTC) => https.post('BenhNhanTTC/AddBenhNhanTTC_ForBanLe',dataBNTTC),
  // postPhieuThuBanLe: (data) => https.post('BenhNhanPhieuThu/ThemBenhNhanPhieuThu', data),
  // getLyDoKhongLayThuoc: () => https.get('LyDoKoLayThuoc/GetAllLyDo_DuocSi'),
}

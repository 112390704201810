import { CheckCircleOutlined, ContainerOutlined, SyncOutlined } from '@ant-design/icons'
import CachedIcon from '@mui/icons-material/Cached'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import PrintIcon from '@mui/icons-material/Print'
import {
  Button as AntButton,
  ConfigProvider,
  DatePicker,
  Input,
  Popconfirm,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { companyService } from '../../../../services/Company/companyService'
import { branchService } from '../../../../services/branch/branchService'
import {
  getphieuChuyen,
  listInfoThuocVTChyenKho_Action,
  printerAutoChuyenKho,
} from '../../../../store/actions/chuyenKhoAction'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { configSearchTableAnt } from '../../../../utils/configSearchTableAntd'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import 'moment/locale/vi'
moment.locale('vi')
const dateFormat = 'DD-MM-YYYY'
const fomatDB = 'YYYY-MM-DD'
const now = moment().format(fomatDB)
const SoPhieu = ({ showModal, cancelModal, setStatusUpdate }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const BranchLogin = localStorage.getItem('BRANH_LOGIN')
  const ID_COMPANY = localStorage.getItem('id_company')
  const dispatch = useDispatch()
  const [since, setSince] = useState(now)
  const [toDate, SetToDate] = useState(now)
  const [companys, setCompany] = useState([])
  const [idCT, setIdCT] = useState(Number(ID_COMPANY))
  const [data, setData] = useState([])
  const [idBranh, setIdBranch] = useState(Number(BranchLogin))
  const [ListBranch, setListBranch] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchValues, setSearchValues] = useState({})
  const [filteredData, setFilteredData] = useState([])
  const [valueExport, setValueExport] = useState([])
  const hasSelected = selectedRowKeys.length > 0

  const maQuyenXemDs = 'QHT189'
  const maQuyenXuatExcel = 'QHT600'
  const maQuyenInDs = 'QHT190'
  const maQuyenXemCt = 'QHT186'
  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        dispatch(getphieuChuyen(filter, setData, setIsLoading))
        break
      case maQuyenXuatExcel:
        exportToExcel()
        break
      case maQuyenInDs:
        dispatch(printerAutoChuyenKho(selectedRowKeys, setSelectedRowKeys, infoNew?.isA5))
        break
      case maQuyenXemCt:
        handleShowModalDetail(infoNew?.idNhapXuat)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXuatExcel:
        //
        break
      case maQuyenInDs:
        //
        break
      case maQuyenXemCt:
        //
        break
      default:
        return false
    }
  }

  // set ngày lại từ ngày
  const handleSince = (name) => (date, dateString) => {
    const formatValue = moment(dateString, dateFormat).format(fomatDB)
    if (name === 'since') {
      setSince(formatValue)
    } else {
      SetToDate(formatValue)
    }
  }
  const filter = {
    fromDate: since, //
    toDate: toDate,
    idCongTy: idCT,
    idChiNhanh: idBranh,
  }
  const handleShowModalDetail = (idNhapXuat) => {
    showModal()
    dispatch(listInfoThuocVTChyenKho_Action(idNhapXuat))
    setStatusUpdate(false) // bật trạng thái cho phép update
  }
  //----------- lấy danh sách chi nhánh theo công ty -------//
  const fetchBranch = async () => {
    try {
      const { data } = await branchService.getListBranch(idCT)
      setListBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  // ---------- lấy danh sách công ty ---------
  const fetchCompany = async () => {
    try {
      const { data } = await companyService.getAllListCompany()
      setCompany(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchData = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  ///---- in phiếu hàng loạt -------
  const autoPrin = (isA5) => {
    if (hasSelected) {
      dispatch(putCheckQuyenThaoTacAction(maQuyenInDs, { isA5: isA5 }, actionIsTrue, actionIsFalse))
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log(extra)
    setValueExport(extra.currentDataSource)
  }
  useEffect(() => {
    fetchBranch()
  }, [idCT])
  useEffect(() => {
    fetchData()
    fetchCompany()
  }, [])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
    },
    {
      title: 'Mã phiếu',
      dataIndex: 'maPhieu',
      key: 'maPhieu',
      ...configSearchTableAnt(setSearchValues, searchValues, 'maPhieu', data, 110, false),
    },
    {
      title: 'Tên phiếu',
      dataIndex: 'tenPhieu',
      key: 'tenPhieu',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenPhieu', data, 150, false),
    },
    {
      title: 'Người chuyển',
      dataIndex: 'tenNVXuat',
      key: 'tenNVXuat',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenNVXuat', data, 150, false),
    },
    {
      title: 'Ngày chuyển',
      dataIndex: 'ngayXuat',
      key: 'ngayXuat',
      ...configSearchTableAnt(setSearchValues, searchValues, 'ngayXuat', data, 150, true, {
        render: (text) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
      }),
    },
    {
      title: 'Chi nhánh chuyển',
      dataIndex: 'tenChiNhanhXuat',
      key: 'tenChiNhanhXuat',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenChiNhanhXuat', data, 150, false),
    },
    {
      title: 'Kho chuyển',
      dataIndex: 'tenKhoXuat',
      key: 'tenKhoXuat',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenKhoXuat', data, 150, false),
    },
    {
      title: 'Người nhận',
      dataIndex: 'tenNVNhan',
      key: 'tenNVNhan',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenNVNhan', data, 150, false),
    },
    {
      title: 'Ngày nhận',
      dataIndex: 'ngayNhan',
      key: 'ngayNhan',
      ...configSearchTableAnt(setSearchValues, searchValues, 'ngayNhan', data, 150, true, {
        render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
      }),
    },
    {
      title: 'Chi nhánh nhận',
      dataIndex: 'tenChiNhanhNhan',
      key: 'tenChiNhanhNhan',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenChiNhanhNhan', data, 150, false),
    },
    {
      title: 'Kho nhận',
      dataIndex: 'tenKhoNhap',
      key: 'tenKhoNhap',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenKhoNhap', data, 150, false),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'sumThucTra',
      key: 'sumThucTra',
      ...configSearchTableAnt(setSearchValues, searchValues, 'sumThucTra', data, 150, false, {
        render: (text) => formatNumberVND(Math.round(text || 0)),
      }),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghiChu',
      key: 'ghiChu',
      ...configSearchTableAnt(setSearchValues, searchValues, 'ghiChu', data, 150, false),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'trangThai',
      key: 'trangThai',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenTrangThai', data, 100, false, {
        render: (text, record) => (
          <Tag
            className="font-semibold"
            icon={
              record.trangThai === 3 ? (
                <CheckCircleOutlined />
              ) : record.trangThai === 2 ? (
                <SyncOutlined />
              ) : null
            }
            color={
              record.trangThai === 3 ? '#87d068' : record.trangThai === 1 ? 'error' : '#108ee9'
            }
          >
            {text}
          </Tag>
        ),
        align: 'center',
        fixed: 'right',
      }),
    },

    {
      title: 'Hành động',
      dataIndex: 'ACTION',
      key: 'ACTION',
      width: 80,
      align: 'center',
      fixed: 'right',
    },
  ]

  const exportToExcel = () => {
    const formattedData = filteredData?.map((item) => ({
      'Mã phiếu': item.maPhieu,
      'Tên phiếu': item.tenPhieu,
      'Người chuyển': item.tenNVXuat,
      'Ngày chuyển': item.ngayXuat ? moment(item.ngayXuat).format('DD/MM/YYYY HH:mm:ss') : '-',
      'Chi nhánh chuyển': item.tenChiNhanhXuat,
      'Kho chuyển': item.tenKhoXuat,
      'Ngày Nhận ': item.ngayNhan ? moment(item.ngayNhan).format('DD/MM/YYYY HH:mm:ss') : '-',
      'Người nhận': item.nhanVienNhan,
      'Chi nhánh nhận': item.tenChiNhanhNhan,
      'Kho nhận': item.tenKhoNhap,
      'Thành tiền': item.sumThucTra ? formatNumberVND(Math.round(item.sumThucTra)) : 0,
      'Ghi chú': item.ghiChu,
      'Trạng thái': item.tenTrangThai,
    }))
    const name = 'Danh sách phiếu chuyển kho'
    formattedData?.length && exportExcelformat(formattedData, name)
  }
  useEffect(() => {
    const filterData = () => {
      let filtered = data
      Object.keys(searchValues).forEach((key) => {
        if (searchValues[key]) {
          filtered = filtered.filter((item) =>
            item[key]?.toString().toLowerCase().includes(searchValues[key].toLowerCase()),
          )
        }
      })
      setFilteredData(filtered)
    }
    filterData()
  }, [searchValues, data])
  return (
    <div className="h-full">
      <div className="flex gap-2 items-center">
        <div className="w-72 gap-2 items-center flex">
          <Select
            className="w-full"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            showSearch
            onChange={(value) => {
              setIdCT(value)
              setIdBranch(null)
            }}
            value={idCT}
            options={companys?.map((item) => ({
              label: item.tenct,
              value: item.idct,
            }))}
          />
        </div>
        <div className="w-72 gap-2 items-center flex">
          <Select
            className="w-full"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            showSearch
            onChange={(value) => {
              setIdBranch(value)
            }}
            value={idBranh}
            options={[
              { label: 'Tất cả', value: null },
              ...ListBranch?.map((item) => ({
                label: item.tenChiNhanh,
                value: item.idChiNhanh,
              })),
            ]}
          />
        </div>
        <div className="flex gap-2 items-center">
          <div>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleSince('since')}
              format={dateFormat}
            />
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
          </svg>
          <div>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              // minDate={dayjs(since, dateFormat)}
              onChange={handleSince('toDate')}
              format={dateFormat}
            />
          </div>
        </div>
        <div className="flex gap-3">
          <div
            className="cursor-pointer rounded-sm text-blue-500  hover:bg-slate-200"
            onClick={fetchData}
          >
            <CachedIcon sx={{ fontSize: 25 }} />
          </div>
        </div>
        <div className="flex gap-3">
          <Popconfirm
            title="In phiếu chuyển kho"
            onConfirm={() => autoPrin(false)}
            onCancel={() => autoPrin(true)}
            okText="A4"
            cancelText="A5"
            icon={<PrintIcon color="primary" />}
            cancelButtonProps={{ type: 'primary', className: 'w-1/3 ml-0' }}
            okButtonProps={{ type: 'primary', className: 'w-1/3 ml-0' }}
            className={`${hasSelected ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            disabled={!hasSelected}
          >
            <PrintIcon
              className={`cursor-pointer rounded-sm ${hasSelected ? 'text-blue-500' : 'text-gray-500'}  hover:bg-slate-200`}
            />
          </Popconfirm>
        </div>
        <div className="flex gap-3 ml-auto">
          <AntButton
            disabled={!filteredData?.length}
            // onClick={exportToExcel}
            onClick={() =>
              dispatch(
                putCheckQuyenThaoTacAction(maQuyenXuatExcel, null, actionIsTrue, actionIsFalse),
              )
            }
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </AntButton>
        </div>
      </div>
      <div
        className=" mt-2 "
        // style={{
        //     boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        // }}
      >
        <ConfigProvider
          theme={{
            token: {
              padding: 4,
              fontSize: 12,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            rowSelection={rowSelection}
            loading={isLoading}
            bordered
            pagination={false}
            scroll={{
              x: filteredData.length !== 0 ? 'max-content' : 2000, // Đảm bảo bảng có thể cuộn ngang khi nội dung vượt quá chiều rộng
              y: '70vh', // Sử dụng y nếu filteredData > 0, ngược lại thì không sử dụng y
            }}
            columns={columns}
            onChange={handleTableChange}
            dataSource={filteredData?.map((items, index) => ({
              ...items,
              key: items.idNhapXuat,
              STT: ++index,
              sumThucTra: Math.round(items.sumThucTra),
              ACTION: (
                <div className="flex gap-4 justify-center ">
                  <Tooltip title="Xem chi tiết" color="#108ee9">
                    <ContainerOutlined
                      onClick={() =>
                        dispatch(
                          putCheckQuyenThaoTacAction(
                            maQuyenXemCt,
                            { idNhapXuat: items?.idNhapXuat },
                            actionIsTrue,
                            actionIsFalse,
                          ),
                        )
                      }
                      className="text-xl text-[#108ee9]  cursor-pointer"
                    />
                  </Tooltip>
                </div>
              ),
            }))}
            summary={() => (
              <Table.Summary sticky>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={16}>
                    <p className="font-semibold">Số phiếu : {filteredData.length}</p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default SoPhieu

import { Input, Modal } from 'antd'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { addVaiTroSchema } from '../../../../schemas/addVaiTroSchema'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'

function ModalCreateVaiTro({ isModalOpen, setIsModalOpen, getDataRefresh }) {
  const { infoUser } = useSelector((state) => state.userReducer)
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      maNhom: '',
      tenNhom: '',
      tenVietTat: '',
      ghiChu: '',
    },
    onSubmit: (values, actions) => handleSubmit(values, actions),
    validationSchema: addVaiTroSchema,
  })

  const handleCloseModal = () => {
    setIsModalOpen(false)
    formik.resetForm()
  }

  const handleSubmit = async (values, actions) => {
    setLoading(true)
    try {
      //api add vai tro
      const resultAddNhomNguoiDung = await https.post('NhomNguoiDung/ADDNhomNguoiDung', {
        ...values,
        nguoiTao: infoUser.idnv,
      })
      if (!resultAddNhomNguoiDung.data) {
        ToastCus.fire({
          icon: 'error',
          title: 'Thêm vai trò thất bại',
        })
        throw new Error('Thêm vai trò thất bại')
      }
      
      //success
      handleCloseModal()
      getDataRefresh()
      ToastCus.fire({
        icon: 'success',
        title: 'Thêm vai trò thành công',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Có lỗi xảy ra khi thêm vai trò',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      width={650}
      className="text-center"
      title="Thêm vai trò"
      okText="Thêm"
      cancelText="Huỷ bỏ"
      open={isModalOpen}
      onOk={formik.handleSubmit}
      onCancel={handleCloseModal}
      maskClosable={false}
      confirmLoading={loading}
    >
      <form className="text-start mt-5">
        <div className="flex flex-col gap-3">
          <div className="flex">
            <label className="w-32 text-end">
              <span className="text-red-500 text-xs">(*)</span>Tên vai trò:
            </label>
            <Input
              status={formik.errors.tenNhom ? 'error' : ''}
              name="tenNhom"
              value={formik.values.tenNhom}
              onChange={formik.handleChange}
              size="small"
            />
          </div>

          <div className="flex">
            <label className="w-32 text-end">
              <span className="text-red-500 text-xs">(*)</span>Mã vai trò:
            </label>
            <Input
              status={formik.errors.maNhom ? 'error' : ''}
              name="maNhom"
              value={formik.values.maNhom}
              onChange={formik.handleChange}
              size="small"
            />
          </div>

          <div className="flex">
            <label className="w-32 text-end">Tên viết tắt:</label>
            <Input
              name="tenVietTat"
              value={formik.values.tenVietTat}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
          <div className="flex">
            <label className="w-32 text-end">Ghi chú:</label>
            <Input
              name="ghiChu"
              value={formik.values.ghiChu}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
        </div>
      </form>
    </Modal>
  )
}

ModalCreateVaiTro.propTypes = {}

export default ModalCreateVaiTro

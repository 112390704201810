import { Tabs } from 'antd'
import React from 'react'
import SoSanh from './SoSanh'
import DoLech from './DoLech'

const TiLeToa = () => {
  return (
    <div className="bg-[#EBEBEB] p-3  w-full h-full">
      <div
        className=" bg-white h-full border rounded-lg p-2"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <Tabs
          items={[
            {
              key: 1,
              label: 'So sánh',
              children: <SoSanh />,
            },
            {
              key: 2,
              label: 'Độ lệch',
              children: <DoLech />,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default TiLeToa

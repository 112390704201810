import { Input, InputNumber, Modal } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { editSchemaPriceXN_DV } from '../../../../schemas/editSchemaPriceXN_DV'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'
const ModalChangePriceXetNghiemCN = ({
  showEditPriceCN,
  setShowEditPriceCN,
  rowActive,
  getListBranchUse,
}) => {
  // xử lí form thêm dịch vụ
  const handleSubmit = async (values) => {
    try {
      await https.put(
        `XetnghiemChiNhanh/UpdateXetNghiemChiNhanh?idxncn=${showEditPriceCN?.data?.idxncn}`,
        {
          ...values,
          idChiNhanh: showEditPriceCN?.data?.idChiNhanh,
        },
      )
      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật xét nghiệm chi nhánh thành công',
      })
      formik.resetForm()
      getListBranchUse(rowActive.idxn)
      setShowEditPriceCN(false)
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Cập nhật xét nghiệm chi nhánh thất bại',
      })
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dongia: showEditPriceCN?.data?.dongia ?? 0,
      tienchietkhau: showEditPriceCN?.data?.tienchietkhau ?? 0,
      tienguimau: showEditPriceCN?.data?.tienguimau ?? 0,
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: editSchemaPriceXN_DV,
  })

  return (
    <>
      <Modal
        okText="Xác nhận"
        cancelText="Huỷ"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Chi tiết xét nghiệm chi nhánh</h2>}
        onOk={formik.handleSubmit}
        open={showEditPriceCN.show}
        onCancel={() => setShowEditPriceCN(false)}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">Tên chi nhánh</label>
            <Input readOnly value={showEditPriceCN?.data?.tenchinhanh} />
          </div>
          <div>
            <label className="font-semibold text-base">Tên xét nghiệm</label>
            <Input value={rowActive?.tenxn} readOnly />
          </div>
          <div>
            <label className="font-semibold text-base">Đơn giá</label>
            <InputNumber
              onChange={(value) => formik.setFieldValue('dongia', value)}
              name="dongia"
              className="w-full"
              value={formik.values.dongia}
              step="0.001"
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              max={999999999}

            />
          </div>
          <div>
            <label className="font-semibold text-base">Tiền gửi mẫu</label>
            <InputNumber
              onChange={(value) => formik.setFieldValue('tienguimau', value)}
              status={formik.errors.tienguimau ? 'error' : ''}
              value={formik.values.tienguimau}
              name="tienguimau"
              className="w-full"
              step="0.001"
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              max={999999999}
            />
          </div>
          <div>
            <label className="font-semibold text-base">Tiền chiết khấu</label>
            <InputNumber
              value={formik.values.tienchietkhau}
              name="tienchietkhau"
              status={formik.errors.tienchietkhau ? 'error' : ''}
              className="w-full"
              step="0.001"
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              max={999999999}
              onChange={(value) => formik.setFieldValue('tienchietkhau', value)}
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalChangePriceXetNghiemCN

import React from 'react'
import { Tabs } from 'antd'
import TabNhiKhoa from './TabNhiKhoa'
import TabSanKhoa from './TabSanKhoa'
import TabTMTD from './TabTMTD'
import TabMat from './TabMat'
import TabTongHop from './TabTongHop'

function ThongKe(props) {
  return (
    <div className="p-2  bg-[#E8E8E8] h-full">
      <div
        className="bg-white rounded-md border p-2 h-full"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <Tabs type="card" className="h-full">
          <Tabs.TabPane tab="Chuyên khoa Nhi" key={1}>
            <TabNhiKhoa />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Chuyên khoa Sản" key={2}>
            <TabSanKhoa />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Chuyên khoa TMTĐ" key={4}>
            <TabTMTD />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Chuyên khoa Mắt" key={5}>
            <TabMat />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Tổng hợp" key={6}>
            <TabTongHop />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  )
}
export default ThongKe

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Button, ConfigProvider, Input, InputNumber, Table, Tooltip, Modal, Select } from 'antd'
import { postDoiTacKHAction, putDoiTacKHAction } from '../../../store/actions/doiTacKHAction'
import { dangKyKhamOnlineService } from '../../../services/dangKyKhamOnline/dangKyKhamOnlineService'
import { recieveService } from '../../../services/receive/recieveService'
import { SearchOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import moment from 'moment'
import ToastCus from '../../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')

function DoiTacKH() {
  const dispatch = useDispatch()
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [isLoading, setIsLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [isModal, setIsModal] = useState(false)
  const [listDoiTac, setListDoiTac] = useState([])
  const [rowActive, setRowActive] = useState(null)
  const [form, setForm] = useState(null)
  const [listTinhTp, setListTinhTp] = useState([])
  const [listQuanHuyen, setListQuanHuyen] = useState([])
  const [listPhuongXa, setListPhuongXa] = useState([])
  const [isLoadingAdd, setIsLoadingAdd] = useState(false)

  const resetForm = () => {
    setForm(null)
    setIsModal(false)
    onLoad()
    setRowActive(null)
  }
  const handleSubmit = () => {
    if (
      !form ||
      !form?.tenDoiTac ||
      !form?.idTinh ||
      !form?.idQuan ||
      !form?.idPhuong ||
      !form?.diaChi
    ) {
      setForm((prev) => ({
        ...prev,
        tenDoiTac: prev?.tenDoiTac ?? null,
        idTinh: prev?.idTinh ?? null,
        idQuan: prev?.idQuan ?? null,
        idPhuong: prev?.idPhuong ?? null,
        diaChi: prev?.diaChi ?? null,
      }))
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    setIsModal(false)
    if (form?.idDoiTac) {
      // cập nhật
      dispatch(putDoiTacKHAction({ ...form, nguoiSua: infoUser?.idnv }, resetForm))
      return
    }
    // thêm mới
    dispatch(postDoiTacKHAction({ ...form, nguoiTao: infoUser?.idnv }, resetForm))
  }
  const getListTinhTp = async () => {
    try {
      setIsLoadingAdd(true)
      const { data } = await recieveService.getTinhTP()
      setListTinhTp(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingAdd(false)
    }
  }
  const getListQuanHuyen = async (idTinh) => {
    try {
      setIsLoadingAdd(true)
      const { data } = await recieveService.getQuanHuyen(idTinh)
      setListQuanHuyen(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingAdd(false)
    }
  }
  const getListPhuongXa = async (idQuan) => {
    try {
      setIsLoadingAdd(true)
      const { data } = await recieveService.getPhuongXa(idQuan)
      setListPhuongXa(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingAdd(false)
    }
  }
  const getListDoiTac = async () => {
    try {
      setIsLoading(true)
      const { data } = await dangKyKhamOnlineService.getListDoiTac(keyword)
      setListDoiTac(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const onLoad = () => {
    getListDoiTac()
  }
  useEffect(() => {
    onLoad()
    getListTinhTp()
  }, [])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 45,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã đối tác',
      dataIndex: 'maDoiTac',
      key: 'maDoiTac',
      width: 100,
      align: 'center',
      fixed: 'left',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.maDoiTac ?? '').localeCompare(b?.maDoiTac ?? '')
        },
      },
    },
    {
      title: 'Tên đối tác',
      dataIndex: 'tenDoiTac',
      key: 'tenDoiTac',
      fixed: 'left',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tenDoiTac ?? '').localeCompare(b?.tenDoiTac ?? '')
        },
      },
    },
    {
      title: 'SĐT',
      dataIndex: 'dienThoai',
      key: 'dienThoai',
      width: 100,
      align: 'center',
      fixed: 'left',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.dienThoai ?? '').localeCompare(b?.dienThoai ?? '')
        },
      },
      render: (text, record) => (record?.idDoiTac === rowActive?.idDoiTac ? <p>{text}</p> : ''),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 300,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.email ?? '').localeCompare(b?.email ?? '')
        },
      },
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'diaChi',
      key: 'diaChi',
      width: 400,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.diaChi ?? '').localeCompare(b?.diaChi ?? '')
        },
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'tenNguoiTao',
      key: 'tenNguoiTao',
      width: 220,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tenNguoiTao ?? '').localeCompare(b?.tenNguoiTao ?? '')
        },
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngayTao',
      key: 'ngayTao',
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.ngayTao ?? '').localeCompare(b?.ngayTao ?? '')
        },
      },
    },
    {
      title: 'Người sửa',
      dataIndex: 'tenNguoiSua',
      key: 'tenNguoiSua',
      width: 220,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tenNguoiSua ?? '').localeCompare(b?.tenNguoiSua ?? '')
        },
      },
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'ngaySua',
      key: 'ngaySua',
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.ngaySua ?? '').localeCompare(b?.ngaySua ?? '')
        },
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghiChu',
      key: 'ghiChu',
      width: 300,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.ghiChu ?? '').localeCompare(b?.ghiChu ?? '')
        },
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 45,
      align: 'center',
      fixed: 'right',
      render: (text, record, index) => (
        <ul className="flex justify-center items-center gap-2">
          <li className="flex justify-center items-center">
            <Tooltip title="Chỉnh sửa" color="green">
              <EditOutlined
                onClick={() => {
                  setIsModal(true)
                  setForm(record)
                }}
                className="text-xl text-green-500  cursor-pointer"
              />
            </Tooltip>
          </li>
        </ul>
      ),
    },
  ]
  return (
    <div className="p-3 h-full bg-[#EFEFEF]">
      <div
        className="h-full p-2 bg-white border rounded-xl"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <Modal
          width={450}
          open={isModal}
          onCancel={() => setIsModal(false)}
          okText={'Lưu'}
          onOk={handleSubmit}
          cancelText="Huỷ bỏ"
          title={<p className="text-center">{form?.idDoiTac ? 'Chỉnh sửa' : 'Thêm'} đối tác</p>}
        >
          <div className="grid grid-flow-row gap-3 pb-3 items-center">
            {form?.maDoiTac && (
              <div>
                <label className="font-semibold">
                  Mã đối tác<span className="text-red-500">&nbsp;(*)</span>
                </label>
                <Input value={form?.maDoiTac} readOnly variant="filled" />
              </div>
            )}
            <div>
              <label className="font-semibold">
                Tên đối tác<span className="text-red-500">&nbsp;(*)</span>
              </label>
              <Input
                status={form && !form?.tenDoiTac && 'error'}
                value={form?.tenDoiTac}
                onChange={(e) => setForm((prev) => ({ ...prev, tenDoiTac: e.target.value }))}
              />
            </div>
            <div>
              <label className="font-semibold">Số điện thoại</label>
              <Input
                value={form?.dienThoai}
                onChange={(e) => setForm((prev) => ({ ...prev, dienThoai: e.target.value }))}
              />
            </div>
            <div>
              <label className="font-semibold">Email</label>
              <Input
                value={form?.email}
                onChange={(e) => setForm((prev) => ({ ...prev, email: e.target.value }))}
              />
            </div>
            <div>
              <label className="font-semibold">
                Tỉnh / Tp<span className="text-red-500">&nbsp;(*)</span>
              </label>
              <Select
                disabled={isLoadingAdd}
                className="w-full"
                status={form && !form?.idTinh && 'error'}
                value={form?.idTinh}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={(value) => {
                  setForm((prev) => ({ ...prev, idTinh: value, idQuan: null, idPhuong: null }))
                  getListQuanHuyen(value)
                  setListPhuongXa([])
                }}
                options={listTinhTp?.map((item) => ({
                  label: item?.tenTinh,
                  value: item?.idTinh,
                  key: `tinhtp${item?.idTinh}`,
                }))}
              />
            </div>
            <div>
              <label className="font-semibold">
                Quận / Huyện<span className="text-red-500">&nbsp;(*)</span>
              </label>
              <Select
                disabled={isLoadingAdd}
                className="w-full"
                status={form && !form?.idQuan && 'error'}
                value={form?.idQuan}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={(value) => {
                  setForm((prev) => ({ ...prev, idQuan: value, idPhuong: null }))
                  getListPhuongXa(value)
                }}
                options={listQuanHuyen?.map((item) => ({
                  label: item?.tenQuan,
                  value: item?.idQuan,
                  key: `quanhuyen${item?.idQuan}`,
                }))}
              />
            </div>
            <div>
              <label className="font-semibold">
                Phường / Xã<span className="text-red-500">&nbsp;(*)</span>
              </label>
              <Select
                disabled={isLoadingAdd}
                className="w-full"
                status={form && !form?.idPhuong && 'error'}
                value={form?.idPhuong}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={(value) => {
                  setForm((prev) => ({ ...prev, idPhuong: value }))
                }}
                options={listPhuongXa?.map((item) => ({
                  label: item?.tenPhuong,
                  value: item?.idPhuong,
                  key: `phuongxa${item?.idPhuong}`,
                }))}
              />
            </div>
            <div>
              <label className="font-semibold">
                Địa chỉ<span className="text-red-500">&nbsp;(*)</span>
              </label>
              <Input
                status={form && !form?.diaChi && 'error'}
                value={form?.diaChi}
                onChange={(e) => setForm((prev) => ({ ...prev, diaChi: e.target.value }))}
              />
            </div>
            <div>
              <label className="font-semibold">Ghi chú</label>
              <Input
                value={form?.ghiChu}
                onChange={(e) => setForm((prev) => ({ ...prev, ghiChu: e.target.value }))}
              />
            </div>
          </div>
        </Modal>
        <div className="flex justify-between items-center mb-2">
          <div className="flex justify-start items-center gap-2">
            <Input
              placeholder="Nhập từ khóa..."
              prefix={<SearchOutlined className="pr-1" />}
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
              disabled={isLoading}
              value={keyword}
              className="w-60"
            />
            <IconButton
              onClick={() => {
                setKeyword((prev) => (prev?.length > 0 ? prev?.trim() : prev))
                onLoad()
              }}
              disabled={isLoading}
              color="primary"
              aria-label="delete"
              size="small"
            >
              <SyncIcon />
            </IconButton>
          </div>
          <Button
            type="primary"
            className="flex justify-center items-center"
            onClick={() => {
              setIsModal(true)
              setForm(null)
            }}
          >
            <PlusOutlined />
            &nbsp;Thêm
          </Button>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
    
          }}
        >
          <Table
            onRow={(record) => {
              return {
                onClick: () => {
                  setRowActive(record)
                }, // click row
              }
            }}
            scroll={{ y: 715, x: 2400 }}
            pagination={false}
            loading={isLoading}
            columns={columns}
            dataSource={listDoiTac}
            bordered
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default DoiTacKH

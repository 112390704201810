// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="file"] {
    display: none;
  }
  
  .style_custom_file_upload__e08nT {
    border: 1px solid #ccc;
    display:flex;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 8px;
    width: 150px;
    justify-content: space-between;
  }`, "",{"version":3,"sources":["webpack://./src/components/layout/NhapKho/Attach/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,8BAA8B;EAChC","sourcesContent":["input[type=\"file\"] {\n    display: none;\n  }\n  \n  .custom_file_upload {\n    border: 1px solid #ccc;\n    display:flex;\n    padding: 6px 12px;\n    cursor: pointer;\n    border-radius: 8px;\n    width: 150px;\n    justify-content: space-between;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom_file_upload": `style_custom_file_upload__e08nT`
};
export default ___CSS_LOADER_EXPORT___;

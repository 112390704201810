import * as typeAction from '../constants/constants'

export const getAllDichVu = () => ({
  type: typeAction.GET_ALL_LIST_DICH_VU,
})

export const ListDichVuAction = (idNhomDv, idct) => ({
  type: typeAction.GET_LIST_DICH_VU,
  idNhomDv,
  idct
})
export const listGroupsDichVuAction = () => ({
  type: typeAction.GET_LIST_GROUPS_DICH_VU,
})
export const getInfoDichVuAction = (iddv) => ({
  type: typeAction.GET_INFO_DICH_VU,
  iddv,
})
export const addNewDichVuAction = (form, onLoad) => ({
  type: typeAction.POST_NEW_DICH_VU,
  form,
  onLoad,
})
export const deleteDichVuAction = (iddv, onLoad) => ({
  type: typeAction.DELETE_DICH_VU,
  iddv,
  onLoad,
})
export const updateDichVuAction = (form, onLoad) => ({
  type: typeAction.UPDATE_DICH_VU,
  form,
  onLoad,
})

export const searchDanhMucDVAction = (idnhomdv, idct, keyword) => ({
  type: typeAction.SEARCH_DV_BY_KEYWORD,
  idnhomdv,
  idct,
  keyword
})

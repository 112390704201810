import React, { useEffect, lazy, Suspense, useState } from 'react'
import FormLogin from './FormLogin/FormLogin'
import style from './login.module.css'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import ButtonLang from '../../components/common/ButtonLang/ButtonLang'
import LoadingPage from '../../components/layout/Loading/LoadingPage'
import { useDispatch, useSelector } from 'react-redux'
import { listBranchAction } from '../../store/actions/BranchAction'
import { listAllCompanyAction } from '../../store/actions/companyAction'
import logo from '../../assets/images/logo/logoLogin/6logo.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component'
const Login = () => {
  const { t } = useTranslation('translation')
  const { loadingLogin } = useSelector((state) => state.modalReducer)
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const dispatch = useDispatch()

  useEffect(() => {
    if (ID_COMPANY) {
      dispatch(listBranchAction(ID_COMPANY))
    }
  }, [ID_COMPANY])
  useEffect(() => {
    dispatch(listAllCompanyAction())
  }, [])
  return (
    <>
      <div className="bg-gradient-to-r from-indigo-500 to-sky-500 h-screen flex ">
        <div className="w-1/2  relative flex flex-col ">
          <div className="w-[600px] absolute top-52 2xl:left-48 left-20 text-white">
            <div className="-translate-x-2 flex">
            <LazyLoadImage src={logo} alt='logo nhi'  loading="lazy" />
            </div>
            <h2
              // initial={{ y: -100, opacity: 0 }}
              // animate={{ y: 0, opacity: 1 }}
              // transition={{
              //   duration: 0.6,
              // }}
              className="  2xl:text-[36px] text-2xl font-medium"
            >
              Welcome to
            </h2>
            <span
              // initial={{ y: -100, opacity: 0 }}
              // animate={{ y: -30, opacity: 1 }}
              // transition={{
              //   duration: 0.6,
              // }}
              className="2xl:text-[72px] text-5xl font-semibold  "
            >
              315 medic
            </span>
            <ul className="2xl:mt-[60px] mt-20">
              <li
                // initial={{ x: -150, opacity: 0 }}
                // animate={{ x: 0, opacity: 1 }}
                // transition={{
                //   duration: 0.5,
                // }}
                className="flex text-center items-center bg-blue-950 gap-2 rounded-r-3xl rounded-tl-3xl p-2"
              >
                <span className="text-black overflow-hidden bg-white rounded-full font-medium w-[40px] h-[40px] leading-[40px] ">
                  <img
                    src="https://media.licdn.com/dms/image/C560BAQG1c6ytBSbvuQ/company-logo_200_200/0/1630655164945/nhi_dong_315_logo?e=2147483647&v=beta&t=lSFNfgWyYlUe_3xDEf4j8HYiSOg1l68VZ1oqF4UzCfE"
                    alt="nhidong315"
                  />
                </span>
                <p>{t('Quản lý phòng khám và bệnh viện')}</p>
              </li>
              <li
                // initial={{ x: -150, opacity: 0 }}
                // animate={{ x: 0, opacity: 1 }}
                // transition={{
                //   delay: 0.3,
                //   duration: 0.4,
                // }}
                className="flex text-center items-center mt-4 bg-blue-950 gap-2 rounded-r-3xl rounded-tl-3xl p-2"
              >
                <span className="text-black overflow-hidden bg-white rounded-full font-medium w-[40px] h-[40px] leading-[40px] ">
                  <img
                    src="https://media.licdn.com/dms/image/C560BAQG1c6ytBSbvuQ/company-logo_200_200/0/1630655164945/nhi_dong_315_logo?e=2147483647&v=beta&t=lSFNfgWyYlUe_3xDEf4j8HYiSOg1l68VZ1oqF4UzCfE"
                    alt="nhidong315"
                  />
                </span>
                <p>{t('Quản lý thông tin bệnh nhân')}</p>
              </li>
              <li
                // initial={{ x: -150, opacity: 0 }}
                // animate={{ x: 0, opacity: 1 }}
                // transition={{
                //   delay: 0.3,
                //   duration: 0.5,
                // }}
                className="flex text-center items-center mt-4 bg-blue-950 gap-2 rounded-r-3xl rounded-tl-3xl p-2"
              >
                <span className="text-black overflow-hidden bg-white rounded-full font-medium w-[40px] h-[40px] leading-[40px] ">
                  <img
                    src="https://media.licdn.com/dms/image/C560BAQG1c6ytBSbvuQ/company-logo_200_200/0/1630655164945/nhi_dong_315_logo?e=2147483647&v=beta&t=lSFNfgWyYlUe_3xDEf4j8HYiSOg1l68VZ1oqF4UzCfE"
                    alt="nhidong315"
                  />
                </span>
                <p>{t('Tra cứu kết quả xét nghiệm Online')}</p>
              </li>
            </ul>
          </div>
        </div>
        <div className={style.container_right}>
          <div className=" absolute top-10 xl:right-10 right-5">
            <ButtonLang label="Ngôn ngữ" />
          </div>
          <div
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1 }}
            // transition={{
            //   duration: 0.5,
            // }}
            className="flex flex-col bg-[rgb(255,255,255,0.85)] xl:mt-0 mt-10 ml-20 text-center rounded-xl xl:w-[450px] w-[400px] p-7"
            style={{
              border: 'unset',
              boxShadow: '0px 0px 14px 1px rgba(255,255,255,0.6)',
            }}
          >
            <h2 className="font-medium text-2xl mb-5">{t('Đăng nhập')}</h2>
            <FormLogin />
          </div>
        </div>
      </div>
      {loadingLogin && <LoadingPage />}
    </>
  )
}

export default Login

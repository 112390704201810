import { PrinterOutlined, SaveOutlined } from '@ant-design/icons'
import {
  Button,
  ConfigProvider,
  Divider,
  Drawer,
  Spin,
  Table,
  Input,
  Checkbox,
  InputNumber,
} from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { https } from '../../../../../services/apiService'
import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import { recieveService } from '../../../../../services/receive/recieveService'
import { generateKhamBenhXuatVacXin } from '../../../../../utils/report/vacxin/phieuKhamBenhXuatVacxin'
import { generatePhieuXuatKhoVacxin } from '../../../../../utils/report/phieuXuatKhoVacxin'
import { generatePhieuThuTienXuatVacxin } from '../../../../../utils/report/phieuThuTienXuatVacxin'
import { putBnTTvaBnCTTTphieuThuToaAction } from '../../../../../store/actions/drugstoreAction'
import ToastCus from '../../../../../utils/Toast'
import { generatePhieuKiemNhapVacxin } from '../../../../../utils/report/phieuKiemNhapVacxin'
import 'moment/locale/vi'
moment.locale('vi')
const columnsThuoc = [
  {
    title: <p className="text-center">Tên vắc xin</p>,
    dataIndex: 'tenbietduoc',
    // align: 'left',
    // align: 'center',
    key: 'tenbietduoc',
    className: 'text-sm',
  },
  {
    title: 'ĐVT',
    dataIndex: 'dvt',
    key: 'dvt',
    align: 'center',
    className: 'text-sm',
  },
  {
    title: 'SL',
    dataIndex: 'soluong',
    key: 'soluong',
    align: 'center',
    className: 'text-sm',
  },
  {
    title: <p className="text-center">Đơn giá</p>,
    dataIndex: 'dongia',
    key: 'dongia',
    align: 'right',
    className: 'text-sm',
    render: (text) => formattedNumber(text),
  },
  {
    title: <p className="text-center">Thành tiền</p>,
    dataIndex: 'thanhtien',
    key: 'thanhtien',
    align: 'right',
    className: 'text-sm',
    render: (text) => formattedNumber(text),
  },
]

const DrawerThuocBanToa = ({ chiTiet, open, setOpen }) => {
  const dispatch = useDispatch()
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [toaThuoc, setToaThuoc] = useState([])
  const [thongTinBN, setThongTinBN] = useState(null)
  const [infoBNTT, setInfoBNTT] = useState(null)
  const [infoBn, setInfoBN] = useState(null)
  const [duocSi, setDuocSi] = useState('')
  const [formBNTT, setFormBNTT] = useState(null)
  const [loading, setLoading] = useState(false)
  const checkIsLoading = !infoBn || !thongTinBN || !toaThuoc?.length || loading

  const [show, setShow] = useState(false)
  const handleSubmitBNTT = () => {
    const tongTienNew = infoBNTT?.tienthanhtoan
    const total =
      formBNTT?.chuyenkhoan +
      formBNTT?.tienmat +
      formBNTT?.thett +
      formBNTT?.momo +
      formBNTT?.vnpay +
      formBNTT?.zalopay +
      formBNTT?.pos
    if (total !== tongTienNew) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    setLoading(true)
    dispatch(
      putBnTTvaBnCTTTphieuThuToaAction(
        { ...infoBNTT, ...formBNTT },
        [],
        tongTienNew,
        null,
        resetForm,
      ),
    )
  }
  const resetForm = () => {
    setShow(false)
    setFormBNTT(null)
    setLoading(false)
  }
  const handleOnChangeHinhThucTT = (tenHinhThuc, giaTri) => {
    // setFormBNTT((prev) => ({ ...prev, pos: value ?? 0 }))
    setFormBNTT((prev) => {
      let conLai =
        infoBNTT?.tienthanhtoan -
        Object.entries(prev)
          .map(([key, value]) =>
            key === tenHinhThuc || key === 'hoadondientu' || key === 'ghichuxuathoadon' ? 0 : value,
          )
          .reduce((tong, item) => (tong += item), 0)
      return {
        ...prev,
        [tenHinhThuc]: giaTri <= conLai ? giaTri : conLai,
      }
    })
  }
  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        setInfoBN(null)
        setToaThuoc([])
        if (chiTiet?.idbnttc) {
          const { data } = await https.get(
            `BenhNhanPhieuThu/GetDanhSachToaThuoc?idbnttc=${chiTiet?.idbnttc}`,
          )
          const result = await recieveService.getInfoBN(chiTiet?.idbn)
          setInfoBN(result?.data)
          setDuocSi(data?.DUOCSI)
          setToaThuoc(data?.DanhSachThuoc)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [chiTiet?.idbnttc])

  useEffect(() => {
    ;(async () => {
      try {
        setThongTinBN(null)
        if (chiTiet?.idbnttc) {
          const { data } = await https.get(
            `BenhNhanTTC/GetByIDBNTTC-BNTT?idBNTTC=${chiTiet?.idbnttc}`,
          )
          setThongTinBN(data)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [chiTiet?.idbnttc])
  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        if (chiTiet?.idbnttc && !show) {
          const { data } = await https.get(
            `BenhNhanThanhToan/GetBenhNhanThanhToanByIDBNTTCToaThuoc`,
            {
              params: { idbnttc: chiTiet?.idbnttc, idloaitt: 6 },
            },
          )
          setInfoBNTT(data)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [chiTiet?.idbnttc, show])

  const calculateSum = () => {
    let sum = 0
    toaThuoc.forEach((item) => {
      sum += item.thanhtien
    })
    return sum
  }

  const handlePrintPhieuChiDinh = () => {
    const toaThuocFormat = toaThuoc?.map((item) => {
      return {
        ...item,
        tenBietDuoc: item.tenbietduoc,
        dvt: item.dvt,
        soLuong: item.soluong,
        duongDung: item.duongdung,
        soLanNgay: item.solanngay,
        lieuDung: item.lieudung,
        ghiChu: item.ghichu ?? '',
      }
    })
    generateKhamBenhXuatVacXin(infoBn, thongTinBN, toaThuocFormat, PkDangNhap)
  }
  const handlePrintPhieuXuatVacXin = async () => {
    const toaThuocFormat = toaThuoc?.map((item) => ({
      ...item,
      donGia: item.dongia,
      tenBietDuoc: item.tenbietduoc,
      // tenHoatChat: item.tenhoatchat,
      soLuong: item.soluong,
      soLo: item.solo,
      hanDung: item.handung,
      thanhTien: item.thanhtien,
    }))
    const { data } = await https.get(
      `BenhNhanThanhToan/GetBenhNhanThanhToanByIDBNTTC?idBNTTC=${chiTiet?.idbnttc}`,
    )
    const ttPhieuThu = {
      ngayThanhToan: data[0].ngayThanhToan,
      maThanhToan: `TT${infoBn.maBenhNhan}`,
      duocSi: duocSi,
      maPhieuThu: data[0].maPhieuThu,
    }
    generatePhieuXuatKhoVacxin(ttPhieuThu, thongTinBN, toaThuocFormat, PkDangNhap)
  }
  const handlePrintPhieuThuTienXuatVacXin = async () => {
    const toaThuocFormat = toaThuoc?.map((item) => ({
      ...item,
      donGia: item.dongia,
      tenBietDuoc: item.tenbietduoc,
      // tenHoatChat: item.tenhoatchat,
      soLuong: item.soluong,
      soLo: item.solo,
      hanDung: item.handung,
      thanhTien: item.thanhtien,
    }))
    const { data } = await https.get(
      `BenhNhanThanhToan/GetBenhNhanThanhToanByIDBNTTC?idBNTTC=${chiTiet?.idbnttc}`,
    )
    const ttPhieuThu = {
      ngayThanhToan: data[0].ngayThanhToan,
      maThanhToan: `TT${infoBn.maBenhNhan}`,
      duocSi: duocSi,
      maPhieuThu: data[0].maPhieuThu,
    }
    generatePhieuThuTienXuatVacxin(ttPhieuThu, thongTinBN, toaThuocFormat, PkDangNhap)
  }
  const handlePrintBienBanKiemNhap = () => {
    const toaThuocFormat = toaThuoc?.map((item) => {
      return {
        ...item,
        tenBietDuoc: item.tenbietduoc,
        soLo: item.solo,
        hanDung: item.handung,
        dvt: item.dvt,
        soLuong: item.soluong,
        duongDung: item.duongdung,
        soLanNgay: item.solanngay,
        lieuDung: item.lieudung,
        ghiChu: item.ghichu ?? '',
      }
    })
    generatePhieuKiemNhapVacxin(infoBn, thongTinBN, toaThuocFormat, PkDangNhap)
  }

  return (
    <Drawer
      title={'Chi tiết phiếu bán vắc xin'}
      placement="right"
      size="large"
      onClose={() => {
        setOpen(false)
        setShow(false)
      }}
      open={open}
    >
      <div>
        <div className="flex justify-start items-center gap-2">
          <Button
            loading={checkIsLoading}
            size="small"
            onClick={handlePrintPhieuChiDinh}
            type="primary"
            icon={<PrinterOutlined />}
          >
            Phiếu chỉ định
          </Button>
          <Button
            loading={checkIsLoading}
            size="small"
            onClick={handlePrintPhieuXuatVacXin}
            type="primary"
            icon={<PrinterOutlined />}
          >
            Phiếu xuất vắc xin
          </Button>
          <Button
            loading={checkIsLoading}
            size="small"
            onClick={() => handlePrintBienBanKiemNhap(PkDangNhap)}
            type="primary"
            icon={<PrinterOutlined />}
          >
            Biên bản kiểm nhập
          </Button>
          <Button
            loading={checkIsLoading}
            size="small"
            onClick={handlePrintPhieuThuTienXuatVacXin}
            type="primary"
            icon={<PrinterOutlined />}
          >
            Phiếu thu tiền
          </Button>
        </div>
        <h2 className="text-gray-500 font-semibold text-lg mb-2">Thông tin bệnh nhân</h2>
        <div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Tên BN :</label>
              <p>{thongTinBN?.benhNhan?.tenBenhNhan}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Mã BN :</label>
              <p>{thongTinBN?.benhNhan?.maBenhNhan}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Ngày sinh :</label>
              <p>
                {thongTinBN?.benhNhan?.ngaySinh &&
                  moment(thongTinBN?.benhNhan?.ngaySinh).format('DD/MM/YYYY')}
              </p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Phiếu thu :</label>
              <p>
                {thongTinBN?.benhNhanThanhToans && thongTinBN?.benhNhanThanhToans[0]?.maPhieuThu}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Giới tính :</label>
              <p>{thongTinBN?.benhNhan?.gioiTinh}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Ngày thu :</label>
              <p>
                {thongTinBN?.benhNhanThanhToans
                  ? moment(thongTinBN?.benhNhanThanhToans[0]?.ngayThanhToan).format('DD/MM/YYYY')
                  : ''}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Đối tượng :</label>
              <p>{infoBn?.tenDoiTuong}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Loại khám :</label>
              <p>{thongTinBN?.tenLoaiKham}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Nguồn :</label>
              <p>{thongTinBN?.tenNguon}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Quốc tịch :</label>
              <p>{thongTinBN?.benhNhan?.tenQuocTich}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Tên người thân :</label>
              <p>{thongTinBN?.tennguoinha}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">SĐT:</label>
              <p>{thongTinBN?.dienthoainguoinha}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Chi nhánh :</label>
              <p>{thongTinBN?.benhNhan?.chiNhanh?.tenChiNhanh}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Mã TCQG:</label>
              <p>{infoBn?.maTCQG}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Người thu :</label>
              <p>{thongTinBN?.benhNhanThanhToans && thongTinBN?.benhNhanThanhToans[0]?.thuNgan}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Số thứ tự:</label>
              <p>{thongTinBN?.sttkham}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Bác sĩ khám :</label>
              <p>{thongTinBN?.bskham}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Phòng:</label>
              <p>{thongTinBN?.tenPhongKham}</p>
            </div>
          </div>
          <div className="flex">
            <label className="font-semibold w-32">Địa chỉ: </label>
            <p>{` ${thongTinBN?.benhNhan?.diaChi ?? ''}${thongTinBN?.benhNhan?.tenPhuongXa ? ', ' + thongTinBN?.benhNhan?.tenPhuongXa : ''}${thongTinBN?.benhNhan?.tenQuanHuyen ? ', ' + thongTinBN?.benhNhan?.tenQuanHuyen : ''}${thongTinBN?.benhNhan?.tenTinhTP ? ', ' + thongTinBN?.benhNhan?.tenTinhTP : ''}`}</p>
          </div>
        </div>
        <Divider style={{ margin: 15 }} />
        <div>
          <div className="flex justify-between items-center">
            <h2 className="text-gray-500 font-semibold text-lg mb-2">Chi tiết thanh toán</h2>
            <div className="flex justify-between items-center gap-2">
              {(infoUser?.idNhom === 1 ||
                infoUser?.idNhom === 28 ||
                infoUser?.idNhom === 27 ||
                (infoBNTT?.idthungan === infoUser?.idnv &&
                  infoBNTT?.idcalamviec === infoUser?.dangNhap?.idCaLamViec)) && ( //|| infoUser?.idNhom === 4 hiện tại chỉ có IT mới dc chỉnh
                <>
                  {!show && !checkIsLoading && (
                    <>
                      <div
                        onClick={() => {
                          setShow(true)
                          setFormBNTT((prev) => ({
                            ...prev,
                            tienmat: infoBNTT?.tienmat,
                            chuyenkhoan: infoBNTT?.chuyenkhoan,
                            thett: infoBNTT?.thett,
                            momo: infoBNTT?.momo,
                            vnpay: infoBNTT?.vnpay,
                            zalopay: infoBNTT?.zalopay,
                            pos: infoBNTT?.pos,
                            hoadondientu: infoBNTT?.hoadondientu,
                            ghichuxuathoadon: infoBNTT?.ghichuxuathoadon,
                          }))
                        }}
                        className="bg-green-200 flex text-green-700 hover:bg-green-500 cursor-pointer duration-150 hover:text-white items-center rounded-md p-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                          />
                        </svg>
                      </div>
                    </>
                  )}
                  {show && !checkIsLoading && (
                    <>
                      <div className="flex gap-2">
                        <div
                          onClick={handleSubmitBNTT}
                          className="bg-blue-200 flex text-blue-700 hover:bg-blue-500 cursor-pointer duration-150 hover:text-white items-center rounded-md p-[5px]"
                        >
                          <SaveOutlined style={{ fontSize: 18 }} />
                        </div>
                        <div
                          onClick={() => {
                            setShow(false)
                            setFormBNTT(null)
                          }}
                          className="bg-red-200 flex text-red-700 hover:bg-red-500 cursor-pointer duration-150 hover:text-white items-center rounded-md p-1"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18 18 6M6 6l12 12"
                            />
                          </svg>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {loading ? (
            <div className="flex justify-center items-center h-[6.75rem]">
              <Spin size="large" />
            </div>
          ) : (
            <div className="grid grid-flow-row gap-1 items-center">
              <div className="grid grid-cols-3 gap-2 items-center h-[1.5rem]">
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">Tiền mặt:</label>
                  <div>
                    {show ? (
                      <>
                        {/* <InputNumber
                          size="small"
                          className="w-full"
                          value={formBNTT?.tienmat}
                          min={0}
                          step={1000}
                          max={infoBNTT?.tienthanhtoan}
                          onChange={(value) =>
                            setFormBNTT((prev) => ({ ...prev, tienmat: value ?? 0 }))
                          }
                        /> */}
                        <InputNumber
                          size="small"
                          className="w-full"
                          value={formBNTT?.tienmat}
                          onChange={(value) => handleOnChangeHinhThucTT('tienmat', value)}
                          onKeyUp={(e) => {
                            if (e.key === 'p' || e.key === 'P') {
                              handleOnChangeHinhThucTT('tienmat', infoBNTT?.tienthanhtoan)
                            }
                          }}
                          step={100}
                          formatter={(value) => {
                            // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                            return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          min={0}
                          max={infoBNTT?.tienthanhtoan}
                        />
                      </>
                    ) : (
                      <>{formattedNumber(infoBNTT?.tienmat ?? 0)}</>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">Chuyển khoản:</label>

                  <div>
                    {show ? (
                      <>
                        {/* <InputNumber
                          size="small"
                          className="w-full"
                          value={formBNTT?.chuyenkhoan}
                          min={0}
                          step={1000}
                          max={infoBNTT?.tienthanhtoan}
                          onChange={(value) =>
                            setFormBNTT((prev) => ({ ...prev, chuyenkhoan: value ?? 0 }))
                          }
                        /> */}
                        <InputNumber
                          size="small"
                          className="w-full"
                          value={formBNTT?.chuyenkhoan}
                          onChange={(value) => handleOnChangeHinhThucTT('chuyenkhoan', value)}
                          onKeyUp={(e) => {
                            if (e.key === 'p' || e.key === 'P') {
                              handleOnChangeHinhThucTT('chuyenkhoan', infoBNTT?.tienthanhtoan)
                            }
                          }}
                          step={100}
                          formatter={(value) => {
                            // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                            return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          min={0}
                          max={infoBNTT?.tienthanhtoan}
                        />
                      </>
                    ) : (
                      <>{formattedNumber(infoBNTT?.chuyenkhoan ?? 0)}</>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">Máy POS:</label>
                  <div>
                    {show ? (
                      <>
                        {/* <InputNumber
                          size="small"
                          className="w-full"
                          value={formBNTT?.pos}
                          min={0}
                          step={1000}
                          max={infoBNTT?.tienthanhtoan}
                          onChange={(value) => setFormBNTT((prev) => ({ ...prev, pos: value ?? 0 }))}
                        /> */}
                        <InputNumber
                          size="small"
                          className="w-full"
                          value={formBNTT?.pos}
                          onChange={(value) => handleOnChangeHinhThucTT('pos', value)}
                          onKeyUp={(e) => {
                            if (e.key === 'p' || e.key === 'P') {
                              handleOnChangeHinhThucTT('pos', infoBNTT?.tienthanhtoan)
                            }
                          }}
                          step={100}
                          formatter={(value) => {
                            // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                            return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          min={0}
                          max={infoBNTT?.tienthanhtoan}
                        />
                      </>
                    ) : (
                      <>{formattedNumber(infoBNTT?.pos ?? 0)}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-2 items-center h-[1.5rem]">
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">Momo:</label>
                  <div>
                    {show ? (
                      <>
                        {/* <InputNumber
                          size="small"
                          className="w-full"
                          value={formBNTT?.momo}
                          min={0}
                          step={1000}
                          max={infoBNTT?.tienthanhtoan}
                          onChange={(value) => setFormBNTT((prev) => ({ ...prev, momo: value ?? 0 }))}
                        /> */}
                        <InputNumber
                          variant="filled"
                          readOnly
                          size="small"
                          className="w-full"
                          value={formBNTT?.momo}
                          onChange={(value) => handleOnChangeHinhThucTT('momo', value)}
                          onKeyUp={(e) => {
                            if (e.key === 'p' || e.key === 'P') {
                              handleOnChangeHinhThucTT('momo', infoBNTT?.tienthanhtoan)
                            }
                          }}
                          step={100}
                          formatter={(value) => {
                            // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                            return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          min={0}
                          max={infoBNTT?.tienthanhtoan}
                        />
                      </>
                    ) : (
                      <>{formattedNumber(infoBNTT?.momo ?? 0)}</>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">VNPay:</label>
                  <div>
                    {show ? (
                      <>
                        {/* <InputNumber
                          size="small"
                          className="w-full"
                          value={formBNTT?.vnpay}
                          min={0}
                          step={1000}
                          max={infoBNTT?.tienthanhtoan}
                          onChange={(value) =>
                            setFormBNTT((prev) => ({ ...prev, vnpay: value ?? 0 }))
                          }
                        /> */}
                        <InputNumber
                          variant="filled"
                          readOnly
                          size="small"
                          className="w-full"
                          value={formBNTT?.vnpay}
                          onChange={(value) => handleOnChangeHinhThucTT('vnpay', value)}
                          onKeyUp={(e) => {
                            if (e.key === 'p' || e.key === 'P') {
                              handleOnChangeHinhThucTT('vnpay', infoBNTT?.tienthanhtoan)
                            }
                          }}
                          step={100}
                          formatter={(value) => {
                            // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                            return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          min={0}
                          max={infoBNTT?.tienthanhtoan}
                        />
                      </>
                    ) : (
                      <>{formattedNumber(infoBNTT?.vnpay ?? 0)}</>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">ZaloPay:</label>
                  <div>
                    {show ? (
                      <>
                        {/* <InputNumber
                          size="small"
                          className="w-full"
                          value={formBNTT?.zalopay}
                          min={0}
                          step={1000}
                          max={infoBNTT?.tienthanhtoan}
                          onChange={(value) =>
                            setFormBNTT((prev) => ({ ...prev, zalopay: value ?? 0 }))
                          }
                        /> */}
                        <InputNumber
                          variant="filled"
                          readOnly
                          size="small"
                          className="w-full"
                          value={formBNTT?.zalopay}
                          onChange={(value) => handleOnChangeHinhThucTT('zalopay', value)}
                          onKeyUp={(e) => {
                            if (e.key === 'p' || e.key === 'P') {
                              handleOnChangeHinhThucTT('zalopay', infoBNTT?.tienthanhtoan)
                            }
                          }}
                          step={100}
                          formatter={(value) => {
                            // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                            return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          min={0}
                          max={infoBNTT?.tienthanhtoan}
                        />
                      </>
                    ) : (
                      <>{formattedNumber(infoBNTT?.zalopay ?? 0)}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-2 items-center h-[1.5rem]">
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">Thẻ thanh toán:</label>
                  <div>
                    {show ? (
                      <>
                        {/* <InputNumber
                          size="small"
                          className="w-full"
                          value={formBNTT?.thett}
                          min={0}
                          step={1000}
                          max={infoBNTT?.tienthanhtoan}
                          onChange={(value) =>
                            setFormBNTT((prev) => ({ ...prev, thett: value ?? 0 }))
                          }
                        /> */}
                        <InputNumber
                          variant="filled"
                          readOnly
                          size="small"
                          className="w-full"
                          value={formBNTT?.thett}
                          onChange={(value) => handleOnChangeHinhThucTT('thett', value)}
                          onKeyUp={(e) => {
                            if (e.key === 'p' || e.key === 'P') {
                              handleOnChangeHinhThucTT('thett', infoBNTT?.tienthanhtoan)
                            }
                          }}
                          step={100}
                          formatter={(value) => {
                            // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                            return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          min={0}
                          max={infoBNTT?.tienthanhtoan}
                        />
                      </>
                    ) : (
                      <>{formattedNumber(infoBNTT?.thett ?? 0)}</>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">Phương thức TT:</label>
                  <div>Thanh toán</div>
                </div>
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">Xuất HĐ điện tử:</label>
                  <Checkbox
                    checked={show ? formBNTT?.hoadondientu : infoBNTT?.hoadondientu}
                    onChange={(e) =>
                      show &&
                      setFormBNTT((prev) => ({ ...prev, hoadondientu: e.target.checked ? 1 : 0 }))
                    }
                  />
                </div>
              </div>
              <div className="flex justify-start items-center h-[1.5rem]">
                <label className="font-semibold w-[8.4rem]">Ghi chú xuất HĐ:</label>
                <div className="w-full">
                  {show ? (
                    <>
                      <Input
                        size="small"
                        className="w-full text-red-500"
                        value={formBNTT?.ghichuxuathoadon}
                        onChange={(e) =>
                          setFormBNTT((prev) => ({ ...prev, ghichuxuathoadon: e.target.value }))
                        }
                      />
                    </>
                  ) : (
                    <>{infoBNTT?.ghichuxuathoadon}</>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <Divider style={{ margin: 15 }} />
        <div>
          {/* Table displaying prescription details */}
          <h2 className="text-gray-500 font-semibold text-lg mb-2">Chi tiết vắc xin</h2>
          <div className="mt-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                bordered
                pagination={{
                  pageSize: 10,
                  showSizeChanger: false,
                }}
                columns={columnsThuoc}
                dataSource={toaThuoc}
                footer={() => (
                  <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    Tổng: {formattedNumber(calculateSum())}
                  </div>
                )}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default DrawerThuocBanToa

import { Modal, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import * as typeAction from '../../../store/constants/constants'
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { suCoService } from '../../../services/suCoService/suCoServvice'

const ModalChangeBranch = () => {
  const dispatch = useDispatch()
  const [branch, setBranch] = useState([])
  const { isChangeBranch } = useSelector((state) => state.modalReducer)
  const ID_BRANCH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const [idBranch, setIdBranch] = useState(ID_BRANCH_LOGIN)
  const cancelModal = () => {
    dispatch({
      type: typeAction.DISPATCH_CLOSE_CHANGE_BRANCH,
    })
  }

  const handleOK = async () => {
    await localStorage.setItem('BRANH_LOGIN', idBranch)
    cancelModal()
    window.location.reload()
  }
  const fetchBranch = async () => {
    try {
      const { data } = await suCoService.getAllBranh()
      setBranch(data)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchBranch()
  }, [])
  return (
    <Modal
      onOk={handleOK}
      okText="Chuyển đổi"
      cancelText="Huỷ"
      onCancel={cancelModal}
      open={isChangeBranch}
      title={
        <h2 className="text-center">
          Chuyển chi nhánh đăng nhập <SyncAltOutlinedIcon />
        </h2>
      }
    >
      <p className="font-semibold">Tên chi nhánh:</p>
      <Select
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        showSearch
        value={idBranch}
        onChange={setIdBranch}
        options={branch?.map((item) => ({
          label: item.tenchinhanh,
          value: item.idchinhanh,
        }))}
        className="w-full"
      />
    </Modal>
  )
}

export default ModalChangeBranch

import { Tabs } from 'antd'
import React from 'react'
import ReportBranch from './ReportBranch'
import DetailBranch from '../Report/DetailBranch/DetailBranch'
import ReportBranchsMonth from '../Report/ReportBranchsMonth/ReportBranchsMonth'

const ContainerReport = () => {
  return (
    <>
      <div className="p-3 h-full bg-[#EFEFEF]">
        <div
          className="h-full p-2 bg-white border rounded-xl"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <Tabs
            items={[
              {
                key: 1,
                value: 1,
                label: 'Tổng hợp',
                children:<ReportBranch/>
              },
              {
                key: 2,
                value: 2,
                label: 'Chi tiết',
                children: <DetailBranch/>
              },
              {
                key: 3,
                value: 3,
                label: 'Chi tiết tháng',
                children: <ReportBranchsMonth/>
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default ContainerReport
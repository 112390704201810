import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  dsLienHe: null,
  chitietLH: null,
  infoLienHe: null,
}

const lienheCRMReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_ALL_LH_CRM:
        draft.dsLienHe = payload
        break
      case typeAction.DISPATCH_INFO_CHITIET_LH_CRM:
        draft.chitietLH = payload
        break
      case typeAction.DISPATCH_INFO_LH_CRM:
        draft.infoLienHe = payload
        break
      default:
        return state
    }
  })
}

export default lienheCRMReducer

import React, { useState } from 'react'
import moment from 'moment'
import _ from 'lodash'
import {
  Tag,
  Popover,
  Popconfirm,
  Tooltip,
  AutoComplete,
  Avatar,
  ConfigProvider,
  Table,
  notification,
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  addNhanVienBaoHong,
  deleteNhanVienBaoHong,
  getDetailBaoHong,
} from '../../../../store/actions/suCoAction'
import DeletStaffTable from '../../SuCo/DeleteStaff/DeletStaffTable'
const TableBaoCao = ({ setOpen, fetchData, setValueExport }) => {
  const [api, contextHolder] = notification.useNotification()
  const { infoUser } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const { isLoadingTable } = useSelector((state) => state.modalReducer)
  const { DanhSachBaoHong, thucHien } = useSelector((state) => state.suCoReducer)
  const [staff, setStaff] = useState(null)
  const [openDel, setOpenDel] = useState(false)
  const handleShowDetail = (idbh) => {
    dispatch(getDetailBaoHong(idbh))
    setOpen(true)
  }

  const openNotificationWithIcon = () => {
    api.warning({
      message: 'Thêm người thực hiện',
      description: 'Nhân viên đã được thêm.',
    })
  }
  const onChange = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }
  const addNhanVien = (option, ob) => {
    for (let i of ob.DanhSachNhanVien) {
      // kiểm tra nhân viên đã add hay chưa
      if (i.idnv === option.id) {
        openNotificationWithIcon()
        return
      }
    }
    const payload = {
      idbaohong: ob.IDBAOHONG,
      idnv: option?.id,
      nguoitao: infoUser?.idnv,
    }
    dispatch(addNhanVienBaoHong(payload, fetchData))
  }
  return (
    <>
      {contextHolder}
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          onChange={onChange}
          loading={isLoadingTable}
          pagination={false}
          scroll={{
            x: 1900,
            y: 600,
          }}
          columns={[
            {
              title: 'STT',
              dataIndex: 'STT',
              width: 60,
              align: 'center',
              render: (text, _, index) => ++index,
            },
            {
              title: 'Chi nhánh',
              dataIndex: 'CHINHANHBAOHONG',
              width: 150,
            },
            {
              title: 'Tên tiêu đề',
              dataIndex: 'TENBAOHONG',
              width: 200,
            },
            {
              title: 'Người tạo',
              dataIndex: 'NGUOIBAOHONG',
              width: 150,
            },
            {
              title: 'ngày tạo',
              dataIndex: 'NGAYBAOHONG',
              width: 100,
              render: (text) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
            },
            {
              title: 'Đơn vị',
              dataIndex: 'DONVINHAN',
              width: 100,
              align: 'center',
              filters: [
                {
                  text: 'Kĩ thuật',
                  value: 270,
                },
                {
                  text: 'IT',
                  value: 27,
                },
              ],
              onFilter: (value, record) => record.IDDONVINHAN === value,
            },
            {
              title: 'Người thực hiện',
              dataIndex: 'donvinhan',
              width: 120,
              render: (text, ob) => {
                return (
                  <div className="flex items-center gap-0.5">
                    {ob.DanhSachNhanVien?.slice(0, 3).map((item) => {
                      // take the final of full name
                      const words = item.tennhanvien?.split(' ')
                      const lastWord = words && words[words.length - 1]
                      return (
                        <Popover
                          placement="bottom"
                          title="Nhân viên"
                          key={item.idnv}
                          content={() => {
                            return (
                              <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                                <table class="w-full text-sm text-gray-500  text-center">
                                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-secondary-dark dark:text-white">
                                    <tr>
                                      <th scope="col" class="px-6 py-3">
                                        Mã nhân viên
                                      </th>
                                      <th scope="col" class="px-6 py-3">
                                        Tên nhân viên
                                      </th>
                                      {ob.IDTRANGTHAIPHIEU === 1 && (
                                        <th scope="col" class="px-6 py-3">
                                          Hành động
                                        </th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {ob.DanhSachNhanVien?.map((item) => (
                                      <tr
                                        class="bg-white border-b  dark:bg-gray-800 dark:text-gray-300"
                                        key={item.idnv}
                                      >
                                        <th
                                          scope="row"
                                          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-gray-300"
                                        >
                                          {item.manhanvien}
                                        </th>
                                        <td class="px-6 py-4">{item.tennhanvien}</td>
                                        {ob.IDTRANGTHAIPHIEU === 1 && (
                                          <td class="px-6 py-4 ">
                                            <button
                                              onClick={() => {
                                                setStaff(item)
                                                setOpenDel(true)
                                              }}
                                              className="text-red-500 hover:text-gray-600 text-lg leading-3 mx-auto block"
                                            >
                                              <DeleteOutlined />
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            )
                          }}
                        >
                          <Avatar className="bg-blue-600" size="default">
                            {lastWord}
                          </Avatar>
                        </Popover>
                      )
                    })}
                    <div>{ob?.DanhSachNhanVien?.length > 3 && '...'}</div>
                    {ob.DanhSachNhanVien && (
                      <Popover
                        trigger="click"
                        content={() => {
                          return (
                            <AutoComplete
                              placeholder="Enter name"
                              style={{ width: 200 }}
                              filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                              }
                              options={thucHien?.map((item) => ({
                                label: item.tennhanvien,
                                value: item.tennhanvien,
                                id: item.idnv,
                              }))}
                              onSelect={(value, option) => {
                                addNhanVien(option, ob)
                              }}
                            />
                          )
                        }}
                        title="Thêm người"
                      >
                        {ob.IDTRANGTHAIPHIEU === 1 && (
                          <button className="w-6 h-6 rounded bg-gray-300 dark:text-black">+</button>
                        )}
                      </Popover>
                    )}
                  </div>
                )
              },
            },

            {
              title: 'Mức độ',
              dataIndex: 'MUCDOSUCO',
              width: 100,
              align: 'center',
              filters: [
                {
                  text: 'Không gấp',
                  value: 1,
                },
                {
                  text: 'Bình thường',
                  value: 2,
                },
                {
                  text: 'Gấp',
                  value: 3,
                },
                {
                  text: 'Rất gấp',
                  value: 4,
                },
              ],
              onFilter: (value, record) => record.IDMUCDOSUCO === value,
              render: (text, record, index) => (
                <Tag
                  color={
                    record.IDMUCDOSUCO === 1 ? 'blue' : record.IDMUCDOSUCO === 2 ? 'orange' : 'red'
                  }
                >
                  {text}
                </Tag>
              ),
            },
            {
              title: 'Trạng thái',
              dataIndex: 'TRANGTHAIPHIEU',
              width: 90,
              render: (text, record, index) => (
                <Tag
                  bordered={false}
                  color={
                    record.IDTRANGTHAIPHIEU === 1
                      ? 'cyan-inverse'
                      : record.IDTRANGTHAIPHIEU === 2
                        ? 'orange-inverse'
                        : record.IDTRANGTHAIPHIEU === 3
                          ? 'blue-inverse'
                          : 'green-inverse'
                  }
                >
                  {text}
                </Tag>
              ),
              filters: [
                {
                  text: 'Mới tạo',
                  value: 1,
                },
                {
                  text: 'Đã nhận',
                  value: 2,
                },
                {
                  text: 'Xác Nhận',
                  value: 3,
                },
                {
                  text: 'Hoàn Thành',
                  value: 4,
                },
              ],
              onFilter: (value, record) => record.IDTRANGTHAIPHIEU === value,
            },
            {
              title: 'Người nhận',
              dataIndex: 'NGUOINHAN',
              width: 120,
            },
            {
              title: 'ngày nhận',
              dataIndex: 'NGAYNHAN',
              width: 120,
              render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : ''),
            },
            {
              title: '',
              dataIndex: 'STT',
              width: 60,
              fixed: 'right',
              render: (_, record) => (
                <>
                  <ul className="flex items-center gap-2 justify-center">
                    <li
                      onClick={() => handleShowDetail(record.IDBAOHONG)}
                      className="text-blue-500 bg-blue-200 p-1 rounded-lg cursor-pointer hover:bg-blue-500 hover:text-white duration-200"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Zm3.75 11.625a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                        />
                      </svg>
                    </li>
                  </ul>
                </>
              ),
            },
          ]}
          dataSource={DanhSachBaoHong}
        />
      </ConfigProvider>
      <DeletStaffTable open={openDel} setOpen={setOpenDel} staff={staff} fetchData={fetchData} />
    </>
  )
}

export default TableBaoCao

import { Tabs } from 'antd'
import React, { useEffect } from 'react'
import Products from './Products/Products'
import CreateKyHang from './CreateKyHang/CreateKyHang'
import CustomizeProducts from './CusomizeProducts/CustomizeProducts'
import { useDispatch } from 'react-redux'
import { getKyHangs } from '../../../store/actions/kiemKeAction'
const KiemKe = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getKyHangs())
  },[])
  return (
    <div className="h-full p-3 bg-[#efefef]">
      <div
        className="bg-white h-full rounded-lg border p-2"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <Tabs
          items={[
            {
              key: 1,
              label: 'Hàng hoá',
              children: <Products />,
            },
            {
              key: 2,
              label: 'Chốt kỳ hàng',
              children: <CreateKyHang/>,
            },
            {
              key: 3,
              label: 'Điều chỉnh',
              children: <CustomizeProducts/>,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default KiemKe

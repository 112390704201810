import { ConfigProvider, Modal, Table } from 'antd'
import React from 'react'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { width } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { postChotDieuChinh } from '../../../../../store/actions/kiemKeAction'
const ModalChotKiemKe = ({ open, setOpen, infoKimeKe, dataSource }) => {
    const {infoUser} = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  const ProductsNhap = (data) =>
    data?.filter((item) => item.tonkhothucte && item.tonkhothucte - item.tonKho > 0)
  const ProductsXuat = (data) =>
    data?.filter((item) => item.tonkhothucte && item.tonkhothucte - item.tonKho < 0)

  const columns = [
    {
      title: 'STT',
      key: 'STT',
      width: 50,
      render: (_, record, index) => ++index,
      align: 'center',
    },
    {
      title: 'Mã hàng',
      key: 'maThuoc',
      dataIndex: 'maThuoc',
      width: 90,
      render: (_, record) => record?.thuocVatTu.maThuoc,
    },
    {
      title: 'Tên hàng',
      key: 'tenHoatChat',
      dataIndex: 'tenHoatChat',
      render: (_, record) => record?.thuocVatTu.tenBietDuoc,
    },
    {
      title: 'Kho',
      key: 'tenKhoCn',
      dataIndex: 'tenKhoCn',
      width: 160,
    },
    {
      title: 'Tồn lẻ',
      key: 'tonKho',
      dataIndex: 'tonKho',
      align: 'center',
      width: 90,
    },
    {
      title: 'Thực tế',
      key: 'tonkhothucte',
      dataIndex: 'tonkhothucte',
      align: 'center',
      width: 90,
    },
    {
      title: 'Chênh lệch',
      key: 'chenhlech',
      dataIndex: 'chenhlech',
      width: 90,
      align: 'center',
      render: (_, record) => {
        const count = record.tonkhothucte - record.tonKho
        return (
          <p className={`${count > 0 ? 'text-green-700' : 'text-red-500'}`}>
            {count > 0 ? '+' : ''}
            {count}
          </p>
        )
      },
      fixed: 'right',
    },
  ]
  const handleCancel = () => {
    setOpen(false)
  }
  const handleOk = () => {
    dispatch(
      postChotDieuChinh(infoKimeKe?.idky, infoKimeKe?.idChiNhanh, infoUser?.idnv, handleCancel),
    )
  }
  return (
    <Modal
      onOk={handleOk}
      title="Chốt hàng hoá điều chỉnh kiểm kê"
      okText="Lưu"
      cancelText="Huỷ"
      open={open}
      width={800}
      onCancel={handleCancel}
    >
      <div className="flex gap-2 border-t p-2">
        <div className="w-1/3">
          <label htmlFor="" className=" text-gray-400">
            Công ty:
          </label>
          <p className="font-semibold ">{infoKimeKe?.tenct}</p>
        </div>
        <div className="w-1/3">
          <label htmlFor="" className=" text-gray-400">
            Chi nhánh:
          </label>
          <p className="font-semibold ">{infoKimeKe?.tenChiNhanh}</p>
        </div>
        <div className="w-1/3">
          <label htmlFor="" className="text-gray-400">
            kỳ hàng:
          </label>
          <p className="font-semibold ">{infoKimeKe?.tenky}</p>
        </div>
      </div>
      <div className="p-2">
        <h2 className="font-semibold text-lg">
          {' '}
          Hàng nhập thêm{' '}
          <i className="text-green-700">
            (<PlusOutlined />)
          </i>
        </h2>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            pagination={false}
            scroll={{
              x: 1000,
              y: 200,
            }}
            bordered
            dataSource={ProductsNhap(dataSource)}
            columns={columns}
          />
        </ConfigProvider>
      </div>
      <div className="p-2">
        <h2 className="font-semibold text-lg">
          {' '}
          Hàng giảm{' '}
          <i className="text-red-500">
            (<MinusOutlined />)
          </i>
        </h2>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            pagination={false}
            scroll={{
              x: 1000,
              y: 200,
            }}
            bordered
            columns={columns}
            dataSource={ProductsXuat(dataSource)}
          />
        </ConfigProvider>
      </div>
    </Modal>
  )
}

export default ModalChotKiemKe

import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Input, Tabs, ConfigProvider, Table, Space, Spin } from 'antd'
import { GoldOutlined, LinkOutlined, AppstoreOutlined, SearchOutlined } from '@ant-design/icons'
import { postListPhieuNhapVPPAction } from '../../../../../store/actions/NhapKhoAction'
import ToastCus from '../../../../../utils/Toast'
import InfoPhieuNhap from './InfoPhieuNhap'
import TabHangHoa from './TabHangHoa'
import TabDinhKem from './TabDinhKem'
import { useFormik } from 'formik'
import _ from 'lodash'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

function ModalNhapKho({
  isModalNhapKho,
  setIsModalNhapKho,
  infoPhieuNhap,
  setInfoPhieuNhap,
  getDataAsync,
}) {
  const dispatch = useDispatch()
  // const ID_COMPANY = Number(localStorage.getItem('id_company'))
  // const now = moment()
  // const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  // const { infoUser } = useSelector((state) => state.userReducer)
  const { thuocVT, listVPP } = useSelector((state) => state.NhapKhoReducer)
  const [selectedFile, setSelectedFile] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const initialValues = useMemo(
    () => ({
      tenPhieu: '',
      phuongThuc: 2,
      hinhThuc: 2,
      soHoaDon: '',
      ngayHoaDon: '',
      linkHoaDon: '',
      ghiChu: '',
    }),
    [infoPhieuNhap?.infodt?.idncc],
  )
  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: initialValues,
    // validationSchema: KhoNhapSchema,
    onSubmit: (value) => handleSubmit(value, true),
  })
  const resetFormPhieuNhapVpp = () => {
    setIsModalNhapKho(false)
    setIsLoading(false)
    formik.resetForm()
    setSelectedFile([])
    getDataAsync(
      infoPhieuNhap?.formFilter?.idct,
      infoPhieuNhap?.formFilter?.idcn,
      infoPhieuNhap?.formFilter?.tungay,
      infoPhieuNhap?.formFilter?.denngay,
      infoPhieuNhap?.formFilter?.keyword,
    )
  }
  const handleSubmit = (value, isPrint) => {
    if (!value?.soHoaDon || !value?.phuongThuc || !value?.hinhThuc) {
      formik.setFieldError('soHoaDon', !value?.soHoaDon ? 'error' : null)
      formik.setFieldError('phuongThuc', !value?.phuongThuc ? 'error' : null)
      formik.setFieldError('hinhThuc', !value?.hinhThuc ? 'error' : null)
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    for (const cn of listVPP) {
      if (!cn?.idkhocnvpp) {
        ToastCus.fire({
          icon: 'error',
          title: `Vui lòng kiểm tra lại!\nKhông tìm thấy: Kho văn phòng phẩm của ${cn?.tencn}`,
        })
        return
      }
      for (const vpp of cn?.list) {
        const tongLeNhap = cn?.list?.reduce(
          (tong, item) =>
            item?.IDTHUOC === vpp?.IDTHUOC
              ? (tong +=
                  item?.khoChiTiet?.soLuong * item?.QUYCACHDONGGOI + item?.khoChiTiet?.countLe) // item?.khoChiTiet?.countLe
              : tong,
          0,
        )
        if (tongLeNhap !== vpp?.slVPPmua) {
          ToastCus.fire({
            icon: 'error',
            title: `Vui lòng kiểm tra lại!\n(${vpp?.MATHUOC}) - SL nhập cần giống SL mua`,
          })
          return
        }
        if (!vpp?.khoChiTiet?.soLo) {
          ToastCus.fire({
            icon: 'error',
            title: `Vui lòng kiểm tra lại!\n(${vpp?.MATHUOC}) - Cần nhập số lô`,
          })
          return
        }
        if (!vpp?.khoChiTiet?.hanDung) {
          ToastCus.fire({
            icon: 'error',
            title: `Vui lòng kiểm tra lại!\n(${vpp?.MATHUOC}) - Cần nhập hạn dùng`,
          })
          return
        }
      }
      // if (
      //   cn?.list?.find(
      //     (item) =>
      //       item?.khoChiTiet?.soLuong * item?.khoChiTiet?.soLuongDongGoi +
      //         item?.khoChiTiet?.countLe !==
      //       item?.slVPPmua,
      //   )
      // ) {
      //   ToastCus.fire({
      //     icon: 'error',
      //     title: 'Số lượng nhập không hợp lệ!',
      //   })
      //   return
      // }
    }
    // return
    dispatch(
      postListPhieuNhapVPPAction(
        {
          ...value,
          ...infoPhieuNhap,
          listHinh: selectedFile,
        },
        isPrint,
        resetFormPhieuNhapVpp,
        setIsLoading,
      ),
    )
    // setIsModalNhapKho(false)
  }
  const showModal = () => {
    setIsModalNhapKho(true)
  }
  const handleCancel = () => {
    if (isLoading) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng đợi xử lý,\n không đóng cửa sổ thao tác!',
      })
      return
    }
    setIsModalNhapKho(false)
  }
  //   useEffect(() => {
  //     if (infoPhieuNhap?.infodt?.mathang?.length) {
  //       infoPhieuNhap?.infodt?.mathang?.map((item) =>
  //         setListHang((prev) => {
  //           if (prev?.length) {
  //           } else {
  //             return [
  //               {
  //                 idcn: item?.idchinhanhdat,
  //                 tencn: item?.tenchinhanh,
  //                 list: [],
  //               },
  //             ]
  //           }
  //         }),
  //       )
  //       console.log(infoPhieuNhap)
  //     }
  //   }, [infoPhieuNhap])

  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) => text,
    // searchedColumn === dataIndex ? (
    //   <Highlighter
    //     highlightStyle={{
    //       backgroundColor: '#ffc069',
    //       padding: 0,
    //     }}
    //     searchWords={[searchText]}
    //     autoEscape
    //     textToHighlight={text ? text.toString() : ''}
    //   />
    // ) : (
    //   text
    // ),
  })
  const columnsHangHoa = [
    {
      title: 'Mã hàng',
      dataIndex: 'mathuoc',
      key: 'mathuoc',
      width: 150,
      // ...getColumnSearchProps('mathuoc', true),
      ...getColumnSearchProps('mathuoc'),
    },
    {
      title: 'Tên hàng hóa',
      dataIndex: 'tenbietduoc',
      key: 'tenbietduoc',
      // ...getColumnSearchProps('tenbietduoc', true),
      ...getColumnSearchProps('tenbietduoc'),
    },
    {
      title: 'SL mua',
      dataIndex: 'soluongduyet',
      key: 'soluongduyet',
      width: 75,
      align: 'center',
      sorter: (a, b) => a.soluongduyet - b.soluongduyet,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Chi nhánh đặt',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      width: 250,
      // ...getColumnSearchProps('tenchinhanh', true),
      ...getColumnSearchProps('tenchinhanh'),
    },
    {
      title: 'Người mua',
      width: 250,
      dataIndex: 'tennguoidat',
      // ...getColumnSearchProps('tennguoidat', true),
      ...getColumnSearchProps('tennguoidat'),
    },
    {
      title: 'Ngày mua',
      width: 120,
      align: 'center',
      dataIndex: 'ngaydat',
      render: (text) => moment(text).format('DD/MM/YYYY'),
      sorter: (a, b) => moment(a.ngaydat).unix() - moment(b.ngaydat).unix(),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
  ]
  return (
    <Modal
      // loading={isLoading}
      width={1360}
      style={{ top: 20 }}
      title={<p className="text-center">Lập phiếu nhập kho</p>}
      open={isModalNhapKho}
      okText={'Lưu & in'}
      onOk={formik.handleSubmit}
      cancelText="Huỷ bỏ"
      onCancel={handleCancel}
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <Button
            type="primary"
            onClick={() => handleSubmit(formik.values, false)}
            loading={isLoading}
          >
            Lưu
          </Button>
          <Button
            type="primary"
            onClick={() => handleSubmit(formik.values, true)}
            loading={isLoading}
          >
            Lưu & in
          </Button>
          {/* <OkBtn loading={isLoading} /> */}
        </>
      )}
    >
      {isLoading && (
        <div className="z-10 absolute h-full w-full top-0 left-0 bg-white bg-opacity-50 flex justify-center items-start rounded-lg">
          <div className="grid grid-flow-row items-center mt-[15rem]">
            <Spin size="large" />
            <div className="font-semibold">Vui lòng đợi xử lý, không đóng cửa sổ thao tác!</div>
          </div>
        </div>
      )}
      <div className="relative">
        <InfoPhieuNhap infoPhieuNhap={infoPhieuNhap} formik={formik} />
        <Tabs
          items={[
            ...useMemo(
              () => [
                {
                  key: 1,
                  value: 1,
                  label: (
                    <div className="flex items-center gap-2">
                      <GoldOutlined style={{ fontSize: 18 }} />
                      &nbsp;Hàng hóa
                    </div>
                  ),
                  children: (
                    <>
                      <TabHangHoa infoPhieuNhap={infoPhieuNhap} />
                    </>
                  ),
                },
                {
                  key: 2,
                  value: 2,
                  label: (
                    <div className="flex items-center gap-2">
                      <AppstoreOutlined style={{ fontSize: 18 }} />
                      &nbsp;VPP đã mua
                    </div>
                  ),
                  children: (
                    <>
                      <ConfigProvider theme={{ token: { padding: 5 } }}>
                        <Table
                          bordered
                          pagination={false}
                          columns={columnsHangHoa}
                          dataSource={infoPhieuNhap?.infodt?.mathang
                            ?.sort((a, b) =>
                              (a?.tenbietduoc ?? '')
                                .toLowerCase()
                                .localeCompare((b?.tenbietduoc ?? '').toLowerCase()),
                            )
                            ?.map((item, index) => ({
                              ...item,
                              key: index,
                            }))}
                        />
                      </ConfigProvider>
                    </>
                  ),
                },
              ],
              [infoPhieuNhap],
            ),
            {
              key: 3,
              value: 3,
              label: (
                <div className="flex items-center gap-2">
                  <LinkOutlined style={{ fontSize: 18 }} />
                  &nbsp;Đính kèm
                  {selectedFile?.length > 0 && (
                    <span className="font-semibold text-white bg-red-500 text-[10px] pl-[0.35rem] pr-[0.35rem] border border-red-500 rounded-full">
                      {selectedFile?.length}
                    </span>
                  )}
                </div>
              ),
              children: (
                <>
                  <TabDinhKem
                    infoPhieuNhap={infoPhieuNhap}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    formik={formik}
                  />
                </>
              ),
            },
          ]}
        />
      </div>
    </Modal>
  )
}

export default ModalNhapKho

import axios from 'axios'
import { https } from '../apiService'

export const authService = {
  login: (form) => https.post('Auth/login', form),
  checkIn: (form) => https.post('DangNhap/MoCa', form),
  getInfoByToken: (token) => https.get('Auth/UserInfoByToken', { params: { token } }),
  checkout: (id) => https.put(`DangNhap/DongCa?idDangNhap=${id}`),
  logOut:(id) =>https.post('Auth/logout',{idDangNhap:id}),
  changePassword: (form) => https.post(`NguoiDung/ChangePassword`, form),
  getUsers: (keyword, pageIndex) =>
    https.get('NguoiDung/SearchNguoiDung', {
      params: {
        keyword,
        pageIndex,
      },
    }),
  getInfoUser: (id) => https.get(`NguoiDung/GetNGuoiDungByID?idNguoiDung=${id}`),
  resetPassUser: (username, nguoiSua) =>
    https.post(`NguoiDung/ResetPassword?username=${username}&nguoiSua=${nguoiSua}`),
  getIPMac:() => axios.get(`http://localhost:3153/system-info`),
  postIPMacComputer: (form) => https.post('IPMAC/AddIPMAC',form),
  getIpMacAddress: () => https.get('IPMAC/GetIPMAC'),
  putActiveIPMac: (form) => https.put('IPMAC/UpdateSuDungIPMAC',form)
}

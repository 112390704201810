import React, { useEffect, useState, useRef } from 'react'
import {
  Input,
  Modal,
  Upload,
  Image,
  notification,
  Select,
  Popover,
  AutoComplete,
  Divider,
  Dropdown,
  Timeline,
  Typography,
  Tag,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  SwapOutlined,
} from '@ant-design/icons'
import { useFormik } from 'formik'
import _ from 'lodash'
import { priority } from './Priority'
import {
  deleteBaoHong,
  deleteFileBaoHong,
  getAllHangMuc,
  getKTThucHienBaoHong,
  getNhanVienSuCoByVung,
  historyStaff,
  updateBaoHong,
  upLoadFileBaoHong,
} from '../../../store/actions/suCoAction'
import moment from 'moment'
import { suCoService } from '../../../services/suCoService/suCoServvice'
import 'moment/locale/vi'
import DeletStaff from '../SuCo/DeleteStaff/DeletStaff'
import CardStaff from './CardStaff/CardStaff'
moment.locale('vi')
const { TextArea } = Input
const { confirm } = Modal
const { Text, Link } = Typography
const DetailBaoHong = ({ open, setOpen, fetchData }) => {
  const { thucHien, DetailBaoHong, AllDanhMuc, KTBaoHong, imgBaoHong, InfoXuLy, HistoryStaff } =
    useSelector((state) => state.suCoReducer)
  const fileInputRef = useRef(null)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [infoNhan, setInfoNhan] = useState({ tenNhan: null, tenDonViNhan: null })
  const [openHistory, setOpenHistory] = useState(false)
  const [openDel, setOpenDel] = useState(false)
  const [staff, setStaff] = useState(null)
  const [api, contextHolder] = notification.useNotification()
  const dispatch = useDispatch()
  const handleCancel = () => {
    setOpen(false)
    setOpenHistory(false)
  }
  const handleSubmit = (values) => {
    if (DetailBaoHong?.idtrangthaiphieu === 3) {
      const today = moment().format('YYYY-MM-DDTHH:mm:ss')
      values.trangthaiphieu = 4
      // values.ngayhoanthanh = today
    }
    handleCancel()
    dispatch(updateBaoHong(values))
    handleCancel()
  }
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    })
  }
  const showDeleteConfirm = () => {
    confirm({
      title: 'Xoá sự cố',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc muốn xoá?',
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        dispatch(deleteBaoHong(DetailBaoHong?.idbaohong, handleCancel, fetchData))
      },
    })
  }
  const onChangeSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  const handleLoaiSuCo = async (value) => {
    formik.setFieldValue('iddanhmucnhan', value)
    try {
      const { data } = await suCoService.getinfoNhanByHangMuc(value)
      setInfoNhan({ tenNhan: data?.tennhan, tenDonViNhan: data?.nhom })
      formik.setFieldValue('donvinhan', data.idnhom)
      formik.setFieldValue('idnhan', data.idnhan)
    } catch (error) {
      console.log(error)
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbaohong: DetailBaoHong?.idbaohong,
      tenbaohong: DetailBaoHong?.tenbaohong,
      noidungbaohong: DetailBaoHong?.noidungbaohong,
      idtrangthaiphieu: DetailBaoHong?.idtrangthaiphieu,
      mucdosuco: DetailBaoHong?.idmucdosuco,
      chinhanhbaohong: DetailBaoHong?.idchinhanhbaohong,
      iddanhmucnhan: DetailBaoHong?.iddanhmucnhan,
      trangthaiphieu: DetailBaoHong?.idtrangthaiphieu,
      donvinhan: DetailBaoHong?.iddonvinhan,
      idnhan: DetailBaoHong?.idnhan,
      nguoibaohong: DetailBaoHong?.idnguoibaohong,
      nguoinhan: DetailBaoHong?.idnguoinhan,
      ngaynhan: DetailBaoHong?.ngaynhan,
      ghichu: DetailBaoHong?.ghichu,
    },
    onSubmit: (value) => handleSubmit(value),
  })
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  )
  useEffect(() => {
    dispatch(getNhanVienSuCoByVung())
    dispatch(getAllHangMuc())
  }, [])
  const handleFileChange = (event) => {
    const file = event.target.files[0] // Get the first file selected
    const allowedFileTypes = ['image/jpeg', 'image/png']
    if (!allowedFileTypes.includes(file.type)) {
      openNotificationWithIcon('error', 'Thêm hình ảnh', 'Chỉ cho phép file hình ảnh')
      fileInputRef.current.value = '' // clear the input
      return
    } else {
      const formData = new FormData()
      formData.append('IDBAOHONG', DetailBaoHong?.idbaohong)
      formData.append('IDCN', formik.values.chinhanhbaohong)
      formData.append('HINHANH', file)
      dispatch(upLoadFileBaoHong(formData, DetailBaoHong?.idbaohong))
    }
  }
  const handleHistoryStaff = () => {
    setOpenHistory(!openHistory)
    if (!openHistory) {
      dispatch(historyStaff(DetailBaoHong?.idbaohong))
    }
  }
  const handleButtonClick = () => {
    fileInputRef.current.click()
  }
  const addNhanVien = async (option, ob) => {
    for (let i of KTBaoHong) {
      // kiểm tra nhân viên đã add hay chưa
      if (i.idnv === option.id) {
        openNotificationWithIcon('error', 'Thêm người thực hiện', 'Người này đã được thêm')
        return
      }
    }
    const payload = {
      idbaohong: DetailBaoHong?.idbaohong,
      idnv: option?.id,
      nguoitao: infoUser?.idnv,
    }
    try {
      await suCoService.postNhanVienBaoHong(payload)
      dispatch(getKTThucHienBaoHong(DetailBaoHong?.idbaohong))
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    setInfoNhan({ tenNhan: DetailBaoHong?.tennhan, tenDonViNhan: DetailBaoHong?.donvinhan })
  }, [DetailBaoHong])
  return (
    <>
      {contextHolder}
      <Modal
        style={{
          top: 25
        }}
        width="55%"
        onOk={formik.handleSubmit}
        okText={DetailBaoHong?.idtrangthaiphieu === 3 ? 'Hoàn thành' : 'Lưu'}
        cancelText="Huỷ bỏ"
        open={open}
        onCancel={handleCancel}
        okButtonProps={{
          disabled:
            DetailBaoHong?.idtrangthaiphieu === 5 ||
            DetailBaoHong?.idtrangthaiphieu === 4 ||
            DetailBaoHong?.idtrangthaiphieu === 2,
        }}
        title={
          <p className="font-semibold text-xl w-full text-center text-blue-500">
            CHI TIẾT CÔNG VIỆC
          </p>
        }
      >
        <div className="flex gap-5">
          <div className="flex flex-col gap-2 w-2/3">
            <div className="flex flex-col gap-1">
              <label className="font-medium text-gray-400">
                Tên sự cố:<span className="text-red-500">(*)</span>
              </label>
              <Input
                name="tenbaohong"
                onChange={formik.handleChange}
                status={formik.errors.tenbaohong && formik.touched.tenbaohong ? 'error' : null}
                value={formik.values.tenbaohong}
                readOnly={DetailBaoHong?.idtrangthaiphieu === 4}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-gray-400 font-medium">
                {' '}
                Loại sự cố:<span className="text-red-500">(*)</span>
              </label>
              <Select
                options={AllDanhMuc?.map((i) => ({
                  label: i.tendanhmuc,
                  value: i.idnhandm,
                }))}
                name="iddanhmucnhan"
                onChange={handleLoaiSuCo}
                value={formik.values.iddanhmucnhan}
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-gray-400 font-medium">Nội dung báo hỏng:</label>
              <TextArea
                showCount
                // maxLength={100}
                onChange={formik.handleChange}
                value={formik.values.noidungbaohong}
                name="noidungbaohong"
                placeholder="Nhập nội dung chi tiết"
                style={{
                  height: 165,
                  resize: 'none',
                }}
                readOnly={DetailBaoHong?.idtrangthaiphieu === 4}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-gray-400 font-medium">Hình ảnh sự cố</label>
              <div className="flex gap-1 ">
                {imgBaoHong?.split(';').map((url, index) => (
                  <li
                    key={index}
                    className="h-[100px] w-[100px] relative rounded border overflow-hidden"
                  >
                    <Image width={98} height={86} src={url} alt={`Image ${index + 1}`} />
                    {DetailBaoHong?.idtrangthaiphieu === 4 ? null : (
                      <div
                        className="h-6 flex justify-center text-red-500 items-center absolute bottom-0 w-full border-t bg-red-50 cursor-pointer duration-200 hover:text-white hover:bg-red-500"
                        disabled
                        onClick={() => dispatch(deleteFileBaoHong(DetailBaoHong?.idbaohong, url))}
                      >
                        <DeleteOutlined style={{ fontSize: 18 }} />
                      </div>
                    )}
                  </li>
                ))}
                {DetailBaoHong?.idtrangthaiphieu === 1 && (
                  <div
                    onClick={handleButtonClick}
                    className="border-dotted border items-center flex justify-center rounded-md w-24 h-24 cursor-pointer hover:border-blue-500"
                  >
                    {imgBaoHong > 5 ? null : uploadButton}
                  </div>
                )}

                <input
                  ref={fileInputRef}
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 w-1/3">
            <div className="flex gap-2">
              <div className="flex flex-col gap-1 w-1/2">
                <label className="text-gray-400 font-medium">Mức độ </label>
                <Select
                  value={formik.values.mucdosuco}
                  onChange={onChangeSelected('mucdosuco')}
                  className="w-full"
                  options={priority}
                />
              </div>
              <div className="flex flex-col gap-1 w-1/2">
                <label className="text-gray-400 flex justify-between font-medium">
                  Trạng thái{' '}
                  {DetailBaoHong?.idtrangthaiphieu === 1 && (
                    <span
                      onClick={showDeleteConfirm}
                      className="flex justify-end text-red-500 hover:scale-125 duration-200 cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                      </svg>
                    </span>
                  )}
                </label>
                <Input
                  name="trangthaiphieu"
                  readOnly
                  variant="filled"
                  value={DetailBaoHong?.trangthaiphieu}
                />
              </div>
            </div>

            <div className="flex gap-2">
              <div className="flex flex-col gap-1 ">
                <label className="text-gray-400 font-medium"> Đơn vị nhận:</label>
                <Input
                  variant="filled"
                  readOnly
                  name="donvinhan"
                  onChange={formik.handleChange}
                  value={infoNhan.tenDonViNhan}
                />
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-gray-400 font-medium">Loại nhãn:</label>
                <Input
                  variant="filled"
                  readOnly
                  name="thoigianxuly"
                  onChange={formik.handleChange}
                  value={infoNhan.tenNhan}
                />
              </div>
            </div>
            <div className="flex flex-col gap-1 ">
              <label className="text-gray-400 font-medium"> Chi nhánh:</label>
              <Input
                variant="filled"
                readOnly
                name="chinhanhbaohong"
                onChange={formik.handleChange}
                value={DetailBaoHong?.chinhanhbaohong}
              />
            </div>
            <div className="flex flex-col gap-1 ">
              <label className="text-gray-400 font-medium"> Người tạo:</label>
              <Input
                variant="filled"
                readOnly
                name="nguoibaohong"
                onChange={formik.handleChange}
                value={DetailBaoHong?.nguoibaohong}
              />
            </div>
            <div className="flex flex-col gap-1 ">
              <label className="text-gray-400 font-medium"> Ngày tạo:</label>
              <Input
                variant="filled"
                readOnly
                name="ngaybaohong"
                onChange={formik.handleChange}
                value={moment(DetailBaoHong?.ngaybaohong).format('DD/MM/YYYY HH:mm:ss')}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-gray-400 font-medium flex justify-between">
                {openHistory ? ' Lịch sử thay đổi' : 'Người thực hiện'}
                <span
                  onClick={handleHistoryStaff}
                  className="text-blue-500 font-normal hover:border-b cursor-pointer border-blue-400"
                >
                  {!openHistory ? ' Lịch sử thay đổi' : 'Người thực hiện'}
                </span>
              </label>
              <div
                className={`flex gap-1  flex-wrap overflow-auto ${openHistory ? 'h-32' : 'h-24'} `}
              >
                {openHistory ? (
                  <Timeline
                    className="mt-2"
                    items={HistoryStaff?.map((history) => ({
                      children: (
                        <>
                          <p>{moment(history.ngaynhan).format('DD/MM/YYYY HH:mm:ss')}</p>
                          <p>
                            {' '}
                            Hành động:{' '}
                            <Tag
                              color={
                                history.idtrangthai === 1
                                  ? 'green'
                                  : history.idtrangthai === 2
                                    ? 'blue'
                                    : 'red'
                              }
                            >
                              {history.trangthai}
                            </Tag>
                          </p>
                          <p>
                            {history.idtrangthai === 1 ? (
                              history.tennguoinhan
                            ) : history.idtrangthai === 2 ? (
                              <span>
                                <Text delete>{history.tennguoinhancu}</Text> ---{' '}
                                <Text mark>{history.tennguoinhan}</Text>
                              </span>
                            ) : (
                              <Text delete>{history.tennguoinhan}</Text>
                            )}
                          </p>
                          <p>Thực hiện: {history.tennguoitao}</p>
                          <p>Lý do: {history.lydo}</p>
                        </>
                      ),
                    }))}
                  />
                ) : (
                  <>
                    {KTBaoHong.map((item) => (
                      <CardStaff item={item} setOpenDel={setOpenDel} setStaff={setStaff} />
                    ))}
                  </>
                )}
              </div>
              {!openHistory && (
                <Popover
                  placement="bottomLeft"
                  trigger="click"
                  content={() => {
                    return (
                      <AutoComplete
                        placeholder="Enter name"
                        style={{ width: 200 }}
                        filterOption={(inputValue, option) =>
                          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        options={thucHien?.map((item) => ({
                          label: item.tennhanvien,
                          value: item.tennhanvien,
                          id: item.idnv,
                        }))}
                        onSelect={(value, option) => {
                          addNhanVien(option)
                        }}
                      />
                    )
                  }}
                  title="Thêm người"
                >
                  {DetailBaoHong?.idtrangthaiphieu === 1 && (
                    <button className="w-6 h-6 rounded bg-gray-300 dark:text-black">+</button>
                  )}
                </Popover>
              )}
            </div>
          </div>
        </div>
        <Divider style={{ margin: 4 }} orientation="left" dashed plain>
          <p className="text-blue-500 font-semibold">Thông tin xử lý</p>
        </Divider>
        <div className="flex gap-2">
          <div className="flex flex-col gap-1 w-2/3">
            <label className="text-gray-400 font-medium">
              {DetailBaoHong?.idtrangthaiphieu === 5 ? 'Không hoàn thành' : 'Nội dung xử lý:'}
            </label>
            {DetailBaoHong?.idtrangthaiphieu === 5 ? (
              <TextArea
                showCount
                // maxLength={100}
                value={DetailBaoHong.lydochuahoanthanh}
                style={{
                  height: 100,
                  resize: 'none',
                }}
              />
            ) : (
              <TextArea
                readOnly
                variant="filled"
                showCount
                onChange={formik.handleChange}
                value={InfoXuLy?.noidung}
                name="NOIDUNGBAOHONG"
                style={{
                  height: 100,
                  resize: 'none',
                }}
              />
            )}

            <label className="text-gray-400 font-medium">Ghi chú</label>
            <Input
              readOnly={DetailBaoHong?.idtrangthaiphieu !== 3}
              onChange={formik.handleChange}
              value={formik.values.ghichu}
              name="ghichu"
            />
          </div>
          <div className="w-1/3">
            <label className="text-gray-400 font-medium">Hình ảnh xử lý</label>
            <ul className="flex flex-wrap gap-2">
              {InfoXuLy?.hinhanh?.split(';').map((url, index) => (
                <li
                  key={index}
                  className="h-[100px] w-[100px] relative rounded border overflow-hidden"
                >
                  <Image width={100} height={100} src={url} alt={`Image ${index + 1}`} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
      <DeletStaff openDel={openDel} setOpenDel={setOpenDel} staff={staff} />
    </>
  )
}

export default DetailBaoHong

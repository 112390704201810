import React, { useEffect, useState } from 'react'
import LayoutApp from '../../../../HOCs/LayoutApp'
import * as typeAction from '../../../../store/constants/constants'
import { ConfigProvider, Table, Select, Button, notification, Progress } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { VerticalAlignBottomOutlined } from '@ant-design/icons'
import * as XLSX from 'xlsx'
import moment from 'moment'
import { formatNumber } from 'devextreme/localization'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { checkImportHanghoa, importThuocVT } from '../../../../store/actions/thuocVTAction'
import fileMau from '../../../../data/Form/mẫu import thu mua.xlsx'
import 'moment/locale/vi'
moment.locale('vi')
const ImportThuMua = () => {
  const dispatch = useDispatch()
  const twoColors = {
    '0%': '#108ee9',
    '100%': '#87d068',
  }
  const id_company = Number(localStorage.getItem('id_company'))
  const [company, setCompany] = useState(id_company)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { fileImport, progress } = useSelector((state) => state.thuocVTReducer)
  const [api, contextHolder] = notification.useNotification()
  const [isLoading, setIsLoading] = useState(false)

  const handleCompany = (value) => {
    dispatch({
      type: typeAction.DISPATCH_FILE_IMPORT_THU_MUA, // update lại store
      payload: [],
    })
    setCompany(value)
  }
  const openNotificationWithIcon = (type, desc) => {
    api[type]({
      message: 'Lỗi thêm file',
      description: desc,
      placement: 'topRight',
    })
  }
  const handleImport = () => {
    dispatch(importThuocVT(fileImport, setIsLoading))
  }
  const handleDownloadFile = () => {
    // Tạo một đường dẫn đến tập tin bạn muốn tải xuống
    // Tạo một thẻ a ẩn
    const link = document.createElement('a')
    link.href = fileMau
    link.target = '_blank'
    link.download = 'Mẫu import thuốc vật tư.xlsx'
    // Thêm thẻ a vào DOM và kích hoạt sự kiện nhấp vào nó
    document.body.appendChild(link)
    link.click()

    // Xóa thẻ a sau khi tải xuống hoàn tất
    document.body.removeChild(link)
  }
  const convertStringToNumber = (str) => {
    // Kiểm tra nếu giá trị là null hoặc undefined thì trả về 0
    if (!str) return 0
    // Đảm bảo giá trị là chuỗi trước khi thay thế và chuyển đổi thành số
    return parseInt(str.toString().replace(/,/g, ''), 10)
  }
  const headerMapping = {
    'Hệ số': 'quyCachDongGoi',
    'Mã hàng': 'maThuoc',
    'Tên biệt dược': 'tenBietDuoc',
    'Tên hoạt chất': 'tenHoatChat',
    'Đơn vị lẻ': 'dvt',
    'Đơn vị chẵn': 'donViChan',
    'Quy cách': 'quyCach',
    'Giá nhập': 'giaMua',
    'Giá bán': 'giaBan',
    'ID nhóm': 'idNhom',
    'Chuyên khoa': 'chuyenKhoa',
    'Phân loại': 'phanLoai',
    'Nhóm hàng': 'nhomHang',
    'Hàm lượng': 'hamLuong',
    'Nồng độ': 'nongDo',
    'Đơn vị dùng': 'donViDung',
    'Cách dùng': 'cachDung',
    'Đường dùng': 'duongDung',
    'Tên đối tác': 'tenDoiTac',
    'Mã số đăng kí': 'maSoDangKy',
    'Xuất xứ': 'nuocSanXuat',
    'Nhà sản xuất': 'nhaSanXuat',
    '% VAT nhập': 'ptVatNhap',
    '% VAT bán lẻ': 'ptVatBanLe',
    '% VAT bán toa': 'ptVatToa',
    'Giá GPP': 'giagpp',
    'Giá trước thuế': 'giatruocthue',
  }
  const handleFileChange = (e) => {
    const today = moment()
    const file = e.target.files[0]
    const fileName = file.name
    const extension = fileName.split('.').pop().toLowerCase()
    if (extension !== 'xlsx' && extension !== 'xls') {
      // kiểm tra xem có phải là file excel hay không
      openNotificationWithIcon('error', 'Chỉ chấp nhận tệp Excel (xlsx, xls)')
      return
    }
    const render = new FileReader()
    render.readAsArrayBuffer(file)
    render.onload = (e) => {
      const excelData = e.target.result
      const workbook = XLSX.read(excelData, {
        type: 'buffer',
        cellDates: true,
      })
      const worksheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[worksheetName]
      const data = XLSX.utils.sheet_to_json(worksheet, {
        raw: false,
        dateNF: null,
      })
      const formattedData = formatHeadersAndData(data)
      for (let i of formattedData) {
        if (!i.maThuoc) {
          openNotificationWithIcon('error', 'Mã hàng hoá không được bỏ trống')
          return
        } else if (!i.idNhom) {
          openNotificationWithIcon('error', 'Nhóm hàng không được để trống')
          return
        } else if (!i.quyCachDongGoi) {
          openNotificationWithIcon('error', 'Hệ số không được bỏ trống')
          return
        } else if (!i.dvt) {
          openNotificationWithIcon('error', 'Đơn vị lẻ không được bỏ trống')
          return
        } else if (!i.donViChan) {
          openNotificationWithIcon('error', 'Đơn vị chẵn không được bỏ trống')
          return
        }
      }
      //   Process the data to format the dates correctly
      const newData = formattedData.map((row) => {
        // Chuyển đổi quyCachDongGoi từ chuỗi sang số
        row.quyCachDongGoi = convertStringToNumber(row.quyCachDongGoi)
        row.giaMua = convertStringToNumber(row.giaMua)
        row.giaBan = convertStringToNumber(row.giaBan)
        row.giagpp = convertStringToNumber(row.giagpp)
        row.giatruocthue = convertStringToNumber(row.giatruocthue)
        row.ptVatToa = convertStringToNumber(row.ptVatToa)
        row.ptVatBanLe = convertStringToNumber(row.ptVatBanLe)
        row.ptVatNhap = convertStringToNumber(row.ptVatNhap)
        row.idNhom = convertStringToNumber(row.idNhom)
        row.idCt = company
        row.nguoiTao = infoUser?.tenNV
        row.ngayTao = today.format('YYYY-MM-DDTHH:mm:ss')
        return row
      })

      dispatch(checkImportHanghoa(newData, company, openNotificationWithIcon))
    }
  }
  // Format headers and data based on headerMapping
  const formatHeadersAndData = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
      throw new Error('Data should be a non-empty array')
    }

    const headers = Object.keys(data[0])

    return data.map((row) => {
      const formattedRow = {}
      headers.forEach((header) => {
        const newHeader = headerMapping[header]
        if (newHeader) {
          formattedRow[newHeader] = row[header]
        } else {
          formattedRow[header] = row[header] // retain original header if not in mapping
        }
      })
      return formattedRow
    })
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
  }, [])
  return (
    <>
      {contextHolder}
      <div className="h-full bg-[#EFEFEF] p-2">
        <div>
          <h2 className="font-semibold text-lg">IMPORT THUỐC VẬT TƯ</h2>
        </div>
        <div className="bg-white p-2 h-[92%] border rounded-xl ">
          <div className="flex  justify-between">
            <div className="flex 2xl:gap-5 gap-1 items-center">
              <div className="w-60 gap-1 items-center ">
                <Select
                  showSearch
                  className="w-full"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={handleCompany}
                  value={company}
                  options={listCompany?.map((items) => ({
                    label: items.tenct,
                    value: items.idct,
                  }))}
                />
              </div>
              <div className="flex gap-5 items-center">
                <Button className="my-2 p-0 bg-blue-500 text-white">
                  <label className="cursor-pointer px-4 py-1" for="file-upload">
                    Chọn file danh sách
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    value={''}
                    onChange={handleFileChange}
                  />
                </Button>
                <button
                  onClick={handleDownloadFile}
                  className="text-green-700 text-base cursor-pointer"
                  size="small"
                >
                  <VerticalAlignBottomOutlined />
                  File mẫu
                </button>
              </div>
            </div>
            <div className="flex items-center gap-5 mb-2">
              <h2 className="font-semibold text-lg">Tổng: {fileImport.length}</h2>
              <Button
                onClick={handleImport}
                disabled={fileImport.length === 0}
                type="primary"
                ghost
              >
                Nạp danh sách
              </Button>
              <Button
                type="primary"
                ghost
                danger
                onClick={() => {
                  dispatch({
                    type: typeAction.DISPATCH_FILE_IMPORT_THU_MUA,
                    payload: [],
                  })
                }}
              >
                Xóa danh sách
              </Button>
            </div>
          </div>
          {/* <Progress percent={progress} strokeColor={twoColors} /> */}
          <div>
            <ConfigProvider
              theme={{
                token: { padding: 5, borderRadius: 0 },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                loading={isLoading}
                pagination={{ pageSize: 20 }}
                bordered
                scroll={{ x: 4000 }}
                dataSource={fileImport}
                columns={[
                  {
                    title: 'STT',
                    dataIndex: 'stt',
                    key: 'stt',
                    render: (text, record, index) => ++index,
                    width: 40,
                    align: 'center',
                    fixed: 'left',
                  },
                  {
                    title: 'Mã',
                    dataIndex: 'maThuoc',
                    key: 'maThuoc',
                    fixed: 'left',
                  },
                  {
                    title: 'Tên biệt dược',
                    dataIndex: 'tenBietDuoc',
                    key: 'tenBietDuoc',
                    fixed: 'left',
                    width: 350,
                  },
                  {
                    title: 'Tên hóa chất',
                    dataIndex: 'tenHoatChat',
                    key: 'tenHoatChat',
                    width: 350,
                  },
                  {
                    title: 'Phân loại',
                    dataIndex: 'phanLoai',
                    key: 'phanLoai',
                  },
                  {
                    title: 'Nhóm hàng',
                    dataIndex: 'nhomHang',
                    key: 'nhomHang',
                  },
                  {
                    title: 'ID nhóm',
                    dataIndex: 'idNhom',
                    key: 'idNhom',
                  },
                  {
                    title: 'Đơn vị lẻ',
                    dataIndex: 'dvt',
                    key: 'dvt',
                  },

                  {
                    title: 'Đơn vị dùng',
                    dataIndex: 'donViDung',
                    key: 'donViDung',
                  },
                  {
                    title: 'Giá mua',
                    dataIndex: 'giaMua',
                    key: 'giaMua',
                    render: (text, record, index) => (text ? formatNumber(text) : 0),
                  },
                  {
                    title: 'Giá bán',
                    dataIndex: 'giaBan',
                    key: 'giaBan',
                    render: (text, record, index) => (text ? formatNumber(text) : 0),
                  },
                  {
                    title: 'Cách dùng',
                    dataIndex: 'cachDung',
                    key: 'cachDung',
                  },
                  {
                    title: 'Đường dùng',
                    dataIndex: 'duongDung',
                    key: 'duongDung',
                  },
                  {
                    title: 'Mã số đăng ký',
                    dataIndex: 'maSoDangKy',
                    key: 'maSoDangKy',
                  },
                  {
                    title: 'Chuyên Khoa',
                    dataIndex: 'chuyenKhoa',
                    key: 'chuyenKhoa',
                  },
                  {
                    title: 'Xuất xứ',
                    dataIndex: 'nuocSanXuat',
                    key: 'nuocSanXuat',
                  },
                  {
                    title: 'Nhà sản xuất',
                    dataIndex: 'nhaSanXuat',
                    key: 'nhaSanXuat',
                  },
                  {
                    title: 'Tên đối tác',
                    dataIndex: 'tenDoiTac',
                    key: 'tenDoiTac',
                    width: 350,
                  },
                  {
                    title: 'Đơn vị Chẵn',
                    dataIndex: 'donViChan',
                    key: 'donViChan',
                  },
                  {
                    title: 'Hệ số',
                    dataIndex: 'quyCachDongGoi',
                    key: 'quyCachDongGoi',
                    width: 100,
                  },
                  {
                    title: 'Quy cách',
                    dataIndex: 'quyCach',
                    key: 'quyCach',
                  },
                  {
                    title: '% VAT Nhập',
                    dataIndex: 'ptVatNhap',
                    key: 'ptVatNhap',
                    width: 100,
                  },
                  {
                    title: '% VAT bán lẻ',
                    dataIndex: 'ptVatBanLe',
                    key: 'ptVatBanLe',
                    width: 100,
                  },
                  {
                    title: '% VAT Toa',
                    dataIndex: 'ptVatToa',
                    key: 'ptVatToa',
                    width: 100,
                  },
                  {
                    title: 'Giá GPP',
                    dataIndex: 'giagpp',
                    key: 'giagpp',
                    width: 100,
                  },
                  {
                    title: 'Giá trước thuế',
                    dataIndex: 'giatruocthue',
                    key: 'giatruocthue',
                    width: 120,
                  },
                ]}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </>
  )
}

export default ImportThuMua

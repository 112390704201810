import { call, put, select, takeLatest } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import * as typeAction from '../constants/constants'
import { dienTimService } from '../../services/DienTim/dienTimService'
import { phanQuyenService } from '../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../actions/phanQuyenAction'
import ToastCus from '../../utils/Toast'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
// Sagas
export function* dienTimSaga() {
  //------------------ GET INFO BENH NHAN --------------//a
  yield takeLatest(typeAction.GET_INFO_BN_DIEN_TIM, function* getInfo({ type, idbnttc, idbndt }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => dienTimService.getInfoBnPending(idbnttc))
      const resultBnDienTim = yield call(() => dienTimService.getInfoBnDienTim(idbndt))
      yield put({
        type: typeAction.DISPATCH_INFO_BN_DIEN_TIM,
        payload: { ...result.data, infoDienTim: resultBnDienTim.data },
      })
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    } catch (err) {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
      console.log(err)
    }
  })
  //------------------ GET INFO BENH NHAN CHO --------------//a
  yield takeLatest(typeAction.GET_INFO_BN_PENDING_DIEN_TIM, function* getInfo({ type, idbnttc }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => dienTimService.getInfoBnPending(idbnttc))
      yield put({
        type: typeAction.DISPATCH_INFO_BN_DIEN_TIM,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  //----------------- SAVE BENH NHAN DIEN TIM ------------//
  yield takeLatest(
    typeAction.POST_BENH_NHAN_DIEN_TIM,
    function* postBnDienTim({ type, form, reload }) {
      try {
        yield put({ type: typeAction.OPEN_LOADING_PAGE })
        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT342'),
        )
        yield call(() => dienTimService.postBnDienTim(form))
        yield call(() => reload())
        yield put({
          type: typeAction.DISPATCH_INFO_BN_DIEN_TIM,
          payload: null,
        })
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (error) {
        console.error('Error : ', error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      } finally {
        yield put({ type: typeAction.CLOSE_LOADING_PAGE })
      }
    },
  )
}

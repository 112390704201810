import { produce } from 'immer'
import * as typeAction from '../constants/constants'

const initialState = {
  IDC10s: [],
  Chuongs: [],
}
const IDC10Reducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_GET_ALL_CHUONG:
        draft.Chuongs = payload
        break
      case typeAction.DISPATCH_ICD10_BY_IDCHUONG:
        draft.IDC10s = payload
        break
      default:
        return state
    }
  })
}

export default IDC10Reducer

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Breadcrumb, Button, ConfigProvider, DatePicker, Select, Table, Tooltip } from 'antd'
import { ContainerOutlined, HomeOutlined, SearchOutlined } from '@ant-design/icons'
import { branchService } from '../../../../services/branch/branchService'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import { xetNghiemService } from '../../../../services/xetNghiem/xetNghiemService'
import { formattedNumber } from '../../../../utils/formattedNumber'
import ToastCus from '../../../../utils/Toast'
import DrawerChiTietXN from './DrawerChiTiet'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')
const formatMomen = 'YYYY-MM-DD'
const dateFromat = 'DD/MM/YYYY'
const PhieuThu = () => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const today = moment()
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const [dateFrom, setDateForm] = useState(today.format(formatMomen))
  const [dateTo, setDateTo] = useState(today.format(formatMomen))
  const [PhieuThu, setPhieuThu] = useState([])
  const [branchs, setBranch] = useState([])
  const [open, setOpen] = useState({
    show: false,
    data: null,
  })
  const [valueBranch, setValueBranch] = useState(ID_BRANCH)
  //-------- lấy danh sách chi nhánh theo công ty ----------//
  const fetchBranch = async () => {
    try {
      const result = await branchService.getListBranch(ID_COMPANY)
      setBranch(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  // Hàm để disable các ngày mới
  const disableFutureDates = (current) => {
    return current && current > moment().endOf('day')
  }
  // --------- lây danh sách phiếu thu -------//
  const fetchPhieuThu = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT314',
      )
      const result = await xetNghiemService.getPhieus(valueBranch, dateFrom, dateTo)
      setPhieuThu(result.data)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const handleOpenChiTiet = async (info) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT315',
      )

      setOpen({ show: true, data: { ...info } })
    } catch (error) {
      setOpen({ show: false, data: null })
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  useEffect(() => {
    fetchBranch()
    fetchPhieuThu()
  }, [])
  return (
    <>
      <div className="p-5 h-full bg-[#EFEFEF]">
        <div className="flex justify-between">
          <h2 className="font-semibold text-xl">Phiếu thu</h2>
          <ConfigProvider theme={{ components: { Breadcrumb: { iconFontSize: 16 } } }}>
            <Breadcrumb
              style={{ fontSize: 16 }}
              items={[
                {
                  title: (
                    <>
                      <HomeOutlined />
                    </>
                  ),
                },
                {
                  title: (
                    <>
                      <Link to={'/canlamsang/xetnghiem'}>
                        <div className="flex ga">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9.75 3.104v5.714a2.25 2.25 0 0 1-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 0 1 4.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0 1 12 15a9.065 9.065 0 0 0-6.23-.693L5 14.5m14.8.8 1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0 1 12 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5"
                            />
                          </svg>

                          <span>Xét nghiệm</span>
                        </div>
                      </Link>
                    </>
                  ),
                },
                {
                  title: 'Phiếu thu',
                },
              ]}
            />
          </ConfigProvider>
        </div>
        <div className="bg-white p-2 rounded-xl mt-5">
          <div className="flex gap-2">
            <Select
              value={valueBranch}
              onChange={(value) => setValueBranch(value)}
              className="w-72"
              options={branchs?.map((items) => ({
                label: items.tenChiNhanh,
                value: items.idChiNhanh,
              }))}
            />
            <div className="flex items-center mb-2">
              <DatePicker
                disabledDate={disableFutureDates}
                allowClear={false}
                format={dateFromat}
                onChange={(date, dateString) =>
                  setDateForm(moment(dateString, dateFromat).format(formatMomen))
                }
                value={dayjs(dateFrom, formatMomen)}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
              </svg>

              <DatePicker
                disabledDate={disableFutureDates}
                allowClear={false}
                onChange={(date, dateString) =>
                  setDateTo(moment(dateString, dateFromat).format(formatMomen))
                }
                format={dateFromat}
                value={dayjs(dateTo, formatMomen)}
              />
            </div>
            <Button onClick={fetchPhieuThu} icon={<SearchOutlined />} type="primary" ghost>
              Tìm
            </Button>
          </div>
          <ConfigProvider
            theme={{
              token: {
                padding: 2,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <Table
              scroll={{
                x: 1500,
              }}
              pagination={{
                pageSize: 20,
              }}
              bordered
              dataSource={PhieuThu?.map((item) => ({
                ...item,
                action: (
                  <ul className="flex gap-2 justify-around">
                    <li className="text-lg">
                      <Tooltip title="Chi tiết" color="#108ee9">
                        <ContainerOutlined
                          onClick={() => handleOpenChiTiet(item)}
                          className="text-xl text-[#108ee9]  cursor-pointer"
                        />
                      </Tooltip>
                    </li>
                  </ul>
                ),
              }))}
              columns={[
                {
                  title: 'STT',
                  key: 'STT',
                  width: 40,
                  align: 'center',
                  render: (text, record, index) => ++index,
                },
                {
                  title: 'Ngày',
                  dataIndex: 'ngayketqua',
                  key: 'ngayketqua',
                  width: 100,
                  render: (text, record, index) =>
                    text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-',
                },
                {
                  title: 'Mã bệnh nhân',
                  dataIndex: 'mabenhnhan',
                  key: 'mabenhnhan',
                  width: 150,
                },
                {
                  title: 'Tên bệnh nhân',
                  dataIndex: 'tenbenhnhan',
                  key: 'tenbenhnhan',
                  width: 150,
                },
                {
                  title: 'Ngày sinh',
                  dataIndex: 'ngaysinh',
                  key: 'ngaysinh',
                  width: 90,
                  render: (text, record, index) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
                },
                {
                  title: 'Người thực hiên',
                  dataIndex: 'ktvxn',
                  key: 'ktvxn',
                  width: 150,
                },
                {
                  title: 'Tiền giảm',
                  dataIndex: 'tiengiamgia',
                  key: 'tiengiamgia',
                  width: 90,
                  align: 'right',
                  render: (text) => formattedNumber(text),
                },
                {
                  title: 'Thanh toán',
                  dataIndex: 'tienthanhtoan',
                  key: 'tienthanhtoan',
                  width: 90,
                  align: 'right',
                  render: (text) => formattedNumber(text),
                },
                {
                  title: '',
                  dataIndex: 'action',
                  key: 'action',
                  width: 40,
                  align: 'center',
                },
              ]}
            />
          </ConfigProvider>
        </div>
      </div>
      <DrawerChiTietXN open={open} setOpen={setOpen} />
    </>
  )
}

export default PhieuThu

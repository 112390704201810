import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, DatePicker, Input, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import moment from 'moment'
import dayjs from 'dayjs'

const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'


function FilterButton(props) {
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [chiNhanh, setChiNhanh] = useState(null)
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [keyword, setKeyword] = useState('')
  const dispatch = useDispatch()
  const [dateFrom, setDateFrom] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'from'
      ? setDateFrom(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }


  useEffect(() => {
    dispatch(listAllCompanyAction())
  }, [])

  useEffect(() => {
    getAllCN_By_idct(company)
  }, [company])

  const getAllCN_By_idct = async (value) => {
    try {
      const { data } = await https.get(`ChiNhanh/GetChiNhanhByIDCT?idct=${value}`)
      setListChiNhanh(data)
    } catch (error) {
      console.log(error)
    }
  }
  const handleCompany = (value) => {
    setCompany(value)
    getAllCN_By_idct(value)
  }

  return (
    <div className="flex gap-2 items-center w-full">
      <Select
        showSearch
        className="w-64"
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        }
        onChange={handleCompany}
        value={company}
        options={listCompany?.map((items) => ({
          label: items.tenct,
          value: items.idct,
        }))}
      />
      <Select
        showSearch
        className="w-64"
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        }
        onChange={(value) => setChiNhanh(value)}
        value={chiNhanh}
        options={listChiNhanh?.map((items) => ({
          label: items.tenchinhanh,
          value: items.idchinhanh,
        }))}
      />
      <Input
        className="w-44"
        placeholder="Nhập tài khoản"
        onChange={(e) => setKeyword(e.target.value)}
        value={keyword}
      />
      <div className="flex items-center gap-3">
        <DatePicker
          allowClear={false}
          defaultValue={dayjs(now)}
          maxDate={dayjs(now)}
          onChange={handleDatePicker('from')}
          format={dateFormat}
        />
        <span className="font-semibold text-lg">-</span>
        <DatePicker
          allowClear={false}
          defaultValue={dayjs(now)}
          maxDate={dayjs(now)}
          onChange={handleDatePicker('to')}
          format={dateFormat}
        />
      </div>
      <Button type="primary">
        <SearchOutlined />
      </Button>
    </div>
  )
}

FilterButton.propTypes = {}

export default React.memo(FilterButton)

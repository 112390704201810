import React, { useEffect } from 'react';
import { jsPDF } from 'jspdf';
import JsBarcode from 'jsbarcode';

export const TTQGPDF = (name, barcode) => {
  // Hàm này sẽ được gọi để tạo PDF
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: [30, 50], // Kích thước giấy 50mm x 30mm
    });

    // Thiết lập các thuộc tính cho PDF
    doc.setFont('Arial', 'normal');
    doc.setFontSize(12);

    // Thêm tiêu đề
    doc.text(name, 10, 10, { align: 'center' });

    // Tạo canvas để chứa mã vạch
    const barcodeCanvas = document.createElement('canvas');

    // Sử dụng jsBarcode để tạo mã vạch
    JsBarcode(barcodeCanvas, barcode, {
      format: 'CODE128', // Loại mã vạch
      displayValue: false,
      width: 2, // Độ rộng của mã vạch
      height: 50, // Chiều cao của mã vạch
      margin: 1, // Khoảng cách
    });

    // Chuyển đổi canvas thành hình ảnh và thêm vào PDF
    const barcodeDataUrl = barcodeCanvas.toDataURL();
    doc.addImage(barcodeDataUrl, 'PNG', 10, 20, 30, 15); // Xác định vị trí và kích thước của mã vạch

    // Thêm giá trị
    doc.setFontSize(14);
    doc.text(barcode, 10, 35, { align: 'center' });

    // Tạo blob và mở cửa sổ in
    const blob = doc.output('blob');
    const url = URL.createObjectURL(blob);
    const printWindow = window.open(url);

    // Đóng cửa sổ in sau khi in
    if (printWindow) {
      printWindow.addEventListener('load', () => {
        printWindow.print();
      }, true);
    }
};

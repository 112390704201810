import React, { useCallback, useEffect, useState } from 'react'
import { Button, ConfigProvider, Input, Pagination, Table, Tabs, Modal, Select } from 'antd'
import DoTuoi from './DoTuoi/DoTuoi'
import MuiTiem from './MuiTiem/MuiTiem'
import HoatChat from './HoatChat/HoatChat'
import KhoangCach from './KhoangCach/KhoangCach'
import { https } from '../../../services/apiService'
import ToastCus from '../../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal

function CanhBao() {
  return (
    <div className="p-3 h-full bg-[#EFEFEF]">
      <div
        className="h-full p-2 bg-white border rounded-xl"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <Tabs
          defaultActiveKey={1}
          items={[
            {
              key: 1,
              value: 1,
              label: 'Độ tuổi',
              children: (
                <>
                  <DoTuoi />
                </>
              ),
            },
            {
              key: 2,
              value: 2,
              label: 'Mũi tiêm',
              children: (
                <>
                  <MuiTiem />
                </>
              ),
            },
            {
              key: 3,
              value: 3,
              label: 'Hoạt chất',
              children: (
                <>
                  <HoatChat />
                </>
              ),
            },
            {
              key: 4,
              value: 4,
              label: 'Khoảng cách',
              children: (
                <>
                  <KhoangCach />
                </>
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}

export default CanhBao

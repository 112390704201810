import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  DatePicker,
  Select,
  ConfigProvider,
  Table,
  Input,
  Button,
  TreeSelect,
  Tag,
  Modal,
  message,
  Form,
} from 'antd'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import DrawerThuocBanToa from './DrawerThuocBanToa/DrawerThuocBanToa'
import { fetchPhieuThu } from '../../../../store/actions/drugstoreAction'
import { reportService } from '../../../../services/Report/reportService'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { https } from '../../../../services/apiService'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import { exportExcelformatSumCol } from '../../../../utils/export/exportExcelFormat'
import { formattedNumber } from '../../../../utils/formattedNumber'
import ToastCus from '../../../../utils/Toast'
import _ from 'lodash'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const { SHOW_PARENT } = TreeSelect
const PTThuoc = () => {
  const dispatch = useDispatch()
  const { PTThuoc } = useSelector((state) => state.drugstoreReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [open, setOpen] = useState(false)
  const [branch, setBranch] = useState([])
  const [ChuyenKhoa, setChuyenKhoa] = useState([])
  const [idChuyenKhoa, setIdChuyenKhoa] = useState(null)
  const [value, setValue] = useState([BranchLogin])
  const [isLoading, setIsLoading] = useState(false)
  const [date, setDate] = useState({ form: now, to: now })
  const [chiTiet, setChiTiet] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [idCaLamViec, setIdCaLamViec] = useState([1, 2, 3])
  const [form] = Form.useForm()
  const filter = {
    idChuyenKhoa: idChuyenKhoa,
    idCa: idCaLamViec,
    idct: id_company,
    idcn: value,
    tuNgay: date.form,
    denNgay: date.to,
    keyword: keyword.trim(),
  }

  const handleDatePicker = (name) => (date, dateString) => {
    const formatDateValue = moment(dateString, dateFormat).format(momentDate)
    setDate((prevSate) => ({ ...prevSate, [name]: formatDateValue }))
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      dispatch(fetchPhieuThu({ ...filter, keyword: keyword }, setIsLoading))
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const onReload = () => {
    setChiTiet(null)
    dispatch(fetchPhieuThu(filter, setIsLoading))
  }
  const onSearch = (e) => {
    const { value } = e.target
    setKeyword(value)
    // debounceDropDown(value)
  }
  useEffect(() => {
    fetchBranchByChuyenKhoa(null)
    fetchChuyenKhoa()
    onReload()
  }, [])
  const exportExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT248',
      )

      const formatData = PTThuoc?.map((item) => ({
        'STT ': item.stt,
        'Mã phiếu Thu': item.maphieuthu,
        'Chi nhánh': item.tenchinhanh,
        'Ngày bán': moment(item.ngayban).format('DD/MM/YYYY'),
        'Ca trực': item.calamviec,
        'Tên bệnh nhân': item.tenbenhnhan,
        'Mã bệnh nhân': item.mabenhnhan,
        'Ngày sinh': moment(item.ngaysinh).format('DD/MM/YYYY'),
        'Bác sĩ kê toa': item.bskedon,
        'Dược sĩ bán': item.duocsiban,
        'Tiền mặt': formattedNumber(item.tienmat ?? 0),
        'Chuyển khoản': formattedNumber(item.chuyenkhoan ?? 0),
        'Máy Pos': formattedNumber(item.pos ?? 0),
        'Momo ': formattedNumber(item.momo ?? 0),
        'VNPay ': formattedNumber(item.vnpay ?? 0),
        'ZaloPay ': formattedNumber(item.zalopay ?? 0),
        'Thẻ thanh toán': formattedNumber(item.thett ?? 0),
        'Tổng tiền': formattedNumber(item.tongtien ?? 0),
        'Tiền giảm': formattedNumber(item.tiengiamgia ?? 0),
        'Tiền thanh toán': formattedNumber(item.tienthanhtoan ?? 0),
      }))
      const name = `Phiếu thu toa thuốc - ${moment().format('DD-MM-YYYY')}` // format date L = '26.08.2024'
      formatData?.length &&
        exportExcelformatSumCol(formatData, name, [
          'Tiền mặt',
          'Chuyển khoản',
          'Máy Pos',
          'Momo ',
          'VNPay ',
          'ZaloPay ',
          'Thẻ thanh toán',
          'Tổng tiền',
          'Tiền giảm',
          'Tiền thanh toán',
        ])
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }
  //------------ lấy danh sách chi nhánh theo chuyên khoa ----------//
  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(id_company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  //-------------- lấy danh sách chuyên kho ------------//
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      children: branch?.map((branch, index) => ({
        key: branch.idChiNhanh,
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
      })),
    },
  ]
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  const tPropsCLV = {
    treeData: [
      {
        title: 'Tất cả',
        value: '',
        children: [
          { title: 'Ca A', value: 1 },
          { title: 'Ca B', value: 2 },
          { title: 'Ca C', value: 3 },
        ],
      },
    ],
    value: idCaLamViec,
    onChange: (value) => {
      if (value.includes('')) {
        setIdCaLamViec([1, 2, 3])
      } else {
        setIdCaLamViec(value)
      }
    },
    treeCheckable: true,
    showCheckedStrategy: TreeSelect.SHOW_PARENT,
    placeholder: 'Chọn ca làm việc...',
    className: 'w-full h-8 overflow-clip',
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 45,
      align: 'center',
      key: 'stt',
      fixed: 'left',
      showSorterTooltip: false,
      render: (text, _, index) => ++index,
    },
    {
      title: 'Mã phiếu thu',
      dataIndex: 'maphieuthu',
      width: 120,
      showSorterTooltip: false,
      key: 'maphieuthu',
      fixed: 'left',
      align: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.maphieuthu ?? '').localeCompare(b?.maphieuthu ?? '')
        },
      },
      render: (text, record) => (
        <span
          className="text-blue-500 cursor-pointer"
          onClick={() => {
            setChiTiet(record)
            setOpen(true)
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenchinhanh',
      // width: 250,
      showSorterTooltip: false,
      key: 'tenchinhanh',
      fixed: 'left',
      align: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.tenchinhanh ?? '').localeCompare(b?.tenchinhanh ?? '')
        },
      },
    },
    {
      title: 'Ngày bán',
      dataIndex: 'ngayban',
      // width: 140,
      align: 'center',
      key: 'ngayban',
      showSorterTooltip: false,
      render: (text) => (text ? moment(text)?.format('DD/MM/YYYY HH:mm:ss') : '-'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngayban ?? '').localeCompare(b?.ngayban ?? '')
        },
      },
    },
    {
      title: 'Ca trực',
      dataIndex: 'calamviec',
      width: 140,
      align: 'center',
      key: 'calamviec',
      showSorterTooltip: false,
      // filters: [
      //   { text: 'Ca A', value: 'Ca A' },
      //   { text: 'Ca B', value: 'Ca B' },
      //   { text: 'Ca C', value: 'Ca C' },
      // ],
      onFilter: (value, record) => record.calamviec === value,
      render: (text, record) => {
        let color = 'blue'
        if (text === 'Ca A') {
          color = 'green'
        } else if (text === 'Ca B') {
          color = 'red'
        } else if (text === 'Ca C') {
          color = 'orange'
        }
        return (
          <Tag color={color} key={record.key}>
            {text}
          </Tag>
        )
      },
      sorter: {
        compare: (a, b) => {
          return (a?.calamviec ?? '').localeCompare(b?.calamviec ?? '')
        },
      },
    },
    {
      title: 'Tên BN',
      dataIndex: 'tenbenhnhan',
      key: 'tenbenhnhan',
      showSorterTooltip: false,
      // width: 250,
      sorter: {
        compare: (a, b) => {
          return (a?.tenbenhnhan ?? '').localeCompare(b?.tenbenhnhan ?? '')
        },
      },
    },
    {
      title: 'Mã BN',
      dataIndex: 'mabenhnhan',
      showSorterTooltip: false,
      key: 'mabenhnhan',
      sorter: {
        compare: (a, b) => {
          return (a?.mabenhnhan ?? '').localeCompare(b?.mabenhnhan ?? '')
        },
      },
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaysinh',
      width: 100,
      align: 'center',
      key: 'ngaysinh',
      showSorterTooltip: false,
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaysinh ?? '').localeCompare(b?.ngaysinh ?? '')
        },
      },
    },
    {
      title: 'Bác sĩ kê toa',
      dataIndex: 'bskedon',
      key: 'bskedon',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.bskedon ?? '').localeCompare(b?.bskedon ?? '')
        },
      },
    },
    {
      title: 'Dược sĩ bán',
      dataIndex: 'duocsiban',
      key: 'duocsiban',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.duocsiban ?? '').localeCompare(b?.duocsiban ?? '')
        },
      },
    },
    {
      title: 'Tiền mặt',
      dataIndex: 'tienmat',
      key: 'tienmat',
      align: 'right',
      width: 100,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.tienmat ?? 0) - (b.tienmat ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.tienMat ?? '').localeCompare(b?.tienMat ?? '')
      //   },
      // },
    },
    {
      title: 'Chuyển khoản',
      dataIndex: 'chuyenkhoan',
      key: 'chuyenkhoan',
      width: 120,
      showSorterTooltip: false,
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (a.chuyenkhoan ?? 0) - (b.chuyenkhoan ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.chuyenKhoan ?? '').localeCompare(b?.chuyenKhoan ?? '')
      //   },
      // },
    },
    {
      title: 'Máy Pos',
      dataIndex: 'pos',
      key: 'pos',
      width: 90,
      align: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.pos ?? 0) - (b.pos ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: 'Momo',
      dataIndex: 'momo',
      key: 'momo',
      align: 'right',
      showSorterTooltip: false,
      width: 90,
      sorter: {
        compare: (a, b) => {
          return (a.momo ?? 0) - (b.momo ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.moMo ?? '').localeCompare(b?.moMo ?? '')
      //   },
      // },
    },
    {
      title: 'VNPay',
      dataIndex: 'vnpay',
      key: 'vnpay',
      align: 'right',
      width: 90,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.vnpay ?? 0) - (b.vnpay ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: 'ZaloPay',
      dataIndex: 'zalopay',
      key: 'zalopay ',
      align: 'right',
      width: 90,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.zalopay ?? 0) - (b.zalopay ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: 'Thẻ thanh toán',
      dataIndex: 'thett',
      key: 'thett',
      align: 'right',
      width: 130,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.thett ?? 0) - (b.thett ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: 'Mã voucher',
      dataIndex: 'mavoucher',
      key: 'mavoucher',
      width: 110,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.mavoucher ?? '').localeCompare(b?.mavoucher ?? '')
        },
      },
    },
    {
      title: <p className="text-center">Tổng tiền</p>,
      dataIndex: 'tongtien',
      key: 'tongtien',
      align: 'right',
      width: 100,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.tongtien ?? 0) - (b.tongtien ?? 0)
        },
      },
      render: (text) => (text ? formattedNumber(text) : 0),
    },
    {
      title: <p className="text-center">Tiền giảm</p>,
      dataIndex: 'tiengiamgia',
      key: 'tiengiamgia',
      width: 100,
      align: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.tiengiamgia ?? 0) - (b.tiengiamgia ?? 0)
        },
      },
      render: (text) => (text ? formattedNumber(text) : 0),
    },
    {
      title: <p className="text-center">Thanh toán</p>,
      dataIndex: 'tienthanhtoan',
      key: 'tienthanhtoan',
      width: 100,
      align: 'right',
      fixed: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.tienthanhtoan ?? 0) - (b.tienthanhtoan ?? 0)
        },
      },
      render: (text) => (text ? formattedNumber(text) : 0),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 45,
      align: 'center',
      showSorterTooltip: false,
      fixed: 'right',
      render: (text, record, index) => (
        <ul className="flex justify-center gap-2">
          <li
            onClick={() => handleOpenChiTiet(record)}
            className="text-green-700 bg-green-200 cursor-pointer w-6 hover:text-white hover:bg-green-700 duration-200 rounded"
          >
            <FormOutlined style={{ fontSize: 16, fontWeight: 700 }} />
          </li>
          {(infoUser?.idNhom === 27 || infoUser?.idNhom === 28) && (
            <li
              onClick={() => handleDelete(record?.idbnttc)}
              className="text-red-700 bg-red-200 cursor-pointer w-6 hover:text-white hover:bg-red-700 duration-200 rounded"
            >
              <DeleteOutlined style={{ fontSize: 16, fontWeight: 700 }} />
            </li>
          )}
        </ul>
      ),
    },
  ]
  const handleOpenChiTiet = async (record) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT249',
      )
      setChiTiet(record)
      setOpen(true)
    } catch (error) {
      setChiTiet(null)
      setOpen(false)
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const handleDelete = async (id) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT256',
      )
      Modal.confirm({
        title: <div className="text-lg font-bold text-red-600">Xác nhận xóa phiếu</div>,
        icon: <ExclamationCircleOutlined className="text-red-500 text-xl" />,
        content: (
          <div className="py-2">
            <p className="mb-3 text-gray-600">Vui lòng nhập lý do xóa phiếu này:</p>
            <Form form={form} className="delete-form">
              <Form.Item
                name="lydo"
                rules={[
                  { required: true, message: 'Vui lòng nhập lý do xóa!' },
                  { min: 100, message: 'Lý do xóa phải có ít nhất 100 ký tự!' },
                  { whitespace: true, message: 'Lý do không được chỉ chứa khoảng trắng!' },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Nhập lý do xóa (tối thiểu 100 ký tự)"
                  className="w-full border rounded-md p-2 hover:border-blue-400 focus:border-blue-500"
                  maxLength={500}
                  showCount
                />
              </Form.Item>
            </Form>
          </div>
        ),
        okText: 'Xác nhận xóa',
        okType: 'danger',
        cancelText: 'Hủy',
        width: 500,
        className: 'delete-modal',
        okButtonProps: {
          className: 'bg-red-100 hover:bg-red-600',
          onClick: async () => {
            try {
              // Validate form before proceeding
              const values = await form.validateFields()

              // Trim whitespace from reason
              const lydo = values.lydo.trim()

              const response = await https.delete(
                `/BenhNhanPhieuThu/DeletePhieuThuDuoc?idbnttc=${id}&idnv=${infoUser?.idnv}&LyDo=${lydo}`,
              )

              if (response.status === 200) {
                message.success({
                  content: 'Xóa phiếu thành công',
                  className: 'custom-message',
                })
                onReload()
                form.resetFields() // Clear the form
                Modal.destroyAll() // Close all modals
                return true
              }

              message.error({
                content: 'Xóa phiếu thất bại',
                className: 'custom-message',
              })
              return false
            } catch (error) {
              if (error.errorFields) {
                return false // Keep modal open if validation fails
              }

              message.error({
                content: 'Có lỗi xảy ra khi xóa phiếu',
                className: 'custom-message',
              })
              console.error('Error:', error)
              return false
            }
          },
        },
      })
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  return (
    <div className="h-full w-full ">
      <div className="flex gap-5 justify-between   mb-2 h-full">
        <div className="flex gap-2">
          <div className="flex items-center gap-1">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="w-56">
            <Select
              onChange={onChangeChuyenKhoa}
              className="w-full"
              value={idChuyenKhoa}
              options={[
                { label: 'Tất cả chuyên khoa', value: null },
                ...ChuyenKhoa.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
            />
          </div>
          <div className="w-80">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="w-60 flex gap-1 items-center">
            <Input
              placeholder="Nhập từ khóa..."
              prefix={<SearchOutlined className="pr-1" />}
              onChange={onSearch}
              value={keyword}
              className="w-full"
            />
          </div>
          <div className="w-60 flex items-center">
            <label className="font-semibold mr-2 w-36" htmlFor="">
              Ca làm việc:
            </label>
            <TreeSelect {...tPropsCLV} />
          </div>
          <div className="flex gap-2">
            <IconButton onClick={onReload} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={exportExcel}
              disabled={!PTThuoc?.length}
              className="text-green-700 font-semibold"
              type="text"
            >
              <ImportExportIcon /> Excel
            </Button>
          </div>
        </div>
      </div>
      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            // scroll={{
            //   x: PTThuoc.length !== 0 ? 'max-content' : 2200,
            //   y: 682,
            // }}
            scroll={{
              x: PTThuoc.length !== 0 ? 'max-content' : 2200,
              y: PTThuoc.length !== 0 ? 682 : undefined,
            }}
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={PTThuoc}
            columns={columns}
            summary={(pageData) => {
              let sumtienmat = 0
              let sumchuyenkhoan = 0
              let summaypos = 0
              let summomo = 0
              let sumvnpay = 0
              let sumzalopay = 0
              let sumthethanhtoan = 0
              let sumtiengiam = 0
              let sumthanhtoan = 0
              let sumtongtien = 0
              pageData.forEach(
                ({
                  tienmat,
                  chuyenkhoan,
                  pos,
                  momo,
                  zalopay,
                  vnpay,
                  thett,
                  tongtien,
                  tiengiamgia,
                  tienthanhtoan,
                }) => {
                  sumtienmat += tienmat
                  sumchuyenkhoan += chuyenkhoan
                  summaypos += pos
                  summomo += momo
                  sumvnpay += vnpay
                  sumzalopay += zalopay
                  sumthethanhtoan += thett
                  sumtongtien += tongtien
                  sumtiengiam += tiengiamgia
                  sumthanhtoan += tienthanhtoan
                },
              )
              // console.log;
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                    <Table.Summary.Cell index={9}></Table.Summary.Cell>
                    <Table.Summary.Cell index={10}>
                      <p className="font-semibold text-right">{formattedNumber(sumtienmat)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11}>
                      <p className="font-semibold text-right">{formattedNumber(sumchuyenkhoan)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12}>
                      <p className="font-semibold text-right">{formattedNumber(summaypos)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13}>
                      <p className="font-semibold text-right">{formattedNumber(summomo)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14}>
                      <p className="font-semibold text-right">{formattedNumber(sumvnpay)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15}>
                      <p className="font-semibold text-right">{formattedNumber(sumzalopay)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={16}>
                      <p className="font-semibold text-right">{formattedNumber(sumthethanhtoan)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={17}></Table.Summary.Cell>
                    <Table.Summary.Cell index={18}>
                      <p className="font-semibold text-right">{formattedNumber(sumtongtien)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={19}>
                      <p className="font-semibold text-right">{formattedNumber(sumtiengiam)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={20}>
                      <p className="font-semibold text-right">{formattedNumber(sumthanhtoan)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={21}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
      <DrawerThuocBanToa open={open} setOpen={setOpen} chiTiet={chiTiet} />
    </div>
  )
}

export default PTThuoc

import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  listCompany: null,
}

const companyReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.DISPATCH_LIST_ALL_COMPANY:
        draft.listCompany = payload
        break
      default:
        return state
    }
  })
}

export default companyReducer

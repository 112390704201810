import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  loaiHangHoa: [],
  infoHangHoa: null,
  nhomHangHoa: [],
}

const loaiHangHoaReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.DISPATCH_THUOC_VAT_TU_PHAN_LOAI:
        draft.loaiHangHoa = payload
        break
      case typeAction.DISPATCH_INFO_THUOC_VAT_TU:
        draft.infoHangHoa = payload
        break
      case typeAction.DISPATCH_NHOM_HANG_HOA_BY_LOAI:
        draft.nhomHangHoa = payload
        break
      default:
        break
    }
  })
}

export default loaiHangHoaReducer

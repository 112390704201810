import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, DatePicker, Input, Select, Table, TreeSelect } from 'antd'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import { SearchOutlined } from '@ant-design/icons'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { branchService } from '../../../../services/branch/branchService'
import { reportService } from '../../../../services/Report/reportService'
import { LoaitoaService } from '../../../../services/LoaiToa/LoaitoaService'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { formattedNumber } from '../../../../utils/formattedNumber'
import ToastCus from '../../../../utils/Toast'
import * as XLSX from 'xlsx'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
import { configSearchTableAnt } from '../../../../utils/configSearchTableAntd'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

const XuatNhaThuocChiTiet = () => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState(['all'])
  const [dataReport, setDataReport] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [search, setSearch] = useState('')
  const [loaiToa, setLoaiToa] = useState([])
  const [selectedBranches, setSelectedBranches] = useState(['all'])
  const [selectedLoaiToa, setSelectedLoaiToa] = useState('all')
  const [valueExport, setValueExport] = useState([])
  const [searchValues, setSearchValues] = useState({})
  const [filteredData, setFilteredData] = useState([])
  const fetchReport = async () => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT534',
      )
      let idChiNhanhs = ''
      if (!selectedBranches.includes('all')) {
        idChiNhanhs = selectedBranches.map((id) => `idChiNhanhs=${id}`).join('&')
      }
      const idLoaiToaQuery = selectedLoaiToa !== 'all' ? `idLoaiToa=${selectedLoaiToa}` : ''
      const queryString = `idCongTy=${company}&${idChiNhanhs}&${idLoaiToaQuery}&keyword=${search?.trim() || ''}&fromDate=${dateForm}&toDate=${dateTo}`
      const { data } = await reportService.getThongKeXuatNhaThuoc(queryString)

      const formattedData = data.flatMap((branch) =>
        branch.ngayList.map((item, index) => ({
          tenchinhanh: branch.tenChiNhanh,
          ngay: item.ngay,
          loaiphieu: item.tenLoaiToa,
          toathuoc: item.toaThuoc,
          mahang: item.maThuoc,
          tenhang: item.tenThuoc,
          hoatchat: item.tenHoatChat,
          donvi: item.dvt,
          soluong: item.soLuong,
          dongia: item.donGia,
          thanhtien: item.thanhTien,
          giam: item.phanTramGiam,
          giamgia: item.tienGiam,
          thanhtoan: item.tongTien,
          solo: item.soLo,
          handung: item.hanDung,
          sohoadon: item.soHoaDon,
          sodangky: item.soDangKy,
          ghichu: item.ghiChu,
        })),
      )
      setDataReport(formattedData)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setIsLoading(false)
    }
  }
  const fetchBranch = async (id) => {
    try {
      setIsLoading(true)
      const result = await branchService.getBranchNotVP(id)
      setBranch(result.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchAllLoaiToa = async () => {
    try {
      const { data } = await LoaitoaService.getAllLoaiToa()
      setLoaiToa(data)
    } catch (error) {
      console.error('Error fetching Loai Toa:', error)
    }
  }
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  useEffect(() => {
    const filterData = () => {
      let filtered = dataReport
      Object.keys(searchValues).forEach((key) => {
        if (searchValues[key]) {
          filtered = filtered.filter((item) =>
            item[key]?.toString().toLowerCase().includes(searchValues[key].toLowerCase()),
          )
        }
      })
      setFilteredData(filtered)
      setValueExport(filtered)
    }
    filterData()
  }, [searchValues, dataReport])
  const onChange = (newValue) => {
    setSelectedBranches(newValue)
  }

  const tProps = {
    treeData,
    value: selectedBranches,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const handleTableChange = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT535',
      )

      const data = valueExport.map((item) => ({
        'Chi nhánh': item.tenchinhanh,
        'Ngày ': item.ngay ? moment(item.ngay).format('DD/MM/YYYY') : '',
        'Loại phiếu': item.loaiphieu,
        'Toa thuốc': item.toathuoc,
        'Mã hàng': item.mahang,
        'Tên hàng': item.tenhang,
        'Hoạt chất': item.hoatchat,
        'Đơn vị': item.donvi,
        'Số lượng': item.soluong,
        'Đơn giá': item.dongia,
        'Thành tiền': item.thanhtien,
        '% giảm': item.giam,
        'Giảm giá': item.giamgia,
        'Thanh toán': item.thanhtoan,
        'Số lô': item.solo,
        'Hạn dùng': item.handung ? moment(item.handung).format('DD/MM/YYYY') : '',
        'Số hóa đơn': item.sohoadon,
        'Số đăng ký': item.sodangky,
        'Ghi chú': item.ghichu,
      }))

      // XLSX.utils.sheet_add_aoa(data, [['Bảng thống kê xuất nhà thuốc']], { origin: 'A1' })
      exportExcelformat(data, 'Bảng thống kê xuất nhà thuốc')

      XLSX.utils.sheet_add_aoa(
        data,
        [
          [
            'Tổng số lượng',
            formattedNumber(dataReport?.reduce((tong, item) => (tong += item?.soluong || 0), 0)),
            'Tổng thành tiền',
            formattedNumber(dataReport?.reduce((tong, item) => (tong += item?.thanhtien || 0), 0)),
            'Tổng giảm giá',
            formattedNumber(dataReport?.reduce((tong, item) => (tong += item?.giamgia || 0), 0)),
            'Tổng Thanh toán',
            formattedNumber(dataReport?.reduce((tong, item) => (tong += item?.thanhtoan || 0), 0)),
          ],
        ],
        { origin: `A${data.length + 2}` },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  const onLoad = () => {
    setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
    fetchReport()
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        await dispatch(listAllCompanyAction())
        await fetchBranch(id_company)
        await fetchAllLoaiToa()
        await fetchReport()
      } catch (error) {
        console.log(error)
      }
    }
    initialize()
  }, [])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      onCell: () => ({
        style: {
          minWidth: 40,
        },
      }),
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
   
    {
      title: 'Chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenchinhanh', dataReport, 120, false),
    },
    {
      title: 'Ngày ',
      dataIndex: 'ngay',
      key: 'ngay',
      ...configSearchTableAnt(setSearchValues, searchValues, 'ngay', dataReport, 90, true,{
        align: 'center',
        render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
      }),
    },
    {
      title: 'Loại phiếu',
      dataIndex: 'loaiphieu',
      key:'loaiphieu',
      ...configSearchTableAnt(setSearchValues, searchValues, 'loaiphieu', dataReport, 120, false),
    },
    {
      title: 'Toa thuốc',
      dataIndex: 'toathuoc',
      key: 'toathuoc',
      ...configSearchTableAnt(setSearchValues, searchValues, 'toathuoc', dataReport, 120, false),
    },
    {
      title: 'Mã hàng',
      dataIndex: 'mahang',
      key: 'mahang',
      ...configSearchTableAnt(setSearchValues, searchValues, 'mahang', dataReport, 120, false),
    },
    {
      title: 'Tên hàng',
      dataIndex: 'tenhang',
      key: 'tenhang',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenhang', dataReport, 300, false),
    },
    {
      title: 'Hoạt chất',
      dataIndex: 'hoatchat',
      key: 'hoatchat',
      ...configSearchTableAnt(setSearchValues, searchValues, 'hoatchat', dataReport, 300, false),
    },
    {
      title: 'Đơn vị',
      dataIndex: 'donvi',
      key: 'donvi',
      ...configSearchTableAnt(setSearchValues, searchValues, 'donvi', dataReport, 90, false,{
        align:'center'
      }),
    },
    {
      title: 'Số lượng',
      dataIndex: 'soluong',
      key: 'soluong',
      ...configSearchTableAnt(setSearchValues, searchValues, 'soluong', dataReport, 120, false,{
        align: 'center',
      }),
    },
    {
      title: 'Đơn giá',
      dataIndex: 'dongia',
      key: 'dongia',
      ...configSearchTableAnt(setSearchValues, searchValues, 'dongia', dataReport, 120, false,{
        align: 'right',
        render: (text) => formattedNumber(text ?? 0),
      }),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'thanhtien',
      key: 'thanhtien',
      ...configSearchTableAnt(setSearchValues, searchValues, 'thanhtien', dataReport, 120, false,{
        align: 'right',
        render: (text) => formattedNumber(text ?? 0),
      }),
    },
    {
      title: '% Giảm',
      dataIndex: 'giam',
      key: 'giam',
      ...configSearchTableAnt(setSearchValues, searchValues, 'giam', dataReport, 120, false,{
        align: 'center',
        render: (text) => (text ? `${text.toFixed(2)}%` : '-'),
      }),
    },
    {
      title: 'Giảm giá',
      dataIndex: 'giamgia',
      key: 'giamgia',
      ...configSearchTableAnt(setSearchValues, searchValues, 'giamgia', dataReport, 120, false,{
        align: 'right',
        render: (text) => formattedNumber(text ?? 0),
      }),
    },
    {
      title: 'Thanh toán',
      dataIndex: 'thanhtoan',
      key: 'thanhtoan',
      ...configSearchTableAnt(setSearchValues, searchValues, 'thanhtoan', dataReport, 140, false,{
        align: 'right',
        render: (text) => formattedNumber(text ?? 0),
      }),
    },
    {
      title: 'Số lô',
      dataIndex: 'solo',
      key: 'solo',
      ...configSearchTableAnt(setSearchValues, searchValues, 'solo', dataReport, 120, false,{
        align: 'right',
      }),
    },
    {
      title: 'Hạn dùng',
      dataIndex: 'handung',
      key: 'handung',
      ...configSearchTableAnt(setSearchValues, searchValues, 'handung', dataReport, 90, true,{
        align: 'center',
        render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
      }),
    },
    {
      title: 'Số hóa đơn',
      dataIndex: 'sohoadon',
      key: 'sohoadon',
      ...configSearchTableAnt(setSearchValues, searchValues, 'sohoadon', dataReport, 130, false,{
        align: 'right',
      }),
    },
    {
      title: 'Số đăng ký',
      dataIndex: 'sodangky',
      key: 'sodangky',
      ...configSearchTableAnt(setSearchValues, searchValues, 'sodangky', dataReport, 120, false,{
        align: 'right',
      }),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      onCell: () => ({
        style: {
          minWidth: 100,
        },
      }),
      align: 'center',
    },
  ]

  return (
    <>
      <div>
        <div className="flex gap-2">
          <div className="w-[17rem] flex items-center gap-2">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="w-[17rem]">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranch(value)
                setSelectedBranches(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              // filterSort={(optionA, optionB) =>
              //   (optionA?.label ?? '')
              //     .toLowerCase()
              //     .localeCompare((optionB?.label ?? '').toLowerCase())
              // }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-[17rem] flex gap-2">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="w-[9rem]">
            <Select
              showSearch
              value={selectedLoaiToa}
              onChange={(value) => {
                setSelectedLoaiToa(value)
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              // filterSort={(optionA, optionB) =>
              //   (optionA?.label ?? '')
              //     .toLowerCase()
              //     .localeCompare((optionB?.label ?? '').toLowerCase())
              // }
              options={[
                { label: 'Tất cả loại toa', value: 'all' },
                ...loaiToa?.map(({ idLoaiToa, tenLoaiToa }) => ({
                  label: tenLoaiToa,
                  value: idLoaiToa,
                })),
              ]}
              placeholder="Chọn loại toa..."
              className="w-full"
            />
          </div>
          <div className="flex w-[15rem]">
            <Input
              value={search}
              placeholder="Tìm mã hàng, tên hàng..."
              onChange={(e) => setSearch(e.target.value)}
              prefix={<SearchOutlined />}
            />
          </div>
          <div className="flex gap-2">
            <IconButton
              disabled={isLoading}
              onClick={onLoad}
              color="primary"
              aria-label="delete"
              size="small"
            >
              <SyncIcon />
            </IconButton>
          </div>
          <div className="font-semibold ">
            <Button
              disabled={isLoading || !dataReport?.length}
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
        <div className="mt-2">
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
                fontSize: 14,
              },
              components: {
                Table: {
                  headerBorderRadius: 0,
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <Table
              onChange={handleTableChange}
              scroll={{
                x: filteredData.length > 0 ? 'max-content' : 0,
                y: '71vh',
              }}
              pagination={false}
              loading={isLoading}
              bordered
              dataSource={filteredData}
              columns={columns}
              summary={() => (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center" index={9}>
                      <span className="font-semibold">
                        {formattedNumber(
                          // số lượng
                          filteredData?.reduce((tong, item) => (tong += item?.soluong || 0), 0),
                        )}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9}></Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center" index={10}>
                      <span className="font-semibold">
                        {formattedNumber(
                          // thành tiền
                          filteredData?.reduce((tong, item) => (tong += item?.thanhtien || 0), 0),
                        )}
                      </span>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={11}></Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center" index={12}>
                      <span className="font-semibold">
                        {formattedNumber(
                          // tiền gia công
                          filteredData?.reduce((tong, item) => (tong += item?.giamgia || 0), 0),
                        )}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center" index={13}>
                      <span className="font-semibold">
                        {formattedNumber(
                          // Thanh toán
                          filteredData?.reduce((tong, item) => (tong += item?.thanhtoan || 0), 0),
                        )}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14}></Table.Summary.Cell>
                    <Table.Summary.Cell index={15}></Table.Summary.Cell>
                    <Table.Summary.Cell index={16}></Table.Summary.Cell>
                    <Table.Summary.Cell index={17}></Table.Summary.Cell>
                    <Table.Summary.Cell index={18}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
            />
          </ConfigProvider>
        </div>
      </div>
    </>
  )
}
export default XuatNhaThuocChiTiet

import { DatePicker, Input, InputNumber, Modal } from 'antd'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateTTVTool } from '../../../../store/actions/toolAction'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')
const today = moment()
const ModalTTV = ({ openTTV, setOpenTTV }) => {
  const handleCancel = () => {
    setOpenTTV(false)
  }
  const [date, setDate] = useState(today.format('YYYY-MM-DDTHH:mm:ss'))
  const dispatch = useDispatch()
  const hanldeSubmit = (value) => {
    value.ngaythanhtoan = date
    dispatch(updateTTVTool(value, reset))
  }
  const handleDatePicker = (data, dateString) => {
    setDate(moment(dateString, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss'))
  }
  const reset = () => {
    formik.handleReset()
    setDate(today.format('YYYY-MM-DDTHH:mm:ss'))
  }
  const formik = useFormik({
    initialValues: {
      maphieuthu: null,
      tienmat: 0,
      chuyenkhoan: 0,
      momo: 0,
      vnpay: 0,
      zalopay: 0,
      pos: 0,
      tiengiamgia: 0,
      tienthanhtoan: 0,
      ghichu: '',
      thett: 0,
      ngaythanhtoan: null,
    },
    onSubmit: (value) => hanldeSubmit(value),
  })
  const handleValue = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  return (
    <Modal
      onOk={formik.handleSubmit}
      okButtonProps={{ disabled: !formik.values.maphieuthu }}
      onCancel={handleCancel}
      open={openTTV}
      title={
        <h2 className="flex gap-2 text-xl items-center">
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-10 text-gray-400"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
            />
          </svg> */}
          Cập nhật Giá Thẻ thành viên
        </h2>
      }
    >
      <div className="flex flex-col gap-2 mt-5">
        <div>
          <label className="font-semibold text-base mb-2">Mã thanh toán</label>
          <Input
            value={formik.values.maphieuthu}
            placeholder="PT. . . ."
            name="maphieuthu"
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex flex-col">
          <label className="font-semibold text-base ">Ngày thanh toán</label>
          <DatePicker
            allowClear={false}
            showTime
            onChange={handleDatePicker}
            value={dayjs(date, 'YYYY-MM-DDTHH:mm:ss')}
            format={'DD/MM/YYYY HH:mm:ss'}
          />
        </div>
        <div>
          <label className="font-semibold text-base mb-2">Ghi chú</label>
          <Input value={formik.values.ghichu} name="ghichu" onChange={formik.handleChange} />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-5">
            <div className="w-1/2">
              <h2 className="font-semibold">Tiền mặt:</h2>
              <InputNumber
                className="w-full"
                value={formik.values.tienmat}
                name="tienmat"
                onChange={handleValue('tienmat')}
                formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
              />
            </div>
            <div className="w-1/2">
              <h2 className="font-semibold">Chuyển khoản:</h2>
              <InputNumber
                className="w-full"
                value={formik.values.chuyenkhoan}
                name="chuyenkhoan"
                onChange={handleValue('chuyenkhoan')}
                formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
              />
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-1/2">
              <h2 className="font-semibold">Máy POS:</h2>
              <InputNumber
                className="w-full"
                value={formik.values.pos}
                name="pos"
                onChange={handleValue('pos')}
                formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
              />
            </div>
            <div className="w-1/2">
              <h2 className="font-semibold">Momo:</h2>
              <InputNumber
                className="w-full"
                value={formik.values.momo}
                onChange={handleValue('momo')}
                name="momo"
                formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
              />
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-1/2">
              <h2 className="font-semibold">VNPay:</h2>
              <InputNumber
                className="w-full"
                value={formik.values.vnpay}
                name="vnpay"
                onChange={handleValue('vnpay')}
                formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
              />
            </div>
            <div className="w-1/2">
              <h2 className="font-semibold">Zalo Pay:</h2>
              <InputNumber
                className="w-full"
                value={formik.values.zalopay}
                name="zalopay"
                onChange={handleValue('zalopay')}
                formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
              />
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-1/2">
              <h2 className="font-semibold">Tiền giảm:</h2>
              <InputNumber
                className="w-full"
                value={formik.values.tiengiamgia}
                name="tiengiamgia"
                onChange={handleValue('tiengiamgia')}
                formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
              />
            </div>
            <div className="w-1/2">
              <h2 className="font-semibold">Tiền thanh toán:</h2>
              <InputNumber
                className="w-full"
                value={formik.values.tienthanhtoan}
                name="tienthanhtoan"
                onChange={handleValue('tienthanhtoan')}
                formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalTTV

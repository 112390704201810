import { https } from '../apiService'

export const nguoidungService = {
  getAllNhomNguoiDung: (tenNhom) => https.get('NhomNguoiDung/GetAll', tenNhom),
  postThemNguoiDung: (form) => https.post('NguoiDung/ThemNguoiDung', form),
  updateUser: (form) => https.put('NguoiDung/UpdateByIDNguoiDung', form),
  putNgDungIslockUser: (list) => https.put('NguoiDung/IsLock-User', list),
  putIPMAC: (idnd) => https.put(`NguoiDung/UpdateIPMAC?idnd=${idnd}`),
  getUserByIdnhom: (idnhom, pageIndex) =>
    https.get(`NguoiDung/GetNguoiDungByIdnhom?idnhom=${idnhom}&pageIndex=${pageIndex}`),
  getSearchByCondition: (idChiNhanh, idNhom, pageIndex) =>
    https.get(
      `NguoiDung/SearchByCondition?idChiNhanh=${idChiNhanh}&idNhom=${idNhom}&pageIndex=${pageIndex}`,
    ),
  getListUserTheoDieuKien: (idnhom, idcn, keyword, pageIndex) =>
    https.get(`NguoiDung/SearchNguoiDungTheoDieuKien`, {
      params: { idnhom: idnhom, idcn: idcn, keyword: keyword, pageIndex: pageIndex },
    }),
}

import React, { useState, useEffect } from 'react'
import {
  Table,
  ConfigProvider,
  Tooltip,
  Modal,
  Select,
  Input,
  Button as AntButton,
  DatePicker,
} from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { EyeOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { Button } from '@mui/material'
import CachedIcon from '@mui/icons-material/Cached'
import moment from 'moment'
import _ from 'lodash'
import {
  filterPT_pendingCK,
  listInfoThuocVTChyenKho_Action,
  updateStatusCK_action,
} from '../../../../../store/actions/chuyenKhoAction'
import { branchService } from '../../../../../services/branch/branchService'
import { chuyenKhoService } from '../../../../../services/ChuyenKho/chuyenKhoService'
import * as XLSX from 'xlsx'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { buildDateFilterCondition } from '../../../../../utils/buildDateFilterCondition'
import dayjs from 'dayjs'
import { exportExcelformat } from '../../../../../utils/export/exportExcelFormat'
import 'moment/locale/vi'
import { configSearchTableAnt } from '../../../../../utils/configSearchTableAntd'
moment.locale('vi')

const { confirm } = Modal
const now = new Date()

const Pedding = ({ showModal, setStatusUpdate }) => {
  const ID_COMPANY = localStorage.getItem('id_company')
  const ID_BRANCH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const [searchValues, setSearchValues] = useState({})
  const [valueExport, setValueExport] = useState([])
  const [data2, setData2] = useState([])
  const { PTPending } = useSelector((state) => state.chuyenKhoReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const id_company = Number(localStorage.getItem('id_company'))
  const [idBranch, setIdBranch] = useState(ID_BRANCH_LOGIN)
  // const [searchKho, setSearchKho] = useState('')
  // const [khoChuyen, setKhoChuyen] = useState('')
  // const [searchMa, setSearchMa] = useState('')
  const [ListBranch, setListBranch] = useState([])
  const [data, setData] = useState([])
  // const [ListKho, setListKho] = useState([])
  const [KhoNhans, setKhoNhans] = useState([])
  // const [filterDate, setFilterDate] = useState('=')
  // const [branhNhan, setBranchNhan] = useState('')
  // const [searchDate, setSearchDate] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  // const [searchName, setSearchName] = useState('')
  // const [searchNguoiXuat, setSearchNguoiXuat] = useState('')
  // const [branchChuyen, setBranchChuyen] = useState('')
  const dispatch = useDispatch()
  const statusChuyenKho = 2 // trạng thái đã rời kho
  const filter = {
    status: statusChuyenKho, //
    idChiNhanh: idBranch,
    idCongTy: id_company,
  }
  // show confirm xuất kho
  const showConfirm = (items) => {
    confirm({
      title: 'Xác nhận chuyển kho ',
      width: 450,
      icon: <ExclamationCircleFilled />,
      content: (
        <p className="text-start">
          bạn có muốn xác nhận phiếu <span className="font-semibold">{items.maPhieu}</span> vào
          <span className="font-semibold"> {items.tenKhoNhap}</span>
        </p>
      ),
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk() {
        const fd = new FormData()
        fd.append('IdNhapXuat', items.idNhapXuat)
        fd.append('TenPhieu', items.tenPhieu)
        fd.append('NhanVienNhan', infoUser.idnv)
        fd.append('TrangThai', 3) // trạng 3 là trạng thái nhận kho
        fd.append('NgayNhan', moment(now).format('YYYY-MM-DDTHH:mm:ss'))
        dispatch(updateStatusCK_action(fd, items.idNhapXuat, filter, 'pending'))
        handleFilter()
      },
      onCancel() {
        // console.log("Cancel");
      },
    })
  }
  // const handleSelectedBranchChuyen = (value, option) => {
  //   setBranchChuyen(value || '')
  //   if (value) {
  //     fetchKho(option.id)
  //   }
  //   setSearchKho('')
  // }
  //----------- lấy danh sách chi nhánh theo công ty -------//
  const fetchBranch = async () => {
    const { data } = await branchService.getListBranch(ID_COMPANY)
    setListBranch(data)
  }
  //---------- lấy danh sách kho chi nhánh-------------//
  // const fetchKho = async (id) => {
  //   const { data } = await chuyenKhoService.getlistKhoNhan(id)
  //   setListKho(data)
  // }
  const fetchKhoNhan = async (id) => {
    const { data } = await chuyenKhoService.getlistKhoNhan(ID_BRANCH_LOGIN)
    setKhoNhans(data)
  }
  // const handleSelectedKhoChuyen = (value) => {
  //   setKhoChuyen(value || '')
  // }
  // const handleSelectedKhoNhan = (value) => {
  //   setSearchKho(value || '')
  // }
  // const filteredData = data?.filter((items) => {
  //   const findKho = searchKho.toLowerCase()
  //   const findMa = searchMa.trim().toLowerCase()
  //   const findKhoChuyen = khoChuyen.toLowerCase()
  //   const nameLower = searchName.trim().toLowerCase()
  //   const nguoiXuatLower = searchNguoiXuat.trim().toLowerCase()
  //   const branchNhanLower = branhNhan.trim().toLowerCase()
  //   const branchChuyenLower = branchChuyen.trim().toLowerCase()
  //   return (
  //     (items?.tenKhoNhap?.toLowerCase().includes(findKho) || !items?.tenKhoNhap) &&
  //     (items?.maPhieu?.toLowerCase().includes(findMa) || !items?.maPhieu) &&
  //     (items?.tenPhieu?.toLowerCase().includes(nameLower) || !items?.tenPhieu) &&
  //     (items?.tenNVXuat?.toLowerCase().includes(nguoiXuatLower) || !items?.tenNVXuat) &&
  //     (items?.tenKhoXuat?.toLowerCase().includes(findKhoChuyen) || !items?.tenKhoXuat) &&
  //     buildDateFilterCondition(filterDate, searchDate, items?.ngayXuat) &&
  //     (items?.tenChiNhanhXuat?.toLowerCase().includes(branchChuyenLower) ||
  //       !items?.tenChiNhanhXuat) &&
  //     (items?.tenChiNhanhNhan.toLowerCase().includes(branchNhanLower) || !items?.tenChiNhanhNhan)
  //   )
  // })
  const handleShowModalDetail = (idNhapXuat) => {
    showModal()
    dispatch(listInfoThuocVTChyenKho_Action(idNhapXuat))
    setStatusUpdate(false) // tắt trạng thái cho phép update
  }
  // xử lí lọc dưc liệu
  const handleFilter = () => {
    dispatch(filterPT_pendingCK(filter, setIsLoading))
  }
  useEffect(() => {
    const sortedData = _.orderBy(PTPending, 'ngayXuat', 'desc')
    setData(sortedData)
  }, [PTPending])
  useEffect(() => {
    dispatch(filterPT_pendingCK(filter, setIsLoading))
    fetchBranch()
    fetchKhoNhan()
  }, [])

  const exportToExcel = () => {
    const formattedData = filteredData2(valueExport).map((item) => ({
      'Mã phiếu': item.maPhieu,
      'Tên phiếu': item.tenPhieu,
      'Người chuyển': item.tenNVXuat,
      'Ngày chuyển': moment(item.ngayXuat).format('DD/MM/YYYY HH:mm:ss'),
      'Chi nhánh chuyển': item.tenChiNhanhXuat,
      'Kho chuyển': item.tenKhoXuat,
      'Chi nhánh nhận': item.tenChiNhanhNhan,
      'Kho nhận': item.tenKhoNhap,
    }))
    const name = 'Phiếu chuyển kho - Phiếu chờ xác nhận'
    exportExcelformat(formattedData, name)
  }
  // const handleSearch = (name) => (e) => {
  //   const { value } = e.target
  //   switch (name) {
  //     case 'ma':
  //       setSearchMa(value || '')
  //       break
  //     case 'TENPHIEU':
  //       setSearchName(value || '')
  //       break
  //     case 'NGUOICHUYEN':
  //       setSearchNguoiXuat(value || '')
  //       break
  //     case 'NOINHAN':
  //       setBranchNhan(value || '')
  //       break
  //     default:
  //       break
  //   }
  // }
  // const compare = (a, b, dataIndex) => {
  //   if (a[dataIndex] < b[dataIndex]) {
  //     return -1
  //   }
  //   if (a[dataIndex] > b[dataIndex]) {
  //     return 1
  //   }
  //   return 0
  // }

  // const PhepTinh = [
  //   { label: '>', value: '>' },
  //   { label: '<', value: '<' },
  //   { label: '=', value: '=' },
  // ]
  const filteredData2 = (
    data, // lọc giá trị search
  ) =>
    data?.filter((item) =>
      Object.entries(searchValues).every(([key, value]) =>
        item[key]?.toLowerCase().includes(value.toLowerCase()),
      ),
    )
  const onChangeTable = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }
  useEffect(() => {
    setData2(PTPending)
    setValueExport(PTPending)
  }, [PTPending])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
    },
    {
      title: 'Mã phiếu',
      dataIndex: 'maPhieu',
      key: 'maPhieu',
      ...configSearchTableAnt(setSearchValues, searchValues, 'maPhieu', PTPending, 100,false),
      // sorter: (a, b) => compare(a, b, 'MAPHIEU'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Input size="small" onChange={handleSearch('ma')} value={searchMa} />
      //       </>
      //     ),
      //     onCell:() => ({
      //       style:{
      //         minWidth:100
      //       }
      //     }),
      //     dataIndex: 'MAPHIEU',
      //     key: 1,
      //   },
      // ],
    },
    {
      title: 'Tên phiếu',
      dataIndex: 'TENPHIEU',
      key: 'TENPHIEU',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenPhieu', PTPending, 120,false),
      // sorter: (a, b) => compare(a, b, 'TENPHIEU'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Input size="small" onChange={handleSearch('TENPHIEU')} value={searchName} />
      //       </>
      //     ),
      //     onCell:() => ({
      //       style:{
      //         minWidth:120
      //       }
      //     }),
      //     dataIndex: 'TENPHIEU',
      //     key: 1,
      //   },
      // ],
    },
    {
      title: 'Người chuyển',
      dataIndex: 'NGUOICHUYEN',
      key: 'NGUOICHUYEN',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenNVXuat', PTPending, 120,false),
      // sorter: (a, b) => compare(a, b, 'NGUOICHUYEN'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Input size="small" onChange={handleSearch('NGUOICHUYEN')} value={searchNguoiXuat} />
      //       </>
      //     ),
      //     dataIndex: 'NGUOICHUYEN',
      //     key: 1,
      //     onCell:() => ({
      //       style:{
      //         minWidth:120
      //       }
      //     }),
      //   },
      // ],
    },
    {
      title: 'Ngày chuyển',
      dataIndex: 'NGAYNHAN',
      key: 'NGAYNHAN',
      ...configSearchTableAnt(setSearchValues, searchValues, 'ngayXuat', PTPending, 120,true, {
        render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : ''),
      }),
      // sorter: (a, b) => compare(a, b, 'NGAYNHAN'),
      // children: [
      //   {
      //     title: (
      //       <div className="flex gap-1">
      //         <Select
      //           onChange={(value) => setFilterDate(value)}
      //           value={filterDate}
      //           size="small"
      //           className="w-16"
      //           options={PhepTinh}
      //         />
      //         <DatePicker
      //           value={searchDate !== '' ? dayjs(searchDate, 'YYYY-MM-DD') : ''}
      //           size="small"
      //           format={'DD/MM/YYYY'}
      //           onChange={(date, DateString) => {
      //             setSearchDate(
      //               DateString ? moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
      //             )
      //           }}
      //         />
      //       </div>
      //     ),
      //     dataIndex: 'NGAYNHAN',
      //     key: 2,
      //     onCell:() => ({
      //       style:{
      //         minWidth:150
      //       }
      //     }),
      //   },
      // ],
    },
    {
      title: 'Chi nhánh chuyển',
      dataIndex: 'tenChiNhanhXuat',
      key: 'tenChiNhanhXuat',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenChiNhanhXuat', PTPending, 120,false),
      // sorter: (a, b) => compare(a, b, 'NOICHUYEN'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Select
      //           size="small"
      //           className="w-full"
      //           filterOption={(input, option) =>
      //             (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      //           }
      //           showSearch
      //           onChange={handleSelectedBranchChuyen}
      //           value={branchChuyen}
      //           options={[
      //             { label: 'Tất cả', value: '' },
      //             ...ListBranch?.map((item) => ({
      //               label: item.tenChiNhanh,
      //               value: item.tenChiNhanh,
      //               id: item.idChiNhanh,
      //               key: item.idChiNhanh,
      //             })),
      //           ]}
      //         />
      //       </>
      //     ),
      //     dataIndex: 'NOICHUYEN',
      //     key: 1,
      //     onCell:() => ({
      //       style:{
      //         minWidth:120
      //       }
      //     }),
      //   },
      // ],
    },
    {
      title: 'kho chuyển',
      dataIndex: 'KHOCHUYEN',
      key: 'KHOCHUYEN',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenKhoXuat', PTPending, 120,false),
      // align: 'center',
      // sorter: (a, b) => compare(a, b, 'KHOCHUYEN'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Select
      //           filterOption={(input, option) =>
      //             (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      //           }
      //           size="small"
      //           value={khoChuyen}
      //           showSearch
      //           onChange={handleSelectedKhoChuyen}
      //           options={[
      //             { label: 'Tất cả', value: '' },
      //             ...ListKho.map((item) => ({
      //               label: item.tenKho,
      //               value: item.tenKho,
      //               key: item.idKhoCN, // Thêm key cho mỗi tùy chọn
      //             })),
      //           ]}
      //           className="w-full"
      //         />
      //       </>
      //     ),
      //     dataIndex: 'KHOCHUYEN',
      //     key: 2,
      //     onCell:() => ({
      //       style:{
      //         minWidth:120
      //       }
      //     }),
      //   },
      // ],
    },
    {
      title: 'Chi nhánh nhận',
      dataIndex: 'tenChiNhanhNhan',
      key: 'tenChiNhanhNhan',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenChiNhanhNhan', PTPending, 120,false),
      // sorter: (a, b) => compare(a, b, 'NOINHAN'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Input size="small" onChange={handleSearch('NOINHAN')} value={branhNhan} />
      //       </>
      //     ),
      //     dataIndex: 'NOINHAN',
      //     key: 2,
      //     onCell:() => ({
      //       style:{
      //         minWidth:120
      //       }
      //     }),
      //   },
      // ],
    },
    {
      title: 'kho nhận',
      dataIndex: 'KHONHAN',
      key: 'KHONHAN',
      // width: 150,
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenKhoNhap', PTPending, 120,false),
      // align: 'center',
      // sorter: (a, b) => compare(a, b, 'KHONHAN'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Select
      //           filterOption={(input, option) =>
      //             (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      //           }
      //           size="small"
      //           value={searchKho}
      //           showSearch
      //           onChange={handleSelectedKhoNhan}
      //           options={[
      //             { label: 'Tất cả', value: '' },
      //             ...KhoNhans.map((item) => ({
      //               label: item.tenKho,
      //               value: item.tenKho,
      //               key: item.tenKho, // Thêm key cho mỗi tùy chọn
      //             })),
      //           ]}
      //           className="w-full"
      //         />
      //       </>
      //     ),
      //     dataIndex: 'KHONHAN',
      //     key: 2,
      //     onCell:() => ({
      //       style:{
      //         minWidth:120
      //       }
      //     }),
      //   },
      // ],
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      align: 'center',
      fixed: 'right',
    },
    {
      title: 'Hành động',
      dataIndex: 'ACTION',
      key: 'ACTION',
      width: 100,
      align: 'center',
      fixed: 'right',
    },
  ]

  return (
    <div className="h-full">
      <div className="flex gap-5">
        <div className="w-96 gap-2 items-center flex">
          <label className="w-24 font-semibold">Chi nhánh:</label>
          <Select
            className="w-full"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            showSearch
            onChange={(value) => setIdBranch(value)}
            value={idBranch}
            options={[
              { label: 'Tất cả', value: '' },
              ...ListBranch?.map((item) => ({
                label: item.tenChiNhanh,
                value: item.idChiNhanh,
              })),
            ]}
          />
        </div>
        <div className="flex gap-3">
          <div
            className="cursor-pointer rounded-sm text-blue-500  hover:bg-slate-200"
            onClick={handleFilter}
          >
            <CachedIcon sx={{ fontSize: 25 }} />
          </div>
        </div>
        <div className="flex gap-3 ml-auto">
          <AntButton
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </AntButton>
        </div>
      </div>
      <div
        className="mt-2"
        // style={{
        //     boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        // }}
      >
        <ConfigProvider
          theme={{
            token: {
              padding: 4,
              fontSize: 12,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            loading={isLoading}
            bordered
            pagination={false}
            onChange={onChangeTable}
            scroll={{
              x: filteredData2(data2)?.length !== 0 ? 'max-content' : 1000,
              y: '70vh',
            }}
            dataSource={filteredData2(data2)?.map((items, index) => ({
              STT: ++index,
              ...items,
              // MAPHIEU: items.maPhieu,
              // TENPHIEU: items.tenPhieu,
              // NGUOICHUYEN: items.tenNVXuat,
              // NGAYNHAN: moment(items.ngayXuat).format('DD/MM/YYYY HH:mm:ss'),
              // NOICHUYEN: items.tenChiNhanhXuat,
              // KHOCHUYEN: items.tenKhoXuat,
              // NOINHAN: items.tenChiNhanhNhan,
              // KHONHAN: items.tenKhoNhap,
              status: (
                <>
                  <Button
                    disabled={ID_BRANCH_LOGIN !== items.idChiNhanhNhan}
                    onClick={() => {
                      showConfirm(items)
                    }}
                    size="small"
                    variant="contained"
                    style={{
                      fontSize: 12,
                      padding: 2,
                    }}
                  >
                    Xác nhận
                  </Button>
                </>
              ),
              ACTION: (
                <Tooltip title="Xem chi tiết" color="#108ee9">
                  <EyeOutlined
                    onClick={() => handleShowModalDetail(items.idNhapXuat)}
                    className="text-xl text-[#108ee9]  cursor-pointer"
                  />
                </Tooltip>
              ),
            }))}
            columns={columns}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default Pedding

import * as typeAction from '../constants/constants'
export const BNchoThuoc = (idChiNhanh) => ({
  type: typeAction.GET_BN_CHO_LAY_THUOC,
  idChiNhanh,
})
export const BNDaLayThuoc = (idChiNhanh) => ({
  type: typeAction.GET_BN_DA_LAY_THUOC,
  idChiNhanh,
})
export const BNChoBSKeToaLai = (idChiNhanh) => ({
  type: typeAction.GET_BN_CHO_BS_KE_TOA_LAI,
  idChiNhanh,
})

export const getDetailInfoBN = (idbnttc, idloaitt) => ({
  type: typeAction.GET_DETAIL_BN_NHA_THUOC,
  idbnttc,
  idloaitt,
})
//----------- lưu thuốc bệnh nhân --------------//
export const postBNThuoc = (fromPay, formPayDetail, data, idChiNhanh, resetFormData) => ({
  type: typeAction.POST_BN_THUOC,
  fromPay,
  formPayDetail,
  data,
  idChiNhanh,
  resetFormData,
})
//----------------- lấy danh sách phiếu thu----------------//
export const fetchPhieuThu = (filter, setIsLoading) => ({
  type: typeAction.GET_PHIEU_THU_THUOC,
  filter,
  setIsLoading,
})
//------------ thêm bệnh nhân  thuốc -------------//
export const addToaBanLe = (
  benhNhanTT,
  detailPay,
  thuocBN,
  dataBNTTC,
  infoVC,
  ghiChu,
  resetStore,
) => ({
  type: typeAction.POST_BN_BAN_LE,
  benhNhanTT,
  detailPay,
  thuocBN,
  dataBNTTC,
  infoVC,
  ghiChu,
  resetStore,
})
//----------- trả thuốc bệnh nhân --------------//
export const putBNTraThuoc = (fromPay, formPayDetail, data, idChiNhanh, resetFormData) => ({
  type: typeAction.POST_BN_TRA_THUOC,
  fromPay,
  formPayDetail,
  data,
  idChiNhanh,
  resetFormData,
})
//----------- trả toa cho bác sĩ kê lại --------------//
export const putTraToaChoBSKeLaiAction = (form) => ({
  type: typeAction.POST_BN_TRA_TOA_CHO_BS,
  form,
})
//----------------- lấy danh sách thuốc trả----------------//
export const getListThuocTra = (filter) => ({
  type: typeAction.GET_LIST_THUOC_TRA,
  filter,
})

//----------------- chỉnh sửa phiếu thu toa thuốc ----------------//
export const putBnTTvaBnCTTTphieuThuToaAction = (
  form,
  listThuoc,
  tongTienNew,
  formHinh,
  resetForm,
) => ({
  type: typeAction.PUT_BNTT_VA_BNCTTT_PHIEUTHU_TOATHUOC,
  form,
  listThuoc,
  tongTienNew,
  formHinh,
  resetForm,
})
//--------------DANH SÁCH THUỐC BAN -------//
export const getDanhSachThuocBan = (filter, setIsLoading, setData) => ({
  type: typeAction.GET_DANH_SACH_THUOC_BAN,
  filter,
  setIsLoading,
  setData,
})

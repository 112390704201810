import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, DatePicker, Select, Table, TreeSelect, Typography } from 'antd'
import { IconButton } from '@mui/material'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { branchService } from '../../../services/branch/branchService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { https } from '../../../services/apiService'
import { phanQuyenService } from '../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../store/actions/phanQuyenAction'
import { formattedNumber } from '../../../utils/formattedNumber'
import { exportExcelformat } from '../../../utils/export/exportExcelFormat'
import ToastCus from '../../../utils/Toast'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD/MM/YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect
const { Text } = Typography
function TabSanKhoa(props) {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [value, setValue] = useState([])
  const [data, setData] = useState([])

  // useEffect(() => {
  //   if (PkDangNhap?.idChuyenKhoa === 2) {
  //     setValue([BranchLogin])
  //   }
  // }, [PkDangNhap])
  const fetchBranch = async (id) => {
    const result = await branchService.getListBranch(id)
    setBranch(result.data.filter((value) => value.idChuyenKhoa === 2))
  }

  const fetchData = async () => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT502',
      )

      let idChiNhanhs = ''
      if (value?.length > 0 && value[0] !== 'all') {
        idChiNhanhs = value.map((id) => `idChiNhanhs=${id}`).join('&')
      } else {
        idChiNhanhs = branch.map((item) => `idChiNhanhs=${item.idChiNhanh}`).join('&')
        setValue(['all'])
      }
      const response = await https.get('/ThongKeThang/ThongKeThangSan', {
        params: {
          idCongTy: company,
          fromDate: dateForm,
          toDate: dateTo,
        },
        paramsSerializer: (params) => {
          return `${idChiNhanhs}&${new URLSearchParams(params).toString()}`
        },
      })
      setData(response.data)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setIsLoading(false)
    }
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT503',
      )

      const formattedData = data?.map((item) => {
        return {
          'Tên Chi Nhánh': item.tenchinhanh,
          'Ngày ': item.ngay ? moment(item.ngay).format('DD/MM/YYYY') : '-',
          'Ngày Khai trương': item.ngaykhaitruong
            ? moment(item.ngaykhaitruong).format('DD/MM/YYYY')
            : '-',
          'Khám sàng lọc tiêm chủng': item.khamsangloctiemchung,
          'Khám phụ khoa': item.khamphukhoa,
          'Khám sản khoa': item.khamsankhoa,
          'Khám hiếm muộn': item.khamhiemmuon,
          'Khám tiền hôn nhân': item.khamtienhonnhan,
          'Khám vú': item.khamvu,
          'Lượt siêu âm / thủ thuật': item.luotsieuamthuthuat,
          'Tái khám phụ khoa': item.taikhamphukhoa,
          'Lượt khách tái khám thẻ': item.luotkhachtaikhamthe,
          'Khách đến khám lần đầu': item.khachdenkhamlandau,
          'Lượt khách tạo thuốc': item.luotkhachtoathuoc,
          'Lượt khách tạo bán lẻ': item.luotkhachtoabanle,
          'Doanh thu khám bệnh': item.doanhthukhambenh,
          'Doanh thu xét nghiệm': item.doanhthuxetnghiem,
          'Doanh thu siêu âm': item.doanhthusieuam,
          'Doanh thu khác': item.doanhthukhac,
          'Doanh thu toa thuốc': item.doanhthutoathuoc,
          'Doanh thu toa bán lẻ': item.doanhthutoabanle,
          'Doanh thu vắc xin': item.doanhthuvacxin,
          'Lợi nhuận bán thuốc': item.loinhuanbanthuoc,
          'Số lượng bán thẻ thành viên phụ khoa': item.soluongbanthethanhvienphukhoa,
          'Số lượng bán thẻ thành viên sản khoa': item.soluongbanthethanhviensankhoa,
          'Số lượng bán thẻ vàng sản khoa': item.soluongbanthevangsankhoa,
          'Số lượng bán thẻ vàng phụ khoa': item.soluongbanthevangphukhoa,
          'Doanh thu thẻ thành viên phụ khoa': item.doanhthuthethanhvienphukhoa,
          'Doanh thu thẻ thành viên sản khoa': item.doanhthuthethanhviensankhoa,
          'Doanh thu thẻ vàng sản khoa': item.doanhthuthevangsankhoa,
          'Doanh thu thẻ vàng phụ khoa': item.doanhthuthevangphukhoa,
          'Tổng doanh thu': item.tongdoanhthu,
          'Thanh toán pos': item.thanhtoanpos,
          'Thanh toán chuyển khoản': item.thanhtoanchuyenkhoan,
          'Tiền mặt': item.tienmat,
          'Thanh toán đối tác': item.thanhtoandoitac,
          'Tổng tiền': item.thanhtoanpos + item.tienmat + item.thanhtoanchuyenkhoan,
        }
      })
      const name = 'thong-ke-san-khoa'
      formattedData?.length && exportExcelformat(formattedData, name)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  const onLoad = () => {}

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
  }, [])

  // useEffect(() => {
  //   fetchData()
  // }, [])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Ngày',
      dataIndex: 'ngay',
      key: 'ngay',
      width: 100,
      fixed: 'left',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      fixed: 'left',
      width: 250,
    },
    {
      title: 'Ngày khai trương',
      dataIndex: 'ngaykhaitruong',
      key: 'ngaykhaitruong',
      width: 150,
      fixed: 'left',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
    },
    {
      title: 'Tổng lượt khách',
      dataIndex: 'tongluotkhach',
      key: 'tongluotkhach',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Khám sàng lọc tiêm chủng',
      dataIndex: 'khamsangloctiemchung',
      key: 'khamsangloctiemchung',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Khám phụ khoa',
      dataIndex: 'khamphukhoa',
      key: 'khamphukhoa',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Khám sản khoa',
      dataIndex: 'khamsankhoa',
      key: 'khamsankhoa',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Khám hiếm muộn',
      dataIndex: 'khamhiemmuon',
      key: 'khamhiemmuon',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Khám tiền hôn nhân',
      dataIndex: 'khamtienhonnhan',
      key: 'khamtienhonnhan',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Khám vú',
      dataIndex: 'khamvu',
      key: 'khamvu',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lượt siêu âm / thủ thuật',
      dataIndex: 'luotsieuamthuthuat',
      key: 'luotsieuamthuthuat',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Tái khám phụ khoa',
      dataIndex: 'taikhamphukhoa',
      align: 'right',
      key: 'taikhamphukhoa',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    // {
    //   title: 'Lượt khách lần đầu thăm',
    //   dataIndex: 'luotkhachlandauthe',
    //   key: 'luotkhachlandauthe',
    //   align: 'right',
    //   width: 150,
    //   render: (text) => formattedNumber(text),
    // },
    {
      title: 'Lượt khách tái khám thẻ',
      dataIndex: 'luotkhachtaikhamthe',
      key: 'luotkhachtaikhamthe',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Khách đến khám lần đầu',
      dataIndex: 'khachdenkhamlandau',
      key: 'khachdenkhamlandau',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lượt khách tạo thuốc',
      dataIndex: 'luotkhachtoathuoc',
      key: 'luotkhachtoathuoc',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lượt khách tạo bán lẻ',
      dataIndex: 'luotkhachtoabanle',
      align: 'right',
      key: 'luotkhachtoabanle',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu khám bệnh',
      dataIndex: 'doanhthukhambenh',
      key: 'doanhthukhambenh',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu xét nghiệm',
      dataIndex: 'doanhthuxetnghiem',
      key: 'doanhthuxetnghiem',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu siêu âm',
      dataIndex: 'doanhthusieuam',
      key: 'doanhthusieuam',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu khác',
      dataIndex: 'doanhthukhac',
      key: 'doanhthukhac',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu toa thuốc',
      dataIndex: 'doanhthutoathuoc',
      key: 'doanhthutoathuoc',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu toa bán lẻ',
      dataIndex: 'doanhthutoabanle',
      key: 'doanhthutoabanle',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu vắc xin',
      dataIndex: 'doanhthuvacxin',
      key: 'doanhthuvacxin',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lợi nhuận bán thuốc',
      dataIndex: 'loinhuanbanthuoc',
      key: 'loinhuanbanthuoc',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Số lượng bán thẻ thành viên phụ khoa',
      dataIndex: 'soluongbanthethanhvienphukhoa',
      key: 'soluongbanthethanhvienphukhoa',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Số lượng bán thẻ thành viên sản khoa',
      dataIndex: 'soluongbanthethanhviensankhoa',
      key: 'soluongbanthethanhviensankhoa',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Số lượng bán thẻ vàng sản khoa',
      dataIndex: 'soluongbanthevangsankhoa',
      key: 'soluongbanthevangsankhoa',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Số lượng bán thẻ vàng phụ khoa',
      dataIndex: 'soluongbanthevangphukhoa',
      key: 'soluongbanthevangphukhoa',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu thẻ thành viên phụ khoa',
      dataIndex: 'doanhthuthethanhvienphukhoa',
      key: 'doanhthuthethanhvienphukhoa',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu thẻ thành viên sản khoa',
      dataIndex: 'doanhthuthethanhviensankhoa',
      key: 'doanhthuthethanhviensankhoa',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu thẻ vàng sản khoa',
      dataIndex: 'doanhthuthevangsankhoa',
      key: 'doanhthuthevangsankhoa',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu thẻ vàng phụ khoa',
      dataIndex: 'doanhthuthevangphukhoa',
      key: 'doanhthuthevangphukhoa',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Tổng doanh thu',
      dataIndex: 'tongdoanhthu',
      key: 'tongdoanhthu',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Thanh toán pos',
      dataIndex: 'thanhtoanpos',
      key: 'thanhtoanpos',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Thanh toán chuyển khoản',
      dataIndex: 'thanhtoanchuyenkhoan',
      key: 'thanhtoanchuyenkhoan',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Tiền mặt',
      dataIndex: 'tienmat',
      key: 'tienmat',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Thanh toán đối tác',
      dataIndex: 'thanhtoandoitac',
      key: 'thanhtoandoitac',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'tongTienNgay',
      key: 'tongTienNgay',
      width: 100,
      align: 'right',
      fixed: 'right',
      render: (_, record) => {
        const total = record.tienmat + record.thanhtoanpos + record.thanhtoanchuyenkhoan
        return formattedNumber(total)
      },
    },
  ]

  return (
    <div>
      <div className="flex gap-5 justify-between border-dotted border-b-0 p-2 ">
        <div className="flex gap-5">
          <div className="w-80">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranch(value)
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-80">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
            {/* <Select
                  onChange={(value) => setIdChiNhanh(value)}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={[
                    { label: 'Tất cả', value: null },
                    ...branch.map(({ idChiNhanh, tenChiNhanh }) => ({
                      label: tenChiNhanh,
                      value: idChiNhanh,
                    })),
                  ]}
                  value={idChiNhanh}
                  className="w-full"
                /> */}
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
              // picker="month"
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
              // picker="month"
            />
          </div>
          <div className="flex gap-3">
            <IconButton onClick={fetchData} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
        </div>
        <div className="font-semibold">
          <Button
            disabled={!data?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 15,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              x: 1500,
              y: 660,
            }}
            locale={{
              emptyText: 'Hãy chọn phòng khám',
            }}
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
            loading={isLoading}
            summary={(pageData) => {
              //             Khám sàng lọc tiêm chủng
              // Khám phụ khoa
              // Khám sản khoa
              // Khám hiếm muộn
              // Khám tiền hôn nhân
              // Khám vú
              // Lượt siêu âm thủ thuật
              // Tái khám phụ khoa
              // Lượt khách lần đầu thăm
              // Lượt khách tái khám thẻ
              // Khách đến khám lần đầu
              // Lượt khách tạo thuốc
              // Lượt khách tạo bán lẻ
              // Doanh thu khám bệnh
              // Doanh thu xét nghiệm
              // Doanh thu siêu âm
              // Doanh thu khác
              // Doanh thu toa thuốc
              // Doanh thu toa bán lẻ
              // Doanh thu vắc xin
              // Lợi nhuận bán thuốc
              // Số lượng bán thẻ thành viên phụ khoa
              // Số lượng bán thẻ thành viên sản khoa
              // Số lượng bán thẻ vàng sản khoa
              // Số lượng bán thẻ vàng phụ khoa
              // Doanh thu thẻ thành viên phụ khoa
              // Doanh thu thẻ thành viên sản khoa
              // Doanh thu thẻ vàng sản khoa
              // Doanh thu thẻ vàng phụ khoa
              // Tổng doanh thu
              // Thanh toán pos
              // Thanh toán chuyển khoản
              // Tiền mặt
              // Thanh toán đối tác
              // let totalkhamsangloctiemchung = 0
              // let totalkhamphukhoa = 0
              // let totalkhamsankhoa = 0
              // let totalkhamhiemmuon = 0
              // let totalkhamtienhonnhan = 0
              // let totalkhamvu = 0
              // let totalluotsieuamthuthuat = 0
              // let totaltaikhamphukhoa = 0
              // let totalluotkhachlandauthe = 0
              // let totalluotkhachtaikhamthe = 0
              // let totalkhachdenkhamlandau = 0
              // let totalluotkhachtoathuoc = 0
              // let totalluotkhachtoabanle = 0
              // let totaldoanhthukhambenh = 0
              // let totaldoanhthuxetnghiem = 0
              // let totaldoanhthusieuam = 0
              // let totaldoanhthukhac = 0
              // let totaldoanhthutoathuoc = 0
              // let totaldoanhthutoabanle = 0
              // let totaldoanhthuvacxin = 0
              // let totalloinhuanbanthuoc = 0
              // let totalsoluongbanthethanhvienphukhoa = 0
              // let totalsoluongbanthethanhviensankhoa = 0
              // let totalsoluongbanthevangsankhoa = 0
              // let totalsoluongbanthevangphukhoa = 0
              // let totaldoanhthuthethanhvienphukhoa = 0
              // let totaldoanhthuthethanhviensankhoa = 0
              // let totaldoanhthuthevangsankhoa = 0
              // let totaldoanhthuthevangphukhoa = 0
              // let totaltongdoanhthu = 0
              // let totalthanhtoanpos = 0
              // let totalthanhtoanchuyenkhoan = 0
              // let totaltienmat = 0
              // let totalthanhtoandoitac = 0
              // let totaltongTienNgay = 0

              // pageData.forEach(
              //   ({
              //     khamsangloctiemchung,
              //     khamphukhoa,
              //     khamsankhoa,
              //     khamhiemmuon,
              //     khamtienhonnhan,
              //     khamvu,
              //     luotsieuamthuthuat,
              //     taikhamphukhoa,
              //     luotkhachlandauthe,
              //     luotkhachtaikhamthe,
              //     khachdenkhamlandau,
              //     luotkhachtoathuoc,
              //     luotkhachtoabanle,
              //     doanhthukhambenh,
              //     doanhthuxetnghiem,
              //     doanhthusieuam,
              //     doanhthukhac,
              //     doanhthutoathuoc,
              //     doanhthutoabanle,
              //     doanhthuvacxin,
              //     loinhuanbanthuoc,
              //     soluongbanthethanhvienphukhoa,
              //     soluongbanthethanhviensankhoa,
              //     soluongbanthevangsankhoa,
              //     soluongbanthevangphukhoa,
              //     doanhthuthethanhvienphukhoa,
              //     doanhthuthethanhviensankhoa,
              //     doanhthuthevangsankhoa,
              //     doanhthuthevangphukhoa,
              //     tongdoanhthu,
              //     thanhtoanpos,
              //     thanhtoanchuyenkhoan,
              //     tienmat,
              //     thanhtoandoitac,
              //     tongTienNgay,
              //   }) => {
              //     totalkhamsangloctiemchung += khamsangloctiemchung
              //     totalkhamphukhoa += khamphukhoa
              //     totalkhamsankhoa += khamsankhoa
              //     totalkhamhiemmuon += khamhiemmuon
              //     totalkhamtienhonnhan += khamtienhonnhan
              //     totalkhamvu += khamvu
              //     totalluotsieuamthuthuat += luotsieuamthuthuat
              //     totaltaikhamphukhoa += taikhamphukhoa
              //     totalluotkhachlandauthe += luotkhachlandauthe
              //     totalluotkhachtaikhamthe += luotkhachtaikhamthe
              //     totalkhachdenkhamlandau += khachdenkhamlandau
              //     totalluotkhachtoathuoc += luotkhachtoathuoc
              //     totalluotkhachtoabanle += luotkhachtoabanle
              //     totaldoanhthukhambenh += doanhthukhambenh
              //     totaldoanhthuxetnghiem += doanhthuxetnghiem
              //     totaldoanhthusieuam += doanhthusieuam
              //     totaldoanhthukhac += doanhthukhac
              //     totaldoanhthutoathuoc += doanhthutoathuoc
              //     totaldoanhthutoabanle += doanhthutoabanle
              //     totaldoanhthuvacxin += doanhthuvacxin
              //     totalloinhuanbanthuoc += loinhuanbanthuoc
              //     totalsoluongbanthethanhvienphukhoa += soluongbanthethanhvienphukhoa
              //     totalsoluongbanthethanhviensankhoa += soluongbanthethanhviensankhoa
              //     totalsoluongbanthevangsankhoa += soluongbanthevangsankhoa
              //     totalsoluongbanthevangphukhoa += soluongbanthevangphukhoa
              //     totaldoanhthuthethanhvienphukhoa += doanhthuthethanhvienphukhoa
              //     totaldoanhthuthevangsankhoa += doanhthuthevangsankhoa
              //     totaldoanhthuthevangphukhoa += doanhthuthevangphukhoa
              //     totaltongdoanhthu += tongdoanhthu
              //     totalthanhtoanpos += thanhtoanpos
              //     totalthanhtoanchuyenkhoan += thanhtoanchuyenkhoan
              //     totaltienmat += tienmat
              //     totalthanhtoandoitac += thanhtoandoitac
              //     totaltongTienNgay += thanhtoanpos + thanhtoanchuyenkhoan + tienmat
              //   },
              // )
              const listColSum = columns?.filter(
                (item) => !['stt', 'ngay', 'tenchinhanh', 'ngaykhaitruong']?.includes(item?.key),
              )
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={4}>
                      <Text strong>Tổng cộng</Text>
                    </Table.Summary.Cell>

                    {listColSum?.map((item, index) => (
                      <>
                        <Table.Summary.Cell
                          index={4 + index}
                          align="right"
                          fixed={item?.dataIndex === 'tongTienNgay'}
                        >
                          <span style={{ color: 'red' }}>
                            {formattedNumber(
                              pageData?.reduce(
                                (tong, itemSum) =>
                                  (tong +=
                                    item?.dataIndex === 'tongTienNgay'
                                      ? (itemSum?.tienmat ?? 0) +
                                        (itemSum?.thanhtoanpos ?? 0) +
                                        (itemSum?.thanhtoanchuyenkhoan ?? 0)
                                      : itemSum[item?.dataIndex] ?? 0),
                                0,
                              ) ?? 0,
                            )}
                          </span>
                        </Table.Summary.Cell>
                      </>
                    ))}
                    {/* <Table.Summary.Cell index={4} align="right">
                      <span style={{ color: 'red' }}>{totalkhamsangloctiemchung}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="right">
                      <span style={{ color: 'red' }}>{totalkhamphukhoa}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="right">
                      <span style={{ color: 'red' }}>{totalkhamsankhoa}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="right">
                      <span style={{ color: 'red' }}>{totalkhamhiemmuon}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="right">
                      <span style={{ color: 'red' }}>{totalkhamtienhonnhan}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="right">
                      <span style={{ color: 'red' }}>{totalkhamvu}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="right">
                      <span style={{ color: 'red' }}>{totalluotsieuamthuthuat}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align="right">
                      <span style={{ color: 'red' }}>{totaltaikhamphukhoa}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12} align="right">
                      <span style={{ color: 'red' }}>{totalluotkhachlandauthe}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13} align="right">
                      <span style={{ color: 'red' }}>{totalluotkhachtaikhamthe}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14} align="right">
                      <span style={{ color: 'red' }}>{totalkhachdenkhamlandau}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15} align="right">
                      <span style={{ color: 'red' }}>{totalluotkhachtoathuoc}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={16} align="right">
                      <span style={{ color: 'red' }}>{totalluotkhachtoabanle}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={17} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthukhambenh)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={18} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totaldoanhthuxetnghiem)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={19} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthusieuam)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={20} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthukhac)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={21} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthutoathuoc)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={22} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthutoabanle)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={23} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthuvacxin)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={24} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totalloinhuanbanthuoc)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={25} align="right">
                      <span style={{ color: 'red' }}>{totalsoluongbanthethanhvienphukhoa}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={26} align="right">
                      <span style={{ color: 'red' }}>{totalsoluongbanthethanhviensankhoa}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={27} align="right">
                      <span style={{ color: 'red' }}>{totalsoluongbanthevangsankhoa}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={28} align="right">
                      <span style={{ color: 'red' }}>{totalsoluongbanthevangphukhoa}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={29} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totaldoanhthuthethanhvienphukhoa)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={30} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totaldoanhthuthethanhviensankhoa)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={31} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totaldoanhthuthevangsankhoa)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={32} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totaldoanhthuthevangphukhoa)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={33} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaltongdoanhthu)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={34} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totalthanhtoanpos)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={35} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totalthanhtoanchuyenkhoan)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={36} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaltienmat)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={37} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totalthanhtoandoitac)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={38} align="right" fixed>
                      <span style={{ color: 'red' }}>{formattedNumber(totaltongTienNgay)}</span>
                    </Table.Summary.Cell> */}
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

TabSanKhoa.propTypes = {}

export default TabSanKhoa

import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { maternityClinicService } from '../../services/maternityClinic/maternityClinicService'
import { childrenClinicService } from '../../services/childrenClinic/childrenClinicService'
import { drugstoreService } from '../../services/drugstore/drugstoreService'
import { recieveService } from '../../services/receive/recieveService'
import { BNChoKhamSan } from '../actions/maternityClinicAction'
import { thuocKeToa } from '../actions/childrenClinicAction'
import moment from 'moment'
import { generatePhieuToaThuoc } from '../../utils/report/phieuToaThuoc'
import { generatePhieuChiDinh } from '../../utils/report/phieuChiDinh'
import { generatePhieuSanLon } from '../../utils/report/san/sanLon'
import { generatePhieuSanNho } from '../../utils/report/san/sanNho'
import { generatePhieuPhuKhoa } from '../../utils/report/san/phuKhoa'
import { generateNhuHoa } from '../../utils/report/san/nhuHoa'
// import { generatePhieuToaThuocTA } from '../../utils/report/phieuToaThuocTA'
import { cardService } from '../../services/card/cardService'
import 'moment/locale/vi'
moment.locale('vi')

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* maternityClinicSaga() {
  // LẤY DANH SÁCH BỆNH NHÂN CHỜ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(typeAction.GET_BN_CHO_KHAM_BY_PK_SAN, function* BNPending({ type, idcnPk }) {
    try {
      const result = yield call(() => maternityClinicService.getBNCho(idcnPk))
      yield put({
        type: typeAction.DISPATCH_BN_CHO_KHAM_SAN,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // LẤY THÔNG TIN CHI TIẾT DANH SÁCH BỆNH NHÂN KHÁM BỆNH
  yield takeLatest(
    typeAction.GET_INFO_DETAIL_BN_KHAM_SAN,
    function* BNPending({ type, idbn, idbnttc }) {
      try {
        // yield put({
        //   type: typeAction.OPEN_LOADING_PAGE,
        // })

        const infoDetailKhamSan = yield call(() =>
          maternityClinicService.getDetailBNKhamSan(idbnttc),
        )
        yield put({
          type: typeAction.DISPATCH_DETAIL_LOAIKHAM_BN_KHAM_SAN,
          payload: infoDetailKhamSan.data,
        })

        // const dataChiNhanh = yield select((state) => state.branchReducer)
        // const { PkDangNhap } = dataChiNhanh

        // const result = yield call(() => recieveService.getInfoBN(idbn))
        // const infoDetail = yield call(() => maternityClinicService.getDetailBNKham(idbnttc))
        // const infoDetailToaThuoc = yield call(() => drugstoreService.getServiceNhaThuoc(idbnttc))
        // const history = yield call(() => recieveService.getHitoryBN(idbn))

        // const infoTTV = yield call(() => cardService.getInfoMemberCard(idbn, PkDangNhap?.idChuyenKhoa))

        // yield put({
        //   type: typeAction.DISPATCH_MEMBER_CARD_KHAM,
        //   payload: infoTTV.data,
        // })

        // yield put({
        //   type: typeAction.DISPATCH_INFO_BN_KHAM_SAN,
        //   payload: result.data,
        // })
        // yield put({
        //   type: typeAction.DISPATCH_DETAIL_BN_KHAM_SAN,
        //   payload: infoDetail.data,
        // })
        // yield put({
        //   type: typeAction.DISPATCH_DETAIL_TOATHUOC_BN_KHAM_SAN,
        //   payload: infoDetailToaThuoc.data,
        // })
        // yield put({
        //   type: typeAction.DISPATCH_HISTORY_KHAM_PK_SAN,
        //   payload: history.data,
        // })
        // yield put({
        //   type: typeAction.CLOSE_LOADING_PAGE,
        // })
      } catch (error) {
        console.log(error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // LẤY THÔNG TIN GỢI Ý KHÁM BỆNH BÁCH SĨ
  yield takeLatest(typeAction.GET_SUGGEST_DOCTOR_SAN, function* suggestDoctor({ type }) {
    try {
      // const chanDoan = yield call(() => maternityClinicService.getChanDoans());
      // yield put({
      //   type: typeAction.DISPATCH_CHAN_DOAN,
      //   payload: chanDoan.data,
      // });
      const loiDan = yield call(() => maternityClinicService.getLoiDan())
      yield put({
        type: typeAction.DISPATCH_LOI_DAN_SAN,
        payload: loiDan.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // LẤY DANH SÁCH THUỐC KÊ TOA TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_THUOC_KE_TOA_DOCTOR_SAN,
    function* thuocKeToaBS({ type, idChiNhanh }) {
      try {
        const result = yield call(() => maternityClinicService.getKetoaThuoc(idChiNhanh))
        const newData = result?.data
          .filter(
            (items) =>
              items.tonKho > 0 &&
              // items?.thuocVatTu?.idNhom !== 169 &&
              items?.thuocVatTu?.idNhom !== 179 &&
              items?.thuocVatTu?.idNhom !== 205,
          ) //không lấy gọng kính trong kho dược)
          .map((items, index) => ({ ...items, ID: index }))
        yield put({
          type: typeAction.DISPATCH_THUOC_KE_TOA_DOCTOR_SAN,
          payload: newData,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
  //---------------- Cập nhật THÔNG TIN KHÁM BỆNH-------------//
  yield takeLatest(
    typeAction.UPDATE_DETAIL_BN_KHAM_SAN,
    function* BNPending({ type, infoBN, formBenhNhan, form, formPhuSan, idcnPk, newArr, isPrint }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        let toaThuoc = []
        const ID_BRANCH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK

        if (form?.lydokolaythuoc) {
          yield call(() => childrenClinicService.deleteBNThuocBSKhongKeToa(form?.idbnttc))
        }
        const result = yield call(
          () =>
            childrenClinicService.updateDetailBN({
              ...form,
              trangthaikhambenh: isPrint ? (newArr?.length > 0 ? 2 : 4) : 1,
            }), // ấn lưu & in đổi ttkb nếu có thuốc = 2, không có thuốc = 4, ấn nút khác thì 1
        )
        yield call(() => childrenClinicService.putInfoBN(formBenhNhan))
        const resultFormPhuSan = yield call(() =>
          maternityClinicService.putDetailBNKhamSan(formPhuSan),
        )
        if (newArr?.length > 0 && !form?.lydokolaythuoc) {
          // const formatData = newArr.map((item) => ({
          //   ...item,
          //   soLanNgay: item.soLanNgay + '',
          //   lieuDung: item.lieuDung + '',
          // }))
          // const toathuoc = yield call(() =>
          //   childrenClinicService.putBNThuocBSKeToaLai(form?.idbnttc, newArr),
          // )
          const toathuoc = yield call(() =>
            childrenClinicService.putToaThuocBsTuLaySoLo(
              form?.idbnttc,
              PkDangNhap?.idChiNhanh,
              newArr,
            ),
          )
          if (isPrint && toathuoc?.data?.length > 0) {
            yield call(() => {
              let listVT = []
              // gộp các dòng khác số lô hạn dùng lại thành 1 dòng
              toathuoc?.data
                ?.sort((a, b) => (a?.stt ?? '').localeCompare(b?.stt ?? ''))
                ?.filter((item) => item?.idnhomthuoc !== 179 && item?.idnhomthuoc !== 205)
                ?.map((item) => {
                  if (listVT?.find((itemVT) => itemVT?.idthuoc === item?.idthuoc)) {
                    listVT?.map(
                      (itemVT) =>
                        itemVT?.idthuoc === item?.idthuoc && (itemVT.soLuong += item.soLuong),
                    )
                  } else {
                    listVT.push({
                      ...item,
                    })
                  }
                })
              // for (const item of toaPrint) {
              //   console.log(item)

              // }
              generatePhieuToaThuoc(infoBN, result.data, listVT, PkDangNhap)
            })
          }
          //const toathuoc = yield call(() => childrenClinicService.postToaThuoc(formatData))
          // toaThuoc = [...toathuoc.data]
          // if (isPrint) {
          //   yield call(() => generatePhieuToaThuoc(infoBN, result.data, toaThuoc, PkDangNhap))
          // }
        }
        yield put(BNChoKhamSan(idcnPk))
        yield put(thuocKeToa(ID_BRANCH_LOGIN))
        yield put({
          type: typeAction.RESET_BS_KHAM_CAPNHAT,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM_SAN,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM_SAN,
          payload: null,
        })
        yield put({
          type: typeAction.RESET_TOA_THUOC_BN_DOCTOR_SAN,
          payload: [],
        })
        //generate
        if (isPrint) {
          switch (formPhuSan.idloaisan) {
            case 1:
              yield call(() => generatePhieuSanLon(result.data, resultFormPhuSan.data, PkDangNhap))
              break
            case 2:
              yield call(() => generatePhieuSanNho(result.data, resultFormPhuSan.data, PkDangNhap))
              break
            case 3:
              yield call(() => generatePhieuPhuKhoa(result.data, resultFormPhuSan.data, PkDangNhap))
              break
            case 4:
              yield call(() => generateNhuHoa(result.data, resultFormPhuSan.data, PkDangNhap))
              break
            default:
              break
          }
        }
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      } catch (error) {
        console.log(error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      }
    },
  )
  // ------------- thêm chỉ định khám bệnh cho bệnh nhân ------------//
  yield takeLatest(
    typeAction.ADD_NEW_CHI_DINH_KHAM_SAN,
    function* thuocKeToaBS({ type, form, idbnttc, tenNV, handleReset, chandoanbandau }) {
      try {
        const result = []
        const data = yield select((state) => state.childrenClinicReducer)
        const dataChiNhanh = yield select((state) => state.branchReducer)
        const dataUser = yield select((state) => state.userReducer)
        const { infoUser } = dataUser
        const { DetailBN } = data
        const { PkDangNhap } = dataChiNhanh
        const today = moment()
        // Lọc form thành ba mảng riêng dựa trên type
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const dvArray = form
          .filter((item) => item.type === 'dv' && !item.old)
          .map((item) => ({
            idbnttc: idbnttc,
            iddichvu: item.idchiDinh,
            dongia: item.dongia,
            ptgiamgia: item?.ptgiamgia ?? 0, /////////////////////////////////////////
            tiengiamgia: item?.tiengiamgia ?? 0, /////////////////////////////////////////
            tienthanhtoan: item?.tienthanhtoan ?? item.dongia, /////////////////////////////////////////

            // tienthanhtoan: item.dongia,
            ngaysudung: today.format('YYYY-MM-DDTHH:mm:ss'),
            trangthaikb: 1,
            trangthaitt: 3,
            idnguoichidinh: infoUser?.idnv,
          }))
        const xnArray = form
          .filter((item) => item.type === 'xn' && !item.old)
          .map((item) => ({
            idbnttc: idbnttc,
            idxn: item.idchiDinh,
            ngaychidinh: today.format('YYYY-MM-DDTHH:mm:ss'),
            bschidinh: tenNV,
            trangthaitt: 3,
            dongia: item.dongia,
            ptgiamgia: item?.ptgiamgia ?? 0, /////////////////////////////////////////
            tiengiamgia: item?.tiengiamgia ?? 0, /////////////////////////////////////////
            tienthanhtoan: item?.tienthanhtoan ?? item.dongia, /////////////////////////////////////////

            //tienthanhtoan: item.dongia,
          }))
        const cdhaArray = form
          .filter((item) => item.type === 'cdha' && !item.old)
          .map((item) => ({
            idbnttc: idbnttc,
            idcdha: item.idchiDinh,
            ngaychidinh: today.format('YYYY-MM-DDTHH:mm:ss'),
            idtrangthaitt: 3,
            dongia: item.dongia,
            ptgiamgia: item?.ptgiamgia ?? 0, /////////////////////////////////////////
            tiengiamgia: item?.tiengiamgia ?? 0, /////////////////////////////////////////
            tienthanhtoan: item?.tienthanhtoan ?? item.dongia, /////////////////////////////////////////

            //tienthanhtoan: item.dongia,
          }))
        if (dvArray.length > 0) {
          const { data } = yield call(() => recieveService.postBNDV(dvArray))
          result.push(...data)
        }
        if (xnArray.length > 0) {
          const { data } = yield call(() => recieveService.postBNXetNghiem(xnArray))
          result.push(...data)
        }
        if (cdhaArray.length > 0) {
          const { data } = yield call(() => recieveService.postBNCDHA(cdhaArray))
          result.push(...data)
        }
        handleReset()
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM_SAN,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM_SAN,
          payload: null,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        yield call(() =>
          generatePhieuChiDinh(infoUser, DetailBN, result, PkDangNhap, chandoanbandau),
        )
        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công!',
        })
      } catch (error) {
        console.log(error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Lưu thất bại!',
        })
      }
    },
  )
  // LẤY DANH SÁCH BỆNH NHÂN ĐANG KHÁM VÀ ĐÃ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_BN_DA_KHAM_BY_PK_SAN,
    function* BNDaKham({ type, idnv, idcn, tuNgay, denNgay }) {
      try {
        const listDangKham = yield call(() =>
          maternityClinicService.getBNDangKham(idnv, idcn, tuNgay, denNgay),
        )
        const listDaKham = yield call(() =>
          maternityClinicService.getBNDaKham(idnv, idcn, tuNgay, denNgay),
        )
        yield put({
          type: typeAction.DISPATCH_BN_DANG_KHAM_SAN,
          payload: listDangKham.data,
        })
        yield put({
          type: typeAction.DISPATCH_BN_DA_KHAM_SAN,
          payload: listDaKham.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )

  // bác sĩ sửa toa đã kê, chọn không kê toa hoặc xóa hết thuốc trong toa đã kê
  yield takeLatest(
    typeAction.DELETE_BN_THUOC_BS_KHONG_KE_TOA_SAN,
    function* deleteBNThuocBSKhongKeToa({ type, idbnttc, idlydokhonglaythuoc }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => maternityClinicService.deleteBNThuocBSKhongKeToa(idbnttc))
        yield call(() =>
          maternityClinicService.putLyDoKhongKeToaBNTTC(idbnttc, idlydokhonglaythuoc),
        )
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM_SAN,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM_SAN,
          payload: null,
        })
        yield put({
          type: typeAction.RESET_TOA_THUOC_BN_DOCTOR_SAN,
          payload: [],
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công!',
        })
      } catch (error) {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Lưu thất bại!',
        })
        console.log(error)
      }
    },
  )
  // bác sĩ sửa toa đã kê, kê lại toa đã lưu
  yield takeLatest(
    typeAction.PUT_BN_THUOC_BS_KE_TOA_LAI_SAN,
    function* updateBNThuocBSKeToaLai({ type, idbnttc, list, infobn, bnttc, pkdangnhap }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => maternityClinicService.putBNThuocBSKeToaLai(idbnttc, list))
        yield call(() => generatePhieuToaThuoc(infobn, bnttc, list, pkdangnhap))
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM_SAN,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM_SAN,
          payload: null,
        })
        yield put({
          type: typeAction.RESET_TOA_THUOC_BN_DOCTOR_SAN,
          payload: [],
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công!',
        })
      } catch (error) {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Lưu thất bại!',
        })
        console.log(error)
      }
    },
  )
}

import React, { useEffect, useState } from 'react'
import { Collapse, DatePicker, Timeline, Button, Divider, Skeleton } from 'antd'
import CachedIcon from '@mui/icons-material/Cached'
import IconButton from '@mui/material/IconButton'
import moment from 'moment'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { historyPaymentCards } from '../../../../store/actions/cardAction'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { generatePhieuThuLeTan } from '../../../../utils/report/phieuThuLeTan'
import PrintIcon from '@mui/icons-material/Print'
import { generatePhieuLichSuGiaoDichBenhNhan } from '../../../../utils/report/phieuLichSuNapTheBenhNhan'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const dateformat = 'DD-MM-YYYY'
const History = () => {
  const dispatch = useDispatch()
  const [dateForm, setDateForm] = useState(now.format())
  const [dateTo, setDateTo] = useState(now.format())
  const [isloading, setIsLoading] = useState(false)
  const { BNPaymentCard, infoPaymentCard, historyPaymentCard } = useSelector(
    (state) => state.cartReducer,
  )
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const setDatePicker = (date, dateString) => {
    setDateForm(moment(dateString, dateformat).format())
  }
  const handleLoadHistory = () => {
    dispatch(
      historyPaymentCards(
        infoPaymentCard?.idThe,
        infoPaymentCard?.idbn,
        dateForm,
        dateTo,
        setIsLoading,
      ),
    )
  }
  useEffect(() => {
    handleLoadHistory()
  }, [infoPaymentCard])

  const handlePrintHistory = () => {
    if (dateForm === 'Invalid date' || !BNPaymentCard) {
      return
    }
    generatePhieuLichSuGiaoDichBenhNhan(BNPaymentCard, historyPaymentCard, dateForm, dateTo)
  }

  return (
    <div>
      <div className="flex gap-2 py-2">
        <div className="flex gap-1 items-center">
          <label className="font-semibold">Từ:</label>
          <DatePicker onChange={setDatePicker} defaultValue={dayjs(dateForm)} format={dateformat} />
        </div>
        <div className="flex gap-1 items-center">
          <label className="font-semibold">Đến:</label>
          <DatePicker defaultValue={dayjs(dateTo)} format={dateformat} />
        </div>
        <div className="flex gap-1">
          <IconButton onClick={handleLoadHistory} aria-label="delete" color="primary" size="small">
            <CachedIcon fontSize="medium" />
          </IconButton>
        </div>
        <div className="flex gap-1 ml-auto">
          {BNPaymentCard && (
            <IconButton onClick={handlePrintHistory} color="primary">
              <PrintIcon />
            </IconButton>
          )}
        </div>
      </div>
      <div className="h-[510px] overflow-auto">
        <Skeleton loading={isloading} active avatar>
          <ul className="flex gap-2 flex-col">
            {historyPaymentCard?.map((items) => (
              <li>
                <Collapse
                  items={[
                    {
                      key: '1',
                      label: (
                        <div className="flex justify-between">
                          <h2 className="font-semibold">
                            {items.tien ? 'Nạp thẻ thanh toán' : 'Thanh toán dịch vụ'}
                            {items.tien ? (
                              <span className="text-green-700">
                                {' '}
                                + {formattedNumber(items.tongTien)} VNĐ
                              </span>
                            ) : (
                              <span className="text-red-700">
                                {' '}
                                - {formattedNumber(items.tienSuDUng)} VNĐ
                              </span>
                            )}
                          </h2>{' '}
                          <p className="font-semibold text-gray-500">
                            {moment(items.ngaySuDung).format('DD/MM/YYYY HH:mm:ss')}
                          </p>
                        </div>
                      ),
                      children: (
                        <>
                          <div className="flex justify-between">
                            <div>
                              <h2 className="text font-semibold">
                                Nhân viên thu: {items.tien ? items.nguoiNap : items.nguoiNhan}
                              </h2>
                              <p className="text-gray-500">Chi nhánh: {items.tenChiNhanh}</p>
                            </div>
                            <h2 className="text font-semibold flex flex-col">
                              Mã giao dịch: {items.idSuDungThe}
                              <Button
                                size="small"
                                type="primary"
                                onClick={() =>
                                  generatePhieuThuLeTan(
                                    {
                                      tien: items.tien,
                                      BNPaymentCard,
                                      infoPaymentCard,
                                      detailHistory: items,
                                    },
                                    PkDangNhap,
                                  )
                                }
                              >
                                In
                              </Button>
                            </h2>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </li>
            ))}
          </ul>
        </Skeleton>
      </div>
    </div>
  )
}

export default History

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DatePicker, Select, ConfigProvider, Table, Button, TreeSelect } from 'antd'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { phanQuyenService } from '../../../../../services/phanQuyen/phanQuyenService'
import { https } from '../../../../../services/apiService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../../store/actions/phanQuyenAction'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import ToastCus from '../../../../../utils/Toast'
import * as XLSX from 'xlsx'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'

const Detail = () => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [idChiNhanh, setIdChiNhanh] = useState(Number(BranchLogin))
  const [branch, setBranch] = useState([])
  const [data, setData] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [value, setValue] = useState([BranchLogin])
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const onLoad = () => {
    fetchReport()
  }
  //-------- export excel-----------//
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT480',
      )

      const worksheet = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
      XLSX.writeFile(wb, 'Lợi nhuận chi tiết.xlsx')
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  //---------- lấy danh sách chi nhánh theo công ty -----------//
  const fetchBranch = async (id) => {
    try {
      const { data } = await https.get(`ChiNhanh/GetAllByIdCTNotVanPhong?idCT=${id}`)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }

  // ------------ lấy danh sách report theo chi nhánh ------------//
  // ThongKeXuatHang/DoanhThuChiTietChiNhanh
  const fetchReport = async () => {
    setIsLoading(true)
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT479',
      )
      let idChiNhanh = null
      if (value?.length > 0 && value[0] !== 'all') {
        idChiNhanh = value.map((id) => `idChiNhanh=${id}`).join('&')
        const { data } = await https.get(
          `ThongKeXuatHang/DoanhThuChiTietChiNhanh?${idChiNhanh}&idCongTy=${company}&fromDate=${dateForm}&toDate=${dateTo}`,
        )
        setData(data)
      } else {
        const { data } = await https.get(
          `ThongKeXuatHang/DoanhThuChiTietChiNhanh?idCongTy=${company}&fromDate=${dateForm}&toDate=${dateTo}`,
        )
        setData(data)
        setValue(['all'])
      }
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setIsLoading(false)
    }
  }
  const COUNT = data.reduce((total, items) => total + items.soluong, 0)
  const PRICE = data.reduce((total, items) => total + items.giavon, 0)
  const MONEY = data.reduce((total, items) => total + items.tienvon, 0)
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
    fetchReport()
  }, [])
  const statis = [
    {
      title: 'TỔNG SỐ THUỐC',
      number: data.length,
    },
    {
      title: 'TỔNG SỐ LƯỢNG',
      number: formattedNumber(COUNT),
    },
    {
      title: 'TỔNG GIÁ VỐN',
      number: formattedNumber(PRICE),
    },
    {
      title: 'TỔNG TIỀN VỐN',
      number: formattedNumber(MONEY),
    },
  ]

  const onChange = (newValue) => {
    setValue(newValue)
    fetchReport(newValue)
    setIdChiNhanh(newValue)
  }

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  return (
    <div>
      <div>
        <ul className="flex border rounded-md">
          {statis.map((items) => (
            <li className="flex flex-col gap-2 w-1/4 border-r p-2">
              <div className="flex justify-between">
                <h2 className="font-semibold text-gray-500 xl:text-base text-xs">{items.title}</h2>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="size-5 text-green-700 border-2 border-green-500  rounded-full "
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <span className="text-lg font-semibold">{formattedNumber(items.number)}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex gap-5 justify-between border-dotted border-b-0 py-2 ">
        <div className="flex gap-5">
          <div className="w-80">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranch(value)
                setIdChiNhanh(value)
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-64 flex gap-1">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="flex gap-3">
            <IconButton onClick={onLoad} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
          <div className="font-semibold ">
            <Button
              disabled={!data?.length}
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>

      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              x: 1000,
              y: 540,
            }}
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={data}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 40,
                align: 'center',

                render: (text, record, index) => ++index,
              },
              {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                width: 150,
                align: 'center',
                render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
              },
              {
                title: 'Chi nhánh',
                dataIndex: 'tenchinhanh',
                key: 'tenchinhanh',
              },
              {
                title: 'Loại phiếu',
                dataIndex: 'loaiphieu',
                key: 'loaiphieu',

                width: 100,
              },
              {
                title: 'Thông tin hàng',
                children: [
                  {
                    title: 'Mã hàng',
                    dataIndex: 'mahang',
                    key: 'mahang',
                    width: 120,
                  },
                  {
                    title: 'Tên hàng',
                    dataIndex: 'tenhang',
                    key: 'tenhang',
                  },
                  {
                    title: 'Đơn vị',
                    dataIndex: 'dvt',
                    key: 'dvt',
                    align: 'center',
                    width: 80,
                  },
                  {
                    title: 'Số lô',
                    dataIndex: 'solo',
                    key: 'solo',
                    width: 120,
                  },
                  {
                    title: 'Hạn dùng',
                    dataIndex: 'handung',
                    key: 'handung',
                    render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
                  },
                ],
              },

              {
                title: 'SL',
                dataIndex: 'soluong',
                key: 'soluong',
                width: 40,
                align: 'center',
                render: (text) => formattedNumber(text),
              },
              {
                title: 'Giá vốn',
                dataIndex: 'giavon',
                key: 'giavon',
                width: 90,
                align: 'right',
                render: (text) => formattedNumber(text),
              },
              {
                title: 'Tiền Vốn',
                dataIndex: 'tienvon',
                key: 'tienvon',
                width: 90,
                align: 'right',
                render: (text) => formattedNumber(text),
              },
            ]}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default Detail

import { Tabs } from 'antd'
import React from 'react'
import TonTongHop from './TonTongHop/TonTongHop'
import TonKho from './TonKho'
import TonFromTo from './TonFromTo/TonFromTo'

const TabsTon = () => {
  return (
    <div className="2xl:p-2 p-2 bg-[#E7E7E7] h-full">
      <div className="bg-white p-2 rounded-lg border h-full">
        <Tabs
          items={[
            {
              label: 'Tổng hợp',
              key: 1,
              children: <TonTongHop />,
            },
            {
              label: 'Chi tiết',
              key: 2,
              children: <TonKho />,
            },
            {
              label: 'Tồn Ngày',
              key: 3,
              children: <TonFromTo />,
            }
          ]}
        />
      </div>
    </div>
  )
}

export default TabsTon

import { https } from '../apiService'

export const doKhucXaService = {
  getBNChoKhamMat: (idcnpk) => https.get(`BenhNhanMat/GetBenhNhanChoKhamMat?idcnpk=${idcnpk}`),
  getBNChoDoKhucXa: (idcn) => https.get(`BenhNhanMat/GetBenhNhanChoDoKhucXa?idcn=${idcn}`),
  getBNDaDoKhucXa: (idcn, tuNgay, denNgay) =>
    https.get(`BenhNhanMat/GetBenhNhanDaDoKhucXa?idcn=${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`),
  getInfoBNDoKhucXa: (idbnttc) =>
    https.get(`BenhNhanMat/GetThongTinBenhNhanDoKhucXa?idbnttc=${idbnttc}`),
  postInfoBNDoKhucXa: (form) => https.post(`BenhNhanMat/AddBenhNhanMat`, form),
  putInfoBNDoKhucXa: (form) => https.put(`BenhNhanMat/UpdateBenhNhanMat`, form),
  putListDVBN: (list) => https.put(`BenhNhanDichVu/UpdateTrangThaiBenhNhanDichVuMat`, list),
  getInfoBN: (idbnttc) => https.get(`BenhNhanTTC/GetByIDBNTTC?id=${idbnttc}`),
  // getListDVTrongKinh: (idnhomdv) => https.get(`DichVu/GetDichVuByIdNhomDV?idNhomDv=${idnhomdv}`),
  getListDVTrongKinh: (idNhomDichVu, idChiNhanh, idct) =>
    https.get(`DichVuChiNhanh/ByNhomAndChiNhanh`, { params: { idNhomDichVu, idChiNhanh, idct } }),
  getListTonGongKinh: (idcn) => https.get(`TonKho/GetGongKinhByChiNhanh?IDChiNhanh=${idcn}`),
  getListDoiTacTK: () => https.get(`DoiTacTrongKinh/GetDoiTacTrongKinh`),
  getListDoiTacTKChiPhi: (iddttk) =>
    https.get(`DoiTacTrongKinh/GetDoiTacTrongKinhChiPhi?iddttk=${iddttk}`),
  postInfoBNTrongKinh: (list) => https.post(`BenhNhanDichVu/AddBenhNhanDichVuTrongKinh`, list),
  getInfoBNTrongVaGongKinh: (idbnttc) =>
    https.get(`TrongKinh/GetThongTinBenhNhanDichVuTrongKinh?idbnttc=${idbnttc}`),
  getListBNDichVu: (idbnttc) =>
    https.get(`BenhNhanDichVu/GetBenhNhanDichVuKhamMat?idbnttc=${idbnttc}`),
  putDoKhucXaKhongKhamMat: (trangthai, idbnttc) =>
    https.put(
      `TrongKinh/UpdateTrangThaiBenhNhanDichVuKhamMat?trangthai=${trangthai}&idbnttc=${idbnttc}`,
    ),
}

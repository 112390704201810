import { Input, Select } from 'antd'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import 'moment/locale/vi'
moment.locale('vi')

const InfoBN = ({}) => {
  const today = moment()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoBNTapVLTL, infoSoLanTapVLTL } = useSelector((state) => state.vltlReducer)
  //   const handleSelected = (value) => {
  //     formik.setFieldValue('donviguimau', value)
  //   }
  return (
    <div className="p-2 border rounded-md">
      <div className="flex gap-2">
        <div className="w-1/2 flex flex-col gap-2">
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Tên BN: </label>
              <Input value={infoBNTapVLTL?.TENBENHNHAN} variant="filled" readOnly />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">Mã BN: </label>
              <Input value={infoBNTapVLTL?.MABENHNHAN} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Địa chỉ: </label>
              <Input value={infoBNTapVLTL?.DIACHI} variant="filled" readOnly />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">SĐT: </label>
              <Input value={infoBNTapVLTL?.DIENTHOAI} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Thực hiện: </label>
              <Input
                value={
                  infoBNTapVLTL
                    ? infoSoLanTapVLTL?.SOLANVLTL <=
                        infoSoLanTapVLTL?.DanhSachBenhNhanVatLyTriLieu?.length &&
                      infoSoLanTapVLTL?.DanhSachBenhNhanVatLyTriLieu?.length > 0
                      ? infoSoLanTapVLTL?.DanhSachBenhNhanVatLyTriLieu[
                          infoSoLanTapVLTL?.SOLANVLTL - 1
                        ]?.nguoitao
                      : infoUser?.tenNV
                    : ''
                }
                variant="filled"
                readOnly
              />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">Ngày: </label>
              <Input
                value={
                  infoBNTapVLTL
                    ? infoSoLanTapVLTL?.SOLANVLTL <=
                        infoSoLanTapVLTL?.DanhSachBenhNhanVatLyTriLieu?.length &&
                      infoSoLanTapVLTL?.DanhSachBenhNhanVatLyTriLieu?.length > 0
                      ? infoSoLanTapVLTL?.DanhSachBenhNhanVatLyTriLieu[
                          infoSoLanTapVLTL?.SOLANVLTL - 1
                        ]?.ngaytap
                        ? moment(
                            infoSoLanTapVLTL?.DanhSachBenhNhanVatLyTriLieu[
                              infoSoLanTapVLTL?.SOLANVLTL - 1
                            ]?.ngaytap,
                          ).format('DD/MM/YYYY')
                        : today.format('DD/MM/YYYY')
                      : today.format('DD/MM/YYYY')
                    : ''
                }
                variant="filled"
                readOnly
              />
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <label className="w-24 font-semibold">Chẩn đoán: </label>
              <Input value={infoBNTapVLTL?.CHANDOAN} variant="filled" readOnly />
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <label className="w-24 font-semibold">Ghi chú: </label>
              <Input value={infoBNTapVLTL?.GHICHU} variant="filled" readOnly />
            </div>
          </div>
        </div>
        <div className="w-1/2 gap-2 flex flex-col">
          <div className="flex gap-2">
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Ngày sinh: </label>
              <Input
                value={
                  infoBNTapVLTL?.NGAYSINH && moment(infoBNTapVLTL?.NGAYSINH).format('DD/MM/YYYY')
                }
                variant="filled"
                readOnly
              />
            </div>
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Giới tính: </label>
              <Input value={infoBNTapVLTL?.GIOITINH} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Đối tượng: </label>
              <Input value={infoBNTapVLTL?.DOITUONG} variant="filled" readOnly />
            </div>
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Mã TCQG: </label>
              <Input value={infoBNTapVLTL?.MATCQG} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Lý do: </label>

              <Input variant="filled" value={infoBNTapVLTL?.LIDO} readOnly />
            </div>
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Lời dặn: </label>
              <Input value={infoBNTapVLTL?.LOIDAN} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex items-center">
            <label className="w-28 font-semibold">Bác sĩ CĐ: </label>
            <Input variant="filled" value={infoBNTapVLTL?.BSCHANDOAN} readOnly />
          </div>
          <div className="flex items-center">
            <label className="w-28 font-semibold">Triệu chứng: </label>
            <Input variant="filled" value={infoBNTapVLTL?.TRIEUCHUNG} readOnly />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoBN

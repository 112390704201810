import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, DatePicker, Input, Select, Table, TreeSelect } from 'antd'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import { SearchOutlined } from '@ant-design/icons'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { branchService } from '../../../../services/branch/branchService'
import { reportService } from '../../../../services/Report/reportService'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import { exportExcelformatSumCol } from '../../../../utils/export/exportExcelFormat'
import { formattedNumber } from '../../../../utils/formattedNumber'
import ToastCus from '../../../../utils/Toast'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

function CongMaiKTV(props) {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState(['all'])
  const [dataReport, setDataReport] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [search, setSearch] = useState('')

  const fetchReport = async () => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT536',
      )
      const { data } = await reportService.getCongMaiKTV(
        search?.length ? search?.trim() : null,
        company,
        value?.includes('all') || !value?.length
          ? branch
              ?.map((item) => `idcn=${item?.idChiNhanh}`)
              ?.join('&')
              ?.toString()
          : value
              ?.map((item) => `idcn=${item}`)
              ?.join('&')
              ?.toString(),
        //value?.includes('all') ? branch?.map((item) => item?.idChiNhanh) : value,
        dateForm,
        dateTo,
      )
      setDataReport(data)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const fetchBranch = async (id) => {
    try {
      setIsLoading(true)
      const result = await branchService.getBranchNotVP(id)
      setBranch(result.data?.filter((item) => item?.idChuyenKhoa === 4))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT537',
      )

      const formatData = dataReport?.map((item, index) => ({
        'STT ': ++index, //item?.ngaykham ? moment(item?.ngaykham).format('DD/MM/YYYY HH:mm:ss') : '-',
        'Chi nhánh': item?.tenchinhanh,
        'Ngày ': item?.ngaychidinh ? moment(item?.ngaychidinh).format('DD/MM/YYYY HH:mm:ss') : '-',
        'Tên KTV': item?.nguoichidinh,
        'Tên bệnh nhân': item?.tenbenhnhan,
        'Mã DV': item?.madichvu,
        'Tên dịch vụ': item?.tendichvu,
        'SL ': 1,
        'ĐVT ': item?.donvitinh,
        'Đơn giá': formattedNumber(item?.dongia ?? 0),
        'Thanh toán': formattedNumber(item?.thanhtien ?? 0),
        'Công mài': formattedNumber(item?.tiengiacong ?? 0),
      }))
      const name = 'TỔNG DỊCH VỤ CÔNG MÀI CHUYÊN KHOA MẮT '
      if (formatData?.length)
        exportExcelformatSumCol(formatData, name, ['SL ', 'Thanh toán', 'Công mài'])
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  const onLoad = () => {
    setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
    if (!value || !value?.length) setValue(['all'])
    fetchReport()
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
  }, [])
  useEffect(() => {
    if (branch?.length) fetchReport()
  }, [branch])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      fixed: 'left',
      width: 250,
    },
    {
      title: 'Ngày',
      dataIndex: 'ngaychidinh',
      key: 'ngaychidinh',
      width: 140,
      align: 'center',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
    },
    {
      title: 'Tên KTV',
      dataIndex: 'nguoichidinh',
      key: 'nguoichidinh',
      width: 220,
    },
    {
      title: 'Tên bệnh nhân',
      dataIndex: 'tenbenhnhan',
      key: 'tenbenhnhan',
      width: 220,
    },
    {
      title: 'Mã DV',
      dataIndex: 'madichvu',
      key: 'madichvu',
      width: 120,
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'tendichvu',
      key: 'tendichvu',
    },
    {
      title: 'SL',
      dataIndex: 'soluong',
      key: 'soluong',
      align: 'center',
      width: 50,
      render: () => 1,
    },
    {
      title: 'ĐVT',
      dataIndex: 'donvitinh',
      key: 'donvitinh',
      align: 'center',
      width: 80,
    },
    {
      title: 'Đơn giá',
      dataIndex: 'dongia',
      key: 'dongia',
      align: 'right',
      width: 100,
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: 'Thanh toán',
      dataIndex: 'thanhtien',
      key: 'thanhtien',
      align: 'right',
      fixed: 'right',
      width: 100,
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: 'Công mài',
      dataIndex: 'tiengiacong',
      key: 'tiengiacong',
      align: 'right',
      fixed: 'right',
      width: 100,
      render: (text) => formattedNumber(text ?? 0),
    },
  ]

  return (
    <>
      <div className="flex gap-2">
        <div className="w-[17rem] flex items-center gap-2">
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-lg">-</span>
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>
        <div className="w-[17rem]">
          <Select
            showSearch
            value={company}
            onChange={(value) => {
              setCompany(value)
              fetchBranch(value)
              setValue(['all'])
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className="w-full"
          />
        </div>
        <div className="w-[17rem] flex gap-2">
          <TreeSelect
            {...tProps}
            style={{ flexWrap: 'unset' }}
            className="w-full h-8 overflow-clip"
          />
        </div>
        <div className="flex w-64">
          <Input
            value={search}
            placeholder="Nhập tên KTV..."
            onChange={(e) => setSearch(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </div>
        <div className="flex gap-2">
          <IconButton
            disabled={isLoading}
            onClick={onLoad}
            color="primary"
            aria-label="delete"
            size="small"
          >
            <SyncIcon />
          </IconButton>
        </div>
        <div className="font-semibold ">
          <Button
            disabled={isLoading || !dataReport?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <div className="mt-2">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              x: 2000,
              y: '68vh',
            }}
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={dataReport}
            columns={columns}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell className="text-center" index={7}>
                    <span className="font-semibold">{dataReport?.length}</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}></Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right" index={9}>
                    <span className="font-semibold">
                      {formattedNumber(
                        //đơn giá
                        dataReport?.reduce((tong, item) => (tong += item?.dongia), 0),
                      )}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right" index={10}>
                    <span className="font-semibold">
                      {formattedNumber(
                        //thành tiền
                        dataReport?.reduce((tong, item) => (tong += item?.thanhtien), 0),
                      )}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right" index={11}>
                    <span className="font-semibold">
                      {formattedNumber(
                        //thành tiền
                        dataReport?.reduce((tong, item) => (tong += item?.tiengiacong), 0),
                      )}
                    </span>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

CongMaiKTV.propTypes = {}

export default CongMaiKTV

import { Tabs } from 'antd'
import React from 'react'
import History from './History'
import Images from './Images'
const ImageSA = ({ image, setImage, listImagePrint, setListImagePrint }) => {
  return (
    <div className="p-2 min-h-[400px]">
      <p className="text-red-500 text-lg">*Số hình giới hạn trên phiếu in: 8</p>
      <Tabs
        items={[
          {
            key: 1,
            label: 'Hình ảnh',
            children: (
              <Images
                image={image}
                setImage={setImage}
                listImagePrint={listImagePrint}
                setListImagePrint={setListImagePrint}
              />
            ),
          },
          {
            key: 2,
            label: 'Lịch sử khám',
            children: <History setListImagePrint={setListImagePrint} />,
          },
        ]}
      />
    </div>
  )
}

export default ImageSA

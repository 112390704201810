import JsBarcode from 'jsbarcode'
import moment from 'moment'
import axios from 'axios'
import { generateLogo } from '../../algorithm'
import { jsPDFCus } from '../../jsPdfAddFont'
import { splitLongWord, splitLongWordFitWidth } from '../../splitWordForReport'
import 'moment/locale/vi'
moment.locale('vi')

function parseColor(style) {
  const match = style.match(/color-rgb\((\d+),(\d+),(\d+)\)/)
  if (match) {
    return {
      r: parseInt(match[1]),
      g: parseInt(match[2]),
      b: parseInt(match[3]),
    }
  }
  return null
}
const fetchAndConvertImage = async (url) => {
  try {
    const response = await axios.get(url, { responseType: 'blob' })
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        const imgElement = document.createElement('img')
        imgElement.src = event.target.result
        imgElement.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = imgElement.width
          canvas.height = imgElement.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(imgElement, 0, 0)
          const pngDataUrl = canvas.toDataURL('image/png')
          resolve(pngDataUrl)
        }
        imgElement.onerror = reject
      }
      reader.onerror = reject
      reader.readAsDataURL(response.data)
    })
  } catch (error) {
    console.error('Error fetching and converting image:', error)
  }
}

const calculateTextHeight = (doc, text, pageWidth, lineSpacing = 4) => {
  let totalHeight = 0

  if (!text) return totalHeight

  const lines = text.split(/\r?\n/).map((line) => line.trim())
  let processedLines = []

  lines.forEach((line) => {
    if (line.length > 80) {
      const wordsArray = splitLongWordFitWidth(doc, line, pageWidth - 20)
      processedLines = processedLines.concat(wordsArray)
    } else {
      processedLines.push(line)
    }
  })

  // Calculate total height
  totalHeight = processedLines.length * lineSpacing

  // Add initial title height
  totalHeight += 5 // Height for "III. Kết luận" + spacing

  return totalHeight
}

export const generatePhieuSieuAmMultiImage = async (ttBN, listImagePrint, PkDangNhap) => {
  const maxPictureAvailable = listImagePrint.length > 8 ? listImagePrint.slice(0, 8) : listImagePrint
  const doc = jsPDFCus('p', 'mm', 'a4')
  let yPos = 10 //mm
  let xPos = 10 //mm
  const pageWidth = doc.internal.pageSize.width
  const pageHeight = doc.internal.pageSize.height
  //header
  generateLogo(doc, xPos, PkDangNhap)
  //barcode
  JsBarcode('#barcode', `TTD${ttBN.mabenhnhan}`, {
    displayValue: false,
  })

  const canvas = document.getElementById('barcode')
  // Convert canvas to an image
  const barcodeDataURL = canvas.toDataURL()
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 50, 5, 40, 15) // Adjust position and size as needed
  doc.setFontSize(11)
  doc.text(`Ref.ID: TTD${ttBN.mabenhnhan}`, pageWidth - 50, 22)
  doc.setFont('times', 'bold')
  doc.setFontSize(16)
  doc.setTextColor(255, 0, 0)
  doc.text(`${ttBN.tieude ? ttBN.tieude.toUpperCase() : ''}`, doc.internal.pageSize.width / 2, 30, {
    align: 'center',
  })
  doc.setTextColor(0)
  doc.setFontSize(12)
  doc.setFont('times', 'bold')
  doc.text('I. Thông tin bệnh nhân', xPos, 35)
  doc.text(ttBN.tenbenhnhan, xPos + doc.getTextDimensions('Họ tên: ').w, 40)
  doc.setFont('times', 'regular')
  doc.text('Họ tên:', xPos, 40)
  doc.text(
    `Năm sinh: ${moment(ttBN.ngaysinh).format('YYYY')}      Giới tính: ${ttBN.gioitinh}`,
    pageWidth - 90,
    40,
  )
  yPos = 40
  yPos += 5
  const diaChiBenhNhan = `Địa chỉ: ${ttBN.diachi ?? ''}${ttBN.tenphuong ? ', ' + ttBN.tenphuong : ''}${ttBN.tenquan ? ', ' + ttBN.tenquan : ''}${ttBN.tentinh ? ', ' + ttBN.tentinh : ''}`
  if (doc.getTextWidth(diaChiBenhNhan) > 120) {
    const wordsArray = splitLongWordFitWidth(doc, diaChiBenhNhan, pageWidth - 20)
    for (let index = 1; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 4
    }
  } else {
    doc.text(diaChiBenhNhan, xPos, yPos)
    yPos += 4
  }
  yPos += 1
  const chanDoanBenhNhan = `Chẩn đoán: ${ttBN.chandoansobo ?? ''}`
  if (doc.getTextWidth(chanDoanBenhNhan) > 120) {
    const wordsArray = splitLongWordFitWidth(doc, chanDoanBenhNhan, pageWidth - 15)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 4
    }
  } else {
    doc.text(`${chanDoanBenhNhan}`, xPos, yPos)
    yPos += 4
  }
  yPos += 1
  doc.text(`Chỉ định: ${ttBN.loaisieuam ?? ''}`, xPos, yPos)
  yPos += 5
  doc.text(`Bác sĩ chỉ định: ${ttBN.bschidinh ?? ''}`, xPos, yPos)
  yPos += 5
  //list image
  doc.text('HÌNH ẢNH / IMAGES', doc.internal.pageSize.width / 2, yPos, {
    align: 'center',
  })
  yPos += 5

  let widthImage = 80
  let heightImage = 55

  if (maxPictureAvailable.length > 6) {
    widthImage = 80
    heightImage = 50
  }

  for (let i = 0; i < maxPictureAvailable.length; i += 2) {
    // Fetch and convert both images concurrently
    const [imageToPNG, secondImageToPNG] = await Promise.all([
      fetchAndConvertImage(maxPictureAvailable[i].base64),
      i + 1 < maxPictureAvailable.length
        ? fetchAndConvertImage(maxPictureAvailable[i + 1].base64)
        : Promise.resolve(null),
    ])
    // Add first image
    if (imageToPNG) {
      doc.addImage(imageToPNG, 'JPEG', xPos + 10, yPos, widthImage, heightImage)
    }

    // Add second image if it exists
    if (secondImageToPNG) {
      doc.addImage(secondImageToPNG, 'JPEG', xPos + 95, yPos, widthImage, heightImage)
    }

    yPos += heightImage + 5
  }

  //add new page
  doc.addPage()
  yPos = 10
  generateLogo(doc, xPos, PkDangNhap)
  //barcode
  JsBarcode('#barcode', `TTD${ttBN.mabenhnhan}`, {
    displayValue: false,
  })
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 50, 5, 40, 15) // Adjust position and size as needed
  doc.setFontSize(11)
  doc.text(`Ref.ID: TTD${ttBN.mabenhnhan}`, pageWidth - 50, 22)
  doc.line(10, 25, pageWidth - 10, 25)
  yPos = 25

  yPos += 5
  // HTML string to add
  doc.setFont('times', 'bold')
  doc.text('II. Mô tả', xPos, yPos)
  yPos += 5

  const formatRaw = JSON.parse(ttBN.bieumau)
  const rawHTML = formatRaw?.blocks ?? []
  function splitTextIntoLines(doc, text, maxWidth) {
    const lines = text.split('\n')
    const result = []
    lines.forEach((line) => {
      if (doc.getTextWidth(line) <= maxWidth) {
        result.push(line)
      } else {
        result.push(...splitLongWordFitWidth(doc, line, maxWidth))
      }
    })
    return result
  }

  //replace \t with ' '
  rawHTML.forEach((element) => {
    const tabWidth = 4 // Assuming 1 tab = 4 spaces (common convention)
    const spacesPerTab = tabWidth * ' '.length
    // Replace tabs with spaces for rendering
    element.text = element.text.replace(/\t/g, ' '.repeat(spacesPerTab))
  })

  doc.setFont('times', 'regular')
  for (let i = 0; i < rawHTML.length; i++) {
    const element = rawHTML[i]

    // Apply styles based on inlineStyleRanges
    if (element.inlineStyleRanges.length > 0) {
      element.inlineStyleRanges.forEach((range) => {
        switch (range.style) {
          case 'BOLD':
            doc.setFont('times', 'bold')
            break
          case 'ITALIC':
            doc.setFont('times', 'italic')
            break
          default:
            // Check for color styles
            if (range.style.toLowerCase().includes('color')) {
              //use color
              const color = parseColor(range.style)
              if (color) {
                doc.setTextColor(color.r, color.g, color.b)
              }
            } else {
              doc.setFont('times', 'regular')
              doc.setTextColor(0, 0, 0)
            }

            break
          // Add more cases for other styles as needed
        }
      })
    } else {
      // Reset to regular style if no inline styles are present
      doc.setFont('times', 'regular')
      doc.setTextColor(0) // Reset text color to black
    }
    const lines = splitTextIntoLines(doc, element.text, pageWidth - 20)
    for (let j = 0; j < lines.length; j++) {
      if (yPos > 290) {
        doc.addPage()
        yPos = 10
        generateLogo(doc, xPos, PkDangNhap)
        //barcode
        JsBarcode('#barcode', `TTD${ttBN.mabenhnhan}`, {
          displayValue: false,
        })

        const canvas = document.getElementById('barcode')
        // Convert canvas to an image
        const barcodeDataURL = canvas.toDataURL()
        doc.addImage(barcodeDataURL, 'PNG', pageWidth - 50, 5, 40, 15) // Adjust position and size as needed
        doc.setFontSize(11)
        doc.text(`Ref.ID: TTD${ttBN.mabenhnhan}`, pageWidth - 50, 22)
        doc.line(10, 25, pageWidth - 10, 25)
        yPos = 30
      }
      doc.text(lines[j], xPos + 5, yPos)
      yPos += 4
    }
    yPos += 1
    doc.setFont('times', 'regular')
  }
  //check after render image

  const ketLuan = ttBN.ketluan ? ttBN.ketluan.trim() : ''
  const textHeight = calculateTextHeight(doc, ketLuan, pageWidth)
  // Check if need new page
  if (yPos + textHeight + 20 > pageHeight - 10) {
    doc.addPage()
    yPos = 10
    generateLogo(doc, xPos, PkDangNhap)
    //barcode
    JsBarcode('#barcode', `TTD${ttBN.mabenhnhan}`, {
      displayValue: false,
    })

    const canvas = document.getElementById('barcode')
    // Convert canvas to an image
    const barcodeDataURL = canvas.toDataURL()
    doc.addImage(barcodeDataURL, 'PNG', pageWidth - 50, 5, 40, 15) // Adjust position and size as needed
    doc.setFontSize(11)
    doc.text(`Ref.ID: TTD${ttBN.mabenhnhan}`, pageWidth - 50, 22)
    doc.line(10, 25, pageWidth - 10, 25)
    yPos = 30
  }

  doc.setFont('times', 'bold')
  doc.text('III. Kết luận', xPos, yPos)
  yPos += 5
  if (ketLuan) {
    const ketLuanLines = ketLuan.split(/\r?\n/).map((line) => line.trim())
    let processedLines = []
    ketLuanLines.forEach((line) => {
      if (line.length > 80) {
        const wordsArray = splitLongWordFitWidth(doc, line, pageWidth - 20)
        processedLines = processedLines.concat(wordsArray)
      } else {
        processedLines.push(line)
      }
    })
    processedLines.forEach((line) => {
      doc.text(line, xPos, yPos)
      yPos += 4
    })
  }
  yPos += 4
  doc.setFont('times', 'regular')
  if (yPos > 265) {
    doc.addPage()
    yPos = 10
    generateLogo(doc, xPos, PkDangNhap)
    //barcode
    JsBarcode('#barcode', `TTD${ttBN.mabenhnhan}`, {
      displayValue: false,
    })

    const canvas = document.getElementById('barcode')
    // Convert canvas to an image
    const barcodeDataURL = canvas.toDataURL()
    doc.addImage(barcodeDataURL, 'PNG', pageWidth - 50, 5, 40, 15) // Adjust position and size as needed
    doc.setFontSize(11)
    doc.text(`Ref.ID: TTD${ttBN.mabenhnhan}`, pageWidth - 50, 22)
    doc.line(10, 25, pageWidth - 10, 25)
    yPos = 30
  }
  const dateText = `Ngày ${moment(ttBN.ngaysieuam).format('DD')} tháng ${moment(ttBN.ngaysieuam).format('MM')} năm ${moment(ttBN.ngaysieuam).format('YYYY')}`
  const doctorText = ttBN.bssieuam ?? ''
  const dateTextWidth = doc.getTextWidth(dateText)
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateTextX = pageWidth - dateTextWidth - 25 // 25 is the padding from the right edge

  // Calculate the center point of the date text
  const dateCenterX = dateTextX + dateTextWidth / 2

  // Position the doctor text centered below the date text
  const doctorTextX = dateCenterX - doctorTextWidth / 2

  doc.text(dateText, dateTextX, yPos)
  yPos += 5
  doc.text('Bác sĩ chuyên khoa', pageWidth - 63, yPos)
  doc.setFont('times', 'bold')
  doc.text(doctorText, doctorTextX, yPos + 20)

  doc.setFont('times', 'italic')
  doc.setFontSize(10)
  doc.line(10, pageHeight - 5, pageWidth - 10, pageHeight - 5)
  doc.text('(*) Đem theo phiếu này khi tái khám.', xPos, pageHeight - 2)
  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}

import { https } from '../apiService'

export const ICD10Service = {
  getAllChuongs: () => https.get('ChanDoan/GetAllChuongChanDoan'),
  getIC10ByIdChuong: (keyword, idchuong, page, pageSize) =>
    https.get('ChanDoan/GetListICD10ByIDchuongAndKeyword', {
      params: {
        keyword,
        idchuong,
        page,
        pageSize,
      },
    }),
}

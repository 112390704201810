import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, DatePicker, Input, Select, Table, TreeSelect } from 'antd'
import { IconButton } from '@mui/material'
import { SearchOutlined } from '@ant-design/icons'
import SyncIcon from '@mui/icons-material/Sync'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { https } from '../../../../services/apiService'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import { formatNumber } from 'devextreme/localization'
import { debounce } from 'lodash'
import * as XLSX from 'xlsx'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

function HoaHongSan(props) {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [branch, setBranch] = useState([])
  const [value, setValue] = useState(['all'])
  const [data, setData] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [search, setSearch] = useState('')

  const fetchReport = async (company, dateForm, dateTo, value, keyword) => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT516',
      )
      let idChiNhanhs = ''
      if (Array.isArray(value) && value?.length > 0 && value[0] !== 'all') {
        // Ensure value is an array before mapping
        idChiNhanhs = value.map((id) => `&idcn=${id}`).join('&')
        const { data } = await https.get(
          `BenhNhanDichVu/GetHoaHongThuThuatSan?idct=${company}${idChiNhanhs}&tuNgay=${dateForm}&denNgay=${dateTo}&keyword=${keyword}`,
        )
        setData(data)
      } else {
        const { data } = await https.get(
          `BenhNhanDichVu/GetHoaHongThuThuatSan?idct=${company}&tuNgay=${dateForm}&denNgay=${dateTo}&keyword=${keyword}`,
        )
        setData(data)
        setValue(['all'])
      }
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const fetchBranch = async (id) => {
    try {
      const { data } = await https.get(`ChiNhanh/GetChiNhanhSan?idCT=${id}`)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch, index) => ({
        title: branch?.tenchinhanh,
        value: branch?.idchinhanh,
        key: `${branch?.idchinhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
    onLoad(company, dateForm, dateTo, value, search)
  }, [])

  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT517',
      )

      const headers = [
        'Chi nhánh',
        'Ngày thực hiện',
        'Bác sĩ thực hiện',
        'Tên dịch vụ',
        'Số lượng',
        'Đơn giá',
        'Thanh toán',
        'Giá vật tư',
        'Thành tiền',
        'Hoa hồng',
      ]
      const formattedData = data.map((item) => ({
        'Chi nhánh': item.tenchinhanh,
        'Ngày thực hiện': item.ngaythuchien ? moment(item.ngaythuchien).format('DD/MM/YYYY') : '',
        'Bác sĩ thực hiện': item.nguoithuchien,
        'Tên dịch vụ': item.tendichvu,
        'Số lượng': item.soluong ?? 0,
        'Đơn giá': item.dongia ?? 0,
        'Thanh toán': item.thanhtoan ?? 0,
        'Giá vật tư': item.giavattu ?? 0,
        'Thành tiền': item.thanhtien ?? 0,
        'Hoa hồng': item.hoahong ?? 0,
      }))
      //last row total
      const totalRow = {
        'Chi nhánh': '',
        'Ngày thực hiện': '',
        'Bác sĩ thực hiện': '',
        'Tên dịch vụ': '',
        'Số lượng': data.reduce((acc, val) => acc + val.soluong, 0),
        'Đơn giá': data.reduce((acc, val) => acc + val.dongia, 0),
        'Thanh toán': data.reduce((acc, val) => acc + val.thanhtoan, 0),
        'Giá vật tư': data.reduce((acc, val) => acc + val.giavattu, 0),
        'Thành tiền': data.reduce((acc, val) => acc + val.thanhtien, 0),
        'Hoa hồng': data.reduce((acc, val) => acc + val.hoahong, 0),
      }
      const worksheet = XLSX.utils.json_to_sheet(formattedData.concat(totalRow), {
        header: headers,
      })
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
      XLSX.writeFile(wb, 'Hoa hồng thủ thuật - Sản.xlsx')
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  const onLoad = (company, dateForm, dateTo, value, search) => {
    fetchReport(company, dateForm, dateTo, value, search)
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 60,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      width: 220,
    },
    {
      title: 'Ngày thực hiện',
      dataIndex: 'ngaythuchien',
      key: 'ngaythuchien',
      width: 150,
      align: 'center',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : ''),
    },
    {
      title: 'BS thực hiện',
      dataIndex: 'nguoithuchien',
      key: 'nguoithuchien',
      width: 250,
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'tendichvu',
      key: 'tendichvu',
    },
    {
      title: 'Số lượng',
      dataIndex: 'soluong',
      key: 'soluong',
      width: 80,
      align: 'center',
    },
    {
      title: 'Đơn giá',
      dataIndex: 'dongia',
      key: 'dongia',
      width: 120,
      align: 'right',
      render: (text) => (text ? formatNumber(text) : 0),
    },
    {
      title: 'Thanh toán',
      dataIndex: 'thanhtoan',
      key: 'thanhtoan',
      width: 120,
      render: (text) => (text ? formatNumber(text) : 0),
      align: 'right',
    },
    {
      title: 'Giá vật tư',
      dataIndex: 'giavattu',
      key: 'giavattu',
      width: 120,
      render: (text) => (text ? formatNumber(text) : 0),
      align: 'right',
    },
    {
      title: 'Thành tiền',
      dataIndex: 'thanhtien',
      key: 'thanhtien',
      width: 120,
      render: (text) => (text ? formatNumber(text) : 0),
      align: 'right',
    },
    {
      title: 'Hoa hồng',
      dataIndex: 'hoahong',
      key: 'hoahong',
      width: 120,
      render: (text) => (text ? formatNumber(text) : 0),
      align: 'right',
    },
  ]

  const renderFooter = (total, index) => {
    switch (index) {
      case 5:
        return total.soluong
      case 6:
        return formatNumber(total.dongia)
      case 7:
        return formatNumber(total.thanhtoan)
      case 8:
        return formatNumber(total.giavattu)
      case 9:
        return formatNumber(total.thanhtien)
      case 10:
        return formatNumber(total.hoahong)
      default:
        return ''
    }
  }

  const handleSearchInput = (e, company, dateForm, dateTo, chinhanhs) => {
    const { value } = e.target
    debounceSearch(value.trim(), company, dateForm, dateTo, chinhanhs)
    setSearch(value)
  }

  const debounceSearch = useCallback(
    debounce((keyword, company, dateForm, dateTo, value) => {
      fetchReport(company, dateForm, dateTo, value, keyword)
    }, 500),
    [],
  )

  return (
    <>
      <div className="p-2 bg-[#EFEFEF] flex flex-col gap-2">
        <div className=" p-4 w-full bg-white rounded-md flex gap-5">
          <div className="w-72">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranch(value)
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-64 flex gap-2">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-2">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="flex w-64">
            <Input
              value={search}
              placeholder="Nhập tên bác sĩ"
              onChange={(e) => handleSearchInput(e, company, dateForm, dateTo, value)}
              prefix={<SearchOutlined />}
            />
          </div>
          <div className="flex gap-2">
            <IconButton
              onClick={() => onLoad(company, dateForm, dateTo, value, search)}
              color="primary"
              aria-label="delete"
              size="small"
            >
              <SyncIcon />
            </IconButton>
          </div>
          <div className="font-semibold ">
            <Button
              disabled={!data?.length}
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
        <div className="p-4 w-full h-full bg-white rounded-md flex gap-5">
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
                fontSize: 14,
              },
              components: {
                Table: {
                  headerBorderRadius: 0,
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <div>
              <Table
                scroll={{
                  x: 1500,
                  y: '65vh',
                }}
                pagination={false}
                loading={isLoading}
                bordered
                dataSource={data}
                columns={columns}
                summary={(pageData) => {
                  const total = {
                    soluong: data.reduce((total, { soluong }) => total + (soluong || 0), 0),
                    dongia: data.reduce((total, { dongia }) => total + (dongia || 0), 0),
                    thanhtoan: data.reduce((total, { thanhtoan }) => total + (thanhtoan || 0), 0),
                    giavattu: data.reduce((total, { giavattu }) => total + (giavattu || 0), 0),
                    thanhtien: data.reduce((total, { thanhtien }) => total + (thanhtien || 0), 0),
                    hoahong: data.reduce((total, { hoahong }) => total + (hoahong || 0), 0),
                  }
                  const cells = Array.from({ length: 11 }, (_, index) => (
                    <Table.Summary.Cell
                      key={index}
                      align={index === 5 ? 'center' : 'right'}
                      index={index}
                    >
                      <p className="font-semibold">{renderFooter(total, index)}</p>
                    </Table.Summary.Cell>
                  ))
                  return (
                    <Table.Summary fixed="bottom">
                      <Table.Summary.Row>{cells}</Table.Summary.Row>
                    </Table.Summary>
                  )
                }}
              />
            </div>
          </ConfigProvider>
        </div>
      </div>
    </>
  )
}

HoaHongSan.propTypes = {}

export default HoaHongSan

import React, { useEffect } from 'react'
import * as typeAction from '../../../../store/constants/constants'
import { Table, ConfigProvider } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { CloseOutlined } from '@ant-design/icons'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import ToastCus from '../../../../utils/Toast'
const columns = [
  {
    title: 'STT',
    dataIndex: 'STT',
    width: 35,
    align: 'center',
  },
  {
    title: 'Tên dịch vụ',
    dataIndex: 'TENDV',
    width: 250,
  },
  {
    title: 'Thành tiền ',
    dataIndex: 'THANHTIEN',
    width: 90,
    align: 'right',
  },
  {
    title: '% Giảm',
    dataIndex: 'GIAMGIA',
    width: 60,
    align: 'center',
  },
  {
    title: 'Tiền giảm',
    dataIndex: 'TIENGIAM',
    width: 90,
    align: 'right',
  },
  {
    title: 'Thanh toán',
    dataIndex: 'THANHTOAN',
    width: 90,
    align: 'right',
    fixed: 'right',
  },
  {
    title: '',
    dataIndex: 'action',
    align: 'center',
    width: 30,
    fixed: 'right',
  },
]

const TablePay = ({ newArr }) => {
  const { discountArr, infoChuongTrinhGiamGiaDoiTac } = useSelector((state) => state.receiveReducer)
  const tongThanhTien = newArr.reduce((tong, item) => tong + item.donGia, 0)
  const tongGiam = newArr.reduce((tong, item) => tong + item.tienGiam, 0)
  const tongThanhToan = newArr.reduce((tong, item) => tong + item.thanhToan, 0)
  const dispatch = useDispatch()
  const deleteTypeChiDinh = (cd) => {
    if (discountArr?.length > 0 || infoChuongTrinhGiamGiaDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng bỏ voucher hoặc đối tác trước xóa chỉ định!',
      })
      return
    }
    dispatch({
      type: typeAction.DELETE_CHI_DINH_BY_TYPE,
      payload: {
        type: cd.type,
        id: cd.idChiDinh,
      },
    })
  }
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
  
        }}
      >
        <Table
          columns={columns}
          pagination={false}
          scroll={{ y: 180 }}
          dataSource={newArr.map((items, index) => ({
            STT: ++index,
            TENDV: items?.tenChiDinh,
            THANHTIEN: formatNumberVND(items?.donGia ?? 0),
            GIAMGIA: items?.ptGiam ?? 0,
            TIENGIAM: formatNumberVND(items?.tienGiam ?? 0),
            THANHTOAN: formatNumberVND(items?.thanhToan ?? 0),
            action: (
              <span
                className="text-red-500 cursor-pointer"
                onClick={() => {
                  deleteTypeChiDinh(items)
                }}
              >
                <CloseOutlined />
              </span>
            ),
          }))}
          bordered
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell className="text-right" index={2}>
                  <span className="font-semibold">{formatNumberVND(tongThanhTien)}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell className="text-center" index={3}></Table.Summary.Cell>
                <Table.Summary.Cell className="text-right" index={4}>
                  <span className="font-semibold">{formatNumberVND(tongGiam)}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell className="text-right" index={5}>
                  <span className="font-semibold">{formatNumberVND(tongThanhToan)}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell className="text-center" index={6}></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </ConfigProvider>
    </>
  )
}

export default TablePay

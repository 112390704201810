import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  phieuThu: null,
  DetailTT: null,
  InfoBN: null,
  BNTTC: null,
  memberCard: null,
  paymentCard: null,
  ChiDinh: null,
  ArrXN: [],
  ArrKB: [],
  ArrCĐHA: [],
  discountArr: [],
  infoChuongTrinhGiamGiaDoiTac: null,
  infoBNcuaPT: null,
  tongGiam: 0,
  tongThanhToan: 0,
  phieuDaThu: [],
}
const cashierReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_SET_CHUONGTRINH_GIAMGIA_DOITAC_CASHIER:
        draft.infoChuongTrinhGiamGiaDoiTac = payload
        break
      case typeAction.DISPATCH_LIST_PHIEU_THU:
        draft.phieuThu = payload
        break
      case typeAction.DISPATCH_SET_ARR_XN_CASHIER:
        draft.ArrXN = payload
        break
      case typeAction.DISPATCH_SET_ARR_KB_CASHIER:
        draft.ArrKB = payload
        break
      case typeAction.DISPATCH_SET_ARR_CDHA_CASHIER:
        draft.ArrCĐHA = payload
        break
      case typeAction.DISPATCH_CASHIER_INFO_BN:
        draft.InfoBN = payload
        break
      case typeAction.DISPATCH_CASHIER_BN_TTC:
        draft.BNTTC = payload
        break
      case typeAction.DISPATCH_CASHIER_BN_TTC_PT:
        draft.infoBNcuaPT = payload
        break
      // case typeAction.DISPATCH_CASHIER_SERVICE:
      //   draft.service = payload;
      //   break;
      case typeAction.DISPATCH_DETAIL_PAYMENT_CASHIER:
        draft.DetailTT = payload
        break
      case typeAction.DISPATCH_PAYMENT_CARD_CASHIER:
        draft.paymentCard = payload
        break
      case typeAction.DISPATCH_MEMBER_CARD_CASHIER:
        draft.memberCard = payload
        break
      case typeAction.DISTPATCH_LIST_KB_CASHIER:
        draft.tongGiam += payload.tiengiamgia
        draft.tongThanhToan += payload.tienthanhtoan
        draft.ArrKB.push(payload)
        break
      case typeAction.DISTPATCH_LIST_XN_CASHIER:
        draft.tongGiam += payload.tiengiamgia
        draft.tongThanhToan += payload.tienthanhtoan
        draft.ArrXN.push(payload)
        break
      case typeAction.DISTPATCH_LIST_CDHA_CASHIER:
        draft.tongGiam += payload.tiengiamgia
        draft.tongThanhToan += payload.tienthanhtoan
        draft.ArrCĐHA.push(payload)
        break
      case typeAction.DISTPATCH_LIST_KB_CASHIER_APPLY_MEMBER_CARD:
        // draft.ArrKB.push(payload)
        // let Arr = [...draft.ArrKB] // thêm chỉ định mới sau khi giảm giá
        // const newArr = Arr.sort((a, b) => { return a.trangthaitt - b.trangthaitt }); // sắp xếp danh sách chỉ định trên phiếu theo trạng thái thanh toán
        // draft.ArrKB = newArr
        draft.tongGiam += payload.tiengiamgia
        draft.tongThanhToan += payload.tienthanhtoan

        // Loại thẻ dịch vụ (idLoaiDV === loaithedichvu) - xài chung cho kb + xn + cdha (1 - dv khám bệnh, 2 - dv xét nghiệm, 3 - dv cdha)
        if (payload.loaithedichvu === 1) {
          draft.ArrKB.push(payload)
          //draft.ArrKB.push({ ...tempArr, type: 'KB', })
        } else if (payload.loaithedichvu === 2) {
          draft.ArrXN.push(payload)
          //draft.ArrXN.push({ ...tempArr, type: 'XN', })
        } else if (payload.loaithedichvu === 3) {
          draft.ArrCĐHA.push(payload)
          //draft.ArrCĐHA.push({ ...tempArr, type: 'CDHA', })
        }
        break
      // case typeAction.DELETE_CHI_DINH_CASHIER:
      //   break
      case typeAction.UPDATE_NEW_PRICE_CHI_DINH_CASHIER:
        draft.discountArr = payload
        break
      case typeAction.DISPATCH_TONG_GIAM_CASHIER:
        draft.tongGiam += payload
        break
      case typeAction.DISPATCH_TONG_THANH_TOAN_CASHIER:
        draft.tongThanhToan += payload
        break
      case typeAction.DISPATCH_SET_TONG_GIAM_CASHIER:
        draft.tongGiam = 0
        break
      case typeAction.DISPATCH_SET_TONG_THANH_TOAN_CASHIER:
        draft.tongThanhToan = 0
        break
      case typeAction.DISPATCH_LOAD_TONG_GIAM_CASHIER:
        draft.tongGiam = payload
        break
      case typeAction.DISPATCH_LOAD_TONG_THANH_TOAN_CASHIER:
        draft.tongThanhToan = payload
        break
      case typeAction.DISPATCH_LIST_PHIEU_DA_THU:
        draft.phieuDaThu = payload
        break
      case typeAction.RESET_PHIEUTHU_CASHIER:
        draft.DetailTT = null
        draft.infoBNcuaPT = null
        draft.BNTTC = null
        draft.InfoBN = null
        draft.ArrKB = []
        draft.ArrXN = []
        draft.ArrCĐHA = []
        draft.tongGiam = 0
        draft.tongThanhToan = 0
        draft.paymentCard = null
        draft.memberCard = null
        draft.discountArr = []
        draft.infoChuongTrinhGiamGiaDoiTac = null
        break // reset phiếu thu đang xem
      case typeAction.RESET_INFO_BN_CUA_PT:
        draft.infoBNcuaPT = null
        break
      case typeAction.RESET_MEMBER_CARD:
        draft.memberCard = null
        break
      case typeAction.RESET_ARR_XN:
        draft.ArrXN = []
        break
      case typeAction.RESET_ARR_CDHA:
        draft.ArrCĐHA = []
        break
      case typeAction.RESET_ARR_KB:
        draft.ArrKB = []
        break
      case typeAction.RESET_TONG_GIAM:
        draft.tongGiam = 0
        break
      case typeAction.RESET_TONG_THANH_TOAN:
        draft.tongThanhToan = 0
        break
      case typeAction.RESET_BNTTC:
        draft.BNTTC = null
        break
      case typeAction.RESET_INFO_BN:
        draft.InfoBN = null
        break
      case typeAction.RESET_DISCOUNT_ARR:
        draft.discountArr = []
        break
      case typeAction.RESET_INFO_CHUONG_TRINH_GIAM_GIA_DOI_TAC:
        draft.infoChuongTrinhGiamGiaDoiTac = null
        break
      case typeAction.RESET_PHIEU_DA_THU:
        draft.phieuDaThu = []
        break
      case typeAction.RESET_DETAIL_TT:
        draft.DetailTT = null
        break
      default:
        return state
    }
  })
}

export default cashierReducer

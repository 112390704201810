import { call, put, takeLatest,select } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { khachhangCRMService } from '../../services/khachhangCRM/khachhangCRMService'
import ToastCus from '../../utils/Toast'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { ICD10Service } from '../../services/ICD10/ICD10Service'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* IDC10Saga() {
  yield takeLatest(typeAction.GET_ALL_CHUONG_ICD10, function* getAllChuongs({ type }) {
    try {
      const { data } = yield call(() => ICD10Service.getAllChuongs())
      yield put({
        type: typeAction.DISPATCH_GET_ALL_CHUONG,
        payload: data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  yield takeLatest(
    typeAction.GET_ICD10_BY_CHUONG,
    function* getICD10ByChuongs({ type, keyword, idchuong, page, pageSize, reset,setTotalPages }) {
      const { IDC10s } = yield select((state) => state.IDC10Reducer)
      yield put({
        type: typeAction.OPEN_LOADING_TABLE,
      })
      try {      
        const {data} = yield call(() =>
          ICD10Service.getIC10ByIdChuong(keyword, idchuong, page, pageSize),
        )
        setTotalPages(data?.totalPages === 0 ? 1 : data.totalPages)
        yield put({
          type: typeAction.DISPATCH_ICD10_BY_IDCHUONG,
          payload: reset ? data : { ...data, data: [...IDC10s?.data, ...data.data] },
        })
      } catch (err) {
        console.log(err)
      }finally{
        yield put({
          type: typeAction.CLOSE_LOADING_TABLE,
        })
      }
    },
  )
}

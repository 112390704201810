import { Input } from 'antd'
import moment from 'moment'
import 'moment/locale/vi'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NhapKhoService } from '../../../../services/NhapKho/NhapKhoService'
import { branchService } from '../../../../services/branch/branchService'
import CustomInput from './CustomInput'
import InputHook from './InputHook'
import { inputData } from '../../NhapKho/Modal/Data'
moment.locale('vi')
const InfoPhieu = ({ formik }) => {
  const { infoPTNhap } = useSelector((state) => state.NhapKhoReducer)
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const [idBranch, setIdBranch] = useState(null)
  const [branch, setBranch] = useState([])
  const [kho, setKho] = useState([])

  //----------fetch chi nhánh------------//
  const fetchBranhchByIdCompany = async () => {
    const { data } = await branchService.getListBranch(ID_COMPANY)
    setBranch(data)
  }
  //--------------fetch kho chi nhanh ----------//
  const fetchKhoChiNhanh = async (id) => {
    try {
      const { data } = await NhapKhoService.getListKhoNhap(id)
      setKho(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setIdBranch(infoPTNhap?.idChiNhanhNhan)
    fetchKhoChiNhanh(infoPTNhap?.idChiNhanhNhan)
  }, [infoPTNhap])
  useEffect(() => {
    fetchBranhchByIdCompany()
  }, [])
  return (
    <>
      <div className="border-b p-2 flex justify-between items-center">
        <h2 className="font-semibold text-lg">Thông tin</h2>
        <p className=" text-blue-500 font-semibold px-2 rounded-md  text-center">
          {infoPTNhap?.tenTrangThai}
        </p>
      </div>
      <form className="text-start flex flex-col gap-2 p-2">
        {inputData.map((item) => (
          <div className="flex">
            <InputHook defaultValue={infoPTNhap?.[item.name]} label={item.label} type={item.type} />
          </div>
        ))}
        <div className="flex">
          <InputHook defaultValue={infoPTNhap?.tenChiNhanhNhan} label={'Chi nhánh'} type={'text'} />
        </div>
        <div className="flex">
          <InputHook
            defaultValue={infoPTNhap?.tenKhoNhap}
            label={
              <>
                <span className="text-red-500">(*) </span>Kho nhận
              </>
            }
            type={'text'}
          />
        </div>
        <div className="flex">
          <CustomInput
            formik={formik}
            id="TenPhieu"
            defaultValue={infoPTNhap?.tenPhieu}
            show={true}
            name="TenPhieu"
            type="text"
            label="Tên phiếu"
          />
        </div>
        <div className="flex">
          <label className="font-semibold text-gray-500 w-1/3">Phương thức:</label>

          <Input
            variant="borderless"
            readOnly
            name="TenPhieu"
            className="w-2/3"
            size="small"
            value={infoPTNhap?.idPhuongThuc === 1 ? 'Thanh toán' : 'Công nợ'}
          />
        </div>
        <div className="flex">
          <label className="font-semibold text-gray-500 w-1/3">Hình thức</label>

          <Input
            variant="borderless"
            readOnly
            name="TenPhieu"
            className="w-2/3"
            size="small"
            value={infoPTNhap?.idHinhThuc === 1 ? 'Tiền mặt' : 'Chuyển khoản'}
          />
        </div>
        <div className="flex">
          {/* <span className="text-red-500">(*)</span> */}
          <CustomInput
            formik={formik}
            id="SoHoaDon"
            defaultValue={infoPTNhap?.soHoaDon}
            show={true}
            name="SoHoaDon"
            type="text"
            label={
              <>
                {' '}
                <span className="text-red-500">(*) </span>Số hoá đơn
              </>
            }
          />
        </div>
        <div className="flex">
          <label className="font-semibold text-gray-500 w-1/3">Ngày hoá đơn</label>

          <Input
            variant="borderless"
            readOnly
            name="TenPhieu"
            className="w-2/3"
            size="small"
            value={
              infoPTNhap?.ngayHoaDon ? moment(infoPTNhap?.ngayHoaDon).format('DD/MM/YYYY') : ''
            }
          />
        </div>
        <div className="flex">
          <label className="font-semibold text-gray-500 w-1/3">Link hoá đơn</label>

          <a
            href={infoPTNhap?.linkHoaDon}
            className="w-2/3 text-blue-500 underline truncate"
            target="_blank"
            rel="noopener noreferrer"
          >
            {infoPTNhap?.linkHoaDon}
          </a>
        </div>
        <div className="flex">
          <label className="font-semibold text-gray-500 w-1/3">Ghi chú</label>
          <Input.TextArea
            variant="borderless"
            readOnly
            name="GhiChu"
            onChange={formik.handleChange}
            value={infoPTNhap?.ghiChu}
            size="small"
            className="w-2/3"
          />
        </div>
      </form>
    </>
  )
}

export default InfoPhieu

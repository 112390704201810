import * as typeAction from '../constants/constants'

export const createNewKyhang = (form, resetForm) => ({
  type: typeAction.CREATE_KY_HANG_HOA,
  form,
  resetForm,
})
export const getKyHangs = () => ({
  type: typeAction.GET_KY_HANG_HOA,
})

export const postKyHang = (form) => ({
  type: typeAction.ADD_KY_HANG_HOA,
  form,
})

export const getTonKiemKe = (form) => ({
  type: typeAction.GET_TON_HANG_KIEM_KE,
  form,
})
export const updateProductKiemKe = (form) => ({
  type: typeAction.UPDATE_PRODUCT_KIEM_KE,
  form,
})
import React, { useEffect,useState } from "react";
import LayoutApp from "../../../HOCs/LayoutApp";
import { Button, Table,Tooltip } from "antd";
import { PlusOutlined,ContainerOutlined,DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { listAllCompanyAction } from "../../../store/actions/companyAction";
const columns = [
  {
    title: "STT",
    dataIndex: "STT",
    key: "STT",
    width:40,
    align:'center'
  },
  {
    title: "Mã công ty ",
    dataIndex: "mact",
    key: "mact",
  },
  {
    title: "Tên công ty ",
    dataIndex: "tenct",
    key: "tenct",
  },
  {
    title: "Địa chỉ",
    dataIndex: "diachi",
    key: "diachi",
  },
  {
    title: "SĐT",
    dataIndex: "dienthoai",
    key: "dienthoai",
  },
  {
    title: "Fax",
    dataIndex: "fax",
    key: "fax",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Website",
    dataIndex: "website",
    key: "website",
  },
  {
    title: "Hành động",
    dataIndex: "action",
    key: "action",
    width: 120,
  },
];
const Company = () => {
  const dispatch = useDispatch();
  const { listCompany } = useSelector((state) => state.companyReducer);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    dispatch(listAllCompanyAction());
  }, []);
  return (
    <>
      <div className="p-5">
        <div>
          <Button type="primary" icon={<PlusOutlined />}>
            Tạo công ty
          </Button>
        </div>
        <div className="mt-5">
          <Table
            columns={columns}
            dataSource={listCompany?.map(
              ({diachi, mact, tenct, dienthoai, fax, email, website },index) => ({
                STT:++index,
                diachi,
                mact,
                tenct,
                dienthoai,
                fax,
                email,
                website,
                action: <ul className="flex justify-around">
                <li>
                  <Tooltip title="Xem" color="#108ee9">
                    <ContainerOutlined
                    //   onClick={showModal}
                      className="text-xl text-[#108ee9]  cursor-pointer"
                    />
                  </Tooltip>
                </li>
                <li>
                  <Tooltip title="Xoá" color="red">
                    <DeleteOutlined
                    //   onClick={showModal}
                      className="text-xl text-red-500  cursor-pointer"
                    />
                  </Tooltip>
                </li>
              </ul>
              })
            )}
          />
        </div>
      </div>
    </>
  );
};

export default Company;

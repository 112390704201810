import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteOutlined } from '@ant-design/icons'
import { Select, notification, Table, ConfigProvider, Input, Divider, Tag } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Button from '@mui/material/Button'
import Price from './Price/Price'
import InfoBN from './InfoBN/InfoBN'
import PayModal from './Modal/PayModal'
import CreateCustomer from '../../common/CreateCustomers/CreateCustomer'
import { searchBN_Action } from '../../../store/actions/receiveAction'
import { childrenClinicService } from '../../../services/childrenClinic/childrenClinicService'
import { recieveService } from '../../../services/receive/recieveService'
import { phanQuyenService } from '../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../store/actions/phanQuyenAction'
import { formatNumber } from 'devextreme/localization'
import * as typeAction from '../../../store/constants/constants'
import ToastCus from '../../../utils/Toast'
import _ from 'lodash'
import moment from 'moment'

import 'moment/locale/vi'
moment.locale('vi')
const BanLe = () => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { BNThuoc, infoVC } = useSelector((state) => state.drugstoreReducer)
  const { listBN } = useSelector((state) => state.receiveReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [api, contextHolder] = notification.useNotification()
  const [ThuocVT, setThuocVT] = useState([])
  const [thuocBN, setThuocBN] = useState([])
  const [idPhuongThuc, setIdPhuongThuc] = useState(1)
  const [InfoBNTTC, setInfoBNTTC] = useState({ nguoinha: null, dienthoainguoinha: null })
  const [isModal, setIsModal] = useState(false)
  const [xuatHD, setXuatHD] = useState(null)
  const [infoBN, setInfoBN] = useState(null)
  const [tabLayThuoc, setTabLayThuoc] = useState(1)
  const [ghiChu, setGhiChu] = useState(null)
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        dispatch(searchBN_Action(keyword?.trim(), ID_COMPANY))
      }
    }, 300),
    [],
  )
  //---------- show modal ---------
  const showModalPay = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT261',
      )
      if (
        (benhNhanTT?.hoaDonDienTu && !benhNhanTT?.ghiChuXuatHoaDon) ||
        (xuatHD?.hoadondientu && !xuatHD?.ghichuxuathoadon)
      ) {
        ToastCus.fire({
          icon: 'error',
          title: 'Vui lòng kiểm tra lại!',
        })
        return
      }
      setIsModal(true)
    } catch (error) {
      setIsModal(false)
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  // ----------cancel modal ---------
  const cancelModal = () => {
    setIsModal(false)
  }
  const openNotificationWithIcon = (message, description) => {
    api.warning({
      message,
      description,
    })
  }
  //---------------- lấy danh thông tin bệnh nhân -----------
  const fetchInfoBN = async (id) => {
    try {
      const result = await recieveService.getInfoBN(id)
      setInfoBN(result.data)
      dispatch({
        type: typeAction.DISPATCH_INFO_VOUCHER_BANTOA,
        payload: null,
      })
    } catch (error) {
      console.log(error)
    }
  }
  // ---------  lây danh sách thuôc vật tư -----------//
  const fetchThuoc = async () => {
    const result = await childrenClinicService.getKetoaThuoc(ID_BRANCH)

    const newData = result.data
      .filter((items) => items.tonKho > 0)
      .map((items, index) => ({ ...items, ID: index, SL: 1 }))
    setThuocVT(newData)
  }
  const onChangThuoc = async (value) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT260',
      )

      if (infoVC) {
        ToastCus.fire({
          icon: 'error',
          title: 'Vui lòng hủy áp dụng voucher!',
        })
        return
      }
      if (thuocBN.some((items) => value === items.ID)) {
        openNotificationWithIcon('Thêm thuốc vật tư', ' Thuốc này đã được chọn!')
      } else {
        const index = ThuocVT.findIndex((items) => items.ID === value)
        setThuocBN((preState) => [...preState, ThuocVT[index]])
      }
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  //--------- xứ lý xố lượng thuốc -----------//
  const handleCount = (e, ID) => {
    if (infoVC) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng hủy áp dụng voucher!',
      })
      return
    }
    const { value } = e.target
    const index = thuocBN.findIndex((items) => items.ID === ID)
    const parsedValue = parseFloat(value)
    if (parseFloat(value) < 1 || value.includes('.') || value === '' || value === null) {
      setThuocBN((preState) =>
        preState.map((item, idx) => (idx === index ? { ...item, SL: 1 } : item)),
      )
      return
    }
    if (parsedValue > thuocBN[index].tonKho) {
      openNotificationWithIcon('Thêm số lượng', 'Số lượng tồn kho không đủ!')
    } else {
      setThuocBN((preState) =>
        preState.map((item, idx) => (idx === index ? { ...item, SL: value } : item)),
      )
    }
  }
  const onClickCreateCustomer = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT259',
      )

      dispatch({
        type: typeAction.OPEN_MODAL_CREATE_CUSTOMER,
      })
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  //-----------delete khỏi mãng ---------
  const deleteThuocVatTu = (ID) => {
    if (infoVC) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng hủy áp dụng voucher!',
      })
      return
    }
    setThuocBN((preState) => preState.filter((item) => item.ID !== ID))
  }
  //---------- tổng tiền -----------//
  const tongGiaTien = thuocBN.reduce(
    (accumulator, currentItem) => accumulator + currentItem.SL * currentItem.thuocVatTu?.giaBan,
    0,
  )
  //---------- reset --------------//
  const resetForm = () => {
    cancelModal()
    setThuocBN([])
    setInfoBNTTC({ nguoinha: null, dienthoainguoinha: null })
    // setXuatHD(1)
    setIdPhuongThuc(1)
    setInfoBN()
    setXuatHD(null)
    fetchThuoc()
    setGhiChu(null)
  }
  const dataBNTTC = {
    idbn: infoBN?.idbn,
    ...InfoBNTTC,
    idcn: PkDangNhap?.idChiNhanh,
    idct: PkDangNhap?.idCongTy,
    idchuyenkhoa: PkDangNhap?.idChuyenKhoa,
  }
  // form BN thanh toán
  const benhNhanTT = {
    // idbnttc: 0,
    idPhuongThuc: idPhuongThuc,
    hoaDonDienTu: xuatHD?.hoadondientu,
    ghiChuXuatHoaDon: xuatHD?.hoadondientu ? xuatHD?.ghichuxuathoadon : null,
    tienThanhToan: tongGiaTien,
    // ngayThanhToan: '2024-06-15T08:49:16.207Z',
    // thuNgan: infoUser?.tenNV,
    thuNgan: infoUser?.tenNV,
    idThuNgan: infoUser?.idnv,
    idloaitt: 5,
    tienThuoc: tongGiaTien,
    idCaLamViec: infoUser?.dangNhap.idCaLamViec,
    idcn: PkDangNhap?.idChiNhanh,
    idct: PkDangNhap?.idCongTy,
    idchuyenkhoa: PkDangNhap?.idChuyenKhoa,
  }
  useEffect(() => {
    fetchThuoc()
  }, [])
  return (
    <>
      {contextHolder}
      <div className="flex border rounded-lg h-[50rem]">
        <div className="border-r w-2/3">
          <div className="p-2">
            <div className="flex">
              <Select
                placeholder="Nhập tên thuốc"
                showSearch
                allowClear
                onChange={onChangThuoc}
                filterOption={(input, option) => {
                  const label = option?.label ?? ''
                  const tenBietDuoc = option?.tenBietDuoc ?? ''
                  const searchText = input.toLowerCase()
                  return (
                    label.toLowerCase().includes(searchText) ||
                    tenBietDuoc.toLowerCase().includes(searchText)
                  )
                }}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                value={null}
                className="w-full mr-2"
                options={ThuocVT?.map((items) => ({
                  key: items.ID,
                  label: items.thuocVatTu.tenHoatChat,
                  tenBietDuoc: items.thuocVatTu.tenBietDuoc,
                  value: items.ID,
                  ton: items.tonKho,
                  dvt: items.thuocVatTu.dvt,
                  hanDung: items.hanDung,
                }))}
                dropdownRender={(menu) => (
                  <div>
                    <div
                      className="flex bg-gray-100"
                      style={{ padding: '8px', fontWeight: 'bold' }}
                    >
                      <span className="w-3/6 text-center ">Tên thuốc</span>
                      <span className="w-1/6 text-center">Hạn dùng</span>
                      <span className="w-1/6 text-center">Tồn</span>
                      <span className="w-1/6 text-center">Đơn vị tính</span>
                    </div>
                    <div style={{ marginBottom: '8px' }}>{menu}</div>
                  </div>
                )}
                optionLabelProp="label"
                optionRender={(options) => (
                  <ul className="flex">
                    <li className="w-3/6 ">
                      <p className="font-semibold w-full text-pretty">{options.data.tenBietDuoc}</p>
                      <span className="text-xs w-full text-gray-500"> ({options.data.label})</span>
                    </li>
                    <li className="w-1/6 border-x flex items-center justify-center">
                      {moment(options.data.hanDung).format('DD/MM/YYYY')}
                    </li>
                    <li className="w-1/6 border-r  flex items-center justify-center">
                      {options.data.ton}
                    </li>
                    <li className="w-1/6  flex items-center justify-center">{options.data.dvt}</li>
                  </ul>
                )}
              />
            </div>
          </div>
          <div className="h-[47.5rem]">
            {/* //h-[55%] border-b*/}
            <div className="h-[100%] p-2 pt-0 ">
              <ConfigProvider
                theme={{
                  token: { padding: 5 },
                  components: {
                    Table: {
                      rowHoverBg: '#ecf0f1',
                      headerBg: '#e6e6e6',
                      footerBg: '#e6e6e6',
                      borderColor: '#BABABA',
                    },
                  },
                }}
              >
                <Table
                  bordered
                  pagination={false}
                  scroll={{ y: 375 }}
                  dataSource={thuocBN}
                  columns={[
                    {
                      title: 'STT',
                      dataIndex: 'stt',
                      key: 'stt',
                      render: (text, record, index) => ++index,
                      width: 40,
                      align: 'center',
                    },
                    {
                      title: 'Tên hàng',
                      dataIndex: 'stt',
                      key: 'stt',
                      render: (text, record, index) => record.thuocVatTu.tenBietDuoc,
                    },
                    {
                      title: 'Số lô',
                      dataIndex: 'soLo',
                      key: 'soLo',
                      width: 100,
                      align: 'center',
                    },
                    {
                      title: 'Hạn dùng',
                      dataIndex: 'hanDung',
                      key: 'hanDung',
                      width: 90,
                      align: 'center',
                      render: (text, record, index) =>
                        text ? moment(text).format('DD/MM/YYYY') : '-',
                    },
                    {
                      title: 'Đơn vị',
                      dataIndex: 'stt',
                      key: 'stt',
                      align: 'center',
                      render: (text, record, index) => record.thuocVatTu.dvt,
                      width: 70,
                    },
                    {
                      title: 'Số lượng',
                      dataIndex: 'SL',
                      key: 'SL',
                      align: 'center',
                      render: (text, record, index) => (
                        <Input
                          size="small"
                          value={text}
                          type="Number"
                          min={1}
                          onChange={(e) => handleCount(e, record.ID)}
                        />
                      ),
                      width: 80,
                    },
                    {
                      title: 'Tồn',
                      dataIndex: 'tonKho',
                      key: 'tonKho',
                      width: 65,
                      align: 'center',
                    },
                    {
                      title: 'Đơn giá',
                      dataIndex: 'stt',
                      key: 'stt',
                      render: (text, record, index) => formatNumber(record.thuocVatTu.giaBan),
                      width: 100,
                      align: 'right',
                    },
                    {
                      title: 'Tổng tiền',
                      dataIndex: 'stt',
                      key: 'stt',
                      render: (text, record, index) =>
                        formatNumber(record.thuocVatTu.giaBan * record.SL),
                      width: 100,
                      align: 'right',
                    },
                    {
                      title: '',
                      dataIndex: 'action',
                      key: 'action',
                      render: (text, record, index) => (
                        <DeleteOutlined
                          onClick={() => deleteThuocVatTu(record.ID)}
                          className="text-red-500"
                          style={{ fontSize: 20 }}
                        />
                      ),
                      width: 40,
                      align: 'center',
                    },
                  ]}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
        <div className="w-1/3 ">
          <div className="p-2">
            <div className="flex gap-2">
              <Select
                onSearch={debounceDropDown}
                value={null}
                onChange={fetchInfoBN}
                filterOption={false}
                notFoundContent={null}
                placeholder="Tìm kiếm Tên BN, Mã BN, SĐT, CCCD, Mã TTV..."
                suffixIcon={<SearchOutlined className=" " />}
                options={listBN?.map((items) => ({
                  label: items.tenbenhnhan,
                  value: items.idbn,
                  MaBN: items.mabenhnhan,
                  ngaySinh: items.ngaysinh ? moment(items.ngaysinh).format('DD/MM/YYYY') : '',
                  tinh: items.tentinhtp,
                  quan: items.tenquanhuyen,
                  xa: items.tenphuongxa,
                  gioiTinh: items.gioitinh,
                  diaChi: items.diachi,
                }))}
                optionRender={(options) => (
                  <ul className="border-b pb-2">
                    <li>
                      <div className="flex justify-between w-full">
                        <p className="font-semibold">
                          {options.data.label} - {options.data.MaBN}
                        </p>
                        <p> Ngày sinh: {options.data.ngaySinh} </p>
                      </div>

                      <i className="text-gray-500 text-wrap">
                        Địa chỉ: {options.data.diaChi}
                        {options.data.xa && `, ${options.data.xa}`}
                        {options.data.quan && `, ${options.data.quan}`}
                        {options.data.tinh && `, ${options.data.tinh}`}
                      </i>
                    </li>
                  </ul>
                )}
                showSearch
                allowClear
                className="w-full"
              />
              <button
                onClick={onClickCreateCustomer}
                className="group cursor-pointer outline-none hover:rotate-90 duration-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  className="stroke-sky-400 fill-none group-hover:fill-sky-100 "
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    strokeWidth="1.5"
                  ></path>
                  <path d="M8 12H16" strokeWidth="1.5"></path>
                  <path d="M12 16V8" strokeWidth="1.5"></path>
                </svg>
              </button>
            </div>
            <div className="mt-2">
              <InfoBN
                InfoBNTTC={InfoBNTTC}
                setInfoBNTTC={setInfoBNTTC}
                infoBN={infoBN}
                ghiChu={ghiChu}
                setGhiChu={setGhiChu}
              />
            </div>
            <div className="mt-2">
              <Price
                thuocBN={thuocBN}
                infoBN={infoBN}
                xuatHD={xuatHD}
                setXuatHD={setXuatHD}
                setIdPhuongThuc={setIdPhuongThuc}
                idPhuongThuc={idPhuongThuc}
                tongGiaTien={tongGiaTien}
              />
            </div>
            <div className="mt-5">
              <Button
                disabled={!infoBN || thuocBN.length === 0}
                onClick={showModalPay}
                className="w-full"
                variant="contained"
                size="small"
                color="success"
              >
                Thanh toán
              </Button>
            </div>
          </div>
        </div>
      </div>
      <PayModal
        benhNhanTT={benhNhanTT}
        resetForm={resetForm}
        dataBNTTC={dataBNTTC}
        thuocBN={thuocBN}
        cancelModal={cancelModal}
        isModal={isModal}
        tongGiaTien={tongGiaTien - (infoVC?.tongGiam ?? 0)}
        ghiChu={ghiChu}
      />
      <CreateCustomer />
    </>
  )
}

export default BanLe

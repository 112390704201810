import { call, put, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
import { kiemKeService } from '../../services/KiemKe/kiemkeService'

export function* kiemKeSaga() {
  // ---------- TẠO KỲ HÀNG HOÁ -------------
  yield takeLatest(
    typeAction.CREATE_KY_HANG_HOA,
    function* closingPeriod({ type, form, resetForm }) {
      try {
        console.log(form)

        yield call(() => kiemKeService.postNewKyHang(form))
        resetForm()
        ToastCus.fire({
          icon: 'success',
          title: 'Thêm mới thành công',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message || 'Thêm mới thất bại',
        })
      }
    },
  )
  // ------------ LẤY DANH SÁCH KỲ HÀNG ----------------
  yield takeLatest(typeAction.GET_KY_HANG_HOA, function* kyHang({ type }) {
    try {
      const result = yield call(() => kiemKeService.getKyHangs())
      yield put({
        type: typeAction.DISPATCH_KY_HANG_HOA,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  //----------- CHỐT KỲ HÀNG --------------
  yield takeLatest(typeAction.ADD_KY_HANG_HOA, function* addKyHang({ type, form }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const { IDCT, idChiNhanh, nguoiTao, idKy } = form
      yield call(() => kiemKeService.postTonKiemKe(IDCT, idChiNhanh, nguoiTao, idKy))
      ToastCus.fire({
        icon: 'success',
        title: 'Chốt kỳ hàng thành công',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message || 'Chốt kỳ hàng thất bại',
      })
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  //--------------GET TON HANG KIỂM KÊ----------------
  yield takeLatest(typeAction.GET_TON_HANG_KIEM_KE, function* tonKiemKe({ type, form }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_TABLE,
      })
      const { idKy, idCT, idChiNhanh, idKhoCN, keyword } = form
      const result = yield call(() =>
        kiemKeService.getTonKiemKe(idKy, idCT, idChiNhanh, idKhoCN, keyword),
      )
      yield put({
        type: typeAction.DISPATCH_TON_KIEM_KE,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_TABLE,
      })
    }
  })
  //---------------- UPDATE PRODUCT KIÊM KÊ ----------------
  yield takeLatest(typeAction.UPDATE_PRODUCT_KIEM_KE, function* updateProduct({ type, form }) {
    try {
      yield call(() => kiemKeService.updateHangKiemKe(form))
    } catch (error) {
      console.log(error)
    }
  })
}

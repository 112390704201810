import { https } from '../apiService'

export const kiemKeService = {
  postNewKyHang: (form) => https.post('KyKiemKe/ADDKyKiemKe', form),
  getKyHangs: () => https.get('KyKiemKe/GetKyKiemKe'),
  postTonKiemKe: (IDCT, idChiNhanh, nguoiTao, idKy) =>
    https.post(
      `TonKhoKiemKe/AddTonKhoKiemKe?IDCT=${IDCT}&idChiNhanh=${idChiNhanh}&nguoiTao=${nguoiTao}&idKy=${idKy}`,
    ),
  getTonKiemKe: (idKy, idCT, idChiNhanh, idKhoCN, keyword) =>
    https.get(`TonKhoKiemKe/TonKhoKiemKeByCondition`, {
      params: {
        idKy,
        idCT,
        idChiNhanh,
        idKhoCN,
        keyword,
      },
    }),
  updateHangKiemKe: (form) => https.put('TonKhoKiemKe/UpdateTonKhoKiemKe',form),
  postDieuChinhHangXuat: (idKy,idCN,idNV) => https.post(`TonKhoKiemKe/AddDieuChinhXuatThuoc?idKy=${idKy}&idCN=${idCN}&idNV=${idNV}`),
  postDieuChinhHangNhap:(idKy,idCN,idNV) => https.post(`TonKhoKiemKe/AddDieuChinhNhapThuoc?idKy=${idKy}&idCN=${idCN}&idNV=${idNV}`)
}

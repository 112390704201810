import { produce } from 'immer'

import * as typeAction from '../constants/constants'
const initialState = {
  listBranch: null,
  PkDangNhap: null,
  listCa: null,
  listBranhDetail: null,
  infoBranch: null,
  Tinh: null,
  Quan: null,
  Huyen: null,
  staffsBranch: null,
  stafs: null,
  ThongKeDoanhThuChiNhanh: null,
}

const branchReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.DISPATCH_LIST_BRANCH:
        draft.listBranch = payload
        break
      case typeAction.DISPATCH_BRANCH_LOGIN:
        draft.PkDangNhap = payload
        break
      case typeAction.DISPATCH_lIST_CALAMVIEC:
        draft.listCa = payload
        break
      case typeAction.DISTPATCH_THONG_KE_DONG_MO_CA:
        draft.ThongKeDoanhThuChiNhanh = payload
        break
      case typeAction.DISTPATCH_LIST_DETAIL_BRANCH:
        draft.listBranhDetail = payload
        break
      case typeAction.DISPATCH_STAFFS:
        draft.stafs = payload
        break
      case typeAction.DISPATCH_INFO_BRANCH:
        draft.infoBranch = payload
        break
      case typeAction.DISPATCH_STAFFS_BRANCH:
        draft.staffsBranch = payload
        break
      case typeAction.DISPATCH_QUAN_BRANCH:
        draft.Quan = payload
        break
      case typeAction.DISPATCH_COUNTRY_BRANCH:
        draft.Tinh = payload
        break
      case typeAction.DISPATCH_PHUONG_BRANCH:
        draft.Huyen = payload
        break
      default:
        return state
    }
  })
}

export default branchReducer

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConfigProvider, DatePicker, Table, TreeSelect, Select, Button } from 'antd'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { getBranchByChuyenKhoa } from '../../../../../store/actions/BranchAction'
import { reportService } from '../../../../../services/Report/reportService'
import { phanQuyenService } from '../../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../../store/actions/phanQuyenAction'
import { exportExcelformatSumCol } from '../../../../../utils/export/exportExcelFormat'
import * as typeAction from '../../../../../store/constants/constants'
import ToastCus from '../../../../../utils/Toast'
import { columns } from './Columns'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const Doctor = ({ ChuyenKhoa }) => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { isLoadingTable } = useSelector((state) => state.modalReducer)
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [company, setCompany] = useState(id_company)
  const [date, setDate] = useState({ form: now, to: now })
  const [branch, setBranch] = useState([])
  const [value, setValue] = useState(['all'])
  const [data, setData] = useState([])
  const fetchReport = async () => {
    try {
      openLoading()
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT548',
      )
      const { data } = await reportService.getBaoCaoThoiGianKhamTheoBacSi(
        (value?.includes('all') || !value?.length ? branch : value)
          ?.map(
            (item) =>
              `idChiNhanhs=${value?.includes('all') || !value?.length ? item?.idChiNhanh : item}`,
          )
          ?.join('&')
          ?.toString(),
        date.form,
        date.to,
      )
      setData(data)
      if (!value || !value?.length) setValue(['all'])
      closeLoading()
    } catch (error) {
      closeLoading()
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      closeLoading()
    }
  }

  const openLoading = () => {
    dispatch({
      type: typeAction.OPEN_LOADING_TABLE,
    })
  }
  const closeLoading = () => {
    dispatch({
      type: typeAction.CLOSE_LOADING_TABLE,
    })
  }
  const handleDatePicker = (name) => (date, dateString) => {
    const formatDate = moment(dateString, dateFormat).format(momentDate)
    setDate((prevSate) => ({ ...prevSate, [name]: formatDate }))
  }
  const onChangeCompany = (value) => {
    setCompany(value)
    setValue(['all'])
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT549',
      )

      const formatData = data?.map((item, index) => ({
        'STT ': ++index,
        'Ngày ': item?.ngaythanhtoan ? moment(item?.ngaythanhtoan).format('DD/MM/YYYY') : '-',
        'Bác sĩ': item?.bskham,
        'Tổng phiếu': item?.tongphieu,
        'Thời gian trung bình':
          item?.thoigiankhamtrungbinh !== '::' ? item?.thoigiankhamtrungbinh : '-',
      }))
      const name = 'Báo cáo thời gian khám theo bác sĩ'
      formatData?.length && exportExcelformatSumCol(formatData, name)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  useEffect(() => {
    dispatch(getBranchByChuyenKhoa(company, idChuyenKhoa, setBranch))
  }, [company])

  const onChangeChuyenKhoa = (value) => {
    dispatch(getBranchByChuyenKhoa(company, value, setBranch))
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    // fetchReport()
    onChangeChuyenKhoa(idChuyenKhoa)
  }, [])
  useEffect(() => {
    if (branch?.length) fetchReport()
  }, [branch])
  // validate
  const valieDateSelect = infoUser?.idNhom === 3
  const TimeAverage = (timeArray) => {
    // const timeArray = ['01:36:11', '00:01:27', '42:16:17', '47:59:51', '47:45:53', '00:03:23']
    if (!timeArray?.length) return false
    // Hàm chuyển từ định dạng hh:mm:ss sang giây
    const timeToSeconds = (time) => {
      const [hours, minutes, seconds] = time.split(':').map(Number)
      return hours * 3600 + minutes * 60 + seconds
    }
    // Chuyển tất cả thời gian sang giây và tính tổng
    const totalSeconds = timeArray?.reduce((acc, time) => acc + timeToSeconds(time), 0)
    // Tính thời gian trung bình
    const avgSeconds = Math.floor(totalSeconds / timeArray?.length)
    // Hàm chuyển từ giây sang định dạng hh:mm:ss
    const secondsToTime = (seconds) => {
      const hours = Math.floor(seconds / 3600)
        .toString()
        .padStart(2, '0')
      const minutes = Math.floor((seconds % 3600) / 60)
        .toString()
        .padStart(2, '0')
      const remainingSeconds = (seconds % 60).toString().padStart(2, '0')
      return `${hours}:${minutes}:${remainingSeconds}`
    }
    const avgTime = secondsToTime(avgSeconds)
    return avgTime
  }
  return (
    <>
      <div className="flex gap-3 border-dotted border-b-0  p-2 ">
        <Select
          showSearch
          value={company}
          onChange={onChangeCompany}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={listCompany?.map(({ idct, tenct }) => ({
            label: tenct,
            value: idct,
          }))}
          className={`w-80 ${valieDateSelect ? 'hidden' : 'block'}`}
        />
        <Select
          showSearch
          value={idChuyenKhoa}
          onChange={onChangeChuyenKhoa}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={[
            { label: 'Tất cả chuyên khoa', value: '' },
            ...ChuyenKhoa.map((items) => ({
              label: items.tenChuyenKhoa,
              value: items.idChuyenKhoa,
            })),
          ]}
          className="w-80"
        />
        <div className={`w-80 ${valieDateSelect ? 'hidden' : 'block'}`}>
          <TreeSelect
            {...tProps}
            style={{ flexWrap: 'unset' }}
            className="w-full h-8 overflow-clip"
          />
        </div>
        <div className="flex items-center gap-3">
          <DatePicker
            allowClear={false}
            value={dayjs(date.form)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-lg">-</span>
          <DatePicker
            allowClear={false}
            value={dayjs(date.to)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>

        <div className="flex gap-3">
          <IconButton color="primary" onClick={fetchReport} aria-label="delete" size="small">
            <SyncIcon />
          </IconButton>
        </div>
        <div className="flex gap-3 ml-auto">
          <Button
            disabled={!data?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
            borderRadius: 0,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          loading={isLoadingTable}
          bordered
          scroll={{
            x: 1500,
            y: 660,
          }}
          dataSource={data}
          columns={columns}
          pagination={false} // Disable pagination
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                {columns?.map((item, index) => (
                  <Table.Summary.Cell index={index}>
                    {item?.key === 'tongphieu' && (
                      <p className="text-center">
                        {data?.reduce((total, item) => (total += item?.tongphieu ?? 0), 0)}
                      </p>
                    )}
                    {item?.key === 'thoigiankhamtrungbinh' && (
                      <p className="text-center pr-[1rem]">
                        {TimeAverage(
                          data
                            ?.filter(
                              (item) =>
                                item?.thoigiankhamtrungbinh &&
                                !item?.thoigiankhamtrungbinh.includes('::'),
                            )
                            ?.map((item) => item?.thoigiankhamtrungbinh),
                        )}
                      </p>
                    )}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </ConfigProvider>
    </>
  )
}

export default Doctor

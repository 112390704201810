import React, { useCallback, useEffect } from "react";
import { Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";
import { inFoPaymentCard, searchPaymentCard } from "../../../../store/actions/cardAction";
import { useDispatch, useSelector } from "react-redux";
const Cards = () => {
  const dispatch = useDispatch();
  const { paymentCard } = useSelector((state) => state.cartReducer)
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      dispatch(searchPaymentCard(keyword.trim()));
    }, 300),
    []
  ); // sử dụng debounce để tối tiểu thánh server perfoman

  const onClickCard = (idthe, idbn) => {
    dispatch(inFoPaymentCard(idthe, idbn))
  }
  useEffect(() => {
    dispatch(searchPaymentCard(""));
  }, []);
  return (
    <>
      <Select
        onSearch={debounceDropDown}
        value={null}
        filterOption={false}
        notFoundContent={null}
        placeholder="Tìm kiếm mã BN, SĐT, CCCD, Tên bệnh nhân"
        suffixIcon={<SearchOutlined />}
        showSearch
        allowClear
        className="w-full"
      />
      <div className="border mt-2 rounded-md">
        <ul className="h-[776px] p-2 flex flex-col gap-2 overflow-auto">
          {paymentCard?.map((items) => (
            <li onClick={() => { onClickCard(items.idThe, items.idBN) }} className="border rounded-md p-2 cursor-pointer hover:bg-sky-100 hover:-translate-y-1 duration-200">
              <div className="flex">
                <h1 className="font-semibold text-base w-2/3">{items.tenBenhNhan} - {items.maBenhNhan}</h1>
                <p className="w-1/3 font-semibold text-base">
                  Mã thẻ: {items.maThe}
                </p>
              </div>
              <div className="flex">
                <h1 className="f w-2/3 text-gray-400">
                  Ngày sinh: {moment(items.ngaySinh).format('DD/MM/YYYY')}
                </h1>
                <p className="w-1/3 text-gray-400">Ngày tạo: {moment(items.ngayDangKy).format('DD/MM/YYYY')}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Cards;

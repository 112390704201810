import { call, put, takeLatest, select } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { phanAnhServices } from '../../services/phanAnh/phanAnhServices'
import _ from 'lodash'
import ToastCus from '../../utils/Toast'

// Sagas
export function* phanAnhSaga() {
  // POST PHẢN ÁNH KHÁCH HÀNG
  yield takeLatest(
    typeAction.POST_PHAN_ANH_KHACH_HANG,
    function* postPhanAnhSaga({ type, payload, idcn, getListPhanAnhByTrangThai }) {
      // yield console.log(payload)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => phanAnhServices.postPhanAnh(payload, idcn))
        yield call(() => getListPhanAnhByTrangThai())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // PUT PHẢN ÁNH KHÁCH HÀNG
  yield takeLatest(
    typeAction.PUT_PHAN_ANH_KHACH_HANG,
    function* putPhanAnhSaga({ type, form, getListPhanAnhByTrangThai }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => phanAnhServices.putPhanAnh(form))
        yield call(() => getListPhanAnhByTrangThai())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // POST PHẢN ÁNH KHÁCH HÀNG
  yield takeLatest(
    typeAction.POST_LIST_XULY_PHANANH,
    function* postListXuLySaga({
      type,
      list,
      infoPAKH,
      getInfoPhanAnh,
      getListPhanAnhKhachHang,
      setIsLoading,
    }) {
      try {
        yield call(() => setIsLoading(true))
        const dataUser = yield select((state) => state.userReducer)
        const { infoUser } = dataUser
        yield call(() => phanAnhServices.postListXuLy(list))
        if (infoPAKH?.IDTRANGTHAI === 1) {
          yield call(() => phanAnhServices.putTrangThaiPhanAnh(infoPAKH?.IDPAKH, 2, infoUser?.idnv))
        }
        yield call(() => getInfoPhanAnh(infoPAKH?.IDPAKH))
        yield call(() => getListPhanAnhKhachHang())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield call(() => setIsLoading(false))
      }
    },
  )
  // PUT TIẾP NHÂN XỬ LÝ PHẢN ÁNH
  yield takeLatest(
    typeAction.PUT_NHAN_XULY_PHANANH,
    function* putNhanXuLySaga({
      type,
      infoXLPA,
      infoPAKH,
      getInfoPhanAnhVaXuLy,
      getListXuLyPhanAnh,
      setIsLoading,
    }) {
      try {
        yield call(() => setIsLoading(true))
        const dataUser = yield select((state) => state.userReducer)
        const { infoUser } = dataUser
        yield call(() => phanAnhServices.putTrangThaiXuLy(infoXLPA?.idxl, 2, infoUser?.idnv))
        yield call(() => getInfoPhanAnhVaXuLy(infoPAKH?.IDPAKH, infoXLPA?.idxl))
        yield call(() => getListXuLyPhanAnh())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield call(() => setIsLoading(false))
      }
    },
  )
  // PUT THÔNG TIN XỬ LÝ PHẢN ÁNH
  yield takeLatest(
    typeAction.PUT_THONGTIN_XULY_PHANANH,
    function* putThongTinXuLySaga({
      type,
      formData,
      infoXLPA,
      infoPAKH,
      getInfoPhanAnhVaXuLy,
      getListXuLyPhanAnh,
      setIsLoading,
      handleResetForm,
    }) {
      try {
        yield call(() => setIsLoading(true))
        const dataUser = yield select((state) => state.userReducer)
        const { infoUser } = dataUser
        yield call(() => phanAnhServices.putThongTinXuLy(formData))
        // yield call(() => phanAnhServices.putTrangThaiXuLy(infoXLPA?.idxl, 3, infoUser?.idnv))
        yield call(() => handleResetForm())
        yield call(() => getInfoPhanAnhVaXuLy(infoPAKH?.IDPAKH, infoXLPA?.idxl))
        yield call(() => getListXuLyPhanAnh())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield call(() => setIsLoading(false))
      }
    },
  )

  // PUT ĐÃ XỬ LÝ PHẢN ÁNH KHÁCH HÀNG
  yield takeLatest(
    typeAction.PUT_DAXULY_PHANANH,
    function* putDaXuLyPAKHSaga({
      type,
      infoPAKH,
      getInfoPhanAnh,
      getListPhanAnhKhachHang,
      setIsLoading,
    }) {
      try {
        yield call(() => setIsLoading(true))
        const dataUser = yield select((state) => state.userReducer)
        const { infoUser } = dataUser
        const listData = infoPAKH?.DanhSachXuLyPhanAnh?.map((item) => ({
          idxuly: item?.idxl,
          trangthai: 4,
          nguoisua: infoUser?.idnv,
        }))
        yield call(() => phanAnhServices.putTrangThaiListXuLy(listData))
        yield call(() => phanAnhServices.putTrangThaiPhanAnh(infoPAKH?.IDPAKH, 3, infoUser?.idnv))
        yield call(() => getInfoPhanAnh(infoPAKH?.IDPAKH))
        yield call(() => getListPhanAnhKhachHang())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield call(() => setIsLoading(false))
      }
    },
  )
}

import { Input, Modal } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { addNewLoiDanBs } from '../../../store/actions/loiDanBsAction'
const CreateNew = ({ isOpen, setIsOpen, reloadData }) => {
  const dispatch = useDispatch()
  const handleCancel = () => {
    setIsOpen(false)
  }
  const reload = () => {
    reloadData()
    formik.resetForm()
    handleCancel()
  }
  const handleSubmit = (values) => {
    dispatch(addNewLoiDanBs(values, reload))
  }
  const formik = useFormik({
    initialValues: {
      loiDan: '',
      ghiChu: '',
      maLoiDan: '',
    },
    validationSchema: Yup.object().shape({
      loiDan: Yup.string().required('Vui lòng nhập lời dặn !'),
      maLoiDan: Yup.string().required('Vui lòng nhập mã lời dặn !'),
    }),
    onSubmit: (values) => handleSubmit(values),
  })
  
  return (
    <Modal
      onCancel={handleCancel}
      title="THÊM MỚI LỜI DẶN"
      open={isOpen}
      okText="Lưu"
      cancelText="Huỷ bỏ"
      onOk={formik.handleSubmit}
    >
      <div className='flex flex-col gap-2'>
        <div>
          <label>
            <span className="text-red-500">(*)</span> Mã lời dặn:
          </label>
          <Input
            status={formik.errors.maLoiDan ? 'error' : ''}
            value={formik.values.maLoiDan}
            name="maLoiDan"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.maLoiDan && formik.errors.maLoiDan && (
            <span className="text-red-500 ">{formik.errors.maLoiDan}</span>
          )}
        </div>
        <div>
          <label>
            <span className="text-red-500">(*)</span>Lời dặn:
          </label>
          <Input.TextArea
            status={formik.errors.loiDan ? 'error' : ''}
            onBlur={formik.handleBlur}
            value={formik.values.loiDan}
            name="loiDan"
            onChange={formik.handleChange}
            style={{
              height: 120,
              resize: 'none',
            }}
          />
          {formik.touched.loiDan && formik.errors.loiDan && (
            <span className="text-red-500 ">{formik.errors.loiDan}</span>
          )}
        </div>
        <div>
          <label>Ghi chú:</label>
          <Input value={formik.values.ghiChu} name="ghiChu" onChange={formik.handleChange} />
        </div>
      </div>
    </Modal>
  )
}

export default CreateNew

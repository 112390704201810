import * as Yup from 'yup'

export const createCustomerSchema = Yup.object().shape({
  ngaySinh: Yup.string().required(),
  idNgheNghiep: Yup.string().required(),
  tenBenhNhan: Yup.string().required(),
  idQuan: Yup.string().required(),
  idPhuong: Yup.string().required(),
  diaChi: Yup.string().required()
})

import * as XLSX from 'xlsx'
import { formattedNumber } from '../formattedNumber'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

export const exportExcelformat = (data, name) => {
  // Tạo worksheet từ dữ liệu đã định dạng
  const worksheet = XLSX.utils.json_to_sheet(data)
  const headers = Object.keys(data[0])

  // Thêm tiêu đề vào worksheet
  XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' })
  // Bật chức năng filter cho các cột
  const range = XLSX.utils.decode_range(worksheet['!ref'])
  worksheet['!autofilter'] = { ref: XLSX.utils.encode_range(range) }
  // Tự động điều chỉnh kích thước cột
  const wscols = headers.map((header) => ({
    wch: Math.max(
      header.length,
      ...data.map((item) => (item[header] != null ? item[header].toString().length : 0)),
    ),
  }))
  worksheet['!cols'] = wscols
  // Tự động điều chỉnh chiều cao hàng
  const rowHeight = { hpx: 20 } // Chiều cao mặc định cho các hàng
  worksheet['!rows'] = new Array(data.length + 1).fill(rowHeight)

  // Tạo workbook và thêm worksheet
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')

  // Xuất file Excel
  XLSX.writeFile(wb, `${name}.xlsx`)
}

// export const exportExcelformatSumCol = (data, name, listSumCol) => {
//   // Tạo worksheet từ dữ liệu đã định dạng
//   const worksheet = XLSX.utils.json_to_sheet(data)
//   const headers = Object.keys(data[0])

//   // Thêm tiêu đề vào worksheet
//   XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' })
//   // Bật chức năng filter cho các cột
//   const range = XLSX.utils.decode_range(worksheet['!ref'])
//   worksheet['!autofilter'] = { ref: XLSX.utils.encode_range(range) }

//   // Tính tổng cho các cột cần thiết (ví dụ: 'Đơn giá', 'Thanh toán', 'Công mài')
//   const totalRow = headers?.map((header) => {
//     if (listSumCol?.includes(header)) {
//       if (header === 'STT ') return 'Tổng'
//       const sum = formattedNumber(
//         data.reduce((sum, item) => sum + (+`${item[header] ?? ''}`.replaceAll(',', '') ?? 0), 0) ??
//           0,
//       )
//       return sum?.includes(',') ? sum : +sum
//     }
//     return ''
//   })

//   // Tự động điều chỉnh kích thước cột
//   const wscols = headers.map((header) => ({
//     wch: Math.max(
//       header.length,
//       ...data.map((item) => (item[header] != null ? item[header].toString().length : 0)),
//     ),
//   }))
//   worksheet['!cols'] = wscols

//   // Thêm hàng tổng vào worksheet
//   XLSX.utils.sheet_add_aoa(worksheet, [totalRow], { origin: -1 }) // Thêm ở cuối worksheet

//   // Tự động điều chỉnh chiều cao hàng
//   const rowHeight = { hpx: 20 } // Chiều cao mặc định cho các hàng
//   worksheet['!rows'] = new Array(data.length + 1).fill(rowHeight)

//   // Tạo workbook và thêm worksheet
//   const wb = XLSX.utils.book_new()
//   XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')

//   // Xuất file Excel
//   XLSX.writeFile(wb, `${name}.xlsx`)
// }

export const exportExcelformatSumCol = (data, name, listSumCol) => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Sheet1')

  // Thêm tiêu đề vào worksheet
  const headers = Object.keys(data[0])
  worksheet.addRow(headers)

  // Thêm dữ liệu vào worksheet
  data.forEach((rowData) => {
    const row = headers.map((header) => rowData[header])
    worksheet.addRow(row)
  })

  if (listSumCol?.length) {
    // Thêm hàng tổng nếu cần
    const totalRow = headers.map((header) => {
      if (listSumCol?.includes(header)) {
        if (header?.includes('STT')) return 'Tổng'
        const sum = data.reduce(
          (sum, item) => sum + (+`${item[header] ?? ''}`.replaceAll(',', '') ?? 0),
          0,
        )
        return formattedNumber(sum ?? 0)
      }
      return ''
    })
    worksheet.addRow(totalRow)
  }

  // Tự động điều chỉnh kích thước cột
  headers.forEach((header, index) => {
    worksheet.getColumn(index + 1).width =
      2 +
      1.2 *
        Math.max(
          header.length,
          Math.max(...data.map((item) => (item[header] ? item[header].toString().length : 0))),
        )
  })
  // Đặt font Arial cho tất cả các ô
  worksheet.eachRow((row) => {
    row.eachCell((cell) => {
      cell.font = { name: 'Arial', size: 11 }
    })
  })

  // Xuất file Excel
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${name}.xlsx`)
  })
}

import { CaretDownOutlined, EditOutlined } from '@ant-design/icons'
import { Input, Select, Tooltip } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as typeAction from '../../../../store/constants/constants'
import { https } from '../../../../services/apiService'

function InputMSDK({ record, isEditing, onChangeInput }) {
  const [value, SetValue] = useState('')
  const [listMSDK, setListMSDK] = useState([])
  const [isShowOption, setIsShowOption] = useState(false)
  const dispatch = useDispatch()

  const valueDefault = useMemo(() => record.maSoDangKy, [])

  const getMSDKThuoc = async (val) => {
    try {
      const { data } = await https.get(
        `ThuocVatTuMSDK/GetAllMSDKByIDTHUOC?idThuoc=${val.idThuocVatTu}`,
      )
      setListMSDK(data.map((item) => item.maSoDangKy))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isEditing(record)) {
      getMSDKThuoc(record)
    }
  }, [record])

  const handleChange = (value) => {
    SetValue(value)
    onChangeInput(record.key, 'maSoDangKy', value)
  }

  const handleRevert = () => {
    setIsShowOption(false)
  }


  return (
    <div className="flex">
      {!isEditing(record) ? (
        <Input size="small" value={record.maSoDangKy} variant={'borderless'} readOnly />
      ) : isShowOption ? (
        <>
          <Select
            variant={isEditing(record) ? 'outlined' : 'borderless'}
            onChange={handleChange}
            className="w-full"
            value={value}
            options={listMSDK?.map((val) => ({
              label: val,
              value: val,
            }))}
            size="small"
            disabled={!isEditing(record)}
          />
          <Tooltip title="Nhập mã số đăng kí" color="blue">
            <EditOutlined style={{ fontSize: '14px', marginLeft: '5px' }} onClick={handleRevert} />
          </Tooltip>
        </>
      ) : (
        <>
          <Input
            size="small"
            value={record.maSoDangKy}
            variant={isEditing(record) ? 'outlined' : 'borderless'}
            readOnly={!isEditing(record)}
            onChange={(e) => onChangeInput(record.key, 'maSoDangKy', e.target.value)}
          />
          {listMSDK && (
            <Tooltip title="Chọn mã số đăng kí" color="green">
              <CaretDownOutlined
                style={{ fontSize: '14px', marginLeft: '5px' }}
                onClick={() => setIsShowOption(true)}
              />
            </Tooltip>
          )}
        </>
      )}
    </div>
  )
}

InputMSDK.propTypes = {}

export default InputMSDK

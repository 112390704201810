import React from "react";
import { Input } from 'antd'
const { TextArea } = Input;
const FormSanLon = ({ formikPhuSan }) => {
    return (
        <>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Cơn gò: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.congo}
                    name="congo"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Khám trong: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.khamtrong}
                    name="khamtrong"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Âm hộ: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.amho}
                    name="amho"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Âm đạo: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.amdao}
                    name="amdao"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">CTC: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.ctc}
                    name="ctc"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Tử cung: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.tucung}
                    name="tucung"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">BCTC: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.bctc}
                    name="bctc"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Tim thai: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.timthai}
                    name="timthai"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Ngôi thai: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.ngoithai}
                    name="ngoithai"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Ối: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.oi}
                    name="oi"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">K. chậu: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.kchau}
                    name="kchau"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Phần phụ T: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.phanphut}
                    name="phanphut"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Phần phụ P: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.phanphup}
                    name="phanphup"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1 mb-5">
                <label className="font-semibold w-44 text-end">GN. khác: </label>
                <TextArea
                    rows={3}
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.gnkhac}
                    name="gnkhac"
                    size="small"
                />
            </div>
        </>
    )
}
export default FormSanLon;
import { Input, Modal, Select,Popconfirm } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createNhanSuCoShema } from '../../../schemas/createNhanSuCoShema'
import { deleteNhanSuCo, updateNhanSuCo } from '../../../store/actions/suCoAction'

function EditNhan({ isEditNhan, setIsEditNhan }) {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { groupNhan, infoNhan } = useSelector((state) => state.suCoReducer)
  const dispatch = useDispatch()
  const handleCancel = () => {
    setIsEditNhan(false)
  }
  const handleSubmit = (value) => {
    dispatch(updateNhanSuCo(value))
  }
  const onChangeSelected = (value) => {
    formik.setFieldValue('nhom', value)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idnhan: infoNhan?.idnhan,
      tennhan: infoNhan?.tennhan,
      mota: infoNhan?.mota,
      nhom: infoNhan?.idnhom,
      // nhom: null,
      thoigianxuly: infoNhan?.thoigianxuly,
      ghichu: infoNhan?.ghichu,
      nguoisua: infoUser?.idnv,
    },
    validationSchema: createNhanSuCoShema,
    onSubmit: (value) => handleSubmit(value),
  })
  return (
    <Modal
      onOk={formik.handleSubmit}
      okText="Cập nhật"
      cancelText="Huỷ bỏ"
      open={isEditNhan}
      onCancel={handleCancel}
      title={
        <p className="font-semibold text-xl w-full text-center text-blue-500">CHI TIẾT NHÃN</p>
      }
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium flex justify-between">
            <p>
              Tên nhãn <span className="text-red-500">(*)</span>
            </p>
            
          </label>
          <Input
            name="tennhan"
            onChange={formik.handleChange}
            status={formik.errors.tennhan && formik.touched.tennhan ? 'error' : null}
            value={formik.values.tennhan}
          />
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
            {' '}
            Thời gian xử lý <span className="text-red-500">(*)</span>
          </label>
          <Input
            name="thoigianxuly"
            onChange={formik.handleChange}
            status={formik.errors.thoigianxuly && formik.touched.tennhan ? 'error' : null}
            value={formik.values.thoigianxuly}
          />
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
            Nhóm người dùng <span className="text-red-500">(*)</span>
          </label>
          <Select
            placeholder="chọn nhóm người dùng"
            onChange={onChangeSelected}
            value={formik.values.nhom}
            options={groupNhan?.map((item) => ({
              label: item.tennhom,
              value: item.idnhom,
            }))}
            className="w-full"
            name="nhom"
            status={formik.errors.nhom && formik.touched.nhom ? 'error' : null}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">Mô tả</label>
          <Input name="mota" onChange={formik.handleChange} value={formik.values.mota} />
          <div className="flex flex-col gap-1">
            <label className="text-base font-medium">Ghi chú</label>
            <Input name="ghichu" onChange={formik.handleChange} value={formik.values.ghichu} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EditNhan

import * as typeAction from '../constants/constants'

export const fetchAllChuongs = () => ({
  type: typeAction.GET_ALL_CHUONG_ICD10,
})
export const getICD10ByChuongs = (keyword, idchuong, page, pageSize, reset, setTotalPages) => ({
  type: typeAction.GET_ICD10_BY_CHUONG,
  keyword,
  idchuong,
  page,
  pageSize,
  reset,
  setTotalPages,
})

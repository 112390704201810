import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Select, Tabs, List, Divider, DatePicker } from "antd";
import CampaignIcon from "@mui/icons-material/Campaign";
import CachedIcon from "@mui/icons-material/Cached";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import dayjs from "dayjs";
import { BNDetailInfo } from '../../../../../store/actions/childrenClinicAction'
import { BNDangVaDaKhamSangLocAction, getInfoBNKhamSangLocAction } from '../../../../../store/actions/khamSangLocAction'
const now = moment();
const dateFormat = 'DD/MM/YYYY'
const formatDateAPI = 'YYYY-MM-DD'
const BNdone = () => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { DetailBN } = useSelector((state) => state.childrenClinicReducer)
  const { ListBNDaKham, ListBNDangKham } = useSelector((state) => state.khamSangLocReducer)
  const [dateFrom, setDateFrom] = useState(now.format(formatDateAPI))
  const [dateTo, setDateTo] = useState(now.format(formatDateAPI))

  const handleDetailInfoBN = (idbn, idbnttc) => {
    dispatch(getInfoBNKhamSangLocAction(idbnttc, idbn, PkDangNhap?.idChiNhanh))
    dispatch(BNDetailInfo(idbn, idbnttc))
  }
  const handleDatePicker = (name) => (Date, dateString) => {
    // format date
    const value = moment(dateString, dateFormat)
    name === "from" ? setDateFrom(value) : setDateTo(value)
  }
  // const onChangePhongKham = (value) => {
  //   setDefaultValuePK(value)
  // }
  const onLoad = () => {
    dispatch(BNDangVaDaKhamSangLocAction(PkDangNhap?.idChiNhanh, moment(dateFrom).format(formatDateAPI), moment(dateTo).format(formatDateAPI)))
  }
  useEffect(() => {
    onLoad()
  }, [])
  return (
    <div className="h-full">
      <div>
        <div className="flex justify-between">
          <ul className="flex gap-1 items-center">
            <li>
              <DatePicker
                defaultValue={dayjs(dateFrom)}
                maxDate={dayjs(now)}
                allowClear={false}
                onChange={handleDatePicker("from")}
                format={dateFormat} size="small" />
            </li>
            <li>
              <DatePicker
                defaultValue={dayjs(dateTo)}
                onChange={handleDatePicker("to")}
                minDate={dayjs(dateFrom)}
                maxDate={dayjs(now)}
                allowClear={false}
                format={dateFormat}
                size="small"
              />
            </li>
          </ul>
          <IconButton onClick={onLoad} color="primary" aria-label="add to shopping cart">
            <CachedIcon />
          </IconButton>
        </div>
        <div>
          <h2 className="font-semibold">
            BS Thực hiện:{" "}
            <span className="font-normal">{infoUser?.tenNV}</span>{" "}
          </h2>
        </div>
      </div>
      <Divider className="my-2 " />
      <ul className=" flex flex-col gap-2 mt-2 rounded-md h-[680px] overflow-auto">
        {ListBNDangKham?.map((items) => (
          <li
            key={items.idbnttc}
            onClick={() => {
              handleDetailInfoBN(items.idbn, items.idbnttc)
            }}
            className="flex items-center border py-2 rounded-md hover:bg-sky-100 hover:cursor-pointer hover:-translate-y-1 duration-200">
            {" "}
            <p className="w-1/6 text-center font-semibold text-lg">
              {items.sttkham}
            </p>
            <div className="w-5/6 flex flex-col">
              <h2 className="font-semibold">{items.tenbenhnhan}</h2>
              <p className="text-gray-400">Ngày sinh: {items.ngaysinh && moment(items.ngaysinh).format('DD/MM/YYYY')}</p>
            </div>
          </li>
        ))}
        {ListBNDaKham?.map((items) => (
          <li
            key={items.idbnttc}
            onClick={() => {
              handleDetailInfoBN(items.idbn, items.idbnttc)
            }}
            className="flex items-center border py-2 rounded-md hover:bg-sky-100 hover:cursor-pointer hover:-translate-y-1 duration-200">
            {" "}
            <p className="w-1/6 text-center font-semibold text-lg">
              {items.sttkham}
            </p>
            <div className="w-5/6 flex flex-col">
              <h2 className="font-semibold">{items.tenbenhnhan}</h2>
              <p className="text-gray-400">Ngày sinh: {items.ngaysinh && moment(items.ngaysinh).format('DD/MM/YYYY')}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BNdone;

import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import { useFormik } from 'formik'
import moment from 'moment'
import 'moment/locale/vi'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sieuAmService } from '../../../services/SieuAm/sieuAmService'
import { getAllBieuMau, saveBnSieuAm } from '../../../store/actions/sieuAmAction'
import * as typeAction from '../../../store/constants/constants'
import ToastCus from '../../../utils/Toast'
import Customer from './Customer/Customer'
import ImageSA from './ImageSA/ImageSA'
import InfoBN from './InfoBN/InfoBN'
import InfoPhieu from './InfoPhieu/InfoPhieu'
import Template from './Template/Template'
moment.locale('vi')
const formatDB = 'YYYY-MM-DDTHH:mm:ss'
const today = moment()
const SieuAm = () => {
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const { infoBNSieuAm } = useSelector((state) => state.sieuAmReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [image, setImage] = useState([])
  const [BNPending, setBNPending] = useState([])
  const [BNdone, setBnDone] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [listImagePrint, setListImagePrint] = useState([])
  const dispatch = useDispatch()
  //----------- lấy danh sách chờ BN --------//
  const fetchBNPending = async (id) => {
    const { data } = await sieuAmService.getBNPendingByBranch(id)
    setBNPending(data)
  }
  // ----------- lấy danh bệnh nhân đã thực hiên ------------//
  const fetchBnDone = async (idcn, dateForm, dataTo) => {
    const { data } = await sieuAmService.getBNDoneBtBranch(idcn, dateForm, dataTo)
    setBnDone(data)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      IDBNTTC: infoBNSieuAm?.idbnttc,
      // IDBM: 1,
      NGAYSIEUAM: null,
      BSCHIDINH: infoBNSieuAm?.bschidinh,
      BSSIEUAM: infoUser?.tenNV,
      NGUOISUA: infoUser?.idnv, // người tạo lần đầu tiên (có id người sửa, không có ngày sửa) để cập nhật dựa trên đúng idnv mới dc cập nhật
      CHANDOANSOBO: infoBNSieuAm?.chandoan,
      LOAISIEUAM: infoBNSieuAm?.loaisieuam,
      IDBNCDHA: infoBNSieuAm?.idbncdha,
      BIEUMAU: null,
      PARA: infoBNSieuAm?.para,
      files: null,
      KETLUAN: infoBNSieuAm?.ketluan,
      tieude: infoBNSieuAm?.tieude,
    },
    onSubmit: (values) => handleSubmit(values),
  })
  const reload = () => {
    formik.resetForm()
    fetchBNPending(ID_BRANCH)
    setEditorState(EditorState.createEmpty())
    setImage([])
    setListImagePrint([])
  }
  const handleSubmit = (values) => {
    // return
    if (!values.PARA || values.PARA?.trim().length === 0) {
      formik.setFieldValue('PARA', null)
      // formik.setErrors({ para: 'require' })
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập chỉ số Para!',
      })
      return
    }
    if (image.length !== 0) {
      // * Nếu  như có hình ảnh thì gán hình ảnh vào !
      const data = image.map((item) => item.file)
      values.files = data
    }
    const contentState = editorState.getCurrentContent()
    const row = convertToRaw(contentState)
    const data = JSON.stringify(row)
    values.BIEUMAU = data
    values.NGAYSIEUAM = today.format(formatDB) // * Lấy lại chính xát ngày siêu âm

    const formData = new FormData()
    for (let key in values) {
      if (values[key] !== null && values[key] !== undefined) {
        // Skip null or undefined values
        if (key === 'files' && values[key].length > 0) {
          values[key].forEach((file) => {
            formData.append(key, file)
          })
        } else {
          formData.append(key, values[key])
        }
      }
    }
    dispatch(saveBnSieuAm(formData, ID_BRANCH, reload, listImagePrint))
  }

  useEffect(() => {
    dispatch({
      type: typeAction.RESET_STORE_SIEUAM,
    })
    dispatch(getAllBieuMau(1)) // lấy biểu mẫu loại siêu âm = 1
    fetchBNPending(ID_BRANCH)
    fetchBnDone(ID_BRANCH, today.format('YYYY-MM-DD'), today.format('YYYY-MM-DD'))
  }, [])
  useEffect(() => {
    if (infoBNSieuAm?.linkhinhanh) {
      const images = infoBNSieuAm.linkhinhanh
        .split(';')
        .map((item) => ({ url: item, checked: false }))
      setImage(images)
    } else {
      setImage([])
    }
  }, [infoBNSieuAm, infoBNSieuAm?.linkhinhanh])
  useEffect(() => {
    if (infoBNSieuAm?.bieumau) {
      const convertJson = JSON.parse(infoBNSieuAm?.bieumau)
      const contentState = convertFromRaw(convertJson)
      setEditorState(EditorState.createWithContent(contentState))
    } else {
      setEditorState(null)
    }
  }, [infoBNSieuAm, infoBNSieuAm?.bieumau])

  return (
    <>
      <div className="h-full bg-[#EFEFEF]">
        <div>
          <div className="p-2">
            <div className="bg-white flex border rounded-lg">
              <div className="w-1/2 border-r">
                <div className="flex">
                  <div className="w-1/2 border-r">
                    <Customer
                      fetchBnDone={fetchBnDone}
                      fetchBNPending={fetchBNPending}
                      BNdone={BNdone}
                      BNPending={BNPending}
                      setListImagePrint={setListImagePrint}
                    />
                  </div>
                  <div className="w-1/2">
                    <div>
                      <InfoBN />
                    </div>
                    <div>
                      <InfoPhieu formik={formik} />
                    </div>
                  </div>
                </div>
                <div className="border-t">
                  <ImageSA
                    image={image}
                    setImage={setImage}
                    listImagePrint={listImagePrint}
                    setListImagePrint={setListImagePrint}
                  />
                </div>
              </div>
              <div className="w-1/2">
                <Template
                  formik={formik}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  listImagePrint={listImagePrint}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SieuAm

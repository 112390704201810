import { Button, Modal, notification } from 'antd'
import React from 'react'
import { authService } from '../../../services/auth/authService'
const ModalCheckIPMac = ({ isCheckIP, setIsCheckIP, code, getIPMac, idBranchRegitry }) => {
  const [api, contextHolder] = notification.useNotification()
  const handleCancel = () => {
    setIsCheckIP(false)
  }
  const addIpMacComputer = async () => {
    if(!code) return openNotificationWithIcon('error','Gửi mã đăng ký thất bại !','Gửi mã bị gián đoạn, vui lòng liên hệ IT hệ thống để được hổ trợ.')
    const form = {
      maipmac: code?.macAddresses[0],
      idchinhanh: idBranchRegitry,
      os: code?.os,
      ram:  code?.ram,
      cpu:  code?.cpu,
      iplocal:  code?.ipLocal,
      ipwan:  code?.ipWan,
    }
    try {
      await authService.postIPMacComputer(form)
      openNotificationWithIcon('success','Gửi mã đăng ký thành công','Vui lòng đợi phê duyệt, hoặc liên hệ cho IT để được hổ trợ.')
    } catch (err) {
      openNotificationWithIcon('error','Gửi mã đăng ký thất bại !','Mã đăng kí đã được tạo, liên hệ IT hệ thống để được hổ trợ.')
      console.log(err)
    }
  }
  const onCLickButton = () => {
    if (!code) {
      getIPMac()
      return
    }
    addIpMacComputer()
  }
  const openNotificationWithIcon = (type,message,desc) => {
    api[type]({
      message,
      description: desc,
    })
  }
  return (
    <>
      {contextHolder}
      <Modal footer={null} onCancel={handleCancel} open={isCheckIP}>
        <h2 className="font-semibold text-lg text-center">MÃ ĐĂNG KÝ</h2>
        <p className="border p-2 rounded-lg text-center my-5 bg-slate-100">
          {code ? (
            code.macAddresses[0]
          ) : (
            <>
              {' '}
              <span>
                Không lấy được mã ! Bấm{' '}
                <a
                  href="https://smartapp315.315healthcare.com/315MacAddress.rar"
                  download
                  className="text-blue-500"
                >
                  {' '}
                  tải công cụ{' '}
                </a>{' '}
                lấy mã
              </span>{' '}
            </>
          )}{' '}
        </p>
        <Button type="primary" loading={false} onClick={onCLickButton} className="w-full">
          {code ? 'Đăng ký' : 'Lấy mã'}
        </Button>
      </Modal>
    </>
  )
}

export default ModalCheckIPMac

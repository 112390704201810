import React, { useEffect, useState } from 'react'
import { Input, InputNumber, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import { createEditDVSchema } from '../../../../schemas/createEditDVSchema'
import {
  ListDichVuAction,
  addNewDichVuAction,
  getAllDichVu,
} from '../../../../store/actions/dichVuAction'
import jsPDF from 'jspdf'
import { timesRegular } from '../../../../assets/fonts/base64/times'
const ModalAdd = ({ isModalOpen, handleCancel, searchGroup, company }) => {
  const dispatch = useDispatch()
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { groupsDichVu } = useSelector((state) => state.dichVuReducer)
  const [allChuyenKhoa, setAllChuyenKhoa] = useState([])
  const onLoad = () => {
    // dispatch(getAllDichVu())
    dispatch(ListDichVuAction(searchGroup, company))
  }
  // xử lí form thêm dịch vụ
  const handleSubmit = (value) => {
    dispatch(addNewDichVuAction(value, onLoad))
    formik.resetForm()
    handleCancel()
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      maDichVu: '',
      tenDichVu: '',
      tenVietTat: '',
      ghichu: '',
      idNhomDV: 1,
      donGia: 0,
      tienGuiMau: 0,
      tienChietKhau: 0,
      tienGiaCong: 0,
      tienCongVanChuyen: 0,
      giaVon: 0,
      idct: 1, // 1 lặt định là công ty y tế chấn văn
      donVi: '',
      idChuyenKhoa: 1,
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: createEditDVSchema,
  })

  const getAllChuyenKhoa = async () => {
    try {
      const { data } = await https.get('ChuyenKhoa/AllChuyenKhoa')
      setAllChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAllChuyenKhoa()
  }, [])

  const ChangeSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }

  return (
    <>
      <Modal
        style={{
          top: 20,
        }}
        okText="Thêm"
        cancelText="Hủy"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Tạo loại dịch vụ</h2>}
        onOk={formik.handleSubmit}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">
              <span className="text-sx text-red-500">(*)</span>Mã dịch vụ{' '}
            </label>
            <Input
              value={formik.values.maDichVu}
              name="maDichVu"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập mã dịch vụ"
              status={formik.errors.maDichVu && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold text-base">
              <span className="text-sx text-red-500">(*)</span>Tên dịch vụ{' '}
            </label>
            <Input
              value={formik.values.tenDichVu}
              name="tenDichVu"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập tên dịch vụ"
              status={formik.errors.tenDichVu && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold text-base">Tên viết tắt </label>
            <Input
              value={formik.values.tenVietTat}
              name="tenVietTat"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập tên viết tắt"
            />
          </div>
          <div>
            <label className="font-semibold text-base">
              <span className="text-sx text-red-500">(*)</span>Đơn vị tính{' '}
            </label>
            <Input
              value={formik.values.donVi}
              name="donVi"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập đơn vị tính"
              status={formik.errors.donVi && 'error'}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold  text-base">Công ty </label>
            <Select
              name="idct"
              value={formik.values.idct}
              options={listCompany?.map((items) => ({
                label: items.tenct,
                value: items.idct,
              }))}
              onChange={ChangeSelected('idct')}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold text-base">Nhóm dịch vụ </label>
            <Select
              name="idNhomDV"
              value={formik.values.idNhomDV}
              options={groupsDichVu?.map((items) => ({
                label: items.tenNhom,
                value: items.idNhom,
              }))}
              onChange={ChangeSelected('idNhomDV')}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold  text-base">Chuyên khoa</label>
            <Select
              name="idChuyenKhoa"
              value={formik.values.idChuyenKhoa}
              options={allChuyenKhoa?.map((items) => ({
                label: items.tenChuyenKhoa,
                value: items.idChuyenKhoa,
              }))}
              onChange={ChangeSelected('idChuyenKhoa')}
            />
          </div>
          <div className="flex  flex-col">
            <label className="font-semibold text-base">Đơn giá </label>
            <InputNumber
              onChange={(value) => formik.setFieldValue('donGia', value)}
              name="donGia"
              className="w-full"
              value={formik.values.donGia}
              step="0.001"
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              max={999999999}
            />
          </div>
          <div className="flex  flex-col">
            <label className="font-semibold text-base">Tiền gửi mẫu</label>
            <InputNumber
              onChange={(value) => formik.setFieldValue('tienGuiMau', value)}
              status={formik.errors.tienGuiMau ? 'error' : ''}
              value={formik.values.tienGuiMau}
              name="tienGuiMau"
              className="w-full"
              step="0.001"
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              max={999999999}
            />
          </div>
          <div className="flex  flex-col">
            <label className="font-semibold text-base">Tiền chiết khấu</label>
            <InputNumber
              onChange={(value) => formik.setFieldValue('tienChietKhau', value)}
              status={formik.errors.tienChietKhau ? 'error' : ''}
              value={formik.values.tienChietKhau}
              name="tienChietKhau"
              className="w-full"
              step="0.001"
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              max={999999999}
            />
          </div>
          <div className="flex  flex-col">
            <label className="font-semibold text-base">Tiền gia công</label>
            <InputNumber
              onChange={(value) => formik.setFieldValue('tienGiaCong', value)}
              status={formik.errors.tienGiaCong ? 'error' : ''}
              value={formik.values.tienGiaCong}
              name="tienGiaCong"
              className="w-full"
              step="0.001"
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              max={999999999}
            />
          </div>
          <div className="flex  flex-col">
            <label className="font-semibold text-base">Tiền công vận chuyển</label>
            <InputNumber
              onChange={(value) => formik.setFieldValue('tienCongVanChuyen', value)}
              status={formik.errors.tienCongVanChuyen ? 'error' : ''}
              value={formik.values.tienCongVanChuyen}
              name="tienCongVanChuyen"
              className="w-full"
              step="0.001"
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              max={999999999}
            />
          </div>
          <div className="flex  flex-col">
            <label className="font-semibold text-base">Tiền vốn</label>
            <InputNumber
              onChange={(value) => formik.setFieldValue('giaVon', value)}
              status={formik.errors.giaVon ? 'error' : ''}
              value={formik.values.giaVon}
              name="giaVon"
              className="w-full"
              step="0.001"
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              max={999999999}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Ghi chú </label>
            <Input
              value={formik.values.ghiChu}
              onChangeCapture={formik.handleChange}
              name="ghichu"
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalAdd

import { Button } from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import BN from './BN/BN'
import ModalPay from './Modal/ModalPay'
import Pay from './Pay/Pay'
import ToastCus from '../../../../utils/Toast'

const BIlling = ({
  idLyDo,
  check,
  lyDo, //list ly do
  setIdLyDo,
  setCheck,
  checkTraThuoc,
  setCheckTraThuoc,
  lyDoKhac, // lý do không lấy thuốc trước khi thanh toán
}) => {
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoBN, service, BNDetail, BNTTPhieuThuLT } = useSelector(
    (state) => state.drugstoreReducer,
  )
  const [isModal, setIsModal] = useState(false)
  const { infoUser } = useSelector((state) => state.userReducer)
  const checkBsDangKeToaLai = !service?.BSKEDON || !service?.NGAYKEDON
  const totalPrice = check
    ? 0
    : service?.DanhSachThuoc.reduce((total, items) => total + items.dongia * items.soluong, 0) ?? 0
  const showModalPay = () => {
    if (checkBsDangKeToaLai) {
      ToastCus.fire({
        icon: 'error',
        title: 'Bác sĩ đang kê toa lại không thể chỉnh sửa!',
      })
      return
    } else if (check && lyDoKhac?.tenlydo === 'Khác' && !lyDoKhac?.noidunglydokhac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập lý do không lấy thuốc!',
      })
      return
    }
    setIsModal(true)
  }
  const cancelModal = () => {
    setIsModal(false)
  }

  const handleSubmit = (values, actions) => {
    // console.log(values)
  }

  const formik_Billing = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbnttc: BNDetail?.idbnttc,
      idPhuongThuc: 1, // mặt định là thanh toán
      hoaDonDienTu: BNTTPhieuThuLT?.hoadondientu ?? 0, // mặc định là LẤY CỦA LỄ TÂN (LỄ TÂN XUẤT THÌ DƯỢC XUẤT) không xuất hoá đơn điện tử
      ghiChuXuatHoaDon: BNTTPhieuThuLT?.ghichuxuathoadon ?? '',
      // maVoucher: 0,
      tienThanhToan: 0,
      // ghiChu: "string",
      ngayThanhToan: '',
      // thuNgan: infoUser?.tenNV,
      thuNgan: infoUser?.tenNV,
      idThuNgan: infoUser?.idnv,
      tienGiamGia: 0,
      idloaitt: 6, // xuất bán vắc xin
      idCaLamViec: infoUser?.dangNhap.idCaLamViec,
      idcn: PkDangNhap?.idChiNhanh,
      idct: PkDangNhap?.idCongTy,
      idchuyenkhoa: PkDangNhap?.idChuyenKhoa,
    },
    onSubmit: (values, actions) => handleSubmit(values, actions),
  })
  return (
    <div className="p-2">
      <div className="flex flex-col gap-2 mb-4">
        <BN />
        <Pay totalPrice={totalPrice} formik_Billing={formik_Billing} />
      </div>
      <Button
        onClick={showModalPay}
        disabled={
          !infoBN ||
          BNDetail?.trangthaikhambenh === 4 ||
          (BNDetail?.trangthaikhambenh === 5 && !checkTraThuoc) ||
          checkBsDangKeToaLai
        }
        variant="contained"
        size="medium "
        color="success"
        className="w-full"
      >
        Thanh toán
      </Button>
      <ModalPay
        idLyDo={idLyDo}
        setIdLyDo={setIdLyDo}
        setCheck={setCheck}
        check={check}
        lyDo={lyDo}
        isModal={isModal}
        formik_Billing={formik_Billing}
        totalPrice={totalPrice}
        cancelModal={cancelModal}
        checkTraThuoc={checkTraThuoc}
        setCheckTraThuoc={setCheckTraThuoc}
        lyDoKhac={lyDoKhac} // lý do không lấy thuốc trước khi thanh toán
      />
    </div>
    // <div className="p-2">
    //   <div className="flex flex-col gap-2 mb-4">
    //     <BN />
    //     <Pay totalPrice={totalPrice} formik_Billing={formik_Billing} />
    //   </div>
    //   <Button
    //     onClick={showModalPay}
    //     disabled={!infoBN || BNDetail?.trangthaikhambenh === 5 || BNDetail?.trangthaikhambenh === 4}
    //     variant="contained"
    //     size="medium "
    //     color="success"
    //     className="w-full "
    //   >
    //     Thanh toán
    //   </Button>
    //   <ModalPay
    //     idLyDo={idLyDo}
    //     setIdLyDo={setIdLyDo}
    //     setCheck={setCheck}
    //     check={check}
    //     lyDo={lyDo}
    //     isModal={isModal}
    //     formik_Billing={formik_Billing}
    //     totalPrice={totalPrice}
    //     cancelModal={cancelModal}
    //   />
    // </div>
  )
}

export default BIlling

import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { sieuAmService } from '../../services/SieuAm/sieuAmService'
import { loiDanBsService } from '../../services/loiDanBS/LoiDanBsService'
import { fetchLoiDanBsDanhMuc } from '../actions/loiDanBsAction'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
// Sagas
export function* LoiDanBsSaga() {
  //----------------GET LOI DAN BÁC SĨ ---------------//
  yield takeLatest(typeAction.GET_LOI_DAN_BS_DANH_MUC, function* loiDans({ type,search }) {
    try {
      yield put({ type: typeAction.OPEN_LOADING_TABLE })
      console.log(search);
      
      const { data } = yield call(() => loiDanBsService.getAllLoiDan(search))
      yield put({
        type: typeAction.DISPATCH_LOI_DAN_DANH_MUC,
        payload: data,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_TABLE })
    }
  })
  //---------------- DELETE LỜI DẶN BÁC SĨ ---------------//
  yield takeLatest(typeAction.DELETE_LOI_DAN_BAC_SI, function* deleteLoiDan({ type, id, reload }) {
    try {
      yield call(() => loiDanBsService.deleteLoiDanById(id))
      yield reload() // reload lại dữ liệu sau khi xoá
      Toast.fire({
        icon: 'success',
        title: 'Xoá thành công !',
      })
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Xoá thất bại !',
      })
      console.log(err)
    }
  })
  //---------------- THÊM MỚI LỜI DẶN BÁC SĨ ---------------//
  yield takeLatest(
    typeAction.ADD_NEW_LOI_DAN_BAC_SI,
    function* addNewLiDan({ type, form, reload }) {
      try {
        yield call(() => loiDanBsService.postNewLoiDan(form))
        yield reload() // reload lại dữ liệu sau thêm
        Toast.fire({
          icon: 'success',
          title: 'Thêm mới thành công !',
        })
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Thêm mới thất bại !',
        })
        console.log(err)
      }
    },
  )
  //---------------- THÊM MỚI LỜI DẶN BÁC SĨ ---------------//
  yield takeLatest(typeAction.GET_INFO_LOI_DAN, function* getInfoLoiDan({ type, id }) {
    try {
      const { data } = yield call(() => loiDanBsService.getInfoLoiDan(id))
      yield put({
        type: typeAction.DISPATCH_INFO_LOI_DAN,
        payload: data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  //----------------  CẬP NHẬP LỜI DẶN ---------------//
  yield takeLatest(
    typeAction.UPDATE_LOI_DAN_BAC_SI,
    function* updateLoiDan({ type, form, reload }) {
      try {
        yield call(() => loiDanBsService.putLoiDanById(form))
        yield reload()
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật thành công !',
        })
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật thất bại !',
        })
      }
    },
  )
}

import React, { useCallback, useEffect, useState } from 'react'
import {
  ConfigProvider,
  DatePicker,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  Tooltip,
  Typography,
  notification,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { EditOutlined, SaveOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons'
import { formatNumber } from 'devextreme/localization'
import moment from 'moment'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import dayjs from 'dayjs'
import {
  DeleteKhoChiTietAction,
  updateKhoChiTietAction,
} from '../../../../store/actions/NhapKhoAction'
import 'moment/locale/vi'
import InputMSDK from './InputMSDK'
moment.locale('vi')
const { Text } = Typography
const TableDetail = ({ editingKey, setEditingKey, editTable, setEditTable, disablePrice }) => {
  const { ListThuocVT_update, infoPTNhap } = useSelector((state) => state.NhapKhoReducer)
  const [api, contextHolder] = notification.useNotification()
  const { infoUser } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const [data, setData] = useState(ListThuocVT_update)
  const isEditing = (record) => record.key === editingKey
  const edit = (record) => {
    setEditTable(true)
    setEditingKey(record.key)
  }
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    })
  }
  const cancel = () => {
    setEditingKey('')
    setEditTable(false)
    setData(ListThuocVT_update)
  }
  const save = async (key, idNhapXuat) => {
    const index = data.findIndex((item) => key === item.idChiTietNX)
    if (index !== -1) {
      const item = data[index]
      if (!item.soLo) {
        openNotificationWithIcon('warning', 'Lưu thuốc vật tư', 'Vui lòng nhập số lô')
        return
      }

      dispatch(updateKhoChiTietAction(data[index], idNhapXuat, cancel))
    } else {
      openNotificationWithIcon('error', 'Lưu thuốc vật tư', 'Lưu thất bại')
    }
    dispatch(updateKhoChiTietAction(data[index], idNhapXuat, cancel))
  }
  const convertToNumber = (str) => {
    return Number(str?.replace(/,/g, ''))
  }
  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day')
  }
  const onDelete = (id, idNhapXuat) => {
    dispatch(DeleteKhoChiTietAction(id, idNhapXuat))
  }
  const checkValidate = !(infoUser?.idnv === infoPTNhap?.nhanVienNhan || infoUser?.idnv === 1)
  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      width: 40,
      render: (text, recoder, index) => ++index,
      align: 'center',
      fixed: 'left',
      key: 'STT',
    },
    {
      title: 'Mã hàng',
      dataIndex: 'maThuoc',
      width: 100,
      fixed: 'left',
      key: 'maThuoc',
    },
    {
      title: 'Tên hàng',
      dataIndex: 'tenBietDuoc',
      width: 250,
      fixed: 'left',
      key: 'tenBietDuoc',
    },
    {
      title: 'SL chẵn',
      dataIndex: 'soLuong',
      width: 80,
      // editable: true,
      key: 'soLuong',
      render: (text, record, index) => (
        <>
          <InputNumber
            variant={isEditing(record) ? 'outlined' : 'borderless'}
            readOnly={!isEditing(record)}
            onChange={(value) => onChangeCountChan(record.key, 'soLuong', value)}
            value={text}
            formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            size="small"
            className="w-16"
          />
        </>
      ),
    },
    {
      title: 'Giá chẵn',
      dataIndex: 'giaChan',
      //   width: '40%',
      width: 100,
      key: 'giaChan',
    },
    {
      title: 'Quy cách',
      dataIndex: 'quyCach',
      //   width: '40%',
      width: 120,
      key: 'quyCach',
    },
    {
      title: 'SL lẻ',
      dataIndex: 'countLe',
      width: 80,
      key: 'countLe',
      render: (text, record, index) => (
        <>
          <Input
            type="Number"
            size="small"
            value={text}
            variant={isEditing(record) ? 'outlined' : 'borderless'}
            readOnly={!isEditing(record)}
            onChange={(e) => onChangeCountLe(record.key, 'countLe', e.target.value)}
          />
        </>
      ),
    },
    {
      title: 'Giá lẻ',
      dataIndex: 'giaLe',
      width: 80,
      key: 'giaLe',
    },
    {
      title: 'Tổng lẻ',
      dataIndex: 'soLuongLe',
      width: 60,
      key: 'soLuongLe',
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'tongTien',
      width: 80,
      key: 'tongTien',
    },
    {
      title: 'P. gia công',
      dataIndex: 'pGiaCong',
      inputType: 'text',
      key: 'pGiaCong',
      render: (text, record, index) => (
        <>
          <InputNumber
            variant={isEditing(record) ? 'outlined' : 'borderless'}
            readOnly={!isEditing(record)}
            onChange={(value) => onChangePrice(record.key, 'phiGiaCong', value)}
            value={text}
            formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            size="small"
          />
        </>
      ),
      width: 100,
    },
    {
      title: 'P. Vận chuyển',
      dataIndex: 'pVanChuyen',
      inputType: 'text',
      key: 'pVanChuyen',
      render: (text, record, index) => (
        <>
          <InputNumber
            variant={isEditing(record) ? 'outlined' : 'borderless'}
            readOnly={!isEditing(record)}
            onChange={(value) => onChangePrice(record.key, 'phiVanChuyen', value)}
            value={text}
            formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            size="small"
          />
        </>
      ),
      width: 100,
    },
    {
      title: '% CK trước VAT',
      dataIndex: 'ptckTruocVat',
      inputType: 'text',
      key: 'ptckTruocVat',
      render: (text, record, index) => (
        <>
          <Input
            type="Number"
            size="small"
            value={text}
            variant={isEditing(record) ? 'outlined' : 'borderless'}
            readOnly={!isEditing(record)}
            onChange={(e) => onChangeptCk(record.key, 'ptckTruocVat', e.target.value)}
          />
        </>
      ),
      width: 100,
    },
    {
      title: 'Tiền CK trước VAT',
      dataIndex: 'ckTruocVat',
      inputType: 'text',
      width: 130,
      key: 'ckTruocVat',
    },
    {
      title: 'Thành Tiền',
      dataIndex: 'tongTienTruocVAT',
      inputType: 'Number',
      key: 'tongTienTruocVAT',
      render: (text, record, index) => (
        <>
          <InputNumber
            variant={isEditing(record) ? 'outlined' : 'borderless'}
            readOnly={!isEditing(record)}
            onChange={(value) => priceTruocVAT(record.key, 'tongTienTruocVAT', value)}
            value={text}
            formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            size="small"
          />
        </>
      ),
      width: 100,
    },
    {
      title: '% VAT',
      dataIndex: 'ptvat',
      //   width: '40%',
      key: 'ptvat',
      align: 'center',
      render: (text, record, index) => {
        const enableEdit = isEditing(record)
        return enableEdit ? (
          <>
            <Select
              size="small"
              value={text}
              onChange={(value) => onChangeVAT(record.key, 'ptvat', value)}
              options={[
                { label: '0%', value: 0 },
                { label: '5%', value: 5 },
                { label: '8%', value: 8 },
                { label: '10%', value: 10 },
              ]}
              className="w-full"
            />
          </>
        ) : (
          <>{text}%</>
        )
      },
      width: 70,
    },
    {
      title: 'Tiền VAT',
      dataIndex: 'tienVAT',
      key: 'tienVAT',
      render: (text, record) => (
        <InputNumber
          variant={isEditing(record) ? 'outlined' : 'borderless'}
          readOnly={!isEditing(record)}
          onChange={(value) => onChangePriceVAT(record, value)}
          value={text}
          formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
          size="small"
        />
      ),
      width: 120,
    },
    {
      title: 'Thành tiền',
      dataIndex: 'thanhTien',
      //   width: '40%',
      width: 120,
      key: 'thanhTien',
    },
    {
      title: 'Thực trả',
      dataIndex: 'thucTra',
      inputType: 'Number',
      key: 'thucTra',
      render: (text, record, index) => (
        <>
          <InputNumber
            variant={isEditing(record) ? 'outlined' : 'borderless'}
            readOnly={!isEditing(record)}
            onChange={(value) => onChangePrice(record.key, 'thucTra', value)}
            value={text}
            formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            size="small"
          />
        </>
      ),
      width: 120,
    },
    {
      title: 'Nhiệt độ',
      dataIndex: 'nhietDo',
      inputType: 'text',
      width: 120,
      key: 'nhietDo',
      render: (text, record, index) => (
        <>
          <Input
            size="small"
            value={text}
            variant={isEditing(record) ? 'outlined' : 'borderless'}
            readOnly={!isEditing(record)}
            onChange={(e) => onChangeInput(record.key, 'nhietDo', e.target.value)}
          />
        </>
      ),
    },
    {
      title: 'MSDK',
      dataIndex: 'maSoDangKy',
      inputType: 'text',
      width: 180,
      key: 'maSoDangKy',
      render: (text, record, index) => (
        <>
          {/* <Input
            size="small"
            value={text}
            variant={isEditing(record) ? 'outlined' : 'borderless'}
            readOnly={!isEditing(record)}
            onChange={(e) => onChangeInput(record.key, 'maSoDangKy', e.target.value)}
          /> */}
          <InputMSDK record={record} isEditing={isEditing} onChangeInput={onChangeInput} />
        </>
      ),
    },
    {
      title: 'Số lô',
      dataIndex: 'soLo',
      inputType: 'text',
      width: 120,
      key: 'soLo',
      render: (text, record, index) => (
        <>
          <Input
            size="small"
            value={text}
            variant={isEditing(record) ? 'outlined' : 'borderless'}
            readOnly={!isEditing(record)}
            onChange={(e) => onChangeInput(record.key, 'soLo', e.target.value)}
          />
        </>
      ),
    },
    {
      title: 'Hạn dùng',
      dataIndex: 'hanDung',
      inputType: 'text',
      width: 120,
      key: 'hanDung',
      render: (text, record, index) => {
        const enableEdit = isEditing(record)
        return enableEdit ? (
          <>
            <DatePicker
              disabledDate={disabledDate}
              size="small"
              allowClear={false}
              value={dayjs(text, 'DD/MM/YYYY')}
              format={'DD/MM/YYYY'}
              onChange={(date, dateString) => onChangeDatePikcer(record.key, 'hanDung', dateString)}
              className="w-full"
            />
          </>
        ) : (
          <>{text}</>
        )
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghiChu',
      inputType: 'text',
      width: 300,
      key: 'ghiChu',
      render: (text, record, index) => (
        <>
          <Input
            size="small"
            value={text}
            variant={isEditing(record) ? 'outlined' : 'borderless'}
            readOnly={!isEditing(record)}
            onChange={(e) => onChangeInput(record.key, 'ghiChu', e.target.value)}
          />
        </>
      ),
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      width: 80,
      align: 'center',
      fixed: 'right',
      key: 'action',
      render: (_, record) => {
        const editable = isEditing(record)

        return editable ? (
          <span className="flex justify-around">
            <Typography.Link
              onClick={() => save(record.key, record.idNhapXuat)}
              style={{
                marginRight: 8,
              }}
            >
              <Tooltip title="Lưu" color="blue">
                <SaveOutlined style={{ fontSize: 18 }} />
              </Tooltip>
            </Typography.Link>
            <Typography.Link>
              <Tooltip title="Huỷ" onClick={cancel}>
                <CloseOutlined />
              </Tooltip>
            </Typography.Link>
          </span>
        ) : (
          <span className="flex justify-around">
            <Typography.Link
              disabled={editingKey !== '' || infoPTNhap?.trangThai !== 5 || checkValidate}
              onClick={() => edit(record)}
            >
              <Tooltip
                title={
                  editingKey !== '' || infoPTNhap?.trangThai !== 5 || checkValidate ? '' : 'Sửa'
                }
                color="green"
              >
                <EditOutlined
                  style={{ fontSize: 18 }}
                  className={
                    editingKey !== '' || infoPTNhap?.trangThai !== 5 || checkValidate
                      ? 'text-gray-500'
                      : 'text-green-700'
                  }
                />
              </Tooltip>
            </Typography.Link>
            <Popconfirm
              title="Bạn có chắc xoá thuốc này?"
              disabled={editingKey !== '' || infoPTNhap?.trangThai !== 5 || checkValidate}
              onConfirm={() => onDelete(record.key, record.idNhapXuat)}
            >
              <Tooltip
                title={
                  editingKey !== '' || infoPTNhap?.trangThai !== 5 || checkValidate ? '' : 'Xoá'
                }
                color="red"
              >
                <DeleteOutlined
                  className={
                    editingKey !== '' || infoPTNhap?.trangThai !== 5 || checkValidate
                      ? 'text-gray-500'
                      : 'text-red-500'
                  }
                  style={{ fontSize: 18 }}
                />
              </Tooltip>
            </Popconfirm>
          </span>
        )
      },
    },
  ]

  const onChangeInput = (key, name, value) => {
    const index = data.findIndex((item) => key === item.idChiTietNX)
    if (index !== -1) {
      const item = { ...data[index], [name]: value }
      const newData = [...data]
      newData[index] = item
      setData(newData)
    }
  }
  const onChangeptCk = (key, name, value) => {
    const index = data.findIndex((item) => key === item.idChiTietNX)
    if (index !== -1) {
      const total = (data[index].countLe + data[index].soLuongLe) * data[index].donGiaMua
      const price = total * (value / 100)
      const tienTruocVAT = total - price
      const tienVAT = (tienTruocVAT * data[index].ptvat) / 100
      const tongTien = tienVAT + tienTruocVAT
      let vat_5 = 0
      let vat_10 = 0
      let vat_8 = 0
      if (data[index].ptvat === 5) {
        vat_5 = tienVAT
      } else if (data[index].ptvat === 8) {
        vat_8 = tienVAT
      } else if (data[index].ptvat === 10) {
        vat_10 = tienVAT
      }
      const item = {
        ...data[index],
        [name]: Number(value),
        ckTruocVat: price,
        tongTienTruocVAT: tienTruocVAT,
        vat5: vat_5,
        vat8: vat_8,
        vat10: vat_10,
        thanhTien: tongTien,
        thucTra: tongTien,
      }
      const newData = [...data]
      newData[index] = item
      setData(newData)
    }
  }
  const onChangeDatePikcer = (key, name, value) => {
    const index = data.findIndex((item) => key === item.idChiTietNX)
    if (index !== -1) {
      const item = {
        ...data[index],
        [name]: moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      }
      const newData = [...data]
      newData[index] = item
      setData(newData)
    }
  }
  const onChangeCountLe = (key, name, value) => {
    const index = data.findIndex((item) => key === item.idChiTietNX)
    if (index !== -1) {
      const total = (Number(value) + data[index].soLuongLe) * data[index].donGiaMua
      const tienCK = total * (data[index].ptckTruocVat / 100)
      const tienTruocVAT = total - tienCK
      const tienVAT = (tienTruocVAT * data[index].ptvat) / 100
      const tongTien = tienVAT + tienTruocVAT
      let vat_5 = 0
      let vat_10 = 0
      let vat_8 = 0
      if (data[index].ptvat === 5) {
        vat_5 = tienVAT
      } else if (data[index].ptvat === 8) {
        vat_8 = tienVAT
      } else if (data[index].ptvat === 10) {
        vat_10 = tienVAT
      }
      const item = {
        ...data[index],
        [name]: Number(value),
        ckTruocVat: tienCK,
        tongTienTruocVAT: tienTruocVAT,
        vat5: vat_5,
        vat8: vat_8,
        vat10: vat_10,
        thanhTien: tongTien,
        thucTra: tongTien,
      }
      const newData = [...data]
      newData[index] = item
      setData(newData)
    }
  }
  const onChangeCountChan = (key, name, value) => {
    const index = data.findIndex((item) => key === item.idChiTietNX)
    if (index !== -1) {
      const count = Number(value) * data[index].soLuongDongGoi
      const total = (count + data[index].countLe) * data[index].donGiaMua
      const tienCK = total * (data[index].ptckTruocVat / 100)
      const tienTruocVAT = total - tienCK
      const tienVAT = (tienTruocVAT * data[index].ptvat) / 100
      const tongTien = tienVAT + tienTruocVAT
      let vat_5 = 0
      let vat_10 = 0
      let vat_8 = 0
      if (data[index].ptvat === 5) {
        vat_5 = tienVAT
      } else if (data[index].ptvat === 8) {
        vat_8 = tienVAT
      } else if (data[index].ptvat === 10) {
        vat_10 = tienVAT
      }
      const item = {
        ...data[index],
        [name]: Number(value),
        soLuongLe: count,
        ckTruocVat: tienCK,
        tongTienTruocVAT: tienTruocVAT,
        vat5: vat_5,
        vat8: vat_8,
        vat10: vat_10,
        thanhTien: tongTien,
        thucTra: tongTien,
      }
      const newData = [...data]
      newData[index] = item
      setData(newData)
    }
  }
  const priceTruocVAT = (key, name, value) => {
    const index = data.findIndex((item) => key === item.idChiTietNX)
    if (index !== -1) {
      const totalPrice = Number(value) / (1 - data[index].ptckTruocVat / 100)
      const tienCK = totalPrice * (data[index].ptckTruocVat / 100)
      const giaMua = totalPrice / (data[index].soLuongLe + data[index].countLe)
      const PriceVAT = Number(value) * (data[index].ptvat / 100)
      const tongTien = PriceVAT + Number(value)
      let vat_5 = 0
      let vat_10 = 0
      let vat_8 = 0

      if (data[index].ptvat === 5) {
        vat_5 = PriceVAT
      } else if (data[index].ptvat === 8) {
        vat_8 = PriceVAT
      } else if (data[index].ptvat === 10) {
        vat_10 = PriceVAT
      }
      const item = {
        ...data[index],
        [name]: Number(value),
        vat5: vat_5,
        vat8: vat_8,
        vat10: vat_10,
        ckTruocVat: tienCK,
        donGiaMua: giaMua,
        thanhTien: tongTien,
        thucTra: tongTien,
      }
      const newData = [...data]
      newData[index] = item
      setData(newData)
    }
  }
  const onChangePriceVAT = (record, value) => {
    const index = data.findIndex((item) => record.key === item.idChiTietNX)
    let vat_5 = 0
    let vat_10 = 0
    let vat_8 = 0
    if (data[index].ptvat === 5) {
      vat_5 = value
    } else if (data[index].ptvat === 8) {
      vat_8 = value
    } else if (data[index].ptvat === 10) {
      vat_10 = value
    }
    const item = {
      ...data[index],
      vat5: vat_5,
      vat8: vat_8,
      vat10: vat_10,
      thanhTien: data[index].tongTienTruocVAT + value,
      thucTra: data[index].tongTienTruocVAT + value,
    }
    const newData = [...data]
    newData[index] = item
    setData(newData)
  }
  const onChangePrice = (key, name, value) => {
    const index = data.findIndex((item) => key === item.idChiTietNX)
    if (index !== -1) {
      const item = { ...data[index], [name]: Number(value) }
      const newData = [...data]
      newData[index] = item
      setData(newData)
    }
  }
  const onChangeVAT = (key, name, value) => {
    const index = data.findIndex((item) => key === item.idChiTietNX)
    if (index !== -1) {
      let vat_5 = 0
      let vat_10 = 0
      let vat_8 = 0
      const priceVAT = data[index].tongTienTruocVAT * (value / 100)
      const totalPrice = priceVAT + data[index].tongTienTruocVAT
      if (value === 5) {
        vat_5 = priceVAT
      } else if (value === 8) {
        vat_8 = priceVAT
      } else if (value === 10) {
        vat_10 = priceVAT
      }
      const item = {
        ...data[index],
        [name]: Number(value),
        vat5: vat_5,
        vat8: vat_8,
        vat10: vat_10,
        thanhTien: totalPrice,
        thucTra: totalPrice,
      }
      const newData = [...data]
      newData[index] = item
      setData(newData)
    }
  }
  const tongTien = (editTable ? data : ListThuocVT_update)?.reduce((total, items) => {
    if (editTable) {
      return total + (items.soLuongLe + items.countLe) * items.donGiaMua
    } else {
      return total + items.soLuongLe * items.donGiaMua
    }
  }, 0)

  const tienCK = (editTable ? data : ListThuocVT_update)?.reduce(
    (total, items) => total + items.ckTruocVat,
    0,
  )
  const vat5 = (editTable ? data : ListThuocVT_update)?.reduce(
    (total, items) => total + items.vat5,
    0,
  )
  const vat8 = (editTable ? data : ListThuocVT_update)?.reduce(
    (total, items) => total + items.vat8,
    0,
  )
  const vat10 = (editTable ? data : ListThuocVT_update)?.reduce(
    (total, items) => total + items.vat10,
    0,
  )
  const thucTra = (editTable ? data : ListThuocVT_update)?.reduce(
    (total, items) => total + items.thucTra,
    0,
  )
  // data
  const dataSource = (editTable ? data : ListThuocVT_update)?.map((items) => ({
    key: items.idChiTietNX,
    maThuoc: items.thuocVatTu?.maThuoc,
    tenBietDuoc: items.thuocVatTu?.tenBietDuoc,
    soLuong: Math.floor(items.soLuongLe / items.soLuongDongGoi),
    giaChan: formatNumber(items.soLuongDongGoi * items.donGiaMua),
    quyCach: items.quyCachDongGoi,
    countLe: items.countLe,
    giaLe: formatNumber(items.donGiaMua),
    soLuongLe: editTable
      ? items.countLe + Math.floor(items.soLuongLe / items.soLuongDongGoi) * items.soLuongDongGoi
      : items.soLuongLe,
    tongTien: editTable
      ? formatNumber((items.soLuongLe + items.countLe) * items.donGiaMua)
      : formatNumber(items.soLuongLe * items.donGiaMua),
    pGiaCong: items.phiGiaCong,
    pVanChuyen: items.phiVanChuyen,
    ptckTruocVat: items.ptckTruocVat,
    ckTruocVat: formatNumber(Math.round(items.ckTruocVat)),
    tongTienTruocVAT: formatNumber(items.tongTienTruocVAT),
    tienVAT: formatNumber(items.vat5 + items.vat8 + items.vat10),
    thanhTien: formatNumber(items.thanhTien),
    thucTra: formatNumber(items.thucTra),
    nhietDo: items.nhietDo,
    maSoDangKy: items.maSoDangKy,
    soLo: items.soLo,
    hanDung: items.hanDung ? moment(items.hanDung).format('DD/MM/YYYY') : '-',
    ghiChu: items.ghiChu,
    ptvat: items.ptvat,
    idNhapXuat: items?.khoNhapXuat?.idNhapXuat,
    idThuocVatTu: items.thuocVatTu.idThuoc,
  }))
  useEffect(() => {
    setData(ListThuocVT_update)
  }, [ListThuocVT_update])
  return (
    <>
      {contextHolder}
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
            fontSize: 12,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          scroll={{
            x: 2200,
            y: 350,
          }}
          // components={{
          //   body: {
          //     cell: EditableCell,
          //   },
          // }}
          bordered
          dataSource={dataSource}
          columns={columns?.filter(
            (item) =>
              !(
                disablePrice &&
                [
                  'giaChan',
                  'giaLe',
                  'tongTien',
                  'pGiaCong',
                  'thucTra',
                  'thanhTien',
                  'thanhTien',
                  'tienVAT',
                  'ptvat',
                  'tongTienTruocVAT',
                  'pVanChuyen',
                  'ptckTruocVat',
                  'ckTruocVat',
                  'action'
                ].includes(item.key)
              ),
          )}
          rowClassName="editable-row"
          pagination={false}
          summary={(pageData) => {
            let soLuongchan = 0
            let priceChan = 0
            let soLuongle = 0
            let priceLe = 0
            let toTalLe = 0
            let totalPrice = 0
            let phiGiaCong = 0
            let phiVanChuyen = 0
            let tienTrCK = 0
            let tienTrVat = 0
            let priceVat = 0
            let ThanhTien = 0
            let ThucTra = 0
            pageData.forEach(
              ({
                soLuong,
                giaChan,
                countLe,
                giaLe,
                soLuongLe,
                tongTien,
                pGiaCong,
                pVanChuyen,
                ckTruocVat,
                tongTienTruocVAT,
                tienVAT,
                thanhTien,
                thucTra,
              }) => {
                soLuongchan += soLuong
                soLuongle += countLe
                priceChan += convertToNumber(giaChan)
                priceLe += convertToNumber(giaLe)
                toTalLe += soLuongLe
                totalPrice += convertToNumber(tongTien)
                phiGiaCong += pGiaCong
                phiVanChuyen += pVanChuyen
                tienTrCK += convertToNumber(ckTruocVat)
                tienTrVat += convertToNumber(tongTienTruocVAT)
                priceVat += convertToNumber(tienVAT)
                ThanhTien += convertToNumber(thanhTien)
                ThucTra += convertToNumber(thucTra)
              },
            )
            return (
              !disablePrice && (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      <Text type="danger">{soLuongchan}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      <Text type="danger">{formatNumber(priceChan)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}>
                      {' '}
                      <Text type="danger">{formatNumberVND(soLuongle)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>
                      <Text type="danger">{formatNumber(priceLe)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9}>
                      <Text type="danger">{formatNumber(toTalLe)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10}>
                      <Text type="danger">{formatNumber(totalPrice)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15}>
                      <Text type="danger">{formatNumber(phiGiaCong)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11}>
                      <Text type="danger">{formatNumber(phiVanChuyen)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right" index={12}></Table.Summary.Cell>
                    <Table.Summary.Cell index={13}>
                      <Text type="danger">{formatNumber(tienTrCK)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14}>
                      <Text type="danger">{formatNumber(tienTrVat)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15}>
                      <Text type="danger"></Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={16}>
                      <Text type="danger">{formatNumber(Math.round(priceVat))}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={17}>
                      <Text type="danger">{formatNumber(ThanhTien)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={18}>
                      <Text type="danger">{formatNumber(Math.round(ThucTra))}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={19}></Table.Summary.Cell>
                    <Table.Summary.Cell index={20}></Table.Summary.Cell>
                    <Table.Summary.Cell index={21}></Table.Summary.Cell>
                    <Table.Summary.Cell index={22}></Table.Summary.Cell>
                    <Table.Summary.Cell index={23}></Table.Summary.Cell>
                    <Table.Summary.Cell index={24}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            )
          }}
          footer={() =>
            !disablePrice && (
              <div className="w-full  flex justify-end text-sm">
                <ul className="flex flex-col gap-2 mt-2 mr-5">
                  <li className="flex w-60 justify-between border-b">
                    <label>Tổng tiền:</label>
                    <p>
                      {formatNumberVND(Math.round(tongTien))}
                      {/* {tongTien.toLocaleString('vi-VN')} */}
                      <span> VNĐ</span>
                    </p>
                  </li>
                  <li className="flex w-60 justify-between border-b">
                    <label>Tiền chiết khấu:</label>
                    <p>
                      <i className="text-red-500">-</i>
                      {formatNumberVND(Math.round(tienCK))}
                      <span> VNĐ</span>
                    </p>
                  </li>
                  <li className="flex w-60 justify-between border-b">
                    <label>VAT 5%:</label>
                    <p>
                      <i className="text-green-500">+</i>
                      {formatNumberVND(Math.round(vat5))}
                      <span> VNĐ</span>
                    </p>
                  </li>
                  <li className="flex w-60 justify-between border-b">
                    <label>VAT 8%:</label>
                    <p>
                      <i className="text-green-500">+</i>
                      {formatNumberVND(Math.round(vat8))}
                      <span> VNĐ</span>
                    </p>
                  </li>
                  <li className="flex w-60 justify-between border-b">
                    <label>VAT 10%:</label>
                    <p>
                      <i className="text-green-500">+</i>
                      {formatNumberVND(Math.round(vat10))}
                      <span> VNĐ</span>
                    </p>
                  </li>
                  <li className="flex w-60 justify-between ">
                    <label>Thực trả:</label>
                    <p>
                      {formatNumberVND(Math.round(thucTra))}
                      <span> VNĐ</span>
                    </p>
                  </li>
                </ul>
              </div>
            )
          }
        />
      </ConfigProvider>
    </>
  )
}

export default TableDetail

import { PrinterOutlined, SearchOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Input,
  Radio,
  Select,
  Typography,
  Popover,
} from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { childrenClinicService } from '../../../../../../services/childrenClinic/childrenClinicService'
import { khamSangLocService } from '../../../../../../services/khamSangLoc/khamSangLocService'
import {
  deleteBNThuocBSKhongKeToaAction,
  updateBNThuocBSKeToaLaiAction,
} from '../../../../../../store/actions/childrenClinicAction'
import ToaThuoc from '../../ToaThuoc/ToaThuoc'
import { generatePhieuKiemTruocTiemChungLao } from '../../../../../../utils/report/phieuKiemTruocTiemChungLao'
import { generatePhieuKiemTruocTiemChungSan } from '../../../../../../utils/report/phieuKiemTruocTiemChungSan'
import { generatePhieuKiemTruocTiemChung } from '../../../../../../utils/report/phieuKiemTruocTiemChung'
import { generateKhamBenhXuatVacXin } from '../../../../../../utils/report/vacxin/phieuKhamBenhXuatVacxin'
import { phanQuyenService } from '../../../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

const FormKSL = ({
  date,
  formBenhNhan,
  count,
  setCount,
  checkBox,
  setCheckBox,
  formik,
  setDate,
  formikKSL,
}) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const { loidan, DetailBN, ThuocBN, infoBN, DetailToaThuocBN } = useSelector(
    (state) => state.childrenClinicReducer,
  )
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { InfoBNKhamSangLoc, lichSuTiemChung } = useSelector((state) => state.khamSangLocReducer)
  const [ChanDoan, setChanDoan] = useState([])
  const [listLyDoKhongKeToa, setListLyDoKhongKeToa] = useState([])
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf('day')
  }
  const checkUpdate =
    DetailBN && DetailBN?.trangthaikhambenh !== 1 && DetailToaThuocBN?.DANHANTOA !== 0
  const [isExpanded, setIsExpanded] = useState(true)
  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }
  const handleSlected = (name) => (value) => {
    // console.log(value)
    formik.setFieldValue(name, value)
  }
  const onChangeDatePicker = (date, dateString) => {
    setDate(moment(dateString, 'DD/MM/YYYY').format())
    formik.setFieldValue('ngaytaikham', moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'))
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      fechChanDoan(keyword)
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const fechChanDoan = async (keyword) => {
    try {
      const result = await childrenClinicService.getChanDoan(keyword)
      setChanDoan(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchListLyDoKhongKeVacXin = async () => {
    try {
      const result = await khamSangLocService.getListLyDoKhongKeVacXin()
      setListLyDoKhongKeToa(result?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const onClickChanDoan = (value, options, key) => {
    const newValue = formik.values[key]
      ? formik.values[key] + ' / ' + options.mabenh + '-' + value
      : options.mabenh + '-' + value
    formik.setFieldValue(key, newValue)
    //reset lai chan doan
    fechChanDoan('')
  }
  const validateKSL = () => {
    Toast.fire({
      icon: 'error',
      title: 'Không thể chỉnh sửa, cần dược trả toa!',
    })
    return
  }
  const onClick = (e) => {
    if (
      DetailBN?.trangthaikhambenh !== 1 &&
      (DetailToaThuocBN?.BSKEDON || DetailToaThuocBN?.NGAYKEDON) &&
      DetailToaThuocBN?.DANHANTOA === 1
    ) {
      Toast.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    if (DetailBN?.trangthaikhambenh === 2 || DetailBN?.trangthaikhambenh === 1) {
      const { checked } = e.target
      // formik.setFieldValue('lydokolaythuoc', null)
      // setCheckBox(checked)
      // formik.setFieldValue('ngaytaikham', dayjs(new Date()))
      // setCount(0)
      // formik.setFieldError('ngaytaikham', null)
      formik.setFieldValue('lydokolaythuoc', null)
      formik.setFieldValue('ngaytaikham', dayjs(new Date()))
      formik.setFieldError('ngaytaikham', null)
      setDate(dayjs(new Date()))
      setCount(0)
      setCheckBox(checked)
    }
  }
  const checkQuyenBtnLuu = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT135',
      )
      handleLuuVaIn()
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const handleLuuVaIn = () => {
    formikKSL.setFieldValue('idchuyenkhoa', PkDangNhap?.idChuyenKhoa)
    const checkCacMuc = Object.entries(formikKSL.values)?.find(
      ([key, value]) => key !== 'lydo' && key !== 'ghichu' && value === null,
    )
    if (
      checkCacMuc
      // formikKSL.values.phanvedoiii === null ||
      // formikKSL.values.phanvedoii === null ||
      // formikKSL.values.benhcaptinhmantinh === null ||
      // formikKSL.values.sothathannhiet === null ||
      // formikKSL.values.suygiammiendich === null ||
      // formikKSL.values.corticoid === null ||
      // formikKSL.values.phanungtang === null ||
      // formikKSL.values.timbatthuong === null ||
      // formikKSL.values.nhipthophoibatthuong === null ||
      // formikKSL.values.trigiacbatthuong === null ||
      // formikKSL.values.cannang === null ||
      // formikKSL.values.khac === null ||
      // formikKSL.values.benhvien === null
    ) {
      Toast.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại các mục!',
      })
      return
    }
    if (
      formikKSL.values.khac === 1 &&
      (!formikKSL.values.ghichu || formikKSL.values.ghichu?.trim()?.length <= 0)
    ) {
      Toast.fire({
        icon: 'error',
        title: 'Vui lòng nhập ghi chú chống chỉ định khác!',
      })
      return
    }
    if (
      formikKSL.values.benhvien === 1 &&
      (!formikKSL.values.lydo || formikKSL.values.lydo?.trim()?.length <= 0)
    ) {
      Toast.fire({
        icon: 'error',
        title: 'Vui lòng nhập lý do khám sàng lọc tại bệnh viện!',
      })
      return
    }
    if (ThuocBN?.length > 0 && formikKSL.values.tiemchung !== 1) {
      Toast.fire({
        icon: 'error',
        title: 'Bệnh nhân không đủ điều kiện tiêm chủng!',
      })
      return
    }
    // if (ThuocBN?.length > 0) formik.setFieldValue('lydokolaythuoc', 0)
    if (
      (checkBox === false && ThuocBN?.length <= 0) ||
      (checkBox && !formik.values.lydokolaythuoc)
    ) {
      // formik.setFieldValue('lydokolaythuoc', null)
      // formik.setFieldValue('ngaytaikham', dayjs(new Date()))
      // setCount(0)
      // setCheckBox(true)

      formik.setFieldValue('lydokolaythuoc', null)
      formik.setFieldValue('ngaytaikham', dayjs(new Date()))
      formik.setFieldError('ngaytaikham', null)
      setDate(dayjs(new Date()))
      setCount(0)
      setCheckBox(true)
      Toast.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    if (
      checkBox === false &&
      ThuocBN?.find((item) => item?.soLuong <= 0 || item?.ton <= 0 || !item?.soLuong || !item?.ton)
    ) {
      Toast.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại số lượng thuốc trong toa!',
      })
      return
    }
    // formik.setFieldValue('ngaytaikham', dayjs(new Date()))
    // formik.setFieldError('ngaytaikham', null)
    // setDate(dayjs(new Date()))
    // setCount(0)
    if (DetailBN?.trangthaikhambenh === 1) {
      if (_.isEmpty(formik.errors)) {
        //nếu mà không có lỗi mới lưu
        //formik.setFieldValue('formPhuSan', formikPhuSan.values)
        formik.handleSubmit()
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Vui lòng kiểm tra lại!',
        })
      }
      return
    }
    if (DetailBN?.trangthaikhambenh === 2) {
      if (
        (DetailToaThuocBN?.BSKEDON || DetailToaThuocBN?.NGAYKEDON) &&
        DetailToaThuocBN?.DANHANTOA === 1
      ) {
        Toast.fire({
          icon: 'error',
          title: 'Dược sĩ đang thao tác!',
        })
        return
      }
      // kê lại toa khi dược chưa lưu
      if (checkBox || ThuocBN?.length < 1) {
        // lưu khi tích chọn không kê toa hoặc toa thuốc rỗng
        // dispatch(deleteBNThuocBSKhongKeToaAction(DetailBN?.idbnttc))
        dispatch(
          deleteBNThuocBSKhongKeToaAction(
            formik.values,
            formBenhNhan.values,
            DetailBN?.idbnttc,
            formik.values.lydokolaythuoc,
          ),
        )
        setCheckBox(false)
      } else {
        // lưu khi không chỉnh sửa hoặc chỉnh số lượng thuốc trong toa hoặc cho thêm thuốc vào toa
        //dispatch(updateBNThuocBSKeToaLaiAction(DetailBN?.idbnttc, ThuocBN))
        dispatch(
          updateBNThuocBSKeToaLaiAction(
            {
              ...formik.values,
              ngaytaikham: null, // tiêm chủng không có hẹn ngày tái khám,
              ngaytaikhamtiemchung: moment(date).format('YYYY-MM-DD'),
            },
            formBenhNhan.values,
            DetailBN?.idbnttc,
            ThuocBN?.map((item, index) => ({
              ...item,
              lieuDung: item?.lieuDung + '',
              soHoaDon: `${item?.soHoaDon}`,
              stt: `${++index}`,
            })),
            infoBN,
            {
              ...DetailBN,
              xuatvacxin: 1,
              formPrint: {
                infoBN: infoBN,
                infoBNTTC: DetailBN,
                infoKSL: formikKSL.values,
                tenVacXin:
                  ThuocBN?.length > 0 ? ThuocBN?.map((item) => item?.tenhoatchat).join(', ') : null,
                PkDangNhap: PkDangNhap,
              },
            },
            PkDangNhap,
            true, // luôn luôn in
          ),
        )
      }
      return
    }
  }
  const handleChangeLyDoKhongLayThuoc = (value) => {
    formik.setFieldValue('lydokolaythuoc', value)
  }
  useEffect(() => {
    fechChanDoan('')
    fetchListLyDoKhongKeVacXin()
  }, [])
  useEffect(() => {
    if (formikKSL.values.khac === 0) formikKSL.setFieldValue('ghichu', null)
    if (formikKSL.values.benhvien === 0) formikKSL.setFieldValue('lydo', null)
    if (PkDangNhap?.idChuyenKhoa === 1 || PkDangNhap?.idChuyenKhoa === 3) {
      if (formikKSL.values.phanvedoiii === 1 || formikKSL.values.khac === 1)
        formikKSL.setFieldValue('chongchidinhtiemchung', 1)
      else formikKSL.setFieldValue('chongchidinhtiemchung', 0)
      if (
        formikKSL.values.phanvedoii === 1 ||
        formikKSL.values.benhcaptinhmantinh === 1 ||
        formikKSL.values.sothathannhiet === 1 ||
        formikKSL.values.suygiammiendich === 1 ||
        formikKSL.values.corticoid === 1 ||
        formikKSL.values.phanungtang === 1 ||
        formikKSL.values.timbatthuong === 1 ||
        formikKSL.values.nhipthophoibatthuong === 1 ||
        formikKSL.values.trigiacbatthuong === 1 ||
        formikKSL.values.cannang === 1
      )
        formikKSL.setFieldValue('tamhoantiemchung', 1)
      else formikKSL.setFieldValue('tamhoantiemchung', 0)
      if (
        formikKSL.values.phanvedoiii === 0 &&
        formikKSL.values.phanvedoii === 0 &&
        formikKSL.values.benhcaptinhmantinh === 0 &&
        formikKSL.values.sothathannhiet === 0 &&
        formikKSL.values.suygiammiendich === 0 &&
        formikKSL.values.corticoid === 0 &&
        formikKSL.values.phanungtang === 0 &&
        formikKSL.values.timbatthuong === 0 &&
        formikKSL.values.nhipthophoibatthuong === 0 &&
        formikKSL.values.trigiacbatthuong === 0 &&
        formikKSL.values.cannang === 0 &&
        formikKSL.values.khac === 0
      )
        formikKSL.setFieldValue('tiemchung', 1)
      else formikKSL.setFieldValue('tiemchung', 0)
    }
  }, [formikKSL.values])
  useEffect(() => {
    if (!checkBox && ThuocBN?.length > 0) formik.setFieldValue('lydokolaythuoc', 0)
  }, [ThuocBN?.length])

  const handleSoNgayTK = (e) => {
    if (e.target.value >= 0 && e.target.value < 10000) {
      setCount(e.target.value)
      formik.setFieldValue(
        'ngaytaikham',
        moment()?.add(e.target.value, 'days')?.format('YYYY-MM-DD'),
      )
      setDate(moment()?.add(e.target.value, 'days')?.format('YYYY-MM-DD'))
    } else {
      setCount(0)
      formik.setFieldValue('ngaytaikham', moment()?.add(0, 'days')?.format('YYYY-MM-DD'))
      setDate(moment()?.add(0, 'days')?.format('YYYY-MM-DD'))
    }
  }

  const handlePrint = () => {
    const infoBn = {
      diaChi: DetailBN?.benhNhan?.diaChi,
      tenPhuongXa: DetailBN?.benhNhan?.tenPhuongXa,
      tenQuanHuyen: DetailBN?.benhNhan?.tenQuanHuyen,
      tenTinhTP: DetailBN?.benhNhan?.tenTinhTP,
    }
    const formatToaThuoc = DetailToaThuocBN?.DanhSachThuoc?.map((item) => ({
      ...item,
      soLuong: item?.soluong,
      tenhoatchat: item?.tenhoatchat,
      duongDung: item?.duongdung,
      soLanNgay: item?.solanngay,
      lieuDung: item?.lieudung,
      ghiChu: item?.ghichu,
    }))
    generateKhamBenhXuatVacXin(infoBn, DetailBN, formatToaThuoc, PkDangNhap)
  }
  const handlePrintPhieu = () => {
    const formPrint = {
      infoBN: infoBN,
      infoBNTTC: DetailBN,
      infoKSL: InfoBNKhamSangLoc,
      tenVacXin: DetailToaThuocBN?.DanhSachThuoc?.map((item) => item?.tenhoatchat).join(', '),
      PkDangNhap: PkDangNhap,
    }
    if (PkDangNhap?.idChuyenKhoa === 3) {
      //lao
      generatePhieuKiemTruocTiemChungLao(formPrint)
    } else if (PkDangNhap?.idChuyenKhoa === 2) {
      // san
      generatePhieuKiemTruocTiemChungSan(formPrint)
    } else {
      generatePhieuKiemTruocTiemChung(formPrint)
    }
  }
  const content = (
    <div className="w-32">
      <Button
        onClick={handlePrint}
        type="primary"
        disabled={!infoBN || !DetailBN || checkBox}
        style={{ marginBottom: '8px', width: '100%' }}
      >
        Phiếu chỉ định
      </Button>
      <Button
        disabled={!infoBN || !DetailBN || !InfoBNKhamSangLoc}
        type="primary"
        onClick={handlePrintPhieu}
        style={{ width: '100%' }}
      >
        Phiếu bảng kiểm
      </Button>
    </div>
  )
  return (
    <div>
      <form>
        <div className="flex gap-2 justify-end">
          {/* <div className="flex w-40 items-center">
            <label className="font-semibold w-1/2">Số ngày TK:</label>
            <Input
              disabled={checkUpdate}
              style={{ color: 'black' }}
              type="number"
              min={0}
              step={1}
              size="small"
              variant="filled"
              // readOnly
              className="w-1/2"
              value={count ?? 0}
              onChange={handleSoNgayTK}
              // onChange={(e) => {
              //   if (e.target.value >= 0) {
              //     setCount(e.target.value)
              //     formik.setFieldValue(
              //       'ngaytaikham',
              //       moment()?.add(e.target.value, 'days')?.format('YYYY-MM-DD'),
              //     )
              //   }
              // }}
            />
          </div> */}
          <div className="flex w-56 items-center">
            <label className="font-semibold w-1/2 text-end mr-2">Hẹn tái khám:</label>
            <DatePicker
              placeholder=" "
              disabled={checkUpdate || checkBox}
              style={{ color: 'black' }}
              status={
                checkBox
                  ? ''
                  : formik.errors.ngaytaikham && formik.values.ngaytaikham === null
                    ? 'error'
                    : ''
              }
              allowClear={false}
              onChange={onChangeDatePicker}
              value={formik.values.ngaytaikham === null ? '' : dayjs(formik.values.ngaytaikham)}
              size="small"
              className="w-1/2"
              format={'DD/MM/YYYY'}
              disabledDate={disabledDate}
            />
          </div>
          <Checkbox onChange={onClick} checked={checkBox} className="flex items-center">
            Không kê toa
          </Checkbox>
          {checkUpdate && (
            <Popover content={content} trigger="click" placement="bottom">
              <Button type="primary" disabled={!infoBN || !DetailBN}>
                <PrinterOutlined /> In
              </Button>
            </Popover>
          )}
          <Button
            disabled={
              !infoBN || DetailBN?.trangthaikhambenh === 4 || DetailBN?.trangthaikhambenh === 5
              // ||              InfoBNKhamSangLoc?.ngaykham
            }
            onClick={checkQuyenBtnLuu}
            type="primary"
          >
            Lưu & in
          </Button>
        </div>
        <div className={`overflow-x-hidden ${checkBox ? 'h-[38rem]' : 'h-[24rem]'}`}>
          {checkBox ? (
            <div className="flex gap-1 mt-1">
              <label className="font-semibold w-44 text-end">
                <span className="text-red-500">(*)</span> Lý do không kê toa:{' '}
              </label>
              <Select
                // disabled={checkUpdate}
                style={{ color: 'black' }}
                className="w-full"
                size="small"
                status={formik.errors.lydokolaythuoc ? 'error' : ''}
                onChange={handleChangeLyDoKhongLayThuoc}
                value={formik.values.lydokolaythuoc}
                options={listLyDoKhongKeToa.map((item) => ({
                  key: item.idld,
                  value: item.idld,
                  label: item.lydo,
                }))}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Lý do khám:</label>
            <Input
              disabled={checkUpdate}
              style={{ color: 'black' }}
              // status={formik.errors.lydokham ? 'error' : ''}
              onChange={formik.handleChange}
              value={formik.values.lydokham}
              name="lydokham"
              size="small"
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Bệnh sử:</label>
            <Input
              disabled={checkUpdate}
              style={{ color: 'black' }}
              // status={formik.errors.benhsu ? 'error' : ''}
              onChange={formik.handleChange}
              value={formik.values.benhsu}
              name="benhsu"
              size="small"
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Triệu chứng:</label>
            <Input
              disabled={checkUpdate}
              style={{ color: 'black' }}
              // status={formik.errors.trieuchung ? 'error' : ''}
              onChange={formik.handleChange}
              value={formik.values.trieuchung}
              name="trieuchung"
              size="small"
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Chẩn đoán:</label>
            <Input
              disabled={checkUpdate}
              style={{ color: 'black' }}
              onChange={formik.handleChange}
              value={formik.values.chandoan}
              name="chandoan"
              size="small"
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">IDC-10:</label>
            <Select
              disabled={checkUpdate}
              style={{ color: 'black' }}
              showSearch
              filterOption={false}
              className="w-full"
              size="small"
              onChange={(value, options) => onClickChanDoan(value, options, 'chandoan')}
              onSearch={debounceDropDown}
              suffixIcon={<SearchOutlined className=" " />}
              notFoundContent={null}
              value={null}
              options={ChanDoan?.map((items) => ({
                label: items.icD10TV,
                value: items.icD10TV,
                mabenh: items.mabenh,
              }))}
              optionRender={(options) => (
                <ul className="flex">
                  <li className="w-1/6">{options.data.mabenh}</li>
                  <li className="w-5/6 border-l px-5">{options.data.label}</li>
                </ul>
              )}
            />
            {/* <Input
              onChange={formik.handleChange}
              value={formik.values.chandoan}
              name="chandoan"
              size="small"
            /> */}
          </div>

          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Lời dặn:</label>
            <Input
              disabled={checkUpdate}
              style={{ color: 'black' }}
              //status={formik.errors.loidan ? 'error' : ''}
              onChange={formik.handleChange}
              value={formik.values.loidan}
              name="loidan"
              size="small"
            />
            {/* <Select
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={loidan?.map((items) => ({
                label: items.loiDan,
                value: items.idld,
              }))}
              showSearch
              status={formik.errors.idloidan ? 'error' : ''}
              onChange={handleSlected('idloidan')}
              value={formik.values.idloidan}
              name="loidan"
              size="small"
              className="w-full"
            /> */}
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Tiền sử bản thân:</label>
            <Input
              disabled={checkUpdate}
              style={{ color: 'black' }}
              onChange={formik.handleChange}
              value={formik.values.tiensubanthan}
              name="tiensubanthan"
              size="small"
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Chẩn đoán ban đầu:</label>
            <Input
              disabled={checkUpdate}
              style={{ color: 'black' }}
              onChange={formik.handleChange}
              value={formik.values.chandoanbandau}
              name="chandoanbandau"
              size="small"
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">ICD-10:</label>
            <Select
              disabled={checkUpdate}
              style={{ color: 'black' }}
              showSearch
              filterOption={false}
              className="w-full"
              size="small"
              onChange={(value, options) => onClickChanDoan(value, options, 'chandoanbandau')}
              onSearch={debounceDropDown}
              suffixIcon={<SearchOutlined className=" " />}
              notFoundContent={null}
              value={null}
              options={ChanDoan?.map((items) => ({
                label: items.icD10TV,
                value: items.icD10TV,
                mabenh: items.mabenh,
              }))}
              optionRender={(options) => (
                <ul className="flex">
                  <li className="w-1/6">{options.data.mabenh}</li>
                  <li className="w-5/6 border-l px-5">{options.data.label}</li>
                </ul>
              )}
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Dị ứng:</label>
            <Input
              disabled={checkUpdate}
              style={{ color: 'black' }}
              onChange={formik.handleChange}
              value={formik.values.diung}
              name="diung"
              size="small"
            />
          </div>
          {isExpanded && (
            <>
              <Typography className="text-[0.8rem] grid grid-flow-row items-center gap-2 mt-3">
                {(PkDangNhap?.idChuyenKhoa === 1 || PkDangNhap?.idChuyenKhoa === 3) && ( // ck nhi + lão thì thấy cái form ksl này
                  <>
                    <div className="grid grid-cols-6 p-1 pl-3 border bg-gray-100">
                      <div
                        className={`col-span-5 font-semibold font-sans ${ThuocBN?.map((itemVX) => lichSuTiemChung?.findIndex((itemLS) => itemVX?.idthuoc === itemLS?.idthuoc) !== -1 && ' text-red-500 ')}`}
                      >
                        1. Phản vệ độ III trở lên sau lần tiêm chủng trước (vắc xin có cùng thành
                        phần)
                      </div>
                      <div className="">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.phanvedoiii}
                          name="phanvedoiii"
                        >
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-6 p-1 pl-3 border bg-gray-100">
                      <div
                        className={`col-span-5 font-semibold font-sans ${ThuocBN?.map((itemVX) => lichSuTiemChung?.findIndex((itemLS) => itemVX?.idthuoc === itemLS?.idthuoc) !== -1 && ' text-red-500 ')}`}
                      >
                        2. Phản vệ độ II sau lần tiêm chủng trước (vắc xin có cùng thành phần)
                      </div>
                      <div className="">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.phanvedoii}
                          name="phanvedoii"
                        >
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-6 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-5 font-semibold font-sans">
                        3. Đang mắc bệnh cấp tính hoặc bệnh mãn tính tiến triển
                        <span className="text-red-500">&nbsp;(*)</span>
                      </div>
                      <div className="">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.benhcaptinhmantinh}
                          name="benhcaptinhmantinh"
                        >
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-6 p-1 pl-3 border bg-gray-100">
                      <div
                        className={`col-span-5 font-semibold font-sans ${(parseFloat(DetailBN?.nhietdo ?? 0) <= 35.5 || parseFloat(DetailBN?.nhietdo ?? 0) >= 38 || !DetailBN?.nhietdo) && ` text-red-500`}`}
                      >
                        4. Sốt/Hạ thân nhiệt (Sốt: nhiệt độ &#8805; 38&#8451; / Hạ Thân nhiệt: nhiệt
                        độ &#8804; 35.5&#8451;)
                      </div>
                      <div className="">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.sothathannhiet}
                          name="sothathannhiet"
                        >
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-6 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-5 font-semibold font-sans">
                        5. Suy giảm miễn dịch chưa xác định mức độ hoặc mức độ nặng, có biểu hiện
                        lâm sàng nghi nhiễm HIV
                      </div>
                      <div className="">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.suygiammiendich}
                          name="suygiammiendich"
                        >
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-6 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-5 font-semibold font-sans">
                        6. Đang hoặc mới kết thúc đợt điều trị corticoid liều cao (tương đương
                        prednison &gt; 2mg/kg/ngày), hóa trị, xạ trị, gammaglobulin
                        <span className="text-red-500">&nbsp;(*)</span>
                      </div>
                      <div className="">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.corticoid}
                          name="corticoid"
                        >
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-6 p-1 pl-3 border bg-gray-100">
                      <div
                        className={`col-span-5 font-semibold font-sans ${ThuocBN?.map((itemVX) => lichSuTiemChung?.findIndex((itemLS) => itemVX?.idthuoc === itemLS?.idthuoc) !== -1 && ' text-red-500 ')}`}
                      >
                        7. Phản ứng tăng dần sau các lần tiêm chủng trước (vắc xin có cùng thành
                        phần)
                      </div>
                      <div className="">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.phanungtang}
                          name="phanungtang"
                        >
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-6 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-5 font-semibold font-sans">
                        8. Nghe tim bất thường
                      </div>
                      <div className="">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.timbatthuong}
                          name="timbatthuong"
                        >
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-6 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-5 font-semibold font-sans">
                        9. Nhịp thở, nghe phổi bất thường
                      </div>
                      <div className="">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.nhipthophoibatthuong}
                          name="nhipthophoibatthuong"
                        >
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-6 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-5 font-semibold font-sans">
                        10. Tri giác bất thường (li bì hoặc kích thích)
                      </div>
                      <div className="">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.trigiacbatthuong}
                          name="trigiacbatthuong"
                        >
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-6 p-1 pl-3 border bg-gray-100">
                      <div
                        className={`col-span-5 font-semibold font-sans ${parseFloat(DetailBN?.cannang ?? 0) < 2 && ` text-red-500`}`}
                      >
                        11. Cân nặng &lt; 2 kg
                      </div>
                      <div className="">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.cannang}
                          name="cannang"
                        >
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="p-1 pl-3 border bg-gray-100">
                      <div className="grid grid-cols-6">
                        <div className="col-span-5 font-semibold font-sans">
                          12. Các chống chỉ định khác (nếu có ghi rõ)
                        </div>
                        <div className="">
                          <Radio.Group
                            onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                            value={formikKSL.values.khac}
                            name="khac"
                          >
                            <Radio value={0}>Không</Radio>
                            <Radio value={1}>Có</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                      <div className="pr-2 pb-1">
                        <Input
                          disabled={!formikKSL.values.khac || formikKSL.values.khac === 0}
                          status={
                            formikKSL.values.khac === 1 &&
                            (!formikKSL.values.ghichu ||
                              formikKSL.values.ghichu?.trim()?.length <= 0)
                              ? 'error'
                              : ''
                          }
                          size="small"
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.ghichu}
                          name="ghichu"
                        />
                      </div>
                    </div>
                    <div className="p-1 pl-3 border bg-gray-100">
                      {/* <div className='grid grid-cols-6'>
                    <div className="col-span-5 font-semibold font-sans">Đủ điều kiện tiêm chủng ngay (Tất cả đều KHÔNG có điểm bất thường)</div>
                    <div className=''>
                      <Radio.Group
                        onChange={(e) => {
                          // console.log(e.target.value)
                          if (e.target.value === 1) {
                            formikKSL.setFieldValue('chongchidinhtiemchung', 0)
                            formikKSL.setFieldValue('tamhoantiemchung', 0)
                          }
                          formikKSL.setFieldValue('tiemchung', e.target.value)
                        }}
                        value={formikKSL.values.tiemchung}
                        name="tiemchung" >
                        <Radio value={0}>Không</Radio>
                        <Radio value={1}>Có</Radio>
                      </Radio.Group>
                    </div>
                  </div> */}
                      <div>
                        <Checkbox
                          disabled={formikKSL.values.tiemchung === 0}
                          // onChange={(e) => {
                          //   if (e.target.checked) {
                          //     formikKSL.setFieldValue('chongchidinhtiemchung', 1)
                          //   } else {
                          //     formikKSL.setFieldValue('chongchidinhtiemchung', 0)
                          //   }
                          // }}
                          checked={formikKSL.values.tiemchung === 1}
                          className="font-semibold font-sans"
                        >
                          {'Đủ điều kiện tiêm chủng ngay (Tất cả đều KHÔNG có điểm bất thường)'}
                        </Checkbox>
                      </div>
                      <div>
                        <Checkbox
                          disabled={formikKSL.values.chongchidinhtiemchung === 0}
                          checked={formikKSL.values.chongchidinhtiemchung === 1}
                          className="font-semibold font-sans"
                        >
                          {'Chống chỉ định tiêm chủng (khi CÓ điểm bất thường tại các mục 1, 12)'}
                        </Checkbox>
                      </div>
                      <div>
                        <Checkbox
                          disabled={formikKSL.values.tamhoantiemchung === 0}
                          checked={formikKSL.values.tamhoantiemchung === 1}
                          className="font-semibold font-sans"
                        >
                          {
                            'Tạm hoãn tiêm chủng (khi CÓ điểm bất thường tại các mục 2, 3, 4, 5, 6, 7, 8, 9, 10, 11)'
                          }
                        </Checkbox>
                      </div>
                    </div>
                    <div className="p-1 pl-3 border bg-gray-100">
                      <div className="grid grid-cols-6">
                        <div className="col-span-5 font-semibold font-sans">
                          Đề nghị khám sàng lọc tại bệnh viện
                        </div>
                        <div className="">
                          <Radio.Group
                            onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                            value={formikKSL.values.benhvien}
                            name="benhvien"
                          >
                            <Radio value={0}>Không</Radio>
                            <Radio value={1}>Có</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                      <div className="flex justify-start pr-2 pb-1">
                        <label className="font-semibold font-sans pr-1 w-10">Lý do:</label>
                        <Input
                          disabled={!formikKSL.values.benhvien || formikKSL.values.benhvien === 0}
                          status={
                            formikKSL.values.benhvien === 1 &&
                            (!formikKSL.values.lydo || formikKSL.values.lydo?.trim()?.length <= 0)
                              ? 'error'
                              : ''
                          }
                          size="small"
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.lydo}
                          name="lydo"
                        />
                      </div>
                    </div>
                  </>
                )}

                {PkDangNhap?.idChuyenKhoa === 2 && ( // ck sản thì thấy cái form ksl này
                  <>
                    <div className="grid grid-cols-12 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-9 font-semibold font-sans">
                        1. Hiện tại bạn có đang mắc bệnh lý gì không?
                      </div>
                      <div className="col-span-3">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.benhly}
                          name="benhly"
                        >
                          <Radio value={2}>Không rõ</Radio>
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-9 font-semibold font-sans">
                        2. Bạn có dị ứng với thuốc, thức ăn hay vắc xin không?
                      </div>
                      <div className="col-span-3">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.diung}
                          name="diung"
                        >
                          <Radio value={2}>Không rõ</Radio>
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-9 font-semibold font-sans">
                        3. Bạn có bị phản ứng nặng sau những lần tiêm chủng vắc xin trước đây?
                      </div>
                      <div className="col-span-3">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.phanungnang}
                          name="phanungnang"
                        >
                          <Radio value={2}>Không rõ</Radio>
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-9 font-semibold font-sans">
                        4. Bạn có bị động kinh, hoặc bệnh lý về não, thần kinh không?
                      </div>
                      <div className="col-span-3">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.thankinh}
                          name="thankinh"
                        >
                          <Radio value={2}>Không rõ</Radio>
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-9 font-semibold font-sans">
                        5. Bạn có bị ung thư, bệnh bạch cầu, AIDS hay bất cứ rối loạn hệ thống miễn
                        dịch nào không?
                      </div>
                      <div className="col-span-3">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.ungthu}
                          name="ungthu"
                        >
                          <Radio value={2}>Không rõ</Radio>
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-9 font-semibold font-sans">
                        6. Trong vòng 3 tháng qua, bạn có điều trị cortisone, prednisone, các thuốc
                        steroide khác hay thuốc điều trị ung thư, hoặc điều trị bằng tia X không?
                      </div>
                      <div className="col-span-3">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.corsiton}
                          name="corsiton"
                        >
                          <Radio value={2}>Không rõ</Radio>
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-9 font-semibold font-sans">
                        7. Trong vòng 1 năm qua, bạn có được truyền máu hay các chế phầm từ máu,
                        hoặc globulin miễn dịch không?
                      </div>
                      <div className="col-span-3">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.truyenmau}
                          name="truyenmau"
                        >
                          <Radio value={2}>Không rõ</Radio>
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-9 font-semibold font-sans">
                        8. Đối với phụ nữ, bạn có đang mang thai hay có dự định mang thai trong
                        tháng tới không?
                      </div>
                      <div className="col-span-3">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.mangthai}
                          name="mangthai"
                        >
                          <Radio value={2}>Không rõ</Radio>
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 p-1 pl-3 border bg-gray-100">
                      <div
                        className={`col-span-9 font-semibold font-sans ${lichSuTiemChung?.length && moment().diff(lichSuTiemChung[0].ngayban, 'days') >= 28 && ` text-red-500`}`}
                      >
                        9. Bạn có được tiêm chủng vắc xin trong vòng 4 tuần vừa qua không?
                      </div>
                      <div className="col-span-3">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.tiemchunG4TUAN}
                          name="tiemchunG4TUAN"
                        >
                          <Radio value={2}>Không rõ</Radio>
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <Divider plain style={{ margin: 0, marginTop: 5 }}>
                      <div className="font-semibold m-0 p-0">
                        Dành cho bác sĩ tư vấn và thăm khám lâm sàng
                      </div>
                    </Divider>
                    <div className="grid grid-cols-12 p-1 pl-3 border bg-gray-100">
                      <div
                        className={`col-span-9 font-semibold font-sans ${(parseFloat(DetailBN?.nhietdo ?? 0) <= 35.5 || parseFloat(DetailBN?.nhietdo ?? 0) >= 38 || !DetailBN?.nhietdo) && ` text-red-500`}`}
                      >
                        10. Thân nhiệt &#8805; 37.5&#8451; hoặc &#8804; 35.5&#8451;
                      </div>
                      <div className="col-span-3">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.sothathannhiet}
                          name="sothathannhiet"
                        >
                          <Radio value={2}>Không rõ</Radio>
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-9 font-semibold font-sans">
                        11. Tri giác bất thường
                      </div>
                      <div className="col-span-3">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.trigiacbatthuong}
                          name="trigiacbatthuong"
                        >
                          <Radio value={2}>Không rõ</Radio>
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-9 font-semibold font-sans">
                        12. Nghe tim bất thường
                      </div>
                      <div className="col-span-3">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.timbatthuong}
                          name="timbatthuong"
                        >
                          <Radio value={2}>Không rõ</Radio>
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 p-1 pl-3 border bg-gray-100">
                      <div className="col-span-9 font-semibold font-sans">
                        13. Nhịp thở, nghe phổi bất thường
                      </div>
                      <div className="col-span-3">
                        <Radio.Group
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.nhipthophoibatthuong}
                          name="nhipthophoibatthuong"
                        >
                          <Radio value={2}>Không rõ</Radio>
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>Có</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="p-1 pl-3 border bg-gray-100">
                      <div className="grid grid-cols-12">
                        <div className="col-span-9 font-semibold font-sans">
                          14. Các chống chỉ định khác (nếu có ghi rõ)
                        </div>
                        <div className="col-span-3">
                          <Radio.Group
                            onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                            value={formikKSL.values.khac}
                            name="khac"
                          >
                            <Radio value={2}>Không rõ</Radio>
                            <Radio value={0}>Không</Radio>
                            <Radio value={1}>Có</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                      <div className="pr-2 pb-1">
                        <Input
                          disabled={!formikKSL.values.khac || formikKSL.values.khac !== 1}
                          status={
                            formikKSL.values.khac === 1 &&
                            (!formikKSL.values.ghichu ||
                              formikKSL.values.ghichu?.trim()?.length <= 0)
                              ? 'error'
                              : ''
                          }
                          size="small"
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.ghichu}
                          name="ghichu"
                        />
                      </div>
                    </div>
                    <div className="p-1 pl-3 border bg-gray-100">
                      <div className="grid grid-cols-12">
                        <div className="col-span-10 font-semibold font-sans">
                          Đủ điều kiện tiêm chủng ngay
                        </div>
                        <div className="col-span-2 pl-[0.85rem]">
                          <Radio.Group
                            onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                            value={formikKSL.values.tiemchung}
                            name="tiemchung"
                          >
                            <Radio value={0}>Không</Radio>
                            <Radio value={1}>Có</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                    <div className="p-1 pl-3 border bg-gray-100">
                      <div className="grid grid-cols-12">
                        <div className="col-span-10 font-semibold font-sans">
                          Đề nghị khám sàng lọc tại bệnh viện
                        </div>
                        <div className="col-span-2 pl-[0.85rem]">
                          <Radio.Group
                            onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                            value={formikKSL.values.benhvien}
                            name="benhvien"
                          >
                            <Radio value={0}>Không</Radio>
                            <Radio value={1}>Có</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                      <div className="flex justify-start pr-2 pb-1">
                        <label className="font-semibold font-sans pr-1 w-10">Lý do:</label>
                        <Input
                          disabled={!formikKSL.values.benhvien || formikKSL.values.benhvien === 0}
                          status={
                            formikKSL.values.benhvien === 1 &&
                            (!formikKSL.values.lydo || formikKSL.values.lydo?.trim()?.length <= 0)
                              ? 'error'
                              : ''
                          }
                          size="small"
                          onChange={checkUpdate ? validateKSL : formikKSL.handleChange}
                          value={formikKSL.values.lydo}
                          name="lydo"
                        />
                      </div>
                    </div>
                  </>
                )}
              </Typography>
            </>
          )}
          <div className="flex justify-center mt-1">
            <button
              type="button"
              onClick={toggleExpand}
              className="cursor-pointer flex justify-between text-blue-500 px-1 py-1 rounded-md  tracking-wider    duration-500  w-[100px]"
            >
              {isExpanded ? (
                <>
                  Thu gọn
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6 animate-bounce"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 15.75 7.5-7.5 7.5 7.5"
                    />
                  </svg>
                </>
              ) : (
                <>
                  Mở rộng
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6 animate-bounce"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </>
              )}
            </button>
          </div>
        </div>
        <div>
          {!checkBox && (
            <>
              <Divider plain style={{ margin: 5 }}>
                <p className="font-semibold">Kê vắc xin</p>
              </Divider>
              <ToaThuoc />
            </>
          )}
        </div>
      </form>
    </div>
  )
}

export default FormKSL

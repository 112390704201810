import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Input, Select, Tabs, ConfigProvider, Table, DatePicker } from 'antd'
import { searchThuocVT } from '../../../../../store/actions/NhapKhoAction'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import TableChiTiet from './TableChiTiet'
import _ from 'lodash'
function TabHangHoa({ infoPhieuNhap }) {
  const dispatch = useDispatch()
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { thuocVT, listVPP } = useSelector((state) => state.NhapKhoReducer)
  const [showAll, setShowAll] = useState(false)
  const [filteredData, setFilteredData] = useState(listVPP)
  const [selectedItem, setSelectedItem] = useState(null)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const selectRef = useRef(null)

  //------------ tính giá tiền ------------//
  const tongTien = listVPP?.reduce(
    (totalcn, cn) =>
      (totalcn += cn?.list?.reduce(
        (total, items) =>
          total +
          (items.khoChiTiet.soLuong * items.khoChiTiet.soLuongDongGoi + items.khoChiTiet.countLe) *
            items.khoChiTiet.donGiaMua,
        0,
      )),
    0,
  )
  const tienCK = listVPP?.reduce(
    (totalcn, cn) =>
      (totalcn += cn?.list?.reduce((total, items) => (total += items?.khoChiTiet?.ckTruocVat), 0)),
    0,
  )
  const vat5 = listVPP?.reduce(
    (totalcn, cn) =>
      (totalcn += cn?.list?.reduce((total, items) => (total += items?.khoChiTiet?.vat5), 0)),
    0,
  )
  const vat8 = listVPP?.reduce(
    (totalcn, cn) =>
      (totalcn += cn?.list?.reduce((total, items) => (total += items?.khoChiTiet?.vat8), 0)),
    0,
  )
  const vat10 = listVPP?.reduce(
    (totalcn, cn) =>
      (totalcn += cn?.list?.reduce((total, items) => (total += items?.khoChiTiet?.vat10), 0)),
    0,
  )
  const thucTra = listVPP?.reduce(
    (totalcn, cn) =>
      (totalcn += cn?.list?.reduce((total, items) => (total += items?.khoChiTiet?.thucTra), 0)),
    0,
  )
  const handleChoose = async (value, opt) => {
    // setSelectedItem(opt?.item?.idThuoc)
    // setListHang((prev) => [...prev, ...opt?.item])
    // dispatch(fetchInfoThuocVT(value, setSelectedItem))
    // console.log(selectedItem)
    // const validate = await checkStoreThuocVT(value)
    // validate
    //   ? dispatch(fetchInfoThuocVT(value))
    //   : openNotificationWithIcon('error', 'Chọn thuốc vật tư', 'Sản phẩm đã có ')
  }
  const handleChooseMemoized = useCallback(handleChoose, [handleChoose])
  // search lấy thông tin thuốc vật tư
  const debounceDropDown = useCallback(
    _.debounce((keyword, company) => {
      dispatch(searchThuocVT(keyword, ID_COMPANY))
    }, 300),
    [],
  )
  const handleSearch = (keyword) => {
    const value = keyword || ''
    debounceDropDown(keyword, ID_COMPANY)
  }
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'k') {
        event.preventDefault()
        if (selectRef.current) {
          selectRef.current.focus()
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const onExpandedRowsChange = (expandedRows) => {
    setExpandedRowKeys(expandedRows)
    if (expandedRows?.length === listVPP?.length) setShowAll(true)
  }
  const handleHideAll = () => {
    setExpandedRowKeys([])
    setShowAll(false)
  }
  const handleShowAll = (data) => {
    const allKeys = data?.map((item) => item?.idcn)
    setExpandedRowKeys(allKeys)
    setShowAll(true)
  }
  useEffect(() => {
    setFilteredData(listVPP)
    handleShowAll(listVPP)
  }, [listVPP])
  const columnsGroup = useMemo(
    () => [
      {
        title: (
          <p>
            Tên chi nhánh&nbsp;
            <span className="italic font-normal">
              (Kho văn phòng phẩm - {infoPhieuNhap?.formFilter?.tenct})
            </span>
          </p>
        ),
        dataIndex: 'tencn',
        key: 'tencn',
      },
      {
        title: (
          <div>
            {showAll ? (
              <Button
                disabled={!filteredData?.length}
                type="primary"
                size="small"
                onClick={handleHideAll}
              >
                Đóng tất cả
              </Button>
            ) : (
              <Button
                disabled={!filteredData?.length}
                onClick={() => handleShowAll(filteredData)}
                type="primary"
                size="small"
              >
                Hiện tất cả
              </Button>
            )}
          </div>
        ),
        dataIndex: 'action',
        key: 'action',
        width: 120,
        align: 'center',
        render: (text, record, index) => (
          <>
            {/* <Button
            disabled={!data?.length}
            size="small"
            type="primary"
            onClick={() => {
              setIsModalNhapKho(true)
              setInfoPhieuNhap({
                infodt: record,
                formFilter: {
                  idct: company,
                  tenct: listCompany?.find((item) => item?.idct === company)?.tenct,
                  idcn: chiNhanh,
                  tecn: listChiNhanh?.find((item) => item?.idchinhanh === chiNhanh)?.tenchinhanh,
                  tungay: dateFrom,
                  denngay: dateTo,
                },
              })
            }}
          >
            Nhập kho
          </Button> */}
          </>
        ),
      },
    ],
    [showAll, filteredData, infoPhieuNhap],
  )
  return (
    <>
      {/* <Select
        ref={selectRef}
        className="w-full mb-2"
        // size="small"
        showSearch
        allowClear
        //   onChange={handleChooseMemoized}
        placeholder="Nhập tên vật tư hàng hoá ( Ctrl + K ) "
        value={null}
        defaultActiveFirstOption={false}
        prefix={null}
        filterOption={false}
        onSearch={handleSearch}
        notFoundContent={null}
        options={(thuocVT || []).map((d, index) => ({
          item: d,
          key: index,
          value: d?.idThuoc,
          label: (
            <ul className="flex no-underline">
              <li className="flex w-[7.5%]">{d?.maThuoc}</li>
              <li className="flex w-[35%] border-x-2 px-2 ">{d?.tenBietDuoc}</li>
              <li className=" w-[47.5%] px-1 border-r text-start">{d?.tenDoiTac}</li>
              <li className=" w-[10%] border-l text-end">
                {formattedNumber(d?.giaMua * d?.quyCachDongGoi)} VNĐ
              </li>
            </ul>
          ),
        }))}
      /> */}
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
        }}
      >
        <Table
          scroll={{
            y: 'max-content',
          }}
          bordered
          pagination={false}
          columns={columnsGroup}
          // dataSource={[
          //   { key: 1 },
          //   { key: 2 },
          //   { key: 3 },
          //   { key: 4 },
          //   { key: 5 },
          //   { key: 6 },
          //   { key: 7 },
          //   { key: 8 },
          //   { key: 9 },
          //   { key: 10 },
          //   { key: 11 },
          //   { key: 12 },
          //   { key: 13 },
          //   { key: 14 },
          //   { key: 15 },
          //   { key: 16 },
          //   { key: 17 },
          //   { key: 18 },
          //   { key: 19 },
          //   { key: 20 },
          //   { key: 21 },
          //   { key: 22 },
          //   { key: 23 },
          //   { key: 24 },
          // ]}
          dataSource={listVPP}
          expandable={{
            expandedRowRender: (record) => {
              return <TableChiTiet selectedItem={selectedItem} infoCN={record} />
            },
            expandedRowKeys,
            onExpandedRowsChange,
          }}
          footer={() => (
            <div className="w-full  flex justify-end text-sm">
              <ul className="flex flex-col gap-2 mt-2 mr-5">
                <li className="flex w-60 justify-between border-b">
                  <label>Tổng tiền:</label>
                  <p>
                    {formattedNumber(Math.round(tongTien))}
                    {/* {tongTien.toLocaleString('vi-VN')} */}
                    <span> VNĐ</span>
                  </p>
                </li>
                <li className="flex w-60 justify-between border-b">
                  <label>Tiền chiết khấu:</label>
                  <p>
                    <i className="text-red-500">-</i>
                    {formattedNumber(Math.round(tienCK))}
                    <span> VNĐ</span>
                  </p>
                </li>
                <li className="flex w-60 justify-between border-b">
                  <label>VAT 5%:</label>
                  <p>
                    <i className="text-green-500">+</i>
                    {formattedNumber(Math.round(vat5))}
                    <span> VNĐ</span>
                  </p>
                </li>
                <li className="flex w-60 justify-between border-b">
                  <label>VAT 8%:</label>
                  <p>
                    <i className="text-green-500">+</i>
                    {formattedNumber(Math.round(vat8))}
                    <span> VNĐ</span>
                  </p>
                </li>
                <li className="flex w-60 justify-between border-b">
                  <label>VAT 10%:</label>
                  <p>
                    <i className="text-green-500">+</i>
                    {formattedNumber(Math.round(vat10))}
                    <span> VNĐ</span>
                  </p>
                </li>
                <li className="flex w-60 justify-between ">
                  <label>Thực trả:</label>
                  <p>
                    {formattedNumber(Math.round(thucTra))}
                    <span> VNĐ</span>
                  </p>
                </li>
              </ul>
            </div>
          )}
        />
      </ConfigProvider>
    </>
  )
}

export default TabHangHoa

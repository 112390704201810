import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Table, ConfigProvider, Button, DatePicker, Modal, Drawer, Select } from 'antd'
import { FileTextOutlined, SyncOutlined, DeleteOutlined } from '@ant-design/icons'

import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
import { xuatTraNCCService } from '../../../../../services/xuatTraNCC/xuatTraNCCService'
import { branchService } from '../../../../../services/branch/branchService'
import { companyService } from '../../../../../services/Company/companyService'
import { formattedNumber } from '../../../../../utils/formattedNumber'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
function ListHangHoa({ keyTabs }) {
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [isLoading, setIsLoading] = useState(false)
  const [idKhoCn, setIdKhoCn] = useState(0)
  const [keyword, setKeyword] = useState(null)
  const [isModal, setIsModal] = useState(false)
  const [listKho, setListKho] = useState([])
  const [listVTHH, setListVTHH] = useState([])
  const [listCongTy, setListCongTy] = useState([])
  const [idCongTy, setIdCongTy] = useState(ID_COMPANY)
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [idChiNhanh, setIdChiNhanh] = useState(ID_BRANCH)
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const getListVTHHDaXuat = async () => {
    try {
      setIsLoading(true)
      const { data } = await xuatTraNCCService.getListVTHHDaXuat(
        keyword?.trim()?.length ? keyword?.trim() : null,
        idCongTy,
        idChiNhanh === 0 ? null : idChiNhanh,
        dateForm,
        dateTo,
      )
      setListVTHH(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getListChiNhanhByIdCty = async (idct) => {
    try {
      setIsLoading(true)
      const { data: arrChiNhanh } = await branchService.getListBranch(idct)
      setListChiNhanh(arrChiNhanh)
    } catch (error) {
      console.log(error)
      setListChiNhanh([])
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    ;(async () => {
      try {
        const { data: arrCongTy } = await companyService.getAllListCompany()
        setListCongTy(arrCongTy)
        getListChiNhanhByIdCty(idCongTy)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  useEffect(() => {
    if (keyTabs === 3) {
      getListVTHHDaXuat()
    }
  }, [keyTabs])
  return (
    <div>
      <div className="flex justify-start items-center mb-2 gap-2">
        <div className="flex justify-start items-center w-[17rem] gap-1">
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-2xl flex items-center">-</span>
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>
        {/* <Select
          className="w-60"
          options={[
            {
              key: 0,
              value: 0,
              label: 'Tất cả các kho',
            },
            ...listKho?.map((item) => ({
              key: item?.idKhoCN,
              value: item?.idKhoCN,
              label: item?.tenKho,
            })),
          ]}
          value={idKhoCn}
          onChange={(value) => setIdKhoCn(value)}
        /> */}
        {infoUser?.idNhom !== 3 && (
          <>
            <Select
              disabled={isLoading}
              className="w-[17rem]"
              options={listCongTy?.map((item) => ({
                key: `${item?.mact}${item?.idct}`,
                value: item?.idct,
                label: item?.tenct,
              }))}
              value={idCongTy}
              onChange={(value) => {
                setIdCongTy(value)
                setIdChiNhanh(0)
                getListChiNhanhByIdCty(value)
              }}
            />
            <Select
              disabled={isLoading}
              className="w-[17rem]"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                {
                  key: `chinhanh0`,
                  value: 0,
                  label: 'Tất cả các chi nhánh',
                },
                ...listChiNhanh?.map((item) => ({
                  key: `chinhanh${item?.idChiNhanh}`,
                  value: item?.idChiNhanh,
                  label: item?.tenChiNhanh,
                })),
              ]}
              value={idChiNhanh}
              onChange={(value) => {
                setIdChiNhanh(value)
              }}
            />
          </>
        )}
        <Input
          placeholder="Tìm Mã phiếu, Mã - Tên ĐT, Mã - Tên hàng..."
          className="w-[19rem]"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Button
          onClick={() => {
            setKeyword((prev) => (prev?.length ? prev?.trim() : prev))
            getListVTHHDaXuat()
          }}
          loading={isLoading}
          type="primary"
          shape="circle"
          icon={<SyncOutlined />}
        ></Button>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 3,
            fontSize: 13,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
  
        }}
      >
        <Table
          //   scroll={{ x: 1000 }}
          //   pagination={{
          //     pageSize: 20,
          //     showSizeChanger: false,
          //   }}
          scroll={{ x: 2850, y: 695 }}
          pagination={false}
          loading={isLoading}
          bordered
          dataSource={listVTHH}
          columns={[
            //             Phiếu điều chỉnh
            // Ngày điều chỉnh
            // Kho hàng
            // Mã hàng
            // Tên hàng
            // Đơn vị
            // Số lượng tồn
            // Số lượng thực
            // Chênh lệch
            // Đơn giá
            // Thành tiền
            // Số lô
            // Hạn dùng
            // Số hóa đơn
            // Số đăng ký
            // Ghi chú
            {
              key: 1,
              title: 'STT',
              dataIndex: 'STT',
              width: 50,
              align: 'center',
              fixed: 'left',
              render: (text, record, index) => index + 1,
            },
            {
              key: 2,
              title: 'Phiếu điều chỉnh',
              dataIndex: 'soPhieu',
              width: 100,
              fixed: 'left',
              align: 'center',
              render: (text, record, index) => (
                <div className="flex justify-center items-center gap-1">
                  <FileTextOutlined className="text-lg" />
                  {text}
                </div>
              ),
            },
            {
              key: 3,
              title: 'Ngày điều chỉnh',
              dataIndex: 'ngayDieuChinh',
              width: 100,
              align: 'center',
              render: (text, record, index) => moment(text).format('DD/MM/YYYY'),
            },
            {
              key: 4,
              title: 'Kho hàng',
              dataIndex: 'tenKho',
              width: 100,
              align: 'center',
            },
            {
              key: 5,
              title: 'Mã hàng',
              dataIndex: 'maHang',
              width: 100,
              align: 'center',
            },
            {
              key: 6,
              title: 'Tên hàng',
              dataIndex: 'tenHang',
              width: 200,
              align: 'center',
            },
            {
              key: 7,
              title: 'Đơn vị',
              dataIndex: 'donVi',
              width: 100,
              align: 'center',
            },
            {
              key: 8,
              title: 'Số lượng tồn',
              dataIndex: 'soLuongTon',
              width: 100,
              align: 'center',
              render: (text, record, index) => formattedNumber(text),
            },
            {
              key: 9,
              title: 'Số lượng thực',
              dataIndex: 'soLuongThuc',
              width: 100,
              align: 'center',
              render: (text, record, index) => formattedNumber(text),
            },
            {
              key: 10,
              title: 'Chênh lệch',
              dataIndex: 'chenhLech',
              width: 100,
              align: 'center',
              render: (text, record, index) => formattedNumber(text),
            },
            {
              key: 11,
              title: 'Đơn giá',
              dataIndex: 'donGia',
              width: 100,
              align: 'center',
              render: (text, record, index) => formattedNumber(text),
            },
            {
              key: 12,
              title: 'Thành tiền',
              dataIndex: 'thanhTien',
              width: 100,
              align: 'center',
              render: (text, record, index) => formattedNumber(text),
            },
            {
              key: 13,
              title: 'Số lô',
              dataIndex: 'soLo',
              width: 100,
              align: 'center',
            },
            {
              key: 14,
              title: 'Hạn dùng',
              dataIndex: 'hanDung',
              width: 100,
              align: 'center',
              render: (text, record, index) => moment(text).format('DD/MM/YYYY'),
            },
            {
              key: 15,
              title: 'Số hóa đơn',
              dataIndex: 'soHoaDon',
              width: 100,
              align: 'center',
            },
            {
              key: 16,
              title: 'Số đăng ký',
              dataIndex: 'soDangKy',
              width: 100,
              align: 'center',
            },
            // {
            //   key: 6,
            //   title: '',
            //   dataIndex: 'ACTION',
            //   width: 40,
            //   align: 'center',
            //   fixed: 'right',
            //   render: (text, record, index) => (
            //     <>
            //       <CloseOutlined
            //         className="text-lg text-red-500 cursor-pointer flex justify-center items-center"
            //         onClick={() =>
            //           setListXuatVTHH((prev) =>
            //             prev?.filter((item) => item?.key !== record?.key),
            //           )
            //         }
            //       />
            //     </>
            //   ),
            // },
          ]}
        />
      </ConfigProvider>
    </div>
  )
}

export default ListHangHoa

import React, { useState } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import Cards from './Cards/Cards'
import History from './History/History'
import Info from './Info/Info'
import Recharge from './Modal/Recharge'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import AddNewCard from './Modal/AddNewCard'
const PaymentCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalAdd, setModalAdd] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const showModalAdd = () => {
    setModalAdd(true)
  }
  return (
    <>
      <div className="w-full h-full p-2 bg-[#E7E7E7]">
        <div className="h-full flex gap-2">
          <div
            className="w-1/3 bg-white rounded-md border h-full"
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <div className="p-2 ">
              <div className='flex justify-between mb-2'>
                <h2 className="font-semibold text-lg text-gray-500">Danh sách thẻ thanh toán</h2>
                <Button icon={<PlusOutlined />} onClick={showModalAdd} type='primary'>Tạo thẻ</Button>
              </div>

              <Cards />
            </div>
          </div>
          <div className="w-2/3 h-full gap-2  flex flex-col ">
            <div
              className="h-1/4 bg-white border rounded-md"
              style={{
                boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
              }}
            >
              <div className="p-2">
                <h2 className="font-semibold text-lg text-gray-500">Thông tin</h2>
                <Info showModal={showModal} />
              </div>
            </div>
            <div
              className="h-3/4 bg-white border rounded-md"
              style={{
                boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
              }}
            >
              <div className="p-2">
                <h2 className="font-semibold text-lg text-gray-500">Lịch sử giao dịch</h2>
                <History />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Recharge isModalOpen={isModalOpen} handleCancel={handleCancel} />
      <AddNewCard modalAdd={modalAdd} setModalAdd={setModalAdd} />
    </>
  )
}

export default PaymentCard

import { Button } from '@mui/material'
import { Input, Modal } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editCtyKhamDoanSchema } from '../../../../schemas/editCtyKhamDoanSchema'
import { editCtyCRM } from '../../../../store/actions/congtyCRMAction'
import { congtyCRMSchema } from '../../../../schemas/congtyCRMSchema'
const ModalEditCty = ({ showEdit, onClose, onLoad }) => {
  const { infoCty } = useSelector((state) => state.congtyCRMReducer)
  const { infoUser } = useSelector((state) => state.userReducer)

  const dispatch = useDispatch()
  const handleCancel = () => {
    onClose(false)
  }
  const handleEditCtyKhamDoan = (value) => {
    dispatch(editCtyCRM(value, onLoad))
    handleCancel()
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      masothue: infoCty?.masothue,
      idct: infoCty?.idct,
      mact: infoCty?.mact,
      tenct: infoCty?.tenct,
      diachi: infoCty?.diachi,
      dienthoai: infoCty?.dienthoai,
      fax: infoCty?.fax,
      email: infoCty?.email,
      website: infoCty?.website,
      ghichu: infoCty?.ghichu,
      nguoisua: infoUser?.idnv,
    },
    validationSchema: congtyCRMSchema,
    onSubmit: (value) => handleEditCtyKhamDoan(value),
  })
  return (
    <>
      <Modal
        footer={null}
        className="text-center"
        title="Chỉnh sửa công ty"
        open={showEdit}
        onCancel={handleCancel}
      >
        <form className="text-start">
          <div>
            <div>
              <label className="font-semibold">
                <span className="text-red-500">(*)</span> Mã số thuế
              </label>
              <Input
                value={formik.values.masothue}
                onChange={formik.handleChange}
                name="masothue"
                status={formik.errors.masothue ? 'error' : ''}
                readOnly={infoUser.tenNhom === 'Sales'}
              />
            </div>
            <label className="font-semibold">
              <span className="text-red-500">(*)</span> Tên công ty{' '}
              {infoCty ? `- Mã CT: ${infoCty.mact}` : ''}
            </label>
            <Input
              value={formik.values.tenct}
              onChange={formik.handleChange}
              name="tenct"
              status={formik.errors.tenct ? 'error' : ''}
              readOnly={infoUser.tenNhom === 'Sales'}
            />
          </div>
          <div>
            <label className="font-semibold">
              <span className="text-red-500">(*)</span> Địa chỉ
            </label>
            <Input
              value={formik.values.diachi}
              onChange={formik.handleChange}
              name="diachi"
              status={formik.errors.diachi ? 'error' : ''}
              readOnly={infoUser.tenNhom === 'Sales'}
            />
          </div>
          <div>
            <label className="font-semibold">
              <span className="text-red-500">(*)</span>Điện thoại
            </label>
            <Input
              value={formik.values.dienthoai}
              onChange={formik.handleChange}
              name="dienthoai"
              status={formik.errors.dienthoai ? 'error' : ''}
              readOnly={infoUser.tenNhom === 'Sales'}
            />
            {formik.errors.sodienthoai && (
              <span className="text-red-500">{formik.errors.sodienthoai}</span>
            )}
          </div>
          <div>
            <label className="font-semibold">Fax</label>
            <Input
              value={formik.values.fax}
              onChange={formik.handleChange}
              name="fax"
              readOnly={infoUser.tenNhom === 'Sales'}
            />
          </div>
          <div>
            <label className="font-semibold">Email</label>
            <Input
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
              readOnly={infoUser.tenNhom === 'Sales'}
            />
          </div>
          <div>
            <label className="font-semibold">website</label>
            <Input
              value={formik.values.website}
              onChange={formik.handleChange}
              name="website"
              readOnly={infoUser.tenNhom === 'Sales'}
            />
          </div>
          <div>
            <label className="font-semibold">Ghi Chú</label>
            <Input
              value={formik.values.ghichu}
              onChange={formik.handleChange}
              name="ghichu"
              readOnly={infoUser.tenNhom === 'Sales'}
            />
          </div>
          <div className="flex flex-col gap-2 mt-2">
            <Button
              onClick={formik.handleSubmit}
              type="submit"
              variant="contained"
              color="success"
              size="small"
              disabled={infoUser.tenNhom === 'Sales'}
            >
              Lưu
            </Button>
            <Button type="button" onClick={handleCancel} variant="outlined" size="small">
              Huỷ bỏ
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalEditCty

import React, { useRef, useState } from 'react'
import { Button, notification, List, Image, Checkbox } from 'antd'
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'
import { isWithin24Hours } from '../../../../utils/algorithm'
import { infoBNSieuAm as getInfoBNSieuAm } from '../../../../store/actions/sieuAmAction'
import { isEmpty } from 'lodash'
const Images = ({ image, setImage, listImagePrint, setListImagePrint }) => {
  const fileInputRef = useRef(null)
  const [api, contextHolder] = notification.useNotification()
  const [fileList, setFileList] = useState([])
  const { infoBNSieuAm } = useSelector((state) => state.sieuAmReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const dispatch = useDispatch()

  const openNotificationWithIcon = () => {
    api.error({
      message: 'Chọn file siêu âm ',
      description: 'Chỉ chấp nhận file hình ảnh!',
    })
  }
  const handleButtonClick = () => {
    if (isEmpty(infoBNSieuAm)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn bệnh nhân',
      })
      return
    }
    fileInputRef.current.click()
  }

  const reloadInfo = () => {
    dispatch(getInfoBNSieuAm(infoBNSieuAm?.idbnttc, infoBNSieuAm?.idbncdha))
  }

  const updateNewImage = async (listImage) => {
    const formData = new FormData()
    formData.append('IDBNSA', infoBNSieuAm?.idbnsa)
    formData.append('IDCN', PkDangNhap?.idChiNhanh)
    const data = listImage.map((item) => item.file)
    data.forEach((file) => {
      formData.append('HINHANH', file)
    })
    try {
      await https.put('BenhNhanSieuAm/UpdateFileSieuAm', formData)
      reloadInfo()
      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật file thành công',
      })
      //get new info
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'success',
        title: error?.reponse?.data?.message || 'Cập nhật file thất bại',
      })
    }
  }

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files)
    const allowedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp']
    const validFiles = files.filter((file) => allowedFileTypes.includes(file.type))
    if (validFiles.length < files.length) {
      openNotificationWithIcon()
    }
    const validFilesWithIdAndUrl = validFiles.map((file) => ({
      file,
      id: uuidv4(), // Tạo ID duy nhất với uuid
      url: URL.createObjectURL(file), // Tạo URL cho file
      checked: false,
    }))
    fileInputRef.current.value = ''
    if (infoBNSieuAm.hasOwnProperty('idttsieuam') && isWithin24Hours(infoBNSieuAm.ngaysieuam)) {
      //call api
      updateNewImage(validFilesWithIdAndUrl)
      return
    }
    setImage([...image, ...validFilesWithIdAndUrl])
  }
  const handleRemoveFile = async (item) => {
    //remove by api
    if (infoBNSieuAm.hasOwnProperty('idttsieuam') && isWithin24Hours(infoBNSieuAm.ngaysieuam)) {
      try {
        await https.delete(
          `BenhNhanSieuAm/DeleteFileSieuAm?idbnsa=${infoBNSieuAm.idbnsa}&fileKetQua=${item.url}`,
        )
        // reload
        reloadInfo()
        ToastCus.fire({
          icon: 'success',
          title: 'Đã xóa file hình ảnh',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: error?.reponse?.data?.message || 'Có lỗi khi xóa file hình ảnh',
        })
      }
      return
    }
    setImage(image.filter((f) => f.id !== item.id))
    setListImagePrint(listImagePrint.filter((f) => f.id !== item.id))
  }

  const handleCheck = (e, item) => {
    if (infoBNSieuAm.hasOwnProperty('idttsieuam')) {
      setImage(image.map((i) => (i.url === item.url ? { ...i, checked: e.target.checked } : i)))
      //đã siêu âm
      if (e.target.checked) {
        setListImagePrint([
          ...listImagePrint,
          {
            base64: item.url,
          },
        ])
      } else {
        setListImagePrint(listImagePrint.filter((f) => f.base64 !== item.url))
      }
    } else {
      setImage(image.map((i) => (i.id === item.id ? { ...i, checked: e.target.checked } : i)))
      //thêm mới siêu ám
      const reader = new FileReader()
      reader.onload = function (event) {
        const base64String = event.target.result
        // You can now use this base64 string for your needs
        if (e.target.checked) {
          setListImagePrint([
            ...listImagePrint,
            {
              base64: base64String,
              id: item.id,
            },
          ])
        } else {
          setListImagePrint(listImagePrint.filter((f) => f.id !== item.id))
        }
      }
      reader.onerror = function (error) {
        console.error('Error converting file to base64:', error)
      }
      reader.readAsDataURL(item.file)
    }
  }

  const handleUpdateImage = () => {
    fileInputRef.current.click()
  }

  return (
    <div className=" h-full">
      {contextHolder}
      <div className="flex flex-wrap gap-2">
        {image?.map((item) => (
          <div key={item.id} className="border rounded-md w-1/6 min-h-28 overflow-hidden  ">
            <div className="overflow-hidden border-b">
              <Image src={item.url} />
            </div>
            <div className=" flex justify-around items-center">
              <Checkbox checked={item.checked} onChange={(e) => handleCheck(e, item)} />
              {isWithin24Hours(infoBNSieuAm?.ngaysieuam) ? (
                <div>
                  <DeleteOutlined
                    onClick={() => handleRemoveFile(item)}
                    style={{ fontSize: 18 }}
                    className="text-red-500 p-1 cursor-pointer hover:bg-red-500 hover:text-white bg-red-100 rounded-md"
                  />
                </div>
              ) : infoBNSieuAm?.idttsieuam ? null : (
                <div>
                  <DeleteOutlined
                    onClick={() => handleRemoveFile(item)}
                    style={{ fontSize: 18 }}
                    className="text-red-500 p-1 cursor-pointer hover:bg-red-500 hover:text-white bg-red-100 rounded-md"
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="mt-2">
        {isWithin24Hours(infoBNSieuAm?.ngaysieuam) ? (
          <>
            <input
              accept="image/*"
              type="file"
              multiple
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Button onClick={handleUpdateImage} type="primary" ghost icon={<PaperClipOutlined />}>
              Thêm ảnh
            </Button>
          </>
        ) : infoBNSieuAm?.idttsieuam ? null : (
          <>
            <input
              accept="image/*"
              type="file"
              multiple
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Button onClick={handleButtonClick} type="primary" ghost icon={<PaperClipOutlined />}>
              Thêm ảnh
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default Images

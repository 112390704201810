import React, { useEffect, useState } from 'react'
import { Tooltip, Modal, Tag, Table, ConfigProvider } from 'antd'
import {
  DeleteOutlined,
  EyeOutlined,
  ExclamationCircleFilled,
  CheckCircleOutlined,
} from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { column } from './Column'
import {
  deletePhieuNhapKhoAction,
  getInfoPTNhapByIdAction,
  updateXacNhan,
} from '../../../../store/actions/NhapKhoAction'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal

const TableList = ({
  filteredData,
  setValueExport,
  showModal,
  filter,
  searchValues,
  setSearchValues,
  disablePrice,
}) => {
  const { isLoading, listPhieuNhap } = useSelector((state) => state.NhapKhoReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const [reverseData, setReverseData] = useState([])

  // disablePrice ? menu chuyển kho : menu nhập kho
  const maQuyenXemCt = disablePrice ? 'QHT606' : 'QHT166'
  const maQuyenChuyenTT = disablePrice ? 'QHT611' : 'QHT609'
  const maQuyenXoaPhieu = disablePrice ? 'QHT497' : 'QHT610'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemCt:
        showDetailProduct(infoNew?.idNhapXuat)
        break
      case maQuyenChuyenTT:
        showConfirm(infoNew?.idNhapXuat, infoNew?.maPhieu)
        break
      case maQuyenXoaPhieu:
        showDeleteConfirm(infoNew?.maPhieu, infoNew?.idNhapXuat)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemCt:
        break
      case maQuyenChuyenTT:
        break
      case maQuyenXoaPhieu:
        break
      default:
        return false
    }
  }

  // show delete phiếu thu
  const showDeleteConfirm = (maPhieu, idPhieu) => {
    confirm({
      title: 'Bạn có chắc muốn xoá phiếu nhập kho ?',
      icon: <ExclamationCircleFilled />,
      content: `Phiếu nhập kho muốn xoá là: ${maPhieu}`,
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        dispatch(deletePhieuNhapKhoAction(idPhieu, filter))
      },
      onCancel() {
        // console.log('Cancel');
      },
    })
  }

  const showConfirm = (idNhapXuat, maPhieu) => {
    confirm({
      title: 'Bạn có muốn xác nhận phiếu nhập kho này không ?',
      icon: <ExclamationCircleFilled />,
      content: `Mã phiếu : ${maPhieu}`,
      onOk() {
        dispatch(updateXacNhan(idNhapXuat, filter))
      },
      okText: 'Xác nhận',
      cancelText: 'Huỷ',
      onCancel() {
        // console.log('Cancel')
      },
    })
  }
  useEffect(() => {
    // if (listPhieuNhap?.length !== 0) {
    //   const sortedData = _.orderBy(listPhieuNhap, 'ngayNhan', 'desc')
    //   setReverseData(sortedData)
    // }
    const sortedData = _.orderBy(listPhieuNhap, 'ngayNhan', 'desc')
    setReverseData(sortedData)
  }, [listPhieuNhap])
  const onChangeTable = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }

  const showDetailProduct = (idNhapXuat) => {
    dispatch(getInfoPTNhapByIdAction(idNhapXuat))
    showModal()
  }
  const validateRole = (item) =>
    item.trangThai !== 5 || !(infoUser?.idnv === item?.nhanVienNhan || infoUser?.idnv === 1)

  useEffect(() => {
    setValueExport(filteredData(reverseData))
  }, [reverseData])

  return (
    <ConfigProvider
      theme={{
        token: {
          padding: 5,
          borderRadius: 0,
        },
        components: {
          Table: {
            rowHoverBg: '#ecf0f1',
            headerBg: '#e6e6e6',
            footerBg: '#e6e6e6',
            borderColor: '#BABABA',
          },
        },
      }}
    >
      <Table
        onChange={onChangeTable}
        rowKey={'key'}
        loading={isLoading}
        scroll={{ x: filteredData(reverseData)?.length !== 0 ? 'max-content' : 1000, y: '70vh' }}
        pagination={false}
        bordered
        dataSource={filteredData(reverseData)?.map((item) => ({
          ...item,
          status:
            item.trangThai === 5 ? (
              <Tag
                disabled
                className="cursor-pointer"
                bordered={infoUser?.idnv === item?.nhanVienNhan || infoUser?.idnv === 1}
                color={
                  infoUser?.idnv === item?.nhanVienNhan || infoUser?.idnv === 1
                    ? 'processing'
                    : 'default'
                }
                onClick={() => {
                  if (infoUser?.idnv === item?.nhanVienNhan || infoUser?.idnv === 1) {
                    dispatch(
                      putCheckQuyenThaoTacAction(
                        maQuyenChuyenTT,
                        { idNhapXuat: item?.idNhapXuat, maPhieu: item?.maPhieu },
                        actionIsTrue,
                        actionIsFalse,
                      ),
                    )
                  }
                }}
              >
                Nhập kho
              </Tag>
            ) : (
              <Tag bordered={false} color="success" icon={<CheckCircleOutlined />}>
                Đã nhập
              </Tag>
            ),
          action: (
            <div className="flex justify-center gap-5">
              <Tooltip
                title={validateRole(item) ? '' : 'Xoá'}
                className="cursor-pointer"
                color="red"
              >
                <DeleteOutlined
                  disabled={true}
                  onClick={() => {
                    if (!validateRole(item)) {
                      dispatch(
                        putCheckQuyenThaoTacAction(
                          maQuyenXoaPhieu,
                          { maPhieu: item?.maPhieu, idNhapXuat: item?.idNhapXuat },
                          actionIsTrue,
                          actionIsFalse,
                        ),
                      )
                    }
                  }}
                  className={`${validateRole(item) ? 'text-gray-400' : ' text-red-500 '} text-xl`}
                />
              </Tooltip>
              <Tooltip color="blue" className="cursor-pointer" title="Xem">
                <EyeOutlined
                  onClick={() =>
                    dispatch(
                      putCheckQuyenThaoTacAction(
                        maQuyenXemCt,
                        { idNhapXuat: item?.idNhapXuat },
                        actionIsTrue,
                        actionIsFalse,
                      ),
                    )
                  }
                  className="text-xl text-blue-500"
                />
              </Tooltip>
            </div>
          ),
        }))}
        columns={column(searchValues, setSearchValues, reverseData, showDetailProduct).filter(
          (item) => (disablePrice ? item.key !== 'TongTien' : item),
        )}
      />
    </ConfigProvider>
  )
}

export default TableList

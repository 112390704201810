import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Table, ConfigProvider, Button, DatePicker, Modal, Drawer, Select } from 'antd'
import { FileTextOutlined, SyncOutlined, DeleteOutlined } from '@ant-design/icons'
import { xuatNoiBoService } from '../../../../services/xuatNoiBo/xuatNoiBoService'
import { companyService } from '../../../../services/Company/companyService'
import { branchService } from '../../../../services/branch/branchService'
import { formattedNumber } from '../../../../utils/formattedNumber'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
function ListHangHoa({ keyTabs }) {
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [isLoading, setIsLoading] = useState(false)
  const [idKhoCn, setIdKhoCn] = useState(0)
  const [keyword, setKeyword] = useState(null)
  const [isModal, setIsModal] = useState(false)
  const [listKho, setListKho] = useState([])
  const [listVTHH, setListVTHH] = useState([])
  const [listCongTy, setListCongTy] = useState([])
  const [idCongTy, setIdCongTy] = useState(ID_COMPANY)
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [idChiNhanh, setIdChiNhanh] = useState(ID_BRANCH)
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const getListVTHHDaXuat = async () => {
    try {
      setIsLoading(true)
      const { data } = await xuatNoiBoService.getListVTHHDaXuat(
        keyword?.trim()?.length ? keyword?.trim() : null,
        idCongTy,
        idChiNhanh === 0 ? null : idChiNhanh,
        dateForm,
        dateTo,
      )
      setListVTHH(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getListChiNhanhByIdCty = async (idct) => {
    try {
      setIsLoading(true)
      const { data: arrChiNhanh } = await branchService.getListBranch(idct)
      setListChiNhanh(arrChiNhanh)
    } catch (error) {
      console.log(error)
      setListChiNhanh([])
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    ;(async () => {
      try {
        const { data: arrCongTy } = await companyService.getAllListCompany()
        setListCongTy(arrCongTy)
        getListChiNhanhByIdCty(idCongTy)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  useEffect(() => {
    if (keyTabs === 3) {
      getListVTHHDaXuat()
    }
  }, [keyTabs])
  return (
    <div>
      <div className="flex justify-start items-center mb-2 gap-2">
        <div className="flex justify-start items-center w-[17rem] gap-1">
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-2xl flex items-center">-</span>
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>
        {/* <Select
          className="w-60"
          options={[
            {
              key: 0,
              value: 0,
              label: 'Tất cả các kho',
            },
            ...listKho?.map((item) => ({
              key: item?.idKhoCN,
              value: item?.idKhoCN,
              label: item?.tenKho,
            })),
          ]}
          value={idKhoCn}
          onChange={(value) => setIdKhoCn(value)}
        /> */}
        {infoUser?.idNhom !== 3 && (
          <>
            <Select
              disabled={isLoading}
              className="w-[17rem]"
              options={listCongTy?.map((item) => ({
                key: `${item?.mact}${item?.idct}`,
                value: item?.idct,
                label: item?.tenct,
              }))}
              value={idCongTy}
              onChange={(value) => {
                setIdCongTy(value)
                setIdChiNhanh(0)
                getListChiNhanhByIdCty(value)
              }}
            />
            <Select
              disabled={isLoading}
              className="w-[17rem]"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                {
                  key: `chinhanh0`,
                  value: 0,
                  label: 'Tất cả các chi nhánh',
                },
                ...listChiNhanh?.map((item) => ({
                  key: `chinhanh${item?.idChiNhanh}`,
                  value: item?.idChiNhanh,
                  label: item?.tenChiNhanh,
                })),
              ]}
              value={idChiNhanh}
              onChange={(value) => {
                setIdChiNhanh(value)
              }}
            />
          </>
        )}
        <Input
          placeholder="Nhập từ khóa tìm kiếm..."
          className="w-[17rem]"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Button
          onClick={() => {
            setKeyword((prev) => (prev?.length ? prev?.trim() : prev))
            getListVTHHDaXuat()
          }}
          loading={isLoading}
          type="primary"
          shape="circle"
          icon={<SyncOutlined />}
        ></Button>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          //   scroll={{ x: 1000 }}
          //   pagination={{
          //     pageSize: 20,
          //     showSizeChanger: false,
          //   }}
          scroll={{ x: 2100, y: 720 }}
          pagination={false}
          loading={isLoading}
          bordered
          dataSource={listVTHH}
          columns={[
            {
              title: 'STT',
              dataIndex: 'stt',
              key: 'stt',
              width: 45,
              align: 'center',
              fixed: 'left',
              render: (text, record, index) => ++index,
            },
            {
              title: 'Ngày xuất',
              dataIndex: 'ngayxuat',
              key: 'ngayxuat',
              width: 140,
              align: 'center',
              fixed: 'left',
              render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
            },
            {
              title: 'Mã phiếu',
              dataIndex: 'maphieu',
              key: 'maphieu',
              width: 140,
              fixed: 'left',
            },
            // {
            //   title: 'Tên phiếu',
            //   dataIndex: 'tenphieu',
            //   key: 'tenphieu',
            //   width: 400,
            // },
            {
              title: 'Chi nhánh xuất',
              dataIndex: 'tenchinhanh',
              key: 'tenchinhanh',
              width: 250,
            },
            {
              title: 'Kho xuất',
              dataIndex: 'tenkho',
              key: 'tenkho',
              width: 250,
            },
            {
              title: 'Mã hàng',
              dataIndex: 'mathuoc',
              key: 'mathuoc',
              width: 140,
            },
            {
              title: 'Tên vật tư hàng hóa',
              dataIndex: 'tenthuoc',
              key: 'tenthuoc',
            },
            {
              title: 'Số lô',
              dataIndex: 'solo',
              key: 'solo',
              width: 120,
            },
            {
              title: 'Hạn Dùng',
              dataIndex: 'handung',
              key: 'handung',
              align: 'center',
              width: 100,
              render: (text, record, index) => (text ? moment(text).format(dateFormat) : '-'),
            },
            {
              title: 'Số hóa đơn',
              dataIndex: 'sohoadon',
              key: 'sohoadon',
              width: 120,
            },
            {
              title: 'ĐVT',
              dataIndex: 'dvt',
              key: 'dvt',
              width: 60,
              align: 'center',
            },
            // {
            //   title: 'Mã số đăng ký',
            //   dataIndex: 'masodangky',
            //   key: 'masodangky',
            //   width: 120,
            // },
            {
              title: 'Đơn giá',
              dataIndex: 'dongia',
              key: 'dongia',
              width: 100,
              align: 'right',
              fixed: 'right',
              render: (text, record, index) => formattedNumber(text ?? 0),
            },
            {
              title: 'Số lượng',
              dataIndex: 'soluong',
              key: 'soluong',
              width: 75,
              align: 'center',
              fixed: 'right',
            },
            {
              title: 'Thành tiền',
              dataIndex: 'thanhtien',
              key: 'thanhtien',
              width: 100,
              align: 'right',
              fixed: 'right',
              render: (text, record, index) => formattedNumber(text ?? 0),
            },
            // {
            //   title: 'Ghi chú',
            //   dataIndex: 'ghichuchitiet',
            //   key: 'ghichuchitiet',
            //   width: 250,
            // },
            // {
            //   title: '',
            //   dataIndex: 'action',
            //   key: 'action',
            //   width: 45,
            //   align: 'center',
            // },
          ]}
        />
      </ConfigProvider>
    </div>
  )
}

export default ListHangHoa

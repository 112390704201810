import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, ConfigProvider, Table, Input, Modal, Form, message } from 'antd'
import { https } from '../../../../services/apiService'
import {
  SearchOutlined,
  SyncOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'

const { confirm } = Modal

const Tinh = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [newProvince, setNewProvince] = useState({ tenTinh: '' })

  const maQuyenXemDs = 'QHT446'
  const maQuyenThem = 'QHT454'
  const maQuyenXoa = 'QHT447'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        fetchData()
        break
      case maQuyenThem:
        setIsModalVisible(true)
        break
      case maQuyenXoa:
        showDeleteConfirm(infoNew?.idTinh)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenThem:
        setIsModalVisible(false)
        break
      case maQuyenXoa:
        //
        break
      default:
        return false
    }
  }

  const fetchData = async () => {
    try {
      const response = await https.get('/tinh')
      setData(response?.data)
      setFilteredData(
        response?.data?.filter((item) => item?.tenTinh?.toLowerCase().includes(searchText)),
      )
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  const onLoad = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  useEffect(() => {
    onLoad()
  }, [])

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase()
    setSearchText(value)
    const filtered = data.filter((item) => item.tenTinh?.toLowerCase().includes(value))
    setFilteredData(filtered)
  }

  const handleAddProvince = async () => {
    try {
      const response = await https.post('/tinh/CreateTinhTP', newProvince)
      onLoad()
      // Refresh data after adding
      // const newResponse = await https.get('/tinh')
      // setData(newResponse.data)
      // setFilteredData(newResponse.data)
      setIsModalVisible(false)
      setNewProvince({ tenTinh: '' })
      message.success('Đã thêm tỉnh thành công')
    } catch (error) {
      console.error('Error adding province:', error)
      message.error('Thêm tỉnh thất bại')
    }
  }

  const showDeleteConfirm = (idTinh) => {
    confirm({
      title: 'Bạn có chắc chắn muốn xóa tỉnh này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Xóa',
      okType: 'danger',
      cancelText: 'Hủy',
      onOk() {
        handleDeleteProvince(idTinh)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const handleDeleteProvince = async (idTinh) => {
    try {
      await https.delete(`/tinh/DeleteTinhTP?idTinh=${idTinh}`)
      onLoad()
      // const updatedData = data.filter((item) => item.idTinh !== idTinh)
      // setData(updatedData)
      // setFilteredData(updatedData)
      message.success('Đã xóa thành công')
    } catch (error) {
      console.error('Error deleting province:', error)
      message.error('Xóa tỉnh thất bại')
    }
  }
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10, // or whatever default page size you want
  })
  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination)
  }
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      render: (text, record, index) => {
        return (
          // Calculate STT based on pagination
          (pagination.current - 1) * pagination.pageSize + index + 1
        )
      },
    },
    {
      title: 'Tên Tỉnh',
      dataIndex: 'tenTinh',
      key: 'tenTinh',
      sorter: (a, b) => (a.tenTinh || '').localeCompare(b.tenTinh || ''),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Hành động',
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
          {/* <Button type="primary" size="small" icon={<EditOutlined />}>
            Sửa
          </Button> */}
          <Button
            type="primary"
            danger
            size="small"
            icon={<DeleteOutlined />}
            onClick={() =>
              dispatch(putCheckQuyenThaoTacAction(maQuyenXoa, record, actionIsTrue, actionIsFalse))
            }
          >
            Xóa
          </Button>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="flex justify-between mb-4">
        <Input
          placeholder="Tìm kiếm Tỉnh/Thành phố"
          value={searchText}
          onChange={handleSearch}
          style={{ width: 250 }}
          prefix={<SearchOutlined />}
        />
        <Button
          onClick={onLoad}
          className="ml-4 mr-auto"
          type="primary"
          shape="circle"
          icon={<SyncOutlined />}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() =>
            dispatch(putCheckQuyenThaoTacAction(maQuyenThem, null, actionIsTrue, actionIsFalse))
          }
        >
          Thêm Tỉnh/Thành phố
        </Button>
      </div>

      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey="idTinh"
          pagination={pagination}
          onChange={handleTableChange}
        />
      </ConfigProvider>

      <Modal
        title="Thêm Tỉnh/Thành phố"
        visible={isModalVisible}
        onOk={handleAddProvince}
        onCancel={() => setIsModalVisible(false)}
        okText="Lưu"
        cancelText="Hủy"
      >
        <Form layout="vertical">
          <Form.Item label="Tên Tỉnh/Thành phố">
            <Input
              value={newProvince.tenTinh}
              onChange={(e) => setNewProvince({ ...newProvince, tenTinh: e.target.value })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default Tinh

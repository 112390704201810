import React, { useEffect, useState } from 'react'
import { Input, InputNumber, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import { createPhongKhamSchema } from '../../../../schemas/createPhongKhamSchema'
import {
  ListDichVuAction,
  addNewDichVuAction,
  getAllDichVu,
} from '../../../../store/actions/dichVuAction'
import jsPDF from 'jspdf'
import { timesRegular } from '../../../../assets/fonts/base64/times'
import ToastCus from '../../../../utils/Toast'
const ModalCreate = ({ isModalCreate, handleCancel, getListPhongKham }) => {
  const dispatch = useDispatch()
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { groupsDichVu } = useSelector((state) => state.dichVuReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [listChuyenKhoa, setListChuyenKhoa] = useState([])
  const onLoad = () => {
    // dispatch(getAllDichVu())
    // dispatch(ListDichVuAction(searchGroup, company))
  }
  // xử lí form thêm dịch vụ
  const handleSubmit = async (value) => {
    //dispatch(addNewDichVuAction(value, onLoad))
    try {
      await https.post('PhongKham/AddPhongKham', value)
      getListPhongKham()
      // getListBranchUse(rowActive?.idPK, company)
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
      formik.resetForm()
      handleCancel()
    } catch (error) {
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      maPK: '',
      tenPK: '',
      nguoiTao: infoUser?.tenNV,
      // ngayTao: "2024-08-07T07:40:05.412Z",
      suDung: 1,
      ghiChu: '',
      //idKhoMacDinh: 0,
      idChuyenKhoa: null,
      // nguoiSua: "string",
      // ngaySua: "2024-08-07T07:40:05.412Z",
      //tenChuyenKhoa: "string"
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: createPhongKhamSchema,
  })

  const getAllChuyenKhoa = async () => {
    try {
      const { data } = await https.get('ChuyenKhoa/AllChuyenKhoa')
      setListChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAllChuyenKhoa()
  }, [])

  const ChangeSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }

  return (
    <>
      <Modal
        style={{
          top: 20,
        }}
        okText="Thêm"
        cancelText="Hủy"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Tạo phòng khám</h2>}
        onOk={formik.handleSubmit}
        open={isModalCreate}
        onCancel={handleCancel}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">
              <span className="text-sx text-red-500">(*)&nbsp;</span>Mã phòng khám
            </label>
            <Input
              value={formik.values.maPK}
              name="maPK"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập mã phòng khám..."
              status={formik.errors.maPK && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">
              <span className="text-sx text-red-500">(*)&nbsp;</span>Tên phòng khám
            </label>
            <Input
              value={formik.values.tenPK}
              name="tenPK"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập tên phòng khám..."
              status={formik.errors.tenPK && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold text-base">
              <span className="text-sx text-red-500">(*)&nbsp;</span>Chuyên khoa
            </label>
            {/* <Input
              value={formik.values.tenDichVu}
              name="tenDichVu"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập tên dịch vụ"
              status={formik.errors.tenDichVu && 'error'}
            /> */}
            <Select
              placeholder="Chọn chuyên khoa..."
              className="w-full"
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              onChange={(value) => formik.setFieldValue('idChuyenKhoa', value)}
              value={formik.values.idChuyenKhoa}
              status={formik.errors.idChuyenKhoa && 'error'}
              options={listChuyenKhoa?.map((items) => ({
                label: items.tenChuyenKhoa,
                value: items.idChuyenKhoa,
                key: items.idChuyenKhoa,
              }))}
            />
          </div>
          {/* <div>
            <label className="font-semibold  text-base">Ghi chú </label>
            <Input
              value={formik.values.ghiChu}
              onChangeCapture={formik.handleChange}
              name="ghiChu"
            />
          </div> */}
        </form>
      </Modal>
    </>
  )
}

export default ModalCreate

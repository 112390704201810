import { ContainerOutlined, SearchOutlined } from '@ant-design/icons'
import { Tooltip } from '@mui/material'
import { Button, ConfigProvider, Empty, Input, Space, Table } from 'antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import LayoutApp from '../../../HOCs/LayoutApp'
import {
  getAllGoiKhamKhachHangCRM,
  getGoiKhamKhachHangCRMById,
} from '../../../store/actions/goikhamkhachhangCRMAction'
import { getAllKhachHangCRM } from '../../../store/actions/khachhangCRMAction'
import ModalAddGoiKhamKH from './Modal/ModalAdd'
import ModalEditGoiKhamKH from './Modal/ModalEdit'
import { getGoiKhamKhachHangCRMByKHId } from '../../../store/actions/goikhamCRMAction'
import 'moment/locale/vi'
moment.locale('vi')

function GoiKhamKhachHang(props) {
  const { listKH } = useSelector((state) => state.khachhangCRMReducer)
  const { listGoiKhamKH } = useSelector((state) => state.goikhamkhachhangCRMReducer)
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState({
    open: false,
    data: null,
  })
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [activeRow, setActiveRow] = useState(null)

  useEffect(() => {
    dispatch(getAllKhachHangCRM())
  }, [])

  const handleShowChitiet = (item) => {
    dispatch(getGoiKhamKhachHangCRMByKHId(item.idkh))
    setOpenEdit({
      open: true,
      data: item,
    })
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã KH',
      dataIndex: 'makh',
      width: 80,
      align: 'center',
      key: 'makh',
      ...getColumnSearchProps('makh'),
    },
    {
      title: 'Tên Khách hàng',
      dataIndex: 'tenkh',
      key: 'tenkh',
      ...getColumnSearchProps('tenkh'),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 50,
      align: 'center',
      key: 'action',
    },
  ]

  const columnsChiTiet = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã GK',
      dataIndex: 'idgk',
      key: 'idgk',
      width: 80,
      align: 'center',
      ...getColumnSearchProps('idgk'),
    },
    {
      title: 'Gói khám',
      dataIndex: 'tengoikham',
      key: 'tengoikham',
      ...getColumnSearchProps('tengoikham'),
    },
    {
      title: 'Từ ngày',
      dataIndex: 'khamtungay',
      align: 'center',
      key: 'khamtungay',
      width: 150,
    },
    {
      title: 'Đến ngày',
      dataIndex: 'khamdenngay',
      align: 'center',
      key: 'khamdenngay',
      width: 150,
    },
  ]
  const handleClickRow = (item) => {
    setActiveRow(item)
    dispatch(getGoiKhamKhachHangCRMByKHId(item.idkh))
  }

  return (
    <>
      <div className="p-1 h-full">
        <div className="bg-[#efefef] rounded-md h-full">
          <div className="flex gap-1 h-full p-2 bg-[#efefef]">
            <div className="w-1/3 p-2 h-full bg-white rounded-md">
              <div>
                <div className="flex mb-2">
                  <div className="ml-auto">
                    <Button type="primary" onClick={() => setOpen(true)}>
                      Đăng kí gói khám
                    </Button>
                  </div>
                </div>
                <ConfigProvider
                  theme={{
                    token: {
                      padding: 5,
                    },
                    components: {
                      Table: {
                        rowHoverBg: '#ecf0f1',
                        headerBg: '#e6e6e6',
                        footerBg: '#e6e6e6',
                        borderColor: '#BABABA',
                      },
                    },
            
                  }}
                >
                  <Table
                    pagination={false}
                    onRow={(record) => ({
                      onClick: () => {
                        handleClickRow(record)
                      },
                    })}
                    bordered
                    columns={columns}
                    dataSource={listKH?.map((item) => ({
                      ...item,
                      action: (
                        <ul className="flex gap-2 justify-around">
                          <li className="text-lg">
                            <Tooltip title="Chi tiết" color="#108ee9">
                              <ContainerOutlined
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleShowChitiet(item)
                                }}
                                className="text-xl text-[#108ee9]  cursor-pointer"
                              />
                            </Tooltip>
                          </li>
                        </ul>
                      ),
                    }))}
                  />
                </ConfigProvider>
              </div>
            </div>
            <div className="w-2/3 h-full bg-white rounded-md">
              <div className="p-2 h-full">
                <h1>
                  Tên khách hàng:{' '}
                  <span className="text-gray-600 font-bold text-lg mb-2">{activeRow?.tenkh}</span>
                </h1>
                {listGoiKhamKH?.length ? (
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 5,
                      },
                      components: {
                        Table: {
                          rowHoverBg: '#ecf0f1',
                          headerBg: '#e6e6e6',
                          footerBg: '#e6e6e6',
                          borderColor: '#BABABA',
                        },
                      },
              
                    }}
                  >
                    <Table
                      pagination={false}
                      bordered
                      columns={columnsChiTiet}
                      dataSource={listGoiKhamKH?.map((item) => ({
                        ...item,
                        tengoikham: item.crmGoiKham.tengoikham,
                        khamtungay: moment(item.crmGoiKham.khamtungay).format('DD/MM/YYYY'),
                        khamdenngay: moment(item.crmGoiKham.khamdenngay).format('DD/MM/YYYY'),
                      }))}
                    />
                  </ConfigProvider>
                ) : (
                  <Empty description="Chưa đăng ký gói khám" />
                )}
              </div>
            </div>
          </div>
        </div>
        {open && <ModalAddGoiKhamKH open={open} setOpen={setOpen} />}
        {openEdit && <ModalEditGoiKhamKH openEdit={openEdit} setOpenEdit={setOpenEdit} />}
      </div>
    </>
  )
}

GoiKhamKhachHang.propTypes = {}

export default GoiKhamKhachHang

import { Button, ConfigProvider, Input, Select, Table, Tag, TreeSelect } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchOutlined } from '@ant-design/icons'
import CachedIcon from '@mui/icons-material/Cached'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { khoVTservice } from '../../../services/khoVT/khoVTservice'
import _ from 'lodash'
import { formatNumber } from 'devextreme/localization'
import { exportExcelformat } from '../../../utils/export/exportExcelFormat'
import { phanQuyenService } from '../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../store/actions/phanQuyenAction'
import ToastCus from '../../../utils/Toast'
const styleButton = {
  Button: {
    defaultColor: 'green',
    defaultBorderColor: 'white',
    defaultHoverColor: 'green',
    defaultHoverBorderColor: 'white',
  },
}
const { SHOW_PARENT } = TreeSelect
const GiaThuoc = () => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const [Groups, setGroups] = useState([])
  const [value, setValue] = useState(['all'])
  const [type, setType] = useState([])
  const [idType, setIdType] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100,
  })
  // ------ get nhom thuoc vat tu  ------//
  const fetchThuocVatTu = async (value) => {
    const { data } = await khoVTservice.getListGroupsThuocVT(value)
    setGroups(data)
  }
  const debounceFn = useCallback(
    _.debounce((e) => {
      setSearchText(e.target.value)
    }, 300),
    [],
  )
  const onChangeType = (value) => {
    setIdType(value)

    fetchThuocVatTu(value)
    setValue(['all'])
  }
  //-------- export excel-----------//
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT297',
      )
      const formattedData = data?.map((items, idx) => ({
        STT: idx + 1,
        'Mã hàng': items.mathuoc,
        'Tên hàng': items.tenbietduoc,
        'Hoạt chất': items.tenhoatchat,
        'Đơn vị tính': items.dvt,
        'Đơn vị dùng': items.donvidung,
        'Cách dùng': items.cachdung,
        'Giá bán': items.giaban ? formatNumber(items.giaban) : 0,
        Loại: items.phanloai,
        'Nhóm hàng': items.nhom,
      }))
      const name = 'Bảng giá'
      formattedData?.length && exportExcelformat(formattedData, name)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  //--------- fetchData ---------//
  const fetchData = async () => {
    let idnhom = null
    setPagination((prevState) => ({ ...prevState, current: 1 }))
    if (value?.length > 0 && value[0] !== 'all') {
      idnhom = value.map((id) => `&idnhom=${id}`).join('&')
    } else {
      idnhom = ''
    }
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT560',
      )

      const { data } = await khoVTservice.getThuocVatTuGiaBan(
        searchText,
        ID_COMPANY,
        idnhom,
        idType,
      )
      setData(data)
      setIsLoading(false)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setIsLoading(false)
    }
  }
  //----------- lấy danh sách loại nhóm thuốc vật tư ----------------//
  const fetchType = async () => {
    try {
      const { data } = await khoVTservice.getListTypesThuocVT()
      setType(data)
    } catch (error) {
      console.log(error)
    }
  }
  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      key: '0-1',
      children: Groups?.map((groups, index) => ({
        title: groups.nhom,
        value: groups.idNhom,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const handleTableChange = (pagination) => {
    setPagination(pagination)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn nhóm thuốc',
    filterTreeNode: (input, treeNode) => {
      const title = treeNode.title || '' // Nếu title là null hoặc undefined, gán giá trị mặc định là chuỗi rỗng
      return title.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const compare = (a, b, dataIndex) => {
    if (a[dataIndex] < b[dataIndex]) {
      return -1
    }
    if (a[dataIndex] > b[dataIndex]) {
      return 1
    }
    return 0
  }
  useEffect(() => {
    fetchData()
  }, [searchText])
  useEffect(() => {
    fetchType()
    fetchThuocVatTu('')
  }, [])
  return (
    <div className="bg-[#EFEFEF] h-full p-3 ">
      <div className="bg-white h-full border rounded-xl  p-2">
        <div className="p-2 flex justify-between ">
          <div className="flex gap-2">
            <Input
              onChange={debounceFn}
              className="w-72"
              placeholder="Nhập từ khoá tìm kiếm"
              prefix={
                <SearchOutlined
                  style={{
                    color: 'rgba(0,0,0,.25)',
                  }}
                />
              }
            />

            <ConfigProvider
              theme={{
                components: {
                  ...styleButton,
                },
              }}
            >
              <Button
                disabled={!data?.length}
                onClick={exportToExcel}
                type="default"
                icon={<FileDownloadIcon />}
              >
                Excel
              </Button>
            </ConfigProvider>
          </div>
          <div className="flex gap-2">
            <Button onClick={fetchData} type="primary" icon={<CachedIcon />}>
              Tải lại
            </Button>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onChange={onChangeType}
              className="w-60"
              value={idType}
              options={[
                { label: 'Tất cả', value: '' },
                ...type?.map((item) => ({
                  label: item.phanLoai,
                  value: item.idPhanLoai,
                })),
              ]}
            />
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-72 h-8 overflow-clip"
            />
          </div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              // borderRadius: 0,
              padding: 5,
            },
            components: {
              Table: {
                headerBg: '#EDF2F7',
                rowHoverBg: '#d5e2e7',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            onChange={handleTableChange}
            scroll={{
              x: 1500,
              y: '72vh',
            }}
            pagination={{
              ...pagination,
              showSizeChanger: false,
            }}
            dataSource={data}
            loading={isLoading}
            bordered
            columns={[
              {
                align: 'center',
                title: 'STT',
                key: 1,
                width: 60,
                render: (text, _, index) =>
                  (pagination.current - 1) * pagination.pageSize + index + 1,
              },
              {
                title: 'Mã thuốc',
                key: 2,
                width: 100,
                dataIndex: 'mathuoc',
                sorter: (a, b) => compare(a, b, 'mathuoc'),
                showSorterTooltip: false,
              },
              {
                title: 'Tên thuốc',
                key: 3,
                dataIndex: 'tenbietduoc',
                sorter: (a, b) => compare(a, b, 'tenbietduoc'),
                showSorterTooltip: false,
              },
              {
                title: 'Hoạt chất',
                key: 4,
                dataIndex: 'tenhoatchat',
                sorter: (a, b) => compare(a, b, 'tenhoatchat'),
                showSorterTooltip: false,
              },
              {
                title: 'Đơn vị tính',
                key: 5,
                width: 100,
                dataIndex: 'dvt',
                align: 'center',
                sorter: (a, b) => compare(a, b, 'dvt'),
                showSorterTooltip: false,
              },
              {
                title: 'Đơn vị dùng',
                key: 6,
                width: 110,
                dataIndex: 'donvidung',
                align: 'center',
                sorter: (a, b) => compare(a, b, 'donvidung'),
                showSorterTooltip: false,
              },
              {
                title: 'Cách dùng',
                key: 7,
                width: 150,
                dataIndex: 'cachdung',
                sorter: (a, b) => compare(a, b, 'cachdung'),
                showSorterTooltip: false,
              },
              {
                title: 'Giá bán',
                align: 'right',
                key: 8,
                width: 100,
                dataIndex: 'giaban',
                render: (text) =>
                  text ? (
                    <Tag color="blue" bordered={false} className="font-semibold">
                      {formatNumber(text)} VNĐ
                    </Tag>
                  ) : (
                    <Tag color="green" bordered={false} className="font-semibold">
                      0 VNĐ
                    </Tag>
                  ),
                sorter: (a, b) => compare(a, b, 'giaban'),
                showSorterTooltip: false,
              },
              {
                title: 'Loại',
                key: 9,
                width: 180,
                dataIndex: 'phanloai',
                sorter: (a, b) => compare(a, b, 'phanloai'),
                showSorterTooltip: false,
              },
              {
                title: 'Nhóm hàng',
                key: 10,
                width: 280,
                dataIndex: 'nhom',
                sorter: (a, b) => compare(a, b, 'nhom'),
                showSorterTooltip: false,
              },
            ]}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default GiaThuoc

import { useEffect } from 'react';

const useWebSocket = ( onMessage) => {
    const url = `wss://${process.env.REACT_APP_DOMAIN}/ws`
  useEffect(() => {
    const socket = new WebSocket(url);
    // Khi có dữ liệu từ server
    socket.onmessage = (event) => {
      if (onMessage) {
        onMessage(event.data);
      }
    };

    // Cleanup khi component unmount
    return () => {
      socket.close();
    };
  }, [url, onMessage]);
};

export default useWebSocket;
import { Checkbox, Input, InputNumber, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState, useRef } from 'react'
import Barcode from 'react-barcode'
import { QRCode } from 'antd'
import { addKhoVTSchema } from '../../../../schemas/addKhoVTSchema'
import { useDispatch, useSelector } from 'react-redux'
import {
  allTypesVT_action,
  fetchListAllGroupsVT_Action,
  updateVT_Action,
} from '../../../../store/actions/thuocVTAction'
import { updateThuocVTSchema } from '../../../../schemas/updateThuocVTSchema'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import moment from 'moment'
import 'moment/locale/vi'
import style from './style.module.css'
moment.locale('vi')
const toDay = moment()

const ModalDetail = ({ isModalDetail, CancelModalDetail }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listDoiTac } = useSelector((state) => state.NhapKhoReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { infoVT, country, allGroupsVT, allTypesVT, duongDung } = useSelector(
    (state) => state.thuocVTReducer,
  )
  const [type, steType] = useState(null)
  const dispatch = useDispatch()

  const maQuyenCapNhat = 'QHT163'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenCapNhat:
        formik.handleSubmit()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenCapNhat:
        //
        break
      default:
        return false
    }
  }

  const handleSumit = (values, actions) => {
    dispatch(
      updateVT_Action({
        ...values,
        nguoiCapNhat: infoUser.tenNV,
        ngayCapNhat: toDay.format('YYYY-MM-DDTHH:mm:ss'),
        hangkhuyenmai: values.hangkhuyenmai ? 1 : 0,
      }),
    )
    CancelModal()
  }
  useEffect(() => {
    if (infoVT) {
      steType(infoVT?.thuocVatTuNhom?.idPhanLoai)
    }
  }, [infoVT])
  const handleInput = (e) => {
    const { value, name } = e.target
    const newValue = parseFloat(value)
    formik.setFieldValue(name, isNaN(newValue) || newValue < 0 ? 0 : newValue)
  }
  const handleCachDongGoi = (e) => {
    const { value, name } = e.target
    const newValue = parseFloat(value)
    formik.setFieldValue(name, isNaN(newValue) || newValue < 0 ? null : newValue)
  }
  const CancelModal = () => {
    CancelModalDetail()
    steType(null)
    dispatch(fetchListAllGroupsVT_Action(''))
  }
  const handleSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  const handleTypeThuovVT = (value) => {
    steType(value)
    dispatch(fetchListAllGroupsVT_Action(value))
    formik.setFieldValue('idNhom', null)
  }

  // data form formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idThuoc: infoVT?.idThuoc,
      maThuoc: infoVT?.maThuoc,
      tenBietDuoc: infoVT?.tenBietDuoc,
      tenHoatChat: infoVT?.tenHoatChat,
      dvt: infoVT?.dvt,
      quyCach: infoVT?.quyCach,
      nongDo: infoVT?.nongDo,
      hamLuong: infoVT?.hamLuong,
      duongDung: infoVT?.duongDung,
      nuocSanXuat: infoVT?.nuocSanXuat,
      nhaSanXuat: infoVT?.nhaSanXuat,
      idCt: infoVT?.idCt,
      cachDung: infoVT?.cachDung,
      maSoDangKy: infoVT?.maSoDangKy,
      donViChan: infoVT?.donViChan,
      chuyenKhoa: infoVT?.chuyenKhoa,
      tenDoiTac: infoVT?.tenDoiTac,
      donViDung: infoVT?.donViDung,
      idNhom: infoVT?.idNhom,
      ptVatNhap: infoVT?.ptVatNhap,
      ptVatBanLe: infoVT?.ptVatBanLe,
      ptVatToa: infoVT?.ptVatToa,
      quyCachDongGoi: infoVT?.quyCachDongGoi,
      giaBan: infoVT?.giaBan,
      giaMua: infoVT?.giaMua,
      giagpp: infoVT?.giagpp,
      barcode: infoVT?.barcode,
      hangkhuyenmai: infoVT?.hangkhuyenmai,
      suDung: infoVT?.suDung,
    },
    onSubmit: (values, actions) => handleSumit(values, actions),
    validationSchema: updateThuocVTSchema,
  })
  useEffect(() => {
    dispatch(fetchListAllGroupsVT_Action(infoVT?.thuocVatTuNhom?.idPhanLoai))
  }, [infoVT])
  useEffect(() => {
    dispatch(allTypesVT_action())
  }, [])
  return (
    <Modal
      width={580}
      className="text-center"
      title="Thông tin thuốc/vật tư/hàng hoá"
      okText="Cập nhật"
      cancelText="Huỷ bỏ"
      open={isModalDetail}
      onOk={() =>
        dispatch(putCheckQuyenThaoTacAction(maQuyenCapNhat, null, actionIsTrue, actionIsFalse))
      }
      onCancel={CancelModal}
      style={{ top: 20 }}
    >
      <form className="text-start mt-5">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-around">
            <div className="w-1/3 flex flex-col justify-center items-center ">
              <QRCode type="svg" size={100} value={formik.values.maThuoc} />
              <p className="font-semibold text-gray-400">QR hàng: {formik.values.maThuoc}</p>
            </div>
            <div className="w-1/3 flex justify-center">
              <div className="w-36">
                <Barcode height={100} className="w-full h-28" value={formik.values.maThuoc} />
              </div>
            </div>
            <div className="w-1/3 flex justify-center">
              <div className="w-36 ">
                <Barcode height={100} className="w-full h-28" value={formik.values.barcode} />
              </div>
            </div>
          </div>
          <div className="flex">
            <label className="w-32 text-end">
              <span className="text-red-500 text-xs">(*)</span>Mã :
            </label>
            <Input
              // readOnly
              // variant='filled'
              style={{ color: 'black' }}
              status={formik.errors.maThuoc ? 'error' : ''}
              name="maThuoc"
              value={formik.values.maThuoc}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
          <div className="flex">
            <label className="w-32 text-end">Mã Barcode:</label>
            <Input
              // readOnly
              // variant='filled'
              style={{ color: 'black' }}
              name="barcode"
              value={formik.values.barcode}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
          <div className="flex">
            <label className="w-32 text-end">
              <span className="text-red-500 text-xs">(*)</span>Tên:
            </label>
            <Input
              status={formik.errors.tenBietDuoc ? 'error' : ''}
              name="tenBietDuoc"
              value={formik.values.tenBietDuoc}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
          <div className="flex ">
            <label className="w-32 text-end ">
              <span className="text-red-500 text-xs">(*)</span>Tên hoạt chất:
            </label>
            <Input
              size="small"
              name="tenHoatChat"
              status={formik.errors.tenHoatChat ? 'error' : ''}
              value={formik.values.tenHoatChat}
              onChange={formik.handleChange}
            />
          </div>
          <div className="flex ">
            <label className="w-32 text-end ">Công ty:</label>
            <Select
              defaultValue={1}
              value={formik.values.idCt}
              onChange={handleSelected('idCt')}
              className="w-full"
              options={listCompany?.map((items) => ({
                label: items.tenct,
                value: items.idct,
              }))}
              placeholder="Chọn pháp danh công ty"
              size="small"
            />
          </div>
          <div className="flex ">
            <label className="w-32 text-end ">Đối tác:</label>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={listDoiTac?.map(({ idDoiTac, tenDoiTac }) => ({
                label: tenDoiTac,
                value: tenDoiTac,
              }))}
              value={formik.values.tenDoiTac}
              onChange={handleSelected('tenDoiTac')}
              className="w-full truncate"
              placeholder="Chọn đối tác"
              size="small"
            />
          </div>
          <div className="flex ">
            <label className="w-32 text-end ">Nhà sản xuất:</label>
            <Input
              name="nhaSanXuat"
              value={formik.values.nhaSanXuat}
              onChange={formik.handleChange}
              placeholder="Nơi sản xuất thuốc"
              size="small"
            />
          </div>
          <div className="flex ">
            <label className="w-32 text-end ">
              <span className="text-red-500 text-xs">(*)</span> Phân loại:
            </label>
            <Select
              value={type}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={allTypesVT?.map((items) => ({
                label: items.phanLoai,
                value: items.idPhanLoai,
              }))}
              onChange={handleTypeThuovVT}
              className="w-full"
              placeholder="Thuộc nhóm hàng hoá"
              size="small"
            />
          </div>
          <div className="flex ">
            <label className="w-32 text-end ">
              <span className="text-red-500 text-xs">(*)</span> Nhóm hàng:
            </label>
            <Select
              showSearch
              status={formik.errors.idNhom ? 'error' : ''}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={allGroupsVT?.map((items) => ({
                label: items.nhom,
                value: items.idNhom,
              }))}
              name="idNhom"
              value={formik.values.idNhom}
              onChange={handleSelected('idNhom')}
              className="w-full"
              placeholder="Thuộc nhóm hàng hoá"
              size="small"
            />
          </div>
          {/* <div className="flex ">
            <label className="w-32 text-end ">Cách dùng:</label>
            <Input
              name="cachDung"
              value={formik.values.cachDung}
              onChange={formik.handleChange}
              placeholder="VD: thoa da 2 lần / ngày"
              size="small"
            />
          </div> */}
          <div className="flex">
            <label className="w-32 text-end">Số đăng kí:</label>
            <div className="flex w-full gap-2">
              <Input
                name="maSoDangKy"
                value={formik.values.maSoDangKy}
                onChange={formik.handleChange}
                placeholder="Mã số đăng kí"
                size="small"
              />
              <label className="w-56 text-end">Giá GPP:</label>
              <InputNumber
                size="small"
                onChange={(value) => formik.setFieldValue('giagpp', value)}
                name="giagpp"
                className={style.input_align}
                value={formik.values.giagpp}
                step="0.001"
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                max={999999999}
              />
              {/* <Input
                className="text-end"
                name="giagpp"
                value={formik.values.giagpp}
                onChange={handleInput}
                size="small"
              /> */}
            </div>
          </div>
          <div className="flex">
            <label className="w-32 text-end">Nồng độ:</label>
            <div className="flex w-full gap-2">
              <Input
                name="nongDo"
                value={formik.values.nongDo}
                onChange={formik.handleChange}
                size="small"
              />
              <label className="w-56 text-end">Hàm lượng:</label>
              <Input
                name="hamLuong"
                value={formik.values.hamLuong}
                onChange={formik.handleChange}
                size="small"
              />
            </div>
          </div>
          <div className="flex">
            <label className="w-32 text-end"> Cách đóng gói:</label>
            <div className="flex w-full gap-2">
              <Input
                min={0}
                name="quyCachDongGoi"
                status={formik.errors.quyCachDongGoi ? 'error' : ''}
                value={formik.values.quyCachDongGoi}
                onChange={handleCachDongGoi}
                type="number"
                size="small"
              />
              <label className="w-56 text-end">Đơn vị dùng:</label>
              <Input
                name="donViDung"
                value={formik.values.donViDung}
                onChange={formik.handleChange}
                size="small"
              />
            </div>
          </div>
          <div className="flex">
            <label className="w-32 text-end">Đường dùng:</label>
            <div className="flex w-full gap-1 ">
              <Select
                onChange={handleSelected('duongDung')}
                value={formik.values.duongDung}
                options={duongDung?.map((items) => ({
                  label: items.duongdung,
                  value: items.duongdung,
                }))}
                className="w-full"
                size="small"
              />
              <label className="w-56 text-end ">Quy cách:</label>
              <Input
                name="quyCach"
                value={formik.values.quyCach}
                onChange={formik.handleChange}
                size="small"
              />
            </div>
          </div>
          <div className="flex">
            <label className="w-32 text-end">
              {' '}
              <span className="text-red-500 text-xs">(*)</span>Đơn vị chẵn:
            </label>
            <div className="flex w-full gap-2">
              <Input
                status={formik.errors.donViChan ? 'error' : ''}
                name="donViChan"
                value={formik.values.donViChan}
                onChange={formik.handleChange}
                size="small"
              />
              <label className="w-56 text-end">
                <span className="text-red-500 text-xs">(*)</span>Đơn vị lẻ:
              </label>
              <Input
                name="dvt"
                status={formik.errors.dvt ? 'error' : ''}
                value={formik.values.dvt}
                onChange={formik.handleChange}
                size="small"
              />
            </div>
          </div>
          <div className="flex">
            <label className="w-32 text-end">Giá nhập lẻ:</label>
            <div className="flex w-full gap-2">
              <InputNumber
                size="small"
                onChange={(value) => formik.setFieldValue('giaMua', value)}
                name="giaMua"
                className={style.input_align}
                value={formik.values.giaMua}
                step="0.001"
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                max={999999999}
              />
              {/* <Input
                className="text-end"
                min={0}
                name="giaMua"
                value={formik.values.giaMua}
                onChange={handleInput}
                type="number"
                size="small"
              /> */}
              <label className="w-56 text-end">Giá bán lẻ:</label>
              <InputNumber
                size="small"
                onChange={(value) => formik.setFieldValue('giaBan', value)}
                name="giaBan"
                className={style.input_align}
                value={formik.values.giaBan}
                step="0.001"
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                max={999999999}
              />
              {/* <Input
                className="text-end"
                min={0}
                name="giaBan"
                value={formik.values.giaBan}
                onChange={handleInput}
                type="number"
                size="small"
              /> */}
            </div>
          </div>
          <div className="flex">
            <label className="w-32 text-end">%VAT nhập:</label>
            <div className="flex w-full gap-2">
              <InputNumber
                size="small"
                onChange={(value) => formik.setFieldValue('ptVatNhap', value)}
                name="ptVatNhap"
                className={style.input_align}
                value={formik.values.ptVatNhap}
                step="0.001"
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                max={999999999}
              />
              {/* <Input
                min={0}
                name="ptVatNhap"
                value={formik.values.ptVatNhap}
                onChange={handleInput}
                type="number"
                size="small"
              /> */}
              <label className="w-56 text-end">%VAT bán lẻ:</label>
              <InputNumber
                size="small"
                onChange={(value) => formik.setFieldValue('ptVatBanLe', value)}
                name="ptVatBanLe"
                className={style.input_align}
                // className='w-full'
                value={formik.values.ptVatBanLe}
                step="0.001"
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                max={999999999}
              />
              {/* <Input
                name="ptVatBanLe"
                value={formik.values.ptVatBanLe}
                onChange={handleInput}
                type="number"
                size="small"
              /> */}
            </div>
          </div>
          <div className="flex">
            <label className="w-32 text-end">Xuất xứ:</label>
            <div className="flex w-full gap-2">
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                className="w-full"
                name="nuocSanXuat"
                value={formik.values.nuocSanXuat}
                options={country?.map((items) => ({
                  label: items.tenQuocTich,
                  value: items.tenQuocTich,
                }))}
                onChange={handleSelected('nuocSanXuat')}
                placeholder="Nước xuất xứ"
                size="small"
              />
              <label className="w-56 text-end">%VAT toa:</label>
              <InputNumber
                size="small"
                onChange={(value) => formik.setFieldValue('ptVatToa', value)}
                name="ptVatToa"
                className={style.input_align}
                value={formik.values.ptVatToa}
                step="0.001"
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                max={999999999}
              />
              {/* <Input
                min={0}
                name="ptVatToa"
                value={formik.values.ptVatToa}
                onChange={handleInput}
                type="number"
                size="small"
              /> */}
            </div>
          </div>
          <div className="flex">
            <label className="w-32 text-end mr-6">Hàng khuyến mãi:</label>
            <Checkbox
              checked={formik.values.hangkhuyenmai}
              onChange={(e) => {
                formik.setFieldValue('hangkhuyenmai', e.target.checked)
              }}
            />
          </div>
          <div className="flex">
            <label className="w-32 text-end mr-6">Sử dụng:</label>
            <Checkbox
              checked={formik.values.suDung}
              onChange={(e) => {
                formik.setFieldValue('suDung', e.target.checked ? 1 : 0)
              }}
            />
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default ModalDetail

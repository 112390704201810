import moment from 'moment'

import 'moment/locale/vi'
moment.locale('vi')
export const buildDateFilterCondition = (condition, value, comparator) => {
  if (value === '') return true // Không áp dụng điều kiện nếu giá trị trống
  const dateValue = moment(value, 'YYYY-MM-DD')
  const comparatorDate = moment(comparator, 'YYYY-MM-DD')
  switch (condition) {
    case '>':
      return comparatorDate.isAfter(dateValue)
    case '<':
      return comparatorDate.isBefore(dateValue)
    case '=':
      return comparatorDate.isSame(dateValue)
    default:
      return true
  }
}

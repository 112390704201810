import { Table, ConfigProvider, Input, Select, DatePicker, Tooltip, Button } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CachedIcon from '@mui/icons-material/Cached'
import moment from 'moment'
import dayjs from 'dayjs'
import _ from 'lodash'
import {
  branchPTByCompany,
  filterPT_recevieCK,
  listInfoThuocVTChyenKho_Action,
} from '../../../../../store/actions/chuyenKhoAction'
import { chuyenKhoService } from '../../../../../services/ChuyenKho/chuyenKhoService'
import * as XLSX from 'xlsx'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { branchService } from '../../../../../services/branch/branchService'
import { buildDateFilterCondition } from '../../../../../utils/buildDateFilterCondition'
import 'moment/locale/vi'
import { exportExcelformat } from '../../../../../utils/export/exportExcelFormat'
import { configSearchTableAnt } from '../../../../../utils/configSearchTableAntd'
moment.locale('vi')

const now = new Date()
const dateFormat = 'DD-MM-YYYY'

const Receive = ({ showModal, setStatusUpdate }) => {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [valueExport, setValueExport] = useState([])
  const [searchValues, setSearchValues] = useState({})
  const [data2, setData2] = useState([])
  const { PTReceive, branchPT } = useSelector((state) => state.chuyenKhoReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(id_company)
  const [idChiNhanh, setIdChiNhanh] = useState(BranchLogin)
  const [since, setSince] = useState(now)
  const [toDate, SetToDate] = useState(now)
  const [ListKho, setListKho] = useState([])
  const [searchKho, setSearchKho] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  // const [searchName, setSearchName] = useState('')
  // const [searchMa, setSearchMa] = useState('')
  // const [khoChuyen, setKhoChuyen] = useState('')
  // const [searchNguoiXuat, setSearchNguoiXuat] = useState('')
  // const [searchDate, setSearchDate] = useState('')
  // const [searchNguoiNhan, setSearchNguoiNhan] = useState('')
  // const [searchDateNhan, setSearchDateNhan] = useState('')
  // const [filterDate, setFilterDate] = useState('=')
  // const [filterDateNhan, setFilterDateNhan] = useState('=')
  // const [ghiChu, setGhiChu] = useState('')
  // const [branhNhan, setBranchNhan] = useState('')
  // const [branchChuyen, setBranchChuyen] = useState('')
  const ID_COMPANY = localStorage.getItem('id_company')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [ListBranch, setListBranch] = useState([])

  // set ngày lại từ ngày
  const handleSince = (name) => (date, dateString) => {
    if (name === 'since') {
      setSince(dateString)
    } else {
      SetToDate(dateString)
    }
  }
  const handleShowModalDetail = (idNhapXuat) => {
    showModal()
    dispatch(listInfoThuocVTChyenKho_Action(idNhapXuat))
    setStatusUpdate(false) // tắt trạng thái cho phép update
  }
  const statusChuyenKho = 3 // trạng thái nhận
  const filter = {
    status: statusChuyenKho, //
    since: moment(since, dateFormat).format('YYYY-MM-DD'),
    toDate: moment(toDate, dateFormat).format('YYYY-MM-DD'),
    idChiNhanh: idChiNhanh,
    idCongTy: company,
  }
  // xử lí chọn chi nhánh
  const handleSelected = (value) => {
    setIdChiNhanh(value)
    if (value) {
      fetchKho(value)
    }
    setSearchKho('')
  }
  // const filteredData = data?.filter((items) => {
  //   const findKho = searchKho.toLowerCase()
  //   const findMa = searchMa.trim().toLowerCase()
  //   const nameLower = searchName.trim().toLowerCase()
  //   const nameNhanLower = searchNguoiNhan.trim().toLowerCase()
  //   const nguoiXuatLower = searchNguoiXuat.trim().toLowerCase()
  //   const branchNhanLower = branhNhan.trim().toLowerCase()
  //   const branchChuyenLower = branchChuyen.trim().toLowerCase()
  //   const ghiChuLower = ghiChu.trim().toLowerCase()
  //   const khoChuyenLower = khoChuyen.trim().toLowerCase()
  //   return (
  //     (items?.tenKhoNhap?.toLowerCase().includes(findKho) || !items?.tenKhoNhap) &&
  //     (items?.maPhieu?.toLowerCase().includes(findMa) || !items?.maPhieu) &&
  //     (items?.tenPhieu?.toLowerCase().includes(nameLower) || !items?.tenPhieu) &&
  //     (items?.tenNVXuat?.toLowerCase().includes(nguoiXuatLower) || !items?.tenNVXuat) &&
  //     (items?.tenNVNhan?.toLowerCase().includes(nameNhanLower) || !items?.tenNVNhan) &&
  //     (items?.noiDung?.toLowerCase().includes(ghiChuLower) || !items?.noiDung) &&
  //     (items?.tenKhoXuat?.toLowerCase().includes(khoChuyenLower) || !items?.tenKhoXuat) &&
  //     buildDateFilterCondition(filterDate, searchDate, items?.ngayXuat) &&
  //     buildDateFilterCondition(filterDateNhan, searchDateNhan, items?.ngayNhan) &&
  //     (items?.tenChiNhanhXuat?.toLowerCase().includes(branchChuyenLower) ||
  //       !items?.tenChiNhanhXuat) &&
  //     (items?.tenChiNhanhNhan.toLowerCase().includes(branchNhanLower) || !items?.tenChiNhanhNhan)
  //   )
  // })
  const handleCompany = (value) => {
    dispatch(branchPTByCompany(value))
    setCompany(value)
    setIdChiNhanh(null)
  }
  // xử lí lọc dưc liệu
  const handleFilter = () => {
    dispatch(filterPT_recevieCK(filter, setIsLoading))
  }
  //---------- lấy danh sách kho chi nhánh-------------//
  const fetchKho = async (id) => {
    try {
      const { data } = await chuyenKhoService.getlistKhoNhan(id)
      setListKho(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    dispatch(filterPT_recevieCK(filter, setIsLoading))
    dispatch(branchPTByCompany(id_company))
    fetchKho(BranchLogin)
    fetchBranch()
  }, [])
  useEffect(() => {
    const sortedData = _.orderBy(PTReceive, 'ngayXuat', 'desc')
    setData(sortedData)
  }, [PTReceive])

  const fetchBranch = async () => {
    const { data } = await branchService.getListBranch(ID_COMPANY)
    setListBranch(data)
  }

  // const compare = (a, b, dataIndex) => {
  //   if (a[dataIndex] < b[dataIndex]) {
  //     return -1
  //   }
  //   if (a[dataIndex] > b[dataIndex]) {
  //     return 1
  //   }
  //   return 0
  // }

  // const PhepTinh = [
  //   { label: '>', value: '>' },
  //   { label: '<', value: '<' },
  //   { label: '=', value: '=' },
  // ]

  // const handleSearch = (name) => (e) => {
  //   const { value } = e.target
  //   switch (name) {
  //     case 'ma':
  //       setSearchMa(value || '')
  //       break
  //     case 'TENPHIEU':
  //       setSearchName(value || '')
  //       break
  //     case 'NGUOICHUYEN':
  //       setSearchNguoiXuat(value || '')
  //       break
  //     case 'NOICHUYEN':
  //       setBranchChuyen(value || '')
  //       break
  //     case 'NGUOINHAN':
  //       setSearchNguoiNhan(value || '')
  //       break
  //     case 'NGAYNHAN':
  //       setSearchDateNhan(value || '')
  //       break
  //     case 'GHICHU':
  //       setGhiChu(value || '')
  //       break
  //     case 'KHOCHUYEN':
  //       setKhoChuyen(value || '')
  //       break
  //     // case 'quyCach':
  //     //   setSearchQuyCach(value || '')
  //     //   break
  //     // case 'tongXuat':
  //     //   setSearchXuat(value || '')
  //     //   break
  //     // case 'tongNhap':
  //     //   setSearchNhap(value || '')
  //     //   break
  //     // case 'SLChan':
  //     //   setSearchSLChan(value || '')
  //     //   break
  //     // case 'tonKho':
  //     //   setSearchTon(value || '')
  //     //   break
  //     // case 'soLo':
  //     //   setSearchSoLo(value || '')
  //     //   break
  //     // case 'soHoaDon':
  //     //   setSearchHD(value || '')
  //     //   break
  //     default:
  //       break
  //   }
  // }

  // const handleSelectedKhoNhan = (value) => {
  //   setSearchKho(value || '')
  // }

  // const handleSelectedBranhNhan = (value, option) => {
  //   setBranchNhan(value || '')
  //   if (value) {
  //     fetchKho(option.id)
  //   }
  //   setSearchKho('')
  // }
  const filteredData2 = (
    data, // lọc giá trị search
  ) =>
    data?.filter((item) =>
      Object.entries(searchValues).every(([key, value]) =>
        item[key]?.toLowerCase().includes(value.toLowerCase()),
      ),
    )
  const onChangeTable = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }
  const exportToExcel = () => {
    const formattedData = filteredData2(valueExport)?.map((item) => ({
      'Mã phiếu': item.maPhieu,
      'Tên phiếu': item.tenPhieu,
      'Người nhận': item.tenNVNhan,
      'Ngày nhận': moment(item.ngayNhan).format('DD/MM/YYYY HH:mm:ss'),
      'Chi nhánh nhận': item.tenChiNhanhNhan,
      'Kho nhận': item.tenKhoNhap,
      'Người chuyển': item.tenNVXuat,
      'Ngày chuyển': moment(item.ngayXuat).format('DD/MM/YYYY HH:mm:ss'),
      'Chi nhánh chuyển': item.tenChiNhanhXuat,
      'Kho chuyển': item.tenKhoXuat,
      'Ghi chú': item.noiDung,
    }))
    const name = 'Phiếu chuyển kho - Phiếu đã nhận.xlsx'
    exportExcelformat(formattedData, name)
  }
  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
    },
    {
      title: 'Mã phiếu',
      dataIndex: 'maPhieu',
      key: 'maPhieu',
      ...configSearchTableAnt(setSearchValues, searchValues, 'maPhieu', PTReceive, 120,),
      // width: 120,
      // sorter: (a, b) => compare(a, b, 'MAPHIEU'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Input size="small" onChange={handleSearch('ma')} value={searchMa} />
      //       </>
      //     ),
      //     width: 120,
      //     dataIndex: 'MAPHIEU',
      //     key: 1,
      //   },
      // ],
    },
    {
      title: 'Tên phiếu',
      dataIndex: 'tenPhieu',
      key: 'tenPhieu',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenPhieu', PTReceive, 120),
      // sorter: (a, b) => compare(a, b, 'TENPHIEU'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Input size="small" onChange={handleSearch('TENPHIEU')} value={searchName} />
      //       </>
      //     ),
      //     width: 200,
      //     dataIndex: 'TENPHIEU',
      //     key: 1,
      //   },
      // ],
    },
    {
      title: 'Người nhận',
      dataIndex: 'tenNVNhan',
      key: 'tenNVNhan',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenNVNhan', PTReceive, 120),
      // sorter: (a, b) => compare(a, b, 'tenNVNhan'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Input size="small" onChange={handleSearch('tenNVNhan')} value={searchNguoiNhan} />
      //       </>
      //     ),
      //     dataIndex: 'tenNVNhan',
      //     key: 1,
      //     width: 160,
      //   },
      // ],
    },
    {
      title: 'Ngày Nhận',
      dataIndex: 'ngayNhan',
      key: 'ngayNhan',
      ...configSearchTableAnt(setSearchValues, searchValues, 'ngayNhan', PTReceive, 120, true, {
        render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : ''),
      }),
      // width: 120,
      // sorter: (a, b) => compare(a, b, 'ngayNhan'),
      // children: [
      //   {
      //     title: (
      //       <div className="flex gap-1">
      //         <Select
      //           onChange={(value) => setFilterDateNhan(value)}
      //           value={filterDateNhan}
      //           size="small"
      //           className="w-16"
      //           options={PhepTinh}
      //         />
      //         <DatePicker
      //           value={searchDateNhan !== '' ? dayjs(searchDateNhan, 'YYYY-MM-DD') : ''}
      //           size="small"
      //           format={'DD/MM/YYYY'}
      //           onChange={(date, DateString) => {
      //             setSearchDateNhan(
      //               DateString ? moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
      //             )
      //           }}
      //         />
      //       </div>
      //     ),
      //     dataIndex: 'NGAYNHAN',
      //     key: 2,
      //     width: 160,
      //   },
      // ],
    },
    {
      title: 'Chi nhánh nhận',
      dataIndex: 'tenChiNhanhNhan',
      key: 'tenChiNhanhNhan',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenChiNhanhNhan', PTReceive, 120),
      // sorter: (a, b) => compare(a, b, 'tenChiNhanhNhan'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Select
      //           size="small"
      //           className="w-full"
      //           filterOption={(input, option) =>
      //             (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      //           }
      //           showSearch
      //           onChange={handleSelectedBranhNhan}
      //           value={branhNhan}
      //           options={[
      //             { label: 'Tất cả', value: '' },
      //             ...ListBranch?.map((item) => ({
      //               label: item.tenChiNhanh,
      //               value: item.tenChiNhanh,
      //               id: item.idChiNhanh,
      //             })),
      //           ]}
      //         />
      //       </>
      //     ),
      //     dataIndex: 'chiNhanhNhan',
      //     key: 2,
      //     width: 190,
      //   },
      // ],
    },
    {
      title: 'Kho nhận',
      dataIndex: 'tenKhoNhap',
      key: 'tenKhoNhap',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenKhoNhap', PTReceive, 120),
      // width: 150,
      // sorter: (a, b) => compare(a, b, 'tenKhoNhap'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Select
      //           filterOption={(input, option) =>
      //             (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      //           }
      //           size="small"
      //           value={searchKho}
      //           showSearch
      //           onChange={handleSelectedKhoNhan}
      //           options={[
      //             { label: 'Tất cả', value: '' },
      //             ...ListKho.map((item) => ({
      //               label: item.tenKho,
      //               value: item.tenKho,
      //               key: item.tenKho, // Thêm key cho mỗi tùy chọn
      //             })),
      //           ]}
      //           className="w-full"
      //         />
      //       </>
      //     ),
      //     dataIndex: 'KHONHAN',
      //     key: 2,
      //     width: 150,
      //   },
      // ],
    },
    {
      title: 'Người chuyển',
      dataIndex: 'tenNVXuat',
      key: 'tenNVXuat',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenNVXuat', PTReceive, 120),
      // sorter: (a, b) => compare(a, b, 'tenNVXuat'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Input size="small" onChange={handleSearch('tenNVXuat')} value={searchNguoiXuat} />
      //       </>
      //     ),
      //     dataIndex: 'tenNVXuat',
      //     key: 1,
      //     width: 160,
      //   },
      // ],
    },
    {
      title: 'Ngày chuyển',
      dataIndex: 'ngayXuat',
      key: 'ngayXuat',
      ...configSearchTableAnt(setSearchValues, searchValues, 'ngayXuat', PTReceive, 120, true, {
        render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : ''),
      }),
      // sorter: (a, b) => compare(a, b, 'ngayXuat'),
      // children: [
      //   {
      //     title: (
      //       <div className="flex gap-1">
      //         <Select
      //           onChange={(value) => setFilterDate(value)}
      //           value={filterDate}
      //           size="small"
      //           className="w-16"
      //           options={PhepTinh}
      //         />
      //         <DatePicker
      //           value={searchDate !== '' ? dayjs(searchDate, 'YYYY-MM-DD') : ''}
      //           size="small"
      //           format={'DD/MM/YYYY'}
      //           onChange={(date, DateString) => {
      //             setSearchDate(
      //               DateString ? moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
      //             )
      //           }}
      //         />
      //       </div>
      //     ),
      //     dataIndex: 'NGAYCHUYEN',
      //     key: 2,
      //     width: 160,
      //   },
      // ],
    },
    {
      title: 'Chi nhánh chuyển',
      dataIndex: 'tenChiNhanhXuat',
      key: 'tenChiNhanhXuat',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenChiNhanhXuat', PTReceive, 120),
      // sorter: (a, b) => compare(a, b, 'tenChiNhanhXuat'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Select
      //           size="small"
      //           className="w-full"
      //           filterOption={(input, option) =>
      //             (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      //           }
      //           showSearch
      //           onChange={(value) => setBranchChuyen(value)}
      //           value={branchChuyen}
      //           options={[
      //             { label: 'Tất cả', value: '' },
      //             ...ListBranch?.map((item) => ({
      //               label: item.tenChiNhanh,
      //               value: item.tenChiNhanh,
      //               id: item.idChiNhanh,
      //             })),
      //           ]}
      //         />
      //       </>
      //     ),
      //     dataIndex: 'chiNhanhChuyen',
      //     key: 2,
      //     width: 190,
      //   },
      // ],
    },
    {
      title: 'Kho chuyển',
      dataIndex: 'tenKhoXuat',
      key: 'tenKhoXuat',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenKhoXuat', PTReceive, 120),
      // width: 150,
      // sorter: (a, b) => compare(a, b, 'tenKhoXuat'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Input size="small" onChange={handleSearch('tenKhoXuat')} value={khoChuyen} />
      //       </>
      //     ),
      //     dataIndex: 'tenKhoXuat',
      //     key: 2,
      //     width: 150,
      //   },
      // ],
    },
    {
      title: 'Ghi chú',
      dataIndex: 'noiDung',
      key: 'noiDung',
      ...configSearchTableAnt(setSearchValues, searchValues, 'noiDung', PTReceive, 120),
      // width: 200,
      // sorter: (a, b) => compare(a, b, 'noiDung'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Input size="small" onChange={handleSearch('noiDung')} value={ghiChu} />
      //       </>
      //     ),
      //     dataIndex: 'noiDung',
      //     key: 1,
      //     width: 160,
      //   },
      // ],
    },
    {
      title: 'Hành động',
      dataIndex: 'ACTION',
      key: 'ACTION',
      width: 100,
      align: 'center',
      fixed: 'right',
    },
  ]

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  useEffect(() => {
    setData2(PTReceive)
    setValueExport(PTReceive)
  }, [PTReceive])
  return (
    <div className="h-full">
      <div className="flex gap-5">
        <div className="w-80 flex gap-1">
          <Select
            onChange={handleCompany}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={company}
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className="w-full"
          />
        </div>
        <div className="w-72 flex gap-1">
          <Select
            onChange={handleSelected}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={idChiNhanh}
            options={[
              { label: 'Tất cả', value: null },
              ...branchPT?.map(({ idChiNhanh, tenChiNhanh }) => ({
                label: tenChiNhanh,
                value: idChiNhanh,
              })),
            ]}
            className="w-full"
          />
        </div>
        <div className="w-52">
          <Select
            onChange={(value) => setSearchKho(value)}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={searchKho}
            options={[
              { label: 'Tất cả', value: '' },
              ...ListKho?.map((item) => ({
                label: item.tenKho,
                value: item.tenKho,
              })),
            ]}
            className="w-full"
          />
        </div>
        <div className="flex gap-3">
          <div>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleSince('since')}
              format={dateFormat}
            />
          </div>
          <div>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              minDate={dayjs(since, dateFormat)}
              onChange={handleSince('toDate')}
              format={dateFormat}
            />
          </div>
        </div>
        <div
          className="cursor-pointer rounded-sm text-blue-500  hover:bg-slate-200"
          onClick={handleFilter}
        >
          <CachedIcon sx={{ fontSize: 25 }} />
        </div>
        <div className="flex gap-3 ml-auto">
          <Button
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <div
        className=" mt-2 "
        // style={{
        //     boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        // }}
      >
        <ConfigProvider
          theme={{
            token: {
              padding: 4,
              fontSize: 12,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            onChange={onChangeTable}
            loading={isLoading}
            pagination={false}
            scroll={{
              x: filteredData2(data2)?.length !== 0 ? 'max-content' : 1800,
              y: '70vh',
            }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredData2(data2)?.map((items, index) => ({
              key: items.idNhapXuat,
              ...items,
              STT: ++index,
              // MAPHIEU: items.maPhieu,
              // TENPHIEU: items.tenPhieu,
              // NGUOINHAN: items.tenNVNhan,
              // NGAYNHAN: moment(items.ngayNhan).format('DD/MM/YYYY HH:mm:ss'),
              // chiNhanhNhan: items.tenChiNhanhNhan,
              // KHONHAN: items.tenKhoNhap,
              // chiNhanhChuyen: items.tenChiNhanhXuat,
              // NGUOICHUYEN: items.tenNVXuat,
              // NGAYCHUYEN: moment(items.ngayXuat).format('DD/MM/YYYY HH:mm:ss'),
              // KHOCHUYEN: items.tenKhoXuat,
              // GHICHU: items.noiDung,
              ACTION: (
                <Tooltip title="Xem chi tiết" color="#108ee9">
                  <EyeOutlined
                    onClick={() => handleShowModalDetail(items.idNhapXuat)}
                    className="text-xl text-[#108ee9]  cursor-pointer"
                  />
                </Tooltip>
              ),
            }))}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default Receive

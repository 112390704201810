import { Checkbox } from 'antd'
import { configSearchTableAnt } from '../../../utils/configSearchTableAntd'

export const columns = (searchValues, setSearchValues, data) => {
  return [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 50,
      Key: 0,
      align: 'center',
      render: (text, _, index) => ++index,
    },
    {
      title: 'Mã thuốc',
      dataIndex: 'maThuoc',
      Key: 1,
      ...configSearchTableAnt(setSearchValues, searchValues, 'maThuoc', data, 110),
    },
    {
      title: 'Mã Barcode',
      dataIndex: 'barcode',
      ...configSearchTableAnt(setSearchValues, searchValues, 'barcode', data, 130),
      key: 2,
    },
    {
      title: 'Loại',
      dataIndex: 'tenphanloai',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenphanloai', data, 90),
      key: 3,
    },
    {
      title: 'Nhóm',
      dataIndex: 'nhom',
      ...configSearchTableAnt(setSearchValues, searchValues, 'nhom', data, 90),
      key: 4,
    },
    {
      title: 'Tên thuốc',
      dataIndex: 'tenBietDuoc',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenBietDuoc', data, 90),
      key: 5,
    },
    {
      title: 'Hoạt chất',
      dataIndex: 'tenHoatChat',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenHoatChat', data, 0, false, {
        width: 500,
      }),
      key: 6,
    },

    {
      title: 'Đơn vị tính',
      dataIndex: 'dvt',
      ...configSearchTableAnt(setSearchValues, searchValues, 'dvt', data, 120),
      key: 7,
    },

    {
      title: 'Quy cách',
      dataIndex: 'quyCach',
      ...configSearchTableAnt(setSearchValues, searchValues, 'quyCach', data, 110),
      key: 8,
    },
    {
      title: 'Đóng gói',
      dataIndex: 'quyCachDongGoi',
      ...configSearchTableAnt(setSearchValues, searchValues, 'quyCachDongGoi', data, 110),
      key: 9,
    },

    {
      title: 'Số đăng kí',
      dataIndex: 'maSoDangKy',
      ...configSearchTableAnt(setSearchValues, searchValues, 'maSoDangKy', data, 120),
      key: 10,
    },
    {
      title: 'Giá mua',
      dataIndex: 'giaMua',
      ...configSearchTableAnt(setSearchValues, searchValues, 'giaMua', data, 110, false, {
        align: 'right',
      }),
      key: 11,
    },
    {
      title: 'Giá bán',
      dataIndex: 'giaBan',
      ...configSearchTableAnt(setSearchValues, searchValues, 'giaBan', data, 100, false, {
        align: 'right',
      }),
      key: 12,
    },
    {
      title: 'Giá GPP',
      dataIndex: 'giagpp',
      ...configSearchTableAnt(setSearchValues, searchValues, 'giagpp', data, 100, false, {
        align: 'right',
      }),
      key: 13,
    },
    {
      title: 'Sử dụng',
      dataIndex: 'active',
      key:14,
      width: 80,
      align:'center',
      fixed: 'right',
      render:(text) => <Checkbox checked={text === 1} />
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      width: 100,
      key:15,
      fixed: 'right',
    },
  ]
}

import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import XuatNhaThuocTongHop from './XuatNhaThuocTongHop'
import XuatNhaThuocChiTiet from './XuatNhaThuocChiTiet'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

function XuatNhaThuoc() {
  return (
    <>
      <div className="p-3 h-full bg-[#EFEFEF]">
        <div
          className="h-full p-2 bg-white border rounded-xl"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <Tabs
            items={[
              {
                key: 1,
                value: 1,
                label: 'Tổng hợp',
                children: (
                  <>
                    <XuatNhaThuocTongHop />
                  </>
                ),
              },
              {
                key: 2,
                value: 2,
                label: 'Chi tiết',
                children: (
                  <>
                    <XuatNhaThuocChiTiet />
                  </>
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}
export default XuatNhaThuoc

import moment from 'moment'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { banLeService } from '../../services/banLe/banLeService'
import { phanQuyenService } from '../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../actions/phanQuyenAction'
import ToastCus from '../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')

export function* banLeSaga() {
  yield takeLatest(typeAction.GET_PHIEU_THU_THUOC_BAN_LE, function* PTThuoc({ type, filter }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const { infoUser } = yield select((state) => state.userReducer)
      const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
      yield call(() =>
        phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT262'),
      )

      const { idcn, tuNgay, denNgay, idCa, keyword } = filter
      const result = yield call(() =>
        banLeService.getPhieuThuBanLe(idcn, tuNgay, denNgay, keyword, idCa),
      )
      yield put({
        type: typeAction.DISPATCH_SET_LIST_PT_BAN_LE,
        payload: result.data,
      })
    } catch (error) {
      console.error('Error : ', error)
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
}

import {
  PrinterOutlined,
  SaveOutlined,
  DeleteOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons'
import {
  Button,
  ConfigProvider,
  Divider,
  Drawer,
  Space,
  Table,
  Spin,
  InputNumber,
  Checkbox,
  Input,
  Tooltip,
} from 'antd'
import moment from 'moment'
import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { https } from '../../../../services/apiService'
import { recieveService } from '../../../../services/receive/recieveService'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { generatePhieuBanThuocNoiBo } from '../../../../utils/report/phieuBanThuocNoiBo'
import { generatePhieuThuTienToaThuoc } from '../../../../utils/report/phieuThuTienToaThuoc'
import { generatePhieuBanLeToaThuoc } from '../../../../utils/report/phieuThuToaBanLe'
import { putBnTTvaBnCTTTphieuThuToaAction } from '../../../../store/actions/drugstoreAction'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import { v4 as uuidv4 } from 'uuid'

import 'moment/locale/vi'
moment.locale('vi')
const DrawerThuocBanLe = ({ chiTiet, open, setOpen }) => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [toaThuoc, setToaThuoc] = useState([])
  const [thongTinBN, setThongTinBN] = useState(null)
  const [duocSi, setDuocSi] = useState('')
  const [infoBn, setInfoBN] = useState(null)
  const [infoBNTT, setInfoBNTT] = useState({})
  const [formBNTT, setFormBNTT] = useState(null)
  const [show, setShow] = useState(false)
  const [checkTraThuoc, setCheckTraThuoc] = useState(false)
  const [listHinh, setListHinh] = useState(null) // chuỗi link hình cách nhau = dấu ;
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState([])
  const fileInputRef = useRef(null)
  const dropzoneRef = useRef(null)
  const checkIsLoading = !infoBn || !thongTinBN || !toaThuoc?.length || loading
  const maQuyenInToaThuoc = 'QHT266'
  const maQuyenInPhieuThuTien = 'QHT267'
  const maQuyenInPhieuThuNoiBo = 'QHT268'
  const maQuyenChinhSuaPhieu = 'QHT265'

  const deleteFileById = (id) => {
    const updatedData = selectedFile.filter((item) => item.id !== id)
    setSelectedFile(updatedData)
  }
  const handleDivClick = () => {
    fileInputRef.current.click()
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px solid #000'
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
    handleFileChange(e.dataTransfer.files[0])
  }
  const handleFileSelect = (e) => {
    handleFileChange(e.target.files[0])
  }
  const handleFileChange = async (file) => {
    //  let file = event.target.files[0]
    // List of allowed file types
    const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp']
    if (!allowedFileTypes.includes(file.type)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ chấp nhận tệp là hình ảnh!',
      })
      fileInputRef.current.value = '' // clear the input
      return
    }
    // formik.setFieldValue('HoaDonFile', file)
    // console.log(formData);
    fileInputRef.current.value = ''
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      const localUrl = URL.createObjectURL(file)
      setSelectedFile((preState) => [
        ...preState,
        { base64: e.target.result, URL: localUrl, name: file.name, id: uuidv4(), file },
      ])
    }
  }
  const handleOnChangeHinhThucTT = (tenHinhThuc, giaTri) => {
    // setFormBNTT((prev) => ({ ...prev, pos: value ?? 0 }))
    setFormBNTT((prev) => {
      let conLai =
        (checkTraThuoc ? calculateSum() : infoBNTT?.tienthanhtoan) -
        Object.entries(prev)
          .map(([key, value]) =>
            key === tenHinhThuc || key === 'hoadondientu' || key === 'ghichuxuathoadon' ? 0 : value,
          )
          .reduce((tong, item) => (tong += item), 0)
      return {
        ...prev,
        [tenHinhThuc]: giaTri <= conLai ? giaTri : conLai,
      }
    })
  }
  const resetForm = () => {
    setShow(false)
    setFormBNTT(null)
    setCheckTraThuoc(false)
    setSelectedFile([])
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Clear the input
    }
    setLoading(false)
  }
  const handleSubmitBNTT = () => {
    const tongTienNew = checkTraThuoc ? calculateSum() : infoBNTT?.tienthanhtoan
    const total =
      formBNTT?.chuyenkhoan +
      formBNTT?.tienmat +
      formBNTT?.thett +
      formBNTT?.momo +
      formBNTT?.vnpay +
      formBNTT?.zalopay +
      formBNTT?.pos
    if (total !== tongTienNew) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    const formData = new FormData()
    if (selectedFile?.length) {
      const dataHoaDonFiles = selectedFile?.map((item) => item.file)
      dataHoaDonFiles?.forEach((file, index) => {
        formData.append('FILEHINHANHS', file)
      })
      // const formDataObj = Object.fromEntries(formData.entries())
      // console.log(formDataObj)
      // for (let pair of formData.entries()) {
      //   console.log(pair)
      // }
      // console.log(formData)
    }
    setLoading(true)
    console.log(toaThuoc)
    dispatch(
      putBnTTvaBnCTTTphieuThuToaAction(
        { ...infoBNTT, ...formBNTT },

        checkTraThuoc ? toaThuoc : [],
        // [], //bán lẻ không gửi thuốc

        tongTienNew,

        selectedFile?.length ? { idbnttc: chiTiet?.idbnttc, data: formData } : null,
        // null, //bán lẻ không gửi hình
        resetForm,
      ),
    )
  }

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        if (chiTiet?.idbnttc && !show && !checkTraThuoc) {
          setInfoBN(null)
          setToaThuoc([])
          const { data } = await https.get(
            `BenhNhanPhieuThu/GetDanhSachToaThuoc?idbnttc=${chiTiet?.idbnttc}`,
          )
          const result = await recieveService.getInfoBN(chiTiet?.idbn)
          setInfoBN(result?.data)
          setDuocSi(data?.DUOCSI)
          setListHinh(data?.HINHANH)
          setToaThuoc(data?.DanhSachThuoc)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [chiTiet?.idbnttc, show, checkTraThuoc])
  useEffect(() => {
    ;(async () => {
      try {
        if (chiTiet?.idbnttc) {
          setThongTinBN(null)
          const { data } = await https.get(
            `BenhNhanTTC/GetByIDBNTTC-BNTT?idBNTTC=${chiTiet?.idbnttc}`,
          )
          setThongTinBN(data)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [chiTiet?.idbnttc])
  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        if (chiTiet?.idbnttc && !show) {
          setInfoBNTT(null)
          const { data } = await https.get(
            `BenhNhanThanhToan/GetBenhNhanThanhToanByIDBNTTCToaThuoc`,
            {
              params: { idbnttc: chiTiet?.idbnttc, idloaitt: 5 },
            },
          )
          setInfoBNTT(data)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [chiTiet?.idbnttc, show])
  // useEffect(() => {
  //   ;(async () => {
  //     try {
  //       setInfoBN(null)
  //       setToaThuoc([])
  //       if (chiTiet?.idbnttc) {
  //         const { data } = await https.get(
  //           `BenhNhanPhieuThu/GetDanhSachToaThuoc?idbnttc=${chiTiet?.idbnttc}`,
  //         )
  //         const result = await recieveService.getInfoBN(chiTiet?.idbn)
  //         setInfoBN(result?.data)
  //         setDuocSi(data?.DUOCSI)
  //         setToaThuoc(data?.DanhSachThuoc)
  //       }
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   })()
  // }, [chiTiet?.idbnttc])

  // useEffect(() => {
  //   ;(async () => {
  //     try {
  //       setThongTinBN(null)
  //       if (chiTiet?.idbnttc) {
  //         const { data } = await https.get(
  //           `BenhNhanTTC/GetByIDBNTTC-BNTT?idBNTTC=${chiTiet?.idbnttc}`,
  //         )
  //         setThongTinBN(data)
  //       }
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   })()
  // }, [chiTiet?.idbnttc])

  const calculateSum = () => {
    let sum = 0
    toaThuoc.forEach((item) => {
      sum += item.thanhtien
    })
    return sum
  }

  const handleOpenChinhSuaPhieu = () => {
    setCheckTraThuoc(false)
    setSelectedFile([])
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Clear the input
    }
    setFormBNTT((prev) => ({
      ...prev,
      tienmat: infoBNTT?.tienmat,
      chuyenkhoan: infoBNTT?.chuyenkhoan,
      thett: infoBNTT?.thett,
      momo: infoBNTT?.momo,
      vnpay: infoBNTT?.vnpay,
      zalopay: infoBNTT?.zalopay,
      pos: infoBNTT?.pos,
      hoadondientu: infoBNTT?.hoadondientu,
      ghichuxuathoadon: infoBNTT?.ghichuxuathoadon,
    }))
    setShow(true)
  }
  const checkQuyenBtn = async (maquyen) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, maquyen)
      if (maquyen === maQuyenInToaThuoc) handlePrintToaThuoc(thongTinBN, toaThuoc, PkDangNhap)
      if (maquyen === maQuyenInPhieuThuTien) handlePrintPhieuThuTien(infoBn, thongTinBN, toaThuoc)
      if (maquyen === maQuyenInPhieuThuNoiBo) handlePrintPhieuThuNoiBo(infoBn, thongTinBN, toaThuoc)
      if (maquyen === maQuyenChinhSuaPhieu) handleOpenChinhSuaPhieu()
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const handlePrintToaThuoc = async (thongTinBN, toaThuoc, PkDangNhap) => {
    const toaThuocFormat = toaThuoc.map((item) => {
      return {
        ...item,
        tenHoatChat: item.tenhoatchat,
        soLo: item.solo,
        hanDung: item.handung,
        soLuong: item.soluong,
        thanhTien: item.thanhtien,
        duocSi: duocSi,
      }
    })
    const { data } = await https.get(
      `BenhNhanThanhToan/GetBenhNhanThanhToanByIDBNTTC?idBNTTC=${chiTiet?.idbnttc}`,
    )
    const ttBNThanhToan = {
      maThanhToan: `TT${thongTinBN?.maBenhNhan}`,
      maPhieuThu: data[0]?.maPhieuThu,
      ngayThanhToan: data[0]?.ngayThanhToan,
    }
    generatePhieuBanLeToaThuoc(thongTinBN, toaThuocFormat, PkDangNhap, ttBNThanhToan)
  }

  const handlePrintPhieuThuTien = async (infoBn, thongTinBN, toaThuoc) => {
    const formatToaThuoc = toaThuoc.map((item) => ({
      ...item,
      tenHoatChat: item.tenhoatchat,
      soLuong: item.soluong,
      soLo: item.solo,
      hanDung: item.handung,
      thanhTien: item.thanhtien,
    }))
    //call api
    const { data } = await https.get(
      `BenhNhanThanhToan/GetBenhNhanThanhToanByIDBNTTC?idBNTTC=${chiTiet?.idbnttc}`,
    )
    const ttPhieuThu = {
      ngayThanhToan: data[0].ngayThanhToan,
      maThanhToan: `TT${infoBn.maBenhNhan}`,
      duocSi: duocSi,
      maPhieuThu: data[0].maPhieuThu,
    }
    generatePhieuThuTienToaThuoc(ttPhieuThu, thongTinBN, formatToaThuoc, PkDangNhap)
  }

  const handlePrintPhieuThuNoiBo = async (infoBn, thongTinBN, toaThuoc) => {
    const formatToaThuoc = toaThuoc.map((item) => ({
      ...item,
      tenBietDuoc: item.tenbietduoc,
      soLuong: item.soluong,
      soLo: item.solo,
      hanDung: item.handung,
      thanhTien: item.thanhtien,
    }))
    const { data } = await https.get(
      `BenhNhanThanhToan/GetBenhNhanThanhToanByIDBNTTC?idBNTTC=${chiTiet?.idbnttc}`,
    )
    const ttPhieuThu = {
      maThanhToan: `TT${infoBn.maBenhNhan}`,
      duocSi: duocSi,
      maPhieuThu: data[0].maPhieuThu,
      ngayThanhToan: data[0].ngayThanhToan,
    }
    generatePhieuBanThuocNoiBo(ttPhieuThu, thongTinBN, formatToaThuoc, PkDangNhap)
    // generatePhieuXuatKhoVacxin(PkDangNhap)
  }

  const columnsThuoc = [
    {
      title: <p className="text-center">Tên thuốc</p>,
      // title: 'Tên thuốc',
      dataIndex: 'tenbietduoc',
      // align: 'left',
      key: 'tenbietduoc',
      // align: 'center',
      className: 'text-sm',
    },
    {
      title: 'ĐVT',
      dataIndex: 'dvt',
      key: 'dvt',
      align: 'center',
      className: 'text-sm',
      width: 50,
    },
    {
      title: 'Đơn vị dùng',
      dataIndex: 'donvidung',
      key: 'donvidung',
      align: 'center',
      className: 'text-sm',
      width: 100,
    },
    {
      title: 'Đường dùng',
      dataIndex: 'duongdung',
      key: 'duongdung',
      align: 'center',
      className: 'text-sm',
      width: 100,
    },
    {
      title: 'SL',
      dataIndex: 'soluong',
      key: 'soluong',
      align: 'center',
      className: 'text-sm',
      width: 75,
      render: (text, record, index) =>
        checkTraThuoc && show ? (
          <>
            <InputNumber
              size="small"
              className="w-full"
              min={0}
              max={record?.soluongcu ?? text}
              step={1}
              value={record?.soluongnew}
              onChange={(value) => {
                setToaThuoc((prev) =>
                  prev?.map((item, idx) =>
                    idx === index
                      ? {
                          ...item,
                          soluongnew: value,
                          tongtien: value * item?.dongia,
                          thanhtien: value * item?.dongia,
                          nguoitra: infoUser?.idnv,
                        }
                      : { ...item },
                  ),
                )
                setFormBNTT((prev) => ({
                  ...prev,
                  tienmat: 0,
                  chuyenkhoan: 0,
                  thett: 0,
                  momo: 0,
                  vnpay: 0,
                  zalopay: 0,
                  pos: 0,
                }))
              }}
            />
          </>
        ) : (
          <Tooltip
            title={record?.soluongcu ? `Số lượng trước khi trả: ${record?.soluongcu}` : null}
          >
            <p>{text}</p>
          </Tooltip>
        ),
    },
    {
      title: <p className="text-center">Đơn giá</p>,
      dataIndex: 'dongia',
      key: 'dongia',
      align: 'right',
      className: 'text-sm',
      width: 90,
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: <p className="text-center">Thành tiền</p>,
      dataIndex: 'thanhtien',
      key: 'thanhtien',
      align: 'right',
      className: 'text-sm',
      width: 90,
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: <p className="text-center">Số lô</p>,
      dataIndex: 'solo',
      key: 'solo',
      align: 'right',
      className: 'text-sm',
      width: 90,
    },
    {
      title: <p className="text-center">Hạn dùng</p>,
      dataIndex: 'handung',
      key: 'handung',
      align: 'right',
      className: 'text-sm',
      width: 90,
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : ''),
    },
    {
      title: <p className="text-center">Số hoá đơn</p>,
      dataIndex: 'sohoadon',
      key: 'sohoadon',
      align: 'right',
      className: 'text-sm',
      width: 100,
    },
  ]
  return (
    <Drawer
      title={'Chi tiết phiếu bán lẻ'}
      placement="right"
      size="large"
      onClose={() => {
        setOpen(false)
        setShow(false)
      }}
      open={open}
      extra={
        <Space>
          <Button
            loading={checkIsLoading}
            size="small"
            // onClick={() => handlePrintToaThuoc(thongTinBN, toaThuoc, PkDangNhap)}
            onClick={() => checkQuyenBtn(maQuyenInToaThuoc)}
            type="primary"
            icon={<PrinterOutlined />}
          >
            Toa thuốc
          </Button>
          <Button
            loading={checkIsLoading}
            size="small"
            // onClick={() => handlePrintPhieuThuTien(infoBn, thongTinBN, toaThuoc)}
            onClick={() => checkQuyenBtn(maQuyenInPhieuThuTien)}
            type="primary"
            icon={<PrinterOutlined />}
          >
            Phiếu thu tiền
          </Button>
          <Button
            loading={checkIsLoading}
            size="small"
            // onClick={() => handlePrintPhieuThuNoiBo(infoBn, thongTinBN, toaThuoc)}
            onClick={() => checkQuyenBtn(maQuyenInPhieuThuNoiBo)}
            type="primary"
            icon={<PrinterOutlined />}
          >
            Phiếu thu nội bộ
          </Button>
        </Space>
      }
    >
      <div>
        <h2 className="text-gray-500 font-semibold text-lg mb-2">Thông tin bệnh nhân</h2>
        <div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Tên BN :</label>
              <p>{thongTinBN?.benhNhan?.tenBenhNhan}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Mã BN :</label>
              <p>{thongTinBN?.benhNhan?.maBenhNhan}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Ngày sinh :</label>
              <p>
                {thongTinBN?.benhNhan?.ngaySinh &&
                  moment(thongTinBN?.benhNhan?.ngaySinh).format('DD/MM/YYYY')}
              </p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Phiếu thu :</label>
              <p>
                {thongTinBN?.benhNhanThanhToans && thongTinBN?.benhNhanThanhToans[0]?.maPhieuThu}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Giới tính :</label>
              <p>{thongTinBN?.benhNhan?.gioiTinh}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Ngày thu :</label>

              <p>
                {thongTinBN?.benhNhanThanhToans
                  ? moment(thongTinBN?.benhNhanThanhToans[0]?.ngayThanhToan).format('DD/MM/YYYY')
                  : ''}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Đối tượng :</label>
              <p>{thongTinBN?.benhNhan?.tenDoiTuong}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Người thu :</label>
              <p>{thongTinBN?.benhNhanThanhToans && thongTinBN?.benhNhanThanhToans[0]?.thuNgan}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Nguồn :</label>
              <p>{thongTinBN?.tenNguon}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Quốc tịch :</label>
              <p>{thongTinBN?.benhNhan?.tenQuocTich}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Tên người thân :</label>
              <p>{thongTinBN?.tennguoinha}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">SĐT:</label>
              <p>{thongTinBN?.dienthoainguoinha}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-2/3 items-center ">
              <label className="font-semibold w-32">Chi nhánh :</label>
              <p>{thongTinBN?.benhNhan?.chiNhanh?.tenChiNhanh}</p>
            </div>
            <div className="flex w-1/3 items-center ">
              <label className="font-semibold w-20">Mã TCQG:</label>
              <p>{thongTinBN?.maTCQG}</p>
            </div>
          </div>
          <div className="flex">
            <label className="font-semibold w-32">Địa chỉ: </label>
            <p>{thongTinBN?.benhNhan?.diaChi}</p>
          </div>
        </div>
        <Divider style={{ margin: 15 }} />
        <div>
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-gray-500 font-semibold text-lg">Chi tiết thanh toán</h2>
            <div className="flex justify-between items-center gap-2">
              {(infoUser?.idNhom === 1 ||
                infoUser?.idNhom === 28 ||
                infoUser?.idNhom === 27 ||
                (infoBNTT?.idthungan === infoUser?.idnv &&
                  infoBNTT?.idcalamviec === infoUser?.dangNhap?.idCaLamViec)) && ( //|| infoUser?.idNhom === 4 hiện tại chỉ có IT mới dc chỉnh
                <>
                  {!show && !checkIsLoading && (
                    <>
                      <div
                        onClick={() => checkQuyenBtn(maQuyenChinhSuaPhieu)}
                        className="bg-green-200 flex text-green-700 hover:bg-green-500 cursor-pointer duration-150 hover:text-white items-center rounded-md p-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                          />
                        </svg>
                      </div>
                    </>
                  )}
                  {show && !checkIsLoading && (
                    <>
                      <div className="flex gap-2">
                        <div
                          onClick={handleSubmitBNTT}
                          className="bg-blue-200 flex text-blue-700 hover:bg-blue-500 cursor-pointer duration-150 hover:text-white items-center rounded-md p-[5px]"
                        >
                          <SaveOutlined style={{ fontSize: 18 }} />
                        </div>
                        <div
                          onClick={() => {
                            resetForm()
                          }}
                          className="bg-red-200 flex text-red-700 hover:bg-red-500 cursor-pointer duration-150 hover:text-white items-center rounded-md p-1"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18 18 6M6 6l12 12"
                            />
                          </svg>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {loading ? (
            <div className="flex justify-center items-center h-[6.75rem]">
              <Spin size="large" />
            </div>
          ) : (
            <div className="grid grid-flow-row gap-1 items-center">
              <div className="grid grid-cols-3 gap-2 items-center h-[1.5rem]">
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">Tiền mặt:</label>
                  <div>
                    {show ? (
                      <>
                        <InputNumber
                          size="small"
                          className="w-full"
                          value={formBNTT?.tienmat}
                          onChange={(value) => handleOnChangeHinhThucTT('tienmat', value)}
                          onKeyUp={(e) => {
                            if (e.key === 'p' || e.key === 'P') {
                              handleOnChangeHinhThucTT('tienmat', infoBNTT?.tienthanhtoan)
                            }
                          }}
                          step={100}
                          formatter={(value) => {
                            // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                            return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          min={0}
                          max={infoBNTT?.tienthanhtoan}
                        />
                      </>
                    ) : (
                      <>{formattedNumber(infoBNTT?.tienmat ?? 0)}</>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">Chuyển khoản:</label>

                  <div>
                    {show ? (
                      <>
                        <InputNumber
                          size="small"
                          className="w-full"
                          value={formBNTT?.chuyenkhoan}
                          onChange={(value) => handleOnChangeHinhThucTT('chuyenkhoan', value)}
                          onKeyUp={(e) => {
                            if (e.key === 'p' || e.key === 'P') {
                              handleOnChangeHinhThucTT('chuyenkhoan', infoBNTT?.tienthanhtoan)
                            }
                          }}
                          step={100}
                          formatter={(value) => {
                            // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                            return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          min={0}
                          max={infoBNTT?.tienthanhtoan}
                        />
                      </>
                    ) : (
                      <>{formattedNumber(infoBNTT?.chuyenkhoan ?? 0)}</>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">Máy POS:</label>
                  <div>
                    {show ? (
                      <>
                        <InputNumber
                          size="small"
                          className="w-full"
                          value={formBNTT?.pos}
                          onChange={(value) => handleOnChangeHinhThucTT('pos', value)}
                          onKeyUp={(e) => {
                            if (e.key === 'p' || e.key === 'P') {
                              handleOnChangeHinhThucTT('pos', infoBNTT?.tienthanhtoan)
                            }
                          }}
                          step={100}
                          formatter={(value) => {
                            // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                            return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          min={0}
                          max={infoBNTT?.tienthanhtoan}
                        />
                      </>
                    ) : (
                      <>{formattedNumber(infoBNTT?.pos ?? 0)}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-2 items-center h-[1.5rem]">
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">Momo:</label>
                  <div>
                    {show ? (
                      <>
                        <InputNumber
                          variant="filled"
                          readOnly
                          size="small"
                          className="w-full"
                          value={formBNTT?.momo}
                          onChange={(value) => handleOnChangeHinhThucTT('momo', value)}
                          onKeyUp={(e) => {
                            if (e.key === 'p' || e.key === 'P') {
                              handleOnChangeHinhThucTT('momo', infoBNTT?.tienthanhtoan)
                            }
                          }}
                          step={100}
                          formatter={(value) => {
                            // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                            return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          min={0}
                          max={infoBNTT?.tienthanhtoan}
                        />
                      </>
                    ) : (
                      <>{formattedNumber(infoBNTT?.momo ?? 0)}</>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">VNPay:</label>
                  <div>
                    {show ? (
                      <>
                        <InputNumber
                          variant="filled"
                          readOnly
                          size="small"
                          className="w-full"
                          value={formBNTT?.vnpay}
                          onChange={(value) => handleOnChangeHinhThucTT('vnpay', value)}
                          onKeyUp={(e) => {
                            if (e.key === 'p' || e.key === 'P') {
                              handleOnChangeHinhThucTT('vnpay', infoBNTT?.tienthanhtoan)
                            }
                          }}
                          step={100}
                          formatter={(value) => {
                            // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                            return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          min={0}
                          max={infoBNTT?.tienthanhtoan}
                        />
                      </>
                    ) : (
                      <>{formattedNumber(infoBNTT?.vnpay ?? 0)}</>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">ZaloPay:</label>
                  <div>
                    {show ? (
                      <>
                        <InputNumber
                          variant="filled"
                          readOnly
                          size="small"
                          className="w-full"
                          value={formBNTT?.zalopay}
                          onChange={(value) => handleOnChangeHinhThucTT('zalopay', value)}
                          onKeyUp={(e) => {
                            if (e.key === 'p' || e.key === 'P') {
                              handleOnChangeHinhThucTT('zalopay', infoBNTT?.tienthanhtoan)
                            }
                          }}
                          step={100}
                          formatter={(value) => {
                            // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                            return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          min={0}
                          max={infoBNTT?.tienthanhtoan}
                        />
                      </>
                    ) : (
                      <>{formattedNumber(infoBNTT?.zalopay ?? 0)}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-2 items-center h-[1.5rem]">
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">Thẻ thanh toán:</label>
                  <div>
                    {show ? (
                      <>
                        <InputNumber
                          variant="filled"
                          readOnly
                          size="small"
                          className="w-full"
                          value={formBNTT?.thett}
                          onChange={(value) => handleOnChangeHinhThucTT('thett', value)}
                          onKeyUp={(e) => {
                            if (e.key === 'p' || e.key === 'P') {
                              handleOnChangeHinhThucTT('thett', infoBNTT?.tienthanhtoan)
                            }
                          }}
                          step={100}
                          formatter={(value) => {
                            // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
                            return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          min={0}
                          max={infoBNTT?.tienthanhtoan}
                        />
                      </>
                    ) : (
                      <>{formattedNumber(infoBNTT?.thett ?? 0)}</>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">Phương thức TT:</label>
                  <div>Thanh toán</div>
                </div>
                <div className="grid grid-cols-2 items-center">
                  <label className="font-semibold">Xuất HĐ điện tử:</label>
                  <Checkbox
                    checked={show ? formBNTT?.hoadondientu : infoBNTT?.hoadondientu}
                    onChange={(e) =>
                      show &&
                      setFormBNTT((prev) => ({ ...prev, hoadondientu: e.target.checked ? 1 : 0 }))
                    }
                  />
                </div>
              </div>
              <div className="flex justify-start items-center h-[1.5rem]">
                <label className="font-semibold w-[8.4rem]">Ghi chú xuất HĐ:</label>
                <div className="w-full">
                  {show ? (
                    <>
                      <Input
                        size="small"
                        className="w-full text-red-500"
                        value={formBNTT?.ghichuxuathoadon}
                        onChange={(e) =>
                          setFormBNTT((prev) => ({ ...prev, ghichuxuathoadon: e.target.value }))
                        }
                      />
                    </>
                  ) : (
                    <>{infoBNTT?.ghichuxuathoadon}</>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <Divider style={{ margin: 15 }} />

        <div>
          {/* Table displaying prescription details */}
          {/* <h2 className="text-gray-500 font-semibold text-lg mb-2">Chi tiết toa thuốc</h2> */}
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-gray-500 font-semibold text-lg">Chi tiết toa thuốc</h2>
            <div className="flex justify-end items-center">
              {show && !loading && (infoUser?.idNhom === 1 || infoUser?.idNhom === 28) && (
                <Checkbox
                  checked={checkTraThuoc}
                  onChange={(e) => {
                    setCheckTraThuoc(e.target.checked)
                    setToaThuoc((prev) =>
                      prev?.map((item) => ({ ...item, soluongnew: item?.soluong })),
                    )
                  }}
                >
                  Trả thuốc
                </Checkbox>
              )}
            </div>
          </div>
          {loading ? (
            <div className="flex justify-center items-center h-[6.75rem]">
              <Spin size="large" />
            </div>
          ) : (
            <>
              {listHinh && (
                <div>
                  <ul className="flex flex-wrap gap-2">
                    {listHinh?.split(';')?.map((url, index) => {
                      return (
                        url?.length && (
                          <li
                            key={`hinh${index}:${url}`}
                            className="w-[7rem] h-[7rem] overflow-hidden border rounded-md relative"
                          >
                            {/* <DeleteOutlined
                                // onClick={() => deleteFileById(items.id)}
                                className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                                style={{ fontSize: 18 }}
                              /> */}
                            <a href={url} target="_blank">
                              <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                                <div className="relative "></div>
                                {/* <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                              {`${infoPhieuXuat?.maphieu}-Hinh${++index}`}
                            </p> */}
                              </div>
                              <img src={url} className="w-full" alt={url} />
                            </a>
                          </li>
                        )
                      )
                    })}
                  </ul>
                </div>
              )}
              <div className="mt-2">
                <ConfigProvider
                  theme={{
                    token: {
                      padding: 4,
                      fontSize: 13,
                    },
                    components: {
                      Table: {
                        rowHoverBg: '#ecf0f1',
                        headerBg: '#e6e6e6',
                        footerBg: '#e6e6e6',
                        borderColor: '#BABABA',
                      },
                    },
                  }}
                >
                  <Table
                    bordered
                    scroll={{
                      x: 1000,
                      y: 160,
                    }}
                    pagination={false}
                    columns={columnsThuoc}
                    dataSource={toaThuoc}
                    footer={() => (
                      <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                        Tổng: {formattedNumber(calculateSum())}
                      </div>
                    )}
                  />
                </ConfigProvider>
              </div>
              {checkTraThuoc && (
                <div>
                  <ul className="flex flex-wrap gap-2 mt-2 mb-2">
                    {selectedFile?.map((items, index) => (
                      <li
                        key={`upHinh${index}`}
                        className="w-[7rem] h-[7rem] overflow-hidden border rounded-md relative"
                      >
                        <DeleteOutlined
                          onClick={() => deleteFileById(items.id)}
                          className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                          style={{ fontSize: 18 }}
                        />
                        <a href={items?.URL} target="_blank">
                          <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                            <div className="relative "></div>
                            <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                              {items.name}
                            </p>
                          </div>
                          <img src={items.base64} className="w-full" alt={items.name} />
                        </a>
                      </li>
                    ))}
                  </ul>
                  {show && (
                    <div
                      style={{
                        border: '2px dashed #cccccc',
                        borderRadius: '4px',
                        padding: '30px',
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                      className="border"
                      ref={dropzoneRef}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      onClick={handleDivClick}
                    >
                      <CloudUploadOutlined style={{ fontSize: 36 }} className="text-gray-500" />
                      <label style={{ cursor: 'pointer' }}>
                        <p className="font-semibold text-lg text-gray-500">
                          Thả tập tin vào đây hoặc bấm vào để tải lên.
                        </p>
                      </label>
                      <input
                        ref={fileInputRef}
                        id="fileInput"
                        type="file"
                        onChange={handleFileSelect}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        {/* <div>
          <h2 className="text-gray-500 font-semibold text-lg mb-2">Chi tiết toa thuốc</h2>
          <div className="mt-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                  },
                },
              }}
            >
              <Table
                bordered
                pagination={{
                  pageSize: 10,
                  showSizeChanger: false,
                }}
                columns={columnsThuoc}
                dataSource={toaThuoc}
                footer={() => (
                  <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    Tổng: {formattedNumber(calculateSum())}
                  </div>
                )}
              />
            </ConfigProvider>
          </div>
        </div> */}
      </div>
    </Drawer>
  )
}

export default DrawerThuocBanLe

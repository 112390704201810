import Branch from '../components/layout/Branch/Branch'
import ChuyenKho from '../components/layout/ChuyenKho/ChuyenKho'
import Dashboard from '../components/layout/Dashboard/Dashboard'
import Staff from '../components/layout/Staff/Staff'
import Home from '../pages/Home/Home'
import Nhapkho from '../components/layout/NhapKho/Nhapkho'
import ThuaMua from '../components/layout/ThuMua/ThuaMua'
import TraCuu from '../components/layout/TraCuu/TraCuu'
import Test from '../components/common/test/Test'
import XetNghiem from '../components/layout/XetNghiem/XetNghiem'
import DmCDHA from '../components/layout/DmCDHA/DmCDHA'
import Cashier from '../components/layout/Cashier/Cashier'
import Company from '../components/layout/Company/Company'
import CardMemberShips from '../components/layout/Card/CardMemberShips'
import Partner from '../components/layout/Partner/Partner'
import BranchBuy from '../components/layout/BranchBuy/BranchBuy'
import DetaildCard from '../components/layout/Card/Detail/DetaildCard'
import CardReceive from '../components/layout/CardReceive/CardReceive'
import ChildrenClinic from '../components/layout/ChildrenClinic/ChildrenClinic'
import MaternityClinic from '../components/layout/MaternityClinic/MaternityClinic'
import TMTDClinic from '../components/layout/TMTDClinic/TMTDClinic'
import PHCNClinic from '../components/layout/PHCNClinic/PHCNClinic'
import OphthalmologyClinic from '../components/layout/OphthalmologyClinic/OphthalmologyClinic'
import DoKhucXa from '../components/layout/DoKhucXa/DoKhucXa'
import PaymentCard from '../components/layout/PaymentCard/PaymentCard'
import Selling from '../components/layout/Selling/Selling'
import PTThuoc from '../components/layout/Selling/PTThuoc/PTThuoc'
import GiaBan from '../components/layout/GiaBan/GiaBan'
import RegistrationCode from '../components/layout/RegistrationCode/RegistrationCode'
import TheKho from '../components/layout/TheKho/TheKho'
import VaiTro from '../components/layout/VaiTro/VaiTro'
import Voucher from '../components/layout/Voucher/Voucher'
import User from '../components/layout/User/User'
import XetNghiemClinic from '../components/layout/XetNghiemClinic/XetNghiemClinic'
import DichVu from '../components/layout/DichVu/DichVu'
import PhieuThu from '../components/layout/XetNghiemClinic/PhieuThu/PhieuThu'
import BanLe from '../components/layout/BanLe/BanLe'
import ImportThuMua from '../components/layout/ThuMua/Import/ImportThuMua'
import Customer from '../components/layout/Customer/Customer'
import ThuThuat from '../components/layout/ThuThuat/ThuThuat'
import ReportBranch from '../components/layout/ReportBranch/ReportBranch'
import BenhNhan from '../components/layout/BenhNhan/BenhNhan'
import DsBnChoKham from '../components/layout/Receive/DsBnChoKham'
import DetailBranch from '../components/layout/Report/DetailBranch/DetailBranch'
import TaiKham from '../components/layout/TaiKham/TaiKham'
import PhieuBanLe from '../components/layout/BanLe/PhieuBanLe'
import Mornitor from '../components/layout/Mornitor/Mornitor'
import Version from '../components/layout/Version/Version'
import DetailNhapKho from '../components/layout/NhapKho/Detail/DetailNhapKho'
import SieuAm from '../components/layout/SieuAm/SieuAm'
import Profit from '../components/layout/Report/Profit/Profit'
import VLTL from '../components/layout/VLTL/VLTL'
import DienTim from '../components/layout/DienTim/DienTim'
import SoKhamBenh from '../components/layout/SoKhamBenh/SoKhamBenh'
import TrongKinh from '../components/layout/TrongKinh/TrongKinh'
import KhamSangLoc from '../components/layout/KhamSangLoc/KhamSangLoc'
import BaoCaoPT from '../components/layout/BaoCaoPhieuThu/BaoCaoPhieuThu'
import XuatVacXin from '../components/layout/XuatVacXin/XuatVacXin'
import VatTuTieuHao from '../components/layout/Report/VatTuTieuHao/VatTuTieuHao'
import TiemChung from '../components/layout/TiemChung/TiemChung'
import PTVacXin from '../components/layout/XuatVacXin/PTThuoc/PTVacXin'
import LuocKham from '../components/layout/Report/LuotKham/LuocKham'
import Tool from '../components/layout/Tool/Tool'
import KhachKhamDoan from '../components/layout/KhachKhamDoan/KhachKhamDoan'
import CongTy from '../components/layout/KhachHang/CongTy'
import SendSms from '../components/layout/Sms/SendSms/SendSms'
import KhoHang from '../components/layout/KhoHang/KhoHang'
import BaoCaoSms from '../components/layout/Sms/BaoCao/BaoCaoSms'
import SoKhamThai from '../components/layout/SoKhamThai/SoKhamThai'
import ChuyenKhoCTY from '../components/layout/ChuyenKhoCTY/ChuyenKhoCTY'
import SmsTuDong from '../components/layout/Sms/SmsTuDong/SmsTuDong'
import ThongKe from '../components/layout/ThongKe/ThongKe'
import BieuMauSA from '../components/layout/BieuMauSA/BieuMauSA'
import TabsTon from '../components/layout/TonKho/TabsTon'
import PhongKham from '../components/layout/PhongKham/PhongKham'
import DatHang from '../components/layout/DatHang/DatHang'
import KiemDuyet from '../components/layout/KiemDuyet/KiemDuyet'
import NhanHang from '../components/layout/NhanHang/NhanHang'
import DuyetHang from '../components/layout/KSNB/DuyetHang'
import VanChuyen from '../components/layout/ThuMuaVPP/VanChuyen'
import GiaThuoc from '../components/layout/GiaThuoc/GiaThuoc'
import KhamSucKhoe from '../components/layout/KhamSucKhoe/KhamSucKhoe'
import Kho from '../components/layout/Kho/Kho'
import DonDatHang from '../components/layout/DonDatHang/DonDatHang'
import CanhBao from '../components/layout/CanhBao/CanhBao'
import DichVuReport from '../components/layout/Report/DichVu/DichVuReport'
import KhongMuaThuoc from '../components/layout/Report/KhongMuaThuoc/KhongMuaThuoc'
import ThuocKhongLay from '../components/layout/Report/ThuocKhongLay/ThuocKhongLay'
import DonViTinh from '../components/layout/DonViTinh/DonViTinh'
import TiepNhan from '../components/layout/PhanAnh/TiepNhan'
import XuLy from '../components/layout/PhanAnh/XuLy'
import SinhNhat from '../components/layout/SinhNhat/SinhNhat'
import CreateSuCo from '../components/layout/CreateSuCo/CreateSuCo'
import SuCo from '../components/layout/SuCo/SuCo'
import NhanSuCo from '../components/layout/NhanSuCo/NhanSuCo'
import KhuVuSuCo from '../components/layout/KhuVucSuCo/KhuVuSuCo'
import BenhAnMat from '../components/layout/BenhAnMat/BenhAnMat'
import LichLamViec from '../components/layout/LichLamViec/LichLamViecTheoCa'
import NoiSoi from '../components/layout/NoiSoi/NoiSoi'
import DoiTacKH from '../components/layout/DoiTacKH/DoiTacKH'
import LichLamViecTheoNhanVien from '../components/layout/LichLamViecNhanVien/LichLamViecTheoNhanVien'
import XuatSuDung from '../components/layout/XuatSuDung/XuatSuDung'
import DanhMucHoaHong from '../components/layout/DanhMucHoaHong/DanhMucHoaHong'
import Zns from '../components/layout/Sms/Zns/Zns'
import XuatTraNCC from '../components/layout/XuatTraNCC/XuatTraNCC'
import TheThanhVien from '../components/layout/Report/TheThanhVien/TheThanhVien'
import HoaHongThuThuat from '../components/layout/Report/HoaHongThuThuat/HoaHongThuThuat'
import BanNoiBo from '../components/layout/BanNoiBo/BanNoiBo'
import RegistrationGPP from '../components/layout/RegistrationGPP/RegistrationGPP'
import CongMaiKTV from '../components/layout/Report/CongMaiKTV/CongMaiKTV'
import ChamCong from '../components/layout/ChamCong/ChamCong'
import BaoCaoXetNghiem from '../components/layout/Report/XetNghiem/BaoCaoXetNghiem'
import BaoCaoVacXin from '../components/layout/Report/Vacxin/Vacxin'
import CapKey from '../components/layout/CapKey/CapKey'
import DoanhSo from '../components/layout/Report/DoanhSo/DoanhSo'
import DangKyKham from '../components/layout/DangKyKhamOnlMKT/DangKyKham'
import GongKinh from '../components/layout/Report/GongKinh/GongKinh'
import XuatNhaThuoc from '../components/layout/Report/XuatNhaThuoc/XuatNhaThuoc'
import ThoiGianKham from '../components/layout/Report/ThoiGianKham/ThoiGianKham'
import Vanban from '../components/layout/VanBan/VanBan/VanbanDi'
import NephrologyClinic from '../components/layout/NephrologyClinic/NephrologyClinic'
import TTV from '../components/layout/Report/TheThanhVien/TTV'
import Container from '../components/layout/Report/GongKinh/Container'
import LayoutBanLe from '../components/layout/BanLe/LayoutBanLe'
import DanhMuc from '../components/layout/VanBan/DanhMuc/DanhMuc'
import TiLeToa from '../components/layout/Report/TiLeToa/TiLeToa'
import VanBanDen from '../components/layout/VanBan/VanBanDen/VanBanDen'
import VanbanDi from '../components/layout/VanBan/VanBan/VanbanDi'
import Thongbao from '../components/layout/VanBan/ThongBao/Thongbao'
import DoanhThuThuoc from '../components/layout/Report/DoanhThuThuoc/DoanhThuThuoc'
import BoPhan from '../components/layout/BoPhan/BoPhan'
import TabsDieuChinhTonKho from '../components/layout/TonKho/DieuChinhTonKho/DieuChinhTonKho'
import Address from '../components/layout/Address/Address'
import KiemKe from '../components/layout/KiemKe/KiemKe'
import ReportTiemChung from '../components/layout/Report/ReportTiemChung/ReportTiemChung'
import PhieuXuatReport from '../components/layout/Report/PhieuXuat/PhieuXuatReport'
import LoaiHangHoa from '../components/layout/LoaiHangHoa/LoaiHangHoa'
import PhanQuyen from '../components/layout/PhanQuyen/PhanQuyen'
import Prodcuts from '../components/layout/Report/Products/Prodcuts'
import ThuocHetHan from '../components/layout/ThuocHetHan/ThuocHetHan'
import LichSuNguoiDung from '../components/layout/LichSuNguoiDung/LichSuNguoiDung'
import IDC10 from '../components/layout/IDC10/IDC10'
import LoiDan from '../components/layout/LoiDan/LoiDan'
import GiaNhap from '../components/layout/GiaNhap/GiaNhap'
import ContainerReport from '../components/layout/ReportBranch/ContainerReport'
//Tạo các route
export const routes = [
  {
    index: true,
    path: '/',
    Component: Home,
    isAuth: true,
    redirectPath: '/login',
  },
  // { path: '/login', Component: Login },
  {
    path: '/hethong/nhanvien',
    Component: Staff,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/hethong/vaitro',
    Component: VaiTro,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/danhmuc/chinhanh',
    Component: Branch,
    isAuth: true,
    redirectPath: '/login',
  },
  // {
  //   path: '/Tiepnhan/Tiepnhanbenh',
  //   Component: Receive,
  //   isAuth: true,
  //   redirectPath: '/login',
  // },
  {
    path: '/Dashboard',
    Component: Dashboard,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/thumuakho/chuyenkho',
    Component: ChuyenKho,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/thumuakho/nhapkho',
    Component: Nhapkho,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/thumuakho/nhapkho/chitiet/:id',
    Component: DetailNhapKho,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/thumuakho/thuoc',
    Component: ThuaMua,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/thumuakho/giaban',
    Component: GiaBan,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/traCuu',
    Component: TraCuu,
    redirectPath: '/login',
  },
  {
    path: '/test',
    Component: Test,
    redirectPath: '/login',
  },
  {
    path: '/thumuakho/tonkho',
    Component: TabsTon,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/canlamsang/dmxetnghiem',
    Component: XetNghiem,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/canlamsang/dmcdha',
    Component: DmCDHA,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/tiepnhan/thungan',
    Component: Cashier,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/danhmuc/congty',
    Component: Company,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/khachhang/thetvtt',
    Component: CardMemberShips,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/khachhang/voucher',
    Component: Voucher,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/danhmuc/doitac',
    Component: Partner,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'thumuakho/chinhanhban',
    Component: BranchBuy,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/khachhang/thetvtt/chiTiet/:id',
    Component: DetaildCard,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'tiepnhan/the',
    Component: CardReceive,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'phongkham/khamnhi',
    Component: ChildrenClinic,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'tiepnhan/thethanhtoan',
    Component: PaymentCard,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'nhathuoc/bantoa',
    Component: Selling,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'nhathuoc/bantoa/phieu',
    Component: PTThuoc,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'thumuakho/masodangky',
    Component: RegistrationCode,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'thumuakho/thekho',
    Component: TheKho,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'thumuakho/thuocVT/import',
    Component: ImportThuMua,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'hethong/nguoidung',
    Component: User,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'canlamsang/xetnghiem',
    Component: XetNghiemClinic,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'canlamsan/mornitor',
    Component: Mornitor,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'canlamsang/xetnghiem/PhieuThu',
    Component: PhieuThu,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'danhmuc/dichvu',
    Component: DichVu,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'nhathuoc/banle',
    Component: LayoutBanLe,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'nhathuoc/banle/PhieuBanLe',
    Component: PhieuBanLe,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'khachhang/dskhachhang',
    Component: Customer,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'lamsang/thuthuat',
    Component: ThuThuat,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'baocao/chinhanh',
    Component: ContainerReport,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'baocao/phieuthu',
    Component: BaoCaoPT,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'tracuu/benhnhanh/',
    Component: BenhNhan,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'tracuu/sokhambenh',
    Component: SoKhamBenh,
    isAuth: true,
    redirectPath: '/login',
  },
  // {
  //   path: 'tiepnhan/DsBnChoKham/',
  //   Component: DsBnChoKham,
  //   isAuth: true,
  //   redirectPath: '/login',
  // },
  {
    path: 'baocao/chinhanh/chitiet/:id',
    Component: DetailBranch,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'khachhang/taikham/',
    Component: TaiKham,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'phongkham/khamsan',
    Component: MaternityClinic,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'hethong/phienban/',
    Component: Version,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'canlamsan/sieuam/',
    Component: SieuAm,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'phongkham/khammat',
    Component: OphthalmologyClinic,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'phongkham/tmtd',
    Component: TMTDClinic,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'baocao/loinhuan/',
    Component: Profit,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'phongkham/phcn',
    Component: PHCNClinic,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'canlamsang/vltl',
    Component: VLTL,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'canlamsang/dientim',
    Component: DienTim,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'phongkham/dokhucxa',
    Component: DoKhucXa,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'canlamsang/trongkinh',
    Component: TrongKinh,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'phongkham/khamsangloc',
    Component: KhamSangLoc,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'nhathuoc/xuatvacxin',
    Component: XuatVacXin,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'tiemchung/tiemchung',
    Component: TiemChung,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'baocao/vattutieuhoa',
    Component: VatTuTieuHao,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'nhathuoc/xuatvacxin/phieu',
    Component: PTVacXin,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'baocao/luotkham/',
    Component: LuocKham,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'tienich/congcu/',
    Component: Tool,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/khamdoan/Khachkham',
    Component: KhachKhamDoan,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/khamdoan/congty',
    Component: CongTy,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'sms/guiSms/',
    Component: SendSms,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'sms/baocaosms/',
    Component: BaoCaoSms,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'sms/smstudong/',
    Component: SmsTuDong,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/khamdoan/congty',
    Component: CongTy,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/danhmuc/khohang',
    Component: KhoHang,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/tracuu/sokhamthai',
    Component: SoKhamThai,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/thumuakho/chuyenkhocty',
    Component: ChuyenKhoCTY,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/danhmuc/bieumau',
    Component: BieuMauSA,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/baocao/thongke',
    Component: ThongKe,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'danhmuc/phongkham',
    Component: PhongKham,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'vanphongpham/dathang',
    Component: DatHang,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'vanphongpham/kiemduyet',
    Component: KiemDuyet,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'vanphongpham/duyethang',
    Component: DuyetHang,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'vanphongpham/vanchuyen',
    Component: VanChuyen,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'vanphongpham/nhanhang',
    Component: NhanHang,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'nhathuoc/banggia/',
    Component: GiaThuoc,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'phongkham/khamsuckhoe',
    Component: KhamSucKhoe,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'danhmuc/kho',
    Component: Kho,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/phananh/tiepnhan',
    Component: TiepNhan,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/phananh/xuly',
    Component: XuLy,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/vanphongpham/dondathang',
    Component: DonDatHang,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/tiemchung/canhbao',
    Component: CanhBao,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/baocao/dichvu',
    Component: DichVuReport,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/nhathuoc/Khongmuathuoc',
    Component: KhongMuaThuoc,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/bantoa/thuockhonglay',
    Component: ThuocKhongLay,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/danhmuc/donvitinh',
    Component: DonViTinh,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/khachhang/sinhnhat',
    Component: SinhNhat,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/baosuco/taosuco',
    Component: CreateSuCo,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/baosuco/danhsachsuco',
    Component: SuCo,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/baosuco/nhansuco',
    Component: NhanSuCo,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/baosuco/khuvuquanly',
    Component: KhuVuSuCo,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'tracuu/benhanmat',
    Component: BenhAnMat,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/calamviec/xeplichtheoca',
    Component: LichLamViec,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/canlamsan/noisoi',
    Component: NoiSoi,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/khachhang/doitac',
    Component: DoiTacKH,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/calamviec/xeplichtheonhanvien',
    Component: LichLamViecTheoNhanVien,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'thumuakho/xuatsudung',
    Component: XuatSuDung,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/danhmuc/hoahong',
    Component: DanhMucHoaHong,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'sms/zns/',
    Component: Zns,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'nhathuoc/xuattrancc',
    Component: XuatTraNCC,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/baocao/thethanhvien',
    Component: TTV,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/baocao/hoahongthuthuat',
    Component: HoaHongThuThuat,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: 'nhathuoc/bannoibo',
    Component: BanNoiBo,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/thumuakho/gpp',
    Component: RegistrationGPP,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/calamviec/chamcong',
    Component: ChamCong,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/baocao/xetnghiem',
    Component: BaoCaoXetNghiem,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/baocao/vacxin',
    Component: BaoCaoVacXin,
    isAuth: true,
  },
  {
    path: '/hethong/capkey',
    Component: CapKey,
    isAuth: true,
  },
  {
    path: '/khachhang/dangkykhamonlinemkt',
    Component: DangKyKham,
    isAuth: true,
    redirectPath: '/login',
  },
  {
    path: '/baocao/gongkinh',
    Component: Container,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: '/baocao/xuatnhathuoc',
    Component: XuatNhaThuoc,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: '/baocao/thoigiankham',
    Component: ThoiGianKham,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: 'vanban/danhmucvanban',
    Component: DanhMuc,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: 'vanban/vanbandi',
    Component: VanbanDi,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: 'vanban/vanbanden',
    Component: VanBanDen,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: '/phongkham/noithan',
    Component: NephrologyClinic,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: '/baocao/tiletoa',
    Component: TiLeToa,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: 'vanban/thongbaovanban',
    Component: Thongbao,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: '/baocao/doanhthuthuoc',
    Component: DoanhThuThuoc,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: '/danhmuc/bophan',
    Component: BoPhan,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: '/thumuakho/dieuchinhtonkho',
    Component: TabsDieuChinhTonKho,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: '/danhmuc/diachi',
    Component: Address,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: '/thumuakho/khokiemke',
    Component: KiemKe,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: '/baocao/tiemchung',
    Component: ReportTiemChung,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: '/baocao/phieuxuat',
    Component: PhieuXuatReport,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: '/danhmuc/loaihanghoa',
    Component: LoaiHangHoa,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: '/hethong/phanquyen',
    Component: PhanQuyen,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: '/baocao/hanghoa',
    Component: Prodcuts,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: '/nhathuoc/thuochethan',
    Component: ThuocHetHan,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: 'hethong/lichsunguoidung',
    Component: LichSuNguoiDung,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: 'danhmuc/ICD10',
    Component: IDC10,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: 'danhmuc/loidan',
    Component: LoiDan,
    isAuth: true,
  },
  {
    redirectPath: '/login',
    path: '/thumuakho/gianhap',
    Component: GiaNhap,
    isAuth: true,
  },
]

import { ConfigProvider, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { infoBNSieuAm } from '../../../../store/actions/sieuAmAction'
import { sieuAmService } from '../../../../services/SieuAm/sieuAmService'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { EyeOutlined } from '@ant-design/icons'
import ModalHistory from './ModalHistory/ModalHistory'
import ToastCus from '../../../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')

const History = () => {
  const { history } = useSelector((state) => state.sieuAmReducer)
  const [isModal, setisModal] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [infoHistory, setinfoHistory] = useState(null)
  const [image, setImage] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const dispatch = useDispatch()
  //--------- thông tin bệnh nhân chờ siêu âm  -----------//
  const onClickBn = async (record) => {
    setisModal(true)
    try {
      setisLoading(true)
      const { data } = await sieuAmService.getInfoBn(record?.idbnttc, record?.idbncdha)
      if (!data?.idbnsa) {
        ToastCus.fire({
          icon: 'error',
          title: 'Không tìm thấy dữ liệu!',
        })
        return
      }
      setinfoHistory(data)
      if (data?.linkhinhanh) {
        const images = data.linkhinhanh.split(';').map((item) => ({ url: item, checked: false }))
        setImage(images)
      } else {
        setImage([])
      }
      if (data?.bieumau) {
        const convertJson = JSON.parse(data?.bieumau)
        const contentState = convertFromRaw(convertJson)
        setEditorState(EditorState.createWithContent(contentState))
      } else {
        setEditorState(null)
      }
    } catch (error) {
      console.log('Lỗi khi load thông tin lịch sử nội soi : ', error)
      setinfoHistory(null)
      setImage([])
      setEditorState(null)
      setisModal(false)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
    } finally {
      setTimeout(() => {
        setisLoading(false)
      }, 1500)
    }
    // setListImagePrint([])
    // dispatch(infoBNSieuAm(record.idbnttc, record.idbncdha))
  }
  return (
    <>
      <ModalHistory
        isModal={isModal}
        setisModal={setisModal}
        isLoading={isLoading}
        infoHistory={infoHistory}
        image={image}
        editorState={editorState}
      />
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          onRow={(record) => ({
            onClick: () => onClickBn(record),
          })}
          bordered
          dataSource={history}
          columns={[
            {
              title: 'STT',
              dataIndex: 'STT',
              key: 'STT',
              align: 'center',
              width: 40,
              render: (text, record, index) => ++index,
            },
            {
              title: 'Ngày nội soi',
              dataIndex: 'ngaysieuam',
              key: 'ngaysieuam',
              width: 150,
              render: (text, record, index) =>
                text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-',
            },
            {
              title: 'Chỉ định',
              dataIndex: 'chidinh',
              key: 'chidinh',
            },
            {
              title: 'Bác sĩ nội soi',
              dataIndex: 'bssieuam',
              key: 'bssieuam',
            },
            {
              title: '',
              dataIndex: 'action',
              key: 'action',
              align: 'center',
              width: 40,
              render: (text, record, index) => (
                <>
                  <Tooltip placement="topRight" title={'Xem chi tiết'} color={'blue'}>
                    <EyeOutlined className="text-lg text-blue-500 cursor-pointer flex justify-center items-center" />
                  </Tooltip>
                </>
              ),
            },
          ]}
        />
      </ConfigProvider>
    </>
  )
}

export default History

import { PrinterOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Checkbox, DatePicker, Divider, Input, Popover, Select } from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import * as typeAction from '../../../../../../store/constants/constants'
import moment from 'moment'
import 'moment/locale/vi'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { https } from '../../../../../../services/apiService'
import { childrenClinicService } from '../../../../../../services/childrenClinic/childrenClinicService'
import {
  deleteBNThuocBSKhongKeToaAction,
  updateBNThuocBSKeToaLaiAction,
} from '../../../../../../store/actions/childrenClinicAction'
import { generatePhieuToaThuoc } from '../../../../../../utils/report/phieuToaThuoc'
import ToaThuoc from '../../ToaThuoc/ToaThuoc'
import { generatePhieuKhamBenh } from '../../../../../../utils/report/phieuKhamBenh'
import { generatePhieuToaThuocKoLayThuoc } from '../../../../../../utils/report/phieuToaThuocKoLayThuoc'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

const FormCD = ({
  date,
  count,
  setCount,
  checkBox,
  setCheckBox,
  formik,
  setDate,
  isPrintToaThuocKhiLuu,
  setIsPrintToaThuocKhiLuu,
  infoDKX,
  formBenhNhan,
}) => {
  const dispatch = useDispatch()
  const { loidan, DetailBN, ThuocBN, infoBN, DetailToaThuocBN } = useSelector(
    (state) => state.childrenClinicReducer,
  )
  const { listBNDV } = useSelector((state) => state.doKhucXaReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [ChanDoan, setChanDoan] = useState([])
  const [listLyDoKhongKeToa, setListLyDoKhongKeToa] = useState([])
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf('day')
  }
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }
  const handleSlected = (name) => (value) => {
    // console.log(value)
    formik.setFieldValue(name, value)
  }
  const onChangeDatePicker = (date, dateString) => {
    setDate(moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'))
    formik.setFieldValue('ngaytaikham', moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'))
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      fechChanDoan(keyword)
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const fechChanDoan = async (keyword) => {
    try {
      const result = await childrenClinicService.getChanDoan(keyword)
      setChanDoan(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchMissingOption = async (idIcd) => {
    try {
      const response = await https.get(`ChanDoan/GetICD10ByID?idICD=${idIcd}`)
      const newOption = response.data
      console.log(ChanDoan, newOption)
      setChanDoan((prevOptions) => [...prevOptions, newOption])
    } catch (error) {
      console.log(error)
    }
  }
  const fetchListLyDoKhongKeToa = async () => {
    try {
      const result = await childrenClinicService.getListLyDoKhongKeToa()
      setListLyDoKhongKeToa(result?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const onClickChanDoan = (value, options, key, keyIDC) => {
    const newValue = options.mabenh + '-' + options.icD10TV + '/'
    // const newValue = formik.values.chandoan
    //   ? formik.values.chandoan + ' / ' + options.label
    //   : options.label

    formik.setFieldValue(key, newValue)
    formik.setFieldValue(keyIDC, value)
    //reset lai chan doan
    // fechChanDoan('')
  }

  const onClick = (e) => {
    if (
      DetailBN?.trangthaikhambenh === 2 &&
      (DetailToaThuocBN?.BSKEDON || DetailToaThuocBN?.NGAYKEDON) &&
      DetailToaThuocBN?.DANHANTOA === 1
    ) {
      Toast.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    const checkStatus = DetailBN?.trangthaikhambenh === 2 || DetailBN?.trangthaikhambenh === 1
    const { checked } = e.target
    if (checkStatus) {
      formik.setFieldValue('lydokolaythuoc', null)
      formik.setFieldValue('ngaytaikham', dayjs(new Date()))
      formik.setFieldError('ngaytaikham', null)
      setDate(dayjs(new Date()))
      setCount(0)
      setCheckBox(checked)
    }
  }
  const handleLuuVaIn = (isPrint) => {
    setIsPrintToaThuocKhiLuu(isPrint)
    // if (
    //   infoDKX &&
    //   infoDKX?.idbnttc &&
    //   listBNDV?.length &&
    //   listBNDV?.find((item) => item?.idnhomdv === 4) &&
    //   !infoDKX?.nguoikham
    // ) {
    //   Toast.fire({
    //     icon: 'error',
    //     title: 'Bệnh nhân chưa có kết quả đo khúc xạ!',
    //   })
    //   return
    // }

    // if (ThuocBN?.length > 0) formik.setFieldValue('lydokolaythuoc', 0)
    if (
      (isPrint || DetailBN?.trangthaikhambenh !== 1) &&
      ((checkBox === false && !ThuocBN?.filter((item) => !item?.isGongKinh)?.length) ||
        (checkBox && !formik.values.lydokolaythuoc))
    ) {
      formik.setFieldValue('lydokolaythuoc', null)
      formik.setFieldError('lydokolaythuoc', true)
      formik.setFieldValue('ngaytaikham', dayjs(new Date()))
      formik.setFieldError('ngaytaikham', null)
      setDate(dayjs(new Date()))
      setCount(0)
      setCheckBox(true)
      Toast.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    if (
      checkBox === false &&
      ThuocBN?.find(
        (item) =>
          !item?.isGongKinh &&
          (item?.soLuong <= 0 || item?.ton <= 0 || !item?.soLuong || !item?.ton),
      )
    ) {
      Toast.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại số lượng thuốc trong toa!',
      })
      return
    }
    if (DetailBN?.trangthaikhambenh === 1) {
      if (_.isEmpty(formik.errors)) {
        //nếu mà không có lỗi mới lưu
        //formik.setFieldValue('formPhuSan', formikPhuSan.values)
        formik.handleSubmit()
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Vui lòng kiểm tra lại!',
        })
      }
      return
    }
    if (DetailBN?.trangthaikhambenh === 2) {
      if (
        (DetailToaThuocBN?.BSKEDON || DetailToaThuocBN?.NGAYKEDON) &&
        DetailToaThuocBN?.DANHANTOA === 1
      ) {
        Toast.fire({
          icon: 'error',
          title: 'Dược sĩ đang thao tác!',
        })
        return
      }
      if (checkBox && !formik.values.lydokolaythuoc) {
        Toast.fire({
          icon: 'error',
          title: 'Vui lòng kiểm tra lại!',
        })
        return
      }
      // kê lại toa khi dược chưa lưu
      if (checkBox || ThuocBN?.length < 1) {
        // lưu khi tích chọn không kê toa hoặc toa thuốc rỗng
        // dispatch(deleteBNThuocBSKhongKeToaAction(DetailBN?.idbnttc))
        dispatch(
          deleteBNThuocBSKhongKeToaAction(
            formik.values,
            formBenhNhan.values,
            DetailBN?.idbnttc,
            formik.values.lydokolaythuoc,
          ),
        )
        setCheckBox(false)
      } else {
        // lưu khi không chỉnh sửa hoặc chỉnh số lượng thuốc trong toa hoặc cho thêm thuốc vào toa
        //dispatch(updateBNThuocBSKeToaLaiAction(DetailBN?.idbnttc, ThuocBN))
        dispatch(
          updateBNThuocBSKeToaLaiAction(
            formik.values,
            formBenhNhan.values,
            DetailBN?.idbnttc,
            ThuocBN?.map((item, index) => ({
              ...item,
              lieuDung: `${item?.lieuDung ?? ''}`,
              soLanNgay: `${item?.soLanNgay ?? ''}`,
              stt: `${++index}`,
            })),
            infoBN,
            { ...DetailBN, dataDoKhucXa: infoDKX && infoDKX?.nguoikham ? infoDKX : null },
            PkDangNhap,
            isPrint,
          ),
        )
      }
      return
    }
  }
  const handleChangeLyDoKhongLayThuoc = (value) => {
    formik.setFieldValue('lydokolaythuoc', value)
  }
  useEffect(() => {
    if (!checkBox && ThuocBN?.filter((item) => !item?.isGongKinh)?.length > 0)
      formik.setFieldValue('lydokolaythuoc', 0)
  }, [ThuocBN?.length])
  useEffect(() => {
    fechChanDoan('')
    fetchListLyDoKhongKeToa()
  }, [])
  useEffect(() => {
    if (
      formik.values.idchandoan &&
      !ChanDoan?.find((item) => item?.idicD10 === formik.values.idchandoan)
    ) {
      fetchMissingOption(formik.values.idchandoan)
    }
  }, [formik.values.idchandoan])
  const handlePrint = () => {
    //không có toa thuốc nhưng vẫn muốn in lại toa chỉ có chuyên khoa nhi + mắt,
    if (
      checkBox &&
      (PkDangNhap?.idChuyenKhoa === 1 ||
        (PkDangNhap?.idChuyenKhoa === 4 && infoDKX && infoDKX?.nguoikham))
    ) {
      generatePhieuToaThuoc(
        infoBN,
        {
          ...DetailBN,
          dataDoKhucXa: infoDKX?.nguoikham ? infoDKX : null,
          khongCoToaNhungVanInToa: 1,
        },
        [{ idphanloai: 1 }], //không có thuốc nhưng vẫn in phiếu toa thuốc
        PkDangNhap,
      )
      return
    } else if (checkBox) {
      //sản + lão thông báo lỗi
      Toast.fire({
        icon: 'error',
        title: 'Bệnh nhân không kê toa!',
      })
      return
    }

    //có thuốc in lại toa
    const infoBn = {
      diaChi: DetailBN.benhNhan.diaChi,
      tenPhuongXa: DetailBN.benhNhan.tenPhuongXa,
      tenQuanHuyen: DetailBN.benhNhan.tenQuanHuyen,
      tenTinhTP: DetailBN.benhNhan.tenTinhTP,
    }
    // const formatToaThuoc = DetailToaThuocBN.DanhSachThuoc.filter(
    //   (item) => item?.idnhomthuoc !== 179 && item?.idnhomthuoc !== 205,
    // ).map((item) => ({
    //   ...item,
    //   soLuong: item.soluong,
    //   tenhoatchat: item.tenhoatchat,
    //   duongDung: item.duongdung,
    //   soLanNgay: item.solanngay,
    //   lieuDung: item.lieudung,
    //   ghiChu: item.ghichu,
    // }))

    let listVT = []
    // gộp các dòng khác số lô hạn dùng lại thành 1 dòng
    for (const item of DetailToaThuocBN?.DanhSachThuoc) {
      if (listVT?.find((itemVT) => itemVT?.idthuoc === item?.idthuoc)) {
        listVT?.map(
          (itemVT) =>
            itemVT?.idthuoc === item?.idthuoc &&
            (itemVT.soLuong += item.soluong) &&
            (itemVT.soluong += item.soluong),
        )
      } else {
        listVT.push({
          ...item,
          soLuong: item.soluong,
          tenhoatchat: item.tenhoatchat,
          duongDung: item.duongdung,
          soLanNgay: item.solanngay,
          lieuDung: item.lieudung,
          ghiChu: item.ghichu,
        })
      }
    }
    generatePhieuToaThuoc(
      infoBn,
      { ...DetailBN, dataDoKhucXa: infoDKX?.nguoikham ? infoDKX : null },
      listVT,
      PkDangNhap,
    )
  }
  const handleSoNgayTK = (e) => {
    const value = e.target.value >= 0 && e.target.value < 10000 ? e.target.value : 0
    const formattedDate = moment()?.add(value, 'days')?.format('YYYY-MM-DD')

    // setCount(value)
    formik.setFieldValue('ngaytaikham', formattedDate)
    setDate(formattedDate)
    // dispatch({
    //   type: typeAction.DISPATCH_SO_LAN_THUOC_NHI,
    //   payload: value,
    // })
  }
  const checkUpdate =
    DetailBN && DetailBN?.trangthaikhambenh !== 1 && DetailToaThuocBN?.DANHANTOA !== 0
  const content = (
    <div className="w-32">
      <Button
        onClick={handlePrint}
        type="primary"
        disabled={!infoBN || !DetailBN}
        style={{ marginBottom: '8px', width: '100%' }}
      >
        Toa thuốc
      </Button>
      <Button
        type="primary"
        onClick={() => {
          generatePhieuKhamBenh(DetailBN, PkDangNhap)
        }}
        style={{ width: '100%' }}
      >
        Phiếu khám bệnh
      </Button>
    </div>
  )

  return (
    <div>
      <form>
        <div className="flex gap-2 justify-end">
          <div className="flex w-40 items-center">
            <label className="font-semibold w-1/2">Số ngày TK:</label>
            <Input
              disabled={checkUpdate}
              style={{ color: 'black' }}
              type="number"
              min={0}
              step={1}
              max={10000}
              size="small"
              variant="filled"
              // readOnly
              className="w-1/2"
              value={count ?? 0}
              onChange={handleSoNgayTK}
            />
          </div>
          <div className="flex w-56 items-center">
            <label className="font-semibold w-1/2 text-end mr-2">Hẹn tái khám:</label>
            <DatePicker
              placeholder=" "
              disabled={checkUpdate}
              style={{ color: 'black' }}
              status={
                formik.errors.ngaytaikham && formik.values.ngaytaikham === null ? 'error' : ''
              }
              allowClear={false}
              onChange={onChangeDatePicker}
              value={formik.values.ngaytaikham === null ? '' : dayjs(formik.values.ngaytaikham)}
              size="small"
              className="w-1/2"
              format={'DD/MM/YYYY'}
              disabledDate={disabledDate}
            />
          </div>
          <Checkbox onChange={onClick} checked={checkBox} className="flex items-center">
            Không kê toa
          </Checkbox>
          {checkUpdate && (
            <Popover content={content} trigger="click" placement="bottom">
              <Button type="primary" disabled={!infoBN || !DetailBN}>
                <PrinterOutlined /> In
              </Button>
            </Popover>
          )}
          <Button
            disabled={!infoBN || DetailBN?.trangthaikhambenh === 4}
            onClick={() => handleLuuVaIn(false)}
            type="primary"
          >
            {DetailBN?.trangthaikhambenh === 1 ? 'Cập nhật' : 'Lưu'}
          </Button>
          <Button
            disabled={!infoBN || DetailBN?.trangthaikhambenh === 4}
            onClick={() => handleLuuVaIn(true)}
            type="primary"
          >
            Lưu & in
          </Button>
        </div>
        <div className="h-[693px]  overflow-x-hidden">
          {checkBox ? (
            <div className="flex gap-1 mt-1">
              <label className="font-semibold w-44 text-end">
                <span className="text-red-500">(*)</span>&nbsp;Lý do không kê toa:
              </label>
              <Select
                // disabled={DetailBN && DetailBN?.trangthaikhambenh !== 1}
                style={{ color: 'black' }}
                className="w-full"
                size="small"
                status={formik.errors.lydokolaythuoc ? 'error' : ''}
                onChange={handleChangeLyDoKhongLayThuoc}
                value={formik.values.lydokolaythuoc}
                options={listLyDoKhongKeToa.map((item) => ({
                  key: item.idld,
                  value: item.idld,
                  label: item.lydo,
                }))}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">
              <span className="text-red-500">(*)</span>&nbsp;Lý do khám:
            </label>
            <Input
              disabled={checkUpdate}
              style={{ color: 'black' }}
              status={formik.errors.lydokham ? 'error' : ''}
              onChange={formik.handleChange}
              value={formik.values.lydokham}
              name="lydokham"
              size="small"
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">
              <span className="text-red-500">(*)</span>&nbsp;Bệnh sử:
            </label>
            <Input
              disabled={checkUpdate}
              style={{ color: 'black' }}
              status={formik.errors.benhsu ? 'error' : ''}
              onChange={formik.handleChange}
              value={formik.values.benhsu}
              name="benhsu"
              size="small"
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">
              <span className="text-red-500">(*)</span>&nbsp;Triệu chứng:
            </label>
            <Input
              disabled={checkUpdate}
              style={{ color: 'black' }}
              status={formik.errors.trieuchung ? 'error' : ''}
              onChange={formik.handleChange}
              value={formik.values.trieuchung}
              name="trieuchung"
              size="small"
            />
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Chẩn đoán:</label>
            <div className="flex gap-3 w-full">
              <Select
                placeholder="ICD-10"
                disabled={checkUpdate}
                style={{ color: 'black' }}
                showSearch
                dropdownStyle={{ width: 550 }}
                filterOption={false}
                className="w-28"
                size="small"
                onChange={(value, options) =>
                  onClickChanDoan(value, options, 'chandoan', 'idchandoan')
                }
                onSearch={debounceDropDown}
                suffixIcon={<SearchOutlined className=" " />}
                notFoundContent={null}
                value={formik.values.idchandoan}
                options={ChanDoan?.map((items) => ({
                  label: items.mabenh,
                  value: items.idicD10,
                  mabenh: items.mabenh,
                  icD10TV: items.icD10TV,
                  ...items,
                }))}
                optionRender={(options) => (
                  <ul className="flex ">
                    <li className="w-10 mr-2">{options.data.mabenh}</li>
                    <li className="w-full border-l px-2 truncate">{options.data.icD10TV}</li>
                  </ul>
                )}
              />
              <Input
                disabled={checkUpdate}
                style={{ color: 'black' }}
                onChange={formik.handleChange}
                value={formik.values.chandoan}
                name="chandoan"
                size="small"
              />
            </div>
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Chẩn đoán bệnh phụ:</label>
            <div className="flex gap-3 w-full">
              <Select
                placeholder="ICD-10"
                dropdownStyle={{ width: 550 }}
                disabled={checkUpdate}
                style={{ color: 'black' }}
                showSearch
                filterOption={false}
                className="w-28"
                size="small"
                onChange={(value, options) =>
                  onClickChanDoan(value, options, 'benhkemtheo', 'idicD10BENHKEMTHEO')
                }
                onSearch={debounceDropDown}
                suffixIcon={<SearchOutlined className=" " />}
                notFoundContent={null}
                value={formik.values.idicD10BENHKEMTHEO}
                options={ChanDoan?.map((items) => ({
                  label: items.mabenh,
                  value: items.idicD10,
                  mabenh: items.mabenh,
                  icD10TV: items.icD10TV,
                  ...items,
                }))}
                optionRender={(options) => (
                  <ul className="flex">
                    <li className="w-10 mr-2">{options.data.mabenh}</li>
                    <li className="w-full border-l px-2 truncate">{options.data.icD10TV}</li>
                  </ul>
                )}
              />
              <Input
                disabled={checkUpdate}
                style={{ color: 'black' }}
                onChange={formik.handleChange}
                value={formik.values.benhkemtheo}
                name="benhkemtheo"
                size="small"
              />
            </div>
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">
              {' '}
              <span className="text-red-500">(*)</span> Lời dặn:
            </label>
            <div className="flex gap-3 w-full">
              <Select
                dropdownStyle={{ width: 750 }}
                disabled={checkUpdate}
                style={{ color: 'black' }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                optionRender={(options) => (
                  <h2 className="text-pretty border-b">{options.data.label}</h2>
                )}
                options={loidan?.map((items) => ({
                  label: items.loiDan,
                  value: items.loiDan,
                }))}
                placeholder="Gợi ý"
                showSearch
                status={formik.errors.idloidan ? 'error' : ''}
                onChange={handleSlected('loidan')}
                value={null}
                name="loidan"
                size="small"
                className="w-28"
              />
              <Input
                disabled={checkUpdate}
                style={{ color: 'black' }}
                status={formik.errors.loidan ? 'error' : ''}
                onChange={formik.handleChange}
                value={formik.values.loidan}
                name="loidan"
                size="small"
              />
            </div>
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Chẩn đoán ban đầu:</label>
            <div className="flex gap-3 w-full">
              <Select
                placeholder="ICD-10"
                dropdownStyle={{ width: 550 }}
                disabled={checkUpdate}
                style={{ color: 'black' }}
                showSearch
                filterOption={false}
                className="w-28"
                size="small"
                onChange={(value, options) =>
                  onClickChanDoan(value, options, 'chandoanbandau', 'idicD10BANDAU')
                }
                onSearch={debounceDropDown}
                suffixIcon={<SearchOutlined className=" " />}
                notFoundContent={null}
                value={formik.values.idicD10BANDAU}
                options={ChanDoan?.map((items) => ({
                  label: items.mabenh,
                  value: items.idicD10,
                  mabenh: items.mabenh,
                  icD10TV: items.icD10TV,
                  ...items,
                }))}
                optionRender={(options) => (
                  <ul className="flex">
                    <li className="w-10 mr-2">{options.data.mabenh}</li>
                    <li className="w-full border-l px-2 truncate">{options.data.icD10TV}</li>
                  </ul>
                )}
              />
              <Input
                disabled={checkUpdate}
                style={{ color: 'black' }}
                onChange={formik.handleChange}
                value={formik.values.chandoanbandau}
                name="chandoanbandau"
                size="small"
              />
            </div>
          </div>
          <div className="flex gap-1 mt-1">
            <label className="font-semibold w-44 text-end">Ghi chú:</label>
            <Input
              disabled={checkUpdate}
              style={{ color: 'black' }}
              onChange={formik.handleChange}
              value={formik.values.ghichu}
              name="ghichu"
              size="small"
            />
          </div>
          {isExpanded && (
            <>
              <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Quá trình bệnh lý:</label>
                <Input
                  disabled={checkUpdate}
                  style={{ color: 'black' }}
                  onChange={formik.handleChange}
                  value={formik.values.quatrinhbenhly}
                  name="quatrinhbenhly"
                  size="small"
                />
              </div>
              <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Tiền sử gia đình</label>
                <Input
                  disabled={checkUpdate}
                  style={{ color: 'black' }}
                  onChange={formik.handleChange}
                  value={formik.values.tieusugiadinh}
                  name="tieusugiadinh"
                  size="small"
                />
              </div>
              <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Toàn thân:</label>
                <Input
                  disabled={checkUpdate}
                  style={{ color: 'black' }}
                  onChange={formik.handleChange}
                  value={formik.values.toanthan}
                  name="toanthan"
                  size="small"
                />
              </div>
              <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Các bộ phận:</label>
                <Input
                  disabled={checkUpdate}
                  style={{ color: 'black' }}
                  onChange={formik.handleChange}
                  value={formik.values.cacbophan}
                  name="cacbophan"
                  size="small"
                />
              </div>

              <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Đã xử lý:</label>
                <Input
                  disabled={checkUpdate}
                  style={{ color: 'black' }}
                  onChange={formik.handleChange}
                  value={formik.values.daxuly}
                  name="daxuly"
                  size="small"
                />
              </div>
              <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">ICD-10 CĐ ra viện:</label>
                <Select
                  disabled={checkUpdate}
                  style={{ color: 'black' }}
                  showSearch
                  filterOption={false}
                  className="w-full"
                  size="small"
                  onChange={(value, options) =>
                    onClickChanDoan(value, options, 'chandoanravien', 'idicD10RAVIEN')
                  }
                  onSearch={debounceDropDown}
                  suffixIcon={<SearchOutlined className=" " />}
                  notFoundContent={null}
                  value={formik.values.idicD10RAVIEN}
                  options={ChanDoan?.map((items) => ({
                    label: items.mabenh + ' - ' + items.icD10TV,
                    value: items.idicD10,
                    mabenh: items.mabenh,
                    ...items,
                  }))}
                  optionRender={(options) => (
                    <ul className="flex">
                      <li className="w-1/6">{options.data.mabenh}</li>
                      <li className="w-5/6 border-l px-5">{options.data.label}</li>
                    </ul>
                  )}
                />
              </div>
              <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Chẩn đoán ra viện:</label>
                <Input
                  disabled={checkUpdate}
                  style={{ color: 'black' }}
                  onChange={formik.handleChange}
                  value={formik.values.chandoanravien}
                  name="chandoanravien"
                  size="small"
                />
              </div>
              <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Điều trị ngoại trú:</label>
                <Input
                  disabled={checkUpdate}
                  style={{ color: 'black' }}
                  onChange={formik.handleChange}
                  value={formik.values.dieutringoaitru}
                  name="dieutringoaitru"
                  size="small"
                />
              </div>
              <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Diễn biến lâm sàng:</label>
                <Input
                  disabled={checkUpdate}
                  style={{ color: 'black' }}
                  onChange={formik.handleChange}
                  value={formik.values.dienbienlamsang}
                  name="dienbienlamsang"
                  size="small"
                />
              </div>
              <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Phương pháp điều trị:</label>
                <Input
                  disabled={checkUpdate}
                  style={{ color: 'black' }}
                  onChange={formik.handleChange}
                  value={formik.values.ppdieutri}
                  name="ppdieutri"
                  size="small"
                />
              </div>
              <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Tình trạng lúc ra viện:</label>
                <Input
                  disabled={checkUpdate}
                  style={{ color: 'black' }}
                  onChange={formik.handleChange}
                  value={formik.values.ttlucravien}
                  name="ttlucravien"
                  size="small"
                />
              </div>
              <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Hướng xử lý:</label>
                <Input
                  disabled={checkUpdate}
                  style={{ color: 'black' }}
                  onChange={formik.handleChange}
                  value={formik.values.huongxuly}
                  name="huongxuly"
                  size="small"
                />
              </div>
            </>
          )}
          <div className="flex justify-center mt-1">
            <button
              type="button"
              onClick={toggleExpand}
              className="cursor-pointer flex justify-between text-blue-500 px-1 py-1 rounded-md  tracking-wider    duration-500  w-[100px]"
            >
              {isExpanded ? (
                <>
                  Thu gọn
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6 animate-bounce"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 15.75 7.5-7.5 7.5 7.5"
                    />
                  </svg>
                </>
              ) : (
                <>
                  Mở rộng
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6 animate-bounce"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </>
              )}
            </button>
          </div>
          <div className={`mb-2 ${checkBox && 'hidden'}`}>
            <Divider orientation="left" plain style={{ margin: 3 }}>
              <p className="font-semibold">Kê toa thuốc</p>
            </Divider>
            <ToaThuoc />
          </div>
          {/* {!checkBox && (            
          )} */}
        </div>
      </form>
    </div>
  )
}

export default FormCD

import React from 'react'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Card, Col, ConfigProvider, Row, Statistic } from 'antd'
import { useSelector } from 'react-redux'
const Static = ({ listPhieuThu }) => {
  // const { cardReceive } = useSelector(state => state.cartReducer)
  const tongThanhTien = listPhieuThu?.reduce((total, items) => total + items.tiensudung, 0) ?? 0
  const totalCard = listPhieuThu?.length ?? 0
  const tongTienGiam = listPhieuThu?.reduce((total, items) => total + items.tiengiamgia, 0) ?? 0
  const tongThanhToan = listPhieuThu?.reduce((total, items) => total + items.tienthanhtoan, 0) ?? 0
  return (
    <div className=" w-full">
      <ConfigProvider theme={{components:{
        Card:{
          paddingLG:5
        }
      }}}>
        <Row gutter={24} justify="between">
          <Col span={6}>
            <Card bordered={true}>
              <Statistic
                title={<h2 className="font-semibold text-lg">Tổng thành tiền</h2>}
                value={tongThanhTien}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
                prefix={<ArrowUpOutlined />}
                //   suffix="(This month)"
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={true}>
              <Statistic
                title={<h2 className="font-semibold text-lg">Tổng tiền giảm</h2>}
                value={tongTienGiam}
                precision={0}
                valueStyle={{ color: '#cf1322' }}
                prefix={<ArrowDownOutlined />}
                //   suffix="(This month)"
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={true}>
              <Statistic
                title={<h2 className="font-semibold text-lg">Tổng thanh toán</h2>}
                value={tongThanhToan}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
                prefix={<ArrowUpOutlined />}
                //   suffix="(This month)"
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={true}>
              <Statistic
                title={<h2 className="font-semibold text-lg">Tổng phiếu</h2>}
                value={totalCard}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
                prefix={<ArrowUpOutlined />}
              />
            </Card>
          </Col>
        </Row>
      </ConfigProvider>
    </div>
  )
}

export default Static

import { SearchOutlined } from '@ant-design/icons'
import CachedIcon from '@mui/icons-material/Cached'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { IconButton } from '@mui/material'
import { Button, ConfigProvider, DatePicker, Input, Select, Space, Table, TreeSelect } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import { phanQuyenService } from '../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../store/actions/phanQuyenAction'
import { https } from '../../../services/apiService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import ToastCus from '../../../utils/Toast'
import ExcelJS from 'exceljs'
import { calculateAgeForReport } from '../../../utils/calculateAge'
import 'moment/locale/vi'
moment.locale('vi')

const { SHOW_PARENT } = TreeSelect

const SoKhamThai = () => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const now = moment()
  const dateFormat = 'DD/MM/YYYY'
  const [search, setSearch] = useState('')
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [dateFrom, setDateFrom] = useState(now.format(dateFormat))
  const [dateTo, setDateTo] = useState(now.format(dateFormat))
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [listBN, setListBN] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [loadingExport, setLoadingExport] = useState(false)
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const [value, setValue] = useState(['all'])
  const [filteredData, setFilteredData] = useState([])
  const [listColumnFilter, setListColumnFilter] = useState([])

  useEffect(() => {
    dispatch(listAllCompanyAction())
    getAllCN_By_idct(company)
  }, [])

  useEffect(() => {
    if (PkDangNhap?.idChuyenKhoa === 2) {
      setValue([BranchLogin])
      getListBn('', dateFrom, dateTo, company, [BranchLogin], 1)
    } else {
      getListBn('', dateFrom, dateTo, company, ['all'], 1)
    }
  }, [PkDangNhap])

  const getAllCN_By_idct = async (value) => {
    try {
      const { data } = await https.get(
        `ChiNhanh/ChiNhanhByCongTyAndChuyenKhoa?idCT=${value}&idChuyenKhoa=2`,
      )
      setListChiNhanh(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCompany = (value) => {
    setCompany(value)
    setValue([])
    getAllCN_By_idct(value)
    setListBN([])
  }

  const exportToExcel = async (keyword, value, company, dateFrom, dateTo) => {
    try {
      setLoadingExport(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT020',
      )

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('SoKhamThai')

      // Group data by NGAYKHAM
      const groupedData = filteredData.reduce((groups, item) => {
        const key = new Date(item.ngaykham).toLocaleDateString('vi-VN') // dd/MM/yyyy
        if (!groups[key]) {
          groups[key] = []
        }
        groups[key].push(item)
        return groups
      }, {})

      // Add border
      worksheet.getCell('A1').border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      }

      worksheet.columns = [
        { width: 5, style: { wrapText: true } }, // STT
        { width: 30, style: { wrapText: true } }, // Họ và tên
        { width: 20, style: { wrapText: true } }, // Ngày khám
        { width: 20, style: { wrapText: true } }, // Tuổi
        { width: 10, style: { wrapText: true } }, // Số thẻ
        { width: 30, style: { wrapText: true } }, // Địa Chỉ
        { width: 20, style: { wrapText: true } }, // Nghề Nghiệp
        { width: 15, style: { wrapText: true } }, // Dân Tộc
        { width: 30, style: { wrapText: true } }, // Tiền sử
        { width: 15, style: { wrapText: true } }, // Ngày kinh cuối
        { width: 15, style: { wrapText: true } }, // Tuần thai
        { width: 15, style: { wrapText: true } }, // Dự kiến ngày sinh
        { width: 10, style: { wrapText: true } }, // Lần có thai thứ
        { width: 10, style: { wrapText: true } }, // Trọng lượng mẹ
        { width: 10, style: { wrapText: true } }, // Chiều cao mẹ
        { width: 10, style: { wrapText: true } }, // Huyết áp
        { width: 10, style: { wrapText: true } }, // Chiều cao TC
        { width: 10, style: { wrapText: true } }, // Vòng bụng
        { width: 10, style: { wrapText: true } }, // Khung chậu
        { width: 10, style: { wrapText: true } }, // Thiếu máu
        { width: 10, style: { wrapText: true } }, // Protein niệu
        { width: 10, style: { wrapText: true } }, // Xét nghiệm HIV
        { width: 10, style: { wrapText: true } }, // Xét nghiệm khác
        { width: 10, style: { wrapText: true } }, // Tiên lượng đẻ
        { width: 10, style: { wrapText: true } }, // Số mũi UV đã tiêm
        { width: 10, style: { wrapText: true } }, // Uống viên sắt/Folic
        { width: 10, style: { wrapText: true } }, // Tim thai
        { width: 10, style: { wrapText: true } }, // Ngôi thai
        { width: 25, style: { wrapText: true } }, // Người khám
        { width: 10, style: { wrapText: true } }, // Ghi chú
        { width: 20, style: { wrapText: true } }, // Chi nhánh
      ]

      let startingRow = 2

      Object.keys(groupedData).forEach((groupKey) => {
        const group = groupedData[groupKey]
        // Add table title
        worksheet.mergeCells(`A${startingRow}:AD${startingRow}`)
        worksheet.getCell(`A${startingRow}`).value = `NGÀY ${groupKey}`
        worksheet.getCell(`A${startingRow}`).font = { bold: true }
        worksheet.getCell(`A${startingRow}`).alignment = { horizontal: 'center' }

        worksheet.getRow(startingRow).eachCell((cell) => {
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
          }
        })
        startingRow++

        worksheet.mergeCells(`A${startingRow}:A${startingRow + 1}`) // STT
        worksheet.getCell(`A${startingRow}`).value = 'STT'

        worksheet.mergeCells(`B${startingRow}:B${startingRow + 1}`) // Họ và tên
        worksheet.getCell(`B${startingRow}`).value = 'Họ và tên'

        worksheet.mergeCells(`C${startingRow}:C${startingRow + 1}`) // Ngày khám thai
        worksheet.getCell(`C${startingRow}`).value = 'Ngày khám thai'

        worksheet.mergeCells(`D${startingRow}:D${startingRow + 1}`) // Tuổi
        worksheet.getCell(`D${startingRow}`).value = 'Tuổi'

        worksheet.mergeCells(`E${startingRow}:E${startingRow + 1}`) // Số thẻ BHYT
        worksheet.getCell(`E${startingRow}`).value = 'Số thẻ BHYT'

        worksheet.mergeCells(`F${startingRow}:F${startingRow + 1}`) // Địa chỉ
        worksheet.getCell(`F${startingRow}`).value = 'Địa chỉ'

        worksheet.mergeCells(`G${startingRow}:G${startingRow + 1}`) // Nghề nghiệp
        worksheet.getCell(`G${startingRow}`).value = 'Nghề nghiệp'

        worksheet.mergeCells(`H${startingRow}:H${startingRow + 1}`) // Dân tộc
        worksheet.getCell(`H${startingRow}`).value = 'Dân tộc'

        worksheet.mergeCells(`I${startingRow}:I${startingRow + 1}`) // Tiền sử sức khỏe và sinh đẻ
        worksheet.getCell(`I${startingRow}`).value = 'Tiền sử sức khỏe và sinh đẻ'

        worksheet.mergeCells(`J${startingRow}:J${startingRow + 1}`) // Ngày kinh cuối cùng
        worksheet.getCell(`J${startingRow}`).value = 'Ngày kinh cuối cùng'

        worksheet.mergeCells(`K${startingRow}:K${startingRow + 1}`) // Tuần thai
        worksheet.getCell(`K${startingRow}`).value = 'Tuần thai'

        worksheet.mergeCells(`L${startingRow}:L${startingRow + 1}`) // Dự kiến ngày sinh
        worksheet.getCell(`L${startingRow}`).value = 'Dự kiến ngày sinh'

        worksheet.mergeCells(`M${startingRow}:M${startingRow + 1}`) // Lần có thai thứ mấy
        worksheet.getCell(`M${startingRow}`).value = 'Lần có thai thứ mấy'

        worksheet.mergeCells(`N${startingRow}:W${startingRow}`) // Phần khám mẹ
        worksheet.getCell(`N${startingRow}`).value = 'Phần khám mẹ'
        worksheet.getCell(`N${startingRow + 1}`).value = 'Trọng lượng mẹ'
        worksheet.getCell(`O${startingRow + 1}`).value = 'Chiều cao mẹ'
        worksheet.getCell(`P${startingRow + 1}`).value = 'Huyết áp'
        worksheet.getCell(`Q${startingRow + 1}`).value = 'Chiều cao TC'
        worksheet.getCell(`R${startingRow + 1}`).value = 'Vòng bụng'
        worksheet.getCell(`S${startingRow + 1}`).value = 'Khung chậu'
        worksheet.getCell(`T${startingRow + 1}`).value = 'Thiếu máu'
        worksheet.getCell(`U${startingRow + 1}`).value = 'Protein Niệu'
        worksheet.getCell(`V${startingRow + 1}`).value = 'Xét nghiệm HIV'
        worksheet.getCell(`W${startingRow + 1}`).value = 'Xét nghiệm khác'

        worksheet.mergeCells(`X${startingRow}:X${startingRow + 1}`) // Tiên lượng đẻ
        worksheet.getCell(`X${startingRow}`).value = 'Tiên lượng đẻ'

        worksheet.mergeCells(`Y${startingRow}:Y${startingRow + 1}`) // Số mũi UV đã tiêm
        worksheet.getCell(`Y${startingRow}`).value = 'Số mũi UV đã tiêm'

        worksheet.mergeCells(`Z${startingRow}:Z${startingRow + 1}`) // Uống viên sắt/Folic
        worksheet.getCell(`Z${startingRow}`).value = 'Uống viên sắt/Folic'

        worksheet.mergeCells(`AA${startingRow}:AB${startingRow}`) // Phần khám thai
        worksheet.getCell(`AA${startingRow}`).value = 'Phần khám thai'
        worksheet.getCell(`AA${startingRow + 1}`).value = 'Tim thai'
        worksheet.getCell(`AB${startingRow + 1}`).value = 'Ngôi thai'

        worksheet.mergeCells(`AC${startingRow}:AC${startingRow + 1}`) // Người khám
        worksheet.getCell(`AC${startingRow}`).value = 'Người khám'

        worksheet.mergeCells(`AD${startingRow}:AD${startingRow + 1}`) // Ghi chú
        worksheet.getCell(`AD${startingRow}`).value = 'Ghi chú'

        worksheet.getRow(startingRow).font = { bold: true }
        worksheet.getRow(startingRow + 1).font = { bold: true }

        // header row
        worksheet.getRow(startingRow).eachCell((cell) => {
          cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true }
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
          }
        })
        //subheader
        worksheet.getRow(startingRow + 1).eachCell((cell) => {
          cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true }
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
          }
        })

        startingRow += 2

        let row = startingRow
        let stt = 1

        group.forEach((item) => {
          // Construct the diachi similar to the C# logic
          const diachi =
            `${item.diachi ?? ''}` +
            `${item.tenPhuongXa ? ', ' + item.tenPhuongXa : ''}` +
            `${item.tenQuanHuyen ? ', ' + item.tenQuanHuyen : ''}` +
            `${item.tenTinhTP ? ', ' + item.tenTinhTP : ''}`

          // Write data into cells
          worksheet.getCell(`A${row}`).value = stt++ // STT
          worksheet.getCell(`B${row}`).value = item.tenbenhnhan ?? '' // Tên bệnh nhân
          worksheet.getCell(`C${row}`).value = item.ngaykham
            ? moment(item.ngaykham).format('DD/MM/YYYY')
            : '' // Ngày khám thai
          worksheet.getCell(`D${row}`).value = item.ngaysinh
            ? calculateAgeForReport(item.ngaysinh)
            : '' // Tuổi
          worksheet.getCell(`E${row}`).value = '' // Số thẻ BHYT (if needed)
          worksheet.getCell(`F${row}`).value = diachi // Địa chỉ
          worksheet.getCell(`G${row}`).value = item.tenNgheNghiep ?? '' // Nghề Nghiệp
          worksheet.getCell(`H${row}`).value = item.tenDanToc ?? '' // Dân Tộc
          worksheet.getCell(`I${row}`).value = item.benhsu ?? '' // Tiền sử sức khỏe
          worksheet.getCell(`J${row}`).value = item.kinhchot ?? '' // Ngày kinh cuối cùng
          worksheet.getCell(`K${row}`).value = item.tuanthai ?? '' // Tuần thai
          worksheet.getCell(`L${row}`).value = item.dusanh ?? '' // Dự kiến ngày sinh
          worksheet.getCell(`M${row}`).value = '' // Lần có thai thứ mấy (if needed)
          worksheet.getCell(`N${row}`).value = item.cannang ?? '' // Trọng lượng mẹ
          worksheet.getCell(`O${row}`).value = item.chieucao ?? '' // Chiều cao mẹ
          worksheet.getCell(`P${row}`).value = item.huyetap ?? '' // Huyết áp
          worksheet.getCell(`Q${row}`).value = '' // Chiều cao TC (if needed)
          worksheet.getCell(`R${row}`).value = '' // Vòng bụng (if needed)
          worksheet.getCell(`S${row}`).value = item.thongTinBenhNhanSan?.kchau ?? '' // Khung chậu
          worksheet.getCell(`T${row}`).value = '' // Thiếu máu (if needed)
          worksheet.getCell(`U${row}`).value = '' // Protein Niệu (if needed)
          worksheet.getCell(`V${row}`).value = '' // Xét nghiệm HIV (if needed)
          worksheet.getCell(`W${row}`).value = '' // Xét nghiệm khác (if needed)
          worksheet.getCell(`X${row}`).value = '' // Tiên lượng đẻ (if needed)
          worksheet.getCell(`Y${row}`).value = '' // Số mũi UV đã tiêm (if needed)
          worksheet.getCell(`Z${row}`).value = '' // Uống viên sắt/Folic (if needed)
          worksheet.getCell(`AA${row}`).value = item.thongTinBenhNhanSan?.timthai ?? '' // Tim thai
          worksheet.getCell(`AB${row}`).value = item.thongTinBenhNhanSan?.ngoithai ?? '' // Ngôi thai
          worksheet.getCell(`AC${row}`).value = item.bskham ?? '' // Người khám
          worksheet.getCell(`AD${row}`).value = item.ghichu ?? '' // Ghi chú

          // Apply styling to the row (alignment, borders, wrap text)
          worksheet.getRow(row).eachCell((cell) => {
            cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true }
            cell.border = {
              top: { style: 'thin' },
              bottom: { style: 'thin' },
              left: { style: 'thin' },
              right: { style: 'thin' },
            }
          })

          // Move to the next row for the next item
          row++
        })

        startingRow = row
      })

      // Merge the first row and center the text
      worksheet.getCell('A1').value = 'SỔ KHÁM THAI'
      worksheet.mergeCells('A1:AD1')
      worksheet.getCell('A1').alignment = { horizontal: 'center' }
      worksheet.getCell('A1').font = { bold: true }

      //download by blob
      // Generate Excel file
      const buffer = await workbook.xlsx.writeBuffer()
      // Create a Blob from the buffer
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      const formatDateFrom = moment(dateFrom, 'DD-MM-YYYY').format('DD-MM-YYYY')
      const formatDateTo = moment(dateTo, 'DD-MM-YYYY').format('DD-MM-YYYY')

      link.download = `Sổ khám thai từ ${formatDateFrom} đến ${formatDateTo}.xlsx`
      link.click()
      setLoadingExport(false)
      // Clean up
      URL.revokeObjectURL(link.href)
    } catch (error) {
      setLoadingExport(false)
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  const handleDatePicker = (name) => (Date, dateString) => {
    // format date
    const value = moment(dateString, dateFormat)
    name === 'from' ? setDateFrom(value) : setDateTo(value)
  }

  const getListBn = async (keyword = '', dateFrom, dateTo, company, value, pageNumber = 1) => {
    setLoading(true)
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT019',
      )

      let idChiNhanhs = null
      const dateFromFormat = moment(dateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD')
      const dateToFormat = moment(dateTo, 'DD/MM/YYYY').format('YYYY-MM-DD')
      if (value?.length > 0 && value[0] !== 'all') {
        // Giả sử `value` là mảng các ID. Chuyển đổi mảng thành chuỗi truy vấn
        idChiNhanhs = value
          .map((id, idx) => {
            if (idx === 0) {
              return `?idChiNhanh=${id}`
            }
            return `&idChiNhanh=${id}`
          })
          .join('&')
        const result = await https.get(
          `BenhNhan/GetSoKhamThaiByCondition${idChiNhanhs}&idCongTy=${company}&fromDate=${dateFromFormat}&toDate=${dateToFormat}${keyword ? `&keyword=${keyword}` : ''}&pageNumber=${pageNumber}`,
        )
        setListBN(result.data)
      } else {
        const result = await https.get(
          `BenhNhan/GetSoKhamThaiByCondition?idCongTy=${company}&fromDate=${dateFromFormat}&toDate=${dateToFormat}${keyword ? `&keyword=${keyword}` : ''}&pageNumber=${pageNumber}`,
        )
        setListBN(result.data)
      }
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCLickSearch = () => {
    if (value.length > 0) {
      setListBN([])
      getListBn(search, dateFrom, dateTo, company, value)
    } else {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn chi nhánh',
      })
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndices) => {
    if (!dataIndices) return //khong search ''
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndices.join(','))
    const idx = listColumnFilter.findIndex((item) => item === dataIndices[0])
    if (idx === -1) {
      setListColumnFilter((prev) => [...prev, { [dataIndices[0]]: selectedKeys[0] }])
    }
  }

  const handleReset = (clearFilters, confirm, dataIndices) => {
    clearFilters()
    setSearchText('')
    setSearchedColumn('')
    confirm()
    setListColumnFilter((prev) => prev.filter((item) => !item.hasOwnProperty(dataIndices[0])))
  }

  const getColumnSearchProps = (...dataIndices) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndices)}
          style={{
            marginBottom: 8,
            display: 'block',
            backgroundColor: '#1890ff',
            color: '#fff',
            borderColor: '#1890ff',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndices)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndices)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const combinedValue = dataIndices
        .map((index) => (record[index] ? record[index].toString() : ''))
        .join(' - ')
        .toLowerCase()
      return combinedValue.includes(value.toLowerCase())
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text, record) => {
      const combinedText = dataIndices
        .map((index) => (record[index] ? ` - ${record[index]}` : ''))
        .join('')
        .substring(3) // Remove the leading " - "

      if (searchedColumn === dataIndices.join(',')) {
        return (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={combinedText}
          />
        )
      }
      return combinedText
    },
  })

  const columnsInfoBN = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      key: 'stt',
      width: 80,
      fixed: 'left',
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenChiNhanh',
      key: 'tenChiNhanh',
      width: 180,
      fixed: 'left',
      ...getColumnSearchProps('tenChiNhanh'),
    },
    {
      title: 'Họ và tên',
      dataIndex: 'tenbenhnhan',
      key: 'tenbenhnhan',
      width: 250,
      ...getColumnSearchProps('tenbenhnhan'),
      fixed: 'left',
    },
    {
      title: 'Ngày khám thai',
      dataIndex: 'ngaykham',
      key: 'ngaykham',
      align: 'center',
      width: 150,
      render: (text) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Tuổi',
      dataIndex: 'ngaysinh',
      key: 'ngaysinh',
      width: 150,
      render: (text, record) => <p>{calculateAgeForReport(record.ngaysinh)}</p>,
    },
    {
      title: 'Số thẻ BHYT',
      dataIndex: 'thebhyt',
      key: 'thebhyt',
      align: 'center',
      width: 100,
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'diachi',
      key: 'diachi',
      width: 400,
      ...getColumnSearchProps('diachi', 'tenPhuongXa', 'tenQuanHuyen', 'tenTinhTP'),
    },
    {
      title: 'Nghề nghiệp',
      dataIndex: 'tenNgheNghiep',
      key: 'tenNgheNghiep',
      width: 200,
    },
    {
      title: 'Dân tộc',
      dataIndex: 'tenDanToc',
      key: 'tenDanToc',
      width: 100,
      align: 'center',
    },
    {
      title: 'Tiền sử sức khỏe và sinh đẻ',
      dataIndex: 'benhsu',
      key: 'benhsu',
      width: 250,
    },
    {
      title: 'Ngày kinh cuối cùng',
      dataIndex: 'kinhchot',
      key: 'kinhchot',
      width: 150,
    },
    {
      title: 'Tuần thai',
      dataIndex: 'tuanthai',
      width: 100,
      key: 'tuanthai',
    },
    {
      title: 'Dự kiến ngày sinh',
      dataIndex: 'dusanh',
      key: 'dusanh',
      width: 150,
    },
    {
      title: 'Lần có thai thứ mấy',
      dataIndex: 'lanthai',
      key: 'lanthai',
      width: 80,
    },
    {
      title: 'Phần khám mẹ',
      dataIndex: 'lanthai',
      key: 'lanthai',
      children: [
        {
          key: 'cannang',
          title: 'Trọng lượng mẹ',
          dataIndex: 'cannang',
          width: 80,
          render: (text, record) => (record.cannang ? `${record.cannang} kg` : ''),
        },
        {
          key: 'chieucao',
          title: 'Chiều cao mẹ',
          dataIndex: 'chieucao',
          width: 80,
          render: (text, record) => (record.cannang ? `${record.cannang} cm` : ''),
        },
        {
          key: 'huyetap',
          title: 'Huyết áp',
          dataIndex: 'huyetap',
          width: 80,
          render: (text, record) => (record.huyetap ? `${record.huyetap} mmHg` : ''),
        },
        {
          key: 'chieucaotc',
          title: 'Chiều cao TC',
          dataIndex: 'chieucaotc',
          width: 80,
        },
        {
          key: 'vongbung',
          title: 'Vòng bụng',
          dataIndex: 'vongbung',
          width: 80,
        },
        {
          key: 'kchau',
          title: 'Khung chậu',
          dataIndex: 'kchau',
          width: 80,
          render: (text, record) => record.thongTinBenhNhanSan?.kchau ?? '',
        },
        {
          key: 'thieumau',
          title: 'Thiếu máu',
          dataIndex: 'thieumau',
          width: 80,
        },
        {
          key: 'proteinNieu',
          title: 'Protein Niệu',
          dataIndex: 'proteinNieu',
          width: 80,
        },
        {
          key: 'xetnghiemhiv',
          title: 'Xét nghiệm HIV',
          dataIndex: 'xetnghiemhiv',
          width: 80,
        },
        {
          key: 'xetnghiemkhac',
          title: 'Xét nghiệm Khác',
          dataIndex: 'xetnghiemkhac',
          width: 80,
        },
      ],
    },
    {
      title: 'Tiên lượng đẻ',
      dataIndex: 'tienluongde',
      key: 'tienluongde',
      width: 250,
    },
    {
      title: 'Số mũi UV đã tiêm',
      dataIndex: 'somui',
      key: 'somui',
      width: 150,
    },
    {
      title: 'Uống viên sắt/Folic',
      dataIndex: 'folic',
      key: 'folic',
      width: 150,
    },
    {
      title: 'Phần khám thai',
      dataIndex: 'lanthai',
      key: 'lanthai',
      children: [
        {
          key: 'timthai',
          title: 'Tim thai',
          dataIndex: 'timthai',
          width: 100,
          render: (text, record) => record.thongTinBenhNhanSan?.timthai ?? '',
        },
        {
          key: 'ngoithai',
          title: 'Ngôi thai',
          dataIndex: 'ngoithai',
          width: 100,
          render: (text, record) => record.thongTinBenhNhanSan?.ngoithai ?? '',
        },
      ],
    },
    {
      title: 'Người khám thai',
      dataIndex: 'bskham',
      key: 'bskham',
      width: 300,
      ...getColumnSearchProps('bskham'),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 300,
    },
  ]

  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      key: '0-1',
      children: listChiNhanh?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
        key: `0-1-${index}`,
      })),
    },
  ]

  const onChange = (newValue) => {
    setValue(newValue)
  }

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  useEffect(() => {
    //map object value in list filter array
    const newFilteredData = listBN
      .filter((item) => {
        if (listColumnFilter.length === 0) return true
        return listColumnFilter.every((filterObj) => {
          const [key, value] = Object.entries(filterObj)[0]
          if (key === 'diachi') {
            //join address
            const joinDiaChi = `${item.diachi ?? ''}${item.tenPhuongXa ? ', ' + item.tenPhuongXa : ''}${item.tenQuanHuyen ? ', ' + item.tenQuanHuyen : ''}${item.tenTinhTP ? ', ' + item.tenTinhTP : ''}`
            return joinDiaChi?.toLowerCase().includes(value.toLowerCase())
          }
          if (key === 'ppDieuTri') {
            //join thuoc
            const joinThuoc = item?.danhSachThuocDTOs
              ?.map((val) => `${val.tenbietduoc} (${val.soluong} ${val.dvt})`)
              .join(', ')
            return joinThuoc?.toLowerCase().includes(value.toLowerCase())
          }
          return item[key]?.toString().toLowerCase().includes(value.toLowerCase())
        })
      })
      .map((item, index) => ({
        stt: index + 1,
        ...item,
        maPhieuThu: `PT${item.maBenhNhan}`,
        maToaThuoc: `MTT${item.maBenhNhan}`,
        ppDieuTri: item?.danhSachThuocDTOs
          ?.map((val) => `${val.tenbietduoc} (${val.soluong} ${val.dvt})`)
          .join(', '),
      }))
    setFilteredData(newFilteredData)
  }, [listBN, listColumnFilter])

  return (
    <>
      <div className="h-full bg-[#EFEFEF] p-5">
        <div className="h-full bg-white border rounded-xl">
          <div className="w-full border-b flex items-center justify-between">
            <div className="p-2 flex gap-5 items-center">
              <Select
                showSearch
                className="w-72"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={handleCompany}
                value={company}
                options={listCompany?.map((items) => ({
                  label: items.tenct,
                  value: items.idct,
                }))}
              />
              <div className="w-80">
                <TreeSelect
                  {...tProps}
                  style={{ flexWrap: 'unset' }}
                  className="w-full h-8 overflow-clip"
                />
              </div>
              <ul className="flex gap-1 items-center">
                <li>
                  {/* Từ:{' '} */}
                  <DatePicker
                    defaultValue={dayjs(dateFrom, dateFormat)}
                    maxDate={dayjs(now)}
                    allowClear={false}
                    onChange={handleDatePicker('from')}
                    format={dateFormat}
                  />
                </li>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                  </svg>
                </span>
                <li>
                  {/* Đến:{' '} */}
                  <DatePicker
                    defaultValue={dayjs(dateTo, dateFormat)}
                    onChange={handleDatePicker('to')}
                    maxDate={dayjs(now)}
                    allowClear={false}
                    format={dateFormat}
                  />
                </li>
              </ul>
              <IconButton
                onClick={handleCLickSearch}
                color="primary"
                aria-label="add to shopping cart"
              >
                <CachedIcon />
              </IconButton>
              <Button
                onClick={() => exportToExcel(search, value, company, dateFrom, dateTo)}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
                loading={loadingExport}
                disabled={loadingExport}
              >
                Xuất Excel
              </Button>
            </div>
          </div>
          <div className="mt-2 p-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                  borderRadius: 0,
                },
                components: {
                  Table: {
                    cellFontSize: 12,
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                className="table-skt"
                loading={loading}
                scroll={{ x: 3500, y: 670 }}
                bordered
                pagination={false}
                columns={columnsInfoBN}
                dataSource={filteredData}
                footer={() => (
                  <div style={{ textAlign: 'left', fontWeight: 'bold' }}>
                    Tổng: {filteredData.length}
                  </div>
                )}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </>
  )
}

export default SoKhamThai

import {
  ConfigProvider,
  DatePicker,
  Input,
  InputNumber,
  Table,
  Typography,
  notification,
} from 'antd'
import dayjs from 'dayjs'
import { formatNumber } from 'devextreme/localization'
import moment from 'moment'
import 'moment/locale/vi'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateKhoChiTietAction } from '../../../../store/actions/NhapKhoAction'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
moment.locale('vi')
const { Text } = Typography
const TableDetail = ({ editingKey, setEditingKey, editTable, setEditTable }) => {
  const { ListThuocVT_update, infoPTNhap } = useSelector((state) => state.NhapKhoReducer)
  const [api, contextHolder] = notification.useNotification()
  const { infoUser } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const [data, setData] = useState(ListThuocVT_update)

  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    })
  }
  const cancel = () => {
    setEditingKey('')
    setEditTable(false)
    setData(ListThuocVT_update)
  }
  const save = async (key, idNhapXuat) => {
    const index = data.findIndex((item) => key === item.idChiTietNX)
    if (index !== -1) {
      const item = data[index]
      if (!item.soLo) {
        openNotificationWithIcon('warning', 'Lưu thuốc vật tư', 'Vui lòng nhập số lô')
        return
      }

      dispatch(updateKhoChiTietAction(data[index], idNhapXuat, cancel))
    } else {
      openNotificationWithIcon('error', 'Lưu thuốc vật tư', 'Lưu thất bại')
    }
    dispatch(updateKhoChiTietAction(data[index], idNhapXuat, cancel))
  }
  const convertToNumber = (str) => {
    return Number(str?.replace(/,/g, ''))
  }
  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day')
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      width: 40,
      render: (text, recoder, index) => ++index,
      align: 'center',
      fixed: 'left',
    },
    {
      title: 'Mã hàng',
      dataIndex: 'maThuoc',
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Tên hàng',
      dataIndex: 'tenBietDuoc',
      width: 250,
      fixed: 'left',
    },
    {
      title: 'SL chẵn',
      dataIndex: 'soLuong',
      width: 80,
      // editable: true,
      render: (text, record, index) => (
        <>
          <InputNumber
            variant="borderless"
            readOnly
            value={text}
            formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            size="small"
            className="w-16"
          />
        </>
      ),
    },
    {
      title: 'Giá chẵn',
      dataIndex: 'giaChan',
      //   width: '40%',
      width: 100,
    },
    {
      title: 'Quy cách',
      dataIndex: 'quyCach',
      //   width: '40%',
      width: 120,
    },
    {
      title: 'SL lẻ',
      dataIndex: 'countLe',
      width: 80,
      render: (text, record, index) => (
        <>
          <Input type="Number" size="small" value={text} variant="borderless" readOnly />
        </>
      ),
    },
    {
      title: 'Giá lẻ',
      dataIndex: 'giaLe',
      width: 80,
    },
    {
      title: 'Tổng lẻ',
      dataIndex: 'soLuongLe',
      width: 60,
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'tongTien',
      width: 80,
    },
    {
      title: 'P. gia công',
      dataIndex: 'pGiaCong',
      inputType: 'text',
      render: (text, record, index) => (
        <>
          <InputNumber
            variant="borderless"
            readOnly
            value={text}
            formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            size="small"
          />
        </>
      ),
      width: 100,
    },
    {
      title: 'P. Vận chuyển',
      dataIndex: 'pVanChuyen',
      inputType: 'text',
      render: (text, record, index) => (
        <>
          <InputNumber
            variant="borderless"
            readOnly
            value={text}
            formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            size="small"
          />
        </>
      ),
      width: 100,
    },
    {
      title: '% CK trước VAT',
      dataIndex: 'ptckTruocVat',
      inputType: 'text',
      render: (text, record, index) => (
        <>
          <Input type="Number" size="small" value={text} variant="borderless" readOnly />
        </>
      ),
      width: 100,
    },
    {
      title: 'Tiền CK trước VAT',
      dataIndex: 'ckTruocVat',
      inputType: 'text',
      width: 130,
    },
    {
      title: 'Thành Tiền',
      dataIndex: 'tongTienTruocVAT',
      inputType: 'Number',
      render: (text, record, index) => (
        <>
          <InputNumber
            variant="borderless"
            readOnly
            value={text}
            formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            size="small"
          />
        </>
      ),
      width: 100,
    },
    {
      title: '% VAT',
      dataIndex: 'ptvat',
      //   width: '40%',
      align: 'center',
      render: (text, record, index) => {
        ;<>{text}%</>
      },
      width: 70,
    },
    {
      title: 'Tiền VAT',
      dataIndex: 'tienVAT',
      render: (text, record) => (
        <InputNumber
          variant="borderless"
          readOnly
          value={text}
          formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
          size="small"
        />
      ),
      width: 120,
    },
    {
      title: 'Thành tiền',
      dataIndex: 'thanhTien',
      //   width: '40%',
      width: 120,
    },
    {
      title: 'Thực trả',
      dataIndex: 'thucTra',
      inputType: 'Number',
      render: (text, record, index) => (
        <>
          <InputNumber
            variant="borderless"
            readOnly
            value={text}
            formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            size="small"
          />
        </>
      ),
      width: 120,
    },
    {
      title: 'Nhiệt độ',
      dataIndex: 'nhietDo',
      inputType: 'text',
      width: 120,
      render: (text, record, index) => (
        <>
          <Input size="small" value={text} variant="borderless" readOnly />
        </>
      ),
    },
    {
      title: 'MSDK',
      dataIndex: 'maSoDangKy',
      inputType: 'text',
      width: 180,
      render: (text, record, index) => (
        <>
          <Input size="small" value={text} variant="borderless" readOnly />
        </>
      ),
    },
    {
      title: 'Số lô',
      dataIndex: 'soLo',
      inputType: 'text',
      width: 120,
      render: (text, record, index) => (
        <>
          <Input size="small" value={text} variant="borderless" readOnly />
        </>
      ),
    },
    {
      title: 'Hạn dùng',
      dataIndex: 'hanDung',
      inputType: 'text',
      width: 120,
      render: (text, record, index) => {
        ;<>{text}</>
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghiChu',
      inputType: 'text',
      width: 300,
      render: (text, record, index) => (
        <>
          <Input size="small" value={text} variant="borderless" readOnly />
        </>
      ),
    },
  ]

  const tongTien = (editTable ? data : ListThuocVT_update)?.reduce((total, items) => {
    if (editTable) {
      return total + (items.soLuongLe + items.countLe) * items.donGiaMua
    } else {
      return total + items.soLuongLe * items.donGiaMua
    }
  }, 0)

  const tienCK = (editTable ? data : ListThuocVT_update)?.reduce(
    (total, items) => total + items.ckTruocVat,
    0,
  )
  const vat5 = (editTable ? data : ListThuocVT_update)?.reduce(
    (total, items) => total + items.vat5,
    0,
  )
  const vat8 = (editTable ? data : ListThuocVT_update)?.reduce(
    (total, items) => total + items.vat8,
    0,
  )
  const vat10 = (editTable ? data : ListThuocVT_update)?.reduce(
    (total, items) => total + items.vat10,
    0,
  )
  const thucTra = (editTable ? data : ListThuocVT_update)?.reduce(
    (total, items) => total + items.thucTra,
    0,
  )
  // data
  const dataSource = (editTable ? data : ListThuocVT_update)?.map((items) => ({
    key: items.idChiTietNX,
    maThuoc: items.thuocVatTu?.maThuoc,
    tenBietDuoc: items.thuocVatTu?.tenBietDuoc,
    soLuong: Math.floor(items.soLuongLe / items.soLuongDongGoi),
    giaChan: formatNumber(items.soLuongDongGoi * items.donGiaMua),
    quyCach: items.quyCachDongGoi,
    countLe: items.countLe,
    giaLe: formatNumber(items.donGiaMua),
    soLuongLe: editTable
      ? items.countLe + Math.floor(items.soLuongLe / items.soLuongDongGoi) * items.soLuongDongGoi
      : items.soLuongLe,
    tongTien: editTable
      ? formatNumber((items.soLuongLe + items.countLe) * items.donGiaMua)
      : formatNumber(items.soLuongLe * items.donGiaMua),
    pGiaCong: items.phiGiaCong,
    pVanChuyen: items.phiVanChuyen,
    ptckTruocVat: items.ptckTruocVat,
    ckTruocVat: formatNumber(Math.round(items.ckTruocVat)),
    tongTienTruocVAT: formatNumber(items.tongTienTruocVAT),
    tienVAT: formatNumber(items.vat5 + items.vat8 + items.vat10),
    thanhTien: formatNumber(items.thanhTien),
    thucTra: formatNumber(items.thucTra),
    nhietDo: items.nhietDo,
    maSoDangKy: items.maSoDangKy,
    soLo: items.soLo,
    hanDung: items.hanDung ? moment(items.hanDung).format('DD/MM/YYYY') : '-',
    ghiChu: items.ghiChu,
    ptvat: items.ptvat,
    idNhapXuat: items?.khoNhapXuat?.idNhapXuat,
    idThuocVatTu: items.thuocVatTu.idThuoc,
  }))
  useEffect(() => {
    setData(ListThuocVT_update)
  }, [ListThuocVT_update])

  return (
    <>
      {contextHolder}
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
            fontSize: 12,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          scroll={{
            x: 2200,
            y: 350,
          }}
          // components={{
          //   body: {
          //     cell: EditableCell,
          //   },
          // }}
          bordered
          dataSource={dataSource}
          columns={columns}
          rowClassName="editable-row"
          pagination={false}
          summary={(pageData) => {
            let soLuongchan = 0
            let priceChan = 0
            let soLuongle = 0
            let priceLe = 0
            let toTalLe = 0
            let totalPrice = 0
            let phiGiaCong = 0
            let phiVanChuyen = 0
            let tienTrCK = 0
            let tienTrVat = 0
            let priceVat = 0
            let ThanhTien = 0
            let ThucTra = 0
            pageData.forEach(
              ({
                soLuong,
                giaChan,
                countLe,
                giaLe,
                soLuongLe,
                tongTien,
                pGiaCong,
                pVanChuyen,
                ckTruocVat,
                tongTienTruocVAT,
                tienVAT,
                thanhTien,
                thucTra,
              }) => {
                soLuongchan += soLuong
                soLuongle += countLe
                priceChan += convertToNumber(giaChan)
                priceLe += convertToNumber(giaLe)
                toTalLe += soLuongLe
                totalPrice += convertToNumber(tongTien)
                phiGiaCong += pGiaCong
                phiVanChuyen += pVanChuyen
                tienTrCK += convertToNumber(ckTruocVat)
                tienTrVat += convertToNumber(tongTienTruocVAT)
                priceVat += convertToNumber(tienVAT)
                ThanhTien += convertToNumber(thanhTien)
                ThucTra += convertToNumber(thucTra)
              },
            )
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <Text type="danger">{soLuongchan}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <Text type="danger">{formatNumber(priceChan)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    {' '}
                    <Text type="danger">{formatNumberVND(soLuongle)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <Text type="danger">{formatNumber(priceLe)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9}>
                    <Text type="danger">{formatNumber(toTalLe)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10}>
                    <Text type="danger">{formatNumber(totalPrice)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={15}>
                    <Text type="danger">{formatNumber(phiGiaCong)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11}>
                    <Text type="danger">{formatNumber(phiVanChuyen)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={12}></Table.Summary.Cell>
                  <Table.Summary.Cell index={13}>
                    <Text type="danger">{formatNumber(tienTrCK)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={14}>
                    <Text type="danger">{formatNumber(tienTrVat)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={15}>
                    <Text type="danger"></Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={16}>
                    <Text type="danger">{formatNumber(Math.round(priceVat))}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={17}>
                    <Text type="danger">{formatNumber(ThanhTien)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={18}>
                    <Text type="danger">{formatNumber(Math.round(ThucTra))}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={19}></Table.Summary.Cell>
                  <Table.Summary.Cell index={20}></Table.Summary.Cell>
                  <Table.Summary.Cell index={21}></Table.Summary.Cell>
                  <Table.Summary.Cell index={22}></Table.Summary.Cell>
                  <Table.Summary.Cell index={23}></Table.Summary.Cell>
                  <Table.Summary.Cell index={24}></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )
          }}
          footer={() => (
            <div className="w-full  flex justify-end text-sm">
              <ul className="flex flex-col gap-2 mt-2 mr-5">
                <li className="flex w-60 justify-between border-b">
                  <label>Tổng tiền:</label>
                  <p>
                    {formatNumberVND(Math.round(tongTien))}
                    {/* {tongTien.toLocaleString('vi-VN')} */}
                    <span> VNĐ</span>
                  </p>
                </li>
                <li className="flex w-60 justify-between border-b">
                  <label>Tiền chiết khấu:</label>
                  <p>
                    <i className="text-red-500">-</i>
                    {formatNumberVND(Math.round(tienCK))}
                    <span> VNĐ</span>
                  </p>
                </li>
                <li className="flex w-60 justify-between border-b">
                  <label>VAT 5%:</label>
                  <p>
                    <i className="text-green-500">+</i>
                    {formatNumberVND(Math.round(vat5))}
                    <span> VNĐ</span>
                  </p>
                </li>
                <li className="flex w-60 justify-between border-b">
                  <label>VAT 8%:</label>
                  <p>
                    <i className="text-green-500">+</i>
                    {formatNumberVND(Math.round(vat8))}
                    <span> VNĐ</span>
                  </p>
                </li>
                <li className="flex w-60 justify-between border-b">
                  <label>VAT 10%:</label>
                  <p>
                    <i className="text-green-500">+</i>
                    {formatNumberVND(Math.round(vat10))}
                    <span> VNĐ</span>
                  </p>
                </li>
                <li className="flex w-60 justify-between ">
                  <label>Thực trả:</label>
                  <p>
                    {formatNumberVND(Math.round(thucTra))}
                    <span> VNĐ</span>
                  </p>
                </li>
              </ul>
            </div>
          )}
        />
      </ConfigProvider>
    </>
  )
}

export default TableDetail

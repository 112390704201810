import * as typeAction from '../constants/constants'
export const listALlCĐHA_Action = () => ({
  type: typeAction.GET_LIST_ALL_CĐHA,
})

export const add_CDHA_Action = (form, handleReloadData) => ({
  type: typeAction.ADD_CĐHA,
  form,
  handleReloadData,
})

export const edit_CDHA_Action = (form, handleReloadData) => ({
  type: typeAction.EDIT_CĐHA,
  form,
  handleReloadData,
})

export const deleteCĐHA_Action = (id, handleReloadData) => ({
  type: typeAction.DELETE_CĐHA,
  id,
  handleReloadData,
})

export const searchDanhMucCĐHA_Action = (keyword, idct, idnhomchha, idChuyenKhoa) => ({
  type: typeAction.SEARCH_CĐHA,
  keyword,
  idct,
  idnhomchha,
  idChuyenKhoa,
})

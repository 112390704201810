import React from 'react'
import { Tabs } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { getInfoBNTiemChungAction } from '../../../../../store/actions/tiemChungAction'
import 'moment/locale/vi'
moment.locale('vi')
const onChange = (key) => {
  console.log(key)
}
const History = () => {
  const dispatch = useDispatch()
  const { historyKham, ThuocBN, infoBN } = useSelector((state) => state.childrenClinicReducer)
  const { infoBNTiemChung, infoTheoDoiSauTiem } = useSelector((state) => state.tiemChungReducer)
  const { lichSuTiemChung } = useSelector((state) => state.khamSangLocReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const handleClickLichSuTC = async (idbnttc) => {
    dispatch(getInfoBNTiemChungAction(idbnttc, PkDangNhap?.idChiNhanh))
  }
  const items = [
    {
      key: '1',
      label: 'Tiêm chủng',
      children: <></>,
    },
    // {
    //   key: '2',
    //   label: 'Khám bệnh',
    //   children: (
    //     <>
    //       <ul className="flex flex-col gap-2 overflow-auto h-[500px]">
    //         {historyKham?.map((items) => (
    //           <li className="border p-1 rounded-md cursor-pointer hover:bg-slate-100">
    //             <h2 className="font-semibold text-base">Ngày khám: {items.ngaykham && moment(items.ngaykham).format('DD/MM/YYYY HH:mm:ss')}</h2>
    //             <p className="text-gray-500">Lý do: {items.lydokham}</p>
    //             <p className="text-gray-500">BS thực hiện: {items.bskham}</p>
    //           </li>
    //         ))}
    //       </ul>
    //     </>
    //   ),
    // },
    // {
    //   key: '3',
    //   label: 'Xét nghiệm',
    //   children: (
    //     <>
    //       <ul className="flex flex-col gap-2 overflow-auto h-[500px]">
    //         {/* <li className="border p-1 rounded-md cursor-pointer hover:bg-slate-100">
    //           <h2 className="font-semibold text-base">
    //             Ngày khám: 17/05/2024 15:30:45
    //           </h2>
    //           <p className="text-gray-500">Tại: Nguyễn Thị Định - nhi full</p>
    //           <p className="text-gray-500">BS Thực hiện:CK.NĐ1 Nguyễn Quốc Tài</p>
    //         </li> */}
    //       </ul>
    //     </>
    //   ),
    // },
    // {
    //   key: "3",
    //   label: "Chẩn đoán hình ảnh",
    //   children: (
    //     <>
    //       <ul className="flex flex-col gap-2 overflow-auto h-[500px]">
    //         {/* <li className="border p-1 rounded-md cursor-pointer hover:bg-slate-100">
    //           <h2 className="font-semibold text-base">
    //             Ngày khám: 17/05/2024 15:30:45
    //           </h2>
    //           <p className="text-gray-500">Tại: Nguyễn Thị Định - nhi full</p>
    //           <p className="text-gray-500">BS Thực hiện:CK.NĐ1 Nguyễn Quốc Tài</p>
    //         </li> */}
    //       </ul>
    //     </>
    //   ),
    // },
  ]
  return (
    <div className="p-2 ">
      <h2 className="text-lg font-semibold text-gray-500 mb-2">Lịch sử</h2>
      {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
      <ul className="flex flex-col gap-2 overflow-auto h-[640px] text-[0.75rem]">
        {lichSuTiemChung?.map(
          (item) =>
            item?.idthuoc &&
            infoBN && (
              <li
                onClick={() => {
                  handleClickLichSuTC(item?.idbnttc)
                }}
                className={
                  `border p-1 rounded-md cursor-pointer hover:bg-slate-100 text-gray-600  ` +
                  (infoBNTiemChung?.IDBNTTC === item?.idbnttc && ` bg-sky-100 `)
                }
              >
                <h2 className="font-semibold text-[0.8rem] text-gray-800">
                  Ngày tiêm: {item.ngayban && moment(item.ngayban).format('DD/MM/YYYY HH:mm:ss')}
                </h2>
                <p className="font-semibold ">
                  Vắc xin:{' '}
                  {ThuocBN?.findIndex((itemVX) => itemVX?.idthuoc === item.idthuoc) !== -1 ? (
                    <span className="text-red-500">{item.tenbietduoc}</span>
                  ) : (
                    <span>{item.tenbietduoc}</span>
                  )}
                </p>
                <div className="grid grid-cols-2">
                  <p>Mũi: {item.muitiem}</p>
                  <p>Liều dùng (ml): {item.lieudung}</p>
                </div>
                <p>Ghi chú: {item.ghichu}</p>
              </li>
            ),
        )}
      </ul>
    </div>
  )
}

export default History

import React, { useEffect, useState } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import Tablist from './TabList/Tablist'
// import General from "./General/General"
import General from './General/General'
import { useDispatch, useSelector } from 'react-redux'
import * as typeAction from '../../../store/constants/constants'
import { SuggestDoctor, thuocKeToa } from '../../../store/actions/childrenClinicAction'
import { listPKByChiNhanh_Action } from '../../../store/actions/receiveAction'
import { khamSangLocService } from '../../../services/khamSangLoc/khamSangLocService'
const KhamSangLoc = () => {
  const dispatch = useDispatch()
  const BRANCH_ID_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const [defaultValuePK, setDefaultValuePK] = useState(-1)
  //const { PkDangNhap } = useSelector((state) => state.branchReducer)
  // const [checkCK, setCheckCK] = useState(false)
  // const checkChuyenKhoaDangNhap = () => {
  //   if ((PkDangNhap?.idChuyenKhoa === 1 && location?.pathname === '/phongkham/khamnhi')
  //     || (PkDangNhap?.idChuyenKhoa === 3 && location?.pathname === '/phongkham/khamlao')
  //     || (PkDangNhap?.idChuyenKhoa === 4 && location?.pathname === '/phongkham/khammat')) {
  //     return true
  //   }
  //   return false
  // }
  // useEffect(() => {
  //   setCheckCK(PkDangNhap?.idChuyenKhoa === 3 && location?.pathname === '/phongkham/phcn' ? true : false)
  // }, [location?.pathname])
  const getListTonVacXin = async () => {
    try {
      const { data } = await khamSangLocService.getListTonVacXin(BRANCH_ID_LOGIN)
      dispatch({
        type: typeAction.DISPATCH_LIST_TON_VAC_XIN_KSL,
        payload: data
          ?.filter((items) => items?.tonKho >= 0)
          ?.map((items, index) => ({ ...items, ID: index })),
      })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    dispatch({
      type: typeAction.RESET_STORE_CLINIC,
    })
    dispatch(SuggestDoctor())
    dispatch(listPKByChiNhanh_Action(BRANCH_ID_LOGIN))
    getListTonVacXin()
  }, [])

  return (
    <>
      <div className="bg-[#EBEBEB]  w-full h-full">
        <div className="p-2 flex gap-2">
          <div
            className="w-1/5 bg-white h-[875px] border rounded-lg"
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <Tablist />
          </div>
          <div className="w-4/5">
            <General defaultValuePK={defaultValuePK} />
          </div>
        </div>
      </div>
    </>
  )
}

export default KhamSangLoc

import { call, put, takeLatest, select } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { authService } from '../../services/auth/authService'
import Swal from 'sweetalert2'
import { menuService } from '../../services/menu/menuService'
import { generateUUID } from '../../utils/generateUUID'
import { nguoidungService } from '../../services/nguoidung/nguoidungService'
import { phanQuyenService } from '../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../actions/phanQuyenAction'
import ToastCus from '../../utils/Toast'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Cookies from 'js-cookie'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* authSaga() {
  //login user
  yield takeLatest(
    typeAction.GET_LOGIN_API,
    function* login({ type, payload, IDCompany, navigate, action }) {
      try {
        const fp = yield FingerprintJS.load()
        const deviceId = yield localStorage.getItem('deviceId') || generateUUID()
        // console.log(ipmac);
        // payload.idDevice = deviceId
        const infoUser = yield call(() => authService.login(payload))
        const getExpirationDateForToday = () => {
          return moment.utc().endOf('day').toDate() // Lấy thời điểm 23:59:59 UTC của ngày hiện tại
        }
        const expires = getExpirationDateForToday()
        Cookies.set('access-token', infoUser.data.token, {
          expires,
          // secure: true,
          // sameSite: 'Strict',
        })
        yield put({
          type: typeAction.DISPATCH_INFO_LOGIN,
          payload: infoUser.data,
        })
        localStorage.setItem('USER_INFO', JSON.stringify(infoUser.data))
        localStorage.setItem('BRANH_LOGIN', JSON.stringify(payload.idChiNhanh))
        localStorage.setItem('id_company', JSON.stringify(IDCompany))
        action.resetForm()
        yield put({
          type: typeAction.CLOSE_LOADING,
        })
        navigate('/')
        Toast.fire({
          icon: 'success',
          title: 'Đăng nhập thành công!',
        })
      } catch (err) {
        yield put({
          type: typeAction.CLOSE_LOADING,
        })
        const { response } = err
        if (response.status === 401) {
          Toast.fire({
            icon: 'error',
            title: response.data.message,
          })
        } else {
          Toast.fire({
            icon: 'error',
            title: response.data.message,
          })
        }

        console.log(err)
      }
    },
  )
  //dispatch info user
  yield takeLatest(typeAction.UPDATE_INFO_USER_LOCASTORAGE, function* getInfoUser({ type, token }) {
    try {
      const res = yield call(() => authService.getInfoByToken(token))
      yield put({
        type: typeAction.DISPATCH_INFO_LOGIN,
        payload: res.data,
      })
      const result = yield call(() => menuService.getlistMenu(res.data.taiKhoan))
      yield put({
        type: typeAction.DISPATCH_MENU,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })

  //logout user
  yield takeLatest(typeAction.LOGOUT_USER, function* logOut({ navigate, id }) {
    try {
      yield localStorage.removeItem('USER_INFO')
      yield Cookies.remove('access-token')
      // yield localStorage.removeItem('BRANH_LOGIN')
      // yield localStorage.removeItem('id_company')
      // yield call(() => authService.logOut(id))
      yield navigate('/login')
      yield put({
        type: typeAction.CLOSE_MODAL_CHECK_IN,
      })
      yield put({
        type: typeAction.DISPATCH_LOGOUT_USER,
        payload: null,
      })
      yield put({
        type: typeAction.RESET_STORE,
      })
      Toast.fire({
        icon: 'success',
        title: 'Đăng xuất thành công!',
      })
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Đăng xuất thật bại!',
      })
      console.log(err)
    }
  })

  // lấy menu theo phân quyền người dùng
  yield takeLatest(typeAction.GET_LIST_MENU, function* listMenu({ type, payload }) {
    try {
      const result = yield call(() => menuService.getlistMenu(payload))
      yield put({
        type: typeAction.DISPATCH_MENU,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // THAY ĐỔI MẬT KHẨU NGƯỜI DÙNG
  yield takeLatest(
    typeAction.CHANGE_PASSWORD_USER,
    function* changePassword({ type, form, openNotificationWithIcon, handleLogOut }) {
      try {
        yield call(() => authService.changePassword(form))
        handleLogOut()
      } catch (error) {
        console.log(error)
        openNotificationWithIcon()
      }
    },
  )
  // ---------- lấy danh sách người dùng -----------//
  yield takeLatest(
    typeAction.GET_ACCOUNT_BY_PAGEINDEX,
    function* changePassword({ type, keyword, pageIndex }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => authService.getUsers(keyword, pageIndex))
        yield put({
          type: typeAction.DISPATCH_LIST_USER,
          payload: result.data,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      } catch (error) {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        console.log(error)
      }
    },
  )
  // ----------Thêm người dùng -----------//
  yield takeLatest(typeAction.ADD_NEW_USER, function* addUser({ type, form, restStore }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })

      const { infoUser } = yield select((state) => state.userReducer)
      const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
      yield call(() =>
        phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT006'),
      )

      yield call(() => nguoidungService.postThemNguoiDung(form))
      restStore()
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })

      Toast.fire({
        icon: 'success',
        title: 'Thêm người dùng thành công!',
      })
    } catch (error) {
      console.error('Error : ', error)
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  })
  // ----------update người dùng -----------//
  yield takeLatest(typeAction.UPDATE_USER, function* updateUser({ type, form, restStore }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      yield call(() => nguoidungService.updateUser(form))
      restStore()
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })

      Toast.fire({
        icon: 'success',
        title: 'cập nhật thành công!',
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'cập nhật thất bại!',
      })
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
      console.log(error)
    }
  })
  // ----------restpass người dùng -----------//
  yield takeLatest(
    typeAction.RESET_PASSWORD_USER,
    function* restPassUser({ type, username, nguoiSua, formLoad }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT010'),
        )

        yield call(() => authService.resetPassUser(username, nguoiSua))

        const result = yield call(() => authService.getUsers(formLoad.keyword, formLoad.page))
        yield put({
          type: typeAction.DISPATCH_LIST_USER,
          payload: result.data,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })

        Toast.fire({
          icon: 'success',
          title: 'Reset mật khẩu thành công!',
        })
      } catch (error) {
        console.error('Error : ', error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      }
    },
  )
  // Mở ca làm việc
  yield takeLatest(typeAction.POST_CLOCK_IN_WORK, function* CheckIn({ type, form }) {
    const { infoUser } = yield select((state) => state.userReducer)
    try {
      const result = yield call(() => authService.checkIn(form))
      const newData = { ...infoUser, dangNhap: result.data }
      localStorage.setItem('USER_INFO', JSON.stringify(newData))
      yield put({
        type: typeAction.DISPATCH_INFO_LOGIN,
        payload: newData,
      })
      yield put({
        type: typeAction.CLOSE_MODAL_CHECK_IN,
      })
      Toast.fire({
        icon: 'success',
        title: 'Mở ca thành công!',
      })
    } catch (error) {
      console.log(error)
      put({
        type: typeAction.CLOSE_MODAL_CHECK_IN,
      })
      Toast.fire({
        icon: 'error',
        title: 'Mở ca Thất bại!',
      })
    }
  })
}

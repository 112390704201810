import React, { useState, useEffect } from 'react'
import { Button as AntButton, Table, ConfigProvider, Tooltip, Modal, Select, Input } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import {
  FileSearchOutlined,
  DownloadOutlined,
  EyeOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons'
import { Button } from '@mui/material'
import CachedIcon from '@mui/icons-material/Cached'
import moment from 'moment'
import _ from 'lodash'
import {
  filterPT_pendingCK,
  listInfoThuocVTChyenKho_Action,
  updateStatusCK_action,
  updateStatusCTY_action,
} from '../../../../../store/actions/chuyenKhoAction'
import { branchService } from '../../../../../services/branch/branchService'
import { chuyenKhoService } from '../../../../../services/ChuyenKho/chuyenKhoService'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import * as XLSX from 'xlsx'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import 'moment/locale/vi'
moment.locale('vi')

const columns = [
  {
    title: 'STT',
    dataIndex: 'STT',
    key: 'STT',
    width: 40,
    align: 'center',
  },
  {
    title: 'Mã phiếu',
    dataIndex: 'MAPHIEU',
    key: 'MAPHIEU',
    width: 120,
  },
  {
    title: 'Tên phiếu',
    dataIndex: 'TENPHIEU',
    key: 'TENPHIEU',
    // width: 200,
  },
  {
    title: 'Người chuyển',
    dataIndex: 'NGUOICHUYEN',
    key: 'NGUOICHUYEN',
  },
  {
    title: 'Ngày chuyển',
    dataIndex: 'NGAYNHAN',
    key: 'NGAYNHAN',
    width: 120,
  },
  {
    title: 'Công ty chuyển',
    dataIndex: 'CTYCHUYEN',
    key: 'CTYCHUYEN',
  },
  {
    title: 'Chi nhánh chuyển',
    dataIndex: 'NOICHUYEN',
    key: 'NOICHUYEN',
  },
  {
    title: 'kho chuyển',
    dataIndex: 'KHOCHUYEN',
    key: 'KHOCHUYEN',
    // width: 150,
    align: 'center',
  },
  {
    title: 'Công ty nhận',
    dataIndex: 'CTYNHAN',
    key: 'CTYNHAN',
  },
  {
    title: 'Chi nhánh nhận',
    dataIndex: 'NOINHAN',
    key: 'NOINHAN',
  },
  {
    title: 'kho nhận',
    dataIndex: 'KHONHAN',
    key: 'KHONHAN',
    // width: 150,
    align: 'center',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    align: 'center',
  },
  {
    title: 'Hành động',
    dataIndex: 'ACTION',
    key: 'ACTION',
    width: 100,
    align: 'center',
  },
]
const { confirm } = Modal
const now = new Date()

const Pedding = ({ showModal, setStatusUpdate }) => {
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const BranchLogin = localStorage.getItem('BRANH_LOGIN')
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { PTPending } = useSelector((state) => state.chuyenKhoReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const id_company = Number(localStorage.getItem('id_company'))
  const { branch } = useSelector((state) => state.NhapKhoReducer) // tên chi nhánh mặc định
  const [searchKho, setSearchKho] = useState('')
  const [searchBranch, setSearchBranch] = useState('')
  const [searchMa, setSearchMa] = useState('')
  const [ListBranch, setListBranch] = useState([])
  const [data, setData] = useState([])
  const [ListKho, setListKho] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const statusChuyenKho = 2 // trạng thái đã rời kho
  const filter = {
    status: statusChuyenKho, //
    //  idChiNhanh: Number(BranchLogin),
    idCongTy: id_company,
  }
  // show confirm xuất kho
  const showConfirm = (items) => {
    confirm({
      title: 'Xác nhận chuyển kho ',
      width: 450,
      icon: <ExclamationCircleFilled />,
      content: (
        <p className="text-start">
          bạn có muốn xác nhận phiếu <span className="font-semibold">{items.maPhieu}</span> vào
          <span className="font-semibold"> {items.tenKhoNhap}</span>
        </p>
      ),
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk() {
        const fd = new FormData()
        fd.append('IdNhapXuat', items.idNhapXuat)
        fd.append('TenPhieu', items.tenPhieu)
        fd.append('NhanVienNhan', infoUser.idnv)
        fd.append('TrangThai', 3) // trạng 3 là trạng thái nhận kho
        fd.append('NgayNhan', moment(now).format('YYYY-MM-DDTHH:mm:ss'))
        dispatch(updateStatusCTY_action(fd, items.idNhapXuat, fetchData, 'pending'))
        handleFilter()
      },
      onCancel() {
        // console.log("Cancel");
      },
    })
  }

  const filteredData = data?.filter((items) => {
    const findBranch = searchBranch.toLowerCase()
    const findKho = searchKho.toLowerCase()
    const findMa = searchMa.toLowerCase()
    return (
      items?.tenCTXuat?.toLowerCase().includes(findBranch) &&
      items?.tenKhoXuat?.toLowerCase().includes(findKho) &&
      items?.maPhieu?.toLowerCase().includes(findMa)
    )
  })
  //------------fetch dữ liệu ------------//
  const fetchData = async () => {
    try {
      setIsLoading(true)
      const { data } = await chuyenKhoService.getPTAccessCTY(filter)
      const sortedData = _.orderBy(data, 'ngayXuat', 'desc')
      setData(sortedData)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  //----------- lấy danh sách chi nhánh theo công ty -------//
  const fetchBranch = async () => {
    try {
      const { data } = await branchService.getListBranch(ID_COMPANY)
      setListBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  //---------- lấy danh sách kho chi nhánh-------------//
  const fetchKho = async (id) => {
    try {
      const { data } = await chuyenKhoService.getlistKhoNhan(id)
      setListKho(data)
    } catch (error) {
      console.log(error)
    }
  }
  const handleSelected = (value, option) => {
    setSearchBranch(option.label === 'Tất cả' ? '' : option.label)
    if (value !== '') {
      fetchKho(value)
    } else {
      setSearchKho('')
      setListKho([])
    }
  }
  const handleShowModalDetail = (idNhapXuat) => {
    showModal()
    dispatch(listInfoThuocVTChyenKho_Action(idNhapXuat))
    setStatusUpdate(false) // tắt trạng thái cho phép update
  }

  // xử lí lọc dưc liệu
  const handleFilter = () => {
    //dispatch(filterPT_pendingCK(filter,setIsLoading))
    fetchData()
  }
  // useEffect(() => {
  //   const sortedData = _.orderBy(PTPending, 'ngayXuat', 'desc')
  //   setData(sortedData)
  // }, [PTPending])
  useEffect(() => {
    // dispatch(filterPT_pendingCK(filter,setIsLoading))
    fetchData()
    dispatch(listAllCompanyAction())
    fetchBranch()
  }, [])
  const selectedStaff = listCompany?.find((item) => item.idct === ID_COMPANY)

  const exportToExcel = () => {
    const headers = [
      'Mã phiếu',
      'Tên phiếu',
      'Người chuyển',
      'Ngày chuyển',
      'Công ty chuyển',
      'Chi nhánh chuyển',
      'Kho chuyển',
      'Công ty nhận',
      'Chi nhánh nhận',
      'Kho nhận',
    ]

    const formattedData = filteredData.map((item) => ({
      'Mã phiếu': item.maPhieu,
      'Tên phiếu': item.tenPhieu,
      'Người chuyển': item.tenNVXuat,
      'Ngày chuyển': moment(item.ngayXuat).format('DD/MM/YYYY HH:mm:ss'),
      'Công ty chuyển': item.tenCTXuat,
      'Chi nhánh chuyển': item.tenChiNhanhXuat,
      'Kho chuyển': item.tenKhoXuat,
      'Công ty nhận': item.tenCTNhan,
      'Chi nhánh nhận': item.tenChiNhanhNhan,
      'Kho nhận': item.tenKhoNhap,
    }))

    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, 'Phiếu chuyển kho công ty - Phiếu chờ xác nhận.xlsx')
  }

  return (
    <div className="h-full">
      <div className="flex gap-5">
        <div className="flex">
          <label className=" font-semibold w-1/4">Công ty nhận:</label>
          <Input value={selectedStaff?.tenct} readOnly size="small" className="w-72" />
        </div>
        <div>
          <label className="font-semibold">Công ty chuyển: </label>
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
              { label: 'Tất cả', value: '' },
              ...listCompany?.map((item) => ({
                label: item.tenct,
                value: item.idct,
              })),
            ]}
            value={searchBranch}
            onChange={handleSelected}
            size="small"
            className="w-60"
          />
        </div>
        {/* <div>
          <label className="font-semibold">Kho chuyển: </label>
          <Select
            size="small"
            options={[
              { label: 'Tất cả', value: '' },
              ...ListKho?.map((item) => ({
                label: item.tenKho,
                value: item.tenKho,
              })),
            ]}
            value={searchKho}
            onChange={(value) => setSearchKho(value)}
            className="w-52"
          />
        </div> */}
        <div>
          <label className="font-semibold">Mã phiếu: </label>
          <Input size="small" className="w-40" onChange={(e) => setSearchMa(e.target.value)} />
        </div>
        <div className="flex gap-3">
          <div
            className="cursor-pointer rounded-sm text-blue-500  hover:bg-slate-200"
            onClick={handleFilter}
          >
            <CachedIcon sx={{ fontSize: 25 }} />
          </div>
        </div>
        <div className="flex gap-3 ml-auto">
          <AntButton
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </AntButton>
        </div>
      </div>
      <div
        className="mt-2"
        // style={{
        //     boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        // }}
      >
        <ConfigProvider
          theme={{
            token: {
              padding: 4,
              fontSize: 12,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            loading={isLoading}
            bordered
            pagination={{
              pageSize: 20,
              showSizeChanger: false,
            }}
            dataSource={filteredData?.map((items, index) => ({
              STT: ++index,
              MAPHIEU: items.maPhieu,
              TENPHIEU: items.tenPhieu,
              NGUOICHUYEN: items.tenNVXuat,
              NGAYNHAN: moment(items.ngayXuat).format('DD/MM/YYYY HH:mm:ss'),
              NOICHUYEN: items.tenChiNhanhXuat,
              KHOCHUYEN: items.tenKhoXuat,
              NOINHAN: items.tenChiNhanhNhan,
              KHONHAN: items.tenKhoNhap,
              CTYNHAN: items.tenCTNhan,
              CTYCHUYEN: items.tenCTXuat,
              status: (
                <>
                  <Button
                    onClick={() => {
                      showConfirm(items)
                    }}
                    size="small"
                    variant="contained"
                    style={{
                      fontSize: 12,
                      padding: 2,
                    }}
                  >
                    Xác nhận
                  </Button>
                </>
              ),
              ACTION: (
                <Tooltip title="Xem chi tiết" color="#108ee9">
                  <EyeOutlined
                    onClick={() => handleShowModalDetail(items.idNhapXuat)}
                    className="text-xl text-[#108ee9]  cursor-pointer"
                  />
                </Tooltip>
              ),
            }))}
            columns={columns}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default Pedding

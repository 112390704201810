// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 3px 8px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 3px 7px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/Tool/Modal/styleTable.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".editable-cell {\n  position: relative;\n}\n\n.editable-cell-value-wrap {\n  padding: 3px 8px;\n  cursor: pointer;\n}\n\n.editable-row:hover .editable-cell-value-wrap {\n  padding: 3px 7px;\n  border: 1px solid #d9d9d9;\n  border-radius: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

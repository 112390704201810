import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, Input, Pagination, Table, Tabs, Modal, Select } from 'antd'
import TabTheoTuan from './TabTheoTuan'
import TabTuyChon from './TabTuyChon'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal

function DoanhThuThuoc() {
  const { infoUser } = useSelector((state) => state.userReducer)
  return (
    <div className="p-3 h-full bg-[#EFEFEF]">
      <div
        className="h-full p-2 bg-white border rounded-xl"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <Tabs
          defaultActiveKey={1}
          items={[
            {
              key: 1,
              value: 1,
              label: 'Theo tuần',
              children: (
                <>
                  <TabTheoTuan />
                </>
              ),
            },
            {
              key: 2,
              value: 2,
              label: 'Tùy chọn',
              children: (
                <>
                  <TabTuyChon />
                </>
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}

export default DoanhThuThuoc

import { Input, Select } from 'antd'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import 'moment/locale/vi'
moment.locale('vi')

const InfoBN = ({}) => {
  const today = moment()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoBNDVThuThuat } = useSelector((state) => state.thuThuatReducer)
  //   const handleSelected = (value) => {
  //     formik.setFieldValue('donviguimau', value)
  //   }
  return (
    <div className="p-2 border rounded-md">
      <div className="flex gap-2">
        <div className="w-1/2 flex flex-col gap-2">
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Tên BN: </label>
              <Input value={infoBNDVThuThuat?.TENBENHNHAN} variant="filled" readOnly />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">Mã BN: </label>
              <Input value={infoBNDVThuThuat?.MABENHNHAN} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Địa chỉ: </label>
              <Input
                value={
                  '' +
                  (infoBNDVThuThuat?.DIACHI?.length > 0 ? infoBNDVThuThuat?.DIACHI : '') +
                  (infoBNDVThuThuat?.TENPHUONG?.length > 0
                    ? ', ' + infoBNDVThuThuat?.TENPHUONG
                    : '') +
                  (infoBNDVThuThuat?.TENQUAN?.length > 0 ? ', ' + infoBNDVThuThuat?.TENQUAN : '') +
                  (infoBNDVThuThuat?.TENTINH?.length > 0 ? ', ' + infoBNDVThuThuat?.TENTINH : '')
                }
                variant="filled"
                readOnly
              />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">SĐT: </label>
              <Input value={infoBNDVThuThuat?.DIENTHOAI} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Thực hiện: </label>
              <Input
                value={
                  infoBNDVThuThuat
                    ? infoBNDVThuThuat?.DanhSachVatTuTieuHao?.length > 0
                      ? infoBNDVThuThuat?.DanhSachVatTuTieuHao[0]?.nguoiThucHien
                      : infoBNDVThuThuat?.DanhSachDichVu[0]?.trangthaikb === 4
                        ? infoBNDVThuThuat?.DanhSachDichVu[0]?.nguoithuchien
                        : infoUser?.tenNV
                    : ''
                }
                variant="filled"
                readOnly
              />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">Ngày: </label>
              <Input
                value={
                  infoBNDVThuThuat
                    ? infoBNDVThuThuat?.DanhSachVatTuTieuHao?.length > 0
                      ? infoBNDVThuThuat?.DanhSachVatTuTieuHao[0]?.ngayThucHien &&
                        moment(infoBNDVThuThuat?.DanhSachVatTuTieuHao[0]?.ngayThucHien).format(
                          'DD/MM/YYYY',
                        )
                      : infoBNDVThuThuat?.DanhSachDichVu[0]?.trangthaikb === 4
                        ? infoBNDVThuThuat?.DanhSachDichVu[0]?.ngaythuchien
                          ? moment(infoBNDVThuThuat?.DanhSachDichVu[0]?.ngaythuchien).format(
                              'DD/MM/YYYY',
                            )
                          : ''
                        : today.format('DD/MM/YYYY')
                    : ''
                }
                variant="filled"
                readOnly
              />
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <label className="w-24 font-semibold">Chẩn đoán: </label>
              <Input value={infoBNDVThuThuat?.CHANDOAN} variant="filled" readOnly />
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <label className="w-24 font-semibold">Ghi chú: </label>
              <Input value={infoBNDVThuThuat?.GHICHU} variant="filled" readOnly />
            </div>
          </div>
        </div>
        <div className="w-1/2 gap-2 flex flex-col">
          <div className="flex gap-2">
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Ngày sinh: </label>
              <Input
                value={
                  infoBNDVThuThuat?.NGAYSINH &&
                  moment(infoBNDVThuThuat?.NGAYSINH).format('DD/MM/YYYY')
                }
                variant="filled"
                readOnly
              />
            </div>
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Giới tính: </label>
              <Input value={infoBNDVThuThuat?.GIOITINH} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Đối tượng: </label>
              <Input value={infoBNDVThuThuat?.DOITUONG} variant="filled" readOnly />
            </div>
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Mã TCQG: </label>
              <Input value={infoBNDVThuThuat?.MATCQG} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Lý do: </label>

              <Input variant="filled" value={infoBNDVThuThuat?.LIDO} readOnly />
            </div>
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Lời dặn: </label>
              <Input value={infoBNDVThuThuat?.LOIDAN} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex items-center">
            <label className="w-28 font-semibold">Bác sĩ CĐ: </label>
            <Input variant="filled" value={infoBNDVThuThuat?.BSCHANDOAN} readOnly />
          </div>
          <div className="flex items-center">
            <label className="w-28 font-semibold">Triệu chứng: </label>
            <Input variant="filled" value={infoBNDVThuThuat?.TRIEUCHUNG} readOnly />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoBN

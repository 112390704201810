import * as Yup from "yup";

export const addVoucherSchema = Yup.object().shape({
    mact: Yup.string().required(),
    tenct: Yup.string().required(),
    batdau: Yup.string().required(),
    ketthuc: Yup.string().required(),
    // giatri: idcombo === 0 ? Yup.number().required().min(1) : '',
    // soluongma: idcombo === 0 ? Yup.number().required().min(1) : '',
    // dodaima: idcombo === 0 ? Yup.number().required().min(1) : '',
});

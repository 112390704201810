import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  listThuocVT: null,
  infoVT: null,
  country: null,
  tonKho: null,
  infoTonKho: null,
  typesVT: null,
  groupsVT: null,
  allGroupsVT: null,
  allTypesVT: null,
  branchBuy: [],
  groupsVTBranchBuy: null,
  chuyenKhoa: null,
  branchTonKho: [],
  historyMSDK: [],
  historyGiaGPP: [],
  groupsTheKho: [],
  khoTheKho: [],
  duongDung: [],
  historyTheKho: [],
  fileImport: [],
  TonTongHop: [],
  TonKhoFromDay: [],
  progress: 0,
  kyHang: [],
  tonKiemKe: [],
  
}

const thuocVTReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_LIST_THUOCVT:
        draft.listThuocVT = payload
        break
      case typeAction.DISPATCH_KY_HANG_HOA:
        draft.kyHang = payload
        break
      case typeAction.DISPATCH_TON_KIEM_KE:
        draft.tonKiemKe = payload
        break
      case typeAction.DISPATCH_INFO_KHO_VT:
        draft.infoVT = payload
        break
      case typeAction.DISPATCH_TON_KHO_FROM_TO:
        draft.TonKhoFromDay = payload
        break
      case typeAction.UPDATE_PROGRESS_THU_MUA:
        draft.progress = payload
        break
      case typeAction.DISPATCH_FILE_IMPORT_THU_MUA:
        draft.fileImport = payload
        break
      case typeAction.DISPATCH_HISTORY_THE_KHO:
        draft.historyTheKho = payload
        break
      case typeAction.DISPATCH_DUONG_DUNG:
        draft.duongDung = payload
        break
      case typeAction.DISPATCH_KHO_THE_KHO:
        draft.khoTheKho = payload
        break
      case typeAction.DISPATCH_GROUPS_THE_KHO:
        draft.groupsTheKho = payload
        break
      case typeAction.DISPATCH_MSDK_THU_MUA:
        draft.historyMSDK = payload
        break
      case typeAction.DISPATCH_BRANCH_TON_KHO:
        draft.branchTonKho = payload
        break
      case typeAction.DISPATCH_INFO_COUNTRY:
        draft.country = payload
        break
      case typeAction.DISPATCH_LIST_TON_KHO:
        draft.tonKho = payload
        break
      case typeAction.DISPATCH_INFO_TON_kHO:
        draft.infoTonKho = payload
        break
      case typeAction.DISPATCH_TYPES_THUOC_VT:
        draft.typesVT = payload
        break
      case typeAction.DISPATCH_GROUPS_THUOC_VT:
        draft.groupsVT = payload
        break
      case typeAction.DISPATCH_GET_ALL_GROUPS_VT:
        draft.allGroupsVT = payload
        break
      case typeAction.DISPATCH_BRANCH_BUY:
        draft.branchBuy = payload
        break
      case typeAction.DISPATCH_GROUPS_VT_BRANCH_BUY:
        draft.groupsVTBranchBuy = payload
        break
      case typeAction.DISPATCH_ALL_CHUYEN_KHOA:
        draft.chuyenKhoa = payload
        break
      case typeAction.DISPATCH_ALL_TYPE_VT:
        draft.allTypesVT = payload
        break
      case typeAction.DISPATCH_TONKHO_TON_HOP:
        draft.TonTongHop = payload
        break
      case typeAction.DISPATCH_HISTORY_GPP:
        draft.historyGiaGPP = Array.isArray(payload) ? payload : [payload]
        break
      default:
        return state
    }
  })
}

export default thuocVTReducer

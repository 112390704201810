import * as typeAction from '../constants/constants'

export const searchBN_Mat_Action = (keyWord, idct) => ({
  type: typeAction.SEARCH_TRACUU_BN_MAT,
  keyWord,
  idct
})
export const getInfoBN_Mat_Action = (idBN, idChuyenKhoa) => ({
  type: typeAction.GET_INFO_TRACUU_BENH_NHAN_MAT,
  idBN,
  idChuyenKhoa,
})

export const getInfoBN_Mat_TT_Action = (idBN, idChuyenKhoa) => ({
  type: typeAction.GET_INFO_TRACUU_BENH_NHAN_TT_MAT,
  idBN,
  idChuyenKhoa,
})

export const getAll_BN_Mat = () => ({
  type: typeAction.GET_ALL_BN_MAT,
})

export const updateInfoBN_Mat = (form, onLoad, idChuyenKhoa, setLoading) => ({
  type: typeAction.UPDATE_BN_TRACUU_MAT,
  form,
  onLoad,
  idChuyenKhoa,
  setLoading
})

export const getList_BN_BY_CHINHANH_CTY_Mat = (idct,  idChinhanh, keyword, pageIndex, setIsLoading) => ({
  type: typeAction.GET_BN_CHINHANH_CTY_TRACUU_MAT,
  idct,
  idChinhanh,
  keyword,
  pageIndex,
  setIsLoading
})


import React from 'react'
import { Dropdown } from 'antd'
import { useSelector } from 'react-redux'
import { DeleteOutlined } from '@ant-design/icons'
const CardStaff = ({ item, setOpenDel, setStaff }) => {
  const { DetailBaoHong } = useSelector((state) => state.suCoReducer)
  return (
    <Dropdown
      disabled={DetailBaoHong?.idtrangthaiphieu !== 1}
      menu={{
        items: [
          {
            key: 2,
            label: 'Xoá kĩ thuật',
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            onClick: () => {
              setOpenDel(true)
              setStaff(item)
            },
          },
        ],
      }}
      placement="bottomRight"
      arrow
    >
      <div
        className="rounded h-5 px-1 dark:text-black bg-blue-500 text-white flex items-center gap-1 relative assignMEM"
        key={item.idnvbh}
      >
        {item.tennhanvien}
      </div>
    </Dropdown>
  )
}

export default CardStaff

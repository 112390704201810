import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Image, Button, Divider, Input, Spin } from 'antd'
import { DeleteOutlined, CloudUploadOutlined, SaveOutlined } from '@ant-design/icons'
import { xetNghiemService } from '../../../../services/xetNghiem/xetNghiemService'
import { infoBNXetNghiem } from '../../../../store/actions/xetNghiemAction'
import ToastCus from '../../../../utils/Toast'
import { v4 as uuidv4 } from 'uuid'

const UpdateThemFile = () => {
  const dispatch = useDispatch()
  const { detailXN } = useSelector((state) => state.xetNghiemReducer)
  const [selectedFile, setSelectedFile] = useState([])
  const [isLoading, setisLoading] = useState(false)
  const fileInputRef = useRef(null)
  const dropzoneRef = useRef(null)

  const deleteFileById = (id) => {
    const updatedData = selectedFile.filter((item) => item?.id !== id)
    setSelectedFile(updatedData)
  }
  const handleDivClick = () => {
    fileInputRef.current.click()
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px solid #000'
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
    // handleFileChange(e.dataTransfer.files)
    handleFileChange(Object.values(e.dataTransfer.files))
  }
  const handleFileSelect = (e) => {
    // handleFileChange(e.target.files)Object.values(e.target.files)
    handleFileChange(Object.values(e.target.files))
  }
  const handleFileChange = async (files) => {
    //  let file = event.target.files[0]
    // List of allowed file types
    const allowedFileTypes = [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'application/pdf',
    ]
    if (!files?.length || files?.find((item) => !allowedFileTypes.includes(item?.type))) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ chấp nhận tệp là hình ảnh hoặc pdf!',
      })
      fileInputRef.current.value = '' // clear the input
      return
    }
    if (files?.concat(selectedFile, detailXN?.FILEKETQUA?.split(';'))?.length > 20) {
      ToastCus.fire({
        icon: 'error',
        title: 'Tối đa chỉ được phép 20 file!',
      })
      fileInputRef.current.value = '' // clear the input
      return
    }
    for (const file of files) {
      fileInputRef.current.value = ''
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        const localUrl = URL.createObjectURL(file)
        setSelectedFile((preState) => [
          ...preState,
          {
            base64: e.target.result,
            URL: localUrl,
            name: file.name,
            id: uuidv4(),
            file,
            type: file?.type,
          },
        ])
      }
    }
  }
  const putUploadThemFileXetNghiem = async () => {
    if (!selectedFile?.length) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    try {
      setisLoading(true)
      const formData = new FormData()
      formData.append('IDBNTTC', detailXN?.IDBNTTC)
      const Files = selectedFile?.map((item) => item?.file)
      Files?.forEach((file, index) => {
        formData.append('FILEHINHANHS', file)
      })
      await xetNghiemService.uploadFileXN(formData)
      setSelectedFile([])
      dispatch(infoBNXetNghiem(detailXN?.IDBN, detailXN?.IDBNTTC))
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (error) {
      console.log('putUploadThemFileXetNghiem : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    } finally {
      setisLoading(false)
    }
  }
  return (
    <Spin spinning={isLoading}>
      <div className={detailXN?.FILEKETQUA ? 'mt-16' : ''}>
        <div className="flex justify-between items-center gap-2 mb-2">
          <div className="w-full pr-2">
            <Divider orientation="left" plain style={{ margin: 3 }}>
              <p className="font-semibold">Thêm tệp đính kèm</p>
            </Divider>
          </div>
          <Button
            loading={isLoading}
            onClick={putUploadThemFileXetNghiem}
            type="primary"
            icon={<SaveOutlined style={{ fontSize: 16 }} />}
          >
            Lưu tệp
          </Button>
        </div>
        <ul
          className={`flex flex-wrap gap-2 mb-2 overflow-y-auto ${selectedFile?.length > 9 ? ' h-[13rem] ' : ''}`}
        >
          {selectedFile?.map((item, index) => (
            <li key={`item${index}`} className="rounded-md border grid grid-flow-row">
              <div className="w-[7.72rem] h-[6rem] rounded-t-md overflow-hidden flex justify-center items-center">
                {item?.type?.includes('pdf') ? (
                  <a href={item?.URL} target="_blank" className="flex justify-center items-center">
                    <Image
                      loading="lazy"
                      key={`image${index}`}
                      height={90}
                      preview={false}
                      src="https://www.svgrepo.com/download/144578/pdf.svg"
                    />
                  </a>
                ) : (
                  <Image loading="lazy" key={`image${index}`} src={item?.base64} />
                )}
              </div>
              <div className="h-[2rem] z-10 border-t flex justify-between items-center gap-1 px-1">
                <Input
                  value={item?.name}
                  size="small"
                  className="w-[5.4rem] p-0"
                  readOnly
                  variant="borderless"
                />
                <style>{`
                  .buttonDelete {
                    width: 24px !important;
                    padding: 3px !important;
                    font-size: 12px !important;
                    line-height: 12px !important;
                    height: fit-content !important;
                  }
                `}</style>
                <Button
                  danger
                  type="primary"
                  icon={<DeleteOutlined />}
                  className="buttonDelete"
                  onClick={() => deleteFileById(item?.id)}
                />
              </div>
            </li>
          ))}
        </ul>
        <div
          style={{
            border: '2px dashed #cccccc',
            borderRadius: '4px',
            padding: '30px',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          className="border"
          ref={dropzoneRef}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleDivClick}
        >
          <CloudUploadOutlined style={{ fontSize: 36 }} className="text-gray-500" />
          <label style={{ cursor: 'pointer' }}>
            <p className="font-semibold text-lg text-gray-500">
              Thả tập tin vào đây hoặc bấm vào để tải lên.
            </p>
          </label>
          <input
            multiple
            ref={fileInputRef}
            id="fileInput"
            type="file"
            onChange={handleFileSelect}
          />
        </div>
      </div>
    </Spin>
  )
}

export default UpdateThemFile

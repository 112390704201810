import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConfigProvider, Table, Input, Select, InputNumber, notification, Alert } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import * as typeAction from '../../../../store/constants/constants'
import ToastCus from '../../../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const VatTu = ({ khoVatTu, setKhoVatTu, checkDcCapNhat }) => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const { infoBNDVThuThuat, listVT, listVTBN } = useSelector((state) => state.thuThuatReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [quyenKeVTTH, setquyenKeVTTH] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const openNotificationWithIcon = (message, description) => {
    api.warning({
      message,
      description,
    })
  }

  const handleChangeSoLuong = (vt, soLuongNew) => {
    if (
      infoBNDVThuThuat?.DanhSachDichVu?.length > 0 &&
      infoBNDVThuThuat?.DanhSachDichVu[0]?.trangthaikb === 4 &&
      !checkDcCapNhat
    ) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Bệnh nhân đã thực hiện, không thể chỉnh sửa!')
      return
    }
    const newValue = Number(soLuongNew)
    const index = listVTBN?.findIndex((items) => items.iD === vt.iD)
    if (newValue > listVTBN[index].tonKho) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Số lượng tồn kho không đủ!')
      return
    }
    dispatch({
      type: typeAction.DISPATCH_EDIT_VT_SL_BN_THUTHUAT,
      payload: {
        index: index,
        value: newValue,
      },
    })
  }
  const handleChangeGhiChu = (vt, ghiChuNew) => {
    if (
      infoBNDVThuThuat?.DanhSachDichVu?.length > 0 &&
      infoBNDVThuThuat?.DanhSachDichVu[0]?.trangthaikb === 4 &&
      !checkDcCapNhat
    ) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Bệnh nhân đã thực hiện, không thể chỉnh sửa!')
      return
    }
    const index = listVTBN.findIndex((items) => items.iD === vt.iD)
    dispatch({
      type: typeAction.DISPATCH_EDIT_VT_GHICHU_BN_THUTHUAT,
      payload: {
        index: index,
        value: ghiChuNew,
      },
    })
  }
  const handleVTTieuHao = (value) => {
    if (
      infoBNDVThuThuat?.DanhSachDichVu?.length > 0 &&
      infoBNDVThuThuat?.DanhSachDichVu[0]?.trangthaikb === 4 &&
      !checkDcCapNhat
    ) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Bệnh nhân đã thực hiện, không thể chỉnh sửa!')
      return
    }
    if (!infoBNDVThuThuat?.IDBNTTC) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Vui lòng chọn bệnh nhận cần thực hiện!')
      return
    }
    const index = listVTBN?.findIndex((itemVTBN) => itemVTBN.iD === value)
    if (index !== -1) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Vật tư đã được chọn!')
      return
    }
    const indexVT = listVT?.findIndex((itemVT) => itemVT.iD === value)
    if (listVT[indexVT]?.tonKho <= 0) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Số lượng tồn kho không đủ!')
      return
    }
    dispatch({
      type: typeAction.DISPATCH_ADD_VT_BN_THUTHUAT,
      payload: {
        iD: listVT[indexVT]?.iD,
        idbnttc: infoBNDVThuThuat?.IDBNTTC,
        idthuoc: listVT[indexVT]?.idThuoc,
        donGia: listVT[indexVT]?.thuocVatTu?.giaBan,
        soLuong: 1,
        thanhTien: listVT[indexVT]?.thuocVatTu?.giaBan,
        tongTien: listVT[indexVT]?.thuocVatTu?.giaBan,
        bsKeDon: infoBNDVThuThuat?.BSCHANDOAN,
        nguoiThucHien: infoUser?.tenNV,
        ghiChu: '',
        idkhocn: listVT[indexVT]?.idKhoCN,
        soLo: listVT[indexVT]?.soLo,
        hanDung: listVT[indexVT]?.hanDung,
        idDichVu: infoBNDVThuThuat?.DanhSachDichVu[0]?.iddichvu,
        tenBietDuoc: listVT[indexVT]?.thuocVatTu?.tenBietDuoc,
        dvt: listVT[indexVT]?.thuocVatTu?.dvt,
        tonKho: listVT[indexVT]?.tonKho,
        soHoaDon: listVT[indexVT]?.soHoaDon,
        maSoDangKy: listVT[indexVT]?.maSoDangKy,
      },
    })
  }
  useEffect(() => {
    dispatch({
      type: typeAction.DISPATCH_LIST_VT_BN_THUTHUAT,
      payload: infoBNDVThuThuat?.DanhSachVatTuTieuHao?.map((item) =>
        checkDcCapNhat
          ? {
              ...item,
              iD: `${item.idthuoc}${item.soLo}${item.hanDung}${item.soHoaDon}${item.idkhocn}`,
              tonKho:
                (listVT?.find(
                  (itemVT) =>
                    itemVT.iD ===
                    `${item.idthuoc}${item.soLo}${item.hanDung}${item.soHoaDon}${item.idkhocn}`,
                )?.tonKho ?? 0) + item?.soLuong,
            }
          : { ...item },
      ),
    })
  }, [infoBNDVThuThuat?.DanhSachVatTuTieuHao, infoBNDVThuThuat?.DanhSachVatTuTieuHao?.length])
  const checkQuyenKeVTTH = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT345',
      )
      setquyenKeVTTH(true)
    } catch (error) {
      setquyenKeVTTH(false)
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  useEffect(() => {
    checkQuyenKeVTTH()
  }, [])
  useEffect(() => {
    if (infoBNDVThuThuat?.IDBN) checkQuyenKeVTTH()
  }, [infoBNDVThuThuat])
  return (
    <div className="border rounded">
      {contextHolder}
      <div className="mb-2">
        {/* <Divider orientation="left">Vật tư tiêu hao
      </Divider> */}
        <h2 className="font-semibold  text-lg ml-2 mt-2 text-gray-500">Vật tư tiêu hao</h2>
        <div className="p-2">
          {!quyenKeVTTH ? (
            <>
              <Alert message="Tài khoản không có quyền thao tác!" type="error" showIcon />
            </>
          ) : (
            <>
              <div className="flex mb-2">
                <div className="w-[12%]">
                  <Select
                    value={khoVatTu}
                    options={[
                      {
                        value: 1,
                        label: 'Kho điều dưỡng',
                      },
                      {
                        value: 2,
                        label: 'Kho cấp cứu',
                      },
                    ]}
                    className="w-full"
                    onChange={setKhoVatTu}
                  />
                </div>
                <div className="w-[88%] pl-1">
                  <Select
                    placeholder="Nhập tên vật tư..."
                    className="w-full"
                    showSearch
                    onChange={handleVTTieuHao}
                    filterOption={(input, option) => {
                      const label = option?.label ?? ''
                      const tenBietDuoc = option?.tenBietDuoc ?? ''
                      const searchText = input.toLowerCase()
                      return (
                        label.toLowerCase().includes(searchText) ||
                        tenBietDuoc.toLowerCase().includes(searchText)
                      )
                    }}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    value={null}
                    options={listVT?.map((items) => ({
                      key: items.iD,
                      value: items.iD,
                      label: items.thuocVatTu.tenHoatChat,
                      tenBietDuoc: items.thuocVatTu.tenBietDuoc,
                      dvt: items.thuocVatTu.dvt,
                      soLo: items.soLo,
                      hanDung: items.hanDung,
                      soHoaDon: items.soHoaDon,
                      maSoDangKy: items.maSoDangKy,
                      ton: items.tonKho,
                    }))}
                    dropdownRender={(menu) => (
                      <div>
                        <div
                          className="flex bg-gray-100"
                          style={{ padding: '8px', fontWeight: 'bold' }}
                        >
                          <span className="w-[50%] text-center">Tên hàng</span>
                          <span className="w-[12.5%] text-center">Số lô</span>
                          <span className="w-[12.5%] text-center">Hạn dùng</span>
                          <span className="w-[12.5%] text-center">Đơn vị</span>
                          <span className="w-[12.5%] text-center">Tồn</span>
                        </div>
                        <div style={{ marginBottom: '8px' }}>{menu}</div>
                      </div>
                    )}
                    optionLabelProp="label"
                    optionRender={(options) => (
                      <ul className="flex">
                        <li className="w-[50%] ">
                          <p className="font-semibold w-full text-pretty">{options.data.label}</p>
                          <span className="text-xs w-full text-gray-500">
                            {' '}
                            ({options.data.tenBietDuoc})
                          </span>
                        </li>
                        <li className="w-[12.5%] border-x flex items-center justify-center text-center">
                          {options.data.soLo}
                        </li>
                        <li className="w-[12.5%] text-center flex items-center justify-center">
                          {moment(options.data.hanDung).format('DD/MM/YYYY')}
                        </li>
                        <li className="w-[12.5%]  text-center flex items-center justify-center">
                          {options.data.dvt}
                        </li>
                        <li className="w-[12.5%] border-x text-center flex items-center justify-center">
                          {options.data.ton}
                        </li>
                      </ul>
                    )}
                    // filterOption={(input, option) =>
                    //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    // }
                    // options={listVT?.map((items) => ({
                    //   label: items.thuocVatTu.tenBietDuoc,
                    //   value: items.idThuoc
                    // }))}
                  />
                </div>
              </div>
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                  },
                }}
              >
                <Table
                  pagination={false}
                  dataSource={listVTBN ?? []}
                  columns={[
                    {
                      title: 'STT',
                      dataIndex: 'stt',
                      key: 'stt',
                      align: 'center',
                      width: 40,
                      render: (text, record, index) => ++index,
                    },
                    {
                      title: 'Tên vật tư',
                      dataIndex: 'tenBietDuoc',
                      key: 'tenBietDuoc',
                    },
                    {
                      title: 'Số lô',
                      dataIndex: 'soLo',
                      key: 'soLo',
                      align: 'center',
                      width: 120,
                    },
                    {
                      title: 'Hạn dùng',
                      dataIndex: 'hanDung',
                      key: 'hanDung',
                      align: 'center',
                      width: 90,
                      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY'),
                    },
                    {
                      title: 'Đơn vị',
                      dataIndex: 'dvt',
                      key: 'dvt',
                      align: 'center',
                      width: 70,
                    },
                    {
                      title: 'SL',
                      dataIndex: 'soLuong',
                      key: 'soLuong',
                      align: 'center',
                      width: 65,
                      render: (text, record, index) => (
                        <InputNumber
                          onChange={(value) => handleChangeSoLuong(record, value)}
                          value={text}
                          min={1}
                          step={1}
                          max={record?.tonKho}
                          size="small"
                          className="w-full"
                        />
                      ),
                    },
                    {
                      title: 'Tồn',
                      dataIndex: 'tonKho',
                      key: 'tonKho',
                      align: 'center',
                      width: 65,
                      className: 'text-red-500',
                      render: (text, record, index) =>
                        (record?.tonKho ?? 0) - (record?.soLuong ?? 0) >= 0
                          ? (record?.tonKho ?? 0) - (record?.soLuong ?? 0)
                          : null,
                    },
                    {
                      title: 'Ghi chú',
                      dataIndex: 'ghiChu',
                      key: 'ghiChu',
                      align: 'center',
                      width: 200,
                      render: (text, record, index) => (
                        <Input
                          onChange={(e) => {
                            handleChangeGhiChu(record, e.target.value)
                          }}
                          value={text ?? ''}
                          size="small"
                        />
                      ),
                    },
                    {
                      title: '',
                      dataIndex: 'action',
                      key: 'action',
                      align: 'center',
                      width: 40,
                      render: (text, record, index) => (
                        <>
                          {((infoBNDVThuThuat?.DanhSachDichVu?.length > 0 &&
                            infoBNDVThuThuat?.DanhSachDichVu[0]?.trangthaikb !== 4) ||
                            checkDcCapNhat) && (
                            <CloseOutlined
                              onClick={() => {
                                dispatch({
                                  type: typeAction.DISPATCH_DELETE_VT_BN_THUTHUAT,
                                  payload: record.iD,
                                })
                              }}
                              className="text-red-500 cursor-pointer"
                            />
                          )}
                        </>
                      ),
                    },
                  ]}
                  bordered
                  scroll={{ y: 175 }}
                />
              </ConfigProvider>
            </>
          )}
        </div>
      </div>
      <div className="p-2">
        {/* <Divider orientation="left">Dịch vụ</Divider> */}
        <h2 className="font-semibold  text-lg m-2 text-gray-500">Dịch vụ</h2>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            pagination={false}
            dataSource={infoBNDVThuThuat?.DanhSachDichVu ?? []}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 40,
                align: 'center',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Mã DV',
                dataIndex: 'madichvu',
                key: 'madichvu',
                width: 130,
              },
              {
                title: 'Tên dịch vụ',
                dataIndex: 'tendichvu',
                key: 'tendichvu',
              },
              {
                title: 'Đơn vị tính',
                dataIndex: 'donvitinh',
                key: 'donvitinh',
                width: 90,
                align: 'center',
              },
            ]}
            bordered
            scroll={{ y: 175 }}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default VatTu

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_toolbarClassName__XnMQz {
    background-color: #f5f5f5;
  }
  
  .style_wrapperClassName__FoyD7 {
    border: 1px solid #d4d4d4;
    padding: 10px;
  }
  
  .style_editorClassName__X-n7i {
    min-height: 550px;
    max-height: 550px; /* Giới hạn chiều cao */
    overflow-y: auto;  /* Hiển thị thanh cuộn dọc khi vượt quá chiều cao */
    padding: 10px;
  }

  .style_editorClassNameKSK__q2cg9 {
     min-height: 15.8rem;
    /*max-height: 17.8rem;  */
    /* Giới hạn chiều cao */
    overflow-y: auto;  /* Hiển thị thanh cuộn dọc khi vượt quá chiều cao */
    padding: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/components/layout/SieuAm/Template/style.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,iBAAiB,EAAE,uBAAuB;IAC1C,gBAAgB,GAAG,mDAAmD;IACtE,aAAa;EACf;;EAEA;KACG,mBAAmB;IACpB,yBAAyB;IACzB,uBAAuB;IACvB,gBAAgB,GAAG,mDAAmD;IACtE,aAAa;EACf","sourcesContent":[".toolbarClassName {\n    background-color: #f5f5f5;\n  }\n  \n  .wrapperClassName {\n    border: 1px solid #d4d4d4;\n    padding: 10px;\n  }\n  \n  .editorClassName {\n    min-height: 550px;\n    max-height: 550px; /* Giới hạn chiều cao */\n    overflow-y: auto;  /* Hiển thị thanh cuộn dọc khi vượt quá chiều cao */\n    padding: 10px;\n  }\n\n  .editorClassNameKSK {\n     min-height: 15.8rem;\n    /*max-height: 17.8rem;  */\n    /* Giới hạn chiều cao */\n    overflow-y: auto;  /* Hiển thị thanh cuộn dọc khi vượt quá chiều cao */\n    padding: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarClassName": `style_toolbarClassName__XnMQz`,
	"wrapperClassName": `style_wrapperClassName__FoyD7`,
	"editorClassName": `style_editorClassName__X-n7i`,
	"editorClassNameKSK": `style_editorClassNameKSK__q2cg9`
};
export default ___CSS_LOADER_EXPORT___;

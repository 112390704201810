import JsBarcode from 'jsbarcode'
import moment from 'moment/moment'
import { generateLogo, getDeTailThanhToan } from '../algorithm'
import { formatNumberVND } from '../formatNumberVND'
import { jsPDFCus } from '../jsPdfAddFont'
import { numberToWords } from '../numberToWords'
import { splitLongWord } from '../splitWordForReport'

export const generatePhieuThuTienToaThuocInLai = async (ttPhieuThu, BNTTC, toathuoc, PkDangNhap) => {
  const doc = jsPDFCus('p', 'mm', 'a5')
  let yPos = 10
  let xPos = 8
  let ruleWidth = 55 // for text of price is longer
  const pageWidth = doc.internal.pageSize.width
  //header
  generateLogo(doc, xPos, PkDangNhap)
  //barcode
  JsBarcode('#barcode', `${ttPhieuThu.maPhieuThu ?? '0'}`, {
    displayValue: false,
  })
  const canvas = document.getElementById('barcode')
  const barcodeDataURL = canvas.toDataURL()
  const barcodeWidth = 35 // Width of the barcode image
  const barcodeX = pageWidth - 45 // X coordinate of the barcode
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 45, 5, 35, 14)
  // Calculate the center position for text
  const text = `${ttPhieuThu.maPhieuThu ?? ''}`
  const textWidth = (doc.getStringUnitWidth(text) * 11) / doc.internal.scaleFactor // Width of the text
  const textX = barcodeX + (barcodeWidth - textWidth) / 2 // Centered X coordinate for the text
  doc.setFontSize(11)
  doc.text(text, textX, 22)

  doc.setFont('times', 'bold')
  doc.setFontSize(13)
  doc.text('PHIẾU THU TIỀN', doc.internal.pageSize.width / 2, 30, { align: 'center' })

  doc.setFontSize(10)

  let textWidthName = doc.getTextDimensions(`Họ và tên: `).w
  doc.text(BNTTC.benhNhan.tenBenhNhan.trim() ?? '', textWidthName + xPos, 42)
  doc.setFont('times', 'regular')
  doc.text(`Mã BN: ${BNTTC.benhNhan.maBenhNhan}`, textX - 2, 28)
  doc.text(
    `Ngày sinh:  ${BNTTC.benhNhan.ngaySinh ? moment(BNTTC.benhNhan.ngaySinh).format('DD/MM/YYYY') : ''}`,
    pageWidth - 50,
    42,
  )
  doc.text('Họ và tên: ', xPos, 42)
  doc.text(`Số điện thoại: ${BNTTC.benhNhan.dienThoai ?? ''}`, xPos, 47)
  const diaChiBenhNhan = `Địa chỉ: ${BNTTC.benhNhan.diaChi ?? ''}${BNTTC.benhNhan.tenPhuongXa ? ', ' + BNTTC.benhNhan.tenPhuongXa : ''}${BNTTC.benhNhan.tenQuanHuyen ? ', ' + BNTTC.benhNhan.tenQuanHuyen : ''}${BNTTC.benhNhan.tenTinhTP ? ', ' + BNTTC.benhNhan.tenTinhTP : ''}`
  if (diaChiBenhNhan.length > 80) {
    const wordsArray = splitLongWord(diaChiBenhNhan, 80)
    doc.text(wordsArray[0], xPos, 52)
    doc.text(wordsArray[1], xPos, 57)
    yPos = 57
  } else {
    doc.text(diaChiBenhNhan, xPos, 52)
    yPos = 52
  }
  yPos = yPos + 5
  doc.text(`Bác sĩ: ${BNTTC.bskham ?? ''}`, xPos, yPos)
  yPos = yPos + 5
  const chanDoanBenhNhan = `Chẩn đoán: ${BNTTC.chandoan ?? ''}`
  if (chanDoanBenhNhan.length > 80) {
    const wordsArray = splitLongWord(`${chanDoanBenhNhan}`, 80)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    yPos += 5
  } else {
    doc.text(`${chanDoanBenhNhan}`, xPos, yPos)
    yPos += 5
  }
  yPos += 5
  //table
  const headers = ['STT', 'Tên hàng hóa', 'Số lô', 'Hạn dùng', 'Đơn vị', 'Số lượng']
  const data = toathuoc.map((item, index) => [
    index + 1,
    item.tenHoatChat,
    item.soLo,
    item.hanDung ? moment(item.hanDung).format('DD/MM/YYYY') : '',
    item.dvt,
    item.soLuong,
  ])

  //for portrait
  const columnWidths = [10, 50, 20, 20, 15, 20]
  doc.autoTable({
    head: [headers],
    body: data,
    startY: yPos,
    columnStyles: {
      // Set custom column widths
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1], halign: 'left' },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
      4: { cellWidth: columnWidths[4] },
      5: { cellWidth: columnWidths[5] },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1, // Set line width for table borders
      fillColor: '#fff', // Set fill color to white for headers and rows
      textColor: '#000', // Set text color to black for headers and rows
      lineColor: '#000',
      halign: 'center',
      valign: 'middle',
      fontStyle: 'regular',
      fontSize: 8,
      // Set line color to black for headers and rows
    },
    headStyles: {
      font: 'times',
      fillColor: '#fff', // Set fill color to white for headers
      textColor: '#000', // Set text color to black for headers
      fontStyle: 'bold', // Set font weight to bold for headers
      fontSize: 8,
    },
    alternateRowStyles: {
      font: 'times',
      fontStyle: 'regular',
      fillColor: '#fff', // Set fill color to white for alternate rows
    },
    showHead: 'firstPage',
    margin: { left: xPos },
  })

  yPos = doc.autoTable.previous.finalY
  const bnchitietthanhtoan = await getDeTailThanhToan(BNTTC.idbnttc)
  const paymentArray = ['Tiền mặt', 'Chuyển khoản', 'Thẻ thanh toán', 'Momo', 'VNPay', 'Zalopay', 'POS']
  .filter(key => bnchitietthanhtoan[key] > 0); 
  const textPhuongThucThanhToan = `HÌNH THỨC THANH TOÁN: ${paymentArray.join(' + ')}`
  const tongTien = toathuoc.reduce((total, item) => total + item.thanhTien, 0)
  const data2Column = [
    ['', ''],
    [textPhuongThucThanhToan, formatNumberVND(tongTien)],
    ['TỔNG TIỀN: ', formatNumberVND(tongTien)],
  ]
  doc.autoTable({
    head: [data2Column[0]],
    body: data2Column.slice(1),
    startY: yPos,
    columnStyles: {
      // Set custom column widths (portrait)
      0: { cellWidth: 115, halign: 'right' },
      1: { cellWidth: 20, halign: 'right', fontStyle: 'bold' },
      // Set custom column widths (landscape)
      // 0: { cellWidth: 160, halign: 'right' },
      // 1: { cellWidth: 30, halign: 'right', fontStyle: 'bold' },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1, // Set line width for table borders
      fillColor: '#fff', // Set fill color to white for headers and rows
      textColor: '#000', // Set text color to black for headers and rows
      lineColor: '#000',
      valign: 'middle',
      fontStyle: 'bold', // Set font weight to bold for headers
      fontSize: 8,
      // Set line color to black for headers and rows
    },
    headStyles: {
      fontSize: 8,
      fillColor: '#fff',
    },
    alternateRowStyles: {
      fillColor: '#fff',
      // Set fill color to white for alternate rows
    },
    showHead: 'never',
    margin: { left: xPos },
  })
  yPos += 7
  if (doc.internal.pageSize.height - yPos < 44) {
    doc.addPage()
    yPos = 10
  } else {
    yPos = doc.autoTable.previous.finalY
  }
  doc.setFont('times', 'italic')
  doc.setFontSize(10)
  yPos += 5
  const dateText = `Ngày ${moment(ttPhieuThu?.ngayThanhToan).format('DD')} tháng ${moment(ttPhieuThu?.ngayThanhToan).format('MM')} năm ${moment(ttPhieuThu?.ngayThanhToan).format('YYYY')}`
  const doctorText = ttPhieuThu.duocSi ?? ''
  const dateTextWidth = doc.getTextWidth(dateText)
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateTextX = pageWidth - dateTextWidth - 18

  // Calculate the center point of the date text
  const dateCenterX = dateTextX + dateTextWidth / 2

  // Position the doctor text centered below the date text
  const doctorTextX = dateCenterX - doctorTextWidth / 2

  doc.text(dateText, dateTextX, yPos)
  yPos += 5

  doc.setFont('times', 'regular')
  doc.text('Người lập phiếu', pageWidth - 50, yPos)
  doc.setFont('times', 'bold')
  doc.text(doctorText, doctorTextX, yPos + 20)

  doc.setFont('times', 'regular')
  yPos -= 10

  // landspace: 60, portrait: 40
  if (`Bằng chữ: ${numberToWords(tongTien)}.`.length > 40) {
    yPos += 5
    const wordsArray = splitLongWord(`Bằng chữ: ${numberToWords(tongTien)}.`, 40)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    yPos += 5
  } else {
    doc.text(`${`Bằng chữ: ${numberToWords(tongTien)}.` ?? ''}`, xPos, yPos + 5)
    yPos += 5
  }
  yPos += 10
  const defaultLoiDan =
    'Toa thuốc chỉ có giá trị tại thời điểm bác sĩ chẩn đoán bệnh. Chúng tôi miễn trừ trách nhiệm trong trường hợp toa thuốc khách hàng sử dụng không ngay tại thời điểm chẩn đoán bệnh'
  const wordsArray = splitLongWord(defaultLoiDan, 45)
  for (let j = 0; j < wordsArray.length; j++) {
    doc.text(`${wordsArray[j]}`, xPos, yPos + 5)
    yPos += 5
  }

  doc.setFontSize(8)
  doc.text(
    `(*)Ngày in phiếu: ${moment().format('DD')}/${moment().format('MM')}/${moment().format('YYYY')}`,
    pageWidth - 40,
    doc.internal.pageSize.height - 5,
  )

  // doc.save('Phieu-thu.pdf')
  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}

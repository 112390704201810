import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, DatePicker, Select, Table, TreeSelect, Typography } from 'antd'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { https } from '../../../services/apiService'
import { reportService } from '../../../services/Report/reportService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { phanQuyenService } from '../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../store/actions/phanQuyenAction'
import { formattedNumber } from '../../../utils/formattedNumber'
import ToastCus from '../../../utils/Toast'
import * as XLSX from 'xlsx'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const { SHOW_PARENT } = TreeSelect
const { Text } = Typography
function TabTongHop(props) {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [date, setDate] = useState({ from: now, to: now })
  const [value, setValue] = useState([])
  const [data, setData] = useState([])

  const [allChuyenKhoa, setAllChuyenKhoa] = useState([])

  // useEffect(() => {
  //   if (PkDangNhap?.idChuyenKhoa === 4) {
  //     setValue([BranchLogin])
  //   }
  // }, [PkDangNhap])
  // const fetchBranch = async (id) => {
  //    const { data } = await reportService.getBranchByChuyenKhoa(company, value)
  //   setBranch(data)
  // }

  const getAllChuyenKhoa = async () => {
    try {
      const { data } = await https.get('ChuyenKhoa/AllChuyenKhoa')
      setAllChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }

  const onChange = (newValue) => {
    setValue(newValue)
  }

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  const handleDatePicker = (name) => (date, dateString) => {
    const formatValueDate = moment(dateString, dateFormat).format(momentDate)
    setDate((prevState) => ({ ...prevState, [name]: formatValueDate }))
  }

  const onLoad = async () => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT508',
      )
      let idChiNhanhs = ''
      if (value?.length > 0 && value[0] !== 'all') {
        idChiNhanhs = value.map((id) => `&idChiNhanhs=${id}`).join('&')
      } else setValue(['all'])
      const { data } = await reportService.getThongKeTongHop(
        company,
        date.from,
        date.to,
        idChuyenKhoa,
        idChiNhanhs,
      )
      setData(data)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setIsLoading(false)
    }
  }

  // useEffect(() => {
  //   onLoad()
  // }, [value])

  useEffect(() => {
    dispatch(listAllCompanyAction())
    getAllChuyenKhoa()
    fetchBranchByChuyenKhoa(company, idChuyenKhoa)
  }, [])

  // const data = [
  //   { key: '1', header: 'TỔNG LƯỢT KHÁCH DỊCH VỤ', value: 'Row 1 Data' },
  //   { key: '2', header: 'Lượt khách khám mắt + đo khúc xạ', value: 'Row 2 Data' },
  //   { key: '3', header: 'Lượt khách vãng lai tái khám miễn phí', value: 'Row 3 Data' },
  //   { key: '3', header: 'Lượt khách làm thủ thuật', value: 'Row 3 Data' },
  //   { key: '3', header: 'Lượt khách lần đầu thẻ thành viên', value: 'Row 3 Data' },
  //   { key: '3', header: 'Lượt khách tái khám thẻ thành viên', value: 'Row 3 Data' },
  //   { key: '3', header: 'Khách đến khám tại phòng khám lần đầu', value: 'Row 3 Data' },
  //   { key: '3', header: 'Lượt khách đến mua thuốc từ dịch vụ', value: 'Row 3 Data' },
  //   { key: '3', header: 'Lượt khách mua lẻ thuốc', value: 'Row 3 Data' },
  //   { key: '3', header: 'Doanh thu dịch vụ khám bệnh', value: 'Row 3 Data' },
  //   { key: '3', header: 'Doanh thu dịch vụ đo khúc xạ', value: 'Row 3 Data' },
  //   { key: '3', header: 'Doanh thu thủ thuật', value: 'Row 3 Data' },
  //   { key: '3', header: 'Doanh thu chỉ định khác', value: 'Row 3 Data' },
  //   { key: '3', header: 'Doanh thu bán thuốc từ dịch vụ', value: 'Row 3 Data' },
  //   { key: '3', header: 'Doanh thu bán lẻ thuốc', value: 'Row 3 Data' },
  //   { key: '3', header: 'Lợi nhuận doanh thu bán thuốc', value: 'Row 3 Data' },
  //   { key: '3', header: 'Số lượng bán thẻ thành viên', value: 'Row 3 Data' },
  //   { key: '3', header: 'Số lượng bán thẻ bạc', value: 'Row 3 Data' },
  //   { key: '3', header: 'Doanh thu thẻ thành viên', value: 'Row 3 Data' },
  //   { key: '3', header: 'Doanh thu thẻ thành viên bạc', value: 'Row 3 Data' },
  //   { key: '3', header: 'TỔNG DOANH THU', value: 'Row 3 Data' },
  //   { key: '3', header: 'Thanh toán thẻ bằng máy Vietcombank', value: 'Row 3 Data' },
  //   { key: '3', header: 'Chuyển khoản Vietcombank', value: 'Row 3 Data' },
  //   { key: '3', header: 'TIỀN MẶT NỘP NGÂN HÀNG', value: 'Row 3 Data' },
  // ]

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Ngày',
      dataIndex: 'ngay',
      key: 'ngay',
      width: 100,
      fixed: 'left',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
    },
    { title: 'Chi nhánh', dataIndex: 'tenchinhanh', key: 'tenchinhanh', fixed: 'left', width: 250 },
    {
      title: 'Ngày khai trương',
      dataIndex: 'ngaykhaitruong',
      key: 'ngaykhaitruong',
      width: 150,
      fixed: 'left',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
    },
    {
      title: 'TỔNG LƯỢT KHÁCH DỊCH VỤ',
      dataIndex: 'tongluotkhach', // chưa có dữ liệu
      key: 'tongluotkhach',
      align: 'right',
      width: 150,
      render: (text) => <span style={{ color: 'blue' }}>{formattedNumber(text)}</span>,
    },
    {
      title: 'Lượt khách khám có thu phí',
      dataIndex: 'khamthuphi', //ok
      key: 'khamthuphi',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lượt khách khám sàng lọc tiêm chủng',
      dataIndex: 'khamsangloctiemchung',
      key: 'khamsangloctiemchung',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Khám sức khoẻ',
      dataIndex: 'khamsuckhoe', //ok
      key: 'khamsuckhoe',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lượt khách vãng lai tái khám miễn phí',
      dataIndex: 'taikhammienphivanglai', //ok
      key: 'taikhammienphivanglai',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lượt khách lần đầu thẻ thành viên',
      dataIndex: 'luotkhachlandauthe', //ok
      key: 'luotkhachlandauthe',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lượt khách tái khám thẻ thành viên',
      dataIndex: 'luotkhachtaikhamthe', //ok
      key: 'luotkhachtaikhamthe',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Khách đến khám tại phòng khám lần đầu',
      dataIndex: 'khachdenkhamlandau', //ok
      key: 'khachdenkhamlandau',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lượt khách mua thuốc từ dịch vụ',
      dataIndex: 'luotkhachtoathuoc', //ok
      key: 'luotkhachtoathuoc',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lượt khách mua lẻ thuốc',
      dataIndex: 'luotkhachtoabanle', //ok
      key: 'luotkhachtoabanle',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu dịch vụ khám bệnh',
      dataIndex: 'doanhthudichvu', //ok
      key: 'doanhthudichvu',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu dịch vụ xét nghiệm',
      dataIndex: 'doanhthuxetnghiem', //ok
      key: 'doanhthuxetnghiem',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu dịch vụ đo khúc xạ',
      dataIndex: 'doanhthudokhucxa', //ok
      key: 'doanhthudokhucxa',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu dịch vụ khác',
      dataIndex: 'doanhthukhac', //ok
      key: 'doanhthukhac',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu bán thuốc từ dịch vụ',
      dataIndex: 'doanhthutoathuoc', // chưa có dữ liệu
      key: 'doanhthutoathuoc',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu bán lẻ thuốc',
      dataIndex: 'doanhthutoabanle',
      key: 'doanhthutoabanle',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu vắc xin',
      dataIndex: 'doanhthuvacxin',
      key: 'doanhthuvacxin',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lợi nhuận doanh thu bán thuốc',
      dataIndex: 'loinhuanbanthuoc',
      key: 'loinhuanbanthuoc',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Số lượng bán thẻ thành viên',
      dataIndex: 'soluongbanthethanhvien',
      key: 'soluongbanthethanhvien',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Số lượng bán thẻ bạc',
      dataIndex: 'soluongbanthebac',
      key: 'soluongbanthebac',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Số lượng bán thẻ vàng',
      dataIndex: 'soluongbanthevang',
      key: 'soluongbanthevang',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu thẻ thành viên',
      dataIndex: 'doanhthuthethanhvien',
      key: 'doanhthuthethanhvien',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu thẻ thành viên bạc',
      dataIndex: 'doanhthuthebac',
      key: 'doanhthuthebac',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu thẻ thành viên vàng',
      dataIndex: 'doanhthuthevang',
      key: 'doanhthuthevang',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    // {
    //   title: 'TỔNG DOANH THU',
    //   dataIndex: 'tongdoanhthu',
    //   key: 'tongdoanhthu',
    //   align: 'right',
    //   width: 150,
    //   render: (text) => <span style={{ color: 'blue' }}>{formattedNumber(text)}</span>,
    // },
    {
      title: 'Thanh toán thẻ bằng máy Vietcombank',
      dataIndex: 'thanhtoanpos',
      key: 'thanhtoanpos',
      align: 'right',
      width: 300,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Chuyển khoản Vietcombank',
      dataIndex: 'thanhtoanchuyenkhoan',
      key: 'thanhtoanchuyenkhoan',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Got it',
      dataIndex: 'gotit',
      key: 'gotit',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Đối tác công nợ Anne Hill, Hello Bs',
      dataIndex: 'doitaccongno',
      key: 'doitaccongno',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Thanh toán bảo hiểm',
      dataIndex: 'thanhtoanbaohiem',
      key: 'thanhtoanbaohiem',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
      // render: (text) => formattedNumber(text),
    },
    {
      title: 'Tiền mặt nộp ngân hàng',
      dataIndex: 'tienmat',
      key: 'tienmat',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'tongdoanhthu',
      key: 'tongdoanhthu',
      width: 100,
      align: 'right',
      fixed: 'right',
      render: (text) => formattedNumber(text),
      // render: (_, record) => {
      //   const total = record.tienmat + record.thanhtoanpos + record.thanhtoanchuyenkhoan
      //   return formattedNumber(total)
      // },
    },
  ]
  const fetchBranchByChuyenKhoa = async (idct, idck) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(idct, idck)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(company, value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }

  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT509',
      )

      const formattedData = data?.map((item) => ({
        // ...item,
        'Tên chi nhánh': item.tenchinhanh,
        'Ngày ': item.ngay ? moment(item.ngay).format('DD/MM/YYYY') : '-', //ok
        'Ngày Khai trương': item.ngaykhaitruong
          ? moment(item.ngaykhaitruong).format('DD/MM/YYYY')
          : '-',
        'Tổng lượt khách dịch vụ': item.tongluotkhach,
        'Lượt khách khám có thu phí': item.khamthuphi,
        'Lượt khách khám sàng lọc tiêm chủng': item.khamsangloctiemchung,
        'Khám sức khoẻ': item.khamsuckhoe,
        'Lượt khách vãng lai tái khám miễn phí': item.taikhammienphivanglai,
        'Lượt khách lần đầu thẻ thành viên': item.luotkhachlandauthe,
        'Lượt khách tái khám thẻ thành viên': item.luotkhachtaikhamthe,
        'Khách đến khám tại phòng khám lần đầu': item.khachdenkhamlandau,
        'Lượt khách mua thuốc từ dịch vụ': item.luotkhachtoathuoc,
        'Lượt khách mua lẻ thuốc': item.luotkhachtoabanle,
        'Doanh thu dịch vụ khám bệnh': item.doanhthudichvu,
        'Doanh thu dịch vụ xét nghiệm': item.doanhthuxetnghiem,
        'Doanh thu dịch vụ đo khúc xạ': item?.doanhthudokhucxa,
        'Doanh thu dịch vụ khác': item.doanhthukhac,
        'Doanh thu bán thuốc từ dịch vụ': item.doanhthutoathuoc,
        'Doanh thu bán lẻ thuốc': item.doanhthutoabanle,
        'Doanh thu vắc xin': item.doanhthuvacxin,
        'Lợi nhuận doanh thu bán thuốc': item.loinhuanbanthuoc,
        'Số lượng bán thẻ thành viên': item.soluongbanthethanhvien,
        'Số lượng bán thẻ bạc': item.soluongbanthebac,
        'Số lượng bán thẻ vàng': item.soluongbanthevang,
        'Doanh thu thẻ thành viên': item.doanhthuthethanhvien,
        'Doanh thu thẻ thành viên bạc': item.doanhthuthebac,
        'Doanh thu thẻ thành viên vàng': item.doanhthuthevang,
        'Thanh toán thẻ bằng máy Vietcombank': item.thanhtoanpos,
        'Chuyển khoản Vietcombank': item.thanhtoanchuyenkhoan,
        'Got it': item.gotit,
        'Đối tác công nợ Anne Hill, Hello Bs': item.doitaccongno,
        'Thanh toán bảo hiểm': item.thanhtoanbaohiem,
        'Tiền mặt nộp ngân hàng': item.tienmat,
        'Tổng tiền': item.tongdoanhthu,
      }))
      const ws = XLSX.utils.json_to_sheet(formattedData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, 'ThongKeTongHop.xlsx')
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  return (
    <div>
      <div className="flex gap-5 justify-between border-dotted border-b-0 p-2 ">
        <div className="flex gap-5">
          <div className="w-80">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranchByChuyenKhoa(value, idChuyenKhoa)
                setIdChuyenKhoa('')
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>

          <div className="w-64 flex gap-1">
            <Select
              showSearch
              value={idChuyenKhoa}
              onChange={onChangeChuyenKhoa}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { label: 'Tất cả chuyên khoa', value: '' },
                ...allChuyenKhoa
                  ?.map((item) => ({
                    label: item.tenChuyenKhoa,
                    value: item.idChuyenKhoa,
                  }))
                  ?.sort((optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase()),
                  ),
              ]}
              className="w-80"
            />
          </div>
          <div className="w-80">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('from')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="flex gap-3">
            <IconButton onClick={onLoad} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
        </div>
        <div className="font-semibold">
          <Button
            disabled={!data?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 15,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              x: 1500,
              y: 660,
            }}
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
            loading={isLoading}
            locale={{
              emptyText: 'Hãy chọn phòng khám',
            }}
            // summary={(pageData) => (
            //   <>
            //     <Table.Summary fixed>
            //       <Table.Summary.Row>
            //         <Table.Summary.Cell index={0} colSpan={4}>
            //           <Text strong>Tổng cộng</Text>
            //         </Table.Summary.Cell>
            //         {columns
            //           ?.filter(
            //             (item) =>
            //               !['stt', 'ngay', 'tenchinhanh', 'ngaykhaitruong']?.includes(item?.key),
            //           )
            //           ?.map((item, index) => (
            //             <Table.Summary.Cell index={4 + index} align="right">
            //               <Text className="text-red-500">
            //                 {formattedNumber(
            //                   pageData?.reduce(
            //                     (tong, itemSum) =>
            //                       (tong += Number(
            //                         +itemSum[item?.key] > 0 ? +itemSum[item?.key] : 0,
            //                       )),
            //                     0,
            //                   ) ?? 0,
            //                 )}
            //               </Text>
            //             </Table.Summary.Cell>
            //           ))}
            //       </Table.Summary.Row>
            //     </Table.Summary>
            //   </>
            // )}
            summary={(pageData) => {
              // let total1 = 0
              // let total2 = 0
              // let total3 = 0
              // let total4 = 0
              // let total5 = 0
              // let total6 = 0
              // let total7 = 0
              // let total8 = 0
              // let total9 = 0
              // let total10 = 0
              // let total11 = 0
              // let total12 = 0
              // let total13 = 0
              // let total14 = 0
              // let total15 = 0
              // let total16 = 0
              // let total17 = 0
              // let total18 = 0
              // let total19 = 0
              // let toltal20 = 0
              // let toltal21 = 0
              // let toltal22 = 0
              // let toltal23 = 0
              // let toltal24 = 0
              // let toltal25 = 0
              // let toltal26 = 0
              // let toltal27 = 0
              // let TongTien = 0
              // pageData.forEach(
              //   ({
              //     tongluotkhach,
              //     khamthuphi,
              //     khamsangloctiemchung,
              //     khamsuckhoe,
              //     taikhammienphivanglai,
              //     luotkhachlandauthe,
              //     luotkhachtaikhamthe,
              //     khachdenkhamlandau,
              //     luotkhachtoathuoc,
              //     luotkhachtoabanle,
              //     doanhthudichvu,
              //     doanhthuxetnghiem,
              //     doanhthukhac,
              //     doanhthutoathuoc,
              //     doanhthutoabanle,
              //     doanhthuvacxin,
              //     loinhuanbanthuoc,
              //     soluongbanthethanhvien,
              //     soluongbanthebac,
              //     soluongbanthevang,
              //     doanhthuthethanhvien,
              //     doanhthuthebac,
              //     doanhthuthevang,
              //     tongdoanhthu,
              //     thanhtoanpos,
              //     thanhtoanchuyenkhoan,
              //     tienmat,
              //   }) => {
              //     total1 += tongluotkhach
              //     total2 += khamthuphi
              //     total3 += khamsangloctiemchung
              //     total4 += khamsuckhoe
              //     total5 += taikhammienphivanglai
              //     total6 += luotkhachlandauthe
              //     total7 += luotkhachtaikhamthe
              //     total8 += khachdenkhamlandau
              //     total9 += luotkhachtoathuoc
              //     total10 += luotkhachtoabanle
              //     total11 += doanhthudichvu
              //     total12 += doanhthuxetnghiem
              //     total13 += doanhthukhac
              //     total14 += doanhthutoathuoc
              //     total15 += doanhthutoabanle
              //     total16 += doanhthuvacxin
              //     total17 += loinhuanbanthuoc
              //     total18 += soluongbanthethanhvien
              //     total19 += soluongbanthebac
              //     toltal20 += soluongbanthevang
              //     toltal21 += doanhthuthethanhvien
              //     toltal22 += doanhthuthebac
              //     toltal23 += doanhthuthevang
              //     TongTien += tongdoanhthu
              //     toltal25 += thanhtoanpos
              //     toltal26 += thanhtoanchuyenkhoan
              //     toltal27 += tienmat

              //     TongTien = toltal25 + toltal26 + toltal27
              //   },
              // )
              const listColSum = columns?.filter(
                (item) => !['stt', 'ngay', 'tenchinhanh', 'ngaykhaitruong']?.includes(item?.key),
              )
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={4}>
                      <Text strong>Tổng cộng</Text>
                    </Table.Summary.Cell>
                    {listColSum?.map((item, index) => (
                      <Table.Summary.Cell index={4 + index} align="right">
                        <Text className="text-red-500">
                          {formattedNumber(
                            pageData?.reduce(
                              (tong, itemSum) =>
                                (tong += Number(+itemSum[item?.key] > 0 ? +itemSum[item?.key] : 0)),
                              0,
                            ) ?? 0,
                          )}
                        </Text>
                      </Table.Summary.Cell>
                    ))}
                    {/* <Table.Summary.Cell index={4} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total1)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total2)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total3)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total4)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total5)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total6)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total7)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total8)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total9)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total10)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total11)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total12)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={16} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total13)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={17} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total14)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={18} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total15)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={19} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total16)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={20} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total17)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={21} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total18)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={22} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total19)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={23} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(toltal20)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={24} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(toltal21)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={25} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(toltal22)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={26} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(toltal23)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={27} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(toltal25)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={28} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(toltal26)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={29} align="right"></Table.Summary.Cell>
                    <Table.Summary.Cell index={30} align="right"></Table.Summary.Cell>
                    <Table.Summary.Cell index={31} align="right"></Table.Summary.Cell>
                    <Table.Summary.Cell index={32} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(toltal27)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={33} colSpan={2} align="right" fixed>
                      <span style={{ color: 'red' }}>{formattedNumber(TongTien)}</span>
                    </Table.Summary.Cell> */}
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

TabTongHop.propTypes = {}

export default TabTongHop

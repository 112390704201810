import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import { Button, ConfigProvider, DatePicker, Select, Table, TreeSelect, Typography } from 'antd'
import dayjs from 'dayjs'
import { debounce } from 'lodash'
import moment from 'moment'
import 'moment/locale/vi'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx'
import { reportService } from '../../../../../services/Report/reportService'
import { https } from '../../../../../services/apiService'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { Text } = Typography
const Synthetic = ({ ChuyenKhoa }) => {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [data, setData] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [value, setValue] = useState([BranchLogin])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [count, setCount] = useState(0)
  const [loadingExport, setLoadingExport] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [reloadTrigger, setReloadTrigger] = useState(0)
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const onLoad = (page) => {
    fetchReport(page)
  }
  //-------- export excel-----------//
  const exportToExcel = async (company, idChuyenKhoa, value, dateForm, dateTo) => {
    setLoadingExport(true)
    try {
      const headers = [
        'STT',
        'Chi nhánh',
        'Mã BN',
        'Tên bệnh nhân',
        'Ngày sinh',
        'Giới tính',
        'Đối tượng',
        'Người nhà',
        'Địa chỉ',
        'Loại thẻ thành viên',
        'Số lần khám',
      ]
      let dataExport = []
      let idChiNhanhs = null
      if (value?.length > 0 && value[0] !== 'all') {
        idChiNhanhs = value.map((id) => `&idChiNhanhs=${id}`).join('&')
        const { data } = await https.get(
          `ThongKeLuotKham/LuotKhamTongExcel?idCongTy=${company}&idChuyenKhoa=${idChuyenKhoa}&fromDate=${dateForm}&toDate=${dateTo}${idChiNhanhs}`,
        )
        dataExport = data
      } else {
        const { data } = await https.get(
          `ThongKeLuotKham/LuotKhamTongExcel?idCongTy=${company}&idChuyenKhoa=${idChuyenKhoa}&fromDate=${dateForm}&toDate=${dateTo}`,
        )
        dataExport = data
      }
      const formattedData = dataExport.map((item, idx) => ({
        STT: idx + 1,
        'Chi nhánh': item.tenchinhanh,
        'Mã BN': item.mabn,
        'Tên bệnh nhân': item.tenbn,
        'Ngày sinh': item.ngaysinh,
        'Giới tính': item.gioitinh,
        'Đối tượng': item.tendoituong,
        'Người nhà': item.tennguoinha ?? '',
        'Địa chỉ': item.diachi,
        'Loại thẻ thành viên': item.loaithe,
        'Số lần khám': item.solankham,
      }))
      const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
      XLSX.writeFile(wb, 'Lợi nhuận tổng hợp.xlsx')
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingExport(false)
    }
  }
  // ------------ lấy danh sách report theo chi nhánh ------------//
  const fetchReport = async (page) => {
    setIsLoading(true)
    try {
      let idChiNhanhs = null
      if (value?.length > 0 && value[0] !== 'all') {
        idChiNhanhs = value.map((id) => `&idChiNhanhs=${id}`).join('&')
        const { data } = await https.get(
          `ThongKeLuotKham/LuotKhamTongByCondition?idCongTy=${company}&idChuyenKhoa=${idChuyenKhoa}&fromDate=${dateForm}&toDate=${dateTo}${idChiNhanhs}&pageNumber=${page}`,
        )
        setTotalPages(data.totalPage)
        setData((prev) => [...prev, ...data.data])
        setCount(data.tongSo)
      } else {
        const { data } = await https.get(
          `ThongKeLuotKham/LuotKhamTongByCondition?idCongTy=${company}&idChuyenKhoa=${idChuyenKhoa}&fromDate=${dateForm}&toDate=${dateTo}&pageNumber=${page}`,
        )
        // console.log(data);
        setData((prev) => [...prev, ...data.data])
        setTotalPages(data.totalPage)
        setCount(data.tongSo)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranchByChuyenKhoa('')
  }, [])

  const onChange = (newValue) => {
    setValue(newValue)
  }

  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      key: '0-1',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
        key: `0-1-${index}`,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  useEffect(() => {
    fetchReport(page)
  }, [page, reloadTrigger])

  const handleScroll = useCallback(
    debounce(() => {
      //api search return all data not pagination
      // disable scroll when search filtering
      const tableBody = document.querySelector('.table-luotkham-tonghop .ant-table-body')
      if (
        tableBody.scrollTop + tableBody.clientHeight >= tableBody.scrollHeight - 50 &&
        !isLoading
      ) {
        if (page + 1 > totalPages) {
          return
        }
        setPage((prevPage) => prevPage + 1)
      }
    }, 300),
    [isLoading],
  )

  useEffect(() => {
    const tableBody = document.querySelector('.table-luotkham-tonghop .ant-table-body')
    console.log(tableBody)
    tableBody.addEventListener('scroll', handleScroll)
    return () => tableBody.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  const handleReload = () => {
    setData([])
    setPage(1)
    setReloadTrigger((prev) => prev + 1)
  }

  return (
    <>
      <div className="flex gap-5 justify-between border-dotted border-b-0 py-2 ">
        <div className="flex gap-5">
          <div className="w-80">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranchByChuyenKhoa('')
                setIdChuyenKhoa('')
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-64 flex gap-1">
            <Select
              showSearch
              value={idChuyenKhoa}
              onChange={onChangeChuyenKhoa}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { label: 'Tất cả', value: '' },
                ...ChuyenKhoa.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
              className="w-80"
            />
          </div>
          <div className="w-64 flex gap-1">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="flex gap-3">
            <IconButton onClick={handleReload} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
          <div className="font-semibold ">
            <Button
              onClick={() => exportToExcel(company, idChuyenKhoa, value, dateForm, dateTo)}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
              loading={loadingExport}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>

      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              x: data.length > 0 ? 'max-content' : 0,
              y: '68vh',
            }}
            className="table-luotkham-tonghop"
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={data}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                onCell: () => ({
                  style: {
                    minWidth: 40,
                  },
                }),
                align: 'center',
                fixed: 'left',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Chi nhánh',
                dataIndex: 'tenchinhanh',
                key: 'tenchinhanh',
                fixed: 'left',
                onCell: () => ({
                  style: {
                    minWidth: 90,
                  },
                }),
              },
              {
                title: 'Mã BN',
                dataIndex: 'mabn',
                key: 'mabn',
                fixed: 'left',
                onCell: () => ({
                  style: {
                    minWidth: 90,
                  },
                }),
              },
              {
                title: 'Tên bệnh nhân',
                dataIndex: 'tenbn',
                key: 'tenbn',
                fixed: 'left',
                onCell: () => ({
                  style: {
                    minWidth: 110,
                  },
                }),
              },
              {
                title: 'Ngày sinh',
                dataIndex: 'ngaysinh',
                key: 'ngaysinh',
                onCell: () => ({
                  style: {
                    minWidth: 90,
                  },
                }),
                render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
              },
              {
                title: 'Giới tính',
                key: 'gioitinh',
                dataIndex: 'gioitinh',
                onCell: () => ({
                  style: {
                    minWidth: 80,
                  },
                }),
                align: 'center',
              },
              {
                title: 'Đối tượng',
                dataIndex: 'tendoituong',
                key: 'tendoituong',
                onCell: () => ({
                  style: {
                    minWidth: 80,
                  },
                }),
              },
              {
                title: 'Người nhà',
                dataIndex: 'tennguoinha',
                key: 'tennguoinha',
                onCell: () => ({
                  style: {
                    minWidth: 80,
                  },
                }),
              },
              {
                title: 'Địa chỉ',
                dataIndex: 'diachi',
                key: 'diachi',
                width: 500,
              },
              {
                title: 'Loại thẻ thành viên',
                dataIndex: 'loaithe',
                key: 'loaithe',
                onCell: () => ({
                  style: {
                    minWidth: 150,
                  },
                }),
              },
              {
                title: 'Số lần khám',
                dataIndex: 'solankham',
                key: 'solankham',
                width: 100,
                align: 'center',
                fixed: 'right',
              },
            ]}
            summary={(pageData) => {
              const cells = Array.from({ length: 11 }, (_, index) => (
                <Table.Summary.Cell key={index} align="center" index={index}>
                  <p className="font-semibold">{index === 10 ? count : null}</p>
                </Table.Summary.Cell>
              ))
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>{cells}</Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default Synthetic

import { Input, Modal, Checkbox } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllNhanSuCo, getDanhMucByNhan, updateDanhMuc } from '../../../store/actions/suCoAction'
import { updateNhomHangHoa, getNhomHangHoaByLoai } from '../../../store/actions/loaiHangHoaAction'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import moment from 'moment'
import * as Yup from 'yup'

const EditNhomThuoc = ({ isEditDanhMuc, setIsEditDanhMuc, idDanhMuc, group, keywordNhan }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)

  const maQuyenSuaNhom = 'QHT430'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenSuaNhom:
        formik.handleSubmit()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenSuaNhom:
        //
        break
      default:
        return false
    }
  }

  const handleCancel = () => {
    setIsEditDanhMuc(false)
  }
  const validationSchema = Yup.object({
    nhom: Yup.string().required('Tên nhóm hàng hóa không được bỏ trống'),
  })
  const fetchDanhMucNhan = () => {
    dispatch(getNhomHangHoaByLoai(group, ''))
  }
  const handlSubmit = (values) => {
    dispatch(updateNhomHangHoa(values, handleCancel, fetchDanhMucNhan))
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idNhom: idDanhMuc?.idNhom,
      nhom: idDanhMuc?.nhom,
      ghiChu: idDanhMuc?.ghiChu,
      idPhanLoai: idDanhMuc?.idPhanLoai,
      nguoiSua: infoUser?.tenNV,
      ngaySua: moment().format('YYYY-MM-DD'),
      thucPhamChucNang: idDanhMuc?.thucPhamChucNang,
    },
    validationSchema,
    onSubmit: (values) => handlSubmit(values),
  })
  return (
    <Modal
      onOk={() =>
        dispatch(putCheckQuyenThaoTacAction(maQuyenSuaNhom, null, actionIsTrue, actionIsFalse))
      }
      okText="Cập nhật"
      cancelText="Huỷ "
      open={isEditDanhMuc}
      onCancel={handleCancel}
      title={
        <p className="font-semibold text-xl w-full text-center text-blue-500">
          CẬP NHẬT NHÓM HÀNG HÓA
        </p>
      }
    >
      <div>
        <label htmlFor="" className="font-semibold">
          Tên nhóm hàng hóa : <span className="text-red-500">(*)</span>
        </label>
        <Input
          value={formik.values.nhom}
          onChange={formik.handleChange}
          name="nhom"
          status={formik.touched.nhom && formik.errors.nhom ? 'error' : ''}
        />
        {formik.touched.nhom && formik.errors.nhom && (
          <span className="text-red-500 text-sm">{formik.errors.nhom}</span>
        )}
      </div>
      <label htmlFor="" className="font-semibold">
        Ghi chú :
      </label>
      <Input.TextArea
        value={formik.values.ghiChu}
        onChange={formik.handleChange}
        name="ghiChu"
        rows={4}
      />
    </Modal>
  )
}

export default EditNhomThuoc

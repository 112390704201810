import { https } from '../apiService'
export const xuatTraNCCService = {
  postPhieuXuatTraNCC: (form) => https.post(`XuatTraNhaCungCap/AddXuatTraNhaCungCap`, form),
  postListChiTietPhieuXuatTraNCC: (list) =>
    https.post(`XuatTraNhaCungCapChiTiet/AddXuatTraNhaCungCapChiTiet`, list),
  getListPhieuXuat: (keyword, idct, idcn, tuNgay, denNgay) =>
    https.get(
      `XuatTraNhaCungCap/GetAllXuatTraNhaCungCap?${keyword?.length ? `keyword=${keyword}&` : ''}idct=${idct}&${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getInfoPhieuXuat: (idxtncc) =>
    https.get(`XuatTraNhaCungCap/GetInfoXuatTraNhaCungCap`, { params: { idxtncc } }),
  getListVTHHCuaPhieuXuat: (idxtncc) =>
    https.get(`XuatTraNhaCungCapChiTiet/GetXuatTraNhaCungCapChiTiet`, { params: { idxtncc } }),
  getListVTHHDaXuat: (keyword, idct, idcn, tuNgay, denNgay) =>
    https.get(`XuatTraNhaCungCap/GetHangHoaXuatTraNhaCungCap`, {
      params: { keyword, idct, idcn, tuNgay, denNgay },
    }),
}

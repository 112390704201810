import React from "react";
import { Input } from 'antd'
const { TextArea } = Input;
const FormNhuHoa = ({ formikPhuSan }) => {
    return (
        <>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Nhìn (2 vú): </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.nhiN2VU}
                    name="nhiN2VU"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Nhìn - trái: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.nhintrai}
                    name="nhintrai"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Nhìn - phải: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.nhinphai}
                    name="nhinphai"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Sờ nắn - trái: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.sonantrai}
                    name="sonantrai"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Sờ nắn - phải: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.sonanphai}
                    name="sonanphai"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1">
                <label className="font-semibold w-44 text-end">Hạch nách: </label>
                <Input
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.hachnach}
                    name="hachnach"
                    size="small"
                />
            </div>
            <div className="flex gap-1 mt-1 mb-5">
                <label className="font-semibold w-44 text-end">GN. khác: </label>
                <TextArea
                    rows={3}
                    onChange={formikPhuSan.handleChange}
                    value={formikPhuSan.values.gnkhac}
                    name="gnkhac"
                    size="small"
                />
            </div>
        </>
    )
}
export default FormNhuHoa;
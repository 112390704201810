import {
  CheckCircleOutlined,
  SyncOutlined,
  RollbackOutlined,
  PrinterOutlined,
  PauseCircleOutlined,
} from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Segmented,
  Tag,
  Modal,
  Radio,
  Input,
  Tooltip,
  ConfigProvider,
  Table,
  DatePicker,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  BNDaLayThuoc,
  BNchoThuoc,
  BNChoBSKeToaLai,
  getDetailInfoBN,
  putTraToaChoBSKeLaiAction,
} from '../../../../store/actions/drugstoreAction'
import TableMedicine from './TableMedicine/TableMedicine'
import { drugstoreService } from '../../../../services/drugstore/drugstoreService'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
import { https } from '../../../../services/apiService'
import { generatePhieuToaThuocNoiBo } from '../../../../utils/report/phieuToaThuocNoiBo'
moment.locale('vi')
const { TextArea } = Input
const { confirm } = Modal
const now = moment()
const dateFormat = 'DD/MM/YYYY'
const momentDate = 'YYYY-MM-DD'
const ListSelling = ({
  check,
  handleCheckBox,
  setCheck,
  setIdLyDo,
  lyDo,
  lyDoTraToa,
  idLyDo,
  checkTraThuoc,
  setCheckTraThuoc,
  lyDoKhac, // lý do không lấy thuốc trước khi thanh toán
  setLyDoKhac, // lý do không lấy thuốc trước khi thanh toán
}) => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const ID_BRANCH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const { BNThuoc, infoBN, service, BNDetail, BNTT } = useSelector(
    (state) => state.drugstoreReducer,
  )
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [tabLayThuoc, setTabLayThuoc] = useState(1)
  const [isModal, setIsModal] = useState(false)
  const [isModalTraToa, setIsModalTraToa] = useState(false)
  const [checkDaTraHet, setCheckDaTraHet] = useState(null)
  const [formLyDoTraToa, setFormLyDoTraToa] = useState(null)
  const [listLichSuTraToa, setListLichSuTraToa] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const checkBsDangKeToaLai = !service?.BSKEDON || !service?.NGAYKEDON
  const navigate = useNavigate()

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  // const showConfirm = () => {
  //   let idLdTraToa = null,
  //     noiDungLdKhac = null
  //   confirm({
  //     title: 'Trả toa cho bác sĩ kê lại',
  //     icon: <ExclamationCircleFilled />,
  //     content: (
  //       <>
  //         <div>
  //           Bạn có muốn trả toa:
  //           <span className="font-semibold ml-1">
  //             {infoBN?.tenBenhNhan} - {infoBN?.maBenhNhan}
  //           </span>
  //         </div>
  //         <div>
  //           <div>Lý do trả toa:</div>
  //           <Radio.Group onChange={(e) => (idLdTraToa = e.target.value)} value={idLdTraToa}>
  //             <div className="flex flex-col gap-3">
  //               {lyDo?.map((item) => (
  //                 <Radio value={item.idld}>{item.lydo}</Radio>
  //               ))}
  //             </div>
  //           </Radio.Group>
  //           {/* {idLdTraToa === 10 && (
  //             <>
  //               <TextArea
  //                 rows={3}
  //                 placeholder="Nhập lý do khác..."
  //                 maxLength={120}
  //                 className="mt-3"
  //                 value={noiDungLdKhac}
  //                 status={idLdTraToa === 10 && noiDungLdKhac?.trim()?.length === 0 ? 'error' : ''}
  //                 onChange={(e) => (noiDungLdKhac = e.target.value)}
  //               />
  //             </>
  //           )} */}
  //         </div>
  //       </>
  //     ),
  //     okText: 'Đồng ý', // Thay đổi văn bản nút "OK"
  //     cancelText: 'Hủy bỏ', // Thay đổi văn bản nút "Cancel"
  //     onOk() {
  //       console.log(idLdTraToa, noiDungLdKhac)
  //       // dispatch(
  //       //   putTraToaChoBSKeLaiAction({
  //       //     idbnttc: BNDetail?.idbnttc,
  //       //     idloaitt: 4, //trong bảng BNTT = 4 là của toa thuốc, 1 là kb, 6 là vắc xin
  //       //     idcn: ID_BRANCH_LOGIN,
  //       //     //xuatvacxin: true,
  //       //   }),
  //       // )
  //     },
  //     onCancel() {
  //       console.log('Cancel')
  //     },
  //   })
  // }
  const handleSubmitTraToa = () => {
    if (!formLyDoTraToa || !formLyDoTraToa?.idld || !formLyDoTraToa?.lydo) {
      setIsModalTraToa(false)
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng thử lại!',
      })
      return
    } else if (formLyDoTraToa?.lydo === 'Khác' && !formLyDoTraToa?.lydokhac?.trim()?.length) {
      setFormLyDoTraToa((prev) => ({ ...prev, lydokhac: '' }))
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập nội dung lý do khác!',
      })
      return
    }
    dispatch(
      putTraToaChoBSKeLaiAction({
        idbnttc: BNDetail?.idbnttc,
        idloaitt: 4, //trong bảng BNTT = 4 là của toa thuốc, 1 là kb, 6 là vắc xin
        idcn: ID_BRANCH_LOGIN,
        formbnttc: {
          idbnttc: BNDetail?.idbnttc,
          idlydotratoa: formLyDoTraToa?.idld,
          noidungtratoa:
            formLyDoTraToa?.lydo === 'Khác' ? formLyDoTraToa?.lydokhac : formLyDoTraToa?.lydo,
          idduocsitratoa: infoUser?.idnv,
          trangthaikhambenh: 1, // Nếu trả toa về danh sách chờ
        },
        formlichsutrathuoc: {
          // idlstt: 0,
          idbn: infoBN?.idbn,
          mabenhnhan: infoBN?.maBenhNhan,
          tenbenhnhan: infoBN?.tenBenhNhan,
          idbnttc: BNDetail?.idbnttc,
          nguoitra: infoUser?.idnv,
          ngaysinh: infoBN?.ngaySinh,
          idcn: PkDangNhap?.idChiNhanh,
          lydotratoa: formLyDoTraToa?.idld,
          noidungtratoa:
            formLyDoTraToa?.lydo === 'Khác' ? formLyDoTraToa?.lydokhac : formLyDoTraToa?.lydo,
          idloaitoa: 1,
        },
        tabLayThuoc,
        //xuatvacxin: true,
      }),
    )
    setIsModalTraToa(false)
  }

  const resetTraThuoc = () => {
    setCheck(false)
    setCheckTraThuoc(false)
    setIdLyDo(lyDo[0]?.idld ?? 7)
    setLyDoKhac(null)
  }
  // const handleOk = () => {
  //   if (idLyDo === 10 && lyDoKhac?.length === 0) {
  //     return
  //   }
  //   dispatch({
  //     type: TypeAction.DISPATCH_SERVICE_NHA_THUOC,
  //     payload: {
  //       ...service,
  //       DanhSachThuoc: cloneDeep(service?.DanhSachThuoc)?.map((item) => ({
  //         ...item,
  //         // tonkho: item?.tonkho + item?.soluong,
  //         soluong: item?.soluong,
  //         soluongcu: item?.soluongcu ?? item?.soluong,
  //       })),
  //     },
  //   })
  //   setCheckTraThuoc(true)
  //   setIsModal(false)
  // }
  // const handleTraHetThuoc = () => {
  //   dispatch({
  //     type: TypeAction.DISPATCH_SERVICE_NHA_THUOC,
  //     payload: {
  //       ...service,
  //       DanhSachThuoc: cloneDeep(service?.DanhSachThuoc)?.map((item) => ({
  //         ...item,
  //         tonkho: item?.tonkho + item?.soluong,
  //         soluong: 0,
  //       })),
  //     },
  //   })
  // }
  const handlePrintToaThuocNoiBo = async (infoBn, BNDetail) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT244',
      )
      const { data } = await https.get(
        `BenhNhanThuoc/GetDanhSachToaThuocLichSu?idbnttc=${BNDetail.idbnttc}`,
      )
      //gộp thuốc
      const toaThuocFormatGop = data.reduce((acc, item) => {
        const formattedItem = {
          ...item,
          tenBietDuoc: item.tenbietduoc,
          tenhoatchat: item.tenhoatchat,
          dvt: item.dvt,
          soLuong: item.soluongke,
          duongDung: item.duongdung,
          soLanNgay: item.solanngay,
          lieuDung: item.lieudung,
          ghiChu: item.ghichu ?? '',
        }
        const existingItem = acc.find((itemAcc) => itemAcc.idthuoc === formattedItem.idthuoc)
        if (existingItem) {
          existingItem.soLuong += formattedItem.soLuong
        } else {
          acc.push(formattedItem)
        }
        return acc
      }, [])

      generatePhieuToaThuocNoiBo(infoBn, BNDetail, toaThuocFormatGop, PkDangNhap)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  const getListLichSuTraToa = async () => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT243',
      )
      const { data } = await drugstoreService.getListLichSuTraToa(
        `idcn=${ID_BRANCH_LOGIN}`,
        1, //id loại toa (bán toa = 1 / xuất vắc xin = 4)
        dateForm,
        dateTo,
      )
      setListLichSuTraToa(data)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setIsLoading(false)
    }
  }
  const onChange = (value) => {
    setTabLayThuoc(value)
    if (value === 1) {
      dispatch(BNchoThuoc(ID_BRANCH_LOGIN))
    } else if (value === 2) {
      dispatch(BNDaLayThuoc(ID_BRANCH_LOGIN))
    } else if (value === 3) {
      dispatch(BNChoBSKeToaLai(ID_BRANCH_LOGIN))
    } else if (value === 4) {
      getListLichSuTraToa()
    }
  }
  const handleBNThuoc = (idbnttc, tienThuoc) => {
    dispatch(getDetailInfoBN(idbnttc, 4)) // idloaitt bán toa =4, xuất vắc xin =6
    setCheckDaTraHet({ idbnttc: idbnttc, tienThuoc: tienThuoc ?? null })
    resetTraThuoc()
  }
  const handleOpenTraThuoc = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT245',
      )

      setIsModalTraToa(true)
      setFormLyDoTraToa((prev) => ({
        ...prev,
        idld: lyDoTraToa[0]?.idld ?? null,
        lydo: lyDoTraToa[0]?.lydo ?? null,
      }))
    } catch (error) {
      setIsModalTraToa(false)
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  useEffect(() => {
    setTabLayThuoc(1)
    dispatch(BNchoThuoc(ID_BRANCH_LOGIN))
  }, [])
  useEffect(() => {
    if (BNThuoc?.length > 0) {
      setTabLayThuoc(BNThuoc[0]?.tab ?? 1)
    }
  }, [BNThuoc])
  return (
    <div>
      <Modal
        width={500}
        open={isModalTraToa}
        onCancel={() => setIsModalTraToa(false)}
        okText={'Lưu'}
        onOk={handleSubmitTraToa}
        cancelText="Huỷ bỏ"
        title={<p className="text-center">Trả toa cho bác sĩ kê lại</p>}
        //className="pb-3"
      >
        <div>
          Bạn xác nhận trả toa:
          <span className="font-semibold ml-1">
            {infoBN?.tenBenhNhan} - {infoBN?.maBenhNhan}
          </span>
        </div>
        <div className="mt-3 mb-1">Lý do trả toa:</div>
        <Radio.Group
          onChange={(e) =>
            setFormLyDoTraToa((prev) => {
              let infoLyDo = lyDoTraToa?.find((item) => item?.idld === e.target.value)
              return { ...prev, idld: infoLyDo?.idld ?? null, lydo: infoLyDo?.lydo ?? null }
            })
          }
          value={formLyDoTraToa?.idld}
        >
          <div className="flex flex-col gap-1">
            {lyDoTraToa?.map((item) => (
              <Radio key={`tratoabs${item.idld}`} value={item.idld}>
                {item.lydo}
              </Radio>
            ))}
          </div>
        </Radio.Group>
        {formLyDoTraToa?.lydo === 'Khác' && (
          <>
            <TextArea
              rows={3}
              placeholder="Nhập lý do khác..."
              maxLength={180}
              className="mt-2"
              value={formLyDoTraToa?.lydokhac}
              status={
                formLyDoTraToa?.lydo === 'Khác' && formLyDoTraToa?.lydokhac?.trim()?.length === 0
                  ? 'error'
                  : ''
              }
              onChange={(e) => setFormLyDoTraToa((prev) => ({ ...prev, lydokhac: e.target.value }))}
            />
          </>
        )}
      </Modal>

      {/* <Modal
        width={500}
        open={isModal}
        onCancel={() => setIsModal(false)}
        okText={'Lưu'}
        onOk={handleOk}
        cancelText="Huỷ bỏ"
        title={<p className="text-center">Lý do trả thuốc</p>}
        //className="pb-3"
      >
        <Radio.Group onChange={(e) => setIdLyDo(e.target.value)} value={idLyDo}>
          <div className="flex flex-col gap-3">
            {lyDo?.map((item) => (
              <Radio value={item.idld}>{item.lydo}</Radio>
            ))}
          </div>
        </Radio.Group>
        {idLyDo === 10 && (
          <>
            <TextArea
              rows={3}
              placeholder="Nhập lý do khác..."
              maxLength={120}
              className="mt-3"
              value={lyDoKhac}
              status={idLyDo === 10 && lyDoKhac?.trim()?.length === 0 ? 'error' : ''}
              onChange={(e) => setLyDoKhac(e.target.value)}
            />
          </>
        )}
      </Modal> */}
      <div className="h-2/3 border m-2 rounded">
        <div className="border-b p-2 flex justify-between">
          <h2 className="text-gray-600 text-lg font-semibold w-48 ">Đơn thuốc</h2>
          <div className="flex items-center gap-2">
            {service && BNDetail?.trangthaikhambenh === 2 && !checkBsDangKeToaLai && (
              <div className="flex">
                <Checkbox checked={check} onChange={handleCheckBox} disabled={checkBsDangKeToaLai}>
                  <p className="">Không lấy thuốc</p>
                </Checkbox>
              </div>
            )}
            {service &&
              !checkBsDangKeToaLai &&
              (!BNTT ||
                (BNTT &&
                  ((BNTT?.tienmat === BNTT?.tienthanhtoan &&
                    (BNTT?.idcalamviec === infoUser?.dangNhap?.idCaLamViec ||
                      infoUser?.idNhom === 1)) ||
                    infoUser?.idNhom === 28 ||
                    infoUser?.idNhom === 27))) && ( //&& BNDetail?.trangthaikhambenh === 4
                <Tooltip title="Trả toa cho bác sĩ kê lại" color="blue">
                  <Button
                    className="h-[1.75rem] p-2"
                    disabled={
                      infoUser?.idNhom !== 1 &&
                      infoUser?.idNhom !== 28 &&
                      infoUser?.idNhom !== 27 &&
                      BNTT &&
                      (BNTT?.idcalamviec !== infoUser?.dangNhap?.idCaLamViec ||
                        BNTT?.idthungan !== infoUser?.idnv)
                      // || service?.BENHNHANLAYTHUOC === 0
                    }
                    onClick={handleOpenTraThuoc}
                    icon={<RollbackOutlined style={{ fontSize: 16 }} />}
                  >
                    Trả toa
                  </Button>
                </Tooltip>
              )}
            {/* {checkTraThuoc && (
              <>
                <Tooltip title="Cập nhật tất cả thuốc số lượng 0" color="blue">
                  <Button type="link" className="mr-3" onClick={handleTraHetThuoc}>
                    Trả hết
                  </Button>
                </Tooltip>
              </>
                <>
                  <Tooltip title="Khách muốn trả lại thuốc" color="blue">
                    <Checkbox
                      className="mr-3 hidden" ///////////////////////////////////////////////////////////////////////////////////////////
                      disabled={
                        checkDaTraHet &&
                        checkDaTraHet?.idbnttc === BNDetail?.idbnttc &&
                        checkDaTraHet?.tienThuoc === 0
                      }
                      checked={checkTraThuoc || checkDaTraHet?.tienThuoc === 0}
                      onChange={(e) => {
                        setIsModal(e.target.checked)
                        resetTraThuoc()
                        if (!e.target.checked) {
                          handleBNThuoc(BNDetail?.idbnttc)
                        }
                      }}
                    >
                      {' '}
                      Trả thuốc
                    </Checkbox>
                  </Tooltip>
                </>
            )} */}
            {/* <Button
              onClick={() => navigate('/nhathuoc/bantoa/phieu')}
              type="link"
              ghost
              icon={<AssignmentIcon />}
            >
              Phiếu
            </Button> */}
            <Button
              size="small"
              disabled={!infoBN}
              onClick={() => handlePrintToaThuocNoiBo(infoBN, BNDetail)}
              type="primary"
              icon={<PrinterOutlined />}
            >
              Toa nội bộ
            </Button>
          </div>
        </div>
        <div className="">
          <TableMedicine
            setIdLyDo={setIdLyDo}
            lyDo={lyDo}
            idLyDo={idLyDo}
            check={check}
            checkTraThuoc={checkTraThuoc}
            setCheckTraThuoc={setCheckTraThuoc}
            lyDoKhac={lyDoKhac} // lý do không lấy thuốc trước khi thanh toán
            setLyDoKhac={setLyDoKhac} // lý do không lấy thuốc trước khi thanh toán
          />
        </div>
      </div>
      <div className="h-1/3 m-2 ">
        <div className="flex justify-between">
          <Segmented
            onChange={onChange}
            defaultValue={1}
            value={tabLayThuoc}
            options={[
              { label: 'Chờ lấy thuốc', value: 1 },
              { label: 'Đã lấy thuốc', value: 2 },
              { label: 'Chờ bác sĩ kê lại', value: 3 },
              { label: 'Lịch sử trả toa', value: 4 },
            ]}
          />
          {tabLayThuoc === 4 ? (
            <>
              <div className="flex items-center gap-1">
                <div className="w-[15rem] flex items-center gap-2">
                  <DatePicker
                    allowClear={false}
                    defaultValue={dayjs(now)}
                    maxDate={dayjs(now)}
                    onChange={handleDatePicker('form')}
                    format={dateFormat}
                    size="small"
                  />
                  <span className="font-semibold text-lg">-</span>
                  <DatePicker
                    allowClear={false}
                    defaultValue={dayjs(now)}
                    maxDate={dayjs(now)}
                    onChange={handleDatePicker('to')}
                    format={dateFormat}
                    size="small"
                  />
                </div>
                <IconButton
                  disabled={isLoading}
                  onClick={getListLichSuTraToa}
                  color="primary"
                  aria-label="delete"
                  size="small"
                >
                  <SyncIcon />
                </IconButton>
              </div>
            </>
          ) : (
            <>
              <div className="flex px-2 font-semibold">
                <label>TOA:</label>
                <p className="text-red-500 ml-1">{BNThuoc?.length ?? 0}</p>
              </div>
            </>
          )}
        </div>
        {tabLayThuoc === 4 ? (
          <>
            <div className="mt-2 rounded flex flex-col gap-2">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 3,
                    fontSize: 13,
                  },
                  components: {
                    Table: {
                      rowHoverBg: '#ecf0f1',
                      headerBg: '#e6e6e6',
                      footerBg: '#e6e6e6',
                      borderColor: '#BABABA',
                    },
                  },
                }}
              >
                <Table
                  loading={isLoading}
                  bordered
                  scroll={{
                    y: 174,
                  }}
                  dataSource={listLichSuTraToa}
                  columns={[
                    {
                      title: 'STT',
                      dataIndex: 'stt',
                      key: 'stt',
                      align: 'center',
                      width: 35,
                      render: (text, record, index) => ++index,
                    },
                    {
                      title: 'Mã BN',
                      dataIndex: 'mabenhnhan',
                      key: 'mabenhnhan',
                      width: 120,
                    },
                    {
                      title: 'Tên BN',
                      dataIndex: 'tenbenhnhan',
                      key: 'tenbenhnhan',
                      width: 250,
                    },
                    {
                      title: 'Ngày sinh',
                      dataIndex: 'ngaysinh',
                      key: 'ngaysinh',
                      align: 'center',
                      width: 80,
                      render: (text, record, index) =>
                        text ? moment(text).format('DD/MM/YYYY') : `-`,
                    },
                    {
                      title: 'Ngày trả toa',
                      dataIndex: 'ngaytra',
                      key: 'ngaytra',
                      align: 'center',
                      width: 130,
                      render: (text, record, index) =>
                        text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : `-`,
                    },
                    {
                      title: 'Lý do trả toa',
                      dataIndex: 'noidungtratoa',
                      key: 'noidungtratoa',
                    },
                  ]}
                  pagination={false} // Disable pagination
                />
              </ConfigProvider>
            </div>
          </>
        ) : (
          <>
            <ul className="border mt-2 p-2 h-52 rounded flex flex-col gap-2 overflow-auto">
              {BNThuoc?.map((items) => (
                <li
                  key={items?.idbnttc}
                  onClick={() => {
                    handleBNThuoc(items?.idbnttc, items?.tienThuoc)
                  }}
                  className="border p-1 cursor-pointer rounded hover:bg-sky-50 grid grid-cols-5 items-center min-h-10"
                >
                  <h2 className="col-span-3 font-semibold">
                    {items?.tenBenhNhan} - Mã BN: {items?.maBenhNhan}
                  </h2>
                  <p className="text-gray-500 ">
                    Sinh ngày: {items?.ngaySinh && moment(items?.ngaySinh).format('DD/MM/YYYY')}
                  </p>
                  <div className="flex justify-end items-center">
                    {
                      (tabLayThuoc === 1 && (
                        <Tag icon={<SyncOutlined spin />} color="processing">
                          Chờ lấy thuốc
                        </Tag>
                      )) ||
                        (tabLayThuoc === 2 && (
                          <Tag icon={<CheckCircleOutlined />} color="success">
                            Đã lấy thuốc
                          </Tag>
                        )) ||
                        (tabLayThuoc === 3 && (
                          <Tag icon={<PauseCircleOutlined />} color="warning">
                            Chờ bác sĩ kê lại
                          </Tag>
                        ))
                      // : items?.tienThuoc === 0 && items?.lyDoKoLayThuoc ? (
                      //   <Tag icon={<CloseCircleOutlined />} color="error">
                      //     Đã trả hết thuốc
                      //   </Tag>
                      // ) : items?.tienThuoc > 0 && items?.lyDoKoLayThuoc ? (
                      //   <Tag icon={<MinusCircleOutlined />} color="warning">
                      //     Đã trả vài thuốc
                      //   </Tag>
                      // )
                    }
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  )
}

export default ListSelling

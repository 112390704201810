import moment from 'moment'
import { call, put, takeLatest } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import { cardService } from '../../services/card/cardService'
import { traCuuService } from '../../services/traCuu/traCuuService'
import * as typeAction from '../constants/constants'
import 'moment/locale/vi'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* traCuuSaga() {
  // TÌM KIẾM BỆNH NHÂN
  yield takeLatest(typeAction.SEARCH_TRACUU_BN, function* searchBN({ type, keyWord, idct }) {
    try {
      const result = yield call(() => traCuuService.searchBN(keyWord, idct))
      yield put({
        type: typeAction.DISPATCH_TRACUU_LIST_BN,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })

  yield takeLatest(
    typeAction.UPDATE_BN_TRACUU,
    function* updateBNInfo({ type, form, onLoad, idChuyenKhoa, setLoading }) {
      try {
        yield call(() => traCuuService.updateBN(form))
        const result = yield call(() => traCuuService.getInfoBN_TT(form.idbn))
        const paymentCard = yield call(() => cardService.getInfoPaymentCard(form.idbn))
        const memberCard = yield call(() => cardService.getInfoMemberCard(form.idbn, idChuyenKhoa))
        yield put({
          type: typeAction.DISPATCH_TRACUU_INFO_BENH_NHAN,
          payload: {
            ...result.data,
            paymentCard: paymentCard.data[0],
            memberCard: memberCard.data,
          },
        })
        setLoading(true)
        onLoad()
        Toast.fire({
          icon: 'success',
          title: 'Sửa thông tin bệnh nhân thành công!',
        })
      } catch (err) {
        yield console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Sửa thông tin bệnh nhân thất bại!',
        })
      } finally {
        setLoading(false)
      }
    },
  )

  // GET INFO BỆNH NHÂN
  yield takeLatest(
    typeAction.GET_INFO_TRACUU_BENH_NHAN,
    function* searchBN({ type, idBN, idChuyenKhoa }) {
      try {
        const result = yield call(() => traCuuService.getInfoBN_TT(idBN))
        const paymentCard = yield call(() => cardService.getInfoPaymentCard(idBN))
        const memberCard = yield call(() => cardService.getInfoMemberCard(idBN, idChuyenKhoa))
        yield put({
          type: typeAction.DISPATCH_TRACUU_INFO_BENH_NHAN,
          payload: {
            ...result.data,
            paymentCard: paymentCard.data[0],
            memberCard: memberCard.data,
          },
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )

  yield takeLatest(
    typeAction.GET_INFO_TRACUU_BENH_NHAN_TT,
    function* searchBN_TT({ type, idBN, idChuyenKhoa }) {
      try {
        const result = yield call(() => traCuuService.getInfoBN_TT(idBN))
        const paymentCard = yield call(() => cardService.getInfoPaymentCard(idBN))
        const memberCard = yield call(() => cardService.getInfoMemberCard(idBN, idChuyenKhoa))
        yield put({
          type: typeAction.DISPATCH_TRACUU_INFO_BENH_NHAN,
          payload: {
            ...result.data,
            paymentCard: paymentCard.data[0],
            memberCard: memberCard.data,
          },
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )

  //GET ALL
  yield takeLatest(typeAction.GET_ALL_BN, function* getAll_BN({ type }) {
    try {
      const { data } = yield call(() => traCuuService.getAllBN())
      // console.log(data)
      yield put({
        type: typeAction.DISPATCH_TRACUU_LIST_BN,
        payload: data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })

  //GET BY ID CHINHANH ID CTY
  yield takeLatest(
    typeAction.GET_BN_CHINHANH_CTY,
    function* getBNByIdChiNhanhIdCty({ type, idct, idChinhanh, keyword, pageIndex, setIsLoading }) {
      try {
        setIsLoading(true)
        const { data } = yield call(() =>
          traCuuService.getByIdChinhanhIdct(idct, idChinhanh, keyword, pageIndex),
        )
        yield put({
          type: typeAction.DISPATCH_TRACUU_LIST_BN,
          payload: data,
        })
      } catch (err) {
        yield console.log(err)
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    },
  )

  //only mat
  yield takeLatest(
    typeAction.GET_BN_CHINHANH_CTY_MAT,
    function* getBNMatByIdct({ type, idct, keyword, pageIndex, setIsLoading }) {
      try {
        setIsLoading(true)
        const { data } = yield call(() => traCuuService.getBNMatByIdct(idct, keyword, pageIndex))
        yield put({
          type: typeAction.DISPATCH_TRACUU_LIST_BN,
          payload: data,
        })
      } catch (err) {
        yield console.log(err)
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    },
  )
}

import React from 'react'
import { Modal, Input, Image } from 'antd'
import { calculateAge } from '../../../../../utils/calculateAge'
import { Editor } from 'react-draft-wysiwyg'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const ModalHistory = ({ isModal, setisModal, isLoading, infoHistory, image, editorState }) => {
  return (
    <Modal
      loading={isLoading}
      width={1200}
      open={isModal}
      onCancel={() => setisModal(false)}
      //   okText={'Lưu'}
      //   onOk={vacXinEdit ? handleSubmitEdit : formik.handleSubmit}
      cancelText="Đóng"
      title={<p className="text-center">Xem chi tiết lịch sử</p>}
      //   className="flex flex-col gap-2"
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
        </>
      )}
    >
      <div className="flex mt-2">
        <div className="w-1/2">
          <div className="flex justify-start mb-2 gap-1">
            <label className="font-semibold w-40 text-end">Tên BN:</label>
            <Input value={infoHistory?.tenbenhnhan} size="small" variant="filled" readOnly />
          </div>
          <div className="flex justify-start mb-2 gap-1">
            <label className="font-semibold w-40 text-end">Mã BN:</label>
            <Input value={infoHistory?.mabenhnhan} size="small" variant="filled" readOnly />
          </div>
          <div className="flex justify-start mb-2 gap-1">
            <label className="font-semibold w-40 text-end">Ngày sinh:</label>
            <Input
              value={infoHistory?.ngaysinh && moment(infoHistory?.ngaysinh).format('DD/MM/YYYY')}
              size="small"
              variant="filled"
              readOnly
            />
          </div>
          <div className="flex justify-start mb-2 gap-1">
            <label className="font-semibold w-40 text-end">Giới tính:</label>
            <Input value={infoHistory?.gioitinh} size="small" variant="filled" readOnly />
          </div>
          <div className="flex justify-start mb-2 gap-1">
            <label className="font-semibold w-40 text-end">Tuổi:</label>
            <Input
              value={infoHistory?.ngaysinh ? calculateAge(infoHistory?.ngaysinh) : null}
              size="small"
              variant="filled"
              readOnly
            />
          </div>
          <div className="flex justify-start mb-2 gap-1">
            <label className="font-semibold w-40 text-end">Địa chỉ:</label>
            <Input value={infoHistory?.diachi} size="small" variant="filled" readOnly />
          </div>
        </div>
        <div className="w-1/2 pl-4">
          <div className="flex justify-start mb-2 gap-1">
            <label className="font-semibold w-36 text-end">Ngày N.Soi:</label>
            <Input
              value={
                infoHistory?.ngaysieuam &&
                moment(infoHistory?.ngaysieuam).format('DD/MM/YYYY HH:mm:ss')
              }
              size="small"
              variant="filled"
              readOnly
            />
          </div>
          <div className="flex justify-start mb-2 gap-1">
            <label className="font-semibold w-36 text-end">BS Chỉ định:</label>
            <Input value={infoHistory?.bschidinh} size="small" variant="filled" readOnly />
          </div>
          <div className="flex justify-start mb-2 gap-1">
            <label className="font-semibold w-36 text-end">Bác sĩ N.Soi:</label>
            <Input value={infoHistory?.bssieuam} size="small" variant="filled" readOnly />
          </div>
          <div className="flex justify-start mb-2 gap-1">
            <label className="font-semibold w-36 text-end">CĐ sơ bộ:</label>
            <Input value={infoHistory?.chandoansobo} size="small" variant="filled" readOnly />
          </div>
          <div className="flex justify-start mb-2 gap-1">
            <label className="font-semibold w-36 text-end">Loại N.Soi:</label>
            <Input value={infoHistory?.loaisieuam} size="small" variant="filled" readOnly />
          </div>
          <div className="flex justify-start mb-2 gap-1">
            <label className="font-semibold w-36 text-end">Para:</label>
            <Input value={infoHistory?.para} size="small" variant="filled" readOnly />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-2 mb-2">
        {image?.map((item) => (
          <div key={item?.id} className="border rounded-md w-[8rem] h-[8rem] overflow-hidden">
            <div className="overflow-hidden border-b">
              <Image src={item.url} />
            </div>
            <div className=" flex justify-around items-center"></div>
          </div>
        ))}
      </div>
      <div className="border m-2">
        <Editor
          editorState={editorState}
          toolbar={{
            options: ['inline', 'blockType', 'fontFamily', 'textAlign', 'colorPicker'],
            inline: {
              options: ['bold', 'italic'],
            },
            blockType: {
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
            },
            fontFamily: {
              options: ['Times New Roman'],
            },
          }}
          // toolbarClassName={style.toolbarClassName}
          // wrapperClassName={style.wrapperClassName}
          // editorClassName={style.editorClassName}
          // onEditorStateChange={onEditorStateChange}
        />
      </div>
      <div className="p-2">
        <h2 className="font-semibold">KẾT LUẬN</h2>
        <Input.TextArea
          style={{
            height: 120,
            resize: 'none',
            fontFamily: 'Time New Roman',
          }}
          className="font-bold text-[1rem]"
          name="KETLUAN"
          // onChange={formik.handleChange}
          value={infoHistory?.ketluan}
        />
      </div>
    </Modal>
  )
}

export default ModalHistory

import { Input } from 'antd'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { calculateAge } from '../../../../utils/calculateAge'
import 'moment/locale/vi'
moment.locale('vi')

const InfoBN = () => {
  const { infoBNSieuAm } = useSelector((state) => state.sieuAmReducer)
  return (
    <div>
      <h2 className="p-3 border-b text-blue-500 font-semibold">Thông tin hành chính</h2>
      <div className="flex gap-1 flex-col p-2">
        <div className="flex">
          <label className="font-semibold w-24">Tên BN:</label>
          <Input value={infoBNSieuAm?.tenbenhnhan} size="small" variant="filled" readOnly />
        </div>
        <div className="flex">
          <label className="font-semibold w-24">Mã BN:</label>
          <Input value={infoBNSieuAm?.mabenhnhan} size="small" variant="filled" readOnly />
        </div>
        <div className="flex gap-2">
          <div className="flex">
            <label className="font-semibold w-32">Ngày sinh:</label>
            <Input
              value={infoBNSieuAm?.ngaysinh && moment(infoBNSieuAm?.ngaysinh).format('DD/MM/YYYY')}
              size="small"
              variant="filled"
              readOnly
            />
          </div>
          <div className="flex">
            <label className="font-semibold w-32">Giới tính:</label>
            <Input size="small" value={infoBNSieuAm?.gioitinh} variant="filled" readOnly />
          </div>
        </div>
        <div className="flex">
          <label className="font-semibold w-24">Tuổi:</label>
          <Input
            size="small"
            value={infoBNSieuAm ? calculateAge(infoBNSieuAm?.ngaysinh) : null}
            variant="filled"
            readOnly
          />
        </div>
        <div className="flex">
          <label className="font-semibold w-24">Địa chỉ:</label>
          <Input value={infoBNSieuAm?.diachi} size="small" variant="filled" readOnly />
        </div>
      </div>
    </div>
  )
}

export default InfoBN

import { ContainerOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import CachedIcon from '@mui/icons-material/Cached'
import { Button, ConfigProvider, Input, Popconfirm, Space, Table, Tooltip, Select } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import LayoutApp from '../../../HOCs/LayoutApp'
import {
  deleteCĐHA_Action,
  listALlCĐHA_Action,
  searchDanhMucCĐHA_Action,
} from '../../../store/actions/CĐHAAction'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import { formatNumberVND } from '../../../utils/formatNumberVND'
import ModalAdd from './Modal/ModalAdd'
import ModalEdit from './Modal/ModalEdit'
import { debounce } from 'lodash'
import { https } from '../../../services/apiService'

const DmCDHA = () => {
  const dispatch = useDispatch()
  const { ListCĐHA } = useSelector((state) => state.CĐHAReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [searchText, setSearchText] = useState('')
  const [searchAll, setSearchAll] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [allChuyenKhoa, setAllChuyenKhoa] = useState([])
  const [chuyenKhoa, setChuyenKhoa] = useState('')
  const [searchChuyenKhoa, setSearchChuyenKhoa] = useState(1)
  const [allNhomCDHA, setAllNhomCDHA] = useState([])
  const [nhomCDHA, setNhomCDHA] = useState('')
  const [isModalOpenEdit, setIsModalOpenEdit] = useState({
    show: false,
    data: {},
  })

  const maQuyenXemDs = 'QHT370'
  const maQuyenXemCt = 'QHT371'
  const maQuyenThem = 'QHT369'
  const maQuyenXoa = 'QHT373'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        dispatch(
          searchDanhMucCĐHA_Action(
            searchAll?.length ? searchAll?.trim() : searchAll,
            company,
            nhomCDHA,
            chuyenKhoa,
          ),
        )
        break
      case maQuyenXemCt:
        setIsModalOpenEdit(infoNew)
        break
      case maQuyenThem:
        showModal()
        break
      case maQuyenXoa:
        handleDeleteById(infoNew?.idChiDinh)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXemCt:
        setIsModalOpenEdit({
          show: false,
          data: {},
        })
        break
      case maQuyenThem:
        handleCancel()
        break
      case maQuyenXoa:
        //
        break
      default:
        return false
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('ChanDoanHinhAnhNhom/GetAllNhomChanDoanHinhAnh')
        setAllNhomCDHA(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  const getAllChuyenKhoa = async () => {
    try {
      const { data } = await https.get('ChuyenKhoa/AllChuyenKhoa')
      setAllChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }

  const onChange = (value) => {
    setSearchChuyenKhoa(value)
    setChuyenKhoa(value)
  }

  const handleCompany = (value) => {
    setCompany(value)
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleReloadData = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }

  const handleDeleteById = (id) => {
    dispatch(deleteCĐHA_Action(id, handleReloadData))
  }

  const handleSearchAll = useCallback(
    debounce((keyword) => {
      setSearchAll(keyword)
    }, 300),
    [],
  )

  const handleNhomCDHA = (value) => {
    setNhomCDHA(value)
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    getAllChuyenKhoa()
  }, [])

  useEffect(() => {
    handleReloadData()
    // dispatch(searchDanhMucCĐHA_Action('', company, nhomCDHA, chuyenKhoa))
  }, [searchAll, company, nhomCDHA, chuyenKhoa])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      width: 80,
      align: 'center',
    },
    {
      title: 'Mã CĐHA',
      dataIndex: 'macdha',
      width: 200,
      align: 'left',
    },
    {
      title: 'Tên chẩn đoán hình ảnh',
      dataIndex: 'tenChiDinh',
      ...getColumnSearchProps('tenCDHA'),
    },
    {
      title: 'Đơn giá',
      dataIndex: 'donGia',
      width: 150,
      align: 'right',
      render: (text) => formatNumberVND(text),
    },
    {
      title: 'Đơn vị tính',
      dataIndex: 'dvt',
      width: 120,
      align: 'center',
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      width: 350,
      align: 'left',
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      width: 120,
      fixed: 'right',
    },
  ]

  return (
    <>
      <div className="p-5">
        <div className="flex w-full items-center justify-between gap-5 p-2 bg-[#f3f6f9] rounded-md">
          <div className="flex gap-3">
            <Select
              showSearch
              className="w-72"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              onChange={handleCompany}
              value={company}
              options={[
                ...(listCompany
                  ? listCompany.map((items) => ({
                      label: items.tenct,
                      value: items.idct,
                    }))
                  : []),
              ]}
            />
            {/* Chuyên khoa */}
            <Select
              showSearch
              className="w-60"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              // filterSort={(optionA, optionB) =>
              //   (optionA?.label ?? '')
              //     .toLowerCase()
              //     .localeCompare((optionB?.label ?? '').toLowerCase())
              // }
              onChange={onChange}
              value={chuyenKhoa}
              options={[
                {
                  label: 'Tất cả chuyên khoa',
                  value: '',
                },
                ...allChuyenKhoa.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
            />
            <Select
              showSearch
              className="w-60"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              // filterSort={(optionA, optionB) =>
              //   (optionA?.label ?? '')
              //     .toLowerCase()
              //     .localeCompare((optionB?.label ?? '').toLowerCase())
              // }
              onChange={handleNhomCDHA}
              value={nhomCDHA}
              options={[
                {
                  label: 'Tất cả các nhóm',
                  value: '',
                },
                ...allNhomCDHA
                  ?.map((items) => ({
                    label: items.tennhom,
                    value: items.idnhomcdha,
                  }))
                  ?.sort((a, b) => (a?.label ?? '').localeCompare(b?.label ?? '')),
              ]}
            />
            <Input.Search
              // value={searchAll}
              allowClear
              className="w-60"
              placeholder="Nhập từ khoá tìm kiếm"
              onChange={(e) => {
                handleSearchAll(e.target.value)
              }}
            />
            <Button
              className="flex items-center"
              type="primary"
              onClick={handleReloadData}
              ghost
              icon={<CachedIcon />}
            >
              Tải lại
            </Button>
          </div>
          <div className="flex gap-5 ">
            <Button
              //  onClick={showModal}
              onClick={() =>
                dispatch(putCheckQuyenThaoTacAction(maQuyenThem, null, actionIsTrue, actionIsFalse))
              }
              className="flex items-center"
              type="primary"
            >
              <PlusOutlined /> Tạo CĐHA
            </Button>
          </div>
        </div>

        <div className="mt-2">
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
                fontSize: 14,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <Table
              pagination={{
                defaultPageSize: 20,
              }}
              bordered
              columns={columns}
              dataSource={ListCĐHA?.map((item, index) => ({
                ...item,
                STT: ++index,
                action: (
                  <ul className="flex justify-around">
                    <li>
                      <Tooltip title="Xem chi tiết" color="#108ee9">
                        <ContainerOutlined
                          onClick={() =>
                            dispatch(
                              putCheckQuyenThaoTacAction(
                                maQuyenXemCt,
                                { show: true, data: item },
                                actionIsTrue,
                                actionIsFalse,
                              ),
                            )
                          }
                          className="text-xl text-[#108ee9]  cursor-pointer"
                        />
                      </Tooltip>
                    </li>
                    <li>
                      <Tooltip title="Xoá" color="red">
                        <Popconfirm
                          title="Xác nhận xóa CĐHA"
                          okText="Xóa"
                          cancelText="Hủy"
                          onConfirm={() =>
                            dispatch(
                              putCheckQuyenThaoTacAction(
                                maQuyenXoa,
                                { idChiDinh: item?.idChiDinh },
                                actionIsTrue,
                                actionIsFalse,
                              ),
                            )
                          }
                          icon={<DeleteOutlined style={{ color: 'red' }} />}
                        >
                          <DeleteOutlined className="text-xl text-red-500  cursor-pointer" />
                        </Popconfirm>
                      </Tooltip>
                    </li>
                  </ul>
                ),
              }))}
            />
          </ConfigProvider>
        </div>
        {isModalOpen && (
          <ModalAdd
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
            handleReloadData={handleReloadData}
          />
        )}
        {isModalOpenEdit && (
          <ModalEdit
            isModalOpenEdit={isModalOpenEdit}
            setIsModalOpenEdit={setIsModalOpenEdit}
            handleReloadData={handleReloadData}
          />
        )}
      </div>
    </>
  )
}

export default DmCDHA

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ConfigProvider, DatePicker, Input, Select, Table, TreeSelect } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import * as XLSX from 'xlsx'
import { branchService } from '../../../../services/branch/branchService'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { reportService } from '../../../../services/Report/reportService'
import { https } from '../../../../services/apiService'
import axios from 'axios'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { SearchOutlined } from '@ant-design/icons'
import { formattedNumber } from '../../../../utils/formattedNumber'
import {
  exportExcelformat,
  exportExcelformatSumCol,
} from '../../../../utils/export/exportExcelFormat'
import _ from 'lodash'
import dayjs from 'dayjs'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/vi_VN'
import 'moment/locale/vi'
import 'dayjs/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

function TabTheoTuan() {
  const dispatch = useDispatch()
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState(['all'])
  const [dataReport, setDataReport] = useState([])
  const [listChuyenKhoa, setListChuyenKhoa] = useState([])
  const [chuyenKhoa, setChuyenKhoa] = useState('all')
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  // const [dateForm, setDateForm] = useState(now.format(momentDate))
  // const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [dateEnd, setDateEnd] = useState(now.format(momentDate))
  // const [search, setSearch] = useState('')

  // const tongSTTT = dataReport?.reduce((tong, item) => (tong += item?.tongsotoatuantruoc), 0)
  // const tongST = dataReport?.reduce((tong, item) => (tong += item?.tongsotoa), 0)
  // const tongTLCLTongST = Math.round((((tongST - tongSTTT) * 100) / tongSTTT) * 10) / 10
  // const tongTCLTTT = dataReport?.reduce(
  //   (tong, item) => (tong += item?.tongsotoacolaythuoctuantruoc),
  //   0,
  // )
  // const tongTCLT = dataReport?.reduce((tong, item) => (tong += item?.tongsotoacolaythuoc), 0)
  // const tongTLCLTongTCLT = Math.round((((tongTCLT - tongTCLTTT) * 100) / tongTCLTTT) * 10) / 10

  const tinhThu2CuaNgayBatKy = (date) => {
    const inputDate = moment(date)
    const dayOfWeek = inputDate.day()
    const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1
    return inputDate.subtract(daysToSubtract, 'days').format('YYYY-MM-DD')
  }
  const fetchReport = async () => {
    try {
      setIsLoading(true)
      const { data } = await reportService.getBaoCaoDoanhThuThuocMotTuan(
        (value?.includes('all') || !value?.length ? branch : value)
          ?.map(
            (item) => `idcn=${value?.includes('all') || !value?.length ? item?.idChiNhanh : item}`,
          )
          ?.join('&')
          ?.toString(),
        tinhThu2CuaNgayBatKy(dateEnd), // dateStart thứ 2
        dateEnd,
      )
      setDataReport(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getListChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setListChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBranch = async (idct, idck) => {
    try {
      setIsLoading(true)
      const { data } = await branchService.getBranchNotVP(idct)
      if (!idck || idck === 'all') setBranch(data)
      else if (idck) setBranch(data?.filter((item) => item?.idChuyenKhoa === idck))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = () => {
    const formatData = dataReport?.map((item, index) => ({
      'STT ': ++index,
      'Tên chi nhánh': item?.tenchinhanh,
      'Tên viết tắt': item?.tenviettat,
      'Tổng số toa thuốc': item?.tongsotoa,
      'Số toa trung bình trong 1 ngày': item?.sotoatrungbinh,
      'Tổng toa không lấy thuốc': item?.tongsotoakhonglaythuoc,
      'Tổng toa có lấy thuốc': item?.tongsotoalaythuoc,
      'Giá trị trung bình toa': formattedNumber(item?.trungbinhtoa ?? 0), //item?.trungbinhtoa,
      'Tổng bán theo toa thuốc': formattedNumber(item?.toathuocnhi ?? 0), //item?.toathuocnhi,
      'Tồn theo giá nhập': formattedNumber(item?.tonggianhap ?? 0), //item?.tonggianhap,
      'Tồn theo giá bán': formattedNumber(item?.tonggiaban ?? 0), //item?.tonggiaban,
    }))
    const name = 'BÁO CÁO TỔNG THUỐC THEO TOA'
    if (formatData?.length)
      exportExcelformatSumCol(formatData, name, [
        'STT ', // in ra chữ tổng
        'Tổng số toa thuốc',
        'Số toa trung bình trong 1 ngày',
        'Tổng toa không lấy thuốc',
        'Tổng toa có lấy thuốc',
        'Giá trị trung bình toa',
        'Tổng bán theo toa thuốc',
        'Tồn theo giá nhập',
        'Tồn theo giá bán',
      ])
  }
  const onLoad = () => {
    // setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
    if (!value?.length) setValue(['all'])
    fetchReport()
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    getListChuyenKhoa()
    fetchBranch(id_company, chuyenKhoa)
  }, [])

  // useEffect(() => {
  //   ;(async () => {
  //     try {
  //       const { data } = await axios.get(
  //         'http://192.168.1.250:4343/api/CaLamViec/Mobile/GetDanhSachDangKy?idNhanVien=4783&deviceID=8491B6C1-8EFF-4D71-8A1C-720589C53D71',
  //       )

  //       console.log(data)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   })()
  // }, [])

  // useEffect(() => {
  //   if (branch?.length) fetchReport()
  // }, [branch])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Tên chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      fixed: 'left',
    },
    {
      title: 'Tên viết tắt',
      dataIndex: 'tenviettat',
      key: 'tenviettat',
      align: 'center',
      fixed: 'left',
      width: 90,
    },
    {
      title: 'Tổng số toa thuốc',
      dataIndex: 'tongsotoa',
      key: 'tongsotoa',
      align: 'center',
      width: 135,
    },
    {
      title: 'Số toa trung bình trong 1 ngày',
      dataIndex: 'sotoatrungbinh',
      key: 'sotoatrungbinh',
      align: 'center',
      width: 135,
    },
    {
      title: 'Tổng toa không lấy thuốc',
      dataIndex: 'tongsotoakhonglaythuoc',
      key: 'tongsotoakhonglaythuoc',
      align: 'center',
      width: 135,
    },
    {
      title: 'Tổng toa có lấy thuốc',
      dataIndex: 'tongsotoalaythuoc',
      key: 'tongsotoalaythuoc',
      align: 'center',
      width: 135,
    },
    {
      title: 'Giá trị trung bình toa',
      dataIndex: 'trungbinhtoa',
      key: 'trungbinhtoa',
      align: 'right',
      width: 135,
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: 'Tổng bán theo toa thuốc',
      dataIndex: 'toathuocnhi',
      key: 'toathuocnhi',
      align: 'right',
      width: 135,
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: 'Tồn theo giá nhập',
      dataIndex: 'tonggianhap',
      key: 'tonggianhap',
      align: 'right',
      width: 135,
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: 'Tồn theo giá bán',
      dataIndex: 'tonggiaban',
      key: 'tonggiaban',
      align: 'right',
      width: 135,
      render: (text) => formattedNumber(text ?? 0),
    },
  ]

  return (
    <>
      <div className="flex gap-2">
        <div className="w-[17rem]">
          <Select
            showSearch
            value={company}
            onChange={(value) => {
              setCompany(value)
              fetchBranch(value, chuyenKhoa)
              setValue('all')
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className="w-full"
          />
        </div>
        <div className="w-[17rem]">
          <Select
            showSearch
            value={chuyenKhoa}
            onChange={(value) => {
              setChuyenKhoa(value)
              fetchBranch(company, value)
              setValue('all')
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                label: 'Tất cả chuyên khoa',
                value: 'all',
              },
              ...listChuyenKhoa?.map((item) => ({
                label: item?.tenChuyenKhoa,
                value: item?.idChuyenKhoa,
              })),
            ]}
            className="w-full"
          />
        </div>
        <div className="w-[17rem] flex gap-2">
          <TreeSelect
            {...tProps}
            style={{ flexWrap: 'unset' }}
            className="w-full h-8 overflow-clip"
          />
        </div>
        <div className="w-[8rem] flex items-center gap-2">
          <DatePicker
            placeholder="Ngày cuối..."
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={(date, dateString) =>
              setDateEnd(moment(dateString, dateFormat).format(momentDate))
            }
            format={dateFormat}
            locale={{ ...locale, week: { start: 1 } }}
          />
        </div>
        {/* <div className="flex w-64">
          <Input
            value={search}
            placeholder="Nhập mã hàng, tên hàng..."
            onChange={(e) => setSearch(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </div> */}
        <div className="flex gap-2">
          <IconButton
            disabled={isLoading}
            onClick={onLoad}
            color="primary"
            aria-label="delete"
            size="small"
          >
            <SyncIcon />
          </IconButton>
        </div>
        <div className="font-semibold ">
          <Button
            disabled={isLoading || !dataReport?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <div className="mt-2">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              // x: 2000,
              y: '68.7vh',
            }}
            pagination={false}
            loading={isLoading}
            bordered
            // dataSource={dataReport.concat(dataReport)}
            dataSource={dataReport}
            columns={columns}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row className="text-center font-semibold">
                  <Table.Summary.Cell index={0} colSpan={3}>
                    Tổng
                  </Table.Summary.Cell>
                  {/* <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell> */}
                  <Table.Summary.Cell index={3}>
                    {dataReport?.reduce((tong, item) => (tong += item?.tongsotoa), 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    {dataReport?.reduce((tong, item) => (tong += item?.sotoatrungbinh), 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    {dataReport?.reduce((tong, item) => (tong += item?.tongsotoakhonglaythuoc), 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    {dataReport?.reduce((tong, item) => (tong += item?.tongsotoalaythuoc), 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} className="text-end">
                    {formattedNumber(
                      dataReport?.reduce((tong, item) => (tong += item?.trungbinhtoa), 0) ?? 0,
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} className="text-end">
                    {formattedNumber(
                      dataReport?.reduce((tong, item) => (tong += item?.toathuocnhi), 0) ?? 0,
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} className="text-end">
                    {formattedNumber(
                      dataReport?.reduce((tong, item) => (tong += item?.tonggianhap), 0) ?? 0,
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10}>
                    <p className="text-end pr-[1rem]">
                      {formattedNumber(
                        dataReport?.reduce((tong, item) => (tong += item?.tonggiaban), 0) ?? 0,
                      )}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default TabTheoTuan

import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { XuatVacXinService } from '../../services/XuatVacXin/XuatVacXinService'
import { phanQuyenService } from '../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../actions/phanQuyenAction'
import ToastCus from '../../utils/Toast'
import Swal from 'sweetalert2'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* xuatVacXinSaga() {
  // LẤY DANH SÁCH BN CHỜ LẤY THUỐC
  yield takeLatest(
    typeAction.GET_BN_CHO_LAY_THUOC_XVX,
    function* getBNChoXVXSaga({ type, idChiNhanh }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT271'),
        )

        const result = yield call(() => XuatVacXinService.getBNChoXVX(idChiNhanh))
        yield put({
          type: typeAction.DISPATCH_BN_LAY_THUOC_XVX,
          payload: result.data?.map((item) => ({ ...item, tab: 1 })),
        })
      } catch (error) {
        console.error('Error : ', error)
        yield put({
          type: typeAction.DISPATCH_BN_LAY_THUOC_XVX,
          payload: [],
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.RESET_STORE_DRUGSTORE,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // LẤY DANH SÁCH BN ĐÃ LẤY THUỐC
  yield takeLatest(
    typeAction.GET_BN_DA_LAY_THUOC_XVX,
    function* getBNDoneXVXSaga({ type, idChiNhanh }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT272'),
        )

        const result = yield call(() => XuatVacXinService.getBNDoneXVX(idChiNhanh))
        yield put({
          type: typeAction.DISPATCH_BN_LAY_THUOC_XVX,
          payload: result.data?.map((item) => ({ ...item, tab: 2 })),
        })
      } catch (error) {
        console.error('Error : ', error)
        yield put({
          type: typeAction.DISPATCH_BN_LAY_THUOC_XVX,
          payload: [],
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.RESET_STORE_DRUGSTORE,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  //------------------- LẤY DANH SÁCH PHIẾU THU BÁN VẮC XIN ---------//
  yield takeLatest(
    typeAction.GET_PHIEU_THU_VACXIN,
    function* getPhieuThuVacXinSaga({ type, filter }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT277'),
        )

        const { idcn, tuNgay, denNgay, keyword, idCa } = filter
        const result = yield call(() =>
          XuatVacXinService.getPhieuThuVacXin(idcn, tuNgay, denNgay, keyword, idCa),
        )
        yield put({
          type: typeAction.DISPATCH_PHIEU_THU_VACXIN,
          payload: result.data,
        })
      } catch (error) {
        console.error('Error : ', error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  yield takeLatest(
    typeAction.GET_BN_CHO_BS_KE_TOA_LAI_XVX,
    function* getBNChoBsKeToaLaiXVXSaga({ type, idChiNhanh }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT273'),
        )

        const result = yield call(() => XuatVacXinService.getBNChoBsKeToaLaiXVX(idChiNhanh))
        yield put({
          type: typeAction.DISPATCH_BN_LAY_THUOC_XVX,
          payload: result.data?.map((item) => ({ ...item, tab: 3 })),
        })
      } catch (error) {
        console.error('Error : ', error)
        yield put({
          type: typeAction.DISPATCH_BN_LAY_THUOC_XVX,
          payload: [],
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.RESET_STORE_DRUGSTORE,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}

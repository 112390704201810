import { ContainerOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { IconButton, Tooltip } from '@mui/material'
import {
  Button,
  ConfigProvider,
  DatePicker,
  Input,
  Modal,
  Pagination,
  Select,
  Table,
  Tabs,
} from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { https } from '../../../services/apiService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import {
  getAllDonHangByTrangThai,
  getInfoDonHang,
  updateTrangThaDatHang,
  updateTrangThaiListDatHang,
} from '../../../store/actions/vanPhongPhamAction'
import { trangThaiDatHang } from '../../../utils/trangThaiDatHang'
import ModalEdit from './Modal/ModalEdit'
import ThongKeMatHang from './ThongKeMatHang'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const formatMoment = 'YYYY-MM-DD'
const dateFormat = 'DD-MM-YYYY'
const { confirm } = Modal

function DuyetHang(props) {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listDatHang } = useSelector((state) => state.vanPhongPhamReducer)
  const dispatch = useDispatch()
  const [dateFrom, setDateFrom] = useState(now.format(formatMoment))
  const [dateTo, setDateTo] = useState(now.format(formatMoment))
  const [openEdit, setOpenEdit] = useState(false)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [chiNhanh, setChiNhanh] = useState('')
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [keyword, setKeyword] = useState('')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
    },
    {
      title: 'Chi nhánh đặt',
      dataIndex: 'chinhanhdat',
      key: 'chinhanhdat',
      width: 150,
    },
    {
      title: 'Bộ phận duyệt',
      dataIndex: 'chinhanhnhan',
      key: 'chinhanhnhan',
      width: 150,
    },
    {
      title: 'Tên đơn hàng',
      dataIndex: 'tendonhang',
      key: 'tendonhang',
      width: 200,
    },
    {
      title: 'Thời gian đặt hàng',
      dataIndex: 'thoigiandathang',
      key: 'thoigiandathang',
      width: 100,
      align: 'center',
    },
    {
      title: 'Người đặt hàng',
      dataIndex: 'nguoidat',
      key: 'nguoidat',
      width: 120,
    },
    // {
    //   title: 'Ngày đặt',
    //   dataIndex: 'ngaydat',
    //   key: 'ngaydat',
    //   width: 80,
    //   align: 'center',
    // },
    // {
    //   title: 'Người duyệt',
    //   dataIndex: 'nguoiduyet',
    //   key: 'nguoiduyet',
    //   width: 120,
    // },
    // {
    //   title: 'Ngày duyệt',
    //   dataIndex: 'ngayduyet',
    //   key: 'ngayduyet',
    //   width: 80,
    //   align: 'center',
    // },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 120,
    },
    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      width: 40,
      align: 'center',
      fixed: 'right',
    },
  ]

  const handleDatePicker = (name) => (Date, dateString) => {
    // format date
    const value = moment(dateString, dateFormat).format(formatMoment)
    name === 'from' ? setDateFrom(value) : setDateTo(value)
  }

  const handleShowModalDetail = (record) => {
    setOpenEdit(true)
    //call api get info
    //dispatch
    dispatch(getInfoDonHang(record.iddathang))
  }

  const getAllCN_By_idct = async (value) => {
    try {
      const { data } = await https.get(`ChiNhanh/GetChiNhanhByIDCT?idct=${value}`)
      setListChiNhanh(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCompany = (value) => {
    setCompany(value)
    setChiNhanh('')
    getAllCN_By_idct(value)
  }

  const handleChiNhanh = (value) => {
    setChiNhanh(value)
  }

  const onLoad = () => {
    dispatch(
      getAllDonHangByTrangThai(
        keyword,
        dateFrom,
        dateTo,
        1,
        trangThaiDatHang.duyetPhieuKSNB,
        company,
        chiNhanh,
      ),
    )
  }

  useEffect(() => {
    onLoad()
  }, [])

  useEffect(() => {
    dispatch(listAllCompanyAction())
    getAllCN_By_idct(company)
  }, [])

  const handleChangePage = (keyword, dateFrom, dateTo, company, chiNhanh, page) => {
    setCurrentPage(page)
    SetSearchParams({ page })
    dispatch(
      getAllDonHangByTrangThai(
        keyword,
        dateFrom,
        dateTo,
        page,
        trangThaiDatHang.duyetPhieuKSNB,
        company,
        chiNhanh,
      ),
    )
  }

  //setTotalPage
  useEffect(() => {
    if (listDatHang?.totalPages) setTotalPage(listDatHang?.totalPages)
  }, [listDatHang?.totalPages])

  const onSelectChange = (newSelectedRowKeys, record) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const handleConfirm = (selectedRowKeys) => {
    const formatData = selectedRowKeys.map((item) => ({
      iddathang: item,
      trangthai: trangThaiDatHang.vanChuyen,
      nguoiduyet: infoUser.idnv,
    }))
    dispatch(updateTrangThaiListDatHang(formatData, onLoad))
  }

  return (
    <Tabs
      defaultActiveKey="1"
      className="p-4"
      items={[
        {
          label: 'Kiểm duyệt',
          key: 1,
          children: (
            <div className="h-full w-full bg-[#EBEBEB] p-2 border rounded-sm ">
              <div className="flex gap-5 justify-between bg-white border-dotted border-b-0 p-4">
                <div className="flex gap-5 justify-between w-full">
                  <Select
                    showSearch
                    className="w-72"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    onChange={handleCompany}
                    value={company}
                    options={listCompany?.map((items) => ({
                      label: items.tenct,
                      value: items.idct,
                    }))}
                  />
                  <Select
                    showSearch
                    className="w-72"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    onChange={handleChiNhanh}
                    value={chiNhanh}
                    options={[
                      {
                        tenchinhanh: 'Tất cả',
                        idchinhanh: '',
                      },
                      ...listChiNhanh,
                    ].map((items) => ({
                      label: items.tenchinhanh,
                      value: items.idchinhanh,
                    }))}
                  />
                  <div className="flex items-center gap-3">
                    <DatePicker
                      allowClear={false}
                      defaultValue={dayjs(now)}
                      maxDate={dayjs(now)}
                      onChange={handleDatePicker('from')}
                      format={dateFormat}
                    />
                    <span className="font-semibold text-lg">-</span>
                    <DatePicker
                      allowClear={false}
                      defaultValue={dayjs(now)}
                      maxDate={dayjs(now)}
                      onChange={handleDatePicker('to')}
                      format={dateFormat}
                    />
                  </div>
                  <div className="w-64">
                    <Input
                      className="w-full"
                      placeholder="Nhập từ khóa..."
                      onChange={(e) => {
                        setKeyword(e.target.value)
                      }}
                      value={keyword}
                    />
                  </div>
                  <div className="flex gap-3 mr-auto">
                    <IconButton
                      color="primary"
                      aria-label="delete"
                      size="small"
                      onClick={() =>
                        dispatch(
                          getAllDonHangByTrangThai(
                            keyword,
                            dateFrom,
                            dateTo,
                            1,
                            trangThaiDatHang.duyetPhieuKSNB,
                            company,
                            chiNhanh,
                          ),
                        )
                      }
                    >
                      <SyncIcon />
                    </IconButton>
                  </div>
                  <div className="ml-auto">
                    <Button
                      disabled={selectedRowKeys.length === 0}
                      onClick={() => handleConfirm(selectedRowKeys)}
                      type="primary"
                    >
                      Duyệt hàng
                    </Button>
                  </div>
                </div>
              </div>
              <div className="bg-white p-2">
                <div>
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 5,
                      },
                      components: {
                        Table: {
                          rowHoverBg: '#ecf0f1',
                          headerBg: '#e6e6e6',
                          footerBg: '#e6e6e6',
                          borderColor: '#BABABA',
                        },
                      },
              
                    }}
                  >
                    <Table
                      bordered
                      scroll={{
                        y: 800,
                      }}
                      rowSelection={rowSelection}
                      pagination={false}
                      columns={columns}
                      dataSource={listDatHang?.data?.map((item, idx) => ({
                        ...item,
                        key: item.iddathang,
                        stt: (currentPage - 1) * 20 + (idx + 1),
                        thoigiandathang: item.thoigiandathang
                          ? moment(item.thoigiandathang).format('DD/MM/YYYY HH:mm')
                          : '',
                        ngayduyet: item.ngayduyet
                          ? moment(item.ngayduyet).format('DD/MM/YYYY')
                          : '',
                        ngaydat: item.ngaydat ? moment(item.ngaydat).format('DD/MM/YYYY') : '',
                        action: (
                          <>
                            <Tooltip title="Xem chi tiết" color="#108ee9" className="ml-4">
                              <ContainerOutlined
                                onClick={() => {
                                  handleShowModalDetail(item)
                                }}
                                className="text-xl text-[#108ee9]  cursor-pointer"
                              />
                            </Tooltip>
                          </>
                        ),
                      }))}
                    />
                  </ConfigProvider>
                  <div className="flex justify-end">
                    <Pagination
                      className="mt-5"
                      showSizeChanger={false}
                      current={+useSearch.get('page')}
                      simple
                      defaultCurrent={1}
                      pageSize={20}
                      total={20 * totalPage}
                      onChange={(page, pagesize) => {
                        handleChangePage(keyword, dateFrom, dateTo, company, chiNhanh, page)
                      }}
                    />
                  </div>
                </div>
              </div>
              {openEdit && (
                <ModalEdit openEdit={openEdit} setOpenEdit={setOpenEdit} onLoad={onLoad} />
              )}
            </div>
          ),
        },
        {
          label: 'Thống kê mặt hàng',
          key: 2,
          children: <ThongKeMatHang />,
        },
      ]}
    />
  )
}

DuyetHang.propTypes = {}

export default DuyetHang

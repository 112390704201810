import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import {
  DatePicker,
  Select,
  ConfigProvider,
  Table,
  Breadcrumb,
  TreeSelect,
  Progress,
  Button,
} from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import * as XLSX from 'xlsx'
import { FormOutlined, HomeOutlined, AuditOutlined } from '@ant-design/icons'
import IconButton from '@mui/material/IconButton'
import { Link, useNavigate } from 'react-router-dom'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { reportService } from '../../../../../services/Report/reportService'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import { branchService } from '../../../../../services/branch/branchService'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect
const CaKham = ({ ChuyenKhoa }) => {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [value, setValue] = useState([BranchLogin])
  const [data, setData] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const dispatch = useDispatch()
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const onLoad = () => {
    fetchReport()
  }
  //-------- export excel-----------//
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, 'Lợi nhuận chi tiết.xlsx')
  }

  // ------------ lấy danh sách report theo chi nhánh ------------//
  const fetchReport = async () => {
    setIsLoading(true)
    let idChiNhanhs = null
    if (value?.length > 0 && value[0] !== 'all') {
      // Giả sử `value` là mảng các ID. Chuyển đổi mảng thành chuỗi truy vấn
      idChiNhanhs = value?.map((id) => `&idChiNhanhs=${id}`).join('&')
      const { data } = await reportService.getTheodoiCaKham(
        company,
        idChuyenKhoa,
        idChiNhanhs,
        dateForm,
        dateTo,
      )
      setData(data)
    } else {
      const { data } = await reportService.getTheodoiCaKham(
        company,
        idChuyenKhoa,
        '',
        dateForm,
        dateTo,
      )
      setData(data)
    }
    setIsLoading(false)
  }
  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      key: '0-1',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
        key: `0-1-${index}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchReport()
    fetchBranchByChuyenKhoa('')
  }, [])
  return (
    <>
      <div className="flex gap-5 justify-between border-dotted border-b-0 py-2 ">
        <div className="flex gap-5">
          <div className="w-80">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranchByChuyenKhoa('')
                setIdChuyenKhoa('')
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-64 flex gap-1">
            <Select
              showSearch
              value={idChuyenKhoa}
              onChange={onChangeChuyenKhoa}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { label: 'Tất cả', value: '' },
                ...ChuyenKhoa.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
              className="w-80"
            />
          </div>
          <div className="w-64 flex gap-1">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="flex gap-3">
            <IconButton onClick={onLoad} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
          <div className="font-semibold ">
            <Button
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>

      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              x: 1500,
              y: '66vh',
            }}
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={data}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 40,
                align: 'center',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                width: 120,
                render: (text) => (text ? moment(text).format('DD/MM/YYYYY') : '-'),
              },
              {
                title: 'Chi nhánh',
                dataIndex: 'tenchinhanh',
                key: 'tenchinhanh',
                width: 250,
              },
              {
                title: 'Ca 1',
                key: 1,
                children: [
                  {
                    title: 'Số lượt',
                    key: 1.1,
                    dataIndex: 'luotkhamcA1',
                    width: 100,
                    align: 'center',
                  },
                  {
                    title: 'Chiếm %',
                    key: 1.2,
                    dataIndex: 'phantramcA1',
                    render: (text) => (
                      <Progress
                        percent={text}
                        percentPosition={{
                          align: 'center',
                          type: 'inner',
                        }}
                        size={[100, 20]}
                      />
                    ),
                  },
                ],
              },

              {
                title: 'Ca 2',
                key: 1,
                children: [
                  {
                    title: 'Số lượt',
                    key: 1.1,
                    dataIndex: 'luotkhamcA2',
                    width: 100,
                    align: 'center',
                  },
                  {
                    title: 'Chiếm %',
                    key: 1.2,
                    dataIndex: 'phantramcA2',
                    render: (text) => (
                      <Progress
                        percent={text}
                        percentPosition={{
                          align: 'center',
                          type: 'inner',
                        }}
                        size={[100, 20]}
                      />
                    ),
                  },
                ],
              },
              {
                title: 'Ca 3',
                key: 1,
                children: [
                  {
                    title: 'Số lượt',
                    key: 1.1,
                    dataIndex: 'luotkhamcA3',
                    width: 100,
                    align: 'center',
                  },
                  {
                    title: 'Chiếm %',
                    key: 1.2,
                    dataIndex: 'phantramcA3',
                    render: (text) => (
                      <Progress
                        percent={text}
                        percentPosition={{
                          align: 'center',
                          type: 'inner',
                        }}
                        size={[100, 20]}
                      />
                    ),
                  },
                ],
              },
              {
                title: 'Tổng lượt',
                dataIndex: 'tongluotkham',
                align: 'center',
                key: 'tenbn',
                width: 100,
              },
            ]}
            summary={(pageData) => {
              let ca1 = 0
              let ca2 = 0
              let ca3 = 0
              let total = 0
              pageData.forEach(({ luotkhamcA1, luotkhamcA2, luotkhamcA3, tongluotkham }) => {
                ca1 += luotkhamcA1
                ca2 += luotkhamcA2
                ca3 += luotkhamcA3
                total += tongluotkham
              })
              const cells = Array.from({ length: 10 }, (_, index) => (
                <Table.Summary.Cell key={index} align="center" index={index}>
                  <p className="font-semibold">
                    {index === 3
                      ? ca1
                      : index === 5
                        ? ca2
                        : index === 7
                          ? ca3
                          : index === 9
                            ? total
                            : null}
                  </p>
                </Table.Summary.Cell>
              ))
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>{cells}</Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default CaKham

import React, { useRef, useState } from 'react'
import { Button, notification, List, Image, Checkbox } from 'antd'
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'
import { isWithin24Hours } from '../../../../utils/algorithm'
import { infoBNSieuAm as getInfoBNSieuAm } from '../../../../store/actions/sieuAmAction'

const ImageTiemChung = ({ listImage, setListImage, activeItemId, getInfoBNTTC }) => {
  const { infoBN } = useSelector((state) => state.traCuuReducer)
  const fileInputRef = useRef(null)
  const [api, contextHolder] = notification.useNotification()

  const dispatch = useDispatch()

  const openNotificationWithIcon = () => {
    api.error({
      message: 'Thêm file tiêm chủng',
      description: 'Chỉ chấp nhận file hình ảnh hoặc pdf!',
    })
  }

  const reload = (activeItemId) => {
    getInfoBNTTC(activeItemId)
  }

  const updateNewImage = async (listImage) => {
    const formData = new FormData()
    const data = listImage.map((item) => item.file)
    data.forEach((file) => {
      formData.append('files', file)
    })
    try {
      await https.put(`BenhNhanTTC/UploadLinkHinhAnhTiemChung?idbnttc=${activeItemId}`, formData)
      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật file thành công!',
      })
      await reload(activeItemId)
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: error?.reponse?.data?.message || 'Cập nhật file thất bại!',
      })
    }
  }

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files)
    const allowedFileTypes = [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'application/pdf',
    ]
    const validFiles = files.filter((file) => allowedFileTypes.includes(file.type))
    if (validFiles.length < files.length) {
      openNotificationWithIcon()
    }
    const validFilesWithIdAndUrl = validFiles.map((file) => ({
      file,
      id: uuidv4(), // Tạo ID duy nhất với uuid
      url: URL.createObjectURL(file), // Tạo URL cho file
      checked: false,
    }))
    fileInputRef.current.value = ''
    updateNewImage(validFilesWithIdAndUrl)
  }
  const handleRemoveFile = async (item) => {
    try {
      await https.delete(
        `BenhNhanTTC/DeleteLinkHinhAnhTiemChung?idbnttc=${activeItemId}&linkHinhAnh=${item}`,
      )
      await reload(activeItemId)
      ToastCus.fire({
        icon: 'success',
        title: 'Xóa file thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: error?.reponse?.data?.message || 'Xóa file thất bại!',
      })
    }
  }
  const handleUpdateImage = () => {
    fileInputRef.current.click()
  }
  return (
    <div className=" h-full">
      {contextHolder}
      <div className="flex flex-wrap gap-2">
        {listImage?.map((item, idx) => (
          <div key={idx} className="border rounded-md overflow-hidden ">
            <div className="overflow-hidden w-[7rem] h-[5rem]">
              {item?.includes('.pdf') ? (
                <>
                  <a
                    // href="https://file.315healthcare.com/KQKham/KQKham_IVY00724000117.pdf"
                    href={item}
                    target="_blank"
                    className="w-full h-full flex justify-center items-center"
                  >
                    <img
                      src="https://www.svgrepo.com/download/144578/pdf.svg"
                      className="w-[5rem]"
                    />
                  </a>
                </>
              ) : (
                <>
                  <Image
                    src={item}
                    // src="https://cms.imgworlds.com/assets/a5366382-0c26-4726-9873-45d69d24f819.jpg?key=home-gallery"
                    // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmBWwjYER0gb_NLlL2GJrdd1uFyPs3aZWh2A&s"
                    // src="https://vsd.vn:9994/VSD_PORTAL//VsdLink/2023080918523499Anh-bia-mobile-2-Haedit.jpg"
                  />
                </>
              )}
            </div>
            <div className=" flex justify-around items-center border-t">
              <div>
                <DeleteOutlined
                  onClick={() => handleRemoveFile(item)}
                  style={{ fontSize: 18 }}
                  className="text-red-500 p-1 cursor-pointer hover:bg-red-500 hover:text-white bg-red-100 rounded-md"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-2">
        <input
          accept="image/*,.pdf"
          type="file"
          multiple
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Button onClick={handleUpdateImage} type="primary" ghost icon={<PaperClipOutlined />}>
          Thêm file
        </Button>
      </div>
    </div>
  )
}

export default ImageTiemChung

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import LayoutApp from '../../../HOCs/LayoutApp'
import Cards from './Card/Cards'
import ModalDeletePhieuThu from './Modal/ModalDeletePhieuThu'
import { Input, Modal, Select, notification } from 'antd'
import { recieveService } from '../../../services/receive/recieveService'
import ModalUpdateNhapKho from './Modal/ModalUpdateNhapKho'
import ModalUpdateThanhToan from './Modal/ModalUpdateThanhToan'
import ModalTTV from './Modal/ModalTTV'
import ModalTaiKham from './Modal/ModalTaiKham'
import ModalGopBN from './Modal/ModalGopBN'
import ModalEditTaiKham from './Modal/ModalEditTaiKham'
import ModalUpdateCaLamViecPhieuThu from './Modal/ModalUpdateCaLamViecPhieuThu'
import ModalUpdateLoaiKhamCuaPhieuThu from './Modal/ModalUpdateLoaiKhamCuaPhieuThu'
import ModalUpdateNguoiBanTTV from './Modal/ModalUpdateNguoiBanTTV'
import ModalDeLetePhieuKho from './Modal/ModalDeLetePhieuKho'

const Tool = () => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const [showDeletePT, setShowDeletePT] = useState(false)
  const [modalUpdateStatus, setUpdateSatatus] = useState(false)
  const [updateKho, setUpdateKho] = useState(false)
  const [openUpdatePay, setOpenUpdatePay] = useState(false)
  const [openTK, setOpenTK] = useState(false)
  const [Phieu, setPhieu] = useState(null)
  const [status, setStatus] = useState(null)
  const [openTTV, setOpenTTV] = useState(false)
  const [openGop, setOpenGop] = useState(false)
  const [openTaiKham, setTaiKham] = useState(false)
  const [openCapNhatCaLamViecPhieuThu, setOpenCapNhatCaLamViecPhieuThu] = useState(false)
  const [openCapNhatLoaiKhamPhieuThu, setOpenCapNhatLoaiKhamPhieuThu] = useState(false)
  const [openCapNhatNguoiBanTTV, setOpenCapNhatNguoiBanTTV] = useState(false)
  const [openPhieuKho, SetPhieuKho] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const data = [
    {
      title: 'Trạng thái khám bệnh',
      status: true,
      description: ' Chuyển trạng thái khám bệnh của bệnh nhân',
      type: 'status',
    },
    {
      title: 'Chỉnh thời gian thanh toán',
      status: true,
      description: 'Thay đổi ngày bán hàng thu ngân, dược',
      type: 'Payment',
    },
    {
      title: 'Chỉnh tái khám ',
      status: true,
      description: 'Chỉnh lại thời gian tái khám của bệnh nhân',
      type: 'TaiKham',
    },
    {
      title: 'Cập nhật lại thẻ thành viên',
      status: true,
      description: 'Cập nhật  thẻ thành viên theo bệnh nhân',
      type: 'TTV',
    },
    {
      title: 'Thời gian hiệu lực TTV',
      status: false,
      description: 'Cập nhật lại thời gian thẻ thành viên theop bệnh nhân',
    },
    {
      title: 'Xoá phiếu thu lễ tân',
      status: false,
      description: 'Xoá phiếu thu lễ tân ',
      type: 'delete',
    },
    // {
    //   title: 'Update trạng thái phiếu nhập',
    //   status: true,
    //   description: 'update lại trạng thái nhập kho hàng hoá',
    //   type: 'NhapKho',
    // },
    {
      title: 'Chương trình tái khám ',
      status: true,
      description: 'Ưu đãi giảm phí tái khám cho khách hàng đối với chi nhánh 1 năm khai trương',
      type: 'voucherTaiKham',
    },
    {
      title: 'Gộp hồ sơ bệnh án',
      status: true,
      description: 'Gộp 2 hồ sơ bệnh án trùng nhau ',
      type: 'grossBN',
    },
    {
      title: 'Cập nhật ca làm việc',
      status: true,
      description: 'Cập nhật ca làm việc của phiếu thu',
      type: 'capNhatCaLamViecPhieuThu',
    },
    {
      title: 'Cập nhật loại khám',
      status: true,
      description: 'Cập nhật loại khám của phiếu thu',
      type: 'capNhatLoaiKhamPhieuThu',
    },
    {
      title: 'Cập nhật người bán TTV',
      status: true,
      description: 'Cập nhật người bán của thẻ thành viên',
      type: 'capNhatNguoiBanTTV',
    },
  ]
  const openNotificationWithIcon = (type, description) => {
    api[type]({
      message: 'Lưu ',
      description,
    })
  }
  const handelModal = (type) => {
    if (type === 'status') {
      setUpdateSatatus(true)
    } else if (type === 'delete') {
      setShowDeletePT(true)
    } else if (type === 'NhapKho') {
      setUpdateKho(true)
    } else if (type === 'Payment') {
      setOpenUpdatePay(true)
    } else if (type === 'TTV') {
      setOpenTTV(true)
    } else if (type === 'voucherTaiKham') {
      setOpenTK(true)
    } else if (type === 'grossBN') {
      setOpenGop(true)
    } else if (type === 'TaiKham') {
      setTaiKham(true)
    } else if (type === 'capNhatCaLamViecPhieuThu') {
      setOpenCapNhatCaLamViecPhieuThu(true)
    } else if (type === 'capNhatLoaiKhamPhieuThu') {
      setOpenCapNhatLoaiKhamPhieuThu(true)
    } else if (type === 'capNhatNguoiBanTTV') {
      setOpenCapNhatNguoiBanTTV(true)
    } else if (type === 'PhieuKho') {
      SetPhieuKho(true)
    }
  }
  const upadateStatus = async () => {
    try {
      const { data } = await recieveService.updateStatusBN(Phieu, status)
      setPhieu(null)
      setStatus(status)
      openNotificationWithIcon('success', 'Lưu thành công')
      setStatus(false)
    } catch (error) {
      console.log(error)
      openNotificationWithIcon('error', 'Lưu Thất bại')
    }
  }
  return (
    <>
      {contextHolder}
      <>
        <div className="p-5 bg-[#EFEFEF] h-full">
          <ul className="grid grid-cols-5 gap-4">
            {data.map((props) => (
              <Cards props={props} handelModal={handelModal} />
            ))}
            {/* {[1, 28].includes(infoUser?.idNhom) && (
              <Cards
                props={{
                  title: 'Xoá phiếu Thu mua - kho',
                  status: true,
                  description: 'Xoá phiếu nhập và chuyển kho',
                  type: 'PhieuKho',
                }}
                handelModal={handelModal}
              />
            )} */}
          </ul>
        </div>
        <Modal
          onOk={upadateStatus}
          title="Cập nhật trạng thái khám bệnh"
          open={modalUpdateStatus}
          onCancel={() => setUpdateSatatus(false)}
        >
          <label className="font-semibold" htmlFor="">
            Nhập mã Phiếu thu{' '}
          </label>
          <Input
            value={Phieu}
            placeholder="Tên phiếu PT....."
            onChange={(e) => setPhieu(e.target.value)}
          />
          <Select
            value={status}
            onChange={(value) => setStatus(value)}
            className="w-full mt-2"
            placeholder="Chọn trạng thái"
            options={[
              {
                label: 'Tiếp nhận',
                value: 1,
              },
              {
                label: 'Hoàn thành',
                value: 4,
              },
            ]}
          />
          <span className="text-red-500 mt-2">
            * lưu ý: Chỉ trả về trang thái KHÁM BỆNH khi không kê toa
          </span>
        </Modal>
        <ModalUpdateThanhToan openUpdatePay={openUpdatePay} setOpenUpdatePay={setOpenUpdatePay} />
        <ModalUpdateNhapKho updateKho={updateKho} setUpdateKho={setUpdateKho} />
        <ModalDeletePhieuThu showDeletePT={showDeletePT} setShowDeletePT={setShowDeletePT} />
        <ModalTTV setOpenTTV={setOpenTTV} openTTV={openTTV} />
        <ModalTaiKham setOpenTK={setOpenTK} openTK={openTK} />
        <ModalGopBN openGop={openGop} setOpenGop={setOpenGop} />
        <ModalEditTaiKham openTaiKham={openTaiKham} setTaiKham={setTaiKham} />
        <ModalUpdateCaLamViecPhieuThu
          openCapNhatCaLamViecPhieuThu={openCapNhatCaLamViecPhieuThu}
          setOpenCapNhatCaLamViecPhieuThu={setOpenCapNhatCaLamViecPhieuThu}
        />
        <ModalUpdateLoaiKhamCuaPhieuThu
          openCapNhatLoaiKhamPhieuThu={openCapNhatLoaiKhamPhieuThu}
          setOpenCapNhatLoaiKhamPhieuThu={setOpenCapNhatLoaiKhamPhieuThu}
        />
        <ModalUpdateNguoiBanTTV
          openCapNhatNguoiBanTTV={openCapNhatNguoiBanTTV}
          setOpenCapNhatNguoiBanTTV={setOpenCapNhatNguoiBanTTV}
        />
        <ModalDeLetePhieuKho openPhieuKho={openPhieuKho} SetPhieuKho={SetPhieuKho} />
      </>
    </>
  )
}

export default Tool

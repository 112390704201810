import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  ListBNChoKham: null,
  // ListBNDangKham: null,
  ListBNDaKham: null,
  InfoBNDoKhucXa: null,
  listTrongKinh: null,
  listGongKinh: null,
  listTrongKinhBN: [],
  listGongKinhBN: [],
  InfoBNTrongVaGongKinh: null,
  listBNDV: null,
}

const doKhucXaReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_BN_CHO_DKX:
        draft.ListBNChoKham = payload
        break
      // case typeAction.DISPATCH_BN_DANG_KHAM_PHCN:
      //   draft.ListBNDangKham = payload
      //   break
      case typeAction.DISPATCH_BN_DA_DKX:
        draft.ListBNDaKham = payload
        break
      case typeAction.DISPATCH_INFO_BN_DKX:
        draft.InfoBNDoKhucXa = payload
        break
      case typeAction.DISPATCH_LIST_TRONG_KINH_DKX:
        draft.listTrongKinh = payload
        break
      case typeAction.DISPATCH_LIST_GONG_KINH_DKX:
        draft.listGongKinh = payload
        break
      case typeAction.DISPATCH_LIST_TRONG_KINH_BN_DKX:
        draft.listTrongKinhBN = payload
        break
      case typeAction.DISPATCH_LIST_GONG_KINH_BN_DKX:
        draft.listGongKinhBN = payload
        break
      case typeAction.DISPATCH_ADD_LIST_TRONG_KINH_BN_DKX:
        draft.listTrongKinhBN.push(payload)//dv tròng kính
        break
      case typeAction.DISPATCH_DEL_LIST_TRONG_KINH_BN_DKX:
        draft.listTrongKinhBN.splice(payload, 1)//index
        break
      case typeAction.DISPATCH_EDIT_DOITAC_TK_BN_DKX:
        draft.listTrongKinhBN[payload.index].doitacthuchien = payload.value
        draft.listTrongKinhBN[payload.index].iddttkcp = null
        draft.listTrongKinhBN[payload.index].tiencong = 0
        draft.listTrongKinhBN[payload.index].chiphivanchuyen = 0
        draft.listTrongKinhBN[payload.index].tienthanhtoan = draft.listTrongKinhBN[payload.index].dongia
        // draft.listTrongKinhBN[payload.index].tenct = ''
        // draft.listTrongKinhBN[payload.index].tengong = ''
        break
      case typeAction.DISPATCH_EDIT_LOAIGONG_TK_BN_DKX:
        draft.listTrongKinhBN[payload.index].iddttkcp = payload.value
        draft.listTrongKinhBN[payload.index].tiencong = payload.cong
        draft.listTrongKinhBN[payload.index].chiphivanchuyen = payload.vanchuyen
        draft.listTrongKinhBN[payload.index].tienthanhtoan = draft.listTrongKinhBN[payload.index].dongia //+ payload.cong + payload.vanchuyen
        // draft.listTrongKinhBN[payload.index].tenct = ' / ' + payload.chiphi.tenct
        // draft.listTrongKinhBN[payload.index].tengong = ' / ' + payload.chiphi.tengong
        break
      case typeAction.DISPATCH_EDIT_GHICHU_TK_BN_DKX:
        draft.listTrongKinhBN[payload.index].ghichu = payload.value
        break
      case typeAction.DISPATCH_ADD_LIST_GONG_KINH_BN_DKX:
        draft.listGongKinhBN.push(payload)
        break
      case typeAction.DISPATCH_EDIT_SOLUONG_GK_BN_DKX:
        draft.listGongKinhBN[payload.index].soLuong = payload.value
        draft.listGongKinhBN[payload.index].thanhToan = payload.value * draft.listGongKinhBN[payload.index].giaBan
        break
      case typeAction.DISPATCH_DEL_LIST_GONG_KINH_BN_DKX:
        draft.listGongKinhBN.splice(payload, 1)//index
        break
      case typeAction.DISPATCH_INFO_TRONG_VA_GONG_KINH_BN_DKX:
        draft.InfoBNTrongVaGongKinh = payload
        break
      case typeAction.DISPATCH_LIST_BN_DV_DKX:
        draft.listBNDV = payload
        break
      default:
        return state
    }
  })
}

export default doKhucXaReducer

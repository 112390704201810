import { https } from '../apiService'

export const CĐHAService = {
  // getListAllCĐHA: () => https.get('ChanDoanHinhAnh/GetAllChanDoanHinhAnh'),
  getListAllCĐHA: (idCT) =>
    https.get(`ChanDoanHinhAnh/GetAllChanDoanHinhAnh`, { params: { idCT } }),
  addCĐHA: (form) => https.post('ChanDoanHinhAnh/AddChanDoanHinhAnh', form),
  editCĐHA: (form) => https.put(`ChanDoanHinhAnh/UpdateChanDoanHinhAnh/${form.idChiDinh}`, form),
  deleteCĐHA: (id) => https.delete(`ChanDoanHinhAnh/DeleteChanDoanHinhAnh/${id}`),
  searchCĐHA: (tenCDHA, idCT, idnhomchha,idChuyenKhoa) =>
    https.get(`ChanDoanHinhAnh/SearchChuanDoanHinhAnh?tenCDHA=${tenCDHA}&idNhomCDHA=${idnhomchha}&idCT=${idCT}&idChuyenKhoa=${idChuyenKhoa}`), //idCT 
}

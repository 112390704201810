import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, Input, Pagination, Table, Tabs, Modal, Select } from 'antd'
import { https } from '../../../services/apiService'
import ToastCus from '../../../utils/Toast'
import TabTaiKhamKB from './TabTaiKhamKB'
import TabTaiKhamTC from './TabTaiKhamTC'
import TabHenTiemVX from './TabHenTiemVX'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal

function TaiKham() {
  const { infoUser } = useSelector((state) => state.userReducer)
  return (
    <div className="p-3 h-full bg-[#EFEFEF]">
      <div
        className="h-full p-2 bg-white border rounded-xl"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <Tabs
          defaultActiveKey={1}
          items={
            [
              {
                key: 1,
                value: 1,
                label: 'Tái khám',
                children: (
                  <>
                    <TabTaiKhamKB />
                  </>
                ),
              },
              {
                key: 2,
                value: 2,
                label: 'Tái khám tiêm chủng',
                children: (
                  <>
                    <TabTaiKhamTC />
                  </>
                ),
              },
              {
                key: 3,
                value: 3,
                label: 'Hẹn tiêm vắc xin',
                children: (
                  <>
                    <TabHenTiemVX />
                  </>
                ),
              },
            ]
            // infoUser?.idNhom === 28 || infoUser?.idNhom === 27
            //   ? 
            //   : [
            //       {
            //         key: 1,
            //         value: 1,
            //         label: 'Tái khám',
            //         children: (
            //           <>
            //             <TabTaiKhamKB />
            //           </>
            //         ),
            //       },
            //     ]
          }
        />
      </div>
    </div>
  )
}

export default TaiKham

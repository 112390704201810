import * as typeAction from '../constants/constants'

export const getAllSelectClinicAction = () => ({
  type: typeAction.GET_ALL_SELECT_CLINIC,
})

export const getQuanAction = (payload) => ({
  type: typeAction.GET_QUAN_API,
  payload,
})

export const getXaAction = (payload) => ({
  type: typeAction.GET_XA_API,
  payload,
})

export const groupsChiDinhKham_Action = (id) => ({
  type: typeAction.GET_GROUPS_CHI_DINH,
  id,
})
export const groupXetNghiem_Action = (id) => ({
  type: typeAction.GET_GROUPS_XET_NGHIEM_BY_BRANCH,
  id,
})
export const fetchListChiDinhKham_Action = (idGroups, idBranch) => ({
  type: typeAction.GET_CHI_DINH,
  idGroups,
  idBranch,
})

export const fetchListChiDinhXetNghiem_Action = (idGroups, idBranch) => ({
  type: typeAction.GET_XET_NGHIEM_RECIEVE,
  idGroups,
  idBranch,
})
export const fetchListChiDinhCĐHA_Action = (idGroups) => ({
  type: typeAction.GET_CHI_DINH_CĐHA_BY_GROUPS,
  idGroups,
})
export const createBN_Action = (form) => ({
  type: typeAction.CREATE_BENH_NHAN,
  form,
})
export const searchBN_Action = (keyWord, idct) => ({
  type: typeAction.SEARCH_BN,
  keyWord,
  idct,
})
export const getInfoBN_Action = (idBN, idChuyenKhoa, formik) => ({
  type: typeAction.GET_INFO_BENH_NHAN,
  idBN,
  idChuyenKhoa,
  formik,
})
export const getListGroupCĐHA_Action = () => ({
  type: typeAction.GET_GROUPS_CĐHA,
})
export const listPKByChiNhanh_Action = (id) => ({
  type: typeAction.GET_PK_BY_CHI_NHANH,
  id,
})
export const BNPendingByBranch = (status, idBranch) => ({
  type: typeAction.GET_BN_PEDING,
  status,
  idBranch,
})
export const addNewBNTTC_Action = (
  form,
  ArrXN,
  ArrCĐHA,
  ArrKB,
  formPay,
  formPayDetail,
  paymentCard,
  isPrint,
  maVoucher,
  maVoucherDoiTac,
) => ({
  type: typeAction.ADD_BN_KHAM_BENH,
  form,
  ArrXN,
  ArrCĐHA,
  ArrKB,
  formPay,
  formPayDetail,
  paymentCard,
  isPrint,
  maVoucher,
  maVoucherDoiTac,
})
export const getListDiscount = (listDV) => ({
  type: typeAction.GET_LIST_DISCOUNT,
  listDV,
})
export const removeListDiscount = (listDV) => ({
  type: typeAction.REMOVE_LIST_DISCOUNT,
  listDV,
})
export const postApplyMemberCardAction = (form) => ({
  type: typeAction.POST_APPLY_MEMBER_CARD,
  form,
})
export const setChuongTrinhGiamGiaDoiTac = (form) => ({
  type: typeAction.SET_CHUONG_TRINH_GIAM_GIA_DOI_TAC,
  form,
})
export const postListDVApplyTTV_Action = (list) => ({
  type: typeAction.POST_DV_MEMBER_CARD,
  list,
})
export const getListXetNghiemTiepNhanAction = (idBranch) => ({
  type: typeAction.GET_LIST_CD_XN_TIEPNHAN,
  idBranch,
})
export const getListCDHATiepNhanAction = (idct) => ({
  type: typeAction.GET_LIST_CD_CDHA_TIEPNHAN,
  idct,
})

export const applyVoucherAction = (ArrKB, temp, form) => ({
  type: typeAction.GET_APPLY_VOUCHER_TIEPNHAN,
  ArrKB,
  temp,
  form,
})
export const getInfoNguoiThan = (idbn, nguoiThan, formik) => ({
  type: typeAction.GET_INFO_NGUOI_NHA_BN,
  idbn,
  nguoiThan,
  formik,
})

import { call, put, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { cardService } from '../../services/card/cardService'
import { NhapKhoService } from '../../services/NhapKho/NhapKhoService'
import { partnerServices } from '../../services/partner/partnerServices'
import _ from 'lodash'
import ToastCus from '../../utils/Toast'

// Common function to fetch partner list and update store
function* fetchAndUpdatePartnerList() {
  try {
    yield put({ type: typeAction.OPEN_LOADING_PAGE })
    const result = yield call(() => NhapKhoService.getListDoiTac())
    const newData = _.orderBy(result.data,'maDoiTac','desc')
    yield put({
      type: typeAction.DISTPATCH_LIST_PARTNER,
      payload:newData,
    })
  } catch (error) {
    console.log(error)
    ToastCus.fire({
      icon: 'error',
      title: 'Lấy dữ liệu thất bại',
    })
  } finally {
    yield put({ type: typeAction.CLOSE_LOADING_PAGE })
  }
}

// Sagas
export function* partnerSaga() {
  yield takeLatest(typeAction.GET_LIST_PARTNER, function* listCardMemberShips({ type }) {
    yield fetchAndUpdatePartnerList()
  })

  yield takeLatest(
    typeAction.ADD_PARTNER,
    function* addNewPartner({ type, form, handleCloseModal }) {
      try {
        yield call(() => partnerServices.postPartner(form))
        yield call(() => handleCloseModal())
        yield fetchAndUpdatePartnerList()
        ToastCus.fire({
          icon: 'success',
          title: 'Thêm đối tác thành công',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thêm đối tác thất bại',
        })
      }
    },
  )

  yield takeLatest(
    typeAction.EDIT_PARTNER,
    function* editPartner({ type, form, handleCloseModal }) {
      try {
        yield call(() => partnerServices.editPartner(form))
        yield call(() => handleCloseModal())
        yield fetchAndUpdatePartnerList()
        ToastCus.fire({
          icon: 'success',
          title: 'Sửa thông tin đối tác thành công',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Sửa thông tin đối tác thất bại',
        })
      }
    },
  )

  yield takeLatest(typeAction.DELETE_PARTNER, function* deletePartner({ type, id }) {
    try {
      yield call(() => partnerServices.deletePartner(id))
      yield fetchAndUpdatePartnerList()
      ToastCus.fire({
        icon: 'success',
        title: 'Xóa đối tác thành công',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Xóa đối tác thất bại',
      })
    }
  })

  yield takeLatest(typeAction.SEARCH_PARTNER, function* searchKeyword({ type, keyword }) {
    yield put({ type: typeAction.OPEN_LOADING_PAGE })
    try {
      const result = yield call(() => partnerServices.searchPartnerByKeyword(keyword))
      yield put({
        type: typeAction.DISTPATCH_LIST_PARTNER,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })
}

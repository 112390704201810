// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_minWidthColumn__ERD2\\+ {
    min-width: 90px;
  }`, "",{"version":3,"sources":["webpack://./src/components/layout/ChildrenClinic/General/Tab/HistoryTongQuat/style.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;EACjB","sourcesContent":[".minWidthColumn {\n    min-width: 90px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"minWidthColumn": `style_minWidthColumn__ERD2+`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react'
import { Select, Tabs, List, Divider } from 'antd'
import CampaignIcon from '@mui/icons-material/Campaign'
import IconButton from '@mui/material/IconButton'
import BNdone from './BNdone/BNdone'
import CachedIcon from '@mui/icons-material/Cached'
// import BNCLS from './BNCLS/BNCLS'
import { useDispatch, useSelector } from 'react-redux'
import { BNDetailInfo } from '../../../../store/actions/childrenClinicAction'
import {
  getBNChoDoKhucXaAction,
  getInfoBNDoKhucXaAction,
} from '../../../../store/actions/doKhucXaAction'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const Tablist = () => {
  const location = useLocation()
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  //const [tenPhongKham, setTenPhongKham] = useState('CN không có chuyên khoa này')
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const { phongKham } = useSelector((state) => state.receiveReducer)
  const { DetailBN } = useSelector((state) => state.childrenClinicReducer)
  const { ListBNChoKham } = useSelector((state) => state.doKhucXaReducer)
  const dispatch = useDispatch()
  const checkChuyenKhoa = PkDangNhap?.idChuyenKhoa !== 4 // nếu khác thì ===> TRUE

  const handleDetailInfoBN = (idbn, idbnttc) => {
    dispatch(getInfoBNDoKhucXaAction(idbnttc))
    dispatch(BNDetailInfo(idbn, idbnttc))
  }
  const onLoad = async () => {
    // if (!checkChuyenKhoa)

    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT123',
      )

      dispatch(getBNChoDoKhucXaAction(PkDangNhap?.idChiNhanh ?? 0))
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  useEffect(() => {
    onLoad()
  }, [DetailBN])
  useEffect(() => {
    onLoad()
  }, [])
  // useEffect(() => {
  //   if (PkDangNhap?.idChuyenKhoa === 3 && location?.pathname === '/phongkham/phcn') setTenPhongKham('Phòng khám PHCN')
  // }, [ListBNChoKham])
  return (
    <Tabs
      className="px-2 h-full overflow-auto"
      defaultActiveKey="1"
      items={[
        {
          label: 'Chờ khám',
          key: '1',
          children: (
            <>
              <div className="h-full">
                <div>
                  <div className="flex items-center justify-between">
                    <Select
                      value={checkChuyenKhoa ? 'Không có phòng khám' : 'Phòng đo khúc xạ'}
                      // options={checkCK === true ? [{
                      //   label: 'Phòng khám PHCN',
                      //   value: 1,
                      // }] : [{
                      //   label: 'CN không có chuyên khoa này',
                      //   value: -1,
                      // }]}
                      className="w-5/6"
                    />
                    <IconButton
                      disabled={checkChuyenKhoa}
                      onClick={onLoad}
                      className="w-1/6"
                      color="primary"
                      aria-label="add to shopping cart"
                    >
                      <CachedIcon />
                    </IconButton>
                  </div>
                  <div>
                    <h2 className="font-semibold">
                      BS Thực hiện: <span className="font-normal">{infoUser?.tenNV}</span>{' '}
                    </h2>
                  </div>
                </div>
                <Divider className="my-2" />
                <ul className=" flex flex-col gap-2 mt-2 rounded-md h-[680px] overflow-auto ">
                  {ListBNChoKham?.map((items) => (
                    <li
                      key={items.idbnttc}
                      onClick={() => {
                        handleDetailInfoBN(items.idbn, items.idbnttc)
                      }}
                      className="flex items-center border py-2 rounded-md hover:bg-sky-100 hover:cursor-pointer hover:-translate-y-1 duration-200"
                    >
                      {' '}
                      <p className="w-1/6 text-center font-semibold text-lg">{items.sttkham}</p>
                      <div className="w-4/6 flex flex-col">
                        <h2 className="font-semibold">{items.tenbenhnhan}</h2>
                        <p className="text-gray-400">
                          Ngày sinh: {items.ngaysinh && moment(items.ngaysinh).format('DD/MM/YYYY')}
                        </p>
                      </div>
                      <IconButton
                        className="w-1/6 flex justify-center bg-red-500"
                        color="primary"
                        aria-label="add to shopping cart"
                      >
                        <CampaignIcon />
                      </IconButton>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          ),
        },
        {
          label: 'Đã khám',
          key: '2',
          children: <BNdone />,
        },
        // {
        //   label: "Cận lâm sàng",
        //   key: "3",
        //   children: <BNCLS />,
        // },
      ]}
    />
  )
}

export default Tablist

import { call, put, select, takeLatest } from 'redux-saga/effects'
import { drugstoreService } from '../../services/drugstore/drugstoreService'
import { recieveService } from '../../services/receive/recieveService'
import { cardService } from '../../services/card/cardService'
import { childrenClinicService } from '../../services/childrenClinic/childrenClinicService'
import { BNDaLayThuoc, BNchoThuoc, BNChoBSKeToaLai } from '../actions/drugstoreAction'
import { generatePhieuBanLeToaThuoc } from '../../utils/report/phieuThuToaBanLe'
import { generatePhieuThuTienToaThuoc } from '../../utils/report/phieuThuTienToaThuoc'
import { generatePhieuXuatKhoVacxin } from '../../utils/report/phieuXuatKhoVacxin'
import { generatePhieuThuTienXuatVacxin } from '../../utils/report/phieuThuTienXuatVacxin'
import {
  BNChoXVXAction,
  BNDaXVXAction,
  getBNChoBsKeToaLaiXVXAction,
} from '../actions/xuatVacXinAction'
import { phanQuyenService } from '../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../actions/phanQuyenAction'
import ToastCus from '../../utils/Toast'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* drugstoreSaga() {
  // LẤY DANH SÁCH BN CHỜ LẤY THUỐC
  yield takeLatest(typeAction.GET_BN_CHO_LAY_THUOC, function* BNPending({ type, idChiNhanh }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const { infoUser } = yield select((state) => state.userReducer)
      const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
      yield call(() =>
        phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT240'),
      )

      const result = yield call(() => drugstoreService.getBNCho(idChiNhanh))
      yield put({
        type: typeAction.DISPATCH_BN_LAY_THUOC,
        payload: result.data?.map((item) => ({ ...item, tab: 1 })),
      })
    } catch (error) {
      console.error('Error : ', error)
      yield put({
        type: typeAction.DISPATCH_BN_LAY_THUOC,
        payload: [],
      })
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      yield put({
        type: typeAction.RESET_STORE_DRUGSTORE,
      })
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  // LẤY DANH SÁCH BN ĐÃ LẤY THUỐC
  yield takeLatest(typeAction.GET_BN_DA_LAY_THUOC, function* BNDone({ type, idChiNhanh }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const { infoUser } = yield select((state) => state.userReducer)
      const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
      yield call(() =>
        phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT241'),
      )

      const result = yield call(() => drugstoreService.getBNDone(idChiNhanh))
      yield put({
        type: typeAction.DISPATCH_BN_LAY_THUOC,
        payload: result.data?.map((item) => ({ ...item, tab: 2 })),
      })
    } catch (error) {
      console.error('Error : ', error)
      yield put({
        type: typeAction.DISPATCH_BN_LAY_THUOC,
        payload: [],
      })
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      yield put({
        type: typeAction.RESET_STORE_DRUGSTORE,
      })
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  //------------------- LẤY DANH SÁCH PHIẾU THU BÁN TOA ---------//
  yield takeLatest(
    typeAction.GET_PHIEU_THU_THUOC,
    function* PTThuoc({ type, filter, setIsLoading }) {
      try {
        setIsLoading(true)
        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT247'),
        )

        let idChiNhanhs = null
        let idCas = null
        let data = []
        const { idChuyenKhoa, idcn, idCa, tuNgay, denNgay, keyword, idct } = filter
        if (idcn?.length > 0 && idcn[0] !== 'all') {
          idChiNhanhs = idcn?.map((id) => `&idcn=${id}`).join('&')
          idCas = idCa?.map((id) => `&idCa=${id}`).join('')
          const result = yield call(() =>
            drugstoreService.getPhieuThu(
              idct,
              idChuyenKhoa,
              idChiNhanhs,
              tuNgay,
              denNgay,
              idCas,
              keyword,
            ),
          )
          data = result.data
        } else {
          idCas = idCa?.map((id) => `&idCa=${id}`).join('')
          const result = yield call(() =>
            drugstoreService.getPhieuThu(idct, idChuyenKhoa, '', tuNgay, denNgay, idCas, keyword),
          )
          data = result.data
        }
        setIsLoading(false)
        yield put({
          type: typeAction.DISPATCH_PHIEU_THU_THUOC,
          payload: data,
        })
      } catch (error) {
        setIsLoading(false)
        console.error('Error : ', error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      } finally {
        setIsLoading(false)
      }
    },
  )
  //------------------- LẤY DANH SÁCH HÀNH HOÁ BÁN ---------//
  yield takeLatest(
    typeAction.GET_DANH_SACH_THUOC_BAN,
    function* getThuocBan({ type, filter, setIsLoading, setData }) {
      try {
        setIsLoading(true)
        let idChiNhanhs = null
        let data = []
        const { idloaitt, idcalamviec, idcn, tuNgay, denNgay, keyword, idct } = filter

        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(
            infoUser?.taiKhoan ?? userInfo?.taiKhoan,
            (idloaitt === 4 && 'QHT257') || // bán toa
              (idloaitt === 5 && 'QHT269') || // bán lẻ
              (idloaitt === 6 && 'QHT285'), // xuất vắc xin
          ),
        )
        if (idcn?.length > 0 && idcn[0] !== 'all') {
          idChiNhanhs = idcn?.map((id) => `&idcn=${id}`).join('&')
          const result = yield call(() =>
            drugstoreService.getThuocBan(
              idct,
              idChiNhanhs,
              idcalamviec,
              idloaitt,
              tuNgay,
              denNgay,
              keyword,
            ),
          )
          data = result.data
        } else {
          const result = yield call(() =>
            drugstoreService.getThuocBan(idct, '', idcalamviec, idloaitt, tuNgay, denNgay, keyword),
          )
          data = result.data
        }
        setData(data)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error('Error : ', error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      } finally {
        setIsLoading(false)
      }
    },
  )
  //------------------- LẤY DANH SÁCH THUỐC TRẢ BÁN TOA ---------//
  yield takeLatest(typeAction.GET_LIST_THUOC_TRA, function* getListThuocTraSaga({ type, filter }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const { idcn, tuNgay, denNgay, keyword } = filter
      const result = yield call(() =>
        drugstoreService.getListThuocTra(idcn, tuNgay, denNgay, keyword),
      )
      yield put({
        type: typeAction.DISPATCH_LIST_THUOC_TRA,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  //---------------- POST THUỐC BỆNH NHÂN -------------------//
  yield takeLatest(
    typeAction.POST_BN_THUOC,
    function* AddThuoc({ type, fromPay, formPayDetail, data, idChiNhanh, resetFormData }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const dataVC = yield select((state) => state.drugstoreReducer)
        const dataUser = yield select((state) => state.userReducer)
        const { infoVC } = dataVC
        const { infoUser } = dataUser
        const drug = yield call(() => drugstoreService.updateThuoc(data))
        const result = yield call(() => recieveService.postBNPay(fromPay))
        formPayDetail.idbntt = result.data.idbntt
        yield call(() => recieveService.postDetailPay(formPayDetail))
        // console.log(data);
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK
        // -------- update lại trạng thái BN thông tin chung -----------//
        const bnttc = yield call(() =>
          childrenClinicService.updateDetailBN({
            idbnttc: formPayDetail.idbnttchung,
            trangthaikhambenh:
              formPayDetail?.xuatvacxin === true && data[0]?.benhNhanLayTHuoc === 1 ? 5 : 4, // trại thái đã hoàn thành khám bệnh (nếu mà xuất vắc xin thì trạng thái khám bệnh bnttc là 5 tiêm chủng vào phong tiêm)
          }),
        )
        if (infoVC && infoVC?.listMaVc?.length > 0 && fromPay?.maVoucher?.length > 0) {
          yield call(() => cardService.putSuDungVoucher(infoVC?.listMaVc, infoUser?.tenNV))
        }
        resetFormData()
        if (formPayDetail?.xuatvacxin === true) {
          yield put(BNChoXVXAction(idChiNhanh))
          // yield put(BNDaXVXAction(idChiNhanh))
          // console.log(result.data, bnttc.data, drug.data, PkDangNhap)
          yield call(() => {
            generatePhieuXuatKhoVacxin(result.data, bnttc.data, drug.data, PkDangNhap)
            generatePhieuThuTienXuatVacxin(result.data, bnttc.data, drug.data, PkDangNhap)
          })
        } else {
          yield put(BNchoThuoc(idChiNhanh))
          yield call(() =>
            generatePhieuThuTienToaThuoc(
              { ...result.data, duocSi: drug.data[0].duocSi },
              bnttc.data,
              drug.data,
              PkDangNhap,
            ),
          )
        }
        Toast.fire({
          icon: 'success',
          title: 'Thanh toán thuốc thành công ',
        })
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Thanh toán thuốc thất bại',
        })
        console.log(err)
      } finally {
        yield put({
          type: typeAction.RESET_STORE_DRUGSTORE,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // LẤY DANH SÁCH CHI TIẾT BN NHÀ THUỐC
  yield takeLatest(
    typeAction.GET_DETAIL_BN_NHA_THUOC,
    function* BNDone({ type, form, idbnttc, idloaitt }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })

        yield call(() => drugstoreService.putDaNhanToa(idbnttc)) // dược mở toa lên xem
        const detailBN = yield call(() => childrenClinicService.getDetailBNKham(idbnttc))
        const infoBN = yield call(() => recieveService.getInfoBN(detailBN.data.idbn))
        const service = yield call(() => drugstoreService.getServiceNhaThuoc(idbnttc))
        const BNTT = yield call(() => drugstoreService.getBNTTtheoLoaiTT(idbnttc, idloaitt)) // idloaitt bán toa =4, xuất vắc xin =6
        const BNTTPhieuThuLT = yield call(() => drugstoreService.getBNTTtheoLoaiTT(idbnttc, 1))
        yield put({
          type: typeAction.DISPATCH_DETAI_BN_NHA_THUOC,
          payload: detailBN.data,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_NHA_THUOC,
          payload: infoBN.data,
        })
        yield put({
          type: typeAction.DISPATCH_SERVICE_NHA_THUOC,
          payload: service.data,
        })
        yield put({
          type: typeAction.DISPATCH_BNTT_PHIEUTHU_LETAN,
          payload: BNTTPhieuThuLT.data,
        })
        yield put({
          type: typeAction.DISPATCH_BNTT_TOATHUOC,
          payload: BNTT?.data?.idbntt ? BNTT?.data : null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_VOUCHER_BANTOA,
          payload: null,
        })
      } catch (err) {
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  //-------------- POST BÁN LẺ --------------
  yield takeLatest(
    typeAction.POST_BN_BAN_LE,
    function* addBNBanLe({
      type,
      benhNhanTT,
      detailPay,
      thuocBN,
      dataBNTTC,
      infoVC,
      ghiChu,
      resetStore,
    }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK

        // console.log(benhNhanTT, detailPay, thuocBN, dataBNTTC);
        const BenhNhanTTC = yield call(() => drugstoreService.postBNBanLe(dataBNTTC))
        const data = []
        for (const items of thuocBN) {
          data.push({
            idbnttc: BenhNhanTTC.data.idbnttc,
            idthuoc: items.idThuoc,
            soLuong: Number(items.SL),
            donGia: items.thuocVatTu.giaBan,
            thanhTien: Number(items.SL) * items.thuocVatTu.giaBan,
            tongTien:
              Number(items.SL) * items.thuocVatTu.giaBan -
              (infoVC && infoVC?.listVTHHGiam?.length > 0
                ? infoVC?.listVTHHGiam?.find(
                    (itemThuocDaGiam) => itemThuocDaGiam?.idthuoc === items?.idThuoc,
                  )?.tiengiam
                : 0), //items?.idthuoc,
            benhNhanLayTHuoc: 1,
            duocSi: benhNhanTT.thuNgan,
            idkhocn: items.idKhoCN,
            soLo: items.soLo,
            hanDung: items.hanDung,
            idLoaiToa: 6, // loại bán lẻ,
            soHoaDon: items.soHoaDon,
            // tiengiam: 0,
            tiengiam:
              infoVC && infoVC?.listVTHHGiam?.length > 0
                ? infoVC?.listVTHHGiam?.find(
                    (itemThuocDaGiam) => itemThuocDaGiam?.idthuoc === items?.idThuoc,
                  )?.tiengiam
                : 0, //items?.idthuoc
            ghiChu: ghiChu,
          })
        }
        // console.log(data);
        const dataPT = yield call(() => drugstoreService.postPhieuThuBanLe(data))
        benhNhanTT.idbnttc = BenhNhanTTC.data.idbnttc
        const result = yield call(() =>
          recieveService.postBNPay({
            ...benhNhanTT,
            tienGiamGia: infoVC?.tongGiam ?? 0,
            maVoucher: infoVC?.chuoiMaVc ?? null,
          }),
        )
        detailPay.idbntt = result.data.idbntt
        yield call(() =>
          recieveService.postDetailPay({
            ...detailPay,
            tongtien: detailPay?.tongtien + (infoVC?.tongGiam ?? 0),
            tiengiamgia: infoVC?.tongGiam ?? 0,
          }),
        )
        if (infoVC && infoVC?.listMaVc?.length > 0) {
          yield call(() => cardService.putSuDungVoucher(infoVC?.listMaVc, benhNhanTT?.thuNgan))
          yield put({
            type: typeAction.DISPATCH_INFO_VOUCHER_BANTOA,
            payload: null,
          })
        }
        yield call(() =>
          generatePhieuBanLeToaThuoc(BenhNhanTTC.data, dataPT.data, PkDangNhap, result.data),
        )
        resetStore()
        Toast.fire({
          icon: 'success',
          title: 'Thanh toán thuốc thành công ',
        })
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Thanh toán thuốc thất bại',
        })
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  //---------------- PUT TRẢ THUỐC BỆNH NHÂN -------------------//
  yield takeLatest(
    typeAction.POST_BN_TRA_THUOC,
    function* AddThuoc({ type, fromPay, formPayDetail, data, idChiNhanh, resetFormData }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const drug = yield call(() => drugstoreService.updateThuoc(data))
        const result = yield call(() => drugstoreService.putBNTTTraThuoc(fromPay))
        yield call(() =>
          cardService.updateChiTietTT({ ...formPayDetail, idbntt: result.data.idbntt }),
        )
        const checkTraThuocFull = data?.findIndex((item) => item?.soLuong > 0)
        if (checkTraThuocFull === -1) {
          //yield call(() => cashierService.putBNCTTTCheckDelete(result.data.idbntt, fromPay?.idnv))
        }
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK
        // -------- update lại trạng thái BN thông tin chung -----------//
        const bnttc = yield call(() =>
          childrenClinicService.updateDetailBN({
            idbnttc: formPayDetail.idbnttchung,
            trangthaikhambenh:
              formPayDetail?.xuatvacxin === true && checkTraThuocFull !== -1 ? 5 : 4, // trại thái đã hoàn thành khám bệnh (nếu mà xuất vắc xin thì trạng thái khám bệnh bnttc là 5 tiêm chủng vào phong tiêm)
          }),
        )
        yield put({
          type: typeAction.RESET_STORE_DRUGSTORE,
        })
        resetFormData()

        if (formPayDetail?.xuatvacxin === true) {
          yield put(BNDaXVXAction(idChiNhanh))
          // yield put(BNDaXVXAction(idChiNhanh))BNChoXVXAction
          yield call(() => {
            generatePhieuXuatKhoVacxin(result.data, bnttc.data, drug.data, PkDangNhap)
            generatePhieuThuTienXuatVacxin(result.data, bnttc.data, drug.data, PkDangNhap)
          })
        } else {
          yield put(BNDaLayThuoc(idChiNhanh))
          yield call(() =>
            generatePhieuThuTienToaThuoc(
              { ...result.data, duocSi: drug.data[0].duocSi },
              bnttc.data,
              drug.data,
              PkDangNhap,
            ),
          )
        }
        Toast.fire({
          icon: 'success',
          title: 'Thanh toán thuốc thành công ',
        })
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Thanh toán thuốc thất bại',
        })
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  //---------------- PUT TRẢ TOA CHO BÁC SĨ KÊ LẠI -------------------//
  yield takeLatest(
    typeAction.POST_BN_TRA_TOA_CHO_BS,
    function* putTraToaChoBSKeLaiSaga({ type, form }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => drugstoreService.putTraToaChoBSKeLai(form?.idbnttc, form?.idloaitt))
        yield call(() => drugstoreService.postLichSuTraToa(form?.formlichsutrathuoc))
        yield call(() => childrenClinicService.updateDetailBN(form?.formbnttc))
        yield put({
          type: typeAction.RESET_STORE_DRUGSTORE,
        })
        if (form?.xuatvacxin === true) {
          if (form?.tabLayThuoc === 1) {
            yield put(BNChoXVXAction(form?.idcn))
          } else if (form?.tabLayThuoc === 2) {
            yield put(BNDaXVXAction(form?.idcn))
          } else if (form?.tabLayThuoc === 3) {
            yield put(getBNChoBsKeToaLaiXVXAction(form?.idcn))
          }
          //yield put(BNDaXVXAction(form?.idcn))
          // yield put(BNDaXVXAction(idChiNhanh))BNChoXVXAction
        } else {
          if (form?.tabLayThuoc === 1) {
            yield put(BNchoThuoc(form?.idcn))
          } else if (form?.tabLayThuoc === 2) {
            yield put(BNDaLayThuoc(form?.idcn))
          } else if (form?.tabLayThuoc === 3) {
            yield put(BNChoBSKeToaLai(form?.idcn))
          }
          //yield put(BNDaLayThuoc(form?.idcn))
        }
        Toast.fire({
          icon: 'success',
          title: 'Trả toa thuốc thành công!',
        })
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Trả toa thuốc thất bại!',
        })
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // LẤY DANH SÁCH BN CHỜ BÁC SĨ KÊ TOA LẠI
  yield takeLatest(
    typeAction.GET_BN_CHO_BS_KE_TOA_LAI,
    function* BNPendingBSKeToaLai({ type, idChiNhanh }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT242'),
        )

        const result = yield call(() => drugstoreService.getBNChoBsKeToaLai(idChiNhanh))
        yield put({
          type: typeAction.DISPATCH_BN_LAY_THUOC,
          payload: result.data?.map((item) => ({ ...item, tab: 3 })),
        })
      } catch (error) {
        console.error('Error : ', error)
        yield put({
          type: typeAction.DISPATCH_BN_LAY_THUOC,
          payload: [],
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.RESET_STORE_DRUGSTORE,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // CHỈNH SỬA CẬP NHẬT BNTT VÀ BNCTTT CỦA PHIẾU THU TOA THUỐC
  yield takeLatest(
    typeAction.PUT_BNTT_VA_BNCTTT_PHIEUTHU_TOATHUOC,
    function* putBnTTvaBnCTTTphieuThuToaSaga({
      type,
      form,
      listThuoc,
      tongTienNew,
      formHinh,
      resetForm,
    }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        listThuoc?.length > 0 &&
          (yield call(() =>
            drugstoreService.updateThuocKhiTraThuoc(
              listThuoc?.map((item) => ({
                idbnpt: item?.idbnpt,
                soLuong: item?.soluongnew,
                tongTien: item?.tongtien,
                thanhTien: item?.thanhtien,
                // benhNhanLayTHuoc: check ? 0 : checkTraThuoc ? 2 : 1,
                // lyDoKoLayThuoc: check || checkTraThuoc ? idLyDo : 0,
                // duocSi: infoUser?.tenNV,
                // idLoaiToa: 1,
                soluongcu: item?.soluongcu ?? item?.soluong,
                // ghiChu: checkTraThuoc ? lyDoKhac : null,
                nguoitra: item?.nguoitra,
                // tiengiam:
                //   infoVC && infoVC?.listVTHHGiam?.length > 0
                //     ? infoVC?.listVTHHGiam?.find(
                //         (itemThuocDaGiam) => itemThuocDaGiam?.idthuoc === items?.idthuoc,
                //       )?.tiengiam
                //     : 0, //items?.idthuoc
              })),
            ),
          ))
        yield call(() =>
          cardService.updateBNTT({
            idbntt: form.idbntt,
            hoadondientu: form.hoadondientu,
            ghichuxuathoadon: form.ghichuxuathoadon,
            tienThuoc: tongTienNew,
            // tienGiamGia:0,
            tienThanhToan: tongTienNew,
          }),
        )
        yield call(() =>
          cardService.updateChiTietTT({
            idcttt: form.idcttt,
            idbntt: form.idbntt,
            tienmat: form.tienmat,
            chuyenkhoan: form.chuyenkhoan,
            thett: form.thett,
            momo: form.momo,
            vnpay: form.vnpay,
            zalopay: form.zalopay,
            pos: form.pos,
            tongtien: tongTienNew,
            // tiengiamgia: 0,
            // tiengiamdoitac: form.tiengiamdoitac,
            tienthanhtoan: tongTienNew,
          }),
        )
        formHinh &&
          (yield call(() => drugstoreService.putFileTraThuoc(formHinh?.idbnttc, formHinh?.data)))
        yield call(() => resetForm(false))
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        // const result = yield call(() => drugstoreService.getBNChoBsKeToaLai(idChiNhanh))
        // yield put({
        //   type: typeAction.DISPATCH_BN_LAY_THUOC,
        //   payload: result.data?.map((item) => ({ ...item, tab: 3 })),
        // })
        // yield put({
        //   type: typeAction.RESET_STORE_DRUGSTORE,
        // })
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}

import React, { memo, useEffect, useRef, useState } from 'react'
import { Button, ConfigProvider, DatePicker, Input, Select, Space, Table, Typography } from 'antd'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { Tag } from 'antd'
import 'moment/locale/vi'
import { configSearchTableAnt } from '../../../../utils/configSearchTableAntd'
import { all } from 'axios'
import { fi } from 'date-fns/locale'
moment.locale('vi')
const TableHang = ({ data, loadingChiTiet, setValueExport }) => {
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [searchValues, setSearchValues] = useState({})
  const [filteredData, setFilteredData] = useState([])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const recordValue = record[dataIndex]
      return recordValue
        ? recordValue.toString().toLowerCase().includes(value.toLowerCase())
        : false
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const columnsDetails = [
    {
      title: 'STT',
      dataIndex: 'STT',
      align: 'center',
      key: 'STT',
      width: 40,
      fixed: 'left',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Mã phiếu',
      dataIndex: 'maphieu',
      key: 'maphieu',
      width: 150,
      fixed: 'left',
      ...configSearchTableAnt(setSearchValues, searchValues, 'maphieu', data, 150, false),
    },
    {
      title: 'Ngày nhập',
      dataIndex: 'ngaynhan',
      key: 'ngaynhan',
      width: 150,
      ...configSearchTableAnt(setSearchValues, searchValues, 'ngaynhan', data, 150, true, {
        render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : ''),
      }),
    },
    {
      title: 'Tên phiếu',
      dataIndex: 'tenphieu',
      key: 'tenphieu',
      width: 350,
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenphieu', data, 350, false),
    },
    {
      title: 'Mã hàng',
      dataIndex: 'mathuoc',
      key: 'mathuoc',
      width: 150,
      ...configSearchTableAnt(setSearchValues, searchValues, 'mathuoc', data, 150, false),
    },
    {
      title: 'Tên biệt dược',
      dataIndex: 'tenbietduoc',
      key: 'tenbietduoc',
      width: 350,
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenbietduoc', data, 350, false),
    },
    {
      title: 'Số Lô',
      dataIndex: 'solo',
      key: 'solo',
      align: 'center',
      width: 120,
      ...configSearchTableAnt(setSearchValues, searchValues, 'solo', data, 120, false),
    },
    {
      title: 'Hạn Dùng',
      dataIndex: 'handung',
      key: 'handung',
      width: 120,
      align: 'center',
      ...configSearchTableAnt(setSearchValues, searchValues, 'handung', data, 120, true, {
        render: (text) => (text ? moment(text).format('DD/MM/YYYY') : ''),
      }),
    },
    {
      title: 'Số hoá đơn',
      dataIndex: 'sohoadon',
      key: 'sohoadon',
      width: 150,
      // onFilter: (value, record) => record.sohoadon.startsWith(value),
      // filters: getUniqueFilters(data, 'sohoadon'),
      // filterSearch: true,
      ...configSearchTableAnt(setSearchValues, searchValues, 'sohoadon', data, 150, false),
    },
    {
      title: 'SL chẵn',
      dataIndex: 'soLuongChan',
      key: 'soLuongChan',
      align: 'center',
      width: 100,
      //   render: (text, record) => Math.floor(record?.soluongle / record?.soluongdonggoi) ?? 0,
      ...configSearchTableAnt(setSearchValues, searchValues, 'soLuongChan', data, 100, false, {
        render: (text, record) => Math.floor(record?.soluongle / record?.soluongdonggoi) ?? 0,
      }),
    },
    {
      title: 'Đơn vi chẵn',
      dataIndex: 'donvichan',
      align: 'center',
      key: 'donvichan',
      width: 150,
      ...configSearchTableAnt(setSearchValues, searchValues, 'donvichan', data, 150, false),
    },
    {
      title: 'Đơn giá chẵn',
      dataIndex: 'donGiaChan',
      align: 'right',
      key: 'donGiaChan',
      width: 150,
      ...configSearchTableAnt(setSearchValues, searchValues, 'donGiaChan', data, 150, false, {
        render: (text, record) => formatNumberVND(record?.dongiamua * record?.soluongdonggoi) || 0,
      }),
    },
    {
      title: 'Quy cách',
      dataIndex: 'quycach',
      key: 'quycach',
      align: 'center',
      width: 120,
      ...configSearchTableAnt(setSearchValues, searchValues, 'quycach', data, 120, false),
    },
    {
      title: 'SL lẻ',
      dataIndex: 'soluongle',
      key: 'soluongle',
      align: 'center',
      width: 100,
      ...configSearchTableAnt(setSearchValues, searchValues, 'soluongle', data, 100, false),
    },
    {
      title: 'Đơn vị lẻ',
      dataIndex: 'dvt',
      key: 'dvt',
      align: 'center',
      width: 120,
      // ...getColumnSearchProps('dvt'),
      ...configSearchTableAnt(setSearchValues, searchValues, 'dvt', data, 120, false),
    },
    {
      title: 'Đơn giá lẻ',
      dataIndex: 'dongiamua',
      key: 'dongiamua',
      width: 150,
      ...configSearchTableAnt(setSearchValues, searchValues, 'dongiamua', data, 150, false, {
        render: (text) => formatNumberVND(text) || 0,
      }),
      align: 'right',
    },
    {
      title: 'Ngày HĐ',
      dataIndex: 'ngayhoadon',
      align: 'center',
      key: 'ngayhoadon',
      width: 120,
      ...configSearchTableAnt(setSearchValues, searchValues, 'ngayhoadon', data, 120, true, {
        render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
      }),
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenchinhanh',
      width: 250,
      key: 'tenchinhanh',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenchinhanh', data, 250, false),
    },
    {
      title: 'Kho nhập',
      dataIndex: 'tenkho',
      key: 'tenkho',
      width: 150,
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenkho', data, 150, false),
    },
    {
      title: 'Mã đối tác',
      dataIndex: 'madoitac',
      align: 'center',
      key: 'madoitac',
      width: 120,
      ...configSearchTableAnt(setSearchValues, searchValues, 'madoitac', data, 120, false),
    },
    {
      title: 'Tên đối tác',
      dataIndex: 'tendoitac',
      key: 'tendoitac',
      width: 550,
      ...configSearchTableAnt(setSearchValues, searchValues, 'tendoitac', data, 550, false),
    },
    {
      title: 'Link hóa đơn',
      dataIndex: 'linkhoadon',
      key: 'linkhoadon',
      width: 550,
      ...configSearchTableAnt(setSearchValues, searchValues, 'linkhoadon', data, 550, false),
    },
    {
      title: 'Tên hoạt chất',
      dataIndex: 'tenhoatchat',
      key: 'tenhoatchat',
      width: 350,
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenhoatchat', data, 350, false),
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'tongTien',
      key: 'tongTien',
      width: 130,
      //     render: (text, record) => formatNumberVND(record?.dongiamua * record?.soluongle) || 0,
      ...configSearchTableAnt(setSearchValues, searchValues, 'tongTien', data, 130, false, {
        render: (text, record) => formatNumberVND(record?.dongiamua * record?.soluongle) || 0,
      }),
      align: 'right',
    },

    {
      title: 'P. Gia công',
      dataIndex: 'phigiacong',
      key: 'phigiacong',
      width: 150,
      ...configSearchTableAnt(setSearchValues, searchValues, 'phigiacong', data, 150, false),
      align: 'right',
    },
    {
      title: 'P. vận chuyển',
      dataIndex: 'phivanchuyen',
      key: 'phivanchuyen',
      width: 150,
      ...configSearchTableAnt(setSearchValues, searchValues, 'phivanchuyen', data, 150, false),
      align: 'right',
    },
    {
      title: '% Ck trước VAT',
      dataIndex: 'ptcktruocvat',
      key: 'ptcktruocvat',
      align: 'center',
      width: 150,
      ...configSearchTableAnt(setSearchValues, searchValues, 'ptcktruocvat', data, 150, false),
    },
    {
      title: 'Tiền CK trước VAT',
      dataIndex: 'cktruocvat',
      key: 'cktruocvat',
      ...configSearchTableAnt(setSearchValues, searchValues, 'cktruocvat', data, 200, false, {
        render: (text) => formatNumberVND(text) || 0,
      }),
      width: 200,
      align: 'right',
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'tongtientruocvat',
      key: 'tongtientruocvat',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tongtientruocvat', data, 150, false, {
        render: (text) => formatNumberVND(text) || 0,
      }),
      width: 150,
      align: 'right',
    },
    {
      title: 'VAT',
      dataIndex: 'vat',
      key: 'vat',
      align: 'center',
      width: 100,
      // ...getColumnSearchProps('vat'),
      // render: (text, record) => (record.vaT5 !== 0 ? '5%' : record.vaT8 !== 0 ? '8%' : '10%'),
      ...configSearchTableAnt(setSearchValues, searchValues, 'vat', data, 100, false, {
        render: (text, record) =>
          record.vaT5 !== 0 ? '5%' : record.vaT8 !== 0 ? '8%' : record.vaT10 !== 0 ? '10%' : '0%',
      }),
    },
    {
      title: 'Tiền % VAT',
      dataIndex: 'phanTramVat',
      key: 'phanTramVat',
      // render: (text, record) => {
      //   const vatValue =
      //     record?.vaT5 !== 0
      //       ? Math.round(record?.vaT5)
      //       : record?.vaT8 !== 0
      //         ? Math.round(record?.vaT8)
      //         : Math.round(record?.vaT10)
      //   return formatNumberVND(vatValue) || 0
      // },
      ...configSearchTableAnt(setSearchValues, searchValues, 'phanTramVat', data, 130, false, {
        render: (text, record) => {
          const vatValue =
            record?.vaT5 !== 0
              ? Math.round(record?.vaT5)
              : record?.vaT8 !== 0
                ? Math.round(record?.vaT8)
                : Math.round(record?.vaT10)
          return formatNumberVND(vatValue) || 0
        },
      }),

      align: 'right',
      width: 120,
    },
    {
      title: 'Thành tiền',
      dataIndex: 'thanhtien',
      key: 'thanhtien',
      //   render: (text) => (text ? formatNumberVND(text) : 0),
      ...configSearchTableAnt(setSearchValues, searchValues, 'thanhtien', data, 130, false, {
        render: (text) => (text ? formatNumberVND(text) : 0),
      }),
      align: 'right',
      width: 150,
    },
    {
      title: 'Thực trả',
      dataIndex: 'thuctra',
      key: 'thuctra',
      ...configSearchTableAnt(setSearchValues, searchValues, 'thuctra', data, 130, false, {
        render: (text) => (text ? formatNumberVND(text) : 0),
      }),
      align: 'right',
      width: 150,
    },
    {
      title: ' Nhiệt độ',
      dataIndex: 'nhietdo',
      key: 'nhietdo',
      width: 120,
      align: 'center',
      ...configSearchTableAnt(setSearchValues, searchValues, 'nhietdo', data, 120, false),
    },
    {
      title: 'Mã số ĐK',
      dataIndex: 'masodangky',
      key: 'masodangky',
      align: 'left',
      width: 120,
      ...configSearchTableAnt(setSearchValues, searchValues, 'masodangky', data, 120, false),
    },

    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 400,
      ...configSearchTableAnt(setSearchValues, searchValues, 'ghichu', data, 400, false),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'trangthaichuyenkho',
      key: 'trangthaichuyenkho',
      align: 'center',
      width: 90,
      fixed: 'right',
      render: (text) =>
        text === 5 ? (
          <Tag color="volcano" bordered={false}>
            Chưa nhập
          </Tag>
        ) : (
          <Tag bordered={false} color="green">
            Đã nhập
          </Tag>
        ),
    },
  ]
  const onChange = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }

  useEffect(() => {
    const filterData = () => {
      let filtered = data
      Object.keys(searchValues).forEach((key) => {
        if (searchValues[key]) {
          filtered = filtered.filter((item) =>
            item[key]?.toString().toLowerCase().includes(searchValues[key].toLowerCase()),
          )
        }
      })
      setFilteredData(filtered)
    }
    filterData()
  }, [searchValues, data])
  return (
    <ConfigProvider
      theme={{
        token: {
          padding: 5,
          borderRadius: 0,
        },
        components: {
          Table: {
            rowHoverBg: '#ecf0f1',
            headerBg: '#e6e6e6',
            footerBg: '#e6e6e6',
            borderColor: '#BABABA',
          },
        },
      }}
    >
      <Table
        onChange={onChange}
        // virtual
        loading={loadingChiTiet}
        // scroll={{
        //   // x: data.length > 0 ? 'max-content' : 5000,
        //   // x: filteredData.length > 0 ? 'max-content' : 5000,
        //   x: 5000,
        //   y: 620,
        // }}
        scroll={{
          x: 'max-content', // Đảm bảo bảng có thể cuộn ngang khi nội dung vượt quá chiều rộng
          y: filteredData.length > 0 ? 620 : undefined, // Sử dụng y nếu filteredData > 0, ngược lại thì không sử dụng y
        }}
        bordered
        pagination={false}
        columns={columnsDetails}
        dataSource={filteredData}
        summary={(pageData) => {
          const totalDonGiaLe = pageData.reduce((sum, record) => sum + (record.dongiamua || 0), 0)
          const totalDonGiaChan = pageData.reduce((sum, record) => {
            const donGiaChan = record?.dongiamua * record?.soluongdonggoi
            return sum + (donGiaChan || 0)
          }, 0)
          const totalSoLuongChan = pageData.reduce((sum, record) => {
            const value = Math.floor(record?.soluongle / record?.soluongdonggoi)
            return sum + (isNaN(value) ? 0 : value)
          }, 0)
          const totalGiaCong = pageData.reduce((sum, record) => sum + (record.phigiacong || 0), 0)
          const totalVanChuyen = pageData.reduce(
            (sum, record) => sum + (record.phivanchuyen || 0),
            0,
          )
          const totalSoLuongLe = pageData.reduce((sum, record) => sum + (record.soluongle || 0), 0)
          const totalTongTien = pageData.reduce((sum, record) => {
            const tongTien = record?.dongiamua * record?.soluongle
            return sum + (tongTien || 0)
          }, 0)
          const totalTongTienTrVAT = pageData.reduce(
            (sum, record) => sum + (record.tongtientruocvat || 0),
            0,
          )
          const totalTienCK = pageData.reduce((sum, record) => sum + (record.cktruocvat || 0), 0)
          const totalTienVAT = pageData.reduce((sum, record) => {
            const vatValue =
              record?.vaT5 !== 0
                ? Math.round(record?.vaT5)
                : record?.vaT8 !== 0
                  ? Math.round(record?.vaT8)
                  : Math.round(record?.vaT10)
            return sum + (vatValue || 0)
          }, 0)
          const totalThanhTien = pageData.reduce((sum, record) => sum + (record.thanhtien || 0), 0)
          const totalThucTra = pageData.reduce((sum, record) => sum + (record.thuctra || 0), 0)

          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                {Array.from({ length: 37 }, (_, index) => (
                  <Table.Summary.Cell key={index} index={index} align="right">
                    {index === 15 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalDonGiaLe)}
                      </Typography.Text>
                    )}
                    {index === 11 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalDonGiaChan)}
                      </Typography.Text>
                    )}
                    {index === 9 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalSoLuongChan)}
                      </Typography.Text>
                    )}
                    {index === 24 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalGiaCong)}
                      </Typography.Text>
                    )}
                    {index === 25 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalVanChuyen)}
                      </Typography.Text>
                    )}
                    {index === 13 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalSoLuongLe)}
                      </Typography.Text>
                    )}{' '}
                    {index === 23 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalTongTien)}
                      </Typography.Text>
                    )}
                    {index === 28 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalTongTienTrVAT)}
                      </Typography.Text>
                    )}
                    {index === 27 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalTienCK)}
                      </Typography.Text>
                    )}
                    {index === 30 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalTienVAT)}
                      </Typography.Text>
                    )}
                    {index === 31 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalThanhTien)}
                      </Typography.Text>
                    )}
                    {index === 32 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalThucTra)}
                      </Typography.Text>
                    )}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            </Table.Summary>
          )
        }}
      />
    </ConfigProvider>
  )
}

export default TableHang

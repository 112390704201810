import { call, put, takeLatest } from 'redux-saga/effects'
import { lienheCRMService } from '../../services/lienhe/lienheService'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'

export function* lienheCRMSaga() {
  yield takeLatest(typeAction.GET_ALL_LH_CRM, function* allLienHeCRM({ type, id }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const { data } = yield call(() => lienheCRMService.getCompanyByIDCompany(id))
      data.result.sort((a, b) => new Date(b.ngaytao) - new Date(a.ngaytao))
      yield put({
        type: typeAction.DISPATCH_ALL_LH_CRM,
        payload: data.result,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  yield takeLatest(
    typeAction.GET_ALL_LH_CRM_BY_ID_NV,
    function* allLienHeCRMByID({ type, idnv, idct }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data } = yield call(() => lienheCRMService.getLienHeByIdNv(idnv, idct))
        yield put({
          type: typeAction.DISPATCH_ALL_LH_CRM,
          payload: data,
        })
      } catch (err) {
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // yield takeLatest(typeAction.GET_CTY_CRM_BY_ID, function* getById({ type, id }) {
  //   try {
  //     yield put({
  //       type: typeAction.OPEN_LOADING_PAGE,
  //     })
  //     const result = yield call(() => congtyCRMService.getCtyCRMById(id))
  //     yield put({
  //       type: typeAction.DISPATCH_INFO_CTY_CRM,
  //       payload: result.data.result,
  //     })
  //   } catch (err) {
  //     console.log(err)
  //   } finally {
  //     yield put({
  //       type: typeAction.CLOSE_LOADING_PAGE,
  //     })
  //   }
  // })

  // //add
  yield takeLatest(typeAction.ADD_LH_CRM, function* addLienHeCRM({ type, form, onload }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      yield call(() => lienheCRMService.addThongTinLH(form))
      yield call(onload)
      ToastCus.fire({
        icon: 'success',
        title: 'Thêm mới thành công',
      })
    } catch (err) {
      console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: err?.response?.data?.message || 'Thêm mới thất bại',
      })
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  //add chi tiet
  yield takeLatest(typeAction.ADD_CHITIET_LH_CRM, function* addChiTietLienHeCRM({ type, form, onLoad }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      yield call(() => lienheCRMService.addChiTietLH(form))
      ToastCus.fire({
        icon: 'success',
        title: 'Thêm mới thành công',
      })
      onLoad()
    } catch (err) {
      console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: 'Thêm mới thất bại',
      })
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  yield takeLatest(typeAction.GET_THONGTIN_LH_CRM, function* getThongTinLienHe({ type, idttlh }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => lienheCRMService.getThongTinLienHe(idttlh))
      yield put({
        type: typeAction.DISPATCH_INFO_LH_CRM,
        payload: result.data.result,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  //edit
  yield takeLatest(typeAction.EDIT_LH_CRM, function* editLienHeCRM({ type, form, onLoad }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => lienheCRMService.editThongTinLH(form))
      yield call(onLoad)
      ToastCus.fire({
        icon: 'success',
        title: 'Sửa thông tin thành công',
      })
    } catch (err) {
      console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: err?.response?.data?.message || 'Sửa thông tin thất bại',
      })
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
}


import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
    infoBNDVThuThuat: null,
    listBNCho: [],
    listBNDaThucHien: [],
    listVT: [],
    listVTBN: [],
}

const thuThuatReducer = (state = initialState, { type, payload }) => {
    return produce(state, (draft) => {
        switch (type) {
            case typeAction.RESET_STORE:
                return initialState // reset store khi logOut
            case typeAction.DISPATCH_INFO_BN_DV_THUTHUAT:
                draft.infoBNDVThuThuat = payload
                break
            case typeAction.DISPATCH_LIST_BN_CHO_THUTHUAT:
                draft.listBNCho = payload
                break
            case typeAction.DISPATCH_LIST_BN_DATHUCHIEN_THUTHUAT:
                draft.listBNDaThucHien = payload
                break
            case typeAction.DISPATCH_LIST_VT_THUTHUAT:
                draft.listVT = payload
                break
            case typeAction.DISPATCH_LIST_VT_BN_THUTHUAT:
                draft.listVTBN = payload
                break
            case typeAction.DISPATCH_ADD_VT_BN_THUTHUAT:
                draft.listVTBN.push(payload)
                break
            case typeAction.DISPATCH_EDIT_VT_SL_BN_THUTHUAT:
                draft.listVTBN[payload.index].soLuong = payload.value
                break
            case typeAction.DISPATCH_EDIT_VT_GHICHU_BN_THUTHUAT:
                draft.listVTBN[payload.index].ghiChu = payload.value
                break
            case typeAction.DISPATCH_DELETE_VT_BN_THUTHUAT:
                const index = draft.listVTBN.findIndex((items) => items.iD === payload)
                draft.listVTBN.splice(index, 1)
                break
            default:
                return state
        }
    })
}

export default thuThuatReducer

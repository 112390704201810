import React from "react";
import InfoBN from "../../ChildrenClinic/General/InfoBN/InfoBN";
import Index from "../../ChildrenClinic/General/Index/Index";
import TabDiagnostic from "./Tab/TabDiagnostic";
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'


const General = ({ defaultValuePK }) => {
  const { infoBN } = useSelector((state) => state.childrenClinicReducer)
  // ------------- Form update thông tin bệnh nhân --------//
  const formBenhNhan = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbn: infoBN?.idbn,
      diUng: infoBN?.diUng,
      tienSuBanThan: infoBN?.tienSuBanThan,
      dienThoai:infoBN?.dienThoai
    },
  })
  return (
    <div className="flex flex-col gap-2 h-full">
      <div
        className="bg-white rounded-md"
        style={{
          boxShadow:
            "0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)",
        }}
      >
        <InfoBN formBenhNhan={formBenhNhan} />
      </div>
      <div>
        <Index />
      </div>
      <div className="flex gap-2 h-full">
        <div
          className="w-full bg-white rounded-md"
          style={{
            boxShadow:
              "0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)",
          }}
        >
          <TabDiagnostic defaultValuePK={defaultValuePK} formBenhNhan={formBenhNhan} />
        </div>
      </div>
    </div>
  );
};

export default General;

// src/components/layout/ChildrenClinic/General/Index/EditableSpan.jsx
import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { phanQuyenService } from '../../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../../store/actions/phanQuyenAction'
import { updateDetailBN } from '../../../../../store/actions/maternityClinicAction'
import ToastCus from '../../../../../utils/Toast'

const EditableSpan = ({ value, unit, field, isEditable = true }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const [isEditing, setIsEditing] = useState(false)
  const [inputValue, setInputValue] = useState(value)
  const [inputWidth, setInputWidth] = useState(null)
  const spanRef = useRef(null)

  useEffect(() => {
    if (spanRef.current) {
      setInputWidth(spanRef.current.offsetWidth)
    }
  }, [isEditing])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const handleDoubleClick = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT075',
      )
      if (isEditable) {
        setIsEditing(true)
      }
    } catch (error) {
      console.error('Error : ', error)
      setIsEditing(false)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  const handleChange = (e) => {
    const newValue =
      field === 'chieucao' || field === 'cannang' ? parseFloat(e.target.value) : e.target.value
    setInputValue(newValue)
    dispatch(updateDetailBN({ [field]: newValue }))
  }

  const handleBlur = () => {
    setIsEditing(false)
  }

  return isEditing ? (
    <input
      type={field === 'chieucao' || field === 'cannang' ? 'number' : 'text'}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      autoFocus
      style={{ width: inputWidth }}
      className="text-end pr-2 font-semibold text-green-700 text-sm"
    />
  ) : (
    <span
      ref={spanRef}
      className="text-end pr-2 font-semibold text-green-700 text-sm"
      onDoubleClick={handleDoubleClick}
    >
      {inputValue} {unit}
    </span>
  )
}

export default EditableSpan

import { Button, DatePicker, Input, Radio, Select, Tooltip,notification } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import TonKho from '../../TonKho/TonKho'
import { useDispatch, useSelector } from 'react-redux'
import ModalCreateKyHang from '../ModalCreateKyhang/ModalCreateKyHang'
import * as typeAction from '../../../../store/constants/constants'
import { postKyHang } from '../../../../store/actions/kiemKeAction'
import { branchService } from '../../../../services/branch/branchService'
const CreateKyHang = () => {
  const dispatch = useDispatch()
  const ID_COMPANY_LOGIN = Number(localStorage.getItem('id_company'))
  const [api, contextHolder] = notification.useNotification();
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { kyHang } = useSelector((state) => state.thuocVTReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [value, setValue] = useState(null)
  const [idCompany, setIdCompany] = useState(ID_COMPANY_LOGIN)
  const [branch, setBranch] = useState([])
  const [IdBranch, setIdBranch] = useState(null)
  const [checked, setChecked] = useState(1)
  const onChangeChecked = (e) => {
    setChecked(e.target.value)
  }
  const openCreateKyHang = () => {
    dispatch({
      type: typeAction.OPEN_CREATE_KY_HANG_HOA,
    })
  }
  const form = {
    IDCT: idCompany,
    idChiNhanh: checked !== 1 ? IdBranch : '',
    nguoiTao: infoUser?.idnv,
    idKy: value?.value,
  }
  const fetchBranchByCompany = async (id) => {
    const { data } = await branchService.getListBranch(id)
    setBranch(data)
    setIdBranch(data[0].idChiNhanh)
  }
  const handleOK = () => {
    if(!value) return openNotificationWithIcon()
    dispatch(postKyHang(form))
  }
  const openNotificationWithIcon = () => {
    api.warning({
      message: 'Chốt kỳ hàng hoá',
      description:
        'Vui lòng chọn kỳ hàng hoá.',
    });
  };
  useEffect(() => {
    fetchBranchByCompany(idCompany)
  }, [idCompany])
  return (
    <>
    {contextHolder}
      <div className="border rounded-md p-2 bg-slate-50">
        <div className=" w-full gap-5 flex ">
          <div className="w-1/2 flex flex-col gap-1">
            <label className="font-medium flex gap-2">
              <span className="text-red-500">(*)</span> Tên kỳ hàng:
              <Tooltip placement="top" title="Tạo kỳ hàng" color="blue" className="cursor-pointer">
                <svg
                  onClick={openCreateKyHang}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6 text-blue-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </Tooltip>
            </label>
            <Select
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              showSearch
              onChange={(value, option) => setValue(option)}
              className="w-full"
              value={value?.value}
              options={kyHang?.map((item) => ({
                label: item.tenky,
                value: item.idky,
                denngay: item.denngay,
              }))}
            />
          </div>
          <div className="w-1/2 flex flex-col">
            <label className="font-medium ">Ngày chốt hàng:</label>
            <Input
              value={value?.denngay && moment(value?.denngay).format('DD/MM/YYYY')}
              readOnly
              className="mt-1"
            />
          </div>
        </div>
        <div className="py-2">
          <div className="flex items-center">
            <Radio.Group onChange={onChangeChecked} value={checked}>
              <Radio value={1}>Công ty</Radio>
              <Radio value={2}>Chi nhánh</Radio>
            </Radio.Group>
            <p className="flex gap-1 items-center bg-red-500 bg-opacity-20 text-red-700  p-1 rounded">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                />
              </svg>
              Chỉ hiện những chi nhánh chưa chốt tồn của kỳ
            </p>
          </div>

          <div className="flex gap-5">
            <div className="w-1/2 flex flex-col">
              <label className="font-medium ">Công ty:</label>
              <Select
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                showSearch
                value={idCompany}
                onChange={setIdCompany}
                options={listCompany?.map((company) => ({
                  label: company.tenct,
                  value: company.idct,
                }))}
              />
            </div>
            <div className="w-1/2 flex flex-col">
              <label className="font-medium ">Chi nhánh:</label>
              <Select
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                showSearch
                disabled={checked !== 2}
                value={IdBranch}
                onChange={setIdBranch}
                options={branch?.map((branch) => ({
                  label: branch.tenChiNhanh,
                  value: branch.idChiNhanh,
                }))}
              />
            </div>
          </div>
        </div>
        <div className="text-right w-full mt-2">
          <Button onClick={handleOK} className="text-right" type="primary">
            Chốt tồn hàng
          </Button>
        </div>
      </div>
      <h2 className="text-lg font-medium text-gray-500 flex items-center gap-1 border-b border-dashed my-2 pb-1">
        {' '}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z"
          />
        </svg>
        Tồn kho{' '}
        <i className="font-normal text-sm p-1 text-green-700 bg-green-500 bg-opacity-20 rounded ">
          {' '}
          Tồn kho thực tế{' '}
        </i>
      </h2>
      <TonKho />
      <ModalCreateKyHang />
    </>
  )
}

export default CreateKyHang

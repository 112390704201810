import { https } from '../apiService'

export const phanQuyenService = {
  getListQuyenHeThong: (keyword, idmenu) =>
    https.get(`QuyenHeThong/GetAllQuyenHeThong`, { params: { keyword, idmenu } }),
  postQuyenHeThong: (form) => https.post(`QuyenHeThong/AddQuyenHeThong`, form),
  putQuyenHeThong: (form) => https.put(`QuyenHeThong/UpdateQuyenHeThong`, form),
  putSuDungQuyenHeThong: (idqht, sudung) =>
    https.put(`QuyenHeThong/UpdateSuDung?idqht=${idqht}&sudung=${sudung}`),
  getListNhomQuyen: (keyword) =>
    https.get(`NhomQuyenHeThong/GetAllNhomQuyenHeThong`, { params: { keyword } }),
  postNhomQuyen: (form) => https.post(`NhomQuyenHeThong/AddNhomQuyenHeThong`, form),
  putNhomQuyen: (form) => https.put(`NhomQuyenHeThong/UpdateNhomQuyenHeThong`, form),
  putSuDungNhomQuyen: (idnqht, sudung) =>
    https.put(`NhomQuyenHeThong/UpdateSuDung?idnqht=${idnqht}&sudung=${sudung}`),
  getListChiTietNhomQuyen: (idnqht) =>
    https.get(`PhanQuyenHeThong/GetQuyenHeThong?idnqht=${idnqht}`),
  putSuDungListCTNQ: (idnqht, sudung, list) =>
    https.put(
      `PhanQuyenHeThong/UpdateSuDungPhanQuyenHeThong?idnqht=${idnqht}&sudung=${sudung}`,
      list,
    ),
  getListNguoiDung: (keyword, idnqht, pageIndex) =>
    https.get(`PhanQuyenHeThong/GetNguoiDungPhanQuyen`, { params: { keyword, idnqht, pageIndex } }),
  putCapQuyen: (idnd, idnqht, list) =>
    https.put(`PhanQuyenHeThong/UpdatePhanQuyenNguoiDung?idnd=${idnd}&idnqht=${idnqht}`, list),
  getListCapQuyen: (idnd) => https.get(`PhanQuyenHeThong/GetQuyenNguoiDung?idnd=${idnd}`),
  putCheckQuyenThaoTac: (taikhoan, maquyen) =>
    // https.put(`PhanQuyenHeThong/CheckPhanQuyenNguoiDung?taikhoan=${taikhoan}&maquyen=${maquyen}`),
  console.log(''),
  // process.env.REACT_APP_ROLE_USER === 'production'
  //   ? console.log('')
  //   : https.put(
  //       `PhanQuyenHeThong/CheckPhanQuyenNguoiDung?taikhoan=${taikhoan}&maquyen=${maquyen}`,
  //     ),
  getHieuLucNguoiDung: (taikhoan) => https.get(`NguoiDung/GetIsLockNguoiDung?taikhoan=${taikhoan}`),
}

import React, { useCallback, useEffect, useState, useRef } from 'react'
import { ConfigProvider, Input, Select, Table, notification, InputNumber } from 'antd'
import * as typeAction from '../../../../../store/constants/constants'
import { SearchOutlined, CloseOutlined } from '@ant-design/icons'
import _, { cloneDeep } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { formatNumber } from 'devextreme/localization'
import { https } from '../../../../../services/apiService'
import ToastCus from '../../../../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')
const ToaThuoc = () => {
  const { ThuocVT, ThuocBN, DetailToaThuocBN, DetailBN, NgayThuoc } = useSelector(
    (state) => state.childrenClinicReducer,
  )
  const [currentRow, setCurrentRow] = useState(0)
  const selectRef = useRef(null)
  const inputRefs = useRef([])
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [api, contextHolder] = notification.useNotification()
  const { infoUser } = useSelector((state) => state.userReducer)

  const checkDuocSiChuaTraToa =
    DetailBN?.trangthaikhambenh === 2 &&
    (DetailToaThuocBN?.BSKEDON || DetailToaThuocBN?.NGAYKEDON) &&
    DetailToaThuocBN?.DANHANTOA === 1
  const [historyToaThuoc, setHistoryToaThuoc] = useState([])
  const dispatch = useDispatch()
  const openNotificationWithIcon = (message, description) => {
    api.warning({
      message,
      description,
    })
  }

  useEffect(() => {
    dispatch({
      type: typeAction.RESET_TOA_THUOC_BN_DOCTOR,
      payload: [],
    })
    if (DetailToaThuocBN?.DanhSachThuoc?.length > 0) {
      let listVT = []
      // gộp các dòng khác số lô hạn dùng lại thành 1 dòng
      for (const item of DetailToaThuocBN?.DanhSachThuoc) {
        if (listVT?.find((itemVT) => itemVT?.idthuoc === item?.idthuoc)) {
          listVT?.map(
            (itemVT) => itemVT?.idthuoc === item?.idthuoc && (itemVT.soluong += item.soluong),
          )
        } else {
          listVT.push({ ...item })
        }
      }
      for (const item of listVT) {
        const index = ThuocVT?.findIndex(
          (itemVT) => itemVT?.idThuoc === item?.idthuoc,
          // &&
          //   itemVT?.soLo === item?.solo &&
          //   itemVT?.hanDung === item?.handung &&
          //   itemVT?.soHoaDon === item?.sohoadon,
        )
        dispatch({
          type: typeAction.DISPATCH_THUOC_BN_DOCTOR,
          payload: {
            ID:
              index !== -1
                ? index
                : `${item?.idthuoc}${item?.solo}${item?.handung}${item?.sohoadon}`,
            //ThuocVT[index]?.tonKho,
            ton:
              // index === -1 || !ThuocVT?.length (index !== -1 ? ThuocVT[index]?.tonKho : 0)
              //   ? 0
              //   :
              DetailBN?.trangthaikhambenh === 1 ||
              (DetailBN?.trangthaikhambenh === 2 && !DetailToaThuocBN?.DANHANTOA)
                ? (index === -1 || !ThuocVT?.length ? 0 : ThuocVT[index]?.tonKho) + item?.soluong
                : index === -1 || !ThuocVT?.length
                  ? 0
                  : ThuocVT[index]?.tonKho,
            donViDung: item?.donvidung,
            idthuoc: item?.idthuoc,
            tenBietDuoc: item?.tenbietduoc,
            tenhoatchat: item?.tenhoatchat,
            duongDung: item?.duongdung,
            dvt: item?.dvt,
            hamLuong: item?.hamluong,
            soLo: item?.solo,
            idkhocn: item?.idkhocn,
            bsKeDon: infoUser?.tenNV,
            soLuong: item?.soluong,
            ghiChu: item?.ghichu,
            lieuDung: item?.lieudung ?? `0`,
            // lieuDungTinh: item?.lieudung ?? `0`,
            soLanNgay: item?.solanngay ?? `0`,
            idbnttc: DetailBN?.idbnttc,
            hanDung: item?.handung,
            soHoaDon: item?.sohoadon,
            // maSoDangKy: item.masodangky,
            giaBan: item?.dongia,
            isGongKinh: item?.idnhomthuoc === 179 || item?.idnhomthuoc === 205, //item?.idnhomthuoc === 169 ||
          },
        })
      }
    }
    // DetailToaThuocBN?.DanhSachThuoc?.map((item) => {})
  }, [DetailToaThuocBN, DetailToaThuocBN?.DanhSachThuoc])
  const handleValue = (ID) => (e) => {
    if (checkDuocSiChuaTraToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    const { name, value } = e.target
    const newValue = Number(value)
    const index = ThuocBN?.findIndex((items) => items.ID === ID)
    if (newValue > ThuocBN[index].ton) {
      openNotificationWithIcon('Kê số lượng thuốc', 'Số lượng tồn  kho không đủ!')
      return
    }
    dispatch({
      type: typeAction.DISPATCH_SL_THUOC_BN,
      payload: {
        index,
        value: newValue,
      },
    })
  }
  const onChangeNgayThuoc = (value) => {
    if (checkDuocSiChuaTraToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    dispatch({
      type: typeAction.DISPATCH_SO_LAN_THUOC_NHI,
      payload: value,
    })
  }
  const handleLieuDung = (ID) => (e) => {
    if (checkDuocSiChuaTraToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    const { value } = e.target
    let text = ('' + value)?.replace(/[^\d,.\/]/g, '')?.replaceAll(',', '.') //?.replaceAll(' ', '')
    // let valueNew = text
    if (text?.includes('/')) {
      const arr = text?.split('/')
      if (arr?.length > 2 || !arr[0]?.length) {
        ToastCus.fire({
          icon: 'error',
          title: 'Vui lòng kiểm tra lại!',
        })
        return
      }
      // if (arr?.length === 2 && arr[1]?.length)
      //   text = Math.round((Number(arr[0]) / Number(arr[1])) * 1000) / 1000
    } else if (text?.includes('..') || text?.includes('./') || text?.includes('/.')) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    const index = ThuocBN?.findIndex((items) => items.ID === ID)
    dispatch({
      type: typeAction.DISPATCH_LIEU_DUNG,
      payload: {
        index,
        value: text,
        ketoa: 1, // phân biệt giữa kê toa thuốc, và kê vắc xin
      },
    })
  }
  const handleLanDung = (ID) => (value) => {
    if (checkDuocSiChuaTraToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    // const { value } = e.target
    // let text = ('' + value)?.replace(/[^\d,.\/]/g, '')?.replaceAll(',', '.') //?.replaceAll(' ', '')
    // if (text?.includes('/')) {
    //   const arr = text?.split('/')
    //   if (arr?.length > 2) {
    //     ToastCus.fire({
    //       icon: 'error',
    //       title: 'Vui lòng kiểm tra lại!',
    //     })
    //     return
    //   }
    //   if (arr?.length === 2 && arr[1]?.length) text = Number(arr[0]) / Number(arr[1])
    // } else if (text?.includes('..') || text?.includes('./') || text?.includes('/.')) {
    //   ToastCus.fire({
    //     icon: 'error',
    //     title: 'Vui lòng kiểm tra lại!',
    //   })
    //   return
    // }
    const index = ThuocBN?.findIndex((items) => items.ID === ID)
    dispatch({
      type: typeAction.DISPATCH_LAN_DUNG,
      payload: {
        index,
        value: Math.ceil(Number(value)),
      },
    })
  }
  const handleghichu = (ID) => (e) => {
    if (checkDuocSiChuaTraToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    const { name, value } = e.target
    const index = ThuocBN?.findIndex((items) => items.ID === ID)
    dispatch({
      type: typeAction.DISPATCH_GHI_CHU_TOA_THUOC_BN,
      payload: {
        index,
        value,
      },
    })
  }
  const handleThuocVT = (id, opt) => {
    if (checkDuocSiChuaTraToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    if (ThuocBN?.find((item) => item?.idthuoc === opt?.idthuoc)) {
      openNotificationWithIcon('Chọn thuốc kê toa', 'Thuốc đã được chọn!')
      return
    }
    const index = ThuocVT?.findIndex((items) => items.idThuoc === opt?.idthuoc)
    // for (const items of ThuocBN) {
    //   if (items.ID === index) {
    //     openNotificationWithIcon('Chọn thuốc kê toa', 'Thuốc đã được chọn!')
    //     return
    //   }
    // }
    const data = {
      ID: ThuocVT[index].ID,
      ton: ThuocVT[index].tonKho,
      idthuoc: ThuocVT[index].idThuoc,
      tenBietDuoc: ThuocVT[index].thuocVatTu.tenBietDuoc,
      tenhoatchat: ThuocVT[index].thuocVatTu.tenHoatChat,
      duongDung: ThuocVT[index].thuocVatTu.duongDung,
      dvt: ThuocVT[index].thuocVatTu.dvt,
      hamLuong: ThuocVT[index].thuocVatTu.hamLuong,
      soLuong: ['lọ', 'chai', 'hủ', 'tuýp', 'tube']?.includes(
        ThuocVT[index].thuocVatTu.dvt?.replaceAll(' ', '')?.toLowerCase(),
      )
        ? 1
        : ThuocVT[index].tonKho >= NgayThuoc * 2
          ? NgayThuoc * 2
          : ThuocVT[index].tonKho, // nếu số lượng vược quá tông thì lấy tồn
      bsKeDon: infoUser?.tenNV,
      dvt: ThuocVT[index].thuocVatTu.dvt,
      //ngayKeDon: "2024-05-23T16:39:02.286Z",
      ghiChu: ThuocVT[index].thuocVatTu.hamLuong,
      donViDung: ThuocVT[index].thuocVatTu.donViDung,
      lieuDung: 1,
      // lieuDungTinh: 1,
      soLanNgay: 2,
      soLo: ThuocVT[index].soLo,
      idkhocn: ThuocVT[index].idKhoCN,
      idbnttc: DetailBN?.idbnttc,
      hanDung: ThuocVT[index].hanDung,
      soHoaDon: ThuocVT[index].soHoaDon,
      // maSoDangKy: ThuocVT[index].maSoDangKy,
      giaBan: ThuocVT[index].thuocVatTu.giaBan,
    }
    dispatch({
      type: typeAction.DISPATCH_THUOC_BN_DOCTOR,
      payload: data,
    })
    setTimeout(() => {
      inputRefs.current[ThuocBN?.length]?.soLanNgay?.focus()
    }, 100)
  }
  const handleKeyDown = (e, rowIndex, nextField) => {
    if (e.key === 'Enter') {
      e.preventDefault() // Ngăn hành vi mặc định

      const isLastRow = rowIndex === ThuocBN.length - 1

      if (nextField === 'soLanNgay') {
        inputRefs.current[rowIndex].lieuDung.focus()
      } else if (nextField === 'lieuDung') {
        inputRefs.current[rowIndex].soLuong.focus()
      } else if (nextField === 'soLuong') {
        inputRefs.current[rowIndex].ghiChu.focus()
      } else if (nextField === 'ghiChu') {
        if (isLastRow) {
          // Focus vào Select thông qua ref khi đến dòng cuối cùng
          selectRef.current?.focus()
        } else {
          inputRefs.current[rowIndex + 1].soLanNgay.focus()
        }
      }
    }
  }
  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Tên thuốc',
      dataIndex: 'tenBietDuoc',
      key: 'tenBietDuoc',
    },
    {
      title: 'Đơn vị',
      dataIndex: 'dvt',
      key: 'dvt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Lần/Ngày',
      dataIndex: 'soLanNgay',
      key: 'soLanNgay',
      width: 80,
      align: 'center',
      render: (text, record, index) => (
        <InputNumber
          ref={(el) => (inputRefs.current[index] = { ...inputRefs.current[index], soLanNgay: el })}
          onKeyDown={(e) => handleKeyDown(e, index, 'soLanNgay')}
          onChange={handleLanDung(record.ID)}
          min={0}
          step={1}
          value={text}
          size="small"
          className="w-full"
          readOnly={record.ton === 0}
        />
      ),
    },
    {
      title: 'Liều dùng',
      dataIndex: 'lieuDung',
      key: 'lieuDung',
      width: 80,
      align: 'center',
      render: (text, record, index) => (
        <Input
          // min={0}
          // type="number"
          ref={(el) => (inputRefs.current[index] = { ...inputRefs.current[index], lieuDung: el })}
          onKeyDown={(e) => handleKeyDown(e, index, 'lieuDung')}
          onChange={handleLieuDung(record.ID)}
          value={text}
          size="small"
          readOnly={record.ton === 0}
        />
      ),
    },
    {
      title: 'Đơn vị dùng',
      dataIndex: 'donViDung',
      key: 'donViDung',
      align: 'center',
      width: 90,
    },
    {
      title: 'Đường dùng',
      dataIndex: 'duongDung',
      key: 'duongDung',
      align: 'center',
      width: 100,
    },
    {
      title: 'SL',
      dataIndex: 'soLuong',
      key: 'soLuong',
      width: 65,
      align: 'center',
      render: (text, record, index) => (
        <Input
          ref={(el) => (inputRefs.current[index] = { ...inputRefs.current[index], soLuong: el })}
          onKeyDown={(e) => handleKeyDown(e, index, 'soLuong')}
          status={!text && 'error'}
          onChange={handleValue(record.ID)}
          value={text}
          min={0}
          type="number"
          size="small"
          readOnly={record.ton === 0}
        />
      ),
    },
    {
      title: 'Tồn kho',
      dataIndex: 'ton',
      key: 'ton',
      width: 80,
      align: 'center',
    },

    {
      title: 'Ghi chú',
      dataIndex: 'ghiChu',
      key: 'ghiChu',
      align: 'center',
      width: 150,
      render: (text, record, index) => (
        <Input
          ref={(el) => (inputRefs.current[index] = { ...inputRefs.current[index], ghiChu: el })}
          onKeyDown={(e) => handleKeyDown(e, index, 'ghiChu')}
          onChange={handleghichu(record.ID)}
          value={text}
          size="small"
          readOnly={record.ton === 0}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 30,
      render: (text, record, index) => (
        <>
          <CloseOutlined
            onClick={() => {
              if (checkDuocSiChuaTraToa) {
                ToastCus.fire({
                  icon: 'error',
                  title: 'Dược sĩ đang thao tác!',
                })
                return
              }
              dispatch({
                type: typeAction.DELETE_THUOC_BN,
                payload: record.ID,
              })
            }}
            className="text-red-500"
          />
        </>
      ),
    },
  ]

  const getHistoryToaThuoc = async () => {
    try {
      const { data } = await https.get(
        `BenhNhan/GetLichSuToaThuoc?IDBN=${DetailBN?.idbn}&IDCHINHANH=${PkDangNhap?.idChiNhanh}`,
      )
      setHistoryToaThuoc(data.filter((item) => item.ngaykham !== null))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (DetailBN) {
      getHistoryToaThuoc()
    }
  }, [DetailBN])

  const handleChangeHistoryToaThuoc = (value) => {
    if (checkDuocSiChuaTraToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    //clear toa thuoc cu
    dispatch({ type: typeAction.RESET_TOA_THUOC_BN_DOCTOR, payload: [] })

    //get toa thuoc lich su
    const toaThuoc = historyToaThuoc.find((item) => item.idbnttc === value)
    const groupedProducts = toaThuoc.toaThuocCopys.reduce((acc, product) => {
      const existingProduct = acc.find((p) => p.idthuoc === product.idthuoc)
      if (existingProduct) {
        // Nếu đã tồn tại sản phẩm với idThuoc này, cộng dồn số lượng
        existingProduct.tonKho += product.tonKho
        existingProduct.soLuong += product.soLuong
      } else {
        // Nếu chưa tồn tại, thêm sản phẩm mới vào danh sách
        acc.push({ ...product })
      }
      return acc
    }, [])

    for (const product of groupedProducts) {
      let quantityRequired = product.soLuong
      let matchedItems = ThuocVT.filter((item) => item.idThuoc === product.idthuoc) // Tìm các item trong thuocvattu có cùng idthuoc
      let dispatchCompleted = false
      if (!matchedItems.length) {
        //show luôn tồn kho = 0
        dispatch({
          type: typeAction.DISPATCH_THUOC_BN_DOCTOR,
          payload: {
            ID: `${product.idthuoc}-${moment().toISOString()}`,
            ton: product.tonKho,
            idthuoc: product.idthuoc,
            tenBietDuoc: product.tenBietDuoc,
            tenhoatchat: product.tenHoatChat,
            duongDung: product.duongDung,
            dvt: product.dvt,
            hamLuong: product.hamLuong,
            bsKeDon: infoUser?.tenNV,
            ghiChu: product.ghiChu,
            donViDung: product.donvidung,
            lieuDung: product.lieuDung,
            // lieuDungTinh: product.lieuDung,
            soLanNgay: product.soLanNgay,
            soLo: product.soLo,
            idkhocn: product.idkhocn,
            idbnttc: toaThuoc?.idbnttc,
            hanDung: product.hanDung,
            soHoaDon: product.soHoaDon,
            giaBan: product.donGia,
            soLuong: product.soLuong,
          },
        })
        continue // chuyển qua sm tiếp theo nếu không có này trong kho
      }
      for (const item of matchedItems) {
        if (dispatchCompleted) break
        const quantityToTake = Math.min(item.tonKho, quantityRequired)
        if (quantityRequired === 0) {
          dispatch({
            type: typeAction.DISPATCH_THUOC_BN_DOCTOR,
            payload: {
              ID: item.ID,
              ton: item.tonKho,
              idthuoc: product.idthuoc,
              tenBietDuoc: item.thuocVatTu.tenBietDuoc,
              tenhoatchat: item.thuocVatTu.tenHoatChat,
              duongDung: item.thuocVatTu.duongDung,
              dvt: item.thuocVatTu.dvt,
              hamLuong: item.thuocVatTu.hamLuong,
              bsKeDon: infoUser?.tenNV,
              ghiChu: product.ghiChu,
              donViDung: item.thuocVatTu.donViDung,
              lieuDung: product.lieuDung,
              // lieuDungTinh: product.lieuDung,
              soLanNgay: product.soLanNgay,
              soLo: item.soLo,
              idkhocn: item.idKhoCN,
              idbnttc: toaThuoc?.idbnttc,
              hanDung: item.hanDung,
              soHoaDon: item.soHoaDon,
              giaBan: product.donGia,
              soLuong: product.soLuong,
            },
          })
          dispatchCompleted = true
          break
        } // không chạy sản phẩm này nữa nếu đã lấy đủ số lượng skip >>;

        if (item.tonKho > 0) {
          dispatch({
            type: typeAction.DISPATCH_THUOC_BN_DOCTOR,
            payload: {
              ID: item.ID,
              ton: item.tonKho,
              idthuoc: product.idthuoc,
              tenBietDuoc: item.thuocVatTu.tenBietDuoc,
              tenhoatchat: item.thuocVatTu.tenHoatChat,
              duongDung: item.thuocVatTu.duongDung,
              dvt: item.thuocVatTu.dvt,
              hamLuong: item.thuocVatTu.hamLuong,
              bsKeDon: infoUser?.tenNV,
              ghiChu: product.ghiChu,
              donViDung: item.thuocVatTu.donViDung,
              lieuDung: product.lieuDung,
              // lieuDungTinh: product.lieuDung,
              soLanNgay: product.soLanNgay,
              soLo: item.soLo,
              idkhocn: item.idKhoCN,
              idbnttc: toaThuoc?.idbnttc,
              hanDung: item.hanDung,
              soHoaDon: item.soHoaDon,
              giaBan: product.donGia,
              soLuong: quantityToTake,
            },
          })
          quantityRequired -= quantityToTake
          if (quantityRequired <= 0) {
            dispatchCompleted = true
            break // Exit the loop for matched items
          }
        } else {
          dispatch({
            type: typeAction.DISPATCH_THUOC_BN_DOCTOR,
            payload: {
              ID: item.ID,
              ton: item.tonKho,
              idthuoc: product.idthuoc,
              tenBietDuoc: item.thuocVatTu.tenBietDuoc,
              tenhoatchat: item.thuocVatTu.tenHoatChat,
              duongDung: item.thuocVatTu.duongDung,
              dvt: item.thuocVatTu.dvt,
              hamLuong: item.thuocVatTu.hamLuong,
              bsKeDon: infoUser?.tenNV,
              ghiChu: product.ghiChu,
              donViDung: item.thuocVatTu.donViDung,
              lieuDung: product.lieuDung,
              // lieuDungTinh: product.lieuDung,
              soLanNgay: product.soLanNgay,
              soLo: item.soLo,
              idkhocn: item.idKhoCN,
              idbnttc: toaThuoc?.idbnttc,
              hanDung: item.hanDung,
              soHoaDon: item.soHoaDon,
              giaBan: product.donGia,
              soLuong: product.soLuong,
            },
          })
        }
      }
    }
  }

  const rowClassName = (record) => {
    return record.ton ? '' : 'bg-yellow-300 bg-opacity-50'
  }

  return (
    <div>
      {contextHolder}
      <div className="flex gap-2">
        <div className="flex  items-center">
          <label className="font-semibold w-20">Ngày thuốc:</label>
          <InputNumber className="w-14" min={1} value={NgayThuoc} onChange={onChangeNgayThuoc} />
        </div>
        <Select
          ref={selectRef}
          placeholder="Nhập tên thuốc"
          showSearch
          allowClear
          onChange={handleThuocVT}
          filterOption={(input, option) => {
            const label = option?.label ?? ''
            const tenBietDuoc = option?.tenBietDuoc ?? ''
            const searchText = input.toLowerCase()
            return (
              label.toLowerCase().includes(searchText) ||
              tenBietDuoc.toLowerCase().includes(searchText)
            )
          }}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          value={null}
          className="w-full"
          options={ThuocVT?.filter((item) => item?.tonKho > 0)?.map((items) => ({
            label: items.thuocVatTu.tenHoatChat,
            tenBietDuoc: items.thuocVatTu.tenBietDuoc,
            value: items.ID,
            ton: items.tonKho,
            dvt: items.thuocVatTu.dvt,
            hanDung: items.hanDung,
            idthuoc: items.idThuoc,
          }))}
          optionRender={(options) => (
            <ul className="flex">
              <li className="w-4/6 ">
                <p className="font-semibold w-full text-pretty">{options.data.label}</p>
                <span className="text-xs w-full text-gray-500"> ({options.data.tenBietDuoc})</span>
              </li>
              {/* <li className="w-1/6 border-x flex items-center justify-center text-center">
                {moment(options.data.hanDung).format('DD/MM/YYYY')}
              </li> */}
              <li className="w-1/6 border-x text-center flex items-center justify-center">
                {options.data.ton}
              </li>
              <li className="w-1/6 text-center flex items-center justify-center">
                {options.data.dvt}
              </li>
            </ul>
          )}
        />
        {DetailBN?.trangthaikhambenh !== 2 &&
          DetailBN?.trangthaikhambenh !== 3 &&
          DetailBN?.trangthaikhambenh !== 4 && (
            <Select
              placeholder="Chọn ngày đã khám"
              onChange={handleChangeHistoryToaThuoc}
              className="w-72"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={historyToaThuoc?.map((items) => ({
                label: moment(items.ngaykham).format('DD/MM/YYYY HH:mm'),
                value: items.idbnttc,
              }))}
              defaultValue={null}
            />
          )}
      </div>
      <div className="mt-2">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            }
          }}
        >
          <Table
            bordered
            scroll={{
              y:230
            }}
            columns={columns}
            pagination={false}
            dataSource={ThuocBN?.filter((item) => !item?.isGongKinh)}
            rowClassName={rowClassName}
            footer={(pageData) => {
              let totalPrice = 0
              pageData.forEach(({ giaBan, soLuong }) => {
                totalPrice += giaBan * (!_.isNaN(soLuong) ? soLuong : 0)
              })
              return (
                <div className="flex justify-end">
                  <p className="font-semibold">
                    Tổng tiền :{' '}
                    <span className="text-blue-500">{formatNumber(totalPrice)} VNĐ</span>
                  </p>
                </div>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default ToaThuoc

import {
  CloseOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  GoldOutlined,
  HddOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { ConfigProvider, Input, InputNumber, Select, Table, Tabs } from 'antd'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import ToastCus from '../../../../../utils/Toast'
import { v4 as uuidv4 } from 'uuid'
export default function LapPhieuXuat() {
  const [selectedFile, setSelectedFile] = useState([])
  const fileInputRef = useRef(null)
  const dropzoneRef = useRef(null)
  const selectRef = useRef(null)
  const data = [
    {
      tenHang: 'Vật tư A',
      maHang: 'A001',
      tonKho: 100,
      donVi: 'Cái',
      donGia: 50000,
      soLo: 'L001',
      hanDung: '01/01/2024',
      soHoaDon: 'HD001',
      soDangKy: 'DK001',
    },
    {
      tenHang: 'Vật tư B',
      maHang: 'B002',
      tonKho: 200,
      donVi: 'Hộp',
      donGia: 100000,
      soLo: 'L002',
      hanDung: '01/06/2024',
      soHoaDon: 'HD002',
      soDangKy: 'DK002',
    },
    {
      tenHang: 'Vật tư C',
      maHang: 'C003',
      tonKho: 150,
      donVi: 'Chai',
      donGia: 75000,
      soLo: 'L003',
      hanDung: '01/12/2024',
      soHoaDon: 'HD003',
      soDangKy: 'DK003',
    },
    // Thêm nhiều mục khác nếu cần
  ]

  const deleteFileById = (id) => {
    const updatedData = selectedFile.filter((item) => item.id !== id)
    setSelectedFile(updatedData)
  }
  const handleDivClick = () => {
    fileInputRef.current.click()
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px solid #000'
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
    handleFileChange(e.dataTransfer.files[0])
  }
  const handleFileSelect = (e) => {
    handleFileChange(e.target.files[0])
  }
  const handleFileChange = async (file) => {
    //  let file = event.target.files[0]
    // List of allowed file types
    const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp']
    if (!allowedFileTypes.includes(file.type)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ chấp nhận tệp là hình ảnh!',
      })
      fileInputRef.current.value = '' // clear the input
      return
    }
    // formik.setFieldValue('HoaDonFile', file)
    // console.log(formData);
    fileInputRef.current.value = ''
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      const localUrl = URL.createObjectURL(file)
      setSelectedFile((preState) => [
        ...preState,
        { base64: e.target.result, URL: localUrl, name: file.name, id: uuidv4(), file },
      ])
    }
  }
  return (
    <>
      <div>
        {' '}
        <div className="grid grid-cols-2 items-start gap-2 pr-5">
          <div className="grid grid-flow-row items-center gap-1">
            <div className="grid grid-cols-6 items-center gap-1">
              <label className="text-end font-semibold">
                <span className="text-red-500 text-xs">(*)</span>
                Người kiểm kho :
              </label>
              <Select
                filterOption={false}
                notFoundContent={null}
                placeholder="Tìm kiếm tên người kiểm kho, mã nhân viên"
                suffixIcon={<SearchOutlined className=" " />}
                options={[
                  { label: 'Nguyễn Văn A - 123456', maNhanVien: '123456' },
                  { label: 'Nguyễn Văn B - 123457', maNhanVien: '123457' },
                  { label: 'Nguyễn Văn C - 123458', maNhanVien: '123458' },
                ]}
                optionRender={(opt) => (
                  <ul>
                    <li>
                      <div className="flex justify-between w-full">
                        <p className="font-semibold">{opt.data?.label}</p>
                        <p> {opt.data?.maNhanVien} </p>
                      </div>
                    </li>
                  </ul>
                )}
                showSearch
                allowClear
                className="w-full col-span-5 text-black"
                size="small"
              />
            </div>
            <div className="grid grid-cols-6 items-center gap-1">
              <label className="text-end font-semibold">
                <span className="text-red-500 text-xs">(*)</span>
                Người nhập máy :
              </label>
              <Select
                filterOption={false}
                notFoundContent={null}
                placeholder="Tìm kiếm tên người nhập máy, mã nhân viên"
                suffixIcon={<SearchOutlined className=" " />}
                options={[
                  { label: 'Nguyễn Văn A - 123456', maNhanVien: '123456' },
                  { label: 'Nguyễn Văn B - 123457', maNhanVien: '123457' },
                  { label: 'Nguyễn Văn C - 123458', maNhanVien: '123458' },
                ]}
                optionRender={(opt) => (
                  <ul>
                    <li>
                      <div className="flex justify-between w-full">
                        <p className="font-semibold">{opt.data?.label}</p>
                        <p> {opt.data?.maNhanVien} </p>
                      </div>
                    </li>
                  </ul>
                )}
                showSearch
                allowClear
                className="w-full col-span-5 text-black"
                size="small"
              />
            </div>
            <div className="grid grid-cols-6 items-center gap-1">
              <label className="text-end font-semibold col-span-1">Ghi chú:</label>
              <Input className="col-span-5" size="small" name="ghiChu" />
            </div>
          </div>
          <div className="grid grid-flow-row items-center gap-1">
            <div className="grid grid-cols-3 items-center gap-1">
              <label className="text-end font-semibold col-span-1">
                <span className="text-red-500 text-xs">(*)</span>
                Phiếu điều chỉnh :
              </label>
              <Input className="col-span-2" size="small" name="ngayDieuChinh" />
            </div>
            <div className="grid grid-cols-3 items-center gap-1">
              <label className="text-end font-semibold col-span-1">Ngày điều chỉnh :</label>
              <Input
                className="col-span-2"
                size="small"
                readOnly
                variant="filled"
                value={moment().format('DD/MM/YYYY HH:mm:ss')}
              />
            </div>
            <div className="grid grid-cols-3 items-center gap-1">
              <label className="text-end font-semibold col-span-1">
                <span className="text-red-500 text-xs">(*)</span>Chi nhánh :
              </label>
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={[
                  { label: 'Chi nhánh 1', value: '1' },
                  { label: 'Chi nhánh 2', value: '2' },
                ]}
                className="col-span-2 w-full"
                size="small"
              />
            </div>
            <div className="grid grid-cols-3 items-center gap-1">
              <label className="text-end font-semibold col-span-1">
                <span className="text-red-500 text-xs">(*)</span>Kho điều chỉnh :
              </label>
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value) => {}}
                className="w-full col-span-2"
                size="small"
                options={[
                  { label: 'Kho 1', value: '1' },
                  { label: 'Kho 2', value: '2' },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="">
          <Tabs
            items={[
              {
                key: 1,
                label: (
                  <div className="flex items-center gap-2">
                    {' '}
                    <GoldOutlined style={{ fontSize: 18 }} /> Chi tiết hàng hóa
                  </div>
                ),
                children: (
                  <>
                    <div className="flex items-center mb-2 gap-2">
                      <Select
                        className="w-full"
                        placeholder="Nhập tên vật tư hàng hoá..."
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        dropdownRender={(menu) => (
                          <div>
                            <div
                              className="flex bg-gray-100"
                              style={{ padding: '8px', fontWeight: 'bold' }}
                            >
                              <span className="w-[30%] text-center">Tên hàng</span>
                              <span className="w-[10%] text-center">Mã hàng</span>
                              <span className="w-[15%] text-center">Tồn kho</span>
                              <span className="w-[5%] text-center">Đơn vị</span>
                              <span className="w-[10%] text-center">Đơn giá</span>
                              <span className="w-[5%] text-center">Số lô</span>
                              <span className="w-[5%] text-center">Hạn dụng</span>
                              <span className="w-[10%] text-center">Số hóa đơn</span>
                              <span className="w-[10%] text-center">Số đang ký</span>
                            </div>
                            <div style={{ marginBottom: '8px' }}>{menu}</div>
                          </div>
                        )}
                        optionLabelProp="label"
                        value={null}
                        options={
                          data &&
                          data.map((d, index) => ({
                            value: d.tenHang,
                            key: index,
                            data: d,
                            label: (
                              <ul className="flex no-underline">
                                <li className="w-[30%] text-center">{d.tenHang}</li>
                                <li className="w-[10%] text-center">{d.maHang}</li>
                                <li className="w-[15%] text-center">{d.tonKho}</li>
                                <li className="w-[5%] text-center">{d.donVi}</li>
                                <li className="w-[10%] text-center">{d.donGia}</li>
                                <li className="w-[5%] text-center">{d.soLo}</li>
                                <li className="w-[5%] text-center">{d.hanDung}</li>
                                <li className="w-[10%] text-center">{d.soHoaDon}</li>
                                <li className="w-[10%] text-center">{d.soDangKy}</li>
                              </ul>
                            ),
                          }))
                        }
                      />
                    </div>
                    <ConfigProvider
                      theme={{
                        token: {
                          padding: 4,
                          fontSize: 12,
                        },
                        components: {
                          Table: {
                            rowHoverBg: '#ecf0f1',
                            headerBg: '#e6e6e6',
                            footerBg: '#e6e6e6',
                            borderColor: '#BABABA',
                          },
                        },
                      }}
                    >
                      <Table
                        bordered
                        pagination={false}
                        scroll={{
                          y: 300,
                        }}
                        // dataSource={}
                        columns={[
                          {
                            key: 1,
                            title: 'Thông tin hàng hóa',
                            children: [
                              {
                                key: 1.1,
                                title: 'STT',
                                dataIndex: 'stt',
                                width: 40,
                                fixed: true,
                                align: 'center',
                              },
                              {
                                key: 1.2,
                                title: 'Tên hàng',
                                dataIndex: 'tenbietduoc',
                                fixed: true,
                              },
                              {
                                key: 1.3,
                                title: 'Mã hàng',
                                dataIndex: 'mathuoc',
                                width: 90,
                                fixed: true,
                              },
                            ],
                          },
                          {
                            key: 2,
                            title: 'Số lượng',
                            children: [
                              {
                                key: 2.1,
                                title: 'Tồn kho',
                                dataIndex: 'tonkho',
                                width: 75,
                                align: 'center',
                                editable: true,
                                render: (text, record, index) => (
                                  <>
                                    <InputNumber
                                      size="small"
                                      className="w-full"
                                      min={1}
                                      step={1}
                                      max={record?.tonkho}
                                      value={text}
                                    />
                                  </>
                                ),
                              },
                              {
                                key: 2.2,
                                title: 'Thưc tế',
                                dataIndex: 'thucte',
                                align: 'center',
                                width: 60,
                              },
                              {
                                key: 2.3,
                                title: 'Chênh lệch',
                                dataIndex: 'chenhlech',
                                align: 'right',
                                width: 90,
                                render: (text, record, index) => formattedNumber(text ?? 0),
                              },
                              {
                                key: 2.4,
                                title: 'Đơn vị',
                                dataIndex: 'dvt',
                                align: 'center',
                                width: 60,
                                render: (text, record, index) => text ?? 'Cái',
                              },
                            ],
                          },
                          {
                            key: 3,
                            title: '',
                            children: [
                              {
                                key: 3.1,
                                title: 'Đơn giá',
                                dataIndex: 'dongia',
                                width: 75,
                                align: 'center',
                              },
                              {
                                key: 3.2,
                                title: 'Thành tiền',
                                dataIndex: 'thanhtien',
                                width: 75,
                                align: 'center',
                              },
                              {
                                key: 3.3,
                                title: 'Số lô',
                                dataIndex: 'solo',
                                align: 'center',
                                width: 60,
                              },
                              {
                                key: 3.4,
                                title: 'Hạn dùng',
                                dataIndex: 'handung',
                                align: 'right',
                                width: 90,
                              },
                              {
                                key: 3.5,
                                title: 'Số hóa đơn',
                                dataIndex: 'sohoadon',
                                align: 'center',
                                width: 90,
                              },
                              {
                                key: 3.6,
                                title: 'Số đăng ký',
                                dataIndex: 'sodangky',
                                align: 'center',
                                width: 90,
                              },
                              {
                                key: 3.7,
                                title: 'Ghi chú',
                                dataIndex: 'ghichu',
                                align: 'center',
                                width: 90,
                              },
                            ],
                          },

                          {
                            key: 6,
                            title: '',
                            dataIndex: 'ACTION',
                            width: 40,
                            align: 'center',
                            fixed: 'right',
                            render: (text, record, index) => (
                              <>
                                <CloseOutlined className="text-lg text-red-500 cursor-pointer flex justify-center items-center" />
                              </>
                            ),
                          },
                        ]}
                        summary={(pageData) => {
                          let total = 0
                          pageData.forEach((item) => {
                            total += item.chenhlech
                          })

                          return (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={2}>
                                  Tổng cộng
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={1} />
                                <Table.Summary.Cell index={2} colSpan={1} align="right">
                                  tồn kho
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={3} colSpan={1} align="center">
                                  thực tế
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4} colSpan={1} align="right">
                                  chênh lệch
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={5} colSpan={1} align="center">
                                  đơn vị
                                </Table.Summary.Cell>
                                <Table.Summary.Cell
                                  index={6}
                                  colSpan={1}
                                  align="center"
                                ></Table.Summary.Cell>
                                <Table.Summary.Cell index={7} colSpan={1} align="center">
                                  thành tiền
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={8} colSpan={1} align="center" />
                                <Table.Summary.Cell index={9} colSpan={1} align="center" />
                                <Table.Summary.Cell index={10} colSpan={1} align="center" />
                                <Table.Summary.Cell index={11} colSpan={1} align="center" />
                                <Table.Summary.Cell index={12} colSpan={1} align="center" />
                                <Table.Summary.Cell index={13} colSpan={1} align="center" />
                              </Table.Summary.Row>
                            </>
                          )
                        }}
                      />
                    </ConfigProvider>
                  </>
                ),
              },
              {
                key: 2,
                label: (
                  <div className="flex items-center  gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
                      />
                    </svg>
                    <div className="flex gap-1 items-start">Đính kèm</div>
                  </div>
                ),
                children: (
                  <>
                    <ul className="flex flex-wrap gap-2 mb-2">
                      {selectedFile?.map((items, index) => (
                        <li
                          key={`upHinh${index}`}
                          className="w-[10rem] h-[10rem] overflow-hidden border rounded-md relative"
                        >
                          <DeleteOutlined
                            onClick={() => deleteFileById(items.id)}
                            className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                            style={{ fontSize: 18 }}
                          />
                          <a href={items?.URL} target="_blank">
                            <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                              <div className="relative "></div>
                              <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                                {items.name}
                              </p>
                            </div>
                            <img src={items.base64} className="w-full" alt={items.name} />
                          </a>
                        </li>
                      ))}
                    </ul>
                    <div
                      style={{
                        border: '2px dashed #cccccc',
                        borderRadius: '4px',
                        padding: '30px',
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                      className="border"
                      ref={dropzoneRef}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      onClick={handleDivClick}
                    >
                      <CloudUploadOutlined style={{ fontSize: 36 }} className="text-gray-500" />
                      <label style={{ cursor: 'pointer' }}>
                        <p className="font-semibold text-lg text-gray-500">
                          Thả tập tin vào đây hoặc bấm vào để tải lên.
                        </p>
                      </label>
                      <input
                        ref={fileInputRef}
                        id="fileInput"
                        type="file"
                        onChange={handleFileSelect}
                      />
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

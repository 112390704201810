import React, { useEffect, useState, useCallback } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import IconButton from '@mui/material/IconButton'
import CachedIcon from '@mui/icons-material/Cached'
import _ from 'lodash'
import { ConfigProvider, Input, Select, Table, Pagination, Timeline, Empty } from 'antd'
import { SearchOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  ListGroupsVTByTypes,
  getListCountry_Action,
  getListThuocVTByPageIndex_Action,
  listTypesThuocVT_Action,
} from '../../../store/actions/thuocVTAction'
import { formattedNumber } from '../../../utils/formattedNumber'
import { formatNumberVND } from '../../../utils/formatNumberVND'
import { getlistDoitac } from '../../../store/actions/NhapKhoAction'
import { useSearchParams } from 'react-router-dom'
import { https } from '../../../services/apiService'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

const GiaBan = () => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState(null)
  const [types, setTypes] = useState(null)
  const id_company = Number(localStorage.getItem('id_company'))
  const [company, setCompany] = useState(id_company)
  const [valueGroups, SetValueGroups] = useState(null)
  const [pageIndex, setPageIndex] = useState(1)
  const [showHistoryChange, setShowHistoryChange] = useState({
    name: '',
    data: [],
  })
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const { listThuocVT, typesVT, groupsVT } = useSelector((state) => state.thuocVTReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)

  const debounceDropDown = useCallback(
    _.debounce((keyword, company, types, valueGroups) => {
      dispatch(
        getListThuocVTByPageIndex_Action(
          keyword,
          company,
          types,
          valueGroups,
          useSearch.get('page'),
        ),
      )
    }, 300),
    [],
  )
  const handleCompany = (value) => {
    setCompany(value)
  }
  const handleSearchInput = (e) => {
    const { value } = e.target
    setSearch(value)
    SetSearchParams({ page: 1 })
    debounceDropDown(value, company, types, valueGroups)
  }
  const getListThuocVT = () => {
    dispatch(
      getListThuocVTByPageIndex_Action(search, company, types, valueGroups, useSearch.get('page')),
    )
  }
  const handleGroupsThuocVT = (value) => {
    SetValueGroups(value)
  }
  const handleTypesThuocVT = (value) => {
    // get list nhóm thuốc vật tư sau khi chọn loại
    setTypes(value)
    SetValueGroups(null)
    dispatch(ListGroupsVTByTypes(value))
  }
  useEffect(() => {
    dispatch(listTypesThuocVT_Action())
    dispatch(getlistDoitac())
    dispatch(getListCountry_Action())
  }, [])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      width: 50,
      align: 'center',
    },
    {
      title: 'Mã thuốc',
      dataIndex: 'maThuoc',
      width: 100,
    },
    {
      title: 'Tên thuốc',
      dataIndex: 'tenBietDuoc',
    },
    {
      title: 'Quy cách',
      dataIndex: 'quyCach',
      width: 150,
      align: 'right',
    },
    {
      title: 'Giá mua',
      dataIndex: 'giaMua',
      width: 90,
      align: 'right',
    },
    {
      title: 'Giá GPP',
      dataIndex: 'giagpp',
      width: 90,
      align: 'right',
    },
    {
      title: 'Giá bán',
      dataIndex: 'giaBan',
      width: 90,
      align: 'right',
    },
  ]
  useEffect(() => {
    dispatch(
      getListThuocVTByPageIndex_Action(search, company, types, valueGroups, useSearch.get('page')),
    )
  }, [useSearch.get('page')])

  const handleShowHistory = (record) => {
    ;(async () => {
      const result = await https.get(
        `/ThuocVatTuGiaBan/GetThuocVatTuGiaBanByIDTHUOC?idThuoc=${record.idThuoc}`,
      )
      setShowHistoryChange({
        name: record.tenBietDuoc,
        data: result.data,
      })
    })()
  }

  const renderHistory = (name, historyArr) => {
    return (
      <div className="p-2 ">
        <p className="font-semibold">
          Tên: <span>{name}</span>
        </p>
        <div className="py-4 my-2 ">
          {historyArr.length !== 0 ? (
            <Timeline
              items={historyArr.map((item) => ({
                dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                children: (
                  <>
                    <h2 className="font-bold">
                      {item.ngayCapNhat
                        ? moment(item.ngayCapNhat).format('DD/MM/YYYY HH:mm:ss')
                        : '-'}
                    </h2>
                    <p className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-5 text-gray-500 mr-2"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span>{item.nguoiCapNhat}</span>
                    </p>
                    <p className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-5 text-green-500 mr-2"
                      >
                        <path d="M12 7.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z" />
                        <path
                          fill-rule="evenodd"
                          d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 14.625v-9.75ZM8.25 9.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM18.75 9a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V9.75a.75.75 0 0 0-.75-.75h-.008ZM4.5 9.75A.75.75 0 0 1 5.25 9h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75V9.75Z"
                          clip-rule="evenodd"
                        />
                        <path d="M2.25 18a.75.75 0 0 0 0 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 0 0-.75-.75H2.25Z" />
                      </svg>
                      <span>{formattedNumber(item.giaBan)} VNĐ</span>
                    </p>
                  </>
                ),
              }))}
            />
          ) : (
            <Empty description="Chưa có lịch sử thay đổi" />
          )}
        </div>
      </div>
    )
  }
  return (
    <>
      <div className="flex gap-2 p-5 h-full bg-[#efefef]">
        <div className="w-3/4 h-full bg-white rounded-xl border">
          <div className="p-5">
            <div className="flex gap-5 items-center justify-between">
              <div className="flex gap-5 items-center">
                <div className="w-60 flex gap-1">
                  <Select
                    showSearch
                    className="w-full"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    onChange={handleCompany}
                    value={company}
                    options={listCompany?.map((items) => ({
                      label: items.tenct,
                      value: items.idct,
                    }))}
                  />
                </div>
                <div className="w-60 flex gap-1">
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    value={types}
                    onChange={handleTypesThuocVT}
                    placeholder="loại Thuốc vật tư"
                    className="w-full"
                    options={[
                      { label: 'Tất cả', value: null },
                      ...(typesVT
                        ? typesVT.map((items) => ({
                            label: items.phanLoai,
                            value: items.idPhanLoai,
                          }))
                        : []),
                    ]}
                  />
                </div>
                <div className="font-semibold w-80 flex gap-1">
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    onChange={handleGroupsThuocVT}
                    value={valueGroups}
                    options={[
                      { label: 'Tất cả', value: null },
                      ...(groupsVT
                        ? groupsVT?.map((items) => ({
                            label: items.nhom,
                            value: items.idNhom,
                          }))
                        : []),
                    ]}
                    placeholder="Nhóm thuốc vật tư"
                    className="w-full truncate"
                  />
                </div>
                <div>
                  <Input
                    value={search}
                    placeholder="Tìm kiếm"
                    onChange={handleSearchInput}
                    prefix={<SearchOutlined />}
                  />
                </div>
                <IconButton
                  onClick={getListThuocVT}
                  color="primary"
                  aria-label="add to shopping cart"
                >
                  <CachedIcon />
                </IconButton>
              </div>
            </div>
            <div className="mt-2">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                    fontSize: 14,
                  },
                  components: {
                    Table: {
                      rowHoverBg: '#ecf0f1',
                      headerBg: '#e6e6e6',
                      footerBg: '#e6e6e6',
                      borderColor: '#BABABA',
                    },
                  },
                }}
              >
                <Table
                  pagination={false}
                  onRow={(record) => ({
                    onClick: () => handleShowHistory(record),
                  })}
                  bordered
                  columns={columns}
                  dataSource={listThuocVT?.data.map(
                    (
                      {
                        idThuoc,
                        maThuoc,
                        tenBietDuoc,
                        tenHoatChat,
                        dvt,
                        quyCach,
                        quyCachDongGoi,
                        giaBan,
                        giaMua,
                        maSoDangKy,
                        duongDung,
                        giagpp,
                      },
                      index,
                    ) => ({
                      idThuoc,
                      STT: ++index + (pageIndex - 1) * 20,
                      maThuoc,
                      tenBietDuoc,
                      tenHoatChat,
                      dvt,
                      quyCach,
                      quyCachDongGoi,
                      giaBan: formatNumberVND(giaBan),
                      giaMua: formatNumberVND(giaMua),
                      giagpp: formatNumberVND(giagpp),
                      maSoDangKy,
                      duongDung,
                    }),
                  )}
                />
              </ConfigProvider>
              <div className="flex justify-end">
                <Pagination
                  className="mt-5 "
                  showSizeChanger={false}
                  current={+useSearch.get('page')}
                  simple
                  defaultCurrent={1}
                  pageSize={20}
                  total={20 * listThuocVT?.totalPage}
                  onChange={(page, pagesize) => {
                    SetSearchParams({ page })
                    setPageIndex(page)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/4 h-full bg-white rounded-xl border">
          <div className="border-b p-5">
            <h2 className="text-xl font-semibold text-gray-400">Lịch sử giá bán</h2>
          </div>
          {showHistoryChange.name
            ? renderHistory(showHistoryChange.name, showHistoryChange.data)
            : null}
        </div>
      </div>
    </>
  )
}

export default GiaBan

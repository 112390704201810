import React from 'react'
import { Modal, Button, Input, Form } from 'antd'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addDonViTinh } from '../../../../store/actions/donvitinhAction'

const ModalAddDonvitinh = ({ isOpen, onRequestClose, onAddSuccess }) => {
  const dispatch = useDispatch()

  const resetForm = () => {
    formik.resetForm() // Clear the form
    onAddSuccess() // Call the callback to refresh data
    onRequestClose()
  }
  const formik = useFormik({
    initialValues: {
      donViTinh: '',
      ghichu: '',
    },
    validationSchema: Yup.object({
      donViTinh: Yup.string().required('Đơn vị tính không được bỏ trống'),
    }),
    onSubmit: (values) => {
      dispatch(addDonViTinh(values, resetForm))
    },
  })

  return (
    <Modal
      title="Thêm đơn vị tính"
      visible={isOpen}
      onCancel={onRequestClose}
      footer={[
        <Button key="back" onClick={onRequestClose}>
          Quay lại
        </Button>,
        <Button key="submit" type="primary" onClick={formik.handleSubmit}>
          Thêm
        </Button>,
      ]}
    >
      <Form layout="vertical" onFinish={formik.handleSubmit}>
        <Form.Item
          required
          label="Đơn vị tính"
          validateStatus={formik.errors.donViTinh && formik.touched.donViTinh ? 'error' : ''}
          help={formik.errors.donViTinh && formik.touched.donViTinh ? formik.errors.donViTinh : ''}
        >
          <Input
            type="text"
            id="donViTinh"
            name="donViTinh"
            value={formik.values.donViTinh}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <Form.Item label="Ghi chú">
          <Input
            type="text"
            id="ghichu"
            name="ghichu"
            value={formik.values.ghichu}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ModalAddDonvitinh

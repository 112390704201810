import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Tabs } from 'antd'
import Synthetic from './Synthetic/Synthetic'
import Detail from './Detail/Detail'
import CaKham from './CaKham/CaKham'
import NguonKhach from './NguonKhach/NguonKhach'
import LoaiKham from './LoaiKham/LoaiKham'
import TheoDoiKhachMoi from './KhachMoi/KhachMoi'
import Doanhthu from './DoanhThu/DoanhThu'
import DoiTuong from './DoiTuong/DoiTuong'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
const LuocKham = () => {
  const [ChuyenKhoa, setChuyenKhoa] = useState([])
  const { infoUser } = useSelector((state) => state.userReducer)
  const BLOCK_VIEWS_GROUPS_USER = [3]
  const isBlockView = useMemo(() => {
    return BLOCK_VIEWS_GROUPS_USER.includes(infoUser?.idNhom)
  }, [infoUser])
  //* ID = 3 ( NHÓM LỄ TÂN )
  //-------------- lấy danh sách chuyên kho ------------//
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchChuyenKhoa()
  }, [])
  return (
    <>
      <div className="p-3 bg-[#EFEFEF] h-full">
        <div className="bg-white h-full p-2 rounded-xl border">
          <Tabs
            items={[
              {
                key: 1,
                label: 'Tổng hợp',
                children: <Synthetic ChuyenKhoa={ChuyenKhoa} isBlockView={isBlockView} />,
              },
              {
                key: 2,
                label: 'Doanh thu',
                children: <Doanhthu ChuyenKhoa={ChuyenKhoa} isBlockView={isBlockView} />,
              },
              {
                key: 3,
                label: 'Chi tiết',
                children: <Detail ChuyenKhoa={ChuyenKhoa} isBlockView={isBlockView} />,
              },
              {
                key: 4,
                label: 'Theo dõi ca khám',
                children: <CaKham ChuyenKhoa={ChuyenKhoa} isBlockView={isBlockView} />,
              },
              {
                key: 5,
                label: 'Theo dõi nguồn khách',
                children: <NguonKhach ChuyenKhoa={ChuyenKhoa} isBlockView={isBlockView} />,
              },
              {
                key: 6,
                label: 'Loại khám',
                children: <LoaiKham ChuyenKhoa={ChuyenKhoa} isBlockView={isBlockView} />,
              },
              {
                key: 7,
                label: 'Theo dõi khách mới',
                children: <TheoDoiKhachMoi ChuyenKhoa={ChuyenKhoa} isBlockView={isBlockView} />,
              },
              {
                key: 8,
                label: 'Theo dõi đối tượng',
                children: <DoiTuong isBlockView={isBlockView} />,
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default LuocKham

import { Tabs } from 'antd'
import React from 'react'
import History from './History'
import Images from './Images'
const ImageSA = ({ image, setImage, listImagePrint, setListImagePrint }) => {
  return (
    <div className="p-2 min-h-[400px]">
      <Images
        image={image}
        setImage={setImage}
      />
    </div>
  )
}

export default ImageSA

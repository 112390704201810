import { Input, Modal, Select, Popconfirm } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateLoaiHangHoa } from '../../../store/actions/loaiHangHoaAction'
import { editLoaiHangHoaSchema } from '../../../schemas/editLoaiHangHoaSchema'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import moment from 'moment'
function EditNhan({ isEditNhan, setIsEditNhan }) {
  const dispatch = useDispatch()
  const now = moment()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoHangHoa } = useSelector((state) => state.loaiHangHoaReducer)

  const maQuyenSuaLoai = 'QHT423'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenSuaLoai:
        formik.handleSubmit()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenSuaLoai:
        //
        break
      default:
        return false
    }
  }

  const handleCancel = () => {
    setIsEditNhan(false)
  }
  const handleSubmit = (value) => {
    handleCancel()
    dispatch(updateLoaiHangHoa(value))
  }
  const onChangeSelected = (value) => {
    formik.setFieldValue('loai', value)
  }

  const loai = [
    { label: 'Thuốc', value: 'THUOC' },
    { label: 'Hàng hóa', value: 'HANGHOA' },
  ]
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idPhanLoai: infoHangHoa?.idPhanLoai,
      phanLoai: infoHangHoa?.phanLoai,
      loai: infoHangHoa?.loai,
      ghiChu: infoHangHoa?.ghiChu,
      nguoiSua: infoUser?.tenNV,
      ngaySua: now.format('YYYY-MM-DD'),
    },
    validationSchema: editLoaiHangHoaSchema,
    onSubmit: (value) => handleSubmit(value),
  })
  return (
    <Modal
      onOk={() =>
        dispatch(putCheckQuyenThaoTacAction(maQuyenSuaLoai, null, actionIsTrue, actionIsFalse))
      }
      okText="Cập nhật"
      cancelText="Huỷ bỏ"
      open={isEditNhan}
      onCancel={handleCancel}
      title={
        <p className="font-semibold text-xl w-full text-center text-blue-500">CHI TIẾT HÀNG HÓA</p>
      }
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium flex justify-between">
            <p>
              Tên hàng hóa <span className="text-red-500">(*)</span>
            </p>
          </label>
          <Input
            name="phanLoai"
            onChange={formik.handleChange}
            status={formik.errors.phanLoai && formik.touched.phanLoai ? 'error' : null}
            value={formik.values.phanLoai}
          />
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
            Loại <span className="text-red-500">(*)</span>
          </label>
          <Select
            placeholder="chọn nhóm người dùng"
            onChange={onChangeSelected}
            value={formik.values.loai}
            options={loai?.map((item) => ({
              label: item.label,
              value: item.value,
            }))}
            className="w-full"
            name="loai"
            status={formik.errors.loai && formik.touched.loai ? 'error' : null}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">Ghi chú</label>
          <Input name="ghiChu" onChange={formik.handleChange} value={formik.values.ghiChu} />
        </div>
      </div>
    </Modal>
  )
}

export default EditNhan

import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  BNThuoc: null,
  // service: null,
  // infoBN: null,
  // BNDetail: null,
  PTVacXin: [],
}

const xuatVacXinReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.DISPATCH_BN_LAY_THUOC_XVX:
        draft.BNThuoc = payload
        break
      case typeAction.DISPATCH_PHIEU_THU_VACXIN:
        draft.PTVacXin = payload
        break
      // case typeAction.DISPATCH_SERVICE_NHA_THUOC:
      //   draft.service = payload
      //   break
      // case typeAction.DISPATCH_INFO_BN_NHA_THUOC:
      //   draft.infoBN = payload
      //   break
      // case typeAction.DISPATCH_DETAI_BN_NHA_THUOC:
      //   draft.BNDetail = payload
      //   break
      // case typeAction.RESET_STORE_DRUGSTORE:
      //   draft.BNDetail = null
      //   draft.infoBN = null
      //   draft.service = null
      //   break
      // case typeAction.INCREMENT_COUNT_DRUGSTORE:
      //   draft.service.DanhSachThuoc[payload.index].soluong = payload.value
      //   --draft.service.DanhSachThuoc[payload.index].tonkho
      //   break
      // case typeAction.DECREMENT_COUNT_DRUGSTORE:
      //   draft.service.DanhSachThuoc[payload.index].soluong = payload.value
      //   ++draft.service.DanhSachThuoc[payload.index].tonkho
      //   break
      default:
        return state
    }
  })
}

export default xuatVacXinReducer

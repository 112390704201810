import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DatePicker, Input, Select } from 'antd'
import dayjs from 'dayjs'
import CustomInput from './CustomInput'
import InputHook from './InputHook'
import { inputData } from './Data'
import { branchService } from '../../../../services/branch/branchService'
import { NhapKhoService } from '../../../../services/NhapKho/NhapKhoService'
import 'moment/locale/vi'
moment.locale('vi')
const InfoPhieu = ({ formik, show }) => {
  const { infoPTNhap } = useSelector((state) => state.NhapKhoReducer)
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const [idBranch, setIdBranch] = useState(null)
  const [branch, setBranch] = useState([])
  const [kho, setKho] = useState([])

  //----------fetch chi nhánh------------//
  const fetchBranhchByIdCompany = async () => {
    const { data } = await branchService.getListBranch(ID_COMPANY)
    setBranch(data)
  }
  //--------------fetch kho chi nhanh ----------//
  const fetchKhoChiNhanh = async (id) => {
    try {
      const { data } = await NhapKhoService.getListKhoNhap(id)
      setKho(data)
    } catch (error) {
      console.log(error)
    }
  }
  //----handle selected ----------//
  const handleSeleted = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  //---------chọn chi nhánh ------//
  const onChangeBranch = (value) => {
    setIdBranch(value)
    fetchKhoChiNhanh(value)
    formik.setFieldValue('IdKhoNhap', null)
  }
  useEffect(() => {
    setIdBranch(infoPTNhap?.idChiNhanhNhan)
    fetchKhoChiNhanh(infoPTNhap?.idChiNhanhNhan)
  }, [infoPTNhap])
  useEffect(() => {
    fetchBranhchByIdCompany()
  }, [])
  return (
    <>
      <div className="border-b p-2 flex justify-between items-center">
        <h2 className="font-semibold text-lg">Thông tin</h2>
        <p className=" text-blue-500 font-semibold px-2 rounded-md  text-center">
          {infoPTNhap?.tenTrangThai}
        </p>
      </div>
      <form className="text-start flex flex-col gap-2 p-2">
        {inputData.map((item) => (
          <div className="flex">
            <InputHook defaultValue={infoPTNhap?.[item.name]} label={item.label} type={item.type} />
          </div>
        ))}
        <div className="flex">
          {!show ? (
            <InputHook
              defaultValue={infoPTNhap?.tenChiNhanhNhan}
              label={'Chi nhánh'}
              type={'text'}
            />
          ) : (
            <>
              {infoPTNhap?.trangThai === 5 ? (
                <>
                  <label className="font-semibold text-gray-500 w-1/3">Chi nhánh: </label>
                  <Select
                    showSearch
                    onChange={onChangeBranch}
                    value={idBranch}
                    placeholder="chọn chi nhánh nhận"
                    options={branch?.map((item) => ({
                      label: item.tenChiNhanh,
                      value: item.idChiNhanh,
                    }))}
                    className="w-2/3"
                  />
                </>
              ) : (
                <InputHook
                defaultValue={infoPTNhap?.tenChiNhanhNhan}
                label={'Chi nhánh'}
                type={'text'}
              />
              )}
            </>
          )}
        </div>
        <div className="flex">
          {!show ? (
            <InputHook
              defaultValue={infoPTNhap?.tenKhoNhap}
              label={
                <>
                  <span className="text-red-500">(*) </span>Kho nhận
                </>
              }
              type={'text'}
            />
          ) : (
            <>
              {infoPTNhap?.trangThai === 5 ? (
                <>
                  <label className="font-semibold text-gray-500 w-1/3">
                    {' '}
                    <span className="text-red-500">(*) </span>Kho nhận:{' '}
                  </label>
                  <Select
                    value={formik.values.IdKhoNhap}
                    options={kho?.map((item) => ({
                      label: item.tenKho,
                      value: item.idKhoCN,
                    }))}
                    onChange={handleSeleted('IdKhoNhap')}
                    className="w-2/3"
                  />
                </>
              ) : (
                <InputHook
                  defaultValue={infoPTNhap?.tenKhoNhap}
                  label={
                    <>
                      <span className="text-red-500">(*) </span>Kho nhận
                    </>
                  }
                  type={'text'}
                />
              )}
            </>
          )}
        </div>
        <div className="flex">
          <CustomInput
            formik={formik}
            id="TenPhieu"
            defaultValue={infoPTNhap?.tenPhieu}
            show={show}
            name="TenPhieu"
            type="text"
            label="Tên phiếu"
          />
        </div>
        <div className="flex">
          <label className="font-semibold text-gray-500 w-1/3">Phương thức:</label>
          {!show ? (
            <Input
              variant="borderless"
              readOnly
              name="TenPhieu"
              className="w-2/3"
              size="small"
              value={infoPTNhap?.idPhuongThuc === 1 ? 'Thanh toán' : 'Công nợ'}
            />
          ) : (
            <Select
              className="w-2/3"
              size="small"
              onChange={handleSeleted('IdPhuongThuc')}
              options={[
                {
                  label: 'Thanh Toán',
                  value: 1,
                },
                {
                  label: 'Công nợ',
                  value: 2,
                },
              ]}
              value={formik.values.IdPhuongThuc}
            />
          )}
        </div>
        <div className="flex">
          <label className="font-semibold text-gray-500 w-1/3">Hình thức</label>
          {!show ? (
            <Input
              variant="borderless"
              readOnly
              name="TenPhieu"
              className="w-2/3"
              size="small"
              value={infoPTNhap?.idHinhThuc === 1 ? 'Tiền mặt' : 'Chuyển khoản'}
            />
          ) : (
            <Select
              onChange={handleSeleted('IdHinhThuc')}
              className="w-2/3"
              size="small"
              options={[
                {
                  label: 'Tiền mặt',
                  value: 1,
                },
                {
                  label: 'Chuyển khoản',
                  value: 2,
                },
              ]}
              value={formik.values.IdHinhThuc}
            />
          )}
        </div>
        <div className="flex">
          {/* <span className="text-red-500">(*)</span> */}
          <CustomInput
            formik={formik}
            id="SoHoaDon"
            defaultValue={infoPTNhap?.soHoaDon}
            show={show}
            name="SoHoaDon"
            type="text"
            label={
              <>
                {' '}
                <span className="text-red-500">(*) </span>Số hoá đơn
              </>
            }
          />
        </div>
        <div className="flex">
          <label className="font-semibold text-gray-500 w-1/3">Ngày hoá đơn</label>
          {!show ? (
            <Input
              variant="borderless"
              readOnly
              name="TenPhieu"
              className="w-2/3"
              size="small"
              value={
                infoPTNhap?.ngayHoaDon ? moment(infoPTNhap?.ngayHoaDon).format('DD/MM/YYYY') : ''
              }
            />
          ) :<>
          
          {infoPTNhap?.trangThai === 5 ? (
                <>
                   <DatePicker
              allowClear={false}
              onChange={(date, dateString) => {
                formik.setFieldValue(
                  'NgayHoaDon',
                  moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                )
              }}
              size="small"
              value={
                formik.values.NgayHoaDon != '' ? dayjs(formik.values.NgayHoaDon, 'YYYY-MM-DD') : ''
              }
              format="DD/MM/YYYY"
              className="w-2/3"
            />
                </>
              ) : (
                <Input
                variant="borderless"
                readOnly
                name="TenPhieu"
                className="w-2/3"
                size="small"
                value={
                  infoPTNhap?.ngayHoaDon ? moment(infoPTNhap?.ngayHoaDon).format('DD/MM/YYYY') : ''
                }
              />
              )}
          </>}
        </div>
        <div className="flex">
          <label className="font-semibold text-gray-500 w-1/3">Link hoá đơn</label>
          {!show ? (
            <a
              href={infoPTNhap?.linkHoaDon}
              className="w-2/3 text-blue-500 underline truncate"
              target="_blank"
              rel="noopener noreferrer"
            >
              {infoPTNhap?.linkHoaDon}
            </a>
          ) : (
            <Input
              name="LinkHoaDon"
              onChange={formik.handleChange}
              value={show ? formik.values.LinkHoaDon : infoPTNhap?.linkHoaDon}
              size="small"
              className="w-2/3"
            />
          )}
        </div>
        <div className="flex">
          <label className="font-semibold text-gray-500 w-1/3">Ghi chú</label>
          <Input.TextArea
            variant={show ? 'outlined' : 'borderless'}
            readOnly={!show}
            name="GhiChu"
            onChange={formik.handleChange}
            value={show ? formik.values.GhiChu : infoPTNhap?.ghiChu}
            size="small"
            className="w-2/3"
          />
        </div>
      </form>
    </>
  )
}

export default InfoPhieu

import moment from 'moment'
import { formatNumberVND } from '../../../../../utils/formatNumberVND'

export const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    align: 'center',
    width: 50,
    render: (text, record, index) => ++index,
  },
  {
    title: 'Ngày',
    dataIndex: 'ngaythanhtoan',
    key: 'ngaythanhtoan',
    align: 'center',
    width: 100,
    render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
  },
  {
    title: 'Chi nhánh',
    dataIndex: 'tenchinhanh',
    key: 'tenchinhanh',
    // width: 250,
  },
  {
    title: 'Tổng phiếu',
    dataIndex: 'tongphieu',
    key: 'tongphieu',
    align: 'center',
    width: 250,
  },
  {
    title: 'Thời gian trung bình',
    dataIndex: 'thoigiankhamtrungbinh',
    key: 'thoigiankhamtrungbinh',
    align: 'center',
    width: 250,
    render: (text) => (text !== '::' ? text : '-'),
  },
]

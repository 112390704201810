import { https } from '../apiService'
export const phanAnhServices = {
  postPhanAnh: (formData, idcn) =>
    https.post(`CPPhanAnhKhachHang/AddCPPhanAnhKhachHang?idcn=${idcn}`, formData),
  putPhanAnh: (form) => https.put(`CPPhanAnhKhachHang/UpdatePhanAnhKhachHang`, form),
  putTrangThaiPhanAnh: (idpakh, trangthai, nguoisua) =>
    https.put(
      `CPPhanAnhKhachHang/UpdateTrangThai?idpakh=${idpakh}&trangthai=${trangthai}&nguoisua=${nguoisua}`,
    ),
  getInfoPhanAnh: (idpakh) =>
    https.get(`CPPhanAnhKhachHang/GetInfoPhanAnhKhachHang`, { params: { idpakh } }),
  getListLoaiPhanAnh: () => https.get(`CPPhanAnhKhachHang/GetLoaiPhanAnhKhachHang`),
  getListBoPhanXuLy: () => https.get(`CPPhanAnhKhachHang/GetChiNhanhVanPhong`), ////
  getListPhanAnhByTrangThai: (idcn, trangthai) =>
    https.get(`CPPhanAnhKhachHang/GetDanhSachPhanAnhKhachHang`, { params: { idcn, trangthai } }),
  getListPhanAnhDaHoanThanh: (idcn, tuNgay, denNgay) =>
    https.get(`CPPhanAnhKhachHang/GetDanhSachDaPhanAnhKhachHang`, {
      params: { idcn, tuNgay, denNgay },
    }),
  getListPhanAnhKhachHang: (keyword, idcn, idct, nguoitiepnhan, tuNgay, denNgay) =>
    https.get(`CPPhanAnhKhachHang/GetAllPhanAnhKhachHang`, {
      params: { keyword, idcn, idct, nguoitiepnhan, tuNgay, denNgay },
    }),
  uploadHinhPhanAnh: (form) => https.put(`CPPhanAnhKhachHang/UpdateFilePhanAnhKhachHang`, form),
  deleteHinhPhanAnh: (idpakh, fileKetQua) =>
    https.delete(
      `CPPhanAnhKhachHang/DeleteFilePhanAnhKhachHang?idpakh=${idpakh}&fileKetQua=${fileKetQua}`,
    ),
  postListXuLy: (list) => https.post(`CPXuLyPhanAnh/AddCPXuLyPhanAnh`, list),
  getListXuLyPhanAnh: (keyword, idcn, idct, nguoitiepnhan) =>
    https.get(`CPXuLyPhanAnh/GetAllXuLyPhanAnh`, {
      params: { keyword, idcn, idct, nguoitiepnhan },
    }),
  putTrangThaiXuLy: (idxl, trangthai, nguoisua) =>
    https.put(
      `CPXuLyPhanAnh/UpdateTrangThaiXuLy?idxl=${idxl}&trangthai=${trangthai}&nguoisua=${nguoisua}`,
    ),
  putThongTinXuLy: (form) => https.put(`CPXuLyPhanAnh/UpdateCPXuLyPhanAnh`, form),
  putTrangThaiListXuLy: (list) => https.put(`CPXuLyPhanAnh/UpdateListTrangThaiXuLy`, list),
}

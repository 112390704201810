import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Modal,
  Divider,
  notification,
  Tabs,
  ConfigProvider,
  Table,
  DatePicker,
  Tooltip,
  Input,
  Select,
  Button,
} from 'antd'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')
function CardTTVTang({ listLoaiTheTang, formik }) {
  const { infoBnTang, detailCard } = useSelector((state) => state.cartReducer)
  const handleOnChangLoaiThe = (value, opt) => {
    formik.setValues((prev) => ({
      ...prev,
      tenthe: opt?.infolt?.loaithe,
      sothang: opt?.infolt?.sothang,
      idchuyenkhoa: opt?.infolt?.idchuyenkhoa,
      idnhom: opt?.infolt?.idnhom,
      tiensudung: opt?.infolt?.tiensudung,
      tungay: infoBnTang?.ngaySinh ? moment(infoBnTang?.ngaySinh).format('YYYY-MM-DD') : null,
      denngay: infoBnTang?.ngaySinh
        ? moment(infoBnTang?.ngaySinh)
            .add(opt?.infolt?.sothang ?? 0, 'months')
            .format('YYYY-MM-DD')
        : null,
      idlt: opt?.infolt?.idlt,
    }))
  }
  const handleOnChangeNgayHieuLuc = (date, dateString) => {
    formik.setFieldValue('tungay', moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'))
    formik.setFieldValue(
      'denngay',
      moment(dateString, 'DD/MM/YYYY')
        .add(formik?.values?.sothang, 'months')
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
    )
  }
  return (
    <div className="grid grid-flow-row w-full items-center gap-2">
      <div className="grid grid-cols-2 items-center">
        <div className="flex justify-end items-center gap-1 pr-[0.6rem]">
          <label>Mã thẻ:</label>
          <Input
            size="small"
            className="w-[19.4rem]"
            value={formik?.values?.mathe}
            disabled
            style={{ color: 'black' }}
          />
        </div>
        <div className="flex justify-end items-center gap-1">
          <label>Loại thẻ:</label>
          {/* <Input size="small" className="w-[19.4rem]" /> */}
          <Select
            status={infoBnTang && !formik?.values?.idlt ? 'error' : ''}
            disabled={!infoBnTang}
            onChange={handleOnChangLoaiThe}
            options={listLoaiTheTang?.map((items) => ({
              label: items.loaithe,
              value: items.idlt,
              infolt: items,
            }))}
            value={formik?.values?.idlt}
            size="small"
            className="w-[19.4rem] text-start"
          />
        </div>
      </div>
      <div className="grid grid-cols-3 items-center">
        <div className="flex justify-end items-center gap-1">
          <label>Thành viên:</label>
          <Input
            type="number"
            min={1}
            step={1}
            max={2}
            size="small"
            className="w-[11.1rem]"
            value={formik?.values?.sothanhvien}
            status={infoBnTang && !formik?.values?.sothanhvien ? 'error' : ''}
            disabled={!infoBnTang || !formik?.values?.idlt}
            onChange={(e) => formik.setFieldValue('sothanhvien', Number(e.target.value))}
            style={{ color: 'black' }}
          />
        </div>
        <div className="flex justify-end items-center gap-1">
          <label>Phương thức:</label>
          <Input
            size="small"
            className="w-[11.1rem]"
            value={'Thanh toán'}
            disabled
            style={{ color: 'black' }}
          />
        </div>
        <div className="flex justify-end items-center gap-1">
          <label>Đơn giá:</label>
          <Input
            size="small"
            className="w-[11.1rem]"
            value={formik?.values?.tiensudung}
            disabled
            style={{ color: 'black' }}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 items-center">
        <div className="flex justify-end items-center gap-1">
          <label>Số tháng:</label>
          <Input
            size="small"
            className="w-[11.1rem]"
            value={formik?.values?.sothang}
            disabled
            style={{ color: 'black' }}
          />
        </div>
        <div className="flex justify-end items-center gap-1">
          <label>Ngày hiệu lực:</label>
          <DatePicker
            placeholder=" "
            allowClear={false}
            disabled
            //disabled={!infoBnTang || !formik?.values?.idlt}
            // maxDate={dayjs(now)}
            minDate={detailCard?.tungay && dayjs(detailCard?.tungay)}
            maxDate={detailCard?.denngay && dayjs(detailCard?.denngay)}
            onChange={handleOnChangeNgayHieuLuc}
            value={
              formik?.values?.idlt &&
              formik?.values?.tungay &&
              dayjs(formik?.values?.tungay, 'YYYY-MM-DD')
            }
            format={'DD/MM/YYYY'}
            style={{ color: 'black' }}
            size="small"
            className="w-[11.1rem] rounded"
          />
          {/* <Input
            size="small"
            className="w-[11.1rem]"
            value={formik?.values?.tungay && moment(formik?.values?.tungay).format('DD/MM/YYYY')}
            disabled
            style={{ color: 'black' }}
          /> */}
        </div>
        <div className="flex justify-end items-center gap-1">
          <label>Ngày hết hạn:</label>
          <Input
            size="small"
            className="w-[11.1rem]"
            value={formik?.values?.denngay && moment(formik?.values?.denngay).format('DD/MM/YYYY')}
            disabled
            style={{ color: 'black' }}
          />
        </div>
      </div>
      {/* <div className="grid grid-cols-2 items-center">
        <div className="flex justify-end items-center gap-1 pr-[0.6rem]">
          <label>Người tạo:</label>
          <Input size="small" className="w-[19.4rem]" />
        </div>
        <div className="flex justify-end items-center gap-1">
          <label>Người bán:</label>
          <Input size="small" className="w-[19.4rem]" />
        </div>
      </div> */}
      {/* <div className="grid grid-cols-2 items-center">
        <div className="flex justify-end items-center gap-1 pr-[0.6rem]">
          <label>Người nộp tiền:</label>
          <Input size="small" className="w-[19.4rem]" />
        </div>
        <div className="flex justify-end items-center gap-1">
          <label>SĐT:</label>
          <Input size="small" className="w-[19.4rem]" />
        </div>
      </div>
      <div className="grid grid-cols-2 items-center">
        <div className="flex justify-end items-center gap-1 pr-[0.6rem]">
          <label>Địa chỉ:</label>
          <Input size="small" className="w-[19.4rem]" />
        </div>
        <div className="flex justify-end items-center gap-1">
          <label>Lý do:</label>
          <Input size="small" className="w-[19.4rem]" />
        </div>
      </div> */}
    </div>
  )
}

export default CardTTVTang

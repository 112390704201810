import { Button, ConfigProvider, Input, Table, Popconfirm, message, Select } from 'antd'
import {
  PlusOutlined,
  VerticalAlignBottomOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AddNew from './AddNew'
import EditModal from './EditModal'
import { exportExcelformat } from '../../../utils/export/exportExcelFormat'
import { companyService } from '../../../services/Company/companyService'
import { khoVTservice } from '../../../services/khoVT/khoVTservice'
import { dichVuService } from '../../../services/dichVu/dichVuService'
import { formattedNumber } from '../../../utils/formattedNumber'
import { deleteGiaVatTuDVThuThuatAction } from '../../../store/actions/hoaHongAction'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import ToastCus from '../../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const DanhMucHoaHong = () => {
  const dispatch = useDispatch()
  const id_company = Number(localStorage.getItem('id_company'))
  const id_branch = Number(localStorage.getItem('BRANH_LOGIN'))
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [congTy, setCongTy] = useState(id_company)
  const [listCongTy, setListCongTy] = useState([])
  const [chuyenKhoa, setChuyenKhoa] = useState('all')
  const [listChuyenKhoa, setListChuyenKhoa] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [listDataGVT, setListDataGVT] = useState([])
  const [keyword, setKeyword] = useState(null)
  const [rowActive, setRowActive] = useState(null)

  const maQuyenXemDs = 'QHT410'
  const maQuyenXuatExcel = 'QHT411'
  const maQuyenThemHh = 'QHT409'
  const maQuyenSuaHh = 'QHT412'
  const maQuyenXoaHh = 'QHT413'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        getListGiaVatTuDVThuThuat()
        break
      case maQuyenXuatExcel:
        exportExcel()
        break
      case maQuyenThemHh:
        setOpen(true)
        break
      case maQuyenSuaHh:
        setOpenEdit(true)
        break
      case maQuyenXoaHh:
        confirm()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXuatExcel:
        //
        break
      case maQuyenThemHh:
        setOpen(false)
        break
      case maQuyenSuaHh:
        setOpenEdit(false)
        break
      case maQuyenXoaHh:
        //
        break
      default:
        return false
    }
  }

  const confirm = (e) => {
    if (!rowActive?.idhh) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng thử lại!',
      })
      return
    }
    dispatch(deleteGiaVatTuDVThuThuatAction(rowActive?.idhh, onLoad))
  }
  const exportExcel = () => {
    if (!listDataGVT?.length) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng thử lại!',
      })
      return
    }
    const formattedData = listDataGVT?.map((item, index) => ({
      STT: ++index,
      'Tên thủ thuật': item?.tenthuthuat,
      'Người tạo': item?.tennguoitao,
      'Ngày tạo': item?.ngaytao ? moment(item?.ngaytao).format('DD/MM/YYYY HH:mm:ss') : '-',
      'Người sửa': item?.tennguoisua,
      'Ngày sửa': item?.ngaysua ? moment(item?.ngaysua).format('DD/MM/YYYY HH:mm:ss') : '-',
      'Giá vật tư': formattedNumber(item?.giavattu),
    }))
    const name = 'Danh mục hoa hồng'
    exportExcelformat(formattedData, name)
  }
  const getListCongTy = async () => {
    try {
      const { data } = await companyService.getAllListCompany()
      setListCongTy(data)
    } catch (error) {
      console.log(error)
    }
  }
  const getListChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setListChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }

  const getListGiaVatTuDVThuThuat = async () => {
    try {
      setIsLoading(true)
      const { data } = await dichVuService.getListGiaVatTuDVThuThuat(
        congTy,
        2, //chỉ lấy chuyên khoa sản
        keyword?.length ? keyword?.trim() : null,
      )
      setListDataGVT(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const onLoad = () => {
    setKeyword((prev) => (prev?.length ? prev?.trim() : prev))
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        onLoad()
        getListCongTy()
        // getListChuyenKhoa()
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])
  return (
    <div className="p-3 bg-[#EFEFEF] border  h-full">
      <div
        className="h-full p-2 bg-white border rounded-xl"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <div className="flex justify-between mb-2">
          <div className="flex gap-2">
            <Select
              disabled={isLoading}
              placeholder="Chọn công ty..."
              className="w-[17rem]"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={listCongTy?.map((items) => ({
                label: items?.tenct,
                value: items?.idct,
              }))}
              value={congTy}
              onChange={(value) => setCongTy(value)}
            />
            {/* <Select
              disabled={isLoading}
              placeholder="Chọn chuyên khoa..."
              className="w-[17rem]"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                {
                  label: 'Tất cả chuyên khoa',
                  value: 'all',
                },
                ...listChuyenKhoa?.map((items) => ({
                  label: items?.tenChuyenKhoa,
                  value: items?.idChuyenKhoa,
                })),
              ]}
              value={chuyenKhoa}
              onChange={(value) => setChuyenKhoa(value)}
            /> */}
            <Input
              disabled={isLoading}
              size="medium"
              className="w-[17rem]"
              placeholder="Nhập từ khóa tìm kiếm..."
              prefix={<SearchOutlined className="pr-1" />}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <Button
              type="primary"
              shape="circle"
              icon={<ReloadOutlined />}
              loading={isLoading}
              onClick={onLoad}
            ></Button>
          </div>
          <div className="flex gap-2">
            <Button
              disabled={!listDataGVT?.length}
              // onClick={exportExcel}
              onClick={() =>
                dispatch(
                  putCheckQuyenThaoTacAction(maQuyenXuatExcel, null, actionIsTrue, actionIsFalse),
                )
              }
              icon={<VerticalAlignBottomOutlined />}
            >
              Excel
            </Button>
            <Button
              onClick={() =>
                dispatch(
                  putCheckQuyenThaoTacAction(maQuyenThemHh, null, actionIsTrue, actionIsFalse),
                )
              }
              type="primary"
              icon={<PlusOutlined />}
            >
              Thêm
            </Button>
          </div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            loading={isLoading}
            bordered
            pagination={{
              pageSize: 20,
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  setRowActive(record)
                }, // click row
              }
            }}
            dataSource={listDataGVT}
            columns={[
              {
                key: 1,
                title: 'STT',
                dataIndex: 'stt',
                width: 50,
                align: 'center',
                render: (text, _, index) => ++index,
              },
              {
                key: 2,
                title: 'Tên thủ thuật',
                dataIndex: 'tenthuthuat',
              },
              {
                key: 3,
                title: 'Người tạo',
                dataIndex: 'tennguoitao',
                width: 220,
              },
              {
                key: 4,
                title: 'Ngày tạo',
                dataIndex: 'ngaytao',
                align: 'center',
                width: 140,
                render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
              },
              {
                key: 5,
                title: 'Người sửa',
                dataIndex: 'tennguoisua',
                width: 220,
              },
              {
                key: 6,
                title: 'Ngày sửa',
                dataIndex: 'ngaysua',
                align: 'center',
                width: 140,
                render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
              },
              {
                key: 7,
                title: 'Giá vật tư',
                dataIndex: 'giavattu',
                align: 'right',
                width: 150,
                render: (text) => formattedNumber(text ?? 0),
              },
              {
                key: 7,
                title: '',
                dataIndex: 'action',
                width: 100,
                align: 'center',
                render: () => (
                  <ul className="flex justify-center gap-4">
                    <li
                      onClick={() =>
                        dispatch(
                          putCheckQuyenThaoTacAction(
                            maQuyenSuaHh,
                            null,
                            actionIsTrue,
                            actionIsFalse,
                          ),
                        )
                      }
                      className="text-green-500 bg-green-100 p-1 rounded-md cursor-pointer hover:bg-green-500 hover:text-white duration-200"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-4"
                      >
                        <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                      </svg>
                    </li>
                    <li className="text-red-500 bg-red-100 p-1 rounded-md cursor-pointer hover:bg-red-500 hover:text-white duration-200">
                      <Popconfirm
                        title="Xoá giá vật tư"
                        description="Bạn có muốn xoá giá vật tư của dịch vụ này?"
                        // onConfirm={confirm}
                        onConfirm={() =>
                          dispatch(
                            putCheckQuyenThaoTacAction(
                              maQuyenXoaHh,
                              null,
                              actionIsTrue,
                              actionIsFalse,
                            ),
                          )
                        }
                        okText="Xoá"
                        cancelText="Huỷ"
                        placement="bottomRight"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="size-4"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Popconfirm>
                    </li>
                  </ul>
                ),
              },
            ]}
          />
        </ConfigProvider>
      </div>
      <AddNew
        open={open}
        setOpen={setOpen}
        listCongTy={listCongTy}
        listChuyenKhoa={listChuyenKhoa}
        onLoad={onLoad}
      />
      <EditModal
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        listCongTy={listCongTy}
        listChuyenKhoa={listChuyenKhoa}
        rowActive={rowActive}
        onLoad={onLoad}
      />
    </div>
  )
}

export default DanhMucHoaHong

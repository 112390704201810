import { ContainerOutlined, SearchOutlined } from '@ant-design/icons'
import CachedIcon from '@mui/icons-material/Cached'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { IconButton, Tooltip } from '@mui/material'
import { Button, Checkbox, ConfigProvider, DatePicker, Input, Select, Space, Table } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LayoutApp from '../../../HOCs/LayoutApp'
import { congtyCRMService } from '../../../services/congtyCRM/congtyCRMService'
import {
  getLienHeCRM,
  getLienHeCRMByidNv,
  getThongTinLienHeCRM,
} from '../../../store/actions/lienheCRMAction'
import DrawerChiTiet from './DrawerChiTiet/DrawerChiTiet'
import ModalAddChiTiet from './Modal/ModalAddChiTiet'
import Highlighter from 'react-highlight-words'
import { debounce } from 'lodash'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const formatMoment = 'YYYY-MM-DD'
const dateFormat = 'DD-MM-YYYY'

function LienHe(props) {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { dsLienHe } = useSelector((state) => state.lienheCRMReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [openChiTiet, setOpenChiTiet] = useState(false)
  const [dateFrom, setDateFrom] = useState(now.format(formatMoment))
  const [dateTo, setDateTo] = useState(now.format(formatMoment))
  const [showChitiet, setShowChitiet] = useState(false)
  const [comapany, setCompany] = useState([])
  const [idCompany, setIdCompany] = useState(-1)
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [companySearch, setCompanySearch] = useState('')

  const handleDatePicker = (name) => (Date, dateString) => {
    // format date
    const value = moment(dateString, dateFormat).format(formatMoment)
    name === 'from' ? setDateFrom(value) : setDateTo(value)
  }

  const handleCancel = () => {
    setOpenChiTiet(false)
  }

  const handleReload = () => {
    dispatch(getLienHeCRM(idCompany !== -1 ? idCompany : null))
  }
  //-----------fetch company ----//
  const fetchCompany = async () => {
    try {
      const { data } = await congtyCRMService.getAllCtyCRM()
      setCompany(data)
    } catch (err) {
      console.log(err)
    }
  }
  const exportToExcel = async () => {}

  useEffect(() => {
    fetchCompany()
  }, [])

  const onLoad = () => {
    dispatch(getLienHeCRMByidNv(infoUser.idnv, companySearch))
  }
  useEffect(() => {
    if (infoUser?.idnv) {
      dispatch(getLienHeCRMByidNv(infoUser.idnv, ''))
    }
  }, [infoUser])

  const handleShowChitiet = (item) => {
    setShowChitiet(true)
    dispatch(getThongTinLienHeCRM(item.idttlh))
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 80,
      align: 'center',
      key: 'stt',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Công ty',
      dataIndex: 'tencongty',
      key: 'tencongty',
      ...getColumnSearchProps('tencongty'),
    },
    {
      title: 'Người liên hệ',
      dataIndex: 'nguoilienhe',
      key: 'nguoilienhe',
    },
    {
      title: 'Điện thoại liên hệ',
      dataIndex: 'dienthoailienhe',
      align: 'center',
      key: 'dienthoailienhe',
      width: 150,
    },
    {
      title: 'Email liên hệ',
      dataIndex: 'emaillienhe',
      align: 'center',
      key: 'emaillienhe',
      width: 150,
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
    },
    {
      title: 'Sử dụng',
      dataIndex: 'ghichu',
      key: 'ghichu',
      align: 'center',
      render: (text, record, index) => <Checkbox checked={record.sudung} />,
      width: 100,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'trangthai',
      align: 'center',
      key: 'trangthai',
      width: 150,
    },
    {
      title: '',
      dataIndex: 'action',
      width: 80,
      align: 'center',
      key: 'action',
    },
  ]

  const handleSearchDebounce = useCallback(
    debounce((value) => {
      if (!value) {
        dispatch(getLienHeCRMByidNv(infoUser?.idnv, ''))
        return
      }
      dispatch(getLienHeCRMByidNv(infoUser?.idnv, value))
    }, 300),
    [],
  )

  const handleChangeCompanySearch = (value) => {
    setCompanySearch(value)
    handleSearchDebounce(value)
  }

  return (
    <>
      <div className="p-2 bg-[#E8E8E8] h-full">
        <div
          className="bg-white p-2 rounded-md h-full"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <div className="mb-2 flex ">
            <div className="flex gap-1 items-center">
              <Select
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                value={companySearch}
                showSearch
                onChange={handleChangeCompanySearch}
                options={[
                  { label: 'Tất cả', value: '' },
                  ...comapany?.map((item) => ({
                    label: item.tenct,
                    value: item.idct,
                  })),
                ]}
                className="w-80"
              />
              {/* <ul className="flex gap-1">
                <li className="flex gap-1 justify-center items-center">
                  <DatePicker
                    format={dateFormat}
                    allowClear={false}
                    maxDate={dayjs(now)}
                    defaultValue={dayjs(dateFrom)}
                    onChange={handleDatePicker('from')}
                    className="w-full"
                  />
                </li>
                <li li className="flex gap-1 justify-center items-center">
                  <p className="font-semibold">-</p>
                  <DatePicker
                    onChange={handleDatePicker('to')}
                    maxDate={dayjs(now)}
                    format={dateFormat}
                    allowClear={false}
                    defaultValue={dayjs(dateTo)}
                    className="w-full"
                  />
                </li>
              </ul> */}
              {/* <div>
                <IconButton sx={{ marginRight: 2 }} onClick={handleReload} color="primary">
                  <CachedIcon />
                </IconButton>
              </div> */}
            </div>
            {/* <div className="font-semibold ">
              <Button
                onClick={exportToExcel}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
              >
                Xuất Excel
              </Button>
            </div> */}
            {/* <div className="ml-auto w-80">
              <Input
                // value={search}
                placeholder="Tìm kiếm"
                onChange={handleSearchInput}
                prefix={<SearchOutlined />}
                className="w-full"
              />
            </div> */}
            <div className="px-2 ml-auto">
              <Button type="primary" onClick={() => setOpenChiTiet(true)}>
                Thêm chi tiết liên hệ
              </Button>
            </div>
          </div>
          <div>
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
        
              }}
            >
              <Table
                pagination={{
                  pageSize: 20,
                  showSizeChanger: false,
                }}
                bordered
                columns={columns}
                dataSource={dsLienHe?.map((item) => {
                  if (item.hasOwnProperty('crmThongTinLienHe')) {
                    return {
                      ...item.crmThongTinLienHe,
                      action: (
                        <ul className="flex gap-2 justify-around">
                          <li className="text-lg">
                            <Tooltip title="Chi tiết" color="#108ee9">
                              <ContainerOutlined
                                onClick={() => handleShowChitiet(item)}
                                className="text-xl text-[#108ee9]  cursor-pointer"
                              />
                            </Tooltip>
                          </li>
                        </ul>
                      ),
                    }
                  } else {
                    return {
                      ...item,
                      action: (
                        <ul className="flex gap-2 justify-around">
                          <li className="text-lg">
                            <Tooltip title="Chi tiết" color="#108ee9">
                              <ContainerOutlined
                                onClick={() => handleShowChitiet(item)}
                                className="text-xl text-[#108ee9]  cursor-pointer"
                              />
                            </Tooltip>
                          </li>
                        </ul>
                      ),
                    }
                  }
                })}
              />
            </ConfigProvider>
          </div>
        </div>
        {openChiTiet && (
          <ModalAddChiTiet onLoad={onLoad} openChiTiet={openChiTiet} handleCancel={handleCancel} />
        )}
        <DrawerChiTiet
          showChitiet={showChitiet}
          setShowChitiet={setShowChitiet}
          onLoad={handleReload}
        />
      </div>
    </>
  )
}

LienHe.propTypes = {}

export default LienHe

import React, { useEffect, useState } from 'react'
// import Header from '../components/layout/Header/Header'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Layout, Menu, Button, theme, ConfigProvider } from 'antd'
import ButtonLang from '../components/common/ButtonLang/ButtonLang'
import Notification from '../components/common/Notificaton/Notification'
import SmsIcon from '@mui/icons-material/Sms'
import Avatars from '../components/common/Avatar/Avatars'
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings'
import GroupIcon from '@mui/icons-material/Group'
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import StorefrontIcon from '@mui/icons-material/Storefront'
import VaccinesIcon from '@mui/icons-material/Vaccines'
import MedicationIcon from '@mui/icons-material/Medication'
import AddModeratorIcon from '@mui/icons-material/AddModerator'
import AddCardIcon from '@mui/icons-material/AddCard'
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import LineAxisIcon from '@mui/icons-material/LineAxis'
import CategoryIcon from '@mui/icons-material/Category'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import GroupsIcon from '@mui/icons-material/Groups'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import HandymanIcon from '@mui/icons-material/Handyman'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import DescriptionIcon from '@mui/icons-material/Description'
import { getListMenu, logoutUser, updateInfoUserToStore } from '../store/actions/userAction'
import logo from '../assets/images/logo/MemLogo_315_Logo.png'
import Password from '../components/layout/Password/Password'
import ButtonLogout from '../components/common/ButtonLogout/ButtonLogout'
import { infoBranchLogin } from '../store/actions/BranchAction'
import LoadingPage from '../components/common/LoadingPage/LoadingPage'
import ModalCheckIn from '../components/common/ModalCheckIn/ModalCheckIn'
import CanVasBarCode from '../components/common/canvasBarCode'
import * as typeAction from '../store/constants/constants'
import Version from '../components/common/Version/Version'
import ModalCheckOut from '../components/common/ModalCheckOut/ModalCheckOut'
import { versionService } from '../services/version/versionService'
import Cookies from 'js-cookie'
import useIdleTimer from '../Auth/Hook/UseIdleTimer'
import ModalChangeBranch from '../components/common/ModalChangeBranch/ModalChangeBranch'
import useWebSocket from '../websockets/useWebsocket'

const { Header, Sider, Content } = Layout
const LayoutApp = (props) => {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const navigate = useNavigate()
  const token = Cookies.get('access-token')
  const { infoUser } = useSelector((state) => state.userReducer)
  const [collapsed, setCollapsed] = useState(false)
  const [isOpenModalPassWord, setIsOpenModalPassWord] = useState(false)
  const [version, setVersion] = useState(null)
  const { menu } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const location = useLocation()
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const dispatch = useDispatch()
  // show modal password
  const showModalPassword = () => {
    setIsOpenModalPassWord(true)
  }
  // cancel modal password
  const cancelModalPassword = () => {
    setIsOpenModalPassWord(false)
  }
  // show modal version
  const showVersion = () => {
    dispatch({
      type: typeAction.OPEN_MODAL_VERSION,
    })
  }
  const fetchNewVersion = async () => {
    try {
      const { data } = await versionService.getNewVersion()
      setVersion(data)
    } catch (error) {
      console.log(error)
    }
  }
  const allChildMenus = menu?.reduce((acc, currentMenu) => {
    return acc.concat(JSON.parse(currentMenu.chilD_MENUS))
  }, [])
  const handleLogoutUser = () => {
    dispatch(logoutUser(navigate, infoUser?.dangNhap.idDangNhap))
  }
  //-----lấy dánh sách menu nu ------------
  // Tìm TENMENU tương ứng với location.pathname
  const currentMenu = allChildMenus?.find((childMenu) => childMenu.LINKS === location.pathname)
  const currentMenuName = currentMenu ? currentMenu.TENMENU : ''
  useEffect(() => {
    infoUser && dispatch({ type: typeAction.PHANQUYEN_CHECK_HIEULUC_TAIKHOAN, infoUser: infoUser })
  }, [window.location.pathname, infoUser])
  useEffect(() => {
    fetchNewVersion()
    dispatch(infoBranchLogin(BranchLogin))
    // dispatch(getListMenu(infoUser?.taiKhoan)) // lấy danh sách MENU theo tài khoản
    token && dispatch(updateInfoUserToStore(token)) // nếu đã từng dăng nhập lưu info user và store reducer
  }, [])
  const renderChildMenu = (chilD_MENUS) => {
    const childMenu = JSON.parse(chilD_MENUS)
    const render = childMenu
      .filter((element) => {
        // Check phong kham
        //check theo idnhom
        if (infoUser.idNhom === 28 || infoUser.idNhom === 37 || infoUser.idNhom === 1) {
          return true
        } else {
          // if (element.IDMENU === 333 && PkDangNhap?.idChuyenKhoa === 4) {
          //   //pk chuyên khoa mắt thì ẩn menu sổ khám bệnh
          //   return false
          // }
          if (
            (element.IDMENU === 377 || element.IDMENU === 369) &&
            PkDangNhap?.idChuyenKhoa !== 2
          ) {
            return false
          }
          if (
            (element.IDMENU === 532 || element.IDMENU === 427) &&
            PkDangNhap?.idChuyenKhoa !== 4
          ) {
            return false
          }
          return true
        }
      })
      .map((element) => ({
        key: element.IDMENU,
        label: (
          <Link
            to={
              element.IDMENU === 282 ? element.LINKS : element.IDMENU === 63 ? '#' : element.LINKS
            }
            onClick={element.IDMENU === 63 ? showModalPassword : undefined}
            target={element.IDMENU === 282 ? '_blank' : undefined}
          >
            {element.TENMENU}
          </Link>
        ),
      }))

    return render
  }
  useWebSocket((message) => {
    if (message === 'MenuUpdated') {
      // Khi nhận được thông báo, gọi lại API getMenu
      dispatch(getListMenu(infoUser?.taiKhoan))  // Dispatch action để gọi API getMenu
    }
  });
  useIdleTimer(handleLogoutUser, 7200000) // Đặt thời gian không hoạt động là 2 tiếng
  return (
    <Layout className="hidden lg:flex">
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              darkItemBg: '#00AFEF',
              // darkPopupBg:'#00AFEF',
              darkSubMenuItemBg: '#0396CD',
            },
          },
        }}
      >
        <Sider
          trigger={null}
          style={{
            backgroundColor: '#00AFEF',
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
          }}
          collapsible
          collapsed={collapsed}
        >
          <div className=" h-12 flex  justify-center items-center">
            {!collapsed ? (
              <Link to="/">
                <h2 className="text-lg font-semibold text-white">Hệ Thống 315</h2>
              </Link>
            ) : (
              <Link to="/">
                <img className="w-16" src={logo} alt="logo nhi dong 315" />
              </Link>
            )}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['1']}
            style={{ paddingBottom: '50px' }} // Add padding to the bottom
            items={menu?.map(({ idMenu, tenMenu, chilD_MENUS }) => ({
              key: idMenu,
              icon:
                idMenu === 1 ? (
                  <div>
                    <DisplaySettingsIcon />
                  </div>
                ) : idMenu === 2 ? (
                  <div>
                    <GroupIcon />
                  </div>
                ) : idMenu === 3 ? (
                  <div>
                    <MarkunreadMailboxIcon />
                  </div>
                ) : idMenu === 4 ? (
                  <div>
                    <WarehouseIcon />
                  </div>
                ) : idMenu === 5 ? (
                  <div>
                    <StorefrontIcon />
                  </div>
                ) : idMenu === 6 ? (
                  <div>
                    <AddModeratorIcon />
                  </div>
                ) : idMenu === 7 ? (
                  <div>
                    <MedicationIcon />
                  </div>
                ) : idMenu === 8 ? (
                  <div>
                    <VaccinesIcon />
                  </div>
                ) : idMenu === 9 ? (
                  <div>
                    <AddCardIcon />
                  </div>
                ) : idMenu === 10 ? (
                  <div>
                    <ContentPasteSearchIcon />
                  </div>
                ) : idMenu === 11 ? (
                  <div>
                    <LineAxisIcon />
                  </div>
                ) : idMenu === 12 ? (
                  <div>
                    <CategoryIcon />
                  </div>
                ) : idMenu === 13 ? (
                  <div>
                    <AutoAwesomeIcon />
                  </div>
                ) : idMenu === 14 ? (
                  <div>
                    <SupportAgentIcon />
                  </div>
                ) : idMenu === 81 ? (
                  <div>
                    <GroupsIcon />
                  </div>
                ) : idMenu === 357 ? (
                  <div>
                    <SmsIcon />
                  </div>
                ) : idMenu === 437 ? (
                  <div>
                    <LocalShippingIcon />
                  </div>
                ) : idMenu === 463 ? (
                  <div>
                    <ContactMailIcon />
                  </div>
                ) : idMenu === 421 ? (
                  <div>
                    <HandymanIcon />
                  </div>
                ) : idMenu === 533 ? (
                  <div>
                    <CalendarMonthIcon />
                  </div>
                ) : idMenu === 624 ? (
                  <div>
                    <DescriptionIcon />
                  </div>
                ) : (
                  <div>
                    <LocalHospitalIcon />
                  </div>
                ),
              label: tenMenu,
              children: JSON.parse(chilD_MENUS)[0].IDMENU ? renderChildMenu(chilD_MENUS) : '',
            }))}
          />
          <div
            onClick={showVersion}
            style={{
              width: !collapsed ? 200 : 80,
              transition: 'all .2s',
            }}
            className=" h-12 flex bg-[#00AFEF] cursor-pointer hover:bg-[#0396CD] fixed  bottom-0  justify-center items-center"
          >
            <h2 className="text-gray-200">
              {!collapsed ? 'Phiên bản' : 'V'} {version?.phienban}
            </h2>
          </div>
        </Sider>
      </ConfigProvider>
      <Layout
        style={{
          marginLeft: !collapsed ? 200 : 80,
          transition: 'all .3s',
        }}
      >
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <div className="flex justify-between ">
            <div className="flex gap-3 items-center">
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
              />
              <div className=" flex flex-col gap-1">
                <h2 className="font-semibold leading-none">Chi nhánh</h2>
                <p className="text-gray-400 leading-none">{PkDangNhap?.tenChiNhanh}</p>
              </div>
              <div className="flex items-center gap-1 translate-y-2">
                {currentMenuName && <span className="text-base text-gray-400">/</span>}
                <h2 className="font-semibold leading-none">{currentMenuName}</h2>
              </div>
            </div>
            <div className="px-5 ">
              <div className="flex justify-between items-center ">
                <ul className=" flex justify-center gap-4  items-center">
                  <li className="w-10">
                    <ButtonLang label={''} />
                  </li>
                  <li>
                    <Notification />
                  </li>
                  <li>
                    <Avatars showModalPassword={showModalPassword} />
                  </li>
                  <li>
                    <ButtonLogout />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Header>
        <Content
          style={{
            minHeight: 'calc(100vh - 64px)',
            background: colorBgContainer,
            borderRadius: 0,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
      <ModalCheckOut />
      <Password
        isOpenModalPassWord={isOpenModalPassWord}
        cancelModalPassword={cancelModalPassword}
      />
      <ModalCheckIn />
      <LoadingPage />
      <CanVasBarCode />
      <Version />
      <ModalChangeBranch />
    </Layout>
  )
}

export default LayoutApp

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { DatePicker, Select, ConfigProvider, Table, TreeSelect, Button } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import * as XLSX from 'xlsx'
import IconButton from '@mui/material/IconButton'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { reportService } from '../../../../../services/Report/reportService'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import { branchService } from '../../../../../services/branch/branchService'
import { khoVTservice } from '../../../../../services/khoVT/khoVTservice'
import { exportExcelformat } from '../../../../../utils/export/exportExcelFormat'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

const Synthetic = () => {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState([BranchLogin])
  const [data, setData] = useState([])
  const [ChuyenKhoa, setChuyenKhoa] = useState([])
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const dispatch = useDispatch()

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const onLoad = () => {
    fetchReport()
  }

  const exportToExcel = () => {
    const formatData = data?.map((item) => ({
      Ngày: moment(item.ngay).format('DD/MM/YYYY'),
      'Ngày khai trương': moment(item.ngayKhaiTruong).format('DD/MM/YYYY'),
      'Chi nhánh': item.tenChiNhanh,
      'Tổng tiền vốn': formattedNumber(item.tongTienVon),
      'Số phiếu lẻ': formattedNumber(item.soPhieuBanLe),
      'Lợi nhuận bán lẻ': formattedNumber(item.soPhieuBanLe),
      'Số phiếu kê toa': formattedNumber(item.soPhieuKeToa),
      'Số lợi nhuận kê toa': formattedNumber(item.tongLoiNhuanKeToa),
      'Tổng số phiếu': formattedNumber(item.tongSoPhieu),
      'Tổng lợi nhuận': formattedNumber(item.tongLoiNhuanNgay),
    }))
    const name = 'Lợi nhuận tổng hợp'
    exportExcelformat(formatData, name)
  }

  const fetchBranch = async (id) => {
    const result = await branchService.getListBranch(id)
    setBranch(result.data)
  }

  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchReport = async () => {
    setIsLoading(true)
    let idChiNhanhs = null
    if (value?.length > 0 && value[0] !== 'all') {
      idChiNhanhs = value?.map((id) => `&idChiNhanhs=${id}`).join('&')
      const { data } = await reportService.getLoiNhuan(
        company,
        idChuyenKhoa,
        idChiNhanhs,
        dateForm,
        dateTo,
      )
      setData(data)
    } else {
      const { data } = await reportService.getLoiNhuan(company, idChuyenKhoa, '', dateForm, dateTo)
      setData(data)
    }
    setIsLoading(false)
  }

  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  const total = data.reduce((total, items) => total + items.tongTienVon, 0)
  const BANLE = data.reduce((total, items) => total + items.tongLoiNhuanBanLe, 0)
  const KETOA = data.reduce((total, items) => total + items.tongLoiNhuanKeToa, 0)
  const TONGPHIEU = data.reduce((total, items) => total + items.tongSoPhieu, 0)
  const TONGLOINHUANNGAY = data.reduce((total, items) => total + items.tongLoiNhuanNgay, 0)

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranchByChuyenKhoa(null)
    fetchChuyenKhoa()
    fetchReport()
  }, [])

  const statis = [
    {
      title: 'TỔNG LỢI NHUẬN BÁN LẺ',
      number: formattedNumber(BANLE),
    },
    {
      title: 'TỔNG LỢI NHUẬN BÁN TOA',
      number: formattedNumber(KETOA),
    },
    {
      title: 'TỔNG SỐ PHIẾU',
      number: formattedNumber(TONGPHIEU),
    },
    {
      title: 'TIỀN VỐN',
      number: formattedNumber(total),
    },
    {
      title: 'TỔNG LỢI NHUẬN',
      number: formattedNumber(TONGLOINHUANNGAY),
    },
  ]

  const onChange = (newValue) => {
    setValue(newValue)
  }

  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  return (
    <div>
      <div>
        <ul className="flex border rounded-md">
          {statis.map((items) => (
            <li className="flex flex-col gap-2 w-1/5 border-r p-2">
              <div className="flex justify-between">
                <h2 className="font-semibold text-gray-500 xl:text-base text-xs">{items.title}</h2>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="size-5 text-green-700 border-2 border-green-500  rounded-full "
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <span className="text-lg font-semibold">{formattedNumber(items.number)}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex gap-5 justify-between border-dotted border-b-0 py-2 ">
        <div className="flex gap-5">
          <div className="w-80">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranch(value)
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-56">
            <Select
              onChange={onChangeChuyenKhoa}
              className="w-full"
              value={idChuyenKhoa}
              options={[
                { label: 'Tất cả', value: '' },
                ...ChuyenKhoa.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
            />
          </div>
          <div className="w-80">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="flex gap-3">
            <IconButton onClick={onLoad} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
          <div className="font-semibold ">
            <Button
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>

      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              x: 1000,
              y: '52vh',
            }}
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={data}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 40,
                align: 'center',
                fixed: 'left',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                width: 100,
                align: 'center',
                fixed: 'left',
                render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
              },
              {
                title: 'Ngày khai trương',
                dataIndex: 'ngayKhaiTruong',
                key: 'ngayKhaiTruong',
                width: 130,
                align: 'center',
                fixed: 'left',
                render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
              },
              {
                title: 'Chi nhánh',
                dataIndex: 'tenChiNhanh',
                key: 'tenChiNhanh',
                fixed: 'left',
              },
              {
                title: 'Tổng tiền vốn',
                dataIndex: 'tongTienVon',
                key: 'tongTienVon',
                width: 100,
                align: 'right',
                render: (text) => (text ? formattedNumber(text) : 0),
              },
              {
                title: 'Bán lẻ',
                dataIndex: 'tongTienDichVu',
                key: 'tongTienDichVu',
                align: 'center',
                children: [
                  {
                    title: 'Số phiếu',
                    key: 'soPhieuBanLe',
                    dataIndex: 'soPhieuBanLe',
                    align: 'right',
                    width: 70,
                  },
                  {
                    title: 'Lợi nhuận',
                    key: 'tongLoiNhuanBanLe',
                    dataIndex: 'tongLoiNhuanBanLe',
                    align: 'right',
                    width: 90,
                    render: (text) => (text ? formattedNumber(text) : 0),
                  },
                ],
              },
              {
                title: 'Theo toa',
                dataIndex: 'tongTienDichVu',
                key: 'tongTienDichVu',
                align: 'center',
                children: [
                  {
                    title: 'Số phiếu',
                    key: 'soPhieuKeToa',
                    dataIndex: 'soPhieuKeToa',
                    align: 'right',
                    width: 70,
                  },
                  {
                    title: 'Lợi nhuận',
                    key: 'tongLoiNhuanKeToa',
                    dataIndex: 'tongLoiNhuanKeToa',
                    align: 'right',
                    width: 90,
                    render: (text) => (text ? formattedNumber(text) : 0),
                  },
                ],
              },
              {
                title: 'Vacxin',
                dataIndex: 'tongTienDichVu',
                key: 'tongTienDichVu',
                align: 'center',
                children: [
                  {
                    title: 'Số phiếu',
                    key: 'soPhieuVacxin',
                    dataIndex: 'soPhieuVacxin',
                    align: 'right',
                    width: 70,
                  },
                  {
                    title: 'Lợi nhuận',
                    key: 'tongLoiNhuanVacxin',
                    dataIndex: 'tongLoiNhuanVacxin',
                    align: 'right',
                    width: 90,
                    render: (text) => (text ? formattedNumber(text) : 0),
                  },
                ],
              },
              {
                title: 'Tổng',
                dataIndex: 'tongTienDichVu',
                key: 'tongTienDichVu',
                align: 'center',
                children: [
                  {
                    title: 'Số phiếu',
                    key: 'tongSoPhieu',
                    dataIndex: 'tongSoPhieu',
                    align: 'right',
                    width: 70,
                  },
                  {
                    title: 'Lợi nhuận',
                    key: 'tongLoiNhuanNgay',
                    dataIndex: 'tongLoiNhuanNgay',
                    align: 'right',
                    width: 90,
                    render: (text) => (text ? formattedNumber(text) : 0),
                  },
                ],
              },
            ]}
            summary={(pageData) => {
              let TotalVon = 0
              let totalSoPhieuBanLe = 0
              let totalLoiNhuanBanLe = 0
              let totalSoPhieuKeToa = 0
              let totalLoiNhuanKeToa = 0
              let totalLoiNhuanNgay = 0
              let totalSoPhieu = 0
              let soPhieuVacxin = 0
              let loiNhuanVacxin = 0

              pageData.forEach((item) => {
                totalSoPhieuBanLe += item.soPhieuBanLe
                totalLoiNhuanBanLe += item.tongLoiNhuanBanLe
                totalSoPhieuKeToa += item.soPhieuKeToa
                totalLoiNhuanKeToa += item.tongLoiNhuanKeToa
                totalLoiNhuanNgay += item.tongLoiNhuanNgay
                totalSoPhieu += item.tongSoPhieu
                TotalVon += item.tongTienVon
                soPhieuVacxin += item.soPhieuVacxin
                loiNhuanVacxin += item.tongLoiNhuanVacxin
              })

              return (
                <Table.Summary fixed>
                  <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                    <Table.Summary.Cell index={0} colSpan={4} align="right">
                      Tổng cộng
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={1} align="right">
                      {TotalVon}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} colSpan={1} align="right">
                      {totalSoPhieuBanLe}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} colSpan={1} align="right">
                      {formattedNumber(totalLoiNhuanBanLe)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1} align="right">
                      {totalSoPhieuKeToa}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} colSpan={1} align="right">
                      {formattedNumber(totalLoiNhuanKeToa)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} colSpan={1} align="right">
                      {soPhieuVacxin}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} colSpan={1} align="right">
                      {formattedNumber(loiNhuanVacxin)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} colSpan={1} align="right">
                      {totalSoPhieu}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} colSpan={1} align="right">
                      {formattedNumber(totalLoiNhuanNgay)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default Synthetic

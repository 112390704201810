import React, { useEffect, useState } from 'react'
import { Button, ConfigProvider, Table, Input, Modal, Form, message } from 'antd'
import Flag from 'react-world-flags'
import { https } from '../../../../services/apiService'
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

const { confirm } = Modal

const countryCodeMap = {
  'Việt Nam': 'VN',
  Anh: 'GB',
  Brunei: 'BN',
  Campuchia: 'KH',
  Canada: 'CA',
  'Cu ba': 'CU',
  'Đài Loan': 'TW',
  Đức: 'DE',
  'Hà Lan': 'NL',
  'Hàn Quốc': 'KR',
  'Hoa Kỳ (USA)': 'US',
  Nga: 'RU',
  Nhật: 'JP',
  Pháp: 'FR',
  'Trung Quốc': 'CN',
  Úc: 'AU',
  'Thuỵ Sĩ': 'CH',
  Bỉ: 'BE',
  Singapore: 'SG',
  'Thái Lan': 'TH',
  Indonesia: 'ID',
  Lào: 'LA',
  Malaysia: 'MY',
  'Ấn Độ': 'IN',
  Israel: 'IL',
  'Tây Ban Nha': 'ES',
  'Nam Phi': 'ZA',
  Myanmar: 'MM',
  Philippines: 'PH',
  Mỹ: 'US',
  'New Zealand': 'NZ',
  'Đông ti mo ': 'TL',
  Khác: 'UN', // UN - trường hợp không xác định
}

const QuocGia = () => {
  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [newCountry, setNewCountry] = useState({ tenQuocTich: '' })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await https.get('/QuocTich')
        setData(response.data)
        setFilteredData(response.data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase()
    setSearchText(value)
    const filtered = data.filter((item) => item.tenQuocTich?.toLowerCase().includes(value))
    setFilteredData(filtered)
  }

  const handleAddCountry = async () => {
    try {
      const response = await https.post('/QuocTich/CreateQuocTich', newCountry)
      setData([...data, response.data])
      setFilteredData([...data, response.data])
      setIsModalVisible(false)
      setNewCountry({ tenQuocTich: '' })
    } catch (error) {
      console.error('Error adding country:', error)
    }
  }

  const showDeleteConfirm = (idQuocTich) => {
    confirm({
      title: 'Bạn có chắc chắn muốn xóa quốc gia này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Xóa',
      okType: 'danger',
      cancelText: 'Hủy',
      onOk() {
        handleDeleteCountry(idQuocTich)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const handleDeleteCountry = async (idQuocTich) => {
    try {
      await https.delete(`/QuocTich/DeleteQuocTich?idQuocTich=${idQuocTich}`)
      const updatedData = data.filter((item) => item.idQuocTich !== idQuocTich)
      setData(updatedData)
      setFilteredData(updatedData)
      message.success('Đã xóa thành công')
    } catch (error) {
      console.error('Error deleting country:', error)
    }
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      render: (text, record, index) => index + 1,
      width: 50,
    },
    {
      title: 'Cờ',
      dataIndex: 'tenQuocTich',
      key: 'flag',
      render: (name) => {
        const countryCode = countryCodeMap[name] || 'UN' // 'UN' cho trường hợp quốc gia không xác định
        return <Flag code={countryCode} style={{ width: '30px', height: '20px' }} />
      },
      width: 350,
    },
    {
      title: 'Tên Quốc Tịch',
      dataIndex: 'tenQuocTich',
      key: 'tenQuocTich',
    },
    {
      title: 'Hành động',
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
          {/* <Button type="primary" size="small" icon={<EditOutlined />}>
            Sửa
          </Button> */}
          <Button
            type="primary"
            danger
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => showDeleteConfirm(record.idQuocTich)}
          >
            Xóa
          </Button>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="flex justify-between mb-2">
        <Input
          placeholder="Tìm kiếm quốc tịch"
          value={searchText}
          onChange={handleSearch}
          style={{ width: 200 }}
        />
        <Button type="primary" onClick={() => setIsModalVisible(true)}>
          Thêm quốc gia
        </Button>
      </div>

      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table columns={columns} dataSource={filteredData} rowKey="idQuocTich" />
      </ConfigProvider>

      <Modal
        title="Thêm quốc gia"
        visible={isModalVisible}
        onOk={handleAddCountry}
        onCancel={() => setIsModalVisible(false)}
        okText="Lưu"
        cancelText="Hủy"
      >
        <Form layout="vertical">
          <Form.Item label="Tên Quốc Tịch">
            <Input
              value={newCountry.tenQuocTich}
              onChange={(e) => setNewCountry({ ...newCountry, tenQuocTich: e.target.value })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default QuocGia

import { Input, InputNumber, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateDMXetNghiemAction } from '../../../../store/actions/xetNghiemAction'
import { https } from '../../../../services/apiService'
const ModalEdit = ({ isModalEdit, handleCancelEdit, onLoad }) => {
  const { infoXetNghiem, groupsXetNghiem } = useSelector((state) => state.xetNghiemReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const dispatch = useDispatch()
  const [allDoiTacXN, setAllDoiTacXN] = useState([])

  const handleSubmit = (value) => {
    dispatch(updateDMXetNghiemAction(value, onLoad))
    formik.resetForm()
    handleCancelEdit()
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idxn: infoXetNghiem?.idxn,
      maxn: infoXetNghiem?.maxn,
      tenxn: infoXetNghiem?.tenxn,
      tenviettat: infoXetNghiem?.tenviettat,
      sudung: infoXetNghiem?.sudung,
      mamic: infoXetNghiem?.mamic,
      ghichu: infoXetNghiem?.ghichu,
      idnhomxn: infoXetNghiem?.idnhomxn,
      dongia: infoXetNghiem?.dongia,
      madiag: infoXetNghiem?.madiag,
      matvd: infoXetNghiem?.matvd,
      idct: infoXetNghiem?.idct,
      capcuu: infoXetNghiem?.capcuu,
      maugui: infoXetNghiem?.maugui,
      dvt: infoXetNghiem?.dvt,
      chuyenkhoa: infoXetNghiem?.chuyenkhoa,
      tienguimau: infoXetNghiem?.tienguimau,
      iddoitacxn: infoXetNghiem?.iddoitacxn,
    },
    onSubmit: (value) => handleSubmit(value),
  })

  const getDoiTacXN = async () => {
    try {
      const { data } = await https.get('DoiTacXetNghiem/GetAllDoiTacXetNghiem')
      setAllDoiTacXN(data)
    } catch (error) {}
  }

  useEffect(() => {
    getDoiTacXN()
  }, [])

  return (
    <>
      <Modal
        okText="Cập nhật"
        cancelText="Huỷ"
        className="text-center"
        title={<h2 className="text-gray-700 text-xl">Thông tin loại xét nghiệm</h2>}
        onOk={formik.handleSubmit}
        open={isModalEdit}
        onCancel={handleCancelEdit}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">Mã xét nghiệm </label>
            <Input
              value={formik.values.maxn}
              name="maxn"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập mã xét nghiệm"
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Tên xét nghiệm </label>
            <Input
              value={formik.values.tenxn}
              name="tenxn"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập tên xét nghiệm"
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Tên viết tắt </label>
            <Input
              value={formik.values.tenviettat}
              name="tenviettat"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập tên viết tắc"
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold  text-base">Nhóm xét nghiệm </label>
            <Select
              value={formik.values.idnhomxn}
              options={groupsXetNghiem?.map((items) => ({
                label: items.tennhom,
                value: items.idnhomxn,
              }))}
              onChange={(value) => formik.setFieldValue('idnhomxn', value)}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold  text-base">Công ty </label>
            <Select
              value={formik.values.idct}
              options={listCompany?.map((items) => ({
                label: items.tenct,
                value: items.idct,
              }))}
              onChange={(value) => formik.setFieldValue('idct', value)}
            />
          </div>
          <div className="flex gap-5">
            <div className="w-full">
              <label className="font-semibold  text-base">Đối tác xét nghiệm </label>
              <Select
                value={formik.values.iddoitacxn}
                options={allDoiTacXN?.map((items) => ({
                  label: items.tendoitac,
                  value: items.iddtxn,
                }))}
                onChange={(value) => formik.setFieldValue('iddoitacxn', value)}
                className="w-full"
              />
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-full">
              <label className="font-semibold  text-base">Đơn vị tính </label>
              <Input value={formik.values.dvt} onChangeCapture={formik.handleChange} name="dvt" />
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-1/2">
              <label className="font-semibold  text-base">Đơn giá </label>
              <InputNumber
                onChange={(value) => formik.setFieldValue('dongia', value)}
                name="dongia"
                className="w-full"
                value={formik.values.dongia}
                step="0.001"
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                max={999999999}
              />
            </div>
            <div className="w-1/2">
              <label className="font-semibold  text-base">Tiền gửi mẫu </label>
              <InputNumber
                onChange={(value) => formik.setFieldValue('tienguimau', value)}
                status={formik.errors.tienguimau ? 'error' : ''}
                value={formik.values.tienguimau}
                name="tienguimau"
                className="w-full"
                step="0.001"
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                max={999999999}
              />
            </div>
          </div>
          <div>
            <label className="font-semibold  text-base">Ghi chú </label>
            <Input
              value={formik.values.ghichu}
              onChangeCapture={formik.handleChange}
              name="ghichu"
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalEdit

import React from 'react'
import { Row, Col, Card, Typography, Descriptions, Divider, Space } from 'antd'

const { Title } = Typography

export default function InfoVanBan() {
  return (
    <div className="info-vanban-container" style={{ padding: '20px' }}>
      <Row gutter={16}>
        <Col span={24}>
          <Card
            bordered={false}
            style={{
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              padding: '20px',
            }}
          >
            <Title level={3} style={{ textAlign: 'center', marginBottom: '20px' }}>
              Thông tin văn bản
            </Title>
            <Descriptions bordered column={1} size="middle">
              <Descriptions.Item label="Tên văn bản">
                <Typography.Text strong>Văn bản mẫu</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Loại văn bản">
                <Typography.Text strong>Loại A</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Số Văn bản">
                <Typography.Text strong>12345</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Nội dung xử lý">
                <Typography.Text strong>
                  Nội dung xử lý mẫu,Nội dung xử lý mẫu ,Nội dung xử lý mẫu,Nội dung xử lý mẫu Nội
                  dung xử lý mẫuNội dung xử lý mẫuNội dung xử lý mẫuNội dung xử lý mẫuNội dung xử lý
                  mẫuNội dung xử lý mẫu
                </Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Ghi chú">
                <Typography.Text strong>Ghi chú mẫu</Typography.Text>
              </Descriptions.Item>
            </Descriptions>
            <Divider style={{ marginTop: '20px' }} />
            <div style={{ textAlign: 'center' }}>
              <Typography.Text type="secondary">
                Thông tin chi tiết hơn có thể được thêm vào đây.
              </Typography.Text>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import {
  Modal,
  Divider,
  notification,
  Tabs,
  ConfigProvider,
  Table,
  DatePicker,
  Tooltip,
  Input,
  Steps,
  Button,
} from 'antd'
import { PlusOutlined, SaveOutlined, RetweetOutlined } from '@ant-design/icons'
import { Button as ButtonMui } from '@mui/material'
import InfoBNTang from './InfoBNTang'
import CardTTVTang from './CardTTVTang'
import * as typeAction from '../../../../../store/constants/constants'
import ModalCreateCustomer from '../../../../layout/Receive/ModalCreateCustomer/ModalCreateCustomer'
import { postTheNhiTangKhiCoTheSan } from '../../../../../store/actions/cardAction'
import { cardService } from '../../../../../services/card/cardService'
import ToastCus from '../../../../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
function CardTang({ isTangTTV, setIsTangTTV, handleClose }) {
  const dispatch = useDispatch()
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { detailCard, infoBN, infoBnTang, infoBnThu2Tang } = useSelector(
    (state) => state.cartReducer,
  )
  const data = useSelector((state) => state.receiveReducer)
  const infoBNnew = data?.infoBN
  const [openModaCreateCustomer, setOpenModaCreateCustomer] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [listLoaiTheTang, setListLoaiTheTang] = useState([])
  //############# FORM ############
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      //idthe: 0,
      mathe: detailCard?.mathe,
      tenthe: null,
      //dongia: null,
      sothang: null,
      idchuyenkhoa: null,
      sothanhvien: 1,
      // ghichu: '',
      idnhom: null,
      // ngaydangky: '2024-08-29T03:45:34.298Z',
      tiensudung: null,
      tungay: null,
      denngay: null,
      nguoitao: infoUser?.tenNV,
      idbn: infoBnTang?.idbn,
      idlt: null,
      idcn: PkDangNhap?.idChiNhanh,
      tienmat: 0,
      chuyenkhoan: 0,
      momo: 0,
      vnpay: 0,
      zalopay: 0,
      pos: 0,
      thett: 0,
      tiengiamgia: 0,
      tienthanhtoan: 0,
      idcalamviec: infoUser?.dangNhap?.idCaLamViec,
      nguoimua: infoBN?.tenBenhNhan,
      sodienthoai: infoBN?.dienThoai,
      diachi: infoBN?.diaChi,
      // lydo: '',
      nguoiban: infoUser?.tenNV,
      idnguoitao: infoUser?.idnv,
      idnguoiban: infoUser?.idnv,
      // mavoucher: '',
      // maphieuthu: '',
      // machungtu: '',
      hoadondientu: 0,
      idphuongthuc: 1, //Thanh toán
      // ghichuxuathoadon: '',
      // mavoucherdoitac: '',
    },
    onSubmit: (value) => handleSubmit(value),
    // validationSchema: receiveSchema,
  })
  const handleSubmit = (value) => {
    // return console.log(
    //   moment(infoBnTang?.ngaySinh).format('YYYY-MM-DD'),
    //   moment(infoBnThu2Tang?.ngaySinh).format('YYYY-MM-DD'),
    // )
    if ((value?.sothanhvien === 2 && !infoBnThu2Tang?.idbn) || !infoBnTang?.idbn) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng chọn bệnh nhân!',
      })
      return
    } else if (!value?.idlt) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng chọn loại thẻ!',
      })
      return
    } else if (value?.sothanhvien < 1 || value?.sothanhvien > 2) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập đúng số thành viên!',
      })
      return
    } else if (
      value?.sothanhvien === 2 &&
      moment(infoBnTang?.ngaySinh).format('YYYY-MM-DD') !==
        moment(infoBnThu2Tang?.ngaySinh).format('YYYY-MM-DD')
    ) {
      ToastCus.fire({
        icon: 'error',
        text: 'Ngày sinh của 2 bệnh nhân không hợp lệ!',
      })
      return
    } else if (moment().isAfter(value?.denngay)) {
      ToastCus.fire({
        icon: 'error',
        text: 'Ngày hết hạn của thẻ tặng không hợp lệ!',
      })
      return
    }
    dispatch(
      postTheNhiTangKhiCoTheSan(
        value,
        value?.sothanhvien === 2 && infoBnThu2Tang
          ? {
              idbn: infoBnThu2Tang?.idbn,
              nguoitao: infoUser?.tenNV,
            }
          : null,
        detailCard,
        handleClose,
      ),
    )
  }
  // show modal create new customer
  const showCreateCustomer = () => {
    setOpenModaCreateCustomer(true)
  }
  // cacel show modal create new customer
  const cancelCreateCustomer = () => {
    setOpenModaCreateCustomer(false)
  }

  const getListLoaiTheTang = async () => {
    try {
      setIsLoading(true)
      const { data } = await cardService.getListLoaiTheTangByCk(PkDangNhap?.idChuyenKhoa)
      setListLoaiTheTang(data)
      // formik.setValues(
      //   (prev) =>
      //     data?.length > 0 && {
      //       ...prev,
      //       tenthe: data[0]?.loaithe,
      //       sothang: data[0]?.sothang,
      //       idchuyenkhoa: data[0]?.idchuyenkhoa,
      //       idnhom: data[0]?.idnhom,
      //       tiensudung: data[0]?.tiensudung,
      //       tungay: moment().format('YYYY-MM-DD'),
      //       denngay: moment()
      //         .add(data[0]?.sothang ?? 0, 'months')
      //         .format('YYYY-MM-DD'),
      //       idlt: data[0]?.idlt,
      //     },
      // )
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const doiViTriBN = () => {
    const tempBN = { ...infoBnTang }
    dispatch({
      type: typeAction.DISPATCH_INFO_BN_CARD_TANGSAN,
      payload: infoBnThu2Tang,
    })
    dispatch({
      type: typeAction.DISPATCH_INFO_BN_THU2_CARD_TANGSAN,
      payload: tempBN,
    })
  }
  useEffect(() => {
    if (infoBNnew && isTangTTV) {
      dispatch({
        type: typeAction.DISPATCH_INFO_BN_CARD_TANGSAN,
        payload: infoBNnew,
      })
    }
  }, [infoBNnew])
  useEffect(() => {
    getListLoaiTheTang()
  }, [])
  return (
    <div className="w-full grid grid-flow-row">
      <div className="text-blue-500 font-semibold text-lg mb-2">Tặng thẻ thành viên nhi</div>
      {/* <Divider
        orientation="left"
        style={{
          margin: '10px',
          marginTop: '0px',
        }}
      >
        <span className="text-blue-500">Thông tin bệnh nhân</span>
      </Divider> */}
      <Divider
        orientation="left"
        style={{
          margin: '10px 0',
        }}
      >
        <span className="text-blue-500">Thông tin bệnh nhân</span>
        <span className="ml-3">
          <Tooltip title="Tạo bệnh nhân" color="blue">
            <ButtonMui
              //onClick={() => { setIsEditTTV(true) }}
              onClick={showCreateCustomer}
              variant="contained"
              // color="success"
              size="small"
              startIcon={<PlusOutlined />}
            >
              Tạo bệnh nhân
            </ButtonMui>
          </Tooltip>
        </span>
        <ModalCreateCustomer
          openModaCreateCustomer={openModaCreateCustomer}
          cancelCreateCustomer={cancelCreateCustomer}
        />
      </Divider>
      <InfoBNTang />
      {formik?.values?.sothanhvien === 2 ? (
        <>
          <Divider
            orientation="left"
            style={{
              margin: '10px 0',
            }}
          >
            <span className="text-green-500">Thông tin bệnh nhân thứ 2</span>
            {/* <span className="ml-3">
              <Tooltip title="Đổi vị trí" color="green">
                <ButtonMui
                  //onClick={() => { setIsEditTTV(true) }}
                  onClick={doiViTriBN}
                  variant="contained"
                  color="success"
                  size="small"
                  startIcon={<RetweetOutlined />}
                >
                  Đổi vị trí
                </ButtonMui>
              </Tooltip>
            </span> */}
          </Divider>
          <InfoBNTang isBNtang={true} />
        </>
      ) : (
        <></>
      )}
      <Divider
        orientation="left"
        style={{
          margin: '10px 0',
        }}
      >
        <span className="text-blue-500">Thông tin thẻ</span>
      </Divider>
      <CardTTVTang listLoaiTheTang={listLoaiTheTang} formik={formik} />
      <div className="flex justify-end items-center mt-2">
        <Tooltip title="Tạo thẻ tặng nhi khi có thẻ sản" color="blue">
          <ButtonMui
            variant="contained"
            color="primary"
            size="small"
            startIcon={<SaveOutlined />}
            onClick={formik.handleSubmit}
          >
            Lưu
          </ButtonMui>
        </Tooltip>
      </div>
    </div>
  )
}

export default CardTang

import * as Yup from 'yup'

export const addPhanAnhSchema = Yup.object().shape({
  // MAPAKH: Yup.string().required(),
  TENPAKH: Yup.string().required(),
  IDLOAIPAKH: Yup.number().required(),
  NGUOITIEPNHAN: Yup.number().required(), // id chi nhánh - bộ phận xử lý
  NGUOIPHANANH: Yup.string().required(),
  SODIENTHOAI: Yup.string().required(),
  // DIACHI: Yup.string().required(),
})

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bieuMau_toolbarClassName__dMKyp {
    background-color: #f5f5f5;
  }
  
  .bieuMau_wrapperClassName__gHm5g {
    border: 1px solid #d4d4d4;
    padding: 10px;
  }
  
  .bieuMau_editorClassName__cUrua {
    min-height: 350px;
    max-height: 350px; /* Giới hạn chiều cao */
    overflow-y: auto;  /* Hiển thị thanh cuộn dọc khi vượt quá chiều cao */
    padding: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/components/layout/SieuAm/Template/bieuMau.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,iBAAiB,EAAE,uBAAuB;IAC1C,gBAAgB,GAAG,mDAAmD;IACtE,aAAa;EACf","sourcesContent":[".toolbarClassName {\n    background-color: #f5f5f5;\n  }\n  \n  .wrapperClassName {\n    border: 1px solid #d4d4d4;\n    padding: 10px;\n  }\n  \n  .editorClassName {\n    min-height: 350px;\n    max-height: 350px; /* Giới hạn chiều cao */\n    overflow-y: auto;  /* Hiển thị thanh cuộn dọc khi vượt quá chiều cao */\n    padding: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarClassName": `bieuMau_toolbarClassName__dMKyp`,
	"wrapperClassName": `bieuMau_wrapperClassName__gHm5g`,
	"editorClassName": `bieuMau_editorClassName__cUrua`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import XuatNhaThuocTongHop from './XuatNhaThuocTongHop'
import XuatNhaThuocChiTiet from './XuatNhaThuocChiTiet'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

function XuatNhaThuoc() {
  // const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  // const id_company = Number(localStorage.getItem('id_company'))
  // const [branch, setBranch] = useState([])
  // const { listCompany } = useSelector((state) => state.companyReducer)
  // const [value, setValue] = useState(['all'])
  // const [dataReport, setDataReport] = useState([])
  // const [company, setCompany] = useState(id_company)
  // const [isLoading, setIsLoading] = useState(false)
  // const [dateForm, setDateForm] = useState(now.format(momentDate))
  // const [dateTo, setDateTo] = useState(now.format(momentDate))
  // const [search, setSearch] = useState('')
  // const [loaiToa, setLoaiToa] = useState([])
  // const [selectedBranches, setSelectedBranches] = useState(['all'])
  // const [selectedLoaiToa, setSelectedLoaiToa] = useState('all')
  // const dispatch = useDispatch()

  // const fetchReport = async () => {
  //   try {
  //     setIsLoading(true)
  //     let idChiNhanhs = ''
  //     if (!selectedBranches.includes('all')) {
  //       idChiNhanhs = selectedBranches.map((id) => `idChiNhanhs=${id}`).join('&')
  //     }
  //     const idLoaiToaQuery = selectedLoaiToa !== 'all' ? `idLoaiToa=${selectedLoaiToa}` : ''
  //     const queryString = `idCongTy=${company}&${idChiNhanhs}&${idLoaiToaQuery}&keyword=${search?.trim() || ''}&fromDate=${dateForm}&toDate=${dateTo}`
  //     const { data } = await reportService.getThongKeXuatNhaThuoc(queryString)

  //     const formattedData = data.flatMap((branch) =>
  //       branch.ngayList.map((item, index) => ({
  //         tenchinhanh: branch.tenChiNhanh,
  //         ngay: item.ngay,
  //         loaiphieu: item.tenLoaiToa,
  //         toathuoc: item.toaThuoc,
  //         mahang: item.maThuoc,
  //         tenhang: item.tenThuoc,
  //         hoatchat: item.tenHoatChat,
  //         donvi: item.dvt,
  //         soluong: item.soLuong,
  //         dongia: item.donGia,
  //         thanhtien: item.thanhTien,
  //         giam: item.phanTramGiam,
  //         giamgia: item.tienGiam,
  //         thanhtoan: item.tongTien,
  //         solo: item.soLo,
  //         handung: item.hanDung,
  //         sohoadon: item.soHoaDon,
  //         sodangky: item.soDangKy,
  //         ghichu: item.ghiChu,
  //       })),
  //     )
  //     setDataReport(formattedData)
  //   } catch (error) {
  //     console.log(error)
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }
  // const fetchBranch = async (id) => {
  //   try {
  //     setIsLoading(true)
  //     const result = await branchService.getBranchNotVP(id)
  //     setBranch(result.data)
  //   } catch (error) {
  //     console.log(error)
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }

  // const fetchAllLoaiToa = async () => {
  //   try {
  //     const { data } = await LoaitoaService.getAllLoaiToa()
  //     setLoaiToa(data)
  //   } catch (error) {
  //     console.error('Error fetching Loai Toa:', error)
  //   }
  // }

  // console.log('loaiToa', loaiToa)

  // const handleDatePicker = (name) => (date, dateString) => {
  //   name === 'form'
  //     ? setDateForm(moment(dateString, dateFormat).format(momentDate))
  //     : setDateTo(moment(dateString, dateFormat).format(momentDate))
  // }

  // const treeData = [
  //   {
  //     title: 'Tất cả chi nhánh',
  //     value: 'all',
  //     key: 'all',
  //     children: branch?.map((branch) => ({
  //       title: branch?.tenChiNhanh,
  //       value: branch?.idChiNhanh,
  //       key: `${branch?.idChiNhanh}`,
  //     })),
  //   },
  // ]

  // const onChange = (newValue) => {
  //   setSelectedBranches(newValue)
  // }

  // const tProps = {
  //   treeData,
  //   value: selectedBranches,
  //   onChange,
  //   treeCheckable: true,
  //   showCheckedStrategy: SHOW_PARENT,
  //   placeholder: 'Chọn chi nhánh...',
  //   filterTreeNode: (input, treeNode) =>
  //     treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
  //   showSearch: true,
  //   style: {
  //     width: '100%',
  //   },
  // }

  // const exportToExcel = () => {
  //   const data = dataReport.map((item) => ({
  //     'Chi nhánh': item.tenchinhanh,
  //     Ngày: item.ngay,
  //     'Loại phiếu': item.loaiphieu,
  //     'Toa thuốc': item.toathuoc,
  //     'Mã hàng': item.mahang,
  //     'Tên hàng': item.tenhang,
  //     'Hoạt chất': item.hoatchat,
  //     'Đơn vị': item.donvi,
  //     'Số lượng': item.soluong,
  //     'Đơn giá': item.dongia,
  //     'Thành tiền': item.thanhtien,
  //     '% giảm': item.giam,
  //     'Giảm giá': item.giamgia,
  //     'Thanh toán': item.thanhtoan,
  //     'Số lô': item.solo,
  //     'Hạn dùng': item.handung ? moment(item.handung).format('DD/MM/YYYY') : '',
  //     'Số hóa đơn': item.sohoadon,
  //     'Số đăng ký': item.sodangky,
  //     'Ghi chú': item.ghichu,
  //   }))

  //   // XLSX.utils.sheet_add_aoa(data, [['Bảng thống kê xuất nhà thuốc']], { origin: 'A1' })
  //   exportExcelformat(data, 'Bảng thống kê xuất nhà thuốc')

  //   XLSX.utils.sheet_add_aoa(
  //     data,
  //     [
  //       [
  //         'Tổng số lượng',
  //         formattedNumber(dataReport?.reduce((tong, item) => (tong += item?.soluong || 0), 0)),
  //         'Tổng thành tiền',
  //         formattedNumber(dataReport?.reduce((tong, item) => (tong += item?.thanhtien || 0), 0)),
  //         'Tổng giảm giá',
  //         formattedNumber(dataReport?.reduce((tong, item) => (tong += item?.giamgia || 0), 0)),
  //         'Tổng Thanh toán',
  //         formattedNumber(dataReport?.reduce((tong, item) => (tong += item?.thanhtoan || 0), 0)),
  //       ],
  //     ],
  //     { origin: `A${data.length + 2}` },
  //   )
  // }

  // const onLoad = () => {
  //   setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
  //   fetchReport()
  // }

  // useEffect(() => {
  //   const initialize = async () => {
  //     try {
  //       await dispatch(listAllCompanyAction())
  //       await fetchBranch(id_company)
  //       await fetchAllLoaiToa()
  //       await fetchReport()
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   initialize()
  // }, [])

  // const columns = [
  //   {
  //     title: 'STT',
  //     dataIndex: 'stt',
  //     key: 'stt',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 40,
  //       },
  //     }),
  //     align: 'center',
  //     fixed: 'left',
  //     render: (text, record, index) => ++index,
  //   },
  //   {
  //     title: 'Chi nhánh',
  //     dataIndex: 'tenchinhanh',
  //     key: 'tenchinhanh',
  //     fixed: 'left',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 90,
  //       },
  //     }),
  //   },
  //   {
  //     title: 'Ngày ',
  //     dataIndex: 'ngay',
  //     key: 'ngay',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 90,
  //       },
  //     }),
  //     align: 'center',
  //     render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
  //   },
  //   {
  //     title: 'Loại phiếu',
  //     dataIndex: 'loaiphieu',
  //     key: 'loaiphieu',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 90,
  //       },
  //     }),
  //     align: 'center',
  //   },
  //   {
  //     title: 'Toa thuốc',
  //     dataIndex: 'toathuoc',
  //     key: 'toathuoc',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 90,
  //       },
  //     }),
  //     align: 'center',
  //   },
  //   {
  //     title: 'Mã hàng',
  //     dataIndex: 'mahang',
  //     key: 'mahang',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 80,
  //       },
  //     }),
  //     align: 'center',
  //   },
  //   {
  //     title: 'Tên hàng',
  //     dataIndex: 'tenhang',
  //     key: 'tenhang',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 90,
  //       },
  //     }),
  //   },
  //   {
  //     title: 'Hoạt chất',
  //     dataIndex: 'hoatchat',
  //     key: 'hoatchat',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 90,
  //       },
  //     }),
  //   },
  //   {
  //     title: 'Đơn vị',
  //     dataIndex: 'donvi',
  //     key: 'donvi',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 90,
  //       },
  //     }),
  //     align: 'center',
  //   },
  //   {
  //     title: 'Số lượng',
  //     dataIndex: 'soluong',
  //     key: 'soluong',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 90,
  //       },
  //     }),
  //     align: 'center',
  //   },
  //   {
  //     title: 'Đơn giá',
  //     dataIndex: 'dongia',
  //     key: 'dongia',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 100,
  //       },
  //     }),
  //     align: 'right',
  //     render: (text) => formattedNumber(text),
  //   },
  //   {
  //     title: 'Thành tiền',
  //     dataIndex: 'thanhtien',
  //     key: 'thanhtien',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 100,
  //       },
  //     }),
  //     align: 'right',
  //     render: (text) => formattedNumber(text),
  //   },
  //   {
  //     title: '% Giảm',
  //     dataIndex: 'giam',
  //     key: 'giam',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 80,
  //       },
  //     }),
  //     align: 'center',
  //     render: (text) => (text ? `${text.toFixed(2)}%` : '-'),
  //   },
  //   {
  //     title: 'Giảm giá',
  //     dataIndex: 'giamgia',
  //     key: 'giamgia',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 80,
  //       },
  //     }),
  //     align: 'center',
  //     render: (text) => (text ? formattedNumber(text) : '-'),
  //   },
  //   {
  //     title: 'Thanh toán',
  //     dataIndex: 'thanhtoan',
  //     key: 'thanhtoan',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 100,
  //       },
  //     }),
  //     align: 'right',
  //     render: (text) => formattedNumber(text),
  //   },
  //   {
  //     title: 'Số lô',
  //     dataIndex: 'solo',
  //     key: 'solo',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 60,
  //       },
  //     }),
  //     align: 'center',
  //   },
  //   {
  //     title: 'Hạn dùng',
  //     dataIndex: 'handung',
  //     key: 'handung',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 100,
  //       },
  //     }),
  //     align: 'center',
  //     render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
  //   },
  //   {
  //     title: 'Số hóa đơn',
  //     dataIndex: 'sohoadon',
  //     key: 'sohoadon',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 110,
  //       },
  //     }),
  //     align: 'center',
  //   },
  //   {
  //     title: 'Số đăng ký',
  //     dataIndex: 'sodangky',
  //     key: 'sodangky',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 100,
  //       },
  //     }),
  //     align: 'center',
  //   },
  //   {
  //     title: 'Ghi chú',
  //     dataIndex: 'ghichu',
  //     key: 'ghichu',
  //     onCell: () => ({
  //       style: {
  //         minWidth: 100,
  //       },
  //     }),
  //     align: 'center',
  //   },
  // ]

  return (
    <>
      <div className="p-3 h-full bg-[#EFEFEF]">
        <div
          className="h-full p-2 bg-white border rounded-xl"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <Tabs
            items={[
              {
                key: 1,
                value: 1,
                label: 'Tổng hợp',
                children: (
                  <>
                    <XuatNhaThuocTongHop />
                  </>
                ),
              },
              {
                key: 2,
                value: 2,
                label: 'Chi tiết',
                children: (
                  <>
                    <XuatNhaThuocChiTiet />
                  </>
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}
export default XuatNhaThuoc

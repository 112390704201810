import { Button, Input, Dropdown, Tag, Result, Modal, Popconfirm, Menu, Alert } from 'antd'
import React, { useEffect, useState, useCallback } from 'react'
import CreateLoaiThuoc from './CreateLoaiThuoc'
import { ContainerOutlined } from '@ant-design/icons'
import { loaiHangHoaService } from '../../../services/loaiHangHoa/loaiHangHoa'
import { useDispatch, useSelector } from 'react-redux'
import * as typeAction from '../../../store/constants/constants'
import { ExclamationCircleFilled } from '@ant-design/icons'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import * as XLSX from 'xlsx'
import {
  getAllLoaiHangHoa,
  getInfoThuocVatTu,
  deleteLoaiHangHoa,
  getNhomHangHoaByLoai,
  deleteNhomHangHoa,
  updateThucPhamChucNang,
} from '../../../store/actions/loaiHangHoaAction'
import moment from 'moment'
import CreateNhomThuoc from './CreateNhomThuoc'
import EditLoaiThuoc from './EditLoaiThuoc'
import _, { values } from 'lodash'
import EditNhomThuoc from './EditNhomThuoc'
import { exportExcelformat } from '../../../utils/export/exportExcelFormat'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
const { confirm } = Modal
const LoaiHangHoa = () => {
  const dispatch = useDispatch()
  const [openAdd, setOpenAdd] = useState(false)
  const [openAdDM, setOpenAddDM] = useState(false)
  const [isEditNhan, setIsEditNhan] = useState(false)
  const [isEditDanhMuc, setIsEditDanhMuc] = useState(false)
  const [idDanhMuc, setIDDanhMuc] = useState(null)
  const [active, setActive] = useState(null)
  const [keywordNhan, setKeywordNhan] = useState('')
  const [keywordDanhMuc, setkeywordDanhMuc] = useState('')
  const [group, setGroups] = useState('')
  const { loaiHangHoa, nhomHangHoa } = useSelector((state) => state.loaiHangHoaReducer)
  const [allNhomHangHoa, setAllNhomHangHoa] = useState([])
  const [isQuyenXemDsNhom, setisQuyenXemDsNhom] = useState()

  const maQuyenXemCtLoai = 'QHT422'
  const maQuyenXuatExcelLoai = 'QHT420'
  const maQuyenThemLoai = 'QHT419'
  const maQuyenXoaLoai = 'QHT424'
  const maQuyenXemDsNhom = 'QHT421'
  const maQuyenXemCtNhom = 'QHT429'
  const maQuyenXuatExcelNhom = 'QHT426'
  const maQuyenThemNhom = 'QHT425'
  const maQuyenCapNhatDsTPCN = 'QHT427'
  const maQuyenCapNhatCtTPCN = 'QHT428'
  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemCtLoai:
        showEditNhan(infoNew?.idPhanLoai)
        break
      case maQuyenXuatExcelLoai:
        exportToExcel()
        break
      case maQuyenThemLoai:
        setOpenAdd(true)
        break
      case maQuyenXoaLoai:
        confirmDelete(infoNew?.idPhanLoai)
        break
      case maQuyenXemDsNhom:
        setisQuyenXemDsNhom(true)
        break
      case maQuyenXemCtNhom:
        setIsEditDanhMuc(true)
        setIDDanhMuc(infoNew)
        break
      case maQuyenXuatExcelNhom:
        infoNew?.type === 'all' && exportToExcelAllHH()
        infoNew?.type === 'byType' && exportToExcelHH()
        break
      case maQuyenThemNhom:
        setOpenAddDM(true)
        break
      case maQuyenCapNhatDsTPCN:
        handleCheckAll(infoNew?.checked)
        break
      case maQuyenCapNhatCtTPCN:
        handleUpdateTPCN(infoNew?.item, infoNew?.checked)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemCtLoai:
        setIsEditNhan(false)
        break
      case maQuyenXuatExcelLoai:
        break
      case maQuyenThemLoai:
        setOpenAdd(false)
        break
      case maQuyenXoaLoai:
        break
      case maQuyenXemDsNhom:
        setisQuyenXemDsNhom(false)
        break
      case maQuyenXemCtNhom:
        setIsEditDanhMuc(false)
        break
      case maQuyenXuatExcelNhom:
        break
      case maQuyenThemNhom:
        setOpenAddDM(false)
        break
      case maQuyenCapNhatDsTPCN:
        break
      case maQuyenCapNhatCtTPCN:
        break
      default:
        return false
    }
  }

  const debounceDropDownNhan = useCallback(
    _.debounce((id, keyword) => {
      dispatch(getAllLoaiHangHoa(id, keyword))
    }, 300),
    [],
  )
  useEffect(() => {
    const TongNhomHangHoaByLoai = async (id, keyword) => {
      try {
        const response = await loaiHangHoaService.getNhomHangHoaByLoai('', '')
        const data = response?.data || []
        setAllNhomHangHoa(data)
      } catch (error) {
        console.error('Error fetching data:', error)
        setAllNhomHangHoa([])
      }
    }
    TongNhomHangHoaByLoai()
  }, [])

  const debounceDropDownDanhMuc = useCallback(
    _.debounce((id, e) => {
      dispatch(getNhomHangHoaByLoai(id, e.target.value))
    }, 300),
    [],
  )
  //---------- lấy danh sách nhóm nhãn sự cố  ---------//
  const fetchDanhMucNhan = () => {
    dispatch(getNhomHangHoaByLoai(active, keywordDanhMuc))
  }

  const showDeleteConfirm = (name, id) => {
    confirm({
      title: 'Xoá nhóm hàng hóa',
      icon: <ExclamationCircleFilled />,
      content: `Bạn có muốn xoá nhóm hàng hóa ${name}`,
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ ',
      onOk() {
        dispatch(deleteNhomHangHoa(id, fetchDanhMucNhan))
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  const showEditNhan = (id) => {
    setIsEditNhan(true)
    dispatch(getInfoThuocVatTu(id))
  }
  useEffect(() => {
    dispatch(getAllLoaiHangHoa(group, keywordNhan))
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDsNhom, null, actionIsTrue, actionIsFalse))
  }, [])
  // useEffect(() => {
  //   // active &&
  //   //   !isEditNhan &&
  // }, [active])
  useEffect(() => {
    if (loaiHangHoa.length !== 0) {
      dispatch(getNhomHangHoaByLoai(loaiHangHoa[0].idPhanLoai, keywordDanhMuc))
      setActive(loaiHangHoa[0].idPhanLoai)
    }
  }, [loaiHangHoa])

  const handleCancel = () => {
    setIsEditNhan(false)

    dispatch(getAllLoaiHangHoa(group, keywordNhan))
  }
  const confirmDelete = (id) => {
    dispatch(deleteLoaiHangHoa(id, handleCancel))
  }
  const exportToExcel = () => {
    const data = loaiHangHoa?.map((item, index) => {
      return {
        'STT ': index + 1,
        'ID Phân loại': item.idPhanLoai,
        'Loại hàng hóa': item.phanLoai,
        'Ghi chú': item.ghiChu,
        'Loại ': item.loai === 'HANGHOA' ? 'Hàng hóa' : item.loai === 'THUOC' ? 'Thuốc' : '',
        'Ngày tạo': item.ngayTao ? moment(item.ngayTao).format('DD/MM/YYYY') : '-',
        'Người tạo': item.nguoiTao,
      }
    })
    const name = 'Loại hàng hoá'
    data?.length && exportExcelformat(data, name)
  }
  const exportToExcelHH = () => {
    const headers = [
      'STT',
      'ID Phân loại',
      'ID Nhóm hàng hóa',
      'Nhóm hàng hóa',
      'Loại hàng hóa',
      'Ghi chú',
      'Người tạo',
      'Ngày tạo',
    ]
    const data = nhomHangHoa?.map((item, index) => {
      return {
        'STT ': index + 1,
        'ID Phân loại': item.idPhanLoai,
        'ID Nhóm hàng hóa': item.idNhom,
        'Nhóm hàng hóa': item.nhom,
        'Loại hàng hóa': item.loaiHang,
        'Ghi chú': item.ghiChu,
        'Người tạo': item.nguoiTao,
        'Ngày tạo': item.ngayTao ? moment(item.ngayTao).format('DD/MM/YYYY') : '-',
      }
    })

    const worksheet = XLSX.utils.json_to_sheet(data, { header: headers })
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(
      workbook,
      `Nhóm hàng hóa ${loaiHangHoa.find((item) => item.idPhanLoai === active).phanLoai}.xlsx`,
    )
  }
  const exportToExcelAllHH = () => {
    const headers = [
      'STT',
      'ID Phân loại',
      'ID Nhóm hàng hóa',
      'Nhóm hàng hóa',
      'Loại hàng hóa',
      'Ghi chú',
      'Người tạo',
      'Ngày tạo',
    ]
    const data = allNhomHangHoa?.map((item, index) => {
      return {
        'STT ': index + 1,
        'ID Phân loại': item.idPhanLoai,
        'ID Nhóm hàng hóa': item.idNhom,
        'Nhóm hàng hóa': item.nhom,
        'Loại hàng hóa': item.loaiHang,
        'Ghi chú': item.ghiChu,
        'Người tạo': item.nguoiTao,
        'Ngày tạo': item.ngayTao ? moment(item.ngayTao).format('DD/MM/YYYY') : '-',
      }
    })

    const worksheet = XLSX.utils.json_to_sheet(data, { header: headers })
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, 'Danh sách nhóm hàng hóa.xlsx')
  }
  const handleXuatExcelNhom = (type) => {
    dispatch(
      putCheckQuyenThaoTacAction(maQuyenXuatExcelNhom, { type: type }, actionIsTrue, actionIsFalse),
    )
  }
  const menu = (
    <Menu>
      <Menu.Item key="all" onClick={(e) => handleXuatExcelNhom(e.key)}>
        Xuất tất cả
      </Menu.Item>
      <Menu.Item key="byType" onClick={(e) => handleXuatExcelNhom(e.key)}>
        Xuất theo loại hàng hóa
      </Menu.Item>
    </Menu>
  )

  const handleUpdateTPCN = (item, checked) => {
    const active = checked ? 1 : 0
    dispatch(updateThucPhamChucNang(active, [item.idNhom], fetchDanhMucNhan))
  }

  const [selectedItems, setSelectedItems] = useState([])

  const handleCheckAll = (isChecked) => {
    // const isChecked = e.target.checked
    if (isChecked) {
      // Get all unchecked items (where thucPhamChucNang !== 1)
      const uncheckedItems = nhomHangHoa.filter((item) => item.thucPhamChucNang !== 1)
      const uncheckedIds = uncheckedItems.map((item) => item.idNhom)
      if (uncheckedIds.length > 0) {
        // Call API to update all unchecked items to active=1
        dispatch(updateThucPhamChucNang(1, uncheckedIds, fetchDanhMucNhan))
      }
    } else {
      // Get all checked items (where thucPhamChucNang === 1)
      const checkedItems = nhomHangHoa.filter((item) => item.thucPhamChucNang === 1)
      const checkedIds = checkedItems.map((item) => item.idNhom)
      if (checkedIds.length > 0) {
        // Call API to update all checked items to active=0
        dispatch(updateThucPhamChucNang(0, checkedIds, fetchDanhMucNhan))
      }
    }
  }
  const handleConfirmUpdate = () => {
    if (selectedItems.length > 0) {
      dispatch(updateThucPhamChucNang(1, selectedItems, fetchDanhMucNhan))
      setSelectedItems([]) // Reset selected items after update
    }
  }
  return (
    <>
      <div className="p-3 bg-[#EFEFEF] h-full">
        <div className="p-3 bg-white h-full rounded-xl border">
          <div className="border h-full rounded-lg flex ">
            <div className="w-1/3 border-r p-2 h-full ">
              <div className="flex gap-2">
                <div className="flex gap-2 w-full">
                  <Input.Search
                    placeholder="Nhập từ khoá"
                    onChange={(e) => {
                      debounceDropDownNhan(e.target.value)
                      setKeywordNhan(e.target.value)
                    }}
                  />
                  {/* <Select
                    variant="filled"
                    className="w-32"
                    value={group}
                    onChange={(value) => {
                      setGroups(value)
                      // dispatch(getAllNhanSuCo(value, keywordNhan))
                    }}
                    options={[
                      { label: 'Tất cả', value: '' },
                      ...groupNhan?.map((item) => ({
                        label: item.tennhom,
                        value: item.idnhom,
                      })),
                    ]}
                  /> */}
                </div>

                <Button
                  // onClick={setOpenAdd}
                  onClick={() =>
                    dispatch(
                      putCheckQuyenThaoTacAction(
                        maQuyenThemLoai,
                        null,
                        actionIsTrue,
                        actionIsFalse,
                      ),
                    )
                  }
                  type="primary"
                >
                  Tạo loại hàng hóa
                </Button>
                <div className="flex gap-3 ml-auto">
                  <Button
                    disabled={!loaiHangHoa?.length}
                    // onClick={exportToExcel}
                    onClick={() =>
                      dispatch(
                        putCheckQuyenThaoTacAction(
                          maQuyenXuatExcelLoai,
                          null,
                          actionIsTrue,
                          actionIsFalse,
                        ),
                      )
                    }
                    type="text"
                    size="middle"
                    className="text-green-700 p-1"
                    icon={<ImportExportIcon />}
                  >
                    Xuất Excel
                  </Button>
                </div>
              </div>
              <div className="bg-[#EFEFEF] p-2 mt-2 rounded-md h-[95%] max-h-[780px] overflow-auto">
                <ul className="flex flex-col gap-3">
                  {loaiHangHoa.map((item, index) => (
                    <li
                      key={index}
                      onClick={(e) => {
                        setActive(item?.idPhanLoai)
                        dispatch(getNhomHangHoaByLoai(item?.idPhanLoai, keywordDanhMuc))
                        // dispatch(
                        //   putCheckQuyenThaoTacAction(
                        //     maQuyenXemDsNhom,
                        //     item,
                        //     actionIsTrue,
                        //     actionIsFalse,
                        //   ),
                        // )
                      }}
                      className={`h-20 ${active === item.idPhanLoai ? 'bg-blue-50 border-blue-400' : 'bg-white'} border rounded-md cursor-pointer hover:-translate-y-1 duration-150 p-2`}
                    >
                      <div className="flex justify-between ">
                        <h2 className="font-semibold  flex gap-1 items-center">
                          {item.phanLoai}
                          <span
                            onClick={(e) => {
                              dispatch(
                                putCheckQuyenThaoTacAction(
                                  maQuyenXemCtLoai,
                                  item,
                                  actionIsTrue,
                                  actionIsFalse,
                                ),
                              )
                            }}
                            className="bg-green-200 text-green-700 rounded hover:bg-green-700 hover:text-white duration-200 cursor-pointer "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </span>
                          <Popconfirm
                            title="Xoá hàng hóa ?"
                            description="Bạn có chắc muốn xoá hàng hóa này không?"
                            onConfirm={() =>
                              dispatch(
                                putCheckQuyenThaoTacAction(
                                  maQuyenXoaLoai,
                                  item,
                                  actionIsTrue,
                                  actionIsFalse,
                                ),
                              )
                            }
                            okText="Xoá"
                            cancelText="bỏ"
                            placement="bottomRight"
                          >
                            <span className="flex items-center text-gray-400 font-normal bg-slate-100 p-1 rounded-md hover:text-red-500 cursor-pointer duration-150">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                />
                              </svg>
                            </span>
                          </Popconfirm>
                        </h2>
                        <span className="text-gray-400 font-normal">
                          Ngày tạo: {item.ngayTao ? moment(item.ngayTao).format('DD/MM/YYYY') : '-'}
                        </span>
                      </div>
                      <div className="flex justify-between mt-1">
                        <p className="text-gray-400">
                          Ghi chú:
                          {item.ghiChu}
                        </p>
                        <Tag
                          className="font-semibold"
                          color={
                            item.loai === 'HANGHOA'
                              ? 'blue'
                              : item.loai === 'THUOC'
                                ? 'green'
                                : 'cyan'
                          }
                        >
                          {item.loai === 'HANGHOA'
                            ? 'Hàng hóa'
                            : item.loai === 'THUOC'
                              ? 'Thuốc'
                              : ''}
                        </Tag>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="w-2/3 p-2">
              <div className="flex justify-between items-center">
                <h2 className="text-green-700 font-semibold">Số nhóm hàng: {nhomHangHoa.length}</h2>
                <div className="flex gap-2 justify-end">
                  <Input.Search
                    className="w-72"
                    placeholder="Nhập từ khoá"
                    onChange={(e) => {
                      debounceDropDownDanhMuc(active, e)
                      setkeywordDanhMuc(e.target.value)
                    }}
                  />
                  <Button
                    onClick={() =>
                      dispatch(
                        putCheckQuyenThaoTacAction(
                          maQuyenThemNhom,
                          null,
                          actionIsTrue,
                          actionIsFalse,
                        ),
                      )
                    }
                    type="primary"
                  >
                    Tạo nhóm hàng hóa
                  </Button>
                  {/* Thực phẩm chức năng check all */}
                  <div className="flex items-center gap-2 ">
                    <label className="text-gray-400">Chọn tất cả thực phẩm chức năng</label>
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-blue-600 cursor-pointer"
                      // onChange={handleCheckAll}
                      onChange={(e) =>
                        dispatch(
                          putCheckQuyenThaoTacAction(
                            maQuyenCapNhatDsTPCN,
                            { checked: e.target.checked },
                            actionIsTrue,
                            actionIsFalse,
                          ),
                        )
                      }
                      checked={
                        nhomHangHoa.length > 0 &&
                        nhomHangHoa.every((item) => item.thucPhamChucNang === 1)
                      }
                    />
                  </div>
                  <Dropdown overlay={menu} trigger={['hover']}>
                    <Button
                      type="text"
                      size="middle"
                      className="text-green-700 p-1"
                      icon={<ImportExportIcon />}
                    >
                      Xuất Excel
                    </Button>
                  </Dropdown>
                </div>
              </div>
              <div className="bg-[#EFEFEF] p-2 mt-2 rounded-md h-[95%] max-h-[780px] overflow-auto">
                {nhomHangHoa.length !== 0 && isQuyenXemDsNhom ? (
                  <ul className="flex flex-col gap-3">
                    {nhomHangHoa.map((item, index) => (
                      <li
                        key={index}
                        className="bg-white rounded-md cursor-pointer hover:-translate-y-1 duration-150 p-2 flex flex-col"
                      >
                        <div className="flex flex-col sm:flex-row justify-between">
                          <h2 className="font-semibold break-words flex gap-2">
                            {item.nhom}
                            <div className="flex gap-2 items-center mt-2 sm:mt-0">
                              <button
                                onClick={() =>
                                  dispatch(
                                    putCheckQuyenThaoTacAction(
                                      maQuyenXemCtNhom,
                                      item,
                                      actionIsTrue,
                                      actionIsFalse,
                                    ),
                                  )
                                }
                                className="text-green-700 bg-green-200 hover:text-white hover:bg-green-700 duration-200 p-1 flex items-center justify-center rounded-md size-6"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="size-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                  />
                                </svg>
                              </button>
                              {/* <button
                                onClick={() => showDeleteConfirm(item.nhom, item.idNhom)}
                                className="text-red-700 bg-red-200 hover:text-white hover:bg-red-700 duration-200 p-1 flex items-center justify-center rounded-md size-6"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                  />
                                </svg>
                              </button> */}
                            </div>
                          </h2>
                          <div className="flex gap-2 items-center">
                            <label className="text-gray-400">Thực phẩm chức năng</label>
                            <input
                              type="checkbox"
                              className="form-checkbox h-5 w-5 text-blue-600 cursor-pointer"
                              checked={
                                item.thucPhamChucNang === 1 || selectedItems.includes(item.idNhom)
                              }
                              onChange={(e) => {
                                if (!selectedItems.includes(item.idNhom)) {
                                  dispatch(
                                    putCheckQuyenThaoTacAction(
                                      maQuyenCapNhatCtTPCN,
                                      { item: item, checked: e.target.checked },
                                      actionIsTrue,
                                      actionIsFalse,
                                    ),
                                  )
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-2">
                          <p className="text-gray-400 text-sm">
                            Người tạo: <span className="font-semibold">{item.nguoiTao}</span> - Ngày
                            tạo: {item.ngayTao ? moment(item.ngayTao).format('DD/MM/YYYY') : '-'}
                          </p>
                          <div className="flex items-center gap-5">
                            <div className="flex flex-col rounded text-gay-500">
                              Hàng sử dụng / Tổng hàng:
                              <div>
                                <span className=" font-semibold text-base text-green-700">
                                  {item.tongActive}
                                </span>
                                / <span className=" font-semibold text-base">{item.tongHang}</span>
                              </div>
                              <i className="text-red-500 bg-red-100 rounded p-1 text-sm">
                                (*) Tổng hàng hoá của tất cả công ty
                              </i>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : isQuyenXemDsNhom === false ? (
                  <>
                    <Result icon={<ContainerOutlined />} title="Xem danh sách nhóm hàng hóa" />
                    <Alert message="Tài khoản không có quyền thao tác!" type="error" showIcon />
                  </>
                ) : (
                  <Result icon={<ContainerOutlined />} title="Chưa có nhóm hàng hóa" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateLoaiThuoc
        openAdd={openAdd}
        setOpenAdd={setOpenAdd}
        group={group}
        keywordNhan={keywordNhan}
      />
      <CreateNhomThuoc
        openAdDM={openAdDM}
        setOpenAddDM={setOpenAddDM}
        active={active}
        keywordDanhMuc={keywordDanhMuc}
      />
      <EditLoaiThuoc isEditNhan={isEditNhan} setIsEditNhan={setIsEditNhan} />
      <EditNhomThuoc
        isEditDanhMuc={isEditDanhMuc}
        setIsEditDanhMuc={setIsEditDanhMuc}
        idDanhMuc={idDanhMuc}
        group={active}
        keywordNhan={keywordNhan}
      />
    </>
  )
}

export default LoaiHangHoa

import * as typeAction from '../constants/constants'

export const postGiaVatTuDVThuThuatAction = (form, resetForm) => ({
  type: typeAction.POST_GIAVATTU_DV_THUTHUAT_HOAHONG,
  form,
  resetForm,
})
export const putGiaVatTuDVThuThuatAction = (form, resetForm) => ({
  type: typeAction.PUT_GIAVATTU_DV_THUTHUAT_HOAHONG,
  form,
  resetForm,
})
export const deleteGiaVatTuDVThuThuatAction = (idhh, onLoad) => ({
  type: typeAction.DELETE_GIAVATTU_DV_THUTHUAT_HOAHONG,
  idhh,
  onLoad,
})

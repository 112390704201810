import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  listCTy: null,
  infoCty: null,
}

const congtyCRMReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_ALL_CTY_CRM:
        draft.listCTy = payload
        break
      case typeAction.DISPATCH_INFO_CTY_CRM:
        draft.infoCty = payload
        break
      default:
        return state
    }
  })
}

export default congtyCRMReducer

import { DatePicker, Input, Modal } from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTaiKhamTool } from '../../../../store/actions/toolAction'
import 'moment/locale/vi'
moment.locale('vi')

const ModalEditTaiKham = ({ openTaiKham, setTaiKham }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const hanldeSubmit = (value) => {
    dispatch(updateTaiKhamTool(value, reset))
  }
  const handleCancel = () => {
    setTaiKham(false)
  }
  const reset = () => {
    formik.resetForm()
    handleCancel()
  }
  const handleDatePicker = (data, dateString) => {
    formik.setFieldValue('ngaytaikham', moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'))
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      maphieuthu: null,
      ngaytaikham: null,
      nguoisua: infoUser?.idnv,
    },
    onSubmit: (value) => hanldeSubmit(value),
  })
  return (
    <Modal
      onOk={formik.submitForm}
      okText="Cập nhật"
      cancelText="Huỷ bỏ"
      title="Chỉnh ngày tái khám"
      open={openTaiKham}
      onCancel={handleCancel}
    >
      <div>
        <div>
          <label className="font-semibold text-base mb-2">Mã Phiếu thu</label>
          <Input
            value={formik.values.maphieuthu}
            placeholder="PT. . . ."
            name="maphieuthu"
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex flex-col">
          <label className="font-semibold text-base ">Ngày Tái khám</label>
          <DatePicker
            allowClear={false}
            onChange={handleDatePicker}
            value={formik.values.ngaytaikham ? dayjs(formik.values.ngaytaikham, 'YYYY-MM-DD') : ''}
            format={'DD/MM/YYYY'}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ModalEditTaiKham

import { SaveOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import Docxtemplater from 'docxtemplater'
import { saveAs } from 'file-saver'
import moment from 'moment'
import PizZip from 'pizzip'
import React, { useState } from 'react'
import benhAnTemplate from '../../../../data/BenhAn/benhantemplate.docx'
import ToastCus from '../../../../utils/Toast'

const getChuyenKhoa = (value) => {
  switch (value) {
    case 1:
      return `NHI KHOA`
    case 2:
      return `SẢN KHOA`
    case 3:
      return `LÃO KHOA`
    case 4:
      return `MẮT`
    case 21:
      return `TIM MẠCH TIỂU ĐƯỜNG`
    default:
      return 'ĐA KHOA'
  }
}

const getLoaiPK = (value) => {
  switch (value) {
    case 1:
      return 'Hệ thống Y tế Nhi đồng 315'
    case 2:
      return 'Hệ thống phòng khám Sản khoa 315'
    case 3:
      return 'Hệ thống Y tế TMTĐ 315'
    case 4:
      return 'Hệ thống phòng khám Mắt 315'
    case 21:
      return 'Hệ thống Y tế TMTĐ 315'
    default:
      return 'Phòng khám Đa khoa Quốc tế IVY HEALTH'
  }
}

const calculateRemainingDots = (text, prefixText, pageWidth) => {
  const charWidth = 0.5 // Approximate width per character in 'em' units, adjust as needed
  const maxCharsPerLine = Math.floor(pageWidth / charWidth)

  const totalTextLength = text.length + prefixText.length
  const remainingLength = maxCharsPerLine - totalTextLength

  return '.'.repeat(Math.max(remainingLength, 0))
}

function calculateAge(birthdate) {
  const birthDate = moment(birthdate)
  const today = moment()
  return today.diff(birthDate, 'years')
}

const calculateLineCountAndAddDots = (content, maxCharsPerLine = 180, totalLines = 6) => {
  // Calculate the estimated number of lines
  const estimatedLineCount = Math.ceil(content.length / maxCharsPerLine)
  const lines = [content]

  // Add lines of dots if the content is fewer than the required lines
  for (let i = estimatedLineCount; i < totalLines; i++) {
    lines.push('.'.repeat(maxCharsPerLine)) // Full line of dots
  }
  //remove line empty
  const filteredLines = lines.filter((line) => line.trim() !== '')

  return filteredLines.join('\n') // Join with new lines for proper line breaks
}

const breakLines = (text, maxChars, totalLines = 6, width = 140) => {
  const words = text.split(' ')
  let lines = []
  let currentLine = ''

  words.forEach((word) => {
    if ((currentLine + ' ' + word).length <= maxChars) {
      currentLine += (currentLine === '' ? '' : ' ') + word
    } else {
      lines.push(currentLine)
      currentLine = word
    }
  })

  if (currentLine) {
    lines.push(currentLine)
  }

  if (lines.length < totalLines) {
    const remainingLines = totalLines - lines.length
    lines = lines.concat(Array(remainingLines).fill('.'.repeat(width)))
  }

  lines = lines.filter((line) => line.trim() !== '')

  return lines.join('\n')
}

const breakLinesNoDot = (text, maxChars) => {
  const words = text.split(' ')
  let lines = []
  let currentLine = ''

  words.forEach((word) => {
    if ((currentLine + ' ' + word).length <= maxChars) {
      currentLine += (currentLine === '' ? '' : ' ') + word
    } else {
      lines.push(currentLine)
      currentLine = word
    }
  })

  if (currentLine) {
    lines.push(currentLine)
  }

  lines = lines.filter((line) => line.trim() !== '')

  return lines.join('\n')
}

const DocxGenerator = ({ infoBNTTC, icdObj }) => {
  const [loading, setLoading] = useState(false)
  // Sample data to be mapped into the template

  const generateDocument = async () => {
    // Đường dẫn đến template .docx
    const templatePath = benhAnTemplate
    setLoading(true)
    // Đọc template
    fetch(templatePath)
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        // Tạo một PizZip object từ buffer
        const zip = new PizZip(buffer)
        // const doc = new Docxtemplater().loadZip(zip)

        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          // Add custom formatter
          parser: function (tag) {
            return {
              get: function (scope) {
                if (tag === 'toan_than') {
                  // Replace 'longText' with your variable name
                  return breakLines(scope[tag], 70, 6)
                }
                if (tag === 'benh_chinh') {
                  // Replace 'longText' with your variable name
                  return breakLinesNoDot(scope[tag], 60, 2)
                }
                if (tag === 'benh_kem_theo'|| tag === 'bien_chung') {
                  // Replace 'longText' with your variable name
                  return breakLinesNoDot(scope[tag], 55, 2)
                }
                return scope[tag]
              },
            }
          },
        })

        const tenBenhNhan = infoBNTTC?.benhNhan?.tenBenhNhan

        // Calculate the number of dots based on `ten_benh_nhan` length
        // Usage
        const pageWidth = 65
        const prefixText = '1. Họ và tên (In hoa): '
        const patientName = infoBNTTC?.benhNhan?.tenBenhNhan
        const dots = calculateRemainingDots(patientName, prefixText, pageWidth)

        const age = calculateAge(infoBNTTC.benhNhan.ngaySinh)
        let ageShow = ''
        if (age < 10) {
          ageShow = '00' + age
        } else {
          //tach so
          let ageString = age + ''
          while (ageString.length < 3) {
            ageString = '0' + ageString
          }
          ageShow = ageString
        }

        let dantocShow = ''
        if (infoBNTTC.benhNhan.idDanToc < 10) {
          // Set border color

          dantocShow = '0' + infoBNTTC.benhNhan.idDanToc ?? '0'
        } else {
          //tach so
          let idDanToc = infoBNTTC.benhNhan.idDanToc + ''
          let dotStrings = []
          for (let i = 0; i < idDanToc.length; i++) {
            dotStrings.push(idDanToc[i])
          }
          dantocShow = dotStrings.join('')
        }

        let quoctichShow = ''
        if (infoBNTTC.benhNhan.idQuocTich < 10) {
          quoctichShow = '00' + infoBNTTC.benhNhan.idQuocTich ?? '0'
        } else {
          //tach so
          let idQuocTich = infoBNTTC.benhNhan.idQuocTich + ''
          while (idQuocTich.length < 3) {
            idQuocTich = '0' + idQuocTich
          }

          quoctichShow = idQuocTich
        }

        let xaphuongShow = ''
        if (infoBNTTC.benhNhan.idPhuong < 10) {
          xaphuongShow = '0000' + infoBNTTC.benhNhan.idPhuong ?? '0'
        } else {
          //tach so
          let idPhuongXa = infoBNTTC.benhNhan.idPhuong + ''
          while (idPhuongXa.length < 5) {
            idPhuongXa = '0' + idPhuongXa
          }
          xaphuongShow = idPhuongXa
        }

        let quanShow = ''
        if (infoBNTTC.benhNhan.idQuan < 10) {
          quanShow = '000' + infoBNTTC.benhNhan.idQuan ?? '0'
        } else {
          //tach so
          let idQuanHuyen = infoBNTTC.benhNhan.idQuan + ''
          while (idQuanHuyen.length < 3) {
            idQuanHuyen = '0' + idQuanHuyen
          }
          quanShow = idQuanHuyen
        }

        let tinhShow = ''
        if (infoBNTTC.benhNhan.idTinh < 10) {
          tinhShow = '0' + infoBNTTC.benhNhan.idTinh ?? '0'
        } else {
          //tach so
          tinhShow = infoBNTTC.benhNhan.idTinh + ''
        }

        // Thay thế các placeholder trong template bằng dữ liệu
        doc.setData({
          khoa: getChuyenKhoa(infoBNTTC.benhNhan.chiNhanh.idChuyenKhoa),
          loai_pk: getLoaiPK(infoBNTTC.benhNhan.chiNhanh.idChuyenKhoa),
          ten_benh_nhan: `${tenBenhNhan}${dots}`,
          ngay_sinh: moment(infoBNTTC.benhNhan.ngaySinh ?? '').format('DD/MM/YYYY'),
          tuoi1: ageShow[0],
          tuoi2: ageShow[1],
          tuoi3: ageShow[2],
          nam: infoBNTTC.benhNhan.gioiTinh === 'Nam' ? '☑' : '☐',
          nu: infoBNTTC.benhNhan.gioiTinh === 'Nữ' ? '☑' : '☐',
          dien_thoai: infoBNTTC.benhNhan.dienThoai ?? '',
          nghe_nghiep: infoBNTTC.benhNhan.ngheNghiep ?? '',
          dan_toc: infoBNTTC.benhNhan.danToc ?? '',
          dt1: dantocShow[0],
          dt2: dantocShow[1],
          quoc_tich: infoBNTTC.benhNhan.tenQuocTich ?? '',
          qt1: quoctichShow[0],
          qt2: quoctichShow[1],
          qt3: quoctichShow[2],
          dia_chi: infoBNTTC.benhNhan.diaChi ?? '',
          xa_phuong: infoBNTTC.benhNhan.tenPhuongXa ?? '',
          xp1: xaphuongShow[0],
          xp2: xaphuongShow[1],
          xp3: xaphuongShow[2],
          xp4: xaphuongShow[3],
          xp5: xaphuongShow[4],
          huyen: infoBNTTC.benhNhan.tenQuanHuyen ?? '',
          h1: quanShow[0],
          h2: quanShow[1],
          h3: quanShow[2],
          tinh_tp: infoBNTTC.benhNhan.tenTinhTP ?? '',
          tp1: tinhShow[0],
          tp2: tinhShow[1],
          cccd: infoBNTTC.benhNhan.cccd ?? '',
          ten_nguoi_than: infoBNTTC.tennguoinha ?? '',
          sdt_nguoi_than: infoBNTTC.dienthoainguoinha ?? '',
          li_do: infoBNTTC.lydokham ?? '',
          quatrinh_benhly: calculateLineCountAndAddDots(infoBNTTC.benhsu ?? ''),
          ko_diung: infoBNTTC.diung ? '☐' : '☑',
          co_diung: infoBNTTC.diung ? '☑' : '☐',
          di_ung: infoBNTTC.diung ?? '',
          ko_banthan: infoBNTTC.tiensubanthan ? '☐' : '☑',
          co_banthan: infoBNTTC.tiensubanthan ? '☑' : '☐',
          ban_than: infoBNTTC.tiensubanthan ?? '',
          ko_giadinh: infoBNTTC.tieusugiadinh ? '☐' : '☑',
          co_giadinh: infoBNTTC.tieusugiadinh ? '☑' : '☐',
          gia_dinh: infoBNTTC.tieusugiadinh ?? '',
          toanthan_bth: infoBNTTC.toanthan ? '☐' : '☑',
          toanthan_batthuong: infoBNTTC.toanthan ? '☑' : '☐',
          toan_than: infoBNTTC.toanthan ?? '',
          m: infoBNTTC.mach ?? '',
          nd: infoBNTTC.nhietdo ?? '',
          ha: infoBNTTC.huyetap ?? '',
          nt: infoBNTTC.nhiptho ?? '',
          cn: infoBNTTC.cannang ?? '',
          cc: infoBNTTC.chieucao ?? '',
          th_binhthuong: infoBNTTC.tuanhoan ? '☐' : '☑',
          th_batthuong: infoBNTTC.tuanhoan ? '☑' : '☐',
          tuan_hoan: infoBNTTC.tuanhoan ?? '',
          hh_binhthuong: infoBNTTC.hohap ? '☐' : '☑',
          hh_batthuong: infoBNTTC.hohap ? '☑' : '☐',
          ho_hap: infoBNTTC.hohap ?? '',
          tieuhoa_binhthuong: infoBNTTC.tieuhoa ? '☐' : '☑',
          tieuhoa_batthuong: infoBNTTC.tieuhoa ? '☑' : '☐',
          tieu_hoa: infoBNTTC.tieuhoa ?? '',
          than_binhthuong: infoBNTTC.thantietnieu ? '☐' : '☑',
          than_batthuong: infoBNTTC.thantietnieu ? '☑' : '☐',
          than: infoBNTTC.thantietnieu ?? '',
          tk_binhthuong: infoBNTTC.thankinh ? '☐' : '☑',
          tk_batthuong: infoBNTTC.thankinh ? '☑' : '☐',
          than_kinh: infoBNTTC.thankinh ?? '',
          csk_binhthuong: infoBNTTC.coxuongkhop ? '☐' : '☑',
          csk_batthuong: infoBNTTC.coxuongkhop ? '☑' : '☐',
          co_xuong_khop: infoBNTTC.coxuongkhop ?? '',
          tmh_binhthuong: infoBNTTC.taimuihong ? '☐' : '☑',
          tmh_batthuong: infoBNTTC.taimuihong ? '☑' : '☐',
          tai_mui_hong: infoBNTTC.coxuongkhop ?? '',
          rhm_binhthuong: infoBNTTC.ranghammat ? '☐' : '☑',
          rhm_batthuong: infoBNTTC.ranghammat ? '☑' : '☐',
          rang_ham_mat: infoBNTTC.ranghammat ?? '',
          mat_binhthuong: infoBNTTC.mat ? '☐' : '☑',
          mat_batthuong: infoBNTTC.mat ? '☑' : '☐',
          mat: infoBNTTC.mat ?? '',
          benh_ly_khac: infoBNTTC.benhlykhac ?? '',
          tomtat_kqcls: calculateLineCountAndAddDots(infoBNTTC.kqcls ?? '', 180, 5),
          icd_vao_vien: icdObj.chandoanbandau ?? '',
          chan_doan_vao_vien: calculateLineCountAndAddDots(infoBNTTC.chandoanbandau ?? '', 180, 2),
          da_xu_ly: calculateLineCountAndAddDots(infoBNTTC.daxuly ?? '', 180, 3),
          icd_ra_vien: icdObj.chandoanravien ?? '',
          chan_doan_ra_vien: calculateLineCountAndAddDots(infoBNTTC.chandoanravien ?? '', 180, 2),
          dieu_tri_ngoai_tru: infoBNTTC.dieutringoaitru ?? '',
          ngay: moment(infoBNTTC.ngaykham).format('DD'),
          thang: moment(infoBNTTC.ngaykham).format('MM'),
          nam_kham: moment(infoBNTTC.ngaykham).format('YYYY'),
          bs_kham: infoBNTTC.bskham ?? '',
          tom_tat_benh_ly: calculateLineCountAndAddDots(
            `${infoBNTTC.benhsu ?? ''} ${infoBNTTC.quatrinhbenhly ?? ''} ${infoBNTTC.dienbienlamsang ?? ''}`,
            180,
            6,
          ),
          tom_tat_kq_xet_nghiem: calculateLineCountAndAddDots(infoBNTTC.kqcls ?? '', 180, 5),
          benh_chinh: infoBNTTC.chandoan ?? '',
          icd_chinh: icdObj.chandoan ?? '',
          benh_kem_theo: infoBNTTC.benhkemtheo ?? '',
          icd_kem_theo: icdObj.benhkemtheo ?? '',
          bien_chung: infoBNTTC.bienchung ?? '',
          icd_bien_chung: icdObj.bienchung ?? '',
          pp_dieu_tri: calculateLineCountAndAddDots(infoBNTTC.ppdieutri ?? '', 180, 5),
          khoi: infoBNTTC.tinhtrangravien === 1 ? '☑' : '☐',
          do: infoBNTTC.tinhtrangravien === 2 ? '☑' : '☐',
          khong_thay_doi: infoBNTTC.tinhtrangravien === 3 ? '☑' : '☐',
          nanghon: infoBNTTC.tinhtrangravien === 4 ? '☑' : '☐',
          tuvong: infoBNTTC.tinhtrangravien === 5 ? '☑' : '☐',
          tienluong_nang: infoBNTTC.tinhtrangravien === 6 ? '☑' : '☐',
          chua_xac_dinh: infoBNTTC.tinhtrangravien === 7 ? '☑' : '☐',
          huong_dieu_tri: calculateLineCountAndAddDots(infoBNTTC.huongxuly ?? '', 180, 4),
        })

        try {
          // Render document
          doc.render()
          const outputBuffer = doc.getZip().generate({ type: 'blob' })
          const name = `Bệnh án ${infoBNTTC.benhNhan.maBenhNhan}-${infoBNTTC.benhNhan.tenBenhNhan} ${moment(infoBNTTC.ngaykham).format('DD-MM-YYYY')}.docx`
          saveAs(outputBuffer, name)
          // In phiếu
        } catch (error) {
          console.error('Error rendering document:', error)
        }
      })
      .catch((error) => {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Lỗi',
          text: 'Có lỗi xảy ra khi tải xuống bệnh án',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      <Button
        loading={loading}
        onClick={generateDocument}
        type="default"
        icon={<SaveOutlined />}
        className="ml-2 text-green-700"
      >
        Bệnh án (Word)
      </Button>
    </div>
  )
}

export default DocxGenerator

export const inputData = [
  {
    label: 'Ngày nhận',
    name: 'ngayNhan',
    type: 'date',
  },
  {
    label: 'Người nhận',
    name: 'tenNVNhan',
    type: 'text',
  },
  {
    label: 'Công ty',
    name: 'tenCT',
    type: 'text',
  },
  // {
  //   label: 'Chi nhánh',
  //   name: 'tenChiNhanhNhan',
  //   type: 'text',
  // },
  // {
  //   label: 'Kho nhận',
  //   name: 'tenKhoNhap',
  //   type: 'text',
  // },
]

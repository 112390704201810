import { https } from '../apiService'

export const banLeService = {
  // getPhieuThuBanLe: (idcn, tuNgay, denNgay, keyword) =>
  //   https.get('BenhNhanPhieuThu/GetDanhSachPhieuThuBanLe', {
  //     params: {
  //       idcn,
  //       tuNgay,
  //       denNgay,
  //       keyword,
  //     },
  //   }),
  getPhieuThuBanLe: (idcn, tuNgay, denNgay, keyword, idCa) =>
    https.get(
      `BenhNhanPhieuThu/GetDanhSachPhieuThuBanLe?${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}${idCa}${keyword?.length ? `&keyword=${keyword}` : ''}`,
    ),
}

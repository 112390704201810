import { call, put, select, takeLatest } from 'redux-saga/effects'
import { thuThuatService } from '../../services/thuThuat/thuThuatService'
import { phanQuyenService } from '../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../actions/phanQuyenAction'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
import Swal from 'sweetalert2'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

export function* thuThuatSaga() {
  //LẤY THÔNG TIN BỆNH NHÂN THỦ THUẬT
  yield takeLatest(
    typeAction.GET_INFO_BN_DV_THUTHUAT,
    function* getInfoBNDVThuThuatSaga({ type, idbnttc, idcn }) {
      try {
        const { data } = yield call(() => thuThuatService.getInfoBNDVThuThuat(idbnttc))
        const listVtthBn = data?.DanhSachVatTuTieuHao?.filter((item) => item?.idDichVu)
        // const listVT = yield call(() => thuThuatService.getListVTThuThuat(idcn))
        // yield put({
        //     type: typeAction.DISPATCH_LIST_VT_THUTHUAT,
        //     payload: listVT?.data.filter((items) => items.tonKho > 0).map((items) => ({ ...items, iD: ('' + items.idThuoc + items.soLo + items.hanDung + items.soHoaDon + items.maSoDangKy + items.tonKho) })),
        // })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_DV_THUTHUAT,
          payload: { ...data, DanhSachVatTuTieuHao: listVtthBn },
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  //LẤY DANH SÁCH TỒN KHO VẬT TƯ TIÊU HAO THỦ THUẬT
  yield takeLatest(
    typeAction.GET_LIST_VT_THUTHUAT,
    function* getListVTThuThuatSaga({ type, idcn }) {
      try {
        const result = yield call(() => thuThuatService.getListVTThuThuat(idcn))
        yield put({
          type: typeAction.DISPATCH_LIST_VT_THUTHUAT,
          payload: result?.data
            .filter((items) => items.tonKho > 0)
            .map((items) => ({
              ...items,
              iD: `${items.idThuoc}${items.soLo}${items.hanDung}${items.soHoaDon}`,
            })),
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )

  //LẤY DANH SÁCH TỒN KHO VẬT TƯ TIÊU HAO THỦ THUẬT THEO KHO ĐIỀU DƯỠNG HOẶC CẤP CỨU
  yield takeLatest(
    typeAction.GET_LIST_VTTH_THEOKHO_THUTHUAT,
    function* getListVTTHThuThuatTheoKhoSaga({ type, idcn, khoChon }) {
      try {
        let result = null
        if (khoChon === 1) {
          // khoChon = 1 là kho điều dưỡng,
          result = yield call(() => thuThuatService.getListVTTheoKhoDieuDuong(idcn))
        } else if (khoChon === 2) {
          // khoChon = 2 là kho cấp cứu
          result = yield call(() => thuThuatService.getListVTTheoKhoCapCuu(idcn))
        } else if (khoChon === 3) {
          // khoChon = 3 là kho kỹ thuật viên tròng kính
          result = yield call(() => thuThuatService.getListVTTheoKhoKTV(idcn))
          // result = yield call(() => thuThuatService.getListVTTheoKhoDieuDuong(idcn))
        }
        if (result?.data) {
          yield put({
            type: typeAction.DISPATCH_LIST_VT_THUTHUAT,
            payload: result?.data
              .filter((items) => items.tonKho > 0)
              .map((items) => ({
                ...items,
                iD: `${items.idThuoc}${items.soLo}${items.hanDung}${items.soHoaDon}${items.idKhoCN}`,
              })),
          })
        }
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  //LẤY DANH SÁCH BỆNH NHÂN CHỜ THỰC HIỆN THỦ THUẬT
  yield takeLatest(typeAction.GET_LIST_BN_CHO_THUTHUAT, function* getListBNChoSaga({ type, idcn }) {
    try {
      const { infoUser } = yield select((state) => state.userReducer)
      const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
      yield call(() =>
        phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT343'),
      )
      const result = yield call(() => thuThuatService.getListBNCho(idcn))
      yield put({
        type: typeAction.DISPATCH_LIST_BN_CHO_THUTHUAT,
        payload: result.data,
      })
    } catch (error) {
      console.error('Error : ', error)
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  })
  //LẤY DANH SÁCH BỆNH NHÂN ĐÃ THỰC HIỆN THỦ THUẬT
  yield takeLatest(
    typeAction.GET_LIST_BN_DATHUCHIEN_THUTHUAT,
    function* getListBNDaThucHienSaga({ type, idcn, tungay, denngay }) {
      try {
        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT344'),
        )
        const result = yield call(() => thuThuatService.getListBNDaThucHien(idcn, tungay, denngay))
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DATHUCHIEN_THUTHUAT,
          payload: result.data,
        })
      } catch (error) {
        console.error('Error : ', error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      }
    },
  )
  //LƯU DANH SÁCH VẬT TƯ TIÊU HAO BỆNH NHÂN THỦ THUẬT
  yield takeLatest(
    typeAction.POST_LIST_VT_BN_THUTHUAT,
    function* postListVTBNThuThuatSaga({ type, list, listdv, idcn, tungay, denngay }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => thuThuatService.postListVTBNThuThuat(list))
        const listDV = yield call(() => thuThuatService.putListDVBNThuThuat(listdv))
        const listCho = yield call(() => thuThuatService.getListBNCho(idcn))
        const listDaThucHien = yield call(() =>
          thuThuatService.getListBNDaThucHien(idcn, tungay, denngay),
        )
        // const listVT = yield call(() => thuThuatService.getListVTThuThuat(idcn))
        // yield put({
        //     type: typeAction.DISPATCH_LIST_VT_THUTHUAT,
        //     payload: listVT?.data.filter((items) => items.tonKho > 0).map((items) => ({ ...items, iD: ('' + items.idThuoc + items.soLo + items.hanDung + items.soHoaDon + items.maSoDangKy + items.tonKho) })),
        // })
        yield put({
          type: typeAction.DISPATCH_LIST_BN_CHO_THUTHUAT,
          payload: listCho.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DATHUCHIEN_THUTHUAT,
          payload: listDaThucHien.data,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_DV_THUTHUAT,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_VT_BN_THUTHUAT,
          payload: [],
        })
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        yield console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  //CẬP NHẬT DANH SÁCH VẬT TƯ TIÊU HAO BỆNH NHÂN THỦ THUẬT
  yield takeLatest(
    typeAction.PUT_LIST_VT_BN_THUTHUAT,
    function* putListVTBNThuThuatSaga({ type, list, listdv, idbnttc, idcn, tungay, denngay }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => thuThuatService.putListVTBNThuThuat(idbnttc, list))
        yield call(() => thuThuatService.putListDVBNThuThuat(listdv))
        const listCho = yield call(() => thuThuatService.getListBNCho(idcn))
        const listDaThucHien = yield call(() =>
          thuThuatService.getListBNDaThucHien(idcn, tungay, denngay),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_BN_CHO_THUTHUAT,
          payload: listCho.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DATHUCHIEN_THUTHUAT,
          payload: listDaThucHien.data,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_DV_THUTHUAT,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_VT_BN_THUTHUAT,
          payload: [],
        })
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        yield console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}

import { Tabs } from 'antd'
import React from 'react'
import LayoutApp from '../../../../HOCs/LayoutApp'
import Synthetic from './Synthetic/Synthetic'
import Medicine from './Medicine/Medicine'
import Vaccine from './Vaccine/Vaccine'
const Profit = () => {
  return (
    <>
      <div className="p-3 bg-[#EFEFEF] h-full">
        <div className="bg-white h-full p-2 rounded-xl border">
          <Tabs
            items={[
              {
                key: 1,
                label: 'Tổng hợp',
                children: <Synthetic />,
              },
              {
                key: 2,
                label: 'Thuốc',
                children: <Medicine />,
              },
              {
                key: 3,
                label: 'Vaccine',
                children: <Vaccine />,
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default Profit

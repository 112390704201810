import { https } from '../apiService'

export const versionService = {
  postVersion: (form) => https.post('PhienBan/AddPhienBan', form),
  getAllVersion: () => https.get('PhienBan/GetAllPhienBan'),
  deleteVersion: (id) => https.delete(`PhienBan/DeletePhienBan?idpb=${id}`),
  updateVersion: (data) => https.put(`PhienBan/UpdatePhienBan?idpb=${data.idpb}`, data),
  getInfoVersion: (id) => https.get(`PhienBan/GetPhienBanByIDPB?idpb=${id}`),
  getNewVersion: () => https.get('PhienBan/GetPhienBan')
}

import moment from 'moment'
import { configSearchTableAnt } from '../../../../utils/configSearchTableAntd'
import { formatNumberVND } from '../../../../utils/formatNumberVND'

export const column = (searchValues, setSearchValues, reverseData, showDetailProduct) => {
  return [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      render: (text, record, index) => ++index,
      align: 'center',
    },
    {
      title: 'Mã phiếu',
      dataIndex: 'maPhieu',
      key: 'maPhieu',
      ...configSearchTableAnt(setSearchValues, searchValues, 'maPhieu', reverseData, 120, false, {
        render: (text, record) => (
          <p
            className="cursor-pointer text-blue-500"
            onClick={() => showDetailProduct(record.idNhapXuat)}
          >
            {text}
          </p>
        ),
      }),
    },
    {
      title: 'Tên phiếu',
      dataIndex: 'tenPhieu',
      key: 'tenPhieu',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenPhieu', reverseData, 120),
    },
    {
      title: 'Người nhập',
      dataIndex: 'tenNVNhan',
      key: 'tenNVNhan',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenNVNhan', reverseData, 130),
    },
    {
      title: 'Ngày nhập',
      dataIndex: 'ngayNhan',
      key: 'ngayNhan',
      ...configSearchTableAnt(setSearchValues, searchValues, 'ngayNhan', reverseData, 120, true, {
        render: (text) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
      }),
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenChiNhanhNhan',
      key: 'tenChiNhanhNhan',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenChiNhanhNhan', reverseData, 120),
    },
    {
      title: 'Kho nhập',
      dataIndex: 'tenKhoNhap',
      key: 'tenKhoNhap',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenKhoNhap', reverseData, 120),
    },
    {
      title: 'Số hoá đơn',
      dataIndex: 'soHoaDon',
      key: 'soHoaDon',
      ...configSearchTableAnt(setSearchValues, searchValues, 'soHoaDon', reverseData, 140),
    },
    {
      title: 'Ngày hoá đơn',
      dataIndex: 'ngayHoaDon',
      key: 'ngayHoaDon',
      ...configSearchTableAnt(setSearchValues, searchValues, 'ngayHoaDon', reverseData, 120, true, {
        render: (text) => (text ? moment(text).format('DD/MM/YYYY') : ''),
      }),
    },
    {
      title: 'Nhà cung cấp',
      dataIndex: 'tenDoiTac',
      key: 'tenDoiTac',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenDoiTac', reverseData, 120),
    },
    {
      title: 'Tổng thực trả',
      key: 'TongTien',
      dataIndex: 'sumThucTra',
      fixed: 'right',
      width: 100,
      align: 'right',
      render: (text) => formatNumberVND(Math.round(text)),
    },
    {
      title: 'Xác nhận kho',
      dataIndex: 'status',
      key: 'status',
      fixed: 'right',
      align: 'center',
      width: 120,
      filters: [
        {
          id: 1,
          value: 3,
          text: 'Đã nhập',
        },
        {
          id: 2,
          value: 5,
          text: 'Nhập kho',
        },
      ],
      onFilter: (value, record) => record.trangThai === value,
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 85,
    },
  ]
}

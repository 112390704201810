import { Button, ConfigProvider, DatePicker, Input, Select, Table, notification } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import CachedIcon from '@mui/icons-material/Cached'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { useDispatch, useSelector } from 'react-redux'
import { branchService } from '../../../../../services/branch/branchService'
import { chuyenKhoService } from '../../../../../services/ChuyenKho/chuyenKhoService'
import { kiemKeService } from '../../../../../services/KiemKe/kiemkeService'
import _ from 'lodash'
import { configSearchTableAnt } from '../../../../../utils/configSearchTableAntd'
import moment from 'moment'
import { updateProductKiemKe } from '../../../../../store/actions/kiemKeAction'
import exportExcelKiemKe from '../../../../../utils/export/exportKiemKe'
const Products = () => {
  const [api, contextHolder] = notification.useNotification()
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { kyHang } = useSelector((state) => state.thuocVTReducer)
  const [page, setPage] = useState(1)
  const [initialState, setInitialState] = useState({ branch: [], wareHouse: [] })
  const [loading, setLoading] = useState(false)
  const [searchValues, setSearchValues] = useState({})
  const [dataSource, setDataSource] = useState([])
  const [valueExport, setValueExport] = useState([])
  const dispatch = useDispatch()
  const [value, setValue] = useState({
    idKho: null,
    idKy: null,
    idCompany: id_company,
    idBranch: BranchLogin,
  })
  const openNotificationWithIcon = () => {
    api.warning({
      message: 'lấy dữ liệu kỳ hàng',
      description: 'Vui lòng cho chọn kỳ hàng.',
    })
  }
  const calculateSLChan = (ton, quyCachDongGoi, dvt, dvChan) => {
    const soLuongChan = Math.floor(ton / quyCachDongGoi) // Calculate full packages
    const soLuongLe = ton - soLuongChan * quyCachDongGoi // Calculate remaining loose units
    let displaySoLuong

    // Format the quantity display
    if (soLuongChan > 0 && soLuongLe > 0) {
      displaySoLuong = `${soLuongChan} ${dvChan || ''} ${soLuongLe} ${dvt || ''}`
    } else if (soLuongChan > 0) {
      displaySoLuong = `${soLuongChan} ${dvChan || ''}`
    } else {
      displaySoLuong = `${soLuongLe} ${dvt || ''}`
    }
    return displaySoLuong
  }
  //call API get Data
  const fetchDataSource = async () => {
    const { idKho, idKy, idCompany, idBranch } = value
    if (!idKy) {
      openNotificationWithIcon() // check kỳ hàng có hay chưa
      return
    }
    setLoading(true)
    try {
      const { data } = await kiemKeService.getTonKiemKe(idKy, idCompany, idBranch, idKho)
      const newData = data?.data.map((item) => ({
        ...item,
        ...item.thuocVatTu,
        tonkhothucte: item.tonkhothucte || 0,
        slChan: calculateSLChan(
          item.tonKho,
          item.thuocVatTu.quyCachDongGoi,
          item.thuocVatTu.dvt,
          item.thuocVatTu.donViChan,
        ),
      }))
      setValueExport(newData)
      setDataSource(newData)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  //Call API get branch by company
  const fetchBranchByCompany = async (id) => {
    try {
      const { data } = await branchService.getListBranch(id)
      setInitialState((prevSate) => ({ ...prevSate, branch: data }))
    } catch (error) {
      console.log(error)
    }
  }
  // call API get wareHouse by branch
  const fetchWareHouseByBranch = async (id) => {
    try {
      if (!value?.idBranch) return
      const { data } = await chuyenKhoService.getlistKhoNhan(id)
      setInitialState((prevSate) => ({ ...prevSate, wareHouse: data }))
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchBranchByCompany(value?.idCompany)
  }, [value?.idCompany])
  useEffect(() => {
    fetchWareHouseByBranch(value?.idBranch)
  }, [value?.idBranch])
  const filteredData = (
    data, // lọc giá trị search
  ) =>
    data?.filter((item) =>
      Object.entries(searchValues).every(([key, value]) =>
        item[key]?.toLowerCase().includes(value.toLowerCase()),
      ),
    )
  const compare = (a, b, dataIndex) => {
    // sort dữ liệu
    if (a[dataIndex] < b[dataIndex]) {
      return -1
    }
    if (a[dataIndex] > b[dataIndex]) {
      return 1
    }
    return 0
  }
  // lấy danh sách value filters
  const getUniqueFilters = (data, dataIndex) => {
    const uniqueValues = Array.from(new Set(data?.map((item) => item[dataIndex]))) // Lấy các giá trị không trùng
    return uniqueValues?.map((value) => ({
      text: value,
      value: value,
    }))
  }
  const handleChangeInput = (e, key, name) => {
    const { value } = e.target
    setDataSource((prevState) => {
      const updateData = prevState.map((item) =>
        item.idtkkk === key ? { ...item, [name]: value ? parseFloat(value) : '' } : item,
      )
      return updateData
    })
  } 
  const handleChangeNote = (e, key, name) => {
    const { value } = e.target
      setDataSource((prevState) => {
        const updateData = prevState.map((item) =>
          item.idtkkk === key ? { ...item, [name]: value } : item,
        )
        return updateData
      })
  } 
  
  const save = async (key) => {
    const now = moment().format('YYYY-MM-DDTHH:mm:ss')
    const data = dataSource?.filter((item) => item.idtkkk === key)[0]
    dispatch(
      updateProductKiemKe({
        idtkkk:data.idtkkk,
        ghichu:data.ghichu,
        tonkhothucte:data.tonkhothucte,
        ngaySua: now,
        nguoiSua: infoUser?.idnv,
      }),
    )
  }
  const handleSearchChange = (column, value, setSearchValues) => {
    setSearchValues((prevValues) => ({
      ...prevValues,
      [column]: value,
    }))
  }
  const handleTableChange = (pagination,filters, sorter, extra) => {
    setPage(pagination.current)
    setValueExport(extra.currentDataSource)
  }
  const exportExcel = () => {    
   exportExcelKiemKe(filteredData(valueExport))
  }
  return (
    <>
      {contextHolder}
      <div className="flex justify-between mb-5">
        <div className="flex gap-5 items-center">
          <div className="w-64">
            <Select
              onChange={(value) => {
                setValue((prevState) => ({ ...prevState, idCompany: value, idBranch: null }))
              }}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              value={value?.idCompany}
              className="w-full"
            />
          </div>
          <div className="w-64">
            <Select
              onChange={(value) => {
                setValue((prevState) => ({ ...prevState, idBranch: value, idKho: null }))
              }}
              value={value?.idBranch}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { label: 'Tất cả', value: null },
                ...(initialState.branch ?? []).map(({ idChiNhanh, tenChiNhanh }) => ({
                  label: tenChiNhanh,
                  value: idChiNhanh,
                })),
              ]}
              className="w-full"
            />
          </div>
          <Select
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={value?.idKho}
            showSearch
            onChange={(value) => setValue((prevSate) => ({ ...prevSate, idKho: value }))}
            options={[
              { label: 'Tất cả', value: null },
              ...(initialState.wareHouse ?? []).map(({ idKhoCN, tenKho }) => ({
                label: tenKho,
                value: idKhoCN,
              })),
            ]}
            className="w-52"
          />
          <Select
            status={!value?.idKy ? 'error' : ''}
            onChange={(value) => setValue((prevSate) => ({ ...prevSate, idKy: value }))}
            value={value?.idKy}
            className="w-72"
            options={kyHang.map((item) => ({
              label: item.tenky,
              value: item.idky,
            }))}
          />{' '}
          <div
            onClick={fetchDataSource}
            className="cursor-pointer rounded-sm text-blue-500 hover:bg-slate-200"
          >
            <CachedIcon sx={{ fontSize: 25 }} />
          </div>
          <div className="font-semibold ">
            <Button
              onClick={exportExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#EFEFEF',
              footerBg: '#EFEFEF',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          onChange={handleTableChange}
          rowKey="key"
          loading={loading}
          pagination={{
            pageSize: 100,
            showSizeChanger: false,
          }}
          dataSource={filteredData(dataSource)}
          bordered
          scroll={{
            x: filteredData(dataSource)?.length !== 0 ? 'max-content' : 3000,
            y: '58vh',
          }}
          columns={[
            {
              title: 'STT',
              dataIndex: 'stt',
              key: 0,
              render: (text, record, index) => {
                return (page - 1) * 100 + index + 1
              },
              width: 50,
              align: 'center',
              fixed: 'left',
            },
            {
              title: 'Mã hàng',
              dataIndex: 'maThuoc',
              key: 1,
              align: 'center',
              fixed: 'left',
              ...configSearchTableAnt(
                setSearchValues,
                searchValues,
                'maThuoc',
                dataSource,
                120,
                false,
                { fixed: 'left' },
              ),
            },
            {
              title: 'Tên hàng',
              key: 2,
              dataIndex: 'tenHoatChat',
              ...configSearchTableAnt(
                setSearchValues,
                searchValues,
                'tenHoatChat',
                dataSource,
                120,
                false,
                { fixed: 'left', width: 400 },
              ),
            },
            {
              title: 'Số lượng lẻ',
              key: 3,
              dataIndex: 'tonKho',
              ...configSearchTableAnt(
                setSearchValues,
                searchValues,
                'tonKho',
                dataSource,
                130,
                false,
                { fixed: 'left' },
              ),
            },
            {
              title: 'Đơn vị lẻ',
              key: 4,
              dataIndex: 'dvt',
              ...configSearchTableAnt(setSearchValues, searchValues, 'dvt', dataSource, 120),
            },
            {
              title: 'Số lượng chẳn',
              key: 5,
              dataIndex: 'slChan',
              ...configSearchTableAnt(setSearchValues, searchValues, 'slChan', dataSource, 140),
            },
            {
              title: 'Số lô',
              key: 6,
              dataIndex: 'soLo',
              ...configSearchTableAnt(setSearchValues, searchValues, 'soLo', dataSource, 100),
            },
            {
              title: 'Hạn dùng',
              key: 7,
              dataIndex: 'hanDung',
              sorter: (a, b) => compare(a, b, 'hanDung'),
              showSorterTooltip: false,
              onFilter: (value, record) => record.hanDung === value,
              filters: getUniqueFilters(dataSource, 'hanDung'),
              filterSearch: true,
              children: [
                {
                  title: (
                    <DatePicker
                      //   value={searchValues[dataIndex] !== '' ? dayjs(searchValues[dataIndex], 'YYYY-MM-DD') : ''}
                      format={'DD/MM/YYYY'}
                      size="small"
                      onChange={(date, DateString) => {
                        const formatValue = DateString
                          ? moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD')
                          : ''
                        handleSearchChange('hanDung', formatValue, setSearchValues)
                      }}
                    />
                  ),
                  onCell: () => ({
                    style: {
                      minWidth: 120,
                    },
                  }),
                  render: (text) => (text ? moment(text).format('DD/MM/YYYY') : ''),
                  dataIndex: 'hanDung',
                  key: 'hanDung',
                },
              ],
            },

            {
              title: 'Số HĐ',
              key: 8,
              dataIndex: 'soHoaDon',
              ...configSearchTableAnt(setSearchValues, searchValues, 'soHoaDon', dataSource, 120),
            },
            {
              title: 'Số đăng ký',
              key: 9,
              dataIndex: 'maSoDangKy',
              ...configSearchTableAnt(setSearchValues, searchValues, 'maSoDangKy', dataSource, 120),
            },
            {
              title: 'Chi nhánh',
              key: 10,
              dataIndex: 'tenCN',
              ...configSearchTableAnt(setSearchValues, searchValues, 'tenCN', dataSource, 140),
            },
            {
              title: 'Kho hàng',
              key: 11,
              dataIndex: 'tenKhoCn',
              ...configSearchTableAnt(setSearchValues, searchValues, 'tenKhoCn', dataSource, 140),
            },
            {
              title: 'SL Thực tế',
              key: 12,
              dataIndex: 'tonkhothucte',
              sorter: (a, b) => compare(a, b, 'tonkhothucte'),
              showSorterTooltip: false,
              onFilter: (value, record) => record.tonkhothucte === value,
              filters: getUniqueFilters(dataSource, 'tonkhothucte'),
              filterSearch: true,
              children: [
                {
                  title: (
                    <Input
                      value={searchValues?.tonkhothucte}
                      size="small"
                      onChange={(e) =>
                        handleSearchChange('tonkhothucte', e.target.value, setSearchValues)
                      }
                    />
                  ),
                  onCell: () => ({
                    style: {
                      minWidth: 100,
                    },
                  }),
                  render: (_, record) => (
                    <Input
                      type="Number"
                      min={0}
                      style={{ width: 110 }}
                      value={record?.tonkhothucte}
                      size="small"
                      onChange={(e) => handleChangeInput(e, record.idtkkk, 'tonkhothucte')}
                      onPressEnter={(e) => save(record.idtkkk)}
                      onBlur={(e) => save(record.idtkkk)}
                    />
                  ),
                  dataIndex: 'tonkhothucte',
                  key: 'tonkhothucte',
                },
              ],
            },
            {
              title: 'Ghi chú',
              key: 13,
              dataIndex: 'ghichu',
              sorter: (a, b) => compare(a, b, 'ghichu'),
              showSorterTooltip: false,
              onFilter: (value, record) => record.ghichu === value,
              filters: getUniqueFilters(dataSource, 'ghichu'),
              filterSearch: true,
              children: [
                {
                  title: (
                    <Input
                      value={searchValues?.ghichu}
                      size="small"
                      onChange={(e) =>
                        handleSearchChange('ghichu', e.target.value, setSearchValues)
                      }
                    />
                  ),
                  onCell: () => ({
                    style: {
                      minWidth: 200,
                    },
                  }),
                  render: (_, record) => (
                    <Input
                      style={{ width: 200 }}
                      value={record.ghichu}
                      size="small"
                      onChange={(e) => handleChangeNote(e, record.idtkkk, 'ghichu')}
                      onPressEnter={(e) => save(record.idtkkk)}
                      onBlur={(e) => save(record.idtkkk)}
                    />
                  ),
                  dataIndex: 'ghichu',
                  key: 'ghichu',
                },
              ],
            },
            {
              title: 'Người sửa',
              key: 14,
              width: 100,
              dataIndex:'tenNguoiSua',
              ...configSearchTableAnt(setSearchValues, searchValues, 'tenNguoiSua', dataSource, 140),
            },
            {
              title: 'Ngày sửa',
              key: 14,
              width: 100,
              dataIndex:'ngaySua',
              ...configSearchTableAnt(setSearchValues, searchValues, 'ngaySua', dataSource, 140,true),
            },
            {
              title: 'Chênh lệch',
              key: 14,
              width: 100,
              fixed: 'right',
              render: (_, record) => {
                const tonTT = record?.tonkhothucte || record?.tonKho
                const count = tonTT - record?.tonKho
                return (
                  <p className={`font-semibold ${count >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                    {count > 0 ? '+' : ''}
                    {count}
                  </p>
                )
              },
            },
          ]}
        />
      </ConfigProvider>
    </>
  )
}

export default Products

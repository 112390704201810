import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConfigProvider, DatePicker, Table, TreeSelect, Select, Button } from 'antd'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import { columns } from './Columns'
import { reportService } from '../../../../../services/Report/reportService'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { getBranchNotVP } from '../../../../../store/actions/BranchAction'
import { phanQuyenService } from '../../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../../store/actions/phanQuyenAction'
import { exportExcelformat } from '../../../../../utils/export/exportExcelFormat'
import { formatNumberVND } from '../../../../../utils/formatNumberVND'
import * as typeAction from '../../../../../store/constants/constants'
import ToastCus from '../../../../../utils/Toast'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const ServicesGroups = () => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { isLoadingTable } = useSelector((state) => state.modalReducer)
  const [company, setCompany] = useState(id_company)
  const [date, setDate] = useState({ form: now, to: now })
  const [branch, setBranch] = useState([])
  const [value, setValue] = useState([BranchLogin])
  const [data, setData] = useState([])
  const fetchReport = async () => {
    try {
      openLoading()
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT512',
      )
      let idChiNhanhs = null
      if (value?.length > 0 && value[0] !== 'all') {
        idChiNhanhs = value.map((id) => `&idChiNhanhs=${id}`).join('&')
        const { data } = await reportService.getServiceNhomDichVu(
          company,
          date.form,
          date.to,
          idChiNhanhs,
        )
        setData(data)
      } else {
        const { data } = await reportService.getServiceNhomDichVu(company, date.form, date.to, '')
        setData(data)
        setValue(['all'])
      }
      closeLoading()
    } catch (error) {
      closeLoading()
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      closeLoading()
    }
  }
  const openLoading = () => {
    dispatch({
      type: typeAction.OPEN_LOADING_TABLE,
    })
  }
  const closeLoading = () => {
    dispatch({
      type: typeAction.CLOSE_LOADING_TABLE,
    })
  }
  const handleDatePicker = (name) => (date, dateString) => {
    const formatDate = moment(dateString, dateFormat).format(momentDate)
    setDate((prevSate) => ({ ...prevSate, [name]: formatDate }))
  }
  const onChangeCompany = (value) => {
    setCompany(value)
    setValue(['all'])
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT513',
      )

      const formatData = data?.map((item) => ({
        Ngày: moment(item.ngay).format('DD/MM/YYYY'),
        'Chi nhánh': item.tenChiNhanh,
        'Nhóm dịch vụ': item.tenNhomDV,
        'Đơn vị tính': item.dvt,
        'Số lượng': item.soLuong,
      }))
      const name = 'Báo cáo nhóm dịch vụ'
      formatData?.length && exportExcelformat(formatData, name)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  useEffect(() => {
    dispatch(getBranchNotVP(company, setBranch))
  }, [company])

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchReport()
  }, [])
  const valieDateSelect = infoUser?.idNhom === 3
  return (
    <>
      <div className="flex gap-3 border-dotted border-b-0  p-2 ">
        <Select
          showSearch
          value={company}
          onChange={onChangeCompany}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={listCompany?.map(({ idct, tenct }) => ({
            label: tenct,
            value: idct,
          }))}
          className={`w-80 ${valieDateSelect ? 'hidden' : 'block'}`}
        />
        <div className={`w-80 ${valieDateSelect ? 'hidden' : 'block'}`}>
          <TreeSelect
            {...tProps}
            style={{ flexWrap: 'unset' }}
            className="w-full h-8 overflow-clip"
          />
        </div>
        <div className="flex items-center gap-3">
          <DatePicker
            allowClear={false}
            value={dayjs(date.form)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-lg">-</span>
          <DatePicker
            allowClear={false}
            value={dayjs(date.to)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>

        <div className="flex gap-3">
          <IconButton onClick={fetchReport} color="primary" aria-label="delete" size="small">
            <SyncIcon />
          </IconButton>
        </div>
        <div className="flex gap-3 ml-auto">
          <Button
            disabled={!data?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
            borderRadius: 0,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          loading={isLoadingTable}
          bordered
          scroll={{
            x: 1000,
            y: 660,
          }}
          dataSource={data}
          columns={columns}
          pagination={false} // Disable pagination
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Tổng cộng:</Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="center">
                  {formatNumberVND(data.reduce((a, b) => a + b.soLuong, 0))}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </ConfigProvider>
    </>
  )
}

export default ServicesGroups

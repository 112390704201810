import { ExclamationCircleOutlined, FormOutlined } from '@ant-design/icons'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import {
  ConfigProvider,
  DatePicker,
  Select,
  Table,
  Input,
  TreeSelect,
  Button,
  Tag,
  message,
  Modal,
} from 'antd'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { SearchOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPhieuThuBanLe } from '../../../store/actions/banLeAction'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { khoVTservice } from '../../../services/khoVT/khoVTservice'
import { branchService } from '../../../services/branch/branchService'
import { formattedNumber } from '../../../utils/formattedNumber'
import { exportExcelformatSumCol } from '../../../utils/export/exportExcelFormat'
import DrawerThuocBanLe from './DrawerThuocBanLe/DrawerThuocBanLe'
import { phanQuyenService } from '../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../store/actions/phanQuyenAction'
import ToastCus from '../../../utils/Toast'

import 'moment/locale/vi'
import { https } from '../../../services/apiService'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

const PhieuBanLe = () => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { listBranch } = useSelector((state) => state.branchReducer)
  const { listPT } = useSelector((state) => state.banLeReducer)
  const { PkDangNhap } = useSelector((state) => state.userReducer)
  const [company, setCompany] = useState(id_company)
  const [listChuyenKhoa, setListChuyenKhoa] = useState([])
  const [chuyenKhoa, setChuyenKhoa] = useState('all')
  const [branch, setBranch] = useState([])
  const [value, setValue] = useState([BranchLogin])
  const [page, setPage] = useState(1)
  const [open, setOpen] = useState(false)
  const [idChiNhanh, setIdChiNhanh] = useState(Number(BranchLogin))
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [chiTiet, setChiTiet] = useState(null)
  const [keyword, setKeyword] = useState('')

  const [idCaLamViec, setIdCaLamViec] = useState('')
  const dispatch = useDispatch()

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  const tPropsCLV = {
    treeData: [
      {
        title: 'Tất cả',
        value: '',
        children: [
          { title: 'Ca A', value: 1 },
          { title: 'Ca B', value: 2 },
          { title: 'Ca C', value: 3 },
        ],
      },
    ],
    value: idCaLamViec,
    onChange: (value) => {
      if (value.includes('')) {
        setIdCaLamViec([1, 2, 3])
      } else {
        setIdCaLamViec(value)
      }
    },
    treeCheckable: true,
    showCheckedStrategy: TreeSelect.SHOW_PARENT,
    placeholder: 'Chọn ca làm việc...',
    className: 'w-full h-8 overflow-clip',
  }

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const filter = {
    idcn: (value?.includes('all') || !value?.length ? branch : value)
      ?.map((item) => `idcn=${value?.includes('all') || !value?.length ? item?.idChiNhanh : item}`)
      ?.join('&')
      ?.toString(),
    tuNgay: dateForm,
    denNgay: dateTo,
    idCa: idCaLamViec?.length ? `&idCa=${idCaLamViec?.join('&idCa=')}` : '',
    keyword: keyword,
  }

  const onReload = () => {
    setChiTiet(null)
    if (!value?.length) setValue(['all'])
    dispatch(fetchPhieuThuBanLe(filter))
  }
  const getListChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setListChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBranch = async (idct, idck) => {
    try {
      setIsLoading(true)
      const { data } = await branchService.getBranchNotVP(idct)
      if (!idck || idck === 'all') setBranch(data)
      else if (idck) setBranch(data?.filter((item) => item?.idChuyenKhoa === idck))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  // useEffect(() => {
  //   dispatch(listAllCompanyAction())
  //   dispatch(listBranchAction(id_company))
  // }, [])
  useEffect(() => {
    dispatch(listAllCompanyAction())
    getListChuyenKhoa()
    fetchBranch(id_company, chuyenKhoa)
  }, [])
  useEffect(() => {
    if (branch?.length) dispatch(fetchPhieuThuBanLe(filter))
  }, [branch])
  const exportExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT263',
      )

      const formatData = listPT?.map((item) => ({
        'STT ': item?.stt,
        'Mã phiếu Thu': item?.maphieuthu,
        'Chi nhánh': item?.tenchinhanh,
        'Ngày bán': item?.ngayban ? moment(item?.ngayban).format('DD/MM/YYYY') : '-',
        'Ca trực': item?.calamviec,
        'Tên bệnh nhân': item?.tenbenhnhan,
        'Mã bệnh nhân': item?.mabenhnhan,
        'Ngày sinh': item?.ngaysinh ? moment(item?.ngaysinh).format('DD/MM/YYYY') : '-',
        // 'Bác sĩ kê toa': item.bskedon,
        'Dược sĩ bán': item?.duocsiban,
        'Tiền mặt': formattedNumber(item?.tienmat ?? 0),
        'Chuyển khoản': formattedNumber(item?.chuyenkhoan ?? 0),
        'Máy Pos': formattedNumber(item?.pos ?? 0),
        'Momo ': formattedNumber(item?.momo ?? 0),
        'VNPay ': formattedNumber(item?.vnpay ?? 0),
        'ZaloPay ': formattedNumber(item?.zalopay ?? 0),
        'Thẻ thanh toán': formattedNumber(item?.thett ?? 0),
        'Mã voucher': item?.mavoucher,
        'Tổng tiền': formattedNumber(item?.tongtien ?? 0),
        'Tiền giảm': formattedNumber(item?.tiengiamgia ?? 0),
        'Tiền thanh toán': formattedNumber(item?.tienthanhtoan ?? 0),
        'Ghi chú': item?.ghichu,
      }))
      const name = `Phiếu thu bán lẻ - ${moment().format('DD-MM-YYYY')}` // format date L = '26.08.2024'
      formatData?.length &&
        exportExcelformatSumCol(formatData, name, [
          'Tiền mặt',
          'Chuyển khoản',
          'Máy Pos',
          'Momo ',
          'VNPay ',
          'ZaloPay ',
          'Thẻ thanh toán',
          'Tổng tiền',
          'Tiền giảm',
          'Tiền thanh toán',
        ])
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 45,
      align: 'center',
      key: 'stt',
      fixed: 'left',
      // render: (text, record, index) => ++index,
      render: (text, _, index) => ++index,
    },
    {
      title: 'Mã phiếu thu',
      dataIndex: 'maphieuthu',
      width: 150,
      key: 'maphieuthu',
      fixed: 'left',
      align: 'left',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.maphieuthu ?? '').localeCompare(b?.maphieuthu ?? '')
        },
      },
      render: (text, record) => (
        <span
          className="text-blue-500 cursor-pointer"
          onClick={() => {
            setChiTiet(record)
            setOpen(true)
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      // width: 250,
      fixed: 'left',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tenchinhanh ?? '').localeCompare(b?.tenchinhanh ?? '')
        },
      },
    },
    {
      title: 'Ngày bán',
      dataIndex: 'ngayban',
      width: 140,
      align: 'center',
      key: 'ngayban',
      render: (text) => (text ? moment(text)?.format('DD/MM/YYYY HH:mm:ss') : '-'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.ngayban ?? '').localeCompare(b?.ngayban ?? '')
        },
      },
    },
    {
      title: 'Ca trực',
      dataIndex: 'calamviec',
      width: 140,
      align: 'center',
      key: 'calamviec',
      showSorterTooltip: false,
      // filters: [
      //   { text: 'Ca A', value: 'Ca A' },
      //   { text: 'Ca B', value: 'Ca B' },
      //   { text: 'Ca C', value: 'Ca C' },
      // ],
      onFilter: (value, record) => record.calamviec === value,
      render: (text, record) => {
        let color = 'blue'
        if (text === 'Ca A') {
          color = 'green'
        } else if (text === 'Ca B') {
          color = 'red'
        } else if (text === 'Ca C') {
          color = 'orange'
        }
        return (
          <Tag color={color} key={record.key}>
            {text}
          </Tag>
        )
      },
      sorter: {
        compare: (a, b) => {
          return (a?.calamviec ?? '').localeCompare(b?.calamviec ?? '')
        },
      },
    },
    {
      title: 'Tên BN',
      dataIndex: 'tenbenhnhan',
      key: 'tenbenhnhan',
      // fixed: 'left',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tenbenhnhan ?? '').localeCompare(b?.tenbenhnhan ?? '')
        },
      },
    },
    {
      title: 'Mã BN',
      dataIndex: 'mabenhnhan',
      // width: 120,
      // align: 'center',
      key: 'mabenhnhan',
      // fixed: 'left',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.mabenhnhan ?? '').localeCompare(b?.mabenhnhan ?? '')
        },
      },
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaysinh',
      width: 100,
      align: 'center',
      key: 'ngaysinh',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.ngaysinh ?? '').localeCompare(b?.ngaysinh ?? '')
        },
      },
    },
    // {
    //   title: 'Bác sĩ kê toa',
    //   dataIndex: 'bskedon',
    //   key: 'bskedon',
    //   width: 220,
    //   sorter: {
    //     compare: (a, b) => { return (a?.bskedon ?? '').localeCompare(b?.bskedon ?? '') },
    //   },
    // },
    {
      title: 'Dược sĩ bán',
      dataIndex: 'duocsiban',
      // width: 220,
      key: 'duocsiban',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.duocsiban ?? '').localeCompare(b?.duocsiban ?? '')
        },
      },
    },
    {
      title: 'Tiền mặt',
      dataIndex: 'tienmat',
      key: 'tienmat',
      align: 'right',
      width: 100,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.tienmat ?? 0) - (b.tienmat ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.tienMat ?? '').localeCompare(b?.tienMat ?? '')
      //   },
      // },
    },
    {
      title: 'Chuyển khoản',
      dataIndex: 'chuyenkhoan',
      key: 'chuyenkhoan',
      width: 120,
      align: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.chuyenkhoan ?? 0) - (b.chuyenkhoan ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.chuyenKhoan ?? '').localeCompare(b?.chuyenKhoan ?? '')
      //   },
      // },
    },
    {
      title: 'Máy Pos',
      dataIndex: 'pos',
      key: 'pos',
      width: 100,
      align: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.pos ?? 0) - (b.pos ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.pos ?? '').localeCompare(b?.pos ?? '')
      //   },
      // },
    },
    {
      title: 'Momo',
      dataIndex: 'momo',
      key: 'momo',
      align: 'right',
      width: 100,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.momo ?? 0) - (b.momo ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.moMo ?? '').localeCompare(b?.moMo ?? '')
      //   },
      // },
    },
    {
      title: 'VNPay',
      dataIndex: 'vnpay',
      key: 'vnpay',
      align: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.vnpay ?? 0) - (b.vnpay ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      width: 100,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.vnPay ?? '').localeCompare(b?.vnPay ?? '')
      //   },
      // },
    },
    {
      title: 'ZaloPay',
      dataIndex: 'zalopay',
      key: 'zalopay',
      align: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.zalopay ?? 0) - (b.zalopay ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      width: 100,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.zaloPay ?? '').localeCompare(b?.zaloPay ?? '')
      //   },
      // },
    },
    {
      title: 'Thẻ thanh toán',
      dataIndex: 'thett',
      key: 'thett',
      align: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.thett ?? 0) - (b.thett ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      width: 125,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.theTT ?? '').localeCompare(b?.theTT ?? '')
      //   },
      // },
    },
    {
      title: 'Mã voucher',
      dataIndex: 'mavoucher',
      key: 'mavoucher',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.mavoucher ?? '').localeCompare(b?.mavoucher ?? '')
        },
      },
      width: 105,
    },
    {
      title: <p className="text-center">Tổng tiền</p>,
      dataIndex: 'tongtien',
      key: 'tongtien',
      align: 'right',
      width: 100,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.tongtien ?? 0) - (b.tongtien ?? 0)
        },
      },
      render: (text) => (text ? formattedNumber(text) : 0),
      //onFilter: (value, record) => record.tongTien === Number(value),
      // sorter: (a, b) => a.tongTien - b.tongTien,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: <p className="text-center">Tiền giảm</p>,
      dataIndex: 'tiengiamgia',
      key: 'tiengiamgia',
      width: 100,
      align: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.tiengiamgia ?? 0) - (b.tiengiamgia ?? 0)
        },
      },
      render: (text) => (text ? formattedNumber(text) : 0),
    },
    {
      title: <p className="text-center">Thanh toán</p>,
      dataIndex: 'tienthanhtoan',
      key: 'tienthanhtoan',
      width: 100,
      align: 'right',
      fixed: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.tienthanhtoan ?? 0) - (b.tienthanhtoan ?? 0)
        },
      },
      render: (text) => (text ? formattedNumber(text) : 0),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: listPT?.every((item) => !item?.ghichu) ? 80 : '',
      // align: 'right',
      // fixed: 'right',
      // render: (text) => (text ? formattedNumber(text) : 0),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.ghichu ?? '').localeCompare(b?.ghichu ?? '')
        },
      },
    },
    {
      title: '',
      dataIndex: 'action',
      width: 45,
      align: 'center',
      fixed: 'right',
      render: (text, record, index) => (
        <ul className="flex justify-center gap-2">
          <li
            onClick={() => handleOpenChiTiet(record)}
            className="text-green-700 bg-green-200 cursor-pointer w-6 hover:text-white hover:bg-green-700 duration-200 rounded"
          >
            <FormOutlined style={{ fontSize: 16, fontWeight: 700 }} />
          </li>
          {/* {(infoUser?.idNhom === 27 || infoUser?.idNhom === 28) && (
            <li
              onClick={() => handleDelete(record.idbnttc)}
              className="text-red-700 bg-red-200 cursor-pointer w-6 hover:text-white hover:bg-red-700 duration-200 rounded"
            >
              <DeleteOutlined style={{ fontSize: 16, fontWeight: 700 }} />
            </li>
          )} */}
        </ul>
      ),
    },
  ]
  const handleOpenChiTiet = async (record) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT264',
      )
      setChiTiet(record)
      setOpen(true)
    } catch (error) {
      setChiTiet(null)
      setOpen(false)
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  const handleDelete = (id) => {
    Modal.confirm({
      title: 'Xác nhận xóa',
      icon: <ExclamationCircleOutlined />,
      content:
        'Bạn có chắc chắn muốn xóa bản ghi này không? Chức năng này chỉ có IT mới có quyền chỉnh sửa.',
      okText: 'Xóa',
      okType: 'danger',
      cancelText: 'Hủy',
      onOk: () => {
        https
          .delete(`/BenhNhanPhieuThu/DeletePhieuLeTan?idbnttc=${id}&idnv=${infoUser?.idnv}`)
          .then((response) => {
            if (response.status === 200) {
              message.success('Xóa thành công')

              onReload()
            } else {
              message.error('Xóa thất bại')
            }
          })
          .catch((error) => {
            message.error('Có lỗi xảy ra')
            console.error('Error:', error)
          })
      },
    })
  }
  return (
    <>
      <div className="flex gap-5 justify-between border-dotted border-b-0  p-2 ">
        <div className="flex gap-2">
          <div className="flex items-center gap-1">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          {/* <div className="w-60 flex gap-1 items-center">
            <Select
              onChange={(value) => setIdChiNhanh(value)}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              defaultValue={idChiNhanh}
              options={listBranch?.map(({ idChiNhanh, tenChiNhanh }) => ({
                label: tenChiNhanh,
                value: idChiNhanh,
              }))}
              className="w-full"
            />
          </div> */}
          <div className="w-[17rem]">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranch(value, chuyenKhoa)
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-[17rem]">
            <Select
              showSearch
              value={chuyenKhoa}
              onChange={(value) => {
                setChuyenKhoa(value)
                fetchBranch(company, value)
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                {
                  label: 'Tất cả chuyên khoa',
                  value: 'all',
                },
                ...listChuyenKhoa?.map((item) => ({
                  label: item?.tenChuyenKhoa,
                  value: item?.idChuyenKhoa,
                })),
              ]}
              className="w-full"
            />
          </div>
          <div className="w-[17rem] flex gap-2">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="w-60 flex gap-1 items-center">
            <Input
              placeholder="Nhập tên bệnh nhân..."
              prefix={<SearchOutlined className="pr-1" />}
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
              value={keyword}
              className="w-full"
            />
          </div>
          <div className="w-60 flex items-center">
            <label className="font-semibold mr-2 w-36" htmlFor="">
              Ca làm việc:
            </label>
            <TreeSelect {...tPropsCLV} />
          </div>
          <div className="flex gap-2 items-center">
            <IconButton onClick={onReload} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={exportExcel}
              disabled={listPT?.length === 0}
              className="text-green-700 font-semibold"
              type="text"
            >
              <ImportExportIcon /> Excel
            </Button>
          </div>
        </div>
      </div>
      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              x: listPT?.length !== 0 ? 'max-content' : 2200,
              y: 'calc(100vh - 300px)',
            }}
            // pagination={{
            //   pageSize: 20,
            //   showSizeChanger: false,
            //   // current: page,
            // }}
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={listPT}
            columns={columns}
            summary={(pageData) => {
              let sumtienmat = 0
              let sumchuyenkhoan = 0
              let summaypos = 0
              let summomo = 0
              let sumvnpay = 0
              let sumzalopay = 0
              let sumthethanhtoan = 0
              let sumtiengiam = 0
              let sumthanhtoan = 0
              let sumtongtien = 0
              pageData.forEach(
                ({
                  tienmat,
                  chuyenkhoan,
                  pos,
                  momo,
                  zalopay,
                  vnpay,
                  thett,
                  tongtien,
                  tiengiamgia,
                  tienthanhtoan,
                }) => {
                  sumtienmat += tienmat
                  sumchuyenkhoan += chuyenkhoan
                  summaypos += pos
                  summomo += momo
                  sumvnpay += vnpay
                  sumzalopay += zalopay
                  sumthethanhtoan += thett
                  sumtongtien += tongtien
                  sumtiengiam += tiengiamgia
                  sumthanhtoan += tienthanhtoan
                },
              )
              // console.log;
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    {columns?.map((item, index) => (
                      <>
                        <Table.Summary.Cell index={index}>
                          {item?.key === 'tienmat' && (
                            <p className="text-end">{formattedNumber(sumtienmat)}</p>
                          )}
                          {item?.key === 'chuyenkhoan' && (
                            <p className="text-end">{formattedNumber(sumchuyenkhoan)}</p>
                          )}
                          {item?.key === 'pos' && (
                            <p className="text-end">{formattedNumber(summaypos)}</p>
                          )}
                          {item?.key === 'momo' && (
                            <p className="text-end">{formattedNumber(summomo)}</p>
                          )}
                          {item?.key === 'vnpay' && (
                            <p className="text-end">{formattedNumber(sumvnpay)}</p>
                          )}
                          {item?.key === 'zalopay' && (
                            <p className="text-end">{formattedNumber(sumzalopay ?? 0)}</p>
                          )}
                          {item?.key === 'thett' && (
                            <p className="text-end">{formattedNumber(sumthethanhtoan)}</p>
                          )}
                          {item?.key === 'tongtien' && (
                            <p className="text-end">{formattedNumber(sumtongtien)}</p>
                          )}
                          {item?.key === 'tiengiamgia' && (
                            <p className="text-end">{formattedNumber(sumtiengiam)}</p>
                          )}
                          {item?.key === 'tienthanhtoan' && (
                            <p className="text-end">{formattedNumber(sumthanhtoan)}</p>
                          )}
                        </Table.Summary.Cell>
                      </>
                    ))}
                    {/* <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>
                      <p className="font-semibold text-right">{formattedNumber(sumtienmat)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8}>
                      <p className="font-semibold text-right">{formattedNumber(sumchuyenkhoan)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9}>
                      <p className="font-semibold text-right">{formattedNumber(summaypos)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10}>
                      <p className="font-semibold text-right">{formattedNumber(summomo)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11}>
                      <p className="font-semibold text-right">{formattedNumber(sumvnpay)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12}>
                      <p className="font-semibold text-right">{formattedNumber(sumzalopay)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13}>
                      <p className="font-semibold text-right">{formattedNumber(sumthethanhtoan)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14}></Table.Summary.Cell>
                    <Table.Summary.Cell index={15}>
                      <p className="font-semibold text-right">{formattedNumber(sumtongtien)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={16}>
                      <p className="font-semibold text-right">{formattedNumber(sumtiengiam)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={17}>
                      <p className="font-semibold text-right">{formattedNumber(sumthanhtoan)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={18}></Table.Summary.Cell>
                    <Table.Summary.Cell index={19}></Table.Summary.Cell> */}
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
      <DrawerThuocBanLe open={open} setOpen={setOpen} chiTiet={chiTiet} />
    </>
  )
}

export default PhieuBanLe

import { call, put, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { versionService } from '../../services/version/versionService'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
// Sagas
export function* versionSaga() {
  //-------------- ADD NEW VERSION ------------//
  yield takeLatest(typeAction.ADD_NEW_VERSION, function* postversion({ type, form, onReset }) {
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      yield call(() => versionService.postVersion(form))
      yield onReset()
      Toast.fire({
        icon: 'success',
        title: 'Thêm phiên bản thành công!',
      })
    } catch (err) {
      console.log(err)
      Toast.fire({
        icon: 'error',
        title: 'Thêm phiên bản thất bại!',
      })
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })
  //---------------- DELETE VERSION--------------//
  yield takeLatest(typeAction.DELETE_VERSION_BY_ID, function* deleteVersion({ type, id, onLoad }) {
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      yield call(() => versionService.deleteVersion(id))
      yield onLoad()
      Toast.fire({
        icon: 'success',
        title: 'Xoá phiên bản thành công!',
      })
    } catch (err) {
      console.log(err)
      Toast.fire({
        icon: 'error',
        title: 'Xoá phiên bản thất bại!',
      })
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })
  //---------------- UPDATE VERSION--------------//
  yield takeLatest(typeAction.UPDATE_VERSION, function* updateVersion({ type, form, reload }) {
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      yield call(() => versionService.updateVersion(form))
      yield reload(form.idpb)
      Toast.fire({
        icon: 'success',
        title: 'Cập nhâp phiên bản thành công!',
      })
    } catch (err) {
      console.log(err)
      Toast.fire({
        icon: 'error',
        title: 'cập nhâp bản thất bại!',
      })
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })
}

import * as typeAction from '../constants/constants'

export const getAllLoaiHangHoa = (keyword) => ({
  type: typeAction.GET_THUOC_VAT_TU_PHAN_LOAI,
  keyword,
})

export const createLoaiHangHoa = (form, onReset) => ({
  type: typeAction.ADD_NEW_THUOC_VAT_TU_PHAN_LOAI,
  form,
  onReset,
})

export const getInfoThuocVatTu = (id) => ({
  type: typeAction.GET_INFO_THUOC_VAT_TU,
  id,
})

export const updateLoaiHangHoa = (form) => ({
  type: typeAction.UPDATE_THUOC_VAT_TU_PHAN_LOAI,
  form,
})

export const deleteLoaiHangHoa = (id, handleCancel) => ({
  type: typeAction.DELETE_THUOC_VAT_TU_PHAN_LOAI,
  id,
  handleCancel,
})

export const getNhomHangHoaByLoai = (id, keyword) => ({
  type: typeAction.GET_NHOM_HANG_HOA_BY_LOAI,
  id,
  keyword,
})

export const createNhomHangHoa = (form, onReset) => ({
  type: typeAction.POST_NEW_NHOM_HANG_HOA,
  form,
  onReset,
})

export const getAllLoaiNhomHangHoa = (setNhan) => ({
  type: typeAction.GET_ALL_LOAI_NHOM_HANG_HOA,
  setNhan,
})

export const updateNhomHangHoa = (form, handleCancel, fetchDanhMucNhan) => ({
  type: typeAction.UPDATE_NHOM_HANG_HOA,
  form,
  handleCancel,
  fetchDanhMucNhan,
})

export const deleteNhomHangHoa = (id, fetchDanhMucNhan) => ({
  type: typeAction.DELETE_NHOM_HANG_HOA,
  id,
  fetchDanhMucNhan,
})

export const updateThucPhamChucNang = (active, listIdNhom, fetchDanhMucNhan) => ({
  type: typeAction.UPDATE_TPCH_NHOM_HANG_HOA,
  active,
  listIdNhom,
  fetchDanhMucNhan,
})

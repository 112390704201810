import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { phanQuyenService } from '../../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../../utils/Toast'
import { https } from '../../../../../services/apiService'
import { nhanvienService } from '../../../../../services/nhanvien/nhanvienService'
import { Modal, Form, Row, Col, Input, Button, message, Checkbox } from 'antd'
import moment from 'moment'
import '../../../../../AesEstyles.css'
import 'moment/locale/vi'
moment.locale('vi')

const SeeEraStaff = ({
  togglePanel,
  cancelSeeModal,
  view,
  professional,
  branch,
  isErase,
  setIsErase,
  cancelEraseModal,
  deleteIdnv,
  setDeleteIdnv,
  onEraseSuccess,
}) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const [grant, setGrant] = useState([]) // Lưu trữ danh sách bằng cấp.
  const [companyName, setCompanyName] = useState('') // Lưu trữ danh sách tên cty
  const [companyID, setCompanyID] = useState('') // Lưu trữ ID cty
  const [provinceName, setProvinceName] = useState([]) // Lưu trữ danh sách tỉnh.
  const [districtName, setDistrictName] = useState([]) // Lưu trữ danh sách quận.
  const [wardName, setWardName] = useState([]) // Lưu trữ danh sách phường.

  // Hàm nhân viên {idnv}
  const fetchEmployeeByID = async (id) => {
    try {
      const result = await nhanvienService.getNhanVienByID(id)
      const employeeData = result.data
      if (Array.isArray(employeeData) && employeeData.length > 0) {
        const employee = employeeData[0]
        setCompanyName(employee.tencongty)
        setCompanyID(employee.idct)
      } else {
        console.error('Dữ liệu nhân viên không ở định dạng mong đợi:', employeeData)
      }
    } catch (error) {
      console.error('Lỗi tìm nhân viên theo ID:', error)
    }
  }
  useEffect(() => {
    if (view && view.id) {
      fetchEmployeeByID(view.id)
    }
  }, [view])
  const fetchGrant = async () => {
    try {
      const result = await nhanvienService.getQualifications()
      setGrant(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  // call lấy danh sách bằng cấp
  useEffect(() => {
    fetchGrant()
  }, [])
  // hàm api tỉnh --> quận --> phường
  useEffect(() => {
    const fetchDataAndUpdateState = async () => {
      try {
        const provinceResult = await nhanvienService.getCity() // danh sách thành phố
        if (provinceResult && provinceResult.data) {
          setProvinceName(provinceResult.data)
        }
        if (view.idtinh) {
          const districtResult = await nhanvienService.getDistrict(view.idtinh) // danh sách quận theo thành phố
          if (districtResult && districtResult.data) {
            setDistrictName(districtResult.data)
          } else {
            console.error(
              'Không tìm thấy dữ liệu của quận hoặc định dạng phản hồi không chính xác:',
              districtResult,
            )
          }
        }
        if (view.idquan) {
          const wardResult = await nhanvienService.getWards(view.idquan) // danh sách phường theo quận
          if (wardResult && wardResult.data) {
            setWardName(wardResult.data)
          } else {
            console.error(
              'Không tìm thấy dữ liệu phường hoặc định dạng phản hồi không chính xác:',
              wardResult,
            )
          }
        }
      } catch (error) {
        console.error('Lỗi tìm nạp dữ liệu:', error)
      }
    }
    fetchDataAndUpdateState()
  }, [view])

  // Hàm xử lý xóa nhân viên
  const handleDelete = async (idnv) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT003',
      )
      const result = await https.delete(`NhanVien/DeleteNhanVien/${idnv}`)
      if (result) {
        setDeleteIdnv(null)
        onEraseSuccess()
        message.success('Xóa nhân viên thành công')
      }
    } catch (error) {
      console.error('Delete Error:', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  // Hàm xử lý khi người dùng nhấn đồng ý xóa trong modal
  const handleOk = async () => {
    // xác nhận xóa hay ko?
    await handleDelete(deleteIdnv)
    setIsErase(false)
  }

  return (
    <div>
      <Modal
        width={700}
        title={<div style={{ textAlign: 'center' }}>Thông tin nhân viên</div>}
        open={togglePanel}
        onCancel={cancelSeeModal}
        footer={[
          <Button key="cancel" onClick={cancelSeeModal}>
            Đóng
          </Button>,
        ]}
      >
        {view && (
          <Form layout="vertical">
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label="Mã nhân viên" style={{ marginBottom: '8px' }}>
                  <Input
                    value={view.manv}
                    readOnly
                    variant="filled"
                    style={{
                      cursor: 'not-allowed',
                    }}
                    onClick={(e) => e.preventDefault()} // Ngăn người dùng tương tác với trường
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Tên tắt chức danh" style={{ marginBottom: '8px' }}>
                  <Input
                    value={view.chucdanhviettat}
                    readOnly
                    variant="filled"
                    style={{
                      cursor: 'not-allowed',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label="Tên nhân viên" style={{ marginBottom: '8px' }}>
                  <Input
                    value={view.tennv}
                    readOnly
                    variant="filled"
                    style={{
                      cursor: 'not-allowed',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Số điện thoại" style={{ marginBottom: '8px' }}>
                  <Input
                    value={view.dienthoai}
                    readOnly
                    variant="filled"
                    style={{
                      cursor: 'not-allowed',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label="Ngày sinh" style={{ marginBottom: '8px' }}>
                  <Input
                    value={view.ngaysinh ? moment(view.ngaysinh).format('DD/MM/YYYY') : ''}
                    readOnly
                    variant="filled"
                    style={{
                      cursor: 'not-allowed',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Giới tính" style={{ marginBottom: '8px' }}>
                  <Input
                    value={view.gioitinh}
                    readOnly
                    variant="filled"
                    style={{
                      cursor: 'not-allowed',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label="Bằng cấp" style={{ marginBottom: '8px' }}>
                  <Input
                    value={
                      grant?.find((grantItem) => grantItem.idbangcap === view.idbangcap)?.bangcap
                    }
                    readOnly
                    variant="filled"
                    style={{
                      cursor: 'not-allowed',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Chức danh" style={{ marginBottom: '8px' }}>
                  <Input
                    value={
                      professional?.find((position) => position.idcd === view.idchucdanh)
                        ?.tenchucdanh
                    }
                    readOnly
                    variant="filled"
                    style={{
                      cursor: 'not-allowed',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Tên công ty" style={{ marginBottom: '8px' }}>
                  <Input
                    value={companyName}
                    readOnly
                    variant="filled"
                    style={{
                      cursor: 'not-allowed',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Tên chi nhánh" style={{ marginBottom: '8px' }}>
                  <Input
                    value={
                      branch?.find((branchItem) => branchItem.idChiNhanh === view.idchinhanh)
                        ?.tenChiNhanh
                    }
                    readOnly
                    variant="filled"
                    style={{
                      cursor: 'not-allowed',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Col span={24}>
              <Form.Item label="Tên máy chấm công" style={{ marginBottom: '8px' }}>
                <Input
                  value={view.tenmaychamcong}
                  readOnly
                  variant="filled"
                  style={{
                    cursor: 'not-allowed',
                  }}
                />
              </Form.Item>
            </Col>
            <Row gutter={8}>
              <Col span={8}>
                <Form.Item label="Tỉnh / Thành phố" style={{ marginBottom: '8px' }}>
                  <Input
                    value={
                      Array.isArray(provinceName) &&
                      provinceName.find((items) => items.idTinh === view.idtinh)?.tenTinh
                    }
                    readOnly
                    variant="filled"
                    style={{
                      cursor: 'not-allowed',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Quận / Huyện" style={{ marginBottom: '8px' }}>
                  <Input
                    value={districtName?.find((items) => items.idQuan === view.idquan)?.tenQuan}
                    readOnly
                    variant="filled"
                    style={{
                      cursor: 'not-allowed',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Phường / Xã" style={{ marginBottom: '8px' }}>
                  <Input
                    value={wardName?.find((items) => items.idPhuong === view.idphuong)?.tenPhuong}
                    readOnly
                    variant="filled"
                    style={{
                      cursor: 'not-allowed',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}></Row>
            <Form.Item label="Địa chỉ" style={{ marginBottom: '8px' }}>
              <Input
                value={view.diachi}
                readOnly
                variant="filled"
                style={{
                  cursor: 'not-allowed',
                }}
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: '8px', marginLeft: '0px' }}>
              <span>Đã nghỉ việc</span>
              <Checkbox style={{ marginLeft: '8px' }} checked={view.thoiviec === 1}></Checkbox>
            </Form.Item>
          </Form>
        )}
      </Modal>
      <Modal
        title="Xác nhận xóa"
        open={isErase}
        onCancel={cancelEraseModal}
        onOk={handleOk}
        okText="Đồng ý"
        cancelText="Hủy"
      >
        <p>Bạn có muốn xóa hay không?</p>
      </Modal>
    </div>
  )
}
export default SeeEraStaff

import { ConfigProvider, DatePicker, Table, TreeSelect, Select, Button } from 'antd'
import React, { useState, useEffect } from 'react'
import { columns } from './Columns'
import { useDispatch, useSelector } from 'react-redux'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import dayjs from 'dayjs'
import * as typeAction from '../../../../../store/constants/constants'
import moment from 'moment'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { getBranchByChuyenKhoa } from '../../../../../store/actions/BranchAction'
import { reportService } from '../../../../../services/Report/reportService'
import {
  exportExcelformat,
  exportExcelformatSumCol,
} from '../../../../../utils/export/exportExcelFormat'
import { formatNumberVND } from '../../../../../utils/formatNumberVND'
import 'moment/locale/vi'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const Detail = ({ ChuyenKhoa }) => {
  const dispatch = useDispatch()
  const id_company = Number(localStorage.getItem('id_company'))
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { isLoadingTable } = useSelector((state) => state.modalReducer)
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [company, setCompany] = useState(id_company)
  const [date, setDate] = useState({ form: now, to: now })
  const [branch, setBranch] = useState([])
  const [value, setValue] = useState(['all'])
  const [data, setData] = useState([])
  const fetchReport = async () => {
    try {
      openLoading()
      const { data } = await reportService.getBaoCaoThoiGianKhamChiTietPhieu(
        (value?.includes('all') || !value?.length ? branch : value)
          ?.map(
            (item) =>
              `idChiNhanhs=${value?.includes('all') || !value?.length ? item?.idChiNhanh : item}`,
          )
          ?.join('&')
          ?.toString(),
        date.form,
        date.to,
      )
      setData(data)
      closeLoading()
    } catch (error) {
      console.log(error)
      closeLoading()
    } finally {
      closeLoading()
    }
  }

  const openLoading = () => {
    dispatch({
      type: typeAction.OPEN_LOADING_TABLE,
    })
  }
  const closeLoading = () => {
    dispatch({
      type: typeAction.CLOSE_LOADING_TABLE,
    })
  }
  const handleDatePicker = (name) => (date, dateString) => {
    const formatDate = moment(dateString, dateFormat).format(momentDate)
    setDate((prevSate) => ({ ...prevSate, [name]: formatDate }))
  }
  const onChangeCompany = (value) => {
    setCompany(value)
    setValue(['all'])
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = () => {
    const formatData = data?.map((item, index) => ({
      'STT ': ++index,
      'Ngày ': item?.ngaythanhtoan ? moment(item?.ngaythanhtoan).format('DD/MM/YYYY') : '-',
      'Chi nhánh': item?.tenchinhanh,
      'Mã phiếu thu': item?.maphieuthu,
      'Mã bệnh nhân': item?.mabenhnhan,
      'Tên bệnh nhân': item?.tenbenhnhan,
      'Địa chỉ': item?.diachi,
      'Tiếp nhận lúc': item?.ngaykham ? moment(item?.ngaykham).format('DD/MM/YYYY HH:mm:ss') : '-',
      'Bắt đầu khám': item?.giovaopk ? moment(item?.giovaopk).format('DD/MM/YYYY HH:mm:ss') : '-',
      'Kết thúc khám': item?.giorapk ? moment(item?.giorapk).format('DD/MM/YYYY HH:mm:ss') : '-',
      'Thời gian khám': item?.thoigiankham !== '::' ? item?.thoigiankham : '-',
      'Phát thuốc lúc': item?.gioketthuc
        ? moment(item?.gioketthuc).format('DD/MM/YYYY HH:mm:ss')
        : '-',
      'Bác sĩ khám': item?.bskham,
    }))
    const name = 'Báo cáo thời gian khám chi tiết phiếu'
    exportExcelformatSumCol(formatData, name)
  }
  useEffect(() => {
    dispatch(getBranchByChuyenKhoa(company, idChuyenKhoa, setBranch))
  }, [company])

  const onChangeChuyenKhoa = (value) => {
    dispatch(getBranchByChuyenKhoa(company, value, setBranch))
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    // fetchReport()
    onChangeChuyenKhoa(idChuyenKhoa)
  }, [])
  useEffect(() => {
    fetchReport()
  }, [branch])
  // validate
  const valieDateSelect = infoUser?.idNhom === 3
  return (
    <>
      <div className="flex gap-3 border-dotted border-b-0  p-2 ">
        <Select
          showSearch
          value={company}
          onChange={onChangeCompany}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={listCompany?.map(({ idct, tenct }) => ({
            label: tenct,
            value: idct,
          }))}
          className={`w-80 ${valieDateSelect ? 'hidden' : 'block'}`}
        />
        <Select
          showSearch
          value={idChuyenKhoa}
          onChange={onChangeChuyenKhoa}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={[
            { label: 'Tất cả chuyên khoa', value: '' },
            ...ChuyenKhoa.map((items) => ({
              label: items.tenChuyenKhoa,
              value: items.idChuyenKhoa,
            })),
          ]}
          className="w-80"
        />
        <div className={`w-80 ${valieDateSelect ? 'hidden' : 'block'}`}>
          <TreeSelect
            {...tProps}
            style={{ flexWrap: 'unset' }}
            className="w-full h-8 overflow-clip"
          />
        </div>
        <div className="flex items-center gap-3">
          <DatePicker
            allowClear={false}
            value={dayjs(date.form)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-lg">-</span>
          <DatePicker
            allowClear={false}
            value={dayjs(date.to)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>

        <div className="flex gap-3">
          <IconButton color="primary" onClick={fetchReport} aria-label="delete" size="small">
            <SyncIcon />
          </IconButton>
        </div>
        <div className="flex gap-3 ml-auto">
          <Button
            disabled={!data?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
            borderRadius: 0,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          loading={isLoadingTable}
          bordered
          scroll={{
            x: 1500,
            y: 660,
          }}
          dataSource={data}
          columns={columns}
          pagination={false} // Disable pagination
          // summary={() => (
          //   <Table.Summary fixed>
          //     <Table.Summary.Row>
          //       {/* Tổng thanh toán */}
          //       <Table.Summary.Cell index={0}>Tổng cộng:</Table.Summary.Cell>
          //       <Table.Summary.Cell index={1} align="center"></Table.Summary.Cell>
          //       <Table.Summary.Cell index={2} align="center"></Table.Summary.Cell>
          //       <Table.Summary.Cell index={3} align="center"></Table.Summary.Cell>
          //       <Table.Summary.Cell index={4} align="center"></Table.Summary.Cell>
          //       <Table.Summary.Cell index={5} align="center"></Table.Summary.Cell>
          //       <Table.Summary.Cell index={6} align="center"></Table.Summary.Cell>
          //       <Table.Summary.Cell index={7} align="center"></Table.Summary.Cell>
          //       <Table.Summary.Cell index={8} align="center"></Table.Summary.Cell>
          //       <Table.Summary.Cell index={9} align="center"></Table.Summary.Cell>
          //       <Table.Summary.Cell index={10} align="center"></Table.Summary.Cell>
          //       <Table.Summary.Cell index={11}>
          //         {formatNumberVND(data.reduce((total, item) => total + item.thanhTien, 0))}
          //       </Table.Summary.Cell>
          //     </Table.Summary.Row>
          //   </Table.Summary>
          // )}
        />
      </ConfigProvider>
    </>
  )
}

export default Detail

import React, { useEffect, useRef, useState } from 'react'
import * as typeAction from '../../../store/constants/constants'
import AttachmentIcon from '@mui/icons-material/Attachment'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { Input, Tabs, Select, Table, ConfigProvider, notification } from 'antd'
import { CloseSquareOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button } from '@mui/material'
import Receive from './Satus/Receive/Receive'
import Pending from './Satus/Pending/Pending'
import Create from './Satus/Create/Create'
import Transfer from './Satus/Transfer/Transfer'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import {
  branchChuyenKhoByCompany,
  getInfoKhoDefault,
  getListKhoNhanAction,
  getListTonkhoCn_action,
  getProductsChuyenKhoByMaHang,
  postPhieuCKAction,
} from '../../../store/actions/chuyenKhoAction'
import moment from 'moment'
import { chuyenKhoSchema } from '../../../schemas/chuyenKhoSchema'
import ModalDetail from './Modal/ModalDetail'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import * as pdfjsLib from 'pdfjs-dist/build/pdf'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import DetaiKho from './DetailKho/DetaiKho'
import ModalAdd from './Modal/ModalAdd'
import SoPhieu from './SoPhieu/SoPhieu'
import 'moment/locale/vi'
import ListKho from '../NhapKho/ListKho/ListKho'
moment.locale('vi')
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker
const columns = [
  {
    key: 1,
    title: 'Thông tin hàng hoá',
    children: [
      {
        title: 'STT',
        dataIndex: 'STT',
        key: 1.4,
        width: 50,
        align: 'center',
        fixed: 'left',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Mã hàng',
        dataIndex: 'MaHangHoa',
        key: 1.2,
        width: 120,
        fixed: 'left',
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Tên hàng',
        dataIndex: 'tenHangHoa',
        key: 1.1,
        width: 250,
        fixed: 'left',
        // sorter: (a, b) => a.age - b.age,
      },

      // {
      //   title: "Số lô",
      //   dataIndex: "SOLO",
      //   key: 1.3,
      //   width: 120,
      //   // sorter: (a, b) => a.age - b.age,
      // },
    ],
  },
  {
    key: 2,
    title: 'Đơn vị chẵn',
    children: [
      {
        title: 'SL',
        dataIndex: 'SLCHAN',
        key: 3.2,
        width: 90,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Đơn vị',
        dataIndex: 'DVCHAN',
        key: 2.2,
        width: 80,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Tồn',
        dataIndex: 'tkChan',
        key: 2.3,
        width: 60,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
    ],
  },
  {
    key: 3,
    title: 'Đơn vị lẻ',
    children: [
      {
        title: 'Quy cách',
        dataIndex: 'QUYCACH',
        key: 3.1,
        width: 150,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'SL',
        dataIndex: 'SLLE',
        key: 3.2,
        width: 90,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Đơn vị',
        dataIndex: 'DVLE',
        key: 3.3,
        width: 80,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Tồn',
        dataIndex: 'tonKho',
        key: 3.4,
        width: 60,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
    ],
  },

  {
    key: 4,
    title: 'Số lô',
    dataIndex: 'soLo',
    width: 120,
    align: 'center',
  },
  {
    key: 5,
    title: 'Hạn dùng',
    dataIndex: 'hanDung',
    width: 120,
    align: 'center',
  },
  {
    key: 7,
    title: 'MSĐK',
    dataIndex: 'maSoDangKy',
    width: 150,
    align: 'center',
  },
  {
    key: 8,
    title: 'Số HĐ',
    dataIndex: 'soHoaDon',
    width: 120,
    align: 'center',
  },
  {
    key: 9,
    title: 'Đối tác',
    dataIndex: 'tenDoiTac',
    width: 500,
  },
  {
    key: 10,
    title: 'Ghi chú',
    dataIndex: 'ghiChu',
    width: 150,
  },
  {
    key: 6,
    title: '',
    dataIndex: 'action',
    width: 40,
    align: 'center',
    fixed: 'right',
  },
]
const ChuyenKho = () => {
  const dispatch = useDispatch()
  const id_company = Number(localStorage.getItem('id_company'))
  const now = moment()
  const fileInputRef = useRef(null)
  const ROLE_KHO_VIEWS = [41, 107, 108, 68, 61, 69, 225, 125, 136, 147, 255, 187, 197] // chỉ xem được các loại kho này
  const ID_NHOM_LE_TAN = 3
  const ID_NHOM_DUOC = 4
  const { infoUser } = useSelector((state) => state.userReducer)
  //########### STATE ###############
  const [open, setOpen] = useState(false)
  const [statusUpdate, setStatusUpdate] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const [branchNhan, setBranchNhan] = useState(null)
  const [date, setDate] = useState(now.format('YYYY-MM-DDTHH:mm:ss'))
  const [selectedFile, setSelectedFile] = useState([])
  const [openAdd, setOpenAdd] = useState(false)
  const [maHang, setMaHang] = useState(null)
  //############## STORE ############
  const { branch, defaultKhoNhap } = useSelector((state) => state.NhapKhoReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { KhoNhan, KhoVT, TonKho, branchByCompany } = useSelector((state) => state.chuyenKhoReducer)
  // mở modal detail Phiếu
  const showModal = () => {
    setOpen(true)
  }
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    })
  }
  // cancel modal Phiếu
  const cancelModal = () => {
    setOpen(false)
  }

  // xử lý lưu  phiếu chuyển kho
  const handleSave = (values, action) => {
    setDate(now.format('YYYY-MM-DDTHH:mm:ss'))
    formik.setFieldValue('NgayXuat', date)
    const data = KhoVT.map((items) => ({
      ...items,
      soLuongLe: items.soLuongLe + items.soLuong * items.thuocVatTu.quyCachDongGoi,
      soLuong: items.soLuong + items.soLuongLe / items.thuocVatTu.quyCachDongGoi,
    }))
    if (data.some((items) => items.soLuongLe === 0)) {
      openNotificationWithIcon('error', 'Lưu phiếu chuyển', 'Số lượng thuốc không được trống!')
      return
    }
    if (selectedFile.length !== 0) {
      const data = selectedFile.map((item) => item.file)
      values.HoaDonFiles = data
    }
    const formData = new FormData()

    for (let key in values) {
      if (key === 'HoaDonFiles') {
        if (values[key] && values[key].length > 0) {
          values[key].forEach((file, index) => {
            formData.append(key, file)
          })
        }
      } else {
        formData.append(key, values[key])
      }
    }
    dispatch(postPhieuCKAction(formData, data))
    handleResetForm()
  }
  const handleResetForm = () => {
    setSelectedFile([])
    formik.handleReset()
    dispatch({
      type: typeAction.RESET_KHOVT_CK,
    })
  }
  // xử lý thông tin chọn
  const handleChangeSelect = (name) => (value) => {
    if (formik.values.IdKhoXuat === value) {
      // cảnh báo chuyển trùng kho
      openNotificationWithIcon('error', 'Chọn kho nhập', 'không thể chọn trùng kho nhập!')
      return
    }
    formik.setFieldValue(name, value)
  }
  // xử lí lấy thuốc vật tư tồn tại kho chi nhánh
  const handleKhoChiNhanh = (idKhoCn) => {
    dispatch(getListTonkhoCn_action(idKhoCn))
    formik.setFieldValue('IdKhoXuat', idKhoCn)
    // nếu trùng với kho nhập thì reset kho nhập
    formik.values.IdKhoNhap === idKhoCn && formik.setFieldValue('IdKhoNhap', null)
  }
  // XỬ LÝ CHỌN CHI NHÁNH NHẬN KHO TRONG CHUYỂN KHO
  const handleBranchNhanKho = (id) => {
    formik.setFieldValue('IdKhoNhap', null)
    setBranchNhan(id)
    dispatch(getListKhoNhanAction(id))
  }
  //XỬ LÍ LẤY THUỐC KHO VT
  const handleKhoVT = (value) => {
    for (let items of KhoVT) {
      if (value === items.ID) {
        openNotificationWithIcon('error', 'Thêm thuốc vật tư', 'thuốc vật tư đã được chọn!')
        return
      }
    }
    dispatch({
      type: typeAction.PUSH_THUOC_VT_CHUYEN_KHO,
      payload: value,
    })
  }
  const onClickProductsByMa = () => {
    if (formik.values.IdKhoXuat === null) {
      openNotificationWithIcon('warning', 'Copy phiếu chuyển kho', 'Vui lòng chọn kho xuất !')
      return
    }
    dispatch(getProductsChuyenKhoByMaHang(maHang, TonKho, setMaHang))
  }
  // XỬ LÝ TĂNG SỐ LƯỢNG THUỐC
  const handleChangeSL = (items) => (e) => {
    const value = Number(e.target.value)
    if (value + items.soLuong * items.thuocVatTu.quyCachDongGoi > items.tonKho) {
      openNotificationWithIcon('error', 'Thay đổi số lượng', 'Tồn kho không đủ số lượng')
      return
    }
    dispatch({
      type: typeAction.CHANGE_SL_THUOC_CK,
      payload: {
        ID: items.ID,
        value: value < 0 || value === '' ? 0 : value, // nếu giá trị < 0 or = '' ==> value = 0
      },
    })
  }
  // ----------tăng số lượng chẵn ------------
  const handleSLChan = (items) => (e) => {
    const value = Number(e.target.value)
    if (value * items.thuocVatTu.quyCachDongGoi + items.soLuongLe > items.tonKho) {
      openNotificationWithIcon('error', 'Thay đổi số lượng', 'Tồn kho không đủ số lượng')
      return
    }
    dispatch({
      type: typeAction.DISPATCH_SL_CHAN_CHUYEN_KHO,
      payload: {
        ID: items.ID,
        value: value < 0 || value === '' ? 0 : value, // nếu giá trị < 0 or = '' ==> value = 0
      },
    })
  }
  const onChangeNote = (items) => (e) => {
    const { value } = e.target
    dispatch({
      type: typeAction.DISPATCH_NOTE_CHUYEN_KHO,
      payload: {
        ID: items.ID,
        value: value, // nếu giá trị < 0 or = '' ==> value = 0
      },
    })
  }
  // XỬ LÝ XOÁ 1 THUỐC TRONG KHO
  const handleDeleteKhoVTById = (ID) => {
    dispatch({
      type: typeAction.CLOSE_THUOC_CK_BY_ID,
      payload: ID,
    })
  }
  const handleButtonClick = () => {
    fileInputRef.current.click()
  }
  //--------------- CHOOSE FILE -----------//
  const handleFileChange = async (event) => {
    let file = event.target.files[0]
    console.log(file)
    // List of allowed file types
    const allowedFileTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'application/pdf',
    ]
    if (!allowedFileTypes.includes(file.type)) {
      openNotificationWithIcon(
        'error',
        'File không được hỗ trợ',
        'Vui lòng chọn file PDF hoặc PNG/jpeg',
      )
      fileInputRef.current.value = '' // clear the input
      return
    }
    if (file.type === 'application/pdf') {
      const pdfImgSrc = await renderPdfAsImage(file)
      fileInputRef.current.value = '' // clear the input
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        const localUrl = URL.createObjectURL(file)
        setSelectedFile((preState) => [
          ...preState,
          { base64: pdfImgSrc, URL: localUrl, name: file.name, id: uuidv4(), file },
        ])
      }
    } else {
      console.log('vo day')
      // formik.setFieldValue('HoaDonFile', file)
      // console.log(formData);
      fileInputRef.current.value = ''
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        const localUrl = URL.createObjectURL(file)
        setSelectedFile((preState) => [
          ...preState,
          { base64: e.target.result, URL: localUrl, name: file.name, id: uuidv4(), file },
        ])
      }
    }
  }
  //------------- RENDER PDF----------//
  const renderPdfAsImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = async (e) => {
        const pdf = await pdfjsLib.getDocument({ data: e.target.result }).promise
        const page = await pdf.getPage(1)
        const viewport = page.getViewport({ scale: 1.5 })
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        canvas.height = viewport.height
        canvas.width = viewport.width

        await page.render({ canvasContext: context, viewport: viewport }).promise
        resolve(canvas.toDataURL())
      }
    })
  }
  //-----------delete file--------------//
  const deleteFileById = (id) => {
    const updatedData = selectedFile.filter((item) => item.id !== id)
    setSelectedFile(updatedData)
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      TenPhieu: '',
      IdKhoXuat: null,
      IdKhoNhap: null,
      NhanVienXuat: infoUser?.idnv,
      NgayXuat: date,
      GhiChu: '',
      // daNhan: 0,
      TrangThai: 1,
      IDCT: id_company,
    },
    onSubmit: (value, action) => {
      handleSave(value, action)
    },
    validationSchema: chuyenKhoSchema,
  })
  const isValidMaHang =
    (maHang && maHang?.startsWith('CK') && maHang?.length === 14) ||
    (maHang?.startsWith('VPP') && formik.values.IdKhoXuat !== null)
  //------------ lấy lên tên công ty tương ứng -------------//
  const selectedStaff = listCompany?.find((item) => item.idct === id_company)
  useEffect(() => {
    handleResetForm()
    dispatch(branchChuyenKhoByCompany(id_company))
    dispatch(listAllCompanyAction())
    dispatch(getInfoKhoDefault()) // lấy thông tin mặc của của chi nhánh nhánh tên và list kho chi nhánh
  }, [])

  const viewsRoleKho = (data) => {
    // Nếu là lễ tân chỉ lấy kho: VPP, Tài sản, Vận hành
    let newData = []
    if (infoUser?.idNhom !== ID_NHOM_LE_TAN && infoUser?.idNhom !== ID_NHOM_DUOC) {
      newData = data
    } else if (infoUser?.idNhom === ID_NHOM_DUOC) {
      newData = data?.filter((item) => [0, 2].includes(item.thuockhotong))
    } else {
      newData = data?.filter((item) => ROLE_KHO_VIEWS.includes(item.idKho))
    }
    return newData
  }
  return (
    <>
      {contextHolder}
      <div className="p-3 bg-[#E7E7E7] h-full">
        <div className="p-2 h-full bg-white rounded-xl">
          <Tabs
            type="card"
            items={[
              {
                label: 'Chuyển kho',
                key: 1,
                children: (
                  <>
                    <div className="flex  gap-5">
                      <div className=" p-2 rounded-md w-3/4 border">
                        <div className="flex gap-5">
                          <div className="flex flex-col gap-4 w-2/5">
                            <div className="flex">
                              <label className="font-semibold w-1/4">Người chuyển:</label>
                              <Input readOnly value={infoUser?.tenNV} size="small" />
                            </div>
                            <div className="flex">
                              <label className="w-1/4 font-medium text-end"> Công ty:</label>
                              <Input value={selectedStaff?.tenct} readOnly size="small" />
                            </div>
                          </div>
                          <div className="flex flex-col gap-4 w-3/5">
                            <div className="w-full flex gap-2">
                              <div className="flex w-1/2">
                                <label className="font-semibold w-1/3">Nơi chuyển:</label>
                                <Input readOnly value={branch} size="small" />
                              </div>
                              <div className="flex w-1/2">
                                <label className="font-semibold w-1/3">
                                  Kho xuất:
                                  <span className="text-red-500">(*)</span>
                                </label>
                                <Select
                                  name="IdKhoXuat"
                                  status={formik.errors.IdKhoXuat ? 'error' : ''}
                                  onChange={handleKhoChiNhanh}
                                  className="w-full"
                                  size="small"
                                  value={formik.values.IdKhoXuat}
                                  options={viewsRoleKho(defaultKhoNhap)?.map(
                                    ({ idKhoCN, tenKho }) => ({
                                      label: tenKho,
                                      value: idKhoCN,
                                    }),
                                  )}
                                />
                              </div>
                            </div>
                            <div className="w-full flex gap-2">
                              <div className="flex w-1/2 items-center">
                                <label className="font-semibold w-1/3 text-center">Nơi nhận:</label>
                                <Select
                                  value={branchNhan}
                                  onChange={handleBranchNhanKho}
                                  allowClear={false}
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '')
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  className="w-full"
                                  options={branchByCompany?.map((items) => ({
                                    label: items.tenChiNhanh,
                                    value: items.idChiNhanh,
                                  }))}
                                  size="small"
                                />
                              </div>
                              <div className="flex w-1/2">
                                <label className="font-semibold w-1/3">
                                  Kho nhận:
                                  <span className="text-red-500">(*)</span>
                                </label>
                                <Select
                                  name="IdKhoNhap"
                                  status={formik.errors.IdKhoNhap ? 'error' : ''}
                                  onChange={handleChangeSelect('IdKhoNhap')}
                                  className="w-full"
                                  size="small"
                                  value={formik.values.IdKhoNhap}
                                  options={viewsRoleKho(KhoNhan)?.map(({ idKhoCN, tenKho }) => ({
                                    label: tenKho,
                                    value: idKhoCN,
                                  }))}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex my-4 gap-2">
                          <div className="flex items-center w-4/5">
                            <labe className="font-semibold w-24 text-end">Tìm kiếm: </labe>
                            <Select
                              className="w-full"
                              showSearch
                              filterOption={(input, option) => {
                                const label = option?.label ?? ''
                                const soHoaDon = option?.soHoaDon ?? ''
                                const soLo = option?.soLo ?? ''
                                const maHang = option.maThuoc ?? ''
                                const searchText = input.toLowerCase()
                                return (
                                  label.toLowerCase().includes(searchText) ||
                                  soHoaDon.toLowerCase().includes(searchText) ||
                                  soLo.toLowerCase().includes(searchText) ||
                                  maHang.toLowerCase().includes(searchText)
                                )
                              }}
                              value={null}
                              placeholder="Nhập tên hàng hoá, số hoá đơn, số lố, mã hàng"
                              onChange={handleKhoVT}
                              defaultActiveFirstOption={false}
                              // onSearch={debounceDropDown}
                              notFoundContent={null}
                              options={TonKho?.map((d) => ({
                                value: d.ID,
                                label: d.thuocVatTu?.tenBietDuoc,
                                maThuoc: d.thuocVatTu?.maThuoc,
                                hoatChat: d.thuocVatTu?.tenHoatChat,
                                tonKho: d.tonKho,
                                handung: d.hanDung,
                                soHoaDon: d.soHoaDon,
                                soLo: d.soLo,
                              }))}
                              dropdownRender={(menu) => (
                                <div>
                                  <div
                                    className="flex bg-gray-100"
                                    style={{ padding: '8px', fontWeight: 'bold' }}
                                  >
                                    <span className="w-7/12 text-center ">Hàng hoá</span>
                                    <span className="w-1/12 text-center">Số lượng</span>
                                    <span className="w-[12%] text-center">Số lô</span>
                                    <span className="w-[11%] text-center">Hạn dùng</span>
                                    <span className="w-[13%] text-center">Hoá đơn</span>
                                  </div>
                                  <div style={{ marginBottom: '8px' }}>{menu}</div>
                                </div>
                              )}
                              optionLabelProp="label"
                              optionRender={(options) => (
                                <ul className="flex items-center">
                                  {/* <li className="w-1/12">{options.data.maThuoc}</li> */}
                                  <li className="w-7/12  text-pretty ">
                                    <p className="font-semibold">
                                      {' '}
                                      {options.data.label} - {options.data.maThuoc}
                                    </p>
                                    <i className="text-gray-400 text-sm">{options.data.hoatChat}</i>
                                  </li>
                                  <li className="w-1/12  border-x">
                                    <p className="font-semibold truncate text-center text-blue-500">
                                      {options.data.tonKho}
                                    </p>
                                  </li>
                                  <li className="w-[12%] ">
                                    <p className="font-semibold truncate text-center">
                                      {options.data.soLo}
                                    </p>
                                  </li>
                                  <li className="w-[11%] px-2 border-x">
                                    <p className="font-semibold truncate text-center">
                                      {options.data.handung &&
                                        moment(options.data.handung).format('DD/MM/YYYY')}
                                    </p>
                                  </li>
                                  <li className="w-[13%] ">
                                    <p className="font-semibold truncate text-center">
                                      {options.data.soHoaDon}
                                    </p>
                                  </li>
                                  {/* <li className="w-2/12 ">
                                    <p className="font-semibold truncate">
                                      Hoá đơn: {options.data.soHoaDon}
                                    </p>
                                    <i className="text-gray-400"> Số lô: {options.data.soLo}</i>
                                  </li>
                                  <li className="w-2/12 ">
                                    <p className="font-semibold">
                                      Số lượng:{' '}
                                      <span className="text-blue-500">{options.data.tonKho}</span>
                                    </p>
                                    <i className="text-gray-400">
                                      {' '}
                                      Hạn dùng:{' '}
                                      {options.data.handung &&
                                        moment(options.data.handung).format('DD/MM/YYYY')}
                                    </i>
                                  </li> */}
                                </ul>
                              )}
                            />
                          </div>
                          <div className="flex gap-2 w-1/5">
                            <Input
                              // size='small'
                              placeholder="VD: CK0A . . ."
                              value={maHang}
                              onChange={(e) => setMaHang(e.target.value)}
                            />
                            <Button
                              onClick={onClickProductsByMa}
                              disabled={!isValidMaHang}
                              className="w-40"
                              size="small"
                              variant="contained"
                            >
                              Lấy hàng
                            </Button>
                          </div>
                        </div>

                        <div className="rounded-lg  border-1 ">
                          <ConfigProvider
                            theme={{
                              token: {
                                padding: 3,
                              },
                              components: {
                                Table: {
                                  rowHoverBg: '#ecf0f1',
                                  headerBg: '#e6e6e6',
                                  footerBg: '#e6e6e6',
                                  borderColor: '#BABABA',
                                },
                              },
                            }}
                          >
                            <Table
                              scroll={{
                                x: 1500,
                                y: 650,
                              }}
                              pagination={false}
                              bordered
                              columns={columns}
                              dataSource={KhoVT?.map((items, index) => ({
                                STT: ++index,
                                tenHangHoa: items.thuocVatTu?.tenBietDuoc,
                                MaHangHoa: items.thuocVatTu?.maThuoc,
                                tonKho:
                                  items.tonKho -
                                  (items.thuocVatTu.quyCachDongGoi * items.soLuong +
                                    items.soLuongLe),
                                soLo: items.soLo,
                                SLCHAN: (
                                  <Input
                                    size="small"
                                    onChange={handleSLChan(items)}
                                    value={items.soLuong}
                                    type="number"
                                  />
                                ),
                                tkChan: Math.floor(
                                  items.tonKho / items.thuocVatTu.quyCachDongGoi -
                                    items.soLuong -
                                    items.soLuongLe / items.thuocVatTu.quyCachDongGoi,
                                ),
                                DVCHAN: items.thuocVatTu.donViChan,
                                QUYCACH: items.thuocVatTu.quyCach,
                                tenDoiTac: items.tenDoiTac,
                                soHoaDon: items.soHoaDon,
                                maSoDangKy: items.maSoDangKy,
                                hanDung:
                                  items.hanDung && moment(items.hanDung).format('DD/MM/YYYY'),
                                SLLE: (
                                  <Input
                                    size="small"
                                    onChange={handleChangeSL(items)}
                                    value={items.soLuongLe}
                                    type="number"
                                  />
                                ),
                                DVLE: items.thuocVatTu.dvt,
                                ghiChu: (
                                  <Input
                                    size="small"
                                    onChange={onChangeNote(items)}
                                    value={items.GhiChu}
                                  />
                                ),
                                action: (
                                  <div
                                    onClick={() => {
                                      handleDeleteKhoVTById(items.ID)
                                    }}
                                  >
                                    <CloseSquareOutlined
                                      style={{ fontSize: 18 }}
                                      className="text-red-500 cursor-pointer"
                                    />
                                  </div>
                                ),
                              }))}
                            />
                          </ConfigProvider>
                        </div>
                        <div className="mb-2 flex flex-col justify-start mt-2 mr-2">
                          <div>
                            <input
                              ref={fileInputRef}
                              id="file-upload"
                              type="file"
                              onChange={handleFileChange}
                            />
                            <Button
                              onClick={handleButtonClick}
                              size="small"
                              variant="outlined"
                              startIcon={<AttachmentIcon />}
                            >
                              Đính kèm
                            </Button>
                          </div>
                          <div>
                            <ul className="flex flex-wrap gap-2 mt-2">
                              {selectedFile.map((items) => (
                                <li className="w-1/6 h-32 w-32 overflow-hidden border rounded-md relative">
                                  <DeleteOutlined
                                    onClick={() => deleteFileById(items.id)}
                                    className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                                    style={{ fontSize: 18 }}
                                  />
                                  <a href={items.URL} target="_blank">
                                    <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                                      <div className="relative "></div>
                                      <p className="absolute text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                                        {items.name}
                                      </p>
                                    </div>
                                    <img src={items.base64} className="w-full" alt={items.name} />
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className=" w-1/4 h-full">
                        <div className="border rounded-md">
                          <div className="flex  flex-col p-2 gap-4">
                            <div className="flex">
                              <label className="w-1/3 font-medium"> Ngày Chuyển:</label>
                              <Input
                                value={moment(formik.values.NgayXuat).format(
                                  'DD/MM/YYYY h:mm:ss a',
                                )}
                                size="small"
                              />
                            </div>
                            <div className="flex">
                              <label className="w-1/3 font-medium"> Trạng thái:</label>
                              <Input value="Mới chuyển" size="small" />
                            </div>
                            <div className="flex">
                              <label className="w-1/3 font-medium"> Tên phiếu:</label>
                              <Input
                                // status={formik.errors.tenPhieu ? 'error' : ''}
                                name="TenPhieu"
                                value={formik.values.TenPhieu}
                                onChange={formik.handleChange}
                                size="small"
                              />
                            </div>
                            <div className="flex">
                              <label className="w-1/3 font-medium"> Ghi chú:</label>
                              <Input.TextArea
                                value={formik.values.GhiChu}
                                name="GhiChu"
                                onChange={formik.handleChange}
                                showCount
                                maxLength={300}
                                style={{
                                  height: 60,
                                  resize: 'none',
                                }}
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="mt-6 ">
                            <ul className="flex p-2 bg-slate-100">
                              <li className="w-3/4 font-semibold border-r border-gray-300">
                                Tên hàng
                              </li>
                              <li className="w-1/4 font-semibold text-center">Số lượng</li>
                            </ul>
                            <div className=" overflow-auto h-[430px]">
                              <ul>
                                {KhoVT?.map((items) => (
                                  <li className="flex p-1 border-b">
                                    <span className="w-3/4">{items.thuocVatTu.tenBietDuoc}</span>
                                    <span className="w-1/4 text-center">
                                      {items.soLuongLe +
                                        items.soLuong * items.thuocVatTu.quyCachDongGoi}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-2 flex-col items-center mt-1">
                          <Button
                            // onClick={saveDocx}
                            className="w-full"
                            onClick={formik.handleSubmit}
                            size="small"
                            color="success"
                            variant="contained"
                            disabled={KhoVT.length === 0}
                          >
                            Lưu phiếu
                          </Button>
                          <Button
                            onClick={handleResetForm}
                            type="button"
                            className="w-full"
                            size="small"
                            color="primary"
                            variant="outlined"
                          >
                            Làm mới
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                ),
              },
              {
                label: 'Phiếu chuyển',
                key: 2,
                children: (
                  <Tabs
                    tabPosition="left"
                    defaultActiveKey="1"
                    type="card"
                    items={[
                      {
                        label: 'Phiếu tạo',
                        key: 1,
                        children: (
                          <Create
                            showModal={showModal}
                            cancelModal={cancelModal}
                            setStatusUpdate={setStatusUpdate}
                          />
                        ),
                      },
                      {
                        label: 'Phiếu đã rời',
                        key: 2,
                        children: (
                          <Transfer showModal={showModal} setStatusUpdate={setStatusUpdate} />
                        ),
                      },
                      {
                        label: 'Phiếu chờ xác nhận',
                        key: 3,
                        children: (
                          <Pending showModal={showModal} setStatusUpdate={setStatusUpdate} />
                        ),
                      },

                      {
                        label: 'Phiếu đã nhận',
                        key: 4,
                        children: (
                          <Receive showModal={showModal} setStatusUpdate={setStatusUpdate} />
                        ),
                      },
                    ]}
                  />
                ),
              },
              {
                label: 'Chi tiết hàng',
                key: 3,
                children: <DetaiKho />,
              },
              {
                label: 'Số phiếu',
                key: 4,
                children: <SoPhieu showModal={showModal} setStatusUpdate={setStatusUpdate} />,
              },
              {
                label: 'Phiếu nhập',
                key: 5,
                children: <ListKho disablePrice={true} />,
              },
            ]}
          />
        </div>
      </div>
      <ModalAdd openAdd={openAdd} showModal={showModal} setOpenAdd={setOpenAdd} />
      <ModalDetail
        open={open}
        showModal={showModal}
        cancelModal={cancelModal}
        statusUpdate={statusUpdate}
        setOpenAdd={setOpenAdd}
      />
    </>
  )
}

export default ChuyenKho

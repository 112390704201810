import {
  ContainerOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import CachedIcon from '@mui/icons-material/Cached'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import PrintIcon from '@mui/icons-material/Print'
import { Button } from '@mui/material'
import {
  Button as AntButton,
  ConfigProvider,
  DatePicker,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Tooltip,
} from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import { chuyenKhoService } from '../../../../../services/ChuyenKho/chuyenKhoService'
import { branchService } from '../../../../../services/branch/branchService'
import {
  deleteChuyenKho,
  listInfoThuocVTChyenKho_Action,
  printerAutoChuyenKho,
  updateStatusCTY_action,
} from '../../../../../store/actions/chuyenKhoAction'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { buildDateFilterCondition } from '../../../../../utils/buildDateFilterCondition'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal

const Create = ({ showModal, cancelModal, setStatusUpdate }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const BranchLogin = localStorage.getItem('BRANH_LOGIN')
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const dispatch = useDispatch()

  const [data, setData] = useState([])
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [searchKho, setSearchKho] = useState('')
  const [idBranh, setIdBranch] = useState(Number(BranchLogin))
  const [idCompany, setIdCompany] = useState(ID_COMPANY)
  const [searchName, setSearchName] = useState('')
  const [searchMa, setSearchMa] = useState('')
  const [searchNguoiXuat, setSearchNguoiXuat] = useState('')
  const [searchDate, setSearchDate] = useState('')
  const [filterDate, setFilterDate] = useState('=')
  const [branhNhan, setBranchNhan] = useState('')
  const [branchChuyen, setBranchChuyen] = useState('')
  const [ctychChuyen, setCtychChuyen] = useState('')
  const [ctyNhan, setCtyNhan] = useState('')
  const [ListBranch, setListBranch] = useState([])
  const [ListKho, setListKho] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const hasSelected = selectedRowKeys.length > 0
  const today = moment()
  const handleSearch = (name) => (e) => {
    const { value } = e.target
    switch (name) {
      case 'ma':
        setSearchMa(value || '')
        break
      case 'TENPHIEU':
        setSearchName(value || '')
        break
      case 'NGUOICHUYEN':
        setSearchNguoiXuat(value || '')
        break
      case 'NOICHUYEN':
        setBranchChuyen(value || '')
        break
      case 'CTYNHAN':
        setCtyNhan(value || '')
        break
      case 'CTYCHUYEN':
        setCtychChuyen(value || '')
        break
      default:
        break
    }
  }
  // show confirm xuất kho
  const showConfirm = (items) => {
    // items.trangThai= 3 // thay đổi trạng thái nhận kho
    // items['tenNVNhan'] = infoUser.tenNV
    confirm({
      title: 'Xác nhận chuyển kho ',
      width: 450,
      icon: <ExclamationCircleFilled />,
      content: (
        <p className="text-start">
          bạn có muốn xác nhận chuyển phiếu <span className="font-semibold">{items.maPhieu}</span>{' '}
          đến chi nhánh
          <span className="font-semibold"> {items.tenChiNhanhNhan}</span>
        </p>
      ),
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk() {
        const fd = new FormData()
        fd.append('IdNhapXuat', items.idNhapXuat)
        fd.append('TenPhieu', items.tenPhieu)
        fd.append('NgayXuat', today.format('YYYY-MM-DDTHH:mm:ss'))
        fd.append('TrangThai', 2)
        dispatch(updateStatusCTY_action(fd, items.idNhapXuat, fetchData, 'create'))
      },
      onCancel() {
        // console.log("Cancel");
      },
    })
  }
  const handleSelectedBranhNhan = (value, option) => {
    setBranchNhan(value || '')
    if (value) {
      fetchKho(option.id)
    }
    setSearchKho('')
  }
  const handleSelectedKhoNhan = (value) => {
    setSearchKho(value || '')
  }
  const handleShowModalDetail = (idNhapXuat) => {
    showModal()
    dispatch(listInfoThuocVTChyenKho_Action(idNhapXuat))
    setStatusUpdate(true) // bật trạng thái cho phép update
  }
  // xoá phiếu chuyển kho
  const handleDelete_CK = (idPhieu) => {
    dispatch(deleteChuyenKho(idPhieu, handleFilter))
  }
  const statusChuyenKho = 1 // trạng thái chuyển kho
  const filter = {
    status: statusChuyenKho, //
    idCongTy: idCompany,
  }
  const filteredData = data?.filter((items) => {
    const findKho = searchKho.toLowerCase()
    const findMa = searchMa.trim().toLowerCase()
    const nameLower = searchName.trim().toLowerCase()
    const nguoiXuatLower = searchNguoiXuat.trim().toLowerCase()
    const branchNhanLower = branhNhan.trim().toLowerCase()
    const branchChuyenLower = branchChuyen.trim().toLowerCase()
    const ctyChuyenLower = ctychChuyen.trim().toLowerCase()
    const ctyNhanLower = ctyNhan.trim().toLowerCase()
    return (
      (items?.tenKhoNhap?.toLowerCase().includes(findKho) || !items?.tenKhoNhap) &&
      (items?.maPhieu?.toLowerCase().includes(findMa) || !items?.maPhieu) &&
      (items?.tenPhieu?.toLowerCase().includes(nameLower) || !items?.tenPhieu) &&
      (items?.tenNVXuat?.toLowerCase().includes(nguoiXuatLower) || !items?.tenNVXuat) &&
      (items?.tenCTXuat?.toLowerCase().includes(ctyChuyenLower) || !items?.tenCTXuat) &&
      (items?.tenCTNhan?.toLowerCase().includes(ctyNhanLower) || !items?.tenCTNhan) &&
      buildDateFilterCondition(filterDate, searchDate, items?.ngayXuat) &&
      (items?.tenChiNhanhXuat?.toLowerCase().includes(branchChuyenLower) ||
        !items?.tenChiNhanhXuat) &&
      (items?.tenChiNhanhNhan.toLowerCase().includes(branchNhanLower) || !items?.tenChiNhanhNhan)
    )
  })
  //----------- lấy danh sách chi nhánh theo công ty -------//
  const fetchBranch = async () => {
    try {
      const { data } = await branchService.getListBranch(ID_COMPANY)
      setListBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  //---------- lấy danh sách kho chi nhánh-------------//
  const fetchKho = async (id) => {
    try {
      const { data } = await chuyenKhoService.getlistKhoNhan(id)
      setListKho(data)
    } catch (error) {
      console.log(error)
    }
  }
  ///---- in phiếu hàng loạt -------
  const autoPrin = (isA5) => {
    if (hasSelected) {
      dispatch(printerAutoChuyenKho(selectedRowKeys, setSelectedRowKeys, isA5))
    }
  }
  //------------fetch dữ liệu ------------//
  const fetchData = async () => {
    try {
      setIsLoading(true)
      const { data } = await chuyenKhoService.getChuyenKhoCTYFilter(filter)
      const sortedData = _.orderBy(data, 'ngayXuat', 'desc')
      setData(sortedData)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  // xử lí lọc dưc liệu
  const handleFilter = () => {
    fetchData()
  }
  const onSelectChange = (newSelectedRowKeys) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  // useEffect(() => {
  //   const sortedData = _.orderBy(PTCreate, 'ngayXuat', 'desc')
  //   setData(sortedData)
  // }, [PTCreate])
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch()
    fetchData()
  }, [])
  const compare = (a, b, dataIndex) => {
    if (a[dataIndex] < b[dataIndex]) {
      return -1
    }
    if (a[dataIndex] > b[dataIndex]) {
      return 1
    }
    return 0
  }

  const PhepTinh = [
    { label: '>', value: '>' },
    { label: '<', value: '<' },
    { label: '=', value: '=' },
  ]
  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
    },
    {
      title: 'Mã phiếu',
      dataIndex: 'MAPHIEU',
      key: 'MAPHIEU',
      width: 120,
      sorter: (a, b) => compare(a, b, 'MAPHIEU'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input size="small" onChange={handleSearch('ma')} value={searchMa} />
            </>
          ),
          width: 120,
          dataIndex: 'MAPHIEU',
          key: 1,
        },
      ],
    },
    {
      title: 'Tên phiếu',
      dataIndex: 'TENPHIEU',
      key: 'TENPHIEU',
      width: 200,
      sorter: (a, b) => compare(a, b, 'TENPHIEU'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input size="small" onChange={handleSearch('TENPHIEU')} value={searchName} />
            </>
          ),
          width: 200,
          dataIndex: 'TENPHIEU',
          key: 1,
        },
      ],
    },
    {
      title: 'Người chuyển',
      dataIndex: 'NGUOICHUYEN',
      key: 'NGUOICHUYEN',
      sorter: (a, b) => compare(a, b, 'NGUOICHUYEN'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input size="small" onChange={handleSearch('NGUOICHUYEN')} value={searchNguoiXuat} />
            </>
          ),
          dataIndex: 'NGUOICHUYEN',
          key: 1,
          width: 160,
        },
      ],
    },
    {
      title: 'Ngày chuyển',
      dataIndex: 'NGAYNHAN',
      key: 'NGAYNHAN',
      width: 160,
      sorter: (a, b) => compare(a, b, 'NGAYNHAN'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <div className="flex gap-1">
              <Select
                onChange={(value) => setFilterDate(value)}
                value={filterDate}
                size="small"
                className="w-16"
                options={PhepTinh}
              />
              <DatePicker
                value={searchDate !== '' ? dayjs(searchDate, 'YYYY-MM-DD') : ''}
                size="small"
                format={'DD/MM/YYYY'}
                onChange={(date, DateString) => {
                  setSearchDate(
                    DateString ? moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
                  )
                }}
              />
            </div>
          ),
          dataIndex: 'NGAYNHAN',
          key: 2,
          width: 160,
        },
      ],
    },
    {
      title: 'Công ty chuyển',
      dataIndex: 'CTYCHUYEN',
      key: 'CTYCHUYEN',
      sorter: (a, b) => compare(a, b, 'CTYCHUYEN'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input size="small" onChange={handleSearch('CTYCHUYEN')} value={ctychChuyen} />
            </>
          ),
          dataIndex: 'CTYCHUYEN',
          key: 1,
          width: 190,
        },
      ],
    },
    {
      title: 'Chi nhánh chuyển',
      dataIndex: 'NOICHUYEN',
      key: 'NOICHUYEN',
      sorter: (a, b) => compare(a, b, 'NOICHUYEN'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input size="small" onChange={handleSearch('NOICHUYEN')} value={branchChuyen} />
            </>
          ),
          dataIndex: 'NOICHUYEN',
          key: 1,
          width: 190,
        },
      ],
    },
    {
      title: 'kho Chuyển',
      dataIndex: 'KHOCHUYEN',
      key: 'KHOCHUYEN',
      sorter: (a, b) => compare(a, b, 'KHOCHUYEN'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Select
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                size="small"
                value={searchKho}
                showSearch
                onChange={handleSelectedKhoNhan}
                options={[
                  { label: 'Tất cả', value: '' },
                  ...ListKho.map((item) => ({
                    label: item.tenKho,
                    value: item.tenKho,
                    key: item.tenKho, // Thêm key cho mỗi tùy chọn
                  })),
                ]}
                className="w-full"
              />
            </>
          ),
          dataIndex: 'KHOCHUYEN',
          key: 2,
          width: 150,
        },
      ],
    },
    {
      title: 'Công ty nhận',
      dataIndex: 'CTYNHAN',
      key: 'CTYNHAN',
      sorter: (a, b) => compare(a, b, 'CTYNHAN'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input size="small" onChange={handleSearch('CTYNHAN')} value={ctyNhan} />
            </>
          ),
          dataIndex: 'CTYNHAN',
          key: 1,
          width: 190,
        },
      ],
    },
    {
      title: 'Chi nhánh nhận',
      dataIndex: 'NOINHAN',
      key: 'NOINHAN',
      sorter: (a, b) => compare(a, b, 'NOINHAN'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Select
                size="small"
                className="w-full"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                showSearch
                onChange={handleSelectedBranhNhan}
                value={branhNhan}
                options={[
                  { label: 'Tất cả', value: '' },
                  ...ListBranch?.map((item) => ({
                    label: item.tenChiNhanh,
                    value: item.tenChiNhanh,
                    id: item.idChiNhanh,
                  })),
                ]}
              />
            </>
          ),
          dataIndex: 'NOINHAN',
          key: 2,
          width: 190,
        },
      ],
    },
    {
      title: 'kho nhận',
      dataIndex: 'KHONHAN',
      key: 'KHONHAN',
      sorter: (a, b) => compare(a, b, 'KHONHAN'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Select
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                size="small"
                value={searchKho}
                showSearch
                onChange={handleSelectedKhoNhan}
                options={[
                  { label: 'Tất cả', value: '' },
                  ...ListKho.map((item) => ({
                    label: item.tenKho,
                    value: item.tenKho,
                    key: item.tenKho, // Thêm key cho mỗi tùy chọn
                  })),
                ]}
                className="w-full"
              />
            </>
          ),
          dataIndex: 'KHONHAN',
          key: 2,
          width: 150,
        },
      ],
    },
    {
      title: 'Trạng thái',
      dataIndex: 'XACNHAN',
      key: 'XACNHAN',
      width: 90,
      align: 'center',
      fixed: 'right',
    },
    {
      title: 'Hành động',
      dataIndex: 'ACTION',
      key: 'unit',
      width: 120,
      align: 'center',
      fixed: 'right',
    },
  ]

  const exportToExcel = () => {
    const headers = [
      'Mã phiếu',
      'Tên phiếu',
      'Người chuyển',
      'Ngày chuyển',
      'Công ty chuyển',
      'Chi nhánh chuyển',
      'Kho chuyển',
      'Công ty nhận',
      'Chi nhánh nhận',
      'Kho nhận',
    ]

    const formattedData = filteredData.map((item) => ({
      'Mã phiếu': item.maPhieu,
      'Tên phiếu': item.tenPhieu,
      'Người chuyển': item.tenNVXuat,
      'Ngày chuyển': moment(item.ngayXuat).format('DD/MM/YYYY HH:mm:ss'),
      'Công ty chuyển': item.tenCTXuat,
      'Chi nhánh chuyển': item.tenChiNhanhXuat,
      'Kho chuyển': item.tenKhoXuat,
      'Công ty nhận': item.tenCTNhan,
      'Chi nhánh nhận': item.tenChiNhanhNhan,
      'Kho nhận': item.tenKhoNhap,
    }))

    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, 'Phiếu chuyển kho công ty - Phiếu tạo.xlsx')
  }

  return (
    <div className="h-full">
      <div className="flex gap-5">
        <div className="w-64">
          <Select
            className="w-full"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            showSearch
            onChange={(value) => setIdCompany(value)}
            value={idCompany}
            options={listCompany?.map((item) => ({
              label: item.tenct,
              value: item.idct,
            }))}
          />
        </div>
        <div className="flex gap-3">
          <div
            className="cursor-pointer rounded-sm text-blue-500  hover:bg-slate-200"
            onClick={handleFilter}
          >
            <CachedIcon sx={{ fontSize: 25 }} />
          </div>
        </div>
        <div className="flex gap-3">
          <Popconfirm
            title="In phiếu chuyển kho"
            onConfirm={() => autoPrin(false)}
            onCancel={() => autoPrin(true)}
            okText="A4"
            cancelText="A5"
            icon={<PrintIcon color="primary" />}
            cancelButtonProps={{ type: 'primary', className: 'w-1/3 ml-0' }}
            okButtonProps={{ type: 'primary', className: 'w-1/3 ml-0' }}
            className={`${hasSelected ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            disabled={!hasSelected}
          >
            <PrintIcon
              className={`cursor-pointer rounded-sm ${hasSelected ? 'text-blue-500' : 'text-gray-500'}  hover:bg-slate-200`}
            />
          </Popconfirm>
        </div>
        <div className="flex gap-3 ml-auto">
          <AntButton
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </AntButton>
        </div>
      </div>
      <div
        className=" mt-2 "
        // style={{
        //     boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        // }}
      >
        <ConfigProvider
          theme={{
            token: {
              padding: 4,
              fontSize: 12,
              borderRadius: 0,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            rowSelection={rowSelection}
            loading={isLoading}
            bordered
            pagination={false}
            scroll={{
              x: 1400,
              y: 660,
            }}
            columns={columns}
            dataSource={filteredData?.map((items, index) => ({
              key: items.idNhapXuat,
              STT: ++index,
              MAPHIEU: items.maPhieu,
              NGUOICHUYEN: items.tenNVXuat,
              TENPHIEU: items.tenPhieu,
              NGAYNHAN: moment(items.ngayXuat).format('DD/MM/YYYY HH:mm:ss'),
              NOICHUYEN: items.tenChiNhanhXuat,
              NOINHAN: items.tenChiNhanhNhan,
              KHOCHUYEN: items.tenKhoXuat,
              KHONHAN: items.tenKhoNhap,
              CTYCHUYEN: items.tenCTXuat,
              CTYNHAN: items.tenCTNhan,
              XACNHAN: (
                <>
                  <Button
                    disabled={Number(BranchLogin) !== items?.idChiNhanhXuat}
                    onClick={() => {
                      showConfirm(items)
                    }}
                    size="small"
                    variant="contained"
                    style={{
                      fontSize: 12,
                      padding: 2,
                    }}
                  >
                    xuất kho
                  </Button>
                </>
              ),
              ACTION: (
                <div className="flex gap-4 justify-center ">
                  <Tooltip title="Xem chi tiết" color="#108ee9">
                    <ContainerOutlined
                      onClick={() => {
                        handleShowModalDetail(items.idNhapXuat)
                      }}
                      className="text-xl text-[#108ee9]  cursor-pointer"
                    />
                  </Tooltip>
                  <Tooltip title="Xoá phiếu" color="red">
                    <Popconfirm
                      placement="left"
                      title="Xoá phiếu chuyển kho"
                      description={`Bạn có chắc muốn xoá phiếu ${items.maPhieu}?`}
                      onConfirm={() => {
                        handleDelete_CK(items.idNhapXuat)
                      }}
                      disabled={items.nhanVienXuat !== infoUser?.idnv}
                      okText="Xoá"
                      okType="danger"
                      cancelText="Huỷ"
                      icon={
                        <QuestionCircleOutlined
                          style={{
                            color: 'red',
                          }}
                        />
                      }
                    >
                      <DeleteOutlined
                        className={`text-xl ${items.nhanVienXuat !== infoUser?.idnv ? 'text-gray-500' : 'text-red-500'}   cursor-pointer`}
                      />
                    </Popconfirm>
                  </Tooltip>
                </div>
              ),
            }))}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default Create

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* From uiverse.io by @JulioCodesSM */
.style_loader__TrRP4 {
    width: 50px;
    height: 50px;
    position: relative;
    transform: translateX(-25px);
    /* Compensating for the left of 50px in the keyframe. */
   }
   
   .style_loader__TrRP4::before,
   .style_loader__TrRP4::after {
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    mix-blend-mode: multiply;
    animation: style_rotate9__t54sj 1s infinite cubic-bezier(0.77, 0, 0.175, 1);
   }
   
   .style_loader__TrRP4::before {
    background-color: #fc3f9e;
   }
   
   .style_loader__TrRP4::after {
    background-color: #50e8f3;
    animation-delay: .5s;
   }
   
   @keyframes style_rotate9__t54sj {
    0%,100% {
     left: 50px;
    }
   
    25% {
     transform: scale(.3);
    }
   
    50% {
     left: 0;
    }
   
    75% {
     transform: scale(1);
    }
   }`, "",{"version":3,"sources":["webpack://./src/components/layout/Loading/style.module.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,4BAA4B;IAC5B,uDAAuD;GACxD;;GAEA;;IAEC,WAAW;IACX,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,wBAAwB;IACxB,2EAA8D;GAC/D;;GAEA;IACC,yBAAyB;GAC1B;;GAEA;IACC,yBAAyB;IACzB,oBAAoB;GACrB;;GAEA;IACC;KACC,UAAU;IACX;;IAEA;KACC,oBAAoB;IACrB;;IAEA;KACC,OAAO;IACR;;IAEA;KACC,mBAAmB;IACpB;GACD","sourcesContent":["/* From uiverse.io by @JulioCodesSM */\n.loader {\n    width: 50px;\n    height: 50px;\n    position: relative;\n    transform: translateX(-25px);\n    /* Compensating for the left of 50px in the keyframe. */\n   }\n   \n   .loader::before,\n   .loader::after {\n    content: '';\n    position: absolute;\n    width: inherit;\n    height: inherit;\n    border-radius: 50%;\n    mix-blend-mode: multiply;\n    animation: rotate9 1s infinite cubic-bezier(0.77, 0, 0.175, 1);\n   }\n   \n   .loader::before {\n    background-color: #fc3f9e;\n   }\n   \n   .loader::after {\n    background-color: #50e8f3;\n    animation-delay: .5s;\n   }\n   \n   @keyframes rotate9 {\n    0%,100% {\n     left: 50px;\n    }\n   \n    25% {\n     transform: scale(.3);\n    }\n   \n    50% {\n     left: 0;\n    }\n   \n    75% {\n     transform: scale(1);\n    }\n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `style_loader__TrRP4`,
	"rotate9": `style_rotate9__t54sj`
};
export default ___CSS_LOADER_EXPORT___;

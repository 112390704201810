import { https } from '../apiService'

export const xetNghiemService = {
  listDanhMucXetNghiem: () => https.get('XetNghiemDanhMuc/GetAllXetNghiemDanhMuc'),
  listDanhMucXetNghiem_CT: (idct) =>
    https.get(`XetNghiemDanhMuc/GetXetNghiemDanhMucByIDCT?idct=${idct}`),

  listDanhMucXetNghiemTheoCT_NHOMXN: (idnhomxn, idct) =>
    https.get(
      `XetNghiemDanhMuc/GetXetNghiemDanhMucByIDNHOMAndIDCT?GroupidXNDM=${idnhomxn}&idct=${idct}`,
    ),
  listGroupsXetNghiem: () => https.get('XetNghiemNhom/GetAllNhomXetNghiem'),
  searchByGroups: (idGroups) => https.get(`XetNghiemDanhMuc/GetNhomXetNghiemDanhMuc/${idGroups}`),
  fetchInfoXetNghiem: (idxn) => https.get(`XetNghiemDanhMuc/GetXetNghiemDanhMucById/${idxn}`),
  postDMXetNghiem: (form) => https.post('XetNghiemDanhMuc/AddXetNghiemDanhMuc', form),
  deleteDMXetNghiem: (id) => https.delete(`XetNghiemDanhMuc/DeleteXetNghiemDanhMuc/${id}`),
  putDMXetNghiem: (form) => https.put(`XetNghiemDanhMuc/UpdateXetNghiemDanhMuc/${form.idxn}`, form),
  getBNPendingXN: (idChiNhanh) =>
    https.get(`BenhNhanXetNghiem/GetBenhNhanChoXetNghiem/${idChiNhanh}`),
  getBNDaGuiMauXN: (idcn) =>
    https.get(`BenhNhanXetNghiem/GetBenhNhanDaGuiMau`, { params: { idcn } }),
  getBNDoneXN: (idcn, tuNgay, denNgay) =>
    https.get('BenhNhanXetNghiem/GetBenhNhanDaXetNghiem', {
      params: {
        idcn,
        tuNgay,
        denNgay,
      },
    }),
  getHistory: (idbn) => https.get(`BenhNhanXetNghiem/GetLichSuBenhNhanXetNghiem/${idbn}`),
  getDetailXN: (idbnttc) => https.get(`BenhNhanXetNghiem/GetThongTinBenhNhanXetNghiem/${idbnttc}`),
  updateBNXN: (form) => https.put(`BenhNhanXetNghiem/UpdateKetQuaXetNghiem`, form),
  searchByGroupsKeyword: (keyword, idnhomxn) => {
    if (!idnhomxn) {
      return https.get(`XetNghiemDanhMuc/SearchXetNghiemDanhMuc?tenXNDM=${keyword}`)
    }
    return https.get(
      `XetNghiemDanhMuc/SearchXetNghiemDanhMuc?tenXNDM=${keyword}&idNhomXNDM=${idnhomxn}`,
    )
  },
  getPhieus: (idcn, tuNgay, denNgay) =>
    https.get(
      `BenhNhanXetNghiem/GetDanhSachPhieuThuXetNghiemBN?idcn=${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  searchByGroupsAndCtyKeyword: (keyword, idnhomxn, idct) => {
    if (keyword) {
      return https.get(
        `XetNghiemDanhMuc/SearchXetNghiemDanhMucByIDCTAndIDNHOMXN?tenXNDM=${keyword}&idct=${idct}&idnhomxn=${idnhomxn}`,
      )
    } else {
      return https.get(
        `XetNghiemDanhMuc/SearchXetNghiemDanhMucByIDCTAndIDNHOMXN?idct=${idct}&idnhomxn=${idnhomxn}`,
      )
    }
  },
  searchByCtyKeyword: (keyword, idct) => {
    if (!keyword) {
      return https.get(`XetNghiemDanhMuc/SearchXetNghiemDanhMucByIDCT?idct=${idct}`)
    }
    return https.get(
      `XetNghiemDanhMuc/SearchXetNghiemDanhMucByIDCT?tenXNDM=${keyword}&idct=${idct}`,
    )
  },
  uploadFileXN: (form) => https.put(`BenhNhanXetNghiem/UpdateFileXetNghiem`, form),
  deleteFileXN: (idbnttc, fileKetQua) =>
    https.delete(
      `BenhNhanXetNghiem/DeleteFileXetNghiem?idbnttc=${idbnttc}&fileKetQua=${fileKetQua}`,
    ),
  getXetNghiemByCondition: (filter) =>
    https.get(`XetNghiemDanhMuc/SearchXetNghiemDanhMucByIDCTAndIDNHOMXN${filter}`),
  putVTTHchoXN: (idbnttc, list) =>
    https.put(`BenhNhanVatTuTieuHao/UpdateBenhNhanVatTuTieuHaoBinhThuong?idbnttc=${idbnttc}`, list),
}

import { call, delay, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { DonViTinhService } from '../../services/DonViTinh/DonViTinhService'

import Swal from 'sweetalert2'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

export function* donViTinhSaga() {
  // ADD DON VI TINH
  yield takeLatest(typeAction.ADD_DON_VI_TINH, function* addDonViTinh({ type, form, resetForm }) {
    try {
      yield call(() => DonViTinhService.AddDonViTinh(form))
      yield call(() => resetForm())
      yield Toast.fire({
        icon: 'success',
        title: 'Thêm đơn vị tính thành công',
      })
    } catch (err) {
      // yield Toast.fire({
      //     icon: 'error',
      //     title: 'Thêm đơn vị tính thất bại',
      // })
    }
  })

  // UPDATE DON VI TINH
  yield takeLatest(
    typeAction.UPDATE_DON_VI_TINH,
    function* updateDonViTinh({ type, payload, resetForm }) {
      try {
        yield call(() => DonViTinhService.UpdateDonViTinh(payload.ma, payload.data))
        yield call(() => resetForm())
        yield Toast.fire({
          icon: 'success',
          title: 'Cập nhật đơn vị tính thành công',
        })
      } catch (err) {
        yield Toast.fire({
          icon: 'error',
          title: 'Cập nhật đơn vị tính thất bại',
        })
      }
    },
  )

  // GET BY MA DON VI TINH
  yield takeLatest(typeAction.GET_BY_MA_DON_VI_TINH, function* getByMaDonViTinh({ type, ma }) {
    try {
      const result = yield call(() => DonViTinhService.GetByMaDonViTinh(ma))
      yield put({
        type: typeAction.DISPATCH_BY_MA_DON_VI_TINH,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })

  // DELETE DON VI TINH
  yield takeLatest(typeAction.DELETE_DON_VI_TINH, function* deleteDonViTinh({ type, ma }) {
    try {
      yield call(() => DonViTinhService.DeleteDonViTinh(ma))
      yield Toast.fire({
        icon: 'success',
        title: 'Xóa đơn vị tính thành công',
      })
    } catch (err) {
      yield Toast.fire({
        icon: 'error',
        title: 'Xóa đơn vị tính thất bại',
      })
    }
  })
}

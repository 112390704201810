import React, { useEffect, useState } from 'react'
import { Select, Tabs, List, Divider } from 'antd'
import CampaignIcon from '@mui/icons-material/Campaign'
import IconButton from '@mui/material/IconButton'
import BNdone from './BNdone/BNdone'
import CachedIcon from '@mui/icons-material/Cached'

import { useDispatch, useSelector } from 'react-redux'
import { BNChoKham, BNDetailInfo } from '../../../../store/actions/childrenClinicAction'
import moment from 'moment'
import { childrenClinicService } from '../../../../services/childrenClinic/childrenClinicService'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const Tablist = () => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { phongKham } = useSelector((state) => state.receiveReducer)
  const { DetailBN } = useSelector((state) => state.childrenClinicReducer)
  const { pending } = useSelector((state) => state.childrenClinicReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [pkBranch, setPkBranch] = useState(null)
  const dispatch = useDispatch()
  const checkChuyenKhoa = PkDangNhap?.idChuyenKhoa !== 1 // nếu khác thì ===> TRUE

  const handleDetailInfoBN = (idbn, idbnttc) => {
    dispatch(BNDetailInfo(idbn, idbnttc))
  }
  const onChanPhongKham = (value) => {
    setPkBranch(value)
    dispatch(BNChoKham(value))
  }

  const onLoad = () => {
    dispatch(BNChoKham(pkBranch ?? 0))

    // if (phongKham && phongKham?.length > 0 && checkCK === true) {
    //   setDefaultValuePK(phongKham[0].idcnpk)
    //   dispatch(BNChoKham(phongKham[0].idcnpk))
    // } else {
    //   setDefaultValuePK(-1)
    //   dispatch(BNChoKham(-1))
    // }
  }
  useEffect(() => {
    if (!checkChuyenKhoa) {
      setPkBranch(phongKham[0]?.idcnpk)
      dispatch(BNChoKham(phongKham[0]?.idcnpk))
    }
    // else {
    //   setDefaultValuePK(-1)
    //   dispatch(BNChoKham(-1))
    // }
  }, [phongKham])
  useEffect(() => {
    onLoad()
    if (DetailBN?.idbnttc && !DetailBN?.giovaopk) {
      // cập nhật giờ vào phòng khám của bệnh nhân
      const today = moment().format('YYYY-MM-DDTHH:mm:ss')
      childrenClinicService.updateDetailBN({ idbnttc: DetailBN?.idbnttc, giovaopk: today })
    }
  }, [DetailBN])
  // useEffect(() => {
  //   onLoad()
  // }, [])
  return (
    <Tabs
      className="px-2 h-full overflow-auto"
      defaultActiveKey="1"
      items={[
        {
          label: 'Chờ khám',
          key: '1',
          children: (
            <>
              <div className="h-full">
                <div>
                  <div className="flex items-center justify-between">
                    <Select
                      onChange={onChanPhongKham}
                      value={checkChuyenKhoa ? -1 : pkBranch}
                      options={
                        checkChuyenKhoa
                          ? [
                              {
                                label: 'Không có phòng khám',
                                value: -1,
                              },
                            ]
                          : phongKham?.map((items) => ({
                              label: items.tenPhongKham,
                              value: items.idcnpk,
                            }))
                      }
                      className="w-5/6"
                    />
                    <IconButton
                      disabled={checkChuyenKhoa}
                      onClick={onLoad}
                      className="w-1/6"
                      color="primary"
                      aria-label="add to shopping cart"
                    >
                      <CachedIcon />
                    </IconButton>
                  </div>
                  <div>
                    <h2 className="font-semibold">
                      BS Thực hiện: <span className="font-normal">{infoUser?.tenNV}</span>{' '}
                    </h2>
                  </div>
                </div>
                <Divider className="my-2" />
                <ul className=" flex flex-col gap-2 mt-2 rounded-md h-[680px] overflow-auto ">
                  {pending?.map((items) => (
                    <li
                      key={items.idbnttc}
                      onClick={() => {
                        handleDetailInfoBN(items.idbn, items.idbnttc)
                      }}
                      className="flex items-center border py-2 rounded-md hover:bg-sky-100 hover:cursor-pointer hover:-translate-y-1 duration-200"
                    >
                      {' '}
                      <p className="w-1/6 text-center font-semibold text-lg">{items.sttkham}</p>
                      <div className="w-4/6 flex flex-col">
                        <h2 className="font-semibold">{items.tenbenhnhan}</h2>
                        <p className="text-gray-400">
                          Ngày sinh: {items.ngaysinh && moment(items.ngaysinh).format('DD/MM/YYYY')}
                        </p>
                      </div>
                      <IconButton
                        className="w-1/6 flex justify-center bg-red-500"
                        color="primary"
                        aria-label="add to shopping cart"
                      >
                        <CampaignIcon />
                      </IconButton>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          ),
        },
        {
          label: 'Đã khám',
          key: '2',
          children: <BNdone />,
        },
        // {
        //   label: "Cận lâm sàng",
        //   key: "3",
        //   children: <BNCLS />,
        // },
      ]}
    />
  )
}

export default Tablist

import * as Yup from 'yup'

export const vanPhongPhamSchema = Yup.object().shape({
  idchinhanhnhan: Yup.number().required(),
  tendonhang: Yup.string().required(),
})

export const nhanHangVVPSchema = Yup.object().shape({
  nguoigiao: Yup.string().required(),
  ngaygiao: Yup.string().required(),
})

import { DatePicker, Input, Select, Tabs, Badge } from 'antd'
import React from 'react'
import Card from './ThongBaoNhan/Cards'
import LichSu from './LichSu/LichSu'
export default function TiepNhan() {
  const unreadCount = 5 // Số thông báo chưa đọc, bạn có thể thay đổi giá trị này theo dữ liệu thực tế

  return (
    <Tabs
      className="px-2 h-full overflow-auto"
      defaultActiveKey="1"
      items={[
        {
          label: (
            <Badge count={unreadCount} offset={[10, 0]}>
              Thông báo nhận
            </Badge>
          ),
          key: '1',
          children: <Card />,
        },
        {
          label: 'Lịch sử gửi',
          key: '2',
          children: <LichSu />,
        },
      ]}
    />
  )
}

import React, { useState } from 'react'
import { Modal, Form, Input, Select, notification } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { https } from '../../../services/apiService'
import { getICD10ByChuongs } from '../../../store/actions/ICD10Action'

const ModalAdd = ({ isModalVisible, handleOk, handleCancel }) => {
  const [form] = Form.useForm()
  const [IdChuong, setIdChuong] = useState('')
  const { Chuongs } = useSelector((state) => state.IDC10Reducer)
  const dispatch = useDispatch()

  const onFinish = async (values) => {
    const payload = {
      idicD10: 0,
      icD10TV: values.icD10TV,
      icD10TA: values.icD10TA,
      idchuong: IdChuong,
      ghichu: values.ghichu,
      mabenh: values.mabenh,
      mabenhkodau: values.mabenhkodau,
    }

    try {
      const response = await https.post('ChanDoan/AddChanDoanICD10', payload)
      if (response.status === 201) {
        notification.success({
          message: 'Tạo thành công',
          description: `Chẩn đoán ICD-10 đã được tạo thành công với ID: ${response.data.idicD10}.`,
        })
        form.resetFields()
        handleOk()
        handleCancel()
        // dispatch(getICD10ByChuongs('', IdChuong, 1, 50, true, () => {})) // Reload IDC10s
      }
    } catch (error) {
      console.log('Failed:', error)
    }
  }

  return (
    <Modal
      title="Tạo mới"
      visible={isModalVisible}
      onOk={() => form.submit()}
      onCancel={handleCancel}
      okText="Tạo mới"
      cancelText="Huỷ"
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Chương"
          name="idChuong"
          rules={[{ required: true, message: 'Vui lòng chọn chương' }]}
        >
          <Select
            onChange={setIdChuong}
            value={IdChuong}
            options={[
              ...Chuongs?.map((chuong) => ({
                label: chuong.chuongTV,
                value: chuong.idChuong,
              })),
            ]}
            className="text-sm"
          />
        </Form.Item>
        <Form.Item
          label="Mã bệnh"
          name="mabenh"
          rules={[{ required: true, message: 'Vui lòng nhập mã bệnh' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Mã bệnh không dấu"
          name="mabenhkodau"
          rules={[{ required: true, message: 'Vui lòng nhập mã bệnh không dấu' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tên tiếng việt"
          name="icD10TV"
          rules={[{ required: true, message: 'Vui lòng nhập ICD-10 TV' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tên tiếng anh"
          name="icD10TA"
          rules={[{ required: true, message: 'Vui lòng nhập ICD-10 TA' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Ghi chú" name="ghichu">
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ModalAdd

import { DeleteOutlined } from '@ant-design/icons'
import { Button, Input, InputNumber, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { lienheCRMService } from '../../../../services/lienhe/lienheService'
import { addChiTietLienHeCRM } from '../../../../store/actions/lienheCRMAction'
import { https } from '../../../../services/apiService'
import { cloneDeep } from 'lodash'
import { trangThais } from '../../../../utils/trangthaiCRM'
import 'moment/locale/vi'
moment.locale('vi')
const dateFormat = 'DD-MM-YYYY'

const ModalAddChiTiet = ({ openChiTiet, handleCancel, onLoad }) => {
  const dispatch = useDispatch()
  const [allTrangThai, setAllTrangThai] = useState(trangThais)
  const [selectedFiles, setSelectedFiles] = useState([])
  const { dsLienHe } = useSelector((state) => state.lienheCRMReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [companyContact, setCompanyContact] = useState([])
  const today = moment()

  // xử lí form thêm dịch vụ
  const handleSubmit = (value) => {
    value.NGAYBAOGIA = today.format('YYYY-MM-DDTHH:mm:ss')
    const formData = new FormData()
    for (let key in value) {
      formData.append(key, value[key])
    }
    if (selectedFiles.length > 0) {
      selectedFiles.forEach((file, index) => {
        formData.append('FILEBAOGIAS', file) // Use the correct field name for files
      })
    }
    dispatch(addChiTietLienHeCRM(formData, onLoad))
    handleCancel()
    formik.resetForm()
    setSelectedFiles([])
  }

  const fetchCompany = async () => {
    const { data } = await lienheCRMService.getCompanyContactByNV(infoUser?.idnv)
    setCompanyContact(
      data.filter((item) => item.crmThongTinLienHe.trangthai !== trangThais[3].value),
    )
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      IDTTLH: '',
      DIENTHOAILIENHE: '',
      NGUOILIENHE: '',
      EMAILLIENHE: '',
      NHANVIENGOI: infoUser?.idnv,
      FILEBAOGIAS: [],
      TIENBAOGIA: 0,
      GHICHU: '',
      TRANGTHAI: '',
    },
    onSubmit: (value) => handleSubmit(value),
  })

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files)
    setSelectedFiles((prevFiles) => [...prevFiles, ...files])
  }

  const handleFileRemove = (fileToRemove) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove))
  }

  const onChange = (value) => {
    formik.setFieldValue('TIENBAOGIA', value)
  }

  useEffect(() => {
    fetchCompany()
  }, [])
  const handleCheckOption = async (value) => {
    try {
      const { data } = await https.get(`CRMThongTinLienHe/GetCongTyByIDTTLH?idTTLH=${value.idttlh}`)
      const trangthai = data.result?.trangthai
      const idx = allTrangThai.findIndex((item) => item.value === trangthai)
      const newTrangThai = cloneDeep(allTrangThai)
      if (idx !== -1) {
        const trangthaislice = newTrangThai.slice(idx + 1, newTrangThai.length)
        setAllTrangThai(trangthaislice)
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <Modal
        okText="Thêm"
        cancelText="Huỷ"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Chi tiết liên hệ</h2>}
        onOk={formik.handleSubmit}
        open={openChiTiet}
        onCancel={handleCancel}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">Người liên hệ</label>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              defaultValue={formik.values.IDTTLH}
              options={companyContact?.map((item) => ({
                label: item.crmThongTinLienHe?.nguoilienhe,
                value: item.idttlh,
                ...item,
              }))}
              className="w-full"
              onChange={(e, value) => {
                handleCheckOption(value)
                formik.setFieldValue('IDTTLH', value?.idttlh)
                formik.setFieldValue('DIENTHOAILIENHE', value?.crmThongTinLienHe?.dienthoailienhe)
                formik.setFieldValue('EMAILLIENHE', value?.crmThongTinLienHe?.emaillienhe)
                formik.setFieldValue('NGUOILIENHE', value?.crmThongTinLienHe?.nguoilienhe)
              }}
              name="IDTTLH"
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Điện thoại liên hệ</label>
            <Input
              value={formik.values.DIENTHOAILIENHE}
              name="DIENTHOAILIENHE"
              onChangeCapture={formik.handleChange}
              status={formik.errors.DIENTHOAILIENHE && 'error'}
              readOnly
            />
          </div>
          <div>
            <label className="font-semibold text-base">Email liên hệ</label>
            <Input
              value={formik.values.EMAILLIENHE}
              name="EMAILLIENHE"
              onChangeCapture={formik.handleChange}
              status={formik.errors.EMAILLIENHE && 'error'}
              readOnly
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Trạng thái</label>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              defaultValue={formik.values.TRANGTHAI}
              options={allTrangThai?.map((item) => ({
                label: item.label,
                value: item.value,
                disabled: item.disabled,
              }))}
              className="w-full"
              onChange={(e, value) => {
                formik.setFieldValue('TRANGTHAI', value.value)
              }}
              name="TRANGTHAI"
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Nhân viên gọi</label>
            <Input
              value={infoUser.tenNV}
              name="NHANVIENGOI"
              onChangeCapture={formik.handleChange}
              status={formik.errors.NHANVIENGOI && 'error'}
              readOnly
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Ngày gọi</label>
            <Input value={today.format('DD/MM/YYYY')} />
          </div>

          <div>
            <label className="font-semibold  text-base">File báo giá</label>

            <Button className="ml-4 my-2 p-0 bg-blue-500 text-white">
              <label className="cursor-pointer px-4 py-1" for="file-upload">
                Chọn file báo giá
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf"
                onChange={handleFileChange}
                className="hidden"
              />
            </Button>
            {selectedFiles.length > 0 && (
              <div className="mt-2">
                <ul>
                  {selectedFiles.map((file, index) => (
                    <li key={index} className="flex justify-between items-center mt-2">
                      <span>{file.name}</span>

                      <DeleteOutlined
                        className="p-1 rounded-sm bg-red-500 text-white "
                        onClick={() => handleFileRemove(file)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div>
            <label className="font-semibold  text-base">Tiền báo giá</label>
            <InputNumber
              className="w-full"
              min={0}
              name="TIENBAOGIA"
              value={formik.values.TIENBAOGIA}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              onChange={onChange}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Ghi chú </label>
            <Input
              value={formik.values.GHICHU}
              onChangeCapture={formik.handleChange}
              name="GHICHU"
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalAddChiTiet

import { call, put, takeLatest } from 'redux-saga/effects'
import { phanconglienheService } from '../../services/phanconglienhe/phanconglienheService'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
export function* phanconglienheCRMSaga() {
  yield takeLatest(
    typeAction.GET_ALL_PHANCONG_LIENHE_CRM,
    function* allPhanCongLienHe({ type, payload }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => phanconglienheService.getAllPhanCongLienHe())
        result.data.sort((a, b) => new Date(b.ngayphancong) - new Date(a.ngayphancong))
        yield put({
          type: typeAction.DISPATCH_LIST_PHANCONG_CRM,
          payload: result.data,
        })
      } catch (err) {
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.GET_PHANCONG_LIENHE_IDNV_CRM,
    function* phanCongLienHeByIdnv({ type, idnv }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => phanconglienheService.getAllPhanCongLienHeByIdnv(idnv))
        yield put({
          type: typeAction.DISPATCH_LIST_PHANCONG_CRM,
          payload: result.data,
        })
      } catch (err) {
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.GET_DETAIL_PHANCONG_LIENHE_CRM,
    function* allPhanCongLienHe({ type, idpclh }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data } = yield call(() => phanconglienheService.getPhanCongLienHeById(idpclh))
        yield put({
          type: typeAction.DISPATCH_INFO_PHANCONG_CRM,
          payload: data.result,
        })
      } catch (err) {
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.GET_TRANGTHAI_PHANCONG_LIENHE_CRM,
    function* allTrangThaiPhanCongLienHe({ type }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data } = yield call(() => phanconglienheService.getListTrangThaiPhanCongLienHe())
        yield put({
          type: typeAction.DISPATCH_TRANGTHAI_PHANCONG_CRM,
          payload: data,
        })
      } catch (err) {
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  //add list

  yield takeLatest(
    typeAction.ADD_LIST_PHANCONG_LIENHE_CRM,
    function* addPhanCongLienHe({ type, form, onLoad, setLoading }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => phanconglienheService.addListPhanCongLienHe(form))
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Thêm thành công',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: err?.response?.data?.message || 'Thêm thất bại',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        setLoading(false)
      }
    },
  )

  //edit
  yield takeLatest(
    typeAction.EDIT_PHANCONG_LIENHE_CRM,
    function* editPhanCongLienHe({ type, form, onLoad }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => phanconglienheService.editListPhanCongLienHe(form))
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Sửa thành công',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Sửa thất bại',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}

import React, { useEffect, useState } from 'react'
import LayoutApp from '../../../../HOCs/LayoutApp'
import { PlusOutlined, QuestionCircleFilled } from '@ant-design/icons'
import CustomerSms from './Customers/CustomerSms'
import { Button, Input, Select, Progress, Radio, Modal } from 'antd'
import ModalSms from './Modal/ModalSms'
import ModalAdd from './Modal/ModalAdd'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import { useDispatch, useSelector } from 'react-redux'
import { getList_BN_SMS, getList_Template_SMS } from '../../../../store/actions/smsAction'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'
import { cloneDeep } from 'lodash'

const optionTVV = [
  {
    label: 'Tất cả bệnh nhân',
    value: 0,
  },
  {
    label: 'Có thẻ thành viên',
    value: 1,
  },
  {
    label: 'Chưa có thẻ thành viên',
    value: 2,
  },
]

const optionPlace = [
  {
    label: 'Tất cả tỉnh/tp',
    value: 0,
  },
  {
    label: 'Tp. Hồ Chí Minh',
    value: 1,
  },
  {
    label: 'Ngoài Tp. Hồ Chí Minh',
    value: 2,
  },
]

const twoColors = {
  '0%': '#108ee9',
  '100%': '#87d068',
}
const { confirm } = Modal

const SendSms = () => {
  const { listTemplate, listBN } = useSelector((state) => state.smsReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenAdd, setIsOpenAdd] = useState(false)
  const [ttv, setTtv] = useState(0)
  const [place, setPlace] = useState(0)
  const [optionDau, setOptionDau] = useState('1')
  const [templateChoosed, setTemplateChoosed] = useState(null)
  const [allBrandName, setAllBrandName] = useState([])
  const [brandName, setBrandName] = useState(1)
  const [keyword, setKeyword] = useState('')
  const [listChuyenKhoa, setListChuyenKhoa] = useState([])
  const [chuyenKhoa, setChuyenKhoa] = useState(PkDangNhap?.idChuyenKhoa ?? 0)
  const [loadingBN, setLoadingBN] = useState(false)

  const dispatch = useDispatch()
  const showModal = () => {
    if (!templateChoosed) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng chọn template gửi sms',
      })
      return
    }
    const numberOfSend = listBN?.filter((item) => item?.guisms)?.length
    if (numberOfSend === 0) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng chọn khách hàng để gửi sms',
      })
      return
    }
    setIsOpen(true)
  }
  const cancelModal = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    dispatch(getList_BN_SMS(chuyenKhoa, null, null, setLoadingBN))
    dispatch(getList_Template_SMS())
  }, [])

  const handleChangeTemplate = (value) => {
    setTemplateChoosed(listTemplate.find((item) => item.idtp === value))
  }

  const handeChangeOptionDau = (e) => {
    setOptionDau(e.target.value)
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('SMSTaiKhoan/GetAll')
        setAllBrandName(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('ChuyenKhoa/AllChuyenKhoa')
        setListChuyenKhoa(data)
        setChuyenKhoa(PkDangNhap?.idChuyenKhoa ?? 0)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  const handleRefresh = () => {
    setTemplateChoosed(null)
    setOptionDau('1')
  }

  const handleSendOTP = async () => {
    if (!templateChoosed) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng chọn template gửi sms',
      })
      return
    }
    const numberOfSend = listBN?.filter((item) => item?.guisms)?.length
    if (numberOfSend === 0) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng chọn khách hàng để gửi sms',
      })
      return
    }
    try {
      // await https.post(`SMSGuiTinNhan/SendOTP?nguoiYeuCau=${infoUser?.idnv}`) // send to phone
      await https.post(`SMSGuiTinNhan/SendOTPEmail?nguoiYeuCau=${infoUser?.idnv}`) // send to email
      ToastCus.fire({
        icon: 'success',
        title: 'Gửi OTP thành công',
        text: 'Vui lòng kiểm tra mã OTP đã được gửi',
        timer: 3000,
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Gửi mã OTP thất bại',
      })
    }
  }

  const handleChangeContentTemplate = (e) => {
    const cloneTemplateChoosed = cloneDeep(templateChoosed)
    if (optionDau === '0') {
      // co dau
      cloneTemplateChoosed.templatE_CODAU = e.target.value
    } else {
      cloneTemplateChoosed.templatE_KHONGDAU = e.target.value
    }
    setTemplateChoosed(cloneTemplateChoosed)
  }

  return (
    <>
      <div className="p-1 h-full bg-[#EFEFEF]">
        <div className="flex bg-white rounded-lg border h-full">
          <div className="w-1/4 border-r">
            <div>
              <div className="p-2 border-b flex justify-between items-center">
                <h2 className="font-semibold ">Gửi SMS</h2>
                <p className="flex font-semibold items-center">
                  Đã chọn:
                  <span className="text-red-500 px-1 text-lg">
                    {listBN && listBN?.filter((item) => item?.guisms)?.length}/
                    {listBN && listBN?.length}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                    />
                  </svg>
                </p>
              </div>
              <div className="p-2 clas">
                <Select
                  placeholder="Brandname"
                  className="w-full mb-2"
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={allBrandName?.map(({ brandname, idtaikhoan }) => ({
                    label: brandname,
                    value: idtaikhoan,
                  }))}
                  value={brandName}
                  onChange={(value) => {
                    setBrandName(value)
                    setTemplateChoosed(null)
                  }}
                />
                <div className="flex gap-2">
                  <Select
                    placeholder="Template"
                    className="w-full"
                    onChange={handleChangeTemplate}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={listTemplate
                      ?.filter((item) => item?.idtaikhoan === brandName)
                      ?.map(({ matp, noidungmau, idtp, chuyenkhoa, templatE_KHONGDAU }) => ({
                        label: `${matp} - ${templatE_KHONGDAU}`,
                        value: idtp,
                      }))}
                    value={templateChoosed?.idtp}
                  />
                  {/* <Button className="w-20" onClick={() => setIsOpenAdd(true)} icon={<PlusOutlined />}></Button> */}
                </div>
                <div className="flex gap-2 mt-2">
                  <h2 className="font-semibold w-1/4">Gửi SMS</h2>
                  <Radio.Group
                    onChange={handeChangeOptionDau}
                    value={optionDau}
                    className="w-3/4 text-center"
                    options={[
                      {
                        label: 'Tin có dấu',
                        value: '0',
                      },
                      {
                        label: 'Tin không dấu',
                        value: '1',
                      },
                    ]}
                  />
                </div>
                <div className="mt-2">
                  <label className="font-semibold mb-2">Nội dung:</label>
                  <Input.TextArea
                    showCount
                    style={{
                      height: 320,
                      resize: 'none',
                    }}
                    value={
                      optionDau === '0'
                        ? templateChoosed?.templatE_CODAU
                        : templateChoosed?.templatE_KHONGDAU
                    }
                    onChange={handleChangeContentTemplate}
                    // readOnly
                    disabled={!templateChoosed}
                    className="w-full"
                  />
                </div>
                <div className="flex gap-2 justify-end mt-5">
                  <Button
                    onClick={handleSendOTP}
                    type="primary"
                    ghost
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.49 12 3.75-3.751m0 0-3.75-3.75m3.75 3.75H3.74V19.5"
                        />
                      </svg>
                    }
                  >
                    Gửi OTP
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                        />
                      </svg>
                    }
                    onClick={handleRefresh}
                  >
                    Làm mới
                  </Button>
                  <Button
                    onClick={showModal}
                    // onClick={handleConfirm}
                    type="primary"
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                        />
                      </svg>
                    }
                  >
                    Gửi SMS
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-3/4">
            <div className="flex gap-5 justify-between border-dotted border-b-0 p-4">
              <div className="flex gap-5 justify-between w-full">
                <div className="flex gap-3 mr-auto">
                  <Select
                    placeholder="Chuyên khoa"
                    className="w-64"
                    options={[
                      {
                        label: 'Tất cả chuyên khoa',
                        value: 0,
                      },
                      ...listChuyenKhoa?.map((item) => ({
                        label: item?.tenChuyenKhoa,
                        value: item?.idChuyenKhoa,
                      })),
                    ]}
                    value={chuyenKhoa}
                    onChange={(value) => setChuyenKhoa(value)}
                  />
                  <Select
                    placeholder="Địa chỉ"
                    className="w-64"
                    options={optionPlace.map((item) => ({
                      label: item.label,
                      value: item.value,
                    }))}
                    value={place}
                    onChange={(value) => setPlace(value)}
                  />
                  <Select
                    placeholder="Thẻ thành viên"
                    className="w-64"
                    options={optionTVV.map((item) => ({
                      label: item.label,
                      value: item.value,
                    }))}
                    value={ttv}
                    onChange={(value) => setTtv(value)}
                  />

                  <IconButton
                    onClick={() =>
                      dispatch(
                        getList_BN_SMS(
                          chuyenKhoa === 0 ? null : chuyenKhoa,
                          place === 0 ? null : place,
                          ttv === 0 ? null : ttv,
                          setLoadingBN,
                        ),
                      )
                    }
                    color="primary"
                    aria-label="delete"
                    size="small"
                  >
                    <SyncIcon />
                  </IconButton>
                </div>
                <div className="ml-auto">
                  {/* <Button
                  //   onClick={() => exportToExcel(search, chiNhanh, company, dateFrom, dateTo)}
                  type="text"
                  size="middle"
                  className="text-green-700 p-1"
                  icon={<ImportExportIcon />}
                  loading={loadingExport}
                  disabled={loadingExport}
                >
                  Xuất Excel
                </Button> */}
                </div>
              </div>
            </div>
            <CustomerSms loadingBN={loadingBN} />
          </div>
        </div>
      </div>
      <ModalSms
        isOpen={isOpen}
        cancelModal={cancelModal}
        templateChoosed={templateChoosed}
        optionDau={optionDau}
      />
      <ModalAdd isOpenAdd={isOpenAdd} setIsOpenAdd={setIsOpenAdd} />
    </>
  )
}

export default SendSms

import { Input, Select } from 'antd'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import 'moment/locale/vi'
moment.locale('vi')

const InfoBN = ({ formik, infoBN }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const today = moment()

  return (
    <div className="p-2 border mt-2 rounded-md">
      <div className="flex gap-2">
        <div className="w-1/2 flex flex-col gap-2">
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Tên: </label>
              <Input size="small" value={infoBN?.benhNhan?.tenBenhNhan} variant="filled" readOnly />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">Mã BN: </label>
              <Input size="small" value={infoBN?.benhNhan?.maBenhNhan} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Địa chỉ: </label>
              <Input size="small" value={infoBN?.benhNhan?.diaChi} variant="filled" readOnly />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">SĐT: </label>
              <Input size="small" value={infoBN?.benhNhan?.soDienThoai} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Thực hiện: </label>
              <Input
                size="small"
                value={
                  infoBN
                    ? formik.values.bsthuchien?.trim().length > 0
                      ? formik.values.bsthuchien
                      : infoUser?.tenNV
                    : ''
                }
                variant="filled"
                readOnly
              />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">Ngày TH: </label>
              <Input
                size="small"
                value={
                  infoBN
                    ? formik.values.ngaythuchien?.trim().length > 0
                      ? moment(formik.values.ngaythuchien).format('DD/MM/YYYY')
                      : today.format('DD/MM/YYYY')
                    : ''
                }
                variant="filled"
                readOnly
              />
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <label className="w-24 font-semibold">Chẩn đoán: </label>
              <Input size="small" value={infoBN?.chandoan} variant="filled" readOnly />
            </div>
          </div>
        </div>
        <div className="w-1/2 gap-2 flex flex-col">
          <div className="flex gap-2">
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Ngày sinh: </label>
              <Input
                size="small"
                value={
                  infoBN?.benhNhan?.ngaySinh &&
                  moment(infoBN?.benhNhan?.ngaySinh).format('DD/MM/YYYY')
                }
                variant="filled"
                readOnly
              />
            </div>
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Giới tính: </label>
              <Input size="small" value={infoBN?.benhNhan?.gioiTinh} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-32 font-semibold">Đối tượng: </label>
              <Input size="small" value={infoBN?.benhNhan?.tenDoiTuong} variant="filled" readOnly />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-36 font-semibold">Mã TCQG: </label>
              <Input size="small" value={infoBN?.benhNhan?.maTCQG} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex items-center">
            <label className="w-28 font-semibold">Bác sĩ CĐ: </label>
            <Input size="small" value={infoBN?.bskham} variant="filled" readOnly />
          </div>
        </div>
      </div>
      <div className="flex items-center mt-2">
        <label className="w-24 font-semibold">Ghi chú: </label>
        <Input
          size="small"
          value={formik.values.ghichu}
          name="ghichu"
          onChange={formik.handleChange}
        />
      </div>
    </div>
  )
}

export default InfoBN

import { call, delay, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { dangKyKhamOnlineService } from '../../services/dangKyKhamOnline/dangKyKhamOnlineService'
import ToastCus from '../../utils/Toast'

export function* khachHangDTSaga() {
  // ADD LIST KHÁCH HÀNG ĐỐI TÁC
  yield takeLatest(typeAction.POST_KHACH_HANG_DT, function* postKhachHangDTSaga({ type, list }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      yield call(() => dangKyKhamOnlineService.postKhachHangDT(list))
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (err) {
      console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  yield takeLatest(
    typeAction.PUT_KHACH_HANG_DT,
    function* putKhachHangDTSaga({ type, form, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const formatData = {
          idKH: form?.idKH,
          tenKH: form?.tenKH,
          gioiTinh: form?.gioiTinh,
          ngaySinh: form?.ngaySinh,
          dienThoai: form?.dienThoai,
          diaChi: form?.diaChi,
          idTinh: form?.idTinh,
          idQuan: form?.idQuan,
          idPhuong: form?.idPhuong,
          idNgheNghiep: form?.idNgheNghiep,
          idDoiTuong: form?.idDoiTuong,
          cccd: form?.cccd,
          ghiChu: form?.ghiChu,
          idDoiTac: form?.idDoiTac,
          nguoiSua: form?.nguoiSua,
        }
        yield call(() => dangKyKhamOnlineService.putKhachHangDT(formatData))
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}

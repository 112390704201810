import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LayoutApp from '../../../HOCs/LayoutApp'
import ListSelling from './ListSelling.jsx/ListSelling'
import BIlling from './Billing/BIlling'
import { drugstoreService } from '../../../services/drugstore/drugstoreService'
import { Tabs } from 'antd'
import PTVacXin from './PTThuoc/PTVacXin'
import ToastCus from '../../../utils/Toast'
import Producs from '../Selling/Producs/Producs'
const Selling = () => {
  const { service } = useSelector((state) => state.drugstoreReducer)
  const [check, setCheck] = useState(false)
  const [checkTraThuoc, setCheckTraThuoc] = useState(false)
  const [lyDo, setLyDo] = useState([]) //listLdKhongLayThuoc
  const [lyDoTraToa, setLyDoTraToa] = useState([]) //listLdKhongLayThuoc
  const [idLyDo, setIdLyDo] = useState(7)
  const [lyDoKhac, setLyDoKhac] = useState(null) // lý do không lấy thuốc trước khi thanh toán
  const handleCheckBox = () => {
    if (!service?.BSKEDON || !service?.NGAYKEDON) {
      ToastCus.fire({
        icon: 'error',
        title: 'Bác sĩ đang kê toa lại không thể chỉnh sửa!',
      })
      return
    }
    setCheck(!check)
  }
  const fetchLyDo = async () => {
    try {
      const { data: listLdKhongLayThuoc } = await drugstoreService.getLyDoKhongLayThuoc()
      const { data: listLdTraToaChoBs } = await drugstoreService.getLyDoTraToaChoBs()
      setLyDo(listLdKhongLayThuoc)
      setLyDoTraToa(listLdTraToaChoBs)
      setIdLyDo(listLdKhongLayThuoc[0]?.idld)
    } catch (error) {
      console.log(error)
    }
  }
  const LoaiThanhToan = 6 // toa xuất vacxin
  useEffect(() => {
    fetchLyDo()
  }, [])
  return (
    <>
      <div className="h-full w-full bg-[#EBEBEB] p-3">
        <div className="p-2 bg-white rounded-xl border h-full">
          <Tabs
            items={[
              {
                key: 1,
                label: 'Xuất vắc xin',
                children: (
                  <div className="flex border rounded-lg">
                    <div className="w-4/6 h-full border-r ">
                      <ListSelling
                        lyDo={lyDo}
                        lyDoTraToa={lyDoTraToa}
                        idLyDo={idLyDo}
                        setIdLyDo={setIdLyDo}
                        check={check}
                        setCheck={setCheck}
                        handleCheckBox={handleCheckBox}
                        checkTraThuoc={checkTraThuoc}
                        setCheckTraThuoc={setCheckTraThuoc}
                        lyDoKhac={lyDoKhac} // lý do không lấy thuốc trước khi thanh toán
                        setLyDoKhac={setLyDoKhac} // lý do không lấy thuốc trước khi thanh toán
                      />
                    </div>
                    <div className="w-2/6 h-full ">
                      <BIlling
                        idLyDo={idLyDo}
                        check={check}
                        setIdLyDo={setIdLyDo}
                        setCheck={setCheck}
                        lyDo={lyDo}
                        checkTraThuoc={checkTraThuoc}
                        setCheckTraThuoc={setCheckTraThuoc}
                        lyDoKhac={lyDoKhac} // lý do không lấy thuốc trước khi thanh toán
                      />
                    </div>
                  </div>
                ),
              },
              {
                label: 'Phiếu thu',
                key: 2,
                children: <PTVacXin />,
              },
              {
                label: 'Hàng hoá',
                key: 3,
                children: <Producs IDLoaiThanhToan={LoaiThanhToan} />,
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default Selling

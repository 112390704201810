import moment from 'moment'
import { call, put, takeLatest } from 'redux-saga/effects'

import * as typeAction from '../constants/constants'
import { smsService } from '../../services/sms/smsService'
import 'moment/locale/vi'
moment.locale('vi')

export function* smsSaga() {
  //GET template
  yield takeLatest(typeAction.GET_LIST_TEMPLATE_SMS, function* getBNByIdChiNhanhIdCty({ type }) {
    yield put({
      type: typeAction.OPEN_LOADING_PAGE,
    })
    try {
      const { data } = yield call(() => smsService.getTemplate())
      yield put({
        type: typeAction.DISPATCH_LIST_TEMPLATE_SMS,
        payload: data,
      })
    } catch (err) {
      yield console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  yield takeLatest(
    typeAction.GET_BN_SMS,
    function* getBNByIdChiNhanhIdCty({ type, chuyenKhoa, numberTP, numberTTV, setLoadingBN }) {
      try {
        setLoadingBN(true)
        const { data } = yield call(() => smsService.getBNSms(chuyenKhoa, numberTP, numberTTV))
        yield put({
          type: typeAction.DISPATCH_LIST_BN_SMS,
          payload: data?.map((item, index) => ({ ...item, stt: ++index, guisms: 0 })),
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        setLoadingBN(false)
      }
    },
  )
}

import React, { useEffect, useState } from 'react'
import { Button, ConfigProvider, Table, Input, Select, Modal, Form, message } from 'antd'
import { https } from '../../../../services/apiService'
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'

const { Option } = Select

const QuanHuyen = () => {
  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [tinhData, setTinhData] = useState([])
  const [selectedTinh, setSelectedTinh] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [newDistrict, setNewDistrict] = useState({ tenQuan: '', idTinh: null })

  useEffect(() => {
    const fetchTinhData = async () => {
      try {
        const response = await https.get('/tinh')
        setTinhData(response.data)
      } catch (error) {
        console.error('Error fetching Tinh data:', error)
      }
    }

    fetchTinhData()
  }, [])

  useEffect(() => {
    if (selectedTinh) {
      const fetchQuanHuyenData = async () => {
        try {
          const response = await https.get(`/QuanHuyen/${selectedTinh}`)
          setData(response.data)
          setFilteredData(response.data)
        } catch (error) {
          console.error('Error fetching QuanHuyen data:', error)
        }
      }

      fetchQuanHuyenData()
    }
  }, [selectedTinh])

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase()
    setSearchText(value)
    const filtered = data.filter((item) => item.tenQuan?.toLowerCase().includes(value))
    setFilteredData(filtered)
  }

  const handleTinhChange = (value) => {
    setSelectedTinh(value)
  }

  const handleAddDistrict = async () => {
    try {
      const response = await https.post('/QuanHuyen/CreateQuanHuyen', newDistrict)
      message.success('Đã thêm quận/huyện thành công')
      setIsModalVisible(false)
      setNewDistrict({ tenQuan: '', idTinh: null })
      // Refetch data after addition
      const fetchQuanHuyenData = async () => {
        try {
          const response = await https.get(`/QuanHuyen/${selectedTinh}`)
          setData(response.data)
          setFilteredData(response.data)
        } catch (error) {
          console.error('Error fetching QuanHuyen data:', error)
        }
      }
      fetchQuanHuyenData()
    } catch (error) {
      console.error('Error adding district:', error)
      message.error('Thêm quận/huyện thất bại')
    }
  }

  const showDeleteConfirm = (idQuanHuyen) => {
    Modal.confirm({
      title: 'Bạn có chắc chắn muốn xóa quận/huyện này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Xóa',
      okType: 'danger',
      cancelText: 'Hủy',
      onOk() {
        handleDeleteDistrict(idQuanHuyen)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const handleDeleteDistrict = async (idQuanHuyen) => {
    try {
      await https.delete(`/QuanHuyen/DeleteQuanHuyen?idQuan=${idQuanHuyen}`)
      message.success('Đã xóa thành công')
      // Refetch data after deletion
      const response = await https.get(`/QuanHuyen/${selectedTinh}`)
      setData(response.data)
      setFilteredData(response.data)
    } catch (error) {
      console.error('Error deleting district:', error)
      message.error('Xóa quận/huyện thất bại')
    }
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên Quận/Huyện',
      dataIndex: 'tenQuan',
      key: 'tenQuan',
    },
    {
      title: 'Hành động',
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
          {/* <Button type="primary" size="small" icon={<EditOutlined />}>
            Sửa
          </Button> */}
          <Button
            type="primary"
            danger
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => showDeleteConfirm(record.idQuan)}
          >
            Xóa
          </Button>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="flex justify-start items-center mb-4 space-x-4">
        <Select
          placeholder="Chọn Tỉnh/Thành phố"
          onChange={handleTinhChange}
          style={{ width: 250 }}
        >
          {tinhData.map((tinh) => (
            <Option key={tinh.idTinh} value={tinh.idTinh}>
              {tinh.tenTinh}
            </Option>
          ))}
        </Select>
        <Input
          placeholder="Tìm kiếm quận/huyện"
          value={searchText}
          onChange={handleSearch}
          style={{ width: 250 }}
          prefix={<SearchOutlined />}
        />
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)}>
          Thêm quận/huyện
        </Button>
      </div>

      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table columns={columns} dataSource={filteredData} rowKey="idQuanHuyen" />
      </ConfigProvider>

      <Modal
        title="Thêm quận/huyện"
        visible={isModalVisible}
        onOk={handleAddDistrict}
        onCancel={() => setIsModalVisible(false)}
        okText="Lưu"
        cancelText="Hủy"
      >
        <Form layout="vertical">
          <Form.Item label="Tên Quận/Huyện">
            <Input
              value={newDistrict.tenQuan}
              onChange={(e) => setNewDistrict({ ...newDistrict, tenQuan: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="Tỉnh/Thành phố">
            <Select
              value={newDistrict.idTinh}
              onChange={(value) => setNewDistrict({ ...newDistrict, idTinh: value })}
              placeholder="Chọn Tỉnh/Thành phố"
            >
              {tinhData.map((tinh) => (
                <Option key={tinh.idTinh} value={tinh.idTinh}>
                  {tinh.tenTinh}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default QuanHuyen

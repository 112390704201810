import React, { memo, useRef, useState } from 'react'
import { Button, ConfigProvider, DatePicker, Input, Select, Space, Table, Typography } from 'antd'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { Tag } from 'antd'
import 'moment/locale/vi'
moment.locale('vi')
const TableHang = ({ data, loadingChiTiet, setValueExport }) => {
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState('')
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const recordValue = record[dataIndex]
      return recordValue
        ? recordValue.toString().toLowerCase().includes(value.toLowerCase())
        : false
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  // Hàm để lấy danh sách các bộ lọc không trùng lặp từ dữ liệu
  const getUniqueFilters = (data, dataIndex) => {
    const uniqueValues = Array.from(new Set(data.map((item) => item[dataIndex]))) // Lấy các giá trị không trùng
    return uniqueValues.map((value) => ({
      text: value,
      value: value,
    }))
  }
  const columnsDetails = [
    {
      title: 'STT',
      dataIndex: 'STT',
      align: 'center',
      key: 'STT',
      width: 40,
      // fixed: 'left',
    },
    {
      title: 'Thông tin phiếu nhập',
      dataIndex: 'thongTinPhieuNhap',
      align: 'center',
      key: 'thongTinPhieuNhap',
      children: [
        {
          title: 'Mã phiếu',
          dataIndex: 'maphieu',
          align: 'center',
          key: 'maphieu',
          width: 150,
          ...getColumnSearchProps('maphieu'),
          // fixed: 'left',
        },
        {
          title: 'Ngày xuất',
          dataIndex: 'ngayxuat',
          key: 'ngayxuat',
          // fixed: 'left',
          width: 150,
          render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
        },
        {
          title: 'Ngày nhập',
          dataIndex: 'ngaynhan',
          key: 'ngaynhan',
          // fixed: 'left',
          width: 150,
          render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
        },
        {
          title: 'Tên phiếu',
          dataIndex: 'tenphieu',
          key: 'tenphieu',
          width: 350,
          ...getColumnSearchProps('tenphieu'),
        },
        {
          title: 'Mã hàng',
          dataIndex: 'mathuoc',
          key: 'mathuoc',
          width: 100,
          ...getColumnSearchProps('mathuoc'),
        },
        {
          title: 'Tên biệt dược',
          dataIndex: 'tenbietduoc',
          key: 'tenbietduoc',
          width: 350,
          ...getColumnSearchProps('tenbietduoc'),
        },
        {
          title: 'Số Lô',
          dataIndex: 'solo',
          key: 'solo',
          align: 'center',
          width: 120,
          ...getColumnSearchProps('solo'),
        },
        {
          title: 'Hạn Dùng',
          dataIndex: 'handung',
          key: 'handung',
          width: 120,
          align: 'center',
          render: (text) => moment(text).format('DD/MM/YYYY'),
        },
        {
          title: 'Số hoá đơn',
          dataIndex: 'sohoadon',
          key: 'sohoadon',
          width: 120,
          onFilter: (value, record) => record.sohoadon.startsWith(value),
          filters: getUniqueFilters(data, 'sohoadon'),
          filterSearch: true,
          // ...getColumnSearchProps('sohoadon'),
        },
        {
          title: 'SL chẵn',
          dataIndex: 'soLuongChan',
          key: 'soLuongChan',
          align: 'center',
          width: 100,
          ...getColumnSearchProps('soLuongChan'),
        },
        {
          title: 'Đơn vi chẵn',
          dataIndex: 'donvichan',
          align: 'center',
          key: 'donvichan',
          width: 120,
          ...getColumnSearchProps('donvichan'),
        },
        {
          title: 'Đơn giá chẵn',
          dataIndex: 'donGiaChan',
          align: 'right',
          key: 'donGiaChan',
          width: 150,
          render: (text) => (text ? formatNumberVND(text) : 0),
        },
        {
          title: 'Quy cách',
          dataIndex: 'quycach',
          key: 'quycach',
          align: 'center',
          width: 120,
          ...getColumnSearchProps('quycach'),
        },
        {
          title: 'SL lẻ',
          dataIndex: 'soluongle',
          key: 'soluongle',
          align: 'center',
          width: 100,
          ...getColumnSearchProps('soluongle'),
        },
        {
          title: 'Đơn vị lẻ',
          dataIndex: 'donViLe',
          key: 'donViLe',
          align: 'center',
          width: 120,
          ...getColumnSearchProps('donViLe'),
        },
        {
          title: 'Đơn giá lẻ',
          dataIndex: 'donGiaLe',
          key: 'donGiaLe',
          width: 150,
          render: (text) => (text ? formatNumberVND(text) : 0),
          align: 'right',
        },
        {
          title: 'Ngày HĐ',
          dataIndex: 'ngayhoadon',
          align: 'center',
          key: 'ngayhoadon',
          width: 100,
          render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
        },
        {
          title: 'Chi nhánh',
          dataIndex: 'tenchinhanh',
          width: 250,
          key: 'tenchinhanh',
          ...getColumnSearchProps('tenchinhanh'),
        },
        {
          title: 'Kho nhập',
          dataIndex: 'tenkho',
          key: 'tenkho',
          width: 150,
          ...getColumnSearchProps('tenkho'),
        },
        {
          title: 'Mã đối tác',
          dataIndex: 'madoitac',
          align: 'center',
          key: 'madoitac',
          width: 120,
          ...getColumnSearchProps('madoitac'),
        },
        {
          title: 'Tên đối tác',
          dataIndex: 'tendoitac',
          key: 'tendoitac',
          width: 550,
          ...getColumnSearchProps('tendoitac'),
        },
        {
          title: 'Link hóa đơn',
          dataIndex: 'linkhoadon',
          key: 'linkhoadon',
          width: 550,
          ...getColumnSearchProps('linkhoadon'),
          // render: (text, record, index) => (
          //   <Input size="small" className="w-full" variant="filed" readOnly value={text} />
          // ),
        },
      ],
    },
    {
      title: 'Thông tin hàng hoá',
      dataIndex: 'tenThuocVatTu',
      key: 'tenThuocVatTu',
      align: 'center',
      children: [
        {
          title: 'Tên hoạt chất',
          dataIndex: 'tenhoatchat',
          key: 'tenhoatchat',
          width: 350,
          ...getColumnSearchProps('tenhoatchat'),
        },

        {
          title: 'Tổng tiền',
          dataIndex: 'tongTien',
          key: 'tongTien',
          width: 130,
          render: (text) => (text ? formatNumberVND(text) : 0),
          align: 'right',
        },
        {
          title: 'P. Gia công',
          dataIndex: 'phigiacong',
          key: 'phigiacong',
          width: 100,
          render: (text) => (text ? formatNumberVND(text) : 0),
          align: 'right',
        },
        {
          title: 'P. vận chuyển',
          dataIndex: 'phivanchuyen',
          key: 'phivanchuyen',
          width: 100,
          render: (text) => (text ? formatNumberVND(text) : 0),
          align: 'right',
        },
        {
          title: '% Ck trước VAT',
          dataIndex: 'ptcktruocvat',
          key: 'ptcktruocvat',
          align: 'center',
          width: 120,
        },
        {
          title: 'Tiền CK trước VAT',
          dataIndex: 'cktruocvat',
          key: 'cktruocvat',
          render: (text) => (text ? formatNumberVND(text) : 0),
          width: 130,
          align: 'right',
        },
        {
          title: 'Tổng tiền',
          dataIndex: 'tongtientruocvat',
          key: 'tongtientruocvat',
          render: (text) => (text ? formatNumberVND(text) : 0),
          width: 120,
          align: 'right',
        },
        {
          title: 'VAT',
          dataIndex: 'vat',
          key: 'vat',
          align: 'center',
          width: 60,
          ...getColumnSearchProps('vat'),
        },
        {
          title: 'Tiền % VAT',
          dataIndex: 'phanTramVat',
          key: 'phanTramVat',
          render: (text) => (text ? formatNumberVND(text) : 0),
          align: 'right',
          width: 120,
        },
        {
          title: 'Thành tiền',
          dataIndex: 'thanhtien',
          key: 'thanhtien',
          render: (text) => (text ? formatNumberVND(text) : 0),
          align: 'right',
          width: 150,
        },
        {
          title: 'Thực trả',
          dataIndex: 'thuctra',
          key: 'thuctra',
          render: (text) => (text ? formatNumberVND(text) : 0),
          align: 'right',
          width: 150,
        },
        {
          title: ' Nhiệt độ',
          dataIndex: 'nhietdo',
          key: 'nhietdo',
          width: 120,
          align: 'center',
          ...getColumnSearchProps('nhietdo'),
        },
        {
          title: 'Mã số ĐK',
          dataIndex: 'masodangky',
          key: 'masodangky',
          align: 'left',
          width: 120,
          ...getColumnSearchProps('masodangky'),
        },

        {
          title: 'Ghi chú',
          dataIndex: 'ghichu',
          key: 'ghichu',
          width: 400,
          ...getColumnSearchProps('ghichu'),
        },
      ],
    },

    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 90,
      fixed: 'right',
      render: (text) =>
        text === 5 ? (
          <Tag color="volcano" bordered={false}>
            Chưa nhập
          </Tag>
        ) : (
          <Tag bordered={false} color="green">
            Đã nhập
          </Tag>
        ),
    },
  ]
  const onChange = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }
  return (
    <ConfigProvider
      theme={{
        token: {
          padding: 5,
          borderRadius: 0,
        },
        components: {
          Table: {
            rowHoverBg: '#ecf0f1',
            headerBg: '#e6e6e6',
            footerBg: '#e6e6e6',
            borderColor: '#BABABA',
          },
        },
      }}
    >
      <Table
        onChange={onChange}
        virtual
        loading={loadingChiTiet}
        scroll={{ x: 3000, y: 620 }}
        bordered
        pagination={false}
        columns={columnsDetails}
        dataSource={data?.map((item, index) => ({
          key: index,
          STT: index + 1,
          maphieu: item.maphieu,
          tenphieu: item.tenphieu,
          ngayxuat: item.ngayxuat,
          ngaynhan: item.ngaynhan,
          sohoadon: item.sohoadon,
          ngayhoadon: item.ngayhoadon,
          tenchinhanh: item.tenchinhanh,
          quycach: item.quycach,
          tenkho: item.tenkho,
          tendoitac: item.tendoitac,
          madoitac: item.madoitac,
          tenbietduoc: item.tenbietduoc,
          tenhoatchat: item.tenhoatchat,
          mathuoc: item.mathuoc,
          soLuongChan: Math.floor(item?.soluongle / item?.soluongdonggoi) ?? 0,
          donvichan: item?.donvichan,
          dongiamua: item.dongiamua,
          soluongdonggoi: item?.soluongdonggoi,
          donGiaChan: item?.dongiamua * item?.soluongdonggoi,
          soluongle: item?.soluongle,
          donViLe: item?.dvt,
          donGiaLe: item?.dongiamua,
          tongTien: item?.dongiamua * item?.soluongle,
          phigiacong: item?.phigiacong,
          phivanchuyen: item?.phivanchuyen,
          ptcktruocvat: item?.ptcktruocvat,
          cktruocvat: item?.cktruocvat,
          tongtientruocvat: item?.tongtientruocvat,
          vaT5: item.vaT5,
          vaT8: item.vaT8,
          vaT10: item.vaT10,
          vat: item.vaT5 !== 0 ? '5%' : item.vaT8 !== 0 ? '8%' : '10%',
          phanTramVat:
            item?.vaT5 !== 0
              ? Math.round(item?.vaT5)
              : item?.vaT8 !== 0
                ? Math.round(item?.vaT8)
                : Math.round(item?.vaT10),
          thanhtien: item?.thanhtien,
          thuctra: item?.thuctra,
          nhietdo: item?.nhietdo,
          masodangky: item?.masodangky,
          solo: item?.solo,
          handung: item?.handung,
          ghichu: item?.ghichu,
          status: item.trangthaichuyenkho,
          linkhoadon: item?.linkhoadon,
        }))}
        summary={(pageData) => {
          const totalDonGiaLe = pageData.reduce((sum, record) => sum + (record.donGiaLe || 0), 0)
          const totalDonGiaChan = pageData.reduce(
            (sum, record) => sum + (record.donGiaChan || 0),
            0,
          )
          const totalSoLuongChan = pageData.reduce(
            (sum, record) => sum + (record.soLuongChan || 0),
            0,
          )
          const totalGiaCong = pageData.reduce((sum, record) => sum + (record.phigiacong || 0), 0)
          const totalVanChuyen = pageData.reduce(
            (sum, record) => sum + (record.phivanchuyen || 0),
            0,
          )
          const totalSoLuongLe = pageData.reduce((sum, record) => sum + (record.soluongle || 0), 0)
          const totalTongTien = pageData.reduce((sum, record) => sum + (record.tongTien || 0), 0)
          const totalTongTienTrVAT = pageData.reduce(
            (sum, record) => sum + (record.tongtientruocvat || 0),
            0,
          )
          const totalTienCK = pageData.reduce((sum, record) => sum + (record.cktruocvat || 0), 0)
          const totalTienVAT = pageData.reduce((sum, record) => sum + (record.phanTramVat || 0), 0)
          const totalThanhTien = pageData.reduce((sum, record) => sum + (record.thanhtien || 0), 0)
          const totalThucTra = pageData.reduce((sum, record) => sum + (record.thuctra || 0), 0)

          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                {Array.from({ length: 39 }, (_, index) => (
                  <Table.Summary.Cell key={index} index={index} align="right">
                    {index === 16 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalDonGiaLe)}
                      </Typography.Text>
                    )}
                    {index === 12 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalDonGiaChan)}
                      </Typography.Text>
                    )}
                    {index === 10 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalSoLuongChan)}
                      </Typography.Text>
                    )}
                    {index === 25 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalGiaCong)}
                      </Typography.Text>
                    )}
                    {index === 26 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalVanChuyen)}
                      </Typography.Text>
                    )}
                    {index === 14 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalSoLuongLe)}
                      </Typography.Text>
                    )}{' '}
                    {index === 24 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalTongTien)}
                      </Typography.Text>
                    )}
                    {index === 29 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalTongTienTrVAT)}
                      </Typography.Text>
                    )}
                    {index === 28 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalTienCK)}
                      </Typography.Text>
                    )}
                    {index === 31 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalTienVAT)}
                      </Typography.Text>
                    )}
                    {index === 32 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalThanhTien)}
                      </Typography.Text>
                    )}
                    {index === 33 && (
                      <Typography.Text type="danger">
                        {formatNumberVND(totalThucTra)}
                      </Typography.Text>
                    )}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            </Table.Summary>
          )
        }}
      />
    </ConfigProvider>
  )
}

export default TableHang

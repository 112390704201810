import JsBarcode from 'jsbarcode'
import moment from 'moment/moment'
import { generateLogo, generateThuVietNamese } from '../algorithm'
import { calculateAgeForReport } from '../calculateAge'
import { splitLongWord } from '../splitWordForReport'

export const generatePhieuTuVanThuocNgoai = (doc, infoBn, benhNhanTTC, toaThuoc, PkDangNhap) => {
  let yPos = 10
  let xPos = 8
  let ruleWidth = 55 // for text of price is longer
  const pageWidth = doc.internal.pageSize.width
  //header
  generateLogo(doc, xPos, PkDangNhap)

  JsBarcode('#barcode', `TT${benhNhanTTC.idbnttc}`, {
    displayValue: false,
  })
  const canvas = document.getElementById('barcode')
  const barcodeDataURL = canvas.toDataURL()
  const barcodeWidth = 35 // Width of the barcode image
  const barcodeX = pageWidth - 45 // X coordinate of the barcode
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 45, 5, 35, 14)
  // Calculate the center position for text
  const text = `TT${benhNhanTTC.idbnttc}`
  const textWidth = (doc.getStringUnitWidth(text) * 11) / doc.internal.scaleFactor // Width of the text
  const textX = barcodeX + (barcodeWidth - textWidth) / 2 // Centered X coordinate for the text
  doc.setFontSize(10)
  doc.text(text, textX, 22)

  doc.setFont('times', 'bold')
  doc.setFontSize(14)
  doc.text('PHIẾU TƯ VẤN SẢN PHẨM HỖ TRỢ ĐIỀU TRỊ', doc.internal.pageSize.width / 2, 30, {
    align: 'center',
  })
  let textWidthName = doc.getTextDimensions('Họ và tên: ').w
  doc.setFontSize(11)
  doc.text(benhNhanTTC.benhNhan.tenBenhNhan.toUpperCase(), textWidthName, 35)
  doc.setFont('times', 'regular')
  doc.setFontSize(10)
  doc.text('Họ và tên: ', xPos, 35)
  let textWidthGioiTinh = doc.getTextDimensions('Giới tính: ').w
  doc.text('Giới tính:', pageWidth - 50, 35)
  doc.setFont('times', 'bold')
  doc.text(benhNhanTTC.benhNhan.gioiTinh, pageWidth - 50 + textWidthGioiTinh, 35)
  doc.setFont('times', 'regular')
  doc.text(`Ngày sinh: ${moment(benhNhanTTC.benhNhan.ngaySinh).format('DD/MM/YYYY')}`, xPos, 40)
  doc.text(`Tuổi: ${calculateAgeForReport(benhNhanTTC.benhNhan.ngaySinh)}`, pageWidth - 80, 40)
  //dịa chỉ
  const diaChiBenhNhan = `Địa chỉ: ${infoBn.diaChi ?? ''}${infoBn.tenPhuongXa ? ', ' + infoBn.tenPhuongXa : ''}${infoBn.tenQuanHuyen ? ', ' + infoBn.tenQuanHuyen : ''}${infoBn.tenTinhTP ? ', ' + infoBn.tenTinhTP : ''}`
  if (diaChiBenhNhan.length > 80) {
    const wordsArray = splitLongWord(diaChiBenhNhan, 80)
    doc.text(wordsArray[0], xPos, 45)
    doc.text(wordsArray[1], xPos, 50)
    yPos = 50
  } else {
    doc.text(diaChiBenhNhan, xPos, 45)
    yPos = 45
  }
  doc.setFont('times', 'regular')
  yPos += 5
  //chẩn đoán
  if (`Chẩn đoán: ${benhNhanTTC.chandoan ?? ''}`.length > 80) {
    const wordsArray = splitLongWord(`Chẩn đoán: ${benhNhanTTC.chandoan ?? ''}`, 80)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
  } else {
    doc.text(`Chẩn đoán: ${benhNhanTTC.chandoan ?? ''}`, xPos, yPos)
    yPos += 5
  }
  // Add a line
  doc.line(xPos, yPos, pageWidth - 10, yPos)
  yPos += 5
  doc.setFontSize(9)
  for (let i = 0; i < toaThuoc.length; i++) {
    // Check if content exceeds the page height
    if (yPos > 200) {
      doc.addPage() // Add a new page
      yPos = 10 // Reset yPos for new page
    }

    //chia 2 cột
    doc.setFont('times', 'bold')
    //check text dài
    doc.text(`${toaThuoc[i].soLuong} ${toaThuoc[i].dvt}`, pageWidth - 25, yPos)
    if (`${i + 1}. ${toaThuoc[i].tenHoatChat || toaThuoc[i].tenhoatchat}`.length > 65) {
      const wordsArray = splitLongWord(
        `${i + 1}. ${toaThuoc[i].tenHoatChat || toaThuoc[i].tenhoatchat}`,
        65,
      )
      for (let j = 0; j < wordsArray.length; j++) {
        doc.text(`${wordsArray[j]}`, xPos, yPos)
        yPos += 4
      }
    } else {
      doc.text(`${i + 1}. ${toaThuoc[i].tenHoatChat || toaThuoc[i].tenhoatchat}`, xPos, yPos)
      yPos += 4
    }
    doc.setFont('times', 'italic')
    //check text cách dùng dài
    if (
      `Mỗi ngày ${toaThuoc[i].duongDung ?? ''} ${toaThuoc[i].soLanNgay} lần, một lần ${toaThuoc[i].lieuDung} ${toaThuoc[i].donvidung} ${toaThuoc[i].ghiChu ? `(${toaThuoc[i].ghiChu})` : ''}`
        .length > 65
    ) {
      const wordsArray = splitLongWord(
        `Một ngày ${toaThuoc[i].duongDung ?? ''} ${toaThuoc[i].soLanNgay} lần, một lần ${toaThuoc[i].lieuDung} ${toaThuoc[i].donvidung} ${toaThuoc[i].ghiChu ? `(${toaThuoc[i].ghiChu})` : ''}`,
        65,
      )
      for (let j = 0; j < wordsArray.length; j++) {
        doc.text(`${wordsArray[j]}`, xPos, yPos)
        yPos += 4
      }
    } else {
      doc.text(
        `Một ngày ${toaThuoc[i].duongDung ?? ''} ${toaThuoc[i].soLanNgay} lần, một lần ${toaThuoc[i].lieuDung} ${toaThuoc[i].donvidung} ${toaThuoc[i].ghiChu ? `(${toaThuoc[i].ghiChu})` : ''}`,
        xPos,
        yPos,
      )
      yPos += 4
    }
    yPos += 1
  }
  yPos = yPos - 5

  if (doc.internal.pageSize.height - yPos < 80) {
    doc.addPage()
    yPos = 10
  }

  yPos += 5
  doc.line(xPos, yPos, pageWidth - 10, yPos)
  yPos += 5
  doc.setFont('times', 'bold')
  const timeWidth = doc.getTextDimensions('Chủ nhật - 03/03/2024').w
  doc.text(
    `${generateThuVietNamese(benhNhanTTC.ngaytaikham)} - ${moment(benhNhanTTC.ngaytaikham).format('DD/MM/YYYY')}`,
    timeWidth,
    yPos,
  )
  doc.setFont('times', 'regular')

  doc.text('Ngày tái khám: ', xPos, yPos)
  const dateText = `Ngày ${moment(benhNhanTTC.ngaykham).format('DD')} tháng ${moment(benhNhanTTC.ngaykham).format('MM')} năm ${moment(benhNhanTTC.ngaykham).format('YYYY')}`
  const doctorText = benhNhanTTC.bskham ?? ''
  const dateTextWidth = doc.getTextWidth(dateText)
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateTextX = pageWidth - dateTextWidth - 15 // 15 is the padding from the right edge

  // Calculate the center point of the date text
  const dateCenterX = dateTextX + dateTextWidth / 2

  // Position the doctor text centered below the date text
  const doctorTextX = dateCenterX - doctorTextWidth / 2

  doc.text(dateText, dateTextX, yPos)
  yPos += 5

  doc.text('Lời dặn:', xPos, yPos)
  doc.text('Bác sĩ khám bệnh', pageWidth - 46, yPos)
  doc.setFont('times', 'bold')
  doc.text(doctorText, doctorTextX, yPos + 20)

  yPos += 4
  //lời dặn
  doc.setFont('times', 'regular')
  if (`- ${benhNhanTTC.loidan ?? ''}`.length > 45) {
    const wordsArray = splitLongWord(`- ${benhNhanTTC.loidan ?? ''}`, 50)
    for (let j = 0; j < wordsArray.length; j++) {
      doc.text(`${wordsArray[j]}`, xPos, yPos)
      yPos += 3.5
    }
    yPos += 1
  } else {
    doc.text(`- ${benhNhanTTC.loidan ?? ''}`, xPos, yPos)
    yPos += 4.5
  }

  //tái khám miễn phí
  // if (PkDangNhap.idChuyenKhoa === 1) {
  //   doc.setFont('times', 'bold')
  //   const defaultLoiDanTaiKham =
  //     '-Tái khám miễn phí khi đúng hẹn tối đa 3 lần trong cùng 1 đợt bệnh'
  //   const wordsArrayTaiKham = splitLongWord(defaultLoiDanTaiKham, 50)
  //   for (let j = 0; j < wordsArrayTaiKham.length; j++) {
  //     doc.text(`${wordsArrayTaiKham[j]}`, xPos, yPos)
  //     yPos += 5
  //   }
  // }

  doc.setFont('times', 'italic')
  //lời dặn default

  const defaultLoiDan =
    '-Toa thuốc chỉ có giá trị tại thời điểm bác sĩ chẩn đoán bệnh. Chúng tôi miễn trừ trách nhiệm trong trường hợp toa thuốc khách hàng sử dụng không ngay tại thời điểm chẩn đoán bệnh'
  const wordsArray = splitLongWord(defaultLoiDan, 50)
  for (let j = 0; j < wordsArray.length; j++) {
    doc.text(`${wordsArray[j]}`, xPos, yPos)
    yPos += 3.5
  }
  yPos += 3.5
  doc.line(xPos, yPos - 4, pageWidth - 10, yPos - 4)
  doc.setFontSize(8)
  doc.setFont('times', 'italic')
  doc.text('(*) Mang theo toa thuốc này khi tái khám.', xPos, yPos)
  // doc.save('phieuToaThuoc.pdf')
  doc.text(
    `(*)Ngày in phiếu: ${moment().format('DD/MM/YYYY HH:mm:ss')}`,
    pageWidth - 55,
    doc.internal.pageSize.height - 5,
  )

  // const blob = doc.output('blob')
  // const url = URL.createObjectURL(blob)
  // const printWindow = window.open(url)
  // if (printWindow) {
  //   printWindow.addEventListener(
  //     'load',
  //     function () {
  //       printWindow.print()
  //     },
  //     true,
  //   )
  // }
}

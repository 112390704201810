import { Input, Modal, Select, Tag } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createDanhMucNhan,
  getAllNhanDanhMuc,
  getDanhMucByNhan,
} from '../../../store/actions/suCoAction'
import { CreateDanhMucNhanSchema } from '../../../schemas/CreateDanhMucNhanSchema'

const CreateDanhMuc = ({ openAdDM, setOpenAddDM, active, keywordDanhMuc }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const [nhan, setNhan] = useState([])
  const dispatch = useDispatch()
  const handleCancel = () => {
    setOpenAddDM(false)
  }
  const handleSubmit = (value) => {
    dispatch(createDanhMucNhan(value, onReset))
  }
  const onChangeSelected = (value) => {
    formik.setFieldValue('idnhan', value)
  }

  const onReset = () => {
    formik.resetForm()
    handleCancel()
    dispatch(getDanhMucByNhan(active, keywordDanhMuc))
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idnhan: active,
      tendanhmuc: '',
      nguoitao: infoUser?.idnv,
    },
    validationSchema: CreateDanhMucNhanSchema,
    onSubmit: (value) => handleSubmit(value),
  })
  useEffect(() => {
    dispatch(getAllNhanDanhMuc(setNhan))
  }, [])
  return (
    <Modal
      onOk={formik.handleSubmit}
      okText="Tạo"
      cancelText="Huỷ bỏ"
      open={openAdDM}
      onCancel={handleCancel}
      title={
        <p className="font-semibold text-xl w-full text-center text-blue-500">TẠO DANH MỤC NHÃN</p>
      }
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
            Tên danh mục <span className="text-red-500">(*)</span>
          </label>
          <Input
            name="tendanhmuc"
            onChange={formik.handleChange}
            status={formik.errors.tendanhmuc && formik.touched.tendanhmuc ? 'error' : null}
            value={formik.values.tendanhmuc}
          />
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
            Nhãn <span className="text-red-500">(*)</span>
          </label>
          <Select
            showSearch
            filterOption={(input, option) => {
              const name = option?.name ?? ''
              const searchText = input.toLowerCase()
              return name.toLowerCase().includes(searchText)
            }}
            placeholder="Chọn loại nhãn"
            onChange={onChangeSelected}
            value={formik.values.idnhan}
            options={nhan?.map((item) => ({
              label: (
                <div className="flex justify-between ">
                  <p> {item.tennhan}</p>
                  <span
                    className={`font-semibold ${item.idnhom === 27 ? 'text-blue-500' : item.idnhom === 270 ? 'text-green-700' : 'text-cyan-500'}`}
                  >
                    {item.nhom}
                  </span>
                </div>
              ),
              name: item.tennhan,
              value: item.idnhan,
            }))}
            className="w-full"
            name="nhom"
            status={formik.errors.idnhan && formik.touched.idnhan ? 'error' : null}
          />
        </div>
      </div>
    </Modal>
  )
}

export default CreateDanhMuc

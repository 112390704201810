import { Button, Input } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { formattedNumber } from "../../../../utils/formattedNumber";

const Info = ({ showModal }) => {
  const { BNPaymentCard, infoPaymentCard } = useSelector(
    (state) => state.cartReducer
  );
  return (
    <div className="flex h-full">
      <div className="w-3/4 border-r-2 pr-2 h-full flex flex-col gap-2">
        <div className="flex gap-2">
          <div className="flex w-1/2 gap-1">
            <label className="w-1/3 font-semibold text-end">
              Tên bệnh nhân:
            </label>
            <Input
              value={BNPaymentCard?.tenBenhNhan}
              size="small"
              className="w-2/3"
              disabled
              style={{ color: "black" }}
            />
          </div>
          <div className="flex w-1/2 gap-1">
            <label className="w-1/3 font-semibold text-end">
              Mã bệnh nhân:
            </label>
            <Input
              value={BNPaymentCard?.maBenhNhan}
              size="small"
              className="w-2/3"
              disabled
              style={{ color: "black" }}
            />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex w-1/2 gap-1">
            <label className="w-1/3 font-semibold text-end">Ngày sinh:</label>
            <Input
              value={BNPaymentCard?.ngaySinh && moment(BNPaymentCard?.ngaySinh).format("DD/MM/YYYY")}
              size="small"
              className="w-2/3"
              disabled
              style={{ color: "black" }}
            />
          </div>
          <div className="flex w-1/2 gap-1">
            <label className="w-1/3 font-semibold text-end">Giới tính:</label>
            <Input
              value={BNPaymentCard?.gioiTinh}
              size="small"
              className="w-2/3"
              disabled
              style={{ color: "black" }}
            />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex w-1/2 gap-1">
            <label className="w-1/3 font-semibold text-end">Dân tộc:</label>
            <Input
              value={BNPaymentCard?.tenDanToc}
              size="small"
              className="w-2/3"
              disabled
              style={{ color: "black" }}
            />
          </div>
          <div className="flex w-1/2 gap-1">
            <label className="w-1/3 font-semibold text-end">Quốc tịch:</label>
            <Input
              value={BNPaymentCard?.tenQuocTich}
              size="small"
              className="w-2/3"
              disabled
              style={{ color: "black" }}
            />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex w-1/2 gap-1">
            <label className="w-1/3 font-semibold text-end">Đối tượng:</label>
            <Input
              value={BNPaymentCard?.tenDoiTuong}
              size="small"
              className="w-2/3"
              disabled
              style={{ color: "black" }}
            />
          </div>
          <div className="flex w-1/2 gap-1">
            <label className="w-1/3 font-semibold text-end">Nghề nghiệp:</label>
            <Input
              value={BNPaymentCard?.tenNgheNghiep}
              size="small"
              className="w-2/3"
              disabled
              style={{ color: "black" }}
            />
          </div>
        </div>
        <div className="flex  gap-1">
          <label className="w-1/6 font-semibold text-end">Địa chỉ:</label>
          <Input
            value={BNPaymentCard?.diaChi}
            size="small"
            className="w-5/6"
            disabled
            style={{ color: "black" }}
          />
        </div>
      </div>
      <div className="w-1/4 p-2 flex flex-col justify-between">
        <div>
          <h2 className="text-base font-semibold text-gray-500">SỐ DƯ </h2>
          <p className="text-lg font-semibold text-green-700">{ infoPaymentCard ?formattedNumber(infoPaymentCard?.tienVi) : 0} VNĐ</p>
        </div>
        <div>
          <h2 className="text-gray-500">Ngày đăng kí: {moment(infoPaymentCard?.ngayDangKY).format('DD/MM/YYYY HH:mm:ss')} </h2>
          <h2 className="text-gray-500">Nhân viên tạo: {infoPaymentCard?.nguoiTao}</h2>
        </div>
        <Button
          onClick={showModal}
          disabled={infoPaymentCard === null || BNPaymentCard === null}
          type="primary"
        >
          Nạp thẻ
        </Button>
      </div>
    </div>
  );
};

export default Info;

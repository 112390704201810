import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  ListBNChoKham: null,
  ListBNDangKham: null,
  ListBNDaKham: null,
  InfoBNKhamSangLoc: null,
  listTonVacXin: null,
  lichSuTiemChung: null,
}

const khamSangLocReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_BN_CHO_KHAM_SANG_LOC:
        draft.ListBNChoKham = payload
        break
      case typeAction.DISPATCH_BN_DANG_KHAM_SANG_LOC:
        draft.ListBNDangKham = payload
        break
      case typeAction.DISPATCH_BN_DA_KHAM_SANG_LOC:
        draft.ListBNDaKham = payload
        break
      case typeAction.DISPATCH_INFO_BN_KSL:
        draft.InfoBNKhamSangLoc = payload
        break
      case typeAction.DISPATCH_LIST_TON_VAC_XIN_KSL:
        draft.listTonVacXin = payload
        break
      case typeAction.DISPATCH_LICHSU_TIEMCHUNG_KSL:
        draft.lichSuTiemChung = payload
        break
      default:
        return state
    }
  })
}

export default khamSangLocReducer

import axios from 'axios'
import React from 'react'
import { call, delay, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { authService } from '../../services/auth/authService'
import Swal from 'sweetalert2'
import { branchService } from '../../services/branch/branchService'
import { menuService } from '../../services/menu/menuService'
import moment from 'moment'
import { generateUUID } from '../../utils/generateUUID'
import { recieveService } from '../../services/receive/recieveService'
import 'moment/locale/vi'
moment.locale('vi')

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* customerSaga() {
  //------------- LẤY DANH SÁCH BỆNH NHÂNH ----------/
  yield takeLatest(
    typeAction.GET_CUSTOMER_BY_KEYWORD,
    function* fetchCustomerByKeyword({ type, keyword, idct, setIsLoading }) {
      try {
        setIsLoading(true)
        const result = yield call(() => recieveService.searchBNByIdctAndKeyword(keyword, idct))
        yield put({
          type: typeAction.DISPATCH_CUSTOMER,
          payload: result.data,
        })
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        console.log(err)
      }
    },
  )
  //----------- TẠO  HỒ SƠ BỆNH NHÂN MỚI----------
  yield takeLatest(typeAction.ADD_NEW_CUSTOMER, function* addNew({ type, form, cancelModal }) {
    const now = moment()
    try {
      form.NgayTao = now.format('YYYY-MM-DDTHH:mm:ss')
      const result = yield call(() => recieveService.postNewBN(form))
      cancelModal()
      Toast.fire({
        icon: 'success',
        title: 'Tạo bệnh nhân thành công!',
      })
    } catch (err) {
      yield console.log(err)
      Toast.fire({
        icon: 'error',
        title: 'Tạo bệnh nhân thất bại!',
      })
    }
  })

  yield takeLatest(typeAction.GET_CUSTOMER_BY_IDCT, function* getByIdct({ type, idct }) {
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      const { data } = yield call(() => recieveService.getBNbyIdct(idct))
      yield put({
        type: typeAction.DISPATCH_CUSTOMER,
        payload: data,
      })
    } catch (err) {
      yield console.log(err)
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })
}

import { IconButton } from '@mui/material'
import {
  Button,
  Checkbox,
  ConfigProvider,
  DatePicker,
  Divider,
  Drawer,
  Input,
  Select,
  Space,
  Table,
} from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import CachedIcon from '@mui/icons-material/Cached'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import { editLienHeCRM } from '../../../../store/actions/lienheCRMAction'
import ToastCus from '../../../../utils/Toast'
import { trangThais } from '../../../../utils/trangthaiCRM'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const formatMoment = 'YYYY-MM-DD'
const dateFormat = 'DD-MM-YYYY'
const DrawerChiTiet = ({ showChitiet, setShowChitiet, onLoad }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoLienHe } = useSelector((state) => state.lienheCRMReducer)
  const [allCongTy, setAllCongTy] = useState([])
  const [dateFrom, setDateFrom] = useState(now.format(formatMoment))
  const [dateTo, setDateTo] = useState(now.format(formatMoment))
  const [rowActive, setRowActive] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [dataCTLH, setDataCTLH] = useState([])
  const searchInput = useRef(null)
  const dispatch = useDispatch()

  const handleClickRow = (row) => {
    const formatData = {
      ...row,
      filebaogia: row.filebaogia
        ? row.filebaogia
            .split(';')
            .filter((item) => item !== '' && item !== null)
            .map((item) => ({
              url: item,
              name: item.split('/').pop().split(';')[0],
            }))
        : [],
    }
    setRowActive(formatData)
  }

  const rowClassName = (record) => {
    return record.idctlh === rowActive?.idctlh ? 'bg-cyan-300 bg-opacity-50' : ''
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columns = [
    {
      title: 'Nhân viên gọi',
      dataIndex: 'tennhanvien',
      align: 'left',
      key: 'tennhanvien',
      className: 'text-sm',
      width: 250,
      ...getColumnSearchProps('tennhanvien'),
    },
    {
      title: 'Ngày gọi',
      dataIndex: 'ngaygoi',
      key: 'ngaygoi',
      align: 'center',
      className: 'text-sm',
      width: 160,
      render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : ''),
    },
    {
      title: 'Ngày báo giá',
      dataIndex: 'ngaybaogia',
      key: 'ngaybaogia',
      align: 'center',
      className: 'text-sm',
      width: 160,
      render: (text, record) => {
        if (record.filebaogia) {
          return moment(text).format('DD/MM/YYYY HH:mm:ss')
        }
        return ''
      },
    },
    {
      title: 'Tiền báo giá',
      dataIndex: 'tienbaogia',
      key: 'tienbaogia',
      align: 'right',
      className: 'text-sm',
      width: 100,
      render: (text) => formatNumberVND(text),
    },
    {
      title: 'Báo giá',
      dataIndex: 'ngaybaogia',
      key: 'ngaybaogia',
      align: 'center',
      className: 'text-sm',
      width: 80,
      render: (text, record, index) => <Checkbox checked={record.daguibaogia === true} />,
    },

    {
      title: 'Trạng thái',
      dataIndex: 'trangthai',
      key: 'trangthai',
      align: 'center',
      className: 'text-sm',
      width: 150,
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      align: 'right',
      className: 'text-sm',
    },
  ]

  const handleSubmit = (value) => {
    dispatch(editLienHeCRM(value, onLoad))
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idttlh: infoLienHe?.idttlh,
      idct: infoLienHe?.idct,
      nguoilienhe: infoLienHe?.nguoilienhe,
      dienthoailienhe: infoLienHe?.dienthoailienhe,
      emaillienhe: infoLienHe?.emaillienhe,
      ghichu: infoLienHe?.ghichu,
      nguoisua: infoUser?.idnv,
      sudung: infoLienHe?.sudung,
      trangthai: infoLienHe?.trangthai,
    },
    onSubmit: (value) => handleSubmit(value),
    // validationSchema: createEditDVSchema,
  })

  const ChangeSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }

  useEffect(() => {
    const getAllCongTy = async () => {
      try {
        const { data } = await https.get('CRMCongTy/GetAllCRMCongTy')
        setAllCongTy(data.map((item) => ({ value: item.idct, label: item.tenct })))
      } catch (error) {
        console.log(error)
      }
    }
    getAllCongTy()
  }, [])

  const orderMap = trangThais.reduce((acc, item) => {
    acc[item.label] = item.stt // Map label to its order
    return acc
  }, {})

  useEffect(() => {
    if(!infoLienHe) return
    ;(async () => {
      try {
        const { data } = await https.get(
          `CRMChiTietLienHe/GetCRMChiTietLienHe/${infoLienHe?.idttlh}`,
        )
        const sortedData = data.sort((a, b) => {
          const orderA = orderMap[a.trangthai] ?? -1 // Default to -1 if trangthai is null
          const orderB = orderMap[b.trangthai] ?? -1 // Default to -1 if trangthai is null
          if (orderA === orderB) {
            // If statuses are the same, sort by ngaylienhe
            const dateA = new Date(a.ngaylienhe || 0) // Default to 0 if ngaylienhe is null
            const dateB = new Date(b.ngaylienhe || 0) // Default to 0 if ngaylienhe is null
            return dateA - dateB // Sort by ngaylienhe
          }

          return orderA - orderB // Sort by the order
        })
        setDataCTLH(sortedData)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [infoLienHe])

  const handleDownloadFile = async (item) => {
    try {
      const response = await fetch(item.url)

      if (!response.ok) {
        ToastCus.fire({
          icon: 'error',
          text: 'Không thể tải xuống file!',
        })
        throw new Error('Network response was not ok')
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.href = url
      link.download = item.name

      document.body.appendChild(link)

      link.click()

      // Clean up and remove the link
      link.remove()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading the file:', error)
    }
  }

  const handleClose = () => {
    setShowChitiet(false)
    setRowActive(null)
  }

  return (
    <Drawer
      title={'Chi tiết liên hệ'}
      placement="right"
      size="large"
      width={'75%'}
      onClose={handleClose}
      open={showChitiet}
    >
      <div>
        <div>
          <h2 className="text-gray-500 font-semibold text-lg mb-2 ">Thông tin liên hệ</h2>
          <div className="flex flex-col gap-5">
            <div className="flex">
              <div className="flex items-center ">
                <label className="font-semibold w-32">Công ty:</label>
                <Select
                  showSearch
                  value={formik.values.idct}
                  options={allCongTy?.map(({ value, label }) => ({
                    label: label,
                    value: value,
                  }))}
                  className="w-64"
                  onChange={ChangeSelected('idct')}
                  disabled
                />
              </div>
            </div>
            <div className="flex">
              <div className="flex  items-center ">
                <label className="font-semibold w-32">Họ và Tên :</label>
                <Input
                  value={formik.values.nguoilienhe}
                  name="nguoilienhe"
                  className="w-64"
                  readOnly
                />
              </div>
            </div>
            <div className="flex">
              <div className="flex  items-center ">
                <label className="font-semibold w-32">SĐT :</label>
                <Input
                  value={formik.values.dienthoailienhe}
                  name="dienthoailienhe"
                  className="w-64"
                  readOnly
                />
              </div>
            </div>
            <div className="flex">
              <div className="flex items-center ">
                <label className="font-semibold w-32">Email:</label>
                <Input
                  value={formik.values.emaillienhe}
                  name="emaillienhe"
                  className="w-64"
                  readOnly
                />
              </div>
            </div>
            <div className="flex">
              <div className="flex  items-center ">
                <label className="font-semibold w-32">Ghi chú :</label>
                <Input value={formik.values.ghichu} name="ghichu" className="w-64" readOnly />
              </div>
            </div>
            <div className="flex">
              <div className="flex  items-center ">
                <label className="font-semibold w-32">Sử dụng :</label>
                <Checkbox checked={formik.values.sudung} />
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="mt-2">
          <div className="w-full flex">
            <div className="w-3/4">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                  },
                  components: {
                    Table: {
                      rowHoverBg: '#ecf0f1',
                      headerBg: '#e6e6e6',
                      footerBg: '#e6e6e6',
                      borderColor: '#BABABA',
                    },
                  },
          
                }}
              >
                <Table
                  columns={columns}
                  dataSource={dataCTLH}
                  onRow={(row) => ({ onClick: () => handleClickRow(row) })}
                  rowClassName={rowClassName}
                />
              </ConfigProvider>
            </div>
            <div className="w-1/4 pl-5 h-full">
              <h2 className="text-gray-500 font-semibold text-lg mb-2 ">File báo giá:</h2>
              <ul className="list-disc list-inside">
                {rowActive &&
                  rowActive?.filebaogia?.map((file, index) => (
                    <p
                      onClick={() => handleDownloadFile(file)}
                      className="text-blue-500 hover:underline cursor-pointer mb-2"
                    >
                      {file.name}
                    </p>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default DrawerChiTiet

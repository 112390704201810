import { Input, InputNumber, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addNewDMXetNghiemAction } from '../../../../store/actions/xetNghiemAction'
import { https } from '../../../../services/apiService'
const ModalAdd = ({ isModalOpen, handleCancel, onLoad }) => {
  const { infoXetNghiem, groupsXetNghiem } = useSelector((state) => state.xetNghiemReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [allDoiTacXN, setAllDoiTacXN] = useState([])

  const dispatch = useDispatch()
  // xử lí form thêm dịch vụ xét nghiệm
  const handleSubmit = (value) => {
    dispatch(addNewDMXetNghiemAction(value, onLoad))
    formik.resetForm()
    handleCancel()
  }
  const formik = useFormik({
    initialValues: {
      maxn: '',
      tenxn: '',
      tenviettat: '',
      ghichu: '',
      idnhomxn: 1,
      dongia: 0,
      mamic: '',
      matvd: '',
      madiag: '',
      idct: 1, // 1 lặt định là công ty y tế chấn văn
      dvt: 'Lần',
      tienguimau: 0,
      iddoitacxn: '',
    },
    onSubmit: (value) => handleSubmit(value),
  })

  const getDoiTacXN = async () => {
    try {
      const { data } = await https.get('DoiTacXetNghiem/GetAllDoiTacXetNghiem')
      setAllDoiTacXN(data)
    } catch (error) {}
  }

  useEffect(() => {
    getDoiTacXN()
  }, [])

  return (
    <>
      <Modal
        okText="Thêm"
        cancelText="Huỷ"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Tạo loại xét nghiệm</h2>}
        onOk={formik.handleSubmit}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">Mã xét nghiệm </label>
            <Input
              value={formik.values.maxn}
              name="maxn"
              onChange={formik.handleChange}
              placeholder="Nhập mã xét nghiệm"
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Tên xét nghiệm </label>
            <Input
              value={formik.values.tenxn}
              name="tenxn"
              onChange={formik.handleChange}
              placeholder="Nhập tên xét nghiệm"
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Tên viết tắt </label>
            <Input
              value={formik.values.tenviettat}
              name="tenviettat"
              onChange={formik.handleChange}
              placeholder="Nhập tên viết tắc"
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold  text-base">Nhóm xét nghiệm </label>
            <Select
              value={formik.values.idnhomxn}
              options={groupsXetNghiem?.map((items) => ({
                label: items.tennhom,
                value: items.idnhomxn,
              }))}
              onChange={(value) => formik.setFieldValue('idnhomxn', value)}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold  text-base">Công ty </label>
            <Select
              value={formik.values.idct}
              options={listCompany?.map((items) => ({
                label: items.tenct,
                value: items.idct,
              }))}
              onChange={(value) => formik.setFieldValue('idct', value)}
            />
          </div>
          <div className="flex gap-5">
            <div className="w-full">
              <label className="font-semibold  text-base">Đối tác xét nghiệm </label>
              <Select
                value={formik.values.iddoitacxn}
                options={allDoiTacXN?.map((items) => ({
                  label: items.tendoitac,
                  value: items.iddtxn,
                }))}
                onChange={(value) => formik.setFieldValue('iddoitacxn', value)}
                className='w-full'
              />
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-full">
              <label className="font-semibold  text-base">Đơn vị tính </label>
              <Input value={formik.values.dvt} onChange={formik.handleChange} name="dvt" />
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-1/2">
              <label className="font-semibold  text-base">Đơn giá </label>
              <InputNumber
                onChange={(value) => formik.setFieldValue('dongia', value)}
                name="dongia"
                className="w-full"
                value={formik.values.dongia}
                step="0.001"
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                max={999999999}
              />
            </div>
            <div className="w-1/2">
              <label className="font-semibold  text-base">Tiền gửi mẫu </label>
              <InputNumber
                onChange={(value) => formik.setFieldValue('tienguimau', value)}
                status={formik.errors.tienguimau ? 'error' : ''}
                value={formik.values.tienguimau}
                name="tienguimau"
                className="w-full"
                step="0.001"
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                max={999999999}
              />
            </div>
          </div>

          <div>
            <label className="font-semibold  text-base">Ghi chú </label>
            <Input value={formik.values.ghichu} onChange={formik.handleChange} name="ghichu" />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalAdd

import { Input, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  createVungBaoHong } from '../../../store/actions/suCoAction'
import { addKhuSchema } from '../../../schemas/addKhuSchema'
const CreateVung = ({fetchKhu, openAdd, setOpenAdd,fetchVung }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const handleCancel = () => {
    setOpenAdd(false)
  }
  const handleSubmit = (value) => {
    dispatch(createVungBaoHong(value,onReset))
  }
  const onReset = () => {
    formik.resetForm()
    handleCancel()
    fetchKhu()
    fetchVung()
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tenvung: '',
      nguoitao: infoUser?.idnv,
    },
    validationSchema: addKhuSchema,
    onSubmit: (value) => handleSubmit(value),
  })
  return (
    <Modal
      onOk={formik.handleSubmit}
      okText="Tạo"
      cancelText="Huỷ bỏ"
      open={openAdd}
      onCancel={handleCancel}
      title={<p className="font-semibold text-xl w-full text-center text-blue-500">TẠO KHU VỰC</p>}
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
           Tên khu vưc:<span className="text-red-500">(*)</span>
          </label>
          <Input
            name="tenvung"
            onChange={formik.handleChange}
            status={formik.errors.tenvung && formik.touched.tenvung ? 'error' : null}
            value={formik.values.tenvung}
          />
        </div>
      </div>
    </Modal>
  )
}

export default CreateVung

import { https } from '../apiService'
export const toolService = {
  updatePay: (idLoaiTT, maPhieu, date) =>
    https.put(`CongCu/updateNgayBanToa?idLoaiTT=${idLoaiTT}&maPhieu=${maPhieu}&date=${date}`),
  updateTTV: (form) => https.put('CongCu/UpdateThe', form),
  getAllUuDaiTK: () => https.get(`UuDaiTaiKham/GetAllUuDaiTK`),
  putUuDaiTK: (form) => https.put('UuDaiTaiKham/UpdateUuDaiTK', form),
  getDichVu: (idct, keyword) =>
    https.get(`DichVu/SearchDichVuByIDCT50?idct=${idct}&keyword=${keyword}`),
  postUuDaiTK: (form) => https.post('UuDaiTaiKham/ThemUuDai', form),
  deleteUuDai: (id) => https.delete(`UuDaiTaiKham/DeleteUuDaiTaiKham?IDUDTK=${id}`),
  putGopBenhAn: (idbn, idbndelete) =>
    https.put(`CongCu/GopBenhAn?idbn=${idbn}&idbndelete=${idbndelete}`),
  putTaiKham: (form) =>
    https.put(
      `CongCu/UpdateNgayTaiKham?maphieuthu=${form.maphieuthu}&ngaytaikham=${form.ngaytaikham}&nguoisua=${form.nguoisua}`,
    ),
  putCaLamViecCuaPhieuThu: (maPhieuThu, idCaLamViec, idLoaiTT, idNguoiSua) =>
    https.put(
      `CongCu/UpdateCaPhieuThu?maPhieuThu=${maPhieuThu}&idCaLamViec=${idCaLamViec}&idLoaiTT=${idLoaiTT}&idNguoiSua=${idNguoiSua}`,
    ),
  putLoaiKhamCuaPhieuThu: (maPhieuThu, idLoaiKham, idNguoiSua) =>
    https.put(
      `CongCu/UpdateLoaiKham?maPhieuThu=${maPhieuThu}&idLoaiKham=${idLoaiKham}&idNguoiSua=${idNguoiSua}`,
    ),
  putNguoiBanTTV: (maPhieuThu, idNguoiBan, idNguoiTao) =>
    https.put(
      `CongCu/UpdateNguoiBanTTV?maPhieuThu=${maPhieuThu}${idNguoiBan ? `&idNguoiBan=${idNguoiBan}` : ''}${idNguoiTao ? `&idNguoiTao=${idNguoiTao}` : ''}`,
    ),
  deletePhieuKho: (maPhieu) => https.delete(`CongCu/DetelePhieuKho?maPhieuKho=${maPhieu}`)
}

import React, { useEffect, useState, useCallback } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import _ from 'lodash'
import { Card, Col, Row, Select, Input, Button, Tabs, Modal, InputNumber } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { fetchListTypesCard_Action, postLoaiTheAction } from '../../../store/actions/cardAction'
import { formattedNumber } from '../../../utils/formattedNumber'
import { useNavigate } from 'react-router-dom'
import { khoVTservice } from '../../../services/khoVT/khoVTservice'
import { cardService } from '../../../services/card/cardService'
const CardMemberShips = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { lisTypesCard, chuyenKhoa } = useSelector((state) => state.cartReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [idChuyenKhoa, setIdChuyenKhoa] = useState(null)
  const [isModalAdd, setIsModalAdd] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [listChuyenKhoa, setListChuyenKhoa] = useState([])
  const [listNhomThe, setListNhomThe] = useState([])
  const [keyword, setKeyword] = useState(null)
  const listCapThe = [
    {
      key: 1,
      value: 1,
      label: 'Cấp 1 - thẻ thường',
    },
    {
      key: 2,
      value: 2,
      label: 'Cấp 2 - thẻ bạc',
    },
    {
      key: 3,
      value: 3,
      label: 'Cấp 3 - thẻ vàng',
    },
  ]
  const resetForm = () => {
    formik.resetForm()
    setIsModalAdd(false)
  }
  //############# FORM ############
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      loaithe: null,
      idchuyenkhoa: PkDangNhap?.idChuyenKhoa,
      idnhom: null,
      sothang: null,
      tiensudung: null,
      nguoitao: infoUser?.tenNV,
      caploaithe: 1,
      mabaocao: null,
      tenbaocao: null,
      // ghichu: '',
    },
    onSubmit: (value) => handleSubmit(value),
    // validationSchema: receiveSchema,
  })
  const handleSubmit = (value) => {
    dispatch(postLoaiTheAction(value, resetForm, { idck: idChuyenKhoa, kw: keyword }))
  }
  // search lấy thông tin thuốc vật tư
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        setKeyword(keyword?.trim())
        dispatch(fetchListTypesCard_Action(idChuyenKhoa, keyword?.trim()))
      }
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const handleSelected = (value) => {
    setIdChuyenKhoa(value)
  }
  const goToDetailCard = (idTypes) => {
    navigate('/khachhang/thetvtt/chiTiet/' + idTypes)
  }
  const handleSearch = (e) => {
    const { value } = e.target
    debounceDropDown(value)
  }
  const getListDanhMucAdd = async () => {
    try {
      setIsLoading(true)
      //const { data: listCK } = await khoVTservice.getAllChuyenKhoa()
      const { data: listNT } = await cardService.listGroupCard()
      setListNhomThe(listNT)
      // setListChuyenKhoa(listCK)
      formik.setValues((prev) => ({
        ...prev,
        idnhom: listNT?.length > 0 ? listNT[0]?.idnhom : null,
        idchuyenkhoa: PkDangNhap?.idChuyenKhoa,
      }))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    dispatch(fetchListTypesCard_Action(idChuyenKhoa, null))
  }, [idChuyenKhoa])
  useEffect(() => {
    dispatch(fetchListTypesCard_Action(idChuyenKhoa, null))
    getListDanhMucAdd()
  }, [])
  return (
    <>
      <Modal
        loading={isLoading}
        width={400}
        title={<p className="text-center">Thêm loại thẻ thành viên</p>}
        open={isModalAdd}
        okText={'Lưu'}
        cancelText="Huỷ bỏ"
        onOk={formik.handleSubmit}
        onCancel={() => setIsModalAdd(false)}
      >
        <div className="grid grid-flow-row items-center gap-3">
          <div>
            <label className="font-semibold">Tên loại thẻ:</label>
            <Input
              size="small"
              value={formik.values.loaithe}
              onChange={formik.handleChange}
              name="loaithe"
            />
          </div>
          <div>
            <label className="font-semibold">Số tháng:</label>
            <InputNumber
              size="small"
              min={0}
              step={1}
              className="w-full"
              value={formik.values.sothang}
              onChange={(value) => formik.setFieldValue('sothang', value ? value : 0)}
              name="sothang"
            />
          </div>
          <div>
            <label className="font-semibold">Cấp thẻ:</label>
            <Select
              size="small"
              className="w-full"
              options={listCapThe}
              value={formik.values.caploaithe}
              onChange={(value) => formik.setFieldValue('caploaithe', value)}
              name="caploaithe"
            />
          </div>
          <div>
            <label className="font-semibold">Nhóm thẻ:</label>
            <Select
              size="small"
              className="w-full"
              options={listNhomThe?.map((item) => ({
                key: item?.idnhom,
                value: item?.idnhom,
                label: item?.tennhom,
              }))}
              value={formik.values.idnhom}
              onChange={(value) => formik.setFieldValue('idnhom', value)}
              name="idnhom"
            />
          </div>
          <div>
            <label className="font-semibold">Chuyên khoa:</label>
            <Select
              size="small"
              className="w-full"
              options={chuyenKhoa?.map((item) => ({
                key: item?.idChuyenKhoa,
                value: item?.idChuyenKhoa,
                label: item?.tenChuyenKhoa,
              }))}
              value={formik.values.idchuyenkhoa}
              onChange={(value) => formik.setFieldValue('idchuyenkhoa', value)}
              name="idchuyenkhoa"
            />
          </div>
          <div>
            <label className="font-semibold">Mã báo cáo:</label>
            <Input
              size="small"
              value={formik.values.mabaocao}
              onChange={formik.handleChange}
              name="mabaocao"
            />
          </div>
          <div>
            <label className="font-semibold">Tên báo cáo:</label>
            <Input
              size="small"
              value={formik.values.tenbaocao}
              onChange={formik.handleChange}
              name="tenbaocao"
            />
          </div>
          <div className="mb-3">
            <label className="font-semibold">Đơn giá:</label>
            <InputNumber
              size="small"
              className="w-full"
              value={formik.values.tiensudung}
              onChange={(value) => formik.setFieldValue('tiensudung', value ? value : 0)}
              name="tiensudung"
              step={100000}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
        </div>
      </Modal>
      <div className="h-full w-full bg-[#f0f0f0]">
        <div className="p-2">
          <div
            className="bg-white rounded-md border p-2 h-full pt-0"
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  key: '1',
                  label: 'Loại thẻ',
                  children: (
                    <>
                      <div className="flex justify-between bg-white rounded-lg mb-4 pl-2 pr-2">
                        <div className="flex items-center gap-2">
                          <div className="w-64">
                            <Select
                              onChange={handleSelected}
                              defaultValue={idChuyenKhoa}
                              options={[
                                { label: 'Tất cả', value: null },
                                ...(chuyenKhoa
                                  ? chuyenKhoa.map((items) => ({
                                      label: items.tenChuyenKhoa,
                                      value: items.idChuyenKhoa,
                                    }))
                                  : []),
                              ]}
                              className="w-full"
                            />
                          </div>
                          <div>
                            <Input.Search
                              onChange={handleSearch}
                              placeholder="Nhập từ khoá"
                              allowClear
                              style={{
                                width: 200,
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <Button
                            onClick={() => setIsModalAdd(true)}
                            type="primary"
                            icon={<PlusOutlined />}
                          >
                            Thêm
                          </Button>
                        </div>
                      </div>
                      <div class="grid grid-cols-5 gap-4 p-2 pt-0">
                        {lisTypesCard?.map((items) => (
                          <div className="flex flex-col bg-[#fafafa] hover:shadow-md duration-200 justify-center hover:scale-105 h-48 rounded-md py-4 px-6 border">
                            <h3 className="text-center font-bold text-xl text-gray-800 pb-2">
                              {formattedNumber(items.tiensudung) + ' VNĐ'}
                            </h3>
                            <h3 className="text-base font-semibold text-gray-900">
                              {items?.loaiThe}
                            </h3>
                            <p className="text-sm text-gray-500 pb-3">Loại thẻ: {items?.loaithe}</p>
                            <div className="flex gap-2 text-sm text-gray-500 border-b pb-2">
                              <p className="">Số tháng hiệu lực:</p>
                              <p>{items?.sothang}</p>
                            </div>
                            <div className="flex justify-around items-center py-3 pb-0">
                              <div className="flex gap-2 text-red-500 p-1 px-3 rounded-lg bg-red-100 hover:bg-red-500 hover:text-white  duration-200 hover:cursor-pointer">
                                <svg
                                  className="w-6 "
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </div>
                              <div
                                onClick={() => goToDetailCard(items?.idlt)}
                                className="flex gap-2 bg-blue-100 px-3 py-1 rounded-md text-blue-500 hover:bg-blue-500 hover:text-white duration-200 hover:cursor-pointer"
                              >
                                <svg
                                  className="w-6 "
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  class="w-6 h-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                                  />
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ),
                },
                // {
                //   key: '2',
                //   label: 'Giá chi nhánh',
                //   children: (<>dsa</>),
                // },
              ]}
              // onChange={onChange}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CardMemberShips

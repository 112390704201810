import React, { useState } from 'react'
import { Input, Modal } from 'antd'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import { editPhongKhamSchema } from '../../../../schemas/editPhongKhamSchema'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
const ModalEdit = ({ isModalEdit, handleCancel, getListPhongKham, rowActive }) => {
  const dispatch = useDispatch()
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { groupsDichVu } = useSelector((state) => state.dichVuReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [listChuyenKhoa, setListChuyenKhoa] = useState([])

  const maQuyenSuaPk = 'QHT393'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenSuaPk:
        formik.handleSubmit()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenSuaPk:
        //
        break
      default:
        return false
    }
  }

  const onLoad = () => {
    // dispatch(getAllDichVu())
    // dispatch(ListDichVuAction(searchGroup, company))
  }
  // xử lí form thêm dịch vụ
  const handleSubmit = async (value) => {
    //dispatch(addNewDichVuAction(value, onLoad))
    try {
      await https.put('PhongKham/UpdatePhongKham', value)
      getListPhongKham()
      // getListBranchUse(rowActive?.idPK, company)
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
      formik.resetForm()
      handleCancel()
    } catch (error) {
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idPK: rowActive?.idPK ?? null,
      maPK: rowActive?.maPK ?? '',
      tenPK: rowActive?.tenPK ?? '',
      nguoiSua: infoUser?.tenNV,
      // ngayTao: "2024-08-07T07:40:05.412Z",
      // suDung: 1,
      ghiChu: '',
      //idKhoMacDinh: 0,
      //idChuyenKhoa: rowActive?.idChuyenKhoa ?? null,
      // nguoiSua: "string",
      // ngaySua: "2024-08-07T07:40:05.412Z",
      //tenChuyenKhoa: "string"
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: editPhongKhamSchema,
  })

  // const getAllChuyenKhoa = async () => {
  //   try {
  //     const { data } = await https.get('ChuyenKhoa/AllChuyenKhoa')
  //     setListChuyenKhoa(data)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // useEffect(() => {
  //   getAllChuyenKhoa()
  // }, [])

  const ChangeSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }

  return (
    <>
      <Modal
        style={{
          top: 20,
        }}
        okText="Lưu"
        cancelText="Hủy"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Chỉnh sửa phòng khám</h2>}
        onOk={() =>
          dispatch(putCheckQuyenThaoTacAction(maQuyenSuaPk, null, actionIsTrue, actionIsFalse))
        }
        open={isModalEdit}
        onCancel={handleCancel}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">
              <span className="text-sx text-red-500">(*)&nbsp;</span>Mã phòng khám
            </label>
            <Input
              value={formik.values.maPK}
              name="maPK"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập mã phòng khám..."
              status={formik.errors.maPK && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">
              <span className="text-sx text-red-500">(*)&nbsp;</span>Tên phòng khám
            </label>
            <Input
              value={formik.values.tenPK}
              name="tenPK"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập tên phòng khám..."
              status={formik.errors.tenPK && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold text-base">
              <span className="text-sx text-red-500">(*)&nbsp;</span>Chuyên khoa
            </label>
            <Input
              readOnly
              variant="filled"
              value={rowActive?.tenChuyenKhoa}
              style={{ color: 'black' }}
            />
            {/* <Input
              value={formik.values.tenDichVu}
              name="tenDichVu"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập tên dịch vụ"
              status={formik.errors.tenDichVu && 'error'}
            /> */}
            {/* <Select
              placeholder="Chọn chuyên khoa..."
              className="w-full"
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              onChange={(value) => formik.setFieldValue('idChuyenKhoa', value)}
              value={formik.values.idChuyenKhoa}
              status={formik.errors.idChuyenKhoa && 'error'}
              options={listChuyenKhoa?.map((items) => ({
                label: items.tenChuyenKhoa,
                value: items.idChuyenKhoa,
                key: items.idChuyenKhoa,
              }))}
            /> */}
          </div>
          {/* <div>
            <label className="font-semibold  text-base">Ghi chú </label>
            <Input
              value={formik.values.ghiChu}
              onChangeCapture={formik.handleChange}
              name="ghiChu"
            />
          </div> */}
        </form>
      </Modal>
    </>
  )
}

export default ModalEdit

import React, { useEffect, useState, useCallback } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import IconButton from '@mui/material/IconButton'
import CachedIcon from '@mui/icons-material/Cached'
import _, { debounce } from 'lodash'
import { ConfigProvider, Input, Select, Table, Pagination, Timeline, Empty } from 'antd'
import { SearchOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  ListGroupsVTByTypes,
  getListCountry_Action,
  getListThuocVTByPageIndex_Action,
  historyMSDKThuocVT,
  listTypesThuocVT_Action,
  searchThuocVT,
} from '../../../store/actions/thuocVTAction'
import { formattedNumber } from '../../../utils/formattedNumber'
import { formatNumberVND } from '../../../utils/formatNumberVND'
import { getlistDoitac } from '../../../store/actions/NhapKhoAction'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import { useSearchParams } from 'react-router-dom'
import { https } from '../../../services/apiService'
import moment from 'moment'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import 'moment/locale/vi'
moment.locale('vi')

const RegistrationCode = () => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState(null)
  const [types, setTypes] = useState(null)
  const [page, setPage] = useState(1)
  const id_company = Number(localStorage.getItem('id_company'))
  const [company, setCompany] = useState(id_company)
  const [valueGroups, SetValueGroups] = useState(null)
  const [name, setName] = useState(null)
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const { listThuocVT, typesVT, groupsVT, historyMSDK } = useSelector(
    (state) => state.thuocVTReducer,
  )
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [totalPages, setTotalPages] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  const maQuyenXemDs = 'QHT197'
  const maQuyenXemCt = 'QHT637'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        dispatch(
          getListThuocVTByPageIndex_Action(
            infoNew?.search,
            infoNew?.company,
            infoNew?.types,
            infoNew?.valueGroups,
            infoNew?.page,
            infoNew?.reset,
            setIsLoading,
            setTotalPages,
          ),
        )
        break
      case maQuyenXemCt:
        dispatch(historyMSDKThuocVT(infoNew?.idThuoc))
        setName(infoNew?.tenBietDuoc)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXemCt:
        //
        break
      default:
        return false
    }
  }

  const debounceDropDown = useCallback(
    _.debounce((keyword, company, types, valueGroups, page, reset) => {
      fetchDataSource(reset, keyword, page)
      // dispatch(
      //   getListThuocVTByPageIndex_Action(
      //     keyword,
      //     company,
      //     types,
      //     valueGroups,
      //     page,
      //     reset,
      //     setIsLoading,
      //     setTotalPages,
      //   ),
      // )
    }, 300),
    [],
  )
  const handleSearchInput = (e) => {
    const { value } = e.target
    setPage(1)
    debounceDropDown(value, company, types, valueGroups, 1, true)
    setSearch(value)
  }
  const getListThuocVT = () => {
    setPage(1)
    loadMoreData(true, search, 1)
  }
  const handleGroupsThuocVT = (value) => {
    SetValueGroups(value)
  }
  const handleCompany = (value) => {
    setCompany(value)
    loadMoreData(true, search, 1)
  }
  const handleTypesThuocVT = (value) => {
    // get list nhóm thuốc vật tư sau khi chọn loại
    setTypes(value)
    SetValueGroups(null)
    dispatch(ListGroupsVTByTypes(value))
  }
  useEffect(() => {
    dispatch(listTypesThuocVT_Action())
    dispatch(getlistDoitac())
    dispatch(getListCountry_Action())
    dispatch(listAllCompanyAction())
    loadMoreData(true, search, page)
  }, [])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 50,
      align: 'center',
    },
    {
      title: 'Mã thuốc',
      dataIndex: 'maThuoc',
      width: 100,
    },
    {
      title: 'Tên thuốc',
      dataIndex: 'tenBietDuoc',
      width: 350,
    },
    {
      title: 'Quy cách',
      dataIndex: 'quyCach',
      width: 120,
      align: 'right',
    },
    {
      title: 'Đối tác',
      dataIndex: 'tenDoiTac',
    },
    {
      title: 'Mã số đăng ký',
      dataIndex: 'maSoDangKy',
      width: 150,
      align: 'center',
    },
  ]
  const fetchDataSource = (reset, search, page) => {
    dispatch(
      putCheckQuyenThaoTacAction(
        maQuyenXemDs,
        {
          search: search,
          company: company,
          types: types,
          valueGroups: valueGroups,
          page: page,
          reset: reset,
        },
        actionIsTrue,
        actionIsFalse,
      ),
    )
  }
  // useEffect(() => {
  //   dispatch(
  //     getListThuocVTByPageIndex_Action(search, company, types, valueGroups, useSearch.get('page')),
  //   )
  // }, [useSearch.get('page')])

  const handleShowHistory = (record) => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemCt, record, actionIsTrue, actionIsFalse))
  }
  const loadMoreData = async (reset, search, page) => {
    if (totalPages < page) return // NẾU DỮ LIỆU CHỈ CÓ 1 PAGE THÌ DỪNG CALL API
    fetchDataSource(reset, search, page)
    setPage((prevPage) => prevPage + 1)
  }
  const handleScroll = useCallback(
    debounce(() => {
      const tableBody = document.querySelector('.table-thumua .ant-table-body')
      if (
        tableBody.scrollTop + tableBody.clientHeight >= tableBody.scrollHeight - 100 &&
        !isLoading
      ) {
        loadMoreData(false, search, page)
      }
    }, 300),
    [isLoading],
  )
  useEffect(() => {
    const tableBody = document.querySelector('.table-thumua .ant-table-body')
    tableBody.addEventListener('scroll', handleScroll)
    return () => tableBody.removeEventListener('scroll', handleScroll)
  }, [handleScroll])
  return (
    <>
      <div className="flex gap-2 p-5 h-full bg-[#efefef]">
        <div className="w-3/4 h-full bg-white rounded-xl border">
          <div className="p-5">
            <div className="flex gap-5 items-center justify-between">
              <div className="flex gap-5 items-center">
                <div className="w-60 flex gap-1">
                  <Select
                    showSearch
                    className="w-full"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    onChange={handleCompany}
                    value={company}
                    options={listCompany?.map((items) => ({
                      label: items.tenct,
                      value: items.idct,
                    }))}
                  />
                </div>
                <div className="w-60 flex gap-1">
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    // filterSort={(optionA, optionB) =>
                    //   (optionA?.label ?? '')
                    //     .toLowerCase()
                    //     .localeCompare((optionB?.label ?? '').toLowerCase())
                    // }
                    value={types}
                    onChange={handleTypesThuocVT}
                    className="w-full"
                    options={[
                      { label: 'Tất cả nhóm hàng', value: null },
                      ...(typesVT
                        ? typesVT
                            ?.map((items) => ({
                              label: items?.phanLoai,
                              value: items?.idPhanLoai,
                              key: items?.idPhanLoai,
                            }))
                            ?.sort((a, b) => (a?.label ?? '')?.localeCompare(b?.label ?? ''))
                        : []),
                    ]}
                  />
                </div>
                <div className="font-semibold w-80 flex gap-1">
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    // filterSort={(optionA, optionB) =>
                    //   (optionA?.label ?? '')
                    //     .toLowerCase()
                    //     .localeCompare((optionB?.label ?? '').toLowerCase())
                    // }
                    onChange={handleGroupsThuocVT}
                    value={valueGroups}
                    options={[
                      { label: 'Tất cả nhóm hàng', value: null },
                      ...(groupsVT && types
                        ? groupsVT
                            ?.map((items) => ({
                              label: items?.nhom,
                              value: items?.idNhom,
                              key: items?.idNhom,
                            }))
                            ?.sort((a, b) => (a?.label ?? '')?.localeCompare(b?.label ?? ''))
                        : []),
                    ]}
                    placeholder="Nhóm thuốc vật tư"
                    className="w-full truncate"
                  />
                </div>
                <div>
                  <Input
                    value={search}
                    placeholder="Tìm kiếm"
                    onChange={handleSearchInput}
                    prefix={<SearchOutlined />}
                  />
                </div>
                <IconButton
                  onClick={getListThuocVT}
                  color="primary"
                  aria-label="add to shopping cart"
                >
                  <CachedIcon />
                </IconButton>
              </div>
            </div>
            <div className="mt-2">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                    fontSize: 14,
                  },
                }}
              >
                <Table
                  loading={isLoading}
                  className="w-full table-thumua"
                  scroll={{
                    y: 670,
                    y: '75vh',
                  }}
                  pagination={false}
                  onRow={(record) => ({
                    onClick: () => handleShowHistory(record),
                  })}
                  bordered
                  columns={columns}
                  dataSource={listThuocVT?.data.map(
                    (
                      {
                        idThuoc,
                        maThuoc,
                        tenBietDuoc,
                        tenHoatChat,
                        dvt,
                        quyCach,
                        quyCachDongGoi,
                        giaBan,
                        tenDoiTac,
                        giaMua,
                        maSoDangKy,
                        duongDung,
                        giagpp,
                        stt,
                      },
                      index,
                    ) => ({
                      idThuoc,
                      stt,
                      maThuoc,
                      tenBietDuoc,
                      tenHoatChat,
                      dvt,
                      quyCach,
                      tenDoiTac,
                      quyCachDongGoi,
                      giaBan: formatNumberVND(giaBan),
                      giaMua: formatNumberVND(giaMua),
                      giagpp: formatNumberVND(giagpp),
                      maSoDangKy,
                      duongDung,
                    }),
                  )}
                />
              </ConfigProvider>
              {/* <div className="flex justify-end">
                <Pagination
                  className="mt-5 "
                  showSizeChanger={false}
                  current={+useSearch.get('page')}
                  simple
                  defaultCurrent={1}
                  pageSize={20}
                  total={20 * listThuocVT?.totalPage}
                  onChange={(page, pagesize) => {
                    SetSearchParams({ page })
                  }}
                />
              </div> */}
            </div>
          </div>
        </div>
        <div className="w-1/4 h-full bg-white rounded-xl border">
          <div className="border-b p-5">
            <h2 className="text-xl font-semibold text-gray-400">Lịch sử mã số đăng kí</h2>
          </div>
          <div className=" p-5">
            <h2 className="font-semibold mb-5">
              Tên: <span>{name}</span>
            </h2>
            {historyMSDK?.length !== 0 ? (
              <Timeline
                items={historyMSDK?.map((item) => ({
                  dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                  children: (
                    <>
                      <h2 className="font-bold">
                        {item.ngayCapNhat
                          ? moment(item.ngayCapNhat).format('DD/MM/YYYY HH:mm:ss')
                          : '-'}
                      </h2>
                      <p className="flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="size-5 text-gray-500 mr-2"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span>{item.nguoiCapNhat}</span>
                      </p>
                      <p className="flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-5 text-green-500 mr-2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                          />
                        </svg>
                        <span>{item.maSoDangKy}</span>
                      </p>
                    </>
                  ),
                }))}
              />
            ) : (
              <Empty description="Chưa có lịch sử thay đổi" />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default RegistrationCode

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as TypeAction from '../../../../../store/constants/constants'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import { Input, notification, Typography, Radio } from 'antd'
import { MinusOutlined, PlusOutlined, DeleteFilled } from '@ant-design/icons'
import ToastCus from '../../../../../utils/Toast'
const { TextArea } = Input
const TableMedicine = ({
  check,
  lyDo,
  idLyDo,
  setIdLyDo,
  checkTraThuoc,
  setCheckTraThuoc,
  lyDoKhac, // lý do không lấy thuốc trước khi thanh toán
  setLyDoKhac, // lý do không lấy thuốc trước khi thanh toán
}) => {
  const { service, BNDetail } = useSelector((state) => state.drugstoreReducer)
  const dispatch = useDispatch()
  const findIndex = (id) => service?.DanhSachThuoc.findIndex((items) => items.idbnthuoc === id)
  const checkBsDangKeToaLai = !service?.BSKEDON || !service?.NGAYKEDON
  const [api, contextHolder] = notification.useNotification()
  const openNotification = (message, description) => {
    api.warning({
      message,
      description,
    })
  }

  const handleIncrement = (id) => {
    if (checkBsDangKeToaLai) {
      ToastCus.fire({
        icon: 'error',
        title: 'Bác sĩ đang kê toa lại không thể chỉnh sửa!',
      })
      return
    }
    const index = findIndex(id)
    if (index !== undefined && index !== -1) {
      // check tông kho
      if (service.DanhSachThuoc[index].tonkho === 0) {
        openNotification('Chỉnh số lượng vắc xin', 'Số lượng vắc xin tốn kho không đủ!')
        return
      }
      const count = service.DanhSachThuoc[index].soluong + 1
      if (count > service.DanhSachThuoc[index].soluongcu && checkTraThuoc) {
        openNotification('Chỉnh số lượng vắc xin', 'Số lượng vắc xin lấy quá giới hạn!')
        return
      }
      dispatch({
        type: TypeAction.INCREMENT_COUNT_DRUGSTORE,
        payload: {
          index,
          value: count,
        },
      })
    }
  }
  const checkThuoc =
    service?.DanhSachThuoc?.reduce((total, items) => total + items.soluong, 0) === 1
  const handleDecrement = (id) => {
    if (checkBsDangKeToaLai) {
      ToastCus.fire({
        icon: 'error',
        title: 'Bác sĩ đang kê toa lại không thể chỉnh sửa!',
      })
      return
    }
    const index = findIndex(id)
    if (index !== undefined && index !== -1) {
      if (checkThuoc && !checkTraThuoc) {
        openNotification(
          'Chỉnh số lượng vắc xin',
          'Phải có ít nhất 1 loại vắc xin hoặc chọn không lấy vắc xin!',
        )
        return
      }
      const count = service.DanhSachThuoc[index].soluong - 1
      dispatch({
        type: TypeAction.DECREMENT_COUNT_DRUGSTORE,
        payload: {
          index,
          value: count,
        },
      })
    }
  }
  const onChange = (e) => {
    setIdLyDo(e.target.value)
    lyDo?.find((item) => item?.idld === e.target.value)
    const infoLd = lyDo?.find((item) => item?.idld === e.target.value)
    setLyDoKhac({ tenlydo: infoLd?.lydo, noidunglydokhac: null })
  }
  return (
    <>
      {contextHolder}
      {check ? (
        <div className="p-2 h-[470px]">
          <Typography.Title level={5}>Lý do không lấy thuốc</Typography.Title>
          <Radio.Group onChange={onChange} value={idLyDo} className="w-full">
            <div className="grid grid-flow-row gap-5 mt-3">
              {lyDo?.map((item) => (
                <div className="flex justify-start items-center">
                  <Radio key={`khonglaythuoc${item?.idld}`} value={item?.idld}>
                    {item?.lydo}
                  </Radio>
                  {`${item?.lydo}` === 'Khác' && item?.idld === idLyDo && (
                    <>
                      <Input
                        status={item?.idld === idLyDo && !lyDoKhac?.noidunglydokhac && 'error'}
                        size="small"
                        value={lyDoKhac?.noidunglydokhac}
                        onChange={(e) =>
                          setLyDoKhac({ tenlydo: `${item?.lydo}`, noidunglydokhac: e.target.value })
                        }
                      />
                    </>
                  )}
                </div>
              ))}
            </div>
          </Radio.Group>
          {/* <div className="p-2">
          <Typography.Title level={5}>Lý do không lấy vắc xin</Typography.Title>
          <Radio.Group onChange={onChange} value={idLyDo}>
            <div className="flex flex-col gap-5">
              {lyDo?.map((item) => (
                <Radio key={`khonglayvacxin${item.idld}`} value={item.idld}>
                  {item.lydo}
                </Radio>
              ))}
            </div>
          </Radio.Group>
          
        </div> */}
        </div>
      ) : (
        <>
          {' '}
          <table className="w-full">
            <thead className="bg-[#F3F6F9]">
              <tr>
                <th className="text-start w-[36%] p-2">Tên vắc xin</th>
                <th className="text-center w-[12%]">Mũi</th>
                <th className="text-center w-[12%]">Đơn giá lẻ</th>
                <th className="text-center w-[10%]">Số lượng</th>
                <th className="text-end w-[6%] p-2">Tồn</th>
                <th className="text-center w-[12%]">Số lô</th>
                <th className="text-end w-[12%] p-2">Tổng tiền</th>
              </tr>
            </thead>
          </table>
          <div className="w-full mt-2  h-[460px] ">
            <div className="overflow-y-auto h-full">
              <table className="w-full">
                <tbody>
                  {service?.DanhSachThuoc.map((items) => (
                    <tr className="h-16">
                      <td className="text-start w-[36%] min-h-20 p-2">{items.tenbietduoc}</td>
                      <td className="text-center w-[12%] min-h-20 "> {items.muitiem}</td>
                      <td className="text-center w-[12%] min-h-20 ">
                        {items.dongia && formattedNumber(items.dongia)}
                      </td>
                      <td className="text-center w-[10%] min-h-20">
                        <div className="flex justify-center">
                          <div className=" flex items-center justify-center ">
                            {((BNDetail?.trangthaikhambenh !== 4 &&
                              BNDetail?.trangthaikhambenh !== 5) ||
                              (BNDetail?.trangthaikhambenh === 5 && checkTraThuoc)) &&
                              !checkBsDangKeToaLai && (
                                <button
                                  onClick={() => handleDecrement(items.idbnthuoc)}
                                  className={`w-10 h-8  ${
                                    items.soluong === 0
                                      ? 'cursor-not-allowed bg-slate-100 text-gray-400'
                                      : 'cursor-pointer bg-gray-200 text-gray-500'
                                  } rounded-lg `}
                                  disabled={items.soluong === 0}
                                >
                                  <MinusOutlined style={{ fontSize: 16 }} />
                                </button>
                              )}

                            <Input
                              className="text-center"
                              placeholder=" "
                              variant="borderless"
                              value={items.soluong}
                            />
                            {((BNDetail?.trangthaikhambenh !== 4 &&
                              BNDetail?.trangthaikhambenh !== 5) ||
                              (BNDetail?.trangthaikhambenh === 5 && checkTraThuoc)) &&
                              !checkBsDangKeToaLai && (
                                <button
                                  onClick={() => handleIncrement(items.idbnthuoc)}
                                  className="w-10 h-8 flex items-center justify-center bg-gray-200 text-gray-500 rounded-lg"
                                >
                                  <PlusOutlined style={{ fontSize: 14 }} />
                                </button>
                              )}
                          </div>
                        </div>
                      </td>
                      <td className="text-end w-[6%] p-2 min-h-20 text-red-500">{items.tonkho}</td>
                      <td className="text-center w-[12%] min-h-20"> {items.solo}</td>
                      <td className="text-end w-[12%] p-2 min-h-20">
                        {' '}
                        {items.soluong && formattedNumber(items.soluong * items.dongia)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default TableMedicine

import * as typeAction from '../constants/constants'
export const BNChoXVXAction = (idChiNhanh) => ({
  type: typeAction.GET_BN_CHO_LAY_THUOC_XVX,
  idChiNhanh,
})
export const BNDaXVXAction = (idChiNhanh) => ({
  type: typeAction.GET_BN_DA_LAY_THUOC_XVX,
  idChiNhanh,
})
export const getBNChoBsKeToaLaiXVXAction = (idChiNhanh) => ({
  type: typeAction.GET_BN_CHO_BS_KE_TOA_LAI_XVX,
  idChiNhanh,
})
//----------------- lấy danh sách phiếu thu----------------//
export const getPhieuThuVacXinAction = (filter) => ({
  type: typeAction.GET_PHIEU_THU_VACXIN,
  filter,
})
// export const getDetailInfoBN = (idbnttc) => ({
//   type: typeAction.GET_DETAIL_BN_NHA_THUOC,
//   idbnttc,
// })
// //----------- lưu thuốc bệnh nhân --------------//
// export const postBNThuoc = (fromPay, formPayDetail, data, idChiNhanh, resetFormData) => ({
//   type: typeAction.POST_BN_THUOC,
//   fromPay,
//   formPayDetail,
//   data,
//   idChiNhanh,
//   resetFormData,
// })
// //------------ thêm bệnh nhân  thuốc -------------//
// export const addToaBanLe = (benhNhanTT, detailPay, thuocBN, dataBNTTC, resetStore) => ({
//   type: typeAction.POST_BN_BAN_LE,
//   benhNhanTT,
//   detailPay,
//   thuocBN,
//   dataBNTTC,
//   resetStore,
// })

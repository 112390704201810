import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Select, DatePicker } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
function InfoPhieuNhap({ infoPhieuNhap, formik }) {
  const { infoUser } = useSelector((state) => state.userReducer)
  return (
    <div className="grid grid-flow-row items-center gap-2">
      <div className="grid grid-cols-4 items-center gap-2">
        <div className="col-span-2 grid grid-cols-6 items-center gap-1">
          <div className="text-end font-semibold">Đối tác:</div>
          <div className="col-span-5 w-full flex items-center gap-2">
            <Input
              className="w-[25%]"
              size="small"
              placeholder="Tên viết tắt"
              variant="filled"
              readOnly
              value={infoPhieuNhap?.infodt?.tenviettat}
            />
            <Input
              className="w-[75%]"
              size="small"
              variant="filled"
              readOnly
              value={infoPhieuNhap?.infodt?.tennhacungcap}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 items-center gap-1">
          <div className="text-end font-semibold">Người nhập:</div>
          <Input
            className="col-span-2 w-full"
            size="small"
            variant="filled"
            readOnly
            value={infoUser?.tenNV}
          />
        </div>
        <div className="grid grid-cols-3 items-center gap-1">
          <div className="text-end font-semibold">Ngày nhập:</div>
          <Input
            className="col-span-2 w-full"
            size="small"
            variant="filled"
            readOnly
            value={moment().format('DD/MM/YYYY HH:mm:ss')}
          />
        </div>
      </div>
      <div className="grid grid-cols-4 items-center gap-2">
        <div className="grid grid-cols-3 items-center gap-1">
          <div className="text-end font-semibold">Mã đối tác:</div>
          <Input
            className="col-span-2 w-full"
            size="small"
            variant="filled"
            readOnly
            value={infoPhieuNhap?.infodt?.madoitac}
          />
        </div>
        <div className="grid grid-cols-3 items-center gap-1">
          <div className="text-end font-semibold">Mã số thuế:</div>
          <Input
            className="col-span-2 w-full"
            size="small"
            variant="filled"
            readOnly
            value={infoPhieuNhap?.infodt?.masothue}
          />
        </div>
        <div className="grid grid-cols-3 items-center gap-1">
          <div className="text-end font-semibold">
            <span className="text-red-500">(*)</span>Phương thức:
          </div>
          <Select
            className="col-span-2 w-full"
            size="small"
            options={[
              {
                key: 1,
                value: 1,
                label: 'Thanh toán',
              },
              {
                key: 2,
                value: 2,
                label: 'Công nợ',
              },
            ]}
            status={formik.errors.phuongThuc ? 'error' : ''}
            value={formik.values.phuongThuc}
            onChange={(value) => formik.setFieldValue('phuongThuc', value)}
            name="phuongThuc"
          />
        </div>
        <div className="grid grid-cols-3 items-center gap-1">
          <div className="text-end font-semibold">
            <span className="text-red-500">(*)</span>Hình thức:
          </div>
          <Select
            className="col-span-2 w-full"
            size="small"
            options={[
              {
                key: 1,
                value: 1,
                label: 'Tiền mặt',
              },
              {
                key: 2,
                value: 2,
                label: 'Chuyển khoản',
              },
            ]}
            status={formik.errors.hinhThuc ? 'error' : ''}
            value={formik.values.hinhThuc}
            onChange={(value) => formik.setFieldValue('hinhThuc', value)}
            name="hinhThuc"
          />
        </div>
      </div>
      <div className="grid grid-cols-4 items-center gap-2">
        <div className="grid grid-cols-3 items-center gap-1">
          <div className="text-end font-semibold">Số điện thoại:</div>
          <Input
            className="col-span-2 w-full"
            size="small"
            variant="filled"
            readOnly
            value={infoPhieuNhap?.infodt?.dienthoai}
          />
        </div>
        <div className="grid grid-cols-3 items-center gap-1">
          <div className="text-end font-semibold">Email:</div>
          <Input
            className="col-span-2 w-full"
            size="small"
            variant="filled"
            readOnly
            value={infoPhieuNhap?.infodt?.email}
          />
        </div>
        <div className="grid grid-cols-3 items-center gap-1">
          <div className="text-end font-semibold">
            <span className="text-red-500">(*)</span>Số HĐ:
          </div>
          <Input
            className="col-span-2 w-full"
            size="small"
            name="soHoaDon"
            status={formik.errors.soHoaDon ? 'error' : ''}
            value={formik.values.soHoaDon}
            onChange={(e) => {
              const { value } = e.target
              const tenVietTat = infoPhieuNhap?.infodt?.tenviettat || ''
              formik.setFieldValue('soHoaDon', value)
              formik.setFieldValue('tenPhieu', `${tenVietTat ? tenVietTat + '-' : ''}` + value)
            }}
          />
        </div>
        <div className="grid grid-cols-3 items-center gap-1">
          <div className="text-end font-semibold">
            {/* <span className="text-red-500">(*)</span> */}
            Ngày HĐ:
          </div>
          <DatePicker
            // tabIndex={6}
            className="col-span-2 w-full"
            size="small"
            placeholder=" "
            format="DD/MM/YYYY"
            name="ngayHoaDon"
            value={formik.values.ngayHoaDon ? dayjs(formik.values.ngayHoaDon) : null}
            onChange={(date) =>
              date ? formik.setFieldValue('ngayHoaDon', dayjs(date).format('YYYY-MM-DD')) : null
            }
          />
        </div>
      </div>
      <div className="grid grid-cols-4 items-center gap-2">
        <div className="col-span-2 grid grid-cols-6 items-center gap-1">
          <div className="text-end font-semibold">Địa chỉ:</div>
          <Input
            className="col-span-5 w-full"
            size="small"
            variant="filled"
            readOnly
            value={infoPhieuNhap?.infodt?.diachi}
          />
        </div>
        <div className="col-span-2 grid grid-cols-6 items-center gap-1">
          <div className="text-end font-semibold">Tên phiếu:</div>
          <Input
            className="col-span-5 w-full"
            size="small"
            name="tenPhieu"
            value={formik.values.tenPhieu}
            onChange={formik.handleChange}
          />
        </div>
      </div>
      <div className=" grid grid-cols-12 items-center gap-1">
        <div className="text-end font-semibold">Ghi chú:</div>
        <Input
          className="col-span-11 w-full"
          size="small"
          name="ghiChu"
          value={formik.values.ghiChu}
          onChange={formik.handleChange}
        />
      </div>
    </div>
  )
}

export default InfoPhieuNhap

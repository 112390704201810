import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import LayoutApp from '../../../HOCs/LayoutApp'
import { Button, ConfigProvider, Input, Table, DatePicker } from 'antd'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { https } from '../../../services/apiService'
import ToastCus from '../../../utils/Toast'
import moment from 'moment'
import dayjs from 'dayjs'

import ExcelJS from 'exceljs'
import saveAs from 'file-saver'

import { useSearchParams } from 'react-router-dom'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()

function SinhNhat(props) {
  const [data, setData] = useState([])
  const [dataShow, setDataShow] = useState([])
  const [startDate, setStartDate] = useState(now.format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(now.format('YYYY-MM-DD'))
  const [keyword, setKeyword] = useState('')
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [activeRow, setActiveRow] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  // search
  const [searchMa, setSearchMa] = useState('')
  const [searchMaTCQG, setSearchMaTCQG] = useState('')
  const [searchTen, setSearchTen] = useState('')
  const [searchDiaChi, setSearchDiaChi] = useState('')
  const [searchGioiTinh, setSearchGioiTinh] = useState('')
  const [searchDoiTuong, setSearchDoiTuong] = useState('')
  const [searchNgaySinh, setSearchNgaySinh] = useState('')
  // Phường xã	Quận huyện	Tỉnh thành
  const [searchPhuongXa, setSearchPhuongXa] = useState('')
  const [searchQuanHuyen, setSearchQuanHuyen] = useState('')
  const [searchTinhTP, setSearchTinhTP] = useState('')
  const [searchDanToc, setSearchDanToc] = useState('')

  const handleSearch = (name) => (e) => {
    const { value } = e.target
    switch (name) {
      case 'ma':
        setSearchMa(value || '')
        break
      case 'maTCQG':
        setSearchMaTCQG(value || '')
        break
      case 'ten':
        setSearchTen(value || '')
        break
      case 'diaChi':
        setSearchDiaChi(value || '')
        break
      case 'gioiTinh':
        setSearchGioiTinh(value || '')
        break
      case 'doiTuong':
        setSearchDoiTuong(value || '')
        break
      case 'phuongXa':
        setSearchPhuongXa(value || '')
        break
      case 'quanHuyen':
        setSearchQuanHuyen(value || '')
        break
      case 'tinhTP':
        setSearchTinhTP(value || '')
        break
      case 'ngaySinh':
        setSearchNgaySinh(value || '')
        break
      case 'danToc':
        setSearchDanToc(value || '')
        break
      default:
        break
    }
  }
  const handleDatePickerNhap = (date, dateString) => {
    setSearchNgaySinh(dateString ? moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : '')
  }

  const getAllSinhNhat = async () => {
    try {
      setIsLoading(true)
      const result = await https.get(`BenhNhan/getBirthdayCustomer`, {
        params: {
          startDate: startDate || null,
          endDate: endDate || null,
          keyword: keyword || null,
        },
      })
      setData(result.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại',
      })
    }
  }

  useEffect(() => {
    getAllSinhNhat()
  }, [startDate, endDate, keyword])

  useEffect(() => {
    // Trigger search when searchMa changes
    getAllSinhNhat()
  }, [searchMa])

  const onChangeStartDate = (date, dateString) => {
    setStartDate(moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'))
  }

  const onChangeEndDate = (date, dateString) => {
    setEndDate(moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'))
  }

  const handleTableChange = (pagination) => {
    SetSearchParams({ page: pagination.current })
  }

  const handleClickRow = (record) => {
    if (activeRow && activeRow.idbn === record.idbn) {
      setActiveRow(null)
    } else {
      setActiveRow(record)
    }
  }

  const compare = (a, b, dataIndex) => {
    if (a[dataIndex] < b[dataIndex]) {
      return -1
    }
    if (a[dataIndex] > b[dataIndex]) {
      return 1
    }
    return 0
  }

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Danh sách sinh nhật')

    // Add header row
    worksheet.columns = [
      { header: 'Mã bệnh nhân', key: 'maBenhNhan', width: 20 },
      { header: 'Tên bệnh nhân', key: 'tenBenhNhan', width: 30 },
      { header: 'Mã TCQG', key: 'maTCQG', width: 20 },
      { header: 'Dân tộc', key: 'tenDanToc', width: 20 },
      { header: 'Đối tượng', key: 'tenDoiTuong', width: 20 },
      { header: 'Ngày sinh', key: 'ngaySinh', width: 15 },
      { header: 'Giới tính', key: 'gioiTinh', width: 10 },
      { header: 'Địa chỉ', key: 'diaChi', width: 30 },
      { header: 'Phường xã', key: 'tenPhuongXa', width: 20 },
      { header: 'Quận huyện', key: 'tenQuanHuyen', width: 20 },
      { header: 'Tỉnh thành', key: 'tenTinhTP', width: 20 },
    ]

    // Add data rows
    dataShow.forEach((item) => {
      worksheet.addRow({
        maBenhNhan: item.maBenhNhan,
        tenBenhNhan: item.tenBenhNhan,
        maTCQG: item.maTCQG,
        tenDanToc: item.tenDanToc,
        tenDoiTuong: item.tenDoiTuong,
        ngaySinh: item.ngaySinh ? moment(item.ngaySinh).format('DD/MM/YYYY') : '',
        gioiTinh: item.gioiTinh,
        diaChi: item.diaChi,
        tenPhuongXa: item.tenPhuongXa,
        tenQuanHuyen: item.tenQuanHuyen,
        tenTinhTP: item.tenTinhTP,
      })
    })

    // Set font to Times New Roman for all cells
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.font = { name: 'Times New Roman' }
      })
    })

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    // Save the file
    saveAs(
      blob,
      `Danh sách sinh nhật từ ${moment(startDate).format('DD/MM/YYYY')} đến ${moment(endDate).format('DD/MM/YYYY')}.xlsx`,
    )
  }

  useEffect(() => {
    const filteredData = data.filter(
      ({
        maBenhNhan,
        maTCQG,
        tenBenhNhan,
        diaChi,
        gioiTinh,
        ngaySinh,
        tenDoiTuong,
        tenPhuongXa,
        tenQuanHuyen,
        tenTinhTP,
        tenDanToc,
      }) => {
        const maLower = searchMa.trim().toLowerCase()
        const maTCQGLower = searchMaTCQG.trim().toLowerCase()
        const tenLower = searchTen.trim().toLowerCase()
        const diaChiLower = searchDiaChi.trim().toLowerCase()
        const ngaySinhLower = searchNgaySinh.trim().toLowerCase()
        const gioiTinhLower = searchGioiTinh.trim().toLowerCase()
        const doiTuongLower = searchDoiTuong.trim().toLowerCase()
        const phuongXaLower = searchPhuongXa.trim().toLowerCase()
        const quanHuyenLower = searchQuanHuyen.trim().toLowerCase()
        const tinhTPLower = searchTinhTP.trim().toLowerCase()
        const danTocLower = searchDanToc.trim().toLowerCase()

        // Kiểm tra nếu không có điều kiện tìm kiếm nào thì trả về true
        return (
          (!maLower || maBenhNhan?.toLowerCase().includes(maLower)) &&
          (!maTCQGLower || maTCQG?.toLowerCase().includes(maTCQGLower)) &&
          (!tenLower || tenBenhNhan?.toLowerCase().includes(tenLower)) &&
          (!diaChiLower || diaChi?.toLowerCase().includes(diaChiLower)) &&
          (!gioiTinhLower || gioiTinh?.toLowerCase().includes(gioiTinhLower)) &&
          (!ngaySinhLower || ngaySinh?.toLowerCase().includes(ngaySinhLower)) &&
          (!doiTuongLower || tenDoiTuong?.toLowerCase().includes(doiTuongLower)) &&
          (!phuongXaLower || tenPhuongXa?.toLowerCase().includes(phuongXaLower)) &&
          (!quanHuyenLower || tenQuanHuyen?.toLowerCase().includes(quanHuyenLower)) &&
          (!tinhTPLower || tenTinhTP?.toLowerCase().includes(tinhTPLower)) &&
          (!danTocLower || tenDanToc?.toLowerCase().includes(danTocLower))
        )
      },
    )
    setDataShow(filteredData)
  }, [
    searchMa,
    searchMaTCQG,
    searchTen,
    searchDiaChi,
    searchGioiTinh,
    searchNgaySinh,
    searchDoiTuong,
    searchPhuongXa,
    searchQuanHuyen,
    searchTinhTP,
    searchDanToc,
    data,
  ])
  const columnsAllVoucher = [
    {
      title: 'STT ',
      dataIndex: 'STT',
      key: 'STT',
      align: 'center',
      onCell: () => ({
        style: {
          minWidth: 40,
        },
      }),
      render: (text, record, index) => (useSearch.get('page') - 1) * 100 + index + 1,
    },
    {
      title: 'Mã bệnh nhân',
      dataIndex: 'maBenhNhan',
      sorter: (a, b) => compare(a, b, 'maBenhNhan'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input size="small" onChange={(e) => setSearchMa(e.target.value)} value={searchMa} />
            </>
          ),
          dataIndex: 'maBenhNhan',
          key: 'maBenhNhan',
          align: 'center',
          onCell: () => ({
            style: {
              minWidth: 140,
            },
          }),
        },
      ],
    },
    {
      title: 'Mã TCQG',
      dataIndex: 'maTCQG',
      key: 'maTCQG',
      align: 'center',
      sorter: (a, b) => compare(a, b, 'maTCQG'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input
                size="small"
                onChange={(e) => setSearchMaTCQG(e.target.value)}
                value={searchMaTCQG}
              />
            </>
          ),
          dataIndex: 'maTCQG',
          key: 'maTCQG',
          onCell: () => ({
            style: {
              minWidth: 100,
            },
          }),
        },
      ],
    },
    {
      title: 'Tên bệnh nhân',
      dataIndex: 'tenBenhNhan',
      key: 'tenBenhNhan',
      align: 'center',
      sorter: (a, b) => compare(a, b, 'tenBenhNhan'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input
                size="small"
                onChange={(e) => setSearchTen(e.target.value)}
                value={searchTen}
              />
            </>
          ),
          dataIndex: 'tenBenhNhan',
          key: 'tenBenhNhan',
          onCell: () => ({
            style: {
              minWidth: 140,
            },
          }),
        },
      ],
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaySinh',
      key: 'ngaySinh',
      sorter: (a, b) => compare(a, b, 'ngaySinh'),
      align: 'center',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
      children: [
        {
          title: (
            <>
              <DatePicker
                format={'DD/MM/YYYY'}
                size="small"
                value={searchNgaySinh ? moment(searchNgaySinh, 'YYYY-MM-DD') : null}
                onChange={handleDatePickerNhap}
              />
            </>
          ),
          render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
          dataIndex: 'ngaySinh',
          onCell: () => ({
            style: {
              minWidth: 100,
            },
          }),
        },
      ],
    },
    {
      title: 'Dân tộc',
      dataIndex: 'tenDanToc',
      key: 'tenDanToc',
      align: 'center',
      sorter: (a, b) => {
        const valueA = a.tenDanToc || ''
        const valueB = b.tenDanToc || ''

        if (valueA === '' && valueB !== '') return 1
        if (valueA !== '' && valueB === '') return -1

        return valueA.localeCompare(valueB)
      },
      children: [
        {
          title: (
            <>
              <Input
                size="small"
                onChange={(e) => setSearchDanToc(e.target.value)}
                value={searchDanToc}
              />
            </>
          ),
          align: 'center',
          dataIndex: 'tenDanToc',
          key: 'tenDanToc',
          onCell: () => ({
            style: {
              minWidth: 90,
            },
          }),
        },
      ],
    },
    {
      title: 'Đối tượng',
      dataIndex: 'tenDoiTuong',
      key: 'tenDoiTuong',
      sorter: (a, b) => compare(a, b, 'tenDoiTuong'),
      align: 'center',
      children: [
        {
          title: (
            <>
              <Input
                size="small"
                onChange={(e) => setSearchDoiTuong(e.target.value)}
                value={searchDoiTuong}
              />
            </>
          ),
          align: 'center',
          dataIndex: 'tenDoiTuong',
          key: 'tenDoiTuong',
          onCell: () => ({
            style: {
              minWidth: 110,
            },
          }),
        },
      ],
    },
    {
      title: 'Giới tính',
      dataIndex: 'gioiTinh',
      key: 'gioiTinh',
      sorter: (a, b) => compare(a, b, 'gioiTinh'),
      children: [
        {
          title: (
            <>
              <Input
                size="small"
                onChange={(e) => setSearchGioiTinh(e.target.value)}
                value={searchGioiTinh}
              />
            </>
          ),
          dataIndex: 'gioiTinh',
          key: 'gioiTinh',
          align: 'center',
          onCell: () => ({
            style: {
              minWidth: 110,
            },
          }),
        },
      ],
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'diaChi',
      key: 'diaChi',
      align: 'center',
      sorter: (a, b) => compare(a, b, 'diaChi'),
      showSorterTooltip: false,
      children: [
        {
          title: (
            <>
              <Input
                size="small"
                onChange={(e) => setSearchDiaChi(e.target.value)}
                value={searchDiaChi}
              />
            </>
          ),
          dataIndex: 'diaChi',
          key: 'diaChi',
          onCell: () => ({
            style: {
              minWidth: 110,
            },
          }),
        },
      ],
    },
    {
      title: 'SĐT',
      dataIndex: 'dienThoai',
      onCell: () => ({
        style: {
          minWidth: 60,
        },
      }),
      align: 'center',
      key: 'dienThoai',
      render: (text, record) => {
        if (record.idbn === activeRow?.idbn) {
          return <p>{text}</p>
        }
        const maskedNumber = text?.slice(0, -6).padEnd(text?.length, '*')
        return <p>{maskedNumber}</p>
      },
    },
    {
      title: 'Phường xã',
      dataIndex: 'tenPhuongXa',
      key: 'tenPhuongXa',
      align: 'center',
      sorter: (a, b) => compare(a, b, 'tenPhuongXa'),
      children: [
        {
          title: (
            <>
              <Input
                size="small"
                onChange={(e) => setSearchPhuongXa(e.target.value)}
                value={searchPhuongXa}
              />
            </>
          ),
          dataIndex: 'tenPhuongXa',
          key: 'tenPhuongXa',
          onCell: () => ({
            style: {
              minWidth: 110,
            },
          }),
        },
      ],
    },
    {
      title: 'Quận huyện',
      dataIndex: 'tenQuanHuyen',
      key: 'tenQuanHuyen',
      align: 'center',
      sorter: (a, b) => compare(a, b, 'tenQuanHuyen'),
      children: [
        {
          title: (
            <>
              <Input
                size="small"
                onChange={(e) => setSearchQuanHuyen(e.target.value)}
                value={searchQuanHuyen}
              />
            </>
          ),
          dataIndex: 'tenQuanHuyen',
          key: 'tenQuanHuyen',
          onCell: () => ({
            style: {
              minWidth: 110,
            },
          }),
        },
      ],
    },
    {
      title: 'Tỉnh thành',
      dataIndex: 'tenTinhTP',
      key: 'tenTinhTP',
      align: 'center',
      sorter: (a, b) => compare(a, b, 'tenTinhTP'),
      children: [
        {
          title: (
            <>
              <Input
                size="small"
                onChange={(e) => setSearchTinhTP(e.target.value)}
                value={searchTinhTP}
              />
            </>
          ),
          dataIndex: 'tenTinhTP',
          key: 'tenTinhTP',
          onCell: () => ({
            style: {
              minWidth: 110,
            },
          }),
        },
      ],
    },
  ]

  return (
    <>
      <div className="p-3 bg-[#EFEFEF] h-full ">
        <div className="h-full p-2 rounded-xl bg-white border">
          <div className="p-2 pt-0">
            <div className="mb-2 flex justify-between items-center">
              <div className="flex items-center">
                <DatePicker
                  allowClear={false}
                  onChange={onChangeStartDate}
                  value={dayjs(startDate)}
                  format={'DD/MM/YYYY'}
                  className="mr-2"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                </svg>
                <DatePicker
                  allowClear={false}
                  onChange={onChangeEndDate}
                  value={dayjs(endDate)}
                  format={'DD/MM/YYYY'}
                  className="mr-2"
                />
                <Input
                  placeholder="Từ khóa"
                  className="w-40 mr-2"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <Button type="primary" onClick={getAllSinhNhat}>
                  Tìm kiếm
                </Button>
              </div>
              <Button
                onClick={exportToExcel}
                className="text-green-700 font-semibold"
                type="default"
              >
                Xuất <ImportExportIcon />
              </Button>
            </div>
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                  },
                },
              }}
            >
              <Table
                columns={columnsAllVoucher}
                dataSource={dataShow}
                scroll={{
                  x: dataShow?.length > 0 ? 'max-content' : 0,
                  y: '69vh',
                }}
                bordered
                pagination={{
                  pageSize: 100,
                  showSizeChanger: false,
                }}
                loading={isLoading}
                onChange={handleTableChange}
                onRow={(record) => ({
                  onClick: () => handleClickRow(record),
                })}
                rowKey={(record) => record.idbn}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </>
  )
}

SinhNhat.propTypes = {
  data: PropTypes.array,
}

export default SinhNhat

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import CachedIcon from '@mui/icons-material/Cached'
import {
  Button,
  Checkbox,
  ConfigProvider,
  Input,
  Modal,
  Pagination,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../services/apiService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import {
  deleteDMXetNghiemAction,
  getInfoXetNghiemAction,
  getXetNghiemByCondition,
  listGroupsXetNghiemAction,
} from '../../../store/actions/xetNghiemAction'
import ToastCus from '../../../utils/Toast'
import { formatNumberVND } from '../../../utils/formatNumberVND'
import ModalAdd from './Modal/ModalAdd'
import ModalChangePriceXetNghiemCN from './Modal/ModalChangePriceCN'
import ModalEdit from './Modal/ModalEdit'
import { useSearchParams } from 'react-router-dom'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import * as XLSX from 'xlsx'

import { PageNumber } from 'docx'
const { confirm } = Modal
const XetNghiem = () => {
  const dispatch = useDispatch()
  const { ListXetNghiem, groupsXetNghiem } = useSelector((state) => state.xetNghiemReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [searchText, setSearchText] = useState('')
  const [searchGroup, setSearchGroup] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalEdit, setisModalEdit] = useState(false)
  const [rowActive, setRowActive] = useState(null)
  const [listBranch, setListBranch] = useState(null)
  const [loadingCN, setLoadingCN] = useState(false)
  const [allDoiTacXN, setAllDoiTacXN] = useState([])
  const [doitac, setDoitac] = useState('')
  const [keyword, setKeyword] = useState('')
  const [showEditPriceCN, setShowEditPriceCN] = useState({
    show: false,
    data: null,
  })
  const [loadingExport, setLoadingExport] = useState(false)
  //search in table
  const searchInput = useRef(null)
  const [searchedColumn, setSearchedColumn] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })

  const filter = useMemo(() => {
    return {
      idct: `?idct=${company}`,
      idnhomxn: searchGroup ? `&idnhomxn=${searchGroup}` : '',
      iddoitacxn: doitac ? `&idDoiTacXN=${doitac}` : '',
      keyword: keyword ? `&keyword=${keyword}` : '',
      pageNumber: `&pageNumber=${useSearch ? useSearch.get('page') : 1}`,
    }
  }, [keyword, searchGroup, company, doitac, useSearch])

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancelEdit = () => {
    setisModalEdit(false)
  }
  const handleShowModalEdit = (idxn) => {
    setisModalEdit(true)
    dispatch(getInfoXetNghiemAction(idxn))
  }
  const onLoad = (filter) => {
    dispatch(getXetNghiemByCondition(filter))
  }
  // handle delete danh mục xét nghiệm
  const showDeleteConfirm = (idxn, maxn, tenxn) => {
    confirm({
      title: 'Bạn có muốn xóa loại xét nghiệm ?',
      icon: <ExclamationCircleFilled />,
      content: `Bạn có chắc muốn xóa ${maxn ? maxn : ''}${tenxn ? ' - ' + tenxn : ''}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(deleteDMXetNghiemAction(idxn, onLoad))
      },
      onCancel() {
        // console.log('Cancel');
      },
    })
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const getDoiTacXN = async () => {
    try {
      const { data } = await https.get('DoiTacXetNghiem/GetAllDoiTacXetNghiem')
      setAllDoiTacXN(data)
    } catch (error) {}
  }

  useEffect(() => {
    getDoiTacXN()
    onLoad(filter)
    dispatch(listGroupsXetNghiemAction())
    dispatch(listAllCompanyAction())
  }, [])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 40,
      align: 'center',
    },
    {
      title: 'Mã xét nghiệm',
      dataIndex: 'maxn',
      width: 150,
    },
    {
      title: 'Tên xét nghiệm',
      dataIndex: 'tenxn',
    },
    {
      title: 'Đơn giá',
      dataIndex: 'dongia',
      width: 100,
      align: 'center',
    },
    {
      title: 'Tiền gửi mẫu',
      dataIndex: 'tienguimau',
      width: 100,
      align: 'center',
    },
    {
      title: 'ĐVT',
      dataIndex: 'dvt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Đối tác XN',
      dataIndex: 'tendoitac',
      align: 'left',
      width: 200,
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      width: 100,
      fixed: 'right',
    },
  ]

  const handleSearchCN = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleResetCN = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchCN(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchCN(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleResetCN(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) => text,
    // searchedColumn === dataIndex ? (
    //   <Highlighter
    //     highlightStyle={{
    //       backgroundColor: '#ffc069',
    //       padding: 0,
    //     }}
    //     searchWords={[searchText]}
    //     autoEscape
    //     textToHighlight={text ? text.toString() : ''}
    //   />
    // ) : (
    //   text
    // ),
  })

  const handleSelect = (e) => {
    if (e.target.checked) {
      setListBranch((prev) => prev.map((item) => ({ ...item, sudung: 1 })))
    } else {
      setListBranch((prev) => prev.map((item) => ({ ...item, sudung: 0 })))
    }
  }

  const columnsCN = [
    {
      title: 'Tên chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      ...getColumnSearchProps('tenchinhanh'),
    },
    {
      title: (
        <div className="flex justify-center flex-col items-center">
          <p>Sử dụng</p>
          <Checkbox
            checked={listBranch?.every((item) => item.sudung === 1)}
            onChange={handleSelect}
            disabled={!rowActive || loadingCN}
          />
        </div>
      ),
      dataIndex: 'sudung',
      width: 90,
      align: 'center',
      key: 'sudung',
      render: (text, record, index) => (
        <Checkbox
          disabled={!rowActive}
          checked={record.sudung === 1}
          onChange={(e) => {
            handleCheckBox(e, record)
          }}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 50,
    },
  ]

  const handleCompany = (value) => {
    setSearchGroup('')
    setSearchText('')
    setDoitac('')
    setCompany(value)
  }

  const handleCheckBox = (e, record) => {
    const { checked } = e.target
    const updatedListBranch = listBranch.map((item) =>
      item.idchinhanh === record.idchinhanh ? { ...item, sudung: checked ? 1 : 0 } : item,
    )
    setListBranch(updatedListBranch)
  }

  const getListBranchUse = async (iddv) => {
    setLoadingCN(true)
    try {
      const { data } = await https.get(
        `XetnghiemChiNhanh/GetChiNhanhByIDXN?idxn=${iddv}&idct=${company}`,
      )
      // Update listBranch based on fetched data
      const updatedListBranch = listBranch.map((item) => {
        const found = data.find((val) => val.idchinhanh === item.idchinhanh)
        return found ? { ...item, ...found } : { ...item, sudung: 0 }
      })

      updatedListBranch.sort((a, b) => b.sudung - a.sudung)
      setListBranch(updatedListBranch)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingCN(false)
    }
  }

  const handleClickRow = (row) => {
    setRowActive(row)
    getListBranchUse(row.idxn)
  }

  const getAllCnByIdct = async (value) => {
    setLoadingCN(true)
    try {
      const { data } = await https.get(`ChiNhanh/GetChiNhanhByIDCT?idct=${value}`)
      //format to show list branch
      const list = data.map((item) => {
        return {
          idchinhanh: item.idchinhanh,
          tenchinhanh: item.tenchinhanh,
          sudung: 0,
          dongia: 0,
          tienguimau: 0,
          tienchietkhau: 0,
        }
      })
      setListBranch(list)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingCN(false)
    }
  }

  useEffect(() => {
    if (company) {
      getAllCnByIdct(company)
    }
  }, [company])

  const handleUpdate = async () => {
    setLoadingCN(true)
    try {
      await https.put(
        'XetnghiemChiNhanh/UpdateSuDung',
        listBranch.map((item) => ({
          sudung: item.sudung,
          idCN: item.idchinhanh,
          id: rowActive.idxn,
        })),
      )
      ToastCus.fire({
        icon: 'success',
        text: 'Cập nhật thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        text: 'Cập nhật thất bại!',
      })
    } finally {
      setLoadingCN(false)
    }
  }

  const handleChangePage = (filter, page) => {
    setCurrentPage(page)
    SetSearchParams({ page })
    onLoad({ ...filter, pageNumber: `&pageNumber=${page}` })
  }

  const handleSearch = (filter) => {
    setCurrentPage(1)
    SetSearchParams({ page: 1 })
    onLoad({ ...filter, pageNumber: `&pageNumber=1` })
  }

  useEffect(() => {
    setTotalPage(ListXetNghiem?.totalPage)
  }, [ListXetNghiem?.totalPage])

  const exportToExcel = async (company, keyword, searchGroup, doitac) => {
    setLoadingExport(true)
    try {
      const filter = {
        idct: `?idct=${company}`,
        idnhomxn: searchGroup ? `&idnhomxn=${searchGroup}` : '',
        iddoitacxn: doitac ? `&idDoiTacXN=${doitac}` : '',
        tenxn: keyword ? `&tenXNDM=${keyword}` : '',
      }
      const condition = Object.values(filter).filter(Boolean).join('')
      const { data } = await https.get(`XetNghiemDanhMuc/SearchXetNghiemDanhMucNotPage${condition}`)
      const headers = ['Mã XN', 'Tên XN', 'ĐVT', 'Tiền gửi mẫu', 'Đơn giá', 'Tên đối tác']
      const formattedData = data.map((item) => ({
        'Mã XN': item.maxn,
        'Tên XN': item.tenxn,
        ĐVT: item.dvt,
        'Tiền gửi mẫu': item.tienguimau,
        'Đơn giá': item.dongia,
        'Tên đối tác': item.tendoitac,
      }))

      const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
      XLSX.writeFile(wb, 'Danh mục xét nghiệm.xlsx')
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        text: 'Có lỗi xảy ra khi xuất excel',
      })
    } finally {
      setLoadingExport(false)
    }
  }
  return (
    <>
      <div className="p-5 bg-[#efefef]">
        <div className="flex w-full items-center gap-5 p-2 bg-white rounded-md">
          <div className="flex w-full gap-3">
            <Select
              showSearch
              className="w-64"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              onChange={handleCompany}
              value={company}
              options={[
                ...(listCompany
                  ? listCompany.map((items) => ({
                      label: items.tenct,
                      value: items.idct,
                    }))
                  : []),
              ]}
            />
            <Select
              onChange={(value) => {
                setSearchGroup(value)
              }}
              value={searchGroup}
              className="w-64"
              options={[
                { label: 'Tất cả', value: '' },
                ...(groupsXetNghiem
                  ? groupsXetNghiem.map((items) => ({
                      label: items.tennhom,
                      value: items.idnhomxn,
                    }))
                  : []),
              ]}
            />
            <Select
              value={doitac}
              options={[
                { label: 'Tất cả', value: '' },
                ...allDoiTacXN?.map((items) => ({
                  label: items.tendoitac,
                  value: items.iddtxn,
                })),
              ]}
              onChange={(value) => setDoitac(value)}
              className="w-32"
              placeholder="Đối tác XN"
            />
            <Input.Search
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
              className="w-80"
              placeholder="Tìm kiếm theo Mã, Tên xét nghiệm"
            />
            <Button
              onClick={() => handleSearch(filter)}
              className="flex items-center"
              type="primary"
              ghost
              icon={<CachedIcon />}
            >
              Tải lại
            </Button>
            <Button
              onClick={() => exportToExcel(company, keyword, searchGroup, doitac)}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
              loading={loadingExport}
            >
              Xuất Excel
            </Button>
          </div>
          <div className="flex gap-5 ml-auto">
            <Button onClick={showModal} className="flex items-center" type="primary" disabled>
              <PlusOutlined /> Tạo nhóm xét nghiệm
            </Button>
            <Button onClick={showModal} className="flex items-center" type="primary">
              <PlusOutlined /> Tạo xét nghiệm
            </Button>
          </div>
        </div>
        <div className="flex gap-2 h-full mt-2">
          <div className="w-2/3 h-full bg-white rounded-xl">
            <div className="p-2">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                    fontSize: 14,
                  },
                  components: {
                    Table: {
                      rowHoverBg: '#ecf0f1',
                      headerBg: '#e6e6e6',
                      footerBg: '#e6e6e6',
                      borderColor: '#BABABA',
                    },
                  },
          
                }}
              >
                <Table
                  pagination={false}
                  bordered
                  onRow={(row) => {
                    return {
                      onClick: () => {
                        handleClickRow(row)
                      },
                    }
                  }}
                  columns={columns}
                  dataSource={ListXetNghiem?.data?.map((item, index) => ({
                    ...item,
                    stt: (currentPage - 1) * 20 + (index + 1),
                    dongia: formatNumberVND(item.dongia),
                    tienguimau: formatNumberVND(item.tienguimau),
                    action: (
                      <ul className="flex justify-around">
                        <li>
                          <Tooltip title="Chỉnh sửa" color="green">
                            <EditOutlined
                              onClick={(e) => {
                                e.stopPropagation()
                                handleShowModalEdit(item.idxn)
                              }}
                              className="text-xl text-green-500  cursor-pointer"
                            />
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Xoá" color="red">
                            <DeleteOutlined
                              onClick={(e) => {
                                e.stopPropagation()
                                showDeleteConfirm(item.idxn, item.maxn, item.tenxn)
                              }}
                              className="text-xl text-red-500  cursor-pointer"
                            />
                          </Tooltip>
                        </li>
                      </ul>
                    ),
                  }))}
                />
              </ConfigProvider>
              <div className="flex justify-end">
                <Pagination
                  className="mt-5"
                  showSizeChanger={false}
                  current={+useSearch.get('page')}
                  simple
                  defaultCurrent={1}
                  pageSize={20}
                  total={20 * totalPage}
                  onChange={(page, pagesize) => {
                    handleChangePage(filter, page)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-1/3 h-full bg-white rounded-md">
            <div className="px-2 pt-2 flex justify-between text-base">
              <div className="flex gap-1 w4/5 ">
                {rowActive && (
                  <>
                    <span>Tên:</span>
                    <p className="font-semibold ">{rowActive?.tenxn}</p>
                  </>
                )}
              </div>
              <div className="w-1/5">
                <Button
                  onClick={handleUpdate}
                  disabled={!rowActive || loadingCN || company === ''}
                  className="h-7 w-full"
                  type="primary"
                  size="small"
                >
                  Lưu
                </Button>
              </div>
            </div>

            <div className="p-2">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                  },
                  components: {
                    Table: {
                      rowHoverBg: '#ecf0f1',
                      headerBg: '#e6e6e6',
                      footerBg: '#e6e6e6',
                      borderColor: '#BABABA',
                    },
                  },
          
                }}
              >
                <Table
                  loading={loadingCN}
                  scroll={{ y: 700 }}
                  // loading={isLoadingBranch}
                  pagination={false}
                  columns={columnsCN}
                  dataSource={listBranch?.map((item) => ({
                    ...item,
                    action: (
                      <ul className="flex justify-around">
                        {rowActive && (
                          <li>
                            <Tooltip title="Chỉnh sửa" color="green">
                              <EditOutlined
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setShowEditPriceCN({ show: true, data: item })
                                }}
                                className="text-xl text-green-500  cursor-pointer"
                              />
                            </Tooltip>
                          </li>
                        )}
                      </ul>
                    ),
                  }))}
                  bordered
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </div>
      <ModalAdd isModalOpen={isModalOpen} handleCancel={handleCancel} onLoad={onLoad} />
      <ModalEdit isModalEdit={isModalEdit} handleCancelEdit={handleCancelEdit} onLoad={onLoad} />
      {showEditPriceCN && (
        <ModalChangePriceXetNghiemCN
          rowActive={rowActive}
          showEditPriceCN={showEditPriceCN}
          setShowEditPriceCN={setShowEditPriceCN}
          getListBranchUse={getListBranchUse}
        />
      )}
    </>
  )
}

export default XetNghiem

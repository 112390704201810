import { call, put, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { GPPService } from '../../services/GPP/GPPService'

import Swal from 'sweetalert2'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
const now = moment()

export function* ThuocGPPSaga() {
  // Get all ThuocVatTu_GiaGPP
  yield takeLatest(typeAction.GET_LIST_TYPES_THUOC_GPP, function* listThuocVatTu_GiaGPP() {
    try {
      const response = yield call(GPPService.getAllThuocVatTu_GiaGPP)
      yield put({
        type: typeAction.DISPATCH_TYPES_THUOC_GPP,
        payload: response.data,
      })
    } catch (error) {
      console.log('error', error)
    }
  })
  // Get ThuocVatTu_GiaGPP by ID
  yield takeLatest(typeAction.GET_THUOC_GPP_BY_ID, function* getThuocVatTu_GiaGPPByID(action) {
    try {
      const response = yield call(GPPService.getThuocVatTu_GiaGPPByID, action.payload)
      yield put({
        type: typeAction.DISPATCH_THUOC_GPP_BY_ID,
        payload: response.data,
      })
    } catch (error) {
      console.log('error', error)
    }
  })

  //Lấy lịch sử GPP
  yield takeLatest(typeAction.GET_HISTORY_GPP, function* historyGPP({ type, idThuoc }) {
    try {
      const result = yield call(() => GPPService.getGPPThuoc(idThuoc))
      yield put({
        type: typeAction.DISPATCH_HISTORY_GPP,
        payload: result.data.result, // Extract the result field
      })
    } catch (error) {
      console.log('error', error)
    }
  })
}

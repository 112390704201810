import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ConfigProvider, Input, Table, Alert } from 'antd'
import CachedIcon from '@mui/icons-material/Cached'
import { putCheckQuyenThaoTacAction } from '../../../../../store/actions/phanQuyenAction'
import moment from 'moment'
const KyHang = ({ tabValue }) => {
  const dispatch = useDispatch()
  const { kyHang } = useSelector((state) => state.thuocVTReducer)
  const [isQuyen, setisQuyen] = useState(null)

  const maQuyenXemDs = 'QHT237'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        setisQuyen(true)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        setisQuyen(false)
        break
      default:
        return false
    }
  }
  useEffect(() => {
    tabValue == 3 &&
      dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }, [tabValue])
  return (
    <>
      {isQuyen === false && (
        <>
          <Alert message="Tài khoản không có quyền thao tác!" type="error" showIcon />
        </>
      )}
      <div className={`${isQuyen ? '' : 'hidden'}`}>
        <div className="flex gap-2 mb-2">
          <Input.Search className="w-72" />
          <div
            className="cursor-pointer rounded-sm text-blue-500  hover:bg-slate-200"
            // onClick={handleFilter}
          >
            <CachedIcon sx={{ fontSize: 25 }} />
          </div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            bordered
            dataSource={kyHang}
            columns={[
              {
                title: 'STT',
                key: 1,
                render: (_, record, index) => +index,
              },
              {
                title: 'Tên kì hàng',
                key: 'tenky',
                dataIndex: 'tenky',
              },
              {
                title: 'Ngày chốt kỳ',
                key: 'denngay',
                render: (text) => moment(text).format('DD/MM/YYYY'),
                dataIndex: 'denngay',
              },
              {
                title: 'Ngày tạo',
                key: 'ngaytao',
                dataIndex: 'ngaytao',
                render: (text) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
              },
              {
                title: 'Người tạo',
                key: 'tennguoitao',
                dataIndex: 'tennguoitao',
              },
            ]}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default KyHang

import JsBarcode from 'jsbarcode'
import moment from 'moment/moment'
import { formatNumberVND } from '../../utils/formatNumberVND'
import { numberToWords } from '../../utils/numberToWords'
import { generateLogo, getDeTailThanhToan } from '../algorithm'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord } from '../splitWordForReport'


export const generatePhieuBanLeToaThuoc = async (benhNhanTTC, toaThuoc, PkDangNhap, ttBNThanhToan) => {
  const doc = jsPDFCus('p', 'mm', 'a5')
  let yPos = 10
  let xPos = 8
  let ruleWidth = 55 // for text of price is longer
  const pageWidth = doc.internal.pageSize.width
  //header
  generateLogo(doc, xPos, PkDangNhap)

  JsBarcode('#barcode', `${ttBNThanhToan.maPhieuThu}`, {
    displayValue: false,
  })
  const canvas = document.getElementById('barcode')
  const barcodeDataURL = canvas.toDataURL()
  const barcodeWidth = 35 // Width of the barcode image
  const barcodeX = pageWidth - 45 // X coordinate of the barcode
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 45, 5, 35, 14)
  // Calculate the center position for text
  const text = `${ttBNThanhToan.maPhieuThu}`
  const textWidth = (doc.getStringUnitWidth(text) * 11) / doc.internal.scaleFactor // Width of the text
  const textX = barcodeX + (barcodeWidth - textWidth) / 2 // Centered X coordinate for the text
  doc.setFontSize(10)
  doc.text(text, textX, 22)
  doc.setFont('times', 'bold')
  doc.setFontSize(13)
  doc.text('PHIẾU THU TIỀN', doc.internal.pageSize.width / 2, 30, { align: 'center' })
  let textWidthName = doc.getTextDimensions('Họ và tên: ').w
  doc.setFontSize(10)
  doc.text(benhNhanTTC.benhNhan.tenBenhNhan.toUpperCase(), textWidthName, 40)
  doc.setFont('times', 'regular')
  doc.setFontSize(9)
  doc.text('Họ và tên: ', xPos, 40)
  //dịa chỉ
  const diaChiBenhNhan = `Địa chỉ: ${benhNhanTTC.benhNhan.diaChi ?? ''}${benhNhanTTC.benhNhan.tenPhuongXa ? ', ' + benhNhanTTC.benhNhan.tenPhuongXa : ''}${benhNhanTTC.benhNhan.tenQuanHuyen ? ', ' + benhNhanTTC.benhNhan.tenQuanHuyen : ''}${benhNhanTTC.benhNhan.tenTinhTP ? ', ' + benhNhanTTC.benhNhan.tenTinhTP : ''}`

  if (diaChiBenhNhan.length > 70) {
    const wordsArray = splitLongWord(diaChiBenhNhan, 70)
    doc.text(wordsArray[0], xPos, 45)
    doc.text(wordsArray[1], xPos, 49)
    yPos = 49
  } else {
    doc.text(diaChiBenhNhan, xPos, 45)
    yPos = 45
  }
  //chẩn đoán
  if (`Ghi chú: ${benhNhanTTC.chandoan ?? ''}`.length > 70) {
    const wordsArray = splitLongWord(`Ghi chú: ${benhNhanTTC.chandoan ?? ''}`, 70)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    yPos += 5
  } else {
    doc.text(`Ghi chú: ${benhNhanTTC.chandoan ?? ''}`, xPos, yPos + 5)
    yPos += 5
  }
  yPos += 5
  // Add a line
  const headers = ['STT', 'Tên Hàng hóa', 'Số lô', 'Hạn dùng', 'Đơn vị', 'SL']
  const columnWidths = [10, 45, 20, 20, 15, 20]
  const data = toaThuoc.map((item, idx) => [
    idx + 1,
    item.tenHoatChat,
    item.soLo,
    item.hanDung ? moment(item.hanDung).format('DD/MM/YYYY') : '',
    item.dvt,
    item.soLuong,
  ])
  doc.autoTable({
    head: [headers],
    body: data,
    startY: yPos,
    columnStyles: {
      // Set custom column widths
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1], halign: 'left' },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
      4: { cellWidth: columnWidths[4] },
      5: { cellWidth: columnWidths[5] },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1,
      fillColor: '#fff',
      textColor: '#000',
      lineColor: '#000',
      valign: 'middle',
      halign: 'center',
      fontStyle: 'regular',
      fontSize: 9,
    },
    headStyles: {
      fillColor: '#fff',
      textColor: '#000',
      fontStyle: 'bold',
      fontSize: 10,
    },
    alternateRowStyles: {
      fillColor: '#fff',
    },
    showHead: 'firstPage',
    margin: { left: xPos },
  })
  const bnchitietthanhtoan = await getDeTailThanhToan(benhNhanTTC.idbnttc)
  const paymentArray = ['Tiền mặt', 'Chuyển khoản', 'Thẻ thanh toán', 'Momo', 'VNPay', 'Zalopay', 'POS']
    .filter(key => bnchitietthanhtoan[key] > 0); 

  const textPhuongThucThanhToan = `HÌNH THỨC THANH TOÁN: ${paymentArray.join(' + ')}`

  const tongTien = toaThuoc.reduce((total, item) => total + item.thanhTien, 0)
  const data2Column = [
    ['', ''],
    [textPhuongThucThanhToan, formatNumberVND(tongTien)],
    ['TỔNG TIỀN', formatNumberVND(tongTien)],
  ]
  doc.autoTable({
    head: [data2Column[0]],
    body: data2Column.slice(1),
    startY: doc.autoTable.previous.finalY,
    columnStyles: {
      // Set custom column widths
      0: { cellWidth: 110, halign: 'right' },
      1: { cellWidth: 20, halign: 'right' },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1, // Set line width for table borders
      fillColor: '#fff', // Set fill color to white for headers and rows
      textColor: '#000', // Set text color to black for headers and rows
      lineColor: '#000',
      valign: 'middle',
      fontSize: 9,
      fontStyle: 'bold',
      // Set line color to black for headers and rows
    },

    headStyles: {
      font: 'times',
      fillColor: '#fff', // Set fill color to white for headers
      textColor: '#000', // Set text color to black for headers
      fontStyle: 'bold', // Set font weight to bold for headers
      fontSize: 10,
    },
    alternateRowStyles: {
      font: 'times',
      fontStyle: 'regular',
      fillColor: '#fff', // Set fill color to white for alternate rows
    },
    showHead: 'never',
    margin: { left: xPos },
  })

  //footer
  doc.setFont('times', 'italic')
  doc.setFontSize(10)
  // landspace: 60, portrait: 40
  if (`Bằng chữ: ${numberToWords(tongTien)}.`.length > 40) {
    const wordsArray = splitLongWord(`Bằng chữ: ${numberToWords(tongTien)}.`, 40)
    doc.text(wordsArray[0], xPos, doc.autoTable.previous.finalY + 5)
    doc.text(wordsArray[1], xPos, doc.autoTable.previous.finalY + 10)
  } else {
    doc.text(
      `${`Bằng chữ: ${numberToWords(tongTien)}.` ?? ''}`,
      xPos,
      doc.autoTable.previous.finalY + 5,
    )
  }
  yPos = doc.autoTable.previous.finalY + 5
  doc.setFont('times', 'regular')

  const dateText = `Ngày ${moment(ttBNThanhToan?.ngayThanhToan).format('DD')} tháng ${moment(ttBNThanhToan?.ngayThanhToan).format('MM')} năm ${moment(ttBNThanhToan?.ngayThanhToan).format('YYYY')}`
  const doctorText = toaThuoc[0].duocSi ?? ''
  const dateTextWidth = doc.getTextWidth(dateText)
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateTextX = pageWidth - dateTextWidth - 20

  // Calculate the center point of the date text
  const dateCenterX = dateTextX + dateTextWidth / 2

  // Position the doctor text centered below the date text
  const doctorTextX = dateCenterX - doctorTextWidth / 2

  doc.text(dateText, dateTextX, yPos)
  yPos += 5
  doc.setFont('times', 'regular')
  doc.text('Người lập phiếu', pageWidth - 52, yPos)
  doc.setFont('times', 'bold')
  doc.text(doctorText, doctorTextX, yPos + 20)

  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}

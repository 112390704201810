import { https } from "../apiService";

export const DonViTinhService = {
    AddDonViTinh: (form) => https.post('DonViTinh/create', form),
    UpdateDonViTinh: (ma, data) => {
        return https.put(`/DonViTinh/updateByMa/${ma}`, data);
      },
    GetByMaDonViTinh: (ma) => https.get(`DonViTinh/getInfoByMa/${ma}`),
    DeleteDonViTinh: (ma) => https.delete(`DonViTinh/deleteByMa/${ma}`),
}

import React, { useState, useEffect } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import { useSelector, useDispatch } from 'react-redux'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { DatePicker, Select, ConfigProvider, Table, Button, TreeSelect } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import * as XLSX from 'xlsx'
import { FormOutlined, HomeOutlined, AuditOutlined } from '@ant-design/icons'

import IconButton from '@mui/material/IconButton'
import { Link, useNavigate } from 'react-router-dom'
import { branchService } from '../../../services/branch/branchService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { reportService } from '../../../services/Report/reportService'
import { formattedNumber } from '../../../utils/formattedNumber'
import { khoVTservice } from '../../../services/khoVT/khoVTservice'
import 'moment/locale/vi'
import { exportExcelformat } from '../../../utils/export/exportExcelFormat'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect
const ReportBranch = () => {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [value, setValue] = useState([BranchLogin])

  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [idChiNhanh, setIdChiNhanh] = useState(Number(BranchLogin))
  const [data, setData] = useState([])
  const [ChuyenKhoa, setChuyenKhoa] = useState([])
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const { infoUser } = useSelector((state) => state.userReducer) // Access infoUser from Redux
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const onLoad = () => {
    fetchReport()
  }
  //-------- export excel-----------//
  const exportToExcel = () => {
    const formattedData = data.map((item) => ({
      'Ngày thu': item.ngay ? moment(item.ngay).format('DD/MM/YYYY') : '-',
      'Chi nhánh': item.tenChiNhanh,
      'Ngày khai trương': item.ngayKhaiTruong
        ? moment(item.ngayKhaiTruong).format('DD/MM/YYYY')
        : '-',
      'Dịch vụ ca A': item.doanhThuCaLamViecs[0].doanhThuDichVu,
      'Dịch vụ ca B': item.doanhThuCaLamViecs[1].doanhThuDichVu,
      'Dịch vụ ca C': item.doanhThuCaLamViecs[2].doanhThuDichVu,
      'Tổng dịch vụ ': item.tongTienDichVu,
      'TTV ca A': item.doanhThuCaLamViecs[0].doanhThuThe,
      'TTV ca B': item.doanhThuCaLamViecs[1].doanhThuThe,
      'TTV ca C': item.doanhThuCaLamViecs[2].doanhThuThe,
      'Tổng TTV ': item.tongTienThe,
      'Toa thuốc ca A': item.doanhThuCaLamViecs[0].doanhThuThuocKeToa,
      'Toa thuốc ca B': item.doanhThuCaLamViecs[1].doanhThuThuocKeToa,
      'Toa thuốc ca C': item.doanhThuCaLamViecs[2].doanhThuThuocKeToa,
      'Tổng Toa thuốc ': item.tongTienThuocKeToa,
      'Bán lẻ ca A': item.doanhThuCaLamViecs[0].doanhThuThuocBanLe,
      'Bán lẻ ca B': item.doanhThuCaLamViecs[1].doanhThuThuocBanLe,
      'Bán lẻ ca C': item.doanhThuCaLamViecs[2].doanhThuThuocBanLe,
      'Tổng Bán lẻ ': item.tongTienThuocBanLe,
      'Vaccine ca A': item.doanhThuCaLamViecs[0].doanhThuThuocVacXin,
      'Vaccine ca B': item.doanhThuCaLamViecs[1].doanhThuThuocVacXin,
      'Vaccine ca C': item.doanhThuCaLamViecs[2].doanhThuThuocVacXin,
      'Tổng Vacxin ': item.doanhThuThuocVacXin,
      'Tổng tiền': item.tongTienNgay,
    }))
    const name = 'Doanh thu tổng hợp'
    exportExcelformat(formattedData, name)
  }
  //---------- lấy danh sách chi nhánh theo công ty -----------//
  // const fetchBranch = async (id) => {
  //   try {
  //     const result = await branchService.getListBranch(id)
  //     setBranch(result.data)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  //-------------- lấy danh sách chuyên kho ------------//
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  // ---------đến trang chi tiết -------------//
  const goToDetailReportBranch = (id) => {
    navigate('/baocao/chinhanh/chitiet/' + id)
  }
  // ------------ lấy danh sách report theo chi nhánh ------------//
  const fetchReport = async () => {
    setIsLoading(true)
    let idChiNhanhs = null
    // Kiểm tra giá trị của `value` để xử lý các trường hợp rỗng hoặc "all"
    if (value?.length > 0 && value[0] !== 'all') {
      // Giả sử `value` là mảng các ID. Chuyển đổi mảng thành chuỗi truy vấn
      idChiNhanhs = value?.map((id) => `&idChiNhanhs=${id}`).join('&')
      const { data } = await reportService.getReportTotalByBranch(
        idChuyenKhoa,
        company,
        idChiNhanhs,
        dateForm,
        dateTo,
      )
      setData(data)
    } else {
      const { data } = await reportService.getReportTotalByBranch(
        idChuyenKhoa,
        company,
        '',
        dateForm,
        dateTo,
      )
      setData(data)
    }
    setIsLoading(false)
  }
  //------------ lấy danh sách chi nhánh theo chuyên khoa ----------//
  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  const total = data.reduce((total, items) => total + items.tongTienNgay, 0)
  const totalDV = data.reduce((total, items) => total + items.tongTienDichVu, 0)
  const totalTHE = data.reduce((total, items) => total + items.tongTienThe, 0)
  const totalTOA = data.reduce((total, items) => total + items.tongTienThuocKeToa, 0)
  const totalLE = data.reduce((total, items) => total + items.tongTienThuocBanLe, 0)
  const totalVX = data.reduce((total, items) => total + items.tongTienThuocVacXin, 0)
  useEffect(() => {
    dispatch(listAllCompanyAction())
    // fetchBranch(id_company)
    fetchReport()
    fetchChuyenKhoa()
    fetchBranchByChuyenKhoa(null)
  }, [])
  const statis = [
    {
      title: 'TỔNG TIỀN DỊCH VỤ',
      number: formattedNumber(totalDV),
    },
    {
      title: 'TỔNG TIỀN TTV',
      number: formattedNumber(totalTHE),
    },
    {
      title: 'TỔNG TIỀN BÁN LẺ',
      number: formattedNumber(totalLE),
    },
    {
      title: 'TỔNG TIỀN TOA THUỐC',
      number: formattedNumber(totalTOA),
    },
    {
      title: 'TỔNG TIỀN VACCINE',
      number: formattedNumber(totalVX),
    },
    {
      title: 'TỔNG DOANH THU',
      number: formattedNumber(total),
    },
  ]
  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      key: '0-1',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
        key: `0-1-${index}`,
      })),
    },
  ]
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  return (
    <>
      <div className="h-full w-full bg-[#EBEBEB] p-2 border ">
        <div className="flex justify-between">
          <h2 className="font-semibold text-xl">Doanh thu tổng hợp</h2>
        </div>
        <div
          className="border mt-2 rounded-md bg-white h-[95%]"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <div className="p-2">
            <ul className="flex border rounded-md">
              {statis.map((items) => (
                <li className="flex flex-col gap-2 w-1/6 border-r p-2">
                  <div className="flex justify-between">
                    <h2 className="font-semibold text-gray-500 xl:text-base text-xs">
                      {items.title}
                    </h2>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        className="size-5 text-green-700 border-2 border-green-500  rounded-full "
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                  <span className="text-lg font-semibold">{formattedNumber(items.number)}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex gap-5 justify-between border-dotted border-b-0 p-2 ">
            <div className="w-80">
              <Select
                showSearch
                value={company}
                onChange={(value) => {
                  // fetchBranch(value)
                  setValue(['all'])
                  setCompany(value)
                  setIdChuyenKhoa('')
                  setIdChiNhanh(null)
                }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={listCompany?.map(({ idct, tenct }) => ({
                  label: tenct,
                  value: idct,
                }))}
                className="w-full"
                disabled={infoUser && infoUser.idNhom === 3}
              />
            </div>
            <div className="w-56">
              <Select
                onChange={onChangeChuyenKhoa}
                className="w-full"
                value={idChuyenKhoa}
                options={[
                  { label: 'Tất cả', value: '' },
                  ...ChuyenKhoa.map((items) => ({
                    label: items.tenChuyenKhoa,
                    value: items.idChuyenKhoa,
                  })),
                ]}
                disabled={infoUser && infoUser.idNhom === 3}
              />
            </div>
            <div className="w-80">
              <TreeSelect
                {...tProps}
                style={{ flexWrap: 'unset' }}
                className="w-full h-8 overflow-clip"
                disabled={infoUser && infoUser.idNhom === 3}
              />
            </div>
            <div className="flex gap-5">
              <div className="flex items-center gap-3">
                <DatePicker
                  allowClear={false}
                  defaultValue={dayjs(now)}
                  maxDate={dayjs(now)}
                  onChange={handleDatePicker('form')}
                  format={dateFormat}
                />
                <span className="font-semibold text-lg">-</span>
                <DatePicker
                  allowClear={false}
                  defaultValue={dayjs(now)}
                  maxDate={dayjs(now)}
                  onChange={handleDatePicker('to')}
                  format={dateFormat}
                />
              </div>
              <div className="flex gap-3">
                <IconButton onClick={onLoad} color="primary" aria-label="delete" size="small">
                  <SyncIcon />
                </IconButton>
              </div>
              <div className="font-semibold ">
                <Button
                  onClick={exportToExcel}
                  type="text"
                  size="middle"
                  className="text-green-700 p-1"
                  icon={<ImportExportIcon />}
                >
                  Xuất Excel
                </Button>
              </div>
            </div>
            <div className="xl:block hidden">
              <Button onClick={() => goToDetailReportBranch(BranchLogin)} type="primary">
                Chi tiết doanh thu
              </Button>
            </div>
          </div>
          <div className="px-2 mb-2 block xl:hidden">
            <Button onClick={() => goToDetailReportBranch(BranchLogin)} type="primary">
              Chi tiết doanh thu
            </Button>
          </div>
          <div>
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                  fontSize: 14,
                },
                components: {
                  Table: {
                    headerBorderRadius: 0,
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                scroll={{
                  x: data.length > 0 ? 'max-content' : 0,
                  y: '61vh',
                }}
                pagination={false}
                loading={isLoading}
                bordered
                dataSource={data}
                columns={[
                  {
                    title: 'STT',
                    dataIndex: 'stt',
                    key: 'stt',
                    onCell: () => ({
                      style: {
                        minWidth: 40,
                      },
                    }),
                    align: 'center',
                    fixed: 'left',
                    render: (text, record, index) => ++index,
                  },
                  {
                    title: 'Ngày',
                    dataIndex: 'ngay',
                    key: 'ngay',
                    onCell: () => ({
                      style: {
                        minWidth: 70,
                      },
                    }),
                    fixed: 'left',
                    render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
                  },
                  {
                    title: 'Chi nhánh',
                    dataIndex: 'tenChiNhanh',
                    key: 'tenChiNhanh',
                    fixed: 'left',
                    onCell: () => ({
                      style: {
                        minWidth: 70,
                      },
                    }),
                  },
                  {
                    title: 'Ngày khai trương',
                    dataIndex: 'ngayKhaiTruong',
                    key: 'ngayKhaiTruong',
                    onCell: () => ({
                      style: {
                        minWidth: 130,
                      },
                    }),
                    fixed: 'left',
                    render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
                  },
                  {
                    title: 'Dịch vụ',
                    dataIndex: 'tongTienDichVu',
                    key: 'tongTienDichVu',
                    align: 'center',
                    children: [
                      {
                        title: 'Ca A',
                        dataIndex: 'DV_A',
                        align: 'right',
                        key: 'DV_A',
                        onCell: () => ({
                          style: {
                            minWidth: 60,
                          },
                        }),
                        render: (_, record) =>
                          formattedNumber(record.doanhThuCaLamViecs[0].doanhThuDichVu) || 0,
                      },
                      {
                        title: 'Ca B',
                        dataIndex: 'DV_B',
                        align: 'right',
                        key: 'DV_A',
                        onCell: () => ({
                          style: {
                            minWidth: 60,
                          },
                        }),
                        render: (_, record) =>
                          formattedNumber(record.doanhThuCaLamViecs[1].doanhThuDichVu) || 0,
                      },
                      {
                        title: 'Ca C',
                        dataIndex: 'DV_C',
                        align: 'right',
                        key: 'DV_C',
                        onCell: () => ({
                          style: {
                            minWidth: 60,
                          },
                        }),
                        render: (_, record) =>
                          formattedNumber(record.doanhThuCaLamViecs[2].doanhThuDichVu) || 0,
                      },
                      {
                        title: 'Tổng DV',
                        dataIndex: 'totalDV',
                        key: 'totalDV',
                        align: 'right',
                        onCell: () => ({
                          style: {
                            minWidth: 110,
                          },
                        }),
                        render: (_, record) => formattedNumber(record.tongTienDichVu) || 0,
                      },
                    ],
                  },
                  {
                    title: 'Thẻ Thành viên',
                    dataIndex: 'tongTienThe',
                    key: 'tongTienThe',
                    align: 'center',
                    children: [
                      {
                        title: 'Ca A',
                        dataIndex: 'DV_A',
                        key: 'DV_A',
                        align: 'right',
                        onCell: () => ({
                          style: {
                            minWidth: 60,
                          },
                        }),
                        render: (_, record) =>
                          formattedNumber(record.doanhThuCaLamViecs[0].doanhThuThe) || 0,
                      },
                      {
                        title: 'Ca B',
                        dataIndex: 'DV_B',
                        key: 'DV_A',
                        align: 'right',
                        onCell: () => ({
                          style: {
                            minWidth: 60,
                          },
                        }),
                        render: (_, record) =>
                          formattedNumber(record.doanhThuCaLamViecs[1].doanhThuThe) || 0,
                      },
                      {
                        title: 'Ca C',
                        dataIndex: 'DV_C',
                        key: 'DV_C',
                        onCell: () => ({
                          style: {
                            minWidth: 60,
                          },
                        }),
                        align: 'right',
                        render: (_, record) =>
                          formattedNumber(record.doanhThuCaLamViecs[2].doanhThuThe) || 0,
                      },
                      {
                        title: 'Tổng Thẻ',
                        dataIndex: 'totalCard',
                        key: 'totalCard',
                        align: 'right',
                        onCell: () => ({
                          style: {
                            minWidth: 110,
                          },
                        }),
                        render: (_, record) => formattedNumber(record.tongTienThe) || 0,
                      },
                    ],
                  },
                  {
                    title: 'Toa thuốc',
                    dataIndex: 'tongTienThuocKeToa',
                    key: 'tongTienThuocKeToa',
                    align: 'center',
                    children: [
                      {
                        title: 'Ca A',
                        dataIndex: 'TT_A',
                        key: 'TT_A',
                        align: 'right',
                        onCell: () => ({
                          style: {
                            minWidth: 60,
                          },
                        }),
                        render: (_, record) =>
                          formattedNumber(record.doanhThuCaLamViecs[0].doanhThuThuocKeToa) || 0,
                      },
                      {
                        title: 'Ca B',
                        dataIndex: 'DV_B',
                        key: 'TT_A',
                        align: 'right',
                        onCell: () => ({
                          style: {
                            minWidth: 60,
                          },
                        }),
                        render: (_, record) =>
                          formattedNumber(record.doanhThuCaLamViecs[1].doanhThuThuocKeToa) || 0,
                      },
                      {
                        title: 'Ca C',
                        dataIndex: 'DV_C',
                        key: 'DV_C',
                        align: 'right',
                        onCell: () => ({
                          style: {
                            minWidth: 60,
                          },
                        }),
                        render: (_, record) =>
                          formattedNumber(record.doanhThuCaLamViecs[2].doanhThuThuocKeToa) || 0,
                      },
                      {
                        title: 'Tổng toa thuốc',
                        dataIndex: 'totalTT',
                        key: 'totalTT',
                        onCell: () => ({
                          style: {
                            minWidth: 110,
                          },
                        }),
                        align: 'right',
                        render: (_, record) => formattedNumber(record.tongTienThuocKeToa) || 0,
                      },
                    ],
                  },
                  {
                    title: 'Bán lẻ',
                    dataIndex: 'tongTienThuocBanLe',
                    key: 'tongTienThuocBanLe',
                    align: 'center',
                    children: [
                      {
                        title: 'Ca A',
                        dataIndex: 'BL_A',
                        key: 'BL_A',
                        align: 'right',
                        onCell: () => ({
                          style: {
                            minWidth: 60,
                          },
                        }),
                        render: (_, record) =>
                          formattedNumber(record.doanhThuCaLamViecs[0].doanhThuThuocBanLe) || 0,
                      },
                      {
                        title: 'Ca B',
                        dataIndex: 'BL_B',
                        key: 'BL_B',
                        onCell: () => ({
                          style: {
                            minWidth: 60,
                          },
                        }),
                        align: 'right',
                        render: (_, record) =>
                          formattedNumber(record.doanhThuCaLamViecs[1].doanhThuThuocBanLe) || 0,
                      },
                      {
                        title: 'Ca C',
                        dataIndex: 'BL_C',
                        key: 'BL_C',
                        align: 'right',
                        onCell: () => ({
                          style: {
                            minWidth: 60,
                          },
                        }),
                        render: (_, record) =>
                          formattedNumber(record.doanhThuCaLamViecs[2].doanhThuThuocBanLe) || 0,
                      },
                      {
                        title: 'Tổng bán lẻ',
                        dataIndex: 'totalCard',
                        key: 'totalCard',
                        align: 'right',
                        onCell: () => ({
                          style: {
                            minWidth: 110,
                          },
                        }),
                        render: (_, record) => formattedNumber(record.tongTienThuocBanLe) || 0,
                      },
                    ],
                  },
                  {
                    title: 'vaccine',
                    dataIndex: 'tongTienThuocVacXin',
                    key: 'tongTienThuocVacXin',
                    align: 'center',
                    children: [
                      {
                        title: 'Ca A',
                        dataIndex: 'BL_A',
                        key: 'BL_A',
                        align: 'right',
                        onCell: () => ({
                          style: {
                            minWidth: 60,
                          },
                        }),
                        render: (_, record) =>
                          formattedNumber(record.doanhThuCaLamViecs[0].doanhThuThuocVacXin) || 0,
                      },
                      {
                        title: 'Ca B',
                        dataIndex: 'BL_B',
                        key: 'BL_B',
                        onCell: () => ({
                          style: {
                            minWidth: 60,
                          },
                        }),
                        align: 'right',
                        render: (_, record) =>
                          formattedNumber(record.doanhThuCaLamViecs[1].doanhThuThuocVacXin) || 0,
                      },
                      {
                        title: 'Ca C',
                        dataIndex: 'BL_C',
                        key: 'BL_C',
                        align: 'right',
                        onCell: () => ({
                          style: {
                            minWidth: 60,
                          },
                        }),
                        render: (_, record) =>
                          formattedNumber(record.doanhThuCaLamViecs[2].doanhThuThuocVacXin) || 0,
                      },
                      {
                        title: 'Tổng vaccine',
                        dataIndex: 'totalCard',
                        key: 'totalCard',
                        align: 'right',
                        onCell: () => ({
                          style: {
                            minWidth: 110,
                          },
                        }),
                        render: (_, record) => formattedNumber(record.tongTienThuocVacXin) || 0,
                      },
                    ],
                  },
                  {
                    title: 'Tổng tiền',
                    dataIndex: 'tongTienNgay',
                    key: 'tongTienNgay',
                    onCell: () => ({
                      style: {
                        minWidth: 90,
                      },
                    }),
                    align: 'right',
                    render: (text) => text && formattedNumber(text),
                    fixed: 'right',
                  },
                  {
                    title: 'Hành động',
                    dataIndex: 'action',
                    key: 'action',
                    width: 100,
                    align: 'center',
                    fixed: 'right',
                    render: (text, record, index) => (
                      <div className="flex gap-2">
                        <Button
                          size="small "
                          onClick={() => goToDetailReportBranch(record.idChiNhanh)}
                          type="link"
                          className="flex items-center gap-1"
                        >
                          Chi tiết{' '}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            className="size-4"
                          >
                            <path
                              fillRule="evenodd"
                              d="M2 8c0 .414.336.75.75.75h8.69l-1.22 1.22a.75.75 0 1 0 1.06 1.06l2.5-2.5a.75.75 0 0 0 0-1.06l-2.5-2.5a.75.75 0 1 0-1.06 1.06l1.22 1.22H2.75A.75.75 0 0 0 2 8Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </Button>
                      </div>
                    ),
                  },
                ]}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportBranch

import React, { useEffect, useState } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import Tablist from './TabList/Tablist'
import General from './General/General'
import { useDispatch, useSelector } from 'react-redux'
import * as typeAction from '../../../store/constants/constants'
import { SuggestDoctor, thuocKeToa } from '../../../store/actions/childrenClinicAction'
import { listPKByChiNhanh_Action } from '../../../store/actions/receiveAction'
import { useLocation } from 'react-router-dom'
const OphthalmologyClinic = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const BRANCH_ID_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { phongKham } = useSelector((state) => state.receiveReducer)
  const { DetailBN } = useSelector((state) => state.childrenClinicReducer)
  const [defaultValuePK, setDefaultValuePK] = useState(-1)
  const [checkCK, setCheckCK] = useState(false)

  // useEffect(() => {
  //   setCheckCK(PkDangNhap?.idChuyenKhoa === 4 && location?.pathname.includes('/phongkham/khammat') ? true : false)
  // }, [location, PkDangNhap, phongKham])
  useEffect(() => {
    dispatch({
      type: typeAction.RESET_STORE_CLINIC,
    })
    dispatch(SuggestDoctor())
    dispatch(listPKByChiNhanh_Action(BRANCH_ID_LOGIN))
    // dispatch(thuocKeToa(BRANCH_ID_LOGIN))
  }, [])
  useEffect(() => {
    dispatch(thuocKeToa(BRANCH_ID_LOGIN))
  }, [DetailBN])
  return (
    <>
      <div className="bg-[#EBEBEB]  w-full h-full">
        <div className="p-2 flex gap-2 h-full">
          <div
            className="w-1/5 bg-white h-full border rounded-lg"
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <Tablist />
          </div>
          <div className="w-4/5">
            <General defaultValuePK={defaultValuePK} />
          </div>
        </div>
      </div>
    </>
  )
}

export default OphthalmologyClinic

import { CaretDownOutlined, EditOutlined } from '@ant-design/icons'
import { Input, Select, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as typeAction from '../../../../../store/constants/constants'
import _, { cloneDeep } from 'lodash'

function InputMSDK({ items, idx, infoCN }) {
  const { listVPP } = useSelector((state) => state.NhapKhoReducer)
  const [isShowOption, setIsShowOption] = useState(false)
  const dispatch = useDispatch()

  const Changemsdk = (value) => {
    const newArr = []
    listVPP?.map((item) => {
      let infoVPP = cloneDeep(item)
      if (item?.idcn === infoCN?.idcn) {
        infoVPP.list[idx].khoChiTiet.maSoDangKy = value
      }
      newArr.push(infoVPP)
    })
    dispatch({
      type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
      payload: newArr,
    })
    // let { value } = e.target
    // const index = infoThuocVT.findIndex((items) => items?.id === id)
    // dispatch({
    //   type: typeAction.CHANGE_MSDK_NHAP_KHO,
    //   payload: {
    //     index,
    //     value: value,
    //   },
    // })
  }

  const handleChange = (value) => {
    // const index = infoThuocVT.findIndex((item) => item?.id === items?.id)
    // dispatch({
    //   type: typeAction.CHANGE_MSDK_NHAP_KHO,
    //   payload: {
    //     index,
    //     value: value,
    //   },
    // })
  }

  return (
    <div className="flex">
      {isShowOption ? (
        <>
          <Select
            // onChange={handleChange}
            onChange={Changemsdk}
            className="w-full"
            value={items?.khoChiTiet?.maSoDangKy}
            options={items?.optionsMSDK?.map((val) => ({
              label: val,
              value: val,
            }))}
            size="small"
          />
          <Tooltip title="Nhập mã số đăng kí" color="blue">
            <EditOutlined
              style={{ fontSize: '14px', marginLeft: '5px' }}
              onClick={() => setIsShowOption(false)}
            />
          </Tooltip>
        </>
      ) : (
        <>
          <Input
            onChange={(e) => Changemsdk(e.target.value)}
            size="small"
            value={items?.khoChiTiet?.maSoDangKy}
          />
          {items?.optionsMSDK?.length > 0 && (
            <Tooltip title="Chọn mã số đăng kí" color="green">
              <CaretDownOutlined
                style={{ fontSize: '14px', marginLeft: '5px' }}
                onClick={() => setIsShowOption(true)}
              />
            </Tooltip>
          )}
        </>
      )}
    </div>
  )
}

InputMSDK.propTypes = {}

export default InputMSDK

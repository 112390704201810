import React from 'react'
import { Row, Col, Card, Typography } from 'antd'

const { Title, Text } = Typography

export default function Info() {
  return (
    <div className="info-container" style={{ padding: '20px' }}>
      <Row gutter={16}>
        <Col span={12}>
          {/* Nội dung phần bên trái */}
          <Card bordered={false} style={{ backgroundColor: '#f0f2f5' }}>
            <Title level={4}>Thông tin xử lý</Title>
            <p>
              <Text strong>Người xử lý:</Text> Nguyễn Văn A
            </p>
            <p>
              <Text strong>Ngày xử lý:</Text> 01/01/2023
            </p>
            <p>
              <Text strong>Chi nhánh gửi:</Text> Chi nhánh Hà Nội
            </p>
          </Card>
        </Col>
        <Col span={12}>
          {/* Nội dung phần bên phải */}
          <Card bordered={false} style={{ backgroundColor: '#f0f2f5' }}>
            <Title level={4}>Thông tin nhận</Title>
            <p>
              <Text strong>Người nhận:</Text> Trần Thị B
            </p>
            <p>
              <Text strong>Ngày nhận:</Text> 02/01/2023
            </p>
            <p>
              <Text strong>Chi nhánh nhận:</Text> Chi nhánh Hồ Chí Minh
            </p>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

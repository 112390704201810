import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import {
  Button,
  ConfigProvider,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd'
import dayjs from 'dayjs'
import { formatNumber } from 'devextreme/localization'
import { useFormik } from 'formik'
import { cloneDeep } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import { getlistDoitac } from '../../../../store/actions/NhapKhoAction'
import { DISPATCH_CLEAR_INFO_DONHANG } from '../../../../store/constants/constants'
import ToastCus from '../../../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal

const vatOptions = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 5,
    label: '5%',
  },
  {
    value: 8,
    label: '8%',
  },
  {
    value: 10,
    label: '10%',
  },
]
const dateFormat = 'DD/MM/YYYY'
const dateFormatAPI = 'YYYY-MM-DD'

const listKhoTong = [
  {
    idkhotong: 32,
    tenkho: 'Kho Tổng Chấn Văn',
  },
  {
    idkhotong: 511,
    tenkho: 'Kho Tổng CV Health24',
  },
]
function ModalEdit({ openEdit, setOpenEdit, onLoad }) {
  const { inforDonHang } = useSelector((state) => state.vanPhongPhamReducer)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const { listDoiTac } = useSelector((state) => state.NhapKhoReducer)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [allDonDatHang, setAllDonDatHang] = useState([])
  const dispatch = useDispatch()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      iddathang: inforDonHang?.IDDATHANG,
      chinhanhdat: inforDonHang?.CHINHANHDAT,
      chinhanhnhan: inforDonHang?.CHINHANHNHAN,
      tendonhang: inforDonHang?.TENDONHANG,
      ghichudathang: inforDonHang?.GHICHU,
      nguoidat: inforDonHang?.NGUOIDAT,
      ngaydat: inforDonHang?.NGAYDAT,
      trangThaiDatHang: inforDonHang?.TRANGTHAIDATHANG,
      thoigiandathang: inforDonHang?.THOIGIANDATHANG,
      idkhotong: '',
    },
    onSubmit: (value) => handleSubmit(value),
  })

  useEffect(() => {
    if (openEdit.data?.idhang) {
      ;(async () => {
        try {
          const { data } = await https.get(
            `VPPDatHangTongHop/GetChiNhanhDatHang?idhang=${openEdit.data?.idhang}`,
          )
          setAllDonDatHang(
            data.map((item) => ({
              ...item,
              thanhtien: 0,
              tongtien: 0,
              vat: 0,
              dongia: item.giaban,
            })),
          )
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }, [openEdit.data?.idhang])

  const handleSubmit = async (value) => {
    const validData = cloneDeep(allDonDatHang).filter((val) => val.soluongduyet > 0)
    const dataSubmit = validData.map((item) => ({
      ...item,
      idhang: openEdit.data.idhang,
      dongia: item.dongia,
      thanhtien:
        item.vat === 0
          ? Math.floor(item.dongia)
          : Math.floor(item.dongia - item.dongia * (item.vat / 100)),
      tongtien:
        item.vat === 0
          ? Math.floor(item.dongia * item.soluongduyet)
          : Math.floor(item.dongia - item.dongia * (item.vat / 100)) * item.soluongduyet,
      vat: item.vat,
      soluong: item.soluongduyet,
    }))
    for (let i = 0; i < dataSubmit.length; i++) {
      if (!dataSubmit[i].idncc && !dataSubmit[i].idkhotong) {
        ToastCus.fire({
          icon: 'error',
          title: 'Vui lòng chọn nhà cung cấp hoặc kho tổng!',
          text: `Hàng hóa thứ ${i + 1} chưa chọn nhà cung cấp/kho tổng`,
        })
        return
      }
    }
    try {
      setLoadingBtn(true)
      await https.post(`VPPDatHangTongHop/AddVPPDatHangTongHop`, dataSubmit)
      ToastCus.fire({
        icon: 'success',
        title: 'Duyệt hàng thành công',
      })
      setOpenEdit(false)
      dispatch({ type: DISPATCH_CLEAR_INFO_DONHANG })
      onLoad()
    } catch (error) {
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message || 'Xảy ra lỗi khi duyệt hàng',
      })
    } finally {
      setLoadingBtn(false)
    }
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          size="small"
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchTable(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchTable(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleSearchTable = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const handleChangeInputNumber = (value, record) => {
    const findItemIdx = allDonDatHang.findIndex((item) => item.iddathang === record.iddathang)
    if (findItemIdx !== -1) {
      const cloneListHang = cloneDeep(allDonDatHang)
      cloneListHang[findItemIdx]['dongia'] = value
      setAllDonDatHang(cloneListHang)
    }
  }

  const handleChangeSelectInput = (value, record, key) => {
    const idxRecord = allDonDatHang.findIndex((item) => item.iddathang === record.iddathang)
    const cloneData = cloneDeep(allDonDatHang)
    cloneData[idxRecord][key] = value
    setAllDonDatHang(cloneData)
  }

  const handleChangeVat = (value, record) => {
    const findItemIdx = allDonDatHang.findIndex((item) => item.iddathang === record.iddathang)
    if (findItemIdx !== -1) {
      const cloneListHang = cloneDeep(allDonDatHang)
      cloneListHang[findItemIdx]['vat'] = value
      setAllDonDatHang(cloneListHang)
    }
  }

  const columnsHangHoa = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 30,
      align: 'center',
      render: (text, record, index) => index + 1,
      fixed: 'left',
    },
    {
      title: 'Chi nhánh đặt',
      dataIndex: 'chinhanhdat',
      key: 'chinhanhdat',
      width: 100,
      ...getColumnSearchProps('chinhanhdat'),
      fixed: 'left',
    },
    {
      title: 'Bộ phận duyệt',
      dataIndex: 'chinhanhnhan',
      key: 'chinhanhnhan',
      ...getColumnSearchProps('chinhanhnhan'),
      fixed: 'left',
      width: 180,
    },
    {
      title: 'SL mua',
      dataIndex: 'soluongduyet',
      key: 'soluongduyet',
      align: 'center',
      width: 40,
      fixed: 'left',
      render: (text, record) => (
        <span className={record.soluongduyet === 0 ? 'text-red-500 font-bold' : ''}>{text}</span>
      ),
    },
    {
      title: 'Đơn giá',
      dataIndex: 'dongia',
      key: 'dongia',
      align: 'center',
      width: 60,
      render: (text, record) => (
        <InputNumber
          onChange={(value) => handleChangeInputNumber(value, record)}
          name="dongia"
          className="w-full text-right"
          value={record.dongia}
          step="0.001"
          formatter={(value) => {
            const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
            return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }}
          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
          max={999999999}
          disabled={record.soluongduyet === 0}
        />
      ),
    },
    {
      title: 'VAT',
      dataIndex: 'vat',
      key: 'vat',
      align: 'center',
      width: 40,
      render: (text, record) => (
        <Select
          value={record.vat}
          options={vatOptions?.map(({ label, value }) => ({
            label,
            value,
          }))}
          onChange={(value) => handleChangeVat(value, record)}
          className="w-full"
          size="small"
          disabled={record.soluongduyet === 0}
        />
      ),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'thanhtien',
      key: 'thanhtien',
      align: 'center',
      width: 60,
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'tongtien',
      key: 'tongtien',
      align: 'center',
      width: 60,
    },
    {
      title: 'Nhà cung cấp',
      dataIndex: 'idncc',
      key: 'idncc',
      width: 180,
      render: (text, record) => (
        <Select
          value={record.idncc}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          onChange={(value) => handleChangeSelectInput(value, record, 'idncc')}
          options={listDoiTac?.map(({ idDoiTac, tenDoiTac }) => ({
            label: tenDoiTac,
            value: idDoiTac,
          }))}
          className="w-full text-left"
          size="small"
          disabled={record.soluongduyet === 0 || record.idkhotong}
          allowClear
        />
      ),
    },
    {
      title: 'Kho tổng',
      dataIndex: 'idkhotong',
      key: 'idkhotong',
      width: 180,
      render: (text, record) => (
        <Select
          value={record.idkhotong}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          onChange={(value) => handleChangeSelectInput(value, record, 'idkhotong')}
          options={listKhoTong?.map(({ idkhotong, tenkho }) => ({
            label: tenkho,
            value: idkhotong,
          }))}
          className="w-full text-left"
          size="small"
          allowClear
          disabled={record.soluongduyet === 0 || record.idncc}
        />
      ),
    },
    {
      title: 'Người đặt',
      dataIndex: 'nguoidat',
      key: 'nguoidat',
      width: 120,
    },
    {
      title: 'Ngày đặt',
      dataIndex: 'ngaydat',
      key: 'ngaydat',
      align: 'center',
      width: 60,
      render: (text, record) => moment(record.ngaydat).format('DD/MM/YYYY'),
    },
  ]

  const handleChangeDoiTac = useCallback(
    (name, value) => {
      formik.setFieldValue(name, value)
      setAllDonDatHang((prev) =>
        prev.map((item) => {
          if (item.soluongduyet === 0) {
            return item
          } else {
            if (name === 'idncc') {
              return {
                ...item,
                idncc: value,
                idkhotong: null,
              }
            } else {
              return {
                ...item,
                idkhotong: value,
                idncc: null,
              }
            }
          }
        }),
      )
    },
    [formik],
  )

  useEffect(() => {
    dispatch(getlistDoitac())
  }, [])

  const rowClassName = (record) => {
    return record.soluongduyet === 0 ? 'bg-gray-100 bg-opacity-50' : ''
  }

  const handleCancel = () => {
    formik.resetForm()
    setOpenEdit({
      show: false,
      data: null,
    })
    dispatch({ type: DISPATCH_CLEAR_INFO_DONHANG })
  }

  return (
    <Modal
      okText="Xác nhận"
      cancelText="Huỷ"
      className="text-center"
      title={'Chi tiết đặt hàng'}
      onOk={formik.handleSubmit}
      open={openEdit.show}
      onCancel={handleCancel}
      width={1500}
      style={{ top: 20 }}
      confirmLoading={loadingBtn}
    >
      <div className="flex text-base mb-2">
        <p>
          Mã hàng: <span className="font-medium">{openEdit.data?.mathuoc}</span>
        </p>
        <p className="ml-4">
          Tên hàng: <span className="font-medium">{openEdit.data?.tenbietduoc}</span>
        </p>
      </div>
      <div className="flex text-base">
        <p>
          Tổng số lượng đặt: <span className="font-medium">{openEdit.data?.soluongduyet}</span>
        </p>
      </div>
      <Divider />
      <div className="flex items-center mb-2">
        <div className="w-1/2 flex ">
          <label className="font-semibold w-32">
            Nhà cung cấp<span className="text-sx text-red-500">(*)</span>
          </label>
          <Select
            name="IdDoiTac"
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            value={formik.values.IdDoiTac}
            onChange={(value) => handleChangeDoiTac('idncc', value)}
            options={listDoiTac?.map(({ idDoiTac, tenDoiTac }) => ({
              label: tenDoiTac,
              value: idDoiTac,
            }))}
            className="w-[400px] text-left"
            size="small"
            disabled={formik.values.idkhotong}
            allowClear
          />
        </div>
        <div className="w-1/2 flex ">
          <label className="font-semibold w-32">
            Kho tổng<span className="text-sx text-red-500">(*)</span>
          </label>
          <Select
            name="idkhotong"
            status={formik.errors.idkhotong ? 'error' : ''}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            value={formik.values.idkhotong}
            onChange={(value) => handleChangeDoiTac('idkhotong', value)}
            options={listKhoTong?.map(({ idkhotong, tenkho }) => ({
              label: tenkho,
              value: idkhotong,
            }))}
            className="w-[400px] text-left"
            size="small"
            disabled={formik.values.idncc}
            allowClear
          />
        </div>
      </div>
      <p className="text-left mb-2">
        <span className="text-sx text-red-500">(*)</span>: Chọn cho tất cả chi nhánh
      </p>
      <div className="overflow-auto h-[500px]">
        <ConfigProvider
          theme={{
            token: {
              padding: 4,
              fontSize: 13,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{ y: 450, x: 2000 }}
            bordered
            pagination={false}
            columns={columnsHangHoa}
            rowClassName={rowClassName}
            dataSource={
              allDonDatHang.map((item) => ({
                ...item,
                key: item.idHang,
                thanhtien:
                  item.vat === 0
                    ? formatNumber(Math.floor(item.dongia))
                    : formatNumber(Math.floor(item.dongia - item.dongia * (item.vat / 100))),
                tongtien:
                  item.vat === 0
                    ? formatNumber(Math.floor(item.dongia * item.soluongduyet))
                    : formatNumber(
                        Math.floor(item.dongia - item.dongia * (item.vat / 100)) *
                          item.soluongduyet,
                      ),
                // action: (
                //   <ul className="flex gap-2 justify-around">
                //     {item.soluongduyet !== 0 && (
                //       <li className="text-lg text-green-500">
                //         <Tooltip title="Tách hàng hoá" color="green">
                //           <PlusOutlined onClick={() => handleAddItem(item)} />
                //         </Tooltip>
                //       </li>
                //     )}
                //   </ul>
                // ),
              })) || []
            }
          />
        </ConfigProvider>
      </div>
    </Modal>
  )
}

ModalEdit.propTypes = {}

export default ModalEdit

import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ConfigProvider, DatePicker, Input, Layout, Space, Table, Tooltip } from 'antd'
import LayoutApp from '../../../HOCs/LayoutApp'
import { formattedNumber } from '../../../utils/formattedNumber'
import moment from 'moment'
import dayjs from 'dayjs'
import SyncIcon from '@mui/icons-material/Sync'
import { IconButton } from '@mui/material'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { formatNumberVND } from '../../../utils/formatNumberVND'
import Highlighter from 'react-highlight-words'
import { ContainerOutlined, SearchOutlined } from '@ant-design/icons'
import ModalAdd from './Modal/ModalAdd'
import { useDispatch, useSelector } from 'react-redux'
import { getAllKhachHangCRM, getKhachHangCRMById } from '../../../store/actions/khachhangCRMAction'
import ModalEdit from './Modal/ModalEdit'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
function DoanhThu(props) {
  const { listKH } = useSelector((state) => state.khachhangCRMReducer)
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [statis, setStatis] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (listKH) {
      setStatis([
        {
          title: 'TỔNG TIỀN DOANH THU',
          number: formattedNumber(listKH?.reduce((total, item) => total + +item.doanhthu, 0)),
        },
        {
          title: 'TỔNG TIỀN GIẢM GIÁ',
          number: formattedNumber(listKH?.reduce((total, item) => total + +item.giamgia, 0)),
        },
        {
          title: 'TỔNG TIỀN CHIẾT KHẤU',
          number: formattedNumber(listKH?.reduce((total, item) => total + +item.chietkhau, 0)),
        },
      ])
    }
  }, [listKH])

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columns = [
    {
      title: 'Tên khách hàng',
      dataIndex: 'tenkh',
      align: 'left',
      key: 'tenkh',
      className: 'text-sm',
      ...getColumnSearchProps('tenkh'),
    },
    {
      title: 'Người liên hệ',
      dataIndex: 'nguoilienhe',
      align: 'left',
      key: 'nguoilienhe',
      className: 'text-sm',
      ...getColumnSearchProps('nguoilienhe'),
    },
    {
      title: 'Doanh thu',
      dataIndex: 'doanhthu',
      key: 'doanhthu',
      align: 'right',
      className: 'text-sm',
      width: 150,
      render: (text) => formatNumberVND(text),
    },
    {
      title: 'Giảm giá',
      dataIndex: 'giamgia',
      key: 'giamgia',
      align: 'right',
      className: 'text-sm',
      width: 150,
      render: (text) => formatNumberVND(text),
    },
    {
      title: 'Chiết khấu',
      dataIndex: 'chietkhau',
      key: 'chietkhau',
      align: 'right',
      className: 'text-sm',
      width: 150,
      render: (text) => formatNumberVND(text),
    },
    {
      title: 'Nhân viên',
      dataIndex: 'tennguoitao',
      key: 'tennguoitao',
      align: 'left',
      className: 'text-sm',
      ...getColumnSearchProps('tennguoitao'),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngaytao',
      key: 'ngaytao',
      align: 'left',
      className: 'text-sm',
      width: 150,
      render: (text) => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      align: 'left',
      className: 'text-sm',
    },
    {
      title: '',
      dataIndex: 'action',
      width: 50,
      align: 'center',
      key: 'action',
      fixed: 'right',
    },
  ]

  const onLoad = () => {
    dispatch(getAllKhachHangCRM())
  }

  useEffect(() => {
    onLoad()
  }, [])

  const handleShowChitiet = (item) => {
    dispatch(getKhachHangCRMById(item.idkh))
    setOpenEdit(true)
  }

  return (
    <>
      <div className="h-full w-full bg-[#EBEBEB] p-2 border ">
        <div
          className="border mt-2 rounded-md bg-white h-[95%]"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <div className="p-2">
            <ul className="flex border rounded-md">
              {statis?.map((items) => (
                <li className="flex flex-col gap-2 w-1/3 border-r p-2">
                  <div className="flex justify-between">
                    <h2 className="font-semibold text-gray-500 xl:text-base text-xs">
                      {items.title}
                    </h2>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        className="size-5 text-green-700 border-2 border-green-500  rounded-full "
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                  <span className="text-lg font-semibold">{formattedNumber(items.number)}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex gap-5 justify-between border-dotted border-b-0 p-2 ">
            <div className="flex gap-5">
              {/* <div className="flex items-center gap-3">
                <DatePicker
                  allowClear={false}
                  defaultValue={dayjs(dateForm)}
                  maxDate={dayjs(now)}
                  onChange={handleDatePicker('form')}
                  format={dateFormat}
                />
                <span className="font-semibold text-lg">-</span>
                <DatePicker
                  allowClear={false}
                  defaultValue={dayjs(dateTo)}
                  maxDate={dayjs(now)}
                  onChange={handleDatePicker('to')}
                  format={dateFormat}
                />
              </div>
              <div className="flex gap-3">
                <IconButton color="primary" aria-label="delete" size="small">
                  <SyncIcon />
                </IconButton>
              </div> */}
              {/* <div className="font-semibold ">
                <Button
                  //   onClick={exportToExcel}
                  type="text"
                  size="middle"
                  className="text-green-700 p-1"
                  icon={<ImportExportIcon />}
                >
                  Xuất Excel
                </Button>
              </div> */}
            </div>
            <div className="ml-auto w-80">
              {/* <Input
                // value={search}
                placeholder="Tìm kiếm"
                // onChange={handleSearchInput}
                prefix={<SearchOutlined />}
                className="w-full"
              /> */}
            </div>
            <div className="px-2">
              <Button type="primary" onClick={() => setOpen(true)}>
                Thêm khách hàng
              </Button>
            </div>
          </div>
          <div className="p-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
        
              }}
            >
              <Table
                pagination={{
                  pageSize: 20,
                  showSizeChanger: false,
                }}
                columns={columns}
                dataSource={listKH?.map((item) => ({
                  ...item,
                  action: (
                    <ul className="flex gap-2 justify-around">
                      <li className="text-lg">
                        <Tooltip title="Chi tiết" color="#108ee9">
                          <ContainerOutlined
                            onClick={() => handleShowChitiet(item)}
                            className="text-xl text-[#108ee9]  cursor-pointer"
                          />
                        </Tooltip>
                      </li>
                    </ul>
                  ),
                }))}
                bordered
              />
            </ConfigProvider>
          </div>
        </div>
        {open && <ModalAdd open={open} setOpen={setOpen} onLoad={onLoad} />}
        {openEdit && <ModalEdit openEdit={openEdit} setOpenEdit={setOpenEdit} onLoad={onLoad} />}
      </div>
    </>
  )
}

DoanhThu.propTypes = {}

export default DoanhThu

import { CloseOutlined, ContainerOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { Button, ConfigProvider, Input, Pagination, Select, Table, Tooltip } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { https } from '../../../services/apiService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import {
  getInfoBN_TT_Action,
  getList_BN_BY_CHINHANH_CTY,
} from '../../../store/actions/traCuuAction'
import * as typeAction from '../../../store/constants/constants'
import CreateCustomer from '../../common/CreateCustomers/CreateCustomer'
import DrawerTT from './DrawerTT/DrawerTT'
import 'moment/locale/vi'
moment.locale('vi')

const BenhNhan = () => {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { listBN } = useSelector((state) => state.traCuuReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState('')
  const [chiNhanh, setChiNhanh] = useState(null) //+localStorage.getItem('BRANH_LOGIN') fix load lần đầu
  const [totalPage, setTotalPage] = useState(1)
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [activeRow, setActiveRow] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)

  useEffect(() => {
    dispatch(listAllCompanyAction())
    dispatch(getList_BN_BY_CHINHANH_CTY(company, chiNhanh, '', 1, setIsLoading)) //fix load lần đầu
    // getAllCN_By_idct(company)
  }, [])

  const columnsInfoBN = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      key: 'stt',
      width: 60,
    },
    {
      title: 'Mã phiếu thu',
      dataIndex: 'maPhieuThu',
      align: 'left',
      key: 'maPhieuThu',
      width: 150,
    },
    {
      title: 'Mã toa thuốc',
      dataIndex: 'maToaThuoc',
      align: 'left',
      key: 'maToaThuoc',
      width: 150,
    },
    {
      title: 'Ngày khám',
      dataIndex: 'ngayKham',
      align: 'left',
      key: 'ngayKham',
      width: 150,
      render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm') : ''),
    },
    {
      title: 'Mã bệnh nhân',
      dataIndex: 'maBenhNhan',
      align: 'left',
      key: 'maBenhNhan',
      render: (text, record) => (
        <p className="text-blue-500 cursor-pointer" onClick={() => handleOpenDrawer(record)}>
          {text}
        </p>
      ),
      width: 150,
    },
    {
      title: 'Tên bệnh nhân',
      dataIndex: 'tenBenhNhan',
      key: 'tenBenhNhan',
      align: 'left',
      width: 250,
    },
    {
      title: 'Giới tính',
      dataIndex: 'gioiTinh',
      align: 'center',
      key: 'gioiTinh',
      width: 80,
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaySinh',
      key: 'ngaySinh',
      align: 'center',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : ''),
      width: 100,
    },
    {
      title: 'Tuổi',
      dataIndex: 'tuoi',
      key: 'tuoi',
      align: 'center',
      width: 180,
    },
    {
      title: 'SĐT',
      dataIndex: 'dienThoai',
      width: 120,
      align: 'center',
      key: 'dienThoai',
      render: (text, record) => (record.idbn === activeRow?.idbn ? <p>{text}</p> : ''),
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'diaChi',
      key: 'diaChi',
      width: 800,
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenChiNhanh',
      key: 'tenChiNhanh',
      width: 250,
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      fixed: 'right', // Fix the last column
    },
  ]

  const getAllCN_By_idct = async (value) => {
    try {
      const { data } = await https.get(`ChiNhanh/GetAllByIdCTNotVanPhong?idCT=${value}`)
      setListChiNhanh(data)
    } catch (error) {
      console.log(error)
    }
  }

  const onClickCreateCustomer = () => {
    dispatch({
      type: typeAction.OPEN_MODAL_CREATE_CUSTOMER,
    })
  }

  const debounceSearch = useCallback(
    _.debounce((keyword, company, chiNhanh) => {
      dispatch(getList_BN_BY_CHINHANH_CTY(company, chiNhanh, keyword, 1, setIsLoading))
    }, 500),
    [],
  )

  const onClose = () => {
    setOpen(false)
  }

  const handleSearchInput = (e, company, chiNhanh) => {
    const { value } = e.target
    debounceSearch(value.trim(), company, chiNhanh)
    setSearch(value)
  }

  const handleClearSearch = () => {
    setSearch('')
    dispatch(getList_BN_BY_CHINHANH_CTY(company, chiNhanh, '', 1, setIsLoading))
    //get lai data
  }

  const handleOpenDrawer = (item) => {
    dispatch(getInfoBN_TT_Action(item.idbn, PkDangNhap?.idChuyenKhoa))
    setOpen(true)
  }

  const handleCompany = (value, search) => {
    setCompany(value)
    setChiNhanh('')
    if (value === '') {
      setListChiNhanh([])
      //call api get all
      dispatch(getList_BN_BY_CHINHANH_CTY('', '', search.trim(), 1, setIsLoading))
      return
    }
    getAllCN_By_idct(value)
  }

  const handleChiNhanh = (value) => {
    setChiNhanh(value)
    setCurrentPage(1)
    SetSearchParams({ page: 1 })
    dispatch(getList_BN_BY_CHINHANH_CTY(company, value, search.trim(), 1, setIsLoading))
  }

  const handleClickRow = (record) => {
    setActiveRow(record)
  }

  const exportToExcel = async (keyword, chiNhanh, company) => {
    setLoadingExport(true)
    try {
      const response = await https.get(
        `BenhNhan/DownloadBenhNhanByCondition?idChiNhanh=${chiNhanh}&idCongTy=${company}&keyword=${keyword}`,
        {
          responseType: 'blob', // Important
        },
      )

      // Create a Blob from the response
      const blob = new Blob([response.data], { type: 'application/octet-stream' })
      // Create a download link and click it programmatically
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Danh sách bệnh nhân.xlsx')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      // Optionally set state or do other operations after download
    } catch (error) {
      console.error('Error downloading file:', error)
    } finally {
      setLoadingExport(false)
    }
  }

  const handleChangePage = (company, chiNhanh, search, page) => {
    setCurrentPage(page)
    SetSearchParams({ page })
    dispatch(getList_BN_BY_CHINHANH_CTY(company, chiNhanh, search, page, setIsLoading))
  }

  useEffect(() => {
    if (listBN?.totalPage) {
      setTotalPage(listBN?.totalPage)
    }
  }, [listBN?.totalPage])

  return (
    <>
      <div className="h-full bg-[#EFEFEF] p-5">
        <div className="h-full bg-white border rounded-xl">
          <div className="w-full border-b flex justify-between">
            <div className="p-2 flex gap-5">
              <Select
                showSearch
                className="w-80"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={(value) => handleCompany(value, search)}
                value={company}
                options={[
                  { label: 'Tất cả', value: '' },
                  ...(listCompany ?? []).map((items) => ({
                    label: items.tenct,
                    value: items.idct,
                  })),
                ]}
              />

              <Select
                showSearch
                className="w-80"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                // filterSort={(optionA, optionB) =>
                //   (optionA?.label ?? '')
                //     .toLowerCase()
                //     .localeCompare((optionB?.label ?? '').toLowerCase())
                // }
                onChange={handleChiNhanh}
                value={chiNhanh}
                options={[
                  { label: 'Tất cả', value: '' },
                  ...(listChiNhanh ?? []).map((items) => ({
                    label: items.tenChiNhanh,
                    value: items.idChiNhanh,
                  })),
                ]}
                disabled={!company}
              />
              {/* <Button
                disabled={loadingExport}
                loading={loadingExport}
                onClick={() => exportToExcel(search, chiNhanh, company)}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
              >
                Xuất Excel
              </Button> */}
            </div>
            <div className="p-2 flex gap-5 w-[400px]">
              <Input
                value={search}
                placeholder="Tìm kiếm"
                onChange={(e) => handleSearchInput(e, company, chiNhanh)}
                prefix={<SearchOutlined />}
                suffix={
                  search?.length > 0 && (
                    <CloseOutlined onClick={() => handleClearSearch(company, chiNhanh)} />
                  )
                }
              />
              <Tooltip title="Tạo hồ sơ">
                <Button
                  onClick={onClickCreateCustomer}
                  icon={<PlusOutlined />}
                  type="primary"
                  ghost
                  shape="circle"
                ></Button>
              </Tooltip>
            </div>
          </div>

          <div className="mt-2 p-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                loading={isLoading}
                scroll={{ x: 2200 }}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      handleClickRow(record)
                    }, // click row
                  }
                }}
                bordered
                pagination={false}
                columns={columnsInfoBN}
                dataSource={listBN?.data?.map((item, index) => ({
                  stt: (currentPage - 1) * 20 + (index + 1),
                  ...item,
                  maToaThuoc: `MTT${item.maBenhNhan}`,
                  diaChi: item.diaChiDayDu,
                  action: (
                    <ul className="flex gap-2 justify-around">
                      <li className="text-lg">
                        <Tooltip title="Chi tiết" color="#108ee9">
                          <ContainerOutlined
                            onClick={() => handleOpenDrawer(item)}
                            className="text-xl text-[#108ee9]  cursor-pointer"
                          />
                        </Tooltip>
                      </li>
                    </ul>
                  ),
                }))}
              />
            </ConfigProvider>
            <div className="flex justify-end">
              <Pagination
                className="mt-5"
                showSizeChanger={false}
                current={+useSearch.get('page')}
                simple
                defaultCurrent={1}
                pageSize={20}
                total={20 * totalPage}
                onChange={(page, pagesize) => {
                  handleChangePage(company, chiNhanh, search, page)
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <DrawerTT open={open} onClose={onClose} company={company} chiNhanh={chiNhanh} />
      <CreateCustomer />
    </>
  )
}

export default BenhNhan

import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { xuatNoiBoService } from '../../services/xuatNoiBo/xuatNoiBoService'
import ToastCus from '../../utils/Toast'
import { generatePhieuXuatBanKhachHang } from '../../utils/report/phieuThuTienXuatBanKhachHang'

export function* xuatNoiBoSaga() {
  // POST PHIẾU XUẤT NỘI BỘ
  yield takeLatest(
    typeAction.POST_PHIEU_XUAT_NOI_BO,
    function* postPhieuXuatNoiBoSaga({ type, form, list, infoBN, resetForm }) {
      try {
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data: infoPhieu } = yield call(() => xuatNoiBoService.postPhieuXuatNoiBo(form))
        const formatData = list?.map((item) => ({
          idthuoc: item?.idthuoc,
          tenthuoc: item?.tenbietduoc,
          // tenhoatchat: item?.tenhoatchat,
          solo: item?.solo,
          handung: item?.handung,
          sohoadon: item?.sohoadon,
          soluong: item?.soluong,
          dongia: item?.dongia,
          thanhtien: item?.dongia * item?.soluong,
          tiengiamgia: 0,
          tienthanhtoan: item?.dongia * item?.soluong,
          // masodangky: item?.masodangky,
          ghichu: item?.ghichu,
          stt: item?.stt,
          mathuoc: item?.mathuoc,
          dvt: item?.dvt,
          idxnb: infoPhieu?.idxnb,
        }))
        const result = yield call(() => xuatNoiBoService.postListVTHH(formatData))
        yield call(() => resetForm())
        //////////////////////////////////////////////
        yield call(() =>
          generatePhieuXuatBanKhachHang(
            { ...infoPhieu, tenbenhnhan: infoBN?.tenBenhNhan, mabenhnhan: infoBN?.maBenhNhan },
            result.data,
            PkDangNhap,
          ),
        )

        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}

import * as Yup from 'yup'

export const ChildrenClinicSchema = Yup.object().shape({
  lydokham: Yup.string().required('Vui lòng nhập lý do khám của bệnh !'),
  benhsu: Yup.string().required('Vui lòng nhập bệnh sử của bệnh nhân !'),
  trieuchung: Yup.string().required('Vui lòng nhập triệu chứng của bệnh nhân !'),
  loidan: Yup.string().required('Vui lòng nhập lời dặn !'),
  ngaytaikham: Yup.string().required('Vui lòng chọn ngày tái khám'),
  lydokolaythuoc: Yup.string().required(),
})

export const KSLClinicSchema = Yup.object().shape({
  // lydokham: Yup.string().required(),
  // benhsu: Yup.string().required(),
  // trieuchung: Yup.string().required(),
  // loidan: Yup.string().required(),
  ngaytaikham: Yup.string().required(),
  lydokolaythuoc: Yup.string().required(),
})

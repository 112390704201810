import { https } from '../apiService'

export const monitorService = {

  getBNPendingMonitor: (idChiNhanh) =>
    https.get(`BenhNhanMonitor/BenhNhanChoMonitor?idcn=${idChiNhanh}`),
  getDetaiMonitor: (idbnttc) => https.get(`BenhNhanXetNghiem/GetThongTinBenhNhanXetNghiem/${idbnttc}`),
  AddBnMonitor: (form) => https.post(`BenhNhanMonitor/ThemBenhNhanMonitor`,form),
  getInfoBNTTC: (idbnttc) => https.get(`BenhNhanTTC/GetByIDBNTTC?id=${idbnttc}`),
  
}

import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import {
  Button as BtnAntd,
  ConfigProvider,
  Input,
  Pagination,
  Table,
  Tabs,
  Modal,
  Select,
  DatePicker,
  notification,
} from 'antd'
import {
  SaveOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleFilled,
  CheckOutlined,
} from '@ant-design/icons'
import Button from '@mui/material/Button'
import CachedIcon from '@mui/icons-material/Cached'
import { addPhanAnhSchema } from '../../../schemas/addPhanAnhSchema'
import { branchService } from '../../../services/branch/branchService'
import { phanAnhServices } from '../../../services/phanAnh/phanAnhServices'
import { postPhanAnhAction, putPhanAnhAction } from '../../../store/actions/phanAnhAction'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { https } from '../../../services/apiService'
import ToastCus from '../../../utils/Toast'
import { v4 as uuidv4 } from 'uuid'
import _, { set } from 'lodash'
import moment from 'moment'
import dayjs from 'dayjs'
import style from './style.module.css'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal
const TiepNhan = () => {
  const dispatch = useDispatch()
  const now = moment()
  const dateFormat = 'DD/MM/YYYY'
  const dateFormatAPI = 'YYYY-MM-DD'
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [listCN, setListCN] = useState([])
  // const [cnDang, setCnDang] = useState(ID_BRANCH)
  const [cnDa, setCnDa] = useState(ID_BRANCH)
  const [dateFrom, setDateFrom] = useState(now.format(dateFormatAPI))
  const [dateTo, setDateTo] = useState(now.format(dateFormatAPI))
  const [tabValue, setTabValue] = useState(1)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [selectedFile, setSelectedFile] = useState([])
  const [listLoaiPA, setListLoaiPA] = useState([])
  const [listBoPhanXuLy, setListBoPhanXuLy] = useState([])
  const [listChoDangDaXuLyPA, setListChoDangDaXuLyPA] = useState([])
  const [listDaHoanThanhPA, setListDaHoanThanhPA] = useState([])
  const fileInputRef = useRef(null)
  const dropzoneRef = useRef(null)
  const [api, contextHolder] = notification.useNotification()
  //############# FORM ############
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // MAPAKH: '',
      TENPAKH: '',
      // NOIDUNG: '',
      IDLOAIPAKH: listLoaiPA?.length > 0 ? listLoaiPA[0]?.idloaipakh : null,
      // HINHANH: '',
      NGUOITIEPNHAN: listBoPhanXuLy?.length > 0 ? listBoPhanXuLy[0]?.idChiNhanh : null, // id chi nhánh - bộ phận xử lý
      // IDCHINHANH: ID_BRANCH, // id chi nhánh đăng nhập thao tác lưu
      GHICHU: '',
      NGUOIPHANANH: '',
      SODIENTHOAI: '',
      DIACHI: '',
      // nguoitao: 0,
      // nguoisua: 0,
    },
    onSubmit: (values) => handleSubmit(values),
    validationSchema: addPhanAnhSchema,
  })
  const handleSubmit = async (values) => {
    const contentState = editorState.getCurrentContent()
    const row = convertToRaw(contentState)
    // const {blocks,...other} = row
    const dataNoiDung = JSON.stringify(row)
    if (values?.IDPAKH) {
      dispatch(
        putPhanAnhAction(
          {
            idpakh: values?.IDPAKH,
            // mapakh: "string",
            tenpakh: values?.TENPAKH,
            noidung: dataNoiDung,
            idloaipakh: values?.IDLOAIPAKH,
            // hinhanh: "string",
            // nguoitao: 0,
            // ngaytao: "2024-08-24T06:44:53.135Z",
            nguoitiepnhan: values?.NGUOITIEPNHAN,
            // idchinhanh: 0,
            // trangthai: 0,
            ghichu: values?.GHICHU,
            nguoisua: infoUser?.idnv,
            // ngaysua: "2024-08-24T06:44:53.135Z",
            nguoiphananh: values?.NGUOIPHANANH,
            sodienthoai: values?.SODIENTHOAI,
            diachi: values?.DIACHI,
          },
          getListPhanAnhByTrangThai,
        ),
      )
    } else {
      const formData = new FormData()
      // formData.append('MAPAKH', values.MAPAKH)
      formData.append('TENPAKH', values.TENPAKH)
      formData.append('IDLOAIPAKH', values.IDLOAIPAKH)
      formData.append('NGUOITAO', infoUser?.idnv)
      formData.append('NGUOITIEPNHAN', values.NGUOITIEPNHAN)
      formData.append('IDCHINHANH', ID_BRANCH)
      formData.append('GHICHU', values.GHICHU)
      formData.append('NGUOIPHANANH', values.NGUOIPHANANH)
      formData.append('SODIENTHOAI', values.SODIENTHOAI)
      formData.append('DIACHI', values.DIACHI)
      formData.append('NOIDUNG', dataNoiDung)

      if (selectedFile?.length !== 0) {
        const dataHinhAnh = selectedFile.map((item) => item.file)
        dataHinhAnh?.forEach((file, index) => {
          formData.append('files', file)
        })
        // const formDataObj = Object.fromEntries(formData.entries())
        // console.log(formDataObj)
        // for (let pair of formData.entries()) {
        //   console.log(pair)
        // }
        // console.log(formData)
      }
      dispatch(postPhanAnhAction(formData, ID_BRANCH, getListPhanAnhByTrangThai))
    }
    handleResetForm()
    // if (_.isEmpty(values)) {
    // } else {
    //   ToastCus.fire({
    //     icon: 'error',
    //     title: 'Vui lòng kiểm tra lại!',
    //   })
    //   return
    // }
  }
  const handleResetForm = () => {
    formik.resetForm()
    setEditorState(EditorState.createEmpty())
    setSelectedFile([])
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Clear the input
    }
  }
  const openNotificationWithIcon = () => {
    api.error({
      message: 'Chọn file đính kèm',
      description: 'Chỉ chấp nhận tệp là hình ảnh',
    })
  }
  const deleteFileById = (id) => {
    const updatedData = selectedFile.filter((item) => item.id !== id)
    setSelectedFile(updatedData)
  }
  const handleDivClick = () => {
    fileInputRef.current.click()
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px solid #000'
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
    handleFileChange(e.dataTransfer.files[0])
    // const files = Array.from(e.dataTransfer.files)
    //  setFileList(files);
  }
  const handleFileSelect = (e) => {
    // let file = e.target.files[0]
    handleFileChange(e.target.files[0])
  }
  const onEditorStateChange = (newState) => {
    setEditorState(newState)
  }
  const handleFileChange = async (file) => {
    //  let file = event.target.files[0]
    // List of allowed file types
    const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp']
    if (!allowedFileTypes.includes(file.type)) {
      openNotificationWithIcon()
      fileInputRef.current.value = '' // clear the input
      return
    }
    // formik.setFieldValue('HoaDonFile', file)
    // console.log(formData);
    fileInputRef.current.value = ''
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      const localUrl = URL.createObjectURL(file)
      setSelectedFile((preState) => [
        ...preState,
        { base64: e.target.result, URL: localUrl, name: file.name, id: uuidv4(), file },
      ])
    }
  }
  const showConfirm = (url) => {
    confirm({
      title: 'Xóa hình phản ảnh khách hàng',
      icon: <ExclamationCircleFilled />,
      content: (
        <>
          <p>
            Bạn có muốn xóa hình:
            <span className="font-semibold ml-1">
              {formik.values.MAPAKH} - {formik.values.TENPAKH}
            </span>
          </p>
          <div className="w-[10rem] h-[10rem] mt-1 border">
            <img src={url} alt={url} className="w-full" />
          </div>
        </>
      ),
      okText: 'Đồng ý', // Thay đổi văn bản nút "OK"
      cancelText: 'Hủy bỏ', // Thay đổi văn bản nút "Cancel"
      onOk() {
        deleteFile(url)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  //------- hoàn thành phản ảnh khách hàng ---------/
  const handleHoanThanh = async () => {
    if (!formik.values.IDPAKH) {
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
      return
    }
    try {
      await phanAnhServices.putTrangThaiPhanAnh(formik.values.IDPAKH, 4, infoUser?.idnv) // 4 = trạng thái hoàn thành
      handleResetForm()
      getListPhanAnhByTrangThai()
      getListPhanAnhDaHoanThanh()
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    }
  }
  //------- delete hình phản ảnh ---------/
  const deleteFile = async (url) => {
    try {
      const idpakh = formik.values.IDPAKH
      await phanAnhServices.deleteHinhPhanAnh(idpakh, url)
      getInfoPhanAnh(idpakh)
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    }
  }
  //----- upload hình phản ảnh-------//
  const handleFileChangeUpload = async (event) => {
    if (event.target.files?.length === 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
      return
    }
    try {
      let file = await event.target.files[0]
      const idpakh = formik.values.IDPAKH
      const formData = new FormData()
      formData.append('IDPAKH', idpakh)
      formData.append('IDCN', ID_BRANCH)
      formData.append('HINHANH', file)
      await phanAnhServices.uploadHinhPhanAnh(formData)
      getInfoPhanAnh(idpakh)
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lưu tệp đính kèm thất bại!',
      })
    } finally {
      fileInputRef.current.value = '' // clear the input
    }
  }
  //-------- lấy danh sách chi nhánh theo công ty đăng nhập -------//
  const fetchBranchByCompany = async () => {
    try {
      const { data } = await branchService.getListBranch(ID_COMPANY)
      setListCN(data)
    } catch (error) {
      console.log(error)
    }
  }
  //-------- lấy danh sách loại phản ảnh -------//
  const getListLoaiPhanAnh = async () => {
    try {
      const { data } = await phanAnhServices.getListLoaiPhanAnh()
      setListLoaiPA(data)
    } catch (error) {
      console.log(error)
    }
  }
  //-------- lấy danh sách bộ phận xử lý phản ảnh -------//
  const getListBoPhanXuLy = async () => {
    try {
      const { data } = await phanAnhServices.getListBoPhanXuLy()
      setListBoPhanXuLy(data)
    } catch (error) {
      console.log(error)
    }
  }
  //-------- lấy danh sách phản ảnh khách hàng chờ xử lý, đang xử lý, đã xử lý -------//
  const getListPhanAnhByTrangThai = async () => {
    try {
      const { data } = await phanAnhServices.getListPhanAnhByTrangThai(ID_BRANCH, tabValue)
      setListChoDangDaXuLyPA(data)
    } catch (error) {
      console.log(error)
    }
  }
  //-------- lấy danh sách phản ảnh khách hàng đã hoàn thành -------//
  const getListPhanAnhDaHoanThanh = async () => {
    try {
      const { data } = await phanAnhServices.getListPhanAnhDaHoanThanh(cnDa, dateFrom, dateTo)
      setListDaHoanThanhPA(data)
    } catch (error) {
      console.log(error)
    }
  }
  //-------- lấy danh sách phản ảnh khách hàng đã hoàn thành -------//
  const getInfoPhanAnh = async (idpakh) => {
    try {
      const { data } = await phanAnhServices.getInfoPhanAnh(idpakh)
      formik.setValues((prev) => ({
        ...prev,
        IDPAKH: data?.IDPAKH,
        MAPAKH: data?.MAPAKH,
        TENPAKH: data?.TENPAKH,
        IDLOAIPAKH: data?.IDLOAIPAKH,
        HINHANH: data?.HINHANH,
        NGUOITIEPNHAN: data?.IDCHINHANHTIEPNHAN,
        TRANGTHAI: data?.IDTRANGTHAI,
        GHICHU: data?.GHICHU,
        NGUOIPHANANH: data?.NGUOIPHANANH,
        SODIENTHOAI: data?.SODIENTHOAI,
        DIACHI: data?.DIACHI,
        NGUOITAO: data?.NGUOITAO,
        NGAYTAO: data?.NGAYTAO,
        IDNGUOITAO: data?.IDNGUOITAO,
      }))
      const convertJson = JSON.parse(data?.NOIDUNG)
      const contentState = convertFromRaw(convertJson)
      setEditorState(EditorState.createWithContent(contentState))
      // setEditorState()
      // setSelectedFile()
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchBranchByCompany()
    getListLoaiPhanAnh()
    getListBoPhanXuLy()
    getListPhanAnhDaHoanThanh()
  }, [])
  useEffect(() => {
    if (tabValue) getListPhanAnhByTrangThai()
    else setListChoDangDaXuLyPA(null)
  }, [tabValue])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã phản ảnh',
      dataIndex: 'mapakh',
      key: 'mapakh',
      width: 120,
    },
    {
      title: 'Tên phản ảnh',
      dataIndex: 'tenpakh',
      key: 'tenpakh',
    },
  ]

  return (
    <div className="p-3 h-full bg-[#EFEFEF]">
      {contextHolder}
      <div className="h-full p-3 bg-white border rounded-xl grid grid-cols-4 gap-2">
        <div className="border h-full rounded-lg grid grid-rows-2 gap-2">
          <div className="border-b">
            <div className="p-2">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 0,
                    margin: 0,
                  },
                }}
              >
                <Tabs
                  defaultActiveKey={1}
                  items={[
                    {
                      key: 1,
                      value: 1,
                      label: 'Chờ xác nhận',
                    },
                    {
                      key: 2,
                      value: 2,
                      label: 'Đang xử lý',
                    },
                    {
                      key: 3,
                      value: 3,
                      label: 'Đã xử lý',
                    },
                  ]}
                  value={tabValue}
                  onChange={setTabValue}
                />
              </ConfigProvider>
              <div className="mt-2">
                <ConfigProvider
                  theme={{
                    token: { padding: 5 },
                    components: {
                      Table: {
                        rowHoverBg: '#ecf0f1',
                        headerBg: '#e6e6e6',
                        footerBg: '#e6e6e6',
                        borderColor: '#BABABA',
                      },
                    },
                  }}
                >
                  <Table
                    pagination={false}
                    dataSource={listChoDangDaXuLyPA ?? []}
                    columns={columns}
                    onRow={(record) => ({
                      onClick: () => getInfoPhanAnh(record?.idpakh),
                    })}
                    bordered
                    scroll={{ y: 295 }}
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
          <div className="">
            <div className="p-2 pt-0">
              <h2 className="font-semibold  text-lg text-gray-500">Đã hoàn thành</h2>
              <div className="flex items-center justify-center">
                <DatePicker
                  allowClear={false}
                  format={dateFormat}
                  maxDate={dayjs(now)}
                  className="w-2/5"
                  defaultValue={dayjs(dateFrom)}
                  value={dayjs(dateFrom)}
                  onChange={(date, dateString) => {
                    setDateFrom(moment(dateString, dateFormat).format(dateFormatAPI))
                    // setDateTo(moment(dateString, dateFormat).format(dateFormatAPI))
                  }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6 w-1/5"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                </svg>
                <DatePicker
                  format={dateFormat}
                  allowClear={false}
                  maxDate={dayjs(now)}
                  className="w-2/5"
                  minDate={dayjs(dateFrom)}
                  defaultValue={dayjs(dateTo)}
                  value={dayjs(dateTo)}
                  onChange={(date, dateString) => {
                    setDateTo(moment(dateString, dateFormat).format(dateFormatAPI))
                  }}
                />
              </div>
              <div className="flex gap-2 mt-2">
                <Select
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  value={cnDa}
                  onChange={setCnDa}
                  showSearch
                  options={listCN?.map((items) => ({
                    label: items.tenChiNhanh,
                    value: items.idChiNhanh,
                  }))}
                  className="w-full"
                />
                <Button
                  onClick={getListPhanAnhDaHoanThanh}
                  //className="w-1/4"
                  variant="text"
                  size="medium"
                  //endIcon={<CachedIcon />}
                >
                  <CachedIcon />
                </Button>
              </div>
              <div className="mt-1">
                <ConfigProvider
                  theme={{
                    token: { padding: 5 },
                    components: {
                      Table: {
                        rowHoverBg: '#ecf0f1',
                        headerBg: '#e6e6e6',
                        footerBg: '#e6e6e6',
                        borderColor: '#BABABA',
                      },
                    },
                  }}
                >
                  <Table
                    pagination={false}
                    dataSource={listDaHoanThanhPA ?? []}
                    columns={columns}
                    onRow={(record) => ({
                      onClick: () => getInfoPhanAnh(record?.idpakh),
                    })}
                    bordered
                    scroll={{ y: 265 }}
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-3 border rounded-lg p-2">
          <div className="flex justify-between items-center">
            <div>
              {formik.values.IDPAKH && (
                <BtnAntd
                  icon={<PlusOutlined style={{ fontSize: 16 }} />}
                  type="primary"
                  ghost
                  onClick={handleResetForm}
                >
                  Tiếp nhận
                </BtnAntd>
              )}
            </div>
            <div className="flex justify-end items-center gap-2">
              {formik.values.IDPAKH && formik.values.TRANGTHAI === 3 && (
                <>
                  <BtnAntd
                    icon={<CheckOutlined style={{ fontSize: 16 }} />}
                    type="primary"
                    onClick={handleHoanThanh}
                  >
                    Hoàn thành
                  </BtnAntd>
                </>
              )}
              {(!formik.values.IDPAKH ||
                (formik.values.IDPAKH && formik.values.TRANGTHAI === 1)) && (
                <>
                  <BtnAntd
                    disabled={
                      formik.values.IDPAKH &&
                      (formik.values.TRANGTHAI !== 1 || formik.values.IDNGUOITAO !== infoUser?.idnv)
                    }
                    icon={<SaveOutlined style={{ fontSize: 16 }} />}
                    type="primary"
                    onClick={formik.handleSubmit}
                  >
                    Lưu
                  </BtnAntd>
                </>
              )}
            </div>
          </div>
          <div className="mt-2 grid grid-flow-row gap-2">
            <div className="grid grid-cols-3">
              {formik.values.IDPAKH ? (
                <>
                  <div className="grid grid-cols-3 pr-1 items-center">
                    <div className="font-semibold text-end pr-1">
                      <label>Mã phản ảnh:</label>
                    </div>
                    <div className="col-span-2">
                      <Input
                        size="medium"
                        variant="filled"
                        readOnly
                        status={formik.errors.MAPAKH ? 'error' : ''}
                        // onChange={formik.handleChange}
                        value={formik.values.MAPAKH}
                        name="MAPAKH"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-3 pl-2 pr-1 items-center">
                    <div className="font-semibold text-end pr-1">
                      <label>
                        <span className="text-red-500">(*)</span>Tên phản ảnh:
                      </label>
                    </div>
                    <div className="col-span-2">
                      <Input
                        size="medium"
                        status={formik.errors.TENPAKH ? 'error' : ''}
                        onChange={formik.handleChange}
                        value={formik.values.TENPAKH}
                        name="TENPAKH"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-span-2 grid grid-cols-6 pr-1 items-center">
                    <div className="font-semibold text-end pr-1">
                      <label>
                        <span className="text-red-500">(*)</span>Tên phản ảnh:
                      </label>
                    </div>
                    <div className="col-span-5">
                      <Input
                        size="medium"
                        status={formik.errors.TENPAKH ? 'error' : ''}
                        onChange={formik.handleChange}
                        value={formik.values.TENPAKH}
                        name="TENPAKH"
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="grid grid-cols-3 pl-2 items-center">
                <div className="font-semibold text-end pr-1">
                  <label>
                    <span className="text-red-500">(*)</span>Loại phản ảnh:
                  </label>
                </div>
                <div className="col-span-2">
                  <Select
                    size="medium"
                    className="w-full"
                    status={formik.errors.IDLOAIPAKH ? 'error' : ''}
                    onChange={(value) => formik.setFieldValue('IDLOAIPAKH', value)}
                    value={formik.values.IDLOAIPAKH}
                    name="IDLOAIPAKH"
                    options={listLoaiPA?.map((item) => ({
                      key: `` + item?.maloaipakh + item?.idloaipakh,
                      value: item?.idloaipakh,
                      label: item?.tenloaipakh ?? item?.maloaipakh,
                    }))}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className="grid grid-cols-3 pr-1 items-center">
                <div className="font-semibold text-end pr-1">
                  <label>
                    <span className="text-red-500">(*)</span>Tên khách hàng:
                  </label>
                </div>
                <div className="col-span-2">
                  <Input
                    size="medium"
                    status={formik.errors.NGUOIPHANANH ? 'error' : ''}
                    onChange={formik.handleChange}
                    value={formik.values.NGUOIPHANANH}
                    name="NGUOIPHANANH"
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 pl-2 pr-1 items-center">
                <div className="font-semibold text-end pr-1">
                  <label>
                    <span className="text-red-500">(*)</span>Số điện thoại:
                  </label>
                </div>
                <div className="col-span-2">
                  <Input
                    size="medium"
                    status={formik.errors.SODIENTHOAI ? 'error' : ''}
                    onChange={formik.handleChange}
                    value={formik.values.SODIENTHOAI}
                    name="SODIENTHOAI"
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 pl-2 items-center">
                <div className="font-semibold text-end pr-1">
                  <label>
                    <span className="text-red-500">(*)</span>Bộ phận xử lý:
                  </label>
                </div>
                <div className="col-span-2">
                  <Select
                    size="medium"
                    className="w-full"
                    status={formik.errors.NGUOITIEPNHAN ? 'error' : ''}
                    onChange={(value) => formik.setFieldValue('NGUOITIEPNHAN', value)}
                    value={formik.values.NGUOITIEPNHAN}
                    name="NGUOITIEPNHAN"
                    options={listBoPhanXuLy?.map((item) => ({
                      key: `` + item?.tenChiNhanh + item?.idChiNhanh,
                      value: item?.idChiNhanh,
                      label: item?.tenChiNhanh,
                    }))}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className="col-span-2 grid grid-cols-6 pr-1 items-center">
                <div className="font-semibold text-end pr-1">
                  <label>Địa chỉ:</label>
                </div>
                <div className="col-span-5">
                  <Input
                    size="medium"
                    status={formik.errors.DIACHI ? 'error' : ''}
                    onChange={formik.handleChange}
                    value={formik.values.DIACHI}
                    name="DIACHI"
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 pl-2 items-center">
                <div className="font-semibold text-end pr-1">
                  <label>Người tiếp nhận:</label>
                </div>
                <div className="col-span-2">
                  <Input
                    size="medium"
                    variant="filled"
                    readOnly
                    value={formik.values.NGUOITAO ?? infoUser?.tenNV}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className="col-span-2 grid grid-cols-6 pr-1 items-center">
                <div className="font-semibold text-end pr-1">
                  <label>Ghi chú:</label>
                </div>
                <div className="col-span-5">
                  <Input
                    size="medium"
                    status={formik.errors.GHICHU ? 'error' : ''}
                    onChange={formik.handleChange}
                    value={formik.values.GHICHU}
                    name="GHICHU"
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 pl-2 items-center">
                <div className="font-semibold text-end pr-1">
                  <label>Ngày tiếp nhận:</label>
                </div>
                <div className="col-span-2">
                  <Input
                    size="medium"
                    variant="filled"
                    readOnly
                    value={
                      formik.values.NGAYTAO
                        ? moment(formik.values.NGAYTAO)?.format(dateFormat)
                        : now?.format(dateFormat)
                    }
                  />
                </div>
              </div>
            </div>
            <Tabs
              defaultActiveKey={1}
              items={[
                {
                  key: 1,
                  label: 'Nội dung',
                  children: (
                    <>
                      <div className="h-[35.3rem] p-2 border border-gray-400">
                        <Editor
                          editorState={editorState}
                          onEditorStateChange={onEditorStateChange}
                          toolbar={{
                            options: [
                              'inline',
                              'blockType',
                              'fontFamily',
                              'fontSize',
                              'textAlign',
                              'colorPicker',
                            ],
                            inline: {
                              options: ['bold', 'italic', 'underline'],
                            },
                            blockType: {
                              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                            },
                            fontFamily: {
                              options: ['Times New Roman'],
                            },
                            fontSize: {
                              options: [14, 16, 18, 24, 30],
                            },
                          }}
                          toolbarClassName={style.toolbarClassName}
                          editorClassName={style.editorClassName}
                        />
                      </div>
                    </>
                  ),
                },
                {
                  key: 2,
                  label: 'Hình ảnh',
                  children: (
                    <>
                      <div
                        className={selectedFile?.length > 0 || formik.values.IDPAKH ? '' : 'hidden'}
                      >
                        <ul className="flex flex-wrap gap-2 mb-2">
                          {formik.values.HINHANH
                            ? formik.values.HINHANH?.split(';')?.map((url, index) => {
                                // url = url?.replace(
                                //   'https://file.315healthcare.com/imageCP',
                                //   'D:/FILE_SHARE/imageCP',
                                // ) // test local
                                // console.log(url)
                                return (
                                  url?.length > 0 && (
                                    <li
                                      key={`hinh${index}:${url}`}
                                      className="w-[10rem] h-[10rem] overflow-hidden border rounded-md relative"
                                    >
                                      <DeleteOutlined
                                        onClick={() => showConfirm(url)}
                                        className={`absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1 ${formik.values.IDPAKH && (formik.values.TRANGTHAI !== 1 || formik.values.IDNGUOITAO !== infoUser?.idnv) ? 'hidden' : ''}`}
                                        style={{ fontSize: 18 }}
                                      />
                                      <a href={url} target="_blank">
                                        <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                                          <div className="relative "></div>
                                          <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                                            {`${formik.values.MAPAKH}-Hinh${++index}`}
                                          </p>
                                        </div>
                                        <img src={url} className="w-full" alt={url} />
                                      </a>
                                    </li>
                                  )
                                )
                              })
                            : selectedFile?.map((items, index) => (
                                <li
                                  key={`upHinh${index}`}
                                  className="w-[10rem] h-[10rem] overflow-hidden border rounded-md relative"
                                >
                                  <DeleteOutlined
                                    onClick={() => deleteFileById(items.id)}
                                    className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                                    style={{ fontSize: 18 }}
                                  />
                                  <a href={items?.URL} target="_blank">
                                    <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                                      <div className="relative "></div>
                                      <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                                        {items.name}
                                      </p>
                                    </div>
                                    <img src={items.base64} className="w-full" alt={items.name} />
                                  </a>
                                </li>
                              ))}
                          {formik.values.TRANGTHAI === 1 &&
                            formik.values.IDNGUOITAO === infoUser?.idnv && (
                              <>
                                <li
                                  key={`themHinh${formik.values.MAPAKH}`}
                                  className="w-[10rem] h-[10rem] overflow-hidden border rounded-md relative"
                                >
                                  <input
                                    ref={fileInputRef}
                                    id="file-upload"
                                    type="file"
                                    onChange={handleFileChangeUpload}
                                  />
                                  <Button
                                    className="h-full w-full"
                                    onClick={() => handleDivClick()}
                                  >
                                    <PlusOutlined className="h-full w-full text-3xl hover:text-4xl flex justify-center items-center" />
                                  </Button>
                                </li>
                              </>
                            )}
                        </ul>
                      </div>
                      {!formik.values.IDPAKH && (
                        <>
                          <div
                            style={{
                              border: '2px dashed #cccccc',
                              borderRadius: '4px',
                              padding: '30px',
                              textAlign: 'center',
                              cursor: 'pointer',
                            }}
                            className="border"
                            ref={dropzoneRef}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            onClick={handleDivClick}
                          >
                            <CloudUploadOutlined
                              style={{ fontSize: 36 }}
                              className="text-gray-500"
                            />
                            <label style={{ cursor: 'pointer' }}>
                              <p className="font-semibold text-lg text-gray-500">
                                Thả tập tin vào đây hoặc bấm vào để tải lên.
                              </p>
                            </label>
                            <input
                              ref={fileInputRef}
                              id="fileInput"
                              type="file"
                              onChange={handleFileSelect}
                            />
                          </div>
                        </>
                      )}
                    </>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default TiepNhan

import * as Yup from "yup";

export const updateThuocVTSchema = Yup.object().shape({
    tenBietDuoc: Yup.string().required(),
    maThuoc: Yup.string().required('Mã thuốc không được bỏ trống').length(8, 'Mã thuốc phải có đúng 8 ký tự'),
    tenHoatChat: Yup.string().required(),
    idNhom: Yup.string().required(),
    dvt: Yup.string().required(),
    quyCachDongGoi: Yup.string().required(),
    donViChan: Yup.string().required(),
});

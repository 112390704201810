export function calculateSum(array, key) {
    if (!Array.isArray(array)) {
        throw new Error("Tham số đầu tiên phải là một mảng.");
    }
    if (typeof key !== "string") {
        throw new Error("Tham số thứ hai phải là tên của một thuộc tính (chuỗi).");
    }

    return array.reduce((sum, item) => {
        const value = item[key];
        if (value === null || value === undefined) {
            return sum; // Nếu giá trị là null hoặc undefined, bỏ qua nó (mặc định là 0)
        }
        return sum + value;
    }, 0);
}
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { Input } from 'antd'
import 'moment/locale/vi'
moment.locale('vi')
const { TextArea } = Input
const BN = () => {
  const { infoBN, service, BNDetail } = useSelector((state) => state.drugstoreReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  return (
    <div className="border rounded h-[400px]">
      <h2 className="border-b text-lg font-semibold text-gray-500 p-2">Bệnh nhân</h2>
      <div className="grid grid-flow-row mt-2 gap-1">
        <div className="grid grid-cols-5 gap-2 px-2">
          <div className="col-span-3 grid grid-cols-3 items-center">
            <div className="font-semibold">Tên BN:</div>
            <div className="col-span-2">{infoBN?.tenBenhNhan}</div>
          </div>
          <div className="col-span-2 grid grid-cols-3 items-center">
            <div className="font-semibold">Mã BN:</div>
            <div className="col-span-2 pl-1">{infoBN?.maBenhNhan}</div>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-2 px-2">
          <div className="col-span-3 grid grid-cols-3 items-center">
            <div className="font-semibold">Giới tính:</div>
            <div className="col-span-2">{infoBN?.gioiTinh}</div>
          </div>
          <div className="col-span-2 grid grid-cols-3 items-center">
            <div className="font-semibold">Ngày sinh:</div>
            <div className="col-span-2 pl-1">
              {infoBN?.ngaySinh && moment(infoBN?.ngaySinh).format('DD/MM/YYYY')}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-2 px-2">
          <div className="col-span-3 grid grid-cols-3 items-center">
            <div className="font-semibold">Người nhà:</div>
            <div className="col-span-2">{BNDetail?.tennguoinha}</div>
          </div>
          <div className="col-span-2 grid grid-cols-3 items-center">
            <div className="font-semibold">SĐT:</div>
            <div className="col-span-2 pl-1">{BNDetail?.dienthoainguoinha}</div>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-2 px-2">
          <div className="col-span-3 grid grid-cols-3 items-center">
            <div className="font-semibold">Dược sĩ:</div>
            <div className="col-span-2">{service?.BSKEDON ? infoUser?.tenNV : ''}</div>
          </div>
          <div className="col-span-2 grid grid-cols-3 items-center">
            <div className="font-semibold">Chi nhánh:</div>
            <div className="col-span-2 pl-1">{service?.BSKEDON ? PkDangNhap?.tenChiNhanh : ''}</div>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-2 px-2">
          <div className="col-span-3 grid grid-cols-3 items-center">
            <div className="font-semibold">Bác sĩ kê toa:</div>
            <div className="col-span-2">{service?.BSKEDON}</div>
          </div>
          <div className="col-span-2 grid grid-cols-3 items-center">
            <div className="font-semibold">Ngày kê:</div>
            <div className="col-span-2 pl-1">
              {service?.NGAYKEDON && moment(service?.NGAYKEDON).format('DD/MM/YYYY HH:mm:ss')}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-2 px-2">
          <div className="col-span-3 grid grid-cols-3 items-center">
            <div className="font-semibold">Chiều cao:</div>
            <div className="col-span-2">{BNDetail?.chieucao ? `${BNDetail?.chieucao} Cm` : ''}</div>
          </div>
          <div className="col-span-2 grid grid-cols-3 items-center">
            <div className="font-semibold">Cân nặng:</div>
            <div className="col-span-2 pl-1">
              {BNDetail?.cannang ? `${BNDetail?.cannang} Kg` : ''}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-5 px-2 items-center mt-1">
          <div className="font-semibold">Lý do khám:</div>
          <TextArea
            disabled
            style={{ color: 'black' }}
            className="col-span-4"
            value={BNDetail?.lydokham}
            autoSize={{ minRows: 2, maxRows: 3 }}
          />
        </div>
        <div className="grid grid-cols-5 px-2 items-center mt-2">
          <div className="font-semibold">Lời dặn:</div>
          <TextArea
            disabled
            style={{ color: 'black' }}
            className="col-span-4"
            value={BNDetail?.loidan}
            autoSize={{ minRows: 2, maxRows: 3 }}
          />
        </div>
      </div>
    </div>
  )
}

export default BN

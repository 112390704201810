import React from 'react'
import { Tabs } from 'antd'
import TrungBinh from './TrungBinh'
import TungLan from './TungLan'

const GiaNhap = () => {
  return (
    <div className="p-3 h-full bg-[#EFEFEF]">
      <div
        className="h-full p-2 bg-white border rounded-xl pt-0"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <Tabs
          items={[
            {
              key: 1,
              label: 'Giá nhập trung bình',
              children: <TrungBinh />,
            },
            {
              key: 2,
              label: 'Giá nhập từng lần',
              children: <TungLan />,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default GiaNhap

import dayjs from 'dayjs'
import { formatNumberVND } from '../../../../../utils/formatNumberVND'

export const columns = [
  {
    title: 'Thông tin',
    key: 'tt',
    children: [
      {
        title: 'STT',
        key: 'stt',
        render: (text, record, index) => ++index,
        align: 'center',
      },
      {
        title: 'Ngày',
        key: 'ngay',
        dataIndex: 'ngay',
        render: (text, record, index) => (text ? dayjs(text).format('DD/MM/YYYY') : '-'),
        align: 'center',
      },
      {
        title: 'Ngày khai trương',
        key: 'ngaykhaitruong',
        dataIndex: 'ngaykhaitruong',
        render: (text, record, index) => (text ? dayjs(text).format('DD/MM/YYYY') : '-'),
        align: 'center',
        width: 150,
      },
      {
        title: 'Chi nhánh',
        key: 'tenchinhanh',
        dataIndex: 'tenchinhanh',
        width: 250,
      },
    ],
  },
  {
    title: 'Bán lẻ',
    key: 'bl',
    children: [
      {
        title: 'Số phiếu',
        key: 'sophieubanle',
        dataIndex: 'sophieubanle',
        align: 'right',
      },
      {
        title: 'Thành tiền',
        key: 'thanhtienbanle',
        dataIndex: 'thanhtienbanle',
        render: (text, record, index) => formatNumberVND(record.thanhtienbanle),
        align: 'right',
      },
    ],
  },
  {
    title: 'Bán theo toa',
    key: 'bl',
    children: [
      {
        title: 'Số phiếu',
        key: 'sophieubantoa',
        dataIndex: 'sophieubantoa',
        align: 'right',
      },
      {
        title: 'Thành tiền',
        key: 'thanhtienbantoa',
        dataIndex: 'thanhtienbantoa',
        render: (text, record, index) => formatNumberVND(record.thanhtienbantoa),
        align: 'right',
      },
    ],
  },
  {
    title: 'Nội bộ',
    key: 'bl',
    children: [
      {
        title: 'Số phiếu',
        key: 'sophieuxuatnoibo',
        dataIndex: 'sophieuxuatnoibo',
        align: 'right',
      },
      {
        title: 'Thành tiền',
        key: 'thanhtienxuatnoibo',
        dataIndex: 'thanhtienxuatnoibo',
        render: (text, record, index) => formatNumberVND(record.thanhtienxuatnoibo),
        align: 'right',
      },
    ],
  },

  {
    title: 'Thuốc',
    key: 'bl',
    children: [
      {
        title: 'Số phiếu',
        key: 'sophieuthuoc',
        dataIndex: 'sophieuthuoc',
        align: 'right',
      },
      {
        title: 'Thành tiền',
        key: 'thanhtienthuoc',
        dataIndex: 'thanhtienthuoc',
        render: (text, record, index) => formatNumberVND(record.thanhtienthuoc),
        align: 'right',
      },
    ],
  },
  {
    title: 'Vaccine',
    key: 'bl',
    children: [
      {
        title: 'Số phiếu',
        key: 'sophieuvacxin',
        dataIndex: 'sophieuvacxin',
        align: 'right',
      },
      {
        title: 'Thành tiền',
        key: 'thanhtienvacxin',
        dataIndex: 'thanhtienvacxin',
        render: (text, record, index) => formatNumberVND(record.thanhtienvacxin),
        align: 'right',
      },
    ],
  },
  {
    title: 'Tổng cộng',
    key: 'bl',
    children: [
      {
        title: 'Tổng phiếu',
        key: 'sophieutong',
        dataIndex: 'sophieutong',
        align: 'right',
      },
      {
        title: 'Tổng tiền',
        key: 'thanhtientong',
        dataIndex: 'thanhtientong',
        render: (text, record, index) => formatNumberVND(record.thanhtientong),
        align: 'right',
      },
    ],
  },
]



import { Padding } from '@mui/icons-material';
import React from 'react'
import Barcode from 'react-barcode'
const PrinterTTQG = ({ name, barcode }) => {
  return (
    <div style={styles.label}>
      <h2 style={styles.title}>{name}</h2>
      <div style={styles.barcodeContainer}>
        <Barcode value={barcode ? barcode : 0} displayValue={false} height={50} margin={1}   />
      </div>
      <div style={styles.price}>{barcode}</div>
    </div>
  )
}
const styles = {
  label: {
    width: '48mm', /* Chiều rộng của tem */
    height: '30mm', /* Chiều cao của tem */
    // width: '100%',
    // height: '100%',
    fontFamily: 'Arial, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    
  },
  barcodeContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontWeight:600,
    fontSize: '12px', // Tăng kích thước font chữ của tiêu đề
    width: '100%',
    textAlign: 'center',
    margin: '0', // Khoảng cách giữa tiêu đề và mã vạch
  },
  price: {
    fontWeight:600,
    fontSize: '14px', // Tăng kích thước font chữ của giá trị
    textAlign: 'center',
  },
};
export default PrinterTTQG

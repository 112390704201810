import { Tabs } from 'antd'
import React, { useEffect } from 'react'
import TongHop from './TongHop/TongHop'
import ChiTiet from './ChiTiet/ChiTiet'
import { useDispatch } from 'react-redux'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
const PhieuXuatReport = () => {
  const dispatch = useDispatch()
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const items = [
    { key: '1', label: 'Doanh thu tổng hợp', children: <TongHop ID_BRANCH={ID_BRANCH} ID_COMPANY={ID_COMPANY} /> },
    { key: '2', label: 'Doanh thu chi tiết', children: <ChiTiet ID_BRANCH={ID_BRANCH} ID_COMPANY={ID_COMPANY} /> },
  ]
  useEffect(() => {
    dispatch(listAllCompanyAction())
  }, [])
  return (
    <div className="bg-[#EFEFEF] p-3 h-full">
      <div className="p-2 border rounded-xl bg-white h-full">
        <Tabs items={items} />
      </div>
    </div>
  )
}

export default PhieuXuatReport

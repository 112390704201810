import React, { useCallback, useEffect, useState } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import _, { debounce } from 'lodash'
import { ConfigProvider, Table, Checkbox, Input, Button, Pagination, Modal, Select } from 'antd'
import {
  SearchOutlined,
  SyncOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser, resetPassUser } from '../../../store/actions/userAction'
import moment from 'moment'
import CreateUser from './Modal/CreateUser'
import EditUser from './Modal/EditUser'
import { authService } from '../../../services/auth/authService'
import { nguoidungService } from '../../../services/nguoidung/nguoidungService'
import { nhanvienService } from '../../../services/nhanvien/nhanvienService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { branchService } from '../../../services/branch/branchService'
import { phanQuyenService } from '../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../store/actions/phanQuyenAction'
import ToastCus from '../../../utils/Toast'
import { useFormik } from 'formik'
import 'moment/locale/vi'
import { suCoService } from '../../../services/suCoService/suCoServvice'
import { https } from '../../../services/apiService'
moment.locale('vi')
const { confirm } = Modal

const User = () => {
  const dispatch = useDispatch()
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [isModal, setIsModal] = useState(false)
  const [info, setInfo] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [search, setSearch] = useState(null)
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  // Khai báo state cho trạng thái checkbox ở file cha
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const [rightsGroup, setRightsGroup] = useState([])
  const [usersCan, setUsersCan] = useState([])
  const [selectedGroup, setSelectedGroup] = useState('all')
  const [listUser, setListUser] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [typeUser, setTypeUser] = useState([])
  //----------- Lấy tất cả chi nhánh--------
  const fetchAllBranch = async () => {
    try {
      const { data } = await suCoService.getAllBranh()
      const formatNameData = data?.map((branch) => ({
        // Cập nhập lại biến
        tenChiNhanh: branch.tenchinhanh,
        idChiNhanh: branch.idchinhanh,
      }))
      setBranch(formatNameData)
    } catch (error) {
      console.log(error)
    }
  }
  //----------------- lấy danh sách loại người dung ----------
  const fetchTypeUser = async () => {
    try {
      const { data } = await nhanvienService.getTypeUser()
      setTypeUser(data)
    } catch (error) {
      console.log(error)
    }
  }
  // lấy danh sách chi nhánh theo công ty
  // const fetchBranch = async (id) => {
  //   try {
  //     const result = await branchService.getListBranch(id)
  //     setBranch(result.data)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // useEffect(() => {
  //   dispatch(listAllCompanyAction())
  //   fetchBranch(id_company)
  // }, [])

  // lấy dánh sách nhóm quyền
  useEffect(() => {
    const fetchAllUsersCan = async () => {
      try {
        const result = await nhanvienService.getRulerUser()
        setRightsGroup(result.data)
      } catch (error) {
        console.error('Không thể tìm được dữ liệu nhóm quyền', error)
      }
    }
    fetchAllUsersCan()
  }, [])

  const showConfirm = async (record) => {
    confirm({
      title: 'Reset mật khẩu người dùng ',
      icon: <ExclamationCircleFilled />,
      content: `bạn có muốn đặt lại mật khẩu cho ${record.taiKhoan}`,
      okText: 'Đồng ý', // Thay đổi văn bản nút "OK"
      cancelText: 'Hủy bỏ', // Thay đổi văn bản nút "Cancel"
      onOk() {
        dispatch(
          resetPassUser(record.taiKhoan, infoUser?.idnv, {
            keyword: search,
            page: useSearch.get('page'),
          }),
        )
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const showEdit = () => {
    setIsEdit(true)
  }
  const cancelEdit = () => {
    setIsEdit(false)
  }
  const cancelModalAdd = () => {
    setIsModal(false)
  }
  const showModaAdd = () => {
    setIsModal(true)
  }
  const onReload = () => {
    setListUser([])
    setSelectedGroup('all')
    setSearch(null)
    getAllUser(1, '', 'all')
  }

  const getAllUser = async (page = 1, keyword, selectedGroup) => {
    setLoading(true)
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT009',
      )
      let selectGroup = ''
      if (selectedGroup !== 'all') {
        selectGroup = selectedGroup
      }
      const { data } = await https.get(`NguoiDung/SearchNguoiDungTheoDieuKien`, {
        params: {
          pageIndex: page,
          keyword: keyword,
          idnhom: selectGroup,
        },
      })
      setPage(page)
      setListUser((prev) => [...prev, ...data.data])
      setTotalPages(data.totalPages)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleEdit = async (id) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT008',
      )

      showEdit()
      const { data } = await authService.getInfoUser(id)
      setInfo(data)
      // Cập nhật tempIsLockParent từ info.isLock
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  // thay đổi chọn nhóm quyền
  const handleChange = (value) => {
    setSelectedGroup(value)
  }

  const handleScroll = useCallback(
    debounce(() => {
      //api search return all data not pagination
      // disable scroll when search filtering
      const tableBody = document.querySelector('.table-nguoi-dung .ant-table-body')
      if (
        tableBody.scrollTop + tableBody.clientHeight >= tableBody.scrollHeight - 100 &&
        !loading
      ) {
        if (page + 1 > totalPages) {
          return
        }
        setPage((prevPage) => prevPage + 1)
      }
    }, 300),
    [loading],
  )

  useEffect(() => {
    const tableBody = document.querySelector('.table-nguoi-dung .ant-table-body')
    tableBody.addEventListener('scroll', handleScroll)
    return () => tableBody.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  useEffect(() => {
    getAllUser(page, search, selectedGroup)
  }, [page])
  useEffect(() => {
    fetchTypeUser()
  }, [])
  const handleSearch = (search, selectedGroup) => {
    setListUser([])
    getAllUser(1, search, selectedGroup)
  }

  return (
    <>
      <div className="h-full p-3 bg-[#EFEFEF]">
        <div className="p-2 rounded-xl  bg-white">
          <div className="flex justify-between mb-2">
            <div className="flex gap-2">
              <div>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes((input ?? '').toLowerCase())
                  }
                  className="w-60"
                  onChange={handleChange}
                  options={[
                    { label: 'Tất cả', value: 'all' },
                    ...(rightsGroup?.map((item) => ({
                      label: item.tenNhom,
                      value: item.idNhom,
                    })) || []),
                  ]}
                  defaultValue="all" // Mặc định là 'all'
                />
                {selectedGroup !== 'all' && (
                  <ul>
                    {usersCan?.map((item) => (
                      <li key={item.idNhom}>{item.idNhom}</li>
                    ))}
                  </ul>
                )}
              </div>
              <Input
                onChange={(e) => setSearch(e.target.value)}
                // onChange={onSearch}
                value={search}
                className="w-60"
                placeholder="Nhập từ khóa tìm kiếm..."
              />
              <Button type="primary" onClick={() => handleSearch(search, selectedGroup)}>
                <SearchOutlined />
              </Button>
            </div>
            <div className="flex gap-2">
              <button
                onClick={showModaAdd}
                className="rounded-lg relative w-32 h-8 cursor-pointer flex items-center overflow-hidden border border-green-500 bg-green-500 group hover:bg-green-500 active:bg-green-500 active:border-green-500"
              >
                <span className="text-white font-semibold ml-7 transform group-hover:translate-x-20 transition-all duration-300">
                  Tạo mới
                </span>
                <span className="absolute right-0 h-full w-10 rounded-lg bg-green-500 flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
                  <svg
                    className="svg w-8 text-white"
                    fill="none"
                    height="24"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line x1="12" x2="12" y1="5" y2="19"></line>
                    <line x1="5" x2="19" y1="12" y2="12"></line>
                  </svg>
                </span>
              </button>
              {/* <Button onClick={onReload} type="primary" icon={<SyncOutlined />}>
                Tải lại
              </Button> */}
            </div>
          </div>
          <ConfigProvider
            theme={{
              token: { padding: 4 },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <Table
              loading={loading}
              scroll={{
                x: 1750,
                y: '80vh',
              }}
              className="table-nguoi-dung"
              pagination={false}
              bordered
              dataSource={listUser?.map((items, index) => ({
                STT: ++index,
                key: index,
                idNguoiDung: items.idnd,
                taiKhoan: items.taikhoan,
                // matKhau: items.matKhau,
                // idChiNhanh: items.idChiNhanh,
                // idNhom: items.idNhom,
                tenNhom: items.tennhom,
                tennv: items.tennhanvien,
                manv: items.manhanvien,
                nguoiTao: items.nguoitao,
                ngayTao: items.ngaytao && moment(items.ngaytao).format('DD/MM/YYYY HH:mm:ss'),
                // nguoiSua: items.nguoisua,
                ngaySua: items.ngaysua && moment(items.ngaysua).format('DD/MM/YYYY HH:mm:ss'),
                isLock: items.hieuluc,
                ipmac: items.ipmac,
                tenNguoiSua: items.nguoisua,
                loainguoidung: items.loainguoidung,
                
              }))}
              columns={[
                {
                  title: 'STT',
                  dataIndex: 'STT',
                  key: 'STT',
                  align: 'center',
                  width: 50,
                  fixed: 'left',
                },
                {
                  title: 'Tài khoản',
                  dataIndex: 'taiKhoan',
                  key: 'taiKhoan',
                  width: 160,
                  fixed: 'left',
                },
                {
                  title: 'Tên nhân viên',
                  dataIndex: 'tennv',
                  key: 'tennv',
                  width: 200,
                  fixed: 'left',
                },
                {
                  title: 'Mã nhân viên',
                  dataIndex: 'manv',
                  key: 'manv',
                  width: 120,
                },
                {
                  title: 'Vai trò',
                  dataIndex: 'tenNhom',
                  key: 'tenNhom',
                  width: 200,
                },
                {
                  title: 'Loại',
                  dataIndex: 'loainguoidung',
                  key: 'loainguoidung',
                  width: 150,
                },
                {
                  title: 'Người tạo',
                  dataIndex: 'nguoiTao',
                  key: 'nguoiTao',
                  width: 200,
                },
                {
                  title: 'Ngày tạo',
                  dataIndex: 'ngayTao',
                  key: 'ngayTao',
                  width: 140,
                  align: 'center',
                },
                {
                  title: 'Người sửa',
                  dataIndex: 'tenNguoiSua',
                  key: 'tenNguoiSua',
                  width: 200,
                },
                {
                  title: 'Ngày sửa',
                  dataIndex: 'ngaySua',
                  key: 'ngaySua',
                  width: 140,
                  align: 'center',
                },
                {
                  title: 'Hiệu lực',
                  dataIndex: 'isLock',
                  key: 'isLock',
                  width: 70,
                  align: 'center',
                  render: (text, record) => (
                    <Checkbox
                      checked={record.isLock === 1} // Đảo ngược giá trị checked dựa trên record.isLock
                      // onChange={() => {
                      //   handleLockChangeParent(record)
                      // }}
                    />
                  ),
                  fixed: 'right',
                },
                {
                  title: 'Reset',
                  dataIndex: 'reset',
                  key: 'reset',
                  width: 130,
                  align: 'center',
                  render: (text, record, index) => (
                    <Button
                      onClick={() => showConfirm(record, record.tenNguoiSua)}
                      size="small"
                      type="primary"
                      ghost
                    >
                      Reset mật khẩu
                    </Button>
                  ),
                  fixed: 'right',
                },
                {
                  title: '',
                  dataIndex: 'action',
                  key: 'action',
                  width: 40,
                  align: 'center',
                  fixed: 'right',
                  render: (text, record, index) => (
                    <EditOutlined
                      style={{ fontSize: 16 }}
                      onClick={() => {
                        handleEdit(record.idNguoiDung)
                        fetchAllBranch()
                      }}
                      className=" rounded p-1 bg-green-200 text-green-700 hover:text-white hover:bg-green-500 cursor-pointer"
                    />
                  ),
                },
              ]}
            />
          </ConfigProvider>
        </div>
      </div>
      {isModal && (
        <CreateUser typeUser={typeUser} cancelModalAdd={cancelModalAdd} isModal={isModal} />
      )}
      {isEdit && (
        <EditUser
          typeUser={typeUser}
          branch={branch}
          setBranch={setBranch}
          onReload={onReload}
          info={info}
          cancelEdit={cancelEdit}
          isEdit={isEdit}
        />
      )}
    </>
  )
}

export default User

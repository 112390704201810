import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ConfigProvider,
  Table,
  Divider,
  Input,
  Select,
  Button,
  notification,
  Tabs,
  DatePicker,
  TimePicker,
  Radio,
} from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import * as typeAction from '../../../../store/constants/constants'
import { postListVTBNThuThuatAction } from '../../../../store/actions/thuThuatAction'
import {
  postInfoBNTheoDoiSauTiemAction,
  getInfoBNTiemChungAction,
} from '../../../../store/actions/tiemChungAction'
import { generatePhieuKiemSauTiem } from '../../../../utils/report/phieuKiemSauTiem'
import { useFormik, useFormikContext } from 'formik'
import moment from 'moment'
import dayjs from 'dayjs'
import ToastCus from '../../../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')
const VatTu = ({ formLoad, infoBN, khoVatTu, setKhoVatTu, reloadListVTTieuHao }) => {
  const now = moment()
  const dateFormat = 'DD/MM/YYYY'
  const timeFormat = 'HH:mm'
  const dateFormatAPI = 'YYYY-MM-DD'
  const dispatch = useDispatch()
  const { listVT, listVTBN } = useSelector((state) => state.thuThuatReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoBNTiemChung, DetailToaThuocBN, infoTheoDoiSauTiem } = useSelector(
    (state) => state.tiemChungReducer,
  )
  const { infoUser } = useSelector((state) => state.userReducer)
  const [keyTabs, setKeyTabs] = useState('1')
  const [api, contextHolder] = notification.useNotification()
  const openNotificationWithIcon = (message, description) => {
    api.warning({
      message,
      description,
    })
  }

  const handleTimeChange = (name) => (date, dateString) => {
    const newTime = '' + moment().format(dateFormatAPI) + 'T' + dateString + ':00'
    formik.setFieldValue(name, newTime)
  }
  const handleDateChange = (name) => (date, dateString) => {
    formik.setFieldValue(name, moment(dateString, dateFormat).format(dateFormatAPI))
  }
  const handleChangeSoLuong = (vt, soLuongNew) => {
    if (
      infoBNTiemChung?.DanhSachDichVu?.length > 0 &&
      infoBNTiemChung?.DanhSachDichVu[0]?.trangthaikb === 4
    ) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Bệnh nhân đã thực hiện, không thể chỉnh sửa!')
      return
    }
    const newValue = Number(soLuongNew)
    const index = listVTBN.findIndex((items) => items.iD === vt.iD)
    if (newValue > listVTBN[index].tonKho) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Số lượng tồn kho không đủ!')
      return
    }
    dispatch({
      type: typeAction.DISPATCH_EDIT_VT_SL_BN_THUTHUAT,
      payload: {
        index: index,
        value: newValue,
      },
    })
  }
  const handleChangeGhiChu = (vt, ghiChuNew) => {
    if (
      infoBNTiemChung?.DanhSachDichVu?.length > 0 &&
      infoBNTiemChung?.DanhSachDichVu[0]?.trangthaikb === 4
    ) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Bệnh nhân đã thực hiện, không thể chỉnh sửa!')
      return
    }
    const index = listVTBN.findIndex((items) => items.iD === vt.iD)
    dispatch({
      type: typeAction.DISPATCH_EDIT_VT_GHICHU_BN_THUTHUAT,
      payload: {
        index: index,
        value: ghiChuNew,
      },
    })
  }
  const resetForm = () => {
    setKeyTabs('1')
    formik.resetForm()
  }
  const handleLuuVatTuTC = () => {
    dispatch(
      postListVTBNThuThuatAction(
        listVTBN,
        infoBNTiemChung?.DanhSachDichVu?.map((item) => ({
          ...item,
          idnguoithuchien: infoUser?.idnv,
          nguoithuchien: infoUser?.tenNV,
        })),
        formLoad?.idcn,
        formLoad?.tungay,
        formLoad?.denngay,
      ),
    )
    setKeyTabs('1')
  }
  const handleVTTieuHao = (value) => {
    if (
      infoBNTiemChung?.DanhSachDichVu?.length > 0 &&
      infoBNTiemChung?.DanhSachDichVu[0]?.trangthaikb === 4
    ) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Bệnh nhân đã thực hiện, không thể chỉnh sửa!')
      return
    }
    if (!infoBNTiemChung?.IDBNTTC) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Vui lòng chọn bệnh nhận cần thực hiện!')
      return
    }
    const index = listVTBN?.findIndex((itemVTBN) => itemVTBN.iD === value)
    if (index !== -1) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Vật tư đã được chọn!')
      return
    }
    const indexVT = listVT?.findIndex((itemVT) => itemVT.iD === value)
    if (listVT[indexVT]?.tonKho <= 0) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Số lượng tồn kho không đủ!')
      return
    }
    dispatch({
      type: typeAction.DISPATCH_ADD_VT_BN_THUTHUAT,
      payload: {
        iD: listVT[indexVT]?.iD,
        idbnttc: infoBNTiemChung?.IDBNTTC,
        idthuoc: listVT[indexVT]?.idThuoc,
        donGia: listVT[indexVT]?.thuocVatTu?.giaBan,
        soLuong: 1,
        thanhTien: listVT[indexVT]?.thuocVatTu?.giaBan,
        tongTien: listVT[indexVT]?.thuocVatTu?.giaBan,
        bsKeDon: infoBNTiemChung?.BSCHANDOAN,
        nguoiThucHien: infoUser?.tenNV,
        ghiChu: '',
        idkhocn: listVT[indexVT]?.idKhoCN,
        soLo: listVT[indexVT]?.soLo,
        hanDung: listVT[indexVT]?.hanDung,
        idDichVu: infoBNTiemChung?.DanhSachDichVu[0]?.iddichvu,
        tenBietDuoc: listVT[indexVT]?.thuocVatTu?.tenBietDuoc,
        dvt: listVT[indexVT]?.thuocVatTu?.dvt,
        tonKho: listVT[indexVT]?.tonKho,
        soHoaDon: listVT[indexVT]?.soHoaDon,
        maSoDangKy: listVT[indexVT]?.maSoDangKy,
      },
    })
  }
  const handlePrint = () => {
    const formatListVX = DetailToaThuocBN?.DanhSachThuoc?.map((item) => [
      item?.tenbietduoc ?? '',
      item?.nhasanxuat ?? '',
      item?.solo ?? '',
      item?.masodangky ?? '',
      item?.handung ? moment(item?.handung).format('DD/MM/YYYY') : '',
      '',
      item?.muitiem,
      item?.duongdung ?? '',
    ])
    const formPrint = {
      infoBN: infoBN,
      infoTDST: infoTheoDoiSauTiem,
      listVX: formatListVX,
      PkDangNhap: PkDangNhap,
    }
    generatePhieuKiemSauTiem(formPrint)
  }
  const handleSubmit = (form) => {
    if (!form.ngaytiem) formik.setFieldValue('ngaytiem', '')
    if (!form.giotiem) formik.setFieldValue('giotiem', '')
    if (!form.ngaykiemtra) formik.setFieldValue('ngaykiemtra', '')
    if (!form.giokiemtra) formik.setFieldValue('giokiemtra', '')
    if (!form.ngaytiem || form.ngaytiem?.length <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn ngày tiêm!',
      })
      return
    }
    if (!form.giotiem || form.giotiem?.length <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn giờ tiêm!',
      })
      return
    }
    if (!form.ngaykiemtra || form.ngaykiemtra?.length <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn ngày kiểm tra!',
      })
      return
    }
    if (!form.giokiemtra || form.giokiemtra?.length <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn giờ kiểm tra!',
      })
      return
    }
    if (
      form.toantrang === 1 &&
      (!form.toantrangghichu || form.toantrangghichu?.trim().length <= 0)
    ) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập ghi chú toàn trạng bất thường!',
      })
      return
    }
    if (form.trigiac === 1 && (!form.trigiacghichu || form.trigiacghichu?.trim().length <= 0)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập ghi chú tri giác bất thường!',
      })
      return
    }
    if (form.mach === 1 && (!form.machghichu || form.machghichu?.trim().length <= 0)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập ghi chú mạch bất thường!',
      })
      return
    }
    if (form.nhiptho === 1 && (!form.nhipthoghichu || form.nhipthoghichu?.trim().length <= 0)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập ghi chú nhịp thở bất thường!',
      })
      return
    }
    if (form.daniem === 1 && (!form.daniemghichu || form.daniemghichu?.trim().length <= 0)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập ghi chú da niêm bất thường!',
      })
      return
    }
    if (
      form.vitritiem === 1 &&
      (!form.vitritiemghichu || form.vitritiemghichu?.trim().length <= 0)
    ) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập ghi chú vị trí tiêm bất thường!',
      })
      return
    }
    if (
      form.dauhieukhac === 1 &&
      (!form.dauhieukhacghichu || form.dauhieukhacghichu?.trim().length <= 0)
    ) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập ghi chú dấu hiệu khác bất thường!',
      })
      return
    }
    if (form.ketqua !== 1 && form.ketqua !== 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn mục kết quả!',
      })
      return
    }
    if (form.ketqua === 1 && (!form.ketquaghichu || form.ketquaghichu?.trim().length <= 0)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập ghi chú kết quả bất thường!',
      })
      return
    }
    const formatListVX = DetailToaThuocBN?.DanhSachThuoc?.map((item) => [
      item?.tenbietduoc ?? '',
      item?.nhasanxuat ?? '',
      item?.solo ?? '',
      item?.masodangky ?? '',
      item?.handung ? moment(item?.handung).format('DD/MM/YYYY') : '',
      '',
      item?.muitiem,
      item?.duongdung ?? '',
    ])
    const formPrint = {
      infoBN: infoBN,
      infoTDST: form,
      listVX: formatListVX,
      PkDangNhap: PkDangNhap,
    }
    //generatePhieuKiemSauTiem()
    dispatch(
      postInfoBNTheoDoiSauTiemAction(
        form,
        formLoad?.idcn,
        formLoad?.tungay,
        formLoad?.denngay,
        formPrint,
        resetForm,
      ),
    )
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbnttc: infoBNTiemChung?.IDBNTTC,
      toantrang: infoTheoDoiSauTiem?.toantrang ?? 0,
      trigiac: infoTheoDoiSauTiem?.trigiac ?? 0,
      mach: infoTheoDoiSauTiem?.mach ?? 0,
      nhiptho: infoTheoDoiSauTiem?.nhiptho ?? 0,
      daniem: infoTheoDoiSauTiem?.daniem ?? 0,
      vitritiem: infoTheoDoiSauTiem?.vitritiem ?? 0,
      dauhieukhac: infoTheoDoiSauTiem?.dauhieukhac ?? 0,
      xutri: infoTheoDoiSauTiem?.xutri,
      ketqua: infoTheoDoiSauTiem?.ketqua ?? 0,
      ngaytiem: infoTheoDoiSauTiem?.ngaytiem, //?? now.format('YYYY-MM-DDTHH:mm:ss'),
      giotiem: infoTheoDoiSauTiem?.giotiem, //?? now.format('YYYY-MM-DDTHH:mm:ss'),
      ngaykiemtra: infoTheoDoiSauTiem?.ngaykiemtra, //?? now.format('YYYY-MM-DDTHH:mm:ss'),
      giokiemtra: infoTheoDoiSauTiem?.giokiemtra, // ?? now.format('YYYY-MM-DDTHH:mm:ss'),
      machbenhnhan: infoTheoDoiSauTiem?.machbenhnhan,
      huyetap: infoTheoDoiSauTiem?.huyetap,
      nhietdo: infoTheoDoiSauTiem?.nhietdo,
      kiemtra: infoTheoDoiSauTiem?.kiemtra ?? infoUser?.tenNV,
      toantrangghichu: infoTheoDoiSauTiem?.toantrangghichu,
      trigiacghichu: infoTheoDoiSauTiem?.trigiacghichu,
      machghichu: infoTheoDoiSauTiem?.machghichu,
      nhipthoghichu: infoTheoDoiSauTiem?.nhipthoghichu,
      daniemghichu: infoTheoDoiSauTiem?.daniemghichu,
      vitritiemghichu: infoTheoDoiSauTiem?.vitritiemghichu,
      dauhieukhacghichu: infoTheoDoiSauTiem?.dauhieukhacghichu,
      ketquaghichu: infoTheoDoiSauTiem?.ketquaghichu,
    },
    onSubmit: (values) => handleSubmit(values),
  })
  useEffect(() => {
    if (formik.values.toantrang === 0) formik.setFieldValue('toantrangghichu', '')
    if (formik.values.trigiac === 0) formik.setFieldValue('trigiacghichu', '')
    if (formik.values.mach === 0) formik.setFieldValue('machghichu', '')
    if (formik.values.nhiptho === 0) formik.setFieldValue('nhipthoghichu', '')
    if (formik.values.daniem === 0) formik.setFieldValue('daniemghichu', '')
    if (formik.values.vitritiem === 0) formik.setFieldValue('vitritiemghichu', '')
    if (formik.values.dauhieukhac === 0) formik.setFieldValue('dauhieukhacghichu', '')
    if (formik.values.ketqua === 0) formik.setFieldValue('ketquaghichu', '')
  }, [formik.values])
  useEffect(() => {
    if (keyTabs === '2' && infoBNTiemChung?.IDBNTTC) {
      dispatch(getInfoBNTiemChungAction(infoBNTiemChung?.IDBNTTC, PkDangNhap?.idChiNhanh))
    }
  }, [keyTabs])
  useEffect(() => {
    dispatch({
      type: typeAction.DISPATCH_LIST_VT_BN_THUTHUAT,
      payload: infoBNTiemChung?.DanhSachVatTuTieuHao,
    })
    formik.setValues((prev) => ({
      ...prev,
      idbnttc: infoBNTiemChung?.IDBNTTC,
      ngaytiem: infoTheoDoiSauTiem?.ngaytiem ?? now.format('YYYY-MM-DDTHH:mm:ss'),
      giotiem: infoTheoDoiSauTiem?.giotiem ?? now.format('YYYY-MM-DDTHH:mm:ss'),
      ngaykiemtra: infoTheoDoiSauTiem?.ngaykiemtra ?? now.format('YYYY-MM-DDTHH:mm:ss'),
      giokiemtra: infoTheoDoiSauTiem?.giokiemtra ?? now.format('YYYY-MM-DDTHH:mm:ss'),
    }))
  }, [
    infoBNTiemChung,
    infoBNTiemChung?.DanhSachVatTuTieuHao,
    infoBNTiemChung?.DanhSachVatTuTieuHao?.length,
  ])
  useEffect(() => {
    formik.resetForm()
  }, [infoTheoDoiSauTiem, infoTheoDoiSauTiem?.idbntdst, infoTheoDoiSauTiem?.idbnttc])
  return (
    <div className="border rounded">
      {contextHolder}
      <Tabs
        onChange={(value) => {
          setKeyTabs(value)
          if (value === '2') reloadListVTTieuHao()
        }}
        activeKey={keyTabs}
        items={[
          {
            key: '1',
            label: <p className="pl-1">Theo dõi sau tiêm</p>,
            children: (
              <>
                <div className="mb-2">
                  <div className="flex justify-between pr-2">
                    <h2 className="font-semibold text-lg ml-2 text-gray-500">Thông tin</h2>
                    <div>
                      <Button
                        disabled={
                          !infoBNTiemChung?.IDBNTTC || infoTheoDoiSauTiem?.kiemtra?.length > 0
                        }
                        onClick={formik.handleSubmit}
                        type="primary"
                      >
                        Lưu & In
                      </Button>
                      {infoBNTiemChung?.IDBNTTC && infoTheoDoiSauTiem?.kiemtra?.length > 0 && (
                        <Button onClick={handlePrint} type="primary" className="ml-2">
                          In
                        </Button>
                      )}
                    </div>
                  </div>
                  {/* <Divider orientation="left">Vật tư tiêu hao</Divider> */}
                  <div className="p-2">
                    <div className="flex ">
                      <div className="flex gap-1 w-1/4">
                        <label className="w-1/3 text-end font-semibold">
                          <span className="text-red-500">(*)&nbsp;</span>Ngày tiêm:
                        </label>
                        <DatePicker
                          status={formik.values.ngaytiem?.length <= 0 ? 'error' : ''}
                          placeholder="Chọn ngày tiêm..."
                          allowClear={false}
                          format={dateFormat}
                          maxDate={dayjs(now)}
                          value={formik.values.ngaytiem && dayjs(formik.values.ngaytiem)}
                          onChange={handleDateChange('ngaytiem')}
                          className="w-2/3"
                          size="small"
                        />
                      </div>
                      <div className="flex gap-1 w-1/4 ">
                        <label className="w-1/3 text-end font-semibold">
                          <span className="text-red-500">(*)&nbsp;</span>Ngày k.tra:
                        </label>
                        <DatePicker
                          status={formik.values.ngaykiemtra?.length <= 0 ? 'error' : ''}
                          placeholder="Chọn ngày kiểm tra..."
                          allowClear={false}
                          format={dateFormat}
                          minDate={formik.values.ngaytiem && dayjs(formik.values.ngaytiem)}
                          maxDate={dayjs(now)}
                          onChange={handleDateChange('ngaykiemtra')}
                          value={formik.values.ngaykiemtra && dayjs(formik.values.ngaykiemtra)}
                          className="w-2/3"
                          size="small"
                        />
                      </div>
                      <div className="flex gap-1 w-1/4 ">
                        <label className="w-1/3 text-end font-semibold">Mạch:</label>
                        <Input
                          onChange={formik.handleChange}
                          value={formik.values.machbenhnhan}
                          name="machbenhnhan"
                          className="w-2/3"
                          addonAfter="Lần/p"
                          size="small"
                        />
                      </div>
                      <div className="flex gap-1 w-1/4 ">
                        <label className="w-1/3 text-end font-semibold">Nhiệt độ:</label>
                        <Input
                          onChange={formik.handleChange}
                          value={formik.values.nhietdo}
                          name="nhietdo"
                          className="w-2/3"
                          addonAfter={<>&#8451;</>}
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="flex mt-1">
                      <div className="flex gap-1 w-1/4 ">
                        <label className="w-1/3 text-end font-semibold">
                          <span className="text-red-500">(*)&nbsp;</span>Giờ tiêm:
                        </label>
                        <TimePicker
                          status={formik.values.giotiem?.length <= 0 ? 'error' : ''}
                          placeholder="Chọn giờ tiêm..."
                          allowClear={false}
                          needConfirm={false}
                          format={timeFormat}
                          onChange={handleTimeChange('giotiem')}
                          value={
                            formik.values.giotiem &&
                            dayjs(formik.values.giotiem, dateFormatAPI + 'T' + timeFormat + ':ss')
                          }
                          className="w-2/3"
                          size="small"
                        />
                      </div>
                      <div className="flex gap-1 w-1/4 ">
                        <label className="w-1/3 text-end font-semibold">
                          <span className="text-red-500">(*)&nbsp;</span>Giờ k.tra:
                        </label>
                        <TimePicker
                          status={formik.values.giokiemtra?.length <= 0 ? 'error' : ''}
                          placeholder="Chọn giờ kiểm tra..."
                          allowClear={false}
                          needConfirm={false}
                          format={timeFormat}
                          onChange={handleTimeChange('giokiemtra')}
                          value={
                            formik.values.giokiemtra &&
                            dayjs(
                              formik.values.giokiemtra,
                              dateFormatAPI + 'T' + timeFormat + ':ss',
                            )
                          }
                          className="w-2/3"
                          size="small"
                        />
                      </div>
                      <div className="flex gap-1 w-1/4 ">
                        <label className="w-1/3 text-end font-semibold">Huyết áp:</label>
                        <Input
                          onChange={formik.handleChange}
                          value={formik.values.huyetap}
                          name="huyetap"
                          className="w-2/3"
                          addonAfter="mmHg"
                          size="small"
                        />
                      </div>
                      <div className="flex gap-1 w-1/4 ">
                        <label className="w-1/3 text-end font-semibold">Kiểm tra:</label>
                        <Input
                          disabled
                          style={{ color: 'black' }}
                          // type="Number"
                          // onChange={formik.handleChange}
                          // name="nhietdo"
                          // readOnly
                          // variant="filled"
                          value={formik.values.kiemtra}
                          className="w-2/3"
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="mt-1 grid grid-cols-3">
                      <div className="flex">
                        <label className="w-1/4 text-end font-semibold">Toàn trạng:</label>
                        <Radio.Group
                          onChange={formik.handleChange}
                          value={formik.values.toantrang}
                          name="toantrang"
                        >
                          <Radio value={0} className="pl-5">
                            Bình thường
                          </Radio>
                          <Radio
                            value={1}
                            className={
                              `ml-5 ` +
                              (formik.values.toantrang === 1 && ` text-red-500 font-semibold`)
                            }
                          >
                            Bất thường
                          </Radio>
                        </Radio.Group>
                      </div>
                      <div className="col-span-2">
                        <Input
                          status={
                            formik.values.toantrang === 1 &&
                            (!formik.values.toantrangghichu ||
                              formik.values.toantrangghichu?.trim().length <= 0)
                              ? 'error'
                              : ''
                          }
                          disabled={formik.values.toantrang === 0}
                          onChange={formik.handleChange}
                          value={formik.values.toantrangghichu}
                          name="toantrangghichu"
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="mt-1 grid grid-cols-3">
                      <div className="flex">
                        <label className="w-1/4 text-end font-semibold">Tri giác:</label>
                        <Radio.Group
                          onChange={formik.handleChange}
                          value={formik.values.trigiac}
                          name="trigiac"
                        >
                          <Radio value={0} className="pl-5">
                            Bình thường
                          </Radio>
                          <Radio
                            value={1}
                            className={
                              `ml-5 ` +
                              (formik.values.trigiac === 1 && ` text-red-500 font-semibold`)
                            }
                          >
                            Bất thường
                          </Radio>
                        </Radio.Group>
                      </div>
                      <div className="col-span-2">
                        <Input
                          status={
                            formik.values.trigiac === 1 &&
                            (!formik.values.trigiacghichu ||
                              formik.values.trigiacghichu?.trim().length <= 0)
                              ? 'error'
                              : ''
                          }
                          disabled={formik.values.trigiac === 0}
                          onChange={formik.handleChange}
                          value={formik.values.trigiacghichu}
                          name="trigiacghichu"
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="mt-1 grid grid-cols-3">
                      <div className="flex">
                        <label className="w-1/4 text-end font-semibold">Mạch:</label>
                        <Radio.Group
                          onChange={formik.handleChange}
                          value={formik.values.mach}
                          name="mach"
                        >
                          <Radio value={0} className="pl-5">
                            Bình thường
                          </Radio>
                          <Radio
                            value={1}
                            className={
                              `ml-5 ` + (formik.values.mach === 1 && ` text-red-500 font-semibold`)
                            }
                          >
                            Bất thường
                          </Radio>
                        </Radio.Group>
                      </div>
                      <div className="col-span-2">
                        <Input
                          status={
                            formik.values.mach === 1 &&
                            (!formik.values.machghichu ||
                              formik.values.machghichu?.trim().length <= 0)
                              ? 'error'
                              : ''
                          }
                          disabled={formik.values.mach === 0}
                          onChange={formik.handleChange}
                          value={formik.values.machghichu}
                          name="machghichu"
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="mt-1 grid grid-cols-3">
                      <div className="flex">
                        <label className="w-1/4 text-end font-semibold">Nhịp thở:</label>
                        <Radio.Group
                          onChange={formik.handleChange}
                          value={formik.values.nhiptho}
                          name="nhiptho"
                        >
                          <Radio value={0} className="pl-5">
                            Bình thường
                          </Radio>
                          <Radio
                            value={1}
                            className={
                              `ml-5 ` +
                              (formik.values.nhiptho === 1 && ` text-red-500 font-semibold`)
                            }
                          >
                            Bất thường
                          </Radio>
                        </Radio.Group>
                      </div>
                      <div className="col-span-2">
                        <Input
                          status={
                            formik.values.nhiptho === 1 &&
                            (!formik.values.nhipthoghichu ||
                              formik.values.nhipthoghichu?.trim().length <= 0)
                              ? 'error'
                              : ''
                          }
                          disabled={formik.values.nhiptho === 0}
                          onChange={formik.handleChange}
                          value={formik.values.nhipthoghichu}
                          name="nhipthoghichu"
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="mt-1 grid grid-cols-3">
                      <div className="flex">
                        <label className="w-1/4 text-end font-semibold">Da niêm:</label>
                        <Radio.Group
                          onChange={formik.handleChange}
                          value={formik.values.daniem}
                          name="daniem"
                        >
                          <Radio value={0} className="pl-5">
                            Bình thường
                          </Radio>
                          <Radio
                            value={1}
                            className={
                              `ml-5 ` +
                              (formik.values.daniem === 1 && ` text-red-500 font-semibold`)
                            }
                          >
                            Bất thường
                          </Radio>
                        </Radio.Group>
                      </div>
                      <div className="col-span-2">
                        <Input
                          status={
                            formik.values.daniem === 1 &&
                            (!formik.values.daniemghichu ||
                              formik.values.daniemghichu?.trim().length <= 0)
                              ? 'error'
                              : ''
                          }
                          disabled={formik.values.daniem === 0}
                          onChange={formik.handleChange}
                          value={formik.values.daniemghichu}
                          name="daniemghichu"
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="mt-1 grid grid-cols-3">
                      <div className="flex">
                        <label className="w-1/4 text-end font-semibold">Vị trí tiêm:</label>
                        <Radio.Group
                          onChange={formik.handleChange}
                          value={formik.values.vitritiem}
                          name="vitritiem"
                        >
                          <Radio value={0} className="pl-5">
                            Bình thường
                          </Radio>
                          <Radio
                            value={1}
                            className={
                              `ml-5 ` +
                              (formik.values.vitritiem === 1 && ` text-red-500 font-semibold`)
                            }
                          >
                            Bất thường
                          </Radio>
                        </Radio.Group>
                      </div>
                      <div className="col-span-2">
                        <Input
                          status={
                            formik.values.vitritiem === 1 &&
                            (!formik.values.vitritiemghichu ||
                              formik.values.vitritiemghichu?.trim().length <= 0)
                              ? 'error'
                              : ''
                          }
                          disabled={formik.values.vitritiem === 0}
                          onChange={formik.handleChange}
                          value={formik.values.vitritiemghichu}
                          name="vitritiemghichu"
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="mt-1 grid grid-cols-3">
                      <div className="flex">
                        <label className="w-1/4 text-end font-semibold">Dấu hiệu khác:</label>
                        <Radio.Group
                          onChange={formik.handleChange}
                          value={formik.values.dauhieukhac}
                          name="dauhieukhac"
                        >
                          <Radio value={0} className="pl-5">
                            Bình thường
                          </Radio>
                          <Radio
                            value={1}
                            className={
                              `ml-5 ` +
                              (formik.values.dauhieukhac === 1 && ` text-red-500 font-semibold`)
                            }
                          >
                            Bất thường
                          </Radio>
                        </Radio.Group>
                      </div>
                      <div className="col-span-2">
                        <Input
                          status={
                            formik.values.dauhieukhac === 1 &&
                            (!formik.values.dauhieukhacghichu ||
                              formik.values.dauhieukhacghichu?.trim().length <= 0)
                              ? 'error'
                              : ''
                          }
                          disabled={formik.values.dauhieukhac === 0}
                          onChange={formik.handleChange}
                          value={formik.values.dauhieukhacghichu}
                          name="dauhieukhacghichu"
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="mt-1">
                      <div className="grid grid-cols-12">
                        <label className="text-end font-semibold">Xử trí:</label>
                        <div className="col-span-11 pl-1">
                          <Input
                            onChange={formik.handleChange}
                            value={formik.values.xutri}
                            name="xutri"
                            size="small"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-1 grid grid-cols-3">
                      <div className="flex">
                        <label className="w-1/4 text-end font-semibold">Kết quả:</label>
                        <Radio.Group
                          onChange={formik.handleChange}
                          value={formik.values.ketqua}
                          name="ketqua"
                        >
                          <Radio value={0} className="pl-5">
                            Bình thường
                          </Radio>
                          <Radio
                            value={1}
                            className={
                              `ml-5 ` +
                              (formik.values.ketqua === 1 && ` text-red-500 font-semibold`)
                            }
                          >
                            Bất thường
                          </Radio>
                        </Radio.Group>
                      </div>
                      <div className="col-span-2">
                        <Input
                          status={
                            formik.values.ketqua === 1 &&
                            (!formik.values.ketquaghichu ||
                              formik.values.ketquaghichu?.trim().length <= 0)
                              ? 'error'
                              : ''
                          }
                          disabled={formik.values.ketqua === 0}
                          onChange={formik.handleChange}
                          value={formik.values.ketquaghichu}
                          name="ketquaghichu"
                          size="small"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <h2 className="font-semibold  text-lg m-2 text-gray-500">Vắc xin</h2>
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 3,
                        fontSize: 13,
                      },
                      components: {
                        Table: {
                          rowHoverBg: '#ecf0f1',
                          headerBg: '#e6e6e6',
                          footerBg: '#e6e6e6',
                          borderColor: '#BABABA',
                        },
                      },
              
                    }}
                  >
                    <Table
                      pagination={false}
                      dataSource={DetailToaThuocBN?.DanhSachThuoc ?? []}
                      columns={[
                        {
                          title: 'STT',
                          dataIndex: 'stt',
                          key: 'stt',
                          width: 40,
                          align: 'center',
                          render: (text, record, index) => ++index,
                          fixed: 'left',
                        },
                        {
                          title: 'Tên vắc xin',
                          dataIndex: 'tenbietduoc',
                          key: 'tenbietduoc',
                          fixed: 'left',
                        },
                        {
                          title: 'Tên nhà sản xuất',
                          dataIndex: 'nhasanxuat',
                          key: 'nhasanxuat',
                          width: 175,
                        },
                        {
                          title: <p className="text-center">Số đăng ký</p>,
                          dataIndex: 'masodangky',
                          key: 'masodangky',
                          width: 125,
                        },
                        {
                          title: 'Số lô',
                          dataIndex: 'solo',
                          key: 'solo',
                          width: 65,
                          align: 'center',
                        },
                        {
                          title: 'Hạn dùng',
                          dataIndex: 'handung',
                          key: 'handung',
                          width: 85,
                          align: 'center',
                          render: (text, record, index) =>
                            text && moment(text).format('DD/MM/YYYY'),
                        },
                        {
                          title: 'Đơn vị',
                          dataIndex: 'dvt',
                          key: 'dvt',
                          align: 'center',
                          width: 65,
                        },
                        {
                          title: 'Mũi',
                          dataIndex: 'muitiem',
                          key: 'muitiem',
                          align: 'center',
                          width: 40,
                        },
                        {
                          title: 'Liều dùng (ml)',
                          dataIndex: 'lieudung',
                          key: 'lieudung',
                          width: 100,
                          align: 'center',
                        },
                        {
                          title: 'Đường dùng',
                          dataIndex: 'duongdung',
                          key: 'duongdung',
                          width: 90,
                          align: 'center',
                        },
                        {
                          title: 'SL',
                          dataIndex: 'soluong',
                          key: 'soluong',
                          width: 40,
                          align: 'center',
                        },
                        // {
                        //   title: 'Ngày tái khám',
                        //   dataIndex: 'taikhamtiemchung',
                        //   key: 'taikhamtiemchung',
                        //   width: 100,
                        //   align: 'center',
                        //   render: (text, record, index) =>
                        //     text && moment(text).format('DD/MM/YYYY'),
                        // },
                        {
                          title: 'Ngày hẹn tiêm',
                          dataIndex: 'ngayhentiem',
                          key: 'ngayhentiem',
                          width: 100,
                          align: 'center',
                          render: (text, record, index) =>
                            text && moment(text).format('DD/MM/YYYY'),
                        },
                        {
                          title: 'Ghi chú',
                          dataIndex: 'ghichu',
                          key: 'ghichu',
                          align: 'center',
                          width: 150,
                          render: (text, record, index) => (
                            <Input value={text} size="small" readOnly={true} variant="filled" />
                          ),
                        },
                      ]}
                      bordered
                      scroll={{ y: 175, x: 1400 }}
                    />
                  </ConfigProvider>
                </div>
              </>
            ),
          },
          {
            key: '2',
            label: 'Vật tư tiêu hao',
            children: (
              <>
                <div className="mb-2">
                  {/* <Divider orientation="left">Vật tư tiêu hao</Divider> */}
                  <div className="flex justify-between pr-2">
                    <h2 className="font-semibold text-lg ml-2 text-gray-500">Vật tư</h2>
                    <Button
                      disabled={
                        !infoBNTiemChung?.IDBNTTC ||
                        (infoBNTiemChung?.DanhSachDichVu?.length > 0 &&
                          infoBNTiemChung?.DanhSachDichVu[0]?.trangthaikb === 4) ||
                        infoBNTiemChung?.DanhSachVatTuTieuHao?.length > 0
                      }
                      onClick={handleLuuVatTuTC}
                      type="primary"
                    >
                      Lưu
                    </Button>
                  </div>
                  <div className="p-2">
                    <div className="flex mb-2">
                      <div className="w-[12%]">
                        <Select
                          value={khoVatTu}
                          options={[
                            {
                              value: 1,
                              label: 'Kho điều dưỡng',
                            },
                            {
                              value: 2,
                              label: 'Kho cấp cứu',
                            },
                          ]}
                          className="w-full"
                          onChange={setKhoVatTu}
                        />
                      </div>
                      <div className="w-[88%] pl-1">
                        <Select
                          placeholder="Nhập tên vật tư..."
                          className="w-full"
                          showSearch
                          onChange={handleVTTieuHao}
                          filterOption={(input, option) => {
                            const label = option?.label ?? ''
                            const tenBietDuoc = option?.tenBietDuoc ?? ''
                            const searchText = input.toLowerCase()
                            return (
                              label.toLowerCase().includes(searchText) ||
                              tenBietDuoc.toLowerCase().includes(searchText)
                            )
                          }}
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '')
                              .toLowerCase()
                              .localeCompare((optionB?.label ?? '').toLowerCase())
                          }
                          value={null}
                          options={listVT?.map((items) => ({
                            key: items.iD,
                            value: items.iD,
                            label: items.thuocVatTu.tenHoatChat,
                            tenBietDuoc: items.thuocVatTu.tenBietDuoc,
                            dvt: items.thuocVatTu.dvt,
                            soLo: items.soLo,
                            hanDung: items.hanDung,
                            soHoaDon: items.soHoaDon,
                            maSoDangKy: items.maSoDangKy,
                            ton: items.tonKho,
                          }))}
                          optionRender={(options) => (
                            <ul className="flex">
                              <li className="w-[50%] ">
                                <p className="font-semibold w-full text-pretty">
                                  {options.data.label}
                                </p>
                                <span className="text-xs w-full text-gray-500">
                                  {' '}
                                  ({options.data.tenBietDuoc})
                                </span>
                              </li>
                              <li className="w-[12.5%] border-x flex items-center justify-center text-center">
                                {options.data.soLo}
                              </li>
                              <li className="w-[12.5%] text-center flex items-center justify-center">
                                {moment(options.data.hanDung).format('DD/MM/YYYY')}
                              </li>
                              <li className="w-[12.5%] border-x text-center flex items-center justify-center">
                                {options.data.ton}
                              </li>
                              <li className="w-[12.5%]  text-center flex items-center justify-center">
                                {options.data.dvt}
                              </li>
                            </ul>
                          )}
                          // filterOption={(input, option) =>
                          //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          // }
                          // options={listVT?.map((items) => ({
                          //   label: items.thuocVatTu.tenBietDuoc,
                          //   value: items.idThuoc
                          // }))}
                        />
                      </div>
                    </div>
                    {/* <Select
                      placeholder="Nhập tên vật tư..."
                      className='w-full mb-2'
                      showSearch
                      onChange={handleVTTieuHao}
                      filterOption={(input, option) => {
                        const label = option?.label ?? ''
                        const tenBietDuoc = option?.tenBietDuoc ?? ''
                        const searchText = input.toLowerCase()
                        return (
                          label.toLowerCase().includes(searchText) ||
                          tenBietDuoc.toLowerCase().includes(searchText)
                        )
                      }}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      value={null}
                      options={listVT?.map((items) => ({
                        key: items.iD,
                        value: items.iD,
                        label: items.thuocVatTu.tenHoatChat,
                        tenBietDuoc: items.thuocVatTu.tenBietDuoc,
                        dvt: items.thuocVatTu.dvt,
                        soLo: items.soLo,
                        hanDung: items.hanDung,
                        soHoaDon: items.soHoaDon,
                        maSoDangKy: items.maSoDangKy,
                        ton: items.tonKho,
                      }))}
                      optionRender={(options) => (<ul className='flex'>
                        <li className="w-[50%] ">
                          <p className="font-semibold w-full text-pretty">{options.data.label}</p>
                          <span className="text-xs w-full text-gray-500"> ({options.data.tenBietDuoc})</span>
                        </li>
                        <li className="w-[12.5%] border-x flex items-center justify-center text-center">
                          {options.data.soLo}
                        </li>
                        <li className="w-[12.5%] text-center flex items-center justify-center">
                          {moment(options.data.hanDung).format('DD/MM/YYYY')}
                        </li>
                        <li className="w-[12.5%] border-x text-center flex items-center justify-center">
                          {options.data.ton}
                        </li>
                        <li className="w-[12.5%]  text-center flex items-center justify-center">
                          {options.data.dvt}
                        </li>
                      </ul>
                      )}
                    // filterOption={(input, option) =>
                    //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    // }
                    // options={listVT?.map((items) => ({
                    //   label: items.thuocVatTu.tenBietDuoc,
                    //   value: items.idThuoc
                    // }))} 
                    /> */}
                    <ConfigProvider
                      theme={{
                        token: {
                          padding: 5,
                        },
                        components: {
                          Table: {
                            rowHoverBg: '#ecf0f1',
                            headerBg: '#e6e6e6',
                            footerBg: '#e6e6e6',
                            borderColor: '#BABABA',
                          },
                        },
                
                      }}
                    >
                      <Table
                        pagination={false}
                        dataSource={listVTBN ?? []}
                        columns={[
                          {
                            title: 'STT',
                            dataIndex: 'stt',
                            key: 'stt',
                            align: 'center',
                            width: 40,
                            render: (text, record, index) => ++index,
                          },
                          {
                            title: 'Tên vật tư',
                            dataIndex: 'tenBietDuoc',
                            key: 'tenBietDuoc',
                          },
                          {
                            title: 'Số lô',
                            dataIndex: 'soLo',
                            key: 'soLo',
                            align: 'center',
                            width: 120,
                          },
                          {
                            title: 'Hạn dùng',
                            dataIndex: 'hanDung',
                            key: 'hanDung',
                            align: 'center',
                            width: 90,
                            render: (text, record, index) =>
                              text && moment(text).format('DD/MM/YYYY'),
                          },
                          {
                            title: 'Đơn vị',
                            dataIndex: 'dvt',
                            key: 'dvt',
                            align: 'center',
                            width: 70,
                          },
                          {
                            title: 'SL',
                            dataIndex: 'soLuong',
                            key: 'soLuong',
                            align: 'center',
                            width: 65,
                            render: (text, record, index) => (
                              <Input
                                onChange={(e) => {
                                  handleChangeSoLuong(record, e.target.value)
                                }}
                                value={text}
                                min={0}
                                type="number"
                                size="small"
                              />
                            ),
                          },
                          {
                            title: 'Tồn',
                            dataIndex: 'tonKho',
                            key: 'tonKho',
                            align: 'center',
                            width: 65,
                          },
                          {
                            title: 'Ghi chú',
                            dataIndex: 'ghiChu',
                            key: 'ghiChu',
                            align: 'center',
                            width: 200,
                            render: (text, record, index) => (
                              <Input
                                onChange={(e) => {
                                  handleChangeGhiChu(record, e.target.value)
                                }}
                                value={text ?? ''}
                                size="small"
                              />
                            ),
                          },
                          {
                            title: '',
                            dataIndex: 'action',
                            key: 'action',
                            align: 'center',
                            width: 40,
                            render: (text, record, index) => (
                              <>
                                {infoBNTiemChung?.DanhSachDichVu?.length > 0 &&
                                  infoBNTiemChung?.DanhSachDichVu[0]?.trangthaikb !== 4 && (
                                    <CloseOutlined
                                      onClick={() => {
                                        dispatch({
                                          type: typeAction.DISPATCH_DELETE_VT_BN_THUTHUAT,
                                          payload: record.iD,
                                        })
                                      }}
                                      className="text-red-500 cursor-pointer"
                                    />
                                  )}
                              </>
                            ),
                          },
                        ]}
                        bordered
                        scroll={{ y: 175 }}
                      />
                    </ConfigProvider>
                  </div>
                </div>
                <div className="p-2">
                  {/* <Divider orientation="left">Dịch vụ</Divider> */}
                  <h2 className="font-semibold  text-lg m-2 text-gray-500">Dịch vụ</h2>
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 5,
                      },
                      components: {
                        Table: {
                          rowHoverBg: '#ecf0f1',
                          headerBg: '#e6e6e6',
                          footerBg: '#e6e6e6',
                          borderColor: '#BABABA',
                        },
                      },
              
                    }}
                  >
                    <Table
                      pagination={false}
                      dataSource={infoBNTiemChung?.DanhSachDichVu ?? []}
                      columns={[
                        {
                          title: 'STT',
                          dataIndex: 'stt',
                          key: 'stt',
                          width: 40,
                          align: 'center',
                          render: (text, record, index) => ++index,
                        },
                        {
                          title: 'Mã DV',
                          dataIndex: 'madichvu',
                          key: 'madichvu',
                          width: 130,
                        },
                        {
                          title: 'Tên dịch vụ',
                          dataIndex: 'tendichvu',
                          key: 'tendichvu',
                        },
                        {
                          title: 'Đơn vị tính',
                          dataIndex: 'donvitinh',
                          key: 'donvitinh',
                          width: 90,
                          align: 'center',
                        },
                      ]}
                      bordered
                      scroll={{ y: 175 }}
                    />
                  </ConfigProvider>
                </div>
              </>
            ),
          },
        ]}
        // onChange={onChange}
      />
    </div>
  )
}

export default VatTu

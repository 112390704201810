import { Input, Modal, InputNumber, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { dichVuService } from '../../../services/dichVu/dichVuService'
import { putGiaVatTuDVThuThuatAction } from '../../../store/actions/hoaHongAction'
import ToastCus from '../../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const EditModal = ({
  openEdit,
  setOpenEdit,
  listCongTy,
  listChuyenKhoa,
  rowActive,
  getListGiaVatTuDVThuThuat,
}) => {
  const dispatch = useDispatch()
  const id_company = Number(localStorage.getItem('id_company'))
  const id_branch = Number(localStorage.getItem('BRANH_LOGIN'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [isLoading, setIsLoading] = useState(false)
  const [dichVu, setDichVu] = useState(null)
  const [listDichVu, setListDichVu] = useState([])
  const [congTy, setCongTy] = useState(id_company)
  const [chuyenKhoa, setChuyenKhoa] = useState('all')
  const [giaVatTu, setGiaVatTu] = useState(rowActive?.giavattu)
  const handleCancel = () => {
    setOpenEdit(false)
  }
  const resetForm = () => {
    getListGiaVatTuDVThuThuat()
    setDichVu(null)
    setCongTy(id_company)
    setGiaVatTu(0)
    setOpenEdit(false)
  }
  const handleSubmit = () => {
    if (!rowActive || !rowActive?.idhh || !rowActive?.iddv || giaVatTu === null || giaVatTu < 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng thử lại!',
      })
      return
    }
    setOpenEdit(false)
    dispatch(
      putGiaVatTuDVThuThuatAction(
        {
          idhh: rowActive?.idhh,
          iddv: rowActive?.iddv,
          tenthuthuat: rowActive?.tenthuthuat,
          giavattu: giaVatTu,
          nguoisua: infoUser?.idnv,
        },
        resetForm,
      ),
    )
  }
  useEffect(() => {
    if (rowActive?.giavattu !== giaVatTu) setGiaVatTu(rowActive?.giavattu)
  }, [rowActive])
  return (
    <Modal
      open={openEdit}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText="Lưu"
      cancelText="Huỷ bỏ"
      title={<p className="text-center">Chỉnh sửa giá vật tư cho thủ thuật</p>}
    >
      <div className="mt-5 flex flex-col gap-2">
        <div>
          <label className="text-gray-500 font-semibold">
            <span className="text-red-500">(*)</span>&nbsp;Thủ thuật:
          </label>
          <Input value={rowActive?.tenthuthuat} readOnly variant="filled" className="w-full" />
        </div>
        <div>
          <label className="text-gray-500 font-semibold">Người tạo:</label>
          <Input value={rowActive?.tennguoitao} readOnly variant="filled" className="w-full" />
        </div>
        <div>
          <label className="text-gray-500 font-semibold">Ngày tạo:</label>
          <Input
            value={
              rowActive?.ngaytao ? moment(rowActive?.ngaytao).format('DD/MM/YYYY HH:mm:ss') : '-'
            }
            readOnly
            variant="filled"
            className="w-full"
          />
        </div>
        <div>
          <label className="text-gray-500 font-semibold">Người sửa:</label>
          <Input value={rowActive?.tennguoisua} readOnly variant="filled" className="w-full" />
        </div>
        <div>
          <label className="text-gray-500 font-semibold">Ngày sửa:</label>
          <Input
            value={
              rowActive?.ngaysua ? moment(rowActive?.ngaysua).format('DD/MM/YYYY HH:mm:ss') : '-'
            }
            readOnly
            variant="filled"
            className="w-full"
          />
        </div>
        <div>
          <label className="text-gray-500 font-semibold">
            <span className="text-red-500">(*)</span>&nbsp;Giá vật tư:
          </label>
          <InputNumber
            status={giaVatTu === null && 'error'}
            className="w-full"
            min={0}
            step={100}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            value={giaVatTu}
            onChange={(value) => setGiaVatTu(value)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default EditModal

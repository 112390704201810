import { https } from '../apiService'

export const reportService = {
  getreportBybranch: (idChiNhanh, fromDate, toDate) =>
    https.get('ThongKeBenhNhan/ByNgayAndChiNhanh', {
      params: {
        idChiNhanh,
        fromDate,
        toDate,
      },
    }),
  getReportTotalByBranch: (idChuyenKhoa, idct, idChiNhanhs, fromDate, toDate) =>
    https.get(
      `ThongKeDoanhThu/DoanhThuTongByChiNhanh?idChuyenKhoa=${idChuyenKhoa}&idCongTy=${idct}${idChiNhanhs}&fromDate=${fromDate}&toDate=${toDate}`,
    ),
  getDetailReportBranch: (idChuyenKhoa, idct, idChiNhanhs, fromDate, toDate) =>
    https.get(
      `ThongKeDoanhThu/DoanhThuChiTietChiNhanh?idChuyenKhoa=${idChuyenKhoa}&idCongTy=${idct}${idChiNhanhs}&fromDate=${fromDate}&toDate=${toDate}`,
    ),
  getDetailReportBranchMonth: (idChuyenKhoa, idct, idChiNhanhs, fromDate, toDate) =>
    https.get(
      `DoanhThuChiTietThang/GetDoanhThuChiTietThang?IDCHUYENKHOA=${idChuyenKhoa}&IDCT=${idct}${idChiNhanhs}&FROMDATE=${fromDate}&TODATE=${toDate}`,
    ),
  getLoiNhuan: (idCongTy, idChuyenKhoa, idChiNhanh, fromDate, toDate) =>
    https.get(`ThongKeLoiNhuan/LoiNhuanTongByChiNhanh?${idChiNhanh}`, {
      params: {
        idCongTy,
        idChuyenKhoa,
        fromDate,
        toDate,
      },
    }),
  getLoiNhuanVaccine: (idCongTy, idChuyenKhoa, idChiNhanh, fromDate, toDate) =>
    https.get(`ThongKeLoiNhuan/LoiNhuanChiTietVacXin?${idChiNhanh}`, {
      params: {
        idCongTy,
        idChuyenKhoa,
        fromDate,
        toDate,
      },
    }),
  getDetailLoiNhuan: (idCongTy, idChuyenKhoa, idChiNhanh, fromDate, toDate) =>
    https.get(`ThongKeLoiNhuan/LoiNhuanChiTietChiNhanh?${idChiNhanh}`, {
      params: {
        idCongTy,
        idChuyenKhoa,
        fromDate,
        toDate,
      },
    }),
  getDetailLoiNhuanVacxin: (idCongTy, idChuyenKhoa, idChiNhanh, fromDate, toDate) =>
    https.get(
      `ThongKeLoiNhuan/LoiNhuanChiTietVacXin?fromDate=${fromDate}&toDate=${toDate}${idChiNhanh}`,
      {
        params: {
          idCongTy,
          idChuyenKhoa,
        },
      },
    ),
  getDetailLoiNhuanPhieuXuat: (idCongTy, idChuyenKhoa, idChiNhanh, fromDate, toDate) =>
    https.get(
      `ThongKePhieuXuat/ThongKePhieuXuatTong?fromDate=${fromDate}&toDate=${toDate}${idChiNhanh}`,
      {
        params: {
          idCongTy,
          idChuyenKhoa,
        },
      },
    ),
  getDetailLoiNhuanChiTietPhieuXuat: (idCongTy, idChuyenKhoa, idChiNhanh, fromDate, toDate) =>
    https.get(
      `ThongKePhieuXuat/ThongKePhieuXuatChiTiet?fromDate=${fromDate}&toDate=${toDate}${idChiNhanh}`,
      {
        params: {
          idCongTy,
          idChuyenKhoa,
        },
      },
    ),
  getVatTuTieuHao: (idChiNhanh, fromDate, toDate) =>
    https.get('ThongKeXuatHang/XuatHangTongByChiNhanh', {
      params: {
        idChiNhanh,
        fromDate,
        toDate,
      },
    }),
  getDetailVaTuTieuHao: (idChiNhanh, fromDate, toDate) =>
    https.get('ThongKeXuatHang/DoanhThuChiTietChiNhanh', {
      params: {
        idChiNhanh,
        fromDate,
        toDate,
      },
    }),
  getLuotKham: (idCongTy, idChiNhanh, fromDate, toDate) =>
    https.get('ThongKeLuotKham/LuotKhamTongByCondition', {
      params: {
        idCongTy,
        idChiNhanh,
        fromDate,
        toDate,
      },
    }),
  getDetailLuotKham: (idCongTy, idChiNhanh, fromDate, toDate) =>
    https.get('ThongKeLuotKham/LuotKhamChiTietByCondition', {
      params: {
        idCongTy,
        idChiNhanh,
        fromDate,
        toDate,
      },
    }),
  getTheodoiCaKham: (idCongTy, idChuyenKhoa, idChiNhanhs, fromDate, toDate) =>
    https.get(
      `ThongKeLuotKham/PhanTramLuotKhamByCondition?idCongTy=${idCongTy}${idChiNhanhs}&idChuyenKhoa=${idChuyenKhoa}&fromDate=${fromDate}&toDate=${toDate}`,
    ),
  getBranchByChuyenKhoa: (idCT, idChuyenKhoa) =>
    https.get(`ChiNhanh/ChiNhanhByCongTyAndChuyenKhoa?idCT=${idCT}`, {
      params: {
        idChuyenKhoa,
      },
    }),
  getServiceKhoPhong: (idCongTy, fromDate, toDate, idChiNhanhs) =>
    https.get(
      `ThongKeDichVu/ThongKeKhoaPhong?idCongTy=${idCongTy}&fromDate=${fromDate}&toDate=${toDate}${idChiNhanhs}`,
    ),

  getServiceNhomDichVu: (idCongTy, fromDate, toDate, idChiNhanhs) =>
    https.get(
      `ThongKeDichVu/ThongKeNhomDichVu?idCongTy=${idCongTy}&fromDate=${fromDate}&toDate=${toDate}${idChiNhanhs}`,
    ),

  getServiceDetail: (idCongTy, fromDate, toDate, idChiNhanhs) =>
    https.get(
      `ThongKeDichVu/ThongKeChiTietDichVu?idCongTy=${idCongTy}&fromDate=${fromDate}&toDate=${toDate}${idChiNhanhs}`,
    ),

  getTheoDoiNguonKhach: (idCongTy, idChiNhanhs, fromDate, toDate) =>
    https.get(
      `ThongKeLuotKham/TheoDoiNguonKhach?idCongTy=${idCongTy}&fromDate=${fromDate}&toDate=${toDate}${idChiNhanhs}`,
    ),

  getThongKeLoaiKham: (idCongTy, idChuyenKhoa, idChiNhanhs, fromDate, toDate, page) =>
    https.get(
      `ThongKeLuotKham/ThongKeLoaiKham?idCongTy=${idCongTy}&idChuyenKhoa=${idChuyenKhoa}&fromDate=${fromDate}&toDate=${toDate}${idChiNhanhs}&pageNumber=${page}`,
    ),
  getDanhSachBenhNhanKhongLayThuoc: (idct, idchuyenkhoa, idcn, tuNgay, denNgay) =>
    https.get(
      `NhaThuoc/GetDanhSachBenhNhanKhongLayThuoc?idct=${idct}${idcn}&idchuyenkhoa=${idchuyenkhoa}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getDanhSachThuocKhongLay: (idct, idChuyenKhoa, idcn, tuNgay, denNgay) =>
    https.get(
      `NhaThuoc/GetDanhSachThuocKhongLay?idct=${idct}${idcn}&idChuyenKhoa=${idChuyenKhoa}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getAllLyDoLayThuoc: () => https.get(`LyDoKoLayThuoc/GetAllLyDo`),
  getTTVPhieu: (idct, idcn, tuNgay, denNgay) =>
    https.get(
      `TheThanhVien_PhieuThu/GetBaoCaoTheThanhVienPhieuThu?idct=${idct}${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getThongKeMat: (idCongTy, fromDate, toDate, idChiNhanhs) =>
    https.get('ThongKeThang/ThongKeThangMat', {
      params: {
        idCongTy,
        fromDate,
        toDate,
      },
      paramsSerializer: (params) => {
        return `${new URLSearchParams(params).toString()}${idChiNhanhs}`
      },
    }),
  getCongMaiKTV: (keyword, idct, idcn, tuNgay, denNgay) =>
    https.get(
      `BenhNhanDichVu/GeThongKeCongMai?${keyword?.length ? `keyword=${keyword}&` : ''}idct=${idct}&${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getListChamCong: (idCNs, keyword, fromDate, toDate) =>
    https.get(
      `CheckInOut/GetAllCheckInCheckOut?${idCNs}&${keyword?.length ? `keyword=${keyword}&` : ''}fromDate=${fromDate}&toDate=${toDate}`,
    ),
  getListGongKinhDaBan: (tuNgay, denNgay, idChiNhanhs, keyword) =>
    https.get(
      `BenhNhanPhieuThu/GetThongKeGongKinh?tuNgay=${tuNgay}&denNgay=${denNgay}${keyword?.length ? `&keyword=${keyword}` : ''}&${idChiNhanhs}`,
    ),
  getThongKeXuatNhaThuoc: (queryString) =>
    https.get(`ThongKeLuotKham/ThongKeBanThuoc?${queryString}`),
  getThongKeTongHop: (idCongTy, fromDate, toDate, idchuyenkhoa, idChiNhanhs) =>
    https.get('ThongKeThang/ThongKeTongHop', {
      params: {
        idCongTy,
        fromDate,
        idchuyenkhoa,
        toDate,
      },
      paramsSerializer: (params) => {
        return `${new URLSearchParams(params).toString()}${idChiNhanhs}`
      },
    }),
  getBinhQuanGiaQuyen: (idct, thang, idnhom, pageIndex, keyword) =>
    https.get('BaoCaoTiemChung/GetBaoCaoBinhQuanGiaQuyen', {
      params: {
        idct,
        thang,
        idnhom,
        pageIndex,
        keyword,
      },
    }),
  getExportBinhQuanGiaQuyen: (idct, thang, idnhom, keyword) =>
    https.get('BaoCaoTiemChung/XuatExcelBaoCaoBinhQuanGiaQuyen', {
      params: {
        idct,
        thang,
        idnhom,
        keyword,
      },
      responseType: 'blob',
    }),
  getDoanhSoTTVNhi: (keyword, idcalamviec, idcn, tuNgay, denNgay) =>
    https.get(
      `TheThanhVien_PhieuThu/GetThongKeTheThanhVienNhi?${keyword?.length ? `keyword=${keyword}&` : ''}${idcalamviec ? `idcalamviec=${idcalamviec}&` : ''}${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}
`,
    ),
  getDoanhSoTTVSan: (keyword, idcalamviec, idcn, tuNgay, denNgay) =>
    https.get(
      `TheThanhVien_PhieuThu/GetThongKeTheThanhVienSan?${keyword?.length ? `keyword=${keyword}&` : ''}${idcalamviec ? `idcalamviec=${idcalamviec}&` : ''}${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}
  `,
    ),
  getDoanhSoTTVLao: (keyword, idcalamviec, idcn, tuNgay, denNgay) =>
    https.get(
      `TheThanhVien_PhieuThu/GetThongKeTheThanhVienLao?${keyword?.length ? `keyword=${keyword}&` : ''}${idcalamviec ? `idcalamviec=${idcalamviec}&` : ''}${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}
    `,
    ),
  getDoanhSoTTVMat: (keyword, idcalamviec, idcn, tuNgay, denNgay) =>
    https.get(
      `TheThanhVien_PhieuThu/GetThongKeTheThanhVienMat?${keyword?.length ? `keyword=${keyword}&` : ''}${idcalamviec ? `idcalamviec=${idcalamviec}&` : ''}${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}
      `,
    ),
  getBaoCaoXetNghiem: (tuNgay, denNgay, idcn) =>
    https.get(
      `BenhNhanXetNghiem/GetThongKeBaoCaoXetNghiem?${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getThongKeXuatNhaThuocTongHop: (queryString) =>
    https.get(`ThongKeLuotKham/ThongKeBanThuocTongHop?${queryString}`),
  // getBaoCaoXuatNhaThuocTongHop: (idCongTy, idChiNhanhs, idLoaiToa, keyword, fromDate, toDate) =>
  //   https.get(
  //     `ThongKeLuotKham/ThongKeBanThuocTongHop?idCongTy=${idCongTy}&${idChiNhanhs}&${idLoaiToa ? `idLoaiToa=${idLoaiToa}&` : ''}${keyword?.length ? `keyword=${keyword}&` : ''}fromDate=${fromDate}&toDate=${toDate}`,
  //   ),
  getBaoCaoVacXin: (idcn, tuNgay, denNgay) =>
    https.get(`BenhNhanPhieuThu/GetThongKeVacxin?${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`),
  getBaoCaoSoSanhTiLeToa: (idcn, tuNgay, denNgay) =>
    https.get(
      `BenhNhanPhieuThu/GetDanhSachChenhLechLayThuoc?${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getBaoCaoDoLechTiLeToa: (idcn, tuNgay, denNgay) =>
    https.get(
      `BenhNhanPhieuThu/GetDanhSachTiLeChenhLechLayThuoc?${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getBaoCaoDoanhThuThuocMotTuan: (idcn, tuNgay, denNgay) =>
    https.get(
      `BenhNhanPhieuThu/GetBaoCaoTongThongNhiTheoToa?${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getBaoCaoTinhHinhSuDung: (idcn, tuNgay, denNgay) =>
    https.get(
      `BaoCaoTiemChung/GetBaoCaoTinhHinhSuDung?${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getBaoCaoThoiGianKhamChiTietPhieu: (idChiNhanhs, tuNgay, denNgay) =>
    https.get(
      `BenhNhanThongTinChung/GetThoiGianKhamChiTietPhieu?${idChiNhanhs}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getBaoCaoThoiGianKhamTheoChiNhanh: (idChiNhanhs, tuNgay, denNgay) =>
    https.get(
      `BenhNhanThongTinChung/GetThoiGianKhamTheoChiNhanh?${idChiNhanhs}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getBaoCaoThoiGianKhamTheoBacSi: (idChiNhanhs, tuNgay, denNgay) =>
    https.get(
      `BenhNhanThongTinChung/GetThoiGianKhamTheoBacSi?${idChiNhanhs}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getListThuocHetHan: (idcn, idkho, tuNgay, denNgay) =>
    https.get(
      `ThuocVatTu/GetThuocHetHan?${idcn}${idkho ? `&idkho=${idkho}` : ''}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getTheoDoiDoiTuong:(idct,idcn,tuNgay,denNgay) => https.get(`BaoCaoTiemChung/GetDanhSachTheoDoiDoiTuong?idct=${idct}${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`),
  getTongQuanTTV:(idct,idcn,tuNgay,denNgay) => https.get(`TheThanhVien_PhieuThu/GetBaoCaoTongQuanTTV?idct=${idct}${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`),
}

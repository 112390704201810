import * as typeAction from '../constants/constants'

export const getAllCtyCRM = () => ({
  type: typeAction.GET_ALL_CTY_CRM,
})

export const addCtyCRM = (form, onLoad) => ({
  type: typeAction.ADD_CTY_CRM,
  form,
  onLoad
})

export const editCtyCRM = (form, onLoad) => ({
    type: typeAction.EDIT_CTY_CRM,
    form,
    onLoad
  })

export const getCtyCRMById = (id) => ({
    type: typeAction.GET_CTY_CRM_BY_ID,
    id,
  })

export const deleteCtyCRM = (idcty) => ({
  type: typeAction.DELETE_CTY_CRM,
  idcty,
})
export const detailCtyCRM = (idct) => ({
  type: typeAction.GET_INFO_DETAIL_COMPANY_CRM,
  idct,
})
import React from 'react'
import { Tabs } from 'antd'
import QuocGia from './QuocGia/QuocGia'
import Tinh from './Tinh/Tinh'
import QuanHuyen from './QuanHuyen/QuanHuyen'
import PhuongXa from './PhuongXa/PhuongXa'
const Address = () => {
  return (
    <div className="2xl:p-2 p-2 bg-[#E7E7E7] h-full">
      <div className="bg-white p-2 rounded-lg border h-full">
        <Tabs
          items={[
            {
              label: 'Quốc gia',
              key: 1,
              children: <QuocGia />,
            },
            {
              label: 'Tỉnh/Thành phố',
              key: 2,
              children: <Tinh />,
            },
            {
              label: 'Quận/Huyện',
              key: 3,
              children: <QuanHuyen />,
            },
            {
              label: 'Phường/ Xã',
              key: 4,
              children: <PhuongXa />,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default Address

import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
import { dichVuService } from '../../services/dichVu/dichVuService'
export function* hoaHongSaga() {
  // THÊM GIÁ VẬT TƯ CHO DỊCH VỤ THỦ THUẬT ĐỂ TÍNH HOA HỒNG
  yield takeLatest(
    typeAction.POST_GIAVATTU_DV_THUTHUAT_HOAHONG,
    function* postGiaVatTuDVThuThuatSaga({ type, form, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => dichVuService.postGiaVatTuChoDVThuThuat(form))
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: err?.response?.data?.message
            ? `${err?.response?.data?.message}`
            : 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // CHỈNH SỬA GIÁ VẬT TƯ CHO DỊCH VỤ THỦ THUẬT ĐỂ TÍNH HOA HỒNG
  yield takeLatest(
    typeAction.PUT_GIAVATTU_DV_THUTHUAT_HOAHONG,
    function* putGiaVatTuDVThuThuatSaga({ type, form, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => dichVuService.putGiaVatTuChoDVThuThuat(form))
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // XÓA GIÁ VẬT TƯ CHO DỊCH VỤ THỦ THUẬT ĐỂ TÍNH HOA HỒNG
  yield takeLatest(
    typeAction.DELETE_GIAVATTU_DV_THUTHUAT_HOAHONG,
    function* deleteGiaVatTuChoDVThuThuatSaga({ type, idhh, getListGiaVatTuDVThuThuat }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => dichVuService.deleteGiaVatTuChoDVThuThuat(idhh))
        yield call(() => getListGiaVatTuDVThuThuat())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}

import React, { useRef, useState } from 'react'
import { Modal, Table, ConfigProvider, notification, Input, Space, Button as AntButton } from 'antd'
import Button from '@mui/material/Button'
import { ExclamationCircleFilled, SearchOutlined } from '@ant-design/icons'
import * as typeAction from '../../../../store/constants/constants'
import { useDispatch, useSelector } from 'react-redux'
import ButtonImportExcel from '../ButtonImportExcel/ButtonImportExcel'
import { importListBNCRM } from '../../../../store/actions/benhnhanCRMAction'
import Highlighter from 'react-highlight-words'

const { confirm } = Modal
const ModalImport = () => {
  const dispatch = useDispatch()
  const { modalImportKhamDoan } = useSelector((state) => state.modalReducer)
  const { listBNImport } = useSelector((state) => state.benhnhanCRMReducer)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const handleCancel = () => {
    dispatch({
      type: typeAction.IMPORT_DATA_CRM,
      payload: null,
    })
    dispatch({
      type: typeAction.CLOSE_MODAL_IMPORT_KHAM_DOAN,
    })
  }
  const [api, contextHolder] = notification.useNotification()
  const openNotificationWithIcon = (type, desc) => {
    api[type]({
      message: 'Lỗi thêm file',
      description: desc,
      placement: 'top',
    })
  }
  const showConfirm = () => {
    confirm({
      title: 'Bạn có chắc muốn thêm danh sách  ?',
      icon: <ExclamationCircleFilled />,
      content: 'Danh sách sẽ được tạo nếu tiếp tục',
      okText: 'Tạo',
      cancelText: 'Huỷ',
      onOk() {
        dispatch(importListBNCRM(listBNImport))
        handleCancel()
      },
      onCancel() {
        dispatch({
          type: typeAction.IMPORT_DATA_CRM,
          payload: null,
        })
      },
    })
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <AntButton
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </AntButton>
          <AntButton
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </AntButton>
          <AntButton
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </AntButton>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Tên BN',
      dataIndex: 'TENBN',
      key: 'TEBN',
      width: 200,
      ...getColumnSearchProps('TENBN'),
    },
    {
      title: 'Giới tính',
      dataIndex: 'GIOITINH',
      key: 'GIOITINH',
      width: 70,
      align: 'center',
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'NGAYSINH',
      key: 'NGAYSINH',
      width: 100,
    },
    {
      title: 'SĐT',
      dataIndex: 'SODIENTHOAI',
      key: 'SODIENTHOAI',
      width: 100,
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'TENKH',
      key: 'TENKH',
      width: 200,
    },
    {
      title: 'MAKH',
      dataIndex: 'MAKH',
      key: 'MAKH',
      width: 100,
      align: 'center',
    },
    {
      title: 'Mã gói khám',
      dataIndex: 'MAGK',
      key: 'MAGK',
      width: 100,
      align: 'center',
      ...getColumnSearchProps('MAGK'),
    },
  ]

  return (
    <>
      <Modal
        footer={null}
        width={1200}
        className="text-center"
        title="Import danh sách bệnh nhân"
        open={modalImportKhamDoan}
        onCancel={handleCancel}
      >
        {contextHolder}
        <div className="text-start">
          <ButtonImportExcel openNotificationWithIcon={openNotificationWithIcon} />
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
      
            }}
          >
            <Table
              bordered
              columns={columns}
              dataSource={listBNImport}
              pagination={false}
              scroll={{ y: 600 }}
            />
          </ConfigProvider>
        </div>
        <div className="flex gap-5 justify-end mt-2">
          <Button
            disabled={listBNImport ? false : true}
            onClick={showConfirm}
            variant="contained"
            size="small"
          >
            Tạo danh sách
          </Button>
          <Button
            onClick={() => {
              handleCancel()
              dispatch({
                type: typeAction.RESET_DATA_BN_IMPORT,
              })
            }}
            variant="outlined"
            color="warning"
            size="small"
          >
            {' '}
            Huỷ
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default ModalImport

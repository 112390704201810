import React, { useEffect, useState } from 'react'
import { Button, ConfigProvider, Table, Input, Modal, Form, message } from 'antd'
import { https } from '../../../../services/apiService'
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

const { confirm } = Modal

const Tinh = () => {
  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [newProvince, setNewProvince] = useState({ tenTinh: '' })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await https.get('/tinh')
        setData(response.data)
        setFilteredData(response.data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase()
    setSearchText(value)
    const filtered = data.filter((item) => item.tenTinh?.toLowerCase().includes(value))
    setFilteredData(filtered)
  }

  const handleAddProvince = async () => {
    try {
      const response = await https.post('/tinh/CreateTinhTP', newProvince)
      setData([...data, response.data])
      setFilteredData([...data, response.data])
      setIsModalVisible(false)
      setNewProvince({ tenTinh: '' })
      message.success('Đã thêm tỉnh thành công')
    } catch (error) {
      console.error('Error adding province:', error)
      message.error('Thêm tỉnh thất bại')
    }
  }

  const showDeleteConfirm = (idTinh) => {
    confirm({
      title: 'Bạn có chắc chắn muốn xóa tỉnh này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Xóa',
      okType: 'danger',
      cancelText: 'Hủy',
      onOk() {
        handleDeleteProvince(idTinh)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const handleDeleteProvince = async (idTinh) => {
    try {
      await https.delete(`/tinh/DeleteTinhTP?idTinh=${idTinh}`)
      const updatedData = data.filter((item) => item.idTinh !== idTinh)
      setData(updatedData)
      setFilteredData(updatedData)
      message.success('Đã xóa thành công')
    } catch (error) {
      console.error('Error deleting province:', error)
      message.error('Xóa tỉnh thất bại')
    }
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên Tỉnh',
      dataIndex: 'tenTinh',
      key: 'tenTinh',
    },
    {
      title: 'Hành động',
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
          {/* <Button type="primary" size="small" icon={<EditOutlined />}>
            Sửa
          </Button> */}
          <Button
            type="primary"
            danger
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => showDeleteConfirm(record.idTinh)}
          >
            Xóa
          </Button>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="flex justify-between mb-2">
        <Input
          placeholder="Tìm kiếm tỉnh"
          value={searchText}
          onChange={handleSearch}
          style={{ width: 200 }}
        />
        <Button type="primary" onClick={() => setIsModalVisible(true)}>
          Thêm tỉnh
        </Button>
      </div>

      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table columns={columns} dataSource={filteredData} rowKey="idTinh" />
      </ConfigProvider>

      <Modal
        title="Thêm tỉnh"
        visible={isModalVisible}
        onOk={handleAddProvince}
        onCancel={() => setIsModalVisible(false)}
        okText="Lưu"
        cancelText="Hủy"
      >
        <Form layout="vertical">
          <Form.Item label="Tên Tỉnh">
            <Input
              value={newProvince.tenTinh}
              onChange={(e) => setNewProvince({ ...newProvince, tenTinh: e.target.value })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default Tinh

import React, { useEffect, useState } from 'react'
import { Button, ConfigProvider, Table, Input, Select } from 'antd'
import { https } from '../../../../services/apiService'
import { EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'

const { Option } = Select

const PhuongXa = () => {
  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [tinhData, setTinhData] = useState([])
  const [quanHuyenData, setQuanHuyenData] = useState([])
  const [selectedTinh, setSelectedTinh] = useState(null)
  const [selectedQuanHuyen, setSelectedQuanHuyen] = useState(null)

  useEffect(() => {
    const fetchTinhData = async () => {
      try {
        const response = await https.get('/tinh')
        setTinhData(response.data)
      } catch (error) {
        console.error('Error fetching Tinh data:', error)
      }
    }

    fetchTinhData()
  }, [])

  useEffect(() => {
    if (selectedTinh) {
      const fetchQuanHuyenData = async () => {
        try {
          const response = await https.get(`/QuanHuyen/${selectedTinh}`)
          setQuanHuyenData(response.data)
        } catch (error) {
          console.error('Error fetching QuanHuyen data:', error)
        }
      }

      fetchQuanHuyenData()
    }
  }, [selectedTinh])

  useEffect(() => {
    if (selectedQuanHuyen) {
      const fetchPhuongXaData = async () => {
        try {
          const response = await https.get(`/PhuongXa/${selectedQuanHuyen}`)
          setData(response.data)
          setFilteredData(response.data)
        } catch (error) {
          console.error('Error fetching PhuongXa data:', error)
        }
      }

      fetchPhuongXaData()
    }
  }, [selectedQuanHuyen])

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase()
    setSearchText(value)
    const filtered = data.filter((item) => item.tenPhuong.toLowerCase().includes(value))
    setFilteredData(filtered)
  }

  const handleTinhChange = (value) => {
    setSelectedTinh(value)
    setSelectedQuanHuyen(null)
    setQuanHuyenData([])
    setData([])
    setFilteredData([])
  }

  const handleQuanHuyenChange = (value) => {
    setSelectedQuanHuyen(value)
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên Phường/Xã',
      dataIndex: 'tenPhuong',
      key: 'tenPhuong',
    },
    {
      title: 'Hành động',
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
          {/* <Button type="primary" size="small" icon={<EditOutlined />}>
            Sửa
          </Button> */}
          <Button type="primary" danger size="small" icon={<DeleteOutlined />}>
            Xóa
          </Button>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="flex justify-start items-center mb-4 space-x-4">
        <Select
          placeholder="Chọn Tỉnh/Thành phố"
          onChange={handleTinhChange}
          style={{ width: 250 }}
        >
          {tinhData.map((tinh) => (
            <Option key={tinh.idTinh} value={tinh.idTinh}>
              {tinh.tenTinh}
            </Option>
          ))}
        </Select>
        <Select
          placeholder="Chọn Quận/Huyện"
          onChange={handleQuanHuyenChange}
          style={{ width: 250 }}
          disabled={!selectedTinh}
        >
          {quanHuyenData.map((quan) => (
            <Option key={quan.idQuan} value={quan.idQuan}>
              {quan.tenQuan}
            </Option>
          ))}
        </Select>
        <Input
          placeholder="Tìm kiếm phường/xã"
          value={searchText}
          onChange={handleSearch}
          style={{ width: 250 }}
          prefix={<SearchOutlined />}
        />
        <Button type="primary" icon={<PlusOutlined />}>
          Thêm phường/xã
        </Button>
      </div>

      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table columns={columns} dataSource={filteredData} rowKey="idPhuong" />
      </ConfigProvider>
    </>
  )
}

export default PhuongXa

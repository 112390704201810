import { https } from '../apiService'

export const noiSoiService = {
  getBNPendingByBranch: (idcn) => https.get(`BenhNhanCDHA/GetBenhNhanChoNoiSoi?idcn=${idcn}`),
  getBNDoneBtBranch: (idcn, tuNgay, denNgay) =>
    https.get(`BenhNhanCDHA/GetBenhNhanDaNoiSoi?idcn=${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`),
  // postBieuMau: (form) => https.post('BieuMau/AddBieuMau', form),
  // getALLBieuMau: (idloai) => https.get('BieuMau/GetAllBieuMau', { params: { idloai } }),
  // getInfoBn: (id, idcdha) =>
  //   https.get(`BenhNhanSieuAm/GetThongTinBenhNhanSieuAm?idbnttc=${id}&idbncdha=${idcdha}`),
  // postBnSieuAm: (form, idcn) => https.post(`BenhNhanSieuAm/AddBenhNhanSieuAm?idcn=${idcn}`, form),
  // getInfoBieuMau: (id) => https.get(`BieuMau/GetBieuMauByIDBM?idbm=${id}`),
  // getHistory: (id) => https.get(`BenhNhanSieuAm/GetTLichSuBenhNhanSieuAm?idbnttc=${id}`),
  // getInfoBnPending: (id, idcdha) =>
  //   https.get(`/BenhNhanCDHA/GetThongTinBenhNhanCDHA/${id}?idbncdha=${idcdha}`),
}

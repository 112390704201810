import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LayoutApp from '../../../HOCs/LayoutApp'
import { ConfigProvider, DatePicker, Select, Table, Button as ButtonAntd, Space, Input } from 'antd'
import Button from '@mui/material/Button'
import CachedIcon from '@mui/icons-material/Cached'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import InfoBN from './InfoBN/InfoBN'
import VatTu from './VatTu/VatTu'
import ToastCus from '../../../utils/Toast'
import moment from 'moment'
import dayjs from 'dayjs'
import {
  getListBNChoAction,
  getListBNDaThucHienAction,
  getInfoBNDVThuThuatAction,
  getListVTTieuHaoThuThuatAction,
  postListVTBNThuThuatAction,
  putListVTBNThuThuatAction,
  getListVTTHThuThuatTheoKhoAction,
} from '../../../store/actions/thuThuatAction'
import { branchService } from '../../../services/branch/branchService'
import 'moment/locale/vi'
moment.locale('vi')
const ThuThuat = () => {
  const dispatch = useDispatch()
  const now = moment()
  const dateFormat = 'DD/MM/YYYY'
  const dateFormatAPI = 'YYYY-MM-DD'
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoBNDVThuThuat, listBNCho, listBNDaThucHien, listVTBN } = useSelector(
    (state) => state.thuThuatReducer,
  )
  // const [vatTu, setVatTu] = useState([])
  const [branchs, setBranch] = useState([])
  const [pending, setPending] = useState([])
  const [branchPending, setBranchPendding] = useState(ID_BRANCH)
  const [branchDone, setBranchDone] = useState(ID_BRANCH)
  const [dateFrom, setDateFrom] = useState(now.format(dateFormatAPI))
  const [dateTo, setDateTo] = useState(now.format(dateFormatAPI))
  const [khoVatTu, setKhoVatTu] = useState(1) // kho vật tư mặc định là điều dưỡng
  const checkDcCapNhat =
    infoBNDVThuThuat?.DanhSachDichVu.some((dv) => dv.trangthaikb !== 4) ||
    (infoBNDVThuThuat?.DanhSachDichVu.every((dv) => dv.trangthaikb === 4) &&
      now.diff(moment(infoBNDVThuThuat?.NGAYKHAM, dateFormatAPI), 'days') === 0)

  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <ButtonAntd
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </ButtonAntd>
          <ButtonAntd
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </ButtonAntd>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) => text,
    // searchedColumn === dataIndex ? (
    //   <Highlighter
    //     highlightStyle={{
    //       backgroundColor: '#ffc069',
    //       padding: 0,
    //     }}
    //     searchWords={[searchText]}
    //     autoEscape
    //     textToHighlight={text ? text.toString() : ''}
    //   />
    // ) : (
    //   text
    // ),
  })
  //-------- lấy danh sách chi nhánh theo công ty đăng nhập -------//
  const fetchBranchByCompany = async () => {
    try {
      const result = await branchService.getListBranch(PkDangNhap?.idCongTy)
      setBranch(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  // //---------- lấy danh sách bệnh nhân chờ ------------//
  // const fetchData = async () => {
  //   try {
  //     const response = await thuThuatService.getListBNCho(branchPedding);
  //     setPending(response.data);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  // //----------- lấy thuốc vật tư theo chi nhánh -----------
  // const fetchVatTu = async () => {
  //   const result = await thuThuatService.getListVatTuThuThuat(ID_BRANCH)
  //   setVatTu(result.data)
  //   // console.log(result.data)
  // }
  // -------------- lấy thông tin chi tiết bệnh nhân chưa thu tiền ------------/
  const onClickDetailBN = (record) => {
    dispatch(getInfoBNDVThuThuatAction(record.idbnttc, PkDangNhap?.idChiNhanh))
    // dispatch({// cập nhật lại tổng tiền giảm khi xem phiếu
    //   type: typeAction.DISPATCH_SET_TONG_GIAM_CASHIER,
    //   payload: 0,
    // })
    // dispatch({// cập nhật lại tổng tiền thanh toán khi xem phiếu
    //   type: typeAction.DISPATCH_SET_TONG_THANH_TOAN_CASHIER,
    //   payload: 0,
    // })
    // resetFormThanhToan()
  }
  const reloadListVTTieuHao = () => {
    // dispatch(getListVTTieuHaoThuThuatAction(PkDangNhap?.idChiNhanh))
    dispatch(getListVTTHThuThuatTheoKhoAction(PkDangNhap?.idChiNhanh, khoVatTu)) // khoVatTu = 1 là kho điều dưỡng, khoVatTu = 2 là kho cấp cứu
    // if (khoVatTu === 1) {
    //   console.log(khoVatTu)
    // } else if (khoVatTu === 2) {
    //   console.log(khoVatTu)
    // }
  }
  const reloadListBNCho = () => {
    dispatch(getListBNChoAction(branchPending))
  }
  const reloadListBNDaThucHien = () => {
    dispatch(getListBNDaThucHienAction(branchDone, dateFrom, dateTo))
  }
  const handleCapNhatThuThuat = () => {
    if (listVTBN?.find((item) => (item?.soLuong ?? item?.soluong) <= 0)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại số lượng!',
      })
      return
    }
    dispatch(
      putListVTBNThuThuatAction(
        listVTBN,
        infoBNDVThuThuat?.DanhSachDichVu?.map((item) => ({
          ...item,
          idnguoithuchien: infoUser?.idnv,
          nguoithuchien: infoUser?.tenNV,
        })),
        infoBNDVThuThuat?.IDBNTTC,
        PkDangNhap?.idChiNhanh,
        dateFrom,
        dateTo,
      ),
    )
  }
  useEffect(() => {
    if (PkDangNhap) {
      reloadListBNCho()
      reloadListBNDaThucHien()
      reloadListVTTieuHao()
      // dispatch(getListBNChoAction(PkDangNhap?.idChiNhanh))
      // dispatch(getListBNDaThucHienAction(PkDangNhap?.idChiNhanh, dateFrom, dateTo))
      fetchBranchByCompany()
    }
  }, [PkDangNhap])
  useEffect(() => {
    reloadListVTTieuHao()
  }, [khoVatTu, infoBNDVThuThuat])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã BN',
      dataIndex: 'mabenhnhan',
      key: 'mabenhnhan',
      width: 120,
      ...getColumnSearchProps('mabenhnhan'),
    },
    {
      title: 'Tên bệnh nhân',
      dataIndex: 'tenbenhnhan',
      key: 'tenbenhnhan',
      ...getColumnSearchProps('tenbenhnhan'),
    },
  ]
  return (
    <>
      <div className="h-full p-3 bg-[#EFEFEF]">
        <div className="h-full bg-white border rounded-xl p-3">
          <div className="flex gap-2 h-full">
            <div className="w-1/4 border h-full rounded-lg">
              <div className="border-b p-2 h-1/2">
                <h2 className="font-semibold  text-lg text-gray-500">Chờ thực hiện</h2>
                <div className="">
                  <div className=" flex gap-2">
                    <Select
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      value={branchPending}
                      onChange={(value) => {
                        setBranchPendding(value)
                      }}
                      showSearch
                      options={branchs.map((items) => ({
                        label: items.tenChiNhanh,
                        value: items.idChiNhanh,
                      }))}
                      className="w-full"
                    />
                    <Button
                      onClick={reloadListBNCho}
                      //className="w-1/4"
                      variant="text"
                      size="small"
                      //endIcon={<CachedIcon />}
                    >
                      <CachedIcon />
                    </Button>
                  </div>
                </div>
                <div className="mt-2">
                  <ConfigProvider
                    theme={{
                      token: { padding: 5 },
                      components: {
                        Table: {
                          rowHoverBg: '#ecf0f1',
                          headerBg: '#e6e6e6',
                          footerBg: '#e6e6e6',
                          borderColor: '#BABABA',
                        },
                      },
                    }}
                  >
                    <Table
                      //   onRow={(record) => ({
                      //     onClick: () => onClickBNPending(record),
                      //   })}
                      pagination={false}
                      dataSource={listBNCho ?? []}
                      columns={columns}
                      onRow={(record) => ({
                        onClick: () => onClickDetailBN(record),
                      })}
                      bordered
                      scroll={{ y: 295 }}
                    />
                  </ConfigProvider>
                </div>
              </div>
              <div className=" p-2 h-1/2">
                <div className="flex justify-between">
                  <h2 className="font-semibold text-lg text-gray-500">Đã thực hiện</h2>
                  {/* <Button
                    //   onClick={goToPhieuThu}
                    size="small"
                    color="success"
                    startIcon={<FileTextOutlined />}
                  >
                    Phiếu
                  </Button> */}
                </div>

                <div className="flex items-center justify-center">
                  <DatePicker
                    allowClear={false}
                    format={dateFormat}
                    className="w-2/5"
                    maxDate={dayjs(now)}
                    defaultValue={dayjs(dateFrom)}
                    value={dayjs(dateFrom)}
                    onChange={(date, dateString) => {
                      setDateFrom(moment(dateString, dateFormat).format(dateFormatAPI))
                      // setDateTo(moment(dateString, dateFormat).format(dateFormatAPI))
                    }}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6 w-1/5"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                  </svg>

                  <DatePicker
                    format={dateFormat}
                    allowClear={false}
                    className="w-2/5"
                    maxDate={dayjs(now)}
                    minDate={dayjs(dateFrom)}
                    defaultValue={dayjs(dateTo)}
                    value={dayjs(dateTo)}
                    onChange={(date, dateString) => {
                      setDateTo(moment(dateString, dateFormat).format(dateFormatAPI))
                    }}
                  />
                </div>
                <div className="mt-2">
                  <div className=" flex gap-2">
                    <Select
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      onChange={(value) => {
                        setBranchDone(value)
                      }}
                      value={branchDone}
                      showSearch
                      options={branchs.map((items) => ({
                        label: items.tenChiNhanh,
                        value: items.idChiNhanh,
                      }))}
                      className="w-full"
                    />
                    <Button
                      onClick={reloadListBNDaThucHien}
                      //className="w-1/4"
                      variant="text"
                      size="small"
                      //endIcon={<CachedIcon />}
                    >
                      <CachedIcon />
                    </Button>
                  </div>
                </div>
                <div className="mt-2">
                  <ConfigProvider
                    theme={{
                      token: { padding: 5 },
                      components: {
                        Table: {
                          rowHoverBg: '#ecf0f1',
                          headerBg: '#e6e6e6',
                          footerBg: '#e6e6e6',
                          borderColor: '#BABABA',
                        },
                      },
                    }}
                  >
                    <Table
                      pagination={false}
                      dataSource={listBNDaThucHien ?? []}
                      columns={columns}
                      onRow={(record) => ({
                        onClick: () => onClickDetailBN(record),
                      })}
                      bordered
                      scroll={{ y: 265 }}
                    />
                  </ConfigProvider>
                </div>
              </div>
            </div>
            <div className="w-3/4">
              <div className="h-full border rounded-lg p-2">
                <div className="flex justify-end mb-2 items-center gap-2">
                  <ButtonAntd
                    disabled={!infoBNDVThuThuat?.IDBNTTC || !checkDcCapNhat}
                    onClick={handleCapNhatThuThuat}
                    type="primary"
                  >
                    Lưu
                  </ButtonAntd>
                </div>
                <div className="mb-2">
                  <InfoBN />
                </div>
                <div>
                  <VatTu
                    khoVatTu={khoVatTu}
                    setKhoVatTu={setKhoVatTu}
                    checkDcCapNhat={checkDcCapNhat}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ThuThuat

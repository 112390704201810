import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
import { nhanvienService } from '../../services/nhanvien/nhanvienService'

export function* userSaga() {
  // POST BỘ PHẬN
  yield takeLatest(
    typeAction.POST_DANHMUC_BOPHAN,
    function* postDanhMucBoPhanSaga({ type, form, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        yield call(() =>
          nhanvienService.postBoPhan({
            // idbp: 0,
            mabophan: form?.mabp,
            tenbophan: form?.tenbp,
            // sudung: 1,
            nguoitao: infoUser?.idnv,
            ghichu: form?.ghichu,
          }),
        )
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // PUT BỘ PHẬN
  yield takeLatest(
    typeAction.PUT_DANHMUC_BOPHAN,
    function* putDanhMucBoPhanSaga({ type, form, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        yield call(() =>
          nhanvienService.putBoPhan({
            idbp: form?.idbp,
            mabophan: form?.mabp,
            tenbophan: form?.tenbp,
            sudung: form?.sudung,
            nguoisua: infoUser?.idnv,
            ghichu: form?.ghichu,
          }),
        )
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // PUT BỘ PHẬN SỬ DỤNG
  yield takeLatest(
    typeAction.PUT_DANHMUC_BOPHAN_SUDUNG,
    function* putDanhMucBoPhanSaga({ type, form, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        yield call(() =>
          nhanvienService.putSuDungBoPhan({
            idbp: form?.idbp,
            // mabophan: form?.mabp,
            // tenbophan: form?.tenbp,
            sudung: form?.sudung ? 0 : 1, //cập nhật ngược lại sử dụng hiện tại
            nguoisua: infoUser?.idnv,
            // ghichu: form?.ghichu,
          }),
        )
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, ConfigProvider, Divider, Form, Input, Space, Table, Tabs, Tooltip } from 'antd'
import { https } from '../../../../services/apiService'
import { cloneDeep, debounce } from 'lodash'
import { DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import ToastCus from '../../../../utils/Toast'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import 'moment/locale/vi'
moment.locale('vi')

function TabDoiTuong({ dataDoiTuong, setDataDoiTuong }) {
  const { infoUser } = useSelector((state) => state.userReducer)
  const [loadingSearching, setLoadingSearching] = useState(false)
  // const [dataDoiTuong, setDataDoiTuong] = useState([])// các chi nhánh dc chọn cho voucher
  const [listDoiTuong, setListDoiTuong] = useState([])
  const [searchText, setSearchText] = useState('')
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [filterActive, setFilterActive] = useState(false)
  const [listTTV, setListTTV] = useState([])
  const searchInput = useRef(null)
  const [searchedColumn, setSearchedColumn] = useState('')

  const columsDoiTuongChon = [
    {
      title: 'Tên đối tượng',
      dataIndex: 'tendoituong',
      key: 'tendoituong',
      align: 'left',
      className: 'text-sm',
      // render: (text, record) => <p className={`${record.children ? 'font-medium' : ''}`}>{text}</p>,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 40,
      align: 'center',
      className: 'text-lg text-red-500',
    },
  ]

  const columsDoiTuong = [
    {
      title: 'Tên đối tượng',
      dataIndex: 'tendoituong',
      key: 'tendoituong',
      align: 'left',
      className: 'text-sm',
      // render: (text, record) => <p className={`${record.children ? 'font-medium' : ''}`}>{text}</p>,
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      render: (text, record) => {
        return record.children ? null : (
          <PlusOutlined
            onClick={() => handleAddChiNhanh(record)}
            className="text-xl text-green-500 cursor-pointer"
          />
        )
      },
      width: 40,
    },
  ]

  const getListDoiTuong = async () => {
    setLoadingSearching(true)
    try {
      const { data } = await https.get('DoiTuong')
      //format data
      const formatData = data.map((item, index) => ({
        key: `` + item.idDoiTuong + item.tenDoiTuong,
        iddt: item.idDoiTuong,
        madt: item.idDoiTuong,
        tendoituong: item.tenDoiTuong,
        info: item,
        // chuyenKhoa: '',
        // children: [],
      }))
      setListDoiTuong(formatData)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSearching(false)
    }
  }

  useEffect(() => {
    getListDoiTuong()
  }, [])

  // const getListChiNhanhByIdCongTy = async (idct) => {
  //   setLoadingSearching(true)
  //   try {
  //     const { data } = await https.get(`ChiNhanh/GetChiNhanhByIDCT`, { params: { idct } })
  //     console.log(data)
  //     //set data in table
  //     let newDataInTable = [...listDoiTuong]
  //     const idx = newDataInTable.findIndex((item) => item.key === idct)
  //     newDataInTable[idx].children = data.map((item, index) => ({
  //       key: '' + index + item?.machinhanh + item?.idchinhanh,
  //       idcn: item?.idchinhanh,
  //       // idCongTy: idct,
  //       maChiNhanh: item?.machinhanh,
  //       tenChiNhanh: item?.tenchinhanh,
  //       // donVi: val.donVi,
  //       chuyenKhoa: item?.tenchuyenkhoa?.replace("Chuyên khoa", "")?.trim(),
  //       info: item
  //     }))
  //     // console.log(data)
  //     setListDoiTuong(newDataInTable)
  //   } catch (error) {
  //     console.log(error)
  //   } finally {
  //     setLoadingSearching(false)
  //   }
  // }

  // const handleExpand = (expanded, record) => {
  //   if (searchText) return
  //   if (expanded) {
  //     setExpandedRowKeys([...expandedRowKeys, record.key])
  //     getListChiNhanhByIdCongTy(record.key)
  //   } else {
  //     // const newDataInTable = cloneDeep(listDoiTuong)
  //     // const idx = newDataInTable.findIndex((item) => item.key === record.key)
  //     // newDataInTable[idx].children = ([])
  //     // // console.log(data)
  //     // setListDoiTuong(newDataInTable)
  //     setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key))
  //   }
  // }

  const handleAddChiNhanh = (record) => {
    const idx = dataDoiTuong.findIndex((item) => item.key === record.key)
    if (idx !== -1) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dịch vụ đã được chọn',
      })
      return
    }
    //add record
    setDataDoiTuong((prev) => [
      ...prev,
      {
        ...record,
      },
    ])
  }

  const handleDeleteInTable = (record) => {
    setDataDoiTuong((prev) => prev.filter((item) => item.key !== record.key))
  }

  return (
    <div>
      <div className="overflow-auto h-[280px]">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
    
          }}
        >
          <Table
            // loading={loadingTable}
            scroll={{ y: 180 }}
            bordered
            pagination={false}
            columns={columsDoiTuongChon}
            dataSource={
              dataDoiTuong.map((item) => ({
                ...item,
                action: (
                  <DeleteOutlined onClick={() => handleDeleteInTable(item)} />
                  // <ul className="flex justify-around p-0">
                  //   <li className="text-lg text-red-500">

                  //   </li>
                  // </ul>
                ),
              })) || []
            }
          />
        </ConfigProvider>
      </div>
      <div>
        <div className="overflow-auto h-[280px]">
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
      
            }}
          >
            <Table
              scroll={{ y: 180 }}
              pagination={false}
              loading={loadingSearching}
              bordered
              columns={columsDoiTuong}
              dataSource={listDoiTuong}
              // expandable={{ onExpand: handleExpand, expandedRowKeys: expandedRowKeys }}
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  )
}

export default TabDoiTuong

import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord } from '../splitWordForReport'
import { logoToDataURI } from '../../assets/images/logoNhiBase64'
import JsBarcode from 'jsbarcode'
import { generateLogo } from '../algorithm'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

function calculateAge(data) {
  const startDate = new Date(data)
  const currentDate = new Date()
  // Calculate difference in milliseconds
  const difference = currentDate - startDate

  // Convert milliseconds to days and months
  const millisecondsPerDay = 1000 * 60 * 60 * 24
  const millisecondsPerMonth = millisecondsPerDay * 30.4375 // Average month length

  const monthsDifference = Math.floor(difference / millisecondsPerMonth)
  const daysDifference = Math.floor((difference % millisecondsPerMonth) / millisecondsPerDay)
  return `${monthsDifference}m${daysDifference}d`
}

function formatDate(date) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const month = months[date.getMonth()]
  const day = date.getDate()
  const year = date.getFullYear()

  return `${month} ${day}, ${year}`
}

function formatDatetime(datetimeString) {
  const date = new Date(datetimeString) // Parse the datetime string
  const options = { month: 'long', day: 'numeric', year: 'numeric' }
  return date.toLocaleDateString('en-US', options)
}

export const generatePhieuToaThuocTA = (benhNhanTTC, toaThuoc, PkDangNhap) => {
  const doc = jsPDFCus('p', 'mm', 'a5')
  let yPos = 10
  let xPos = 8
  let ruleWidth = 55 // for text of price is longer
  const pageWidth = doc.internal.pageSize.width
  //header
  generateLogo(doc, xPos, PkDangNhap)

  JsBarcode('#barcode', `TT${benhNhanTTC.idbnttc}`, {
    displayValue: false,
  })
  const canvas = document.getElementById('barcode')
  const barcodeDataURL = canvas.toDataURL()
  const barcodeWidth = 35 // Width of the barcode image
  const barcodeX = pageWidth - 45 // X coordinate of the barcode
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 45, 5, 35, 14)
  // Calculate the center position for text
  const text = `TT${benhNhanTTC.idbnttc}`
  const textWidth = (doc.getStringUnitWidth(text) * 11) / doc.internal.scaleFactor // Width of the text
  const textX = barcodeX + (barcodeWidth - textWidth) / 2 // Centered X coordinate for the text
  doc.setFontSize(10)
  doc.text(text, textX, 22)

  doc.setFont('times', 'bold')
  doc.setFontSize(14)
  doc.text('PRESCRIPTION', doc.internal.pageSize.width / 2, 30, { align: 'center' })
  let textWidthName = doc.getTextDimensions('Full name:').w
  doc.setFontSize(11)
  doc.text(benhNhanTTC.benhNhan.tenBenhNhan.toUpperCase(), textWidthName, 35)
  doc.setFont('times', 'regular')
  doc.setFontSize(10)
  doc.text('Full name: ', xPos, 35)
  let textWidthGioiTinh = doc.getTextDimensions('Gender:').w
  doc.text('Gender:', pageWidth - 65, 35)
  doc.setFont('times', 'bold')
  doc.text(
    benhNhanTTC.benhNhan.gioiTinh === 'Nữ'
      ? 'Female'
      : benhNhanTTC.benhNhan.gioiTinh === 'Nam'
        ? 'Male'
        : 'Other',
    pageWidth - 65 + textWidthGioiTinh,
    35,
  )
  doc.setFont('times', 'regular')
  doc.text(`Age: ${calculateAge(benhNhanTTC.benhNhan.ngaySinh)}`, pageWidth - 35, 35)
  //dịa chỉ
  const diaChiBenhNhan = `Address: ${benhNhanTTC.benhNhan.diaChi ?? ''}${benhNhanTTC.benhNhan.tenPhuongXa ? ', ' + benhNhanTTC.benhNhan.tenPhuongXa : ''}${benhNhanTTC.benhNhan.tenQuanHuyen ? ', ' + benhNhanTTC.benhNhan.tenQuanHuyen : ''}${benhNhanTTC.benhNhan.tenTinhTP ? ', ' + benhNhanTTC.benhNhan.tenTinhTP : ''}`

  if (diaChiBenhNhan.length > 70) {
    const wordsArray = splitLongWord(diaChiBenhNhan, 70)
    doc.text(wordsArray[0], xPos, 40)
    doc.text(wordsArray[1], xPos, 45)
    yPos = 45
  } else {
    doc.text(diaChiBenhNhan, xPos, 40)
    yPos = 40
  }
  yPos += 4
  doc.text('Weight: ', xPos, yPos)
  doc.text('Pulse: ', pageWidth / 2 - 20, yPos)
  doc.text('Blood pressure:  ', pageWidth - 50, yPos)
  doc.setFont('times', 'bold')
  let canNangWidth = doc.getTextDimensions('Weight: ').w
  let chieuCaoWidth = doc.getTextDimensions('Pulse: ').w
  let nhietDoWidth = doc.getTextDimensions('Blood pressure:  ').w

  doc.text(`${benhNhanTTC.cannang ? `${benhNhanTTC.cannang} KG` : ''}`, canNangWidth + xPos, yPos)
  doc.text(
    `${benhNhanTTC.chieucao ? `${benhNhanTTC.chieucao} CM` : ''}`,
    chieuCaoWidth + pageWidth / 2 - 20,
    yPos,
  )
  doc.text(
    `${benhNhanTTC.nhietdo ? `${benhNhanTTC.nhietdo} °C` : ''}`,
    nhietDoWidth + pageWidth - 50,
    yPos,
  )
  doc.setFont('times', 'regular')
  yPos += 5
  //chẩn đoán
  if (`Diagnosis: ${benhNhanTTC.chandoan ?? ''}`.length > 70) {
    const wordsArray = splitLongWord(`Diagnosis: ${benhNhanTTC.chandoan ?? ''}`, 70)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
  } else {
    doc.text(`Diagnosis: ${benhNhanTTC.chandoan ?? ''}`, xPos, yPos)
    yPos += 5
  }
  // Add a line
  doc.line(xPos, yPos, pageWidth - 10, yPos)
  yPos += 4
  doc.setFont('times', 'bold')
  doc.text('+ Prescription for 01 days:', xPos, yPos)
  doc.setFontSize(9)
  yPos += 4
  for (let i = 0; i < toaThuoc.length; i++) {
    // Check if content exceeds the page height
    if (yPos > 200) {
      doc.addPage() // Add a new page
      yPos = 10 // Reset yPos for new page
    }

    //chia 2 cột
    doc.setFont('times', 'bold')
    //check text dài
    doc.text(`${toaThuoc[i].soLuong} ${toaThuoc[i].dvt}`, pageWidth - 25, yPos)
    if (`${i + 1}. ${toaThuoc[i].tenhoatchat}`.length > 65) {
      const wordsArray = splitLongWord(`${i + 1}. ${toaThuoc[i].tenhoatchat}`, 65)
      for (let j = 0; j < wordsArray.length; j++) {
        doc.text(`${wordsArray[j]}`, xPos, yPos)
        yPos += 4
      }
    } else {
      doc.text(`${toaThuoc[i].tenhoatchat}`, xPos, yPos)
      yPos += 4
    }
    doc.setFont('times', 'italic')
    //check text cách dùng dài
    if (
      `${toaThuoc[i].duongDung ?? ''} ${toaThuoc[i].soLanNgay} times daily, ${toaThuoc[i].duongDung ?? ''} ${toaThuoc[i].lieuDung} ${toaThuoc[i].donvidung} each time ${toaThuoc[i].ghiChu ? `(${toaThuoc[i].ghiChu})` : ''}`
        .length > 65
    ) {
      const wordsArray = splitLongWord(
        `${toaThuoc[i].duongDung ?? ''} ${toaThuoc[i].soLanNgay} times daily, ${toaThuoc[i].duongDung ?? ''} ${toaThuoc[i].lieuDung} ${toaThuoc[i].donvidung} each time ${toaThuoc[i].ghiChu ? `(${toaThuoc[i].ghiChu})` : ''}`,
        65,
      )
      for (let j = 0; j < wordsArray.length; j++) {
        doc.text(`${wordsArray[j]}`, xPos, yPos)
        yPos += 4
      }
    } else {
      doc.text(
        `${toaThuoc[i].duongDung ?? ''} ${toaThuoc[i].soLanNgay} times daily, ${toaThuoc[i].duongDung ?? ''} ${toaThuoc[i].lieuDung} ${toaThuoc[i].donvidung} each time ${toaThuoc[i].ghiChu ? `(${toaThuoc[i].ghiChu})` : ''}`,
        xPos,
        yPos,
      )
      yPos += 4
    }
    yPos += 1
  }
  yPos = yPos - 10
  if (PkDangNhap.idChuyenKhoa === 4) {
    if (doc.internal.pageSize.height - yPos < 50) {
      doc.addPage()
      yPos = 10
    }
  } else {
    if (doc.internal.pageSize.height - yPos < 80) {
      doc.addPage()
      yPos = 10
    }
  }
  // height of footer page is 50
  yPos += 8
  doc.line(xPos, yPos, pageWidth - 10, yPos)
  yPos += 5
  doc.setFont('times', 'bold')
  const timeWidth = doc.getTextDimensions(formatDatetime(benhNhanTTC.ngaytaikham)).w
  doc.text(formatDatetime(benhNhanTTC.ngaytaikham), timeWidth + xPos + 14, yPos)
  doc.setFont('times', 'regular')
  doc.text('Re-examination date: ', xPos, yPos)

  const dateText = `Date: ${formatDate(new Date(benhNhanTTC.ngaykham))}`
  const doctorText = benhNhanTTC.bskham ?? ''
  const dateTextWidth = doc.getTextWidth(dateText)
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateTextX = pageWidth - dateTextWidth - 20 // 15 is the padding from the right edge

  // Calculate the center point of the date text
  const dateCenterX = dateTextX + dateTextWidth / 2

  // Position the doctor text centered below the date text
  const doctorTextX = dateCenterX - doctorTextWidth / 2

  doc.text(dateText, dateTextX, yPos)
  yPos += 5
  doc.text('Messages for patient:', xPos, yPos)
  doc.text('Doctor In Charge', pageWidth - 46, yPos)
  doc.setFont('times', 'bold')
  doc.text(doctorText, doctorTextX, yPos + 20)
  doc.setFont('times', 'regular')
  //lời dặn
  yPos += 5
  if (`- ${benhNhanTTC.loidan ?? ''}`.length > 50) {
    const wordsArray = splitLongWord(`- ${benhNhanTTC.loidan ?? ''}`, 50)
    for (let j = 0; j < wordsArray.length; j++) {
      doc.text(`${wordsArray[j]}`, xPos, yPos)
      yPos += 4
    }
    yPos += 4
  } else {
    doc.text(`- ${benhNhanTTC.loidan ?? ''}`, xPos, yPos)
    yPos += 4
  }
  //tái khám miễn phí
  // if (PkDangNhap.idChuyenKhoa === 1) {
  //   doc.setFont('times', 'bold')
  //   const defaultLoiDanTaiKham =
  //     '-Tái khám miễn phí khi đúng hẹn tối đa 3 lần trong cùng 1 đợt bệnh'
  //   const wordsArrayTaiKham = splitLongWord(defaultLoiDanTaiKham, 50)
  //   for (let j = 0; j < wordsArrayTaiKham.length; j++) {
  //     doc.text(`${wordsArrayTaiKham[j]}`, xPos, yPos)
  //     yPos += 4
  //   }
  // }
  doc.setFont('times', 'italic')
  //lời dặn default
  const defaultLoiDan = `-The prescription is only valid at the time of the doctor's diagnosis. We disclaim responsibility in cases where the customer uses the prescription at a time other than the moment of diagnosis.`
  const wordsArray = splitLongWord(defaultLoiDan, 50)
  for (let j = 0; j < wordsArray.length; j++) {
    doc.text(`${wordsArray[j]}`, xPos, yPos)
    yPos += 5
  }
  doc.line(xPos, yPos - 4, pageWidth - 10, yPos - 4)
  doc.setFontSize(8)
  doc.text('(*) Please bring this prescription for reference', xPos, yPos)

  doc.text(
    `(*)Date of printing: ${moment().format('DD/MM/YYYY HH:mm:ss')}`,
    pageWidth - 53,
    doc.internal.pageSize.height - 2,
  )

  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}

import { Input, Select, Button, Table, ConfigProvider } from 'antd'
import { ReloadOutlined, SaveOutlined } from '@ant-design/icons'
import { formatNumber } from 'devextreme/localization'
import moment from 'moment'
import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { searchThuocVT } from '../../../../../store/actions/NhapKhoAction'
import { branchService } from '../../../../../services/branch/branchService'

const today = moment()

const EditProducts = () => {
  const dispatch = useDispatch()
  const id_company = Number(localStorage.getItem('id_company'))
  const idBranchDefault = Number(localStorage.getItem('BRANH_LOGIN'))
  const [company, setCompany] = useState(id_company)
  const [idChiNhanh, setIdChiNhanh] = useState(idBranchDefault)
  const [branch, setBranch] = useState([])
  const [khoCN, setKhoCN] = useState(null)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { kyHang } = useSelector((state) => state.thuocVTReducer)
  // STORE REDUX
  const { thuocVT } = useSelector((state) => state.NhapKhoReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [rows, setRows] = useState([])
  const [value, setValue] = useState(null)
  const debounceDropDown = useCallback(
    _.debounce((keyword, company) => {
      dispatch(searchThuocVT(keyword || '', company))
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const handleSearch = (keyword) => {
    debounceDropDown(keyword, 1) // default ID company  =  1
  }
  const featchBranch = async (id) => {
    const { data } = await branchService.getListBranch(id)
    setBranch(data)
  }
  useEffect(() => {
    featchBranch(company)
  }, [])
  return (
    <>
      <div className="border w-full rounded-md p-2">
        <div className="flex justify-between gap-5">
          <div className="w-1/3">
            <label htmlFor="" className="font-medium text-gray-500">
              <span className="text-red-500">(*)</span> Kỳ hàng hóa{' '}
            </label>
            <Select
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              showSearch
              onChange={(value, option) => setValue(option)}
              className="w-full"
              value={value?.value}
              options={kyHang?.map((item) => ({
                label: item.tenky,
                value: item.idky,
                denngay: item.denngay,
              }))}
            />
          </div>
          <div className="w-1/3">
            <label htmlFor="" className="font-medium text-gray-500">
              Người thực hiện{' '}
            </label>
            <Input readOnly variant="filled" value={infoUser?.tenNV} className="w-full" />
          </div>
          <div className="w-1/3">
            <label htmlFor="" className="font-medium text-gray-500">
              Ngày điều chỉnh{' '}
            </label>
            <Input
              readOnly
              variant="filled"
              value={today.format('DD/MM/YYYY HH:mm:ss')}
              className="w-full"
            />
          </div>
        </div>
        <div className="flex justify-between gap-5 mt-2">
          <div className="w-1/3">
            <label htmlFor="" className="font-medium text-gray-500">
              Công ty{' '}
            </label>
            <Select
              onChange={(value) => {
                setCompany(value)
                featchBranch(value)
              }}
              className="w-full"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              value={company}
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
            />
          </div>
          <div className="w-1/3">
            <label htmlFor="" className="font-medium text-gray-500">
              <span className="text-red-500">(*)</span> Chi nhánh{' '}
            </label>
            <Select
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              showSearch
              onChange={(value) => {
                setIdChiNhanh(value)
                setKhoCN(null)
                // getKhoTheoChiNhanh(value)
              }}
              value={idChiNhanh}
              options={[
                { label: 'Tất cả', value: null },
                ...branch?.map((item) => ({
                  label: item.tenChiNhanh,
                  value: item.idChiNhanh,
                })),
              ]}
              className="w-full"
            />
          </div>
          <div className="w-1/3">
            <label htmlFor="" className="font-medium text-gray-500">
              <span className="text-red-500">(*)</span> Kho hàng{' '}
            </label>
            <Select
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              value={khoCN}
              showSearch
              onChange={(value) => setKhoCN(value)}
              // options={viewsRoleKho(listKho)}
              className="w-full"
            />
          </div>
        </div>

        {rows}
      </div>
      <div>
        <h2 className="font-semibold  text-lg my-2">Chi tiết hàng:</h2>
        <Select
          // ref={selectRef}
          className="w-full"
          // size="small"
          showSearch
          allowClear
          // onChange={handleChooseMemoized}
          placeholder="Nhập tên vật tư hàng hoá "
          value={null}
          defaultActiveFirstOption={false}
          prefix={null}
          filterOption={false}
          onSearch={handleSearch}
          notFoundContent={null}
          options={(thuocVT || []).map((d, index) => ({
            key: index,
            value: d.idThuoc,
            label: (
              <u className="flex no-underline">
                <li className="flex w-[50%]">
                  <p className="pr-2">{d.maThuoc}</p>
                  <p className="border-x-2 px-2 w-full ">{d.tenBietDuoc}</p>
                </li>
                <li className=" w-[35%] px-1 border-r text-start">{d.tenDoiTac}</li>
                <li className=" w-[15%] text-end">
                  {formatNumber(d.giaMua * d.quyCachDongGoi)} VNĐ
                </li>
              </u>
            ),
          }))}
        />
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
    
          }}
        >
          <Table
            bordered
            className="mt-2"
            columns={[
              {
                title: 'Tên hàng',
                key: 1,
              },
              {
                title: 'Mã hàng',
                key: 2,
              },
              {
                title: 'Tồn kho',
                key: 3,
              },
              {
                title: 'Thực tế',
                key: 4,
              },

              {
                title: 'Chênh lệch',
                key: 5,
              },
              {
                title: 'Đơn vị',
                key: 6,
              },
              {
                title: 'Số lô',
                key: 7,
              },

              {
                title: 'hạn dùng',
                key: 8,
              },
              {
                title: 'Số hoá đơn',
                key: 9,
              },
              {
                title: 'Số đăng ký',
                key: 10,
              },
              {
                title: 'ghi chú',
                key: 11,
              },
            ]}
            summary={(pageData) => {
              return (
                <Table.Summary>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={2}></Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={1}>0</Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={1}>0</Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={1}>0</Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={6}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
      <div className="flex gap-2 justify-end mt-5">
        <Button icon={<ReloadOutlined />} type="primary" ghost>
          Làm mới
        </Button>
        <Button icon={<SaveOutlined />} type="primary">
          Lưu
        </Button>
      </div>
    </>
  )
}

export default EditProducts

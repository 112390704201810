import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConfigProvider, Table, Input, Select, notification, InputNumber, Alert } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import * as typeAction from '../../../../store/constants/constants'
import ToastCus from '../../../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const VatTu = ({ checkDcCapNhat }) => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const { listVT, listVTBN } = useSelector((state) => state.thuThuatReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { InfoBNTrongVaGongKinh } = useSelector((state) => state.doKhucXaReducer)
  const [quyenKeVTTH, setquyenKeVTTH] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const openNotificationWithIcon = (message, description) => {
    api.warning({
      message,
      description,
    })
  }

  const handleChangeSoLuong = (vt, soLuongNew) => {
    if (!checkDcCapNhat) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Bệnh nhân đã thực hiện, không thể chỉnh sửa!')
      return
    }
    const newValue = Number(soLuongNew)
    const index = listVTBN?.findIndex((items) => items.iD === vt.iD)
    if (newValue > listVTBN[index].tonKho) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Số lượng tồn kho không đủ!')
      return
    }
    dispatch({
      type: typeAction.DISPATCH_EDIT_VT_SL_BN_THUTHUAT,
      payload: {
        index: index,
        value: newValue,
      },
    })
  }
  const handleChangeGhiChu = (vt, ghiChuNew) => {
    if (!checkDcCapNhat) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Bệnh nhân đã thực hiện, không thể chỉnh sửa!')
      return
    }
    const index = listVTBN.findIndex((items) => items.iD === vt.iD)
    dispatch({
      type: typeAction.DISPATCH_EDIT_VT_GHICHU_BN_THUTHUAT,
      payload: {
        index: index,
        value: ghiChuNew,
      },
    })
  }
  const handleVTTieuHao = (value) => {
    if (!checkDcCapNhat) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Bệnh nhân đã thực hiện, không thể chỉnh sửa!')
      return
    }
    if (!InfoBNTrongVaGongKinh?.IDBNTTC) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Vui lòng chọn bệnh nhận cần thực hiện!')
      return
    }
    const index = listVTBN?.findIndex((itemVTBN) => itemVTBN.iD === value)
    if (index !== -1) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Vật tư đã được chọn!')
      return
    }
    const indexVT = listVT?.findIndex((itemVT) => itemVT.iD === value)
    if (listVT[indexVT]?.tonKho <= 0) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Số lượng tồn kho không đủ!')
      return
    }
    dispatch({
      type: typeAction.DISPATCH_ADD_VT_BN_THUTHUAT,
      payload: {
        iD: listVT[indexVT]?.iD,
        idbnttc: InfoBNTrongVaGongKinh?.IDBNTTC,
        idthuoc: listVT[indexVT]?.idThuoc,
        donGia: listVT[indexVT]?.thuocVatTu?.giaBan,
        soLuong: 1,
        thanhTien: listVT[indexVT]?.thuocVatTu?.giaBan,
        tongTien: listVT[indexVT]?.thuocVatTu?.giaBan,
        bsKeDon: InfoBNTrongVaGongKinh?.BSCHANDOAN,
        nguoiThucHien: infoUser?.tenNV,
        ghiChu: '',
        idkhocn: listVT[indexVT]?.idKhoCN,
        soLo: listVT[indexVT]?.soLo,
        hanDung: listVT[indexVT]?.hanDung,
        idDichVu: InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan[0]?.iddichvu,
        tenBietDuoc: listVT[indexVT]?.thuocVatTu?.tenBietDuoc,
        dvt: listVT[indexVT]?.thuocVatTu?.dvt,
        tonKho: listVT[indexVT]?.tonKho,
        soHoaDon: listVT[indexVT]?.soHoaDon,
        maSoDangKy: listVT[indexVT]?.maSoDangKy,
      },
    })
  }
  useEffect(() => {
    dispatch({
      type: typeAction.DISPATCH_LIST_VT_BN_THUTHUAT,
      payload: InfoBNTrongVaGongKinh?.DanhSachVatTuTieuHao?.map((item) =>
        checkDcCapNhat
          ? {
              ...item,
              iD: `${item.idthuoc}${item.soLo}${item.hanDung}${item.soHoaDon}${item.idkhocn}`,
              tonKho:
                (listVT?.find(
                  (itemVT) =>
                    itemVT.iD ===
                    `${item.idthuoc}${item.soLo}${item.hanDung}${item.soHoaDon}${item.idkhocn}`,
                )?.tonKho ?? 0) + item?.soLuong,
            }
          : { ...item },
      ),
    })
  }, [
    InfoBNTrongVaGongKinh?.IDBNTTC,
    InfoBNTrongVaGongKinh?.DanhSachVatTuTieuHao,
    InfoBNTrongVaGongKinh?.DanhSachVatTuTieuHao?.length,
  ])
  const checkQuyenKeVTTH = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT320',
      )
      setquyenKeVTTH(true)
    } catch (error) {
      setquyenKeVTTH(false)
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  useEffect(() => {
    checkQuyenKeVTTH()
  }, [])
  useEffect(() => {
    if (InfoBNTrongVaGongKinh?.IDBNTTC) checkQuyenKeVTTH()
  }, [InfoBNTrongVaGongKinh])
  const columnsTK = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Tên tròng kính',
      dataIndex: 'tendichvu',
      key: 'tendichvu',
      // render: (text, record, index) => record?.tendichvu + record?.tenct ?? "" + record?.tengong ?? "",
    },
    {
      title: 'Đối tác',
      dataIndex: 'tenct',
      key: 'tenct',
      align: 'left',
      width: 250,
    },
    {
      title: 'Loại gọng',
      dataIndex: 'tengong',
      key: 'tengong',
      align: 'center',
      width: 95,
    },
    {
      title: 'Đơn vị',
      dataIndex: 'donvitinh',
      key: 'donvitinh',
      align: 'center',
      width: 60,
    },
    {
      title: <p className="text-center">Thành tiền</p>,
      dataIndex: 'dongia',
      key: 'dongia',
      align: 'right',
      width: 90,
      render: (text, record, index) => formatNumberVND(text),
    },
    {
      title: <p className="text-center">Thanh toán</p>,
      dataIndex: 'tienthanhtoan',
      key: 'tienthanhtoan',
      align: 'right',
      width: 90,
      render: (text, record, index) => formatNumberVND(text),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      align: 'center',
      width: 150,
      render: (text, record, index) => (
        <Input placeholder="Ngày hẹn lấy..." variant="filled" readOnly value={text} size="small" />
      ),
    },
  ]
  const columnsGK = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Tên gọng kính',
      dataIndex: 'tenbietduoc',
      key: 'tenbietduoc',
    },
    {
      title: 'Số lô',
      dataIndex: 'solo',
      key: 'solo',
      align: 'center',
      width: 120,
    },
    {
      title: 'Hạn dùng',
      dataIndex: 'handung',
      key: 'handung',
      align: 'center',
      width: 90,
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Đơn vị',
      dataIndex: 'dvt',
      key: 'dvt',
      align: 'center',
      width: 70,
    },
    {
      title: 'SL',
      dataIndex: 'soluong',
      key: 'soluong',
      align: 'center',
      width: 45,
    },
    {
      title: <p className="text-center">Thành tiền</p>,
      dataIndex: 'dongia',
      key: 'dongia',
      align: 'right',
      width: 90,
      render: (text, record, index) => formatNumberVND(text),
    },
    {
      title: <p className="text-center">Thanh toán</p>,
      dataIndex: 'thanhtien',
      key: 'thanhtien',
      align: 'right',
      width: 90,
      render: (text, record, index) => formatNumberVND(text),
    },
  ]
  return (
    <div className="border rounded p-2">
      {contextHolder}

      <>
        <div className="h-[15.4rem]">
          <h2 className="font-semibold  text-lg m-2 text-gray-500 mt-0">Vật tư tiêu hao</h2>
          {!quyenKeVTTH ? (
            <>
              <Alert message="Tài khoản không có quyền thao tác!" type="error" showIcon />
            </>
          ) : (
            <>
              <Select
                placeholder="Nhập tên vật tư..."
                className="w-full mb-2"
                showSearch
                onChange={handleVTTieuHao}
                filterOption={(input, option) => {
                  const label = option?.label ?? ''
                  const tenBietDuoc = option?.tenBietDuoc ?? ''
                  const searchText = input.toLowerCase()
                  return (
                    label.toLowerCase().includes(searchText) ||
                    tenBietDuoc.toLowerCase().includes(searchText)
                  )
                }}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                value={null}
                options={listVT?.map((items) => ({
                  key: items.iD,
                  value: items.iD,
                  label: items.thuocVatTu.tenHoatChat,
                  tenBietDuoc: items.thuocVatTu.tenBietDuoc,
                  dvt: items.thuocVatTu.dvt,
                  soLo: items.soLo,
                  hanDung: items.hanDung,
                  soHoaDon: items.soHoaDon,
                  maSoDangKy: items.maSoDangKy,
                  ton: items.tonKho,
                }))}
                dropdownRender={(menu) => (
                  <div>
                    <div
                      className="flex bg-gray-100"
                      style={{ padding: '8px', fontWeight: 'bold' }}
                    >
                      <span className="w-[50%] text-center">Tên hàng</span>
                      <span className="w-[12.5%] text-center">Số lô</span>
                      <span className="w-[12.5%] text-center">Hạn dùng</span>
                      <span className="w-[12.5%] text-center">Đơn vị</span>
                      <span className="w-[12.5%] text-center">Tồn</span>
                    </div>
                    <div style={{ marginBottom: '8px' }}>{menu}</div>
                  </div>
                )}
                optionLabelProp="label"
                optionRender={(options) => (
                  <ul className="flex">
                    <li className="w-[50%] ">
                      <p className="font-semibold w-full text-pretty">{options.data.label}</p>
                      <span className="text-xs w-full text-gray-500">
                        {' '}
                        ({options.data.tenBietDuoc})
                      </span>
                    </li>
                    <li className="w-[12.5%] border-x flex items-center justify-center text-center">
                      {options.data.soLo}
                    </li>
                    <li className="w-[12.5%] text-center flex items-center justify-center">
                      {moment(options.data.hanDung).format('DD/MM/YYYY')}
                    </li>
                    <li className="w-[12.5%]  text-center flex items-center justify-center">
                      {options.data.dvt}
                    </li>
                    <li className="w-[12.5%] border-x text-center flex items-center justify-center">
                      {options.data.ton}
                    </li>
                  </ul>
                )}
                // filterOption={(input, option) =>
                //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                // }
                // options={listVT?.map((items) => ({
                //   label: items.thuocVatTu.tenBietDuoc,
                //   value: items.idThuoc
                // }))}
              />
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                  },
                  components: {
                    Table: {
                      rowHoverBg: '#ecf0f1',
                      headerBg: '#e6e6e6',
                      footerBg: '#e6e6e6',
                      borderColor: '#BABABA',
                    },
                  },
                }}
              >
                <Table
                  bordered
                  pagination={false}
                  // scroll={{ y: 379 }}

                  scroll={{ y: 137 }}
                  dataSource={listVTBN ?? []}
                  columns={[
                    {
                      title: 'STT',
                      dataIndex: 'stt',
                      key: 'stt',
                      align: 'center',
                      width: 40,
                      render: (text, record, index) => ++index,
                    },
                    {
                      title: 'Tên vật tư',
                      dataIndex: 'tenBietDuoc',
                      key: 'tenBietDuoc',
                    },
                    {
                      title: 'Số lô',
                      dataIndex: 'soLo',
                      key: 'soLo',
                      align: 'center',
                      width: 120,
                    },
                    {
                      title: 'Hạn dùng',
                      dataIndex: 'hanDung',
                      key: 'hanDung',
                      align: 'center',
                      width: 90,
                      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY'),
                    },
                    {
                      title: 'Đơn vị',
                      dataIndex: 'dvt',
                      key: 'dvt',
                      align: 'center',
                      width: 70,
                    },
                    {
                      title: 'SL',
                      dataIndex: 'soLuong',
                      key: 'soLuong',
                      align: 'center',
                      width: 65,
                      render: (text, record, index) => (
                        <InputNumber
                          onChange={(value) => handleChangeSoLuong(record, value)}
                          value={text}
                          min={1}
                          step={1}
                          max={record?.tonKho}
                          size="small"
                          className="w-full"
                        />
                      ),
                    },
                    {
                      title: 'Tồn',
                      dataIndex: 'tonKho',
                      key: 'tonKho',
                      align: 'center',
                      width: 65,
                      className: 'text-red-500',
                      render: (text, record, index) =>
                        (record?.tonKho ?? 0) - (record?.soLuong ?? 0) >= 0
                          ? (record?.tonKho ?? 0) - (record?.soLuong ?? 0)
                          : null,
                    },
                    {
                      title: 'Ghi chú',
                      dataIndex: 'ghiChu',
                      key: 'ghiChu',
                      align: 'center',
                      width: 200,
                      render: (text, record, index) => (
                        <Input
                          onChange={(e) => {
                            handleChangeGhiChu(record, e.target.value)
                          }}
                          value={text ?? ''}
                          size="small"
                        />
                      ),
                    },
                    {
                      title: '',
                      dataIndex: 'action',
                      key: 'action',
                      align: 'center',
                      width: 40,
                      render: (text, record, index) => (
                        <>
                          {(InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan?.find(
                            (item) => item?.trangthaikb !== 4 && item?.trangthaitt === 2,
                          ) ||
                            checkDcCapNhat) && (
                            <CloseOutlined
                              onClick={() => {
                                dispatch({
                                  type: typeAction.DISPATCH_DELETE_VT_BN_THUTHUAT,
                                  payload: record.iD,
                                })
                              }}
                              className="text-red-500 cursor-pointer"
                            />
                          )}
                        </>
                      ),
                    },
                  ]}
                />
              </ConfigProvider>
            </>
          )}
        </div>
        <>
          <div className="mt-3 mb-3">
            <h2 className="font-semibold  text-lg m-2 text-gray-500">Tròng kính</h2>
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                bordered
                columns={columnsTK}
                dataSource={InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan}
                pagination={false}
                scroll={{ y: 70 }}
              />
            </ConfigProvider>
          </div>
          <div>
            <h2 className="font-semibold  text-lg m-2 text-gray-500">Gọng kính</h2>
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                bordered
                columns={columnsGK}
                dataSource={InfoBNTrongVaGongKinh?.DanhSachGongKinhBenhNhan}
                pagination={false}
                scroll={{ y: 70 }}
              />
            </ConfigProvider>
          </div>
        </>
      </>
      {/* <Tabs
        className="p-2 pt-0"
        defaultActiveKey="1"
        items={[
          {
            key: '1',
            label: 'Vật tư tiêu hao',
            children: (
              <>
                <div className="">
                  <h2 className="font-semibold  text-lg m-2 text-gray-500">Vật tư tiêu hao</h2>
                  <Select
                    placeholder="Nhập tên vật tư..."
                    className="w-full mb-2"
                    showSearch
                    onChange={handleVTTieuHao}
                    filterOption={(input, option) => {
                      const label = option?.label ?? ''
                      const tenBietDuoc = option?.tenBietDuoc ?? ''
                      const searchText = input.toLowerCase()
                      return (
                        label.toLowerCase().includes(searchText) ||
                        tenBietDuoc.toLowerCase().includes(searchText)
                      )
                    }}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    value={null}
                    options={listVT?.map((items) => ({
                      key: items.iD,
                      value: items.iD,
                      label: items.thuocVatTu.tenHoatChat,
                      tenBietDuoc: items.thuocVatTu.tenBietDuoc,
                      dvt: items.thuocVatTu.dvt,
                      soLo: items.soLo,
                      hanDung: items.hanDung,
                      soHoaDon: items.soHoaDon,
                      maSoDangKy: items.maSoDangKy,
                      ton: items.tonKho,
                    }))}
                    optionRender={(options) => (
                      <ul className="flex">
                        <li className="w-[50%] ">
                          <p className="font-semibold w-full text-pretty">{options.data.label}</p>
                          <span className="text-xs w-full text-gray-500">
                            {' '}
                            ({options.data.tenBietDuoc})
                          </span>
                        </li>
                        <li className="w-[12.5%] border-x flex items-center justify-center text-center">
                          {options.data.soLo}
                        </li>
                        <li className="w-[12.5%] text-center flex items-center justify-center">
                          {moment(options.data.hanDung).format('DD/MM/YYYY')}
                        </li>
                        <li className="w-[12.5%] border-x text-center flex items-center justify-center">
                          {options.data.ton}
                        </li>
                        <li className="w-[12.5%]  text-center flex items-center justify-center">
                          {options.data.dvt}
                        </li>
                      </ul>
                    )}
                    // filterOption={(input, option) =>
                    //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    // }
                    // options={listVT?.map((items) => ({
                    //   label: items.thuocVatTu.tenBietDuoc,
                    //   value: items.idThuoc
                    // }))}
                  />
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 5,
                      },
                    }}
                  >
                    <Table
                      bordered
                      pagination={false}
                      // scroll={{ y: 379 }}

                      scroll={{ y: 250 }}
                      // dataSource={listVTBN ?? []}
                      dataSource={InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan.concat(
                        InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan,
                      )}
                      columns={[
                        {
                          title: 'STT',
                          dataIndex: 'stt',
                          key: 'stt',
                          align: 'center',
                          width: 40,
                          render: (text, record, index) => ++index,
                        },
                        {
                          title: 'Tên vật tư',
                          dataIndex: 'tenBietDuoc',
                          key: 'tenBietDuoc',
                        },
                        {
                          title: 'Số lô',
                          dataIndex: 'soLo',
                          key: 'soLo',
                          align: 'center',
                          width: 120,
                        },
                        {
                          title: 'Hạn dùng',
                          dataIndex: 'hanDung',
                          key: 'hanDung',
                          align: 'center',
                          width: 90,
                          render: (text, record, index) =>
                            text && moment(text).format('DD/MM/YYYY'),
                        },
                        {
                          title: 'Đơn vị',
                          dataIndex: 'dvt',
                          key: 'dvt',
                          align: 'center',
                          width: 70,
                        },
                        {
                          title: 'SL',
                          dataIndex: 'soLuong',
                          key: 'soLuong',
                          align: 'center',
                          width: 65,
                          render: (text, record, index) => (
                            <InputNumber
                              onChange={(value) => handleChangeSoLuong(record, value)}
                              value={text}
                              min={1}
                              step={1}
                              max={record?.tonKho}
                              size="small"
                              className="w-full"
                            />
                          ),
                        },
                        {
                          title: 'Tồn',
                          dataIndex: 'tonKho',
                          key: 'tonKho',
                          align: 'center',
                          width: 65,
                          className: 'text-red-500',
                          render: (text, record, index) =>
                            (record?.tonKho ?? 0) - (record?.soLuong ?? 0) >= 0
                              ? (record?.tonKho ?? 0) - (record?.soLuong ?? 0)
                              : null,
                        },
                        {
                          title: 'Ghi chú',
                          dataIndex: 'ghiChu',
                          key: 'ghiChu',
                          align: 'center',
                          width: 200,
                          render: (text, record, index) => (
                            <Input
                              onChange={(e) => {
                                handleChangeGhiChu(record, e.target.value)
                              }}
                              value={text ?? ''}
                              size="small"
                            />
                          ),
                        },
                        {
                          title: '',
                          dataIndex: 'action',
                          key: 'action',
                          align: 'center',
                          width: 40,
                          render: (text, record, index) => (
                            <>
                              {(InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan?.find(
                                (item) => item?.trangthaikb !== 4 && item?.trangthaitt === 2,
                              ) ||
                                checkDcCapNhat) && (
                                <CloseOutlined
                                  onClick={() => {
                                    dispatch({
                                      type: typeAction.DISPATCH_DELETE_VT_BN_THUTHUAT,
                                      payload: record.iD,
                                    })
                                  }}
                                  className="text-red-500 cursor-pointer"
                                />
                              )}
                            </>
                          ),
                        },
                      ]}
                    />
                  </ConfigProvider>
                </div>
                <>
                  <div className="mt-5 mb-5">
                    <h2 className="font-semibold  text-lg m-2 text-gray-500">Tròng kính</h2>
                    <ConfigProvider
                      theme={{
                        token: {
                          padding: 5,
                        },
                      }}
                    >
                      <Table
                        bordered
                        columns={columnsTK}
                        // dataSource={InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan}
                        dataSource={InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan.concat(
                          InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan,
                        )}
                        pagination={false}
                        scroll={{ y: 55 }}
                      />
                    </ConfigProvider>
                  </div>
                  <div>
                    <h2 className="font-semibold  text-lg m-2 text-gray-500">Gọng kính</h2>
                    <ConfigProvider
                      theme={{
                        token: {
                          padding: 5,
                        },
                      }}
                    >
                      <Table
                        bordered
                        columns={columnsGK}
                        // dataSource={InfoBNTrongVaGongKinh?.DanhSachGongKinhBenhNhan}
                        dataSource={InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan.concat(
                          InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan,
                        )}
                        pagination={false}
                        scroll={{ y: 55 }}
                      />
                    </ConfigProvider>
                  </div>
                </>
              </>
            ),
          },
          {
            key: '2',
            label: 'Tròng & gọng kính',
            children: (
              <>
                <div className="mb-5">
                  <h2 className="font-semibold  text-lg m-2 text-gray-500">Tròng kính</h2>
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 5,
                      },
                    }}
                  >
                    <Table
                      bordered
                      columns={columnsTK}
                      // dataSource={InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan}
                      dataSource={InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan.concat(
                        InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan,
                      )}
                      pagination={false}
                      scroll={{ y: 190 }}
                    />
                  </ConfigProvider>
                </div>
                <div>
                  <h2 className="font-semibold  text-lg m-2 text-gray-500">Gọng kính</h2>
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 5,
                      },
                    }}
                  >
                    <Table
                      bordered
                      columns={columnsGK}
                      // dataSource={InfoBNTrongVaGongKinh?.DanhSachGongKinhBenhNhan}
                      dataSource={InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan.concat(
                        InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan,
                      )}
                      pagination={false}
                      scroll={{ y: 190 }}
                    />
                  </ConfigProvider>
                </div>
              </>
            ),
          },
        ]}
      /> */}
    </div>
  )
}

export default VatTu

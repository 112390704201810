import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { xuatTraNCCService } from '../../services/xuatTraNCC/xuatTraNCCService'
import ToastCus from '../../utils/Toast'

export function* xuatTraNCCSaga() {
  // POST PHIẾU XUẤT SỬ DỤNG
  yield takeLatest(
    typeAction.POST_PHIEU_XUAT_TRA_NCC,
    function* postPhieuXuatTraNCCSaga({ type, form, list, infoDT, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        console.log(form, list, infoDT, resetForm)
        const { data: infoPhieu } = yield call(() => xuatTraNCCService.postPhieuXuatTraNCC(form))
        console.log(infoPhieu)
        const formatData = list?.map((item) => ({
          idthuoc: item?.idthuoc,
          mathuoc: item?.mathuoc,
          tenthuoc: item?.tenbietduoc,
          soluongchan: item?.soluongchan,
          donvichan: item?.dvtchan,
          dongiachan: item?.dongiachan,
          solo: item?.solo,
          handung: item?.handung,
          sohoadon: item?.sohoadon,
          masodangky: item?.masodangky,
          quycach: item?.quycach,
          quycachdonggoi: item?.quycachdonggoi,
          soluongle: item?.soluongle,
          donvile: item?.dvtle,
          dongiale: item?.dongiale,
          thanhtien: item?.thanhtien,
          ghichu: item?.ghichu,
          stt: item?.stt,
          idxtncc: infoPhieu?.idxtncc,
        }))
        yield call(() => xuatTraNCCService.postListChiTietPhieuXuatTraNCC(formatData))
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}

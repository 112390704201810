import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  ListBNChoKham: null,
  ListBNDangKham: null,
  ListBNDaKham: null,
}

const phcnClinicReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_BN_CHO_KHAM_TMTD:
        draft.ListBNChoKham = payload
        break
      case typeAction.DISPATCH_BN_DANG_KHAM_TMTD:
        draft.ListBNDangKham = payload
        break
      case typeAction.DISPATCH_BN_DA_KHAM_TMTD:
        draft.ListBNDaKham = payload
        break
      default:
        return state
    }
  })
}

export default phcnClinicReducer

import { https } from '../apiService'

export const benhnhanCRMService = {
  deleteBNCRMById: (id) => https.delete(`CRMBenhNhan/DeleteBenhNhan/${id}`),
  postBNCRM: (form) => https.post('CRMBenhNhan/AddListBenhNhan', form),
  getAllKHCRM: () => https.get('CRMKhachHang/GetAll'),
  sendSMS: (nguoigui, listSMS) => https.post(`CRMBenhNhan/SendSMS?nguoiGui=${nguoigui}`, listSMS),
  sendSMSAllBN: (params) => https.post(`CRMBenhNhan/SendSMSByCondition${params}`),
  getInfoBNCRMById: (id) => https.get(`CRMBenhNhan/GetBenhNhanByIDBN?IDBN=${id}`),
  postInfoBNCRMById: (id, form) => https.put(`CRMBenhNhan/UpdateBenhNhan?id=${id}`, form),
  postBNCRMByExcel: (form) => https.post('CRMBenhNhan/ImportListBenhNhan', form),
  // searchBNCRM: (idkh, keyword) => https.get(`CRMBenhNhan/SearchBenhNhanByCondition?idKH=${idkh}&keyword=${keyword}`),
  // getAllBNCRM: () => https.get('CRMBenhNhan/SearchBenhNhanByCondition'),
  searchBNCRM: (idkh, keyword) =>
    https.get(`CRMBenhNhan/SearchBenhNhanByConditionNotPagination?idKH=${idkh}&keyword=${keyword}`),
  getAllBNCRM: () => https.get('CRMBenhNhan/SearchBenhNhanByConditionNotPagination'),
}

import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'

import ToastCus from '../../utils/Toast'
import { khoDieuChinhService } from '../../services/khodieuchinh/khoDieuChinhService'
import { check } from 'prettier'

export function* khoDieuChinhSaga() {
  // POST PHIẾU XUẤT SỬ DỤNG
  yield takeLatest(
    typeAction.POST_PHIEU_DIEU_CHINH_KHO,
    function* postPhieuKhoDieuChinhSaga({ type, form, list, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data: infoPhieu } = yield call(() =>
          khoDieuChinhService.postPhieuDieuChinhKho(form),
        )
        console.log('infoPhieu', infoPhieu)
        const formatData = list?.map((item) => ({
          idthuoc: item?.idthuoc,
          // tenbietduoc: item?.tenbietduoc,
          // tenhoatchat: item?.tenhoatchat,
          solo: item?.solo,
          handung: item?.handung,
          sohoadon: item?.sohoadon,
          soluong: item?.soluong,
          // masodangky: item?.masodangky,
          ghichu: item?.ghichu,
          stt: item?.stt,
          // mathuoc: item?.mathuoc,
          // dvt: item?.dvt,
          idNhapXuat: infoPhieu?.data?.idNhapXuat,
          chenhlech: item?.chenhlech,
        }))
        yield call(() => khoDieuChinhService.postListVTHH(formatData))
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}

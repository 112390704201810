import moment from 'moment'
import { formatNumberVND } from '../formatNumberVND'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord } from '../splitWordForReport'
import 'moment/locale/vi'
moment.locale('vi')

export const generatePhieuLichSuGiaoDichBenhNhan = (
  bnPaymentCard,
  historyPaymentCard,
  dateForm,
  dateTo,
) => {
  const doc = jsPDFCus('p', 'mm', 'a4')
  let yPos = 10
  let xPos = 8
  const pageWidth = doc.internal.pageSize.width

  doc.setFont('times', 'bold')
  doc.setFontSize(16)
  doc.text('LỊCH SỬ SỬ DỤNG THẺ THANH TOÁN', pageWidth / 2, 15, {
    align: 'center',
  })
  doc.setFontSize(9)

  doc.text(
    `Từ ${moment(dateForm).format('DD/MM/YYYY')} Đến ${moment(dateTo).format('DD/MM/YYYY')}`,
    pageWidth -
      doc.getTextDimensions(
        `Từ ${moment(dateForm).format('DD/MM/YYYY')} Đến ${moment(dateTo).format('DD/MM/YYYY')}`,
      ).w -
      20,
    20,
  )
  doc.setFont('times', 'regular')
  doc.setFontSize(11)
  doc.text('Họ và tên: ', xPos, 30)
  doc.setFont('times', 'bold')
  let textWidthName = doc.getTextDimensions('Họ và tên: ').w
  doc.text(bnPaymentCard.tenBenhNhan ?? '', xPos + textWidthName, 30)
  doc.setFont('times', 'regular')
  doc.text(
    `Giới tính: ${bnPaymentCard.gioiTinh}`,
    pageWidth - doc.getTextDimensions(`Giới tính: ${bnPaymentCard.gioiTinh}`).w - 65,
    30,
  )
  doc.text(
    `Mã BN: ${bnPaymentCard.maBenhNhan}`,
    pageWidth - doc.getTextDimensions(`Mã BN: ${bnPaymentCard.maBenhNhan}`).w - 20,
    30,
  )
  doc.text(`Ngày sinh: ${moment(bnPaymentCard.ngaySinh).format('DD/MM/YYYY')}`, xPos, 35)
  doc.text(`Dân tộc: ${bnPaymentCard.tenDanToc}`, xPos + 80, 35)
  doc.text(`Quốc tịch: ${bnPaymentCard.tenQuocTich}`, xPos + 130, 35)
  const diaChiBenhNhan = `Địa chỉ: ${bnPaymentCard.diaChi ?? ''}${bnPaymentCard.tenPhuongXa ? ', ' + bnPaymentCard.tenPhuongXa : ''}${bnPaymentCard.tenQuanHuyen ? ', ' + bnPaymentCard.tenQuanHuyen : ''}${bnPaymentCard.tenTinhTP ? ', ' + bnPaymentCard.tenTinhTP : ''}`

  if (diaChiBenhNhan.length > 90) {
    const wordsArray = splitLongWord(diaChiBenhNhan, 90)
    doc.text(wordsArray[0], xPos, 40)
    doc.text(wordsArray[1], xPos, 44)
    yPos = 44
  } else {
    doc.text(diaChiBenhNhan, xPos, 40)
    yPos = 40
  }
  yPos += 5

  //table
  const headers = ['Số giao dịch', 'Thời gian', 'Nội dung', 'Loại tiền', 'Thu', 'Chi']
  const data = historyPaymentCard.map((item) => [
    item.idSuDungThe,
    moment(item.ngaySuDung).format('DD/MM/YYYY HH:mm:ss'),
    item.tien ? 'Nạp thẻ thanh toán' : 'Thanh toán dịch vụ',
    'VNĐ',
    item.tongTien ? formatNumberVND(item.tongTien) : '',
    item.tienSuDUng ? formatNumberVND(item.tienSuDUng) : '',
  ])
  const columnWidths = [25, 35, 50, 20, 30, 30]
  doc.autoTable({
    head: [headers],
    body: data,
    startY: yPos,
    columnStyles: {
      // Set custom column widths
      0: { cellWidth: columnWidths[0], halign: 'center' },
      1: { cellWidth: columnWidths[1], halign: 'center' },
      2: { cellWidth: columnWidths[2], halign: 'left' },
      3: { cellWidth: columnWidths[3], halign: 'center' },
      4: { cellWidth: columnWidths[4], halign: 'right' },
      5: { cellWidth: columnWidths[5], halign: 'right' },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1, // Set line width for table borders
      fillColor: '#fff', // Set fill color to white for headers and rows
      textColor: '#000', // Set text color to black for headers and rows
      lineColor: '#000',
      halign: 'center',
      valign: 'middle',
      fontStyle: 'regular',
      fontSize: 9,
      // Set line color to black for headers and rows
    },
    headStyles: {
      font: 'times',
      fillColor: '#fff', // Set fill color to white for headers
      textColor: '#000', // Set text color to black for headers
      fontStyle: 'bold', // Set font weight to bold for headers
      fontSize: 10,
    },
    alternateRowStyles: {
      font: 'times',
      fontStyle: 'regular',
      fillColor: '#fff', // Set fill color to white for alternate rows
    },
    showHead: 'firstPage',
    margin: { left: xPos },
  })

  const tongThu = historyPaymentCard.reduce((total, item) => total + item.tongTien, 0)
  const tongChi = historyPaymentCard.reduce((total, item) => total + item.tienSuDUng, 0)

  const data2Column = [
    ['', '', ''],
    ['TỔNG TIỀN: ', formatNumberVND(tongThu), formatNumberVND(tongChi)],
  ]
  doc.autoTable({
    head: [data2Column[0]],
    body: data2Column.slice(1),
    startY: doc.autoTable.previous.finalY,
    columnStyles: {
      0: { cellWidth: 130, halign: 'right' },
      1: { cellWidth: 30, halign: 'right', fontStyle: 'bold' },
      2: { cellWidth: 30, halign: 'right', fontStyle: 'bold' },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1, // Set line width for table borders
      fillColor: '#fff', // Set fill color to white for headers and rows
      textColor: '#000', // Set text color to black for headers and rows
      lineColor: '#000',
      valign: 'middle',
      fontStyle: 'bold', // Set font weight to bold for headers
      fontSize: 10,
      // Set line color to black for headers and rows
    },
    headStyles: {
      font: 'times',
      fillColor: '#fff', // Set fill color to white for headers
      textColor: '#000', // Set text color to black for headers
      fontSize: 10,
    },
    alternateRowStyles: {
      font: 'times',
      fillColor: '#fff', // Set fill color to white for alternate rows
    },
    showHead: 'never',
    margin: { left: xPos },
  })

  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}

import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, DatePicker, Input, Select, Space, Table, TreeSelect } from 'antd'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { SearchOutlined } from '@ant-design/icons'
import { khoVTservice } from '../../../services/khoVT/khoVTservice'
import { formattedNumber } from '../../../utils/formattedNumber'
import Highlighter from 'react-highlight-words'
import { exportExcelformat } from '../../../utils/export/exportExcelFormat'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import dayjs from 'dayjs'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/vi_VN'
import 'moment/locale/vi'
import 'dayjs/locale/vi'
moment.locale('vi')
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

const TungLan = () => {
  const dispatch = useDispatch()
  const [dataReport, setDataReport] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [dateFrom, setDateFrom] = useState(moment().format(momentDate))
  const [dateTo, setDateTo] = useState(moment().format(momentDate))
  const [idLoai, setidLoai] = useState(['all'])
  const [listLoai, setlistLoai] = useState([])
  const [idNhom, setidNhom] = useState(['all'])
  const [listNhom, setlistNhom] = useState([])
  const [keyword, setkeyword] = useState(null)
  const [isLoadingLoai, setisLoadingLoai] = useState(false)
  const [isLoadingNhom, setisLoadingNhom] = useState(false)
  const [valueExport, setValueExport] = useState([])
  const [searchText, setSearchText] = useState('')
  const searchInput = useRef(null)
  const [searchedColumn, setSearchedColumn] = useState('')
  const listNhomFilter =
    !idLoai || !idLoai?.length
      ? []
      : idLoai?.includes('all')
        ? listNhom
        : listNhom?.filter((item) => idLoai?.includes(item?.idPhanLoai))

  const maQuyenXemDs = 'QHT631'
  const maQuyenXuatExcel = 'QHT632'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        setkeyword((prev) => (prev?.length ? prev?.trim() : prev))
        getDataReport()
        break
      case maQuyenXuatExcel:
        exportToExcel()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXuatExcel:
        //
        break
      default:
        return false
    }
  }

  const getDataReport = async () => {
    try {
      setIsLoading(true)
      let strIdNhom = ''

      if (!idLoai || !idLoai?.length || idLoai?.includes('all')) {
        setidLoai(['all'])
        setidNhom(['all'])
        strIdNhom = listNhom?.reduce((str, item) => (str += `${item?.idNhom},`), '')
      } else {
        if (!idNhom || !idNhom?.length || idNhom?.includes('all')) {
          setidNhom(['all'])
          strIdNhom = listNhom
            ?.filter((item) => idLoai?.includes(item?.idPhanLoai))
            ?.reduce((str, item) => (str += `${item?.idNhom},`), '')
        } else {
          strIdNhom = listNhom
            ?.filter((item) => idLoai?.includes(item?.idPhanLoai) && idNhom?.includes(item?.idNhom))
            ?.reduce((str, item) => (str += `${item?.idNhom},`), '')
        }
      }

      if (strIdNhom?.length && strIdNhom?.endsWith(','))
        strIdNhom = `idnhom=${strIdNhom?.slice(0, -1)}&`
      else return

      // const params = new URLSearchParams()
      // keyword?.length && keyword?.trim()?.length && params.append('keyword', keyword?.trim())
      // listIdNhom?.forEach((id) => params.append('idnhom', id))
      // params.append('tuNgay', dateFrom)
      // params.append('denNgay', dateTo)
      // console.log(params)

      const { data } = await khoVTservice.getGiaNhapTungLan(
        keyword?.length ? keyword?.trim() : '',
        strIdNhom?.length ? strIdNhom : '',
        dateFrom,
        dateTo,
      )
      setDataReport(data)
    } catch (error) {
      console.log('getDataReport : ', error)
    } finally {
      setIsLoading(false)
    }
  }
  const getListLoaiHang = async () => {
    try {
      setisLoadingLoai(true)
      const { data } = await khoVTservice.getListTypesThuocVT()
      setlistLoai(data?.filter((item) => item?.suDung))
    } catch (error) {
      console.log('getListLoaiHang : ', error)
    } finally {
      setisLoadingLoai(false)
    }
  }
  const getListNhomHang = async () => {
    try {
      setisLoadingNhom(true)
      const { data } = await khoVTservice.getAllGroupThuocVT()
      setlistNhom(data?.filter((item) => item?.suDung))
    } catch (error) {
      console.log('getListNhomHang : ', error)
    } finally {
      setisLoadingNhom(false)
    }
  }
  // const getListNhomHangByIdLoai = async (idloai) => {
  //   try {
  //     const { data } = await khoVTservice.getListGroupsThuocVT(idloai)
  //     setlistNhom(data)
  //   } catch (error) {
  //     console.log('getListNhomHangByIdLoai : ', error)
  //   }
  // }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const onChangeTable = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const onLoad = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  const exportToExcel = () => {
    const formattedData = valueExport?.map((item, index) => ({
      'STT ': ++index,
      'Mã hàng': item?.mathuoc,
      'Tên hàng': item?.tenbietduoc,
      'Loại hàng': item?.phanloai,
      'Nhóm hàng': item?.nhom,
      'Đơn vị tính': item?.dvt,
      'Giá nhập trước thuế': formattedNumber(item?.tongtientruocvat ?? 0),
      'Giá nhập sau thuế': formattedNumber(item?.thanhtien ?? 0),
      'Ngày nhập': item?.ngaynhan ? moment(item?.ngaynhan).format('DD/MM/YYYY HH:mm:ss') : '-',
      'Nhà cung cấp': item?.tendoitac,
    }))
    const name = 'Giá nhập từng lần'
    formattedData?.length && exportExcelformat(formattedData, name)
  }

  useEffect(() => {
    getListLoaiHang()
    getListNhomHang()
  }, [])
  useEffect(() => {
    onLoad()
  }, [listNhom])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã hàng',
      dataIndex: 'mathuoc',
      key: 'mathuoc',
      width: 100,
      fixed: 'left',
      ...getColumnSearchProps('mathuoc')
    },
    {
      title: 'Tên hàng',
      dataIndex: 'tenbietduoc',
      key: 'tenbietduoc',
      fixed: 'left',
      ...getColumnSearchProps('tenbietduoc')
    },
    {
      title: 'Loại hàng',
      dataIndex: 'phanloai',
      key: 'phanloai',
      ...getColumnSearchProps('phanloai')
      
    },
    {
      title: 'Nhóm hàng',
      dataIndex: 'nhom',
      key: 'nhom',
      ...getColumnSearchProps('nhom')
    },
    {
      title: 'ĐVT',
      dataIndex: 'dvt',
      key: 'dvt',
      width: 60,
      align: 'center',
      ...getColumnSearchProps('dvt')
    },
    {
      title: 'Giá nhập trước thuế',
      dataIndex: 'tongtientruocvat',
      key: 'tongtientruocvat',
      width: 145,
      align: 'right',
      render: (text, record, index) => formattedNumber(text ?? 0),
    },
    {
      title: 'Giá nhập sau thuế',
      dataIndex: 'thanhtien',
      key: 'thanhtien',
      width: 145,
      align: 'right',
      render: (text, record, index) => formattedNumber(text ?? 0),
    },
    {
      title: 'Ngày nhập',
      dataIndex: 'ngaynhan',
      key: 'ngaynhan',
      width: 140,
      align: 'center',
      render: (text, record, index) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
    },
    {
      title: 'Nhà cung cấp',
      dataIndex: 'tendoitac',
      key: 'tendoitac',
      ...getColumnSearchProps('tendoitac')
    },
  ]
  const treeDataLoai = [
    {
      title: 'Tất cả loại hàng',
      value: 'all',
      key: 'all',
      children: listLoai
        ?.map((item) => ({
          title: item?.phanLoai,
          value: item?.idPhanLoai,
          key: `${item?.idPhanLoai}`,
        }))
        ?.sort((a, b) => (a?.title ?? '')?.localeCompare(b?.title ?? '')),
    },
  ]
  const tPropsLoai = {
    disabled: isLoadingLoai,
    treeData: treeDataLoai,
    value: isLoadingLoai ? ['Đang lấy dữ liệu...'] : idLoai,
    onChange: (value) => {
      setidLoai(value)
      setidNhom(['all'])
    },
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn loại hàng...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const treeDataNhom = [
    {
      title: 'Tất cả nhóm hàng',
      value: 'all',
      key: 'all',
      children: listNhomFilter
        ?.map((item) => ({
          title: item?.nhom,
          value: item?.idNhom,
          key: `${item?.idNhom}`,
        }))
        ?.sort((a, b) => (a?.title ?? '')?.localeCompare(b?.title ?? '')),
    },
  ]
  const tPropsNhom = {
    disabled: isLoadingNhom,
    treeData: treeDataNhom,
    value: isLoadingNhom ? ['Đang lấy dữ liệu...'] : idNhom,
    onChange: (value) => setidNhom(value),
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn nhóm hàng...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  useEffect(()  => {
    setValueExport(dataReport)
  },[dataReport])
  return (
    <>
      <div className="flex gap-2">
        <div className="w-[17rem] flex justify-between items-start gap-1">
          <DatePicker
            placeholder="Từ ngày..."
            allowClear={false}
            defaultValue={dayjs(moment())}
            maxDate={dayjs(moment())}
            onChange={(date, dateString) =>
              setDateFrom(moment(dateString, dateFormat).format(momentDate))
            }
            format={dateFormat}
            locale={{ ...locale, week: { start: 1 } }}
          />
          <div className="font-semibold text-2xl flex items-start">-</div>
          <DatePicker
            placeholder="Đến ngày..."
            allowClear={false}
            defaultValue={dayjs(moment())}
            maxDate={dayjs(moment())}
            onChange={(date, dateString) =>
              setDateTo(moment(dateString, dateFormat).format(momentDate))
            }
            format={dateFormat}
            locale={{ ...locale, week: { start: 1 } }}
          />
        </div>
        <div className="w-[17rem]">
          <TreeSelect
            {...tPropsLoai}
            style={{ flexWrap: 'unset', color: 'black' }}
            className="w-full h-8 overflow-clip"
            size="medium"
          />
          {/* <Select
            placeholder="Chọn loại hàng..."
            showSearch
            className="w-full"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={idLoai}
            onChange={(value) => {
              // setidLoai(value)
              // setidNhom(0)
              // getListNhomHangByIdLoai(value)
            }}
            options={[
              {
                key: 0,
                value: 0,
                label: 'Tất cả loại hàng',
              },
              ...listLoai
                ?.sort((a, b) =>
                  `${a?.phanLoai ?? ''}`
                    .toLowerCase()
                    .localeCompare(`${b?.phanLoai ?? ''}`.toLowerCase()),
                )
                ?.map((item) => ({
                  key: item?.idPhanLoai,
                  value: item?.idPhanLoai,
                  label: item?.phanLoai,
                })),
            ]}
          /> */}
        </div>
        <div className="w-[17rem]">
          <TreeSelect
            {...tPropsNhom}
            style={{ flexWrap: 'unset', color: 'black' }}
            className="w-full h-8 overflow-clip"
            size="medium"
          />
          {/* <Select
            placeholder="Chọn nhóm hàng..."
            showSearch
            className="w-full"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={idNhom}
            // onChange={(value) => setidNhom(value)}
            options={[
              {
                key: 0,
                value: 0,
                label: 'Tất cả nhóm hàng',
              },
              ...(!idLoai
                ? []
                : listNhom
                    ?.sort((a, b) =>
                      `${a?.nhom ?? ''}`
                        .toLowerCase()
                        .localeCompare(`${b?.nhom ?? ''}`.toLowerCase()),
                    )
                    ?.map((item) => ({
                      key: item?.idNhom,
                      value: item?.idNhom,
                      label: item?.nhom,
                    }))),
            ]}
          /> */}
        </div>
        <div className="w-[17rem]">
          <Input
            value={keyword}
            placeholder="Nhập mã hàng, tên hàng..."
            onChange={(e) => setkeyword(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </div>
        <div className="flex gap-2">
          <IconButton
            disabled={isLoading || isLoadingLoai || isLoadingNhom}
            onClick={onLoad}
            color="primary"
            aria-label="delete"
            size="small"
          >
            <SyncIcon />
          </IconButton>
        </div>
        <div className="font-semibold">
          <Button
            disabled={isLoading || !dataReport?.length}
            // onClick={exportToExcel}
            onClick={() =>
              dispatch(
                putCheckQuyenThaoTacAction(maQuyenXuatExcel, null, actionIsTrue, actionIsFalse),
              )
            }
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <div className="mt-2">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
           onChange={onChangeTable}
            scroll={{
              x: dataReport?.length ? 'max-content' : '',
              y: '75.4vh',
            }}
            bordered
            pagination={false}
            loading={isLoading || isLoadingLoai || isLoadingNhom}
            columns={columns}
            dataSource={dataReport}
            // dataSource={[
            //   1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2,
            //   3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4,
            //   5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9,
            // ]}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default TungLan

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker, Select, TreeSelect, Button, ConfigProvider, Table } from 'antd'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { reportService } from '../../../../../services/Report/reportService'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { khoVTservice } from '../../../../../services/khoVT/khoVTservice'
import { phanQuyenService } from '../../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../../store/actions/phanQuyenAction'
import { exportExcelformat } from '../../../../../utils/export/exportExcelFormat'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import { calculateSum } from '../../../../../utils/calculateSum'
import ToastCus from '../../../../../utils/Toast'
import { columns } from './Columns'
import dayjs from 'dayjs'
import moment from 'moment'
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const { SHOW_PARENT } = TreeSelect
const TongHop = ({ ID_BRANCH, ID_COMPANY }) => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [branchs, setBranchs] = useState([])
  const [chuyenkhoas, setChuyenKhoas] = useState([])
  const [data, setData] = useState([])
  const [dataExport, setDataExport] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState({
    idCompany: ID_COMPANY,
    idChuyenKhoa: '',
    idBranch: [ID_BRANCH],
    form: now,
    to: now,
  })
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoas(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBranchByChuyenKhoa = async (idct, value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(idct, value)
      setBranchs(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangSelected = (name) => (value) => {
    setForm((prevState) => ({ ...prevState, [name]: value, idBranch: '' }))
    fetchBranchByChuyenKhoa(form?.idCompany, value)
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: '',
      children: branchs?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
      })),
    },
  ]
  const handleDatePicker = (name) => (date, dateString) => {
    const value = moment(dateString, dateFormat).format(momentDate)
    setForm((prevState) => ({ ...prevState, [name]: value }))
  }
  const tProps = {
    treeData,
    value: form?.idBranch,
    onChange: (value) => setForm((prevState) => ({ ...prevState, idBranch: value })),
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const fetchData = async () => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT556',
      )
      let idChiNhanhs = null
      if (form.idBranch?.length > 0 && !['all', '']?.includes(form.idBranch[0])) {
        idChiNhanhs = form.idBranch?.map((id) => `&idChiNhanhs=${id}`).join('&')
        const { data } = await reportService.getDetailLoiNhuanPhieuXuat(
          form.idCompany,
          form.idChuyenKhoa,
          idChiNhanhs,
          form.form,
          form.to,
        )
        setData(data)
      } else {
        const { data } = await reportService.getDetailLoiNhuanPhieuXuat(
          form.idCompany,
          form.idChuyenKhoa,
          '',
          form.form,
          form.to,
        )
        setData(data)
      }
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranchByChuyenKhoa(ID_COMPANY, '')
    fetchChuyenKhoa()
  }, [])
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT557',
      )

      const formatData = data?.map((item) => ({
        Ngày: moment(item.ngay).format('DD/MM/YYYY'),
        'Ngày khai trương': moment(item.ngaykhaitruong).format('DD/MM/YYYY'),
        'Chi nhánh': item.tenchinhanh,
        'Số phiếu bán lẻ': item.sophieubanle,
        'Thành tiền bán lẻ': item.thanhtienbanle,
        'Số phiếu bán toa': item.sophieubantoa,
        'Thành tiền bán toa': item.thanhtienbantoa,
        'Số phiếu bán nội bộ': item.sophieuxuatnoibo,
        'Thành tiền bán nội bộ': item.thanhtienxuatnoibo,
        'Số phiếu thuốc': item.sophieuthuoc,
        'Thành tiền thuốc': item.thanhtienthuoc,
        'Số phiếu vacxin': item.sophieuvacxin,
        'Thành tiền vacxin': item.thanhtienvacxin,
        'Tổng số phiếu': item.sophieutong,
        'Tổng thành tiền': item.thanhtientong,
      }))
      const name = 'Doanh thu tổng hợp phiếu xuất'
      formatData?.length && exportExcelformat(formatData, name)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  return (
    <>
      <div className="flex gap-5 justify-between border-dotted border-b-0">
        <div className="flex flex-wrap gap-5 items-center mb-2">
          <div className="w-80">
            <Select
              showSearch
              value={form.idCompany}
              onChange={(value) => {
                fetchBranchByChuyenKhoa(value, '')
                // setIdChiNhanh(null)
                setForm((prevState) => ({
                  ...prevState,
                  idCompany: value,
                  idChuyenKhoa: '',
                  idBranch: [''],
                }))
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-56">
            <Select
              onChange={onChangSelected('idChuyenKhoa')}
              className="w-full"
              value={form.idChuyenKhoa}
              options={[
                { label: 'Tất cả chuyên khoa', value: '' },
                ...chuyenkhoas.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
            />
          </div>
          <div className="w-72">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-3">
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('form')}
                format={dateFormat}
              />
              <span className="font-semibold text-lg">-</span>
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('to')}
                format={dateFormat}
              />
            </div>
            <div className="flex gap-3">
              <IconButton onClick={fetchData} color="primary" aria-label="delete" size="small">
                <SyncIcon />
              </IconButton>
            </div>
            <div className="font-semibold ">
              <Button
                disabled={!data?.length}
                onClick={exportToExcel}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
              >
                Xuất Excel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#EFEFEF',
                footerBg: '#EFEFEF',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            loading={isLoading}
            bordered
            scroll={{
              y: '66vh',
            }}
            columns={columns}
            pagination={false}
            dataSource={data}
            summary={() => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={4}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'sophieubanle'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'thanhtienbanle'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'sophieubantoa'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'thanhtienbantoa'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'sophieuxuatnoibo'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'thanhtienxuatnoibo'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'sophieuthuoc'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'thanhtienthuoc'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'sophieuvacxin'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'thanhtienvacxin'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'sophieutong'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12} align="right" className="font-semibold">
                      {formattedNumber(calculateSum(data, 'thanhtientong'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default TongHop

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Input, Button, Modal, Spin, Checkbox } from 'antd'
import ToastCus from '../../../../../utils/Toast'
import {
  postQuyenHeThongAction,
  putQuyenHeThongAction,
} from '../../../../../store/actions/phanQuyenAction'

const ModalQuyen = ({ isModalQuyen, setisModalQuyen, infoEditQuyen, getListQuyen }) => {
  const dispatch = useDispatch()
  const [form, setform] = useState(null)
  const [isLoading, setisLoading] = useState(false)
  const handleSubmit = () => {
    if (!form || !form?.tenquyen) {
      if (!form) {
        setform((prev) => ({ ...prev, tenquyen: null }))
      }
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    if (infoEditQuyen && !infoEditQuyen?.idqht) {
      resetForm()
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    setisLoading(true)
    if (!infoEditQuyen || !infoEditQuyen?.idqht) {
      //thêm mới
      dispatch(postQuyenHeThongAction(form, resetForm))
    } else if (infoEditQuyen?.idqht) {
      // cập nhật
      dispatch(
        putQuyenHeThongAction(
          { ...form, idqht: infoEditQuyen?.idqht, maquyen: infoEditQuyen?.maquyen },
          resetForm,
        ),
      )
    }
  }
  const resetForm = () => {
    setform(null)
    setisModalQuyen(false)
    setisLoading(false)
    getListQuyen()
  }
  useEffect(() => {
    if (isModalQuyen && infoEditQuyen?.idqht) {
      setform(infoEditQuyen)
    } else {
      setform(null)
    }
  }, [isModalQuyen, infoEditQuyen])
  return (
    <Modal
      open={isModalQuyen}
      onCancel={() => !isLoading && setisModalQuyen(false)}
      title={
        <p className="text-center">{infoEditQuyen ? 'Chỉnh sửa' : 'Thêm'}&nbsp;quyền hệ thống</p>
      }
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <Button disabled={isLoading} onClick={() => !isLoading && setisModalQuyen(false)}>
            Đóng
          </Button>
          <Button type="primary" loading={isLoading} onClick={handleSubmit}>
            Lưu
          </Button>
        </>
      )}
    >
      <Spin spinning={isLoading}>
        <div className="grid grid-flow-row gap-2">
          {infoEditQuyen?.idqht && (
            <div>
              <div className="font-semibold">
                <span className="text-red-500">(*)</span>&nbsp;Mã quyền:
              </div>
              <Input
                readOnly
                variant="filled"
                status={
                  infoEditQuyen &&
                  (!infoEditQuyen?.maquyen || !infoEditQuyen?.maquyen?.trim()?.length)
                    ? 'error'
                    : ''
                }
                value={infoEditQuyen?.maquyen}
              />
            </div>
          )}
          <div>
            <div className="font-semibold">
              <span className="text-red-500">(*)</span>&nbsp;Tên quyền:
            </div>
            <Input
              status={form && (!form?.tenquyen || !form?.tenquyen?.trim()?.length) ? 'error' : ''}
              value={form?.tenquyen}
              onChange={(e) => setform((prev) => ({ ...prev, tenquyen: e.target.value }))}
            />
          </div>
          <div>
            <div className="font-semibold">Ghi chú:</div>
            <Input
              value={form?.ghichu}
              onChange={(e) => setform((prev) => ({ ...prev, ghichu: e.target.value }))}
            />
          </div>
          {infoEditQuyen?.idqht && (
            <div className="flex justify-start items-center gap-2">
              <div className="font-semibold">Sử dụng:</div>
              <Checkbox
                checked={form?.sudung}
                onChange={(e) => setform((prev) => ({ ...prev, sudung: e.target.checked ? 1 : 0 }))}
              />
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  )
}

export default ModalQuyen

import { Input, Modal } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rechargePaymentCard } from '../../../../store/actions/cardAction'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { numberToWords } from '../../../../utils/numberToWords'
const Recharge = ({ isModalOpen, handleCancel }) => {
  const { BNPaymentCard, infoPaymentCard } = useSelector((state) => state.cartReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const idBranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const dispatch = useDispatch()
  const handleSetNumber = (e) => {
    const { value } = e.target
    const newValue = parseFloat(value)
    if (newValue < 0) {
      formik.setFieldValue('tien', 0)
      return
    }
    formik.setFieldValue('tien', newValue)
  }
  const closeModal = () => {
    handleCancel()
    formik.handleReset()
  }

  const handleSubmit = (value) => {
    value.idthe = infoPaymentCard.idThe
    value.idbn = BNPaymentCard.idbn
    value.nguoinap = infoUser.tenNV
    value.chinhanhnap = idBranchLogin
    dispatch(
      rechargePaymentCard(
        {
          ...value,
          infoPaymentCard,
          BNPaymentCard,
          detailHistory: { ngaySuDung: value.ngaysudung },
        },
        closeModal,
      ),
    )
    handleCancel()
    formik.handleReset()
  }

  const formik = useFormik({
    initialValues: {
      idthe: 0,
      idbn: 0,
      tien: 0,
      nguoinap: '',
      chinhanhnap: 0,
      //   ngaysudung: "2024-05-20T07:22:26.851Z",
    },
    onSubmit: handleSubmit,
  })

  return (
    <Modal
      className="text-center"
      title="Nạp thẻ thanh toán"
      open={isModalOpen}
      cancelText="Huỷ"
      okText="Nạp & In"
      onOk={formik.handleSubmit}
      onCancel={handleCancel}
    >
      <form className="text-start flex flex-col gap-2">
        <div>
          <label className="font-semibold">Tên Bệnh nhân: </label>
          <Input disabled style={{ color: 'black' }} value={BNPaymentCard?.tenBenhNhan} />
        </div>
        <div>
          <label className="font-semibold">Mã bệnh nhân: </label>
          <Input style={{ color: 'black' }} value={BNPaymentCard?.maBenhNhan} disabled />
        </div>
        <div>
          <label className="font-semibold">Số tiền nạp: </label>
          <Input
            type="number"
            name="tien"
            onChange={handleSetNumber}
            value={formik.values.tien}
            style={{ color: 'black' }}
            min={0}
          />
        </div>
        <h2 className="text-lg font-semibold">
          Tiền Nạp: {formattedNumber(formik.values.tien)} VNĐ
        </h2>
        <i className="text-red-500">* {numberToWords(formik.values.tien)}</i>
      </form>
    </Modal>
  )
}

export default Recharge

import { https } from '../apiService'

export const chuyenKhoService = {
  getlistKhoNhan: (id) => https.get(`KhoChiNhanh/${id}`),
  postChuyenKho: (form) => https.post(`KhoNhapXuat/PhieuXuat`, form),
  postChuyenKhoCompany: (form) => https.post(`KhoNhapXuat/PhieuXuatCTY`, form),
  getChuyenKhoFilter: (filter) =>
    https.get('KhoNhapXuat/FindPhieuXuatByCondition', {
      params: {
        fromDate: filter.since,
        toDate: filter.toDate,
        idChiNhanh: filter.idChiNhanh,
        trangThai: filter.status,
      },
    }),
  getChuyenKhoCTYFilter: (filter) =>
    https.get('KhoNhapXuat/FindPhieuXuatCTYByCondition', {
      params: {
        fromDate: filter.since,
        toDate: filter.toDate,
        idCongTy: filter.idCongTy,
        trangThai: filter.status,
      },
    }),
  getTonKhoCN: (id) => https.get(`TonKho/${id}`),
  updateStatusCk: (form, idNhapXuat) => https.put(`KhoNhapXuat/${idNhapXuat}`, form),
  getPTAccess: (filter) =>
    https.get('KhoNhapXuat/FindPhieuXuatByIdKhoNhap', {
      params: {
        fromDate: filter.since,
        toDate: filter.toDate,
        idChiNhanh: filter.idChiNhanh,
        trangThai: filter.status,
        idCongTy: filter.idCongTy,
      },
    }),
  getPTAccessCTY: (filter) =>
    https.get('KhoNhapXuat/FindPhieuXuatByCTYNhap', {
      params: {
        fromDate: filter.since,
        toDate: filter.toDate,
        idChiNhanh: filter.idChiNhanh,
        trangThai: filter.status,
        idCongTy: filter.idCongTy,
        keyword: filter.keyword,
      },
    }),
  getPhieuChuyenKho: (filter) =>
    https.get('KhoNhapXuat/GetPhieuXuat', {
      params: {
        fromDate: filter.fromDate,
        toDate: filter.toDate,
        idCongTy: filter.idCongTy,
        idChiNhanh: filter.idChiNhanh,
      },
    }),
}

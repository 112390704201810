import { Checkbox, Input, Modal } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { updateLoiDanById } from '../../../store/actions/loiDanBsAction'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'

const UpdateLoiDan = ({ isUpdate, setIsUpdate, reloadData }) => {
  const dispatch = useDispatch()
  const { infoLoiDan } = useSelector((state) => state.LoiDanBsReducer)

  const maQuyenSuaLd = 'QHT441'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenSuaLd:
        formik.handleSubmit()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenSuaLd:
        //
        break
      default:
        return false
    }
  }

  const handleCancel = () => {
    setIsUpdate(false)
  }
  const reload = () => {
    reloadData()
    formik.resetForm()
    handleCancel()
  }
  const handleSubmit = (values) => {
    dispatch(updateLoiDanById(values, reload))
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idld: infoLoiDan?.idld,
      loiDan: infoLoiDan?.loiDan,
      ghiChu: infoLoiDan?.ghiChu,
      maLoiDan: infoLoiDan?.maLoiDan,
      suDung: infoLoiDan?.suDung,
    },
    validationSchema: Yup.object().shape({
      loiDan: Yup.string().required('Vui lòng nhập lời dặn !'),
      maLoiDan: Yup.string().required('Vui lòng nhập mã lời dặn !'),
    }),
    onSubmit: (values) => handleSubmit(values),
  })
  const onClickCheckBox = (e) => {
    const { checked } = e.target
    formik.setFieldValue('suDung', checked ? 1 : 0)
  }
  return (
    <Modal
      onCancel={handleCancel}
      title="CẬP NHẬP LỜI DẶN"
      open={isUpdate}
      okText="Cập nhật"
      cancelText="Huỷ bỏ"
      onOk={() =>
        dispatch(putCheckQuyenThaoTacAction(maQuyenSuaLd, null, actionIsTrue, actionIsFalse))
      }
    >
      <div className="flex flex-col gap-2">
        <div>
          <label>
            <span className="text-red-500">(*)</span> Mã lời dặn:
          </label>
          <Input
            status={formik.errors.maLoiDan ? 'error' : ''}
            value={formik.values.maLoiDan}
            name="maLoiDan"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.maLoiDan && formik.errors.maLoiDan && (
            <span className="text-red-500 ">{formik.errors.maLoiDan}</span>
          )}
        </div>
        <div>
          <label>
            <span className="text-red-500">(*)</span>Lời dặn:
          </label>
          <Input.TextArea
            status={formik.errors.loiDan ? 'error' : ''}
            onBlur={formik.handleBlur}
            value={formik.values.loiDan}
            name="loiDan"
            onChange={formik.handleChange}
            style={{
              height: 120,
              resize: 'none',
            }}
          />
          {formik.touched.loiDan && formik.errors.loiDan && (
            <span className="text-red-500 ">{formik.errors.loiDan}</span>
          )}
        </div>
        <div>
          <label>Ghi chú:</label>
          <Input value={formik.values.ghiChu} name="ghiChu" onChange={formik.handleChange} />
        </div>
        <Checkbox onChange={onClickCheckBox} checked={formik.values.suDung === 1}>
          Sử dụng
        </Checkbox>
      </div>
    </Modal>
  )
}

export default UpdateLoiDan

import * as typeAction from '../constants/constants'
export const fetListPartner_Action = () => ({
  type: typeAction.GET_LIST_PARTNER,
})
export const addPartner = (form, handleCloseModal, onLoad) => ({
  type: typeAction.ADD_PARTNER,
  form,
  handleCloseModal,
  onLoad,
})

export const editPartner = (form, handleCloseModal, onLoad) => ({
  type: typeAction.EDIT_PARTNER,
  form,
  handleCloseModal,
  onLoad,
})

export const deletePartner = (id, onLoad) => ({
  type: typeAction.DELETE_PARTNER,
  id,
  onLoad,
})

export const searchPartner = (keyword) => ({
  type: typeAction.SEARCH_PARTNER,
  keyword,
})

import { Input, Modal, Select } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { useDispatch, useSelector } from 'react-redux'
import { postBieuMau } from '../../../../store/actions/sieuAmAction'

import 'ckeditor5/ckeditor5.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import style from './bieuMau.module.css'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'
const BieuMau = ({ open, cancelModal, itemEdit }) => {
  const dispatch = useDispatch()
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [name, setName] = useState(null)
  const [tieuDe, setTieuDe] = useState(null)
  const [idLoaiBM, setIdLoaiBM] = useState(1)
  const [desc, setDesc] = useState(null)
  const { infoUser } = useSelector((state) => state.userReducer)
  const onEditorStateChange = (newState) => {
    setEditorState(newState)
  }
  const handleOk = async () => {
    const contentState = editorState.getCurrentContent()
    const row = convertToRaw(contentState)
    // const {blocks,...other} = row
    const data = JSON.stringify(row)
    if (itemEdit) {
      try {
        await https.put(`BieuMau/UpdateBieuMau`, {
          idbm: itemEdit?.idbm,
          tieude: tieuDe,
          tenbieumau: name,
          mota: data,
          nguoisua: infoUser?.idnv,
          ketluan: desc,
          idloaibieumau: idLoaiBM, // idloaibieumau = 1 là loại biểu mẫu siêu âm
        })
        reset()
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      }
    } else {
      dispatch(
        postBieuMau(
          {
            tieude: tieuDe,
            tenbieumau: name,
            mota: data,
            nguoitao: infoUser?.tenNV,
            ketluan: desc,
            idloaibieumau: idLoaiBM, // idloaibieumau = 1 là loại biểu mẫu siêu âm
          },
          reset,
        ),
      )
    }
  }
  const reset = () => {
    setEditorState(EditorState.createEmpty())
    setName(null)
    setTieuDe(null)
    setIdLoaiBM(1)
    setDesc(null)
    cancelModal()
  }
  useEffect(() => {
    if (itemEdit) {
      setName(itemEdit?.tenbieumau ?? null)
      setTieuDe(itemEdit?.tieude ?? null)
      setIdLoaiBM(itemEdit?.idloaibieumau ?? 1)
      setDesc(itemEdit?.ketluan ?? null)

      const convertJson = JSON.parse(itemEdit?.mota)
      const contentState = convertFromRaw(convertJson)
      setEditorState(EditorState.createWithContent(contentState))
    } else reset()
  }, [itemEdit])
  return (
    <Modal
      width={900}
      open={open}
      onCancel={cancelModal}
      okText={'Lưu'}
      onOk={handleOk}
      cancelText="Huỷ bỏ"
      title={<p className="text-center">{itemEdit ? 'Chỉnh sửa biểu mẫu' : 'Thêm biểu mẫu'}</p>}
      className="flex flex-col gap-2"
    >
      <form>
        <div>
          <div>
            <label className="font-semibold">Tên tiêu đề:</label>
            <Input value={tieuDe} onChange={(e) => setTieuDe(e.target.value)} />
          </div>
          <div className="flex items-center justify-between">
            <div className="w-1/2 pr-2">
              <label className="font-semibold">Tên biểu mẫu:</label>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="w-1/2 pl-2">
              <label className="font-semibold">Loại biểu mẫu:</label>
              <Select
                value={idLoaiBM}
                options={[
                  // {
                  //   key: 0,
                  //   value: 0,
                  //   label: 'Tất cả',
                  // },
                  {
                    key: 1,
                    value: 1,
                    label: 'Siêu âm',
                  },
                  {
                    key: 2,
                    value: 2,
                    label: 'Khám sức khỏe',
                  },
                  {
                    key: 3,
                    value: 3,
                    label: 'Nội soi',
                  },
                ]}
                onChange={setIdLoaiBM}
                className="w-full"
              />
            </div>
          </div>
          <div>
            <label className="font-semibold">Nội dung:</label>
            <Editor
              toolbar={{
                options: ['inline', 'blockType', 'fontFamily', 'textAlign', 'colorPicker'],
                inline: {
                  options: ['bold', 'italic'],
                },
                blockType: {
                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                },
                fontFamily: {
                  options: ['Times New Roman'],
                },
              }}
              editorState={editorState}
              toolbarClassName={style.toolbarClassName}
              wrapperClassName={style.wrapperClassName}
              editorClassName={style.editorClassName}
              onEditorStateChange={onEditorStateChange}
            />
          </div>
          <div>
            <label className="font-semibold">Kết luận:</label>
            <Input.TextArea value={desc} onChange={(e) => setDesc(e.target.value)} />
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default BieuMau

import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  listCombo: [],
  listCTGiamGia: [],
  nguonKH: null,
  phongKham: [],
  ngheNghiep: null,
  hinhThucTT: null,
  nguoiThan: null,
  danToc: null,
  doiTuong: null,
  tinhTP: null,
  quocTich: null,
  phuongXa: null,
  quanHuyen: null,
  groupsChiDinh: null,
  BNPending: null,
  ChiDinh: null,
  listBN: null,
  infoBN: null,
  paymentCard: null,
  memberCard: null,
  ArrXN: [],
  ArrKB: [],
  ArrCĐHA: [],
  discountArr: [],
  infoChuongTrinhGiamGiaDoiTac: null,
  UuDaiTaiKham: [],
}
const priceChiDinh = {
  ptGiam: 0,
  tienGiam: 0,
  thanhToan: 0,
  type: '', // Mục đích sét type để khi xoá danh sách chỉ đỉnh khám dựa vào type xoá đúng mảng chỉ định
}
const receiveReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_SET_CHUONGTRINH_GIAMGIA_DOITAC:
        draft.infoChuongTrinhGiamGiaDoiTac = payload
        break
      case typeAction.DISPATCH_SET_ARR_XN:
        draft.ArrXN = payload
        break
      case typeAction.DISPATCH_SET_ARR_KB:
        draft.ArrKB = payload
        break
      case typeAction.DISPATCH_SET_ARR_CDHA:
        draft.ArrCĐHA = payload
        break
      case typeAction.DISPATCH_UU_DAI_TAI_KHAM_TIEP_NHAN:
        draft.UuDaiTaiKham = payload
        break
      case typeAction.DISPATCH_LIST_COMBO_TIEPNHAN:
        draft.listCombo = payload
        break
      case typeAction.DISPATCH_LIST_CTGIAMGIA_TIEPNHAN:
        draft.listCTGiamGia = payload
        break
      case typeAction.DISPATCH_NGUON_KH:
        draft.nguonKH = payload
        break
      case typeAction.DISPATCH_BN_PENDING:
        draft.BNPending = payload
        break
      case typeAction.DISPATCH_PAYMENT_CARD_RECEIVE:
        draft.paymentCard = payload
        break
      case typeAction.DISPATCH_MEMBER_CARD_RECEIVE:
        draft.memberCard = payload
        break
      case typeAction.DISPATCH_PHONG_KHAM:
        draft.phongKham = payload
        break
      case typeAction.DISPATCH_NGHE_NGHIEP:
        draft.ngheNghiep = payload
        break
      case typeAction.DISPATCH_HINH_THUC_TT:
        draft.hinhThucTT = payload
        break
      case typeAction.DISPATCH_DAN_TOC:
        draft.danToc = payload
        break
      case typeAction.DISPATCH_DOI_TUONG:
        draft.doiTuong = payload
        break
      case typeAction.DISPATCH_TINH_TP:
        draft.tinhTP = payload
        break
      case typeAction.DISPATCH_QUOC_TICH:
        draft.quocTich = payload
        break
      case typeAction.DISPATCH_PHUONG_XA:
        draft.phuongXa = payload
        break
      case typeAction.DISPATCH_QUAN_HUYEN:
        draft.quanHuyen = payload
        break
      case typeAction.DISPATCH_GROUPS_CHI_DINH:
        draft.groupsChiDinh = payload
        break
      case typeAction.DISPATCH_CHI_DINH:
        draft.ChiDinh = payload
        break
      case typeAction.DISPATCH_LIST_BN:
        draft.listBN = payload
        break
      case typeAction.DISPATCH_INFO_BENH_NHAN:
        draft.infoBN = payload
        break
      case typeAction.DISTPATCH_LIST_NGUOI_THAN:
        draft.nguoiThan = payload
        break
      case typeAction.DISTPATCH_LIST_XN_RECEIVE:
        // Tìm index
        const indexXN = draft.ChiDinh.findIndex((items) => items.idChiDinh === payload)
        let newArrXN = {
          ...draft.ChiDinh[indexXN],
          ...priceChiDinh,
          thanhToan: draft.ChiDinh[indexXN].donGia,
          type: 'XN',
        }
        draft.ArrXN.push(newArrXN)
        break
      case typeAction.DISTPATCH_LIST_CDHA_RECEIVE:
        // Tìm index
        const indexCDHA = draft.ChiDinh.findIndex((items) => items.idChiDinh === payload)
        let newArrCDHA = {
          ...draft.ChiDinh[indexCDHA],
          ...priceChiDinh,
          thanhToan: draft.ChiDinh[indexCDHA].donGia,
          type: 'CDHA',
        }
        draft.ArrCĐHA.push(newArrCDHA)
        break
      case typeAction.DISTPATCH_LIST_KB_RECEIVE:
        // Tìm index
        const indexKB = draft.ChiDinh.findIndex((items) => items.idChiDinh === payload.id)
        const discount = draft.ChiDinh[indexKB].donGia * (payload.ptGiam/100)
        let newArr = {
          ...draft.ChiDinh[indexKB],
          ...priceChiDinh,
          tienGiam: discount,
          ptGiam: payload.ptGiam,
          thanhToan: draft.ChiDinh[indexKB].donGia - discount,
          type: 'KB',
          taikham: payload.taiKham,
        }
        draft.ArrKB.push(newArr)
        break
      case typeAction.DISTPATCH_LIST_KB_RECEIVE_APPLY_MEMBER_CARD:
        // Tìm index
        const indexDV = draft.ChiDinh.findIndex((items) => items.idChiDinh === payload.iddv)
        let tempArr = {
          ...draft.ChiDinh[indexDV],
          ...priceChiDinh,
          ptGiam: payload.ptgiamgia,
          tienGiam: payload.tiengiamgia,
          thanhToan: payload.tienthanhtoan,
          loaithedichvu: payload.ptgiamgia > 0 ? payload.loaithedichvu : null,
          //type: 'KB',
        }

        // Loại thẻ dịch vụ (idLoaiDV === loaithedichvu) - xài chung cho kb + xn + cdha (1 - dv khám bệnh, 2 - dv xét nghiệm, 3 - dv cdha)
        if (payload.loaithedichvu === 1) {
          draft.ArrKB.push({ ...tempArr, type: 'KB' })
        } else if (payload.loaithedichvu === 2) {
          draft.ArrXN.push({ ...tempArr, type: 'XN' })
        } else if (payload.loaithedichvu === 3) {
          draft.ArrCĐHA.push({ ...tempArr, type: 'CDHA' })
        }
        break
      case typeAction.DELETE_CHI_DINH_BY_TYPE:
        if (payload.type === 'KB') {
          const index = draft.ArrKB.findIndex((items) => items.idChiDinh === payload.id)
          draft.ArrKB.splice(index, 1)
        }
        if (payload.type === 'CDHA') {
          const index = draft.ArrCĐHA.findIndex((items) => items.idChiDinh === payload.id)
          draft.ArrCĐHA.splice(index, 1)
        }
        if (payload.type === 'XN') {
          const index = draft.ArrXN.findIndex((items) => items.idChiDinh === payload.id)
          draft.ArrXN.splice(index, 1)
        }
        break
      case typeAction.UPDATE_NEW_PRICE_CHI_DINH:
        draft.discountArr = payload
        break
      case typeAction.RESET_SOTER_CHI_CHI_RECEIVE:
        draft.ArrCĐHA = []
        draft.ArrKB = []
        draft.ArrXN = []
        break
      default:
        return state
    }
  })
}

export default receiveReducer

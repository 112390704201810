import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { ConfigProvider, Table, Button, Input, InputNumber, Divider, Tabs } from 'antd'
import { SaveOutlined, FileAddOutlined, DownloadOutlined, CloseOutlined } from '@ant-design/icons'
import importKhachHangFileExcel from '../../../../data/Form/importKhachHangFileExcel.xlsx'
import { cashierService } from '../../../../services/cashier/cashierService'
import { https } from '../../../../services/apiService'
import { formattedNumber } from '../../../../utils/formattedNumber'
import styled from 'styled-components'
import dayjs from 'dayjs'
import moment from 'moment'
import ToastCus from '../../../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')
const { Search } = Input
const BtnNhapExcel = styled(Button)`
  color: #fff;
  border-color: #1da57a;
  background-color: #1da57a;

  &:hover {
    color: #fff !important;
    border-color: #1da57acc !important;
    background-color: #1da57acc !important;
  }
`
const BtnFileMau = styled(Button)`
  color: #1da57a;
  border-color: #ccc;
  background-color: #fff;

  &:hover {
    color: #1da57a !important;
    border-color: #1da57a !important;
    background-color: #fff !important;
  }
`
function TabVoucher() {
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [listMaVoucher, setListMaVoucher] = useState([])
  const [form, setForm] = useState(null)
  const [loading, setLoading] = useState(false)
  const [traCuuVC, setTraCuuVC] = useState(null)
  const [infoVoucher, setInfoVoucher] = useState(null)

  const onSearch = async (value) => {
    try {
      setLoading(true)
      const { data: infoMaVC } = await https.get(`VoucherDanhSach/GetInfoMaVoucher`, {
        params: { mavoucher: value },
      })
      if (!infoMaVC?.idct) {
        setInfoVoucher(null)
        ToastCus.fire({
          icon: 'error',
          title: 'Không tìm thấy thông tin!',
        })
        return
      }
      const { data: listDV } = await https.get(`VoucherDichVu/GetVoucherDichVu/${infoMaVC?.idct}`)
      const { data: listTTV } = await https.get(
        `VoucherTheThanhVien/GetVoucherTheThanhVien/${infoMaVC?.idct}`,
      )
      const { data: listHH } = await https.get(`VoucherHangHoa/GetVoucherHangHoaChuongTrinh`, {
        params: { idchuongtrinh: infoMaVC?.idct },
      })
      const { data: listCN } = await https.get(`VoucherChiNhanh/GetVoucherChiNhanh`, {
        params: { idct: infoMaVC?.idct },
      })
      const { data: listDT } = await https.get(`VoucherDoiTuong/GetVoucherDoiTuong`, {
        params: { idct: infoMaVC?.idct },
      })
      const { data: listTG } = await https.get(`VoucherThoiGian/GetVoucherThoiGian`, {
        params: { idct: infoMaVC?.idct },
      })
      setInfoVoucher({
        ...infoMaVC,
        listDV: listDV,
        listTTV: listTTV,
        listHH: listHH,
        listCN: listCN,
        listDT: listDT,
        listTG: listTG,
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
      setInfoVoucher(null)
    } finally {
      setLoading(false)
    }
  }
  const handleSubmit = async () => {
    if (!form?.mavoucher || !form?.thoigianhieuluc) {
      setForm((prev) => ({
        mavoucher: prev?.mavoucher ?? null,
        thoigianhieuluc: prev?.thoigianhieuluc ?? null,
      }))
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    try {
      setLoading(true)
      await cashierService.putNgayHieuLucListVoucher([
        {
          mavoucher: form?.mavoucher,
          thoigianhieuluc: form?.thoigianhieuluc,
          nguoisua: infoUser?.idnv,
        },
      ])
      setForm(null)
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    } finally {
      setLoading(false)
    }
  }
  const handleDownloadFile = (e) => {
    e.preventDefault()
    const link = document.createElement('a')
    link.href = importKhachHangFileExcel
    link.target = '_blank'
    link.download = 'Mẫu-import-khách-hàng.xlsx'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const columns = [
    {
      title: 'STT ',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      render: (text, record, index) => ++index,
      fixed: 'left',
    },
    {
      title: 'Mã voucher',
      dataIndex: 'mavoucher',
      key: 'mavoucher',
      width: 150,
    },
    {
      title: 'Ngày hiệu lực',
      dataIndex: 'ngayhieuluc',
      key: 'ngayhieuluc',
      width: 100,
      align: 'center',
      render: (text, record, index) => moment().format('DD/MM/YYYY'),
    },
    {
      title: 'Số tháng',
      dataIndex: 'thoigianhieuluc',
      key: 'thoigianhieuluc',
      align: 'center',
      width: 70,
    },
    {
      title: 'Ngày sửa',
      dataIndex: '',
      key: '',
      width: 150,
      align: 'center',
      render: (text, record, index) => moment().format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Người sửa',
      dataIndex: 'nguoisua',
      key: 'nguoisua',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 50,

      render: (text, record, index) => (
        <div className="flex justify-center items-center w-full">
          <CloseOutlined style={{ fontSize: 16 }} className="cursor-pointer text-red-500" />
        </div>
      ),
    },
  ]
  return (
    <div className="flex justify-center items-start gap-2">
      <div className="w-4/5 border rounded-md p-2">
        <div className="flex justify-start items-center gap-2">
          <div className="text-lg font-semibold text-gray-600">Tra cứu mã voucher</div>
          <Search
            loading={loading}
            placeholder="VD: VC00001 ..."
            enterButton
            value={traCuuVC}
            onChange={(e) => setTraCuuVC(e.target.value?.trim())}
            onSearch={onSearch}
            className="w-60"
          />
        </div>
        {infoVoucher && (
          <>
            <div className="grid grid-flow-row gap-2">
              <Divider
                orientation="left"
                style={{
                  margin: '2rem 0 0.2rem',
                }}
              >
                <span>Thông tin mã voucher</span>
              </Divider>
              <div className="grid grid-cols-3 items-center gap-2">
                <div className=" grid grid-cols-3 items-center gap-1">
                  <div className="font-semibold text-end">Trạng thái:</div>
                  <Input
                    className="w-full col-span-2"
                    size="small"
                    variant="filled"
                    readOnly
                    value={infoVoucher?.trangthai}
                  />
                  {/* <div className="col-span-2">{infoVoucher?.trangthai}</div> */}
                </div>
                <div className="grid grid-cols-3 items-center gap-1">
                  <div className="font-semibold text-end">Người kích hoạt:</div>
                  <Input
                    className="w-full col-span-2"
                    size="small"
                    variant="filled"
                    readOnly
                    value={infoVoucher?.nguoikichhoat}
                  />
                  {/* <div className="col-span-2">{infoVoucher?.nguoikichhoat}</div> */}
                </div>
                <div className="grid grid-cols-3 items-center gap-1">
                  <div className="font-semibold text-end">Ngày kích hoạt:</div>
                  <Input
                    className="w-full col-span-2"
                    size="small"
                    variant="filled"
                    readOnly
                    value={
                      infoVoucher?.ngaykichhoat
                        ? moment(infoVoucher?.ngaykichhoat).format('DD/MM/YYYY HH:mm:ss')
                        : '-'
                    }
                  />
                  {/* <div className="col-span-2">
                    {infoVoucher?.ngaykichhoat
                      ? moment(infoVoucher?.ngaykichhoat).format('DD/MM/YYYY HH:mm:ss')
                      : '-'}
                  </div> */}
                </div>
              </div>
              <div className="grid grid-cols-3 items-center gap-2">
                <div className=" grid grid-cols-3 items-center gap-1">
                  <div className="font-semibold text-end">Ngày hiệu lực:</div>
                  <Input
                    className="w-full col-span-2"
                    size="small"
                    variant="filled"
                    readOnly
                    value={
                      infoVoucher?.ngayhieuluc
                        ? moment(infoVoucher?.ngayhieuluc).format('DD/MM/YYYY HH:mm:ss')
                        : '-'
                    }
                  />
                  {/* <div className="col-span-2">
                    {infoVoucher?.ngayhieuluc
                      ? moment(infoVoucher?.ngayhieuluc).format('DD/MM/YYYY HH:mm:ss')
                      : '-'}
                  </div> */}
                </div>
                <div className="grid grid-cols-3 items-center gap-1">
                  <div className="font-semibold text-end">Thời gian hiệu lực:</div>
                  <Input
                    className="w-full col-span-2"
                    size="small"
                    variant="filled"
                    readOnly
                    value={
                      infoVoucher?.thoigianhieuluc ? `${infoVoucher?.thoigianhieuluc} tháng` : '-'
                    }
                  />
                  {/* <div className="col-span-2">
                    {infoVoucher?.thoigianhieuluc ? `${infoVoucher?.thoigianhieuluc} tháng` : '-'}
                  </div> */}
                </div>
              </div>
            </div>
            <div className="grid grid-flow-row gap-2">
              <Divider
                orientation="left"
                style={{
                  margin: '1.5rem 0 0.2rem',
                }}
              >
                <span>Thông tin chương trình</span>
              </Divider>
              <div className="grid grid-cols-3 items-center gap-2">
                <div className="col-span-2 grid grid-cols-6 items-center gap-1">
                  <div className="font-semibold text-end">Tên chương trình:</div>
                  <Input
                    className="w-full col-span-5"
                    size="small"
                    variant="filled"
                    readOnly
                    value={infoVoucher?.tenct}
                  />
                  {/* <div className="col-span-5">{infoVoucher?.tenct}</div> */}
                </div>
                <div className="grid grid-cols-3 items-center gap-1">
                  <div className="font-semibold text-end">Mã chương trình:</div>
                  <Input
                    className="w-full col-span-2"
                    size="small"
                    variant="filled"
                    readOnly
                    value={infoVoucher?.mact}
                  />
                  {/* <div className="col-span-2">{infoVoucher?.mact}</div> */}
                </div>
              </div>
              <div className="grid grid-cols-3 items-center gap-2">
                <div className=" grid grid-cols-3 items-center gap-1">
                  <div className="font-semibold text-end">Ngày bắt đầu:</div>
                  <Input
                    className="w-full col-span-2"
                    size="small"
                    variant="filled"
                    readOnly
                    value={
                      infoVoucher?.batdau ? moment(infoVoucher?.batdau).format('DD/MM/YYYY') : '-'
                    }
                  />
                  {/* <div className="col-span-2">
                    {infoVoucher?.batdau ? moment(infoVoucher?.batdau).format('DD/MM/YYYY') : '-'}
                  </div> */}
                </div>
                <div className="grid grid-cols-3 items-center gap-1">
                  <div className="font-semibold text-end">Ngày kết thúc:</div>
                  <Input
                    className="w-full col-span-2"
                    size="small"
                    variant="filled"
                    readOnly
                    value={
                      infoVoucher?.ketthuc ? moment(infoVoucher?.ketthuc).format('DD/MM/YYYY') : '-'
                    }
                  />
                  {/* <div className="col-span-2">
                    {infoVoucher?.ketthuc ? moment(infoVoucher?.ketthuc).format('DD/MM/YYYY') : '-'}
                  </div> */}
                </div>
                <div className="grid grid-cols-3 items-center gap-1">
                  <div className="font-semibold text-end">Giá trị:</div>
                  <Input
                    className="w-full col-span-2"
                    size="small"
                    variant="filled"
                    readOnly
                    value={
                      infoVoucher?.giatrict
                        ? `${formattedNumber(infoVoucher?.giatrict ?? 0)} ${(infoVoucher?.loaigiatrict === 1 && 'VNĐ') || (infoVoucher?.loaigiatrict === 2 && '%')}`
                        : '-'
                    }
                  />
                  {/* <div className="col-span-2">
                    {infoVoucher?.giatrict
                      ? `${formattedNumber(infoVoucher?.giatrict ?? 0)} ${(infoVoucher?.loaigiatrict === 1 && 'VNĐ') || (infoVoucher?.loaigiatrict === 2 && '%')}`
                      : '-'}
                  </div> */}
                </div>
              </div>
              <Tabs
                className="mt-3"
                defaultActiveKey="1"
                items={[
                  infoVoucher?.listDV?.length && {
                    key: '1',
                    label: 'Dịch vụ',
                    children: (
                      <>
                        <ConfigProvider
                          theme={{
                            token: {
                              padding: 5,
                            },
                            components: {
                              Table: {
                                rowHoverBg: '#ecf0f1',
                                headerBg: '#e6e6e6',
                                footerBg: '#e6e6e6',
                                borderColor: '#BABABA',
                    
                              },
                            },
                          }}
                        >
                          <Table
                            bordered
                            dataSource={infoVoucher?.listDV}
                            //   pagination={{
                            //     pageSize: 20,
                            //     showSizeChanger: false,
                            //   }}
                            scroll={{ y: 440 }}
                            pagination={false}
                            columns={[
                              {
                                title: 'STT',
                                dataIndex: 'stt',
                                key: 'stt',
                                align: 'center',
                                width: 50,
                                render: (text, record, index) => ++index,
                              },
                              {
                                title: 'Mã dịch vụ',
                                dataIndex: 'madichvu',
                                key: 'madichvu',
                                width: 150,
                              },
                              {
                                title: 'Tên dịch vụ',
                                dataIndex: 'tendichvu',
                                key: 'tendichvu',
                              },
                              {
                                title: 'ĐVT',
                                dataIndex: 'dvt',
                                key: 'dvt',
                                align: 'center',
                                width: 60,
                              },
                              // {
                              //   title: 'Đơn giá',
                              //   dataIndex: 'mavoucher',
                              //   key: 'mavoucher',
                              //   align: 'right',
                              //   width: 150,
                              //   render: (text) => formattedNumber(text ?? 0),
                              // },
                            ]}
                          />
                        </ConfigProvider>
                      </>
                    ),
                  },
                  infoVoucher?.listTTV?.length && {
                    key: '2',
                    label: 'Thẻ thành viên',
                    children: (
                      <>
                        <ConfigProvider
                          theme={{
                            token: {
                              padding: 5,
                            },
                            components: {
                              Table: {
                                rowHoverBg: '#ecf0f1',
                                headerBg: '#e6e6e6',
                                footerBg: '#e6e6e6',
                                borderColor: '#BABABA',
                    
                              },
                            },
                          }}
                        >
                          <Table
                            bordered
                            dataSource={infoVoucher?.listTTV}
                            //   pagination={{
                            //     pageSize: 20,
                            //     showSizeChanger: false,
                            //   }}
                            scroll={{ y: 440 }}
                            pagination={false}
                            columns={[
                              {
                                title: 'STT',
                                dataIndex: 'stt',
                                key: 'stt',
                                align: 'center',
                                width: 50,
                                render: (text, record, index) => ++index,
                              },
                              {
                                title: 'Tên loại thẻ',
                                dataIndex: 'tenloaithe',
                                key: 'tenloaithe',
                              },
                              {
                                title: 'Chuyên khoa',
                                dataIndex: 'tenchuyenkhoa',
                                key: 'tenchuyenkhoa',
                                align: 'center',
                                width: 100,
                                render: (text, record, index) =>
                                  text?.replace('Chuyên khoa', '')?.trim(),
                              },
                              // {
                              //   title: 'Đơn giá',
                              //   dataIndex: 'mavoucher',
                              //   key: 'mavoucher',
                              //   align: 'right',
                              //   width: 150,
                              //   render: (text) => formattedNumber(text ?? 0),
                              // },
                            ]}
                          />
                        </ConfigProvider>
                      </>
                    ),
                  },
                  infoVoucher?.listHH?.length && {
                    key: '3',
                    label: 'Hàng hóa',
                    children: (
                      <>
                        <ConfigProvider
                          theme={{
                            token: {
                              padding: 5,
                            },
                            components: {
                              Table: {
                                rowHoverBg: '#ecf0f1',
                                headerBg: '#e6e6e6',
                                footerBg: '#e6e6e6',
                                borderColor: '#BABABA',
                    
                              },
                            },
                          }}
                        >
                          <Table
                            bordered
                            dataSource={infoVoucher?.listHH}
                            //   pagination={{
                            //     pageSize: 20,
                            //     showSizeChanger: false,
                            //   }}
                            scroll={{ y: 440 }}
                            pagination={false}
                            columns={[
                              {
                                title: 'STT',
                                dataIndex: 'stt',
                                key: 'stt',
                                align: 'center',
                                width: 50,
                                render: (text, record, index) => ++index,
                              },
                              {
                                title: 'Mã hàng',
                                dataIndex: 'mathuoc',
                                key: 'mathuoc',
                                width: 150,
                              },
                              {
                                title: 'Tên vật tư hàng hóa',
                                dataIndex: 'tenthuoc',
                                key: 'tenthuoc',
                              },
                              {
                                title: 'ĐVT',
                                dataIndex: 'dvt',
                                key: 'dvt',
                                align: 'center',
                                width: 60,
                              },
                              // {
                              //   title: 'Đơn giá',
                              //   dataIndex: 'mavoucher',
                              //   key: 'mavoucher',
                              //   align: 'right',
                              //   width: 150,
                              //   render: (text) => formattedNumber(text ?? 0),
                              // },
                            ]}
                          />
                        </ConfigProvider>
                      </>
                    ),
                  },
                  infoVoucher?.listCN?.length && {
                    key: '4',
                    label: 'Chi nhánh',
                    children: (
                      <>
                        <ConfigProvider
                          theme={{
                            token: {
                              padding: 5,
                            },
                            components: {
                              Table: {
                                rowHoverBg: '#ecf0f1',
                                headerBg: '#e6e6e6',
                                footerBg: '#e6e6e6',
                                borderColor: '#BABABA',
                    
                              },
                            },
                          }}
                        >
                          <Table
                            bordered
                            dataSource={infoVoucher?.listCN}
                            //   pagination={{
                            //     pageSize: 20,
                            //     showSizeChanger: false,
                            //   }}
                            scroll={{ y: 440 }}
                            pagination={false}
                            columns={[
                              {
                                title: 'STT',
                                dataIndex: 'stt',
                                key: 'stt',
                                align: 'center',
                                width: 50,
                                render: (text, record, index) => ++index,
                              },
                              {
                                title: 'Mã chi nhánh',
                                dataIndex: 'machinhanh',
                                key: 'machinhanh',
                                width: 150,
                              },
                              {
                                title: 'Tên chi nhánh',
                                dataIndex: 'tenchinhanh',
                                key: 'tenchinhanh',
                              },
                              {
                                title: 'Chuyên khoa',
                                dataIndex: 'tenchuyenkhoa',
                                key: 'tenchuyenkhoa',
                                align: 'center',
                                width: 100,
                                render: (text, record, index) =>
                                  text?.replace('Chuyên khoa', '')?.trim(),
                              },
                            ]}
                          />
                        </ConfigProvider>
                      </>
                    ),
                  },
                  infoVoucher?.listDT?.length && {
                    key: '5',
                    label: 'Đối tượng',
                    children: (
                      <>
                        <ConfigProvider
                          theme={{
                            token: {
                              padding: 5,
                            },
                            components: {
                              Table: {
                                rowHoverBg: '#ecf0f1',
                                headerBg: '#e6e6e6',
                                footerBg: '#e6e6e6',
                                borderColor: '#BABABA',
                    
                              },
                            },
                          }}
                        >
                          <Table
                            bordered
                            dataSource={infoVoucher?.listDT}
                            //   pagination={{
                            //     pageSize: 20,
                            //     showSizeChanger: false,
                            //   }}
                            scroll={{ y: 440 }}
                            pagination={false}
                            columns={[
                              {
                                title: 'STT',
                                dataIndex: 'stt',
                                key: 'stt',
                                align: 'center',
                                width: 50,
                                render: (text, record, index) => ++index,
                              },
                              {
                                title: 'Tên đối tượng',
                                dataIndex: 'tendoituong',
                                key: 'tendoituong',
                              },
                            ]}
                          />
                        </ConfigProvider>
                      </>
                    ),
                  },
                  infoVoucher?.listTG?.length && {
                    key: '6',
                    label: 'Khung giờ',
                    children: (
                      <>
                        <ConfigProvider
                          theme={{
                            token: {
                              padding: 5,
                            },
                            components: {
                              Table: {
                                rowHoverBg: '#ecf0f1',
                                headerBg: '#e6e6e6',
                                footerBg: '#e6e6e6',
                                borderColor: '#BABABA',
                    
                              },
                            },
                          }}
                        >
                          <Table
                            bordered
                            dataSource={infoVoucher?.listTG}
                            //   pagination={{
                            //     pageSize: 20,
                            //     showSizeChanger: false,
                            //   }}
                            scroll={{ y: 440 }}
                            pagination={false}
                            columns={[
                              {
                                title: 'STT',
                                dataIndex: 'stt',
                                key: 'stt',
                                align: 'center',
                                width: 50,
                                render: (text, record, index) => ++index,
                              },
                              {
                                title: 'Giờ bắt đầu',
                                dataIndex: 'tugio',
                                key: 'tugio',
                                align: 'center',
                              },
                              {
                                title: 'Giờ kết thúc',
                                dataIndex: 'dengio',
                                key: 'dengio',
                                align: 'center',
                              },
                            ]}
                          />
                        </ConfigProvider>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </>
        )}
      </div>
      <div className="w-1/5 border rounded-md p-2">
        <div className="flex justify-between items-center mb-2">
          <div className="text-lg font-semibold text-gray-600">Phát mã voucher</div>
          <Button
            icon={<SaveOutlined style={{ fontSize: 16 }} />}
            type="primary"
            loading={loading}
            onClick={handleSubmit}
          >
            Lưu
          </Button>
        </div>
        <div className="grid grid-flow-row gap-2">
          <div>
            <label className="text-end">Mã voucher:</label>
            <Input
              placeholder="VD: VC00001 ..."
              disabled={loading}
              className="col-span-2 w-full"
              status={form && !form?.mavoucher && 'error'}
              value={form?.mavoucher}
              onChange={(e) => setForm((prev) => ({ ...prev, mavoucher: e.target.value?.trim() }))}
            />
          </div>
          <div>
            <label className="text-end">Thời gian hiệu lực (tháng):</label>
            <InputNumber
              placeholder="VD: 6 ..."
              disabled={loading}
              className="col-span-2 w-full"
              status={form && !form?.thoigianhieuluc && 'error'}
              min={0}
              step={1}
              value={form?.thoigianhieuluc}
              onChange={(value) => setForm((prev) => ({ ...prev, thoigianhieuluc: value }))}
            />
          </div>
        </div>
      </div>
      {/* <BtnNhapExcel>
            <label className="flex items-center" for="file-upload">
              <FileAddOutlined style={{ fontSize: 16 }} />
              &nbsp;Nhập excel
            </label>
            <input
              id="file-upload"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              value={''}
              //   onChange={handleFileChange}
              type="file" ///////////////////////
              className="hidden" ///////////////////////
            />
          </BtnNhapExcel>
          <BtnFileMau
            onClick={handleDownloadFile}
            icon={<DownloadOutlined style={{ fontSize: 16 }} />}
          >
            File mẫu
          </BtnFileMau> */}
      {/* <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
            },
          },
        }}
      >
        <Table
          bordered
          dataSource={[
            {
              mavoucher: (
                <>
                  <Input size="small" />
                </>
              ),
              ngayhieuluc: (
                <>
                  <Input size="small" />
                </>
              ),
              thoigianhieuluc: (
                <>
                  <Input size="small" />
                </>
              ),
              nguoisua: (
                <>
                  <Input size="small" />
                </>
              ),
            },
            ...listMaVoucher,
          ]}
          //   pagination={{
          //     pageSize: 20,
          //     showSizeChanger: false,
          //   }}
          scroll={{ y: 700 }}
          pagination={false}
          columns={columns}
        />
      </ConfigProvider> */}
    </div>
  )
}

export default TabVoucher

import * as typeAction from '../constants/constants'
export const fetListPartner_Action = () => ({
  type: typeAction.GET_LIST_PARTNER,
})
export const addPartner = (form, handleCloseModal) => ({
  type: typeAction.ADD_PARTNER,
  form,
  handleCloseModal,
})

export const editPartner = (form, handleCloseModal) => ({
  type: typeAction.EDIT_PARTNER,
  form,
  handleCloseModal,
})

export const deletePartner = (id) => ({
  type: typeAction.DELETE_PARTNER,
  id,
})

export const searchPartner = (keyword) => ({
  type: typeAction.SEARCH_PARTNER,
  keyword,
})


import { https } from '../apiService'

export const goikhamCRMService = {
  getAllGoikhamCRM: () => https.get('CRMGoiKham/GetAll'),
  // addGoikhamCRM: (form) => https.post('CRMGoiKham/ADDGoiKham',form),
  addGoikhamCRM: (form) => https.post('CRMGoiKham/AddGoiKhamAndDichVu', form),
  editGoikhamCRM: (form) => https.put(`CRMGoiKham/UpdateGoiKhamAndDichVu?idGK=${form.goiKham.idgk}`, form),
  getGoikhamCRMById: (id) => https.get(`CRMGoiKham/GetByIDKHGK?IDGK=${id}`),
  getListDVByIdGK: (id) => https.get(`CRMDichVuKham/GetByIDGK?IDGK=${id}`),
  getListGoiKhamByIdKH: (id) => https.get(`CRMKhachHangGoiKham/GetByIDKH?IDKH=${id}`),
}

import { Input, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { cnhaSchema } from '../../../../schemas/cdhaSchema'
import { https } from '../../../../services/apiService'
import { edit_CDHA_Action } from '../../../../store/actions/CĐHAAction'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
const ModalEdit = ({ isModalOpenEdit, setIsModalOpenEdit, handleReloadData }) => {
  const dispatch = useDispatch()
  const [allCty, setAllCty] = useState([])
  const [allNhomCDHA, setAllNhomCDHA] = useState([])

  const maQuyenSua = 'QHT372'
  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenSua:
        formik.handleSubmit()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenSua:
        //
        break
      default:
        return false
    }
  }
  const handleSubmit = (values) => {
    dispatch(edit_CDHA_Action(values, handleReloadData))
    setIsModalOpenEdit(false)
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idChiDinh: isModalOpenEdit.data?.idChiDinh,
      macdha: isModalOpenEdit.data?.macdha,
      tenChiDinh: isModalOpenEdit.data?.tenChiDinh,
      ghichu: isModalOpenEdit.data?.ghichu,
      idnhomcdha: isModalOpenEdit.data?.idnhomcdha,
      donGia: isModalOpenEdit.data?.donGia,
      dvt: isModalOpenEdit.data?.dvt,
      idct: isModalOpenEdit.data?.idct,
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: cnhaSchema,
  })

  const getAllNhomCDHA = async () => {
    try {
      const { data } = await https.get('ChanDoanHinhAnhNhom/GetAllNhomChanDoanHinhAnh')
      setAllNhomCDHA(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAllNhomCDHA()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('CongTy')
        setAllCty(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  return (
    <>
      <Modal
        okText="Xác nhận"
        cancelText="Huỷ"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Thông tin chẩn đoán hình ảnh</h2>}
        onOk={() =>
          dispatch(putCheckQuyenThaoTacAction(maQuyenSua, null, actionIsTrue, actionIsFalse))
        }
        open={isModalOpenEdit.show}
        onCancel={() => setIsModalOpenEdit(false)}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold text-base">
              {' '}
              <span className="text-sx text-red-500">(*)</span>Mã chẩn đoán hình ảnh
            </label>
            <Input
              value={formik.values.macdha}
              name="macdha"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập mã chẩn đoán hình ảnh"
              status={formik.errors.macdha && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold text-base">
              {' '}
              <span className="text-sx text-red-500">(*)</span>Tên chẩn đoán hình ảnh
            </label>
            <Input
              value={formik.values.tenChiDinh}
              name="tenChiDinh"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập tên chẩn đoán hình ảnh"
              status={formik.errors.tenChiDinh && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold text-base">
              {' '}
              <span className="text-sx text-red-500">(*)</span>Đơn vị tính{' '}
            </label>
            <Input
              value={formik.values.dvt}
              name="dvt"
              onChangeCapture={formik.handleChange}
              placeholder="Nhập đơn vị tính"
              status={formik.errors.dvt && 'error'}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold text-base">Công ty </label>
            <Select
              name="idct"
              value={formik.values.idct}
              options={allCty?.map((items) => ({
                label: items.tenct,
                value: items.idct,
              }))}
              onChange={(value) => formik.setFieldValue('idct', value)}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold  text-base">Nhóm chẩn đoán hình ảnh</label>
            <Select
              name="idnhomcdha"
              value={formik.values.idnhomcdha}
              options={allNhomCDHA?.map((items) => ({
                label: items.tennhom,
                value: items.idnhomcdha,
              }))}
              onChange={(value) => formik.setFieldValue('idnhomcdha', value)}
            />
          </div>
          <div className="flex  flex-col">
            <label className="font-semibold text-base">Đơn giá </label>
            <Input
              name="donGia"
              value={formik.values.donGia}
              onChangeCapture={formik.handleChange}
              type="number"
              min={0}
              step={1}
              status={formik.errors.donGia && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Ghi chú </label>
            <Input
              value={formik.values.ghichu}
              onChangeCapture={formik.handleChange}
              name="ghichu"
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalEdit

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { DatePicker, Select, ConfigProvider, Table, Button, Typography, TreeSelect } from 'antd'
import IconButton from '@mui/material/IconButton'
import { useNavigate } from 'react-router-dom'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { https } from '../../../../../services/apiService'
import { reportService } from '../../../../../services/Report/reportService'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import { phanQuyenService } from '../../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../../utils/Toast'
import * as XLSX from 'xlsx'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { Text } = Typography
const DoanhThu = ({ ChuyenKhoa, isBlockView }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [data, setData] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [value, setValue] = useState([BranchLogin])
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const onLoad = () => {
    fetchReport()
  }
  //-------- export excel-----------//
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT486',
      )

      const worksheet = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
      XLSX.writeFile(wb, `DoanhThu.xlsx`)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  // ------------ lấy danh sách report theo chi nhánh ------------//
  const fetchReport = async () => {
    setIsLoading(true)
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT485',
      )

      let idChiNhanhs = null
      if (value?.length > 0 && value[0] !== 'all') {
        idChiNhanhs = value.map((id) => `&idChiNhanhs=${id}`).join('&')
        const { data } = await https.get(
          `ThongKeLuotKham/ThongKeLuotKhamDoanhThu?idCongTy=${company}&idChuyenKhoa=${idChuyenKhoa}&fromDate=${dateForm}&toDate=${dateTo}${idChiNhanhs}`,
        )
        setData(data)
      } else {
        const { data } = await https.get(
          `ThongKeLuotKham/ThongKeLuotKhamDoanhThu?idCongTy=${company}&idChuyenKhoa=${idChuyenKhoa}&fromDate=${dateForm}&toDate=${dateTo}`,
        )
        setData(data)
        setValue(['all'])
      }
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setIsLoading(false)
    }
  }
  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranchByChuyenKhoa('')
    fetchReport()
  }, [])

  const onChange = (newValue) => {
    setValue(newValue)
  }

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  return (
    <>
      <div className="flex gap-5 justify-between border-dotted border-b-0 py-2 ">
        <div className="flex gap-5">
          <div className={`w-80  ${isBlockView ? 'hidden' : ''}`}>
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranchByChuyenKhoa('')
                setIdChuyenKhoa('')
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className={`w-64 flex gap-1 ${isBlockView ? 'hidden' : ''}`}>
            <Select
              showSearch
              value={idChuyenKhoa}
              onChange={onChangeChuyenKhoa}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { label: 'Tất cả chuyên khoa', value: '' },
                ...ChuyenKhoa.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
              className="w-80"
            />
          </div>
          <div className={`w-64 flex gap-1 ${isBlockView ? 'hidden' : ''}`}>
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="flex gap-3">
            <IconButton onClick={onLoad} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
          <div className="font-semibold ">
            <Button
              disabled={!data?.length}
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>

      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              x: 1500,
              y: '68vh',
            }}
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={data}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 40,
                align: 'center',
                fixed: 'left',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                width: 150,
                fixed: 'left',
                render: (text) => <Text>{dayjs(text).format('DD-MM-YYYY')}</Text>,
              },
              {
                title: 'Tên chi nhánh',
                dataIndex: 'tenChiNhanh',
                key: 'tenChiNhanh',
                width: 150,
                fixed: 'left',
              },
              {
                title: 'Ngày khai trương',
                dataIndex: 'ngayKhaiTruong',
                key: 'ngayKhaiTruong',
                width: 150,
                fixed: 'left',
                render: (text) => <Text>{dayjs(text).format('DD-MM-YYYY')}</Text>,
              },
              {
                title: 'Lượt khách',
                dataIndex: 'luotKhach',
                key: 'luotKhach',
                width: 150,
                align: 'center',
              },

              {
                title: 'Tổng số tiền',
                dataIndex: 'tongSoTien',
                key: 'tongSoTien',
                width: 150,
                align: 'center',
                render: (text) => <Text>{formattedNumber(text)}</Text>,
              },
            ]}
            summary={(pageData) => {
              let totalLuotKhach = 0
              let totaltongSoTien = 0

              pageData.forEach(({ luotKhach, tongSoTien }) => {
                totaltongSoTien += tongSoTien
                totalLuotKhach += luotKhach
              })

              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      index={0}
                      colSpan={4}
                      align="center"
                      style={{ fontWeight: 'bold' }}
                    >
                      Tổng cộng:
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="center">
                      <span style={{ color: 'red' }}>{totalLuotKhach}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="center">
                      <span style={{ color: 'red' }}>{formattedNumber(totaltongSoTien)}</span>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
            sticky
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default DoanhThu

import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { monitorService } from '../../services/monitor/monitorService'
import { generateMonitorTimThai } from '../../utils/report/san/monitorTimThai'
import { phanQuyenService } from '../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../actions/phanQuyenAction'
import ToastCus from '../../utils/Toast'
import Swal from 'sweetalert2'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* monitorSaga() {
  yield takeLatest(typeAction.GET_INFO_BN_MONITOR, function* infoBNMonitor({ type, idbnttc }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })

      const infoDetail = yield call(() => monitorService.getDetaiMonitor(idbnttc))
      yield put({
        type: typeAction.DISPATCH_INFO_BN_MONITOR,
        payload: infoDetail.data,
      })
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    } catch (error) {
      console.log(error)
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  yield takeLatest(typeAction.GET_ALL_BN_CHO_MONITOR, function* getBNCho({ type, idcn }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const { infoUser } = yield select((state) => state.userReducer)
      const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
      yield call(() =>
        phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT326'),
      )

      const result = yield call(() => monitorService.getBNPendingMonitor(idcn))
      yield put({
        type: typeAction.DISPATCH_LIST_MONITOR,
        payload: result.data,
      })
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    } catch (error) {
      console.error('Error : ', error)
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  })

  yield takeLatest(typeAction.ADD_BN_MONITOR, function* addBNMonitor({ type, form, onLoad }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const { infoUser } = yield select((state) => state.userReducer)
      const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
      yield call(() =>
        phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT328'),
      )
      const dataPK = yield select((state) => state.branchReducer)
      const { PkDangNhap } = dataPK
      //get BN TTC
      const result = yield call(() => monitorService.AddBnMonitor(form))
      const bnttc = yield call(() => monitorService.getInfoBNTTC(result.data.idbnttc))
      yield call(() => generateMonitorTimThai(bnttc.data, result.data, PkDangNhap))
      //call lai ds benh nhan cho
      onLoad()
      Toast.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (error) {
      console.error('Error : ', error)
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
}

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker, Select, TreeSelect, Button, ConfigProvider, Table, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import SyncIcon from '@mui/icons-material/Sync'
import _, { debounce } from 'lodash'
import IconButton from '@mui/material/IconButton'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { reportService } from '../../../../services/Report/reportService'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import ToastCus from '../../../../utils/Toast'
import dayjs from 'dayjs'
import moment from 'moment'
import { formattedNumber } from '../../../../utils/formattedNumber'
const dateFormat = 'MM-YYYY'
const momentDate = 'YYYY-MM'
const now = moment().format(momentDate)
const Prodcuts = () => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [group, setGroup] = useState([])
  const [idNhom, setIdNhom] = useState('')
  const [type, setType] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [search, setSearch] = useState(null)
  const [data, setData] = useState([])
  const [dataExport, setDataExport] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState({
    idCompany: ID_COMPANY,
    date: now,
  })
  //------------ LẤY NHÓM THUỐC VẬT TƯ THEO ID LOẠI -------------//
  const fetchGroupsByIDType = async (id) => {
    try {
      const newData = id === -1 ? [] : (await khoVTservice.getListGroupsThuocVT(id)).data // nếu id = -1 =>  chọn tất cả trả về rỗng
      setIdNhom((prevState) => (id === -1 ? '' : prevState))
      setGroup(newData)
    } catch (error) {
      console.log(error)
    }
  }
  //---------------- LẤY DANH SÁCH LOẠI THUỐC VẬT TƯ -------------//
  const fetchTypesProducts = async () => {
    try {
      const { data } = await khoVTservice.getListTypesThuocVT()
      setType(data)
    } catch (error) {
      console.log(error)
    }
  }
  const loadMoreData = async (reset, search, page) => {
    if (totalPages < page) return // NẾU DỮ LIỆU CHỈ CÓ 1 PAGE THÌ DỪNG CALL API
    fetchData(reset, search, page)
    setPage((prevPage) => prevPage + 1)
  }
  const handleScroll = useCallback(
    debounce(() => {
      const tableBody = document.querySelector('.table-binhquan .ant-table-body')
      if (
        tableBody.scrollTop + tableBody.clientHeight >= tableBody.scrollHeight - 100 &&
        !isLoading
      ) {
        loadMoreData(false, search, page)
      }
    }, 300),
    [isLoading],
  )

  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT555',
      )
      const response = await reportService.getExportBinhQuanGiaQuyen(
        form.idCompany,
        form.date,
        idNhom,
        search,
      )
      const blob = new Blob([response.data], { type: 'application/octet-stream' })
      // Create a download link and click it programmatically
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Danh sách hàng hoá bình quân gia quyền.xlsx')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  useEffect(() => {
    const tableBody = document.querySelector('.table-binhquan .ant-table-body')
    tableBody.addEventListener('scroll', handleScroll)
    return () => tableBody.removeEventListener('scroll', handleScroll)
  }, [handleScroll])
  const fetchData = async (reset, search, page) => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT554',
      )
      const { data } = await reportService.getBinhQuanGiaQuyen(
        form.idCompany,
        form.date,
        idNhom,
        page,
        search,
      )
      setTotalPages(data?.totalPages === 0 ? 1 : data.totalPages)
      setData((preState) => {
        if (reset) {
          return data.data || {}
        } else {
          return [...(preState || {}), ...(data?.data || {})]
        }
      })
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setIsLoading(false)
    }
  }
  const debounceDropDown = useCallback(
    _.debounce(async (e) => {
      loadMoreData(true, e.target.value, 1)
    }, 300),
    [],
  )
  useEffect(() => {
    dispatch(listAllCompanyAction())
    loadMoreData(true, search, 1)
    fetchTypesProducts()
  }, [])
  return (
    <div className="p-3 bg-[#EFEFEF] h-full">
      <div className="bg-white h-full p-2 rounded-xl border">
        <h2 className="font-semibold text-lg mb-2">
          Báo Cáo Hàng Hóa: Bình Quân Gia Quyền Theo Tháng
        </h2>
        <div className="flex gap-5 justify-between border-dotted border-b-0">
          <div className="flex flex-wrap gap-5 items-center mb-2">
            <div className="w-80">
              <Select
                showSearch
                value={form?.idCompany}
                onChange={(value) => {
                  setForm((prevState) => ({
                    ...prevState,
                    idCompany: value,
                  }))
                }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={listCompany?.map(({ idct, tenct }) => ({
                  label: tenct,
                  value: idct,
                }))}
                className="w-full"
              />
            </div>
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-3">
                <DatePicker
                  picker="month"
                  allowClear={false}
                  defaultValue={dayjs(form?.date)}
                  maxDate={dayjs(now)}
                  onChange={(date, dateString) => {
                    const value = moment(dateString, dateFormat).format(momentDate)
                    setForm((prevState) => ({ ...prevState, date: value }))
                  }}
                  format={dateFormat}
                />
              </div>
              <div className="w-60 gap-1 items-center flex">
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={fetchGroupsByIDType}
                  placeholder="Loại Thuốc Vật tư"
                  className="w-full"
                  options={[
                    { label: 'Tất cả', value: -1 },
                    ...type?.map((items) => ({
                      label: items.phanLoai,
                      value: items.idPhanLoai,
                    })),
                  ]}
                />
              </div>
              <div className="w-60 gap-1 items-center flex">
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  value={idNhom}
                  onChange={setIdNhom}
                  placeholder="Nhóm Thuốc Vật tư"
                  className="w-full"
                  options={[
                    { label: 'Tất cả', value: '' },
                    ...group?.map((items) => ({
                      label: items.nhom,
                      value: items.idNhom,
                    })),
                  ]}
                />
              </div>
              <div>
                <Input
                  placeholder="Tìm kiếm"
                  onChange={(e) => {
                    setSearch(e.target.value)
                    debounceDropDown(e)
                  }}
                  prefix={<SearchOutlined />}
                />
              </div>
              <div className="flex gap-3">
                <IconButton
                  onClick={() => loadMoreData(true, search, 1)}
                  color="primary"
                  aria-label="delete"
                  size="small"
                >
                  <SyncIcon />
                </IconButton>
              </div>
              <div className="font-semibold ">
                <Button
                  // disabled={!data?.length}
                  onClick={exportToExcel}
                  type="text"
                  size="middle"
                  className="text-green-700 p-1"
                  icon={<ImportExportIcon />}
                >
                  Xuất Excel
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#EFEFEF',
                  footerBg: '#EFEFEF',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <Table
              className="w-full table-binhquan"
              loading={isLoading}
              bordered
              scroll={{ y: 670, x: 1000 }}
              dataSource={data}
              pagination={false}
              columns={[
                {
                  title: 'STT',
                  key: 'stt',
                  render: (text, record, index) => ++index,
                  align: 'center',
                  width: 60,
                },
                {
                  title: 'Tháng',
                  key: 'thang',
                  dataIndex: 'thang',
                  render: (text) => moment(text).format('MM/YYYY'),
                  width: 100,
                },
                {
                  title: 'Mã hàng',
                  key: 'mahang',
                  dataIndex: 'mahang',
                  width: 100,
                },
                {
                  title: 'Tên hàng hoá',
                  key: 'tenhang',
                  dataIndex: 'tenhang',
                },
                {
                  title: 'Đơn vị tính',
                  key: 'donvitinh',
                  dataIndex: 'donvitinh',
                  width: 100,
                },
                {
                  title: 'Đóng gói',
                  key: 'quycachdonggoi',
                  dataIndex: 'quycachdonggoi',
                  width: 100,
                },
                {
                  title: 'Số lượng',
                  key: 'soluong',
                  dataIndex: 'soluong',
                  render: (text) => formattedNumber(text),
                },
                {
                  title: 'Đơn giá bình quân',
                  key: 'dongiabinhquan',
                  dataIndex: 'dongiabinhquan',
                  render: (text) => formattedNumber(text),
                },
                {
                  title: 'Tổng giá trị',
                  key: 'tonggiatri',
                  dataIndex: 'tonggiatri',
                  render: (text) => formattedNumber(text),
                },
              ]}
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  )
}

export default Prodcuts

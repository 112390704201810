import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  branch: null,
  listKhoNhap: null,
  defaultKhoNhap: null,
  listDoiTac: null,
  infoDoiTac: null,
  branchNhapByCT: [],
  thuocVT: null,
  infoThuocVT: [],
  listPhieuNhap: null,
  infoPTNhap: null,
  isLoading: false,
  ListThuocVT_update: [],
  BranchPT: [],
  listVPP: [],
}

const NhapKhoReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_BRANCH_NHAPKHO:
        draft.branch = payload
        break
      case typeAction.DISPATCH_LIST_KHONHAP:
        draft.listKhoNhap = payload
        break
      case typeAction.DISPATCH_BRANCH_PT_NHAP_KHO:
        draft.BranchPT = payload
        break
      case typeAction.DISPATCH_BRANCH_BY_COMPANY:
        draft.branchNhapByCT = payload
        break
      case typeAction.DISPATCH_DEFAULT_KHONHAP:
        draft.defaultKhoNhap = payload
        break
      case typeAction.DISPATCH_LIST_DOITAC:
        draft.listDoiTac = payload
        break
      case typeAction.DISPATCH_INFO_DOITAC:
        draft.infoDoiTac = payload
        break
      case typeAction.DISPATCH_RESET_INFO_DOITAC:
        draft.infoDoiTac = null
        break
      case typeAction.DISPATCH_ALL_THUOCVT:
        draft.thuocVT = payload
        break
      case typeAction.DISPATCH_LIST_INFO_THUOCVT:
        const { newItems, setSelectedItem } = payload
        // Tính toán index bắt đầu cho các phần tử mới
        const startIndex =
          draft.infoThuocVT.length > 0 ? draft.infoThuocVT[draft.infoThuocVT.length - 1].id + 1 : 1
        // Gán ID cho newItem
        newItems.id = startIndex
        setSelectedItem(startIndex)
        draft.infoThuocVT = [...draft.infoThuocVT, newItems]
        break
      case typeAction.CHANGE_MSDK_NHAP_KHO:
        draft.infoThuocVT[payload.index].khoChiTiet.maSoDangKy = payload.value // thêm info thuốc và vật tư vào mảng
        break
      case typeAction.CHANGE_NONG_DO_NHAP_KHO:
        draft.infoThuocVT[payload.index].khoChiTiet.nhietDo = payload.value // thêm info thuốc và vật tư vào mảng
        break
      case typeAction.DISPATCH_GHI_CHU_NHAP_KHO:
        draft.infoThuocVT[payload.index].khoChiTiet.ghiChu = payload.value // thêm info thuốc và vật tư vào mảng
        break
      case typeAction.DISPATCH_CHANGE_PRICE_THUC_TRA:
        draft.infoThuocVT[payload.index].khoChiTiet.thucTra = payload.value // thêm info thuốc và vật tư vào mảng
        break
        case typeAction.DISPATCH_CHANGE_PRICE_CK:
        draft.infoThuocVT[payload.index].khoChiTiet.ckTruocVat = payload.value // thêm info thuốc và vật tư vào mảng
        break
      case typeAction.DELETE_INFO_THUOCVT_BY_ID:
        // tìm ID thuốc trong mãng và xoá nó ra khỏi mãng
        for (let i = 0; i < draft.infoThuocVT.length; i++) {
          if (draft.infoThuocVT[i].id === payload) {
            draft.infoThuocVT.splice(i, 1)
            return
          }
        }
        break
      case typeAction.DISPATCH_ONCHANGE_COUNT_LE_NHAP_KHO:
        var khoSL = draft.infoThuocVT[payload.index].khoChiTiet
        khoSL.countLe = payload.value
        // tổng tiền
        var total = (khoSL.soLuongDongGoi * khoSL.soLuong + payload.value) * khoSL.donGiaMua
        // tổng tiền chiết khấu trước VAT
        var totalCKVAT = total - (total * khoSL.ptckTruocVat) / 100
        // tổng sau tiền VAT
        var totalVAT = totalCKVAT + khoSL.ptVAT * totalCKVAT
        // tinh toán
        khoSL.ckTruocVat = total - totalCKVAT
        khoSL.tongTienTruocVAT = totalCKVAT
        khoSL.thanhTien = totalVAT
        khoSL.thucTra = totalVAT
        khoSL.tienVAT = totalVAT - totalCKVAT
        if (khoSL.ptVAT === 0.08) {
          khoSL.vat8 = totalVAT - totalCKVAT
        } else if (khoSL.ptVAT === 0.05) {
          khoSL.vat5 = totalVAT - totalCKVAT
        } else if (khoSL.ptVAT === 0.1) {
          khoSL.vat10 = totalVAT - totalCKVAT
        }
        break
      case typeAction.EDIT_INFO_SL_THUOCVT_BY_ID:
        var khoSL = draft.infoThuocVT[payload.index].khoChiTiet
        khoSL.soLuong = payload.value
        // tổng tiền
        var total = (khoSL.soLuongDongGoi * payload.value + khoSL.countLe) * khoSL.donGiaMua
        // tổng tiền chiết khấu trước VAT
        var totalCKVAT = total - (total * khoSL.ptckTruocVat) / 100
        // tổng sau tiền VAT
        var totalVAT = totalCKVAT + khoSL.ptVAT * totalCKVAT
        // tinh toán
        khoSL.ckTruocVat = total - totalCKVAT
        khoSL.tongTienTruocVAT = totalCKVAT
        khoSL.thanhTien = totalVAT
        khoSL.thucTra = totalVAT
        khoSL.tienVAT = totalVAT - totalCKVAT
        if (khoSL.ptVAT === 0.08) {
          khoSL.vat8 = totalVAT - totalCKVAT
        } else if (khoSL.ptVAT === 0.05) {
          khoSL.vat5 = totalVAT - totalCKVAT
        } else if (khoSL.ptVAT === 0.1) {
          khoSL.vat10 = totalVAT - totalCKVAT
        }
        break
      case typeAction.EDIT_PHI_VAN_CHUYEN_BY_ID:
        for (let i of draft.infoThuocVT) {
          if (i.id === payload.id) {
            i.khoChiTiet.phiVanChuyen = payload.value
          }
        }
        break
      case typeAction.EDIT_PHI_GIA_CONG_BY_ID:
        for (let i of draft.infoThuocVT) {
          if (i.id === payload.id) {
            i.khoChiTiet.phiGiaCong = payload.value
          }
        }
        break
      case typeAction.EDIT_TIEN_CK_VAT_BY_ID:
        for (let i of draft.infoThuocVT) {
          if (i.id === payload.id) {
            i.khoChiTiet.ckTruocVat = payload.value
            i.khoChiTiet.ptckTruocVat =
              (payload.value * 100) /
              (i.khoChiTiet.soLuong * i.khoChiTiet.soLuongDongGoi * i.khoChiTiet.donGiaMua)
          }
        }
        break
      case typeAction.DISPATCH_GIA_VAT_NHAP_KHO:
        let itemHang = draft.infoThuocVT[payload.index].khoChiTiet
        if (itemHang.ptVAT === 0.05) {
          itemHang.vat5 = payload.value
        } else if (itemHang.ptVAT === 0.08) {
          itemHang.vat8 = payload.value
        }
        if (itemHang.ptVAT === 0.1) {
          itemHang.vat10 = payload.value
        }
        itemHang.tienVAT = payload.value
        itemHang.thanhTien = itemHang.tongTienTruocVAT + payload.value
        itemHang.thucTra = itemHang.tongTienTruocVAT + payload.value

        break
      case typeAction.DISPACTH_TIEN_CHIEC_KHAU_NHAP_KHO:
        let i = draft.infoThuocVT[payload.index].khoChiTiet
        let tong_1 = i.donGiaMua * (i.soLuong * i.soLuongDongGoi + i.countLe)
        i.ckTruocVat = payload.value
        i.ptckTruocVat = ((tong_1 - i.tongTienTruocVAT) / tong_1) * 100
        break
      case typeAction.DISPATCH_GIA_TRUOC_VAT_NHAP_KHO:
        var e = draft.infoThuocVT[payload.index].khoChiTiet
        e.tongTienTruocVAT = payload.value
        var tong = payload.value / (1 - e.ptckTruocVat / 100)
        e.ckTruocVat = (tong * e.ptckTruocVat) / 100
        e.donGiaMua = tong / (e.soLuong * e.soLuongDongGoi + e.countLe)
        e.tienVAT = e.ptVAT * payload.value
        e.thanhTien = payload.value + e.ptVAT * payload.value
        e.thucTra = payload.value + e.ptVAT * payload.value
        if (e.ptVAT === 0.08) {
          e.vat8 = e.ptVAT * payload.value
        } else if (e.ptVAT === 0.05) {
          e.vat5 = e.ptVAT * payload.value
        } else if (e.ptVAT === 0.1) {
          e.vat10 = e.ptVAT * payload.value
        }
        break
      case typeAction.EDIT_PT_CK_VAT_BY_ID:
        const index = draft.infoThuocVT.findIndex((item) => item.id === payload.id)
        const item = draft.infoThuocVT[index].khoChiTiet
        // tổng tiền
        const totalPrice = (item.soLuong * item.soLuongDongGoi + item.countLe) * item.donGiaMua
        //tổng tiền trước chiết khấu
        var totalCK = totalPrice - totalPrice * (payload.value / 100)
        item.ptckTruocVat = payload.value
        item.ckTruocVat = totalPrice - totalCK
        item.tongTienTruocVAT = totalCK
        // tổng tiền sau vat
        var thanhtien = item.ptVAT * totalCK + totalCK
        item.thanhTien = thanhtien
        item.thucTra = thanhtien
        item.tienVAT = thanhtien - totalCK
        if (item.ptVAT === 0.08) {
          item.vat8 = thanhtien - totalCK
        } else if (item.ptVAT === 0.05) {
          item.vat5 = thanhtien - totalCK
        } else if (item.ptVAT === 0.1) {
          item.vat10 = thanhtien - totalCK
        }
        break
      case typeAction.DISPATCH_VAT_THUOCVT:
        for (let i of draft.infoThuocVT) {
          const items = i.khoChiTiet
          if (i.id === payload.id) {
            if (payload.value === 0.05) {
              items.vat5 = Number(
                (
                  ((items.soLuong * items.soLuongDongGoi + items.countLe) * items.donGiaMua -
                    items.ckTruocVat) *
                  0.05
                ).toFixed(0),
              )
              items.tienVAT = items.vat5
              items.ptVAT = 0.05
              items.vat8 = 0
              items.vat10 = 0
              items.thanhTien =
                (items.soLuong * items.soLuongDongGoi + items.countLe) * items.donGiaMua +
                items.tienVAT -
                items.ckTruocVat
              //THỰC TRẢ
              items.thucTra =
                (items.soLuong * items.soLuongDongGoi + items.countLe) * items.donGiaMua +
                items.tienVAT -
                items.ckTruocVat
            }
            if (payload.value === 0.08) {
              items.vat8 = Number(
                (
                  ((items.soLuong * items.soLuongDongGoi + items.countLe) * items.donGiaMua -
                    items.ckTruocVat) *
                  0.08
                ).toFixed(0),
              )
              items.tienVAT = items.vat8
              items.ptVAT = 0.08
              items.vat5 = 0
              items.vat10 = 0
              items.thanhTien =
                (items.soLuong * items.soLuongDongGoi + items.countLe) * items.donGiaMua +
                items.tienVAT -
                items.ckTruocVat
              //THỰC TRẢ
              items.thucTra =
                (items.soLuong * items.soLuongDongGoi + items.countLe) * items.donGiaMua +
                items.tienVAT -
                items.ckTruocVat
            }
            if (payload.value === 0.1) {
              items.vat10 = Number(
                (
                  ((items.soLuong * items.soLuongDongGoi + items.countLe) * items.donGiaMua -
                    items.ckTruocVat) *
                  0.1
                ).toFixed(0),
              )
              items.tienVAT = items.vat10
              items.vat5 = 0
              items.vat8 = 0
              items.ptVAT = 0.1
              items.thanhTien =
                (items.soLuong * items.soLuongDongGoi + items.countLe) * items.donGiaMua +
                items.tienVAT -
                items.ckTruocVat
              //THỰC TRẢ
              items.thucTra =
                (items.soLuong * items.soLuongDongGoi + items.countLe) * items.donGiaMua +
                items.tienVAT -
                items.ckTruocVat
            } else if (payload.value === 0) {
              items.vat10 = 0
              items.tienVAT = 0
              items.vat5 = 0
              items.vat8 = 0
              items.ptVAT = 0
              items.thanhTien =
                (items.soLuong * items.soLuongDongGoi + items.countLe) * items.donGiaMua +
                items.tienVAT -
                items.ckTruocVat
              //THỰC TRẢ
              items.thucTra =
                (items.soLuong * items.soLuongDongGoi + items.countLe) * items.donGiaMua +
                items.tienVAT -
                items.ckTruocVat
            }
          }
        }

        break
      case typeAction.DISPATCH_SOLO_THUOCVT:
        for (let i of draft.infoThuocVT) {
          if (i.id === payload.id) {
            i.khoChiTiet.soLo = payload.value
          }
        }
        break
      case typeAction.CHANGE_PRICE_THUOC_VAT_TU:
        var items = draft.infoThuocVT[payload.index]
        items.GIAMUA = payload.value
        items.khoChiTiet.donGiaMua = payload.value
        var total =
          payload.value * (items.khoChiTiet.soLuong * items.QUYCACHDONGGOI + items.countLe)
        var priceCKvat = total * (items.khoChiTiet.ptckTruocVat / 100)
        var priceVAT = (total - priceCKvat) * items.khoChiTiet.ptVAT
        items.khoChiTiet.ckTruocVat = priceCKvat
        items.khoChiTiet.tienVAT = priceVAT
        items.khoChiTiet.thanhTien = total - priceCKvat + priceVAT
        items.khoChiTiet.thucTra = total - priceCKvat + priceVAT
        // Define a small tolerance for comparison
        const TOLERANCE = 1e-6

        if (Math.abs(items.khoChiTiet.ptVAT - 0.05) < TOLERANCE) {
          items.khoChiTiet.vat5 = priceVAT
          items.khoChiTiet.vat8 = 0
          items.khoChiTiet.vat10 = 0
        } else if (Math.abs(items.khoChiTiet.ptVAT - 0.08) < TOLERANCE) {
          items.khoChiTiet.vat8 = priceVAT
          items.khoChiTiet.vat5 = 0
          items.khoChiTiet.vat10 = 0
        } else if (Math.abs(items.khoChiTiet.ptVAT - 0.1) < TOLERANCE) {
          items.khoChiTiet.vat10 = priceVAT
          items.khoChiTiet.vat8 = 0
          items.khoChiTiet.vat5 = 0
        } else if (Math.abs(items.khoChiTiet.ptVAT - 0) < TOLERANCE) {
          items.khoChiTiet.vat10 = 0
          items.khoChiTiet.vat8 = 0
          items.khoChiTiet.vat5 = 0
        }
        break
      case typeAction.DISPATCH_HANDUNG_THUOCVT:
        for (let i of draft.infoThuocVT) {
          if (i.id === payload.id) {
            i.khoChiTiet.hanDung = payload.date
          }
        }
        break
      case typeAction.RESET_INFO_THUOVT:
        draft.infoThuocVT = []
        break
      case typeAction.DISPATCH_LIST_PHIEU_NHAP:
        draft.listPhieuNhap = payload
        break
      case typeAction.DISPATCH_INFO_PT_NHAP_KHO:
        draft.infoPTNhap = payload
        break
      case typeAction.OPEN_LOADING_TABLE_NHAP_KHO:
        draft.isLoading = true
        break
      case typeAction.CLOSE_LOADING_TABLE_NHAP_KHO:
        draft.isLoading = false
        break
      case typeAction.DISPATCH_INFO_THUOVT_NHAP_KHO:
        draft.ListThuocVT_update = payload
        break
      case typeAction.DISPATCH_LIST_VPP_NHAPKHO:
        draft.listVPP = payload
        break
      default:
        return state
    }
  })
}

export default NhapKhoReducer

import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import * as typeAction from '../../../../store/constants/constants'
import { Select, DatePicker, Input, Button } from 'antd'
import { useSelector } from 'react-redux'
import CachedIcon from '@mui/icons-material/Cached'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import TableList from './TableList'
import { useDispatch } from 'react-redux'
import { branchNhapKhoPT, filterPTNhapKhoAction } from '../../../../store/actions/NhapKhoAction'
import ModalDetail from '../Modal/ModalDetail'
import moment from 'moment'
import dayjs from 'dayjs'
import { listBranchAction } from '../../../../store/actions/BranchAction'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import 'moment/locale/vi'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
moment.locale('vi')

const ListKho = ({ disablePrice }) => {
  const now = new Date()
  const dateFormat = 'DD-MM-YYYY'
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const dispatch = useDispatch()
  const [searchValues, setSearchValues] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { BranchPT, listPhieuNhap } = useSelector((state) => state.NhapKhoReducer)
  const [valueExport, setValueExport] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [since, setSince] = useState(now)
  const [toDate, SetToDate] = useState(now)
  const [company, setCompany] = useState(id_company)
  const [idChiNhanh, setIdChiNhanh] = useState(Number(BranchLogin))
  const [show, setShow] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setShow(false)
    setIsModalOpen(false)
    // tra về kho chi iết ban đầu khi cancel
    dispatch({
      type: typeAction.DISPATCH_INFO_THUOVT_NHAP_KHO,
      payload: [],
    })
    dispatch({
      type: typeAction.DISPATCH_INFO_PT_NHAP_KHO,
      payload: null,
    })
  }
  // set ngày lại từ ngày
  const handleSince = (name) => (date, dateString) => {
    if (name === 'since') {
      setSince(dateString)
    } else {
      SetToDate(dateString)
    }
  }
  const handleCompany = (value) => {
    setCompany(value)
    dispatch(branchNhapKhoPT(value))
    setIdChiNhanh(null)
  }
  const filter = {
    since: moment(since, dateFormat).format('YYYY-MM-DD'),
    toDate: moment(toDate, dateFormat).format('YYYY-MM-DD'),
    idChiNhanh,
    idCongTy: company,
  }
  // xử lí chọn chi nhánh
  const handleSelected = (value) => {
    setIdChiNhanh(value)
  }
  // xử lí lọc dưc liệu
  const handleFilter = () => {
    dispatch(filterPTNhapKhoAction(filter))
  }
  const filteredData = (
    data, // lọc giá trị search
  ) =>
    data?.filter((item) =>
      Object.entries(searchValues).every(([key, value]) =>
        item[key]?.toLowerCase().includes(value.toLowerCase()),
      ),
    )
  //-------- export excel-----------//
  const exportToExcel = () => {
    const formattedData = filteredData(valueExport)?.map((item) => ({
      'Mã phiếu': item.maPhieu || '',
      'Tên phiếu': item.tenPhieu || '',
      'Người nhập': item.tenNVNhan || '',
      'Ngày nhập': item.ngayNhan ? moment(item.ngayNhan).format('DD/MM/YYYY') : '',
      'Chi nhánh': item.tenChiNhanhNhan || '',
      'Kho nhập': item.tenKhoNhap || '',
      'Số hoá đơn': item.soHoaDon || '',
      'Ngày hoá đơn': item.ngayHoaDon ? moment(item.ngayHoaDon).format('DD/MM/YYYY') : '',
      'Nhà cung cấp': item.tenDoiTac || '',
      'Tổng thực trả': formatNumberVND(Math.round(item.sumThucTra)),
    }))
    const name = 'Danh sách nhâp kho'
    exportExcelformat(formattedData, name)
  }

  useEffect(() => {
    dispatch(branchNhapKhoPT(id_company))
    dispatch(filterPTNhapKhoAction(filter))
    dispatch(listBranchAction())
  }, [])

  return (
    <>
      <div className="flex justify-between mb-5">
        <div className="flex gap-5 items-center">
          <div className="w-80 ">
            <Select
              onChange={handleCompany}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              value={company}
              className="w-full"
            />
          </div>
          <div className="w-80 ">
            <Select
              onChange={handleSelected}
              value={idChiNhanh}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { label: 'Tất cả', value: null },
                ...(BranchPT ?? []).map(({ idChiNhanh, tenChiNhanh }) => ({
                  label: tenChiNhanh,
                  value: idChiNhanh,
                })),
              ]}
              className="w-full"
            />
          </div>
          <div className="flex gap-3 items-center">
            <div>
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleSince('since')}
                format={dateFormat}
              />
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
            </svg>
            <div>
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                minDate={dayjs(since, dateFormat)}
                onChange={handleSince('toDate')}
                format={dateFormat}
              />
            </div>
          </div>
          <div className="font-semibold ">
            <Button
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
          <div
            className="cursor-pointer rounded-sm text-blue-500  hover:bg-slate-200"
            onClick={handleFilter}
          >
            <CachedIcon sx={{ fontSize: 25 }} />
          </div>
        </div>
      </div>
      <div className=" h-full">
        <TableList
          disablePrice={disablePrice}
          searchValues={searchValues}
          setSearchValues={setSearchValues}
          setValueExport={setValueExport}
          filteredData={filteredData}
          filter={filter}
          showModal={showModal}
        />
      </div>
      <ModalDetail
        disablePrice={disablePrice}
        handleCancel={handleCancel}
        show={show}
        setShow={setShow}
        isModalOpen={isModalOpen}
      />
    </>
  )
}

export default ListKho

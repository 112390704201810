import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import {
  DatePicker,
  Select,
  ConfigProvider,
  Table,
  Breadcrumb,
  Pagination,
  Button,
  Typography,
  TreeSelect,
} from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import * as XLSX from 'xlsx'
import { FormOutlined, HomeOutlined, AuditOutlined } from '@ant-design/icons'
import IconButton from '@mui/material/IconButton'
import { Link, useNavigate } from 'react-router-dom'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { https } from '../../../../../services/apiService'
import { reportService } from '../../../../../services/Report/reportService'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { Text } = Typography
const { SHOW_PARENT } = TreeSelect

const NguonKhach = ({ ChuyenKhoa }) => {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [data, setData] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [value, setValue] = useState([BranchLogin])
  const dispatch = useDispatch()
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const onLoad = () => {
    fetchReport()
  }
  //-------- export excel-----------//
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, 'Nguồn khách.xlsx')
  }
  // ------------ lấy danh sách report theo chi nhánh ------------//
  const fetchReport = async () => {
    try {
      setIsLoading(true)
      let idChiNhanhs = null
      if (value?.length > 0 && value[0] !== 'all') {
        idChiNhanhs = value.map((id) => `&idChiNhanhs=${id}`).join('&')
        const { data } = await https.get(
          `ThongKeLuotKham/TheoDoiNguonKhach?idCongTy=${company}&idChuyenKhoa=${idChuyenKhoa}&fromDate=${dateForm}&toDate=${dateTo}${idChiNhanhs}`,
        )
        setData(data)
      } else {
        const { data } = await https.get(
          `ThongKeLuotKham/TheoDoiNguonKhach?idCongTy=${company}&idChuyenKhoa=${idChuyenKhoa}&fromDate=${dateForm}&toDate=${dateTo}`,
        )
        setData(data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      key: '0-1',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
        key: `0-1-${index}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranchByChuyenKhoa('')
    fetchReport()
  }, [])

  const calculateTotals = (data) => {
    const totals = {
      mabn: 'Tổng cộng',
      tenbn: data.length,
      diNgang:  data.reduce((acc, curr) => acc + curr.diNgang, 0),
      anneHill: data.reduce((acc, curr) => acc + curr.anneHill, 0),
      diNgang: data.reduce((acc, curr) => acc + curr.diNgang, 0),
      facebook: data.reduce((acc, curr) => acc + curr.facebook, 0),
      helloBacSi: data.reduce((acc, curr) => acc + curr.helloBacSi, 0),
      hotline: data.reduce((acc, curr) => acc + curr.hotline, 0),
      nguoiQuen: data.reduce((acc, curr) => acc + curr.nguoiQuen, 0),
      ganNha: data.reduce((acc, curr) => acc + curr.ganNha, 0),
      nhanVien: data.reduce((acc, curr) => acc + curr.nhanVien, 0),
      app315: data.reduce((acc, curr) => acc + curr.app315, 0),
      website: data.reduce((acc, curr) => acc + curr.website, 0),
      youMed: data.reduce((acc, curr) => acc + curr.youMed, 0),
      total: data.reduce((acc, curr) => acc + curr.total, 0),
    }

    return totals
  }

  return (
    <>
      <div className="flex gap-5 justify-between border-dotted border-b-0 py-2 ">
        <div className="flex gap-5">
          <div className="w-80">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranchByChuyenKhoa('')
                setIdChuyenKhoa('')
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-64 flex gap-1">
            <Select
              showSearch
              value={idChuyenKhoa}
              onChange={onChangeChuyenKhoa}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { label: 'Tất cả', value: '' },
                ...ChuyenKhoa.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
              className="w-80"
            />
          </div>
          <div className="w-72 flex gap-1">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="flex gap-3">
            <IconButton onClick={onLoad} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
          <div className="font-semibold ">
            <Button
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>

      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              x: 1500,
              y: '66vh',
            }}
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={data}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 40,
                align: 'center',
                fixed: 'left',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Chi nhánh',
                dataIndex: 'tenChiNhanh',
                key: 'tenChiNhanh',
                fixed: 'left',
                width: 150,
              },

              {
                title: 'Anne Hill Khám sức khỏe',
                dataIndex: 'anneHill',
                key: 'anneHill',
                width: 60,

                align: 'center',
              },
              {
                title: 'Đi ngang',
                key: 'diNgang',
                dataIndex: 'diNgang',
                width: 60,
                align: 'center',
              },
              {
                title: 'Facebook',
                dataIndex: 'facebook',
                key: 'facebook',
                width: 60,
                align: 'center',
              },
              {
                title: 'Hello Bác sĩ',
                dataIndex: 'helloBacSi',
                key: 'helloBacSi',
                width: 60,
                align: 'center',
              },
              {
                title: 'Hotline: 0901.315.315',
                dataIndex: 'hotline',
                key: 'hotline',
                width: 60,
                align: 'center',
              },
              {
                title: 'Người quen',
                dataIndex: 'nguoiQuen',
                key: 'nguoiQuen',
                width: 60,
                align: 'center',
              },
              {
                title: 'Nhà gần',
                dataIndex: 'ganNha',
                key: 'ganNha',
                width: 60,
                align: 'center',
              },
              {
                title: 'Nhân viên',
                dataIndex: 'nhanVien',
                key: 'nhanVien',
                width: 60,
                align: 'center',
              },
              {
                title: 'Nhi đồng 315',
                dataIndex: 'app315',
                key: 'app315',
                width: 60,
                align: 'center',
              },
              {
                title: 'Website',
                dataIndex: 'website',
                key: 'website',
                width: 60,
                align: 'center',
              },
              {
                title: 'YouMed',
                dataIndex: 'youMed',
                key: 'youMed',
                width: 60,
                align: 'center',
              },
              {
                title: 'Tổng cộng',
                dataIndex: 'total',
                key: 'total',
                width: 60,
                align: 'center',
              },
            ]}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} align="center"></Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="center"></Table.Summary.Cell>

                  <Table.Summary.Cell index={2} align="center">
                    {calculateTotals(data).anneHill}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    {calculateTotals(data).diNgang}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="center">
                    {calculateTotals(data).facebook}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="center">
                    {calculateTotals(data).helloBacSi}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="center">
                    {calculateTotals(data).hotline}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="center">
                    {calculateTotals(data).nguoiQuen}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align="center">
                    {calculateTotals(data).ganNha}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align="center">
                    {calculateTotals(data).nhanVien}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10} align="center">
                    {calculateTotals(data).app315}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11} align="center">
                    {calculateTotals(data).website}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={12} align="center">
                    {calculateTotals(data).youMed}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={13} align="center">
                    {calculateTotals(data).total}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default NguonKhach

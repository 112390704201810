import { Button, Input } from 'antd'
import React, { useEffect, useState, useCallback } from 'react'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import _ from 'lodash'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import ModalCreateSuCo from './CreateSuCo/ModalCreateSuCo'
import TableBaoCao from './TableBaoCao'
import { useDispatch, useSelector } from 'react-redux'
import { getDanhSachBaoHong, getNhanVienSuCoByVung } from '../../../../store/actions/suCoAction'
import DetailBaoHong from '../DetailBaoHong'
import moment from 'moment'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
const Todo = ({setOpen,open}) => {
  const IDCT = Number(localStorage.getItem('id_company'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const { DanhSachBaoHong } = useSelector((state) => state.suCoReducer)
  const [openAdd, setOpenAdd] = useState(false)
  const [keyWord, setKeyWord] = useState('')
  const [valueExport,setValueExport] = useState([])
  const dispatch = useDispatch()
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      dispatch(getDanhSachBaoHong(keyword, BranchLogin))
    }, 300),
    [],
  )
  const fetchData = () => {
    dispatch(getDanhSachBaoHong(keyWord, BranchLogin,))
  }
  const exportToExcel = () => {
    const formattedData = valueExport?.map((item) => ({
      'Chi nhánh':item.CHINHANHBAOHONG,
      'Tên tiêu đề':item.TENBAOHONG,
      'Người tạo':item.NGUOIBAOHONG,
      'Ngày tạo':item.NGAYBAOHONG ? moment(item.NGAYBAOHONG).format('YYYY/MM/DD HH:mm:ss') : '',
      'Đơn vị': item.DONVINHAN,
      'Người thực hiện':item.DanhSachNhanVien.length !== 0 ? item.DanhSachNhanVien.map(nv => nv.tennhanvien).join(', ') : '',
      'Mức độ':item.MUCDOSUCO,
      'Trạng thái':item.TRANGTHAIPHIEU,
      'Người nhận':item.NGUOINHAN,
      'Ngày nhận':item.NGAYNHAN ? moment(item.NGAYNHAN).format('YYYY/MM/DD HH:mm:ss') : '',

    }))
    const name = 'Danh sách sự cố'
    exportExcelformat(formattedData,name)
  }
  useEffect(() => {
    fetchData()
    dispatch(getNhanVienSuCoByVung())
  }, [])
  useEffect(() => {
    setValueExport(DanhSachBaoHong)
  },[DanhSachBaoHong])
  return (
    <>
      <div>
        <div className="flex justify-between mb-2">
          <div className="flex gap-2">
            <Input.Search
              value={keyWord}
              className="w-72"
              placeholder="Nhập tìm kiếm"
              onChange={(e) => {
                setKeyWord(e.target.value)
                debounceDropDown(e.target.value)
              }}
            />
            <IconButton onClick={fetchData} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
            <Button
                onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>

          <Button onClick={() => setOpenAdd(true)} type="primary">
            Tạo sự cố
          </Button>
        </div>
        <TableBaoCao fetchData={fetchData}  setOpen={setOpen} setValueExport={setValueExport} />
      </div>
      <ModalCreateSuCo openAdd={openAdd} setOpenAdd={setOpenAdd}  fetchData={fetchData} />
      <DetailBaoHong setOpen={setOpen} open={open} fetchData={fetchData} />
    </>
  )
}

export default Todo

import { produce } from 'immer'
import * as typeAction from '../constants/constants'
import _, { cloneDeep } from 'lodash'
const initialState = {
  infoBN: null,
  DetailBN: null,
  infoTTV: null,
  pending: null,
  loidan: null,
  chanDoan: null,
  ThuocVT: [],
  listTonDuoc: null,
  ThuocBN: [],
  historyKham: [],
  ListBNDangKham: null,
  ListBNDaKham: null,
  DetailToaThuocBN: null,
  NgayThuoc: 2,
  TTV: null,
}
const dvtKhongNhanSL = ['lọ', 'chai', 'hủ', 'tuýp', 'tube']
const childrenClinicReducer = (state = initialState, { type, payload }) => {
  const formatLieuDung = (lieudung) => {
    let text = ('' + lieudung)?.replace(/[^\d,.\/]/g, '')?.replaceAll(',', '.') //?.replaceAll(' ', '')
    if (text?.includes('/')) {
      const arr = text?.split('/')
      if (arr?.length === 2 && arr[1]?.length) return Number(arr[0]) / Number(arr[1])
    }
    return _.isNumber(+text) && !_.isNaN(+text) ? +text : 0
  }
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE_CLINIC:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_BN_CHO_KHAM:
        draft.pending = payload
        break
      case typeAction.DISPATCH_HISTORY_KHAM_PK:
        draft.historyKham = payload
        break
      case typeAction.TTV_BENH_NHAN_NHI:
        draft.TTV = payload
        break
      case typeAction.DISPATCH_INFO_BN_KHAM:
        draft.infoBN = payload
        break
      case typeAction.DISPATCH_DETAIL_BN_KHAM:
        draft.DetailBN = payload
        break
      case typeAction.DISPATCH_DETAIL_TOATHUOC_BN_KHAM:
        draft.DetailToaThuocBN = payload
        break
      case typeAction.DISPATCH_LOI_DAN:
        draft.loidan = payload
        break
      case typeAction.DISPATCH_CHAN_DOAN:
        draft.chanDoan = payload
        break
      case typeAction.DISPATCH_THUOC_KE_TOA_DOCTOR:
        if (payload?.ThuocVT && payload?.listTonDuoc) {
          draft.ThuocVT = payload.ThuocVT
          draft.listTonDuoc = payload.listTonDuoc
        } else {
          draft.ThuocVT = []
          draft.listTonDuoc = null
        }
        break
      case typeAction.DISPATCH_THUOC_BN_DOCTOR:
        draft.ThuocBN.push(payload)
        break
      case typeAction.DISPATCH_SL_THUOC_BN:
        draft.ThuocBN[payload.index].soLuong = payload.value
        break
      case typeAction.DISPATCH_LIEU_DUNG:
        draft.ThuocBN[payload.index].lieuDung = payload.value
        const dvt1 = draft.ThuocBN[payload.index].dvt
        if (
          payload?.ketoa && // phân biệt giữa kê toa thuốc, và kê vắc xin
          dvt1?.length &&
          !dvtKhongNhanSL?.includes(dvt1?.replaceAll(' ', '')?.toLowerCase())
        ) {
          //nếu đơn vị tính của thuốc hiện tại, không có trong mảng dvtKhongNhanSL, thì tiến hành nhân số lượng ,còn nếu có thì không nhân
          const slnew = Math.ceil(
            draft.NgayThuoc *
              draft.ThuocBN[payload.index].soLanNgay *
              formatLieuDung(payload.value),
          )
          const ton = draft.ThuocBN[payload.index].ton
          draft.ThuocBN[payload.index].soLuong = slnew > ton ? 0 : slnew
        }
        break
      case typeAction.DISPATCH_LAN_DUNG:
        draft.ThuocBN[payload.index].soLanNgay = payload.value
        const dvt2 = draft.ThuocBN[payload.index].dvt
        if (dvt2?.length && !dvtKhongNhanSL?.includes(dvt2?.replaceAll(' ', '')?.toLowerCase())) {
          //nếu đơn vị tính của thuốc hiện tại, không có trong mảng dvtKhongNhanSL, thì tiến hành nhân số lượng ,còn nếu có thì không nhân
          const slnew = Math.ceil(
            draft.NgayThuoc * payload.value * formatLieuDung(draft.ThuocBN[payload.index].lieuDung),
          )
          const ton = draft.ThuocBN[payload.index].ton
          draft.ThuocBN[payload.index].soLuong = slnew > ton ? 0 : slnew
        }
        break
      case typeAction.DISPATCH_MUI_TOA_THUOC_BN:
        draft.ThuocBN[payload.index].muitiem = payload.value ////// kham sang loc
        break
      case typeAction.DISPATCH_GHI_CHU_TOA_THUOC_BN:
        draft.ThuocBN[payload.index].ghiChu = payload.value
        break
      case typeAction.DISPATCH_NGAYTAIKHAM_TOA_THUOC_BN:
        draft.ThuocBN[payload.index].taikhamtiemchung = payload.value
        break
      case typeAction.DISPATCH_NGAYHENTIEM_TOA_THUOC_BN:
        draft.ThuocBN[payload.index].ngayhentiem = payload.value
        break
      case typeAction.RESET_TOA_THUOC_BN_DOCTOR:
        draft.ThuocBN = payload
        break
      case typeAction.DELETE_THUOC_BN:
        const index = draft.ThuocBN.findIndex((items) => items.ID === payload)
        draft.ThuocBN.splice(index, 1)
        break
      case typeAction.DISPATCH_BN_DANG_KHAM:
        draft.ListBNDangKham = payload
        break
      case typeAction.DISPATCH_BN_DA_KHAM:
        draft.ListBNDaKham = payload
        break
      case typeAction.DISPATCH_MEMBER_CARD_KHAM:
        draft.infoTTV = payload
        break
      case typeAction.NGAY_THUOC_NHI:
        draft.NgayThuoc = payload
        break
      case typeAction.DISPATCH_SO_LAN_THUOC_NHI:
        draft.NgayThuoc = payload
        for (let item of draft.ThuocBN) {
          if (
            item.dvt?.length &&
            !dvtKhongNhanSL?.includes(item.dvt?.replaceAll(' ', '')?.toLowerCase())
          ) {
            const total = Math.ceil(payload * item.soLanNgay * formatLieuDung(item.lieuDung))
            item.soLuong = total > item.ton ? 0 : total
          }
        }
        break
      case 'UPDATE_DETAIL_BN':
        return {
          ...state,
          DetailBN: {
            ...state.DetailBN,
            ...payload,
          },
        }
      case typeAction.RESET_BS_KHAM_CAPNHAT:
        draft.infoBN = null
        draft.DetailBN = null
        draft.infoTTV = null
        draft.ThuocBN = []
        draft.historyKham = []
        draft.DetailToaThuocBN = null
        draft.NgayThuoc = 2
        draft.TTV = null
        break
      default:
        return state
    }
  })
}

export default childrenClinicReducer

import moment from 'moment'

import 'moment/locale/vi'
moment.locale('vi')
export function calculateAge(dateString) {
  // Chuyển đổi chuỗi ngày sinh thành đối tượng moment
  const birthDate = dateString ?  moment(dateString, 'YYYY-MM-DD') : moment()
  // Lấy ngày hiện tại
  const today = moment()
  // Tính toán tuổi
  const ageYears = today.diff(birthDate, 'years')
  birthDate.add(ageYears, 'years')
  const ageMonths = today.diff(birthDate, 'months')
  birthDate.add(ageMonths, 'months')
  const ageDays = today.diff(birthDate, 'days')
  const dateText = ageYears + ` tuổi, ` + ageMonths + ` tháng, ` + ageDays + ` ngày`
  return dateText
}

export function calculateAgeForReport(dateString) {
  // Chuyển đổi chuỗi ngày sinh thành đối tượng moment
  const birthDate = moment(dateString, 'YYYY-MM-DD')
  // Lấy ngày hiện tại
  const today = moment()
  // Tính toán tuổi
  const ageYears = today.diff(birthDate, 'years')
  birthDate.add(ageYears, 'years')
  const ageMonths = today.diff(birthDate, 'months')
  birthDate.add(ageMonths, 'months')
  const ageDays = today.diff(birthDate, 'days')
  if (ageYears < 6) {
    const newAgeMonths = ageMonths + ageYears * 12
    return newAgeMonths + ` tháng, ` + ageDays + ` ngày`
  }
  const dateText = ageYears + ` tuổi, ` + ageMonths + ` tháng`
  return dateText
}

import { Input, InputNumber, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { https } from '../../../../services/apiService'
import { addKhachHangCRM } from '../../../../store/actions/khachhangCRMAction'
import ToastCus from '../../../../utils/Toast'
const dateFormat = 'DD-MM-YYYY'

const ModalAdd = ({ open, setOpen, onLoad }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const [allCongTy, setAllCongTy] = useState([])

  // // xử lí form thêm dịch vụ
  const handleSubmit = (value) => {
    if (!value.idct) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn công ty',
      })
      return
    }
    dispatch(addKhachHangCRM(value, onLoad))
    formik.resetForm()
    setOpen(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      doanhthu: 0,
      giamgia: 0,
      chietkhau: 0,
      idct: '',
      nguoitao: infoUser.idnv,
      tennguoitao: infoUser.tenNV,
      ghichu: '',
      idttlh: '',
    },
    onSubmit: (value) => handleSubmit(value),
  })

  const ChangeSelected = (value, option) => {
    formik.setFieldValue('idct', value)
    formik.setFieldValue('idttlh', option.idttlh)
  }

  useEffect(() => {
    const getAllCongty = async () => {
      const { data } = await https.get('CRMThongTinLienHe/GetAllCRMThongTinLienHeForSelectOptions')
      setAllCongTy(data)
    }
    getAllCongty()
  }, [])

  return (
    <>
      <Modal
        okText="Thêm"
        cancelText="Huỷ"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Tạo Khách hàng</h2>}
        onOk={formik.handleSubmit}
        open={open}
        onCancel={() => setOpen(false)}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">Công ty</label>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              defaultValue={formik.values.idct}
              options={allCongTy?.map((item) => ({
                label: item.tencongty,
                value: item.idct,
                ...item,
              }))}
              className="w-full"
              onChange={ChangeSelected}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Doanh thu</label>
            <InputNumber
              onChange={(value) => formik.setFieldValue('doanhthu', value)}
              className="w-full"
              min={0}
              step={1}
              value={formik.values.doanhthu}
              name="doanhthu"
              status={formik.errors.doanhthu && 'error'}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              max={999999999}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Giảm giá</label>
            <InputNumber
              className="w-full"
              min={0}
              step={1}
              value={formik.values.giamgia}
              name="giamgia"
              onChange={(value) => formik.setFieldValue('giamgia', value)}
              status={formik.errors.giamgia && 'error'}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              max={999999999}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Chiết khấu</label>
            <InputNumber
              className="w-full"
              min={0}
              step={1}
              value={formik.values.chietkhau}
              name="chietkhau"
              onChange={(value) => formik.setFieldValue('chietkhau', value)}
              status={formik.errors.chietkhau && 'error'}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              max={999999999}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Ghi chú</label>
            <Input
              value={formik.values.ghichu}
              name="ghichu"
              onChangeCapture={formik.handleChange}
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalAdd

import {
  CloseOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  GoldOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import { ConfigProvider, DatePicker, Input, InputNumber, Select, Table, Tabs } from 'antd'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import ToastCus from '../../../../../utils/Toast'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { companyService } from '../../../../../services/Company/companyService'
import { branchService } from '../../../../../services/branch/branchService'
import { chuyenKhoService } from '../../../../../services/ChuyenKho/chuyenKhoService'
import { https } from '../../../../../services/apiService'
import { formatNumberVND } from '../../../../../utils/formatNumberVND'
import { useFormik } from 'formik'
import { postKhoDieuChinhAction } from '../../../../../store/actions/khodieuchinhAction'
import { putCheckQuyenThaoTacAction } from '../../../../../store/actions/phanQuyenAction'
import dayjs from 'dayjs'
const dateFormat = 'DD/MM/YYYY'
const formatDateDB = 'YYYY-MM-DD'
export default function LapPhieuXuat() {
  const [selectedFile, setSelectedFile] = useState([])
  const fileInputRef = useRef(null)
  const dropzoneRef = useRef(null)
  const id_company = Number(localStorage.getItem('id_company'))
  const id_branch = Number(localStorage.getItem('BRANH_LOGIN'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [congTy, setCongTy] = useState(id_company)
  const [listCongTy, setListCongTy] = useState([])
  const [branch, setBranch] = useState(id_branch)
  const [listBranch, setListBranch] = useState([])
  const [khoDieuChinh, setKhoDieuChinh] = useState(null)
  const [listKho, setListKho] = useState([])
  const [isLoadingTon, setIsLoadingTon] = useState(false)
  const [listTonVTHH, setListTonVTHH] = useState([])
  const [listXuatVTHH, setListXuatVTHH] = useState([])
  const chonKhoVPP = khoDieuChinh?.makho?.toUpperCase()?.includes('VPP')
  const [boPhanSD, setBoPhanSD] = useState(infoUser?.idNhom)
  const dispatch = useDispatch()
  const now = useMemo(() => moment(), [])

  const maQuyenThemPhieu = 'QHT629'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenThemPhieu:
        formik.setFieldValue('clickedButton', 'save')
        formik.handleSubmit()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenThemPhieu:
        //
        break
      default:
        return false
    }
  }
  const deleteFileById = (id) => {
    const updatedData = selectedFile.filter((item) => item.id !== id)
    setSelectedFile(updatedData)
  }
  const handleDivClick = () => {
    fileInputRef.current.click()
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px solid #000'
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
    handleFileChange(e.dataTransfer.files[0])
  }
  const handleFileSelect = (e) => {
    handleFileChange(e.target.files[0])
  }
  const handleFileChange = async (file) => {
    //  let file = event.target.files[0]
    // List of allowed file types
    const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp']
    if (!allowedFileTypes.includes(file.type)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ chấp nhận tệp là hình ảnh!',
      })
      fileInputRef.current.value = '' // clear the input
      return
    }
    fileInputRef.current.value = ''
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      const localUrl = URL.createObjectURL(file)
      setSelectedFile((preState) => [
        ...preState,
        { base64: e.target.result, URL: localUrl, name: file.name, id: uuidv4(), file },
      ])
    }
  }
  const getLisCongTy = async () => {
    try {
      const { data } = await companyService.getAllListCompany()
      setListCongTy(data)
    } catch (error) {
      console.log(error)
    }
  }
  const getListChiNhanh = async (idct) => {
    try {
      const { data } = await branchService.getListBranch(idct)
      setListBranch(data)
    } catch (error) {
      console.log(error)
    }
  }

  const getListKhoDieuChinh = async (idcn) => {
    try {
      const { data } = await chuyenKhoService.getlistKhoNhan(idcn)
      setListKho(data)
      setKhoDieuChinh(data?.length ? data[0].idKhoCN : null)
      getListVTHHbyIdKhoCN(data?.length ? data[0].idKhoCN : null)
    } catch (error) {
      console.log(error)
    }
  }

  const getListVTHHbyIdKhoCN = async (idkhocn) => {
    try {
      setIsLoadingTon(true)
      const { data } = await https.get(`TonKho/${idkhocn ?? 0}`)
      setListTonVTHH(
        data?.result
          ?.map((item) => ({
            ...item,
            tonkhochan: Math.floor(item?.tonKho / (item?.thuocVatTu?.quyCachDongGoi ?? 1)),
          }))
          ?.filter((item) => item?.tonkhochan > 0),
      )
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingTon(false)
    }
  }

  const handleAddVTHH = (value, opt) => {
    // if (listXuatVTHH?.find((item) => item?.key === opt?.key)) {
    //   ToastCus.fire({
    //     icon: 'error',
    //     title: 'Vật tư hàng hóa này đã được chọn!',
    //   })
    //   return
    // }
    // if (!opt?.infoVTHH?.tonkhochan || opt?.infoVTHH?.tonkhochan <= 0) {
    //   ToastCus.fire({
    //     icon: 'error',
    //     title: 'Vật tư hàng hóa này không đủ tồn!',
    //   })
    //   return
    // }
    setListXuatVTHH((prev) => [
      ...prev,
      {
        dongia: opt?.infoVTHH?.dongia,
        key: uuidv4(),
        idthuoc: opt?.infoVTHH?.idThuoc,
        tenbietduoc: opt?.infoVTHH?.thuocVatTu?.tenBietDuoc,
        tenhoatchat: opt?.infoVTHH?.thuocVatTu?.tenHoatChat,
        solo: opt?.infoVTHH?.soLo,
        handung: opt?.infoVTHH?.hanDung,
        sohoadon: opt?.infoVTHH?.soHoaDon,
        soluong: 1,
        masodangky: opt?.infoVTHH?.thuocVatTu?.maSoDangKy,
        ghichu: null,
        stt: (prev?.length ?? 0) + 1,
        mathuoc: opt?.infoVTHH?.thuocVatTu?.maThuoc,
        dvt: opt?.infoVTHH?.thuocVatTu?.dvt,
        tonkho: opt?.infoVTHH?.tonKho,
        chenhlech: 0,
      },
    ])
  }

  const handleThucTeChange = (soluong, tonkho, key) => {
    const chenhlech = Number(soluong) - Number(tonkho)

    setListXuatVTHH((prev) => {
      const updated = prev?.map((item) =>
        item.key === key ? { ...item, soluong: soluong, chenhlech: chenhlech } : item,
      )

      return updated
    })
  }
  const handleInput = (key, name) => (e) => {
    const { value } = e.target
    setListXuatVTHH((prev) => {
      const updated = prev?.map((item) => (item.key === key ? { ...item, [name]: value } : item))
      return updated
    })
  }
  const handleDatepicker = (key, name) => (date, dateString) => {
    const newValue = moment(dateString, dateFormat).format(formatDateDB)
    setListXuatVTHH((prev) => {
      const updated = prev?.map((item) => (item.key === key ? { ...item, [name]: newValue } : item))
      return updated
    })
  }
  const resetForm = () => {
    setListXuatVTHH([])
    setBoPhanSD(null)
    setSelectedFile([])
    formik.resetForm()
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Clear the input
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        getLisCongTy()
        getListChiNhanh(id_company)
        getListKhoDieuChinh(id_branch)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  const handleSave = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenThemPhieu, null, actionIsTrue, actionIsFalse))
  }
  const initialValues = useMemo(
    () => ({
      GhiChu: '',
      NguoiXuat: infoUser?.idnv,
      HoaDonFiles: null,
      clickedButton: '',
    }),
    [now, id_company],
  )
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (value, action) => handleSubmit(value, action),
  })

  const closeImg = () => {
    setSelectedFile([])
    formik.setFieldValue('HoaDonFile', null)
  }
  const handleSubmit = (values, action) => {
    if (!khoDieuChinh || (chonKhoVPP && !boPhanSD)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    if (!listXuatVTHH?.length) {
      ToastCus.fire({
        icon: 'error',
        title: 'Không có vật tư hàng hóa cần xuất!',
      })
      return
    }

    const formData = new FormData()
    formData.append('IDKHOCN', khoDieuChinh)
    formData.append('GHICHU', values.GhiChu)
    formData.append('NGUOITAO', infoUser?.idnv)
    if (selectedFile?.length !== 0) {
      const dataHoaDonFiles = selectedFile?.map((item) => item.file)
      dataHoaDonFiles?.forEach((file, index) => {
        formData.append('hoaDonFiles', file)
      })
    }

    dispatch(
      postKhoDieuChinhAction(formData, listXuatVTHH, () => {
        resetForm()
      }),
    )
  }

  return (
    <>
      <div>
        {' '}
        <div className="grid grid-cols-2 items-start gap-2 pr-5">
          <div className="grid grid-flow-row items-center gap-1">
            <div className="grid grid-cols-6 items-center gap-1">
              <label className="text-end font-semibold">
                <span className="text-red-500 text-xs">(*)</span>
                Người nhập máy :
              </label>
              <Input
                className="col-span-5"
                size="small"
                readOnly
                variant="filled"
                value={infoUser?.tenNV}
              />
            </div>
            <div className="grid grid-cols-6 items-center gap-1">
              <label className="text-end font-semibold col-span-1">Ghi chú:</label>
              <Input
                className="col-span-5"
                size="small"
                name="GhiChu"
                value={formik.values.GhiChu}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="grid grid-flow-row items-center gap-1">
            <div className="grid grid-cols-3 items-center gap-1">
              <label className="text-end font-semibold col-span-1">Ngày điều chỉnh :</label>
              <Input
                className="col-span-2"
                size="small"
                readOnly
                variant="filled"
                value={moment().format('DD/MM/YYYY HH:mm:ss')}
              />
            </div>
            <div className="grid grid-cols-3 items-center gap-1">
              <label className="text-end font-semibold col-span-1">
                <span className="text-red-500 text-xs">(*)</span>Công ty:
              </label>
              <Select
                showSearch
                value={congTy}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={listCongTy.map((items) => ({
                  label: items?.tenct,
                  value: items?.idct,
                }))}
                className="col-span-2 w-full"
                size="small"
                onChange={(value) => {
                  setCongTy(value)
                  getListChiNhanh(value)
                  setBranch(null)
                  setKhoDieuChinh(null)
                  setListXuatVTHH([])
                }}
              />
            </div>
            <div className="grid grid-cols-3 items-center gap-1">
              <label className="text-end font-semibold col-span-1">
                <span className="text-red-500 text-xs">(*)</span>Chi nhánh :
              </label>
              <Select
                status={!branch && 'error'}
                value={branch}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={listBranch.map((items) => ({
                  label: items.tenChiNhanh,
                  value: items.idChiNhanh,
                }))}
                className="col-span-2 w-full"
                size="small"
                onChange={(value) => {
                  setBranch(value)
                  getListKhoDieuChinh(value)
                  setListXuatVTHH([])
                }}
              />
            </div>
            <div className="grid grid-cols-3 items-center gap-1">
              <label className="text-end font-semibold col-span-1">
                <span className="text-red-500 text-xs">(*)</span>Kho điều chỉnh :
              </label>
              <Select
                status={!khoDieuChinh && 'error'}
                value={khoDieuChinh}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={listKho?.map(({ idKhoCN, tenKho }) => ({
                  label: tenKho,
                  value: idKhoCN,
                }))}
                className="w-full col-span-2"
                size="small"
                onChange={(value) => {
                  setKhoDieuChinh(value)
                  getListVTHHbyIdKhoCN(value)
                  setListXuatVTHH([])
                }}
              />
            </div>
          </div>
        </div>
        <div className="">
          <Tabs
            items={[
              {
                key: 1,
                label: (
                  <div className="flex items-center gap-2">
                    {' '}
                    <GoldOutlined style={{ fontSize: 18 }} /> Chi tiết hàng hóa
                  </div>
                ),
                children: (
                  <>
                    <div className="flex items-center mb-2 gap-2">
                      <Select
                        disabled={isLoadingTon}
                        className="w-full"
                        placeholder="Nhập tên vật tư hàng hoá..."
                        showSearch
                        onChange={handleAddVTHH}
                        filterOption={(value, option) => {
                          const tenBietDuoc = option?.infoVTHH?.thuocVatTu?.tenBietDuoc ?? ''
                          const maThuoc = option?.infoVTHH?.thuocVatTu?.maThuoc ?? ''
                          const searchText = value?.toLowerCase()
                          return (
                            tenBietDuoc?.toLowerCase()?.includes(searchText) ||
                            maThuoc?.toLowerCase()?.includes(searchText)
                          )
                        }}
                        dropdownRender={(menu) => (
                          <>
                            <div
                              className="flex bg-gray-100"
                              style={{ padding: '8px', fontWeight: 'bold' }}
                            >
                              <span className="w-[10%] text-center">Mã hàng</span>
                              <span className="w-[30%] text-center">Tên hàng</span>
                              <span className="w-[10%] text-center">Tồn kho</span>
                              <span className="w-[5%] text-center">Đơn vị</span>
                              <span className="w-[10%] text-center">Đơn giá</span>
                              <span className="w-[5%] text-center">Số lô</span>
                              <span className="w-[10%] text-center">Hạn dùng</span>
                              <span className="w-[10%] text-center">Số hóa đơn</span>
                              <span className="w-[10%] text-center">Số đăng ký</span>
                            </div>
                            <div style={{ marginBottom: '8px' }}>{menu}</div>
                          </>
                        )}
                        optionLabelProp="label"
                        value={null}
                        options={
                          !congTy || !branch || !khoDieuChinh
                            ? []
                            : listTonVTHH?.map((d, index) => ({
                                infoVTHH: d,
                                key: `${d?.idThuoc}${d?.soLo}${d?.hanDung}${d?.soHoaDon}`,
                                value: `${d?.idThuoc}${d?.soLo}${d?.hanDung}${d?.soHoaDon}`,
                                label: (
                                  <ul className="flex no-underline">
                                    <li className="flex w-[10%] border-r-2">
                                      <p className=" w-full truncate text-center">
                                        {d?.thuocVatTu?.maThuoc}
                                      </p>
                                    </li>
                                    <li className="flex w-[30%] border-r-2 pl-2">
                                      <p>{d?.thuocVatTu?.tenBietDuoc}</p>
                                    </li>
                                    <li className="flex w-[10%] border-r-2 pl-2">
                                      <p className="w-full truncate text-center">{d?.tonKho}</p>
                                    </li>
                                    <li className="flex w-[5%] border-r-2 items-end justify-center">
                                      <p className=" w-full truncate text-center">
                                        {d?.thuocVatTu?.dvt}
                                      </p>
                                    </li>
                                    <li className="flex w-[10%] border-r-2 pl-2">
                                      <p className="w-full truncate text-center">
                                        {formatNumberVND(d?.dongia)}
                                      </p>
                                    </li>
                                    <li className="flex w-[5%] border-r-2 pl-2 items-end justify-center">
                                      <p className="w-full truncate text-center">{d?.soLo}</p>
                                    </li>
                                    <li className="flex w-[10%] border-r-2 pl-2 items-end justify-center">
                                      <p className="w-full truncate text-center">
                                        {d?.hanDung && moment(d?.hanDung).format('DD/MM/YYYY')}
                                      </p>
                                    </li>

                                    <li className="flex w-[10%] items-end justify-center">
                                      <p className=" w-full truncate text-center">{d?.soHoaDon}</p>
                                    </li>
                                    <li className="flex w-[10%] border-r-2 pl-2">
                                      <p>{d?.thuocVatTu?.maSoDangKy}</p>
                                    </li>
                                  </ul>
                                ),
                              }))
                        }
                      />
                    </div>
                    <ConfigProvider
                      theme={{
                        token: {
                          padding: 4,
                          fontSize: 12,
                        },
                        components: {
                          Table: {
                            rowHoverBg: '#ecf0f1',
                            headerBg: '#e6e6e6',
                            footerBg: '#e6e6e6',
                            borderColor: '#BABABA',
                          },
                        },
                      }}
                    >
                      <Table
                        bordered
                        pagination={false}
                        scroll={{
                          y: 300,
                        }}
                        dataSource={listXuatVTHH}
                        columns={[
                          {
                            key: 1,
                            title: 'Thông tin hàng hóa',
                            children: [
                              {
                                key: 1.1,
                                title: 'STT',
                                dataIndex: 'stt',
                                width: 40,
                                fixed: true,
                                align: 'center',
                              },
                              {
                                key: 1.2,
                                title: 'Tên hàng',
                                dataIndex: 'tenbietduoc',
                                fixed: true,
                              },
                              {
                                key: 1.3,
                                title: 'Mã hàng',
                                dataIndex: 'mathuoc',
                                width: 90,
                                fixed: true,
                              },
                            ],
                          },
                          {
                            key: 2,
                            title: 'Số lượng',
                            children: [
                              {
                                key: 2.1,
                                title: 'Tồn kho',
                                dataIndex: 'tonkho',
                                width: 75,
                                align: 'center',
                                editable: true,
                              },
                              {
                                key: 2.2,
                                title: 'Thực tế',
                                dataIndex: 'soluong',
                                align: 'center',
                                width: 60,
                                render: (text, record, index) => (
                                  <InputNumber
                                    className="w-full"
                                    size="small"
                                    value={text === '' ? 0 : text}
                                    min={0}
                                    step={1}
                                    onChange={(value) =>
                                      handleThucTeChange(value, record.tonkho, record.key)
                                    }
                                  />
                                ),
                              },
                              {
                                key: 2.3,
                                title: 'Chênh lệch',
                                dataIndex: 'chenhlech',
                                align: 'center',
                                width: 90,
                                render: (text, record) => {
                                  const chenhlech = isNaN(record.soluong - record.tonkho)
                                    ? 0
                                    : record.soluong - record.tonkho
                                  return (
                                    <span
                                      className={`${chenhlech < 0 ? 'text-red-500' : 'text-green-500'}`}
                                    >
                                      {chenhlech}
                                    </span>
                                  )
                                },
                              },
                              {
                                key: 2.4,
                                title: 'Đơn vị',
                                dataIndex: 'dvt',
                                align: 'center',
                                width: 60,
                                render: (text, record, index) => text ?? 'Cái',
                              },
                            ],
                          },
                          {
                            key: 3,
                            title: '',
                            children: [
                              {
                                key: 3.1,
                                title: 'Đơn giá',
                                dataIndex: 'dongia',
                                width: 75,
                                align: 'center',
                                render: (text, record, index) => formattedNumber(text),
                              },
                              {
                                key: 3.2,
                                title: 'Thành tiền',
                                dataIndex: 'thanhtien',
                                width: 100,
                                align: 'center',
                                render: (text, record, index) =>
                                  formattedNumber(record.soluong * record.dongia),
                              },
                              {
                                key: 3.3,
                                title: 'Số lô',
                                dataIndex: 'solo',
                                align: 'center',
                                width: 60,
                                render: (text, record, index) => (
                                  <Input
                                    className="w-full"
                                    size="small"
                                    value={text}
                                    onChange={handleInput(record.key, 'solo')}
                                  />
                                ),
                              },
                              {
                                key: 3.4,
                                title: 'Hạn dùng',
                                dataIndex: 'handung',
                                align: 'right',
                                width: 120,
                                render: (text, record, index) => (
                                  <DatePicker
                                    allowClear={false}
                                    className="w-full"
                                    size="small"
                                    value={dayjs(text)}
                                    format={dateFormat}
                                    onChange={handleDatepicker(record.key, 'handung')}
                                  />
                                ),
                              },
                              {
                                key: 3.5,
                                title: 'Số hóa đơn',
                                dataIndex: 'sohoadon',
                                align: 'center',
                                width: 90,
                                render: (text, record, index) => (
                                  <Input
                                    className="w-full"
                                    size="small"
                                    value={text}
                                    onChange={handleInput(record.key, 'sohoadon')}
                                  />
                                ),
                              },
                              // {
                              //   key: 3.6,
                              //   title: 'Số đăng ký',
                              //   dataIndex: 'masodangky',
                              //   align: 'center',
                              //   width: 90,
                              //   render: (text, record, index) => (
                              //     <Input
                              //       className="w-full"
                              //       size="small"
                              //       value={text}
                              //       onChange={handleInput(record.key, 'masodangky')}
                              //     />
                              //   ),
                              // },
                              {
                                title: 'Ghi chú',
                                dataIndex: 'ghichu',
                                key: 'ghichu',
                                width: 300,
                                render: (text, record, index) => (
                                  <Input
                                    className="w-full"
                                    size="small"
                                    value={text}
                                    onChange={handleInput(record.key, 'ghichu')}
                                  />
                                ),
                              },
                            ],
                          },

                          {
                            key: 6,
                            title: '',
                            dataIndex: 'action',
                            width: 40,
                            align: 'center',
                            fixed: 'right',
                            render: (text, record, index) => (
                              <>
                                <CloseOutlined
                                  className="text-lg text-red-500 cursor-pointer flex justify-center items-center"
                                  onClick={() => {
                                    setListXuatVTHH((prev) => {
                                      const filteredItems = prev.filter(
                                        (item) => item.key !== record.key,
                                      )
                                      return filteredItems.map((item, index) => ({
                                        ...item,
                                        stt: index + 1,
                                      }))
                                    })
                                  }}
                                />
                              </>
                            ),
                          },
                        ]}
                        summary={(pageData) => {
                          let totalChenhlech = 0
                          let totalTonkho = 0
                          let totalThucte = 0
                          let totalThanhTien = 0

                          pageData.forEach((item) => {
                            totalChenhlech += item.soluong - item.tonkho
                            totalTonkho += item.tonkho
                            totalThucte += item.soluong
                            totalThanhTien += item.soluong * item.dongia
                          })

                          return (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={3} align="center">
                                  Tổng cộng
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={2} colSpan={1} align="center">
                                  {totalTonkho}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={3} colSpan={1} align="center">
                                  {isNaN(totalThucte) ? 0 : totalThucte}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4} colSpan={1} align="center">
                                  {isNaN(totalChenhlech) ? 0 : totalChenhlech}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={5} colSpan={2}></Table.Summary.Cell>
                                <Table.Summary.Cell index={7} colSpan={1} align="center">
                                  {formattedNumber(totalThanhTien)}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={8} colSpan={5} align="center" />
                              </Table.Summary.Row>
                            </>
                          )
                        }}
                      />
                    </ConfigProvider>
                  </>
                ),
              },
              {
                key: 2,
                label: (
                  <div className="flex items-center  gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
                      />
                    </svg>
                    <div className="flex gap-1 items-start">
                      Đính kèm
                      {selectedFile?.length > 0 && (
                        <span className="font-semibold text-white bg-red-500 text-[10px] pl-[0.35rem] pr-[0.35rem] border border-red-500 rounded-full">
                          {selectedFile?.length}
                        </span>
                      )}
                    </div>
                  </div>
                ),
                children: (
                  <>
                    <ul className="flex flex-wrap gap-2 mb-2">
                      {selectedFile?.map((items, index) => (
                        <li
                          key={`upHinh${index}`}
                          className="w-[10rem] h-[10rem] overflow-hidden border rounded-md relative"
                        >
                          <DeleteOutlined
                            onClick={() => deleteFileById(items.id)}
                            className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                            style={{ fontSize: 18 }}
                          />
                          <a href={items?.URL} target="_blank">
                            <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                              <div className="relative "></div>
                              <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                                {items.name}
                              </p>
                            </div>
                            <img src={items.base64} className="w-full" alt={items.name} />
                          </a>
                        </li>
                      ))}
                    </ul>
                    <div
                      style={{
                        border: '2px dashed #cccccc',
                        borderRadius: '4px',
                        padding: '30px',
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                      className="border"
                      ref={dropzoneRef}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      onClick={handleDivClick}
                    >
                      <CloudUploadOutlined style={{ fontSize: 36 }} className="text-gray-500" />
                      <label style={{ cursor: 'pointer' }}>
                        <p className="font-semibold text-lg text-gray-500">
                          Thả tập tin vào đây hoặc bấm vào để tải lên.
                        </p>
                      </label>
                      <input
                        ref={fileInputRef}
                        id="fileInput"
                        type="file"
                        onChange={handleFileSelect}
                      />
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>
        <div className="mt-5 flex gap-5 justify-end">
          <button type="button" className="btn btn-primary btn-save" onClick={handleSave}>
            <SaveOutlined style={{ marginRight: '8px' }} />
            Lưu
          </button>
        </div>
      </div>
    </>
  )
}

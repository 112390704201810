import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Badge, Button, Input, Modal, Popover, Select } from 'antd'
import { ExclamationCircleFilled, FilterOutlined } from '@ant-design/icons'
import { https } from '../../../services/apiService'
import { useDispatch } from 'react-redux'
import { sendSMSCRMSendAll } from '../../../store/actions/benhnhanCRMAction'
import ToastCus from '../../../utils/Toast'

const optionKQ = [
  { label: 'Tất cả', value: '' },
  {
    label: 'Chưa có',
    value: 2,
  },
  {
    label: 'Hoàn thành',
    value: 1,
  },
]

const { confirm } = Modal

function OptionFIlter({ page, fetchBN, sendAll, setSendAll, reload, setReload, isExport, setIsLoadingExport }) {
  const [listKh, setListKh] = useState(null)
  const [filterKH, setFilterKH] = useState('')
  const [filterKQXetNghiem, setFilterKQXetNghiem] = useState('')
  const [filterKQKham, setFilterKQKham] = useState('')
  const [filterKQCdha, setFilterKQCdha] = useState('')
  const [filterSms, setFilterSms] = useState('')
  const [searchTriggered, setSearchTriggered] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [visible, setVisible] = useState(false)
  const [alllStatusSMS, setAlllStatusSMS] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      const { data } = await https.get('CongTyTrangThaiSMS')
      setAlllStatusSMS(
        [{ label: 'Tất cả', value: '' }].concat(
          data.map((item) => ({
            label: item.trangthaisms,
            value: item.idttsms,
          })),
        ),
      )
    })()
  }, [])

  const filterSendSMS = useMemo(() => {
    const tenCT = listKh?.find((val) => val.idkh === filterKH)
    const kqXN = optionKQ.find((val) => val.value === filterKQXetNghiem)
    const kqCDHA = optionKQ.find((val) => val.value === filterKQCdha)
    const kqKham = optionKQ.find((val) => val.value === filterKQKham)
    const statusSMS = alllStatusSMS?.find((val) => val.idttsms === filterSms)
    return {
      keyword: keyword ?? '',
      congTy: tenCT?.tenkh ?? 'Tất cả',
      kqXN: kqXN?.label ?? 'Tất cả',
      kqKham: kqKham?.label ?? 'Tất cả',
      kqCDHA: kqCDHA?.label ?? 'Tất cả',
      trangThaiSMS: statusSMS?.label ?? 'Tất cả',
    }
  }, [
    listKh,
    filterKH,
    keyword,
    filterKQXetNghiem,
    filterKQKham,
    filterKQCdha,
    filterSms,
    alllStatusSMS,
  ])

  const filterCondition = useMemo(() => {
    return {
      idKH: filterKH ? `&idKH=${filterKH}` : '',
      kqXN: filterKQXetNghiem ? `&kqXN=${filterKQXetNghiem}` : '',
      kqKham: filterKQKham ? `&kqKham=${filterKQKham}` : '',
      kqCDHA: filterKQCdha ? `&kqCDHA=${filterKQCdha}` : '',
      trangThaiSMS: filterSms ? `&trangThaiSMS=${filterSms}` : '',
      keyword: keyword ? `&keyword=${keyword}` : '',
    }
  }, [filterKH, filterKQXetNghiem, filterKQKham, filterKQCdha, filterSms, keyword])

  const showConfirmSendAll = (filterSendSMS, filterCondition) => {
    confirm({
      width: 500,
      title: 'Bạn có muốn gửi sms hay không ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          <p className="text-red-400 font-bold">Vui lòng kiểm tra điều kiện gửi</p>
          <ul>
            <li className="w-full flex">
              <p className="w-32 font-semibold">Tên bệnh nhân:</p> {filterSendSMS.keyword}
            </li>
            <li className="w-full flex">
              <p className="w-32 font-semibold">Công ty:</p> {filterSendSMS.congTy}
            </li>
            <li className="w-full flex">
              <p className="w-32 font-semibold">KQ xét nghiệm:</p> {filterSendSMS.kqXN}
            </li>
            <li className="w-full flex">
              <p className="w-32 font-semibold">KQ khám:</p> {filterSendSMS.kqKham}
            </li>
            <li className="w-full flex">
              <p className="w-32 font-semibold">KQ CĐHA:</p> {filterSendSMS.kqCDHA}
            </li>
            <li className="w-full flex">
              <p className="w-32 font-semibold">Trạng thái SMS:</p> {filterSendSMS.trangThaiSMS}
            </li>
          </ul>
        </div>
      ),
      okText: 'Gửi SMS',
      cancelText: 'Huỷ bỏ',
      onOk() {
        dispatch(sendSMSCRMSendAll(filterCondition, setReload, setSendAll))
      },
      onCancel() {},
    })
  }

  useEffect(() => {
    ;(async () => {
      const { data } = await https.get('CRMKhachHang/GetAll')
      setListKh(data)
    })()
  }, [])

  const handleSearch = (filterCondition) => {
    setSearchTriggered(true)
    setVisible(false)
    fetchBN(filterCondition, 1)
    //call api
  }
  const handleClearFilter = () => {
    setKeyword('')
    setFilterKH('')
    setFilterKQXetNghiem('')
    setFilterKQKham('')
    setFilterKQCdha('')
    setFilterSms('')
    setSearchTriggered(false) // Reset the search flag
    const filterRefreshCondition = {
      idKH: '',
      kqXN: '',
      kqKham: '',
      kqCDHA: '',
      trangThaiSMS: '',
      keyword: '',
    }
    fetchBN(filterRefreshCondition, 1)
  }

  useEffect(() => {
    fetchBN(filterCondition, page)
  }, [page])

  useEffect(() => {
    if (sendAll) {
      showConfirmSendAll(filterSendSMS, filterCondition)
    }
  }, [sendAll])

  useEffect(() => {
    if (reload) {
      fetchBN(filterCondition, page)
    }
  }, [reload])

  const handleDownload = async (filterCondition) => {
    setIsLoadingExport(true)
    try {
      const condition = Object.values(filterCondition).filter(Boolean).join('')
      console.log(filterCondition);
      const { data } = await https.get(`CRMBenhNhan/ExportDanhSachBenhNhan${condition}`, { responseType: 'blob' })
      const href = URL.createObjectURL(data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', 'Danh sách bệnh nhân.xlsx')
      document.body.appendChild(link)
      link.click()
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        type: 'error',
        message: 'Lỗi',
        description: 'Có lỗi xảy ra khi export file',
      })
    } finally {
      setIsLoadingExport(false)
    }
  }

  useEffect(() => {
    if(isExport) {
      handleDownload(filterCondition)
    }
  },[isExport])

  const contentFilter = (
    <div className="w-[500px] flex flex-col gap-2">
      <div className="w-full flex items-center">
        <label className="w-1/4 font-semibold">Tìm kiếm:</label>
        <Input
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          className="w-3/4"
          placeholder="Nhập tên bệnh nhân hoặc mã bệnh nhân"
        />
      </div>

      <div className="w-full flex items-center">
        <label className="w-1/4 font-semibold">Công ty:</label>
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) => setFilterKH(value)}
          allowClear
          options={[
            { label: 'Tất cả', value: '' },
            ...(listKh
              ? listKh.map((items) => ({
                  label: items.tenkh,
                  value: items.idkh,
                }))
              : []),
          ]}
          className="w-3/4"
          value={filterKH}
        />
      </div>
      <div className="w-full flex items-center">
        <label className="w-1/4 font-semibold">KQ xét nghiệm:</label>
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) => setFilterKQXetNghiem(value)}
          allowClear
          options={optionKQ.map(({ label, value }) => ({
            label,
            value,
          }))}
          className="w-3/4"
          value={filterKQXetNghiem}
        />
      </div>
      <div className="w-full flex items-center">
        <label className="w-1/4 font-semibold">KQ khám:</label>
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) => setFilterKQKham(value)}
          allowClear
          options={optionKQ.map(({ label, value }) => ({
            label,
            value,
          }))}
          className="w-3/4"
          value={filterKQKham}
        />
      </div>
      <div className="w-full flex items-center">
        <label className="w-1/4 font-semibold">KQ CĐHA:</label>
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) => setFilterKQCdha(value)}
          allowClear
          options={optionKQ.map(({ label, value }) => ({
            label,
            value,
          }))}
          className="w-3/4"
          value={filterKQCdha}
        />
      </div>
      <div className="w-full flex items-center">
        <label className="w-1/4 font-semibold">Trạng thái SMS:</label>
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) => setFilterSms(value)}
          allowClear
          options={alllStatusSMS.map(({ label, value }) => ({
            label,
            value,
          }))}
          className="w-3/4"
          value={filterSms}
        />
      </div>
      <div className="w-full flex items-center justify-end">
        <Button onClick={handleClearFilter}>Refresh</Button>
        <Button onClick={() => handleSearch(filterCondition)} className="ml-2" type="primary">
          Tìm kiếm
        </Button>
      </div>
    </div>
  )

  const countFilter = useMemo(() => {
    let count = 0
    if (keyword) count++
    if (filterKH) count++
    if (filterKQXetNghiem) count++
    if (filterKQKham) count++
    if (filterKQCdha) count++
    if (filterSms) count++
    return count
  }, [keyword, filterKH, filterKQXetNghiem, filterKQKham, filterKQCdha, filterSms])

  const handleVisbleChange = (value) => {
    setVisible(value)
  }
  useEffect(() => {
    if (!visible && !searchTriggered) {
      //reset
      setKeyword('')
      setFilterKH('')
      setFilterKQXetNghiem('')
      setFilterKQKham('')
      setFilterKQCdha('')
      setFilterSms('')
      setSearchTriggered(false)
    }
  }, [visible, searchTriggered])

  return (
    <Popover
      visible={visible}
      onVisibleChange={handleVisbleChange}
      placement="bottomLeft"
      content={contentFilter}
      trigger="click"
    >
      <Badge count={searchTriggered ? countFilter : 0}>
        <Button type="primary">
          <FilterOutlined /> Tìm kiếm
        </Button>
      </Badge>
    </Popover>
  )
}

OptionFIlter.propTypes = {}

export default React.memo(OptionFIlter)

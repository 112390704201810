import { Input, Select } from 'antd'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import 'moment/locale/vi'
moment.locale('vi')

const InfoBN = ({ formik }) => {
  const today = moment()
  const { detailXN } = useSelector((state) => state.xetNghiemReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const handleSelected = (value) => {
    formik.setFieldValue('donviguimau', value)
  }
  return (
    <div className="p-2 border mt-2 rounded-md">
      <div className="flex gap-2">
        <div className="w-1/2 flex flex-col gap-2">
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Tên: </label>
              <Input value={detailXN?.TENBENHNHAN} variant="filled" readOnly />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">Mã BN: </label>
              <Input value={detailXN?.MABENHNHAN} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Địa chỉ: </label>
              <Input value={detailXN?.DIACHI} variant="filled" readOnly />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">SĐT: </label>
              <Input value={detailXN?.DIENTHOAI} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-2/3 flex items-center">
              <label className="w-24 font-semibold">Lấy mẫu: </label>
              <Input
                value={
                  detailXN?.IDBNTTC
                    ? detailXN?.DanhSachXetNghiem?.length > 0
                      ? detailXN?.DanhSachXetNghiem[0]?.ktvxn
                        ? detailXN?.DanhSachXetNghiem[0]?.ktvxn
                        : infoUser?.tenNV
                      : infoUser?.tenNV
                    : ''
                }
                variant="filled"
                readOnly
              />
            </div>
            <div className="w-1/3 flex items-center">
              <label className="w-24 font-semibold">Ngày KQ: </label>
              <Input
                value={
                  detailXN?.IDBNTTC
                    ? detailXN?.DanhSachXetNghiem?.length > 0
                      ? detailXN?.DanhSachXetNghiem[0]?.ngayketqua
                        ? moment(detailXN?.DanhSachXetNghiem[0]?.ngayketqua).format('DD/MM/YYYY')
                        : today.format('DD/MM/YYYY')
                      : today.format('DD/MM/YYYY')
                    : ''
                }
                variant="filled"
                readOnly
              />
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <label className="w-24 font-semibold">Chẩn đoán: </label>
              <Input value={detailXN?.CHUANDOAN} variant="filled" readOnly />
            </div>
          </div>
        </div>
        <div className="w-1/2 gap-2 flex flex-col">
          <div className="flex gap-2">
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Ngày sinh: </label>
              <Input
                value={detailXN?.NGAYSINH && moment(detailXN?.NGAYSINH).format('DD/MM/YYYY')}
                variant="filled"
                readOnly
              />
            </div>
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Giới tính: </label>
              <Input value={detailXN?.GIOITINH} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Đối tượng: </label>
              <Input value={detailXN?.DOITUONG} variant="filled" readOnly />
            </div>
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Mã TCQG: </label>
              <Input value={detailXN?.MATCQG} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">Đơn vị lấy: </label>
              <Select
                onChange={handleSelected}
                className="w-full"
                value={formik.values.donviguimau}
                options={[
                  { label: 'Diag', value: 'Diag' },
                  { label: 'Mic', value: 'Mic' },
                  { label: 'Tvd', value: 'Tvd' },
                  { label: 'Medlatec ', value: 'Medlatec ' },
                ]}
              />
              {/* <Input variant="filled" readOnly /> */}
            </div>
            <div className="w-1/2 flex items-center">
              <label className="w-36 font-semibold">ID mẫu: </label>
              <Input value={detailXN?.IDBNTTC} variant="filled" readOnly />
            </div>
          </div>
          <div className="flex items-center">
            <label className="w-28 font-semibold">Bác sĩ CĐ: </label>
            <Input value={formik.values.bschidinh} variant="filled" readOnly />
          </div>
        </div>
      </div>
      <div className="flex items-center mt-2">
        <label className="w-24 font-semibold">Ghi chú: </label>
        <Input value={formik.values.ghichu} name="ghichu" onChange={formik.handleChange} />
      </div>
    </div>
  )
}

export default InfoBN

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Divider, Input, InputNumber,DatePicker } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import FormSanLon from './FormBenhNhanSan/FormSanLon'
import FormSanNhoPhuKhoa from '../../MaternityClinic/General/Tab/FormCD/FormSanNhoPhuKhoa'
import FormNhuHoa from './FormBenhNhanSan/FormNhuHoa'
import dayjs from 'dayjs'
import moment from 'moment'
import { CalculateDuSanh } from '../../../../utils/CalculateDuSanh'
function InfoBenhNhanSan({ formik, formikPhuSan }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isFormKham, setIsFormKham] = useState(true)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const handleChangeLucThai = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  // -------- chọn ngày dự sanh và tính ngày sanh---------//
  const handleDatePickerDuSanh = (date, dateString) => {
    const formatDate = moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD')
    const Calculate = CalculateDuSanh(formik.values.ngaykham, formatDate)
    formik.setFieldValue('dusanh', formatDate)
    formik.setFieldValue('ngaysanh', Calculate.ngaySanh)
    formik.setFieldValue('tuoithai', Calculate.tuoiThai)
  }
  return (
    <form>
      <>
        <div className="flex gap-1 mt-1">
          <label className="font-semibold w-44 text-end">
            <span className="text-red-500">(*) </span>
            Para:{' '}
          </label>
          <Input
            status={formik.errors.para ? 'error' : ''}
            onChange={formik.handleChange}
            value={formik.values.para}
            name="para"
            size="small"
          />
        </div>
        <div
          className={
            formikPhuSan.values.idloaisan === 1 || formikPhuSan.values.idloaisan === 2
              ? 'hidden'
              : 'flex gap-1 mt-1'
          }
        >
          <label className="font-semibold w-44 text-end">Chu kỳ kinh: </label>
          <Input
            onChange={formik.handleChange}
            value={formik.values.chukikinh}
            name="chukikinh"
            size="small"
          />
        </div>
        <div
          className={
            formikPhuSan.values.idloaisan === 1 || formikPhuSan.values.idloaisan === 2
              ? 'hidden'
              : 'flex gap-1 mt-1'
          }
        >
          <label className="font-semibold w-44 text-end">Ngừa thai: </label>
          <Input
            onChange={formik.handleChange}
            value={formik.values.nguathai}
            name="nguathai"
            size="small"
          />
        </div>
        <div className="flex gap-1 mt-1">
          <label className="font-semibold w-44 text-end">Kinh chót: </label>
          <Input
            onChange={formik.handleChange}
            value={formik.values.kinhchot}
            name="kinhchot"
            size="small"
          />
        </div>
        <div
          className={
            formikPhuSan.values.idloaisan === 1 || formikPhuSan.values.idloaisan === 2
              ? 'hidden'
              : 'flex gap-1 mt-1'
          }
        >
          <label className="font-semibold w-44 text-end">Kinh áp chót: </label>
          <Input
            onChange={formik.handleChange}
            value={formik.values.kinhapchot}
            name="kinhapchot"
            size="small"
          />
        </div>
        <div
          className={
            formikPhuSan.values.idloaisan === 3 || formikPhuSan.values.idloaisan === 4
              ? 'hidden'
              : 'flex gap-1 mt-1'
          }
        >
          <label className="font-semibold w-44 text-end">Dự sanh lúc đầu: </label>
          <Input
            onChange={formik.handleChange}
            value={formik.values.dusanhkc}
            name="dusanhkc"
            size="small"
          />
        </div>
        <div
          className={
            formikPhuSan.values.idloaisan === 3 || formikPhuSan.values.idloaisan === 4
              ? 'hidden'
              : 'flex gap-1 mt-1'
          }
        >
          <div className="w-1/2 flex gap-2">
            <label className="font-semibold w-52 text-end">Dự sanh:</label>
            <DatePicker
              onChange={handleDatePickerDuSanh}
              allowClear={false}
              value={formik.values.dusanh && dayjs(formik.values.dusanh)}
              format={'DD/MM/YYYY'}
              size="small"
              className="w-full"
            />
          </div>
          <Input
            onChange={formik.handleChange}
            name="ngaysanh"
            value={formik.values.ngaysanh}
            className="w-1/2 text-red-500 font-semibold"
            size="small"
          />
          {/* <label className="font-semibold w-44 text-end">Dự sanh: </label>
          <Input
            onChange={formik.handleChange}
            value={formik.values.dusanh}
            name="dusanh"
            size="small"
          /> */}
        </div>
        <div
          className={
            formikPhuSan.values.idloaisan === 3 || formikPhuSan.values.idloaisan === 4
              ? 'hidden'
              : 'flex gap-1 mt-1'
          }
        >
          <label className="font-semibold w-44 text-end">Tuổi thai: </label>
          <Input
            onChange={formik.handleChange}
            value={formik.values.tuoithai}
            name="tuoithai"
            size="small"
          />
        </div>
        <div
          className={
            formikPhuSan.values.idloaisan === 3 || formikPhuSan.values.idloaisan === 4
              ? 'hidden'
              : 'flex gap-1 mt-1'
          }
        >
          <label className="font-semibold w-[12.5%] text-end">Lúc thai: </label>
          <InputNumber
            min={0}
            max={100}
            step={1}
            onChange={handleChangeLucThai('tuanthai')}
            value={formik.values.tuanthai}
            name="tuanthai"
            size="small"
            className="w-1/5"
          />
          <span className="mr-16">Tuần</span>
          <InputNumber
            min={0}
            max={100}
            step={1}
            onChange={handleChangeLucThai('ngaythai')}
            value={formik.values.ngaythai}
            name="ngaythai"
            size="small"
            className="w-1/5"
          />
          <span>Ngày</span>
        </div>
        <div className="flex gap-1 mt-1">
          <label className="font-semibold w-44 text-end">Tổng trạng: </label>
          <Input
            onChange={formik.handleChange}
            value={formik.values.tongtrang}
            name="tongtrang"
            size="small"
          />
        </div>
        <div className="flex gap-1 mt-1">
          <label className="font-semibold w-44 text-end">Tim phổi: </label>
          <Input
            onChange={formik.handleChange}
            value={formik.values.timphoi}
            name="timphoi"
            size="small"
          />
        </div>
        <div className="flex gap-1 mt-1">
          <label className="font-semibold w-44 text-end">Da niêm: </label>
          <Input
            onChange={formik.handleChange}
            value={formik.values.daniem}
            name="daniem"
            size="small"
          />
        </div>
        <div className="flex gap-1 mt-1">
          <label className="font-semibold w-44 text-end">Bụng: </label>
          <Input
            onChange={formik.handleChange}
            value={formik.values.bung}
            name="bung"
            size="small"
          />
        </div>
        <div className="flex gap-1 mt-1">
          <label className="font-semibold w-44 text-end">Phù: </label>
          <Input onChange={formik.handleChange} value={formik.values.phu} name="phu" size="small" />
        </div>
        <Divider
          plain
          style={{ margin: 5, marginTop: 15 }}
          className="mt-10"
          onClick={() => {
            if (isFormKham) setIsFormKham(false)
            else setIsFormKham(true)
          }}
        >
          <p className="font-semibold">
            {formikPhuSan.values.idloaisan === 1
              ? 'Khám sản lớn '
              : formikPhuSan.values.idloaisan === 2
                ? 'Khám sản nhỏ '
                : formikPhuSan.values.idloaisan === 3
                  ? 'Khám phụ khoa '
                  : formikPhuSan.values.idloaisan === 4
                    ? 'Khám nhũ hoa '
                    : ''}
            {isFormKham ? <UpOutlined /> : <DownOutlined />}
          </p>
        </Divider>
        {isFormKham ? (
          formikPhuSan.values.idloaisan === 1 ? (
            <>
              <FormSanLon formikPhuSan={formikPhuSan} />
            </>
          ) : formikPhuSan.values.idloaisan === 2 || formikPhuSan.values.idloaisan === 3 ? (
            <>
              <FormSanNhoPhuKhoa formikPhuSan={formikPhuSan} />
            </>
          ) : formikPhuSan.values.idloaisan === 4 ? (
            <>
              <FormNhuHoa formikPhuSan={formikPhuSan} />
            </>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </>
    </form>
  )
}

InfoBenhNhanSan.propTypes = {}

export default InfoBenhNhanSan

import React, { useState } from 'react'
import { ConfigProvider, Segmented } from 'antd'
import EditProducts from './EditProducts/EditProducts'
import { FormOutlined, ProductOutlined, CalendarOutlined } from '@ant-design/icons'
import Products from './Products/Products'
import KyHang from './KyHang/KyHang'
const CustomizeProducts = () => {
  const [value, setValue] = useState(2)

  const viewsComponent = {
    // 1: <EditProducts />,
    2: <Products />,
    3: <KyHang />,
  }
  const getViewsByValue = (value) => viewsComponent[value] || ''
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Segmented: {
              itemColor: '#344767',
              itemSelectedColor: '#344767',
            },
          },
        }}
      >
        <Segmented
          className="mb-2"
          size="middle"
          value={value}
          options={[
            // {
            //   label: 'Cập nhật hàng',
            //   value: 1,
            //   className: ' font-semibold',
            //   icon: <FormOutlined style={{ fontSize: 16 }} />,
            // },
            {
              label: 'Hàng hóa',
              value: 2,
              className: ' font-semibold',
              icon: <ProductOutlined style={{ fontSize: 16 }} />,
            },
            {
              label: 'Kỳ hàng',
              value: 3,
              className: ' font-semibold',
              icon: <CalendarOutlined style={{ fontSize: 16 }} />,
            },
          ]}
          onChange={setValue}
        />
      </ConfigProvider>
      {getViewsByValue(value)}
    </>
  )
}

export default CustomizeProducts

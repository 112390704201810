import { ContainerOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import SyncIcon from '@mui/icons-material/Sync'
import { IconButton, Tooltip } from '@mui/material'
import { ConfigProvider, Input, Modal, Pagination, Select, Table, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getlistDoitac } from '../../../store/actions/NhapKhoAction'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import {
  getAllDonHangThuMuaDuyet,
  updateTrangThaDatHang,
} from '../../../store/actions/vanPhongPhamAction'
import { trangThaiDatHang } from '../../../utils/trangThaiDatHang'
import ModalEdit from './Modal/ModalEdit'
import ThongKeMatHangDaMua from './ThongKeMatHangDaMua'

const { confirm } = Modal

function VanChuyen(props) {
  const { listDatHang } = useSelector((state) => state.vanPhongPhamReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const [openEdit, setOpenEdit] = useState({
    show: false,
    data: null,
  })
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [keyword, setKeyword] = useState('')

  const handleShowModalDetail = (record) => {
    setOpenEdit({
      show: true,
      data: record,
    })
  }

  const showConfirm = (item) => {
    confirm({
      title: (
        <div>
          <p>Xác nhận vận chuyển đơn đặt hàng:</p>
          <p className="font-bold">{item?.tendonhang}</p>
        </div>
      ),
      icon: <ExclamationCircleFilled />,
      content: `Hàng hóa sẽ được vận chuyển tới đơn vị nhận`,
      okText: 'Xác nhận',
      cancelText: 'Huỷ bỏ',
      onOk() {
        //update trang thai dat hang ve 6 nhan hang
        dispatch(updateTrangThaDatHang(item.iddathang, trangThaiDatHang.nhapHang, '', onLoad))
      },
      onCancel() {},
    })
  }

  const handleCompany = (value) => {
    setCompany(value)
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
  }, [])

  const onLoad = () => {
    dispatch(getAllDonHangThuMuaDuyet(keyword, company, 1))
  }

  useEffect(() => {
    onLoad()
  }, [])

  //setTotalPage
  useEffect(() => {
    if (listDatHang?.totalPages) setTotalPage(listDatHang?.totalPages)
  }, [listDatHang?.totalPages])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 80,
      align: 'center',
    },
    {
      title: 'Mã hàng hóa',
      dataIndex: 'mathuoc',
      key: 'mathuoc',
      width: 200,
    },
    {
      title: 'Tên hàng',
      dataIndex: 'tenbietduoc',
      key: 'tenbietduoc',
    },
    {
      title: 'SL cần đặt',
      dataIndex: 'soluongduyet',
      key: 'soluongduyet',
      width: 120,
      align: 'center',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
    },
  ]

  useEffect(() => {
    dispatch(getlistDoitac())
  }, [])

  const handleChangePage = (keyword, company, page) => {
    setCurrentPage(page)
    SetSearchParams({ page })
    dispatch(getAllDonHangThuMuaDuyet(keyword, company, page))
  }

  const handleSearch = () => {
    SetSearchParams({ page: 1 })
    setCurrentPage(1)
    dispatch(getAllDonHangThuMuaDuyet(keyword, company, 1))
  }
  return (
    <Tabs
      defaultActiveKey="1"
      className="p-4"
      items={[
        {
          label: 'Mua hàng',
          key: 1,
          children: (
            <div className="h-full w-full bg-[#EBEBEB] p-2 border rounded-sm ">
              <div className="flex gap-5 justify-between bg-white border-dotted border-b-0 p-4">
                <div className="flex gap-5 justify-between w-full">
                  <Select
                    showSearch
                    className="w-72"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    onChange={handleCompany}
                    value={company}
                    options={listCompany?.map((items) => ({
                      label: items.tenct,
                      value: items.idct,
                    }))}
                  />
                  <div className="w-64">
                    <Input
                      className="w-full"
                      placeholder="Nhập từ khóa..."
                      onChange={(e) => {
                        setKeyword(e.target.value)
                      }}
                      value={keyword}
                    />
                  </div>
                  <div className="flex gap-3 mr-auto">
                    <IconButton
                      color="primary"
                      aria-label="delete"
                      size="small"
                      onClick={handleSearch}
                    >
                      <SyncIcon />
                    </IconButton>
                  </div>
                  <div className="ml-auto">
                    {/* <Button
                //   onClick={() => exportToExcel(search, chiNhanh, company, dateFrom, dateTo)}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
                //   loading={loadingExport}
                //   disabled={loadingExport}
              >
                Xuất Excel
              </Button> */}
                  </div>
                </div>
              </div>
              <div className="bg-white p-2">
                <div>
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 5,
                      },
                      components: {
                        Table: {
                          rowHoverBg: '#ecf0f1',
                          headerBg: '#e6e6e6',
                          footerBg: '#e6e6e6',
                          borderColor: '#BABABA',
                        },
                      },
              
                    }}
                  >
                    <Table
                      bordered
                      scroll={{
                        y: 800,
                      }}
                      pagination={false}
                      columns={columns}
                      dataSource={listDatHang?.data?.map((item, idx) => ({
                        ...item,
                        stt: (currentPage - 1) * 20 + (idx + 1),
                        action: (
                          <>
                            <Tooltip title="Xem chi tiết" color="#108ee9" className="ml-4">
                              <ContainerOutlined
                                onClick={() => {
                                  handleShowModalDetail(item)
                                }}
                                className="text-xl text-[#108ee9]  cursor-pointer"
                              />
                            </Tooltip>
                          </>
                        ),
                      }))}
                    />
                  </ConfigProvider>
                  <div className="flex justify-end">
                    <Pagination
                      className="mt-5"
                      showSizeChanger={false}
                      current={+useSearch.get('page')}
                      simple
                      defaultCurrent={1}
                      pageSize={20}
                      total={20 * totalPage}
                      onChange={(page, pagesize) => {
                        handleChangePage(keyword, company, page)
                      }}
                    />
                  </div>
                </div>
              </div>
              {openEdit && (
                <ModalEdit openEdit={openEdit} setOpenEdit={setOpenEdit} onLoad={onLoad} />
              )}
            </div>
          ),
        },
        {
          label: 'Thống kê mặt hàng đã mua',
          key: 2,
          children: <ThongKeMatHangDaMua />,
        },
      ]}
    />
  )
}

VanChuyen.propTypes = {}

export default VanChuyen
